export const roles = [
    { label: "Admin", value: "admin" },
    { label: "Manager", value: "manager" },
    { label: "Operator/Logistician", value: "operator" },
    { label: "Viewer/Analyst", value: "viewer" },
    { label: "Technician", value: "Technician" },
];


export const accessTypes = [
    { label: "All access", value: "all_access" },
    { label: "View financials", value: "financials_view" },
    { label: "Edit shipments", value: "shipment_edit" },
    { label: "Edit workflows", value: "workflow_edit" },
    { label: "View workflows only", value: "workflow_view" },
    { label: "View shipment", value: "shipment_view" },
    { label: "View analytics", value: "analytics_view" },
    { label: "Inventory edit", value: "inventory_edit" },
    { label: "Inventory view", value: "inventory_view" },
    { label: "Supplier edit", value: "supplier_edit" },
    { label: "Supplier view", value: "supplier_view" },
    { label: "Purchase order edit", value: "purchase_order_edit" },
    { label: "Purchase order view", value: "purchase_order_view" },
    { label: "Edit analytics", value: "analytics_edit" },
    { label: "Operations access", value: "operations_edit" },
    { label: "View other members", value: "team_view" },
    { label: "Manage other members", value: "team_edit" },
];


