import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import { formatMoney, getCurrencyFromCurrencyCode, onScroll } from "helpers";
import { getEditPermissionList } from "helpers/getEditPermissionList";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";

const StockCountHistory = ({ setTotalStock }) => {
    const limit = 20;
    const dispatch = useAppDispatch();
    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
    const [stockLimit, setStockLimit] = useState(limit);
    const [loadMoreStockCount, setLoadMoreStockCount] = useState(false);
    const [stockCountList, setStockCountList] = useState<{ [key: string]: any }[]>([]);
    const [stockCountPagination, setStockCountPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const { inventoryId, warehouseId } = useParams();
    const {
        fetchedListStockWarehouseSuccess,
        fetchingListStockWarehouse,
        fetchedListStockWarehouseFailure,
    } = useAppSelector((state) => state.inventory);
    const dashboardContent = document.getElementById("dashboardContent");

    useEffect(() => {
        dispatch(
            inventoryActions.fetchListStockWarehouse(warehouseId, stockLimit, inventoryId, "")
        );
    }, [stockLimit, warehouseId, inventoryId]);

    const navigate = useNavigate();

    useEffect(() => {
        if (Boolean(fetchedListStockWarehouseSuccess)) {
            setTotalStock(fetchedListStockWarehouseSuccess);
            setStockCountList(fetchedListStockWarehouseSuccess?.products);
            setStockCountPagination({
                current: fetchedListStockWarehouseSuccess?.pagination?.current,
                number_of_pages: fetchedListStockWarehouseSuccess?.pagination?.number_of_pages,
            });
            setIsPageLoaded(true);
        }
    }, [fetchedListStockWarehouseSuccess]);

    useEffect(() => {
        if (Boolean(fetchedListStockWarehouseFailure)) {
            setIsPageLoaded(true);
        }
    }, [fetchedListStockWarehouseFailure]);

    const onVendorScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                stockCountPagination?.current as number,
                stockCountPagination?.number_of_pages as number,
                () => {
                    setStockLimit(() => stockLimit + limit);
                    setLoadMoreStockCount(true);
                }
            ),
        [dashboardContent, stockCountPagination, stockLimit]
    );

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onVendorScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onVendorScroll);
        };
    }, [dashboardContent, onVendorScroll]);

    const tableHeader = [
        { title: "Batch ID", widthClass: "w-[100px]" },
        { title: "Vendor/Supplier", widthClass: "w-[148px]" },
        { title: "Unit cost (USD)", widthClass: "w-[118px]" },
        { title: "Quantity", widthClass: "w-[84px]" },
        { title: "UOM", widthClass: "w-[84px]" },
        { title: "MFD", widthClass: "w-[125px]" },
        { title: "Best Before Date", widthClass: "w-[125px]" },
        { title: "Expiration Time", widthClass: "w-[125px]" },
        { title: "Removal Time", widthClass: "w-[125px]" },
        { title: "", widthClass: "w-[48px]" },
    ];
    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if ((e.target as any).scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const tableBody = stockCountList?.map((count) => [
        {
            content: (
                <div className={`py-3 pr-3 h-full border-slate-100 text-sm font-rocGroteskMedium`}>
                    {count?.batchId}
                </div>
            ),
            widthClass: "!border-t",
            cellClickAction: () => navigate(`/dashboard/inventory/stock-control/${count?._id}`),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-2">
                        <VendorAvatar
                            name={count?.vendor?.profile?.businessName}
                            imageSrc={count?.vendor?.profile?.avatar}
                            size={32}
                            containerClassname="!rounded"
                        />

                        <span>{count?.vendor?.profile?.businessName}</span>
                    </div>
                </div>
            ),
            widthClass: "!border-t",
        },

        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {getCurrencyFromCurrencyCode(count?.cost?.currency)}
                    {formatMoney()?.format(count?.cost?.amount ?? 0)}
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {count?.productStockDetails?.stockLevel}
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3  items-center h-full flex space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {count?.inventory?.productStockDetails?.unitOfMeasurement}
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3  items-center h-full flex space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {moment(count?.productViabilityDetails?.manufacturingDate).format(
                        "MMM DD, YYYY"
                    )}
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3  items-center h-full flex space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {count?.productViabilityDetails?.bestBefore
                        ? moment(count?.productViabilityDetails?.bestBefore).format("MMM DD, YYYY")
                        : "N/A"}
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3  items-center h-full flex space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {count?.productViabilityDetails?.expiryDate
                        ? moment(count?.productViabilityDetails?.expiryDate).format("MMM DD, YYYY")
                        : "N/A"}
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3  items-center h-full flex space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {count?.productViabilityDetails?.removalTime
                        ? moment(count?.productViabilityDetails?.removalTime).format("MMM DD, YYYY")
                        : "N/A"}
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div
                    className={`py-[18px] flex justify-center  items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        dropdownTriggerClassName={" "}
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-700"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-4 -left-[100px]  border border-slate-100 w-[159px] rounded !max-h-fit`}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "Edit Stock",
                                value: "edit stock",
                                action: () =>
                                    navigate(
                                        `/dashboard/inventory/stock-control/edit-stock/${count?._id}?showcrumbs=true`
                                    ),
                                permissions: getEditPermissionList("inventory"),
                            },
                        ]}
                    />
                </div>
            ),
        },
    ]);

    return (
        <div className="overflow-x-scroll w-full" onScroll={handleScroll}>
            {!isPageLoaded ? (
                <Loader />
            ) : (
                <>
                    {stockCountList?.length > 0 ? (
                        <>
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isAllSelectable={false}
                                isScrollable={true}
                                isScrolled={isScrolled}
                                isCellBordered={false}
                                headerContainerClass="!bg-slate-50  !border-slate-100 "
                                bodyItemClass="hover:border-slate-100 !border-b hover:bg-slate-50 "
                                headerItemClass="font-rocGroteskMedium !text-slate-700"
                            />
                            {loadMoreStockCount && fetchingListStockWarehouse && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {stockCountPagination?.current ===
                                stockCountPagination?.number_of_pages && (
                                <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="flex items-center justify-center mt-[76px]">
                            <div>
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1704890969/Tradeally_2.0/empty_state_img_a8twwl.svg"
                                    alt="empty state"
                                    className="w-[268px] h-[235px]"
                                />
                                <p className="text-center text-sm text-[#475569] font-rocGroteskMedium mt-5">
                                    You have no stock
                                </p>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default StockCountHistory;
