import React from "react";
import Button from "components/common/Button";
import LandingFooter from "components/views/Landing/LandingFooter";
import LandingNav from "components/views/Landing/LandingNav";
import PreFooterCard from "components/views/Landing/PreFooterCard";
import RoiCalculator from "components/views/Ldb/Dashboard/Settings/RoiCalculator";
import { Link } from "react-router-dom";

const Home = () => {
    return (
        <div className={`relative`}>
            <LandingNav />
            <div
                className={`bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702551019/Tradeally_2.0/Landing%20page/Dotted_background_1_u2w4ev.png')] bg-cover bg-no-repeat h-[861px] max-lg:h-[451px] w-screen flex justify-center`}
            >
                <div>
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702551334/Tradeally_2.0/Landing%20page/Ellipse_2_wbs31z.png"
                        alt="green gradient"
                        className="absolute right-0 object-cover max-lg:hidden"
                        height={861}
                        width={"50%"}
                    />
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702551334/Tradeally_2.0/Landing%20page/Ellipse_2_wbs31z.png"
                        alt="green gradient"
                        className="absolute right-0 object-cover max-lg:hidden"
                        height={861}
                        width={"50%"}
                    />
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702557648/Tradeally_2.0/Landing%20page/mobile_Ellipse_8_1_uhxtf8.png"
                        alt="orange gradient"
                        className="absolute left-0 w-full object-cover hidden max-lg:block"
                    />
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702557646/Tradeally_2.0/Landing%20page/mobile_Ellipse_2_1_mv700j.png"
                        alt="green gradient"
                        className="absolute right-0 w-full object-cover hidden max-lg:block"
                    />
                </div>
                <div className="mt-[158px] text-center w-[63%] max-lg:w-full px-6 relative z-10 flex flex-col items-center">
                    <h1 className="text-[72px] max-lg:text-[32px] max-lg:leading-[38.4px] leading-[86.4px] font-rocGroteskMedium mb-3">
                        An Orchestra of <br /> Seamless Procurement
                    </h1>
                    <p className="text-2xl text-slate-600 max-lg:text-sm font-rocGroteskMedium max-lg:w-full px-10 max-lg:px-5 mb-9">
                        Automate local + cross-border requisition-to-delivery tasks in a single
                        platform with AI
                    </p>
                    <div className="flex items-center gap-4">
                        <Link to={"/register"}>
                            <Button
                                btnText={"Get started"}
                                btnClassname={
                                    "!shadow-boxShadow-4 !w-fit !cursor-pointer !h-[48px] px-6"
                                }
                            />
                        </Link>
                        <Link to={"/book-a-demo"}>
                            <Button
                                btnText={"Book a demo"}
                                btnClassname={
                                    "!shadow-boxShadow-4 !cursor-pointer !h-[48px] !w-[161px] !bg-white"
                                }
                            />
                        </Link>
                    </div>
                </div>
            </div>
            <img
                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702551918/Tradeally_2.0/Landing%20page/Ellipse_8_jrchta.png"
                alt="orange gradient"
                className="left-0 top-0 absolute max-lg:hidden"
            />
            <img
                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702551918/Tradeally_2.0/Landing%20page/Ellipse_8_jrchta.png"
                alt="orange gradient"
                className="left-0 top-0 absolute max-lg:hidden"
            />
            <img
                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702551918/Tradeally_2.0/Landing%20page/Ellipse_8_jrchta.png"
                alt="orange gradient"
                className="left-0 top-0 absolute hidden max-lg:block w-full"
            />
            <img
                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702551918/Tradeally_2.0/Landing%20page/Ellipse_8_jrchta.png"
                alt="orange gradient"
                className="left-0 top-0 absolute hidden max-lg:block w-full"
            />
            <div className="flex justify-center mt-[-250px] max-lg:mt-[-20px] z-10 relative">
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702543083/Tradeally_2.0/Landing%20page/Hero_image_3_z0ymhh.svg"
                    alt="vendor managament"
                    width={1000}
                    className="max-sm:hidden"
                />
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702543083/Tradeally_2.0/Landing%20page/Hero_image_3_z0ymhh.svg"
                    alt="vendor managament"
                    width={357}
                    height={224}
                    className="hidden max-sm:block"
                />
            </div>
            <div className="flex flex-col items-center mt-[120px] max-lg:[75px] px-6 relative text-center">
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702543325/Tradeally_2.0/Landing%20page/flash_qcfztu.svg"
                    alt="flash icon"
                    className="mb-3 max-lg:h-[50px] max-lg:w-[29px]"
                />
                <div className="w-[55%] max-lg:w-full max-lg:px-5 mb-[62px] max-lg:mb-[36px] relative">
                    <p className="text-[48px] max-lg:text-[28px] font-rocGroteskMedium mb-3 max-lg:mb-2">
                        Synthesize{" "}
                        <span className="bg-clip-text text-transparent bg-gradient-to-b from-[#FDB709] from-0% to-[#9E57EE] to-99.59%">
                            Procurement with AI
                        </span>
                    </p>
                    <p className="text-xl max-lg:text-xs max-lg:leading-normal text-center text-slate-600 font-rocGroteskMedium">
                        Synth is powered by an AI-assistant that understands the procurement needs
                        of your projects and business, automating your workflows into a unified +
                        seamless symphony of efficient execution.
                    </p>
                </div>

                <div className="w-[87%] h-[786px] max-lg:w-full max-lg:px-2 max-sm:h-[235px] max-lg:h-[500px] rounded-xl flex justify-center items-center bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702552337/Tradeally_2.0/Landing%20page/video_container_vchdxx.png')] bg-cover bg-no-repeat">
                    <video
                        poster="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702543083/Tradeally_2.0/Landing%20page/Hero_image_3_z0ymhh.svg"
                        src="https://res.cloudinary.com/dvxi7qcmd/video/upload/q_auto,f_auto/v1706864861/Synth_Demo_Video_dnh9xn.mp4"
                        playsInline
                        controls={true}
                        autoPlay
                        controlsList="nodownload"
                        muted
                        className="z-10 cursor-pointer w-[1000px] h-[560px] max-lg:w-full max-lg:h-auto shadow-[0px_24px_48px_-12px_rgba(22,198,164,0.18)]"
                    />
                </div>
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702552588/Tradeally_2.0/Landing%20page/Ellipse_4_nctwbg.png"
                    alt="orange gradient"
                    className="max-lg:bottom-[-250px] bottom-[-820px] absolute"
                />
            </div>
            <div id="features" className="mt-[72px] mb-[142px] max-sm:mt-[100px] relative">
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702543886/Tradeally_2.0/Landing%20page/line_1_piz9fz.svg"
                    alt="curved line"
                    className="w-full absolute top-[-630px] max-lg:top-[-205px]"
                />
                <div className="text-center mb-12">
                    <p className="text-[40px] max-lg:text-[28px] max-lg:mb-2 mb-3 font-rocGroteskMedium bg-clip-text text-transparent bg-gradient-to-b from-[#FDB709] from-0% to-[#9E57EE] to-99.59%">
                        Save Up to 2 Months <br className="hidden max-lg:block" /> Yearly with Synth
                    </p>

                    <p className="text-xl max-lg:text-sm text-slate-600 font-rocGroteskMedium ">
                        See what that translates to in cost-savings.
                    </p>
                </div>
                <div className="flex flex-col relative z-10 items-center px-6 justify-center">
                    <div className="grid grid-cols-3 w-[87%] max-lg:w-full max-lg:grid-cols-1 gap-6 mb-8">
                        <div className="p-8 bg-white/70 rounded-xl shadow-boxShadow-6">
                            <p className="text-2xl font-rocGroteskMedium mb-1">8+hours</p>
                            <p className="text-sm font-rocGroteskMedium ">
                                Saved per cross-border transaction
                            </p>
                        </div>
                        <div className="p-8 bg-white/70 rounded-xl shadow-boxShadow-6">
                            <p className="text-2xl font-rocGroteskMedium mb-1">6%</p>
                            <p className="text-sm font-rocGroteskMedium ">
                                Saved on costs per cross-border transaction
                            </p>
                        </div>
                        <div className="p-8 bg-white/70 rounded-xl shadow-boxShadow-6">
                            <p className="text-2xl font-rocGroteskMedium mb-1">{">80%"}</p>
                            <p className="text-sm font-rocGroteskMedium ">
                                Supply-chain process automation
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-center mb-[53px]" id="calculator">
                <div className="w-[75%] pb-8 max-lg:w-full px-6 relative z-[10]">
                    <p className="text-center text-[40px] max-lg:text-[28px] max-lg:px-[54px] font-rocGroteskMedium mb-12">
                        Calculate your subscription ROI
                    </p>
                    <RoiCalculator isWebsite={true} />
                </div>
            </div>

            <div className=" mb-[118px]">
                <p className="text-center text-[40px] max-lg:text-[28px] max-lg:px-[54px] font-rocGroteskMedium mb-12">
                    Automate 80% of Procurement Processes on Synth
                </p>
                <div className="flex justify-center relative">
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702553179/Tradeally_2.0/Landing%20page/orange_gradient_1_xkjy66.png"
                        alt="orange gradient"
                        className="left-0 top-[-190px] absolute max-lg:hidden"
                    />
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702553175/Tradeally_2.0/Landing%20page/purple_gradient_1_naqrjk.png"
                        alt="purple gradient"
                        className=" top-[-40px] absolute max-lg:hidden"
                    />
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702553187/Tradeally_2.0/Landing%20page/green_gradient_2_hr72ik.png"
                        alt="green gradient"
                        className="right-0 top-[-350px] absolute max-lg:hidden"
                    />

                    <div className="w-[87%] max-lg:w-full grid grid-cols-3 max-lg:grid-cols-1 px-6 gap-4 relative">
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702558685/Tradeally_2.0/Landing%20page/mobile_orange_gradient_2_ugbluz.png"
                            alt="orange gradient"
                            className="w-full absolute top-[-400px] max-lg:top-[-800px] hidden max-lg:block"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702558683/Tradeally_2.0/Landing%20page/mobile_purple_gradient_1_jtstex.png"
                            alt="purple gradient"
                            className="w-full absolute top-[800px] max-lg:top-0 hidden max-lg:block"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702558679/Tradeally_2.0/Landing%20page/mobile_green_gradient_2_qrcn7u.png"
                            alt="green gradient"
                            className="w-full absolute max-md:top-[1200px] hidden max-lg:block"
                        />
                        <div className=" flex flex-col gap-4 max-lg:hidden">
                            <div className="h-[318px] relative w-full flex flex-col justify-end rounded-lg shadow-boxShadow-3 bg-white/70 pl-8 pr-4 pb-6">
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702546050/Tradeally_2.0/Landing%20page/timer-flash-line_evfnk3.png"
                                    alt="timer background"
                                    className="absolute right-0 top-0"
                                    width={150}
                                    height={150}
                                />
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1692975367/Tradeally_2.0/Landing%20page/saveTimeIcon_ycxw23.svg"
                                    alt="icon"
                                    className="ml-[-10px]"
                                    width={72}
                                    height={52}
                                />
                                <div>
                                    <p className="text-xl font-rocGroteskMedium mb-3">
                                        Save Time on Manual Processes
                                    </p>
                                    <p className="text-base text-slate-500 font-rocGroteskMedium ">
                                        Cutting-edge AI automation allows you to generate RFQs in
                                        minutes, evaluate quotes using established parameters in
                                        seconds, and turn your SOPs + processes into powerful
                                        workflows with Synth
                                    </p>
                                </div>
                            </div>
                            <div className="h-[384px] relative w-full flex flex-col justify-end rounded-lg shadow-boxShadow-3 bg-white/70 p-8">
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702546050/Tradeally_2.0/Landing%20page/flow-chart_rmypeo.png"
                                    alt="flow background"
                                    className="absolute right-0 top-0"
                                    width={278}
                                    height={278}
                                />
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1692975365/Tradeally_2.0/Landing%20page/reduceManualIcon_miy9mm.svg"
                                    alt="icon"
                                    className="ml-[-10px]"
                                    width={72}
                                    height={52}
                                />
                                <div>
                                    <p className="text-xl font-rocGroteskMedium mb-3">
                                        Minimal Learning Curve
                                    </p>
                                    <p className="text-base text-slate-500 font-rocGroteskMedium ">
                                        Dive in without delay. Synth is designed to be intuitive,
                                        ensuring that you, your team, and vendors can get started
                                        without any hassle.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className=" max-lg:hidden">
                            <div className="h-full max-lg:h-[500px] relative w-full flex flex-col items-center justify-end rounded-lg shadow-boxShadow-3 bg-white/70 p-6">
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702545697/Tradeally_2.0/Landing%20page/Map_1_vdtbek.svg"
                                    alt="map background"
                                    className="absolute top-0"
                                    width={"100%"}
                                    height={649}
                                />
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1692975364/Tradeally_2.0/Landing%20page/crossBorderIcon_ggtlug.svg"
                                    alt="icon"
                                    width={72}
                                    height={52}
                                />
                                <div className="text-center">
                                    <p className="text-xl font-rocGroteskMedium mb-3 whitespace-nowrap">
                                        Enhanced Procurement Oversight
                                    </p>
                                    <p className="text-base text-slate-500 font-rocGroteskMedium ">
                                        Reduce procurement fraud and project delays with workflows
                                        and real-time collaboration and transparency by aggregating
                                        conversations with customers, team members, and vendors from
                                        Email and WhatsApp into one channel, Synth.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="max-lg:hidden flex flex-col gap-4">
                            <div className="h-[392px] relative w-full flex flex-col justify-end rounded-lg shadow-boxShadow-3 bg-white/70 p-8">
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702545669/Tradeally_2.0/Landing%20page/plug_lfqc3u.png"
                                    alt="plug background"
                                    className="absolute right-0 top-0"
                                    width={144}
                                    height={236}
                                />
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1692975368/Tradeally_2.0/Landing%20page/seamlessIntergrationIcon_wmq0js.svg"
                                    alt="icon"
                                    className="ml-[-10px]"
                                    width={72}
                                    height={52}
                                />

                                <div>
                                    <p className="text-xl font-rocGroteskMedium mb-3">
                                        Uninterrupted Stream of Information
                                    </p>
                                    <p className="text-base text-slate-500 font-rocGroteskMedium ">
                                        With Synth, you can integrate easily with existing systems,
                                        ensuring a seamless flow of information from your core
                                        system to your procurement software.
                                    </p>
                                </div>
                            </div>

                            <div className="h-[310px] relative w-full flex flex-col justify-between rounded-lg shadow-boxShadow-3 bg-white/70 p-8">
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702546050/Tradeally_2.0/Landing%20page/flash_cut_w8luqi.png"
                                    alt="flash background"
                                    className="absolute right-0 top-0"
                                    width={140}
                                    height={92}
                                />
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1692975365/Tradeally_2.0/Landing%20page/onboardVendorIcon_sasdcn.svg"
                                    alt="icon"
                                    className="ml-[-10px]"
                                    width={72}
                                    height={52}
                                />
                                <div>
                                    <p className="text-xl font-rocGroteskMedium mb-3">
                                        Streamlined Vendor
                                        <br /> Management
                                    </p>
                                    <p className="text-base text-slate-500 font-rocGroteskMedium ">
                                        Supercharge your vendor relations with accelerated
                                        integration — that your vendors love — enhanced
                                        communication, and real-time performance analysis.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="h-full max-lg:h-[416px] relative w-full hidden max-lg:flex flex-col items-center justify-end rounded-lg shadow-boxShadow-3 bg-white/70 p-5">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702559468/Tradeally_2.0/Landing%20page/mobile_timer-flash-line_1_foibab.png"
                                alt="timer background"
                                className="absolute top-0 max-lg"
                                height={160}
                                width={170}
                            />
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1692975367/Tradeally_2.0/Landing%20page/saveTimeIcon_ycxw23.svg"
                                alt="icon"
                                width={85}
                                height={52}
                            />
                            <div className="text-center">
                                <p className="text-xl font-rocGroteskMedium mb-3">
                                    Save Time on Manual Processes
                                </p>
                                <p className="text-base text-slate-500 font-rocGroteskMedium ">
                                    Cutting-edge AI automation allows you to generate RFQs in
                                    minutes, evaluate quotes using established parameters in
                                    seconds, and turn your SOPs + processes into powerful workflows
                                    with Synth
                                </p>
                            </div>
                        </div>
                        <div className="h-full max-lg:h-[435px] relative w-full hidden max-lg:flex flex-col items-center justify-end rounded-lg shadow-boxShadow-3 bg-white/70 p-5">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702559469/Tradeally_2.0/Landing%20page/mobile_flow-chart_1_qla3x6.png"
                                alt=" background"
                                className="absolute top-0"
                                height={268}
                                width={"100%"}
                            />
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1692975365/Tradeally_2.0/Landing%20page/reduceManualIcon_miy9mm.svg"
                                alt="icon"
                                width={85}
                                height={52}
                            />
                            <div className="text-center">
                                <p className="text-xl font-rocGroteskMedium mb-3">
                                    Minimal Learning Curve
                                </p>
                                <p className="text-base text-slate-500 font-rocGroteskMedium ">
                                    Dive in without delay. Synth is designed to be intuitive,
                                    ensuring that you, your team, and vendors can get started
                                    without any hassle.
                                </p>
                            </div>
                        </div>
                        <div className="h-full max-lg:h-[500px] relative w-full hidden max-lg:flex flex-col items-center justify-end rounded-lg shadow-boxShadow-3 bg-white/70 p-5">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702559469/Tradeally_2.0/Landing%20page/mobile_Map_r5vrcu.png"
                                alt="map background"
                                className="absolute top-0"
                                width={"100%"}
                                // height={286}
                            />
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1692975364/Tradeally_2.0/Landing%20page/crossBorderIcon_ggtlug.svg"
                                alt="icon"
                                width={85}
                                height={52}
                            />
                            <div className="text-center">
                                <p className="text-xl font-rocGroteskMedium mb-3">
                                    Enhanced Procurement Oversight
                                </p>
                                <p className="text-base text-slate-500 font-rocGroteskMedium ">
                                    Reduce procurement fraud and project delays with workflows and
                                    real-time collaboration and transparency by aggregating
                                    conversations with customers, team members, and vendors from
                                    Email and WhatsApp into one channel, Synth.
                                </p>
                            </div>
                        </div>
                        <div className="h-full max-lg:h-[473px] relative w-full hidden max-lg:flex flex-col items-center justify-end rounded-lg shadow-boxShadow-3 bg-white/70 p-5">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702559469/Tradeally_2.0/Landing%20page/mobile_plug_rsgqji.png"
                                alt=" background"
                                className="absolute top-0"
                                // height={286}
                            />
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1692975368/Tradeally_2.0/Landing%20page/seamlessIntergrationIcon_wmq0js.svg"
                                alt="icon"
                                width={85}
                                height={52}
                            />
                            <div className="text-center">
                                <p className="text-xl font-rocGroteskMedium mb-3">
                                    Uninterrupted Stream of Information
                                </p>
                                <p className="text-base text-slate-500 font-rocGroteskMedium ">
                                    With Synth, you can integrate easily with existing systems,
                                    ensuring a seamless flow of information from your core system to
                                    your procurement software.
                                </p>
                            </div>
                        </div>
                        <div className="h-full max-lg:h-[416px] relative w-full hidden max-lg:flex flex-col items-center justify-end rounded-lg shadow-boxShadow-3 bg-white/70 p-5">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702559468/Tradeally_2.0/Landing%20page/mobile_flash_cut_elj6cd.png"
                                alt=" background"
                                className="absolute top-0"
                                height={286}
                            />
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1692975365/Tradeally_2.0/Landing%20page/onboardVendorIcon_sasdcn.svg"
                                alt="icon"
                                width={85}
                                height={52}
                            />
                            <div className="text-center">
                                <p className="text-xl font-rocGroteskMedium mb-3">
                                    Streamlined Vendor Management
                                </p>
                                <p className="text-base text-slate-500 font-rocGroteskMedium ">
                                    Supercharge your vendor relations with accelerated integration —
                                    that your vendors love — enhanced communication, and real-time
                                    performance analysis.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PreFooterCard />
            <LandingFooter />
        </div>
    );
};

export default Home;
