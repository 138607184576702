import React, {useEffect, useState} from "react";
import AuthFrame from "./AuthFrame";
import TextInput from "components/common/InputField/TextInput";
import Button from "components/common/Button";
import {Link, useNavigate} from "react-router-dom";
import {authActions} from "redux/Ldb/actions";
import config from "config/config";
import Checkbox from "components/common/InputField/Checkbox";
import {useAppDispatch, useAppSelector} from "../../../hooks/storeHooks";
import { getFromStorage } from "helpers";

const Login = () => {
    const [loginInfo, setLoginInfo] = useState({
        email: "",
        password: "",
        remember: false,
    });

    const dispatch = useAppDispatch();
    const redirectUrl = getFromStorage("redirect-url");
    const navigate = useNavigate();
    const {userLoggingIn, userLoggedInSuccess, fetchUserProfileRequest, fetchUserProfileSuccess} =
        useAppSelector((state) => state.auth);

    const handleChange = (evt) => {
        const {name, value} = evt.target;

        setLoginInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const body = {
            email: loginInfo.email,
            password: loginInfo.password,
        };

        dispatch(authActions.loginUser(body, loginInfo.remember));
    };

    useEffect(() => {
        if (Boolean(userLoggedInSuccess)) {
            const hasOnboarded =
                Boolean(userLoggedInSuccess?.user?.profile?.businessName) &&
                Boolean(userLoggedInSuccess?.user?.profile?.country) &&
                Boolean(userLoggedInSuccess?.user?.profile?.businessType) &&
                Boolean(userLoggedInSuccess?.user?.fullName) &&
                Boolean(userLoggedInSuccess?.user?.profile?.address);

            if (hasOnboarded) {
                navigate(
                    redirectUrl && redirectUrl !== "/new-onboard" ? redirectUrl : "/dashboard"
                );
            } else if (userLoggedInSuccess?.user?.role?.toLowerCase() !== "owner") {
                navigate(
                    redirectUrl && redirectUrl !== "/new-onboard" ? redirectUrl : "/dashboard"
                );
            } else {
                navigate("/new-onboard");
            }
        }
    }, [userLoggedInSuccess]);

    // useEffect(() => {
    //     if (Boolean(userLoggedInSuccess) && !hasOnboarded) {
    //         navigate("/new-onboard", { replace: true });
    //     }

    //     if (Boolean(userLoggedInSuccess) && hasOnboarded) {
    //         navigate("/dashboard", { replace: true });
    //     }
    // }, [navigate, userLoggedInSuccess, hasOnboarded]);

    const hangleGoogleLogin = () => {
        window.open(`${config.API_URL}/api/v1/ldb/auth/google/login`);
    };

    return (
        <AuthFrame>
            <div className="w-[48%] max-lg:w-[90%]">
                <div className="mb-6">
                    <Link to="/" className="w-fit">
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702538223/Tradeally_2.0/synthAlly_logo_skcywb.svg"
                            alt="trade ally logo"
                            width={157}
                            height={29}
                        />
                    </Link>
                </div>
                <div className="mb-[38px]">
                    <p className="text-[32px] leading-[40px] font-rocGroteskMedium text-p-70 mb-1.5">
                        Log in to your SynthAlly account
                    </p>
                    <p className="text-sm font-rocGroteskRegular text-gm-35">
                        Enter your details to pick up from where you left off.
                    </p>
                </div>
                <form onSubmit={handleSubmit}>
                    <TextInput
                        value={loginInfo.email}
                        name={"email"}
                        type={"email"}
                        placeholder={"Enter work email"}
                        onChange={handleChange}
                        required={true}
                        containerClassname="mb-6"
                    />
                    <TextInput
                        value={loginInfo.password}
                        name={"password"}
                        type={"password"}
                        onChange={handleChange}
                        placeholder={"Enter password"}
                        required={true}
                        containerClassname="mb-6"
                    />
                    <div className="flex items-center justify-between mb-6">
                        <div className="flex items-center gap-2">
                            <Checkbox
                                name="remember"
                                isChecked={loginInfo.remember}
                                onChange={(evt) => {
                                    handleChange({
                                        target: {
                                            name: "remember",
                                            value: evt.target.checked,
                                        },
                                    });
                                }}
                                className={"!h-[14px] !w-[14px] !rounded-[4px]"}
                            />
                            <label
                                htmlFor="remember"
                                className="text-xs text-gm-45 font-rocGroteskRegular mt-[2px]"
                            >
                                Remember password
                            </label>
                        </div>
                        <Link
                            to={"/forgot-password"}
                            className="text-xs underline font-rocGroteskMedium text-b-55"
                        >
                            forgot password?
                        </Link>
                    </div>

                    <div className="w-full mb-6">
                        <Button isLoading={userLoggingIn} btnText={"Log in"} type={"submit"} />
                    </div>
                    <div className="flex items-center gap-[10px] w-full mb-6">
                        <hr className="w-[46%]" /> <span className="text-sm">or</span>{" "}
                        <hr className="w-[46%]" />
                    </div>
                    <div className="w-full mb-6">
                        <Button
                            btnClassname={"!bg-white border"}
                            onClick={hangleGoogleLogin}
                            btnCustomContent={
                                <div className="flex items-center gap-2">
                                    <img
                                        src={
                                            "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1690905410/Web%20App/google_logo_u0iqig.svg"
                                        }
                                        width={24}
                                        height={24}
                                        alt="google logo"
                                    />
                                    <span className="text-sm font-rocGroteskRegular text-dark-gray-2">
                                        Log in with google
                                    </span>
                                </div>
                            }
                            type={"button"}
                        />
                    </div>
                    <p className="text-center">
                        <span className="text-xs text-gm-45 font-rocGroteskRegular">
                            Don't have a SynthAlly account yet?
                        </span>{" "}
                        <Link
                            to={"/register"}
                            className="text-xs text-carribean-green font-rocGroteskBold cursor-pointer"
                        >
                            Get started
                        </Link>
                    </p>
                </form>
            </div>
        </AuthFrame>
    );
};

export default Login;
