/* eslint-disable */
import Button from "components/common/Button";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { authActions, inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import moment from "moment";
import PageFrame from "components/layout/PageFrame";
import SelectInput from "components/common/InputField/SelectInput";
import { debounce } from "lodash";
import TextInput from "components/common/InputField/TextInput";
import DateInput from "components/common/DateInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import CustomTable from "components/common/CustomTable";
import { displayFileIcon, getCurrencyFromCurrencyCode } from "helpers";
import { firebaseService } from "services/firebaseService";
import useGetUserProfile from "hooks/useGetUserProfile";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import Loader from "components/common/Loader";
import ReactCountryFlag from "react-country-flag";
import countries from "variables/countries";
import AddressInput from "components/common/InputField/AddressInput";
import VendorAvatar from "components/common/VendorAvatar";

const EditSuppliedStock = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [profile] = useGetUserProfile();
    const [suppliedStockData, setSuppliedStockData] = useState<{ [key: string]: any }>({});
    const [productData, setProductData] = useState<{ [key: string]: any }>();
    const [dateOfSupply, setDateOfSupply] = useState<Date>();
    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [timelineDelivery, setTimelineDelivery] = useState<any>();
    const [showCalendarTwo, setShowCalendarTwo] = useState<boolean>(false);
    const [attachment, setAttachment] = useState([]);
    const [address, setAddress] = useState<{ [key: string]: any }>({});
    const [uploadingAttachment, setUploadingAttachment] = useState<boolean>(false);
    const [suppliedStock, setSuppliedStock] = useState<{ [key: string]: any }>();

    const {
        fetchingWarehouses,
        fetchedWarehousesSuccess,
        updatingSuppliedStock,
        updatedSuppliedStockSuccess,
        fetchedStockListSuccess,
        fetchedSingleSuppliedStockSuccess,
    } = useAppSelector((state) => state?.inventory);

    const [productList, setProductList] = useState<{ [key: string]: any }>([]);
    const [debounceProductSearch, setDebounceProductSearch] = useState<string>();
    const [debounceWarehouseSearch, setDebounceWarehouseSearch] = useState<string>();
    const [warehouses, setWarehouses] = useState<any>();
    const [teamMembers, setTeamMembers] = useState<{ [key: string]: any }>([]);
    const [searchMember, setSearchMember] = useState<string>();

    const { fetchedTeamMemberSuccess, fetchingTeamMember } = useAppSelector((state) => state.auth);

    useEffect(() => {
        if (Boolean(fetchedSingleSuppliedStockSuccess)) {
            setSuppliedStock(fetchedSingleSuppliedStockSuccess?.supplyStock);
        }
    }, [fetchedSingleSuppliedStockSuccess]);

    useEffect(() => {
        if (id) {
            dispatch(inventoryActions?.fetchSingleSuppliedStock(id));
        }
    }, []);


    useEffect(() => {
        setSuppliedStockData({
            ...suppliedStockData,
            customerDetails: suppliedStock?.customerDetails,
            notes: suppliedStock?.notes,
            procurementManagerId: suppliedStock?.procurementManagerId,
            assigneeId: suppliedStock?.assigneeId,
            orderSource: suppliedStock?.orderSource,
        });
        setAttachment(suppliedStock?.documents);
        setAddress(suppliedStock?.deliveryAddress);
        setDateOfSupply(suppliedStock?.dateOfSupply);
        setTimelineDelivery([
            suppliedStock?.deliveryTimeline?.start,
            suppliedStock?.deliveryTimeline?.end,
        ]);
        setProductData({
            id: suppliedStock?.inventory?._id,
        });
    }, [suppliedStock])
    
    useEffect(() => {
        if (Boolean(fetchedStockListSuccess)) {
            setProductList(fetchedStockListSuccess?.stock);
        }
    }, [fetchedStockListSuccess]);

    useEffect(() => {
        if (Boolean(fetchedWarehousesSuccess)) {
            setWarehouses(fetchedWarehousesSuccess?.warehouses.data);
        }
    }, [fetchedWarehousesSuccess]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamMembers(fetchedTeamMemberSuccess?.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        dispatch(authActions.getTeamMembers("", searchMember));
    }, [dispatch, searchMember]);

    useEffect(() => {
        if (suppliedStockData?.warehouseId) {
            dispatch(
                inventoryActions.fetchStockList(
                    20,
                    debounceProductSearch,
                    suppliedStockData?.warehouseId
                )
            );
        } else {
            setProductList([]);
        }
    }, [dispatch, debounceProductSearch, suppliedStockData?.warehouseId]);

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouses(20, debounceWarehouseSearch));
    }, [dispatch, debounceWarehouseSearch]);

    const handleDateChange = (newDate) => {
        setDateOfSupply(newDate);
    };
    const handleTimelineChange = (newDate) => {
        setTimelineDelivery(newDate);
    };

    useEffect(() => {
        if (productData?.length > 0) {
            const newProductData = productData?.map(({ cost, ...rest }) => rest);
            setSuppliedStockData({
                ...suppliedStockData,
                data: newProductData,
            });
        }
    }, [productData]);

    useEffect(() => {
        if (dateOfSupply) {
            setSuppliedStockData({
                ...suppliedStockData,
                dateOfSupply: new Date(dateOfSupply)?.toISOString(),
            });
        }
    }, [dateOfSupply]);

    useEffect(() => {
        if (attachment?.length > 0) {
            const newArr = attachment?.map(({ _id, ...rest }) => rest);
            setSuppliedStockData({
                ...suppliedStockData,
                documents: newArr,
            });
        }
    }, [attachment]);

    useEffect(() => {
        if (address?.Location?.address)
            setSuppliedStockData({
                ...suppliedStockData,
                deliveryAddress: address?.Location,
            });
    }, [address]);

    const handleSuppliedStockData = (e) => {
        const { name, value } = e.target;
        const customerDetails = [
            "name",
            "email",
            "companyName",
            "country",
            "rawMobile",
            "mobileExtension",
        ];
        setSuppliedStockData((prev: any) => {
            if (customerDetails?.includes(name)) {
                return {
                    ...prev,
                    customerDetails: {
                        ...prev?.customerDetails,
                        [name]: value,
                    },
                };
            } else {
                return { ...prev, [name]: value };
            }
        });
    };


    const handleSaveStock = () => {
        dispatch(inventoryActions?.updateSuppliedStock(id, suppliedStockData));
    };

    useEffect(() => {
        if (updatedSuppliedStockSuccess) {
            navigate("/dashboard/inventory/stock-control?tabId=3");
            dispatch(inventoryActions.resetUpdateSuppliedStock());
        }
    }, [updatedSuppliedStockSuccess]);

    const handleUpload = (fileName: string, file: File) => {
        if (fileName && file) {
            setUploadingAttachment(true);
            firebaseService.uploadFile(
                `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/inventory/${
                    profile?.profile?.businessName
                }/createdByDocument/${fileName}`,
                file,
                (url) => {
                    setAttachment((prev) => [...prev, { url, name: fileName }]);

                    setUploadingAttachment(false);
                }
            );
        }
    };

    const deleteAttachment = (idx) => {
        const newArr = [...attachment];
        const imagePath = decodeURIComponent(
            newArr[idx]?.url?.split("/o/")?.[1]?.split("?alt")?.[0]
        );

        firebaseService.removeFile(imagePath, () => {
            newArr?.splice(idx, 1);
            setAttachment(newArr);
        });
    };

    const tableHeader = [
        { title: "Product name", widthClass: "w-[54%] !font-rocGroteskMedium" },
        { title: "Qty", widthClass: "w-[9%] !font-rocGroteskMedium" },
        { title: "Unit cost", widthClass: "w-[13%] !font-rocGroteskMedium" },
        { title: "Total cost (USD)", widthClass: "w-[12%] !font-rocGroteskMedium" },
    ];
    const tableBody = [
        [
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gun-metal font-rocGroteskMedium flex gap-2 p-4`}
                    >
                        <VendorAvatar
                            size={32}
                            imageSrc={suppliedStock?.inventory?.productImageDetails?.productAvatar}
                            name={suppliedStock?.inventory?.productName}
                        />
                        <div className="text-sm">
                            <p className="text-gun-metal">
                                {suppliedStock?.inventory?.productName}
                            </p>

                            <p className="text-slate-500">{suppliedStock?.batchId}</p>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gun-metal py-4 px-4 font-rocGroteskMedium`}
                    >
                        <p className="">{suppliedStock?.quantity}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gun-metal py-4 px-4 font-rocGroteskMedium`}
                    >
                        <p className="">{`${getCurrencyFromCurrencyCode(
                            suppliedStock?.inventory?.unitCost?.currency
                        )} ${suppliedStock?.inventory?.unitCost?.amount} `}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gun-metal py-4 px-4 font-rocGroteskMedium`}
                    >
                        <p className="">
                            {`${getCurrencyFromCurrencyCode(
                                suppliedStock?.inventory?.unitCost?.currency
                            )} ${
                                suppliedStock?.quantity * suppliedStock?.inventory?.unitCost?.amount
                            } `}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
        ],
    ];

    return (
        <PageFrame containerClassName={""}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSaveStock();
                }}
            >
                <div className="bg-white rounded-lg border border-slate-100">
                    <div className="flex items-center justify-between px-8 py-5 border-b border-slate-100">
                        <p className="text-base font-rocGroteskMedium">Edit Supplied Stocks</p>
                        <div className="flex items-center gap-3">
                            <Button
                                btnText="Cancel"
                                btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                                onClick={() =>
                                    navigate("/dashboard/inventory/stock-control?tabId=3")
                                }
                                disabled={updatingSuppliedStock}
                            />
                            <Button
                                btnText="Save Changes"
                                type="submit"
                                btnClassname="!px-4 !py-2.5 !w-fit"
                                disabled={updatingSuppliedStock}
                                isLoading={updatingSuppliedStock}
                            />
                        </div>
                    </div>

                    <div className="px-8 pt-[56px] pb-8">
                        <div className="w-full">
                            <div>
                                <div className="">
                                    <div className="flex flex-col gap-4">
                                        <div className="grid grid-cols-2 items-center gap-4 ">
                                            <TextInput
                                                value={
                                                    suppliedStockData?.customerDetails?.companyName
                                                }
                                                name="companyName"
                                                type={"text"}
                                                onChange={handleSuppliedStockData}
                                                placeholder={"Company"}
                                                inputContainerClassname={`${
                                                    suppliedStockData?.customerDetails
                                                        ?.companyName &&
                                                    "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                                }  `}
                                                required={true}
                                            />
                                            <TextInput
                                                value={suppliedStockData?.customerDetails?.name}
                                                name="name"
                                                type={"text"}
                                                onChange={handleSuppliedStockData}
                                                placeholder={"Customer name"}
                                                inputContainerClassname={`${
                                                    suppliedStockData?.customerDetails?.name &&
                                                    "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                                }  `}
                                                required={true}
                                            />

                                            <div className="flex gap-1">
                                                <div className="w-[160px]">
                                                    <SelectInput
                                                        value={
                                                            suppliedStockData?.customerDetails
                                                                ?.mobileExtension
                                                        }
                                                        name="mobileExtension"
                                                        placeholder="Ext"
                                                        handleChange={(name, value) => {
                                                            handleSuppliedStockData({
                                                                target: { name, value },
                                                            });
                                                        }}
                                                        className={`${
                                                            suppliedStockData?.customerDetails
                                                                ?.mobileExtension &&
                                                            "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                                        }`}
                                                        isRequired={true}
                                                        clearValue
                                                        dropdownOptions={countries
                                                            .filter((data) =>
                                                                Boolean(data?.codes[0])
                                                            )
                                                            .sort((p1, p2) =>
                                                                p1.codes[0] > p2.codes[0]
                                                                    ? 1
                                                                    : p1.codes[0] < p2.codes[0]
                                                                    ? -1
                                                                    : 0
                                                            )
                                                            .map((item) => ({
                                                                label: item.codes[0]?.replace(
                                                                    " ",
                                                                    ""
                                                                ),
                                                                value: item.codes[0]?.replace(
                                                                    " ",
                                                                    ""
                                                                ),
                                                                icon: (
                                                                    <ReactCountryFlag
                                                                        countryCode={
                                                                            item?.abbreviation
                                                                        }
                                                                        svg
                                                                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                                        cdnSuffix="svg"
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            borderRadius: "50%",
                                                                            marginTop: "-4px",
                                                                        }}
                                                                    />
                                                                ),
                                                            }))}
                                                    />
                                                </div>

                                                <TextInput
                                                    value={
                                                        suppliedStockData?.customerDetails
                                                            ?.rawMobile
                                                    }
                                                    name={"rawMobile"}
                                                    type={"number"}
                                                    onChange={handleSuppliedStockData}
                                                    placeholder={"Mobile"}
                                                    inputContainerClassname={`${
                                                        suppliedStockData?.customerDetails
                                                            ?.rawMobile &&
                                                        "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                                    }`}
                                                    required
                                                />
                                            </div>
                                            <TextInput
                                                value={suppliedStockData?.customerDetails?.email}
                                                name="email"
                                                type={"text"}
                                                onChange={handleSuppliedStockData}
                                                placeholder={"Email Address"}
                                                inputContainerClassname={`${
                                                    suppliedStockData?.customerDetails?.email &&
                                                    "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                                }  `}
                                                required={true}
                                            />

                                            <SelectInput
                                                value={suppliedStockData?.customerDetails?.country}
                                                name="country"
                                                placeholder="Country"
                                                handleChange={(name, value) => {
                                                    handleSuppliedStockData({
                                                        target: { name, value },
                                                    });
                                                }}
                                                className={`${
                                                    suppliedStockData?.customerDetails?.country &&
                                                    "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                                }`}
                                                isRequired={true}
                                                clearValue
                                                dropdownOptions={countries?.map((item) => ({
                                                    label: item?.name,
                                                    value: item?.name,
                                                    icon: (
                                                        <ReactCountryFlag
                                                            countryCode={item?.abbreviation}
                                                            svg
                                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                            cdnSuffix="svg"
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                                borderRadius: "50%",
                                                                marginTop: "-4px",
                                                            }}
                                                        />
                                                    ),
                                                }))}
                                            />

                                            <DateInput
                                                label={"Supply Date"}
                                                value={dateOfSupply}
                                                onChange={(newDate) => handleDateChange(newDate)}
                                                showCalendar={showCalendar}
                                                onCalendarToggle={setShowCalendar}
                                                calendarClassname="!right-0"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-6 mt-8">
                                    <div>
                                        <label className="text-base font-rocGroteskMedium">
                                            Order details
                                        </label>
                                    </div>
                                    <div className="grid grid-cols-2 items-center gap-4">
                                        <SelectInput
                                            value={suppliedStock?.warehouse?.warehouseFrom?._id}
                                            name="warehouseId"
                                            placeholder={"Warehouse"}
                                            handleChange={(name, value) => {
                                                handleSuppliedStockData({
                                                    target: { name, value },
                                                });
                                            }}
                                            className={`${
                                                suppliedStock?.warehouse?.warehouseFrom?._id &&
                                                "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                            }`}
                                            searchLoading={fetchingWarehouses}
                                            isRequired={true}
                                            handleInputChange={debounce((evt) => {
                                                setDebounceWarehouseSearch(evt.target.value);
                                            }, 800)}
                                            disabled
                                            dropdownOptions={
                                                warehouses
                                                    ? warehouses?.map((item) => ({
                                                          label: item?.warehouseName,
                                                          value: item?._id,
                                                      }))
                                                    : []
                                            }
                                        />
                                        <SelectInput
                                            value={suppliedStockData?.procurementManagerId}
                                            name="procurementManagerId"
                                            placeholder={"Procurement Manager"}
                                            handleChange={(name, value) => {
                                                handleSuppliedStockData({
                                                    target: { name, value },
                                                });
                                            }}
                                            className={`${
                                                suppliedStockData?.procurementManagerId &&
                                                "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                            }`}
                                            searchLoading={fetchingTeamMember}
                                            isRequired={true}
                                            handleInputChange={debounce((evt) => {
                                                setDebounceWarehouseSearch(evt.target.value);
                                            }, 800)}
                                            dropdownOptions={
                                                teamMembers
                                                    ? teamMembers?.map((item) => ({
                                                          label: item?.fullName || item?.email,
                                                          value: item?._id,
                                                          icon: (
                                                              <VendorAvatar
                                                                  size="24"
                                                                  containerClassname="rounded-full"
                                                                  name={
                                                                      item?.fullName || item?.email
                                                                  }
                                                                  imageSrc={item?.avatar}
                                                              />
                                                          ),
                                                      }))
                                                    : []
                                            }
                                        />

                                        <DateInput
                                            label={"Delivery timeline"}
                                            value={timelineDelivery}
                                            onChange={(newDate) => handleTimelineChange(newDate)}
                                            showCalendar={showCalendarTwo}
                                            onCalendarToggle={setShowCalendarTwo}
                                            calendarClassname="!left-0"
                                            doubleValue={true}
                                        />
                                        <AddressInput
                                            setData={setAddress}
                                            savedAddress={address?.address || ""}
                                            placeholder="Delivery Address"
                                            required={true}
                                            name={"Location"}
                                        />
                                        <SelectInput
                                            value={suppliedStockData?.assigneeId}
                                            name="assigneeId"
                                            placeholder={"Assigned To"}
                                            handleChange={(name, value) => {
                                                handleSuppliedStockData({
                                                    target: { name, value },
                                                });
                                            }}
                                            className={`${
                                                suppliedStockData?.assigneeId &&
                                                "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                            }`}
                                            searchLoading={fetchingTeamMember}
                                            isRequired={true}
                                            // handleInputChange={debounce((evt) => {
                                            //     setDebounceWarehouseSearch(evt.target.value);
                                            // }, 800)}
                                            dropdownOptions={
                                                teamMembers
                                                    ? teamMembers?.map((item) => ({
                                                          label: item?.fullName || item?.email,
                                                          value: item?._id,
                                                          icon: (
                                                              <VendorAvatar
                                                                  size="24"
                                                                  containerClassname="rounded-full"
                                                                  name={
                                                                      item?.fullName || item?.email
                                                                  }
                                                                  imageSrc={item?.avatar}
                                                              />
                                                          ),
                                                      }))
                                                    : []
                                            }
                                        />
                                        <TextInput
                                            value={suppliedStockData?.orderSource}
                                            name={"orderSource"}
                                            type={"text"}
                                            onChange={handleSuppliedStockData}
                                            placeholder={"Order Source"}
                                            inputContainerClassname={`${
                                                suppliedStockData?.orderSource &&
                                                "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                            }`}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="mb-6 mt-12">
                                    <label className="text-base font-rocGroteskMedium">
                                        Products
                                    </label>
                                    <div className="mt-4">
                                        <CustomTable
                                            tableBody={tableBody}
                                            tableHeader={tableHeader}
                                            headerContainerClass=""
                                            isScrollable={false}
                                            isCellBordered={true}
                                        />
                                    </div>
                                </div>
                                <div className="mt-8">
                                    <label className="text-base font-rocGroteskMedium">
                                        Attachments
                                    </label>
                                    <input
                                        type="file"
                                        className="hidden"
                                        id="attachment"
                                        accept="image/*, .pdf, .xlsx"
                                        disabled={uploadingAttachment}
                                        onChange={(e) => {
                                            if (e.target.files?.[0]) {
                                                handleUpload(
                                                    e.target.files?.[0]?.name as string,
                                                    e.target.files?.[0]?.size as any
                                                );
                                            }
                                        }}
                                    />
                                    <div className="mt-4 ">
                                        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                                            {attachment?.map((item, idx) => {
                                                return (
                                                    <div className="flex items-center justify-between p-3 rounded-md border border-slate-200 mb-4">
                                                        <div className="flex items-center gap-2">
                                                            {displayFileIcon(
                                                                getFileTypeFromUrl(item?.url),
                                                                false
                                                            )}
                                                            <div className="font-rocGroteskMedium">
                                                                <p className="text-sm">
                                                                    {item?.name}
                                                                </p>
                                                                <p className="text-xs text-slate-500"></p>
                                                            </div>
                                                        </div>
                                                        <i
                                                            className="ri-delete-bin-line text-xl text-r-50 cursor-pointer"
                                                            onClick={() => deleteAttachment(idx)}
                                                        ></i>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <label
                                            className={`flex items-center gap-4 py-3 px-6 rounded border border-dashed border-slate-200  ${
                                                !uploadingAttachment && "cursor-pointer"
                                            }`}
                                            htmlFor="attachment"
                                        >
                                            <i className="ri-file-add-line text-2xl text-carribean-green"></i>
                                            {uploadingAttachment ? (
                                                <div className="">
                                                    <Loader size={4} />
                                                </div>
                                            ) : (
                                                <p className="text-sm underline text-carribean-green font-rocGroteskMedium">
                                                    Click to upload document
                                                </p>
                                            )}
                                        </label>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-4 mt-8">
                                    <div className="mt-4">
                                        <label className="text-base font-rocGroteskMedium">
                                            Notes
                                        </label>
                                        <div className="mt-4">
                                            <TextareaInput
                                                name={"notes"}
                                                value={suppliedStockData?.notes || ""}
                                                onChange={handleSuppliedStockData}
                                                rows={5}
                                                placeholder={"Add note"}
                                                required={false}
                                                inputContainerClassname={`${
                                                    suppliedStockData?.notes &&
                                                    "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                                }`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </PageFrame>
    );
};

export default EditSuppliedStock;
