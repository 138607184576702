import React, { useEffect, useState } from "react";
import { authService } from "services/Ldb";
import { getFromStorage, removeFromStorage, saveToStorage } from "helpers";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { authActions, settingsActions } from "redux/Ldb/actions";
import Button from "../Button";
import ModalContainer from "../ModalContainer";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { io } from "socket.io-client";
import config from "config/config";
import { calculateDaysUntilToday } from "helpers/calculateInDays";

const socket = io(`${config.API_URL}`);

interface PrivateRoutesProps {
    children: React.ReactNode;
}

const PrivateRoutes: React.FC<PrivateRoutesProps> = ({ children }) => {
    const [featureLimitModal, setFeatureLimitModal] = useState<boolean>(false);
    const [isPlanExpired, setIsPlanExpired] = useState<boolean>(false);
    const [isShowOpacity, setIsShowOpacity] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isShowOpacityTwo, setIsShowOpacityTwo] = useState<boolean>(false);
    const [featureLimitMessage, setFeatureLimitMessage] = useState("");
    const [subscriptionPlan, setSubscriptionPlan] = useState<{ [key: string]: any }>({});
    const location = useLocation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const showUpgrade = param.get("showUpgrade");
    const data = [
        "Manage up to 100 active vendors",
        "Create unlimited AI text prompts",
        "Add unlimited team members",
        "Manage your team and create access levels",
        "Up to 10,000 AI-assisted quote evaluations",
        "500 AI-generated RFQs",
        "Dedicated onboarding & account manager",
        "Enable integration with standard and custom ERPs & 3rd-party systems",
        "And many more...",
    ];

    const {
        fetchCurrentSubscriptionSuccess,
        fetchingCurrentSubscription,
        fetchCurrentSubscriptionFailure,
    } = useAppSelector((state) => state.settings);
    const [profile, setProfile] = useState(() => getFromStorage("ally-user"));
    const redirectUrl = getFromStorage("redirect-url");

    useEffect(() => {
        if (!Boolean(fetchCurrentSubscriptionSuccess)) {
            dispatch(settingsActions.fetchCurrentSubscriptionPlan());
        }
    }, [dispatch, fetchCurrentSubscriptionSuccess]);

    useEffect(() => {
        if (Boolean(fetchCurrentSubscriptionSuccess)) {
            setSubscriptionPlan(fetchCurrentSubscriptionSuccess?.subscription);
            setLoading(true);
        }
    }, [fetchCurrentSubscriptionSuccess]);

    useEffect(() => {
        if (fetchCurrentSubscriptionFailure !== null) {
            setLoading(true);
        }
    }, [fetchCurrentSubscriptionFailure]);

    const handleStorageChange = () => {
        setProfile(getFromStorage("ally-user"));
    };

    useEffect(() => {
        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    useEffect(() => {
        const handleErrorOccurred = () => {
            setFeatureLimitModal(true);
        };
        document.addEventListener("errorOccurred", handleErrorOccurred);

        return () => {
            document.removeEventListener("errorOccurred", handleErrorOccurred);
        };
    }, []);

    const expiryDays = calculateDaysUntilToday(subscriptionPlan.expiryDate);

    useEffect(() => {
        if (expiryDays <= 0) {
            setIsPlanExpired(true);
        } else {
            setIsPlanExpired(false);
        }
    }, [expiryDays]);

    useEffect(() => {
        if (loading) {
            if (isPlanExpired && showUpgrade && !fetchingCurrentSubscription) {
                setIsShowOpacity(false);
            } else if (isPlanExpired && !showUpgrade && !fetchingCurrentSubscription) {
                setIsShowOpacity(true);
            } else {
                setIsShowOpacity(false);
            }
        }
    }, [isPlanExpired, showUpgrade, fetchingCurrentSubscription, loading]);

    useEffect(() => {
        if (loading) {
            if (fetchCurrentSubscriptionFailure && showUpgrade && !fetchingCurrentSubscription) {
                setIsShowOpacityTwo(false);
            } else if (
                fetchCurrentSubscriptionFailure &&
                !showUpgrade &&
                !fetchingCurrentSubscription
            ) {
                setIsShowOpacityTwo(true);
            } else {
                setIsShowOpacityTwo(false);
            }
        }
    }, [fetchCurrentSubscriptionFailure, showUpgrade, fetchingCurrentSubscription, loading]);

    useEffect(() => {
        socket.on("SUBCRIPTION_LIMIT_EXCEEDED", (data) => {
            if (data?.ldbId === profile?.user?._id) {
                setFeatureLimitMessage(data?.message);
                setFeatureLimitModal(true);
            }
        });

        // Remove event listener on component unmount
        return () => {
            socket.off("SUBCRIPTION_LIMIT_EXCEEDED");
        };
    }, [profile?.user?._id]);

    if (redirectUrl && location.pathname !== `/new-onboard`) {
        removeFromStorage("redirect-url");
    }

    if (!authService.loggedIn()) {
        if (!authService.loggedIn() && Boolean(profile?.user)) {
            dispatch(authActions.logOut());
        }

        saveToStorage("redirect-url", location.pathname + location?.search);
        window.open("/login", "_self");
        return;
    }

    if (
        authService.loggedIn() &&
        !profile?.user?.active &&
        profile?.token &&
        // !profile?.verifyComplete &&
        location.pathname !== `/registration-success/${profile?.token}`
    ) {
        return <Navigate to={`/registration-success/${profile?.token}?resend=true`} replace />;
    }
    return (
        <>
            {children}

            <ModalContainer
                open={featureLimitModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setFeatureLimitModal(false);
                    setFeatureLimitMessage("");
                }}
            >
                <div className="bg-white shadow-faintShadow !px-2 p-8  rounded-[12px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1701946138/Tradeally_2.0/icons/feature_limit_icon_i3m7bb.svg"
                                alt="feature limit icon"
                                className="w-[76px] h-[76px]"
                            />
                            <div>
                                <h3 className="text-2xl text-black mb-2 font-rocGroteskMedium">
                                    Feature Limit Exceeded, <br /> Upgrade Now for More Benefits
                                </h3>
                                <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                    {featureLimitMessage
                                        ? featureLimitMessage
                                        : `It looks like you've reached the limit for this feature in your
                                    subscription. upgrade your account and unlock additional
                                    features and improve their experience.`}
                                </p>
                            </div>
                        </div>
                        <div className="my-5">
                            <div className="flex flex-col gap-3 ">
                                {data.map((description, index) => (
                                    <div
                                        key={description + String(index)}
                                        className="flex gap-2 items-center"
                                    >
                                        <i className="ri-check-fill text-g-50"></i>
                                        <span className="text-gm-50 text-[13px] font-rocGroteskMedium">
                                            {description}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mt-5 flex space-x-3">
                            <div className="w-full whitespace-nowrap">
                                <Button
                                    btnText={"Not now"}
                                    type={"button"}
                                    btnClassname={"!bg-n-20 !text-gm-50"}
                                    isLoading={false}
                                    onClick={() => {
                                        setFeatureLimitModal(false);
                                        setFeatureLimitMessage("");
                                    }}
                                />
                            </div>
                            <div className="w-full whitespace-nowrap">
                                <Button
                                    onClick={() => {
                                        setFeatureLimitModal(false);
                                        setFeatureLimitMessage("");
                                        navigate("/dashboard/settings/2");
                                    }}
                                    btnText={"Upgrade"}
                                    type={"button"}
                                    isLoading={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            {loading && (
                <ModalContainer
                    isPlanExpired={isShowOpacity}
                    open={isShowOpacity}
                    showCloseIcon={false}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[500px]"
                >
                    <div className="bg-white shadow-faintShadow !px-2 p-8  rounded-[12px]">
                        <div className="mt-4 px-6">
                            <div className="text-center flex justify-center flex-col items-center space-y-5">
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1701946138/Tradeally_2.0/icons/feature_limit_icon_i3m7bb.svg"
                                    alt="feature limit icon"
                                    className="w-[76px] h-[76px]"
                                />
                                <div>
                                    <h3 className="text-2xl text-black mb-2 font-rocGroteskMedium">
                                        Your {subscriptionPlan?.trial ? "free trial" : "plan"} has
                                        expired
                                    </h3>
                                    <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                        Your{" "}
                                        {subscriptionPlan?.trial ? "14-Day Free Trial" : "plan"} has
                                        now expired. We hope you have found Synth useful so far.
                                        Purchase a subscription plan to continue orchestrating your
                                        supply chain seamlessly.
                                    </p>
                                </div>
                            </div>

                            <div className="mt-5 ">
                                <div className="w-full whitespace-nowrap">
                                    <Button
                                        btnText={"Buy a subscription plan"}
                                        type={"button"}
                                        isLoading={false}
                                        onClick={() => {
                                            navigate(
                                                "/dashboard/settings/2?showUpgrade=true&planInterval=year"
                                            );
                                        }}
                                    />
                                </div>
                                <div className="w-full whitespace-nowrap">
                                    <Button
                                        btnClassname="!bg-transparent !underline"
                                        onClick={() => {
                                            dispatch(authActions.logOut());
                                        }}
                                        btnText={"Log out"}
                                        type={"button"}
                                        isLoading={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}

            {loading && (
                <ModalContainer
                    isPlanExpired={isShowOpacityTwo}
                    open={isShowOpacityTwo}
                    showCloseIcon={false}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[500px]"
                >
                    <div className="bg-white shadow-faintShadow !px-2 p-8  rounded-[12px]">
                        <div className="mt-4 px-6">
                            <div className="text-center flex justify-center flex-col items-center space-y-5">
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1701946138/Tradeally_2.0/icons/feature_limit_icon_i3m7bb.svg"
                                    alt="feature limit icon"
                                    className="w-[76px] h-[76px]"
                                />
                                <div>
                                    <h3 className="text-2xl text-black mb-2 font-rocGroteskMedium">
                                        Your plan has expired
                                    </h3>
                                    <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                        Your plan has now expired. We hope you have found Synth
                                        useful so far. Purchase a subscription plan to continue
                                        orchestrating your supply chain seamlessly.
                                    </p>
                                </div>
                            </div>

                            <div className="mt-5 ">
                                <div className="w-full whitespace-nowrap">
                                    <Button
                                        btnText={"Buy a subscription plan"}
                                        type={"button"}
                                        isLoading={false}
                                        onClick={() => {
                                            navigate(
                                                "/dashboard/settings/2?showUpgrade=true&planInterval=year"
                                            );
                                        }}
                                    />
                                </div>
                                <div className="w-full whitespace-nowrap">
                                    <Button
                                        btnClassname="!bg-transparent !underline"
                                        onClick={() => {
                                            dispatch(authActions.logOut());
                                        }}
                                        btnText={"Log out"}
                                        type={"button"}
                                        isLoading={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </>
    );
};

export default PrivateRoutes;
