import Button from "components/common/Button";
import CustomToast from "components/common/CustomToast";
import Dropdown from "components/common/Dropdown";
import AddressInput from "components/common/InputField/AddressInput";
import Checkbox from "components/common/InputField/Checkbox";
import MultiselectInput from "components/common/InputField/MultiselectInput";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import ModalContainer from "components/common/ModalContainer";
import TabsContainer from "components/common/TabsContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { getFromStorage } from "helpers";
import { getEditPermissionList } from "helpers/getEditPermissionList";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { authActions, inventoryActions } from "redux/Ldb/actions";
import { accessTypes } from "variables";

const Settings = () => {
    const limit = 20;
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [warehouseLimit, setWarehouseLimit] = useState(limit);
    const [activeTab, setActiveTab] = useState<number>(0);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
    const [inviteMember, setInviteMember] = useState<boolean>(false);
    const [addCustomRole, setAddCustomRole] = useState<boolean>(false);
    const [fromInvite, setFromInvite] = useState<boolean>(false);
    const [addWarehouse, setAddWarehouse] = useState<boolean>(false);
    const [showRolesAndResponsibiltyModal, setShowRolesAndResponsibiltyModal] =
        useState<boolean>(false);
    const [warehouseSearch, setWarehouseSearch] = useState<string>("");
    const [warehouseId, setWarehouseId] = useState<string>("");
    const [role, setRole] = useState<string>("");
    const [settingsId, setSettingsId] = useState<string>("");
    const [warehouseList, setWarehouseList] = useState([]);
    const [roles, setRoles] = useState([]);
    const [address, setAddress] = useState<{ [key: string]: any }>({});

    const [teamMembers, setTeamMembers] = useState<{ [key: string]: any }[]>([]);
    const [debouncedSearch, setDebouncedSearch] = useState({
        search: "",
    });
    const [debouncedTeamSearch, setDebouncedTeamSearch] = useState({
        search: "",
    });
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
    const [addWarehouseData, setAddWarehouseData] = useState<{
        contactPerson: string;
        note: string;
        name: string;
    }>({
        contactPerson: "",
        note: "",
        name: "",
    });
    const containerRef = useRef<HTMLDivElement>(null);
    const [teamInfo, setTeamInfo] = useState<{ [key: string]: any }[]>([
        { "email-0": "", "role-0": "" },
    ]);
    const [expiryAlerts, setExpiryAlerts] = useState<any>([
        {
            whenStock: "",
            recipientsIds: [],
            alertPeriod: {
                quantity: "",
                unit: "",
            },
        },
    ]);
    const [stockAlerts, setStockAlerts] = useState<any>([
        {
            alertLevel: "",
            recipientsIds2: [],
            alertPeriod: {
                quantity: "",
                unit: "minutes",
            },
        },
    ]);
    const [settingsData, setSettingsData] = useState<any>({
        allowedUserRoles: [],
        resendNotifications: {
            alertPeriod: {
                unit: "minutes",
                quantity: "",
            },
            resendNotification: false,
        },
        notificationMethod: {
            email: false,
            inApp: false,
        },
        stockAdjustment: false,
        stockTransfer: false,
        warehouseId: "",
    });

    const {
        addedInventorySettingsSuccess,
        fetchingWarehouseList,
        updatedInventorySettingsSuccess,
        updatingInventorySettings,
        fetchedWarehouseListSuccess,
        fetchedInventorySettingsSuccess,
        fetchedInventorySettingsFailure,
        fetchingInventorySettings,
        createWarehouse,
        createWarehouseSuccess,
        addingInventorySettings,
    } = useAppSelector((state) => state.inventory);
    const [userProfile, setUserProfile] = useState(() => getFromStorage("ally-user"));

    const {
        fetchingRoles,
        fetchedRolesSuccess,
        addingTeamMember,
        addTeamMemberSuccess,
        createRoles,
        createRolesSuccess,
        fetchingTeamMember,
        fetchedTeamMemberSuccess,
    } = useAppSelector((state) => state.auth);

    const navigate = useNavigate();
    const tabs = ["Product stocks level alert", "Product Expiry date alert"];

    useEffect(() => {
        dispatch(authActions.getRoles(20, userProfile?.token, debouncedSearch?.search));
    }, [dispatch, debouncedSearch?.search]);

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouseList(warehouseLimit, warehouseSearch));
    }, [warehouseLimit, warehouseSearch]);

    useEffect(() => {
        dispatch(authActions.getTeamMembers(20, debouncedTeamSearch.search));
    }, [dispatch, debouncedSearch]);

    useEffect(() => {
        dispatch(inventoryActions.fetchInventorySettings(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (Boolean(createRolesSuccess)) {
            setSelectedPermissions([]);
            setAddCustomRole(false);
            if (fromInvite) {
                setInviteMember(true);
            }
            dispatch(authActions.resetCreateRoles());
            dispatch(authActions.getRoles(20, userProfile?.token));
        }
    }, [createRolesSuccess, dispatch]);

    useEffect(() => {
        if (Boolean(fetchedWarehouseListSuccess)) {
            setWarehouseList(fetchedWarehouseListSuccess?.warehouses?.data);
        }
    }, [fetchedWarehouseListSuccess]);

    useEffect(() => {
        if (Boolean(addTeamMemberSuccess)) {
            dispatch(authActions.getTeamMembers(10));
            setInviteMember(false);
            dispatch(authActions.resetAddTeamMemberSuccess());
            setTeamInfo([{ "email-0": "", "role-0": "" }]);
        }
    }, [dispatch, addTeamMemberSuccess]);

    useEffect(() => {
        if (Boolean(fetchedRolesSuccess)) {
            setRoles(fetchedRolesSuccess?.rolesList);
        }
    }, [fetchedRolesSuccess]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamMembers(fetchedTeamMemberSuccess?.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        if (Boolean(fetchedInventorySettingsSuccess)) {
            if (fetchedInventorySettingsSuccess?.stockSetting) {
                const {
                    _id,
                    stockExpiryAlerts,
                    stockLevelAlerts,
                    resendNotifications,
                    notificationMethod,
                    allowedUserRoles,
                    stockAdjustment,
                    stockTransfer,
                    warehouseId,
                } = fetchedInventorySettingsSuccess?.stockSetting;
                setSettingsId(_id);
                setExpiryAlerts(
                    stockExpiryAlerts?.map((expiryAlert) => ({
                        whenStock: expiryAlert.whenStock,
                        recipientsIds: [...expiryAlert.recipientsIds],
                        alertPeriod: {
                            quantity: expiryAlert.alertPeriod?.quantity,
                            unit: expiryAlert.alertPeriod?.unit,
                        },
                    }))
                );
                setStockAlerts(
                    stockLevelAlerts?.map((alert) => ({
                        alertLevel: alert?.alertLevel,
                        recipientsIds: [...alert.recipientsIds],
                        alertPeriod: {
                            quantity: alert.alertPeriod?.quantity,
                            unit: alert.alertPeriod?.unit,
                        },
                    }))
                );
                setSettingsData({
                    ...settingsData,
                    resendNotifications: {
                        alertPeriod: resendNotifications?.alertPeriod,
                        resendNotification: resendNotifications?.resendNotification,
                    },
                    notificationMethod: notificationMethod,
                    allowedUserRoles: allowedUserRoles,
                    stockAdjustment: stockAdjustment,
                    stockTransfer: stockTransfer,
                    warehouseId: warehouseId,
                });
                setIsPageLoaded(true);
            }
        }
    }, [fetchedInventorySettingsSuccess, fetchedInventorySettingsSuccess?.stockSetting]);

    useEffect(() => {
        if (fetchedInventorySettingsFailure === "Stock Setting not found") {
            setSettingsData({
                allowedUserRoles: [],
                resendNotifications: {
                    alertPeriod: {
                        unit: "minutes",
                        quantity: "",
                    },
                    resendNotification: false,
                },
                notificationMethod: {
                    email: false,
                    inApp: false,
                },
                stockAdjustment: false,
                stockTransfer: false,
            });
            setStockAlerts([
                {
                    alertLevel: "",
                    recipientsIds: [],
                    alertPeriod: {
                        quantity: "",
                        unit: "minutes",
                    },
                },
            ]);
            setExpiryAlerts([
                {
                    whenStock: "",
                    recipientsIds: [],
                    alertPeriod: {
                        quantity: "",
                        unit: "minutes",
                    },
                },
            ]);
            setIsPageLoaded(true);
        }
    }, [fetchedInventorySettingsFailure]);

    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e.target.value);
    }, 1500);

    const handleChange = (name: string, value: any) => {
        setSettingsData((prev) => {
            if (name === "resendNotification") {
                return {
                    ...prev,
                    resendNotifications: {
                        ...prev.resendNotifications,
                        [name]: value,
                    },
                };
            }
            if (name === "unit") {
                return {
                    ...prev,
                    resendNotifications: {
                        ...prev.resendNotifications,
                        alertPeriod: {
                            ...prev.resendNotifications.alertPeriod,
                            unit: value,
                        },
                    },
                };
            }
            if (name === "quantity") {
                return {
                    ...prev,
                    resendNotifications: {
                        ...prev.resendNotifications,
                        alertPeriod: {
                            ...prev.resendNotifications.alertPeriod,
                            quantity: Number(value),
                        },
                    },
                };
            }
            if (name === "inApp" || name === "email") {
                return {
                    ...prev,
                    notificationMethod: {
                        ...prev.notificationMethod,
                        [name]: value,
                    },
                };
            }
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleInviteChange = (
        e: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } },
        index: number
    ) => {
        const { name, value } = e.target;
        const updatedTeamInfo = [...teamInfo];
        updatedTeamInfo[index] = {
            ...updatedTeamInfo[index],
            [name]: value,
        };
        setTeamInfo(updatedTeamInfo);
    };

    const handleAddRole = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const permission = selectedPermissions;
        if (role === "") {
            toast.custom((t) => (
                <CustomToast t={t} message={"Role name is required"} type="error" />
            ));
        }
        if (role !== "") {
            const teamMember = {
                role,
                ...(permission.length > 0 && { permission }),
            };
            dispatch(authActions.createRoles(teamMember, userProfile?.token));
        }
    };

    const onSaveSettingsChanges = (e: any) => {
        e.preventDefault();
        const body = {
            stockLevelAlerts: stockAlerts,
            warehouseId: settingsData?.warehouseId ?? id,
            resendNotifications: settingsData?.resendNotifications,
            notificationMethod: settingsData?.notificationMethod,
            allowedUserRoles: settingsData?.allowedUserRoles,
            stockAdjustment: settingsData?.stockAdjustment,
            stockExpiryAlerts: expiryAlerts,
            stockTransfer: settingsData?.stockTransfer,
        };
        if (settingsId !== "") {
            dispatch(inventoryActions.updateInventorySettings(body, settingsId));
        } else {
            dispatch(inventoryActions.addInventorySettings(body));
        }
    };

    useEffect(() => {
        if (Boolean(addedInventorySettingsSuccess)) {
            dispatch(inventoryActions.fetchInventorySettings(id));
        }
    }, [addedInventorySettingsSuccess, dispatch, id]);

    useEffect(() => {
        if (Boolean(updatedInventorySettingsSuccess)) {
            dispatch(inventoryActions.fetchInventorySettings(id));
        }
    }, [updatedInventorySettingsSuccess, dispatch, id]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: { [key: string]: any }[] = [];
        const filteredTeamInfo = teamInfo.filter(
            (member, index) => member[`email-${index}`] && member[`role-${index}`]
        );

        filteredTeamInfo.map((member, index) => {
            const teamMember = {
                email: member[`email-${index}`],
                role: member[`role-${index}`],
            };

            body.push(teamMember);
        });

        const teamsBody = { teamMembers: body };

        dispatch(authActions.addTeamMember(teamsBody, userProfile?.token));
    };

    const onDelete = (index: number) => {
        const updatedTeamInfo = [...teamInfo];
        updatedTeamInfo.splice(index, 1);
        setTeamInfo(updatedTeamInfo);
    };

    const handlePermissionChange = (permission: string) => {
        const isSelected = selectedPermissions.includes(permission);

        if (isSelected) {
            const updatedPermissions = selectedPermissions.filter((item) => item !== permission);
            setSelectedPermissions(updatedPermissions);
        } else {
            setSelectedPermissions([...selectedPermissions, permission]);
        }
    };

    const addNewInputField = () => {
        const newInputField = {
            email: "",
            role: "",
            permissions: "",
        };
        setTeamInfo([...teamInfo, newInputField]);
    };
    const addStockAlert = (alertData) => {
        setStockAlerts([...stockAlerts, alertData]);
    };

    const handleAddStockAlert = () => {
        const newStockAlert = {
            alertLevel: "",
            recipientsIds: [],
            alertPeriod: {
                unit: "minutes",
                quantity: "",
            },
        };
        addStockAlert(newStockAlert);
    };

    const handleStockAlertChange = (index, field, value) => {
        setStockAlerts((prevStockAlerts) => {
            const updatedStockAlerts = prevStockAlerts.map((stockAlert, i) => {
                if (i === index) {
                    if (field === "alertLevel") {
                        if (value === "abovetheminimumlevel") {
                            return {
                                ...stockAlert,
                                alertLevel: "above the minimum level",
                            };
                        } else if (value === "below the minimum level") {
                            return {
                                ...stockAlert,
                                alertLevel: "below the minimum level",
                            };
                        } else if (value === "above the maximum level") {
                            return {
                                ...stockAlert,
                                alertLevel: "above the maximum level",
                            };
                        } else if (value === "below the maximum level") {
                            return {
                                ...stockAlert,
                                alertLevel: "below the maximum level",
                            };
                        }
                    }

                    if (field === "unit") {
                        return {
                            ...stockAlert,
                            alertPeriod: {
                                ...stockAlert.alertPeriod,
                                unit: value,
                            },
                        };
                    }
                    if (field === "quantity") {
                        return {
                            ...stockAlert,
                            alertPeriod: {
                                ...stockAlert.alertPeriod,
                                quantity: Number(value),
                            },
                        };
                    }
                    return {
                        ...stockAlert,
                        [field]: value,
                    };
                }
                return stockAlert;
            });

            return updatedStockAlerts;
        });
    };

    const handleExpiryAlertChange = (index, field, selectedItems) => {
        setExpiryAlerts((prevExpiryAlerts) => {
            const updatedExpiryAlerts = prevExpiryAlerts.map((expiryAlert, i) => {
                if (i === index) {
                    if (field === "whenStock") {
                        if (selectedItems === "isclosetoexpirydate") {
                            return {
                                ...expiryAlert,
                                whenStock: "is close to expiry date",
                            };
                        }
                    }
                    if (field === "unit") {
                        return {
                            ...expiryAlert,
                            alertPeriod: {
                                ...expiryAlert.alertPeriod,
                                unit: selectedItems,
                            },
                        };
                    }
                    if (field === "quantity") {
                        return {
                            ...expiryAlert,
                            alertPeriod: {
                                ...expiryAlert.alertPeriod,
                                quantity: Number(selectedItems),
                            },
                        };
                    }
                    return {
                        ...expiryAlert,
                        [field]: selectedItems,
                    };
                }

                return expiryAlert;
            });

            return updatedExpiryAlerts;
        });
    };

    const deleteStockAlert = (index) => {
        const updatedStockAlerts = [...stockAlerts];
        updatedStockAlerts.splice(index, 1);
        setStockAlerts(updatedStockAlerts);
    };
    const deleteExpiryAlert = (index) => {
        const updatedExpiryAlerts = [...expiryAlerts];
        updatedExpiryAlerts.splice(index, 1);
        setExpiryAlerts(updatedExpiryAlerts);
    };

    const addExpiryAlert = (alertData) => {
        setExpiryAlerts([...expiryAlerts, alertData]);
    };

    const handleAddExpiryAlert = () => {
        const newStockAlert = {
            whenStock: "",
            recipientsIds: [],
            alertPeriod: {
                unit: "minutes",
                quantity: "",
            },
        };

        addExpiryAlert(newStockAlert);
    };
    const renderStockAlertLevel = (index) => {
        return (
            <div className="flex items-center w-full group">
                <div className="flex mb-4 items-center justify-between gap-3 w-full ">
                    <div className="w-full">
                        <p className="text-sm font-rocGroteskMedium text-gm-50 mb-2">
                            When stock level is
                        </p>
                        <SelectInput
                            value={stockAlerts[index].alertLevel?.replace(/\s/g, "")}
                            name={`stockAlert_${index}_alertLevel`}
                            placeholder={"Select stock level"}
                            handleChange={(_, value) =>
                                handleStockAlertChange(index, "alertLevel", value)
                            }
                            handleInputChange={() => {}}
                            isRequired={true}
                            searchLoading={false}
                            dropdownOptions={[
                                { label: "above the minimum level", value: "abovetheminimumlevel" },
                                { label: "below the minimum level", value: "belowtheminimumlevel" },
                                { label: "above the maximum level", value: "abovethemaximumlevel" },
                                { label: "below the maximum level", value: "belowmaximum" },
                            ]}
                        />
                    </div>
                    <div className="w-full">
                        <p className="text-sm font-rocGroteskMedium text-gm-50 mb-2">
                            Notify team members
                        </p>
                        <MultiselectInput
                            value={stockAlerts[index].recipientsIds}
                            name={`stockAlert_${index}_recipientsIds`}
                            placeholder={"Team Members"}
                            handleChange={(name, value, selectedItems) =>
                                handleStockAlertChange(index, "recipientsIds", selectedItems)
                            }
                            searchLoading={fetchingTeamMember}
                            handleInputChange={(e) =>
                                setDebouncedTeamSearch({
                                    search: e?.target?.value,
                                })
                            }
                            onAddNewMember={() => setInviteMember(true)}
                            frontChecks
                            isRequired={true}
                            addModalButton
                            addText="Invite team member"
                            dropdownOptions={teamMembers?.map((member) => ({
                                label: member?.fullName || member?.email,
                                value: member?._id,
                                icon: (
                                    <VendorAvatar
                                        size={24}
                                        name={member?.fullName || member?.email}
                                        imageSrc={member?.avatar}
                                    />
                                ),
                            }))}
                        />
                    </div>
                    <div className="w-full">
                        <p className="text-sm font-rocGroteskMedium text-gm-50 mb-2">Every</p>
                        <div className="flex items-center w-full">
                            <div className="w-[65%]">
                                <TextInput
                                    value={stockAlerts[index].alertPeriod.quantity}
                                    name={`stockAlert_${index}_quantity`}
                                    type={"number"}
                                    required={true}
                                    disabled={false}
                                    onChange={(evt) =>
                                        handleStockAlertChange(
                                            index,
                                            "quantity",
                                            evt?.target?.value
                                        )
                                    }
                                    placeholder={"Enter value"}
                                    containerClassname=""
                                    inputContainerClassname={" !rounded-tr-none !rounded-br-none "}
                                    inputClassName="!w-[70%] "
                                />
                            </div>
                            <div className="w-[30%]">
                                <Dropdown
                                    dropdownTriggerClassName={" "}
                                    dropdown={
                                        <div className=" border border-slate-200 text-sm text-gm-50 font-rocGroteskMedium bg-slate-50 rounded-tr rounded-br flex space-x-4 items-center py-[14px] px-2.5">
                                            <p>{stockAlerts[index].alertPeriod.unit}</p>
                                            <i className="ri-arrow-down-s-line text-slate-500 "></i>
                                        </div>
                                    }
                                    handleChange={(_, value) =>
                                        handleStockAlertChange(index, "unit", value)
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4   border border-slate-100 w-[159px] rounded !max-h-fit`}
                                    name={"faq-dropdown"}
                                    dropdownOptions={[
                                        { label: "Minutes", value: "minutes" },
                                        { label: "Hours", value: "hours" },
                                        { label: "Days", value: "days" },
                                        { label: "Weeks", value: "weeks" },
                                        { label: "Months", value: "months" },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {index > 0 && (
                    <div onClick={() => deleteStockAlert(index)} className="cursor-pointer  group-hover:block hidden ">
                        <i className="ri-delete-bin-line text-slate-500"></i>
                    </div>
                )}
            </div>
        );
    };

    const renderExpiryAlertInputs = (index) => {
        return (
            <div className="flex w-full items-center group">
                <div className="flex mb-4 items-center justify-between gap-3 w-full">
                    <div className="w-full">
                        <p className="text-sm font-rocGroteskMedium text-gm-50 mb-2">
                            When product
                        </p>
                        <SelectInput
                            value={expiryAlerts[index].whenStock?.replace(/\s/g, "")}
                            name={`whenStock`}
                            placeholder={"Select when product"}
                            handleChange={(_, value) =>
                                handleExpiryAlertChange(index, "whenStock", value)
                            }
                            handleInputChange={() => {}}
                            isRequired={true}
                            searchLoading={false}
                            dropdownOptions={[
                                {
                                    label: "is close to expiry date",
                                    value: "isclosetoexpirydate",
                                },
                                { label: "expires", value: "expire" },
                            ]}
                        />
                    </div>
                    <div className="w-full">
                        <p className="text-sm font-rocGroteskMedium text-gm-50 mb-2">
                            Notify team members
                        </p>
                        <MultiselectInput
                            value={expiryAlerts[index].recipientsIds}
                            name={`expiryAlerts_${index}_recipientsIds`}
                            placeholder={"Team Members"}
                            handleChange={(name, value, selectedItems) =>
                                handleExpiryAlertChange(index, "recipientsIds", selectedItems)
                            }
                            searchLoading={fetchingTeamMember}
                            handleInputChange={(e) =>
                                setDebouncedTeamSearch({
                                    search: e?.target?.value,
                                })
                            }
                            onAddNewMember={() => setInviteMember(true)}
                            frontChecks
                            isRequired={true}
                            addModalButton
                            addText="Invite team member"
                            dropdownOptions={teamMembers?.map((member) => ({
                                label: member?.fullName || member?.email,
                                value: member?._id,
                                icon: (
                                    <VendorAvatar
                                        size={24}
                                        name={member?.fullName || member?.email}
                                        imageSrc={member?.avatar}
                                    />
                                ),
                            }))}
                        />
                    </div>
                    <div className="w-full">
                        <p className="text-sm font-rocGroteskMedium text-gm-50 mb-2">Every</p>
                        <div className="flex items-center w-full">
                            <div className="w-[65%]">
                                <TextInput
                                    value={expiryAlerts[index].alertPeriod.quantity}
                                    name={`expiryAlerts_${index}_quantity`}
                                    type={"number"}
                                    required={true}
                                    disabled={false}
                                    onChange={(evt) =>
                                        handleExpiryAlertChange(
                                            index,
                                            "quantity",
                                            evt?.target?.value
                                        )
                                    }
                                    placeholder={"Enter value"}
                                    containerClassname=""
                                    inputContainerClassname={" !rounded-tr-none !rounded-br-none "}
                                    inputClassName="!w-[70%] "
                                />
                            </div>
                            <div className="w-[30%]">
                                <Dropdown
                                    dropdownTriggerClassName={" "}
                                    dropdown={
                                        <div className=" border border-slate-200 text-sm text-gm-50 font-rocGroteskMedium bg-slate-50 rounded-tr rounded-br flex space-x-4 items-center py-[14px] px-2.5">
                                            <p>{expiryAlerts[index].alertPeriod.unit}</p>
                                            <i className="ri-arrow-down-s-line text-slate-500 "></i>
                                        </div>
                                    }
                                    handleChange={(_, value) =>
                                        handleExpiryAlertChange(index, "unit", value)
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4   border border-slate-100 w-[159px] rounded !max-h-fit`}
                                    name={"faq-dropdown"}
                                    dropdownOptions={[
                                        { label: "Minutes", value: "minutes" },
                                        { label: "Hours", value: "hours" },
                                        { label: "Days", value: "days" },
                                        { label: "Weeks", value: "weeks" },
                                        { label: "Months", value: "months" },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {index > 0 && (
                    <div onClick={() => deleteExpiryAlert(index)} className="cursor-pointer group-hover:block hidden ">
                        <i className="ri-delete-bin-line text-slate-500"></i>
                    </div>
                )}
            </div>
        );
    };

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <div className="border-b pb-10 border-slate-200">
                        {stockAlerts?.map((alert, index) => (
                            <div key={`stockAlert_${index}`}>{renderStockAlertLevel(index)}</div>
                        ))}
                        <div
                            onClick={handleAddStockAlert}
                            className="flex items-center gap-1 mt-6 text-g-65 font-rocGroteskMedium w-fit cursor-pointer"
                        >
                            <i className="ri-add-fill text-xl"></i>
                            <span className="underline text-sm">Add new alert</span>
                        </div>
                    </div>
                );

            case 1:
                return (
                    <div className="border-b pb-10 border-slate-200">
                        {expiryAlerts?.map((alert, index) => (
                            <div key={`expiryAlert_${index}`}>{renderExpiryAlertInputs(index)}</div>
                        ))}
                        <div
                            onClick={handleAddExpiryAlert}
                            className="flex items-center gap-1 mt-6 text-g-65 font-rocGroteskMedium w-fit cursor-pointer"
                        >
                            <i className="ri-add-fill text-xl"></i>
                            <span className="underline text-sm">Add new alert</span>
                        </div>
                    </div>
                );

            default:
                return;
        }
    };

    const filteredWarehouse = warehouseList.filter(
        (warehouse) => warehouse._id === (settingsData?.warehouseId ?? id)
    );

    const handleAddNewWarehouse = () => {
        const body = {
            address: address?.location,
            contactPersonId: addWarehouseData.contactPerson,
            warehouseName: addWarehouseData.name,
            additionalNotes: addWarehouseData.note,
        };

        dispatch(inventoryActions.createWarehouse(body));
    };
    useEffect(() => {
        if (createWarehouseSuccess) {
            setAddWarehouse(false);
            setAddWarehouseData({
                contactPerson: "",
                note: "",
                name: "",
            });
            dispatch(inventoryActions.resetCreateWarehouseSuccess());
            dispatch(inventoryActions.fetchWarehouseList(20));
        }
    }, [createWarehouseSuccess]);
    return (
        <PageFrame containerClassName={""} isLoading={!isPageLoaded}>
            <form onSubmit={(e) => onSaveSettingsChanges(e)}>
                <div className=" w-full pb-16 bg-white border border-slate-100  rounded ">
                    <div className=" w-full">
                        <div className="top-[-2px]  md:sticky md:px-8 py-4 px-[28px] z-[40] bg-white w-full border-b border-n-20 flex items-center justify-between">
                            <div className="flex items-center">
                                <div
                                    onClick={() => navigate(-1)}
                                    className="cursor-pointer space-x-1 flex items-center justify-center"
                                >
                                    <i className="ri-arrow-left-line text-sm text-carribean-green"></i>
                                    <p className="text-sm underline text-gm-50 font-rocGroteskMedium">
                                        Back
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-3">
                                <div>
                                    <Button
                                        disabled={
                                            addingInventorySettings || updatingInventorySettings
                                        }
                                        btnText="Cancel"
                                        btnClassname=" !py-2 !bg-[#F4F5F7] !text-gm-50"
                                        onClick={() => navigate(-1)}
                                    />
                                </div>

                                <div>
                                    <Button
                                        btnText="Save changes"
                                        btnClassname=" !py-2"
                                        type="submit"
                                        isLoading={
                                            addingInventorySettings || updatingInventorySettings
                                        }
                                        permissions={getEditPermissionList("inventory")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="md:px-8 py-4 px-[28px] ">
                            <div>
                                <div className="text-gm-50">
                                    <p className="text-lg font-rocGroteskMedium mb-4">
                                        Warehouse settings
                                    </p>
                                    <div className="mb-10 w-[495px] relative">
                                        <Dropdown
                                            name="warehouseId"
                                            value={settingsData?.warehouseId ?? filteredWarehouse[0]?._id}
                                            dropdownTriggerClassName={"w-full "}
                                            handleChange={(name, value) =>
                                                handleChange(name, value)
                                            }
                                            search={
                                                <TextInput
                                                    name={"search"}
                                                    value={warehouseSearch}
                                                    type={"text"}
                                                    inputPlaceholder={"Search"}
                                                    inputClassName={
                                                        "!h-[40px] pl-[0px] !bg-white text-sm !mb-0"
                                                    }
                                                    onChange={(e: any) =>
                                                        setWarehouseSearch(e?.target?.value)
                                                    }
                                                    onInput={(e: any) =>
                                                        setWarehouseSearch(e?.target?.value)
                                                    }
                                                    leftIcon={
                                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                                    }
                                                    inputContainerClassname={
                                                        "!rounded-[4px] w-full !pl-[40px]  !h-[40px] !bg-white shadow-boxShadow-2 !border-none"
                                                    }
                                                />
                                            }
                                            dropdown={
                                                <div
                                                    className={`${
                                                        isFocused ? "!border-g-60" : "border-gm-25"
                                                    } !border-[0.5px] px-4 py-2 rounded flex items-center w-full justify-between`}
                                                >
                                                    <div>
                                                        <p
                                                            className={` ${
                                                                filteredWarehouse[0]
                                                                    ?.warehouseName === undefined
                                                                    ? "text-sm"
                                                                    : "text-[8px]"
                                                            }  text-gm-45 font-rocGroteskMedium`}
                                                        >
                                                            Select reorder location
                                                        </p>
                                                        <p className="text-sm font-rocGroteskMedium text-gm-50">
                                                            {filteredWarehouse[0]?.warehouseName}
                                                        </p>
                                                    </div>
                                                    <div className="cursor-pointer">
                                                        <i className="ri-arrow-down-s-line text-gm-50"></i>
                                                    </div>
                                                </div>
                                            }
                                            dropdownClassName={"!w-full  !mb-10 !-mt-7"}
                                            dropdownContainerClasses={`shadow-cardShadow-4   !left-[0px] !mt-0  border border-slate-100 w-full rounded !max-h-fit
                                    !h-[300px] !top-[100%]
                            `}
                                            dropdownOptions={[
                                                
                                                ...warehouseList?.map((warehouse) => ({
                                                    label: (
                                                        <div className="flex space-x-2 items-center px-2  hover:bg-slate-100 cursor-pointer">
                                                            <i className="ri-map-pin-line text-gm-50 text-base"></i>
                                                            <div>
                                                                <p className="text-gm-50 text-sm font-rocGroteskMedium">
                                                                    {warehouse?.warehouseName}
                                                                </p>
                                                                <p className="text-xs font-rocGroteskMedium text-slate-500">
                                                                    {warehouse?.address?.address}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ),
                                                    value: warehouse?._id,
                                                })),
                                            ]}
                                            addMoreButton={
                                                <div
                                                    className="border-t pt-3 flex space-x-3 cursor-pointer px-4 items-center"
                                                    onClick={() => setAddWarehouse(true)}
                                                >
                                                    <div className="h-8 w-8 flex justify-center items-center bg-[#EDFFFC] ">
                                                        <i className="ri-add-fill text-g-55"></i>
                                                    </div>
                                                    <p className="text-g-55 text-sm font-rocGroteskMedium  underline">
                                                        Add new location
                                                    </p>
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="text-gm-50">
                                    <p className="text-gm-50 mb-3 font-rocGroteskMedium">
                                        Set your alert threshold
                                    </p>
                                    <TabsContainer
                                        tabs={tabs}
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                        component={displayActiveTab()}
                                        className="!px-0"
                                        itemClassName="!pb-2"
                                        borderLineClase={"!text-slate-100  w-full  !mb-6"}
                                    />
                                </div>
                                {activeTab === 0 && (
                                    <div className="py-10 border-b border-slate-200">
                                        <p className="text-gm-50 text-lg mb-3 font-rocGroteskMedium">
                                            Resending notification
                                        </p>
                                        <div className="flex gap-3 items-center mb-4">
                                            <Checkbox
                                                name="resendNotification"
                                                isChecked={
                                                    settingsData?.resendNotifications
                                                        ?.resendNotification
                                                }
                                                onChange={(e) =>
                                                    handleChange(
                                                        "resendNotification",
                                                        e.target.checked
                                                    )
                                                }
                                                className={"!h-[20px] !w-[20px] !rounded-[4px]"}
                                            />
                                            <p className="text-gm-50 font-rocGroteskMedium text-sm">
                                                Keep notifying users when they have reached the
                                                alert threshold
                                            </p>
                                        </div>
                                        <div className="w-[474px]">
                                            <p className="text-gm-50 text-sm mb-3 font-rocGroteskMedium">
                                                Renotify users every
                                            </p>
                                            <div className="flex items-center w-full">
                                                <div className="w-[65%]">
                                                    <TextInput
                                                        value={
                                                            settingsData?.resendNotifications
                                                                ?.alertPeriod?.quantity
                                                        }
                                                        name={"quantity"}
                                                        type={"number"}
                                                        disabled={false}
                                                        onChange={(e) =>
                                                            handleChange(
                                                                "quantity",
                                                                e?.target?.value
                                                            )
                                                        }
                                                        placeholder={"Enter value"}
                                                        containerClassname=""
                                                        inputContainerClassname={" !rounded-none "}
                                                        inputClassName="!w-[70%] "
                                                    />
                                                </div>
                                                <div className="w-[35%]">
                                                    <Dropdown
                                                        dropdownTriggerClassName={" "}
                                                        dropdown={
                                                            <div className=" border border-slate-200 text-sm text-gm-50 font-rocGroteskMedium bg-slate-50 rounded-tr rounded-br flex space-x-4 items-center py-[14px] px-2.5">
                                                                <p>
                                                                    {
                                                                        settingsData
                                                                            ?.resendNotifications
                                                                            ?.alertPeriod?.unit
                                                                    }
                                                                </p>
                                                                <i className="ri-arrow-down-s-line text-slate-500 "></i>
                                                            </div>
                                                        }
                                                        handleChange={(_, value) =>
                                                            handleChange("unit", value)
                                                        }
                                                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                                        dropdownContainerClasses={`shadow-cardShadow-4   border border-slate-100 w-[159px] rounded !max-h-fit`}
                                                        name={"faq-dropdown"}
                                                        dropdownOptions={[
                                                            { label: "Minutes", value: "minutes" },
                                                            { label: "Hours", value: "hours" },
                                                            { label: "Days", value: "days" },
                                                            { label: "Weeks", value: "weeks" },
                                                            { label: "Months", value: "months" },
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="py-10 border-b border-slate-200">
                                    <p className="text-gm-50 text-lg mb-4 font-rocGroteskMedium">
                                        Choose your alert notification method
                                    </p>
                                    <div className="flex items-start space-x-3 mb-4">
                                        <Checkbox
                                            name="email"
                                            isChecked={settingsData?.notificationMethod?.email}
                                            onChange={(evt) =>
                                                handleChange("email", evt?.target?.checked)
                                            }
                                            className={"!h-[20px] !w-[20px] !rounded-[4px]"}
                                        />
                                        <div className=" font-rocGroteskMedium text-sm">
                                            <p className="text-gm-50">Email</p>
                                            <p className="text-slate-500">
                                                Receive instant notifications directly to your email
                                                inbox. Stay informed in real-time
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-start space-x-3">
                                        <Checkbox
                                            name="inApp"
                                            isChecked={settingsData?.notificationMethod?.inapp}
                                            onChange={(evt) =>
                                                handleChange("inApp", evt?.target?.checked)
                                            }
                                            className={"!h-[20px] !w-[20px] !rounded-[4px]"}
                                        />
                                        <div className=" font-rocGroteskMedium text-sm">
                                            <p className="text-gm-50">In-App</p>
                                            <p className="text-slate-500">
                                                Receive instant notifications within the app and
                                                stay informed while using the Synthally.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-10 border-b border-slate-200">
                                    <p className="text-gm-50 text-lg mb-4 font-rocGroteskMedium">
                                        Define user roles and permission
                                    </p>
                                    <div className="w-[474px]">
                                        <p className="text-gm-50 text-sm mb-3 font-rocGroteskMedium">
                                            Restrict inventory editing of this warehouse to only
                                        </p>
                                        <div className=" w-[474px]">
                                            <MultiselectInput
                                                value={settingsData?.allowedUserRoles}
                                                name="allowedUserRoles"
                                                placeholder={"Role"}
                                                handleChange={(name, value, selectedItems) => {
                                                    handleChange(name, selectedItems);
                                                }}
                                                frontChecks
                                                searchLoading={fetchingRoles}
                                                handleInputChange={(e) => handleDebouncedChange(e)}
                                                addModalButton
                                                addText="Add a custom role"
                                                onAddNewMember={() => setAddCustomRole(true)}
                                                dropdownOptions={roles?.map((role) => ({
                                                    label: role?.label,
                                                    value: role.role,
                                                }))}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="py-10 border-b border-slate-200">
                                    <p className="text-gm-50 text-lg mb-4 font-rocGroteskMedium">
                                        Stock adjustment
                                    </p>
                                    <div className="flex items-start space-x-3">
                                        <Checkbox
                                            name="stockAdjustment"
                                            isChecked={settingsData?.stockAdjustment}
                                            onChange={(evt) =>
                                                handleChange(
                                                    "stockAdjustment",
                                                    evt?.target?.checked
                                                )
                                            }
                                            className={"!h-[20px] !w-[20px] !rounded-[4px]"}
                                        />
                                        <div className=" font-rocGroteskMedium text-sm">
                                            <p className="text-gm-50">Stock Adjustment Approval</p>
                                            <p className="text-slate-500">
                                                Adding an approval step to stock adjustments ensures
                                                accurate and accountable changes in your inventory.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-10 pb-6">
                                    <p className="text-gm-50 text-lg mb-4 font-rocGroteskMedium">
                                        Stock transfer
                                    </p>
                                    <div className="flex items-start space-x-3">
                                        <Checkbox
                                            name="stockTransfer"
                                            isChecked={settingsData?.stockTransfer}
                                            onChange={(evt) =>
                                                handleChange("stockTransfer", evt?.target?.checked)
                                            }
                                            className={"!h-[20px] !w-[20px] !rounded-[4px]"}
                                        />
                                        <div className=" font-rocGroteskMedium text-sm">
                                            <p className="text-gm-50">Stock Transfer Approval</p>
                                            <p className="text-slate-500">
                                                Adding an approval step to stock transfer ensures
                                                accurate and accountable changes in your inventory.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <ModalContainer
                open={inviteMember}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[553px]"
                closeModal={() => {
                    setInviteMember(false);
                    setTeamInfo([{ "email-0": "", "role-0": "" }]);
                }}
            >
                <div className="bg-white px-2 pt-3 pb-10 shadow-cardShadow relative rounded-[20px]">
                    <div
                        onClick={() => {
                            setInviteMember(false);
                            setTeamInfo([{ "email-0": "", "role-0": "" }]);
                        }}
                        className="cursor-pointer px-4 flex justify-end "
                    >
                        <i className="ri-close-fill text-gm-45 text-[24px]"></i>
                    </div>
                    <div className="w-full flex justify-center">
                        <div className="lg:w-[500px] relative w-[380px]">
                            <div className="w-full  pb-3   flex text-center items-center justify-center">
                                <div className="flex text-center flex-col items-center justify-center">
                                    <p className="text-xl mb-1.5 font-rocGroteskMedium text-g-75">
                                        Invite a team member
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-gm-40">
                                        Add team members to your workspace
                                    </p>
                                    <div
                                        className="cursor-pointer  w-fit my-2"
                                        onClick={() => setShowRolesAndResponsibiltyModal(true)}
                                    >
                                        <p className="text-b-55  text-xs   font-rocGroteskMedium  underline-offset-1 underline">
                                            View roles and responsibilities
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit} className="w-full  ">
                                <div
                                    className={`flex  flex-col max-h-[225px]  ${
                                        teamInfo?.length > 3 && " overflow-y-scroll "
                                    }  gap-4 w-full`}
                                    ref={containerRef}
                                >
                                    {teamInfo.map((inputData, index) => (
                                        <div
                                            key={index}
                                            className="flex   w-full items-center group"
                                        >
                                            <div className="w-[80%]">
                                                <TextInput
                                                    value={inputData[`email-${index}`]}
                                                    name={`email-${index}`}
                                                    type="email"
                                                    onChange={(e) => handleInviteChange(e, index)}
                                                    placeholder="Enter email address"
                                                    required={true}
                                                    inputContainerClassname="!rounded-tr-none rounded-br-none"
                                                />
                                            </div>
                                            <div className="  right-0 z-[]   bg-white">
                                                <SelectInput
                                                    value={inputData[`role-${index}`] || ""}
                                                    name={`role-${index}`}
                                                    placeholder="Assign role"
                                                    handleChange={(name, value) =>
                                                        handleInviteChange(
                                                            {
                                                                target: { name, value },
                                                            },
                                                            index
                                                        )
                                                    }
                                                    handleInputChange={debounce((evt) => {
                                                        setDebouncedSearch({
                                                            search: evt.target.value,
                                                        });
                                                    }, 800)}
                                                    searchLoading={fetchingRoles}
                                                    className={`!rounded-tl-none !bg-[#FCFCFC] rounded-bl-none`}
                                                    isRequired={true}
                                                    disabled={inputData[`email-${index}`] === ""}
                                                    dropdownClassName="!py-0 "
                                                    customDropdownContainer={
                                                        <div className="w-full  py-1.5 flex flex-col gap-3.5">
                                                            <div
                                                                onClick={() => {
                                                                    setAddCustomRole(true);
                                                                    setInviteMember(false);
                                                                    setFromInvite(true);
                                                                }}
                                                                className="flex items-center gap-1 px-3 font-rocGroteskMedium cursor-pointer w-fit"
                                                            >
                                                                <i className="ri-add-fill before:content-['\ea12'] text-p-55 text-xl mt-[-2px]"></i>
                                                                <span className="text-sm text-p-50">
                                                                    Add custom role
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                    dropdownOptions={roles?.map((item) => ({
                                                        label: item?.label,
                                                        value: item?.role,
                                                    }))}
                                                />
                                            </div>
                                            {index !== 0 && (
                                                <div className="pl-2 group-hover:block hidden">
                                                    <i
                                                        className="ri-delete-bin-line text-[20px] text-slate-400 cursor-pointer"
                                                        onClick={() => onDelete(index)}
                                                    ></i>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div
                                    className="cursor-pointer  flex space-x-1 items-center w-fit my-3"
                                    onClick={addNewInputField}
                                >
                                    <i className="ri-add-fill text-b-55  text-[24px]"></i>
                                    <p className="text-b-55  text-xs font-rocGroteskMedium  underline-offset-1 underline">
                                        Invite more team members
                                    </p>
                                </div>
                                <div className="w-full mt-10">
                                    <Button
                                        btnText={"Invite member"}
                                        isLoading={addingTeamMember}
                                        type={"submit"}
                                    />
                                    <Button
                                        btnText={"Close"}
                                        onClick={() => {
                                            setInviteMember(false);
                                            setTeamInfo([{ "email-0": "", "role-0": "" }]);
                                        }}
                                        disabled={addingTeamMember}
                                        btnClassname={"!bg-transparent !text-gm-75 mt-2"}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={addCustomRole}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[513px] xl:w-[600px]"
                closeModal={() => {
                    setAddCustomRole(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-modalShadow relative pt-5 pb-10">
                    <div className="flex justify-end px-10">
                        <span
                            onClick={() => setAddCustomRole(false)}
                            className="material-icons text-gm-50 text-xl cursor-pointer"
                        >
                            close
                        </span>
                    </div>
                    <div className="px-10 pt-3">
                        <div className="flex flex-col items-center text-center mb-[45px]">
                            <p className="text-2xl mb-2.5 font-rocGroteskMedium text-g-75">
                                Create a custom role
                            </p>
                            <p className="text-sm font-rocGroteskMedium text-gm-40">
                                Define what the new role’s responsibilities are
                            </p>
                        </div>
                        <form className="w-full">
                            <TextInput
                                name={`role`}
                                type={"text"}
                                placeholder={"Enter role name"}
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                                required={true}
                                containerClassname={"mb-6 "}
                            />
                            <div>
                                <p className="text-base font-rocGroteskMedium mb-6">
                                    Select access levels
                                </p>
                                <div className="flex flex-wrap gap-4 relative overflow-auto">
                                    {accessTypes.map((access, idx) => (
                                        <div
                                            key={idx}
                                            onClick={() => handlePermissionChange(access.value)}
                                            className={`px-2.5 w-fit h-[30px] ${
                                                selectedPermissions.includes(access.value)
                                                    ? "!bg-carribean-green !text-white !border-carribean-green"
                                                    : ""
                                            } border flex items-center gap-2 rounded-[8px] font-rocGroteskMedium bg-white hover:!bg-g-65 hover:text-white hover:!border-g-65 border-slate-300 text-[13px] cursor-pointer`}
                                        >
                                            {selectedPermissions.includes(access.value) ? (
                                                <i className="ri-checkbox-circle-fill text-base"></i>
                                            ) : (
                                                <i className="ri-add-fill text-base"></i>
                                            )}
                                            {access.label}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <Button
                                onClick={handleAddRole}
                                btnText={"Add role"}
                                isLoading={createRoles}
                                btnClassname={"my-10"}
                            />
                            <p
                                onClick={() => setAddCustomRole(false)}
                                className="text-sm font-rocGroteskMedium text-center cursor-pointer"
                            >
                                Close
                            </p>
                        </form>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={addWarehouse}
                showCloseIcon={false}
                tailwindClassName="w-[42%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setAddWarehouse(false);
                }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleAddNewWarehouse();
                    }}
                    className="bg-white rounded-lg shadow-cardShadow relative"
                >
                    <div className="px-10 pt-3">
                        <div className="flex flex-col items-center">
                            <div className="flex w-full justify-end mr-[-12px]">
                                <i
                                    onClick={() => setAddWarehouse(false)}
                                    className="ri-close-fill text-2xl cursor-pointe"
                                ></i>
                            </div>

                            <p className="text-xl mb-3 text-center  font-rocGroteskMedium text-g-75">
                                Add a new warehouse location
                            </p>
                            <div className="flex flex-col gap-6 w-full">
                                <TextInput
                                    value={addWarehouseData.name}
                                    name={"name"}
                                    type={"text"}
                                    onChange={(e) =>
                                        setAddWarehouseData((prev) => ({
                                            ...prev,
                                            [e.target.name]: e.target.value,
                                        }))
                                    }
                                    placeholder={"Warehouse name"}
                                    required={true}
                                    containerClassname=""
                                />
                                <SelectInput
                                    value={addWarehouseData.contactPerson}
                                    name="contactPerson"
                                    placeholder={"Contact Person"}
                                    handleChange={(name, value) => {
                                        setAddWarehouseData((prev) => ({ ...prev, [name]: value }));
                                    }}
                                    isRequired={true}
                                    searchLoading={fetchingTeamMember}
                                    dropdownOptions={teamMembers?.map((team) => ({
                                        label: team?.fullName ? team?.fullName : team?.email,
                                        value: team?._id,
                                        icon: (
                                            <VendorAvatar
                                                name={team?.fullName ? team?.fullName : team?.email}
                                                imageSrc={team?.profile?.avatar}
                                                size={20}
                                            />
                                        ),
                                    }))}
                                />
                                <AddressInput
                                    setData={setAddress}
                                    savedAddress={
                                        address?.location ? address?.location?.address : ""
                                    }
                                    placeholder="Location"
                                    required={true}
                                    name={"location"}
                                />
                                <TextareaInput
                                    name={"note"}
                                    value={addWarehouseData.note}
                                    onChange={(e) =>
                                        setAddWarehouseData((prev) => ({
                                            ...prev,
                                            [e.target.name]: e.target.value,
                                        }))
                                    }
                                    rows={5}
                                    placeholder={"Additional note"}
                                    required={true}
                                    containerClassname=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full items-center justify-between px-8 py-4 border-t border-slate-200 mt-10">
                        <Button
                            btnText={"View all warehouse"}
                            type={"button"}
                            btnClassname="!bg-white !border !border-n-20 !py-3 !pl-3 !pr-4 !w-fit !h-[48px]"
                            icon={<i className="ri-external-link-line text-lg"></i>}
                            btnType="textFirst"
                            onClick={() => {
                                navigate("/dashboard/inventory/warehouse");
                            }}
                        />
                        <div className="flex items-center gap-2">
                            <Button
                                btnText={"Cancel"}
                                type={"button"}
                                btnClassname="!bg-tradeally-neutral-20 !w-fit !h-[48px]"
                                disabled={createWarehouse}
                                onClick={() => {
                                    setAddWarehouse(false);
                                }}
                            />
                            <Button
                                btnText={"Add warehouse"}
                                type={"submit"}
                                btnClassname="!py-3 !px-6 !w-fit !h-[48px]"
                                isLoading={createWarehouse}
                                disabled={createWarehouse}
                            />
                        </div>
                    </div>
                </form>
            </ModalContainer>
        </PageFrame>
    );
};

export default Settings;
