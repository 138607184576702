import AddVendorForm from "components/common/AddVendorForm";
import AddVendorMethods from "components/common/AddVendorMethods";
import Button from "components/common/Button";
import CustomMobileTable from "components/common/CustomMobileTable";
import CustomTable from "components/common/CustomTable";
import CustomToast from "components/common/CustomToast";
import Checkbox from "components/common/InputField/Checkbox";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import TabsContainer from "components/common/TabsContainer";
import { getFromStorage } from "helpers";
import { set, truncate } from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { authActions, vendorActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import TextInput from "components/common/InputField/TextInput";
import SelectInput from "components/common/InputField/SelectInput";
import { vendorCategories } from "variables";
import ReactCountryFlag from "react-country-flag";
import countries from "variables/countries";
import ToggleInput from "components/common/InputField/ToggleInput";

const AddVendor = () => {
    // const [activeTab, setActiveTab] = useState<number>(0);
    // const [email, setEmail] = useState<string>("");
    // const [vendorCategory, setVendorCategory] = useState<string>("");
    // const [requiredDocuments, setRequiredDocuments] = useState<{ [key: string]: any }[]>([]);
    const [suggestedVendors, setSuggestedVendors] = useState<{ [key: string]: any }[]>([]);
    const [selectedVendors, setSelectedVendors] = useState<{ [key: string]: any }[]>([]);
    const [newDoc, setNewDoc] = useState<string>("");
    const [newCategory, setNewCategory] = useState<string>("");
    const [canExpire, setCanExpire] = useState<boolean>(false);
    const [addDocModal, setAddDocModal] = useState<boolean>(false);
    const [addCategoryModal, setAddCategoryModal] = useState<boolean>(false);
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [inviteSupplierData, setInviteSupplierData] = useState<{ [key: string]: any }>({
        email: "",
        category: "",
        channel: "",
        socialMedia: "",
        ext: "",
        phone: "",
    });
    const [documents, setDocuments] = useState<{ [key: string]: any }[]>([]);
    const [categories, setCategories] = useState<{ [key: string]: any }[]>([]);
    const [docs, setDocs] = useState<{ [key: string]: any }[]>([
        { name: "W-9", expiryDate: false },
        { name: "Business registration", expiryDate: false },
        { name: "Tax clearance", expiryDate: false },
        { name: "Audited financial account", expiryDate: false },
        { name: "Track record of successful shipments", expiryDate: false },
        { name: "Trade lanes", expiryDate: false },
    ]);

    const { addingVendor, addVendorSuccess } = useAppSelector((state) => state.auth);
    const {
        fetchedSuggestedVendorsSuccess,
        connectingGoogleWorkspace,
        connectGoogleWorkspaceSuccess,
        connectingMicrosoftWorkspace,
        connectMicrosoftWorkspaceSuccess,
        fetchingVendorCategories,
        fetchVendorCategoriesSuccess,
        addingVendorCategory,
        addVendorCategorySuccess,
    } = useAppSelector((state) => state.vendor);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [param] = useSearchParams();
    const code = param.get("code");
    // const manual = param.get("manual");
    // const [tabs, setTabs] = useState(["Add existing vendors"]);

    const handleSelectAllVendors = (deselect?: boolean) => {
        setSelectedVendors(() => (deselect ? [] : suggestedVendors));
    };

    const tableHeader = [
        {
            title: "Vendors",
            widthClass: "w-[40%]",
            onSelectAll: () => {
                handleSelectAllVendors(selectedVendors?.length > 0);
            },
            isAllChecked: selectedVendors?.length === suggestedVendors?.length,
        },
        { title: "Category" },
        { title: "Email address" },
        // { title: "Rating" },
    ];

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }
    ) => {
        const { name, value } = e.target;

        setInviteSupplierData((prev) => ({ ...prev, [name]: value }));
    };
    console.log("documents", documents);
    console.log("inviteSupplierData", inviteSupplierData);
    const handleSetDocuments = (value: { [key: string]: any }) => {
        const documentsCopy = [...documents];
        const documentIdx = documentsCopy.findIndex((doc) => doc?.name === value?.name);

        if (documentIdx !== -1) {
            documentsCopy.splice(documentIdx, 1);
        } else {
            documentsCopy.push(value);
        }

        setDocuments(documentsCopy);
    };

    const handleAddSuggestedVendors = () => {
        if (selectedVendors && selectedVendors?.length > 0) {
            const body = {
                vendors: selectedVendors?.map((vendor) => ({
                    email: vendor?.email?.trim(),
                    documents: [],
                    category: vendor?.category?.trim(),
                })),
            };

            dispatch(authActions.addVendor(body));
        } else {
            toast.custom((t) => (
                <CustomToast t={t} message={"Please select at least one vendor"} type="success" />
            ));
        }
    };

    const handleSelectEachVendor = (vendorSelected: { [key: string]: any }) => {
        const selectedVendorsCopy = [...selectedVendors];
        const idx = selectedVendorsCopy?.findIndex((vendor) => vendor?._id === vendorSelected?._id);

        if (idx !== -1) {
            selectedVendorsCopy.splice(idx, 1);
        } else {
            selectedVendorsCopy.push(vendorSelected);
        }
        setSelectedVendors(selectedVendorsCopy);
    };

    const tableBody = suggestedVendors?.map((vendor) => {
        const isChecked = selectedVendors?.find((vend) => vend?._id === vendor?._id);

        return [
            {
                content: (
                    <div className={`py-3 pr-3 text-sm text-slate-700`}>
                        <div className="flex items-center gap-3">
                            <span>{vendor?.name}</span>
                        </div>
                    </div>
                ),
                data: vendor,
                isItemChecked: isChecked,

                onCheckBoxClick: () => {
                    handleSelectEachVendor(vendor);
                },
            },
            {
                content: (
                    <div className={`py-3 pr-3 flex items-center h-full text-sm text-slate-700 `}>
                        {vendor?.category}
                    </div>
                ),
            },
            {
                content: (
                    <div className={`py-3 pr-3 flex items-center h-full text-sm text-slate-700 `}>
                        {truncate(vendor?.email, { length: 25 })}
                    </div>
                ),
            },
            // {
            //     content: (
            //         <div className={`py-3 pr-3 flex items-center h-full text-sm text-slate-700 `}>
            //             <div className="flex items-center gap-2">
            //                 <i className="ri-star-fill text-star-gold"></i>
            //                 <i className="ri-star-fill text-star-gold"></i>
            //                 <i className="ri-star-fill text-star-gold"></i>
            //                 <i className="ri-star-line text-slate-300"></i>
            //                 <i className="ri-star-line text-slate-300"></i>
            //             </div>
            //         </div>
            //     ),
            // },
        ];
    });

    const mobileTableBody = suggestedVendors?.map((vendor) => {
        const isChecked = selectedVendors?.find((vend) => vend?._id === vendor?._id);

        return {
            topContent: <p className="text-sm font-rocGroteskMedium">{vendor?.name}</p>,
            bottomContent: (
                <p className="text-sm font-rocGroteskMedium text-slate-500">
                    {truncate(vendor?.category, { length: 25 })}
                </p>
            ),
            rightIcon: (
                <Checkbox
                    name="select"
                    isChecked={Boolean(isChecked)}
                    // value={Boolean(isChecked)}
                    onChange={() => {
                        handleSelectEachVendor(vendor);
                    }}
                    className={"!h-4 !w-4 !rounded-[4px]"}
                />
            ),
            cellClickAction: () => {
                handleSelectEachVendor(vendor);
            },
        };
    });

    const handleAddVendorManually = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: { [key: string]: any } = {
            vendors: [
                {
                    email: inviteSupplierData?.email,
                    documents: documents?.map((docu) => {
                        return { name: docu?.name, expiryDate: docu?.expiryDate };
                    }),
                    category: inviteSupplierData.category,
                    onboardingChannel: inviteSupplierData.channel,
                    // mobile: inviteSupplierData.ext + inviteSupplierData.phone,
                    // mobileExtension: inviteSupplierData.ext,
                    // rawMobile: inviteSupplierData.phone,
                },
            ],
        };

        if (inviteSupplierData?.channel !== "email") {
            body.vendors[0].mobile = inviteSupplierData.ext + inviteSupplierData.phone;
            body.vendors[0].mobileExtension = inviteSupplierData.ext;
            body.vendors[0].rawMobile = inviteSupplierData.phone;
        }

        dispatch(authActions.addVendor(body));
    };

    // const displayActiveTab = () => {
    //     switch (activeTab) {
    //         case 0:
    //             return (
    //                 <>
    //                     {!manual && (
    //                         <div className="w-[83%] max-lg:w-full pt-6">
    //                             <AddVendorMethods
    //                                 addContactFunc={() =>
    //                                     navigate("/dashboard/suppliers/add-vendor?manual=true", {
    //                                         replace: true,
    //                                     })
    //                                 }
    //                             />
    //                         </div>
    //                     )}
    //                     {manual && (
    //                         <div className="relative">
    //                             <div className="flex justify-center">
    //                                 <div className="w-[50%] max-sm:w-full">
    //                                     <AddVendorForm
    //                                         title={
    //                                             <p className="text-xl font-rocGroteskMedium mb-6">
    //                                                 Invite your vendors via email
    //                                             </p>
    //                                         }
    //                                         onSubmit={handleAddVendorManually}
    //                                         email={email}
    //                                         vendorCategory={vendorCategory}
    //                                         onChangeEmail={setEmail}
    //                                         onChangeCategory={setVendorCategory}
    //                                         onChangeDocs={setRequiredDocuments}
    //                                         isSending={addingVendor}
    //                                     />
    //                                     <div
    //                                         className="text-sm px-10 pb-10 relative mt-[-14px] font-rocGroteskMedium w-full flex justify-center items-center gap-2 cursor-pointer"
    //                                         onClick={() => {
    //                                             setEmail("");
    //                                             setVendorCategory("");
    //                                             setRequiredDocuments([]);
    //                                             navigate("/dashboard/suppliers/add-vendor", {
    //                                                 replace: true,
    //                                             });
    //                                         }}
    //                                     >
    //                                         <span>Back</span>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     )}
    //                 </>
    //             );

    //         case 1:
    //             return (
    //                 <div className="pt-6 max-lg:flex max-lg:flex-col">
    //                     <div className="max-lg:hidden">
    //                         <CustomTable
    //                             tableBody={tableBody}
    //                             tableHeader={tableHeader}
    //                             headerContainerClass="!bg-slate-50"
    //                             isAllSelectable={true}
    //                             isCellSelectable={true}
    //                             isScrollable={false}
    //                             isCellBordered={false}
    //                         />
    //                     </div>
    //                     <div className="hidden max-lg:block">
    //                         <CustomMobileTable data={mobileTableBody} />
    //                     </div>
    //                     <div className="flex justify-end my-8">
    //                         <div className="flex items-center gap-3">
    //                             {/* <Button
    //                                 btnText={"Show more vendors"}
    //                                 btnClassname={"!bg-n-20 !text-gm-50 whitespace-nowrap !py-3"}
    //                             /> */}
    //                             <Button
    //                                 btnText={"Add vendors to list"}
    //                                 btnClassname={"!py-3"}
    //                                 isLoading={addingVendor}
    //                                 onClick={handleAddSuggestedVendors}
    //                             />
    //                         </div>
    //                     </div>
    //                 </div>
    //             );

    //         default:
    //             return (
    //                 <>
    //                     {!manual && (
    //                         <div className="w-[83%] pt-6">
    //                             <AddVendorMethods
    //                                 addContactFunc={() =>
    //                                     navigate("/dashboard/suppliers/add-vendor?manual=true", {
    //                                         replace: true,
    //                                     })
    //                                 }
    //                             />
    //                         </div>
    //                     )}
    //                     {manual && (
    //                         <div className="relative">
    //                             <div className="flex justify-center">
    //                                 <div className="w-[50%]">
    //                                     <AddVendorForm
    //                                         title={
    //                                             <p className="text-xl font-rocGroteskMedium mb-6">
    //                                                 Invite your vendors via email
    //                                             </p>
    //                                         }
    //                                         onSubmit={handleAddVendorManually}
    //                                         email={email}
    //                                         vendorCategory={vendorCategory}
    //                                         onChangeEmail={setEmail}
    //                                         onChangeCategory={setVendorCategory}
    //                                         onChangeDocs={setRequiredDocuments}
    //                                         isSending={addingVendor}
    //                                     />
    //                                     <div
    //                                         className="text-sm px-10 pb-10 relative mt-[-14px] font-rocGroteskMedium w-full flex justify-center items-center gap-2 cursor-pointer"
    //                                         onClick={() => {
    //                                             setEmail("");
    //                                             setVendorCategory("");
    //                                             setRequiredDocuments([]);
    //                                             navigate("/dashboard/suppliers/add-vendor", {
    //                                                 replace: true,
    //                                             });
    //                                         }}
    //                                     >
    //                                         <span>Back</span>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     )}
    //                 </>
    //             );
    //     }
    // };

    useEffect(() => {
        if (Boolean(addVendorSuccess)) {
            setDocuments([]);
            setInviteSupplierData({
                email: "",
                category: "",
                channel: "",
                socialMedia: "",
                ext: "",
                phone: "",
            });
            setSuccessModal(true);
            // navigate("/dashboard/suppliers/add-vendor", {
            //     replace: true,
            // });
            dispatch(authActions.resetAddVendorSuccess());
        }
    }, [addVendorSuccess, navigate, dispatch]);

    useEffect(() => {
        const source = getFromStorage("connectionSource");
        if (code && source && source === "gmail") {
            dispatch(vendorActions.connectGoogleWorkspace({ code }));
        }

        if (code && source && source === "microsoft") {
            dispatch(vendorActions.connectMicrosoftWorkspace({ code }));
        }
    }, [code, dispatch]);

    useEffect(() => {
        dispatch(vendorActions.getVendorCategories(100));
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchVendorCategoriesSuccess)) {
            console.log("fetchVendorCategoriesSuccess", fetchVendorCategoriesSuccess);
            setCategories(fetchVendorCategoriesSuccess?.categories?.data);
        }
    }, [fetchVendorCategoriesSuccess]);

    useEffect(() => {
        if (Boolean(addVendorCategorySuccess)) {
            console.log("addVendorCategorySuccess", addVendorCategorySuccess);
            // dispatch(vendorActions.getVendorCategories());
            setCategories((prev) => [addVendorCategorySuccess?.category, ...prev]);
            setNewCategory("");
            handleChange({
                target: { name: "category", value: addVendorCategorySuccess?.category?.category },
            });
            setAddCategoryModal(false);
            dispatch(vendorActions.resetAddVendorCategorySuccess());
        }
    }, [dispatch, addVendorCategorySuccess]);

    useEffect(() => {
        if (Boolean(connectGoogleWorkspaceSuccess)) {
            dispatch(vendorActions.resetConnectGoogleWorkspaceSuccess());
            navigate("/dashboard/suppliers", { replace: true });
        }
        if (Boolean(connectMicrosoftWorkspaceSuccess)) {
            dispatch(vendorActions.resetConnectMicrosoftWorkspaceSuccess());
            navigate("/dashboard/suppliers", { replace: true });
        }
    }, [connectGoogleWorkspaceSuccess, navigate, dispatch, connectMicrosoftWorkspaceSuccess]);

    // useEffect(() => {
    //     dispatch(vendorActions.getSuggestedVendors());
    // }, [addVendorSuccess, dispatch]);

    // useEffect(() => {
    //     if (Boolean(fetchedSuggestedVendorsSuccess)) {
    //         setSuggestedVendors(fetchedSuggestedVendorsSuccess?.vendors);
    //         const suggested = [...fetchedSuggestedVendorsSuccess?.vendors];
    //         if (tabs?.length === 2) {
    //             setTabs((prev) => (suggested?.length > 0 ? [...prev] : ["Add existing vendors"]));
    //         } else {
    //             setTabs((prev) =>
    //                 suggested?.length > 0
    //                     ? [...prev, "Suggested vendors"]
    //                     : ["Add existing vendors"]
    //             );
    //         }

    //         dispatch(vendorActions.resetGetSuggestedVendorsSuccess());
    //     }
    // }, [fetchedSuggestedVendorsSuccess, dispatch, tabs?.length]);

    return (
        <>
            <div className="w-full h-full px-[14px] max-lg:px-0">
                <div className="w-full px-6 h-auto rounded-[12px] bg-white border border-slate-100">
                    <Link
                        to={"/dashboard/suppliers"}
                        className=" pt-6 flex items-center gap-1 mb-4 cursor w-fit"
                    >
                        <i className="ri-arrow-left-line mt-[-2px] text-sm"></i>
                        <p className="text-sm text-gm-50 font-rocGroteskMedium">Back</p>
                    </Link>
                    <div>
                        <div className="mb-[100px]">
                            <p className="text-xl font-rocGroteskMedium mb-1 flex items-center space-x-3 ">
                                <span>Initiate onboarding</span>
                                <span className="px-4 h-[40px] rounded text-[13px] bg-[#6E18F412] flex items-center justify-center">
                                    Admin only
                                </span>
                            </p>
                            {/* <p className="text-sm font-rocGroteskMedium text-gm-40">
                                Invite existing vendors to your TradeAlly account to accelerate
                                supply chain operations and improve vendor management.
                            </p> */}
                        </div>
                        {/* <div>
                            <TabsContainer
                                tabs={tabs}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                component={displayActiveTab()}
                                className={"!px-0"}
                            />
                        </div> */}
                        <div className="w-full flex justify-center">
                            <div className="w-[47%] bg-white shadow-[0px_4px_24px_0px_#0000000F] py-8 px-6 font-rocGroteskMedium">
                                <p className="text-center mb-6">Invite your Supplier</p>
                                <form
                                    onSubmit={handleAddVendorManually}
                                    className="flex flex-col space-y-4"
                                >
                                    <SelectInput
                                        value={inviteSupplierData.channel}
                                        name="channel"
                                        placeholder={"Select channel"}
                                        className=""
                                        handleChange={(name, value) =>
                                            handleChange({ target: { name, value } })
                                        }
                                        isRequired={true}
                                        dropdownOptions={[
                                            { label: "Email", value: "email" },
                                            { label: "Whatsapp", value: "whatsApp" },
                                            // { label: "Portal website", value: "portal website" },
                                        ]}
                                    />

                                    <TextInput
                                        value={inviteSupplierData.email}
                                        name={"email"}
                                        type={"email"}
                                        pattern={"^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"}
                                        title={"Please fill in a valid email address"}
                                        onChange={handleChange}
                                        placeholder={"Enter email address"}
                                        required={true}
                                        containerClassname="mb-3"
                                    />

                                    {/* {inviteSupplierData.channel?.toLowerCase() ===
                                        "social media" && (
                                        <SelectInput
                                            value={inviteSupplierData.socialMedia}
                                            name="socialMedia"
                                            placeholder={"Social media"}
                                            className=""
                                            handleChange={(name, value) =>
                                                handleChange({ target: { name, value } })
                                            }
                                            isRequired={true}
                                            dropdownOptions={[
                                                {
                                                    label: "Whatsapp",
                                                    value: "whatsapp",
                                                    icon: (
                                                        <img
                                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1706286733/whatsapp_jlyfky.svg"
                                                            alt="whatsapp"
                                                            className="w-5 h-5"
                                                        />
                                                    ),
                                                },
                                                {
                                                    label: "Facebook",
                                                    value: "facebook",
                                                    icon: (
                                                        <img
                                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1707822161/Facebook_kceeff.svg"
                                                            alt="whatsapp"
                                                            className="w-5 h-5"
                                                        />
                                                    ),
                                                },
                                                {
                                                    label: "LinkedIn",
                                                    value: "linkedin",
                                                    icon: (
                                                        <img
                                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1707822161/linkedin_y8vyvq.svg"
                                                            alt="whatsapp"
                                                            className="w-5 h-5"
                                                        />
                                                    ),
                                                },
                                                {
                                                    label: "Instagram",
                                                    value: "instagram",
                                                    icon: (
                                                        <img
                                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1707822161/instagram_gagiai.svg"
                                                            alt="whatsapp"
                                                            className="w-5 h-5"
                                                        />
                                                    ),
                                                },
                                            ]}
                                        />
                                    )} */}

                                    {inviteSupplierData.channel !== "email" &&
                                        inviteSupplierData.channel !== "" && (
                                            <div className="w-full grid grid-cols-[115px_calc(100%-119px)] gap-1 mb-6">
                                                <SelectInput
                                                    value={inviteSupplierData.ext}
                                                    name="ext"
                                                    placeholder={"Ext"}
                                                    handleChange={(name, value) =>
                                                        handleChange({ target: { name, value } })
                                                    }
                                                    isRequired={true}
                                                    inputClassName={
                                                        "!placeholder:text-slate-400 !placeholder:font-rocGroteskMedium"
                                                    }
                                                    dropdownOptions={countries
                                                        .filter((data) => Boolean(data?.codes[0]))
                                                        .sort((p1, p2) =>
                                                            p1.codes[0] > p2.codes[0]
                                                                ? 1
                                                                : p1.codes[0] < p2.codes[0]
                                                                ? -1
                                                                : 0
                                                        )
                                                        .map((item) => ({
                                                            label: item.codes[0]?.replace(" ", ""),
                                                            value: item.codes[0]?.replace(" ", ""),
                                                            icon: (
                                                                <ReactCountryFlag
                                                                    countryCode={item?.abbreviation}
                                                                    svg
                                                                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                                    cdnSuffix="svg"
                                                                    style={{
                                                                        width: "16px",
                                                                        height: "16px",
                                                                        borderRadius: "50%",
                                                                        marginTop: "-4px",
                                                                    }}
                                                                />
                                                            ),
                                                        }))}
                                                />
                                                <TextInput
                                                    name={"phone"}
                                                    type={"number"}
                                                    value={inviteSupplierData.phone}
                                                    onChange={handleChange}
                                                    placeholder={"Phone number"}
                                                    minLength={10}
                                                    onKeyDown={(evt) =>
                                                        [
                                                            "e",
                                                            "E",
                                                            "+",
                                                            "-",
                                                            "ArrowUp",
                                                            "ArrowDown",
                                                        ].includes(evt.key) && evt.preventDefault()
                                                    }
                                                    required={true}
                                                    pattern={"^[0-9]+$"}
                                                    title={"must be digits"}
                                                    inputClassName={
                                                        "!placeholder:text-slate-400 !placeholder:font-rocGroteskMedium"
                                                    }
                                                />
                                            </div>
                                        )}

                                    <SelectInput
                                        value={inviteSupplierData.category}
                                        name="category"
                                        placeholder={"Select supplier category"}
                                        className=""
                                        handleChange={(name, value) =>
                                            handleChange({ target: { name, value } })
                                        }
                                        isRequired={true}
                                        dropdownOptions={categories.map((item) => ({
                                            label: item?.category,
                                            value: item?.category,
                                        }))}
                                        customDropdownContainer={
                                            <div
                                                onClick={() => setAddCategoryModal(true)}
                                                className="w-fit flex items-center space-x-1 pl-4 cursor-pointer"
                                            >
                                                <i className="ri-add-fill text-b-55"></i>
                                                <span className="text-sm underline text-b-55">
                                                    Add new
                                                </span>
                                            </div>
                                        }
                                    />

                                    <div className="text-sm">
                                        <p className="mb-2">
                                            Select required documents for this vendor
                                            <span className="text-r-50 text-sm mt-[-2px]">*</span>
                                        </p>
                                        <div>
                                            {docs?.map((doc, idx) => {
                                                const isDocPresent = documents?.find(
                                                    (docu) => doc?.name === docu?.name
                                                );
                                                return (
                                                    <div
                                                        key={doc?.name + idx}
                                                        className="flex items-center justify-between py-2"
                                                    >
                                                        <div className="flex items-center space-x-1">
                                                            <span className="text-slate-500">
                                                                {doc?.name}
                                                            </span>

                                                            {doc?.expiryDate && (
                                                                <span className="text-b-55 text-sm mt-[-2px] border-l border-[#E2E8F0] pl-1">
                                                                    Expiry date
                                                                </span>
                                                            )}
                                                        </div>

                                                        <Checkbox
                                                            name="selectDoc"
                                                            isChecked={Boolean(isDocPresent)}
                                                            required={false}
                                                            onChange={(evt) => {
                                                                handleSetDocuments(doc);
                                                            }}
                                                            className={"!h-4 !w-4 !rounded-[4px]"}
                                                        />
                                                    </div>
                                                );
                                            })}
                                            <div
                                                onClick={() => setAddDocModal(true)}
                                                className="w-fit flex items-center space-x-1 mt-4 cursor-pointer"
                                            >
                                                <i className="ri-add-fill"></i>
                                                <span className="text-sm underline">Add new</span>
                                            </div>
                                        </div>
                                    </div>
                                    <Button
                                        btnText={"Send invite"}
                                        type="submit"
                                        isLoading={addingVendor}
                                        btnClassname="py-3 mt-6"
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalContainer
                open={connectingGoogleWorkspace || connectingMicrosoftWorkspace}
                showCloseIcon={false}
                tailwindClassName="w-[36%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="w-full py-8 bg-white flex flex-col items-center rounded-md">
                    <Loader size={6} color={"gm-50"} />
                    <p className="text-sm text-gm-50 font-rocGroteskMedium mt-2">
                        Connecting account...
                    </p>
                </div>
            </ModalContainer>

            <ModalContainer
                open={addDocModal}
                showCloseIcon={false}
                tailwindClassName="w-[37%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setAddDocModal(false);
                    setCanExpire(false);
                }}
            >
                <div className="w-full py-4 px-4 bg-white rounded-md font-rocGroteskMedium">
                    <div className="flex items-center justify-between py-2.5 mb-4 border-b border-slate-200">
                        <span className="text-base">Add new document</span>
                        <i
                            onClick={() => {
                                setAddDocModal(false);
                                setCanExpire(false);
                            }}
                            className="ri-close-fill text-xl cursor-pointer"
                        ></i>
                    </div>
                    <div className="mb-6">
                        <TextInput
                            value={newDoc}
                            name={"docName"}
                            type={"text"}
                            onChange={(e) => setNewDoc(e.target.value)}
                            placeholder={"Document name"}
                            required={true}
                            containerClassname="mb-4"
                        />
                        <div className="flex items-center justify-between">
                            <span className="text-sm text-slate-500">Include expiring date</span>
                            <ToggleInput
                                onChange={(e) => setCanExpire(e.target.checked)}
                                name={"canExpire"}
                                checked={canExpire}
                            />
                        </div>
                    </div>
                    <div className="pt-4 px-6 border-t border-slate-200 flex items-center space-x-2">
                        <Button
                            btnText={"Cancel"}
                            type="button"
                            btnClassname="py-3 !bg-n-20"
                            onClick={() => {
                                setAddDocModal(false);
                                setCanExpire(false);
                            }}
                        />
                        <Button
                            btnText={"Add document"}
                            type="button"
                            btnClassname="py-3"
                            onClick={() => {
                                if (newDoc) {
                                    setDocs((prev) => [
                                        ...prev,
                                        { name: newDoc, expiryDate: canExpire },
                                    ]);
                                    setDocuments((prev) => [
                                        ...prev,
                                        { name: newDoc, expiryDate: canExpire },
                                    ]);
                                    setNewDoc("");
                                    setAddDocModal(false);
                                    setCanExpire(false);
                                }
                            }}
                        />
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={addCategoryModal}
                showCloseIcon={false}
                tailwindClassName="w-[37%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => setAddCategoryModal(false)}
            >
                <div className="w-full py-4 px-4 bg-white rounded-md font-rocGroteskMedium">
                    <div className="flex items-center justify-between py-2.5 mb-4 border-b border-slate-200">
                        <span className="text-base">Add new vendor category</span>
                        <i
                            onClick={() => setAddCategoryModal(false)}
                            className="ri-close-fill text-xl cursor-pointer"
                        ></i>
                    </div>
                    <div className="mb-6">
                        <TextInput
                            value={newCategory}
                            name={"newCategory"}
                            type={"text"}
                            onChange={(e) => setNewCategory(e.target.value)}
                            placeholder={"Vendor category"}
                            required={true}
                            containerClassname="mb-4"
                        />
                    </div>
                    <div className="pt-4 px-6 border-t border-slate-200 flex items-center space-x-2">
                        <Button
                            btnText={"Cancel"}
                            type="button"
                            btnClassname="py-3 !bg-n-20"
                            disabled={addingVendorCategory}
                            onClick={() => setAddCategoryModal(false)}
                        />
                        <Button
                            btnText={"Add"}
                            type="button"
                            btnClassname="py-3"
                            disabled={addingVendorCategory}
                            isLoading={addingVendorCategory}
                            onClick={() => {
                                if (newCategory) {
                                    dispatch(
                                        vendorActions.addVendorCategory({ category: newCategory })
                                    );
                                }
                            }}
                        />
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={successModal}
                showCloseIcon={false}
                tailwindClassName="w-[38%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => setSuccessModal(false)}
            >
                <div className="w-full py-8 bg-white flex flex-col text-center items-center rounded-md">
                    <div className="px-8 mb-8">
                        <div className="w-full flex justify-center">
                            <div className="w-[50px] h-[50px] rounded-full bg-carribean-green flex items-center justify-center">
                                <i className="ri-checkbox-circle-fill text-[28px] text-white shadow-[0px_10px_10px_0px_#0000001]"></i>
                            </div>
                        </div>
                        <p className="text-2xl text-gm-50 font-rocGroteskMedium mt-8">Link sent</p>
                        <p className="text-base text-gm-50 font-rocGroteskMedium mt-4">
                            The onboarding link has been dispatched to the supplier to initiate the
                            onboarding process
                        </p>
                    </div>

                    <div className="px-8 flex items-center w-full">
                        <Button
                            btnText={"Done"}
                            type="button"
                            btnClassname="py-3 w-full"
                            onClick={() => {
                                setSuccessModal(false);
                            }}
                        />
                    </div>
                </div>
            </ModalContainer>
        </>
    );
};

export default AddVendor;
