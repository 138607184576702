import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import React from "react";
import { useNavigate } from "react-router-dom";

const Settings = () => {
    const navigate = useNavigate();
    return (
        <div className="bg-white rounded-lg border border-slate-200">
            <div className="flex top-[-2px] rounded-t-lg md:sticky px-8 py-4 z-[50] bg-white  items-center justify-between  border-b border-slate-100">
                <div className="flex space-x-2 items-center cursor-pointer" onClick={() => navigate(-1)}>
                    <i className="ri-arrow-left-line"></i>
                    <p className="text-sm text-gm-50 underline font-rocGroteskMedium">Back</p>
                </div>
                <div className="flex items-center gap-3">
                    <Button
                        btnText="Cancel "
                        btnClassname="!px-4 !bg-[#F4F5F7] !py-2 !w-fit"
                        isLoading={false}
                        onClick={() => {}}
                    />
                    <Button
                        btnText="Save changes"
                        btnClassname="!px-4 !py-2 !w-fit"
                        isLoading={false}
                        onClick={() => {}}
                    />
                </div>
            </div>
            <div className="mt-5 px-8 mb-10">
                <p className="text-base font-rocGroteskMedium text-gm-50">RFQ Settings</p>
                <div className="w-full mt-4">
                    <p className="text-sm font-rocGroteskMedium text-gm-50 mb-2">
                        RFQ Response timeline from suppliers
                    </p>
                    <div className="flex items-center w-[40%] mb-4">
                        <div className="w-[80%]">
                            <TextInput
                                value={""}
                                name={`responseTimeline`}
                                type={"number"}
                                required={true}
                                disabled={false}
                                onChange={(evt) => {}}
                                placeholder={"Enter value"}
                                containerClassname=""
                                inputContainerClassname={" !rounded-tr-none !rounded-br-none "}
                                inputClassName="!w-[70%] "
                            />
                        </div>
                        <Dropdown
                            dropdownTriggerClassName={" "}
                            dropdown={
                                <div className=" border  border-slate-200 text-sm text-gm-50 font-rocGroteskMedium bg-slate-50 rounded-tr rounded-br flex space-x-4 items-center py-[14px] px-2.5">
                                    <p>months</p>
                                    <i className="ri-arrow-down-s-line text-slate-500 "></i>
                                </div>
                            }
                            handleChange={(_, value) => {}}
                            className="!w-[40%]"
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-4  border border-slate-100 rounded !max-h-fit`}
                            name={"faq-dropdown"}
                            dropdownOptions={[
                                { label: "Days", value: "days" },
                                { label: "Weeks", value: "weeks" },
                                { label: "Months", value: "months" },
                            ]}
                        />
                    </div>
                    <div className="w-[40%]">
                        <SelectInput
                            value={""}
                            name="orderType"
                            placeholder={"Default order type"}
                            handleChange={(name, value) => {}}
                            isRequired={true}
                            dropdownOptions={[
                                {
                                    label: (
                                        <>
                                            <i className="ri-user-location-line"></i> Supply
                                        </>
                                    ),
                                    value: "supply",
                                },
                                {
                                    label: (
                                        <>
                                            <i className="ri-truck-line"></i> Logistics
                                        </>
                                    ),
                                    value: "logistics",
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;
