import Button from "components/common/Button";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

type LandingNavProps = {
    bgClass?: string;
};

const LandingNav = ({bgClass}: LandingNavProps) => {
    const [openMenu, setOpenMenu] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const path = location?.pathname;
    const calculatorUrl = (path as string) !== "/" || (path as string) !== "/pricing" ? "/#calculator" : "#calculator";

    useEffect(() => {
        const scrollToCalculator = () => {
            const calculatorDiv = document.getElementById("calculator");

            if (calculatorDiv) {
                calculatorDiv.scrollIntoView({ behavior: "smooth" });
            }
        };

        if (location.hash === "#calculator") {
            setTimeout(scrollToCalculator, 0);

            navigate("/#calculator");
        }
    }, []);

    return (
        <div className="relative">
            <nav
                className={`flex absolute items-center justify-between px-16 max-lg:px-6 transition-all duration-1000 w-screen h-16 bg-transparent ${bgClass} z-[1000]`}
            >
                <Link to={"/"}>
                    <img
                        src={
                            "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702538223/Tradeally_2.0/synthAlly_logo_skcywb.svg"
                        }
                        alt="Logo Icon"
                        className="cursor-pointer"
                        height={24}
                        width={128}
                    />
                </Link>

                <div className="flex items-center gap-[54px]">
                    <div className="flex items-center gap-10 max-lg:hidden">
                        <a
                            href={calculatorUrl}
                            className="text-gm-50 font-rocGroteskMedium text-base"
                        >
                            ROI Calculator
                        </a>
                        <a href={"/pricing"} className="text-gm-50 font-rocGroteskMedium text-base">
                            Pricing
                        </a>
                    </div>

                    <div className="flex items-center gap-3">
                        <Link to={"/book-a-demo"} className="max-lg:hidden">
                            <Button
                                btnText={"Book a demo"}
                                btnClassname={"!shadow-boxShadow-4 !w-fit !bg-white !text-gm-50"}
                            />
                        </Link>
                        <Link to="/login">
                            <Button
                                btnText={"Login"}
                                btnClassname={" !w-fit !px-6 !shadow-boxShadow-4"}
                            />
                        </Link>
                        <i
                            className="ri-menu-line text-2xl cursor-pointer hidden max-lg:block"
                            onClick={() => setOpenMenu(true)}
                        ></i>
                    </div>
                </div>
            </nav>

            <div
                className={`fixed top-0 left-0 right-0 bg-white hidden  max-lg:block pb-10 pt-8 px-5 z-[1999] transition ease-in-out delay-150 duration-300 ${
                    openMenu ? "opacity-100 visible" : "opacity-0 invisible"
                }`}
            >
                <div className="flex justify-end pb-6 ">
                    <i className="ri-close-fill text-2xl" onClick={() => setOpenMenu(false)}></i>
                </div>
                <div className="flex flex-col justify-center items-center gap-10">
                    <a
                        href={calculatorUrl}
                        onClick={() => setOpenMenu(false)}
                        className="text-gm-50 font-rocGroteskMedium text-base"
                    >
                        ROI Calculator
                    </a>
                    <NavLink to={"/pricing"} className="text-gm-50 font-rocGroteskMedium text-base">
                        Pricing
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default LandingNav;
