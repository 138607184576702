import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { getFromStorage, onScroll } from "helpers";
import { debounce, truncate } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authActions, taskActions } from "redux/Ldb/actions";
import TaskDetailsModal from "./TaskDetails";
import { calculateRemainingTime } from "helpers/calculateCommentDate";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";
import CustomMobileTable from "components/common/CustomMobileTable";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import Button from "components/common/Button";
import ModalContainer from "components/common/ModalContainer";
import { accessTypes } from "variables";
import SelectInput from "components/common/InputField/SelectInput";

const TaskLists = () => {
    const limit = 11;
    const containerRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const [isPageLoaded, setIsPageLoaded]= useState<boolean>(false)
    const [taskLimit, setTaskLimit] = useState(limit);
    const [userProfile, setUserProfile] = useState(() => getFromStorage("ally-user"));
    const [taskList, setTaskList] = useState([]);
    const [loadMoreTask, setLoadMoreTask] = useState(false);
    const [filter, setFilter] = useState({
        search: "",
    });
    const [showTaskDetails, setShowTaskDetails] = useState<boolean>(false);
    const [debouncedFilter, setDebouncedFilter] = useState({
        search: "",
    });
    const [taskPagination, setTaskPagination] = useState<{
        current: number | string;
        number_of_pages: number | string;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [inviteMember, setInviteMember] = useState<boolean>(false);
    const [showRolesAndResponsibiltyModal, setShowRolesAndResponsibiltyModal] =
        useState<boolean>(false);
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
    const [roles, setRoles] = useState<{ [key: string]: any }[]>([]);
    const [role, setRole] = useState<string>("");
    const [numDivs, setNumDivs] = useState<number>(1);
    const [debouncedSearch, setDebouncedSearch] = useState<{ search: string }>({
        search: "",
    });
    const [addCustomRole, setAddCustomRole] = useState<boolean>(false);
    const [teamInfo, setTeamInfo] = useState<{ [key: string]: any }[]>([
        { "email-0": "", "role-0": "" },
    ]);
    const [param] = useSearchParams();
    const tab = param.get("show");
    const [taskId, setTaskId] = useState("");
    const dispatch = useAppDispatch();

    const handleCardClick = (e, id) => {
        const excludeElement = document.getElementById("excludeElement");
        if (excludeElement && !excludeElement.contains(e.target)) {
            setTaskId(id);
        }
    };

    useEffect(() => {
        if (Boolean(taskId)) {
            setShowTaskDetails(true);
        }
    }, [taskId]);

    const {
        fetchingTaskList,
        fetchTaskListSuccess,
        updateTaskSuccess,
        fetchActiveTaskTwoSuccess,
        fetchPendingTaskTwoSuccess,
        fetchCompletedTaskTwoSuccess,
        fetchOverdueTaskTwoSuccess,
        fetchMyTaskTwoSuccess,
        fetchAllTaskTwoSuccess,
    } = useAppSelector((state) => state.task);
    const {
        addingTeamMember,
        fetchingRoles,
        fetchedRolesSuccess,
        createRoles,
        createRolesSuccess,
    } = useAppSelector((state) => state.auth);
    const dashboardContent = document.getElementById("scrollableTable");

    const taskTitle = [
        fetchAllTaskTwoSuccess?.totalCount !== 0 && {
            id: 1,
            icon: "ri-list-unordered",
            title: "All tasks",
            name: "all",
            total: fetchAllTaskTwoSuccess?.totalCount,
        },
        fetchActiveTaskTwoSuccess?.totalCount !== 0 && {
            id: 1,
            icon: "ri-flashlight-line",
            title: "Active tasks",
            name: "on-track",
            total: fetchActiveTaskTwoSuccess?.totalCount,
        },
        fetchPendingTaskTwoSuccess?.totalCount !== 0 && {
            id: 1,
            icon: "ri-hourglass-line",
            title: "Pending tasks ",
            name: "pending",
            total: fetchPendingTaskTwoSuccess?.totalCount,
        },
        fetchCompletedTaskTwoSuccess?.totalCount !== 0 && {
            id: 1,
            icon: "ri-checkbox-multiple-line",
            title: "Completed tasks",
            name: "completed",
            total: fetchCompletedTaskTwoSuccess?.totalCount,
        },
        fetchOverdueTaskTwoSuccess?.totalCount !== 0 && {
            id: 1,
            icon: "ri-alarm-warning-line",
            title: "Overdue tasks",
            name: "delayed",
            total: fetchOverdueTaskTwoSuccess?.totalCount,
        },
        fetchMyTaskTwoSuccess?.totalCount !== 0 && {
            id: 1,
            icon: "ri-user-add-line",
            title: "Assigned to me",
            name: "assignee",
            total: fetchMyTaskTwoSuccess?.totalCount,
        },
    ].filter(Boolean);

    const tableHeader = [
        { title: `Tasks (${fetchAllTaskTwoSuccess?.totalCount})`, widthClass: "w-[25%]" },
        { title: `Assignee`, widthClass: "w-[14%]" },
        { title: "Status", widthClass: "w-[10%]" },
        { title: "Date created", widthClass: "w-[10%]" },
        { title: "Due date", widthClass: "w-[10%]" },
        { title: "", widthClass: "w-[4%]" },
    ];

    const handleSearch = (e) => {
        const { name, value } = e.target;

        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleDebouncedChange = debounce((e) => {
        const { name, value } = e.target;

        setDebouncedFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, 1500);
    useEffect(() => {
        if (debouncedFilter.search !== "") {
            dispatch(taskActions.fetchTaskList(taskLimit, "", "", debouncedFilter.search));
        }
    }, [dispatch, taskLimit, debouncedFilter.search]);

    useEffect(() => {
        dispatch(taskActions.fetchPendingTaskList(taskLimit, "pending"));
        dispatch(taskActions.fetchOverdueTaskList(taskLimit, "delayed"));
        dispatch(taskActions.fetchCompletedTaskList(taskLimit, "completed"));
        dispatch(taskActions.fetchActiveTaskList(taskLimit, "on-track"));
        dispatch(taskActions.fetchMyTaskList(taskLimit, "", "assignee"));
        dispatch(taskActions.fetchAllTaskList(taskLimit));
    }, [dispatch, taskLimit]);

    useEffect(() => {
        dispatch(taskActions.fetchPendingTaskTwoList(taskLimit, "pending"));
        dispatch(taskActions.fetchOverdueTaskTwoList(taskLimit, "delayed"));
        dispatch(taskActions.fetchCompletedTaskTwoList(taskLimit, "completed"));
        dispatch(taskActions.fetchActiveTaskTwoList(taskLimit, "on-track"));
        dispatch(taskActions.fetchMyTaskTwoList(taskLimit, "", "assignee"));
        dispatch(taskActions.fetchAllTaskTwoList(taskLimit));
    }, [dispatch]);

    useEffect(() => {
        if (tab?.toLowerCase() === "assignee") {
            dispatch(taskActions.fetchTaskList(taskLimit, "", tab, debouncedFilter.search));
        } else if (tab?.toLowerCase() === "all") {
            dispatch(taskActions.fetchTaskList(taskLimit, "", "", debouncedFilter.search));
        } else {
            dispatch(taskActions.fetchTaskList(taskLimit, tab, "", debouncedFilter.search));
        }
    }, [dispatch, taskLimit, tab, debouncedFilter.search]);

    useEffect(() => {
        if (Boolean(fetchTaskListSuccess)) {
            setTaskList(fetchTaskListSuccess?.tasks);
            setTaskPagination({
                current: fetchTaskListSuccess?.pagination?.current,
                number_of_pages: fetchTaskListSuccess?.pagination?.number_of_pages,
            });
            setIsPageLoaded(true)
        }
    }, [fetchTaskListSuccess]);

    const onTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                taskPagination?.current as number,
                taskPagination?.number_of_pages as number,
                () => {
                    setTaskLimit(() => taskLimit + limit);
                    setLoadMoreTask(true);
                }
            ),
        [dashboardContent, taskPagination]
    );

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onTableScroll);
        };
    }, [dashboardContent, onTableScroll]);

    const handleStatusChange = (id, status) => {
        const updatedTaskList = taskList.map((task) => {
            if (task._id === id) {
                return {
                    ...task,
                    status,
                };
            }
            return task;
        });
        setTaskList(updatedTaskList);

        const body = {
            status,
        };
        if (id) {
            dispatch(taskActions.updateTask(body, id));
        }
    };

    useEffect(() => {
        if (Boolean(updateTaskSuccess)) {
            if (tab?.toLowerCase() === "assignee") {
                dispatch(taskActions.fetchTaskList(taskLimit, "", tab));
            } else if (tab?.toLowerCase() === "all") {
                dispatch(taskActions.fetchTaskList(taskLimit, "", ""));
            } else {
                dispatch(taskActions.fetchTaskList(taskLimit, tab));
                dispatch(taskActions.fetchPendingTaskList(taskLimit, "pending"));
                dispatch(taskActions.fetchOverdueTaskList(taskLimit, "delayed"));
                dispatch(taskActions.fetchCompletedTaskList(taskLimit, "completed"));
                dispatch(taskActions.fetchActiveTaskList(taskLimit, "on-track"));
                dispatch(taskActions.fetchPendingTaskTwoList(taskLimit, "pending"));
                dispatch(taskActions.fetchOverdueTaskTwoList(taskLimit, "delayed"));
                dispatch(taskActions.fetchCompletedTaskTwoList(taskLimit, "completed"));
                dispatch(taskActions.fetchActiveTaskTwoList(taskLimit, "on-track"));
                dispatch(taskActions.fetchMyTaskTwoList(taskLimit, "", "assignee"));
                dispatch(taskActions.fetchAllTaskTwoList(taskLimit));
            }
            dispatch(taskActions.resetUpdateTask());
        }
    }, [dispatch, updateTaskSuccess, taskLimit, tab]);

    const tableBody = taskList?.map((task) => [
        {
            content: (
                <div className="flex pr-3 items-center py-[18px] space-x-1.5" key={task?._id}>
                    <div className="h-5 w-5 flex justify-center items-center rounded-[4px] bg-n-20">
                        <i className="ri-flashlight-fill text-[13px] text-p-50"></i>
                    </div>
                    <p
                        className={`text-[14px] text-slate-900 font-rocGroteskMedium whitespace-nowrap`}
                    >
                        {truncate(camelCaseToRegularCase(task?.name), {
                            length: 40,
                        })}
                    </p>
                </div>
            ),
            cellClickAction: (e) => {
                handleCardClick(e, task?._id);
            },
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 font-rocGroteskMedium text-sm text-slate-900 `}
                >
                    {task?.action?.assignee ? (
                        <div
                            className="flex items-center space-x-2"
                            key={task?.action?.assignee[0]?._id}
                        >
                            <VendorAvatar
                                name={task?.action?.assignee[0]?.email}
                                size={30}
                                imageSrc={task?.action?.assignee[0]?.avatar}
                            />
                            <span>{task?.action?.assignee[0]?.fullName}</span>
                        </div>
                    ) : (
                        <div className="w-5 h-5 p-2 rounded bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100% flex items-center justify-center">
                            <i className="ri-robot-line text-white"></i>
                        </div>
                    )}
                </div>
            ),
        },
        {
            content: (
                <div
                    id="excludeElement"
                    className={`py-3 pr-3  border-slate-100 text-sm text-slate-900 font-rocGroteskMedium `}
                    onClick={(e) => e.stopPropagation()}
                >
                    {task?.action?.assignee ? (
                        <>
                            {task?.status?.toLowerCase() === "completed" ? (
                                <div className="bg-g-25  px-[15px] text-slate-900 font-rocGroteskMedium py-[5px] text-xs rounded-[20px] text-center flex items-center">
                                    Completed
                                </div>
                            ) : (
                                <Dropdown
                                    dropdownTriggerClassName={" "}
                                    disabled={task?.action?.assignee ? false : true}
                                    dropdown={
                                        <div className="flex space-x-4">
                                            <div
                                                className={`${
                                                    task?.status?.toLowerCase() === "delayed" &&
                                                    "bg-r-25"
                                                } ${
                                                    task?.status?.toLowerCase() === "completed" &&
                                                    "bg-g-25"
                                                } 
                               ${task?.status?.toLowerCase() === "pending" && "bg-light-yellow"} 
                               ${
                                   task?.status?.toLowerCase() === "on-track" && "bg-p-25"
                               }  px-[15px] py-[4px] text-slate-900 font-rocGroteskMedium text-xs rounded-[20px] text-center flex items-center`}
                                            >
                                                {task?.status?.toLowerCase() === "delayed"
                                                    ? "Overdue"
                                                    : task?.status?.toLowerCase() === "on-track"
                                                    ? "Active"
                                                    : task?.status?.charAt(0).toUpperCase() +
                                                      task?.status?.slice(1)}
                                            </div>
                                            <i className="ri-arrow-down-s-line text-lg text-gm-40"></i>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 -left-[10px] !mt-3  border border-slate-100 w-[159px] rounded !max-h-fit
                       `}
                                    name={"faq-dropdown"}
                                    dropdownOptions={[
                                        {
                                            label: (
                                                <div className="bg-g-25 px-[15px] text-slate-900 font-rocGroteskMedium py-[4px] text-xs rounded-[20px] text-center flex items-center">
                                                    Completed
                                                </div>
                                            ),

                                            value: "completed",
                                            action: () =>
                                                handleStatusChange(task?._id, "completed"),
                                        },
                                        {
                                            label: (
                                                <div className="bg-r-25 px-[15px] text-slate-900 font-rocGroteskMedium py-[4px] text-xs rounded-[20px] text-center flex items-center">
                                                    Overdue
                                                </div>
                                            ),
                                            value: "delayed",
                                            action: () => handleStatusChange(task?._id, "delayed"),
                                        },
                                        {
                                            label: (
                                                <div className="bg-p-25 px-[15px] text-slate-900 font-rocGroteskMedium py-[4px] text-xs rounded-[20px] text-center flex items-center">
                                                    Active
                                                </div>
                                            ),
                                            value: "on-track",
                                            action: () => handleStatusChange(task?._id, "on-track"),
                                        },
                                        {
                                            label: (
                                                <div className="bg-light-yellow px-[15px] text-slate-900 font-rocGroteskMedium py-[4px] text-xs rounded-[20px] text-center flex items-center">
                                                    Pending
                                                </div>
                                            ),
                                            value: "pending",
                                            action: () => handleStatusChange(task?._id, "pending"),
                                        },
                                    ]}
                                />
                            )}
                        </>
                    ) : (
                        <div className="bg-g-25  px-[15px] text-slate-900 font-rocGroteskMedium py-[5px] text-xs rounded-[20px] text-center flex items-center">
                            {task?.status?.toLowerCase() === "delayed"
                                ? "Overdue"
                                : task?.status?.toLowerCase() === "on-track"
                                ? "Active"
                                : task?.status?.charAt(0).toUpperCase() + task?.status?.slice(1)}
                        </div>
                    )}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm text-slate-900 font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-3">
                        <span>{moment(task?.createdAt).format("MMM. Do, YYYY")}</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm text-slate-900 font-rocGroteskMedium `}
                >
                    <div className="flex flex-col items-center">
                        <span>{moment(task?.endDate).format("MMM. Do, YYYY")}</span>
                        {task?.status?.toLowerCase() === "delayed" && (
                            <span className="text-r-50 text-[10px] font-rocGroteskMedium">
                                {calculateRemainingTime(task?.endDate)}
                            </span>
                        )}
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-[18px] flex justify-center  items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    {/* <Dropdown
                        dropdownTriggerClassName={" "}
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-700"></i>}
                        dropdownClassname={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-4 -left-[150px]  border border-slate-100 w-[159px] rounded !max-h-fit
                            `}
                        dropdownItemsClasses={`last:text-r-50 last:hover:bg-r-25`}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "View runs",
                                value: "view runs",
                                action: () => {
                                    navigate(`/dashboard/workflow/run/`);
                                },
                            },
                            {
                                label: "View activity log",
                                value: "view activity log",
                                action: () => {},
                            },
                            {
                                label: "Edit workflow name",
                                value: "edit workflow name",
                                action: () => {},
                            },
                        ]}
                    /> */}
                </div>
            ),
        },
    ]);

    const mobileTableBody = taskList?.map((task, idx) => {
        return {
            cellClickAction: (e) => {
                handleCardClick(e, task?._id);
            },
            topString: camelCaseToRegularCase(task?.name) + task?._id,
            topContent: (
                <div className="flex items-center gap-3">
                    <div className="h-5 w-5 flex justify-center items-center rounded-[4px] bg-n-20">
                        <i className="ri-flashlight-fill text-[13px] text-p-50"></i>
                    </div>

                    <div className="">
                        <p className="text-sm font-rocGroteskMedium mb-1.5">
                            {truncate(camelCaseToRegularCase(task?.name), { length: 30 })}
                        </p>
                        <p
                            className={`text-sm font-rocGroteskMedium ${
                                task?.status?.toLowerCase() === "delayed" && "text-r-50"
                            } ${task?.status?.toLowerCase() === "completed" && "text-g-50"} 
                                    ${
                                        task?.status?.toLowerCase() === "pending" &&
                                        "text-[#FF8A00]"
                                    } 
                                    ${task?.status?.toLowerCase() === "on-track" && "text-p-40"}`}
                        >
                            {task?.status?.toLowerCase() === "delayed"
                                ? "Overdue"
                                : task?.status?.toLowerCase() === "on-track"
                                ? "Active"
                                : task?.status?.charAt(0).toUpperCase() + task?.status?.slice(1)}
                        </p>
                    </div>
                </div>
            ),

            rightIcon: <i className="ri-arrow-right-s-line text-2xl"></i>,
        };
    });

    const addNewInputField = () => {
        const newInputField = {
            email: "",
            role: "",
            permissions: "",
        };
        setTeamInfo([...teamInfo, newInputField]);
    };

    const handleAddRole = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const permission = selectedPermissions;
        if (role === "") {
            toast.custom((t) => (
                <CustomToast t={t} message={"Role name is required"} type="error" />
            ));
        }
        if (role !== "") {
            const teamMember = {
                role,
                ...(permission.length > 0 && { permission }),
            };
            dispatch(authActions.createRoles(teamMember));
        }
    };

    useEffect(() => {
        if (Boolean(createRolesSuccess)) {
            setSelectedPermissions([]);
            setAddCustomRole(false);
            dispatch(authActions.resetCreateRoles());
            dispatch(authActions.getRoles(20));
        }
    }, [createRolesSuccess, dispatch]);

    useEffect(() => {
        if (Boolean(fetchedRolesSuccess)) {
            setRoles(fetchedRolesSuccess?.rolesList);
        }
    }, [fetchedRolesSuccess]);

    const handlePermissionChange = (permission: string) => {
        const isSelected = selectedPermissions.includes(permission);

        if (isSelected) {
            const updatedPermissions = selectedPermissions.filter((item) => item !== permission);
            setSelectedPermissions(updatedPermissions);
        } else {
            setSelectedPermissions([...selectedPermissions, permission]);
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            const lastItem = (container as any).lastChild;
            (container as any).scrollTop = lastItem.offsetTop;
        }
    }, [teamInfo]);

    const onDelete = (index: number) => {
        const updatedTeamInfo = [...teamInfo];
        updatedTeamInfo.splice(index, 1);
        setTeamInfo(updatedTeamInfo);
    };

    const handleRoleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: { [key: string]: any }[] = [];
        const filteredTeamInfo = teamInfo.filter(
            (member, index) => member[`email-${index}`] && member[`role-${index}`]
        );

        filteredTeamInfo.map((member, index) => {
            const teamMember = {
                email: member[`email-${index}`],
                role: member[`role-${index}`],
            };

            body.push(teamMember);
        });

        const teamsBody = { teamMembers: body };

        dispatch(authActions.addTeamMember(teamsBody, userProfile?.token));
    };

    const handleRoleChange = (
        e: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } },
        index: number
    ) => {
        const { name, value } = e.target;
        const updatedTeamInfo = [...teamInfo];
        updatedTeamInfo[index] = {
            ...updatedTeamInfo[index],
            [name]: value,
        };
        setTeamInfo(updatedTeamInfo);
    };

    useEffect(() => {
        if (inviteMember) {
            setShowTaskDetails(false);
        }
    }, [inviteMember]);

    console.log("inviteMember", inviteMember);
    console.log("showTaskDetails", showTaskDetails);
    return (
        <PageFrame
            containerClassName={"!h-[calc(100vh-108px)]"}
            isLoading={!isPageLoaded && taskList?.length === 0}
        >
            <div className="mt-5 ">
                <div className="flex space-x-[14px] max-lg:flex-nowrap max-lg:w-full max-lg:overflow-x-auto">
                    {taskTitle?.map((task, idx) => (
                        <div
                            key={idx}
                            className={`flex cursor-pointer max-lg:flex-shrink-0 font-rocGroteskMedium items-center shadow-boxShadow-2 space-x-2 w-fit border ${
                                tab === task?.name?.toLowerCase()
                                    ? "border-gm-50 bg-gm-50 text-white"
                                    : "border-n-20 bg-neutral-50 text-gm-40"
                            } px-4 py-1.5 rounded-[30px]`}
                            onClick={() => {
                                task?.name === ""
                                    ? navigate(
                                          `/dashboard/task/1?show=${task?.name?.toLowerCase()}`
                                      )
                                    : navigate(
                                          `/dashboard/task/1?show=${task?.name?.toLowerCase()}`
                                      );
                            }}
                        >
                            <i className={`${task?.icon} text-base`}></i>
                            <p className="text-[13px] ">
                                {task?.title} ({task?.total})
                            </p>
                        </div>
                    ))}
                </div>
                <div className="flex mt-9 items-center gap-3 max-sm:w-full">
                    <div className="max-sm:w-full">
                        <TextInput
                            name={"search"}
                            value={filter.search}
                            type={"text"}
                            inputPlaceholder={"Search"}
                            inputClassName={"!h-[40px] pl-[0px] !bg-white text-sm !mb-0"}
                            onChange={handleSearch}
                            onInput={handleDebouncedChange}
                            leftIcon={
                                <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                            }
                            inputContainerClassname={
                                "!rounded-[4px] !w-[300px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                            }
                        />
                    </div>

                    {/* <div>
                        <Dropdown
                            value={""}
                            dropdown={
                                <div className="flex items-center cursor-pointer gap-2 py-1.5 px-3 bg-white rounded shadow-boxShadow-2 border border-n-20">
                                    <i className="ri-filter-3-fill"></i>
                                    <p className="text-[13px] font-rocGroteskMedium">Filter</p>
                                </div>
                            }
                            dropdownContainerClasses={
                                " left-[-234px] !py-0 top-[49px] shadow-dropdownShadow rounded !border-0"
                            }
                            dropdownClassName={"!max-h-fit"}
                            name={"quickAction"}
                        />
                    </div> */}
                </div>
                <div
                    className="mt-8 overflow-y-auto  pb-40 max-h-[calc(100vh-220px)]"
                    id="scrollableTable"
                >
                    {taskList?.length > 0 ? (
                        <div className="">
                            <div className="max-lg:hidden">
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isScrollable={false}
                                    isCellBordered={false}
                                    isCellSelectable={true}
                                    isAllSelectable={true}
                                    bodyItemClass="hover:border-slate-100 hover:bg-slate-50 "
                                    headerContainerClass="!bg-slate-50 !border-slate-100 "
                                    tableClass="mb-12"
                                    headerItemClass="font-rocGroteskMedium "
                                />
                            </div>
                            <div className="hidden max-lg:block">
                                <CustomMobileTable data={mobileTableBody} />
                            </div>
                            {loadMoreTask && fetchingTaskList && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {taskPagination?.current === taskPagination?.number_of_pages &&
                                !fetchingTaskList && (
                                    <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                        End of list
                                    </div>
                                )}
                        </div>
                    ) : (
                        <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                            <div className="space-y-2  text-center">
                                <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                                <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                    {filter.search ? "No task found" : "No task yet"}
                                </p>
                                <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                    {filter.search
                                        ? "No task found for this search query"
                                        : "Recent task  will be displayed here"}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {showTaskDetails && (
                <TaskDetailsModal
                    inviteMember={inviteMember}
                    id={taskId}
                    setInviteMember={setInviteMember}
                    openModal={showTaskDetails}
                    closeModal={() => {
                        setShowTaskDetails(false);
                        setTaskId("");
                    }}
                />
            )}
            <ModalContainer
                open={inviteMember}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[553px]"
                closeModal={() => {
                    setInviteMember(false);
                    setShowTaskDetails(false);
                    setTeamInfo([{ "email-0": "", "role-0": "" }]);
                    setNumDivs(1);
                }}
            >
                <div className="bg-white px-2 pt-3 pb-10 shadow-cardShadow relative rounded-[20px]">
                    <div
                        onClick={() => {
                            setInviteMember(false);
                            setShowTaskDetails(false);
                            setTeamInfo([{ "email-0": "", "role-0": "" }]);
                            setNumDivs(1);
                        }}
                        className="cursor-pointer px-4 flex justify-end "
                    >
                        <i className="ri-close-fill text-gm-45 text-[24px]"></i>
                    </div>
                    <div className="w-full flex justify-center">
                        <div className="lg:w-[500px] relative w-[380px]">
                            <div className="w-full  pb-3   flex text-center items-center justify-center">
                                <div className="flex text-center flex-col items-center justify-center">
                                    <p className="text-xl mb-1.5 font-rocGroteskMedium text-g-75">
                                        Invite a team member
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-gm-40">
                                        Add team members to your workspace
                                    </p>
                                    <div
                                        className="cursor-pointer  w-fit my-2"
                                        onClick={() => setShowRolesAndResponsibiltyModal(true)}
                                    >
                                        <p className="text-b-55  text-xs   font-rocGroteskMedium  underline-offset-1 underline">
                                            View roles and responsibilities
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={handleRoleSubmit} className="w-full  ">
                                <div
                                    className={`flex  flex-col max-h-[225px]  ${
                                        teamInfo?.length > 3 && " overflow-y-scroll "
                                    }  gap-4 w-full`}
                                    ref={containerRef}
                                >
                                    {teamInfo.map((inputData, index) => (
                                        <div
                                            key={index}
                                            className="flex   w-full items-center group"
                                        >
                                            <div className="w-[80%]">
                                                <TextInput
                                                    value={inputData[`email-${index}`]}
                                                    name={`email-${index}`}
                                                    type="email"
                                                    onChange={(e) => handleRoleChange(e, index)}
                                                    placeholder="Enter email address"
                                                    required={true}
                                                    inputContainerClassname="!rounded-tr-none rounded-br-none"
                                                />
                                            </div>
                                            <div className="  right-0  bg-white">
                                                <SelectInput
                                                    value={inputData[`role-${index}`] || ""}
                                                    name={`role-${index}`}
                                                    placeholder="Assign role"
                                                    handleChange={(name, value) =>
                                                        handleRoleChange(
                                                            {
                                                                target: { name, value },
                                                            },
                                                            index
                                                        )
                                                    }
                                                    handleInputChange={debounce((evt) => {
                                                        setDebouncedSearch({
                                                            search: evt.target.value,
                                                        });
                                                    }, 800)}
                                                    searchLoading={fetchingRoles}
                                                    className={`!rounded-tl-none !bg-[#FCFCFC] rounded-bl-none`}
                                                    isRequired={true}
                                                    disabled={inputData[`email-${index}`] === ""}
                                                    dropdownClassName="!py-0 "
                                                    customDropdownContainer={
                                                        <div className="w-full  py-1.5 flex flex-col gap-3.5">
                                                            <div
                                                                onClick={() =>
                                                                    setAddCustomRole(true)
                                                                }
                                                                className="flex items-center gap-1 px-3 font-rocGroteskMedium cursor-pointer w-fit"
                                                            >
                                                                <i className="ri-add-fill before:content-['\ea12'] text-p-55 text-xl mt-[-2px]"></i>
                                                                <span className="text-sm text-p-50">
                                                                    Add custom role
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                    dropdownOptions={roles?.map((item) => ({
                                                        label: item?.label,
                                                        value: item?.role,
                                                    }))}
                                                />
                                            </div>
                                            {index !== 0 && (
                                                <div className="pl-2 group-hover:block hidden">
                                                    <i
                                                        className="ri-delete-bin-line text-[20px] text-slate-400 cursor-pointer"
                                                        onClick={() => onDelete(index)}
                                                    ></i>
                                                </div>
                                            )}
                                            <ModalContainer
                                                open={addCustomRole}
                                                showCloseIcon={false}
                                                modalClassName={"py-10 px-10"}
                                                tailwindClassName="w-[513px] xl:w-[600px]"
                                                closeModal={() => {
                                                    setAddCustomRole(false);
                                                }}
                                            >
                                                <div className="bg-white rounded-lg shadow-modalShadow relative pt-5 pb-10">
                                                    <div className="flex justify-end px-10">
                                                        <span
                                                            onClick={() => setAddCustomRole(false)}
                                                            className="material-icons text-gm-50 text-xl cursor-pointer"
                                                        >
                                                            close
                                                        </span>
                                                    </div>
                                                    <div className="px-10 pt-3">
                                                        <div className="flex flex-col items-center text-center mb-[45px]">
                                                            <p className="text-2xl mb-2.5 font-rocGroteskMedium text-g-75">
                                                                Create a custom role
                                                            </p>
                                                            <p className="text-sm font-rocGroteskMedium text-gm-40">
                                                                Define what the new role’s
                                                                responsibilities are
                                                            </p>
                                                        </div>
                                                        <form className="w-full">
                                                            <TextInput
                                                                name={`role`}
                                                                type={"text"}
                                                                placeholder={"Enter role name"}
                                                                value={role}
                                                                onChange={(e) =>
                                                                    setRole(e.target.value)
                                                                }
                                                                required={true}
                                                                containerClassname={"mb-6 "}
                                                            />
                                                            <div>
                                                                <p className="text-base font-rocGroteskMedium mb-6">
                                                                    Select access levels
                                                                </p>
                                                                <div className="flex flex-wrap gap-4 relative overflow-auto">
                                                                    {accessTypes.map(
                                                                        (access, idx) => (
                                                                            <div
                                                                                key={idx}
                                                                                onClick={() =>
                                                                                    handlePermissionChange(
                                                                                        access.value
                                                                                    )
                                                                                }
                                                                                className={`px-2.5 w-fit h-[30px] ${
                                                                                    selectedPermissions.includes(
                                                                                        access.value
                                                                                    )
                                                                                        ? "!bg-carribean-green !text-white !border-carribean-green"
                                                                                        : ""
                                                                                } border flex items-center gap-2 rounded-[8px] font-rocGroteskMedium bg-white hover:!bg-g-65 hover:text-white hover:!border-g-65 border-slate-300 text-[13px] cursor-pointer`}
                                                                            >
                                                                                {selectedPermissions.includes(
                                                                                    access.value
                                                                                ) ? (
                                                                                    <i className="ri-checkbox-circle-fill text-base"></i>
                                                                                ) : (
                                                                                    <i className="ri-add-fill text-base"></i>
                                                                                )}
                                                                                {access.label}
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <Button
                                                                onClick={handleAddRole}
                                                                btnText={"Add role"}
                                                                isLoading={createRoles}
                                                                btnClassname={"my-10"}
                                                            />
                                                            <p
                                                                onClick={() =>
                                                                    setAddCustomRole(false)
                                                                }
                                                                className="text-sm font-rocGroteskMedium text-center cursor-pointer"
                                                            >
                                                                Close
                                                            </p>
                                                        </form>
                                                    </div>
                                                </div>
                                            </ModalContainer>
                                        </div>
                                    ))}
                                </div>
                                <div
                                    className="cursor-pointer  flex space-x-1 items-center w-fit my-3"
                                    onClick={addNewInputField}
                                >
                                    <i className="ri-add-fill text-b-55  text-[24px]"></i>
                                    <p className="text-b-55  text-xs font-rocGroteskMedium  underline-offset-1 underline">
                                        Invite more team members
                                    </p>
                                </div>
                                <div className="w-full mt-10">
                                    <Button
                                        btnText={"Invite member"}
                                        isLoading={addingTeamMember}
                                        type={"submit"}
                                    />
                                    <Button
                                        btnText={"Close"}
                                        onClick={() => {
                                            setInviteMember(false);
                                            setTeamInfo([{ "email-0": "", "role-0": "" }]);
                                            setNumDivs(1);
                                        }}
                                        disabled={addingTeamMember}
                                        btnClassname={"!bg-transparent !text-gm-75 mt-2"}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default TaskLists;
