import Button from "components/common/Button";
import CustomToast from "components/common/CustomToast";
import WorkflowBoard from "components/common/WorkflowTimelineEditor/WorkflowBoard";
import PageFrame from "components/layout/PageFrame";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import { workFlowActions } from "redux/Ldb/actions";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { WorkflowTemplates } from "variables/workflowTemplates";
import { copilotActions } from "variables/workflow";
import ModalContainer from "components/common/ModalContainer";
import Loader from "components/common/Loader";
import useGetUserProfile from "hooks/useGetUserProfile";
import { getEditPermissionList } from "helpers/getEditPermissionList";

const WorkflowGraph = () => {
    // const [profile, setProfile] = useState({});
    const [profile] = useGetUserProfile();
    const [workflowData, setWorkflowData] = useState<{ [key: string]: any }>({});
    const [customActions, setACustomActions] = useState([]);
    const [customEvents, setCustomEvents] = useState([]);
    const [isWorkflowActive, setIsWorkflowActive] = useState(false);
    const [workflowCat, setWorkflowCat] = useState("");
    const [playable, setPlayable] = useState(false);
    const [userInteract, setUserInteract] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { workflowId } = useParams();
    const [params] = useSearchParams();
    const createdThrough = params.get("createdBy");
    const templateName = params.get("templateName");
    const isRun = params.get("run");
    const { fetchUserProfileSuccess } = useAppSelector((state) => state.auth);
    const {
        fetchingSingleWorkflow,
        fetchedSingleWorkflowSuccess,
        playOrPauseWorkFlow,
        playOrPauseWorkFlowSuccess,
        togglingPausePlayWorkflowRun,
        togglePausePlayWorkflowRunSuccess,
        updateEventsAndActionsSuccess,
        deleteActionSuccess,
        fetchingEventAndActionOptionList,
        fetchedEventAndActionOptionListSuccess,
        fetchingSingleWorkflowRun,
        fetchedSingleWorkflowRunSuccess,
        retryActionSuccess,
        markActionAsDoneSuccess,
        creatingMultipleActions,
        createMultipleActionsSuccess,
        creatingWorkFlow,
        createWorkFlowSuccess,
    } = useAppSelector((state) => state.workFlow);

    const selectedTemplate = WorkflowTemplates?.find((template) => {
        return template?.value === templateName;
    });

    const templateActions = selectedTemplate?.data?.actions?.map((action, idx) => {
        return {
            _id: String(idx),
            actionCategory: action?.actionCat,
            actionType: selectedTemplate?.data?.workFlowCategory,
            assignee: [profile?._id],
            assigneeId: [profile?._id],
            position: {
                x: window.innerWidth / 4 + 505 / 2 + (505 - 419) / 2,
                y: 200 * (idx + 1),
            },
            nextConnection: {
                connectionId: String(idx + 1),
                connectionType: "WorkFlowAction",
                connection: String(idx + 1),
            },
            name: action?.actionType,
            currentStatus: "on-track",
            duration: action?.duration,
            assigneeType: "LDB",
        };
    });

    const templateData = {
        _id: "vt-1",
        name: selectedTemplate?.data?.firstEventName,
        position: {
            x: window.innerWidth / 4 + 505 / 2,
            y: 20,
        },
        nextConnection: {
            connectionId: "0",
            connectionType: "WorkFlowAction",
            connection: "0",
        },
        workFlowCategory: selectedTemplate?.data?.workFlowCategory,
        workFlowActions: templateActions,
        triggerEventSource: selectedTemplate?.data?.triggerEventSource,
        templateName: selectedTemplate?.value,
    };

    const togglePauseAndPlay = () => {
        if (playable) {
            const body = {
                active: !isWorkflowActive,
            };
            dispatch(workFlowActions.pauseOrPlayWorkFlow(workflowId as string, body));
        } else {
            toast.custom((t) => (
                <CustomToast t={t} message={"Please link all actions and events"} type={"error"} />
            ));
        }
    };

    const togglePauseAndPlayWorkflowRun = () => {
        if (workflowData?.currentStatus?.toLowerCase() !== "completed") {
            const body = {
                active: !isWorkflowActive,
            };

            dispatch(workFlowActions.togglePausePlayWorkflowRun(body, workflowId as string));
        }
    };

    const handleCreateWorkflow = () => {
        const body = {
            workFlowType: "manual",
            workFlowCategory: templateData?.workFlowCategory,
            name:
                templateName?.replace(/([A-Z])/g, " $1") +
                "-" +
                moment().format("DD-MMM-YYYY hh:mm A"),
            triggerEventSource: templateData?.triggerEventSource,
            firstEventName: templateData?.name,
            position: {
                x: window.innerWidth / 4 + 505 / 2,
                y: 20,
            },
            template: true,
            templateName: selectedTemplate?.value,
        };

        dispatch(workFlowActions.createWorkflow(body));
    };

    useEffect(() => {
        if (!playable && userInteract && isWorkflowActive) {
            const body = {
                active: false,
            };
            dispatch(workFlowActions.pauseOrPlayWorkFlow(workflowId as string, body));
        }
    }, [playable, userInteract, dispatch, isWorkflowActive, workflowId]);

    useEffect(() => {
        if (Boolean(workflowCat)) {
            dispatch(workFlowActions.getEventAndActionsOptionList(workflowCat));
        }
    }, [dispatch, updateEventsAndActionsSuccess, workflowCat, createMultipleActionsSuccess]);

    useEffect(() => {
        if (Boolean(fetchedEventAndActionOptionListSuccess)) {
            setACustomActions(fetchedEventAndActionOptionListSuccess?.workFlow?.actions);
            setCustomEvents(fetchedEventAndActionOptionListSuccess?.workFlow?.events);
        }
    }, [fetchedEventAndActionOptionListSuccess]);

    // useEffect(() => {
    //     if (Boolean(fetchUserProfileSuccess)) {
    //         setProfile(fetchUserProfileSuccess);
    //     }
    // }, [fetchUserProfileSuccess]);

    useEffect(() => {
        if (Boolean(retryActionSuccess)) {
            const workflowDataCopy = { ...workflowData?.firstEvent };
            const workflowActionsDataCopy = [...workflowDataCopy?.workFlowActions];
            const findIndex = workflowActionsDataCopy?.findIndex(
                (data) => data?._id === retryActionSuccess?.actionUpdated?.action?._id
            );

            if (findIndex !== -1) {
                workflowActionsDataCopy[findIndex] = retryActionSuccess?.actionUpdated?.action;
                workflowDataCopy.workFlowActions = workflowActionsDataCopy;
            }

            setWorkflowData({ ...workflowData, firstEvent: workflowDataCopy });
        }
    }, [retryActionSuccess]);

    useEffect(() => {
        if (Boolean(markActionAsDoneSuccess)) {
            const workflowDataCopy = { ...workflowData?.firstEvent };
            const workflowActionsDataCopy = [...workflowDataCopy?.workFlowActions];
            const findIndex = workflowActionsDataCopy?.findIndex(
                (data) => data?._id === markActionAsDoneSuccess?.actionUpdated?._id
            );
            if (findIndex !== -1) {
                workflowActionsDataCopy[findIndex] = markActionAsDoneSuccess?.actionUpdated;
                workflowDataCopy.workFlowActions = workflowActionsDataCopy;
            }

            setWorkflowData({ ...workflowData, firstEvent: workflowDataCopy });
        }
    }, [markActionAsDoneSuccess]);

    useEffect(() => {
        if (Boolean(workflowId)) {
            if (isRun) {
                return dispatch(workFlowActions.getSingleWorkflowRun(workflowId as string));
            }
            dispatch(workFlowActions.getSingleWorkflow(workflowId as string));
        }
    }, [
        workflowId,
        dispatch,
        isRun,
        updateEventsAndActionsSuccess,
        // deleteActionSuccess,
        createdThrough,
    ]);

    useEffect(() => {
        if (Boolean(fetchedSingleWorkflowSuccess)) {
            setWorkflowData(fetchedSingleWorkflowSuccess?.workFlow);
            setIsWorkflowActive(fetchedSingleWorkflowSuccess?.workFlow?.active);
            setWorkflowCat(fetchedSingleWorkflowSuccess?.workFlow?.workFlowCategory);
        }
    }, [fetchedSingleWorkflowSuccess]);

    useEffect(() => {
        if (Boolean(fetchedSingleWorkflowRunSuccess)) {
            setWorkflowData(fetchedSingleWorkflowRunSuccess?.workFlow);
            setIsWorkflowActive(fetchedSingleWorkflowRunSuccess?.workFlow?.active);
        }
    }, [fetchedSingleWorkflowRunSuccess]);

    useEffect(() => {
        if (Boolean(playOrPauseWorkFlowSuccess)) {
            toast.custom((t) => (
                <CustomToast
                    t={t}
                    message={isWorkflowActive ? "Workflow inactive" : "Workflow active"}
                    type="success"
                />
            ));
            setIsWorkflowActive((prev) => {
                return !prev;
            });

            dispatch(workFlowActions.resetPauseOrPlayWorkFlowSuccess());
        }
    }, [playOrPauseWorkFlowSuccess, dispatch, isWorkflowActive]);

    useEffect(() => {
        if (Boolean(togglePausePlayWorkflowRunSuccess)) {
            toast.custom((t) => (
                <CustomToast
                    t={t}
                    type="success"
                    message={isWorkflowActive ? "Run inactive" : "Run active"}
                />
            ));
            setIsWorkflowActive((prev) => {
                return !prev;
            });

            dispatch(workFlowActions.resetTogglePausePlayWorkflowRunSuccess());
        }
    }, [togglePausePlayWorkflowRunSuccess, dispatch, isWorkflowActive]);

    useEffect(() => {
        if (Boolean(createWorkFlowSuccess)) {
            const generatedActions: { [key: string]: any }[] = templateData?.workFlowActions;
            const body = generatedActions?.map((action, idx) => {
                const isCopilotAction = copilotActions?.find((act) => act?.value === action?.name);

                const obj: { [key: string]: any } = {
                    name: action?.name,
                    workFlowEventId: createWorkFlowSuccess?.workFlow?.firstEventId,
                    position: {
                        x: window.innerWidth / 4 + 505 / 2 + (505 - 419) / 2,
                        y: 200 * (idx + 1),
                    },
                    actionCategory: action?.actionCategory,

                    actionType: templateData?.workFlowCategory,
                    duration: {
                        value: action?.duration?.value,
                        unit: action?.duration?.unit,
                    },
                };

                if (action?.rules?.length > 0) {
                    obj.actionRules = action?.rules?.map((rule) => ({
                        action: rule,
                    }));
                }

                if (action?.actionCategory !== "approval") {
                    if (!isCopilotAction) {
                        obj.assigneeId = [profile?._id];
                        obj.assigneeType = "LDB";
                    } else {
                        obj.assigneeType = "copilot";
                    }
                } else {
                    obj.approvals = [profile?._id];
                    obj.assigneeType = "LDB";
                }

                return obj;
            });

            console.log("body", body);

            dispatch(workFlowActions.createMultipleActions({ actions: body }));
        }
    }, [dispatch, createWorkFlowSuccess]);

    // useEffect(() => {
    //     if (Boolean(createMultipleActionsSuccess)) {
    //         console.log(
    //             "createMultipleActionsSuccess",
    //             createWorkFlowSuccess?.workFlowEvent?.event?.workFlowId
    //         );
    //         navigate(
    //             `/dashboard/workflow/${createWorkFlowSuccess?.workFlowEvent?.event?.workFlowId}`,
    //             {
    //                 replace: true,
    //             }
    //         );
    //         dispatch(workFlowActions.resetCreateWorkFlowSuccess());
    //         dispatch(workFlowActions.resetCreateMultipleActionSuccess());
    //     }
    // }, [createMultipleActionsSuccess, createWorkFlowSuccess]);
    console.log("====>> whole workflowData", workflowData);
    return (
        // <PageFrame containerClassName={"!h-[calc(100vh-84px)]"} isLoading={fetchingSingleWorkflow}>
        <div className="relative">
            <div className="w-[calc(100%+32px)] absolute left-[-16px] top-[-24px]">
                <div className="bg-white  py-3 px-[28px] h-[67px] w-full border-b border-slate-100 flex items-center justify-between max-lg:flex-col max-lg:h-auto max-lg:items-start gap-4">
                    <div className="flex items-center gap-5">
                        <div
                            onClick={() => navigate(-1)}
                            className="cursor-pointer border border-slate-300 w-[26px] h-[26px] rounded-[3px] flex items-center justify-center"
                        >
                            <i className="ri-arrow-left-s-line text-slate-400"></i>
                        </div>
                        <div>
                            <p className="flex items-center gap-2 mb-1">
                                <span className="text-sm capitalize font-rocGroteskMedium">
                                    {Boolean(templateName)
                                        ? templateName?.replace(/([A-Z])/g, " $1") + " Template"
                                        : workflowData?.name}
                                </span>
                                <span className="bg-slate-100 rounded-full w-5 h-5 flex items-center justify-center">
                                    <i className="ri-arrow-up-s-line text-slate-400"></i>
                                </span>
                            </p>
                            {!Boolean(templateName) && (
                                <p className="text-xs text-slate-500">
                                    <span className="font-rocGroteskBold">Created:</span>{" "}
                                    <span className="font-rocGroteskMedium">
                                        {workflowData?.creator?.fullName
                                            ? `by ${workflowData?.creator?.fullName}`
                                            : ""}{" "}
                                        on {moment(workflowData?.createdAt)?.format("Do MMM, YYYY")}
                                    </span>
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        {/* <div className="flex items-center gap-3 mr-[90px]"> */}
                        {/* <div className="h-6 bg-p-55 flex items-center justify-center py-[3.5px] px-[6px] rounded-full">
                            <p className="text-white text-[11px] font-rocGroteskMedium">FF</p>
                        </div>
                        <img
                            src={profile?.profile?.avatar}
                            alt="logo"
                            className="rounded-full w-[28px] h-6 "
                        />
                        <div className="h-6 bg-p-70 flex items-center justify-center py-[3.5px] px-1 rounded-full">
                            <p className="text-white text-[11px] font-rocGroteskMedium">+30</p>
                        </div>
                        <div className="relative cursor-pointer">
                            <i className="ri-chat-1-line text-xl text-slate-700"></i>
                            <p className="p-[2px] flex items-center justify-center absolute top-0 right-0 bg-r-50 rounded-[1px] text-white font-rocGroteskMedium text-[5px]">
                                12
                            </p>
                        </div> */}
                        {/* </div> */}

                        {!isRun && !Boolean(templateName) && (
                            <Button
                                btnText={"Share"}
                                btnClassname={
                                    "!w-[54px] !h-[26px] rounded !bg-slate-100 !text-gm-50 !text-[10px]"
                                }
                                onClick={() => {
                                    navigator.clipboard.writeText(window.location?.href);
                                    toast.custom((t) => (
                                        <CustomToast t={t} message={"Link copied"} type="success" />
                                    ));
                                }}
                            />
                        )}
                        {!isRun && !Boolean(templateName) && (
                            <Button
                                btnText={isWorkflowActive ? "Disable workflow" : "Publish workflow"}
                                btnClassname={`!w-[108px] !whitespace-nowrap !h-[26px] ${
                                    isWorkflowActive && "!bg-r-55"
                                } rounded !text-white !text-[10px]`}
                                onClick={() => {
                                    if (
                                        !getEditPermissionList("workflow")?.some((permission) =>
                                            profile?.permission?.includes(permission)
                                        )
                                    ) {
                                        toast.custom((t) => (
                                            <CustomToast
                                                t={t}
                                                message="You don't have access to this feature"
                                                type="error"
                                            />
                                        ));
                                    } else {
                                        isRun
                                            ? togglePauseAndPlayWorkflowRun()
                                            : togglePauseAndPlay();
                                    }
                                }}
                                isLoading={playOrPauseWorkFlow || togglingPausePlayWorkflowRun}
                            />
                        )}
                        {Boolean(templateName) && (
                            <Button
                                btnText={"Create workflow"}
                                btnClassname={`!w-[108px] !whitespace-nowrap !h-[26px] rounded !text-white !text-[10px]`}
                                onClick={() => {
                                    if (
                                        !getEditPermissionList("workflow")?.some((permission) =>
                                            profile?.permission?.includes(permission)
                                        )
                                    ) {
                                        toast.custom((t) => (
                                            <CustomToast
                                                t={t}
                                                message="You don't have access to this feature"
                                                type="error"
                                            />
                                        ));
                                    } else {
                                        handleCreateWorkflow();
                                    }
                                }}
                                isLoading={creatingWorkFlow || creatingMultipleActions}
                            />
                        )}
                    </div>
                </div>

                {/* <div className="w-full h-[calc(100vh-136px)] relative"> */}
                <PageFrame
                    containerClassName={" w-full !h-[calc(100vh-136px)] relative"}
                    isLoading={fetchingSingleWorkflow || fetchingEventAndActionOptionList}
                >
                    <DndProvider backend={HTML5Backend}>
                        <div className="w-full h-[calc(100vh-151px)] relative">
                            <ReactFlowProvider>
                                <WorkflowBoard
                                    workflowId={workflowId as string}
                                    workflowCat={
                                        Boolean(templateName)
                                            ? templateData?.workFlowCategory
                                            : workflowCat
                                    }
                                    workflowData={
                                        Boolean(templateName)
                                            ? templateData
                                            : workflowData?.firstEvent
                                    }
                                    setPlayable={setPlayable}
                                    currentStatus={workflowData?.currentStatus}
                                    // playable={playable}
                                    profile={profile}
                                    customer={workflowData?.customerName}
                                    setUserInteract={setUserInteract}
                                    customActions={customActions}
                                    customEvents={customEvents}
                                    creator={workflowData?.creator}
                                    isTemplate={Boolean(templateName)}
                                />
                            </ReactFlowProvider>
                        </div>
                    </DndProvider>
                </PageFrame>
            </div>
            <ModalContainer
                open={creatingMultipleActions}
                showCloseIcon={false}
                // closeModal={}
                tailwindClassName="w-[36%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="flex flex-col items-center justify-center">
                    <Loader size={8} />
                    <p className="text-2xl mt-4 text-white font-rocGroteskMedium">
                        Creating workflow...
                    </p>
                </div>
            </ModalContainer>
        </div>
        // </PageFrame>
    );
};

export default WorkflowGraph;
