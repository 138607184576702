import React from "react";
import Button from "./Button";
import SelectInput from "./InputField/SelectInput";
import { useDispatch, useSelector } from "react-redux";
import { debounce, truncate } from "lodash";
import { useEffect, useState } from "react";
import { shipmentActions } from "redux/Ldb/actions/shipmentActions";
import { authActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";

type DashboardModalProps = {
    onClose: () => void;
    contentTitle: string;
    content: string;
    btnContent: string;
    btnContentTwo?: string;
};

export const DashboardModalAction = ({
    onClose,
    contentTitle,
    content,
    btnContent,
    btnContentTwo,
}: DashboardModalProps) => {
    const [shipments, setShipments] = useState<{ [key: string]: any }[]>([]);
    const [selectedSipment, setSelectedSipment] = useState("");
    const [allTeamMembers, setAllTeamMembers] = useState<{ [key: string]: any }[]>([]);
    const [teamMember, setTeamMember] = useState("");
    const [debounceShipmentValue, setDebounceShipmentValue] = useState("");

    const dispatch = useAppDispatch();
    const {
        fetchingShipments,
        fetchShipmentsSuccess,
        assigningShipmentToMember,
        assignShipmentToMemberSuccess,
    } = useAppSelector((state) => state.shipment);
    const { fetchingTeamMember, fetchedTeamMemberSuccess } = useAppSelector((state) => state.auth);

    const handleAssign = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body = {
            shipmentId: selectedSipment,
            memberId: teamMember,
        };
        dispatch(shipmentActions.assignShipment(body));
    };

    useEffect(() => {
        dispatch(shipmentActions.getShipments(20, debounceShipmentValue));
    }, [debounceShipmentValue]);

    useEffect(() => {
        if (Boolean(fetchShipmentsSuccess)) {
            setShipments(fetchShipmentsSuccess?.data);
        }
    }, [fetchShipmentsSuccess]);

    useEffect(() => {
        dispatch(authActions.getTeamMembers(100));
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setAllTeamMembers(fetchedTeamMemberSuccess.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        if (Boolean(assignShipmentToMemberSuccess)) {
            setSelectedSipment("");
            setTeamMember("");
            dispatch(shipmentActions.resetAssignShipmentSuccess());
            onClose();
        }
    }, [assignShipmentToMemberSuccess, dispatch]);

    return (
        <div className=" bg-white py-1  rounded-[20px]   drop-shadow-md shadow-modalShadow">
            <div onClick={onClose} className="cursor-pointer mt-2 px-4 flex justify-end ">
                <i className="ri-close-fill text-gm-45 text-[20px]"></i>
            </div>
            <form
                onSubmit={handleAssign}
                className="py-6 flex justify-center  flex-col  items-center px-6 lg:px-8"
            >
                <h3 className="text-xl mb-1 text-[24px] font-rocGroteskMedium font-[600] text-g-75">
                    {contentTitle}
                </h3>
                <p className="text-center text-[14px] text-gm-40 font-rocGroteskMedium font-normal">
                    {content}
                </p>
                <div className="pb-4 mt-8 w-full flex flex-col gap-6 justify-center">
                    <SelectInput
                        value={selectedSipment}
                        name="shipment"
                        placeholder={"Select a shipment"}
                        handleChange={(_, value) => setSelectedSipment(value)}
                        handleInputChange={debounce((evt) => {
                            setDebounceShipmentValue(evt.target.value);
                        }, 800)}
                        isRequired={true}
                        searchLoading={fetchingShipments}
                        dropdownOptions={shipments?.map((item) => ({
                            label: `${item?.taId} - ${truncate(
                                item?.shipmentType?.toLowerCase() === "freight"
                                    ? item?.freight?.goodsDetails
                                          ?.map((item) => item?.description)
                                          .join(", ")
                                    : item?.supplier?.ItemDetails?.map(
                                          (item) => item?.description
                                      ).join(", "),
                                {
                                    length: 30,
                                }
                            )}`,
                            value: item?._id,
                        }))}
                    />

                    <SelectInput
                        value={teamMember}
                        name="status"
                        placeholder={"Select team member"}
                        handleChange={(_, value) => setTeamMember(value)}
                        isRequired={true}
                        searchLoading={fetchingTeamMember}
                        dropdownOptions={allTeamMembers?.map((item) => ({
                            label: item?.email,
                            value: item?._id,
                        }))}
                    />
                </div>

                <div className="w-full space-y-2 mt-4">
                    {btnContent && (
                        <Button
                            btnText={btnContent}
                            type={"submit"}
                            btnClassname="!py-3 !px-4"
                            isLoading={assigningShipmentToMember}
                            disabled={assigningShipmentToMember}
                        />
                    )}
                    {btnContentTwo && (
                        <Button
                            btnText={btnContentTwo}
                            type={"button"}
                            disabled={assigningShipmentToMember}
                            btnClassname="!py-3 !bg-transparent !text-dark-gray-2 !px-4"
                            onClick={onClose}
                        />
                    )}
                </div>
            </form>
        </div>
    );
};
