import React from "react";
import { Document, Page, Font, Text, View, Image, StyleSheet } from "@react-pdf/renderer";
// import rocGroteskLight from "../../../../assets/fonts/rocGrotesk/RocGrotesk-Light.otf";
// import rocGroteskRegular from "../../../../assets/fonts/rocGrotesk/RocGrotesk-Regular.otf";
// import rocGroteskMedium from "../../../../assets/fonts/rocGrotesk/RocGrotesk-Medium.otf";
// import rocGroteskBold from "../../../../assets/fonts/rocGrotesk/RocGrotesk-Bold.otf";
import { getCurrencyFromCurrencyCode, hasHTMLTag, sanitizeHtml } from "helpers";
import moment from "moment";

type PurchaseOrdersPDFProps = {
    item: string;
    taId?: string;
    issuedDate: string;
    dueDate: string;
    selectedRFQData?: { [key: string]: any };
    userName: string;
    userEmail: string;
    containers: { [key: string]: any }[];
    tax: string | number;
    currency: string;
    taxAmount: string | number;
    shipping: string | number;
    totalAmount: string | number;
    orderDescription: string;
    tagNumber?: string;
    imageDetails: string;
    title?: string;
    address: string;
    postalCode?: string;
    city: string;
    country: string;
    vendorEmail: string;
    vendorName: string;
    status?: string;
};

const PurchaseOrdersPDF = ({
    item,
    taId,
    issuedDate,
    dueDate,
    selectedRFQData,
    userName,
    userEmail,
    containers,
    tax,
    currency,
    taxAmount,
    shipping,
    totalAmount,
    orderDescription,
    tagNumber,
    imageDetails,
    title,
    address,
    postalCode,
    city,
    country,
    vendorEmail,
    vendorName,
    status,
}: PurchaseOrdersPDFProps) => {
    Font.register({
        family: "roc-grotesk",
        fonts: [
            {
                src: "../../../../assets/fonts/rocGrotesk/RocGrotesk-Light.otf",
                fontWeight: "light",
            },
            {
                src: "../../../../assets/fonts/rocGrotesk/RocGrotesk-Regular.otf",
                fontWeight: "normal",
            },
            {
                src: "../../../../assets/fonts/rocGrotesk/RocGrotesk-Medium.otf",
                fontWeight: "medium",
            },
            {
                src: "../../../../assets/fonts/rocGrotesk/RocGrotesk-Bold.otf",
                fontWeight: "bold",
            },
        ],
    });

    const styles = StyleSheet.create({
        page: {
            flexDirection: "column",
            backgroundColor: "#FFF",
            padding: 20,
        },
        bgWhite: {
            backgroundColor: "#FFF",
        },
        textEnd: {
            textAlign: "right",
            justifyContent: "flex-end",
        },
        rounded: {
            borderRadius: 4,
        },
        shadow: {
            // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        },
        wFull: {
            width: "100%",
        },
        borderDashed: {
            borderStyle: "dashed",
            borderWidth: 1,
            borderColor: "#ccc",
        },
        flex: {
            display: "flex",
            flexDirection: "row",
        },
        justifyBetween: {
            justifyContent: "space-between",
        },
        itemsCenter: {
            alignItems: "center",
        },
        textGray300: {
            color: "#ccc",
        },
        textXs: {
            fontSize: 10,
        },

        textSm: {
            fontSize: "12px",
        },
        textGray500: {
            color: "#888",
        },
        textGm50: {
            color: "#142837",
        },
        textSlate900: {
            color: "#0F172A",
        },
        textCenter: {
            textAlign: "center",
        },
        borderBGm: {
            borderBottomWidth: 1,
            borderBottomColor: "#142837",
        },
        borderBSlate: {
            borderBottomWidth: 1,
            borderBottomColor: "#F1F5F9",
        },
        spaceY2: {
            marginVertical: 8,
        },
        logo: {
            width: 80,
            height: 80,
        },
        text16: {
            fontSize: "16px",
        },
        section: {
            marginBottom: 10,
        },
        label: {
            fontSize: 12,
            fontWeight: "bold",
        },
        fontMedium: {
            fontWeight: "medium",
        },
        content: {
            fontSize: 12,
        },
        w1_3: {
            width: "33.33%",
        },
        w1_2: {
            width: "50%",
        },
        w2_5: {
            width: "40%",
        },
        w2_3: {
            width: "66.67%",
        },
        mb3: {
            marginBottom: 3,
        },
        mb1: {
            marginBottom: 1,
        },
        mb4: {
            marginBottom: 4,
        },
        mb6: {
            marginBottom: 6,
        },
        mb8: {
            marginBottom: 8,
        },
        mb10: {
            marginBottom: 10,
        },
        mt4: {
            marginTop: 4,
        },
        pb3: {
            paddingBottom: 3,
        },
        pb4: {
            paddingBottom: 4,
        },
        pt2: {
            paddingTop: 2,
        },
        pt4: {
            paddingTop: 4,
        },
        pb6: {
            paddingBottom: 6,
        },
        pb8: {
            paddingBottom: 8,
        },
        my4: {
            marginBottom: 4,
            marginTop: 4,
        },
        py8: {
            paddingBottom: 8,
            paddingTop: 8,
        },
        py20: {
            paddingBottom: 20,
            paddingTop: 20,
        },
        pb20: {
            paddingBottom: 20,
        },
        pb5: {
            paddingBottom: 5,
        },
        mt20: {
            marginTop: 20,
        },
        my10: {
            marginBottom: 10,
            marginTop: 10,
        },
        mt1: {
            marginTop: 1,
        },
        mt10: {
            marginTop: 10,
        },
    });
    const textSm = [styles.textSm, styles.textGray500, styles.fontMedium];
    const headerStyles = StyleSheet.create({
        container: {
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 30,
            marginTop: 10,
        },
        imageContainer: {
            borderStyle: "dashed",
            borderWidth: 1,
            borderColor: "#ccc",
            borderRadius: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: 80,
            height: 80,
        },
        labelContainer: {
            backgroundColor: "#FFF",
            padding: 6,
            borderRadius: 4,
            cursor: "pointer",
        },
        label: {
            fontSize: 16,
            color: "#ccc",
        },
    });

    const renderContactSection = (label: string, name: string, email: string) => {
        return (
            <View style={styles.wFull}>
                <View style={[styles.section]}>
                    <Text
                        style={[
                            styles.textSm,
                            styles.textGray500,
                            styles.fontMedium,
                            styles.textGm50,
                            styles.fontMedium,
                        ]}
                    >
                        {label}
                    </Text>
                </View>
                <Text
                    style={[
                        styles.mt1,
                        styles.pb6,
                        styles.textSm,
                        styles.textGray500,
                        styles.fontMedium,
                        styles.textGm50,
                        styles.fontMedium,
                    ]}
                >
                    {name}
                </Text>
                <Text
                    style={[
                        styles.textSm,
                        styles.textGray500,
                        styles.fontMedium,
                        styles.textGray500,
                        styles.fontMedium,
                        styles.mb8,
                    ]}
                >
                    {email}
                </Text>
            </View>
        );
    };

    const totalAmountTextStyle = [
        textSm,
        styles.flex,
        styles.justifyBetween,
        styles.fontMedium,
        styles.textGray500,
    ];

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.bgWhite}>
                    <View style={[styles.rounded, styles.shadow, styles.wFull]}>
                        <View style={[headerStyles.container]}>
                            <View style={headerStyles.imageContainer}>
                                {imageDetails && <Image src={imageDetails} style={styles.logo} />}
                            </View>
                            <View style={headerStyles.labelContainer}>
                                <Text style={headerStyles.label}>{title} Created</Text>
                            </View>
                        </View>
                        <View style={[styles.flex, styles.wFull, styles.pb4, styles.borderBSlate]}>
                            <View style={styles.w1_2}>
                                <View style={styles.section}>
                                    <Text
                                        style={[styles.textSm, styles.textGm50, styles.fontMedium]}
                                    >
                                        RFQ {taId}
                                    </Text>
                                </View>
                                <View style={styles.section}>
                                    <Text
                                        style={[
                                            styles.textSm,
                                            styles.textGm50,
                                            styles.fontMedium,
                                            styles.pb6,
                                        ]}
                                    >
                                        {title} Number
                                    </Text>
                                    <Text
                                        style={[
                                            styles.textSm,
                                            styles.textGray500,
                                            styles.fontMedium,
                                        ]}
                                    >
                                        {tagNumber ? tagNumber : "----"}
                                    </Text>
                                </View>
                                <View style={styles.section}>
                                    <Text
                                        style={[
                                            styles.textSm,
                                            styles.textGm50,
                                            styles.fontMedium,
                                            styles.pb6,
                                        ]}
                                    >
                                        Currency
                                    </Text>
                                    <Text
                                        style={[
                                            styles.textSm,
                                            styles.textGray500,
                                            styles.fontMedium,
                                        ]}
                                    >
                                        {currency}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.w1_2}>
                                {renderContactSection("From", userName, userEmail)}
                                <View style={styles.pt2}>
                                    {renderContactSection("To", vendorName, vendorEmail)}
                                </View>
                            </View>
                        </View>
                        <View style={[styles.flex, styles.borderBSlate, styles.py8, styles.mt4]}>
                            <View style={styles.w1_2}>
                                <View style={styles.section}>
                                    <Text
                                        style={[
                                            styles.textSm,
                                            styles.textGray500,
                                            styles.fontMedium,
                                            styles.pb6,
                                        ]}
                                    >
                                        Issued Date
                                    </Text>
                                    <Text
                                        style={[styles.textSm, styles.textGm50, styles.fontMedium]}
                                    >
                                        {issuedDate
                                            ? moment(issuedDate).format("MMM Do, YYYY")
                                            : "----"}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.w1_2}>
                                <View style={styles.section}>
                                    <Text
                                        style={[
                                            styles.textSm,
                                            styles.textGray500,
                                            styles.fontMedium,
                                            styles.pb6,
                                        ]}
                                    >
                                        Due Date
                                    </Text>
                                    <Text
                                        style={[styles.textSm, styles.textGm50, styles.fontMedium]}
                                    >
                                        {dueDate ? moment(dueDate).format("MMM Do, YYYY") : "----"}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.mt4}>
                            <View style={[styles.py8, styles.borderBSlate]}>
                                <Text
                                    style={[
                                        styles.textSm,
                                        styles.my4,
                                        styles.textGray500,
                                        styles.fontMedium,
                                        styles.pb6,
                                    ]}
                                >
                                    Ship to
                                </Text>

                                <View style={styles.flex}>
                                    <View style={styles.w1_2}>
                                        <View style={styles.section}>
                                            <Text
                                                style={[
                                                    styles.textSm,
                                                    styles.textGm50,
                                                    styles.fontMedium,
                                                    styles.pb6,
                                                ]}
                                            >
                                                Street: {address}
                                            </Text>
                                            <Text
                                                style={[
                                                    styles.textSm,
                                                    styles.textGm50,
                                                    styles.fontMedium,
                                                ]}
                                            >
                                                Postal Code: {postalCode}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.w1_2}>
                                        <View style={styles.section}>
                                            <Text
                                                style={[
                                                    styles.textSm,
                                                    styles.textGm50,
                                                    styles.fontMedium,
                                                    styles.pb6,
                                                ]}
                                            >
                                                City: {city}
                                            </Text>
                                            <Text
                                                style={[
                                                    styles.textSm,
                                                    styles.textGm50,
                                                    styles.fontMedium,
                                                ]}
                                            >
                                                Country: {country}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.pb5, styles.mt10, styles.wFull]}>
                            <Text
                                style={[
                                    styles.text16,
                                    styles.my10,
                                    styles.pt4,
                                    styles.fontMedium,
                                    styles.textGm50,
                                ]}
                            >
                                Quote for {item}
                            </Text>
                            <View
                                style={[
                                    styles.textXs,
                                    styles.flex,
                                    styles.justifyBetween,
                                    styles.fontMedium,
                                    styles.textGray500,
                                    styles.my10,
                                    styles.pb4,
                                    styles.wFull,
                                ]}
                            >
                                <Text style={styles.w1_2}>ITEMS/SERVICES</Text>
                                <View style={[styles.flex, styles.w1_2, styles.justifyBetween]}>
                                    <Text style={[styles.wFull, styles.textEnd]}>QTY</Text>
                                    <Text style={[styles.wFull, styles.textEnd]}>RATE</Text>
                                    <Text style={[styles.wFull, styles.textEnd]}>TOTAL</Text>
                                </View>
                            </View>
                            {containers?.map((container, index) => (
                                <View
                                    style={[
                                        styles.textSm,
                                        styles.pb8,
                                        styles.pt4,
                                        styles.my4,
                                        styles.flex,
                                        styles.justifyBetween,
                                        styles.fontMedium,
                                        styles.textGm50,
                                        styles.borderBSlate,
                                    ]}
                                    key={index}
                                >
                                    <Text style={[styles.w1_2, styles.textGm50]}>
                                        {container?.name}
                                    </Text>
                                    <View
                                        style={[
                                            styles.flex,
                                            styles.w1_2,
                                            styles.justifyBetween,
                                            styles.textGm50,
                                        ]}
                                    >
                                        <Text
                                            style={[styles.wFull, styles.textGm50, styles.textEnd]}
                                        >
                                            {container?.qty}
                                        </Text>
                                        <Text
                                            style={[styles.textGm50, styles.wFull, styles.textEnd]}
                                        >
                                            {getCurrencyFromCurrencyCode(
                                                container?.amount?.currency
                                            )}
                                            {Intl.NumberFormat("en-US", {
                                                maximumFractionDigits: 2,
                                            }).format(container?.price?.amount)}
                                        </Text>
                                        <Text
                                            style={[styles.wFull, styles.textGm50, styles.textEnd]}
                                        >
                                            {getCurrencyFromCurrencyCode(
                                                container?.amount?.currency
                                            )}
                                            {Intl.NumberFormat("en-US", {
                                                maximumFractionDigits: 2,
                                            }).format(container?.qty * container?.price?.amount)}
                                        </Text>
                                    </View>
                                </View>
                            ))}
                        </View>
                        <View style={[styles.mb4]}>
                            <View
                                style={[
                                    styles.flex,
                                    styles.justifyBetween,
                                    styles.textSm,
                                    styles.mt20,
                                    styles.mb10,
                                    styles.fontMedium,
                                    styles.textGray500,
                                ]}
                            >
                                <Text style={[styles.textGray500]}>Tax ({tax} %)</Text>
                                <Text style={[styles.textGm50]}>
                                    {getCurrencyFromCurrencyCode(currency)}
                                    {taxAmount !== "" ? taxAmount : 0}
                                </Text>
                            </View>
                            <View
                                style={[
                                    styles.flex,
                                    styles.justifyBetween,
                                    styles.textSm,
                                    styles.my4,
                                    styles.mb10,
                                    styles.pb8,
                                    styles.borderBSlate,
                                    styles.fontMedium,
                                    styles.textGray500,
                                ]}
                            >
                                <Text style={[styles.textGray500]}>Shipping and Handling</Text>
                                <Text style={[styles.textGm50]}>
                                    {getCurrencyFromCurrencyCode(currency)}
                                    {shipping !== "" ? shipping : 0}
                                </Text>
                            </View>
                            <View
                                style={[
                                    styles.flex,
                                    styles.justifyBetween,
                                    styles.textSm,
                                    styles.fontMedium,
                                    styles.textGray500,
                                    styles.mt4,
                                    styles.pb20,
                                ]}
                            >
                                <Text
                                    style={[
                                        styles.textSm,
                                        styles.flex,
                                        styles.justifyBetween,
                                        styles.fontMedium,
                                        styles.textGray500,
                                        styles.textGm50,
                                    ]}
                                >
                                    Total Amount
                                </Text>
                                <Text style={[styles.textGm50]}>
                                    {getCurrencyFromCurrencyCode(currency)}
                                    {totalAmount}
                                </Text>
                            </View>
                        </View>
                        {orderDescription && (
                            <View style={styles.my4}>
                                <Text
                                    style={[
                                        styles.textSm,
                                        styles.flex,
                                        styles.pb6,
                                        styles.my10,
                                        styles.justifyBetween,
                                        styles.fontMedium,
                                        styles.textGm50,
                                    ]}
                                >
                                    Purchase Order Terms and Conditions
                                </Text>
                                <View
                                    style={[styles.textSm, styles.fontMedium, styles.textGray500]}
                                >
                                    {hasHTMLTag(orderDescription)
                                        ? sanitizeHtml(orderDescription, true).sanitizedHTML
                                        : orderDescription}
                                </View>
                            </View>
                        )}
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default PurchaseOrdersPDF;
