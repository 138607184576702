export const vendorTypes = {
    JOIN_WAITLIST_REQUEST: "JOIN_WAITLIST_REQUEST",
    JOIN_WAITLIST_SUCCESS: "JOIN_WAITLIST_SUCCESS",
    JOIN_WAITLIST_FAILURE: "JOIN_WAITLIST_FAILURE",
    FETCH_LDB_VENDORS_REQUEST: "FETCH_LDB_VENDORS_REQUEST",
    FETCH_LDB_VENDORS_SUCCESS: "FETCH_LDB_VENDORS_SUCCESS",
    FETCH_LDB_VENDORS_FAILURE: "FETCH_LDB_VENDORS_FAILURE",
    FETCH_SINGLE_VENDOR_REQUEST: "FETCH_SINGLE_VENDOR_REQUEST",
    FETCH_SINGLE_VENDOR_SUCCESS: "FETCH_SINGLE_VENDOR_SUCCESS",
    FETCH_SINGLE_VENDOR_FAILURE: "FETCH_SINGLE_VENDOR_FAILURE",
    AUTHENTICATE_GMAIL_REQUEST: "AUTHENTICATE_GMAIL_REQUEST",
    AUTHENTICATE_GMAIL_SUCCESS: "AUTHENTICATE_GMAIL_SUCCESS",
    AUTHENTICATE_GMAIL_FAILURE: "AUTHENTICATE_GMAIL_FAILURE",
    AUTHENTICATE_GMAIL_SETTINGS_REQUEST: "AUTHENTICATE_GMAIL_SETTINGS_REQUEST",
    AUTHENTICATE_GMAIL_SETTINGS_SUCCESS: "AUTHENTICATE_GMAIL_SETTINGS_SUCCESS",
    AUTHENTICATE_GMAIL_SETTINGS_FAILURE: "AUTHENTICATE_GMAIL_SETTINGS_FAILURE",
    CONNECT_GOOGLE_WORKSPACE_REQUEST: "CONNECT_GOOGLE_WORKSPACE_REQUEST",
    CONNECT_GOOGLE_WORKSPACE_SUCCESS: "CONNECT_GOOGLE_WORKSPACE_SUCCESS",
    CONNECT_GOOGLE_WORKSPACE_FAILURE: "CONNECT_GOOGLE_WORKSPACE_FAILURE",
    CONNECT_MICROSOFT_WORKSPACE_REQUEST: "CONNECT_MICROSOFT_WORKSPACE_REQUEST",
    CONNECT_MICROSOFT_WORKSPACE_SUCCESS: "CONNECT_MICROSOFT_WORKSPACE_SUCCESS",
    CONNECT_MICROSOFT_WORKSPACE_FAILURE: "CONNECT_MICROSOFT_WORKSPACE_FAILURE",
    FETCH_ALL_CHANNELS_REQUEST: "FETCH_ALL_CHANNELS_REQUEST",
    FETCH_ALL_CHANNELS_SUCCESS: "FETCH_ALL_CHANNELS_SUCCESS",
    FETCH_ALL_CHANNELS_FAILURE: "FETCH_ALL_CHANNELS_FAILURE",
    FETCH_VENDORS_BY_ORDER_TYPE_REQUEST: "FETCH_VENDORS_BY_ORDER_TYPE_REQUEST",
    FETCH_VENDORS_BY_ORDER_TYPE_SUCCESS: "FETCH_VENDORS_BY_ORDER_TYPE_SUCCESS",
    FETCH_VENDORS_BY_ORDER_TYPE_FAILURE: "FETCH_VENDORS_BY_ORDER_TYPE_FAILURE",
    FETCH_ONBOARDED_VENDORS_REQUEST: "FETCH_ONBOARDED_VENDORS_REQUEST",
    FETCH_ONBOARDED_VENDORS_SUCCESS: "FETCH_ONBOARDED_VENDORS_SUCCESS",
    FETCH_ONBOARDED_VENDORS_FAILURE: "FETCH_ONBOARDED_VENDORS_FAILURE",
    FETCH_SUGGESTED_VENDORS_REQUEST: "FETCH_SUGGESTED_VENDORS_REQUEST",
    FETCH_SUGGESTED_VENDORS_SUCCESS: "FETCH_SUGGESTED_VENDORS_SUCCESS",
    FETCH_SUGGESTED_VENDORS_FAILURE: "FETCH_SUGGESTED_VENDORS_FAILURE",
    AUTHENTICATE_MICROSOFT_REQUEST: "AUTHENTICATE_MICROSOFT_REQUEST",
    AUTHENTICATE_MICROSOFT_SUCCESS: "AUTHENTICATE_MICROSOFT_SUCCESS",
    AUTHENTICATE_MICROSOFT_FAILURE: "AUTHENTICATE_MICROSOFT_FAILURE",
    AUTHENTICATE_MICROSOFT_SETTINGS_REQUEST: "AUTHENTICATE_MICROSOFT_SETTINGS_REQUEST",
    AUTHENTICATE_MICROSOFT_SETTINGS_SUCCESS: "AUTHENTICATE_MICROSOFT_SETTINGS_SUCCESS",
    AUTHENTICATE_MICROSOFT_SETTINGS_FAILURE: "AUTHENTICATE_MICROSOFT_SETTINGS_FAILURE",
    CONNECT_MICROSOFT_WORKSPACE_SETTINGS_REQUEST: "CONNECT_MICROSOFT_WORKSPACE_SETTINGS_REQUEST",
    CONNECT_MICROSOFT_WORKSPACE_SETTINGS_SUCCESS: "CONNECT_MICROSOFT_WORKSPACE_SETTINGS_SUCCESS",
    CONNECT_MICROSOFT_WORKSPACE_SETTINGS_FAILURE: "CONNECT_MICROSOFT_WORKSPACE_SETTINGS_FAILURE",
    CONNECT_GOOGLE_WORKSPACE_SETTINGS_REQUEST: "CONNECT_GOOGLE_WORKSPACE_SETTINGS_REQUEST",
    CONNECT_GOOGLE_WORKSPACE_SETTINGS_SUCCESS: "CONNECT_GOOGLE_WORKSPACE_SETTINGS_SUCCESS",
    CONNECT_GOOGLE_WORKSPACE_SETTINGS_FAILURE: "CONNECT_GOOGLE_WORKSPACE_SETTINGS_FAILURE",
    FETCH_VENDOR_PO_REQUEST: "FETCH_VENDOR_PO_REQUEST",
    FETCH_VENDOR_PO_SUCCESS: "FETCH_VENDOR_PO_SUCCESS",
    FETCH_VENDOR_PO_FAILURE: "FETCH_VENDOR_PO_FAILURE",
    MANAGE_CONNECTION_REQUEST: "MANAGE_CONNECTION_REQUEST",
    MANAGE_CONNECTION_SUCCESS: "MANAGE_CONNECTION_SUCCESS",
    MANAGE_CONNECTION_FAILURE: "MANAGE_CONNECTION_FAILURE",
    FETCH_VENDOR_ACTIVITIES_REQUEST: "FETCH_VENDOR_ACTIVITIES_REQUEST",
    FETCH_VENDOR_ACTIVITIES_SUCCESS: "FETCH_VENDOR_ACTIVITIES_SUCCESS",
    FETCH_VENDOR_ACTIVITIES_FAILURE: "FETCH_VENDOR_ACTIVITIES_FAILURE",
    FETCH_VENDOR_BY_CATEGORY_REQUEST: "FETCH_VENDOR_BY_CATEGORY_REQUEST",
    FETCH_VENDOR_BY_CATEGORY_SUCCESS: "FETCH_VENDOR_BY_CATEGORY_SUCCESS",
    FETCH_VENDOR_BY_CATEGORY_FAILURE: "FETCH_VENDOR_BY_CATEGORY_FAILURE",
    FETCH_VENDOR_CATEGORIES_REQUEST: "FETCH_VENDOR_CATEGORIES_REQUEST",
    FETCH_VENDOR_CATEGORIES_SUCCESS: "FETCH_VENDOR_CATEGORIES_SUCCESS",
    FETCH_VENDOR_CATEGORIES_FAILURE: "FETCH_VENDOR_CATEGORIES_FAILURE",
    ADD_VENDOR_CATEGORY_REQUEST: "ADD_VENDOR_CATEGORY_REQUEST",
    ADD_VENDOR_CATEGORY_SUCCESS: "ADD_VENDOR_CATEGORY_SUCCESS",
    ADD_VENDOR_CATEGORY_FAILURE: "ADD_VENDOR_CATEGORY_FAILURE",
    FETCH_VENDOR_DOCS_REQUEST: "FETCH_VENDOR_DOCS_REQUEST",
    FETCH_VENDOR_DOCS_SUCCESS: "FETCH_VENDOR_DOCS_SUCCESS",
    FETCH_VENDOR_DOCS_FAILURE: "FETCH_VENDOR_DOCS_FAILURE",
    APPROVE_VENDOR_DOC_REQUEST: "APPROVE_VENDOR_DOC_REQUEST",
    APPROVE_VENDOR_DOC_SUCCESS: "APPROVE_VENDOR_DOC_SUCCESS",
    APPROVE_VENDOR_DOC_FAILURE: "APPROVE_VENDOR_DOC_FAILURE",
    REQUEST_VENDOR_DOC_REQUEST: "REQUEST_VENDOR_DOC_REQUEST",
    REQUEST_VENDOR_DOC_SUCCESS: "REQUEST_VENDOR_DOC_SUCCESS",
    REQUEST_VENDOR_DOC_FAILURE: "REQUEST_VENDOR_DOC_FAILURE",
    ADD_DOC_COMMENT_REQUEST: "ADD_DOC_COMMENT_REQUEST",
    ADD_DOC_COMMENT_SUCCESS: "ADD_DOC_COMMENT_SUCCESS",
    ADD_DOC_COMMENT_FAILURE: "ADD_DOC_COMMENT_FAILURE",
    FETCH_DOC_COMMENT_REQUEST: "FETCH_DOC_COMMENT_REQUEST",
    FETCH_DOC_COMMENT_SUCCESS: "FETCH_DOC_COMMENT_SUCCESS",
    FETCH_DOC_COMMENT_FAILURE: "FETCH_DOC_COMMENT_FAILURE",
    APPROVE_SUPPLIER_REQUEST: "APPROVE_SUPPLIER_REQUEST",
    APPROVE_SUPPLIER_SUCCESS: "APPROVE_SUPPLIER_SUCCESS",
    APPROVE_SUPPLIER_FAILURE: "APPROVE_SUPPLIER_FAILURE",
    REVIEW_SUPPLIER_RESPONSES_REQUEST: "REVIEW_SUPPLIER_RESPONSES_REQUEST",
    REVIEW_SUPPLIER_RESPONSES_SUCCESS: "REVIEW_SUPPLIER_RESPONSES_SUCCESS",
    REVIEW_SUPPLIER_RESPONSES_FAILURE: "REVIEW_SUPPLIER_RESPONSES_FAILURE",
};
