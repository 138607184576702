import Button from "components/common/Button";
import React, { useEffect, useRef, useState } from "react";
import TextInput from "../../../../components/common/InputField/TextInput";
import { displayFileIcon } from "helpers";
import CustomCalendar from "components/common/CalendarComp.js/Calendar";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ModalContainer from "components/common/ModalContainer";
import SingleProduct from "components/views/Ldb/Dashboard/Inventory/AddProductMethods/SingleProduct";
import ExcelUpload from "components/views/Ldb/Dashboard/Inventory/AddProductMethods/ExcelUpload";
import { ProductDataType } from "types/productDataType";
import { authActions, inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import SelectInput from "components/common/InputField/SelectInput";
import AddressInput from "components/common/InputField/AddressInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import { isFileSizeLessThanOrEqualTo } from "helpers/compareFileSizeInMb";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import { firebaseService } from "services/firebaseService";
import useGetUserProfile from "hooks/useGetUserProfile";
import Loader from "components/common/Loader";
import { truncate } from "lodash";
import VendorAvatar from "components/common/VendorAvatar";
import { getEditPermissionList } from "helpers/getEditPermissionList";

const AddProduct = () => {
    const [profile] = useGetUserProfile();
    const [deleteImageModal, setDeleteImageModal] = useState<boolean>(false);
    const [addWarehouseModal, setAddWarehouseModal] = useState<boolean>(false);
    const [uploadingFiles, setUploadingFiles] = useState<boolean>(false);
    const [deletingImage, setDeletingImage] = useState<boolean>(false);
    const [skipDuplicates, setSkipDuplicates] = useState<boolean>(true);
    const [selectedRow, setSelectedRow] = useState<number | null>(null);
    const [address, setAddress] = useState<{ [key: string]: any }>({});
    const [teamList, setTeamList] = useState<{ [key: string]: any }[]>([]);
    const [addProductMethod, setAddProductMethod] = useState<string>("single");
    const [productImages, setProductImages] = useState<string[]>([]);
    const [productImage, setProductImage] = useState<string>("");
    const [addWarehouseData, setAddWarehouseData] = useState<{
        contactPerson: string;
        note: string;
        name: string;
    }>({
        contactPerson: "",
        note: "",
        name: "",
    });
    const [productData, setProductData] = useState<ProductDataType>({
        productName: "",
        inventoryCategory: "",
        vendors: [],
        unitOfMeasurement: "",
        selectAttribute: "",
        attributeValue: "",
        description: "",
        warehouse: "",
        minimumStockLevel: "",
        maximumStockLevel: "",
        bufferStockLevel: "",
        isProductPerishable: false,
    });
    const [productDataArr, setProductDataArr] = useState<ProductDataType[]>([]);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        addingSingleProduct,
        addSingleProductSuccess,
        createWarehouse,
        createWarehouseSuccess,
        addingMultipleProducts,
        addMultipleProductsSuccess,
    } = useAppSelector((state) => state.inventory);

    const { fetchingTeamMember, fetchedTeamMemberSuccess } = useAppSelector((state) => state.auth);

    const handleChange = (name: string, value: any) => {
        setProductData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSaveProduct = () => {
        const body: { [key: string]: any } = {
            productName: productData.productName,
            inventoryCategory: productData.inventoryCategory,
            vendorIds: productData.vendors,
            productStockDetails: {
                unitOfMeasurement: productData.unitOfMeasurement,
                // "stockLevel": 14000
            },
            productStockSettings: [
                {
                    bufferStock: productData.bufferStockLevel,
                    minimumStockLevel: productData.minimumStockLevel,
                    maximumStockLevel: productData.maximumStockLevel,
                    warehouseId: productData.warehouse,
                },
            ],
            isProductPerishable: productData.isProductPerishable,
            productDescription: productData.description,
        };

        if (productImages?.length > 0) {
            console.log("productImages", productImages);
            body.productImageDetails = {
                productImages: productImages?.slice(0, 5)?.map((img) => img),
                productAvatar: productImages[0],
            };
        }

        if (productData.selectAttribute && productData.attributeValue) {
            body.customFields = {
                [productData.selectAttribute]: productData.attributeValue,
            };
        }

        dispatch(inventoryActions.addSingleProduct(body));
    };

    const handleSaveMultipleProducts = () => {
        const data = productDataArr.map((productData) => {
            const body: { [key: string]: any } = {
                productName: productData.productName,
                inventoryCategory: productData.inventoryCategory,
                vendorIds: productData.vendors,
                unitOfMeasurement: productData.unitOfMeasurement,
                // productStockDetails: {
                //     unitOfMeasurement: productData.unitOfMeasurement,
                //     // "stockLevel": 14000
                // },
                productStockSettings: [
                    {
                        bufferStock: productData.bufferStockLevel,
                        minimumStockLevel: productData.minimumStockLevel,
                        maximumStockLevel: productData.maximumStockLevel,
                        warehouseId: productData.warehouse,
                    },
                ],
                isProductPerishable: productData.isProductPerishable,
                productDescription: productData.description,
            };

            if (productData?.productImages?.length > 0) {
                body.images = productData?.productImages?.slice(0, 5)?.map((img) => img);
            }

            if (productData.selectAttribute && productData.attributeValue) {
                body.customFields = {
                    [productData.selectAttribute]: productData.attributeValue,
                };
            }

            return body;
        });

        dispatch(
            inventoryActions.addMultipleProducts({ products: data, skipDuplicates: skipDuplicates })
        );
    };

    const handleUpload = (files: File[]) => {
        if (files.length > 0) {
            const filePaths = files?.map((file) => {
                return `synthally/${process.env.REACT_APP_STAGE ?? "staging"}/inventory/${
                    profile?.profile?.businessName
                }/${file?.name}`;
            });
            setUploadingFiles(true);
            firebaseService.uploadMultipleFiles(files, filePaths, (urlArr) => {
                // setUploadedUrls(urlArr);
                setProductImage(urlArr[0]);
                if (Boolean(selectedRow)) {
                    handleMultipleProductChange("productImages", urlArr, selectedRow - 1);
                }

                setProductImages((prev) => [...prev, ...urlArr]);
                setUploadingFiles(false);
            });
        }
    };

    const handleRemoveImage = () => {
        const imagePath = decodeURIComponent(
            productImages[0]?.split("/o/")?.[1]?.split("?alt")?.[0]
        );

        setDeletingImage(true);

        firebaseService.removeFile(imagePath, () => {
            const remainingImages = productImages.filter((img) => img !== productImage);
            setProductImage(remainingImages?.[0] || "");
            setProductImages(remainingImages);
            setDeletingImage(false);
            setDeleteImageModal(false);
        });
    };

    const handleInputUploadAction = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = Object.values(e.target.files)?.slice(0, 5 - productImages.length);
        const filesToUpload = [];
        const namesOfFilesNotToUpload = [];

        for (const file of files) {
            const isUploadable = isFileSizeLessThanOrEqualTo(file, 5); // Convert bytes to megabytes

            if (isUploadable) {
                filesToUpload.push(file);
            } else {
                namesOfFilesNotToUpload.push(file?.name);
            }
        }
        console.log(files);
        if (namesOfFilesNotToUpload.length > 0) {
            toast.custom((t) => (
                <CustomToast
                    t={t}
                    message={`The following file(s) are too large: ${namesOfFilesNotToUpload.join(
                        ", "
                    )}`}
                    type="error"
                />
            ));
        } else {
            handleUpload(filesToUpload);
        }
    };

    const handleAddNewWarehouse = () => {
        const body = {
            address: address?.location,
            contactPersonId: addWarehouseData.contactPerson,
            warehouseName: addWarehouseData.name,
            additionalNotes: addWarehouseData.note,
        };

        dispatch(inventoryActions.createWarehouse(body));
    };
    function handleMultipleProductChange(name: string, value: any, idx: number) {
        const newArr = [...productDataArr];
        newArr[idx][name] = value;
        setProductDataArr(newArr);
    }

    const handleDeleteRow = (idx: number) => {
        const newArr = [...productDataArr];
        newArr.splice(idx, 1);
        setProductDataArr(newArr);
    };

    const handleAddNewRow = () => {
        const newArr = [...productDataArr];
        newArr.push({
            productName: "",
            inventoryCategory: "",
            vendors: [],
            unitOfMeasurement: "",
            selectAttribute: "",
            attributeValue: "",
            description: "",
            warehouse: "",
            minimumStockLevel: "",
            maximumStockLevel: "",
            bufferStockLevel: "",
            isProductPerishable: false,
        });
        setProductDataArr(newArr);
    };

    useEffect(() => {
        if (addSingleProductSuccess) {
            navigate("/dashboard/inventory");
            dispatch(inventoryActions.resetAddSingleProductSuccess());
        }
    }, [dispatch, addSingleProductSuccess]);

    useEffect(() => {
        if (addMultipleProductsSuccess) {
            navigate("/dashboard/inventory");
            dispatch(inventoryActions.resetAddMultipleProductsSuccess());
        }
    }, [dispatch, addMultipleProductsSuccess]);

    useEffect(() => {
        dispatch(authActions.getTeamMembers(100));
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamList(fetchedTeamMemberSuccess?.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        if (createWarehouseSuccess) {
            setAddWarehouseModal(false);
            setAddWarehouseData({
                contactPerson: "",
                note: "",
                name: "",
            });
            dispatch(inventoryActions.resetCreateWarehouseSuccess());
        }
    }, [createWarehouseSuccess]);

    return (
        <div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    if (addProductMethod === "single") {
                        return handleSaveProduct();
                    }
                    if (addProductMethod === "excel") {
                        return handleSaveMultipleProducts();
                    }
                }}
                className="bg-white rounded-lg border border-slate-100"
            >
                <div className="flex items-center justify-between px-8 py-5 border-b border-slate-100">
                    <p className="text-base font-rocGroteskMedium">Add product</p>
                    <div className="flex items-center gap-3">
                        {/* <Button
                            btnText="Duplicate"
                            btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                        /> */}
                        <Button
                            btnText="Cancel"
                            btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                            disabled={addingSingleProduct || addingMultipleProducts}
                            onClick={() => navigate("/dashboard/inventory")}
                        />
                        <Button
                            btnText="Save"
                            type="submit"
                            isLoading={addingSingleProduct || addingMultipleProducts}
                            disabled={addingSingleProduct || addingMultipleProducts}
                            btnClassname="!px-4 !py-2.5 !w-fit"
                            permissions={getEditPermissionList("inventory")}
                        />
                    </div>
                </div>
                <div className="px-8 py-6 flex gap-[7.7%]">
                    <div className="w-[65%]">
                        <div className="mb-8">
                            <div className="grid grid-cols-2 gap-4 mb-4">
                                <div
                                    onClick={() => setAddProductMethod("single")}
                                    className={`flex items-center gap-2.5 p-4 rounded-md border-[1.5px] border-slate-200 cursor-pointer hover:bg-[#FFF9EA] hover:border-[#FFB903] ${
                                        addProductMethod === "single" &&
                                        "bg-[#FFF9EA] !border-[#FFB903]"
                                    }`}
                                >
                                    <i className="ri-box-1-line text-[28px]"></i>
                                    <div className="font-rocGroteskMedium">
                                        <p className="text-sm">Single product</p>
                                        <p className="text-sm text-slate-500">
                                            Manage and track a specific type within the inventory.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    onClick={() => setAddProductMethod("excel")}
                                    className={`flex items-center gap-2.5 p-4 rounded-md border-[1.5px] border-slate-200 cursor-pointer hover:bg-[#FFF9EA] hover:border-[#FFB903] ${
                                        addProductMethod === "excel" &&
                                        "bg-[#FFF9EA] !border-[#FFB903]"
                                    }`}
                                >
                                    <i className="ri-box-1-line text-[28px]"></i>
                                    <div className="font-rocGroteskMedium">
                                        <p className="text-sm">Import from Excel file</p>
                                        <p className="text-sm text-slate-500">
                                            Manage and track a specific type within the inventory.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div
                                onClick={() => setAddProductMethod("scan")}
                                className={`flex items-center gap-2.5 p-4 rounded-md border-[1.5px] border-slate-200 cursor-pointer hover:bg-[#FFF9EA] hover:border-[#FFB903] ${
                                    addProductMethod === "scan" && "bg-[#FFF9EA] !border-[#FFB903]"
                                }`}
                            >
                                <i className="ri-box-1-line text-[28px]"></i>
                                <div className="font-rocGroteskMedium">
                                    <p className="text-sm">Scan barcode</p>
                                    <p className="text-sm text-slate-500">
                                        Manage and track a specific type within the inventory.
                                    </p>
                                </div>
                            </div> */}
                        </div>
                        {/* {displayActiveMethod(addProductMethod)} */}
                        {addProductMethod === "single" && (
                            <SingleProduct
                                productData={productData}
                                handleChange={handleChange}
                                setAddWarehouseModal={setAddWarehouseModal}
                            />
                        )}
                    </div>

                    {addProductMethod === "single" && (
                        <div className="w-[27%] flex justify-end">
                            <div className="w-fit">
                                {productImages.length > 0 ? (
                                    <div className="w-[252px] ">
                                        <div className="relative h-[252px] w-full border rounded border-slate-200 ">
                                            <div
                                                className="left-[220px] absolute justify-end cursor-pointer  mt-2"
                                                onClick={() => {
                                                    setDeleteImageModal(true);
                                                }}
                                            >
                                                <img
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1705060965/Delete_icon_brttke.svg"
                                                    className="w-8 mr-2 object-contain "
                                                    alt="delete icon"
                                                />
                                            </div>
                                            <div>
                                                <img
                                                    src={productImage}
                                                    className="bg-no-repeat h-[252px] object-cover"
                                                    alt="product Avatar"
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-3 w-full flex items-center space-x-2">
                                            {productImages.map((image, idx) => (
                                                <div
                                                    className="cursor-pointer"
                                                    key={image}
                                                    onClick={() => {
                                                        setProductImage(image);
                                                    }}
                                                >
                                                    <img
                                                        src={image}
                                                        className={`bg-no-repeat h-[44px] w-[44px]  ${
                                                            productImage === image
                                                                ? "border-[1.5px] border-carribean-green"
                                                                : "border-slate-200 border"
                                                        } rounded  object-contain`}
                                                        alt={`product avatar ${idx + 1}`}
                                                    />
                                                </div>
                                            ))}
                                            {productImages?.length < 5 && (
                                                <div className="relative">
                                                    <div
                                                        onClick={() => {
                                                            const elem =
                                                                document.getElementById(
                                                                    "uploadProductImages"
                                                                );
                                                            elem?.click();
                                                        }}
                                                        className="cursor-pointer h-[44px] w-[44px] rounded border border-slate-200 border-dashed flex items-center justify-center"
                                                    >
                                                        <i className="ri-add-fill text-2xl"></i>
                                                    </div>

                                                    <input
                                                        type="file"
                                                        id="uploadProductImages"
                                                        className="absolute w-full h-full left-0 top-0 opacity-0 cursor-pointer"
                                                        accept="image/png, image/jpeg"
                                                        multiple
                                                        onChange={(e) => {
                                                            handleInputUploadAction(e);
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="w-[252px] h-[252px] relative px-6 py-12 flex items-center justify-center rounded border border-dashed bg-slate-50">
                                        {uploadingFiles ? (
                                            <Loader size={5} />
                                        ) : (
                                            <div className="w-full h-full">
                                                <div className="font-rocGroteskMedium flex flex-col justify-center items-center">
                                                    <i className="ri-upload-cloud-2-line text-[40px] text-slate-500"></i>
                                                    <p className="underline text-sm mb-2 text-center">
                                                        Add product images
                                                    </p>
                                                    <p className="text-tradeally-neutral-200 text-sm text-center">
                                                        You can add up to 5 images, each not
                                                        exceeding 5 MB.
                                                    </p>
                                                    <p className="text-tradeally-neutral-200 text-xs mt-8 text-center">
                                                        file format: JPEG, PNG
                                                    </p>
                                                </div>
                                                <input
                                                    type="file"
                                                    id="uploadProductImages"
                                                    className="absolute w-full h-full left-0 top-0 opacity-0 cursor-pointer"
                                                    accept="image/png, image/jpeg"
                                                    multiple
                                                    onChange={(e) => {
                                                        handleInputUploadAction(e);
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="px-8">
                    {addProductMethod === "excel" && (
                        <div className="w-full relative">
                            <ExcelUpload
                                productDataArr={productDataArr}
                                handleMultipleProductChange={handleMultipleProductChange}
                                handleDeleteRow={handleDeleteRow}
                                handleAddNewRow={handleAddNewRow}
                                uploadingFiles={uploadingFiles}
                                triggerImageUpload={(idx) => {
                                    const elem = document.getElementById(
                                        "uploadProductImagesForMultipleProduct"
                                    );
                                    elem?.click();
                                    setSelectedRow(idx + 1);
                                }}
                                selectedRow={selectedRow}
                                setProductDataArr={setProductDataArr}
                                skipDuplicates={skipDuplicates}
                                setSkipDuplicates={setSkipDuplicates}
                            />

                            <input
                                type="file"
                                id="uploadProductImagesForMultipleProduct"
                                className="absolute w-full h-full left-0 top-0 opacity-0 z-[-1] cursor-pointer"
                                accept="image/png, image/jpeg"
                                multiple
                                onChange={(e) => {
                                    handleInputUploadAction(e);
                                }}
                            />
                        </div>
                    )}
                </div>
            </form>

            <ModalContainer
                open={deleteImageModal}
                showCloseIcon={false}
                tailwindClassName="w-[30%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setDeleteImageModal(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="px-10 pt-3">
                        <div className="flex flex-col items-center text-center">
                            <div className="flex w-full justify-end mr-[-12px]">
                                <i
                                    onClick={() => setDeleteImageModal(false)}
                                    className="ri-close-fill text-2xl cursor-pointe"
                                ></i>
                            </div>

                            <i className="ri-delete-bin-6-line text-[32px]"></i>

                            <p className="text-xl mb-3 text-center mt-6 font-rocGroteskMedium text-g-75">
                                Delete Image?
                            </p>
                            <p className="text-sm text-center font-rocGroteskMedium text-slate-500">
                                Are you sure about deleting this image?
                            </p>
                        </div>

                        <div className="w-full flex items-center gap-2 py-6">
                            <div className="w-full">
                                <Button
                                    btnText={"No, Cancel"}
                                    type={"button"}
                                    btnClassname="!bg-tradeally-neutral-20"
                                    onClick={() => {
                                        setDeleteImageModal(false);
                                    }}
                                />
                            </div>
                            <div className="w-full">
                                <Button
                                    btnText={"Yes, delete"}
                                    type={"button"}
                                    btnClassname="!bg-r-50 !text-white"
                                    isLoading={deletingImage}
                                    onClick={() => {
                                        handleRemoveImage();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={addWarehouseModal}
                showCloseIcon={false}
                tailwindClassName="w-[42%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setAddWarehouseModal(false);
                }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleAddNewWarehouse();
                    }}
                    className="bg-white rounded-lg shadow-cardShadow relative"
                >
                    <div className="px-10 pt-3">
                        <div className="flex flex-col items-center">
                            <div className="flex w-full justify-end mr-[-12px]">
                                <i
                                    onClick={() => setAddWarehouseModal(false)}
                                    className="ri-close-fill text-2xl cursor-pointe"
                                ></i>
                            </div>

                            <p className="text-xl mb-3 text-center  font-rocGroteskMedium text-g-75">
                                Add a new warehouse location
                            </p>
                            <div className="flex flex-col gap-6 w-full">
                                <TextInput
                                    value={addWarehouseData.name}
                                    name={"name"}
                                    type={"text"}
                                    onChange={(e) =>
                                        setAddWarehouseData((prev) => ({
                                            ...prev,
                                            [e.target.name]: e.target.value,
                                        }))
                                    }
                                    placeholder={"Warehouse name"}
                                    required={true}
                                    containerClassname=""
                                />
                                <SelectInput
                                    value={addWarehouseData.contactPerson}
                                    name="contactPerson"
                                    placeholder={"Contact Person"}
                                    handleChange={(name, value) => {
                                        setAddWarehouseData((prev) => ({ ...prev, [name]: value }));
                                    }}
                                    isRequired={true}
                                    searchLoading={fetchingTeamMember}
                                    dropdownOptions={teamList?.map((team) => ({
                                        label: team?.fullName ? team?.fullName : team?.email,
                                        value: team?._id,
                                        icon: (
                                            <VendorAvatar
                                                name={team?.fullName ? team?.fullName : team?.email}
                                                imageSrc={team?.profile?.avatar}
                                                size={20}
                                            />
                                        ),
                                    }))}
                                />
                                <AddressInput
                                    setData={setAddress}
                                    savedAddress={
                                        address?.location ? address?.location?.address : ""
                                    }
                                    placeholder="Location"
                                    required={true}
                                    name={"location"}
                                />
                                <TextareaInput
                                    name={"note"}
                                    value={addWarehouseData.note}
                                    onChange={(e) =>
                                        setAddWarehouseData((prev) => ({
                                            ...prev,
                                            [e.target.name]: e.target.value,
                                        }))
                                    }
                                    rows={5}
                                    placeholder={"Additional note"}
                                    required={true}
                                    containerClassname=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full items-center justify-between px-8 py-4 border-t border-slate-200 mt-10">
                        <Button
                            btnText={"View all warehouse"}
                            type={"button"}
                            btnClassname="!bg-white !border !border-n-20 !py-3 !pl-3 !pr-4 !w-fit !h-[48px]"
                            icon={<i className="ri-external-link-line text-lg"></i>}
                            btnType="textFirst"
                            onClick={() => {
                                navigate("/dashboard/inventory/warehouse");
                            }}
                        />
                        <div className="flex items-center gap-2">
                            <Button
                                btnText={"Cancel"}
                                type={"button"}
                                btnClassname="!bg-tradeally-neutral-20 !w-fit !h-[48px]"
                                disabled={createWarehouse}
                                onClick={() => {
                                    setAddWarehouseModal(false);
                                }}
                            />
                            <Button
                                btnText={"Add warehouse"}
                                type={"submit"}
                                btnClassname="!py-3 !px-6 !w-fit !h-[48px]"
                                isLoading={createWarehouse}
                                disabled={createWarehouse}
                            />
                        </div>
                    </div>
                </form>
            </ModalContainer>
        </div>
    );
};

export default AddProduct;
