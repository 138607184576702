import { truncate } from "lodash";
import React from "react";

type ShipmentListCardProps = {
    shipTitle: string;
    activeId: string;
    origin: string;
    id: string;
    taId?: string;
    destination: string;
    onClick: (id: string) => void;
    status: string;
};

type ShipmentCardDetailsProps = {
    status?: string;
    onClose: () => void;
    description: string;
    origin: string;
    destination: string;
    etd?: string;
    eta?: string;
    mode?: string;
};

export const ShipmentListCard = ({
    shipTitle,
    activeId,
    origin,
    id,
    destination,
    taId,
    onClick,
    status,
}: ShipmentListCardProps) => {
    return (
        <div
            className={`space-y-2 border py-3 px-3 rounded-[8px] ${
                id === activeId ? "border-g-55" : "border-slate-100"
            } cursor-pointer`}
            onClick={() => onClick(id)}
        >
            <div className="flex items-center justify-between">
                <h4 className="text-slate-800 font-rocGroteskMedium text-[14px]">SYN{taId}</h4>
                <div
                    className={`flex items-center space-x-2 ${
                        status === "in-transit" && "bg-light-orange"
                    }  ${status === "delayed" && "bg-light-red"} ${
                        status === "delivered" && "bg-light-green"
                    } py-1 px-4 justify-center rounded-[25px]`}
                >
                    <div
                        className={`h-[8px] w-[8px] rounded-full ${
                            status === "in-transit" && "bg-orange"
                        }  ${status === "delayed" && "bg-red-nice"}  ${
                            status === "delivered" && "bg-g-50"
                        }`}
                    ></div>
                    <p className={`text-slate-700 text-[14px] font-rocGroteskMedium`}>
                        {status === "in-transit" && "In transit"}
                        {status === "delayed" && "Delayed"}
                        {status === "delivered" && "Delivered"}
                    </p>
                </div>
            </div>
            <p className="text-slate-800 font-rocGroteskMedium text-[14px]">{shipTitle}</p>
            <div className="flex space-x-3 items-center">
                <p className="text-slate-500 font-rocGroteskMedium text-[10px]">{origin}</p>
                <i className="ri-arrow-right-line text-slate-500 text-xs"></i>
                <p className="text-slate-500 text-[10px] font-rocGroteskMedium">{destination}</p>
            </div>
        </div>
    );
};

export const ShipmentCardDetails = ({
    status = "intransit",
    onClose,
    description,
    origin,
    destination,
    etd,
    eta,
    mode,
}: ShipmentCardDetailsProps) => {
    return (
        <div className="flex w-full justify-between  ">
            <div className="flex gap-1.5 items-start">
                <div
                    onClick={onClose}
                    className="border cursor-pointer rounded flex items-center justify-center border-slate-100 p-1 mr-[17px] h-[30px] w-[30px]"
                >
                    <i className="ri-close-fill text-2xl "></i>
                </div>
                <div className="flex space-x-4">
                    <div className="space-y-1.5">
                        <h6 className="text-slate-700 text-[18px] leading-5 font-rocGroteskMedium ">
                            {truncate(description, { length: 40 })}
                        </h6>
                        <div className="flex items-center gap-3 font-rocGroteskMedium">
                            <p className="text-slate-500  text-[14px]">{origin}</p>
                            {destination && (
                                <div className=" h-6 w-6 flex justify-center drop-shadow-sm shadow-iconShadow items-center rounded-[30px] border border-[#F4F5F7]">
                                    <i className="ri-arrow-right-line text-slate-500"></i>
                                </div>
                            )}
                            <p className="text-slate-500 text-[14px] leading-5">{destination}</p>
                        </div>
                        <div className="flex space-x-2 text-[13px] text-slate-500 leading-5 font-rocGroteskMedium">
                            {etd && <p className="pr-3">ETD: {etd}</p>}
                            {eta && <p className="border-l px-3">ETA: {eta}</p>}
                            {mode && (
                                <p className={` ${(eta || etd) && "pl-3 border-l"} capitalize`}>
                                    Mode: {mode}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div
                    className={`flex items-center space-x-2 ${
                        status === "in-transit" && "bg-light-orange"
                    }  ${status === "delayed" && "bg-light-red"} ${
                        status === "delivered" && "bg-light-green"
                    } py-1.5 w-[120px] justify-center rounded-[25px]`}
                >
                    <div
                        className={`h-[8px] w-[8px] rounded-full ${
                            status === "in-transit" && "bg-orange"
                        }  ${status === "delayed" && "bg-red-nice"}  ${
                            status === "delivered" && "bg-g-50"
                        }`}
                    ></div>
                    <p className={`text-slate-700 text-[14px] font-rocGroteskMedium`}>
                        {status === "in-transit" && "In transit"}
                        {status === "delayed" && "Delayed"}
                        {status === "delivered" && "Delivered"}
                    </p>
                </div>
            </div>
        </div>
    );
};
