/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../../../components/common/CustomTable";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import { onScroll } from "helpers";
import moment from "moment";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import Button from "components/common/Button";
import { useNavigate } from "react-router-dom";
import { truncate } from "lodash";
import Dropdown from "components/common/Dropdown";

type StockProps = {
    debouncedSearch?: string;
    location?: string;
    stockCategory?: string;
};

const StockSupplied = ({ debouncedSearch, location, stockCategory }: StockProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [limit, setLimit] = useState<number>(10);
    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [suppliedStockList, setSuppliedStockList] = useState([]);
    const [loadMore, setLoadMore] = useState<boolean>(false);
    const [paginate, setPaginate] = useState<{
        current: number | string;
        number_of_pages: number | string;
    }>({
        current: "",
        number_of_pages: "",
    });

    const { fetchingSuppliedStockList, fetchedSuppliedStockListSuccess } = useAppSelector(
        (state) => state.inventory
    );

    useEffect(() => {
        if (Boolean(fetchedSuppliedStockListSuccess)) {
            setSuppliedStockList(fetchedSuppliedStockListSuccess?.stock);
            setPaginate({
                current: fetchedSuppliedStockListSuccess?.pagination?.current,
                number_of_pages: fetchedSuppliedStockListSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedSuppliedStockListSuccess]);

    useEffect(() => {
        if (!Boolean(fetchingSuppliedStockList)) {
            dispatch(
                inventoryActions.fetchSuppliedStockList(
                    limit,
                    debouncedSearch,
                    location,
                    stockCategory
                )
            );
        }
    }, [dispatch, limit, debouncedSearch, location, stockCategory]);

    const dashboardContent = document.getElementById("dashboardContent");

    const onTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                paginate?.current as number,
                paginate?.number_of_pages as number,
                () => {
                    setLimit(() => limit + 10);
                    setLoadMore(true);
                }
            ),
        [dashboardContent, paginate]
    );
    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onTableScroll);
        };
    }, [dashboardContent, onTableScroll]);

    const handleScroll = (e) => {
        if (e.target.scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const tableHeader = [
        {
            title: "Reference",
            widthClass: "!border-b !border-slate-200 w-[150px]",
            itemClass: "",
        },
        {
            title: "Product",
            widthClass: "!border-b !border-slate-200 w-[220px]",
            itemClass: "",
        },
        {
            title: "Customer",
            widthClass: "!border-b !border-slate-200 w-[200px]",
            itemClass: "",
        },
        {
            title: "Date of supply",
            widthClass: "!border-b !border-slate-200 w-[220px]",
            itemClass: "",
        },
        {
            title: "Status",
            widthClass: "!border-b !border-slate-200 w-[140px]",
            itemClass: "",
        },
        {
            title: "Note",
            widthClass: "!border-b !border-slate-200 w-[290px]",
            itemClass: "",
        },
        {
            title: "Recorded by",
            widthClass: "!border-b !border-slate-200 w-[180px]",
            itemClass: "",
        },
        {
            title: "",
            widthClass: "!border-b !border-slate-200 w-[60px]",
            itemClass: "",
        },
    ];

    const tableBody =
        suppliedStockList?.length > 0
            ? suppliedStockList?.map((item, idx) => [
                  {
                      content: (
                          <div className="py-[18px]" key={idx}>
                              <p className=""> {item?.reference}</p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                      cellClickAction: () =>
                          navigate(`/dashboard/inventory/supplied-stock/${item?._id}`),
                  },
                  {
                      content: (
                          <div className="py-2 flex items-center gap-3">
                              <div className="flex gap-3 items-center">
                                  <div className="w-8 h-8">
                                      <img
                                          src={item?.inventory?.productImageDetails?.productAvatar}
                                          alt=""
                                          className=""
                                      />
                                  </div>
                                  <div className="">
                                      <p className="">
                                          {truncate(item?.inventory?.productName, { length: 18 })}
                                      </p>
                                      <p className="text-slate-500"> {item?.batchId}</p>
                                  </div>
                              </div>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-[18px] flex items-center gap-3">
                              {item?.customerDetails?.name}
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-[18px]">
                              <p className="">
                                  {moment(item?.dateOfSupply).format("MMM DD, YYYY • h:mm:ss A")}
                              </p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-[18px] flex items-center gap-2">
                              <p
                                  className={`rounded-full w-2 h-2 ${
                                      item?.status === "fulfilled"
                                          ? "bg-g-50"
                                          : item?.status === "inProgress"
                                          ? "bg-[#00C6FA]"
                                          : item?.status === "pending"
                                          ? "bg-[#F59E0B]"
                                          : item?.status === "rejected"
                                          ? "bg-[#FF3347]"
                                          : item?.status === "draft" && "bg-slate-300"
                                  }`}
                              ></p>
                              <p className="capitalize">
                                  {" "}
                                  {item?.status === "inProgress" ? "In Progress" : item?.status}
                              </p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-[18px]">
                              <p className="">{truncate(item?.notes, { length: 80 })}</p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-[18px] flex items-center gap-3">
                              <VendorAvatar
                                  imageSrc={item?.creator?.profile?.avatar}
                                  name={item?.creator?.profile?.businessName}
                                  size={32}
                              />

                              {item?.creator?.fullName}
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div onClick={(e) => e.stopPropagation()}>
                              <Dropdown
                                  dropdown={
                                      <i className="ri-more-2-fill before:content-['\ef76'] text-gm-50"></i>
                                  }
                                  dropdownClassName={"!w-full dropdown-container  mt-4 !mb-10 "}
                                  dropdownContainerClasses={`shadow-cardShadow-2 left-[-120px]  border-0 rounded !max-h-fit !top-[-50%]`}
                                  dropdownItemsClasses={``}
                                  name={"faq-dropdown"}
                                  dropdownOptions={[
                                      {
                                          label: "Edit Stock",
                                          value: "edit",
                                          action: () =>
                                              navigate(
                                                  `/dashboard/inventory/edit-supplied-stock/${item?._id}`
                                              ),
                                      },
                                      {
                                          label: "View Stock",
                                          value: "view",
                                          action: () =>
                                              navigate(
                                                  `/dashboard/inventory/supplied-stock/${item?._id}`
                                              ),
                                      },
                                  ]}
                              />
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
              ])
            : [];

    return (
        <>
            {fetchingSuppliedStockList && suppliedStockList?.length === 0 ? (
                <div className={`w-full min-h-[30vh]  flex items-center  justify-center`}>
                    <Loader color={"gm-50"} size={8} />
                </div>
            ) : (
                <div>
                    {suppliedStockList?.length > 0 ? (
                        <div>
                            <div
                                className="overflow-x-scroll mt-6 "
                                onScroll={handleScroll}
                                id="scrollTable"
                            >
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isScrollable={true}
                                    isScrolled={isScrolled}
                                    isCellBordered={false}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                                    headerContainerClass="!bg-slate-50"
                                    tableClass=" w-full !font-rocGroteskMedium cursor-pointer"
                                    tbodyClass="!text-sm !text-slate-900"
                                    bodyItemClass="hover:!bg-slate-50"
                                />
                            </div>

                            {loadMore && fetchingSuppliedStockList && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {paginate?.current === paginate?.number_of_pages && (
                                <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className=" min-h-[30vh] my text-slate-500  flex items-center  justify-center text-sm font-rocGroteskMedium">
                            {debouncedSearch || location || stockCategory ? (
                                <p className="">"No data matches your search query"</p>
                            ) : (
                                <div className="w-full flex justify-center">
                                    <div
                                        className="flex mt-[85px] p-10 rounded-lg border border-slate-100 text-slate-500
                        items-start gap-6 font-rocGroteskMedium max-w-[635px] shadow-tableNoDataShadow"
                                    >
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1705927221/bar-chart-fill_v4lcot.svg"
                                            alt="no data "
                                            className=""
                                        />
                                        <div className="">
                                            <p className="text-black text-base  pb-[9px]">
                                                No Supplied Stocks yet.
                                            </p>
                                            <p className="text-sm text-slate-500 pb-6">
                                                No records of stock supplies yet. Begin supplying
                                                items to track and manage your distribution
                                                history.When you do, they'll be neatly tracked here.
                                                Start by supplying stock to see your business grow
                                            </p>
                                            <Button
                                                btnText="Record supplied goods"
                                                btnClassname="!py-[10px]  !text-black !text-[13px] !w-auto !bg-[#ffb903]"
                                                onClick={() =>
                                                    navigate("/dashboard/inventory/supplied-stock")
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default StockSupplied;
