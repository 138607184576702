/* eslint-disable */
import { Editor } from "@tinymce/tinymce-react";
import Button from "components/common/Button";
import AddressInput from "components/common/InputField/AddressInput";
import { DraggableContainerList } from "components/common/InputField/DraggableInput";
import EmailInput from "components/common/InputField/EmailInput";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import ModalContainer from "components/common/ModalContainer";
import PurchaseOrderDetails from "components/views/Ldb/Orders/PurchaseOrderDetails";
import {
    displayFileIcon,
    formatMoney,
    getCurrencyFromCurrencyCode,
    getFromStorage,
    saveToStorage,
} from "helpers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { authActions, orderActions } from "redux/Ldb/actions";
import { firebaseService } from "services/firebaseService";
import countries from "variables/countries";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { toast } from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import VendorAvatar from "components/common/VendorAvatar";
import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";
import CustomTable from "components/common/CustomTable";
import config from "config/config";

const CreateOrder = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [address, setAddress] = useState<{ [key: string]: any }>({});
    const [responses, setResponses] = useState([]);
    const [showPreview, setShowPreview] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [showCancelSign, setShowCancelSign] = useState(false);
    const [showSendNow, setShowSendNow] = useState(false);
    const [uploadingPdf, setUploadingPdf] = useState(false);
    const [emails, setEmails] = useState([]);
    const [success, setSuccess] = useState(false);
    const [saveBlob, setSaveBlob] = useState([]);
    const [showSuccessPO, setShowSuccessPO] = useState(false);
    const [remainders, setRemainders] = useState(0);
    const [selectedValue, setSelectedValue] = useState("");
    const [purchaseOrderId, setPurchaseOrderId] = useState("");
    const [pdfUrl, setPdfUrl] = useState("");
    const [saveItem, setSaveItem] = useState("");
    const [selectedRFQLabel, setSelectedRFQLabel] = useState("");
    const [selectedRFQData, setSelectedRFQData] = useState({
        vendorDetails: null,
        charges: null,
    });

    const [userProfile, setUserProfile] = useState(() => getFromStorage("ally-user"));
    const [imageUrl, setImageUrl] = useState("");

    const [debouncedSearch, setDebouncedSearch] = useState({
        search: "",
    });

    useEffect(() => {
        if (userProfile?.user?.parent) {
            setImageUrl(userProfile?.user?.parent?.profile?.avatar);
        } else {
            setImageUrl(userProfile?.user?.profile?.avatar);
        }
    }, [userProfile]);
    const updateAvatar = (newAvatarValue) => {
        if (userProfile) {
            userProfile.user.profile.avatar = newAvatarValue;
            setUserProfile({ ...userProfile });
            saveToStorage("ally-user", userProfile);
        }
    };
    const [customerDetails, setCustomerDetails] = useState<{ [key: string]: any }>({
        image: "",
        issuedDate: "",
        dueDate: "",
        postalCode: "",
        country: "",
        rfqCategory: "",
        city: "",
        tax: "",
        shipping: "",
        orderDescription: "",
    });
    const [emailDetails, setEmailDetails] = useState({
        sender: userProfile?.user?.email,
        title: "",
    });

    const [containers, setContainers] = useState([]);
    const {
        createPurchaseOrder,
        createPurchaseOrderSuccess,
        fetchedApprovedResponsesSuccess,
        fetchingApprovedResponses,
        sendPurchaseOrder,
        sendPurchaseOrderSuccess,
    } = useAppSelector((state) => state.order);
    const { updateLdbProfileSuccess } = useAppSelector((state) => state.auth);

    useEffect(() => {
        if (selectedRFQData && selectedRFQData.charges) {
            const mappedContainers = selectedRFQData.charges.map((charge) => ({
                name: charge.description,
                qty: charge.quantity,
                price: {
                    amount: charge?.amount?.amount,
                    currency: selectedRFQData?.charges?.[0]?.amount?.currency,
                },
                subtotal: {
                    amount: charge?.amount?.amount * charge.quantity,
                    currency: selectedRFQData?.charges?.[0]?.amount?.currency,
                },
                inventoryId: charge.inventory,
            }));
            setContainers(mappedContainers);
        } else {
            setContainers(getDefaultContainers());
        }
    }, [selectedRFQData]);

    const today = new Date().toISOString().slice(0, 10);
    document.getElementsByName("issuedDate")[0]?.setAttribute("min", today);
    document.getElementsByName("dueDate")[0]?.setAttribute("min", today);

    function getDefaultContainers() {
        return [
            {
                name: "",
                qty: "",
                price: {
                    amount: 0,
                    currency: "",
                },
                subtotal: {
                    amount: "",
                    currency: "",
                },
            },
        ];
    }

    const handleAddContainer = () => {
        setContainers([
            ...containers,
            {
                name: "",
                qty: 0,
                price: {
                    amount: 0,
                    currency: selectedRFQData?.charges?.[0]?.amount?.currency || "",
                },
                subtotal: {
                    amount: 0,
                    currency: selectedRFQData?.charges?.[0]?.amount?.currency || "",
                },
            },
        ]);
    };

    const handleDeleteContainer = (index: number) => {
        const newContainers = [...containers];
        newContainers.splice(index, 1);
        setContainers(newContainers);
    };

    function handleMultipleItemChange(name: string, value: any, idx: number) {
        const newArr: { [key: string]: any }[] = [...containers];

        if (name === "price") {
            const qty = parseFloat(newArr[idx].qty);
            const price = parseFloat(value);
            newArr[idx].price.amount = String(price);
            newArr[idx].subtotal.amount = qty * price;
        } else {
            newArr[idx][name] = value;
        }
        setContainers(newArr);
    }
    const tableHeader = [
        { title: "Items", widthClass: "w-[60%]" },
        { title: "Qty", widthClass: "w-[11.6%]" },
        { title: "Unit price", widthClass: "w-[16.5%]" },
        { title: "Sub total", widthClass: "w-[11.6%]" },
    ];

    const tableBody = containers?.map((item, idx) => {
        return [
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 relative`}>
                        <TextInput
                            value={item.name}
                            name={"name"}
                            type={"text"}
                            required={true}
                            onChange={(e) => {
                                if (idx > selectedRFQData.charges?.length - 1) {
                                    handleMultipleItemChange(e.target.name, e.target.value, idx);
                                } else {
                                    console.log("");
                                }
                            }}
                            disabled={idx <= selectedRFQData.charges?.length - 1}
                            placeholder="Item"
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full disabled:!bg-white"
                        />
                        {idx <= selectedRFQData.charges?.length - 1 && (
                            <div className="absolute right-[14px] top-[10px] flex items-center gap-1.5 py-1 px-4 font-rocGroteskMedium rounded-[110px] w-fit bg-p-50/5">
                                <i className="ri-information-line text-p-50 text-xs"></i>
                                <span className="text-[10px] text-p-50">Non editable</span>
                            </div>
                        )}
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={item.qty}
                            name={"qty"}
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            required={true}
                            disabled={idx <= selectedRFQData.charges?.length - 1}
                            onChange={(e) => {
                                if (idx > selectedRFQData.charges?.length - 1) {
                                    handleMultipleItemChange(e.target.name, e.target.value, idx);
                                } else {
                                    console.log("");
                                }
                            }}
                            inputPlaceholder="0"
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full disabled:!bg-white"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={item?.price?.amount}
                            name={"price"}
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            required={true}
                            disabled={idx <= selectedRFQData.charges?.length - 1}
                            onChange={(e) => {
                                if (idx > selectedRFQData.charges?.length - 1) {
                                    handleMultipleItemChange(e.target.name, e.target.value, idx);
                                } else {
                                    console.log("");
                                }
                            }}
                            inputPlaceholder="0"
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full disabled:!bg-white"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 relative`}>
                        <TextInput
                            value={String(Number(item?.price?.amount) * Number(item?.qty))}
                            name={"subtotal"}
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            required={true}
                            disabled={idx <= selectedRFQData.charges?.length - 1}
                            onChange={(e) => console.log("")}
                            inputPlaceholder="0"
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full disabled:!bg-white"
                        />
                        {idx > selectedRFQData.charges?.length - 1 && (
                            <i
                                onClick={() => handleDeleteContainer(idx)}
                                className="ri-delete-bin-line text-slate-400 text-xl absolute top-[10px] right-[-40px]"
                            ></i>
                        )}
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
        ];
    });

    useEffect(() => {
        dispatch(orderActions.getApprovedResponses(20, debouncedSearch.search));
    }, [dispatch, debouncedSearch]);

    useEffect(() => {
        if (Boolean(fetchedApprovedResponsesSuccess)) {
            setResponses(fetchedApprovedResponsesSuccess?.rfqs);
        }
    }, [fetchedApprovedResponsesSuccess]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSelectedValue(value);
        setCustomerDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleUpload = (fileName, file) => {
        setUploading(true);
        firebaseService.uploadFile(
            `tradeally/${process.env.REACT_APP_STAGE}/businessLogo/${fileName}`,
            file,
            (url) => {
                setImageUrl(url);
                setUploading(false);
            }
        );
    };

    useEffect(() => {
        const body = {
            avatar: imageUrl,
        };
        if (imageUrl !== "") {
            dispatch(authActions.updateLdbProfile(body, userProfile?.token));
        }
    }, [imageUrl]);

    useEffect(() => {
        if (updateLdbProfileSuccess) {
            updateAvatar(updateLdbProfileSuccess?.profile?.avatar);
        }
    }, [updateLdbProfileSuccess]);

    const calculateTotals = () => {
        let totalSubtotals = 0;
        for (const container of containers) {
            const subtotal = parseFloat(container.subtotal.amount);
            totalSubtotals += subtotal;
        }
        const taxPercentage = parseFloat(customerDetails.tax);
        const taxAmount = (taxPercentage / 100) * totalSubtotals;
        return {
            totalSubtotals,
            taxAmount,
        };
    };
    console.log("coon", containers);
    useEffect(() => {
        const updatedContainers = containers.map((container) => ({
            ...container,
            price: {
                ...container.price,
                currency: selectedRFQData?.charges?.[0]?.amount?.currency,
            },
            subtotal: {
                ...container.subtotal,
                currency: selectedRFQData?.charges?.[0]?.amount?.currency,
            },
            inventoryId: container.inventory,
        }));

        setContainers(updatedContainers);
    }, [setContainers]);

    const { totalSubtotals, taxAmount } = calculateTotals();

    useEffect(() => {
        const correctedTaxAmount = isNaN(taxAmount) ? 0 : taxAmount;
        let remainder = totalSubtotals + correctedTaxAmount + Number(customerDetails?.shipping);
        setRemainders(remainder);
    }, [totalSubtotals, taxAmount, customerDetails]);

    const handleUploadPdf = (fileName, file) => {
        setUploadingPdf(true);
        firebaseService.uploadFile(
            `tradeally/${process.env.REACT_APP_STAGE}/document/${fileName}`,
            file,
            (url) => {
                setPdfUrl(url);
                setUploadingPdf(false);
            }
        );
    };
    useEffect(() => {
        if (saveBlob) {
            handleUploadPdf("purchase-order.pdf", saveBlob);
        }
    }, [saveBlob]);

    const handleSubmit = () => {
        if (pdfUrl !== "") {
            const body: { [key: string]: any } = {
                issuedDate:
                    moment(customerDetails.issuedDate).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
                dueDate: moment(customerDetails.dueDate).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
                itemsOrServices: containers,
                documents: [
                    {
                        filename: "purchase-order-doc.pdf",
                        path: pdfUrl,
                    },
                ],
                destination: {
                    address: address?.streetAddress?.address,
                    lng: address?.streetAddress?.lng,
                    lat: address?.streetAddress?.lat,
                    details: [customerDetails?.city],
                    country: customerDetails?.country,
                    postalCode: customerDetails?.postalCode,
                },
                quoteResponseId: customerDetails?.rfqCategory,
            };
            if (customerDetails?.orderDescription) {
                body.additionalComment = customerDetails?.orderDescription;
            }
            if (customerDetails?.tax) {
                body.tax = {
                    amount: taxAmount,
                    percentage: customerDetails?.tax,
                    currency: selectedRFQData?.charges?.[0]?.amount?.currency,
                };
            }

            if (customerDetails?.shipping) {
                body.shippingAndHandling = {
                    amount: customerDetails?.shipping,
                    currency: selectedRFQData?.charges?.[0]?.amount?.currency,
                };
            }
            dispatch(orderActions.createPurchaseOrder(body));
        }
    };

    useEffect(() => {
        if (Boolean(createPurchaseOrderSuccess)) {
            setPurchaseOrderId(createPurchaseOrderSuccess?.po?._id);
            setSuccess(true);
            setShowPreview(false);
            setShowSuccessPO(true);
        }
    }, [createPurchaseOrderSuccess]);

    const onCancel = () => {
        setCustomerDetails({
            issuedDate: "",
            dueDate: "",
            postalCode: "",
            country: "",
            rfqCategory: "",
            city: "",
            tax: "",
            shipping: "",
            orderDescription: "",
        });
        setSelectedRFQData({
            vendorDetails: null,
            charges: null,
        });
        navigate("/dashboard/purchase-orders");
    };

    const handleChangeTwo = (e) => {
        const { name, value } = e.target;
        setSelectedValue(value);
        setEmailDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const onSendNow = () => {
        const body = {
            attachment: [
                {
                    filename: "purchase-order-doc",
                    path: pdfUrl,
                },
            ],
            comment: emailDetails.title,
            toEmail: emailDetails.sender,
            bcc: emails,
        };

        dispatch(orderActions.sendPurchaseOrder(purchaseOrderId, body));
    };

    useEffect(() => {
        if (selectedRFQData?.vendorDetails?.email) {
            setEmails((prevEmails) => {
                const newEmail = selectedRFQData.vendorDetails.email;
                if (!prevEmails.includes(newEmail)) {
                    return [...prevEmails, newEmail];
                } else {
                    return prevEmails;
                }
            });
        }
    }, [selectedRFQData]);

    useEffect(() => {
        if (Boolean(sendPurchaseOrderSuccess)) {
            navigate("/dashboard/purchase-orders");
            setSuccess(false);
            setShowPreview(false);
            setCustomerDetails({
                image: "",
                issuedDate: "",
                dueDate: "",
                postalCode: "",
                country: "",
                rfqCategory: "",
                city: "",
                tax: "",
                shipping: "",
                orderDescription: "",
            });
            setEmails([]);
            setEmailDetails({
                title: "",
                sender: "",
            });
            setSelectedRFQData({
                vendorDetails: null,
                charges: null,
            });
            dispatch(orderActions.resetSendPurchaseOrderSuccess());
            dispatch(orderActions.resetCreatePurchaseOrderSuccess());
        }
    }, [sendPurchaseOrderSuccess]);

    const handleShowPreview = (e) => {
        e.preventDefault();
        if (customerDetails.country) {
            setShowPreview(true);
        } else {
            toast.custom((t) => (
                <CustomToast t={t} message={"Please select country"} type={"error"} />
            ));
        }
    };
    console.log("charges", selectedRFQData.charges);
    return (
        <>
            {!showPreview && !success && (
                <div className="flex justify-center px-3 md:px-10">
                    <div className="bg-white rounded-[8px] py-6 shadow-sm w-full sm:w-full  2xl:w-[75%] xl:w-[90%]  lg:w-[100%]  ">
                        <form onSubmit={handleShowPreview} className="relative">
                            <div className=" px-8 md:px-16 pb-6 sticky md:flex-row flex-col md:space-y-0 space-y-2 flex md:justify-between md:items-center shadow-faintShadow">
                                <p className="font-rocGroteskMedium text-gm-50 md:text-2xl">
                                    Create PO
                                </p>
                                <div className="flex md:justify-start justify-between whitespace-nowrap  space-x-3 items-center">
                                    <Button
                                        btnText={"Cancel"}
                                        type={"button"}
                                        btnClassname="!py-2 border md:boder-none border-n-20  !bg-transparent md:w-full !w-fit  !text-gm-50 !font-rocGroteskMedium !px-4"
                                        onClick={() => setShowCancelSign(true)}
                                    />
                                    <Button
                                        btnText={"Preview PO"}
                                        type={"submit"}
                                        btnClassname="!py-2 md:w-full !w-fit !font-rocGroteskMedium !px-4"
                                    />
                                </div>
                            </div>
                            <div className="h-[calc(100vh-132px)] overflow-y-auto pt-10">
                                <div className="flex px-16 justify-between items-center">
                                    <div className="flex sm:flex-row flex-col sm:space-y-0 w-full  space-y-3 sm:space-x-6 items-center mb-6 md:justify-start justify-center">
                                        <div className="border border-dashed rounded-[4px]   border-[n-40] w-[128px] h-[128px] flex items-center justify-center">
                                            <VendorAvatar
                                                containerClassname={"!rounded-[4px]"}
                                                size={127}
                                                imageSrc={imageUrl}
                                                name={userProfile?.user?.profile?.businessName}
                                            />
                                        </div>
                                    </div>
                                    <div className="bg-slate-100 px-4 py-1 md:block hidden rounded-[4px]">
                                        <p className="text-slate-500 text-[14px]">Draft</p>
                                    </div>
                                </div>
                                <div className="mt-3 md:px-16 px-8 md:flex-row flex-col flex gap-4 md:gap-10 w-full">
                                    <div className="w-full">
                                        <TextInput
                                            name={"poNumber"}
                                            type={"number"}
                                            onWheel={(e) => (e.target as any).blur()}
                                            value={""}
                                            onChange={handleChange}
                                            placeholder={
                                                "PO Number shows immediately after it's created."
                                            }
                                            required={false}
                                            containerClassname="mb-4"
                                            disabled={true}
                                        />
                                        <SelectInput
                                            value={customerDetails?.rfqCategory}
                                            name="rfqCategory"
                                            placeholder={"Select RFQ"}
                                            className="mb-3"
                                            handleInputChange={debounce((evt) => {
                                                setDebouncedSearch({
                                                    search: evt.target.value,
                                                });
                                            }, 800)}
                                            searchLoading={fetchingApprovedResponses}
                                            handleChange={(name, value) => {
                                                if (value) {
                                                    let selectedRFQData = {
                                                        vendorDetails: {},
                                                        charges: [],
                                                    };
                                                    const selectedRFQ = responses.find(
                                                        (res) => res._id === value
                                                    );
                                                    if (selectedRFQ) {
                                                        setSaveItem(selectedRFQ?.item?.description);
                                                        selectedRFQData.vendorDetails =
                                                            selectedRFQ.vendor;
                                                        selectedRFQData.charges =
                                                            selectedRFQ.charges;
                                                    }
                                                    setSelectedRFQLabel(
                                                        responses.find((res) => res._id === value)
                                                            ?.rfq?.taId
                                                    );
                                                    setSelectedRFQData(selectedRFQData);
                                                }
                                                handleChange({
                                                    target: {
                                                        name,
                                                        value,
                                                    },
                                                });
                                            }}
                                            isRequired={true}
                                            dropdownOptions={responses?.map((res) => ({
                                                label: (
                                                    <div className="font-rocGroteskMedium -mt-2">
                                                        <p className="text-slate-700 ">
                                                            {res?.rfq?.taId}
                                                        </p>
                                                        <p className="text-slate-500 ">
                                                            {res?.item?.description}
                                                        </p>
                                                    </div>
                                                ),
                                                value: res?._id,
                                            }))}
                                        />
                                        <TextInput
                                            value={
                                                selectedRFQData?.charges?.[0]?.amount?.currency ||
                                                ""
                                            }
                                            name="currency"
                                            placeholder={"Currency"}
                                            onChange={handleChange}
                                            required={true}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <div>
                                            <p className="text-[14px] text-gm-50 font-rocGroteskMedium">
                                                From
                                            </p>
                                            <div className="mt-5">
                                                <p className="text-[14px] text-gm-50 font-rocGroteskMedium">
                                                    {userProfile?.user?.profile?.businessName}
                                                </p>
                                                <p className="text-[14px] text-slate-500 font-rocGroteskRegular">
                                                    {userProfile?.user?.email}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-5">
                                            <p className="text-[14px] mb-2 text-gm-50 font-rocGroteskMedium">
                                                To
                                            </p>
                                            {selectedRFQData?.vendorDetails && (
                                                <div className="mt-5">
                                                    <p className="text-[14px] text-gm-50 font-rocGroteskMedium">
                                                        {selectedRFQData?.vendorDetails?.profile
                                                            ?.businessName
                                                            ? selectedRFQData?.vendorDetails
                                                                  ?.profile?.businessName
                                                            : `${
                                                                  selectedRFQData?.vendorDetails
                                                                      ?.firstName
                                                              } ${" "}
                                                    ${selectedRFQData?.vendorDetails?.lastName}`}
                                                    </p>
                                                    <p className="text-[14px] text-slate-500 font-rocGroteskRegular">
                                                        {selectedRFQData?.vendorDetails?.email}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex md:flex-row flex-col px-8 md:px-16 mt-8 border-b pb-5 border-slate-200 gap-4 md:gap-10 w-full">
                                    <div className="w-full md:mb-4 md:space-y-4">
                                        <TextInput
                                            name={"issuedDate"}
                                            type={"date"}
                                            value={customerDetails.issuedDate}
                                            onChange={handleChange}
                                            placeholder={"Issued date"}
                                            required={true}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <TextInput
                                            name={"dueDate"}
                                            type={"date"}
                                            value={customerDetails.dueDate}
                                            onChange={handleChange}
                                            placeholder={"Due date"}
                                            required={true}
                                            containerClassname="mb-4"
                                        />
                                    </div>
                                </div>

                                <div className="mt-10 px-8 md:px-16 border-b pb-8 border-slate-200  ">
                                    <p className="text-[14px] mb-2 text-gm-50 font-rocGroteskMedium">
                                        Ship to
                                    </p>

                                    <div className="flex md:flex-row flex-col gap-4  md:gap-10 w-full">
                                        <div className="w-full space-y-4">
                                            <AddressInput
                                                setData={setAddress}
                                                savedAddress={
                                                    address?.streetAddress
                                                        ? address?.streetAddress?.address
                                                        : ""
                                                }
                                                placeholder="Street address"
                                                required={true}
                                                name={"streetAddress"}
                                            />
                                            <TextInput
                                                name={"city"}
                                                type={"text"}
                                                value={customerDetails.city}
                                                onChange={handleChange}
                                                placeholder={"City"}
                                                required={true}
                                                containerClassname=""
                                            />
                                        </div>
                                        <div className="w-full">
                                            <TextInput
                                                name={"postalCode"}
                                                type={"text"}
                                                // onWheel={(e) => (e.target as any).blur()}
                                                value={customerDetails.postalCode}
                                                onChange={handleChange}
                                                placeholder={"Postal Code"}
                                                required={true}
                                                containerClassname="mb-4"
                                            />
                                            <SelectInput
                                                value={customerDetails.country}
                                                name="country"
                                                placeholder={"Country"}
                                                handleChange={(name, value) =>
                                                    handleChange({ target: { name, value } })
                                                }
                                                isRequired={true}
                                                dropdownOptions={countries.map((item) => ({
                                                    label: item.name,
                                                    value: item.name,
                                                }))}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {containers?.length > 0 && (
                                    <div className="mt-5 ">
                                        <div className="mt-4 pl-16 pr-[108px]">
                                            <CustomTable
                                                tableBody={tableBody}
                                                tableHeader={tableHeader}
                                                isAllSelectable={false}
                                                isCellSelectable={false}
                                                headerContainerClass="!bg-slate-100"
                                                isScrollable={false}
                                                isCellBordered={true}
                                                bodyItemClass={"hover:!bg-transparent"}
                                            />
                                        </div>
                                        <div
                                            className="flex items-center mt-7 w-fit  px-16  space-x-3 cursor-pointer pb-6"
                                            onClick={handleAddContainer}
                                        >
                                            <div className="w-[40px] h-[40px] rounded-full flex justify-center items-center border border-slate-100">
                                                <i className="ri-add-fill text-slate-500 text-[20px]"></i>
                                            </div>
                                            <p className="text-[14px] font-rocGroteskMedium text-slate-500 ">
                                                Add an item
                                            </p>
                                        </div>
                                    </div>
                                )}
                                <div className="w-full md:px-16 px-8 md:flex-row flex-col mt-5 flex   border-b pb-3 md:space-x-10">
                                    <TextInput
                                        name={"tax"}
                                        type={"number"}
                                        onWheel={(e) => (e.target as any).blur()}
                                        value={customerDetails.tax}
                                        onChange={handleChange}
                                        placeholder={"Tax"}
                                        required={false}
                                        containerClassname="mb-4"
                                        rightIcon="%"
                                    />
                                    <TextInput
                                        name={"shipping"}
                                        type={"number"}
                                        onWheel={(e) => (e.target as any).blur()}
                                        value={customerDetails.shipping}
                                        onChange={handleChange}
                                        placeholder={"Shipping and Handling (Optional)"}
                                        required={false}
                                        containerClassname="mb-4"
                                    />
                                </div>
                                <div className="mt-3 px-16 space-y-3 border-b boder-slate-100 pt-3 pb-5">
                                    <div className="flex justify-between text-[14px] font-rocGroteskMedium text-gm-50">
                                        <p>Total Amount</p>
                                        <p>
                                            {getCurrencyFromCurrencyCode(
                                                selectedRFQData?.charges?.[0]?.amount?.currency
                                            )}
                                            {isNaN(remainders)
                                                ? 0
                                                : formatMoney()?.format(remainders)}
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="mt-10 px-16 w-full">
                                    <p className="text-base mb-6 font-rocGroteskMedium text-black">
                                        Attachments
                                    </p>
                                    <div className="mt-4">
                                        <div className="flex items-center justify-between p-3 rounded-md border border-slate-200 mb-4">
                                            <div className="flex items-center gap-2">
                                                {displayFileIcon("pdf", false)}
                                                <div className="font-rocGroteskMedium">
                                                    <p className="text-sm">Inspection reports</p>
                                                    <p className="text-xs text-slate-500">4 MB</p>
                                                </div>
                                            </div>
                                            <div className="w-fit cursor-pointer">
                                                <i className="ri-delete-bin-line text-xl text-r-50"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="py-7 px-8 md:px-16">
                                    <div>
                                        <Editor
                                            apiKey={config.TINYMICE_API}
                                            value={customerDetails.orderDescription}
                                            onEditorChange={(newValue, editor) => {
                                                setCustomerDetails((prev) => ({
                                                    ...prev,
                                                    orderDescription: newValue,
                                                }));
                                            }}
                                            init={{
                                                height: 400,
                                                menubar: false,
                                                plugins: [
                                                    "advlist",
                                                    "autolink",
                                                    "lists",
                                                    "link",
                                                    "anchor",
                                                    "searchreplace",
                                                    "visualblocks",
                                                    "fullscreen",
                                                    "insertdatetime",
                                                    "media",
                                                ],
                                                placeholder:
                                                    "Add Terms and Condition,  payment terms, scope of work, and other notes",
                                                // selector: "textarea",
                                                resize: false,
                                                branding: false,
                                                toolbar:
                                                    "undo redo | casechange blocks | bold italic underline link backcolor | " +
                                                    "alignleft aligncenter alignright alignjustify | " +
                                                    "bullist numlist checklist outdent indent | removeformat",
                                                content_style:
                                                    "body { font-family:rocGrotesk,Helvetica,Arial,sans-serif; font-size:14px; }",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {showPreview && !success && (
                <div className="flex justify-center  px-2  sm:px-6 md:px-10">
                    <div className="sm:w-full w-full   2xl:w-[75%] xl:w-[90%]  lg:w-[100%] ">
                        <PurchaseOrderDetails
                            btnTextOne={"Keep editing"}
                            btnTextTwo={"Create PO"}
                            btnTextThree={"Cancel"}
                            title={"Preview PO"}
                            successPreview={false}
                            imageDetails={imageUrl}
                            setSaveBlob={setSaveBlob}
                            isLoading={createPurchaseOrder}
                            uploading={uploading}
                            handleUpload={handleUpload}
                            cancelDisabled={createPurchaseOrder}
                            disabled={createPurchaseOrder}
                            onClickOne={() => setShowPreview(false)}
                            onClickTwo={handleSubmit}
                            onClickThree={() => setShowCancelSign(true)}
                            issuedDate={moment(customerDetails?.issuedDate).format("MMM Do, YYYY")}
                            dueDate={moment(customerDetails?.dueDate).format("MMM Do, YYYY")}
                            selectedRFQData={selectedRFQData}
                            vendorName={selectedRFQData?.vendorDetails?.profile?.businessName}
                            vendorEmail={selectedRFQData?.vendorDetails?.email}
                            address={address?.streetAddress?.address}
                            taId={selectedRFQLabel}
                            postalCode={customerDetails?.postalCode}
                            userName={userProfile?.user?.profile?.businessName}
                            userEmail={userProfile?.user?.email}
                            city={customerDetails?.city}
                            item={saveItem}
                            country={customerDetails?.country}
                            containers={containers}
                            tax={isNaN(customerDetails?.tax) ? 0 : customerDetails?.tax}
                            currency={selectedRFQData?.charges?.[0]?.amount?.currency}
                            taxAmount={isNaN(taxAmount) ? 0 : taxAmount}
                            shipping={
                                isNaN(customerDetails?.shipping) ? 0 : customerDetails?.shipping
                            }
                            totalAmount={isNaN(remainders) ? 0 : remainders}
                            orderDescription={customerDetails?.orderDescription}
                        />
                    </div>
                </div>
            )}
            {success && (
                <div className="flex justify-center px-6 md:px-10">
                    <div className="sm:w-full w-full  2xl:w-[75%] xl:w-[90%]  lg:w-[100%] ">
                        <PurchaseOrderDetails
                            btnTextOne={"Download PDF"}
                            successPreview={true}
                            imageDetails={imageUrl}
                            item={saveItem}
                            disabled={false}
                            isLoading={false}
                            uploading={uploading}
                            handleUpload={handleUpload}
                            customerDetails={customerDetails}
                            imageUrl={imageUrl}
                            selectedRFQLabel={selectedRFQLabel}
                            userProfile={userProfile}
                            remainders={remainders}
                            saveItem={saveItem}
                            setPdfUrl={setPdfUrl}
                            selectedRFQData={selectedRFQData}
                            issuedDate={customerDetails?.issuedDate}
                            dueDate={customerDetails?.dueDate}
                            setShowSendNow={setShowSendNow}
                            address={address?.streetAddress?.address}
                            postalCode={customerDetails?.postalCode}
                            userName={userProfile?.user?.profile?.businessName}
                            userEmail={userProfile?.user?.email}
                            city={customerDetails?.city}
                            country={customerDetails?.country}
                            containers={containers}
                            tax={
                                isNaN(Number(customerDetails?.tax))
                                    ? 0
                                    : Number(customerDetails?.tax)
                            }
                            currency={selectedRFQData?.charges?.[0]?.amount?.currency}
                            taxAmount={isNaN(taxAmount) ? 0 : taxAmount}
                            shipping={
                                isNaN(Number(customerDetails?.shipping))
                                    ? 0
                                    : Number(customerDetails?.shipping)
                            }
                            totalAmount={isNaN(remainders) ? 0 : remainders}
                            orderDescription={customerDetails?.orderDescription}
                            title={"Preview PO"}
                            taId={selectedRFQLabel}
                            handleRemoveImage={() => setImageUrl("")}
                        />
                    </div>
                </div>
            )}

            {showSendNow && (
                <ModalContainer
                    open={showSendNow}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[400px]"
                    showCloseIcon={false}
                    closeModal={() => {
                        setShowSendNow(false);
                        dispatch(orderActions.resetCreatePurchaseOrderSuccess());
                        navigate("/dashboard/purchase-orders");
                        setSuccess(false);
                    }}
                >
                    <div className="bg-white !px-2 pt-6 pb-6  rounded-[12px]">
                        <div className="border-b pb-4  border-slate-200">
                            <div className="flex px-6 items-center justify-between">
                                <h1 className="text-black font-rocGroteskMedium text-[18px] ">
                                    Share PO
                                </h1>
                                <span
                                    className="material-icons text-gm-50 text-[24px] cursor-pointer font-medium"
                                    onClick={() => {
                                        setShowSendNow(false);
                                        navigate("/dashboard/quotes");
                                        setSuccess(false);
                                    }}
                                >
                                    close
                                </span>
                            </div>
                        </div>
                        <div className="mt-4 px-6">
                            <div className="space-y-5 ">
                                <TextInput
                                    name={"sender"}
                                    type={"text"}
                                    value={emailDetails.sender}
                                    onChange={handleChangeTwo}
                                    placeholder={"From"}
                                    required={false}
                                />
                                <EmailInput
                                    setEmails={setEmails}
                                    emails={emails}
                                    placeholder={"Email"}
                                    required={true}
                                />

                                <TextareaInput
                                    name={"title"}
                                    value={emailDetails.title}
                                    onChange={handleChangeTwo}
                                    rows={6}
                                    placeholder={"Title"}
                                    required={false}
                                />
                            </div>
                            <div className="mt-12">
                                <Button
                                    btnText={"Share PO"}
                                    type={"button"}
                                    isLoading={sendPurchaseOrder}
                                    btnClassname="!py-2 !text-[14px] !px-4"
                                    onClick={onSendNow}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}

            {showSuccessPO && (
                <ModalContainer
                    open={showSuccessPO}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[400px]"
                    showCloseIcon={false}
                    closeModal={() => {
                        dispatch(orderActions.resetCreatePurchaseOrderSuccess());
                        setShowSuccessPO(false);
                        navigate("/dashboard/purchase-orders");
                    }}
                >
                    <div className="bg-white !px-2 pt-6 pb-7  rounded-[12px]">
                        <div className="flex px-6 items-center justify-end">
                            <span
                                className="material-icons text-gm-45 text-[24px] cursor-pointer font-medium"
                                onClick={() => {
                                    dispatch(orderActions.resetCreatePurchaseOrderSuccess());
                                    setShowSuccessPO(false);
                                    navigate("/dashboard/purchase-orders");
                                }}
                            >
                                close
                            </span>
                        </div>
                        <div className="mt-4 px-6">
                            <div className="text-center flex justify-center flex-col items-center space-y-5">
                                <div className="w-14 h-14 flex justify-center items-center bg-[#00DB8F] rounded-full">
                                    <i className="ri-checkbox-circle-fill text-[29px] text-white "></i>
                                </div>
                                <div>
                                    <h3 className="text-xl text-[#333333] font-rocGroteskMedium">
                                        PO created successfully
                                    </h3>
                                    <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                        Your purchase order has been created and sent. If you want
                                        to share it with others, you can use the provided sharing
                                        options.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5">
                                <Button
                                    btnText={"Share PO"}
                                    btnClassname="!py-3 cursor-pointer !bg-[#F4F5F7] !text-[14px] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4"
                                    onClick={() => {
                                        setShowSuccessPO(false);
                                        setShowSendNow(true);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}
            {showCancelSign && (
                <ModalContainer
                    open={showCancelSign}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[400px]"
                    showCloseIcon={false}
                    closeModal={() => {
                        setShowCancelSign(false);
                    }}
                >
                    <div className="bg-white !px-2 pt-6 pb-7  rounded-[12px]">
                        <div className="flex px-6 items-center justify-end">
                            <span
                                className="material-icons text-gm-45 text-[24px] cursor-pointer font-medium"
                                onClick={() => setShowCancelSign(false)}
                            >
                                close
                            </span>
                        </div>
                        <div className="mt-4 px-6">
                            <div className="text-center flex justify-center flex-col items-center space-y-5">
                                <div className="">
                                    <img src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1697712771/Caution_sign_g1u9nv.svg" />
                                </div>
                                <div>
                                    <h3 className="text-xl text-[#333333] font-rocGroteskMedium">
                                        Discard Changes?
                                    </h3>
                                    <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                        Leaving this page will result in the loss of any unsaved
                                        progress in your purchase order creation. Are you sure you
                                        want to exit?
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 w-full flex space-x-2">
                                <div className="w-full whitespace-nowrap">
                                    <Button
                                        btnText={"No"}
                                        type={"button"}
                                        btnClassname="!py-3  !bg-n-20 !text-gm-50 !text-[14px] !px-4"
                                        onClick={() => setShowCancelSign(false)}
                                    />
                                </div>
                                <div className="w-full whitespace-nowrap">
                                    <Button
                                        btnText={"Yes, cancel"}
                                        type={"button"}
                                        btnClassname="!py-3 !w-full !bg-r-50 !text-white !text-[14px] !px-4"
                                        onClick={onCancel}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </>
    );
};

export default CreateOrder;
