import LandingFooter from "components/views/Landing/LandingFooter";
import LandingNav from "components/views/Landing/LandingNav";
import PreFooterCard from "components/views/Landing/PreFooterCard";
import RoiCalculator from "components/views/Ldb/Dashboard/Settings/RoiCalculator";
import SubscriptionWebsiteView from "components/views/Ldb/Dashboard/Settings/SubscriptionWebsiteView";

const Pricing = () => {
    return (
        <div className="bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702596337/pricing-desktop.png')] max-lg:bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702595366/pricing_zqqx7i.png')] bg-cover bg-no-repeat min-h-screen w-screen">
            <div>
                <LandingNav />
            </div>

            <div className="flex pt-32 justify-center ">
                <div className="w-[80%] max-lg:w-full px-6 relative z-[10]">
                <div className="md:text-center w-full">
                    <h1 className="text-[48px] max-lg:text-[28px] max-lg:leading-[36.4px] leading-[69.12px] font-rocGroteskMedium mb-3  text-gm-50">
                        Accessible Procurement + Project Management Software for Businesses of All
                        Sizes
                    </h1>
                    <div className="flex justify-center items-center">
                        <p className="text-xl md:w-[70%] w-full md:text-center flex justify-center max-lg:text-sm font-rocGroteskMedium text-slate-600 max-lg:w-full">
                            Synth covers all your procurement and project requirements with a
                            pricing plan for different budgets and measurable return on investment.
                        </p>
                    </div>
                </div>
                </div>
            </div>
            <div className="flex justify-center mb-[51px] md:mb-[141px]" id="pricing">
                <div className="w-[80%] max-lg:w-full px-6 relative z-[10]">
                    <SubscriptionWebsiteView />
                </div>
            </div>
            
            <div className="flex justify-center" id="calculator">
                <div className="w-[75%] max-lg:w-full px-6 relative z-[10]">
                    <p className="text-center text-[40px] max-lg:text-[28px] max-lg:px-[54px] font-rocGroteskMedium">
                        Calculate your subscription ROI
                    </p>
                    <div className="md:mt-[33px]">
                    <RoiCalculator isWebsite={true} />
                    </div>
                </div>
            </div>

            <div className="md:mt-[71px]">
                <div className="">
                    <PreFooterCard />
                </div>

                <LandingFooter />
            </div>
        </div>
    );
};

export default Pricing;
