/* eslint-disable no-unused-vars */
import ModalContainer from "components/common/ModalContainer";
import Navbar from "components/common/Navbar/Navbar";
import PageFrame from "components/layout/PageFrame";
import {ShowRolesAndResponsibiltyModal} from "components/views/Ldb/Dashboard/Teams/ShowRolesAndResponsibilty";
import AddTeamMembers from "components/views/Ldb/Onboarding/AddTeam";
import BusinessInfo from "components/views/Ldb/Onboarding/BusinessInfo";
import {getFromStorage} from "helpers";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {authActions} from "redux/Ldb/actions";
import {useAppDispatch, useAppSelector} from "../../../hooks/storeHooks";

const NewOnboard = () => {
    const [step, setStep] = useState(1);
    const [showRolesAndResponsibiltyModal, setShowRolesAndResponsibiltyModal] = useState(false);
    const [address, setAddress] = useState({});
    const [profile, setProfile] = useState<{[key: string]: any}>({});
    const [businessInfo, setBusinessInfo] = useState({
        businessCountry: "",
        businessType: "",
        businessName: "",
        fullName: "",
        reason: "",
    });

    const [teamInfo, setTeamInfo] = useState([{"email-0": "", "role-0": ""}]);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const redirectUrl = getFromStorage("redirect-url");

    const [userProfile, setUserProfile] = useState(() => getFromStorage("ally-user"));

    const {fetchUserProfileRequest, fetchUserProfileSuccess} = useAppSelector(
        (state) => state.auth
    );

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setProfile(fetchUserProfileSuccess);

            setBusinessInfo({
                businessCountry: fetchUserProfileSuccess?.profile?.country ?? "",
                businessType: fetchUserProfileSuccess?.profile?.businessType ?? "",
                businessName: fetchUserProfileSuccess?.profile?.businessName ?? "",
                reason: fetchUserProfileSuccess?.profile?.reason ?? "",
                fullName: fetchUserProfileSuccess?.fullName ?? "",
            });

            setAddress(
                fetchUserProfileSuccess?.profile?.address
                    ? {businessAddress: fetchUserProfileSuccess?.profile?.address}
                    : {}
            );
        }
    }, [fetchUserProfileSuccess]);

    useEffect(() => {
        if (userProfile) {
            dispatch(authActions.getLdbProfile(userProfile?.token));
        }
    }, [dispatch]);

    useEffect(() => {
        const hasOnboarded =
            Boolean(fetchUserProfileSuccess?.profile?.businessName) &&
            Boolean(fetchUserProfileSuccess?.profile?.country) &&
            Boolean(fetchUserProfileSuccess?.profile?.businessType) &&
            Boolean(fetchUserProfileSuccess?.fullName) &&
            Boolean(fetchUserProfileSuccess?.profile?.address);

        if (hasOnboarded) {
            navigate(redirectUrl && redirectUrl !== "/new-onboard" ? redirectUrl : "/dashboard", {
                replace: true,
            });
        } else if (userProfile?.user?.role?.toLowerCase() !== "owner") {
            navigate(redirectUrl && redirectUrl !== "/new-onboard" ? redirectUrl : "/dashboard", {
                replace: true,
            });
        }
    }, [navigate, userProfile, fetchUserProfileSuccess, redirectUrl]);

    return (
        <PageFrame containerClassName="overflow-x-hidden" isLoading={fetchUserProfileRequest}>
            <div className="w-full  h-full bg-body-1">
                <Navbar showNavDropdown profile={profile} showNav />
                <div className="min-h-[calc(100vh-84px)] flex max-sm:flex-col">
                    <div className="w-[500px] min-h-[calc(100vh-84px)] max-sm:min-h-fit max-sm:w-full max-sm:px-4 max-sm:py-5 px-7 pt-16 bg-slate-100">
                        <h6 className="font-rocGroteskMedium text-[18px] text-gm-50">
                            Setting up your workspace
                        </h6>
                        <div className="mt-16 max-sm:mt-4">
                            <div className="flex items-center space-x-3">
                                {(step === 1 || step === 2) && (
                                    <div className="rounded-full cursor-pointer">
                                        <i className="ri-checkbox-circle-fill text-[14px] border-[2px]  rounded-full border-g-55  text-g-55"></i>
                                    </div>
                                )}
                                <p
                                    className={`text-slate-700
                                     font-rocGroteskMedium text-[13px] cursor-pointer`}
                                >
                                    About you and your business
                                </p>
                            </div>
                            <div className=" w-[15px] flex flex-col items-center mt-1 ">
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1694524842/Vector_310_1_z49p39.svg"
                                    alt=""
                                />
                            </div>
                            <div className="flex mt-1 items-center space-x-3">
                                {step === 2 ? (
                                    <div className="rounded-full cursor-pointer">
                                        <i className="ri-checkbox-circle-fill text-[14px] border-[2px]  rounded-full border-g-55  text-g-55"></i>
                                    </div>
                                ) : (
                                    <div className="rounded-full border h-[14px] w-[14px] border-slate-400 cursor-pointer"></div>
                                )}
                                <p
                                    className={`${
                                        step === 2 ? "text-slate-700" : "text-slate-500"
                                    } font-rocGroteskMedium text-[13px] cursor-pointer`}
                                >
                                    Collaborate with your team members
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className=" mt-[5%] w-full  flex justify-center">
                        {step === 1 && (
                            <BusinessInfo
                                activeStep={step}
                                setActiveStep={setStep}
                                businessInfo={businessInfo}
                                setBusinessInfo={setBusinessInfo}
                                setAddress={setAddress}
                                address={address}
                            />
                        )}
                        {step === 2 && (
                            <AddTeamMembers
                                setActiveStep={setStep}
                                teamInfo={teamInfo}
                                setTeamInfo={setTeamInfo}
                                setShowRolesAndResponsibiltyModal={
                                    setShowRolesAndResponsibiltyModal
                                }
                            />
                        )}
                        <ModalContainer
                            modalClassName={"py-10 px-10"}
                            open={showRolesAndResponsibiltyModal}
                            showCloseIcon={false}
                            closeModal={() => {
                                setShowRolesAndResponsibiltyModal(false);
                            }}
                            tailwindClassName="w-[90%]  sm:w-[600px] md:w-[768px]"
                        >
                            <ShowRolesAndResponsibiltyModal />
                        </ModalContainer>
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default NewOnboard;
