import React, {useState, useEffect} from "react";

type TypingEffectProps = {
    text: string,
    typingSpeed: number,
};

const TypingEffect = ({text, typingSpeed}: TypingEffectProps) => {
    const [displayedText, setDisplayedText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (currentIndex < text?.length) {
            const timer = setTimeout(() => {
                setDisplayedText(text?.slice(0, currentIndex + 1));
                setCurrentIndex(currentIndex + 1);
            }, typingSpeed);
            return () => clearTimeout(timer);
        }
    }, [currentIndex, text, typingSpeed]);

    return <span>{displayedText}</span>;
};

export default TypingEffect;
