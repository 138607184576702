import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import TabsContainer from "components/common/TabsContainer";
import Documents from "components/views/Ldb/Dashboard/Tasks/Documents";
import React, { useEffect, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks";
import { Link, useNavigate } from "react-router-dom";
import { authActions, taskActions, workFlowActions } from "redux/Ldb/actions";
import Comments from "./Comments";
import DetailsTab from "./DetailsTab";
import VendorAvatar from "components/common/VendorAvatar";
import QuoteAnalysis from "./QuoteAnalysis";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";

type TaskDetailsModalProps = {
    id: string;
    openModal: boolean;
    inviteMember: boolean;
    setInviteMember: any;
    closeModal: () => void;
    setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
};

const TaskDetailsModal = ({
    id,
    openModal,
    closeModal,
    inviteMember,
    setInviteMember,
}: TaskDetailsModalProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const timelineRef = useRef<HTMLDivElement>(null);
    const [activeTab, setActiveTab] = useState(0);
    const [activeAiTab, setActiveAiTab] = useState(0);
    const [singleTask, setSingleTask] = useState<{ [key: string]: any } | undefined>();
    const [profile, setProfile] = useState<{ [key: string]: any }>({});
    const [lineManager, setLineManager] = useState<{ [key: string]: any } | undefined>();
    const [assignee, setAssignee] = useState<{ [key: string]: any } | undefined>();
    const [assigneeId, setAssigneeId] = useState();
    const [teamMembers, setTeamMembers] = useState<{ [key: string]: any }[] | undefined>();
    const [searchString, setSearchString] = useState<string | undefined>();
    const [priority, setPriority] = useState<string | undefined>();
    const [open, setOpen] = useState<boolean | undefined>();
    const [inputDuration, setInputDuration] = useState<{ [key: string]: any }>({});
    const [formatDuration, setFormatDuration] = useState("");
    const [initial, setInitial] = useState("");
    const [status, setStatus] = useState("");
    const [done, setDone] = useState("");
    const { fetchingSingleTaskSuccess, fetchingSingleTask, updateTaskSuccess, updateTask } =
        useAppSelector((state) => state.task);
    const { fetchUserProfileSuccess, fetchedTeamMemberSuccess } = useAppSelector(
        (state) => state.auth
    );
    const { markingActionAsRejected, markActionAsRejectedSuccess } = useAppSelector(
        (state) => state.workFlow
    );
    const handleTimeline = (
        e: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }
    ) => {
        const { name, value } = e.target;
        if (name === "unit") {
            setInputDuration({
                ...inputDuration,
                [name]: value,
            });
        } else {
            setInputDuration({
                ...inputDuration,
                [name]: value,
            });
        }
    };

    useEffect(() => {
        if (inputDuration.value && inputDuration.unit) {
            setOpen(false);
            const result = inputDuration.unit.replace(/\(s\)/g, "").toLowerCase();
            setFormatDuration(
                `${inputDuration.value} ${inputDuration.value > 1 ? `${result}s` : result}`
            );
            const duration = {
                value: inputDuration?.value,
                unit: `${result}s`,
            };
            const body = {
                duration,
            };
            dispatch(taskActions.updateTask(body, singleTask?._id));
        }
    }, [inputDuration]);

    const filteredData = searchString
        ? teamMembers?.filter((item) => item?.email?.toLowerCase()?.includes(searchString))
        : teamMembers;

    const handleSearch = (
        event: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }
    ) => {
        const search = event.target.value.toLowerCase();
        setSearchString(search);
    };

    const handleStatusChange = () => {
        const body = {
            status: "completed",
        };
        setDone("completed");
        dispatch(taskActions.updateTask(body, singleTask?._id));
    };

    useEffect(() => {
        if (
            (Boolean(updateTaskSuccess) || Boolean(markActionAsRejectedSuccess)) &&
            done === "completed"
        ) {
            setStatus("completed");
        }
    }, [dispatch, updateTaskSuccess, markActionAsRejectedSuccess]);

    const handleAssigneeChange = (
        e: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }
    ) => {
        const { value } = e.target;
        const selectedMember = filteredData?.find((member) => member._id === value);
        setAssignee(selectedMember);
        setAssigneeId(selectedMember?._id);
    };

    const handleChangePriority = (
        e: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }
    ) => {
        const { value } = e.target;
        setPriority(value);
    };

    useEffect(() => {
        if (priority) {
            const body = {
                priority,
            };
            dispatch(taskActions.updateTask(body, singleTask?._id));
        }
    }, [priority]);

    useEffect(() => {
        if (assigneeId) {
            const body = {
                assigneeId,
            };
            dispatch(taskActions.updateTask(body, singleTask?._id));
        }
    }, [assigneeId]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                timelineRef.current &&
                !timelineRef.current.contains(event.target as Node) &&
                open
            ) {
                setOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [timelineRef, open]);

    useEffect(() => {
        if (Boolean(teamMembers) && Boolean(singleTask)) {
            const filterLineManager = teamMembers?.filter(
                (item) => item._id === singleTask?.lineManagerId
            );
            setLineManager(filterLineManager?.[0]);
        }
        if (Boolean(teamMembers) && Boolean(singleTask)) {
            setAssignee(singleTask?.action?.assignee?.[0]);
        }
    }, [teamMembers, singleTask]);

    useEffect(() => {
        dispatch(authActions.getLdbProfile());
        dispatch(authActions.getTeamMembers());
    }, []);

    useEffect(() => {
        dispatch(taskActions.fetchSingleTask(id));
    }, [id]);

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setProfile(fetchUserProfileSuccess);
        }
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamMembers(fetchedTeamMemberSuccess?.teamMembers);
        }
        if (Boolean(fetchingSingleTaskSuccess)) {
            setSingleTask(fetchingSingleTaskSuccess.task);
            const duration = fetchingSingleTaskSuccess?.task?.action?.duration;
            const result = duration?.unit.replace(/s/g, "").toLowerCase();
            setInitial(`${duration.value} ${duration.value > 1 ? `${result}s` : result}`);
        }
        setAssigneeId(fetchingSingleTaskSuccess?.task?.action?.assignee?._id);
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamMembers(fetchedTeamMemberSuccess?.teamMembers);
        }
    }, [fetchUserProfileSuccess, fetchedTeamMemberSuccess, fetchingSingleTaskSuccess]);

    useEffect(() => {
        if (Boolean(updateTaskSuccess) || Boolean(markActionAsRejectedSuccess)) {
            dispatch(taskActions.fetchTaskList(20));
            dispatch(taskActions.fetchPendingTaskList(20, "pending"));
            dispatch(taskActions.fetchOverdueTaskList(20, "delayed"));
            dispatch(taskActions.fetchCompletedTaskList(20, "completed"));
            dispatch(taskActions.fetchActiveTaskList(20, "on-track"));
            dispatch(taskActions.resetUpdateTask());
            dispatch(workFlowActions.resetMarkActionAsRejectedSuccess());
            closeModal();
        }
    }, [updateTaskSuccess, markActionAsRejectedSuccess]);

    const tabs = ["Details", "Attachments", "Comments"];

    const getAiTabs = () => {
        const allAiTabs = ["Generated RFQ", "Generated PO", "Generated email", "Evaluated quote"];
        const aiTabs = [];

        if (
            singleTask?.action?.data?.rfqIds?.length > 0 ||
            singleTask?.action?.workFlowRun?.data?.rfqIds?.length > 0
        ) {
            aiTabs.push(allAiTabs[0]);
        }

        if (
            singleTask?.action?.data?.poIds?.length > 0 ||
            singleTask?.action?.workFlowRun?.data?.poIds?.length > 0
        ) {
            aiTabs.push(allAiTabs[1]);
        }

        if (
            Boolean(
                singleTask?.action?.data?.craftedEmail ||
                    Boolean(singleTask?.action?.workFlowRun?.data?.craftedEmail)
            )
        ) {
            aiTabs.push(allAiTabs[2]);
        }

        if (
            Boolean(singleTask?.action?.data?.quoteId) ||
            Boolean(singleTask?.action?.workFlowRun?.data?.quoteId)
        ) {
            aiTabs.push(allAiTabs[3]);
        }

        return aiTabs;
    };

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return tabs.includes("Details") && <DetailsTab singleTask={singleTask} />;

            case 1:
                return (
                    tabs.includes("Attachments") && (
                        <Documents
                            documents={singleTask?.action?.documents}
                            profile={profile}
                            taskId={singleTask?._id}
                        />
                    )
                );

            case 2:
                return (
                    tabs.includes("Comments") && (
                        <Comments actionId={singleTask?.action?._id} profile={profile} />
                    )
                );

            default:
                return (
                    tabs.includes("Attachments") && (
                        <Documents
                            documents={singleTask?.action?.documents}
                            profile={profile}
                            taskId={singleTask?._id}
                        />
                    )
                );
        }
    };

    const displayActiveAiTab = () => {
        const associatedComponent = {
            "Generated RFQ": (
                <div className="mt-6 flex flex-nowrap w-full overflow-x-auto items-center gap-5">
                    {(
                        singleTask?.action?.data?.rfqIds ||
                        singleTask?.action?.workFlowRun?.data?.rfqIds
                    )?.map((rfq) => (
                        <Link
                            to={`/dashboard/rfq/${rfq?._id}`}
                            key={rfq?._id}
                            className="p-4 max-sm:py-2 w-[234px] flex-shrink-0 flex items-center gap-4 cursor-pointer bg-[#F0FDF4] 
                            border-[2px] border-[#4ADE80] rounded"
                        >
                            <div className="w-11 h-11 bg-[#4ADE80] rounded flex items-center justify-center ">
                                <i className="ri-file-list-3-fill text-xl text-white"></i>
                            </div>
                            <div>
                                <p className="text-sm font-rocGroteskMedium">RFQ #{rfq?.taId}</p>
                                <p className="text-xs text-slate-700 font-rocGroteskMedium">
                                    Click to view
                                </p>
                            </div>
                        </Link>
                    ))}
                </div>
            ),
            "Generated PO": (
                <div className="mt-6 flex flex-nowrap w-full overflow-x-auto items-center gap-5">
                    {(
                        singleTask?.action?.data?.poIds ||
                        singleTask?.action?.workFlowRun?.data?.poIds
                    )?.map((po) => (
                        <Link
                            to={`/dashboard/purchase-orders/${po?._id}`}
                            key={po?._id}
                            className="p-4 max-sm:py-2 w-[234px] flex-shrink-0 flex items-center gap-4 cursor-pointer bg-[#E0E0FA80] border-[2px] border-p-50 rounded"
                        >
                            <div className="w-11 h-11 bg-p-50 rounded flex items-center justify-center ">
                                <i className="ri-file-list-3-fill text-xl text-white"></i>
                            </div>
                            <div>
                                <p className="text-sm font-rocGroteskMedium">PO #{po?.taId}</p>
                                <p className="text-xs text-slate-700 font-rocGroteskMedium">
                                    Click to view
                                </p>
                            </div>
                        </Link>
                    ))}
                </div>
            ),
            "Generated email": (
                <div className="mt-4">
                    {singleTask?.action?.name?.toLowerCase() === "sendemail" && (
                        <div className="px-4 mb-4 py-1.5 flex flex-col gap-2 rounded bg-slate-50">
                            <p className="flex gap-1 items-center text-sm text-slate-700 font-rocGroteskMedium">
                                <span className="font-rocGroteskBold">Sent by:</span>
                                <span>frank@gpc.co</span>
                            </p>
                            <p className="flex gap-1 items-center text-sm text-slate-700 font-rocGroteskMedium">
                                <span className="font-rocGroteskBold">to:</span>
                                <span>frank@gpc.co</span>
                            </p>
                        </div>
                    )}
                    <div className="bg-slate-50 px-4 py-2.5">
                        <p className="text-base text-slate-900 mb-4 font-rocGroteskMedium">
                            Subject:{" "}
                            {singleTask?.action?.data?.email?.subject ||
                                singleTask?.action?.workFlowRun?.data?.email?.subject}
                        </p>
                        <p className="text-sm leading-[24px]">
                            {singleTask?.action?.data?.craftedEmail ||
                                singleTask?.action?.workFlowRun?.data?.craftedEmail}
                        </p>
                    </div>
                </div>
            ),
            "Evaluated quote": (
                <QuoteAnalysis
                    id={
                        singleTask?.action?.data?.quoteId ||
                        singleTask?.action?.workFlowRun?.data?.quoteId
                    }
                />
            ),
        };

        return associatedComponent[getAiTabs()[activeAiTab]];
    };

    const handleRejectTask = () => {
        const body = {
            actionId: singleTask?.action?._id,
        };
        setDone("completed");
        dispatch(workFlowActions.markActionAsRejected(body));
    };

    return (
        <>
            {!fetchingSingleTask && (
                <>
                    <ModalContainer
                        open={openModal && !inviteMember}
                        showCloseIcon={false}
                        closeModal={closeModal}
                        tailwindClassName="w-[90%] md:w-[650px] "
                    >
                        <div className="bg-white flex h-full  flex-col rounded-[12px] max-h-[90vh] overflow-y-scroll">
                            <div className=" mt-6 px-[30px] flex gap-4 items-center ">
                                <i
                                    className="ri-close-fill cursor-pointer text-gm-45 text-2xl border-[#E2E8F0] border-[1px] rounded-[4px]
                        px-1"
                                    onClick={closeModal}
                                ></i>
                                <p className="text-lg !leading-[20px] text-[#0F172A] font-rocGroteskMedium">
                                    Task Details
                                </p>
                            </div>

                            <div className="px-7 pt-9 pb-6 ">
                                <div className="max-h-[450px] overflow-y-auto">
                                    <h1 className="text-[#334155] font-rocGroteskBold text-2xl max-sm:text-lg mb-[21px] capitalize">
                                        {camelCaseToRegularCase(singleTask?.name)}
                                        <span className="text-[#12A589] ml-1">
                                            {`#${singleTask?.action?.taId}`}
                                        </span>
                                    </h1>
                                    <div className="flex items-center gap-6 font-rocGroteskMedium">
                                        <p
                                            className={`${
                                                singleTask?.status === "completed"
                                                    ? "bg-g-25"
                                                    : singleTask?.status === "delayed" ||
                                                      singleTask?.status === "off-track"
                                                    ? "bg-r-25"
                                                    : singleTask?.status === "on-track"
                                                    ? "bg-p-25"
                                                    : "bg-[rgba(249,223,179,0.6)]"
                                            } text-[#0F172A] py-[10px] px-[15px] rounded-[25px]  text-[14px] capitalize `}
                                        >
                                            {singleTask?.status === "delayed" ||
                                            singleTask?.status === "off-track"
                                                ? "overdue"
                                                : singleTask?.status === "disabled"
                                                ? "not active"
                                                : singleTask?.status === "on-track"
                                                ? "active"
                                                : (singleTask?.status === "disabled" ||
                                                      singleTask?.status === "completed" ||
                                                      singleTask?.status === "pending") &&
                                                  singleTask?.status}
                                        </p>
                                        {/* {singleTask?.status !== "completed" &&
                                            singleTask?.action?.actionCategory?.toLowerCase() ===
                                                "approval" &&
                                            singleTask?.action?.name?.toLowerCase() ===
                                                "approvepo" && (
                                                <Button
                                                    isLoading={updateTask}
                                                    btnText={"Approve PR"}
                                                    type={"button"}
                                                    btnType={"textFirst"}
                                                    btnClassname="!py-2.5 !px-4 !text-[13px] !bg-n-20 !py-2 !text-gm-50 !px-4 !w-fit !h-[40px]"
                                                    icon={
                                                        <i className="ri-arrow-right-s-line text-gm-50 "></i>
                                                    }
                                                    onClick={() =>
                                                        navigate(
                                                            `/dashboard/purchase-orders/${
                                                                singleTask?.action?.data?.poIds?.[0]
                                                                    ?._id ||
                                                                singleTask?.action?.workFlowRun
                                                                    ?.data?.poIds?.[0]?._id
                                                            }`
                                                        )
                                                    }
                                                />
                                            )} */}
                                    </div>

                                    <div className="my-8 flex gap-[14px] flex-col">
                                        <div className="flex items-center text-sm max-sm:grid max-sm:grid-cols-[0.7fr_1.3fr]">
                                            <p className="text-[#64748B] font-rocGroteskMedium w-[166px] max-sm:w-fit">
                                                Reporter
                                            </p>
                                            <div className="flex items-center gap-2 py-1">
                                                <VendorAvatar
                                                    size="24"
                                                    containerClassname="rounded-full"
                                                    name={profile?.fullName}
                                                    imageSrc={profile?.profile?.avatar}
                                                />
                                                <p className="text-[#0F172A] font-rocGroteskMedium">
                                                    {profile?.fullName}
                                                </p>
                                            </div>
                                        </div>
                                        {lineManager && (
                                            <div className="flex items-center text-sm max-sm:grid max-sm:grid-cols-[0.7fr_1.3fr]">
                                                <p className="text-[#64748B] font-rocGroteskMedium w-[166px] max-sm:w-fit">
                                                    Line Manager
                                                </p>
                                                <div className="flex items-center gap-2 py-1">
                                                    <VendorAvatar
                                                        size="24"
                                                        containerClassname="rounded-full"
                                                        name={lineManager?.fullName}
                                                        imageSrc={lineManager?.profile?.avatar}
                                                    />
                                                    <p className="text-[#0F172A] font-rocGroteskMedium">
                                                        {lineManager?.fullName
                                                            ? lineManager?.fullName
                                                            : lineManager?.email}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        {assignee && (
                                            <div className="flex items-center text-sm max-sm:grid max-sm:grid-cols-[0.7fr_1.3fr]">
                                                <p className="text-[#64748B] font-rocGroteskMedium w-[166px] max-sm:w-fit">
                                                    Assignee
                                                </p>

                                                <Dropdown
                                                    name="assignee"
                                                    value={assignee}
                                                    handleChange={(name, value) =>
                                                        handleAssigneeChange({
                                                            target: { name, value },
                                                        })
                                                    }
                                                    search={
                                                        <div
                                                            className="border border-slate-200 rounded-[4px] flex py-[5px] px-3 items-center
                                   gap-2"
                                                        >
                                                            <i className="ri-search-line text-slate-400 text-[16px] font-rocGroteskMedium"></i>
                                                            <input
                                                                type="text"
                                                                placeholder="Search team members"
                                                                className="outline-none text-[13px] leading-[24px]"
                                                                name="searchString"
                                                                value={searchString}
                                                                onChange={handleSearch}
                                                            />
                                                        </div>
                                                    }
                                                    dropdown={
                                                        <div className="flex space-x-2 items-center py-1">
                                                            {assignee ? (
                                                                <>
                                                                    <VendorAvatar
                                                                        size="24"
                                                                        containerClassname="rounded-full"
                                                                        name={assignee?.fullName}
                                                                        imageSrc={
                                                                            assignee?.profile
                                                                                ?.avatar
                                                                        }
                                                                    />
                                                                    <div className="font-rocGroteskMedium py-1 text-sm text-[#0F172A]">
                                                                        {assignee?.fullName
                                                                            ? assignee?.fullName
                                                                            : assignee?.email}
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="text-slate-300 font-rocGroteskMedium py-1 text-sm">
                                                                    Select Assignee
                                                                </div>
                                                            )}
                                                        </div>
                                                    }
                                                    dropdownClassName={
                                                        "!w-[159px] !h-[418px] !mb-10 !-mt-7"
                                                    }
                                                    dropdownContainerClasses={`shadow-cardShadow-4 !left-[0px] !mt-0  border border-slate-100 w-[159px] rounded !max-h-fit
                                            !h-[418px]  !top-[100%]
                                    `}
                                                    dropdownOptions={[
                                                        {
                                                            label: (
                                                                <button
                                                                    onClick={() => {
                                                                        setInviteMember(true);
                                                                    }}
                                                                    className="additional-label text-p-50 text-xs flex gap-[10px] items-center"
                                                                >
                                                                    <i className="ri-add-line text-[16px]"></i>
                                                                    Invite team member
                                                                </button>
                                                            ),
                                                        },
                                                        ...(filteredData?.map((item) => ({
                                                            label: (
                                                                <div
                                                                    key={item.email}
                                                                    className="text-gm-50 font-rocGroteskMedium py-1 text-xs flex items-center gap-2"
                                                                >
                                                                    <VendorAvatar
                                                                        size="24"
                                                                        containerClassname="rounded-full"
                                                                        name={item?.fullName}
                                                                        imageSrc={
                                                                            item?.profile?.avatar
                                                                        }
                                                                    />

                                                                    {item?.fullName
                                                                        ? item.fullName
                                                                        : item.email}
                                                                </div>
                                                            ),
                                                            value: item._id,
                                                        })) || []),
                                                    ]}
                                                />
                                            </div>
                                        )}

                                        <div className="flex items-center text-sm max-sm:grid max-sm:grid-cols-[0.7fr_1.3fr]">
                                            <p className="text-[#64748B] font-rocGroteskMedium w-[166px] max-sm:w-fit">
                                                Timeline
                                            </p>
                                            <div className="relative cursor-pointer py-1">
                                                <div
                                                    className={`text-gm-50 text-sm font-rocGroteskMedium flex gap-[10px] items-center bg-slate-50 py-[5px] px-[10px] rounded`}
                                                    onClick={() => setOpen(!open)}
                                                >
                                                    <i className="ri-calendar-2-line text-gm-40"></i>
                                                    {!formatDuration ? initial : formatDuration}
                                                </div>
                                                {open && (
                                                    <div
                                                        ref={timelineRef}
                                                        className="absolute z-[100] max-sm:left-[-120px] bg-[#fff] p-5 w-[338px] shadow-cardShadow-4 radius-[8px] "
                                                    >
                                                        <p className="text-[#142837] text-sm pb-[10px]">
                                                            Set timeline
                                                        </p>
                                                        <div className="flex gap-[14px]">
                                                            <TextInput
                                                                type="number"
                                                                placeholder="Enter number"
                                                                inputContainerClassname="!border !bg-[#fff] !border-gm-25 "
                                                                inputClassName="!hover-border-gm-25 !text-slate-400 text-base !h-[48px]"
                                                                name="value"
                                                                value={inputDuration.value}
                                                                onChange={handleTimeline}
                                                                min="0"
                                                            />
                                                            <Dropdown
                                                                dropdownTriggerClassName={" "}
                                                                dropdown={
                                                                    <div className="space-x-2 w-[137px] border border-gm-25 h-[49px] px-4 py-4 rounded-[4px] flex items-center !justify-between">
                                                                        <div className=" text-slate-400 py-1 text-center gap-[6px] ">
                                                                            {inputDuration.unit
                                                                                ? inputDuration.unit
                                                                                : "Duration"}
                                                                        </div>
                                                                        <i className="ri-arrow-down-s-line text-lg text-slate-400"></i>
                                                                    </div>
                                                                }
                                                                dropdownClassName={
                                                                    "!w-full !mb-10 !-mt-7"
                                                                }
                                                                dropdownContainerClasses={`shadow-cardShadow-4 left-[0px] !w-[137px] rounded !max-h-fit !pb-0 !justify-between
                            `}
                                                                handleChange={(name, value) =>
                                                                    handleTimeline({
                                                                        target: { name, value },
                                                                    })
                                                                }
                                                                dropdownItemsClasses="!bg-transparent "
                                                                name={"unit"}
                                                                value={inputDuration.unit}
                                                                dropdownOptions={[
                                                                    {
                                                                        label: (
                                                                            <div className=" text-slate-700 font-rocGroteskMedium py-1 text-xs text-center  ">
                                                                                Minute(s)
                                                                            </div>
                                                                        ),
                                                                        value: "Minute(s)",
                                                                    },
                                                                    {
                                                                        label: (
                                                                            <div className=" text-slate-700 font-rocGroteskMedium py-1 text-xs text-center  ">
                                                                                Hour(s)
                                                                            </div>
                                                                        ),
                                                                        value: "Hour(s)",
                                                                        action: () => {
                                                                            navigate("");
                                                                        },
                                                                    },
                                                                    {
                                                                        label: (
                                                                            <div className=" text-slate-700 font-rocGroteskMedium py-1 text-xs text-center  ">
                                                                                Day(s)
                                                                            </div>
                                                                        ),
                                                                        value: "Day(s)",
                                                                        action: () => {
                                                                            navigate("");
                                                                        },
                                                                    },
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex items-center text-sm max-sm:grid max-sm:grid-cols-[0.7fr_1.3fr]">
                                            <p className="text-[#64748B] font-rocGroteskMedium w-[166px] max-sm:w-fit">
                                                Priority Level
                                            </p>
                                            <div>
                                                <Dropdown
                                                    handleChange={(name, value) =>
                                                        handleChangePriority({
                                                            target: { name, value },
                                                        })
                                                    }
                                                    dropdown={
                                                        <div className="flex space-x-2 ">
                                                            <div
                                                                className={`px-2 text-slate-700 font-rocGroteskMedium py-1 text-xs rounded text-center flex items-center gap-[6px] ${
                                                                    priority === "low" ||
                                                                    singleTask?.priority === "low"
                                                                        ? "bg-[#E2E8F0]"
                                                                        : priority === "medium" ||
                                                                          singleTask?.priority ===
                                                                              "medium"
                                                                        ? "bg-[#AAC6EB]"
                                                                        : "bg-[#FBECD1]"
                                                                } capitalize`}
                                                            >
                                                                {priority === "low" ||
                                                                singleTask?.priority === "low" ? (
                                                                    <i className="ri-battery-low-line text-[#64748B] text-base"></i>
                                                                ) : priority === "medium" ||
                                                                  singleTask?.priority ===
                                                                      "medium" ? (
                                                                    <i className="ri-scales-2-fill text-[#2B71CC] text-base"></i>
                                                                ) : (
                                                                    <i className="ri-fire-fill text-[#FF5D43] text-base"></i>
                                                                )}
                                                                {priority
                                                                    ? priority
                                                                    : singleTask?.priority}
                                                            </div>
                                                            <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                                        </div>
                                                    }
                                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                                    dropdownContainerClasses={`shadow-cardShadow-4 -left-[10px] !mt-3  border border-slate-100 w-[159px] rounded !max-h-fit
                            `}
                                                    name="priority"
                                                    dropdownOptions={[
                                                        {
                                                            label: (
                                                                <div className="bg-[#FBECD1] px-2 text-slate-700 font-rocGroteskMedium py-1 text-xs rounded-[4px] text-center flex items-center gap-[6px]">
                                                                    <i className="ri-fire-fill text-[#FF5D43]"></i>{" "}
                                                                    High
                                                                </div>
                                                            ),
                                                            value: "high",
                                                        },
                                                        {
                                                            label: (
                                                                <div className="bg-[#AAC6EB] px-2 text-slate-700 font-rocGroteskMedium py-1 text-xs rounded-[4px] text-center flex items-center gap-[6px]">
                                                                    <i className="ri-scales-2-fill text-[#2B71CC]"></i>
                                                                    Medium
                                                                </div>
                                                            ),
                                                            value: "medium",
                                                            action: () => {},
                                                        },
                                                        {
                                                            label: (
                                                                <div className="bg-[#E2E8F0] px-2 text-slate-700 font-rocGroteskMedium py-1 text-xs rounded-[4px] text-center flex items-center gap-[6px]">
                                                                    <i className="ri-battery-low-line text-[#64748B]"></i>{" "}
                                                                    Low
                                                                </div>
                                                            ),
                                                            value: "low",
                                                            action: () => {},
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <TabsContainer
                                            tabs={getAiTabs()}
                                            activeTab={activeAiTab}
                                            setActiveTab={setActiveAiTab}
                                            component={displayActiveAiTab()}
                                            className="!px-0"
                                            itemClassName="!pb-2"
                                        />
                                    </div>

                                    <div className="mt-[26px]">
                                        <TabsContainer
                                            tabs={tabs}
                                            activeTab={activeTab}
                                            setActiveTab={setActiveTab}
                                            component={displayActiveTab()}
                                            className="!px-0"
                                            itemClassName="!pb-2"
                                        />
                                    </div>
                                </div>

                                <div
                                    className={`flex  ${
                                        singleTask?.status !== "completed" &&
                                        singleTask?.action?.actionCategory?.toLowerCase() ===
                                            "approval" &&
                                        singleTask?.action?.name?.toLowerCase() !== "approvepo"
                                            ? "justify-between"
                                            : "justify-end"
                                    } gap-3 items-center border-t-[#E2E8F0] border-t-[1px] pt-6 mt-4`}
                                >
                                    <Button
                                        btnText="Close"
                                        disabled={updateTask || markingActionAsRejected}
                                        btnClassname="!w-auto !bg-[#F4F5F7] !text-[#142837] !text-[13px] !leading-[24px] !py-[10px] !px-4"
                                        onClick={closeModal}
                                    />
                                    {status === "completed" ? (
                                        ""
                                    ) : singleTask?.status !== "completed" &&
                                      singleTask?.action?.actionCategory?.toLowerCase() ===
                                          "approval" &&
                                      singleTask?.action?.name?.toLowerCase() === "approvepo" ? (
                                        <Button
                                            isLoading={updateTask}
                                            btnText={"Approve PR"}
                                            type={"button"}
                                            btnType={"textFirst"}
                                            btnClassname="!py-2.5 !px-4 !text-[13px] !py-2 !text-gm-50 !px-4 !w-fit !h-[40px]"
                                            icon={
                                                <i className="ri-arrow-right-s-line text-gm-50 "></i>
                                            }
                                            onClick={() =>
                                                navigate(
                                                    `/dashboard/purchase-orders/${
                                                        singleTask?.action?.data?.poIds?.[0]?._id ||
                                                        singleTask?.action?.workFlowRun?.data
                                                            ?.poIds?.[0]?._id
                                                    }`
                                                )
                                            }
                                        />
                                    ) : singleTask?.status !== "completed" &&
                                      singleTask?.action?.actionCategory?.toLowerCase() ===
                                          "approval" &&
                                      singleTask?.action?.name?.toLowerCase() !== "approvepo" ? (
                                        <div className="flex items-center space-x-3">
                                            <Button
                                                btnText="Reject"
                                                btnClassname="!py-3 !px-4 !bg-r-50 !text-white"
                                                isLoading={markingActionAsRejected}
                                                disabled={markingActionAsRejected}
                                                onClick={handleRejectTask}
                                            />
                                            <Button
                                                btnText="Approve"
                                                btnClassname="!py-3 !px-4"
                                                isLoading={updateTask}
                                                disabled={updateTask}
                                                onClick={handleStatusChange}
                                            />
                                        </div>
                                    ) : (
                                        singleTask?.status !== "completed" && (
                                            <Button
                                                isLoading={updateTask}
                                                btnText={"Mark as done"}
                                                type={"button"}
                                                btnClassname="!py-3 !px-4 !text-[13px] !leading-[20px] !py-2 !px-4 !w-[145px]
                                                      !h-[45px]"
                                                icon={<i className="ri-check-double-line"></i>}
                                                onClick={handleStatusChange}
                                            />
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </ModalContainer>
                </>
            )}
        </>
    );
};

export default TaskDetailsModal;
