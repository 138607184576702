/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../../../components/common/CustomTable";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import { onScroll } from "helpers";
import moment from "moment";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import Button from "components/common/Button";
import { useNavigate } from "react-router-dom";
import { truncate } from "lodash";
import { getEditPermissionList } from "helpers/getEditPermissionList";

type StockProps = {
    debouncedSearch?: string;
    location?: string;
    stockCategory?: string;
};

const StockAdjustment = ({ debouncedSearch, location, stockCategory }: StockProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [limit, setLimit] = useState<number>(20);
    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [stockAdjustList, setStockAdjustList] = useState([]);
    const [loadMore, setLoadMore] = useState<boolean>(false);
    const [paginate, setPaginate] = useState<{
        current: number | string;
        number_of_pages: number | string;
    }>({
        current: "",
        number_of_pages: "",
    });

    const { fetchingStockAdjustmentList, fetchedStockAdjustmentSuccess } = useAppSelector(
        (state) => state.inventory
    );

    useEffect(() => {
        if (Boolean(fetchedStockAdjustmentSuccess)) {
            setStockAdjustList(fetchedStockAdjustmentSuccess?.stock);
            setPaginate({
                current: fetchedStockAdjustmentSuccess?.pagination?.current,
                number_of_pages: fetchedStockAdjustmentSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedStockAdjustmentSuccess]);

    useEffect(() => {
        if (!Boolean(fetchingStockAdjustmentList)) {
            dispatch(
                inventoryActions.fetchStockAdjustmentList(
                    limit,
                    debouncedSearch,
                    location,
                    stockCategory
                )
            );
        }
    }, [dispatch, limit, debouncedSearch, location, stockCategory]);

    const scrollTable = document.getElementById("scrollTable");

    const onTableScroll = useCallback(
        () =>
            onScroll(
                scrollTable,
                paginate?.current as number,
                paginate?.number_of_pages as number,
                () => {
                    setLimit(() => limit + 10);
                    setLoadMore(true);
                }
            ),
        [scrollTable, paginate]
    );
    useEffect(() => {
        scrollTable?.addEventListener("scroll", onTableScroll);
        return () => {
            scrollTable?.removeEventListener("scroll", onTableScroll);
        };
    }, [scrollTable, onTableScroll]);

    const handleScroll = (e) => {
        if (e.target.scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const tableHeader = [
        {
            title: "Adjustment",
            widthClass: "!border-b !border-slate-200 w-[150px]",
            itemClass: "",
        },
        {
            title: "Product name",
            widthClass: "!border-b !border-slate-200 w-[200px]",
            itemClass: "",
        },
        {
            title: "Warehouse",
            widthClass: "!border-b !border-slate-200 w-[202px]",
            itemClass: "",
        },
        {
            title: "Adjusted by",
            widthClass: "!border-b !border-slate-200 w-[185px]",
            itemClass: "",
        },
        {
            title: "Reason",
            widthClass: "!border-b !border-slate-200 w-[120px]",
            itemClass: "",
        },
        {
            title: "Date",
            widthClass: "!border-b !border-slate-200 w-[216px]",
            itemClass: "",
        },
        // {
        //     title: "Approved by",
        //     widthClass: "!border-b !border-slate-200 w-[185px]",
        //     itemClass: "",
        // },
        {
            title: "Notes",
            widthClass: "!border-b !border-slate-200 w-[216px]",
            itemClass: "",
        },
    ];

    const tableBody =
        stockAdjustList?.length > 0
            ? stockAdjustList?.map((item, idx) => [
                  {
                      content: (
                          <div className="py-[18px]" key={idx}>
                              <p className=""> {item?.reference}</p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                      cellClickAction: () =>
                          navigate(
                              `/dashboard/inventory/stock-control/stock-adjustment/${item?._id}`
                          ),
                  },
                  {
                      content: (
                          <div className="py-2 flex items-center gap-3">
                              <div className="flex gap-3 items-center">
                                  <div className="w-8 h-8">
                                      <img
                                          src={item?.inventory?.productImageDetails?.productAvatar}
                                          alt=""
                                          className=""
                                      />
                                  </div>
                                  <div className="">
                                      <p className="">
                                          {truncate(item?.inventory?.productName, { length: 18 })}
                                      </p>
                                      <p className=""> {item?.batchId}</p>
                                  </div>
                              </div>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-4 flex items-center gap-2">
                              <p className="">
                                  {truncate(item?.warehouse[0]?.warehouseName, { length: 22 })}
                              </p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-[18px] flex items-center gap-3">
                              {item?.creator[0]?.profile?.avatar && (
                                  <VendorAvatar
                                      imageSrc={item?.creator[0]?.profile?.avatar}
                                      name={item?.creator[0]?.profile?.businessName}
                                      size={32}
                                  />
                              )}

                              {item?.creator[0]?.fullName}
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },

                  {
                      content: (
                          <div className="py-[18px]">
                              <p className="capitalize">{item?.movementCategory}</p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-[18px]">
                              <p className="">
                                  {moment(item?.movementDate).format("MMM DD, YYYY • h:mm:ss A")}
                              </p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  //   {
                  //       content: (
                  //           <div className="py-[18px] flex items-center gap-3">
                  //               {item?.creator?.profile?.avatar && (
                  //                   <VendorAvatar
                  //                       imageSrc={item?.creator[0]?.profile?.avatar}
                  //                       name={item?.creator[0]?.profile?.businessName}
                  //                       size={32}
                  //                   />
                  //               )}

                  //               {item?.creator?.fullName}
                  //           </div>
                  //       ),
                  //       widthClass: "!border-b !border-slate-200",
                  //   },
                  {
                      content: (
                          <div className="py-[18px]">
                              <p className="">{truncate(item?.notes, { length: 50 })}</p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
              ])
            : [];

    return (
        <>
            {fetchingStockAdjustmentList && stockAdjustList?.length === 0 ? (
                <div className={`w-full min-h-[30vh]  flex items-center  justify-center`}>
                    <Loader color={"gm-50"} size={8} />
                </div>
            ) : (
                <div>
                    {stockAdjustList?.length > 0 ? (
                        <div>
                            <div
                                className="overflow-x-scroll mt-6  max-h-[calc(100vh-220px)]"
                                onScroll={handleScroll}
                                id="scrollTable"
                            >
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isScrollable={true}
                                    isScrolled={isScrolled}
                                    isCellBordered={false}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                                    headerContainerClass="!bg-slate-50"
                                    tableClass=" w-full !font-rocGroteskMedium cursor-pointer"
                                    tbodyClass="!text-sm !text-slate-900"
                                    bodyItemClass="hover:!bg-slate-50"
                                />
                            </div>

                            {loadMore && fetchingStockAdjustmentList && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {paginate?.current === paginate?.number_of_pages && (
                                <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className=" min-h-[30vh] my text-slate-500  flex items-center  justify-center text-sm font-rocGroteskMedium">
                            {debouncedSearch ? (
                                <p className="">"No data matches your search query"</p>
                            ) : (
                                <div className="w-full flex justify-center">
                                    <div
                                        className="flex mt-[85px] p-10 rounded-lg border border-slate-100 text-slate-500
                        items-start gap-6 font-rocGroteskMedium max-w-[635px] shadow-tableNoDataShadow"
                                    >
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1705927221/bar-chart-fill_v4lcot.svg"
                                            alt="no data "
                                            className=""
                                        />
                                        <div className="">
                                            <p className="text-black text-base  pb-[9px]">
                                                No stock adjustments have been made.
                                            </p>
                                            <p className="text-sm text-slate-500 pb-6">
                                                Keep your inventory precise by recording stock
                                                adjustments when necessary. Begin a new adjustment
                                                to ensure accurate tracking.
                                            </p>
                                            <Button
                                                btnText="Adjust your stock"
                                                btnClassname="!py-[10px]  !text-black !text-[13px] !w-auto !bg-[#ffb903]"
                                                onClick={() =>
                                                    navigate(
                                                        "/dashboard/inventory/stock-control/stock-adjustment"
                                                    )
                                                }
                                                permissions={getEditPermissionList("inventory")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default StockAdjustment;
