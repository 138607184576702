import React from 'react';

const CircularProgressBar = ({ radius, strokeWidth, progress }) => {
  const circumference = 2 * Math.PI * radius;
  const progressOffset = ((100 - progress) / 100) * circumference;

  return (
    <svg
      className="circular-progress"
      width={radius * 2}
      height={radius * 2}
    >
      <circle
        className="background"
        cx={radius}
        cy={radius}
        r={radius - strokeWidth / 2}
        strokeWidth={strokeWidth}
        stroke='#ccc'
      />
      <circle
        className="progress"
        cx={radius}
        cy={radius}
        stroke='#ff0000'
        r={radius - strokeWidth * 1.5}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={progressOffset}
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="20"
        color='white'
      >
        {progress}%
      </text>
    </svg>
  );
};

export default CircularProgressBar;