import CustomTable from "components/common/CustomTable";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import { onScroll } from "helpers";
import { displaySocialMediaIcons } from "helpers/displaySocialMediaIcons";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { truncate } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";

const Order = () => {
    const limit = 20;
    const [orderLimit, setOrderLimit] = useState(limit);
    const [isScrolled, setIsScrolled] = useState(false);
    const [loadMoreOrder, setLoadMoreOrder] = useState(false);
    const [orderList, setOrderList] = useState<any>([]);
    const [vendorPagination, setVendorPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const dashboardContent = document.getElementById("dashboardContent");

    const { fetchedInventoryOrdersSuccess, fetchingInventoryOrders } = useAppSelector(
        (state) => state.inventory
    );

    useEffect(() => {
        dispatch(inventoryActions.fetchInventoryOrders(id, orderLimit));
    }, [id, orderLimit]);

    useEffect(() => {
        if (Boolean(fetchedInventoryOrdersSuccess)) {
            setOrderList(fetchedInventoryOrdersSuccess?.orders?.data);
            setVendorPagination({
                current: fetchedInventoryOrdersSuccess?.pagination?.current,
                number_of_pages: fetchedInventoryOrdersSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedInventoryOrdersSuccess]);
    const onVendorScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                vendorPagination?.current as number,
                vendorPagination?.number_of_pages as number,
                () => {
                    setOrderLimit(() => orderLimit + limit);
                    setLoadMoreOrder(true);
                }
            ),
        [dashboardContent, vendorPagination, orderLimit]
    );

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onVendorScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onVendorScroll);
        };
    }, [dashboardContent, onVendorScroll]);

    const tableHeader = [
        { title: "Order ID", widthClass: "w-[216px]" },
        { title: "Customer", widthClass: "w-[185px]" },
        { title: "Source", widthClass: "w-[155px]" },
        { title: "Stock level", widthClass: "w-[95px]" },
        { title: "Order date", widthClass: "w-[125px]" },
        { title: "Status", widthClass: "w-[140px]" },
        { title: "Vendor/Supplier", widthClass: "w-[313px]" },
    ];

    const tableBody = orderList?.map((order) => [
        {
            content: (
                <div
                    key={order?._id}
                    className={`py-3 pr-3 h-full border-slate-100 text-sm font-rocGroteskMedium`}
                >
                    <p className="text-slate-900 ">Ord {order?.orderDetails?.taId}</p>
                    <p className="text-slate-500 ">{order?.inventoryDetails?.productName}</p>
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-2">
                        <VendorAvatar
                            name={order?.orderDetails?.customerDetails?.companyName}
                            imageSrc=""
                            size={32}
                        />

                        <span>{order?.orderDetails?.customerDetails?.companyName}</span>
                    </div>
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    <div className="flex items-center space-x-1">
                        {displaySocialMediaIcons(order?.orderDetails?.source)}
                        {/* <VendorAvatar
                            name={order?.orderDetails?.source}
                            imageSrc=""
                            size={32}
                            containerClassname=" !rounded-none"
                        /> */}

                        <span>{truncate(order?.orderDetails?.source, { length: 24 })}</span>
                    </div>
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {order?.inventoryDetails?.productStockDetails?.stockLevel}
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {moment(order?.orderDate).format("MMM DD, YYYY")}
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3  items-center h-full flex space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    <div className={`h-2 w-2 ${order?.status?.toLowerCase()==="accepted" &&"bg-g-50"}  ${order?.status?.toLowerCase()==="progress" &&"bg-[#00C6FA]"} ${order?.status?.toLowerCase()==="rejected" &&"bg-[#FF3347]"}  ${order?.status?.toLowerCase()==="pending" &&"bg-[#F59E0B]"} rounded-full`}></div>
                    <p>{order?.status}</p>
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full  space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {order?.supplierAndvendors?.map((vendor) => (
                        <VendorAvatar
                            name={vendor?.responder?.profile?.businessName}
                            imageSrc={vendor?.responder?.profile?.avatar}
                            size={32}
                            containerClassname=" !rounded-none"
                        />
                    ))}
                </div>
            ),
            widthClass: "!border-t",
        },
    ]);

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if ((e.target as any).scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    return (
        <div className="overflow-y-scroll w-full" onScroll={handleScroll}>
            {fetchingInventoryOrders ? (
                <div>
                    <Loader />
                </div>
            ) : (
                <>
                    {orderList?.length > 0 ? (
                        <>
                            <div className="overflow-x-scroll w-full">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isAllSelectable={false}
                                isScrollable={true}
                                isScrolled={isScrolled}
                                isCellBordered={false}
                                headerContainerClass="!bg-slate-50  !border-slate-100 "
                                bodyItemClass="hover:border-slate-100 !border-b hover:bg-slate-50 "
                                headerItemClass="font-rocGroteskMedium !text-slate-700"
                            />
                            </div>
                            {loadMoreOrder && fetchingInventoryOrders && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {vendorPagination?.current === vendorPagination?.number_of_pages && (
                                <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="flex items-center justify-center mt-[76px]">
                            <div>
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1704890969/Tradeally_2.0/empty_state_img_a8twwl.svg"
                                    alt="empty state"
                                    className="w-[268px] h-[235px]"
                                />
                                <p className="text-center text-sm text-[#475569] font-rocGroteskMedium mt-5">
                                    You have no order
                                </p>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Order;
