/* eslint-disable */
import { Editor } from "@tinymce/tinymce-react";
import Button from "components/common/Button";
import AddressInput from "components/common/InputField/AddressInput";
import { DraggableContainerList } from "components/common/InputField/DraggableInput";
import EmailInput from "components/common/InputField/EmailInput";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import ModalContainer from "components/common/ModalContainer";
import PurchaseOrderDetails from "components/views/Ldb/Orders/PurchaseOrderDetails";
import { getCurrencyFromCurrencyCode, formatMoney, getFromStorage, saveToStorage } from "helpers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { authActions, orderActions } from "redux/Ldb/actions";
import { firebaseService } from "services/firebaseService";
import countries from "variables/countries";
import { useNavigate, useSearchParams } from "react-router-dom";
import VendorAvatar from "components/common/VendorAvatar";
import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";
import config from "config/config";

const EditOrder = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const editId = param.get("id");
    const [address, setAddress] = useState<{ [key: string]: any }>({});
    const [responses, setResponses] = useState([]);
    const [editResponses, setEditResponses] = useState<{ [key: string]: any }>({});
    const [showPreview, setShowPreview] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [showCancelSign, setShowCancelSign] = useState(false);
    const [showSendNow, setShowSendNow] = useState(false);
    const [uploadingPdf, setUploadingPdf] = useState(false);
    const [emails, setEmails] = useState([]);

    const [success, setSuccess] = useState(false);
    const [saveBlob, setSaveBlob] = useState([]);
    const [showSuccessPO, setShowSuccessPO] = useState(false);
    const [remainders, setRemainders] = useState(0);
    const [selectedValue, setSelectedValue] = useState("");
    const [purchaseOrderId, setPurchaseOrderId] = useState("");
    const [pdfUrl, setPdfUrl] = useState("");
    const [saveItem, setSaveItem] = useState("");
    const [selectedRFQLabel, setSelectedRFQLabel] = useState("");
    const [selectedRFQData, setSelectedRFQData] = useState({
        vendorDetails: null,
        charges: null,
    });

    const [userProfile, setUserProfile] = useState<{ [key: string]: any }>(() =>
        getFromStorage("ally-user")
    );
    const [imageUrl, setImageUrl] = useState("");

    const [debouncedSearch, setDebouncedSearch] = useState({
        search: "",
    });

    useEffect(() => {
        if (userProfile?.user?.parent) {
            setImageUrl(userProfile?.user?.parent?.profile?.avatar);
        } else {
            setImageUrl(userProfile?.user?.profile?.avatar);
        }
    }, [userProfile]);
    const updateAvatar = (newAvatarValue) => {
        if (userProfile) {
            userProfile.user.profile.avatar = newAvatarValue;
            setUserProfile({ ...userProfile });
            saveToStorage("ally-user", userProfile);
        }
    };
    const [customerDetails, setCustomerDetails] = useState<{
        [key: string]: any;
    }>({
        image: "",
        issuedDate: "",
        dueDate: "",
        postalCode: "",
        country: "",
        rfqCategory: "",
        city: "",
        tax: "",
        shipping: "",
        orderDescription: "",
        currency: "",
    });
    const [emailDetails, setEmailDetails] = useState({
        sender: userProfile?.user?.email,
        title: "",
    });

    const [containers, setContainers] = useState([]);

    const {
        updatePurchaseOrder,
        updatePurchaseOrderSuccess,
        fetchedApprovedResponsesSuccess,
        fetchedSinglePurchaseOrderSuccess,
        sendPurchaseOrder,
        sendPurchaseOrderSuccess,
    } = useAppSelector((state) => state.order);
    const { updateLdbProfileSuccess } = useAppSelector((state) => state.auth);

    useEffect(() => {
        if (editId) {
            dispatch(orderActions.getSinglePurchaseOrder(editId));
        }
    }, [dispatch, editId]);

    useEffect(() => {
        if (Boolean(fetchedSinglePurchaseOrderSuccess)) {
            setEditResponses(fetchedSinglePurchaseOrderSuccess?.purchaseOrder);
        }
    }, [fetchedSinglePurchaseOrderSuccess]);

    useEffect(() => {
        if (editId && editResponses) {
            setSaveItem(editResponses?.rfq?.item?.description);
            setCustomerDetails((prev) => ({
                ...prev,
                rfqCategory: editResponses?.rfq?.taId,
                city: editResponses?.destination?.details[0],
                country: editResponses?.destination?.country,
                postalCode: editResponses?.destination?.postalCode,
                dueDate: moment(editResponses?.dueDate).format("YYYY-MM-DD"),
                issuedDate: moment(editResponses?.issuedDate).format("DD/MM/YYYY"),
                orderDescription: editResponses?.additionalComment,
                shipping: editResponses.shippingAndHandling?.amount,
                tax: editResponses.tax?.percentage,
                currency: editResponses?.totalAmount?.currency,
            }));
            setAddress({
                streetAddress: {
                    address: editResponses?.destination?.address,
                    lng: editResponses?.destination?.lng,
                    lat: editResponses?.destination?.lat,
                },
            });
            handleChange({
                target: { name: "rfqCategory", value: editResponses?.rfq?._id },
            });
        }
    }, [editId, editResponses]);

    useEffect(() => {
        if (editResponses?.itemsOrServices && Array.isArray(editResponses.itemsOrServices)) {
            setContainers(
                editResponses.itemsOrServices.map((charge) => ({
                    name: charge.name,
                    qty: charge.qty,
                    price: {
                        amount: charge?.price?.amount,
                        currency: customerDetails?.currency,
                    },
                    subtotal: {
                        amount: charge?.price?.amount * charge.qty,
                        currency: customerDetails?.currency,
                    },
                }))
            );
        }
    }, [editResponses?.itemsOrServices]);

    const formattedIssuedDate = customerDetails?.issuedDate.split("/").reverse().join("-");
    document.getElementsByName("dueDate")[0]?.setAttribute("min", formattedIssuedDate);

    useEffect(() => {
        dispatch(orderActions.getApprovedResponses(20, debouncedSearch.search));
    }, [dispatch, debouncedSearch]);

    useEffect(() => {
        if (Boolean(fetchedApprovedResponsesSuccess)) {
            setResponses(fetchedApprovedResponsesSuccess?.rfqs);
        }
    }, [fetchedApprovedResponsesSuccess]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSelectedValue(value);
        setCustomerDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleUpload = (fileName, file) => {
        setUploading(true);
        firebaseService.uploadFile(
            `tradeally/${process.env.REACT_APP_STAGE}/businessLogo/${fileName}`,
            file,
            (url) => {
                setImageUrl(url);
                setUploading(false);
            }
        );
    };

    useEffect(() => {
        const body = {
            avatar: imageUrl,
        };
        if (imageUrl !== "") {
            dispatch(authActions.updateLdbProfile(body, userProfile?.token));
        }
    }, [imageUrl]);

    useEffect(() => {
        if (updateLdbProfileSuccess) {
            updateAvatar(updateLdbProfileSuccess?.profile?.avatar);
        }
    }, [updateLdbProfileSuccess]);

    const calculateTotals = () => {
        let totalSubtotals = 0;
        for (const container of containers) {
            const subtotal = parseFloat(container.subtotal.amount);
            totalSubtotals += subtotal;
        }
        const taxPercentage = parseFloat(customerDetails.tax);
        const taxAmount = (taxPercentage / 100) * totalSubtotals;
        return {
            totalSubtotals,
            taxAmount,
        };
    };

    useEffect(() => {
        const updatedContainers = containers.map((container) => ({
            ...container,
            price: {
                ...container.price,
                currency: customerDetails?.currency,
            },
            subtotal: {
                ...container.subtotal,
                currency: customerDetails?.currency,
            },
        }));

        setContainers(updatedContainers);
    }, [customerDetails?.currency, setContainers]);

    const handleDraggableInputChange = (containerIndex, fieldName, value) => {
        const newContainers = [...containers];
        newContainers[containerIndex][fieldName] = value;
        if (fieldName === "qty" || fieldName === "price") {
            const qty = parseFloat(newContainers[containerIndex].qty);
            const price = parseFloat(newContainers[containerIndex].price.amount);
            newContainers[containerIndex].subtotal.amount = qty * price;
        }

        setContainers(newContainers);
    };
    const { totalSubtotals, taxAmount } = calculateTotals();

    useEffect(() => {
        const correctedTaxAmount = isNaN(taxAmount) ? 0 : taxAmount;
        const shippingAndHandling = customerDetails?.shipping ? customerDetails.shipping : 0;
        let remainder = totalSubtotals + correctedTaxAmount + Number(shippingAndHandling);
        setRemainders(remainder);
    }, [totalSubtotals, taxAmount, customerDetails]);

    const handleUploadPdf = (fileName, file) => {
        setUploadingPdf(true);
        firebaseService.uploadFile(
            `tradeally/${process.env.REACT_APP_STAGE}/document/${fileName}`,
            file,
            (url) => {
                setPdfUrl(url);
                setUploadingPdf(false);
            }
        );
    };
    useEffect(() => {
        if (saveBlob) {
            handleUploadPdf("purchase-order.pdf", saveBlob);
        }
    }, [saveBlob]);

    const handleSubmit = () => {
        if (pdfUrl !== "") {
            const body: { [key: string]: any } = {
                issuedDate:
                    moment(customerDetails?.issuedDate, "DD/MM/YYYY").format(
                        "YYYY-MM-DDTHH:mm:ss.SSS"
                    ) + "Z",
                dueDate: moment(customerDetails.dueDate).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
                itemsOrServices: containers,
                documents: [
                    {
                        filename: "purchase-order-doc",
                        path: pdfUrl,
                    },
                ],
                destination: {
                    address: address?.streetAddress?.address,
                    lng: address?.streetAddress?.lng,
                    lat: address?.streetAddress?.lat,
                    details: [customerDetails?.city],
                    country: customerDetails?.country,
                    postalCode: customerDetails?.postalCode,
                },
            };
            if (customerDetails?.orderDescription) {
                body.additionalComment = customerDetails?.orderDescription;
            }
            if (customerDetails?.tax) {
                body.tax = {
                    amount: taxAmount.toFixed(1),
                    percentage: customerDetails?.tax,
                    currency: customerDetails.currency,
                };
            }

            if (customerDetails?.shipping) {
                body.shippingAndHandling = {
                    amount: customerDetails?.shipping,
                    currency: customerDetails.currency,
                };
            }
            dispatch(orderActions.updatePurchaseOrder(editId, body));
        }
    };

    useEffect(() => {
        if (Boolean(updatePurchaseOrderSuccess)) {
            setPurchaseOrderId(updatePurchaseOrderSuccess?.po?._id);
            setSuccess(true);
            setShowPreview(false);
            setShowSuccessPO(true);
        }
    }, [updatePurchaseOrderSuccess]);

    const onCancel = () => {
        setCustomerDetails({
            issuedDate: "",
            dueDate: "",
            postalCode: "",
            country: "",
            rfqCategory: "",
            city: "",
            tax: "",
            shipping: "",
            orderDescription: "",
        });
        setSelectedRFQData({
            vendorDetails: null,
            charges: null,
        });
        navigate("/dashboard/purchase-orders");
    };

    const handleChangeTwo = (e) => {
        const { name, value } = e.target;
        setSelectedValue(value);
        setEmailDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const onSendNow = () => {
        const body = {
            attachment: [
                {
                    filename: "purchase-order-doc.pdf",
                    path: pdfUrl,
                },
            ],
            comment: emailDetails.title,
            toEmail: emailDetails.sender,
            bcc: emails,
        };

        dispatch(orderActions.sendPurchaseOrder(purchaseOrderId, body));
    };

    useEffect(() => {
        if (selectedRFQData?.vendorDetails?.email) {
            setEmails((prevEmails) => {
                const newEmail = selectedRFQData.vendorDetails.email;
                if (!prevEmails.includes(newEmail)) {
                    return [...prevEmails, newEmail];
                } else {
                    return prevEmails;
                }
            });
        }
    }, [selectedRFQData]);

    useEffect(() => {
        if (Boolean(sendPurchaseOrderSuccess)) {
            navigate("/dashboard/purchase-orders");
            setSuccess(false);
            setShowPreview(false);
            setCustomerDetails({
                image: "",
                issuedDate: "",
                dueDate: "",
                postalCode: "",
                country: "",
                rfqCategory: "",
                city: "",
                tax: "",
                shipping: "",
                orderDescription: "",
            });
            setEmails([]);
            setEmailDetails({
                title: "",
                sender: "",
            });
            setSelectedRFQData({
                vendorDetails: null,
                charges: null,
            });
            dispatch(orderActions.resetSendPurchaseOrderSuccess());
            dispatch(orderActions.resetUpdatePurchaseOrderSuccess());
        }
    }, [sendPurchaseOrderSuccess]);

    const handleShowPreview = (e) => {
        e.preventDefault();
        setShowPreview(true);
    };
    return (
        <>
            {!showPreview && !success && (
                <div className="flex justify-center px-3 md:px-10">
                    <div className="bg-white rounded-[8px] py-6 shadow-sm sm:w-full  2xl:w-[75%] xl:w-[90%]  lg:w-[100%]  ">
                        <form onSubmit={handleShowPreview} className="relative">
                            <div className=" px-8 md:px-16 pb-6 sticky md:flex-row flex-col md:space-y-0 space-y-2 flex md:justify-between md:items-center shadow-faintShadow">
                                <p className="font-rocGroteskMedium text-gm-50 md:text-2xl">
                                    Edit PO
                                </p>
                                <div className="flex whitespace-nowrap space-x-3 items-center">
                                    <Button
                                        btnText={"Cancel"}
                                        type={"button"}
                                        btnClassname="!py-2 border md:boder-none border-n-20  !bg-transparent md:w-full w-full sm:w-fit  !text-gm-50 !font-rocGroteskMedium !px-4"
                                        onClick={() => setShowCancelSign(true)}
                                    />
                                    <Button
                                        btnText={"Preview PO"}
                                        type={"submit"}
                                        btnClassname="!py-2  !font-rocGroteskMedium !px-4"
                                    />
                                </div>
                            </div>
                            <div className="h-[calc(100vh-132px)] overflow-y-auto pt-10">
                                <div className="flex px-16 justify-between items-center">
                                    <div className="flex sm:flex-row flex-col sm:space-y-0 w-full  space-y-3 sm:space-x-6 items-center mb-6 md:justify-start justify-center">
                                        <div className="border border-dashed rounded-[4px]  border-[n-40] w-[128px] h-[128px] flex items-center justify-center">
                                            <VendorAvatar
                                                containerClassname={"!rounded-[4px]"}
                                                size={127}
                                                imageSrc={imageUrl}
                                                name={userProfile?.user?.profile?.businessName}
                                            />
                                        </div>
                                    </div>
                                    <div className="bg-slate-100 md:block hidden px-4 py-1 rounded-[4px]">
                                        <p className="text-slate-500 text-[14px]">Draft</p>
                                    </div>
                                </div>
                                <div className="mt-3 md:px-16 px-8 md:flex-row flex-col flex gap-4 md:gap-10 w-full">
                                    <div className="w-full">
                                        <TextInput
                                            name={"poNumber"}
                                            type={"number"}
                                            onWheel={(e) => (e.target as any).blur()}
                                            value={""}
                                            onChange={handleChange}
                                            placeholder={
                                                "PO Number shows immediately after it's created."
                                            }
                                            required={false}
                                            containerClassname="mb-4"
                                            disabled={true}
                                        />
                                        <TextInput
                                            name="rfqCategory"
                                            type={"text"}
                                            value={editResponses?.rfq?.taId ?? ""}
                                            placeholder={"RFQ"}
                                            required={false}
                                            containerClassname="mb-4"
                                            disabled={true}
                                            onChange={() => {}}
                                        />
                                        <TextInput
                                            value={customerDetails?.currency}
                                            name="currency"
                                            placeholder={"Currency"}
                                            onChange={handleChange}
                                            required={true}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <div>
                                            <p className="text-[14px] text-gm-50 font-rocGroteskMedium">
                                                From
                                            </p>
                                            <div className="mt-5">
                                                <p className="text-[14px] text-gm-50 font-rocGroteskMedium">
                                                    {userProfile?.user?.profile?.businessName}
                                                </p>
                                                <p className="text-[14px] text-slate-500 font-rocGroteskMedium">
                                                    {userProfile?.user?.email}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-5">
                                            <p className="text-[14px] mb-2 text-gm-50 font-rocGroteskMedium">
                                                To
                                            </p>
                                            {editResponses?.vendor && (
                                                <div className="mt-3">
                                                    <p className="text-[14px] text-gm-50 font-rocGroteskMedium">
                                                        {editResponses?.vendor?.profile
                                                            ?.businessName
                                                            ? editResponses?.vendor?.profile
                                                                  ?.businessName
                                                            : `${
                                                                  editResponses?.vendor?.firstName
                                                              } ${" "}
                                                    ${editResponses?.vendor?.lastName}`}
                                                    </p>
                                                    <p className="text-[14px] text-slate-500 font-rocGroteskMedium">
                                                        {editResponses?.vendor?.email}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex md:flex-row flex-col px-8 md:px-16 mt-8 border-b pb-5 border-slate-200 gap-4 md:gap-10 w-full">
                                    <div className="w-full md:mb-4 md:space-y-4">
                                        <TextInput
                                            name={"issuedDate"}
                                            // type={"date"}
                                            value={customerDetails.issuedDate}
                                            onChange={handleChange}
                                            placeholder={"Issued date"}
                                            required={true}
                                            disabled={true}
                                            containerClassname="mb-4 !text-black"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <TextInput
                                            name={"dueDate"}
                                            type={"date"}
                                            value={customerDetails.dueDate}
                                            onChange={handleChange}
                                            placeholder={"Due date"}
                                            required={true}
                                            containerClassname="mb-4"
                                        />
                                    </div>
                                </div>

                                <div className="mt-10 px-8 md:px-16 border-b pb-8 border-slate-200  ">
                                    <p className="text-[14px] mb-2 text-gm-50 font-rocGroteskMedium">
                                        Ship to
                                    </p>

                                    <div className="flex md:flex-row flex-col gap-4  md:gap-10 w-full">
                                        <div className="w-full space-y-4">
                                            <AddressInput
                                                setData={setAddress}
                                                savedAddress={
                                                    address?.streetAddress
                                                        ? address?.streetAddress?.address
                                                        : ""
                                                }
                                                placeholder="Street address"
                                                required={true}
                                                name={"streetAddress"}
                                            />
                                            <TextInput
                                                name={"city"}
                                                type={"text"}
                                                value={customerDetails.city}
                                                onChange={handleChange}
                                                placeholder={"City"}
                                                required={true}
                                                containerClassname=""
                                            />
                                        </div>
                                        <div className="w-full">
                                            <TextInput
                                                name={"postalCode"}
                                                type={"number"}
                                                onWheel={(e) => (e.target as any).blur()}
                                                value={customerDetails.postalCode}
                                                onChange={handleChange}
                                                placeholder={"Postal Code"}
                                                required={true}
                                                containerClassname="mb-4"
                                            />
                                            <SelectInput
                                                value={customerDetails.country}
                                                name="country"
                                                placeholder={"Country"}
                                                handleChange={(name, value) =>
                                                    handleChange({ target: { name, value } })
                                                }
                                                isRequired={true}
                                                dropdownOptions={countries.map((item) => ({
                                                    label: item.name,
                                                    value: item.name,
                                                }))}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 px-6 ">
                                    <DraggableContainerList
                                        setContainers={setContainers}
                                        containers={containers}
                                        onChange={handleDraggableInputChange}
                                        currency={customerDetails?.currency}
                                    />
                                </div>
                                <div className="w-full md:px-16 px-8 md:flex-row flex-col  flex   border-b pb-3 md:space-x-10">
                                    <TextInput
                                        name={"tax"}
                                        type={"number"}
                                        onWheel={(e) => (e.target as any).blur()}
                                        value={customerDetails.tax}
                                        onChange={handleChange}
                                        placeholder={"Tax"}
                                        required={false}
                                        containerClassname="mb-4"
                                        rightIcon="%"
                                    />
                                    <TextInput
                                        name={"shipping"}
                                        type={"number"}
                                        onWheel={(e) => (e.target as any).blur()}
                                        value={customerDetails.shipping}
                                        onChange={handleChange}
                                        placeholder={"Shipping and Handling (Optional)"}
                                        required={false}
                                        containerClassname="mb-4"
                                    />
                                </div>
                                <div className="mt-3 px-16 space-y-3 border-b boder-slate-100 pt-3 pb-5">
                                    <div className="flex justify-between text-[14px] font-rocGroteskMedium text-gm-50">
                                        <p>Total Amount</p>
                                        <p>
                                            {getCurrencyFromCurrencyCode(customerDetails?.currency)}
                                            {isNaN(remainders)
                                                ? 0
                                                : formatMoney()?.format(remainders)}
                                        </p>
                                    </div>
                                </div>
                                <div className="py-7 px-8 md:px-16">
                                    <div>
                                        <Editor
                                            apiKey={config.TINYMICE_API}
                                            value={customerDetails.orderDescription}
                                            onEditorChange={(newValue, editor) => {
                                                setCustomerDetails((prev) => ({
                                                    ...prev,
                                                    orderDescription: newValue,
                                                }));
                                            }}
                                            init={{
                                                height: 400,
                                                menubar: false,
                                                plugins: [
                                                    "advlist",
                                                    "autolink",
                                                    "lists",
                                                    "link",
                                                    "anchor",
                                                    "searchreplace",
                                                    "visualblocks",
                                                    "fullscreen",
                                                    "insertdatetime",
                                                    "media",
                                                ],
                                                placeholder:
                                                    "Add Terms and Condition,  payment terms, scope of work, and other notes",
                                                // selector: "textarea",
                                                resize: false,
                                                branding: false,
                                                toolbar:
                                                    "undo redo | casechange blocks | bold italic underline link backcolor | " +
                                                    "alignleft aligncenter alignright alignjustify | " +
                                                    "bullist numlist checklist outdent indent | removeformat",
                                                content_style:
                                                    "body { font-family:rocGrotesk,Helvetica,Arial,sans-serif; font-size:14px; }",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {showPreview && !success && (
                <div className="flex justify-center px-6 md:px-10">
                    <div className="sm:w-full w-full 2xl:w-[75%] xl:w-[90%]  lg:w-[100%] ">
                        <PurchaseOrderDetails
                            btnTextOne={"Keep editing"}
                            btnTextTwo={"Update PO"}
                            btnTextThree={"Cancel"}
                            title={"Preview PO"}
                            successPreview={false}
                            imageDetails={imageUrl}
                            setSaveBlob={setSaveBlob}
                            isLoading={updatePurchaseOrder}
                            uploading={uploading}
                            handleUpload={handleUpload}
                            disabled={updatePurchaseOrder}
                            onClickOne={() => setShowPreview(false)}
                            onClickTwo={handleSubmit}
                            onClickThree={() => setShowCancelSign(true)}
                            issuedDate={moment(customerDetails?.issuedDate, "DD/MM/YYYY").format(
                                "MMM Do, YYYY"
                            )}
                            dueDate={moment(customerDetails?.dueDate).format("MMM Do, YYYY")}
                            selectedRFQData={selectedRFQData}
                            vendorName={selectedRFQData?.vendorDetails?.profile?.businessName}
                            vendorEmail={selectedRFQData?.vendorDetails?.email}
                            address={address?.streetAddress?.address}
                            taId={editResponses?.taId}
                            postalCode={customerDetails?.postalCode}
                            userName={userProfile?.user?.profile?.businessName}
                            userEmail={userProfile?.user?.email}
                            city={customerDetails?.city}
                            item={saveItem}
                            country={customerDetails?.country}
                            containers={containers}
                            tax={
                                isNaN(Number(customerDetails?.tax))
                                    ? 0
                                    : Number(customerDetails?.tax)
                            }
                            currency={customerDetails?.currency}
                            taxAmount={isNaN(taxAmount) ? 0 : Number(taxAmount.toFixed(1))}
                            shipping={
                                isNaN(Number(customerDetails?.shipping))
                                    ? 0
                                    : customerDetails?.shipping
                            }
                            totalAmount={isNaN(remainders) ? 0 : remainders}
                            orderDescription={customerDetails?.orderDescription}
                        />
                    </div>
                </div>
            )}
            {success && (
                <div className="flex justify-center px-6 md:px-10">
                    <div className="sm:w-full w-full  2xl:w-[75%] xl:w-[90%]  lg:w-[100%] ">
                        <PurchaseOrderDetails
                            btnTextOne={"Download PDF"}
                            successPreview={true}
                            imageDetails={imageUrl}
                            item={saveItem}
                            disabled={false}
                            isLoading={false}
                            uploading={uploading}
                            handleUpload={handleUpload}
                            customerDetails={customerDetails}
                            imageUrl={imageUrl}
                            selectedRFQLabel={selectedRFQLabel}
                            userProfile={userProfile}
                            remainders={remainders}
                            saveItem={saveItem}
                            setPdfUrl={setPdfUrl}
                            selectedRFQData={selectedRFQData}
                            issuedDate={customerDetails?.issuedDate}
                            dueDate={customerDetails?.dueDate}
                            setShowSendNow={setShowSendNow}
                            address={address?.streetAddress?.address}
                            postalCode={customerDetails?.postalCode}
                            userName={userProfile?.user?.profile?.businessName}
                            userEmail={userProfile?.user?.email}
                            city={customerDetails?.city}
                            country={customerDetails?.country}
                            containers={containers}
                            tax={
                                isNaN(Number(customerDetails?.tax))
                                    ? 0
                                    : Number(customerDetails?.tax)
                            }
                            currency={customerDetails?.currency}
                            taxAmount={isNaN(taxAmount) ? 0 : Number(taxAmount.toFixed(1))}
                            shipping={
                                isNaN(Number(customerDetails?.shipping))
                                    ? 0
                                    : customerDetails?.shipping
                            }
                            totalAmount={isNaN(remainders) ? 0 : remainders}
                            orderDescription={customerDetails?.orderDescription}
                            title={"Preview PO"}
                            taId={editResponses?.taId}
                            handleRemoveImage={() => setImageUrl("")}
                        />
                    </div>
                </div>
            )}

            {showSendNow && (
                <ModalContainer
                    open={showSendNow}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[400px]"
                    showCloseIcon={false}
                    closeModal={() => {
                        setShowSendNow(false);
                        dispatch(orderActions.resetUpdatePurchaseOrderSuccess());
                        navigate("/dashboard/purchase-orders");
                        setSuccess(false);
                    }}
                >
                    <div className="bg-white !px-2 pt-6 pb-6  rounded-[12px]">
                        <div className="border-b pb-4  border-slate-200">
                            <div className="flex px-6 items-center justify-between">
                                <h1 className="text-black font-rocGroteskMedium text-[18px] ">
                                    Share PO
                                </h1>
                                <span
                                    className="material-icons text-gm-50 text-[24px] cursor-pointer font-medium"
                                    onClick={() => {
                                        setShowSendNow(false);
                                        dispatch(orderActions.resetUpdatePurchaseOrderSuccess());
                                        navigate("/dashboard/purchase-orders");
                                        setSuccess(false);
                                    }}
                                >
                                    close
                                </span>
                            </div>
                        </div>
                        <div className="mt-4 px-6">
                            <div className="space-y-5 ">
                                <TextInput
                                    name={"sender"}
                                    type={"text"}
                                    value={emailDetails.sender}
                                    onChange={handleChangeTwo}
                                    placeholder={"From"}
                                    required={false}
                                />
                                <EmailInput
                                    setEmails={setEmails}
                                    emails={emails}
                                    placeholder={"Email"}
                                    required={true}
                                />

                                <TextareaInput
                                    name={"title"}
                                    value={emailDetails.title}
                                    onChange={handleChangeTwo}
                                    rows={6}
                                    placeholder={"Title"}
                                    required={false}
                                />
                            </div>
                            <div className="mt-12">
                                <Button
                                    btnText={"Share PO"}
                                    type={"button"}
                                    isLoading={sendPurchaseOrder}
                                    btnClassname="!py-2 !text-[14px] !px-4"
                                    onClick={onSendNow}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}

            {showSuccessPO && (
                <ModalContainer
                    open={showSuccessPO}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[400px]"
                    showCloseIcon={false}
                    closeModal={() => {
                        dispatch(orderActions.resetUpdatePurchaseOrderSuccess());
                        setShowSuccessPO(false);
                        navigate("/dashboard/purchase-orders");
                    }}
                >
                    <div className="bg-white !px-2 pt-6 pb-7  rounded-[12px]">
                        <div className="flex px-6 items-center justify-end">
                            <span
                                className="material-icons text-gm-45 text-[24px] cursor-pointer font-medium"
                                onClick={() => {
                                    dispatch(orderActions.resetUpdatePurchaseOrderSuccess());
                                    setShowSuccessPO(false);
                                    navigate("/dashboard/purchase-orders");
                                }}
                            >
                                close
                            </span>
                        </div>
                        <div className="mt-4 px-6">
                            <div className="text-center flex justify-center flex-col items-center space-y-5">
                                <div className="w-14 h-14 flex justify-center items-center bg-[#00DB8F] rounded-full">
                                    <i className="ri-checkbox-circle-fill text-[29px] text-white "></i>
                                </div>
                                <div>
                                    <h3 className="text-xl text-[#333333] font-rocGroteskMedium">
                                        PO created successfully
                                    </h3>
                                    <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                        Your purchase order has been created and sent. If you want
                                        to share it with others, you can use the provided sharing
                                        options.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5">
                                <Button
                                    btnText={"Share PO"}
                                    btnClassname="!py-3 cursor-pointer !bg-[#F4F5F7] !text-[14px] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4"
                                    onClick={() => {
                                        setShowSuccessPO(false);
                                        setShowSendNow(true);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}
            {showCancelSign && (
                <ModalContainer
                    open={showCancelSign}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[400px]"
                    showCloseIcon={false}
                    closeModal={() => {
                        setShowCancelSign(false);
                    }}
                >
                    <div className="bg-white !px-2 pt-6 pb-7  rounded-[12px]">
                        <div className="flex px-6 items-center justify-end">
                            <span
                                className="material-icons text-gm-45 text-[24px] cursor-pointer font-medium"
                                onClick={() => setShowCancelSign(false)}
                            >
                                close
                            </span>
                        </div>
                        <div className="mt-4 px-6">
                            <div className="text-center flex justify-center flex-col items-center space-y-5">
                                <div className="">
                                    <img src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1697712771/Caution_sign_g1u9nv.svg" />
                                </div>
                                <div>
                                    <h3 className="text-xl text-[#333333] font-rocGroteskMedium">
                                        Discard Changes?
                                    </h3>
                                    <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                        Leaving this page will result in the loss of any unsaved
                                        progress in your purchase order creation. Are you sure you
                                        want to exit?
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 flex space-x-2">
                            <div className="w-full whitespace-nowrap">
                                <Button
                                    btnText={"No"}
                                    type={"button"}
                                    btnClassname="!py-3 !bg-n-20 !text-gm-50 !text-[14px] !px-4"
                                    onClick={() => setShowCancelSign(false)}
                                />
                                </div>
                                <div className="w-full whitespace-nowrap">
                                <Button
                                    btnText={"Yes, cancel"}
                                    type={"button"}
                                    btnClassname="!py-3 !bg-r-50 !text-white !text-[14px] !px-4"
                                    onClick={onCancel}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </>
    );
};

export default EditOrder;
