export const onScroll = (
    scrollContainer: HTMLElement,
    currentPage: number,
    numberOfPages: number,
    onScrollFunc?: () => void
) => {
    let lastScrollTop = 0;
    let scrollTop = scrollContainer.scrollTop;
    const scrollHeight = scrollContainer.scrollHeight;
    const clientHeight = scrollContainer.clientHeight;
    let st = window.pageYOffset || scrollTop;
    const condition = scrollTop + clientHeight >= scrollHeight - 25;

    if (condition && st > lastScrollTop) {
        if (currentPage < numberOfPages) {
            onScrollFunc?.();
        }
    }
    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
};
