import Button from "components/common/Button";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SelectInput from "components/common/InputField/SelectInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import AddressInput from "components/common/InputField/AddressInput";
import ModalContainer from "components/common/ModalContainer";
import CustomTable from "components/common/CustomTable";
import TextInput from "components/common/InputField/TextInput";
import VendorAvatar from "components/common/VendorAvatar";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { authActions, inventoryActions } from "redux/Ldb/actions";
import { onScroll } from "helpers";
import { debounce } from "lodash";
import Loader from "components/common/Loader";
import { getEditPermissionList } from "helpers/getEditPermissionList";

type YourProductType = {
    inventoryId?: string;
    productName?: string;
    minimumStockLevel: number;
    maximumStockLevel: number;
    bufferStock: number;
};

const AddWarehouse = () => {
    const limit = 30;
    const [address, setAddress] = useState<{ [key: string]: any }>({});
    const [addProduct, setAddProduct] = useState<boolean>(false);
    const [addWarehouseSuccess, setAddWarehouseSuccess] = useState<boolean>(false);
    const [selectProduct, setSelectProduct] = useState<{ [key: string]: any }[]>([]);
    const [searchInventoryLimit, setSearchInventoryLimit] = useState(limit);
    const [teamLimit, setTeamLimit] = useState(limit);
    const [searchInventoryList, setSearchInventoryList] = useState<{ [key: string]: any }[]>([]);
    const [loadMoreSearchInventory, setLoadMoreSearchInventory] = useState(false);
    const [loadMoreTeam, setLoadMoreTeam] = useState(false);
    const [teamList, setTeamList] = useState([]);
    const [dashboardContent, setDashboardContent] = useState<any>();
    const [selectContent, setSelectContent] = useState<any>();
    const [products, setProducts] = useState<YourProductType[]>([]);
    const [productData, setProductData] = useState<YourProductType[]>([]);
    const [productDetails, setProductDetails] = useState({
        warehouseName: "",
        contactPerson: "",
        additionalNote: "",
    });

    const [searchProduct, setSearchProduct] = useState("");
    const [searchInventoryPagination, setSearchInventoryPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [teamPagination, setTeamPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [debouncedSearch, setDebouncedSearch] = useState({
        search: "",
    });
    const [inputValues, setInputValues] = useState({});
    const [disabledRows, setDisabledRows] = useState({});
    const [clickCount, setClickCount] = useState({});
    const dispatch = useAppDispatch();

    const {
        fetchingSearchProductListing,
        fetchedSearchProductListingSuccess,
        createWarehouse,
        createWarehouseSuccess,
    } = useAppSelector((state) => state.inventory);
    const { fetchingTeamMember, fetchedTeamMemberSuccess } = useAppSelector((state) => state.auth);

    useEffect(() => {
        if (addProduct) {
            setDashboardContent(document.getElementById("custom-modal"));
        }
    }, [addProduct]);

    useEffect(() => {
        dispatch(inventoryActions.fetchSearchProductListing(searchInventoryLimit, searchProduct));
    }, [searchInventoryLimit, searchProduct]);

    useEffect(() => {
        dispatch(authActions.getTeamMembers(40, debouncedSearch.search));
    }, [dispatch, debouncedSearch]);

    const onWarehouseScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                searchInventoryPagination?.current as number,
                searchInventoryPagination?.number_of_pages as number,
                () => {
                    setSearchInventoryLimit(() => searchInventoryLimit + limit);
                    setLoadMoreSearchInventory(true);
                }
            ),
        [dashboardContent, searchInventoryPagination, searchInventoryLimit]
    );

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onWarehouseScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onWarehouseScroll);
        };
    }, [dashboardContent, onWarehouseScroll]);

    // const onTeamScroll = useCallback(
    //     () =>
    //         onScroll(
    //             selectContent,
    //             teamPagination?.current as number,
    //             teamPagination?.number_of_pages as number,
    //             () => {
    //                 setTeamLimit(() => teamLimit + limit);
    //                 setLoadMoreTeam(true);
    //             }
    //         ),
    //     [selectContent, teamPagination, teamLimit]
    // );

    // useEffect(() => {
    //     selectContent?.addEventListener("scroll", onTeamScroll);
    //     return () => {
    //         selectContent?.removeEventListener("scroll", onTeamScroll);
    //     };
    // }, [selectContent, onTeamScroll]);

    useEffect(() => {
        if (Boolean(fetchedSearchProductListingSuccess)) {
            setSearchInventoryList(fetchedSearchProductListingSuccess?.inventory?.data);
            setSearchInventoryPagination({
                current: fetchedSearchProductListingSuccess?.pagination?.current,
                number_of_pages: fetchedSearchProductListingSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedSearchProductListingSuccess]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamList(fetchedTeamMemberSuccess?.teamMembers);
            setTeamPagination({
                current: fetchedTeamMemberSuccess?.pagination?.current,
                number_of_pages: fetchedTeamMemberSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedTeamMemberSuccess]);

    const inputRef = useRef(null);
    const navigate = useNavigate();
    useEffect(() => {
        (inputRef?.current as any)?.focus();
    }, []);

    useEffect(() => {
        const initialDisabledRows = {};
        searchInventoryList?.forEach((item) => {
            initialDisabledRows[item?._id] = true;
        });
        setDisabledRows(initialDisabledRows);
    }, [searchInventoryList]);

    const handleSelectAllProducts = (deselect = false) => {
        const selectedProducts = deselect ? [] : searchInventoryList;
        setSelectProduct(selectedProducts);
    };

    const handleSelectEachProduct = (itemSelected, values) => {
        const selectedProductsCopy = { ...inputValues };

        if (!selectedProductsCopy[itemSelected?._id]) {
            selectedProductsCopy[itemSelected?._id] = {};
        }

        selectedProductsCopy[itemSelected?._id] = {
            ...selectedProductsCopy[itemSelected?._id],
            ...values,
        };

        setInputValues(selectedProductsCopy);
    };
    const getSelectedInputValues = () => {
        const selectedInputValues = selectProduct.map((selectedItem) => ({
            inventoryId: selectedItem._id,
            minimumStockLevel: inputValues[selectedItem._id]?.minimumStockLevel || 0,
            maximumStockLevel: inputValues[selectedItem._id]?.maximumStockLevel || 0,
            bufferStock: inputValues[selectedItem._id]?.bufferStock || 0,
        }));

        return selectedInputValues;
    };

    const getSelectedProductValues = () => {
        const selectedProductValues = selectProduct.map((selectedItem) => ({
            productName: selectedItem.productName,
            minimumStockLevel: inputValues[selectedItem._id]?.minimumStockLevel || 0,
            maximumStockLevel: inputValues[selectedItem._id]?.maximumStockLevel || 0,
            bufferStock: inputValues[selectedItem._id]?.bufferStock || 0,
        }));

        return selectedProductValues;
    };
    const handleCheckboxClick = (itemSelected) => {
        const clickCountCopy = { ...clickCount };
        const disabledRowsCopy = { ...disabledRows };

        if (!clickCountCopy[itemSelected?._id]) {
            clickCountCopy[itemSelected?._id] = 1;
        } else {
            clickCountCopy[itemSelected?._id] += 1;

            if (clickCountCopy[itemSelected?._id] % 2 === 0) {
                disabledRowsCopy[itemSelected?._id] = !disabledRowsCopy[itemSelected?._id];
            }
        }

        setClickCount(clickCountCopy);
        setDisabledRows(disabledRowsCopy);

        const updatedSelectProduct = selectProduct.includes(itemSelected)
            ? selectProduct.filter((product) => product?._id !== itemSelected?._id)
            : [...selectProduct, itemSelected];

        setSelectProduct(updatedSelectProduct);
    };
    const handleAddProductToWarehouse = () => {
        const selectedValues = getSelectedInputValues();
        const selectedProductValues = getSelectedProductValues();
        setProducts(selectedValues);
        setProductData(selectedProductValues);
        setAddProduct(false);
    };
    const tableHeader = [
        {
            title: "",
            widthClass: "w-[5%] !py-2 !text-slate-700",
            onSelectAll: () => {
                handleSelectAllProducts(selectProduct?.length > 0);
            },
            isAllChecked: selectProduct?.length === searchInventoryList?.length,
        },
        { title: "Product name", widthClass: "w-[48%] !py-2 !text-slate-700" },
        { title: "Minimum Qty", widthClass: "w-[18%] !text-slate-700" },
        { title: "Maximum Qty", widthClass: "w-[18%] !text-slate-700" },
        { title: "Buffer Stock", widthClass: "w-[18%] !text-slate-700" },
    ];
    const tableProductHeader = [
        { title: "Product name", widthClass: "w-[50%] !py-2 !text-slate-700" },
        { title: "Minimum Qty", widthClass: "w-[16%] !text-slate-700" },
        { title: "Maximum Qty", widthClass: "w-[17%] !text-slate-700" },
        { title: "Buffer Stock", widthClass: "w-[17%] !text-slate-700" },
    ];

    const handleStockChange = (item, inputType, actionType) => {
        setInputValues((prevInputValues) => {
            const currentValue = prevInputValues[item?._id] || {
                minimumStockLevel: 0,
                maximumStockLevel: 0,
                bufferStock: 0,
            };

            let newValue;
            switch (actionType) {
                case "decrease":
                    newValue = {
                        ...currentValue,
                        [inputType]: currentValue[inputType] - 1,
                    };
                    break;
                case "increase":
                    newValue = {
                        ...currentValue,
                        [inputType]: currentValue[inputType] + 1,
                    };
                    break;
                default:
                    newValue = currentValue;
            }

            return {
                ...prevInputValues,
                [item?._id]: newValue,
            };
        });
    };

    const tableProductBody = productData?.map((item) => [
        {
            content: (
                <div className={`py-3 h-full border-slate-100 text-sm font-rocGroteskMedium`}>
                    {item?.productName}
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div className={`py-3 h-full border-slate-100 text-sm font-rocGroteskMedium`}>
                    {item?.minimumStockLevel}
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div className={`py-3 h-full border-slate-100 text-sm font-rocGroteskMedium`}>
                    {item?.maximumStockLevel}
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div className={`py-3 h-full border-slate-100 text-sm font-rocGroteskMedium`}>
                    {item?.bufferStock}
                </div>
            ),
            widthClass: "!border-t",
        },
    ]);

    const tableBody = searchInventoryList?.map((item) => {
        const minimumStockLevelInputId = `minimumStockLevel_${item?._id}`;
        const maximumStockLevelInputId = `maximumStockLevel_${item?._id}`;
        const bufferStockInputId = `bufferStock_${item?._id}`;

        if (!inputValues[item?._id]) {
            setInputValues((prevInputValues) => ({
                ...prevInputValues,
                [item?._id]: {
                    minimumStockLevel: 0,
                    maximumStockLevel: 0,
                    bufferStock: 0,
                },
            }));
        }
        const isChecked = selectProduct.includes(item);

        return [
            {
                content: (
                    <div className={` h-full border-slate-100 text-sm font-rocGroteskMedium`}></div>
                ),
                widthClass: "!border-t",
                isItemChecked: isChecked,
                onCheckBoxClick: () => {
                    handleCheckboxClick(item);
                },
            },
            {
                content: (
                    <div
                        className={`py-2 pr-3 h-full flex space-x-2 items-center border-slate-100 text-sm font-rocGroteskMedium`}
                    >
                        <VendorAvatar
                            size={32}
                            imageSrc={item?.productImageDetails?.productAvatar}
                            containerClassname="!bg-none border !rounded"
                        />
                        <div>
                            <p className="text-slate-900 ">{item?.sku}</p>
                            <p className="text-slate-500 ">{item?.productName}</p>
                        </div>
                    </div>
                ),
                widthClass: "!border-t",
            },

            {
                content: (
                    <div
                        className={`py-3  pr-3  flex justify-center w-full items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50`}
                    >
                        <span
                            className="bg-[#166534]  px-[5px] rounded-l text-center py-1"
                            onClick={() => {
                                handleStockChange(item, "minimumStockLevel", "decrease");
                            }}
                        >
                            <i className="ri-subtract-line text-white text-sm font-rocGroteskMedium"></i>
                        </span>
                        <div className="text-center">
                            <TextInput
                                name="minimumStockLevel"
                                type="number"
                                id={minimumStockLevelInputId}
                                value={inputValues[item?._id]?.minimumStockLevel.toString() || ""}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value, 10) || 0;
                                    handleSelectEachProduct(item, {
                                        ...inputValues[item?._id],
                                        minimumStockLevel: value,
                                    });
                                }}
                                inputClassName={"!text-center "}
                                disabled={!isChecked}
                                inputContainerClassname={
                                    "!rounded-none text-sm text-gun-metal px-2 py-1 uppercase !bg-[#F0FDF4] !min-w-[84px] !w-[84px]  !text-center !px-0 !py-0 !w-auto !h-[30px] !border-0"
                                }
                            />
                        </div>

                        <span
                            className="bg-[#166534] px-[5px]  rounded-r text-center py-1"
                            onClick={() => {
                                handleStockChange(item, "minimumStockLevel", "increase");
                            }}
                        >
                            <i className="ri-add-line text-white text-sm"></i>
                        </span>
                    </div>
                ),
                widthClass: "!border-t",
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex justify-center w-full items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50`}
                    >
                        <span
                            className="bg-[#166534] px-[5px]  rounded-l text-center py-1"
                            onClick={() => {
                                handleStockChange(item, "maximumStockLevel", "decrease");
                            }}
                        >
                            <i className="ri-subtract-line text-white text-sm font-rocGroteskMedium"></i>
                        </span>
                        <div className="text-center">
                            <TextInput
                                name="maximumStockLevel"
                                type="number"
                                id={maximumStockLevelInputId}
                                value={inputValues[item?._id]?.maximumStockLevel.toString() || ""}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value, 10) || 0;
                                    handleSelectEachProduct(item, {
                                        ...inputValues[item?._id],
                                        maximumStockLevel: value,
                                    });
                                }}
                                inputClassName={"!text-center "}
                                disabled={!isChecked}
                                inputContainerClassname={
                                    "!rounded-none text-sm text-gun-metal px-2 py-1 uppercase !bg-[#F0FDF4] !min-w-[84px] !w-[84px]  !text-center !px-0 !py-0 !w-auto !h-[30px] !border-0"
                                }
                            />
                        </div>

                        <span
                            className="bg-[#166534] px-[5px]  rounded-r text-center py-1"
                            onClick={() => {
                                handleStockChange(item, "maximumStockLevel", "increase");
                            }}
                        >
                            <i className="ri-add-line text-white text-sm"></i>
                        </span>
                    </div>
                ),
                widthClass: "!border-t",
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex justify-center w-full items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50`}
                    >
                        <span
                            className="bg-[#166534] px-[5px]  rounded-l text-center py-1"
                            onClick={() => {
                                handleStockChange(item, "bufferStock", "decrease");
                            }}
                        >
                            <i className="ri-subtract-line text-white text-sm font-rocGroteskMedium"></i>
                        </span>
                        <div className="text-center">
                            <TextInput
                                name="bufferStock"
                                type="number"
                                id={bufferStockInputId}
                                value={inputValues[item?._id]?.bufferStock.toString() || ""}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value, 10) || 0;
                                    handleSelectEachProduct(item, {
                                        ...inputValues[item?._id],
                                        bufferStock: value,
                                    });
                                }}
                                inputClassName={"!text-center "}
                                disabled={!isChecked}
                                inputContainerClassname={
                                    "!rounded-none text-sm text-gun-metal px-2 py-1 uppercase !bg-[#F0FDF4] !min-w-[84px] !w-[84px]  !text-center !px-0 !py-0 !w-auto !h-[30px] !border-0"
                                }
                            />
                        </div>

                        <span
                            className="bg-[#166534] px-[5px]  rounded-r text-center py-1"
                            onClick={() => {
                                handleStockChange(item, "bufferStock", "increase");
                            }}
                        >
                            <i className="ri-add-line text-white text-sm"></i>
                        </span>
                    </div>
                ),
                widthClass: "!border-t",
            },
        ];
    });

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setProductDetails((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const countryComponent = address?.Location?.details?.[0]?.address_components.find((component) =>
        component.types.includes("country")
    );
    const onAddWareHouse = (e) => {
        e.preventDefault();
        const body = {
            address: {
                lat: address?.Location?.lng,
                lng: address?.Location?.lat,
                address: address?.Location?.address,
                details: [address?.Location?.details?.[0]?.formatted_address],
                country: countryComponent?.long_name,
            },
            contactPersonId: productDetails?.contactPerson,
            warehouseName: productDetails?.warehouseName,
            additionalNotes: productDetails?.additionalNote,
            products,
        };
        dispatch(inventoryActions.createWarehouse(body));
    };

    useEffect(() => {
        if (Boolean(createWarehouseSuccess)) {
            dispatch(inventoryActions.resetCreateWarehouse());
            setAddWarehouseSuccess(true);
        }
    }, [createWarehouseSuccess]);

    return (
        <div>
            <form onSubmit={(e) => onAddWareHouse(e)}>
                <div className="bg-white rounded-lg border border-slate-100">
                    <div className="">
                        <div className="flex top-[-2px] md:sticky md:px-8 py-4 px-[28px] z-[50] bg-white  items-center justify-between  border-b border-slate-100">
                            <p className="text-base font-rocGroteskMedium">New warehouse</p>
                            <div className="flex items-center gap-3">
                                <Button
                                    disabled={createWarehouse}
                                    btnText="Duplicate"
                                    btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                                />
                                <Button
                                    disabled={createWarehouse}
                                    btnText="Cancel"
                                    onClick={() => navigate("/dashboard/inventory/warehouse")}
                                    btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                                />
                                <Button
                                    btnText="Add warehouse"
                                    btnClassname="!px-4 !py-2.5 !w-fit"
                                    isLoading={createWarehouse}
                                    type="submit"
                                    permissions={getEditPermissionList("inventory")}
                                />
                            </div>
                        </div>
                        <div className="w-full py-6 px-8">
                            <div className="mb-6">
                                <div className="flex items-center w-full justify-between mb-4">
                                    <p className="text-sm font-rocGroteskMedium">
                                        Warehouse name <span className="text-r-50">*</span>
                                    </p>
                                </div>
                                <div className="border-b-[1px] border-b-[#E2E8F0] pb-5">
                                    <input
                                        type="text"
                                        placeholder="Enter a warehouse name"
                                        className="!text-2xl font-rocGroteskMedium  placeholder:text-slate-300 !border-none outline-none w-full
                                         placeholder:pl-1 caret-slate-700 !leading-6"
                                        ref={inputRef}
                                        name="warehouseName"
                                        value={productDetails?.warehouseName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col w-[70%] gap-6">
                                <div className="grid grid-cols-2 items-center gap-4">
                                    <SelectInput
                                        value={productDetails?.contactPerson}
                                        name="contactPerson"
                                        placeholder={"Contact Person"}
                                        handleChange={(name, value) => {
                                            handleChange({ target: { name, value } });
                                        }}
                                        handleInputChange={debounce((evt) => {
                                            setDebouncedSearch({
                                                search: evt.target.value,
                                            });
                                        }, 800)}
                                        setCustomDropdown={setSelectContent}
                                        searchLoading={fetchingTeamMember}
                                        isRequired={true}
                                        dropdownOptions={teamList?.map((team) => ({
                                            label: team?.email,
                                            value: team?._id,
                                        }))}
                                    />
                                    <AddressInput
                                        setData={setAddress}
                                        savedAddress={
                                            address?.streetAddress
                                                ? address?.streetAddress?.address
                                                : ""
                                        }
                                        placeholder="Location"
                                        required={true}
                                        name={"Location"}
                                    />
                                </div>
                                <div>
                                    <TextareaInput
                                        name={"additionalNote"}
                                        value={productDetails?.additionalNote}
                                        onChange={handleChange}
                                        rows={7}
                                        placeholder={"Additional Note"}
                                        required={true}
                                        containerClassname=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-8  mt-5">
                        <div className="w-[70%]">
                            {products?.length > 0 ? (
                                <p className="text-gm-50 mb-4 font-rocGroteskMedium text-base">
                                    products Added to warehouse
                                </p>
                            ) : (
                                <p className="text-gm-50 mb-4 font-rocGroteskMedium text-base">
                                    Add products to warehouse
                                </p>
                            )}
                            {products?.length > 0 ? (
                                <div className="">
                                    <div className=" w-full mt-5 ">
                                        <CustomTable
                                            tableBody={tableProductBody}
                                            tableHeader={tableProductHeader}
                                            isAllSelectable={false}
                                            isScrollable={true}
                                            isCellBordered={true}
                                            headerContainerClass="!border-t-none !bg-slate-50 !py-3 "
                                            bodyItemClass="hover:border-slate-100 hover:bg-slate-50 "
                                            headerItemClass="font-rocGroteskMedium !py-3 !text-slate-700"
                                        />
                                    </div>
                                    <div className=" my-6">
                                        <Button
                                            btnText={"View products"}
                                            type={"button"}
                                            btnClassname={"!py-2 !w-fit !text-gm-50"}
                                            disabled={createWarehouse}
                                            onClick={() => setAddProduct(true)}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="pt-4 mb-10">
                                    <div className="space-y-2.5 border-[1.5px] rounded border-dashed py-[43px] flex flex-col justify-center items-center">
                                        <i className="ri-box-3-line text-slate-500 text-[40px]"></i>
                                        <div className="flex items-center flex-col">
                                            <p className="text-gm-50 text-sm font-rocGroteskMedium">
                                                No products found
                                            </p>
                                            <p className="text-[#6B778C] font-rocGroteskMedium text-sm">
                                                Store all products in the warehouse for precise
                                                tracking and efficient management.
                                            </p>
                                        </div>
                                        <Button
                                            btnText="Add products"
                                            btnClassname="!bg-[#F4F5F7] !py-2 !w-fit"
                                            onClick={() => setAddProduct(true)}
                                            disabled={false}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        <ModalContainer
                            open={addProduct}
                            showCloseIcon={false}
                            closeModal={() => setAddProduct(false)}
                            tailwindClassName="w-[90%] lg:w-[1000px]"
                        >
                            <div className="bg-white  rounded-lg max-h-[90vh] overflow-y-scroll">
                                <div className=" px-6 py-4 flex gap-4 items-center justify-between border-b border-b-slate-200">
                                    <p className="text-lg text-gm-50 font-rocGroteskMedium">
                                        Select products and add to warehouse
                                    </p>
                                    <i
                                        onClick={() => setAddProduct(false)}
                                        className="ri-close-fill cursor-pointer text-gm-50 text-2xl "
                                    ></i>
                                </div>
                                <div className="my-8 mx-8  ">
                                    <div className="">
                                        <TextInput
                                            name="search"
                                            type="text"
                                            value={searchProduct}
                                            onChange={(e) => setSearchProduct(e.target.value)}
                                            inputPlaceholder="Search for product"
                                            inputClassName={
                                                "!h-[50px] pl-[0px] !mb-0  !font-rocGroteskMedium !text-slate-400"
                                            }
                                            leftIcon={
                                                <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                            }
                                            inputContainerClassname={`!rounded-tl-md !rounded-tr-md !rounded-bl-none !rounded-br-none !h-[50px] !pl-[40px] !bg-white !border !border-slate-200 ${
                                                searchInventoryList?.length > 0 &&
                                                "!border-b-[0.5px]"
                                            }`}
                                        />
                                    </div>
                                    {searchInventoryList?.length > 0 ? (
                                        <div className="">
                                            <div
                                                className="max-h-[300px] overflow-y-auto"
                                                id="custom-modal"
                                            >
                                                <CustomTable
                                                    tableBody={tableBody}
                                                    tableHeader={tableHeader}
                                                    isAllSelectable={true}
                                                    isCellBordered={true}
                                                    headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                                                    headerContainerClass="!bg-slate-50"
                                                    isScrollable={false}
                                                />

                                                {loadMoreSearchInventory &&
                                                    fetchingSearchProductListing && (
                                                        <div className="flex my-4 justify-center">
                                                            <Loader color="gm-25" size={4} />
                                                        </div>
                                                    )}
                                                {searchInventoryPagination?.current ===
                                                    searchInventoryPagination?.number_of_pages && (
                                                    <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                                        End of list
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex justify-center w-full my-[5%]">
                                            <div className="border w-[635px] border-slate-100 shadow-tableNoDataShadow rounded-[8px] bg-white p-10">
                                                <div className="flex space-x-6">
                                                    <i className="ri-box-3-line text-slate-500 text-6xl"></i>
                                                    <div className="space-y-6">
                                                        <div className="font-rocGroteskMedium space-y-2">
                                                            <p className="text-base text-gm-50 ">
                                                                You haven't added any products yet
                                                            </p>
                                                            <p className="text-slate-500 text-sm">
                                                                Start building your inventory by
                                                                adding products and their details.
                                                                Streamline your stock management
                                                                effortlessly.
                                                            </p>
                                                        </div>
                                                        <Button
                                                            btnClassname="!w-fit"
                                                            btnText="Add product"
                                                            onClick={() =>
                                                                navigate(
                                                                    "/dashboard/inventory/add-product"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {searchInventoryList?.length > 0 && (
                                    <div className="flex   px-6 border-t py-3 justify-between items-center">
                                        <div className=" ">
                                            <Button
                                                btnText={"Add new product"}
                                                icon={<i className="ri-add-fill"></i>}
                                                type={"button"}
                                                btnClassname={"!bg-n-20 !py-3 !w-fit !text-gm-50"}
                                                onClick={() =>
                                                    navigate("/dashboard/inventory/add-product")
                                                }
                                            />
                                        </div>
                                        <div className="flex  items-center space-x-2 ">
                                            <Button
                                                btnText={"Cancel"}
                                                type={"button"}
                                                btnClassname={"!bg-n-20  !py-3 !w-fit !text-gm-50"}
                                                disabled={false}
                                                onClick={() => setAddProduct(false)}
                                            />
                                            <Button
                                                btnClassname={"!py-3 !text-gm-50 !w-fit !px-8"}
                                                onClick={handleAddProductToWarehouse}
                                                btnText={"Add product to warehouse"}
                                                type={"button"}
                                                isLoading={false}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </ModalContainer>

                        {addWarehouseSuccess && (
                            <ModalContainer
                                open={addWarehouseSuccess}
                                showCloseIcon={false}
                                modalClassName={"py-10 px-10"}
                                tailwindClassName="w-[600px]"
                                closeModal={() => {
                                    setProductData([]);
                                    setProducts([]);
                                    setProductDetails({
                                        warehouseName: "",
                                        contactPerson: "",
                                        additionalNote: "",
                                    });
                                    setAddress({});
                                    setAddWarehouseSuccess(false);
                                    navigate("/dashboard/inventory/warehouse");
                                }}
                            >
                                <div className="bg-white py-7 px-6  shadow-cardShadow relative rounded-[8px]">
                                    <div className="mb-5">
                                        <div className="w-10 h-10 flex justify-center mb-4 items-center bg-[#00DB8F] rounded-full  shadow-lightGreenShadow">
                                            <i className="ri-checkbox-circle-fill text-[29px] text-white "></i>
                                        </div>
                                        <h1 className="text-lg mb-2  font-rocGroteskMedium">
                                            Warehouse Added Successfully
                                        </h1>
                                        <p className="text-slate-500 font-rocGroteskMedium  text-base">
                                            The new warehouse has been added to the system
                                            successfully. Ensure all details are accurate for
                                            seamless operations.
                                        </p>
                                    </div>
                                    <div className="flex justify-end items-center ">
                                        <div className="flex items-center !whitespace-nowrap space-x-2 ">
                                            <Button
                                                btnText={"No, don't transfer"}
                                                type={"button"}
                                                btnClassname={
                                                    "!bg-white !border !border-[#F4F5F7]!w-fit !text-gm-50"
                                                }
                                                disabled={false}
                                                onClick={() => {
                                                    setProductData([]);
                                                    setProducts([]);
                                                    setProductDetails({
                                                        warehouseName: "",
                                                        contactPerson: "",
                                                        additionalNote: "",
                                                    });
                                                    setAddress({});
                                                    setAddWarehouseSuccess(false);
                                                    navigate("/dashboard/inventory/warehouse");
                                                }}
                                            />
                                            <Button
                                                btnClassname={"!py-3 !text-gm-50  !w-fit"}
                                                onClick={() =>
                                                    navigate("/dashboard/inventory/warehouse")
                                                }
                                                btnText={"View warehouse"}
                                                type={"button"}
                                                isLoading={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </ModalContainer>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddWarehouse;
