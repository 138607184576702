import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { authActions, inventoryActions } from "redux/Ldb/actions";
import { StockTransferProductType, StockTransferType } from "types/StockTransferType";

type FromVendorProps = {
    stockData: StockTransferType;
    productData: StockTransferProductType[];
    handleChange: (name: string, value: any) => void;
    handleMultipleProductChange: (name: string, value: any, idx: number) => void;
    handleDeleteRow: (idx: number) => void;
    handleAddNewRow: () => void;
    // allProducts: { [key: string]: any }[];
    // setAllProducts: React.Dispatch<React.SetStateAction<{ [key: string]: any }[]>>;
};

const FromVendor = ({
    stockData,
    handleChange,
    productData,
    handleMultipleProductChange,
    handleDeleteRow,
    handleAddNewRow,
}: // allProducts, setAllProducts
FromVendorProps) => {
    const [teamList, setTeamList] = useState<{ [key: string]: any }[]>([]);
    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [debouncedWarehouseSearch, setDebouncedWarehouseSearch] = useState<string>("");
    const [warehouses, setWarehouses] = useState<{ [key: string]: any }[]>([]);
    const [selectedRow, setSelectedRow] = useState<number>(0);
    const [allProducts, setAllProducts] = useState<{ [key: string]: any }[]>([]);
    const dispatch = useAppDispatch();
    const { fetchingTeamMember, fetchedTeamMemberSuccess } = useAppSelector((state) => state.auth);

    const {
        fetchingSearchInventoryListing,
        fetchedSearchInventoryListingSuccess,
        fetchingWarehouseList,
        fetchedWarehouseListSuccess,
        createWarehouseSuccess,
    } = useAppSelector((state) => state.inventory);

    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e.target.value);
    }, 1500);

    const tableHeader = [
        { title: "No", widthClass: "w-[7%]" },
        { title: "Product name", widthClass: "w-[68.5%]" },
        { title: "Quantity", widthClass: "w-[19.7%]" },
        { title: "", widthClass: "w-[4.6%]" },
    ];

    const tableBody = productData?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        className={`w-full flex items-center gap-2 border-slate-100 text-sm text-slate-700 `}
                    >
                        {idx + 1}
                    </div>
                ),
            },
            {
                content: (
                    <div className={`w-full  border-slate-100 text-sm text-slate-700 `}>
                        <SelectInput
                            value={item.productName}
                            name="productName"
                            inputPlaceholder={"Select..."}
                            handleChange={(name, value) =>
                                handleMultipleProductChange(name, value, idx)
                            }
                            handleInputChange={(e) => handleDebouncedChange(e)}
                            isRequired={true}
                            searchLoading={fetchingSearchInventoryListing}
                            className="!border-0 !rounded-none"
                            dropdownOptions={allProducts?.map((product) => ({
                                label: product?.productName,
                                value:
                                    product?._id +
                                    "-" +
                                    product?.productName +
                                    "-" +
                                    JSON.stringify(product?.vendors?.map((vendor) => vendor?._id)),
                            }))}
                        />
                    </div>
                ),
                // widthClass: `w-[287px] absolute`,
                tableDataDivClass: "!pl-0",
            },

            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={item.qty}
                            name={"qty"}
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            required={true}
                            onChange={(e) =>
                                handleMultipleProductChange(e.target.name, e.target.value, idx)
                            }
                            inputPlaceholder="0.00"
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        {idx > 0 && (
                            <i
                                onClick={() => handleDeleteRow(idx)}
                                className="ri-delete-bin-line cursor-pointer"
                            ></i>
                        )}
                    </div>
                ),
            },
        ];
    });

    useEffect(() => {
        dispatch(authActions.getTeamMembers(100));
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamList(fetchedTeamMemberSuccess?.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        dispatch(inventoryActions.fetchSearchInventoryListing(30, debouncedSearch));
    }, [dispatch, debouncedSearch]);

    useEffect(() => {
        if (Boolean(fetchedSearchInventoryListingSuccess)) {
            setAllProducts(fetchedSearchInventoryListingSuccess?.inventory?.data);
        }
    }, [fetchedSearchInventoryListingSuccess]);

    return (
        <>
            <div>
                <div className="mb-6">
                    <label className="text-base font-rocGroteskMedium">Products</label>
                    <div className="mt-4">
                        <CustomTable
                            tableBody={tableBody}
                            tableHeader={tableHeader}
                            isAllSelectable={true}
                            isCellSelectable
                            headerContainerClass=""
                            isScrollable={false}
                            isCellBordered={true}
                            bodyItemClass={"hover:!border-[1.5px] hover:!border-gm-50 "}
                        />
                    </div>
                    <div className="flex items-center gap-3 mt-4">
                        <Button
                            btnText="Add more products"
                            btnClassname="text-[13px] !px-4 !py-2 !bg-n-20 !w-fit"
                            onClick={() => handleAddNewRow()}
                        />
                    </div>
                </div>

                <div className="w-[65%]">
                    <label className="text-base font-rocGroteskMedium">Notes</label>
                    <div className="mt-4">
                        <TextareaInput
                            name={"note"}
                            value={stockData.note}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            rows={5}
                            placeholder={"Add note"}
                            required={false}
                            containerClassname=""
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default FromVendor;
