import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "components/common/CustomTable";
import VendorAvatar from "components/common/VendorAvatar";
import { truncate } from "lodash";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import { formatMoney, getCurrencyFromCurrencyCode, onScroll } from "helpers";
import Loader from "components/common/Loader";
import moment from "moment";

const PurchaseOrderInventory = () => {
    const limit = 20;
    const [isScrolled, setIsScrolled] = useState(false);
    const [poLimit, setPOLimit] = useState(limit);
    const [loadMorePO, setLoadMorePO] = useState(false);
    const [poList, setPOList] = useState<any>([]);
    const [poPagination, setPOPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const dashboardContent = document.getElementById("dashboardContent");

    const { fetchedSingleInventoryPurchaseOrderSuccess, fetchingSingleInventoryPurchaseOrder } =
        useAppSelector((state) => state.inventory);

    useEffect(() => {
        dispatch(inventoryActions.fetchSingleInventoryPO(id, poLimit));
    }, [id, poLimit]);

    useEffect(() => {
        if (Boolean(fetchedSingleInventoryPurchaseOrderSuccess)) {
            setPOList(fetchedSingleInventoryPurchaseOrderSuccess?.purchaseOrders?.data);
            setPOPagination({
                current: fetchedSingleInventoryPurchaseOrderSuccess?.pagination?.current,
                number_of_pages:
                    fetchedSingleInventoryPurchaseOrderSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedSingleInventoryPurchaseOrderSuccess]);

    const onPOScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                poPagination?.current as number,
                poPagination?.number_of_pages as number,
                () => {
                    setPOLimit(() => poLimit + limit);
                    setLoadMorePO(true);
                }
            ),
        [dashboardContent, poPagination, poLimit]
    );

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onPOScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onPOScroll);
        };
    }, [dashboardContent, onPOScroll]);

    const tableHeader = [
        { title: "PO Number", widthClass: "w-[100px]" },
        { title: "Vendor/Supplier", widthClass: "w-[246px]" },
        { title: "Assigned to", widthClass: "w-[202px]" },
        { title: "Issue Date", widthClass: "w-[130px]" },
        { title: "Due Date", widthClass: "w-[130px]" },
        { title: "Total Amount", widthClass: "w-[116px]" },
        { title: "Status", widthClass: "w-[140px]" },
    ];

    const tableBody = poList?.map((po) => [
        {
            content: (
                <div
                    key={po?._id}
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    PO {po?.taId}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3  border-slate-100 text-sm text-slate-900 font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-3">
                        <VendorAvatar
                            name={po?.vendor?.profile?.businessName}
                            imageSrc={po?.vendor?.profile?.avatar}
                            size={32}
                            containerClassname=" !rounded-none"
                        />

                        <span>{po?.vendor?.profile?.businessName}</span>
                    </div>
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3   border-slate-100 text-sm text-slate-900 font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-3">
                        <VendorAvatar name={po?.assignee?.[0]?.profile?.businessName} imageSrc={po?.assignee?.[0]?.profile?.avatar} size={32} />

                        <span>{po?.assignee?.[0]?.profile?.businessName}</span>
                    </div>
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {moment(po?.issuedDate).format("MMM DD, YYYY")}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {moment(po?.dueDate).format("MMM DD, YYYY")}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {getCurrencyFromCurrencyCode(po?.totalAmount?.currency)}
                    {formatMoney()?.format(po?.totalAmount?.amount)}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3  items-center h-full flex space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    <div
                        className={`h-2 w-2 rounded-full ${
                            po?.currentStatus?.toLowerCase() === "accepted" && "bg-g-50"
                        }  ${po?.currentStatus?.toLowerCase() === "progress" && "bg-[#00C6FA]"} ${
                            po?.currentStatus?.toLowerCase() === "rejected" && "bg-[#FF3347]"
                        }  ${po?.currentStatus?.toLowerCase() === "pending" && "bg-[#F59E0B]"} `}
                    ></div>
                    <p>{po?.currentStatus}</p>
                </div>
            ),
            widthClass: "!border-b",
        },
    ]);

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if ((e.target as any).scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    return (
        <div className="w-full">
            <div className="overflow-x-auto w-full hidden lg:block" onScroll={handleScroll}>
                {fetchingSingleInventoryPurchaseOrder ? (
                    <div>
                        <Loader />
                    </div>
                ) : (
                    <>
                        {poList?.length > 0 ? (
                            <>
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isScrollable={true}
                                    isScrolled={isScrolled}
                                    isCellBordered={false}
                                    headerContainerClass="!bg-slate-50  !border-slate-100 "
                                    bodyItemClass="hover:border-slate-100 hover:bg-slate-50 "
                                    headerItemClass="font-rocGroteskMedium !text-slate-700"
                                />
                                {loadMorePO && fetchingSingleInventoryPurchaseOrder && (
                                    <div className="flex my-4 justify-center">
                                        <Loader color="gm-25" size={4} />
                                    </div>
                                )}
                                {poPagination?.current === poPagination?.number_of_pages && (
                                    <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                        End of list
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="flex items-center justify-center mt-[76px]">
                                <div>
                                    <img
                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1704890969/Tradeally_2.0/empty_state_img_a8twwl.svg"
                                        alt="empty state"
                                        className="w-[268px] h-[235px]"
                                    />
                                    <p className="text-center text-sm text-[#475569] font-rocGroteskMedium mt-5">
                                        You have no purchase order
                                    </p>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default PurchaseOrderInventory;
