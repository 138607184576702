import React, { useEffect, useState } from "react";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import { displayFileIcon } from "helpers";
import MultiselectInput from "components/common/InputField/MultiselectInput";
import { ProductDataType } from "types/productDataType";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions, vendorActions } from "redux/Ldb/actions";
import { debounce } from "lodash";
import VendorAvatar from "components/common/VendorAvatar";

type SingleProductProps = {
    productData: ProductDataType;
    handleChange: (name: string, value: any) => void;
    setAddWarehouseModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const SingleProduct = ({
    productData,
    handleChange,
    setAddWarehouseModal,
}: SingleProductProps): JSX.Element => {
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [debouncedWarehouseSearch, setDebouncedWarehouseSearch] = useState<string>("");
    const [onboardedVendors, setOnboardedVendors] = useState<{ [key: string]: any }[]>([]);
    const [warehouses, setWarehouses] = useState<{ [key: string]: any }[]>([]);

    const dispatch = useAppDispatch();
    const { fetchedLdbVendorsSuccess, fetchingLdbVendors } = useAppSelector(
        (state) => state.vendor
    );
    const { fetchingWarehouseList, fetchedWarehouseListSuccess, createWarehouseSuccess } =
        useAppSelector((state) => state.inventory);

    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e.target.value);
    }, 1500);

    const handleWarehouseDebouncedChange = debounce((e) => {
        setDebouncedWarehouseSearch(e.target.value);
    }, 1500);

    useEffect(() => {
        dispatch(vendorActions.getLdbVendors(20, debouncedSearch, "accepted"));
    }, [dispatch, debouncedSearch]);

    useEffect(() => {
        if (Boolean(fetchedLdbVendorsSuccess)) {
            setOnboardedVendors(fetchedLdbVendorsSuccess?.vendors);
        }
    }, [fetchedLdbVendorsSuccess]);

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouseList(20, debouncedWarehouseSearch));
    }, [dispatch, debouncedWarehouseSearch, createWarehouseSuccess]);

    useEffect(() => {
        if (Boolean(fetchedWarehouseListSuccess)) {
            setWarehouses(fetchedWarehouseListSuccess?.warehouses?.data);
        }
    }, [fetchedWarehouseListSuccess]);

    return (
        <div className="w-full">
            <div className="mb-6">
                <div className="flex items-center w-full justify-between mb-4">
                    <p className="text-sm font-rocGroteskMedium">
                        Product name <span className="text-r-50">*</span>
                    </p>
                </div>
                <div className="border-b-[1px] border-b-[#E2E8F0] pb-5">
                    <input
                        type="text"
                        placeholder="Enter a product name"
                        className="!text-2xl font-rocGroteskMedium  placeholder:text-[#CBD5E1] !border-none outline-none w-full placeholder:pl-1 caret-slate-700 !leading-6"
                        // ref={inputRef}
                        name="productName"
                        value={productData.productName}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        required
                    />
                </div>
            </div>
            <div className="flex flex-col gap-6">
                <div className="grid grid-cols-2 items-center gap-4">
                    <SelectInput
                        value={productData.inventoryCategory}
                        name="inventoryCategory"
                        placeholder={"Inventory categories"}
                        handleChange={(name, value) => {
                            handleChange(name, value);
                        }}
                        isRequired={true}
                        dropdownOptions={[
                            {
                                label: "Raw Materials",
                                value: "Raw Material",
                            },
                            {
                                label: "Work-in-Progress (WIP)",
                                value: "Work-In-Progress (WIP)",
                            },
                            {
                                label: "Finished Goods",
                                value: "Finished Goods",
                            },
                            {
                                label: "Maintenance, Repair, and Overall (MRO)",
                                value: "Maintenance, Repair, and Overall (MRO)",
                            },
                        ]}
                    />
                    <MultiselectInput
                        value={productData.vendors}
                        name="vendors"
                        placeholder={"Vendor"}
                        handleChange={(name, value, selectedItems) => {
                            handleChange(name, selectedItems);
                        }}
                        searchLoading={fetchingLdbVendors}
                        handleInputChange={(e) => handleDebouncedChange(e)}
                        isRequired={true}
                        dropdownOptions={onboardedVendors?.map((vendor) => ({
                            label: vendor?.vendor?.profile?.businessName,
                            value: vendor.vendorId,
                            subText: vendor?.category,
                            icon: (
                                <VendorAvatar
                                    size={30}
                                    imageSrc={vendor?.vendor?.profile?.avatar}
                                    name={vendor?.vendor?.profile?.businessName}
                                />
                            ),
                        }))}
                    />
                    <SelectInput
                        value={productData.unitOfMeasurement}
                        name="unitOfMeasurement"
                        placeholder={"Unit of measure"}
                        handleChange={(name, value) => {
                            handleChange(name, value);
                        }}
                        isRequired={true}
                        dropdownOptions={[
                            {
                                label: "Unit",
                                value: "unit",
                            },
                            {
                                label: "Pcs",
                                value: "pcs",
                            },
                            {
                                label: "Units",
                                value: "units",
                            },
                            {
                                label: "Cartridges",
                                value: "cartridges",
                            },
                            {
                                label: "Rolls",
                                value: "rolls",
                            },
                            {
                                label: "Box",
                                value: "box",
                            },
                        ]}
                    />

                    <SelectInput
                        value={productData.selectAttribute}
                        name="selectAttribute"
                        placeholder={"Select attribute"}
                        handleChange={(name, value) => {
                            handleChange(name, value);
                        }}
                        isRequired={false}
                        dropdownOptions={[
                            {
                                label: "Color",
                                value: "color",
                            },
                            {
                                label: "Size",
                                value: "size",
                            },
                        ]}
                    />
                    <TextInput
                        value={productData.attributeValue}
                        name={"attributeValue"}
                        type={"text"}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        placeholder={"Attribute"}
                        required={false}
                        containerClassname=""
                    />
                </div>

                <div>
                    <TextareaInput
                        name={"description"}
                        value={productData.description}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        rows={5}
                        placeholder={"Description"}
                        required={true}
                        containerClassname=""
                    />
                </div>

                <div>
                    <label className="text-base font-rocGroteskMedium">Warehouse details</label>
                    <div className="mt-4">
                        <div className="mb-[18px]">
                            <SelectInput
                                value={productData.warehouse}
                                name="warehouse"
                                placeholder={"Select location"}
                                handleChange={(name, value) => {
                                    handleChange(name, value);
                                }}
                                searchLoading={fetchingWarehouseList}
                                handleInputChange={(e) => handleWarehouseDebouncedChange(e)}
                                isRequired={true}
                                optionItemContainerClassName="border-b border-slate-200"
                                dropdownOptions={warehouses?.map((warehouse) => ({
                                    label: warehouse?.warehouseName,
                                    value: warehouse?._id,
                                }))}
                            />
                        </div>

                        <div
                            onClick={() => setAddWarehouseModal(true)}
                            className="flex items-center gap-2 text-b-50 font-rocGroteskMedium w-fit cursor-pointer"
                        >
                            <i className="ri-add-fill text-xl"></i>
                            <span className="underline text-sm">Add new warehouse</span>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                    <TextInput
                        value={productData.minimumStockLevel}
                        name={"minimumStockLevel"}
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                            evt.preventDefault()
                        }
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        placeholder={"Minimum stock level"}
                        required={true}
                        containerClassname=""
                    />
                    <TextInput
                        value={productData.maximumStockLevel}
                        name={"maximumStockLevel"}
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                            evt.preventDefault()
                        }
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        placeholder={"Maximum stock level"}
                        required={true}
                        containerClassname=""
                    />
                    <TextInput
                        value={productData.bufferStockLevel}
                        name={"bufferStockLevel"}
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                            evt.preventDefault()
                        }
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        placeholder={"Buffer stock level"}
                        required={true}
                        containerClassname=""
                    />
                </div>

                <div>
                    <label className="text-base font-rocGroteskMedium">
                        Is this a perishable goods?
                    </label>
                    <div className="flex items-center mt-4">
                        <div
                            className="mr-5 cursor-pointer flex items-center"
                            onClick={() => handleChange("isProductPerishable", true)}
                        >
                            {productData.isProductPerishable ? (
                                <i
                                    className={`ri-radio-button-fill text-xl ${
                                        productData.isProductPerishable && "text-y-10"
                                    }`}
                                ></i>
                            ) : (
                                <span className={`material-icons text-xl text-n-50`}>
                                    radio_button_unchecked
                                </span>
                            )}
                            <span className="text-base font-rocGroteskMedium ml-[5px]">Yes</span>
                        </div>

                        <div
                            className="cursor-pointer flex items-center"
                            onClick={() => handleChange("isProductPerishable", false)}
                        >
                            {!productData.isProductPerishable ? (
                                <i
                                    className={`ri-radio-button-fill text-xl ${
                                        !productData.isProductPerishable && "text-y-10"
                                    }`}
                                ></i>
                            ) : (
                                <span className={`material-icons text-xl text-n-50`}>
                                    radio_button_unchecked
                                </span>
                            )}
                            <span className="text-base font-rocGroteskMedium ml-[5px] translate-y-[1px]">
                                No
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleProduct;
