/* eslint-disable */
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { displayFileIcon, getCurrencyFromCurrencyCode } from "helpers";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { truncate } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";

const SuppliedStockDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [suppliedStock, setSuppliedStock] = useState<{ [key: string]: any }>({});

    const { fetchingSingleSuppliedStock, fetchedSingleSuppliedStockSuccess } = useAppSelector(
        (state) => state.inventory
    );

    useEffect(() => {
        if (Boolean(fetchedSingleSuppliedStockSuccess)) {
            setSuppliedStock(fetchedSingleSuppliedStockSuccess?.supplyStock);
        }
    }, [fetchedSingleSuppliedStockSuccess]);

    useEffect(() => {
        if (id) {
            dispatch(inventoryActions?.fetchSingleSuppliedStock(id));
        }
    }, []);

    const downloadAttach = (attachment) => {
        fetch(attachment?.url).then((response) => {
            response.blob().then((blob) => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = attachment?.name;
                alink.click();
            });
        });
    };

    const tableHeader = [
        { title: "Product name", widthClass: "w-[75%] !font-rocGroteskMedium" },
        { title: "Qty", widthClass: "w-[7%] !font-rocGroteskMedium" },
        { title: "Unit cost", widthClass: "w-[10%] !font-rocGroteskMedium" },
        { title: "Total cost", widthClass: "w-[10%] !font-rocGroteskMedium" },
    ];
    const tableBody = [
        [
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gun-metal font-rocGroteskMedium flex gap-2 p-4`}
                    >
                        <VendorAvatar
                            size={32}
                            imageSrc={suppliedStock?.inventory?.productImageDetails?.productAvatar}
                            name={suppliedStock?.inventory?.productName}
                        />
                        <div className="text-sm">
                            <p className="text-gun-metal">
                                {suppliedStock?.inventory?.productName}
                            </p>

                            <p className="text-slate-500">{suppliedStock?.inventory?.sku}</p>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gun-metal py-4 px-4 font-rocGroteskMedium`}
                    >
                        <p className="">{suppliedStock?.quantity}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gun-metal py-4 px-4 font-rocGroteskMedium`}
                    >
                        <p className="">{`${getCurrencyFromCurrencyCode(
                            suppliedStock?.inventory?.unitCost?.currency
                        )} ${suppliedStock?.inventory?.unitCost?.amount} `}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gun-metal py-4 px-4 font-rocGroteskMedium`}
                    >
                        <p className="">
                            {`${getCurrencyFromCurrencyCode(
                                suppliedStock?.inventory?.unitCost?.currency
                            )} ${
                                suppliedStock?.quantity * suppliedStock?.inventory?.unitCost?.amount
                            } `}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
        ],
    ];

    return (
        <PageFrame
            isLoading={fetchingSingleSuppliedStock}
            containerClassName={"h-[calc(100vh-14px)]"}
        >
            <div className="">
                <div className="bg-white rounded-lg border border-slate-100">
                    <div className="">
                        <div className="flex items-center justify-between px-8 py-4 border-b border-n-20 top-[-10px] md:sticky  z-[50] bg-white">
                            <p className="text-base font-rocGroteskMedium text-gun-metal">
                                Record Supplied Stocks
                            </p>
                            <div className="flex gap-3">
                                <div className="flex items-center gap-2 text-sm font-rocGroteskMedium">
                                    <p
                                        className={`rounded-full w-2 h-2 ${
                                            suppliedStock?.status === "fulfilled"
                                                ? "bg-g-50"
                                                : suppliedStock?.status === "inProgress"
                                                ? "bg-[#00C6FA]"
                                                : suppliedStock?.status === "pending"
                                                ? "bg-[#F59E0B]"
                                                : suppliedStock?.status === "rejected"
                                                ? "bg-[#FF3347]"
                                                : suppliedStock?.status === "draft" && "bg-slate-300"
                                        }`}
                                    ></p>
                                    <p className="capitalize">
                                        {" "}
                                        {suppliedStock?.status === "inProgress"
                                            ? "In Progress"
                                            : suppliedStock?.status}
                                    </p>
                                </div>

                                <Button
                                    btnText="Close"
                                    btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                                    onClick={() =>
                                        navigate("/dashboard/inventory/stock-control?tabId=3")
                                    }
                                />
                                <Button
                                    btnText="Edit"
                                    btnClassname="!px-4 !py-2 !w-fit"
                                    onClick={() =>
                                        navigate(`/dashboard/inventory/edit-supplied-stock/${id}`)
                                    }
                                />
                            </div>
                        </div>

                        <div className="w-full pt-6 pb-8 px-8">
                            <div className="w-full mb-8">
                                <p className="text-sm font-rocGroteskMedium mb-2">
                                    Supplied Stocks number <span className="text-r-50">*</span>
                                </p>
                                <p className="text-2xl font-rocGroteskMedium text-slate-700">
                                    {suppliedStock?.reference}
                                </p>
                            </div>

                            <div className="pt-6 pb-8 border-b border-slate-100 w-full grid grid-cols-3 gap-y-6 font-rocGroteskMedium">
                                <div>
                                    <p className="text-sm text-gm-50 ">Company</p>
                                    <p className="text-sm text-slate-500">
                                        {suppliedStock?.customerDetails?.companyName || "N/A"}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm text-gm-50 ">Contact name</p>
                                    <p className="text-sm text-slate-500">
                                        {suppliedStock?.customerDetails?.name}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm text-gm-50 ">Email address</p>
                                    <p className="text-sm text-slate-500">
                                        {suppliedStock?.customerDetails?.email}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm text-gm-50 ">Phone number</p>
                                    <p className="text-sm text-slate-500">
                                        {suppliedStock?.customerDetails?.mobile || "N/A"}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm text-gm-50 ">Country</p>
                                    <p className="text-sm text-slate-500">
                                        {suppliedStock?.customerDetails?.country || "N/A"}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm text-gm-50 ">Date of supply</p>
                                    <p className="text-sm text-slate-500">
                                        {moment(suppliedStock?.dateOfSupply)
                                            ?.format("DD MMM, YYYY")}
                                    </p>
                                </div>
                            </div>

                            <div className="py-8 border-b border-slate-100   font-rocGroteskMedium">
                                <p className="text-lg text-gm-50 pb-8">Order details</p>
                                <div className="w-full grid grid-cols-3 gap-y-4">
                                    <div>
                                        <p className="text-sm text-gm-50 ">Warehouse location</p>
                                        <p className="text-sm text-slate-500">
                                            {suppliedStock?.warehouse?.warehouseFrom?.address
                                                ?.address || "N/A"}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gm-50 ">Procurement Manager</p>
                                        <p className="text-sm text-slate-500">
                                            {suppliedStock?.procurementManager?.fullName ||
                                                suppliedStock?.procurementManager?.email}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gm-50 ">Delivery timeline</p>
                                        <p className="text-sm text-slate-500">
                                            {`${moment(suppliedStock?.deliveryTimeline?.start)
                                                ?.format("DD MMM, YYYY")} -
                                                ${moment(suppliedStock?.deliveryTimeline?.end)
                                                    ?.format("DD MMM, YYYY")} `}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gm-50 ">Delivery address</p>
                                        <p className="text-sm text-slate-500">
                                            {`${suppliedStock?.deliveryAddress?.address}` || "N/A"}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gm-50 ">Assigned to</p>
                                        <p className="text-sm text-slate-500">
                                            {suppliedStock?.assignee?.fullName ||
                                                suppliedStock?.assignee?.email}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gm-50 ">Order source</p>
                                        <p className="text-sm text-slate-500">
                                            {suppliedStock?.orderSource || "N/A"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                <div className="mt-6 mb-8">
                                    <p className="text-base text-gm-50 font-rocGroteskMedium mb-4">
                                        Products
                                    </p>
                                    <CustomTable
                                        tableBody={tableBody}
                                        tableHeader={tableHeader}
                                        isCellSelectable={false}
                                        headerContainerClass=""
                                        isScrollable={false}
                                        isCellBordered={true}
                                    />
                                </div>

                                <div>
                                    {suppliedStock?.documents?.length > 0 && (
                                        <div className="py-4 ">
                                            <p className="text-base text-gm-50 font-rocGroteskMedium mb-4">
                                                Attachments
                                            </p>

                                            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                                                {suppliedStock?.documents?.map((item) => {
                                                    return (
                                                        <div className="flex items-center justify-between rounded-md border border-slate-100 p-3 w-full">
                                                            <div className="flex items-center gap-3">
                                                                <p className="h-7 w-7">
                                                                    {displayFileIcon(
                                                                        getFileTypeFromUrl(
                                                                            item?.url
                                                                        ),
                                                                        false
                                                                    )}
                                                                </p>
                                                                <div>
                                                                    <p className="text-sm font-rocGroteskMedium">
                                                                        {truncate(item?.name, {
                                                                            length: 30,
                                                                        })}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <i
                                                                className="ri-download-line text-xl cursor-pointer"
                                                                onClick={() => downloadAttach(item)}
                                                            ></i>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="w-full py-6 border-b border-slate-200 font-rocGroteskMedium">
                                    <p className=" text-slate-700 text-xs mb-3">Recorded by</p>
                                    <div className=" flex items-center gap-3 text-sm">
                                        <VendorAvatar
                                            imageSrc={suppliedStock?.creator?.profile?.avatar}
                                            name={suppliedStock?.creator?.profile?.businessName}
                                            size={32}
                                        />

                                        <div className="">
                                            <p className="text-gun-metal">
                                                {suppliedStock?.creator?.fullName}
                                            </p>
                                            <p className="text-slate-500">
                                                {suppliedStock?.creator?.email}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="w-full pt-6 pb-2 border-b border-slate-200 font-rocGroteskMedium">
                                    <p className=" text-slate-700 text-xs mb-3">Approved by</p>
                                    <div className="flex gap-3 flex-col">
                                        <div className="flex items-center justify-between border-b border-slate-100 py-4">
                                            <div className=" flex items-center gap-3 text-sm">
                                                {suppliedStock?.creator?.profile?.avatar && (
                                                    <VendorAvatar
                                                        imageSrc={
                                                            suppliedStock?.creator?.profile?.avatar
                                                        }
                                                        name={
                                                            suppliedStock?.creator?.profile
                                                                ?.businessName
                                                        }
                                                        size={32}
                                                    />
                                                )}

                                                <div className="">
                                                    <p className="text-gun-metal">
                                                        {suppliedStock?.creator?.fullName}
                                                    </p>
                                                    <p className="text-slate-500">
                                                        {suppliedStock?.creator?.email}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="flex items-center gap-2 text-xs font-rocGroteskMedium">
                                                <p className="bg-[#f1f5f9] rounded py-1 px-3">
                                                    {" "}
                                                    Level 1
                                                </p>
                                                <p className="bg-[#F9DFB3] rounded py-1 px-3">
                                                    Pending
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between border-b border-slate-100 py-4">
                                            <div className=" flex items-center gap-3 text-sm">
                                                {suppliedStock?.creator?.profile?.avatar && (
                                                    <VendorAvatar
                                                        imageSrc={
                                                            suppliedStock?.creator?.profile?.avatar
                                                        }
                                                        name={
                                                            suppliedStock?.creator?.profile
                                                                ?.businessName
                                                        }
                                                        size={32}
                                                    />
                                                )}

                                                <div className="">
                                                    <p className="text-gun-metal">
                                                        {suppliedStock?.creator?.fullName}
                                                    </p>
                                                    <p className="text-slate-500">
                                                        {suppliedStock?.creator?.email}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="flex items-center gap-2 text-xs font-rocGroteskMedium">
                                                <p className="bg-[#f1f5f9] rounded py-1 px-3">
                                                    {" "}
                                                    Level 1
                                                </p>
                                                <p className="bg-[#F9DFB3] rounded py-1 px-3">
                                                    Pending
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                {suppliedStock?.notes && (
                                    <div className="w-full py-6">
                                        <p className="text-sm font-rocGroteskMedium text-gun-metal">
                                            Additional Note
                                        </p>
                                        <p className="text-sm font-rocGroteskMedium text-slate-500 mt-2">
                                            {suppliedStock?.notes}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default SuppliedStockDetails;
