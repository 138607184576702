import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import TabsContainer from "components/common/TabsContainer";
import React, { useEffect, useState } from "react";
import { ProductDataType } from "types/productDataType";
import NewUploads from "../ImportFromExcel/NewUploads";
import * as XLSX from "xlsx";
import AllUploads from "../ImportFromExcel/AllUploads";
import MultiselectInput from "components/common/InputField/MultiselectInput";
import Loader from "components/common/Loader";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { debounce } from "lodash";
import { inventoryActions, vendorActions } from "redux/Ldb/actions";
import VendorAvatar from "components/common/VendorAvatar";

type SingleProductProps = {
    productDataArr: ProductDataType[];
    handleMultipleProductChange: (name: string, value: any, idx: number) => void;
    handleDeleteRow: (idx: number) => void;
    handleAddNewRow: () => void;
    triggerImageUpload: (idx: number) => void;
    uploadingFiles: boolean;
    selectedRow: number;
    setProductDataArr: React.Dispatch<React.SetStateAction<ProductDataType[]>>;
    skipDuplicates?: boolean;
    setSkipDuplicates?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ExcelUpload = ({
    productDataArr,
    handleMultipleProductChange,
    handleDeleteRow,
    handleAddNewRow,
    uploadingFiles,
    selectedRow,
    triggerImageUpload,
    setProductDataArr,
    skipDuplicates,
    setSkipDuplicates,
}: SingleProductProps) => {
    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [debouncedWarehouseSearch, setDebouncedWarehouseSearch] = useState<string>("");
    const [onboardedVendors, setOnboardedVendors] = useState<{ [key: string]: any }[]>([]);
    const [warehouses, setWarehouses] = useState<{ [key: string]: any }[]>([]);
    const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
    const [readingFile, setReadingFile] = useState<boolean>(false);
    const [openMapFieldsModal, setOpenMapFieldsModal] = useState<boolean>(false);
    const [excelSheetHeader, setExcelSheetHeader] = useState<Array<string | number> | unknown>([]);
    const [fileToUpload, setFileToUpload] = useState<File | null>(null);
    const [activeTab, setActiveTab] = useState(0);
    const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
    const [showInfo, setShowInfo] = useState<boolean>(true);
    const [matchingColumnIds, setMatchingColumnIds] = useState<{ [key: string]: string }>({
        productName: "",
        unitOfMeasurement: "",
        minimumStockLevel: "",
        maximumStockLevel: "",
        bufferStockLevel: "",
    });
    const [sheetData, setSheetData] = useState<Array<string | number>[] | unknown[]>([]);
    const [matchedColumnData, setMatchedColumnData] = useState<ProductDataType[]>([]);
    const tabs = ["New Upload"];
    const mappedFields = Object.values(matchingColumnIds).map((item) => Number(item));
    const unmappedFields = (excelSheetHeader as Array<string | number>)?.filter(
        (item, idx) => !mappedFields.includes(idx)
    );
    const dispatch = useAppDispatch();
    const { fetchedLdbVendorsSuccess, fetchingLdbVendors } = useAppSelector(
        (state) => state.vendor
    );
    const { fetchingWarehouseList, fetchedWarehouseListSuccess, createWarehouseSuccess } =
        useAppSelector((state) => state.inventory);

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return !Boolean(fileToUpload) ? (
                    <div className="w-full relative rounded border-[#BFCDE0] border-dashed border my-6">
                        <div className="py-8 font-rocGroteskMedium flex justify-center flex-col items-center">
                            <i className="ri-upload-cloud-2-line mb-4 text-4xl  text-slate-500 "></i>
                            <p className="text-[#142837] mb-1 text-sm ">
                                Drag your file here to upload
                            </p>
                            <p className="text-[#6B778C] text-sm mb-4">
                                XLSX | File size limit: 25MB
                            </p>
                            <Button
                                btnText="Browse files"
                                btnClassname="!bg-white !py-2 !px-4 border border-[#DFE1E6] !text-[#142837] !text-[13px] !w-auto"
                            />
                        </div>
                        <input
                            id="uploadSheet"
                            type="file"
                            className="opacity-0 absolute w-full h-full top-0 cursor-pointer"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={(e) => setFileToUpload(e.target.files[0] as File)}
                        />
                    </div>
                ) : (
                    <NewUploads
                        fileToUpload={fileToUpload}
                        setFileToUpload={setFileToUpload}
                        skipDuplicates={skipDuplicates}
                        setSkipDuplicates={setSkipDuplicates}
                    />
                );
            // case 1:
            //     return <AllUploads />;
            default:
                return;
        }
    };

    const fields = [
        { label: "Product Name", value: "productName" },
        { label: "Description", value: "description" },
        { label: "Unit of Measurement", value: "unitOfMeasurement" },
        { label: "Minimum stock level", value: "minimumStockLevel" },
        { label: "Maximum stock level", value: "maximumStockLevel" },
        { label: "Buffer stock level", value: "bufferStockLevel" },
    ];

    const tableHeader = [
        { title: "Add Images", widthClass: "w-[111px]" },
        { title: "Product name", widthClass: "w-[287px]" },
        { title: "Inventory categories", widthClass: "w-[215px]" },
        { title: "Description", widthClass: "w-[250px]" },
        { title: "Unit of measure", widthClass: "w-[151px]" },
        { title: "Select attribute", widthClass: "w-[138px]" },
        { title: "Attribute name", widthClass: "w-[138px]" },
        { title: "Vendor/Supplier", widthClass: "w-[180px]" },
        { title: "Warehouse location", widthClass: "w-[180px]" },
        { title: "BufferStock", widthClass: "w-[140px]" },
        { title: "Min Stock Level", widthClass: "w-[140px]" },
        { title: "Max Stock Level", widthClass: "w-[140px]" },
        { title: "Perishable goods", widthClass: "w-[160px]" },
        { title: "", widthClass: "w-[50px]" },
    ];

    const tableBody = productDataArr?.map((column, idx) => {
        return [
            {
                content: (
                    <div
                        className={`w-full flex items-center gap-2 border-slate-100 text-sm text-slate-700 `}
                    >
                        {selectedRow - 1 === idx && uploadingFiles ? (
                            <div>
                                <Loader size={3} />
                            </div>
                        ) : column?.productImages?.length > 0 ? (
                            <div
                                onClick={() => triggerImageUpload(idx)}
                                className="flex items-center gap-2"
                            >
                                <img
                                    src={column?.productImages?.[0]}
                                    className="rounded border border-dashed object-fit w-[38px] h-[38px]"
                                />

                                {column?.productImages?.length > 1 && (
                                    <div className="w-[38px] h-[38px] p-2 text-xs rounded border border-dashed flex items-center justify-center">
                                        +{column?.productImages?.splice(1)?.length}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div
                                onClick={() => triggerImageUpload(idx)}
                                className="flex items-center justify-center gap-2"
                            >
                                <div className="p-2 rounded border border-dashed flex items-center justify-center">
                                    <i className="ri-image-add-line text-[17px] text-slate-500"></i>
                                </div>
                            </div>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={column.productName}
                            name={"productName"}
                            type={"text"}
                            required={true}
                            onChange={(e) =>
                                handleMultipleProductChange(e.target.name, e.target.value, idx)
                            }
                            inputPlaceholder={"Enter..."}
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full  border-slate-100 text-sm text-slate-700 `}>
                        <SelectInput
                            value={column.inventoryCategory}
                            name="inventoryCategory"
                            inputPlaceholder={"Select..."}
                            handleChange={(name, value) =>
                                handleMultipleProductChange(name, value, idx)
                            }
                            isRequired={true}
                            className="!border-0 !rounded-none"
                            dropdownOptions={[
                                {
                                    label: "Raw Materials",
                                    value: "Raw Material",
                                },
                                {
                                    label: "Work-in-Progress (WIP)",
                                    value: "Work-In-Progress (WIP)",
                                },
                                {
                                    label: "Finished Goods",
                                    value: "Finished Goods",
                                },
                                {
                                    label: "Maintenance, Repair, and Overall (MRO)",
                                    value: "Maintenance, Repair, and Overall (MRO)",
                                },
                            ]}
                        />
                    </div>
                ),
                // widthClass: `w-[215px] ${isScrolled ? "relative" : "fixed z-[2]"}`,
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={column.description}
                            name={"description"}
                            type={"text"}
                            required={true}
                            onChange={(e) =>
                                handleMultipleProductChange(e.target.name, e.target.value, idx)
                            }
                            inputPlaceholder={"Enter description..."}
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <SelectInput
                            value={column.unitOfMeasurement}
                            name="unitOfMeasurement"
                            inputPlaceholder={"Select..."}
                            handleChange={(name, value) => {
                                handleMultipleProductChange(name, value, idx);
                            }}
                            isRequired={true}
                            className="!border-0 !rounded-none"
                            dropdownOptions={[
                                {
                                    label: "Unit",
                                    value: "unit",
                                },
                                {
                                    label: "Pcs",
                                    value: "pcs",
                                },
                                {
                                    label: "Units",
                                    value: "units",
                                },
                                {
                                    label: "Cartridges",
                                    value: "cartridges",
                                },
                                {
                                    label: "Rolls",
                                    value: "rolls",
                                },
                                {
                                    label: "Box",
                                    value: "box",
                                },
                            ]}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <SelectInput
                            value={column.selectAttribute}
                            name="selectAttribute"
                            inputPlaceholder={"Select..."}
                            handleChange={(name, value) =>
                                handleMultipleProductChange(name, value, idx)
                            }
                            isRequired={true}
                            className="!border-0 !rounded-none"
                            dropdownOptions={[
                                {
                                    label: "Color",
                                    value: "color",
                                },
                                {
                                    label: "Size",
                                    value: "size",
                                },
                            ]}
                        />
                    </div>
                ),
                // widthClass: `w-[138px] absolute`,
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={column.attributeValue}
                            name={"attributeValue"}
                            type={"text"}
                            required={true}
                            onChange={(e) =>
                                handleMultipleProductChange(e.target.name, e.target.value, idx)
                            }
                            inputPlaceholder={"Enter..."}
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                // widthClass: `w-[138px] absolute`,
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <MultiselectInput
                            value={column?.vendors || []}
                            // value={""}
                            name="vendors"
                            inputPlaceholder={"Select..."}
                            handleChange={(name, value, selectedItems) => {
                                console.log("first", name, value, selectedItems);
                                handleMultipleProductChange(name, selectedItems, idx);
                            }}
                            className="!border-0 !rounded-none"
                            searchLoading={fetchingLdbVendors}
                            handleInputChange={(e) => handleDebouncedChange(e)}
                            isRequired={true}
                            dropdownOptions={onboardedVendors?.map((vendor) => ({
                                label: vendor?.vendor?.profile?.businessName,
                                value: vendor.vendorId,
                                subText: vendor?.category,
                            }))}
                        />
                    </div>
                ),
                // widthClass: `w-[138px] absolute`,
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <SelectInput
                            value={column.warehouse}
                            name="warehouse"
                            inputPlaceholder="Select..."
                            handleChange={(name, value) =>
                                handleMultipleProductChange(name, value, idx)
                            }
                            className="!border-0 !rounded-none"
                            searchLoading={fetchingWarehouseList}
                            handleInputChange={(e) => handleWarehouseDebouncedChange(e)}
                            isRequired={true}
                            optionItemContainerClassName="border-b border-slate-200"
                            dropdownOptions={warehouses?.map((warehouse) => ({
                                label: warehouse?.warehouseName,
                                value: warehouse?._id,
                            }))}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={column.bufferStockLevel}
                            name={"bufferStockLevel"}
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            required={true}
                            onChange={(e) =>
                                handleMultipleProductChange(e.target.name, e.target.value, idx)
                            }
                            inputPlaceholder="0.00"
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={column.minimumStockLevel}
                            name={"minimumStockLevel"}
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            required={true}
                            onChange={(e) =>
                                handleMultipleProductChange(e.target.name, e.target.value, idx)
                            }
                            inputPlaceholder="0.00"
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={column.maximumStockLevel}
                            name={"maximumStockLevel"}
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            required={true}
                            onChange={(e) =>
                                handleMultipleProductChange(e.target.name, e.target.value, idx)
                            }
                            inputPlaceholder="0.00"
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <div className="flex items-center">
                            <div
                                className="mr-5 cursor-pointer flex items-center"
                                onClick={() =>
                                    handleMultipleProductChange("isProductPerishable", true, idx)
                                }
                            >
                                {column.isProductPerishable ? (
                                    <i
                                        className={`ri-radio-button-fill text-xl ${
                                            column.isProductPerishable && "text-y-10"
                                        }`}
                                    ></i>
                                ) : (
                                    <span className={`material-icons text-xl text-n-50`}>
                                        radio_button_unchecked
                                    </span>
                                )}
                                <span className="text-sm font-rocGroteskMedium ml-[5px]">Yes</span>
                            </div>

                            <div
                                className="cursor-pointer flex items-center"
                                onClick={() =>
                                    handleMultipleProductChange("isProductPerishable", false, idx)
                                }
                            >
                                {!column.isProductPerishable ? (
                                    <i
                                        className={`ri-radio-button-fill text-xl ${
                                            !column.isProductPerishable && "text-y-10"
                                        }`}
                                    ></i>
                                ) : (
                                    <span className={`material-icons text-xl text-n-50`}>
                                        radio_button_unchecked
                                    </span>
                                )}
                                <span className="text-sm font-rocGroteskMedium ml-[5px] translate-y-[1px]">
                                    No
                                </span>
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <i onClick={() => handleDeleteRow(idx)} className="ri-delete-bin-line"></i>
                    </div>
                ),
            },
        ];
    });

    const handleScroll = (e) => {
        if (e.target.scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const handleFileUpload = (file: File) => {
        setReadingFile(true);
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: "array" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];

            const worksheetJsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            // Do something with the parsed data
            console.log("workbook.Sheets", worksheetJsonData);
            setExcelSheetHeader(worksheetJsonData[0]);
            setSheetData(worksheetJsonData);
            setReadingFile(false);
            setOpenUploadModal(false);
            setOpenMapFieldsModal(true);
        };

        reader?.readAsArrayBuffer?.(file);
    };

    const handleMatchColumnData = () => {
        const sheetDataCopy = [...sheetData]?.splice(1);
        const matchedSheetData = mappedFields?.map((sheetIndex) => {
            return sheetDataCopy?.map((sheetData) => sheetData[sheetIndex])?.filter(Boolean);
        });

        const mappedColumnData = matchedSheetData[0].map((_, columnIndex) => {
            const rowData = {};
            Object.keys(matchingColumnIds).forEach((columnKey, rowIndex) => {
                rowData[columnKey] = matchedSheetData[rowIndex][columnIndex];
            });
            return { ...productDataArr?.[0], ...rowData };
        });

        if (mappedColumnData.length > 0) {
            setMatchedColumnData(mappedColumnData);
            setProductDataArr(mappedColumnData);
        } else {
            setProductDataArr([
                {
                    productName: "",
                    inventoryCategory: "",
                    vendors: [],
                    unitOfMeasurement: "",
                    selectAttribute: "",
                    attributeValue: "",
                    description: "",
                    warehouse: "",
                    minimumStockLevel: "",
                    maximumStockLevel: "",
                    bufferStockLevel: "",
                    isProductPerishable: false,
                },
            ]);
            setMatchedColumnData([
                {
                    productName: "",
                    inventoryCategory: "",
                    vendors: [],
                    unitOfMeasurement: "",
                    selectAttribute: "",
                    attributeValue: "",
                    description: "",
                    warehouse: "",
                    minimumStockLevel: "",
                    maximumStockLevel: "",
                    bufferStockLevel: "",
                    isProductPerishable: false,
                },
            ]);
        }

        setOpenPreviewModal(false);
    };

    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e.target.value);
    }, 1500);

    const handleWarehouseDebouncedChange = debounce((e) => {
        setDebouncedWarehouseSearch(e.target.value);
    }, 1500);

    useEffect(() => {
        dispatch(vendorActions.getLdbVendors(20, debouncedSearch, "accepted"));
    }, [dispatch, debouncedSearch]);

    useEffect(() => {
        if (Boolean(fetchedLdbVendorsSuccess)) {
            setOnboardedVendors(fetchedLdbVendorsSuccess?.vendors);
        }
    }, [fetchedLdbVendorsSuccess]);

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouseList(20, debouncedWarehouseSearch));
    }, [dispatch, debouncedWarehouseSearch, createWarehouseSuccess]);

    useEffect(() => {
        if (Boolean(fetchedWarehouseListSuccess)) {
            console.log("fetchedWarehouseListSuccess", fetchedWarehouseListSuccess);
            setWarehouses(fetchedWarehouseListSuccess?.warehouses?.data);
        }
    }, [fetchedWarehouseListSuccess]);

    return (
        <div className="w-full">
            {productDataArr?.length > 0 ? (
                <div className="mb-6">
                    <label className="text-base font-rocGroteskMedium">Products</label>
                    <div
                        className="overflow-x-auto mt-6 mb-[-260px] pb-[275px]"
                        onScroll={handleScroll}
                    >
                        <CustomTable
                            tableBody={tableBody}
                            tableHeader={tableHeader}
                            isAllSelectable={false}
                            headerContainerClass=""
                            isScrollable={true}
                            isScrolled={isScrolled}
                            tableClass="w-full !border-collapse"
                            // contentContainer="!pl-0"
                            isCellBordered={true}
                            bodyItemClass={"hover:!border-[1.5px] hover:!border-gm-50 "}
                            leftFixedColumnCount={2}
                            leftFixedColumnClass={["!left-0", "!left-[111px]"]}
                            rightFixedColumnCount={1}
                        />
                    </div>
                    <div className="flex items-center gap-3">
                        <Button
                            btnText="Add new row"
                            btnClassname="text-[13px] !px-4 !py-2 !bg-n-20 !w-fit"
                            onClick={() => handleAddNewRow()}
                        />
                    </div>
                </div>
            ) : (
                <div className="w-[65%]">
                    <label className="text-base font-rocGroteskMedium">
                        Upload Products from Excel file
                    </label>
                    <div
                        onClick={() => setOpenUploadModal(true)}
                        className="relative mt-4 mb-6 py-8 px-6 rounded border border-dashed flex flex-col gap-4 items-center"
                    >
                        <i className="ri-upload-cloud-2-line text-[36px] text-slate-500"></i>
                        <div className="font-rocGroteskMedium">
                            <p className="text-sm mb-1 text-center">
                                Drag your file here to upload
                            </p>
                            <p className="text-sm text-slate-500 text-center">
                                XLSX | File size limit: 25MB
                            </p>
                        </div>
                        <Button
                            btnText="Browse files"
                            btnClassname="text-[13px] !px-4 !py-2 !bg-white !w-fit border border-n-40"
                        />
                        {/* <input
                        id="uploadSheet"
                        type="file"
                        className="opacity-0 absolute w-full h-full top-0 cursor-pointer"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    /> */}
                    </div>
                    <div className="flex items-center gap-3">
                        <Button
                            btnText="Upload file"
                            btnClassname="text-[13px] !px-4 !py-2 !w-fit"
                            onClick={() => {
                                setOpenUploadModal(true);
                            }}
                        />
                        {/* <Button
                            btnText="Download sample template"
                            btnClassname="text-[13px] !px-4 !py-2 !bg-white !w-fit border border-n-40"
                        /> */}
                    </div>
                </div>
            )}

            <ModalContainer
                open={openUploadModal}
                showCloseIcon={false}
                closeModal={() => setOpenUploadModal(false)}
                tailwindClassName="w-[90%] md:w-[800px]"
            >
                <div className="rounded-lg bg-white">
                    <div className="py-[17px] pr-6 pl-[22px] flex items-center justify-between border-b border-b-slate-200">
                        <p className="text-lg text-gm-50 font-rocGroteskMedium">Import products</p>
                        <i
                            onClick={() => setOpenUploadModal(false)}
                            className="ri-close-fill cursor-pointer text-2xl text-gm-50  "
                        ></i>
                    </div>

                    <div className="pt-4 px-6">
                        <TabsContainer
                            tabs={tabs}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            component={displayActiveTab()}
                            itemClassName=""
                            className="!px-0"
                        />
                    </div>

                    <div className="py-5 px-6 flex items-center justify-end border-t border-t-slate-200">
                        {/* <Button
                            btnText="Download sample template"
                            btnClassname="!bg-white !py-2 border border-[#DFE1E6] !text-[#142837] !text-[13px] !w-auto"
                        /> */}
                        <div className="gap-2 flex !w-auto justify-end">
                            <Button
                                btnText="Cancel"
                                onClick={() => setOpenUploadModal(false)}
                                btnClassname="!bg-[#F4F5F7] !py-[10px]  !text-[#142837] !text-[13px] !w-auto"
                            />
                            <Button
                                btnText="Upload File"
                                isLoading={readingFile}
                                onClick={() => {
                                    if (!Boolean(fileToUpload)) {
                                        const elem = document.getElementById("uploadSheet");
                                        elem?.click();
                                    } else {
                                        handleFileUpload(fileToUpload);
                                    }
                                }}
                                btnClassname="!py-[10px]  !text-[#142837] !text-[13px] !w-[110px]"
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={openMapFieldsModal}
                showCloseIcon={false}
                closeModal={() => setOpenMapFieldsModal(false)}
                tailwindClassName="w-[90%] md:w-[800px]"
            >
                <div className="rounded-lg bg-white">
                    <div className="py-[17px] pr-6 pl-[22px] flex items-center justify-between border-b border-b-slate-200">
                        <p className="text-lg text-gm-50 font-rocGroteskMedium">
                            Map Fields - Update column headings
                        </p>
                        <i
                            onClick={() => setOpenMapFieldsModal(false)}
                            className="ri-close-fill cursor-pointer text-2xl text-gm-50  "
                        ></i>
                    </div>

                    <div className="pb-6 text-sm font-rocGroteskMedium">
                        <p className="text-[#C38E03]  py-[10px] px-6">
                            Review the matching column titles and use the dropdowns to make any
                            changes needed.
                        </p>
                        <div className="">
                            <div className="flex">
                                <p className="pl-6  py-[10px] border-slate-200 border w-[360px] bg-slate-50">
                                    Synthally inventory field
                                </p>
                                <p className="pl-4 py-[10px] border-slate-200 border-y flex-1 bg-slate-50">
                                    Imported file header
                                </p>
                            </div>
                            {fields?.map((item, idx) => {
                                return (
                                    <div className="flex" key={idx}>
                                        <p className="pl-6  py-[18px] border-slate-200 border-b border-r w-[360px] underline">
                                            {item?.label}{" "}
                                            {idx === 0 && <span className="text-r-50"> * </span>}
                                        </p>
                                        <div className="pl-4 py-[10px] border-slate-200 border-b flex-1">
                                            <div className="max-w-[320px]">
                                                <SelectInput
                                                    inputPlaceholder="Select"
                                                    inputContainerClassName="!mb-0"
                                                    className=" !h-9 !py-2 !border-slate-200 !border"
                                                    floatingPlaceholderClass="!top-[8px] !text-slate-500 !font-rocGroteskMedium"
                                                    iconClassName="!text-slate-400"
                                                    value={matchingColumnIds[item?.value]}
                                                    name={item?.value}
                                                    handleChange={(name, value) => {
                                                        setMatchingColumnIds((prevState) => ({
                                                            ...prevState,
                                                            [name]: value,
                                                        }));
                                                    }}
                                                    dropdownOptions={(
                                                        excelSheetHeader as Array<string | number>
                                                    )

                                                        ?.map((header, id) => ({
                                                            label: header as string,
                                                            value: `${id}`,
                                                        }))
                                                        ?.filter((header) =>
                                                            Boolean(header?.label)
                                                        )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className="py-5 px-6 flex items-center justify-between border-t border-t-slate-200">
                        <Button
                            btnText="Back"
                            onClick={() => {
                                setOpenMapFieldsModal(false);
                                setOpenUploadModal(true);
                            }}
                            btnClassname="!bg-white !py-2 border border-[#DFE1E6] !text-[#142837] !text-[13px] !w-auto"
                        />
                        <div className="gap-2 flex !w-auto justify-end">
                            <Button
                                btnText="Cancel"
                                onClick={() => setOpenMapFieldsModal(false)}
                                btnClassname="!bg-[#F4F5F7] !py-[10px]  !text-[#142837] !text-[13px] !w-auto"
                            />
                            <Button
                                btnText="Preview"
                                onClick={() => {
                                    setOpenMapFieldsModal(false);
                                    setOpenPreviewModal(true);
                                }}
                                btnClassname="!py-[10px]  !text-[#142837] !text-[13px] !w-auto"
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={openPreviewModal}
                showCloseIcon={false}
                closeModal={() => setOpenPreviewModal(false)}
                tailwindClassName="w-[90%] md:w-[800px]"
            >
                <div className="rounded-lg bg-white">
                    <div className="py-[17px] pr-6 pl-[22px] flex items-center justify-between border-b border-b-slate-200">
                        <p className="text-lg text-gm-50 font-rocGroteskMedium">Preview</p>
                        <i
                            onClick={() => setOpenPreviewModal(false)}
                            className="ri-close-fill cursor-pointer text-2xl text-gm-50  "
                        ></i>
                    </div>

                    <div className="pb-6 text-sm font-rocGroteskMedium">
                        <p className="text-slate-500 py-4 px-6 border-b border-slate-200">
                            You will be importing approximately 
                            {
                                [...sheetData]
                                    ?.splice(1)
                                    ?.filter((item: (string | number)[]) => item?.length > 0)
                                    ?.length
                            }{" "}
                            products. Importing will {!skipDuplicates ? "" : "not"} overwrite any
                            existing products that have the same product handle and will publish to
                            all sales channels
                        </p>
                        <div className="px-6">
                            <div className="py-3 border-b border-slate-200">
                                <div className="flex items-center justify-between">
                                    <div className="flex gap-[6px] items-center">
                                        <i className="ri-checkbox-circle-fill text-[15px] text-[#00DB8F]"></i>
                                        <p className="">
                                            Items that are ready to be imported -{" "}
                                            {
                                                [...sheetData]
                                                    ?.splice(1)
                                                    ?.filter(
                                                        (item: (string | number)[]) =>
                                                            item?.length > 0
                                                    )?.length
                                            }
                                        </p>
                                    </div>

                                    {/* <i className="ri-arrow-right-s-line text-gun-metal text-xl cursor-pointer"></i> */}
                                </div>
                            </div>
                            {unmappedFields && unmappedFields?.length > 0 && (
                                <div className="border-b border-slate-200">
                                    <div className="flex items-center justify-between py-3">
                                        <div className="flex gap-[6px] items-center">
                                            <i className="text-[#FFB903] text-base ri-information-fill"></i>
                                            <p className="">
                                                Unmapped Fields - {unmappedFields?.length}
                                            </p>
                                        </div>
                                        <div
                                            className="w-fit cursor-pointer"
                                            onClick={() => setShowInfo(!showInfo)}
                                        >
                                            {showInfo ? (
                                                <i className="ri-arrow-down-s-line text-gun-metal text-xl"></i>
                                            ) : (
                                                <i className="ri-arrow-right-s-line text-gun-metal text-xl"></i>
                                            )}
                                        </div>
                                    </div>
                                    {showInfo && (
                                        <div className="">
                                            <p className="mb-4 text-slate-500">
                                                The following fields in your import file have not
                                                been mapped to any Inventory field. The data in
                                                these fields will be ignored during the import.
                                            </p>
                                            <ul className="list-disc mx-4 ">
                                                {unmappedFields?.map((item, idx) => {
                                                    return (
                                                        <li
                                                            key={`${item} - ${idx}`}
                                                            className="text-sm leading-6"
                                                        >
                                                            {item}
                                                        </li>
                                                    );
                                                })}
                                            </ul>

                                            <p className="bg-[#FFFBEB] w-full px-3 py-2 text-[#78350F] mt-4 mb-6">
                                                Click the back button if you want to match the above
                                                column header(s) or click the Import products button
                                                to continue
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="py-5 px-6 flex items-center justify-between border-t border-t-slate-200">
                        <Button
                            btnText="Back"
                            btnClassname="!bg-white !py-2 border border-[#DFE1E6] !text-[#142837] !text-[13px] !w-auto"
                            onClick={() => {
                                setOpenMapFieldsModal(true);
                                setOpenPreviewModal(false);
                            }}
                        />
                        <div className="gap-2 flex !w-auto justify-end">
                            <Button
                                btnText="Cancel"
                                btnClassname="!bg-[#F4F5F7] !py-[10px]  !text-[#142837] !text-[13px] !w-auto"
                            />
                            <Button
                                btnText="Import products"
                                onClick={() => handleMatchColumnData()}
                                btnClassname="!py-[10px]  !text-[#142837] !text-[13px] !w-auto"
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default ExcelUpload;
