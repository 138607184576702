import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import Rating from "components/common/Rating";
import PageFrame from "components/layout/PageFrame";
import { displayFileIcon, getCurrencyFromCurrencyCode } from "helpers";
import { reduce } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { orderActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";

const OrderDetails = () => {
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [orderDetails, setOrderDetails] = useState<{ [key: string]: any }>({});
    const [activeSuggestedVendor, setActiveSuggestedVendor] = useState<number | null>(null);
    const [updateOrderData, setUpdateOrderData] = useState<{
        budget: string;
        currency: string;
        orderType: string;
    }>({
        budget: "",
        currency: "",
        orderType: "",
    });
    const [itemData, setItemData] = useState<{ [key: string]: any }[]>([
        {
            description: "",
            qty: "",
            _id: "",
        },
    ]);

    const dispatch = useAppDispatch();
    const { orderId } = useParams();
    const { fetchingSingleOrder, fetchedSingleOrderSuccess, updatingOrder, updateOrderSuccess } =
        useAppSelector((state) => state.order);
    const { sendingRfqs, sendRfqsSuccess } = useAppSelector((state) => state.order);

    const description = orderDetails?.items
        ?.map((item: { [key: string]: any }) => item?.description)
        ?.join(", ");
    const quantity = reduce(
        orderDetails?.items?.map((item: { [key: string]: any }) => Number(item?.quantity)),
        function (sum, n) {
            return sum + n;
        },
        0
    );

    const handleSendRfqs = (vendorId?: string) => {
        const body: { [key: string]: any } = {
            itemsAndVendors: orderDetails?.items?.map((service: { [key: string]: any }) => ({
                item: service?.description,
                quantity: service?.quantity,
                vendors: vendorId
                    ? [vendorId]
                    : orderDetails.suggestedVendors?.map(
                          (vendor: { [key: string]: any }) => vendor?._id
                      ),
            })),
            deliveryDate: orderDetails?.deliveryDate,
            specifications: orderDetails?.specifications,
            orderType: orderDetails?.orderType,
            budget: {
                currency: orderDetails?.budget?.currency,
                amount: orderDetails?.budget?.amount,
            },
        };

        if (Boolean(orderDetails?.customerDetails?.email)) {
            body.customerDetails = {
                ...body.customerDetails,
                email: orderDetails?.customerDetails?.email,
            };
        }
        if (Boolean(orderDetails?.customerDetails?.country)) {
            body.customerDetails = {
                ...body.customerDetails,
                country: orderDetails?.customerDetails?.country,
            };
        }
        if (Boolean(orderDetails?.customerDetails?.firstNane)) {
            body.customerDetails = {
                ...body.customerDetails,
                firstNane: orderDetails?.customerDetails?.firstNane,
            };
        }
        if (Boolean(orderDetails?.customerDetails?.lastName)) {
            body.customerDetails = {
                ...body.customerDetails,
                lastName: orderDetails?.customerDetails?.lastName,
            };
        }
        if (Boolean(orderDetails?.customerDetails?.companyName)) {
            body.customerDetails = {
                ...body.customerDetails,
                companyName: orderDetails?.customerDetails?.companyName,
            };
        }
        if (
            Boolean(orderDetails?.customerDetails?.phone?.mobile) &&
            Boolean(orderDetails?.customerDetails?.phone?.rawMobile) &&
            Boolean(orderDetails?.customerDetails?.phone?.mobileExtension)
        ) {
            body.customerDetails = {
                ...body.customerDetails,
                phone: {
                    mobile: orderDetails?.customerDetails?.phone?.mobile,
                    rawMobile: orderDetails?.customerDetails?.phone?.rawMobile,
                    mobileExtension: orderDetails?.customerDetails?.phone?.mobileExtension,
                },
            };
        }

        dispatch(orderActions.sendRfqs(body));
    };

    const handleItemChange = (idx: number, name: string, value: string | string[]) => {
        const itemDataCopyArr = [...itemData];
        const itemDataCopy = itemDataCopyArr?.[idx];
        itemDataCopy[name] = value;

        setItemData(itemDataCopyArr);
    };

    const handleUpdateOrder = () => {
        const body = {
            items: itemData
                ?.map((item) => {
                    const obj: { [key: string]: any } = {};

                    if (Boolean(item?.description) && Boolean(item?.qty)) {
                        obj.description = item?.description;
                        obj.quantity = item.qty;
                    }

                    if (Boolean(item?.description) && Boolean(item?.qty) && Boolean(item?._id)) {
                        obj._id = item?._id;
                    }

                    return obj;
                })
                ?.filter((data: { [key: string]: any }) => data?.description && data?.quantity),
            budget: {
                currency: updateOrderData?.currency,
                amount: updateOrderData?.budget,
            },
            orderType: updateOrderData?.orderType,
        };

        dispatch(orderActions.updateOrder(orderId as string, body));
    };

    const tableHeader = [
        { title: "Item name", widthClass: "w-[90%] max-sm:w-[75%]" },
        { title: "QTY", widthClass: "w-[10%] max-sm:w-[25%]" },
    ];

    const tableBody = itemData?.map((item, idx) => [
        {
            content: (
                <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                    <TextInput
                        value={item?.description}
                        name={"description"}
                        type={"text"}
                        onChange={(e) => handleItemChange(idx, e.target.name, e.target.value)}
                        placeholder={"Item description"}
                        containerClassname=""
                        inputContainerClassname={"!border-0 !rounded-none"}
                        inputClassName="w-full "
                    />
                </div>
            ),
            tableDataDivClass: "!pl-0",
        },
        {
            content: (
                <div className={`w-full h-full border-slate-100 text-sm text-slate-700 `}>
                    <TextInput
                        value={item?.qty}
                        name={"qty"}
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(e) => handleItemChange(idx, e.target.name, e.target.value)}
                        onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                            evt.preventDefault()
                        }
                        placeholder={"Qty"}
                        containerClassname=""
                        inputContainerClassname={"!border-0 !rounded-none"}
                        inputClassName="w-full "
                    />
                    {idx !== 0 && (
                        <i
                            onClick={() => {
                                const itemDataCopy = [...itemData];
                                itemDataCopy.pop();
                                setItemData(itemDataCopy);
                            }}
                            className="ri-delete-bin-line cursor-pointer text-slate-500 absolute right-[-20px]"
                        ></i>
                    )}
                </div>
            ),
            tableDataDivClass: "!pl-0",
        },
    ]);

    useEffect(() => {
        dispatch(orderActions.getSingleOrder(orderId as string));
    }, [dispatch, orderId, updateOrderSuccess]);

    useEffect(() => {
        if (Boolean(fetchedSingleOrderSuccess)) {
            setOrderDetails(fetchedSingleOrderSuccess?.order);
        }
    }, [fetchedSingleOrderSuccess]);

    useEffect(() => {
        if (Boolean(updateOrderSuccess)) {
            setEditModalOpen(false);
            dispatch(orderActions.resetUpdateOrderSuccess());
        }
    }, [dispatch, updateOrderSuccess]);

    useEffect(() => {
        if (Boolean(sendRfqsSuccess)) {
            dispatch(orderActions.resetSendRfqsSuccess());
        }
    }, [dispatch, sendRfqsSuccess]);

    useEffect(() => {
        const data = orderDetails?.items?.map((item: { [key: string]: any }) => ({
            description: item?.description,
            qty: item?.quantity,
            _id: item?._id,
        }));
        setItemData(data);
        setUpdateOrderData({
            budget: orderDetails?.budget?.amount,
            currency: orderDetails?.budget?.currency,
            orderType: orderDetails?.orderType,
        });
    }, [orderDetails]);

    return (
        <PageFrame isLoading={fetchingSingleOrder}>
            <div>
                <div className="w-full h-full flex rounded-[12px] max-lg:flex-col bg-white border border-slate-100">
                    <div className="w-[74%] max-lg:w-full border-r max-lg:border-none border-slate-100">
                        <div className="w-full">
                            <Link
                                to={"/dashboard/orders"}
                                className="px-6 pt-6 flex items-center gap-1 mb-4 cursor"
                            >
                                <i className="ri-arrow-left-line mt-[-2px] text-sm"></i>
                                <p className="text-sm text-gm-50 font-rocGroteskMedium">Go back</p>
                            </Link>
                            <div className="px-6 pb-6 border-b border-slate-100 w-full">
                                <p className="text-xl font-rocGroteskMedium mb-1 text-slate-700">
                                    {description}
                                </p>
                                <div className="flex gap-3 items-center">
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        Edited:{" "}
                                        {moment(orderDetails?.updatedAt).format("DD MMM YYYY | LT")}
                                    </p>
                                </div>
                            </div>
                            <div className="px-8 py-6 border-b border-slate-100 hidden max-lg:block">
                                <Button
                                    btnText={"Request for quote"}
                                    type={"button"}
                                    btnClassname="mb-3"
                                    onClick={() => {
                                        setActiveSuggestedVendor(null);
                                        handleSendRfqs();
                                    }}
                                    isLoading={sendingRfqs && activeSuggestedVendor === null}
                                    disabled={sendingRfqs}
                                />
                                <Button
                                    btnClassname="!bg-n-20 !text-gm-50"
                                    btnText={"Edit Order"}
                                    onClick={() => {
                                        setEditModalOpen(true);
                                    }}
                                    type={"button"}
                                />
                            </div>
                            <div className="p-6 border-b border-slate-100 w-full">
                                <div className="flex items-center gap-2 max-lg:flex-col max-lg:items-start">
                                    <div className="py-1 px-2 bg-slate-100 rounded">
                                        <p className="text-xs text-gm-50 font-rocGroteskMedium">
                                            Quantity: {quantity}
                                        </p>
                                    </div>
                                    <div className="py-1 px-2 bg-slate-100 rounded">
                                        <p className="text-xs text-gm-50 font-rocGroteskMedium">
                                            Requested {moment(orderDetails?.createdAt).fromNow()}
                                        </p>
                                    </div>
                                    <div className="py-1 px-2 bg-slate-100 rounded">
                                        <p className="text-xs text-gm-50 font-rocGroteskMedium">
                                            Type of Order: {orderDetails?.orderType}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="px-6 py-4 border-b border-slate-100 w-full">
                                <p className="text-sm font-rocGroteskMedium mb-1 text-slate-700">
                                    Est. Budget
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-slate-500">
                                    {orderDetails?.budget?.amount
                                        ? getCurrencyFromCurrencyCode(
                                              orderDetails?.budget?.currency
                                          ) + orderDetails?.budget?.amount
                                        : "N/A"}
                                </p>
                            </div>
                            <div className="p-6 w-full">
                                <div className="flex items-center justify-between font-rocGroteskMedium mb-2">
                                    <p className="text-base text-slate-700 w-1/2">Items</p>
                                    <p className="text-base text-slate-700 w-1/2 text-right">Qty</p>
                                </div>
                                {orderDetails?.items?.map((item: { [key: string]: any }) => (
                                    <div
                                        key={item?._id}
                                        className="flex items-center justify-between font-rocGroteskMedium py-4 border-b border-slate-100"
                                    >
                                        <p className="text-sm text-slate-800 w-1/2">
                                            {item?.description}
                                        </p>
                                        <p className="text-sm text-slate-800 w-1/2 text-right">
                                            {item?.quantity}
                                        </p>
                                    </div>
                                ))}
                            </div>
                            <div className="p-6 w-full">
                                <p className="text-sm font-rocGroteskMedium mb-1 text-gm-50">
                                    Desired delivery date
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-slate-500">
                                    {moment(orderDetails?.deliveryDate).format("Do MMM YYYY")}
                                </p>
                            </div>
                            {orderDetails?.suggestedVendors &&
                                orderDetails?.suggestedVendors?.length > 0 && (
                                    <div>
                                        <p className="text-xl text-gm-50 font-rocGroteskMedium py-6 px-8">
                                            Suggested vendors
                                        </p>
                                        <div>
                                            {orderDetails?.suggestedVendors?.map(
                                                (vendor: { [key: string]: any }, idx: number) => {
                                                    return (
                                                        <div
                                                            key={vendor?._id}
                                                            className="py-6 px-8 flex items-start justify-between border-t border-slate-100 max-lg:flex-col"
                                                        >
                                                            <div className="w-[65%]">
                                                                <div className="flex items-center gap-3 mb-4">
                                                                    <img
                                                                        src={
                                                                            vendor?.profile?.avatar
                                                                        }
                                                                        width={40}
                                                                        height={40}
                                                                        alt="logo"
                                                                    />
                                                                    <div>
                                                                        <div className="flex items-center gap-2">
                                                                            <p className="text-base text-slate-700 font-rocGroteskMedium">
                                                                                {
                                                                                    vendor?.profile
                                                                                        ?.businessName
                                                                                }
                                                                            </p>{" "}
                                                                            {/* <Rating ratingNo={3} />{" "} */}
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="flex items-center gap-2">
                                                                <div className="py-1 px-2 bg-slate-100 rounded">
                                                                    <p className="text-xs text-gm-50 font-rocGroteskMedium">
                                                                        Amount: $300,000
                                                                    </p>
                                                                </div>

                                                                <div className="py-1 px-2 bg-slate-100 rounded">
                                                                    <p className="text-xs text-gm-50 font-rocGroteskMedium">
                                                                        Sent: 2 hours ago
                                                                    </p>
                                                                </div>
                                                            </div> */}
                                                                </div>
                                                                <div className="flex items-center gap-3">
                                                                    {/* <div className="w-[34px] h-[34px] border rounded-full border-slate-100 flex items-center justify-center">
                                                                <i className="ri-thumb-down-line"></i>
                                                            </div> */}
                                                                    <Button
                                                                        btnText={
                                                                            "Request for quote"
                                                                        }
                                                                        type={"button"}
                                                                        btnClassname="!py-1.5 !px-3 !w-fit !text-[13px]"
                                                                        onClick={() => {
                                                                            setActiveSuggestedVendor(
                                                                                idx
                                                                            );
                                                                            handleSendRfqs(
                                                                                vendor?._id
                                                                            );
                                                                        }}
                                                                        disabled={sendingRfqs}
                                                                        isLoading={
                                                                            sendingRfqs &&
                                                                            activeSuggestedVendor ===
                                                                                idx
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className="w-[26%] max-lg:w-full">
                        <div className="px-8 py-6 border-b border-slate-100 max-lg:hidden">
                            <Button
                                btnText={"Request for quote"}
                                type={"button"}
                                btnClassname="mb-3"
                                onClick={() => {
                                    setActiveSuggestedVendor(null);
                                    handleSendRfqs();
                                }}
                                isLoading={sendingRfqs && activeSuggestedVendor === null}
                                disabled={sendingRfqs}
                            />
                            <Button
                                btnClassname="!bg-n-20 !text-gm-50"
                                btnText={"Edit Order"}
                                onClick={() => {
                                    setEditModalOpen(true);
                                }}
                                type={"button"}
                            />
                        </div>
                        <div className="px-8 py-6 border-b border-slate-100 max-lg:border-t">
                            <p className="text-base text-gm-50 font-rocGroteskMedium mb-6">
                                Customer details
                            </p>
                            <div className="flex flex-col gap-4">
                                <div>
                                    <p className="text-sm text-gm-50 font-rocGroteskMedium mb-1">
                                        Company
                                    </p>
                                    <p className="text-sm text-slate-500 font-rocGroteskMedium ">
                                        {orderDetails?.customerDetails?.companyName}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm text-gm-50 font-rocGroteskMedium mb-1">
                                        First name
                                    </p>
                                    <p className="text-sm text-slate-500 font-rocGroteskMedium ">
                                        {orderDetails?.customerDetails?.firstName}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm text-gm-50 font-rocGroteskMedium mb-1">
                                        Last name
                                    </p>
                                    <p className="text-sm text-slate-500 font-rocGroteskMedium ">
                                        {orderDetails?.customerDetails?.lastName}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm text-gm-50 font-rocGroteskMedium mb-1">
                                        Email address
                                    </p>
                                    <p className="text-sm text-slate-500 font-rocGroteskMedium ">
                                        {orderDetails?.customerDetails?.email}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm text-gm-50 font-rocGroteskMedium mb-1">
                                        Phone
                                    </p>
                                    <p className="text-sm text-slate-500 font-rocGroteskMedium ">
                                        {orderDetails?.customerDetails?.phone?.mobile}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm text-gm-50 font-rocGroteskMedium mb-1">
                                        Country
                                    </p>
                                    <p className="text-sm text-slate-500 font-rocGroteskMedium ">
                                        {orderDetails?.customerDetails?.country}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="px-8 py-6 ">
                            <p className="text-base text-gm-50 font-rocGroteskMedium mb-6">
                                Documents
                            </p>
                            <div>
                                {orderDetails?.documents ? (
                                    <div>
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center gap-3">
                                                {displayFileIcon("pdf", false)}
                                                <div>
                                                    <p className="text-sm font-rocGroteskMedium">
                                                        Quotation for Electric...
                                                    </p>
                                                    <p className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        4 MB{" "}
                                                    </p>
                                                </div>
                                            </div>
                                            <i className="ri-download-line text-xl"></i>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex flex-col items-center">
                                        <i className="ri-file-line mb-4 text-3xl"></i>
                                        <p className="text-sm font-rocGroteskMedium text-center mb-1">
                                            No documents found
                                        </p>
                                        <p className="text-sm font-rocGroteskMedium text-center text-slate-500">
                                            It seems like there are no documents found in this
                                            order.
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <ModalContainer
                    open={editModalOpen}
                    showCloseIcon={false}
                    tailwindClassName="w-[68%] max-lg:w-[95%]"
                    closeModal={() => {
                        setEditModalOpen(false);
                    }}
                >
                    <div className="bg-white shadow-cardShadow relative rounded-[6px]">
                        <div className="px-8 py-6 flex items-center justify-between border-b border-slate-100">
                            <div className=" w-full">
                                <p className="text-base font-rocGroteskMedium text-slate-700">
                                    Edit order details
                                </p>
                            </div>
                            <i
                                className="ri-close-fill text-xl cursor-pointer"
                                onClick={() => {
                                    setEditModalOpen(false);
                                }}
                            ></i>
                        </div>
                        <div className="min-h-[370px] max-h-[450px] p-8 overflow-auto scrollbar-hide">
                            <div className="grid grid-cols-3 gap-4 mb-6 max-lg:grid-cols-1">
                                <TextInput
                                    value={orderDetails?.taId}
                                    name={"orderId"}
                                    type={"text"}
                                    placeholder={"Order ID"}
                                    disabled={true}
                                    containerClassname=""
                                />

                                <div className="w-full grid grid-cols-[115px_calc(100%-119px)] gap-1 ">
                                    <SelectInput
                                        value={updateOrderData?.currency}
                                        name="currency"
                                        placeholder={"($) USD"}
                                        handleChange={(name, value) =>
                                            setUpdateOrderData((prev) => ({
                                                ...prev,
                                                [name]: value,
                                            }))
                                        }
                                        isRequired={false}
                                        dropdownOptions={[
                                            {
                                                label: "GBP (£)",
                                                value: "GBP",
                                            },
                                            {
                                                label: "USD ($)",
                                                value: "USD",
                                            },
                                            {
                                                label: "EUR (€)",
                                                value: "EUR",
                                            },
                                            {
                                                label: "NGN (₦)",
                                                value: "NGN",
                                            },
                                        ]}
                                    />
                                    <TextInput
                                        value={updateOrderData?.budget}
                                        name={"budget"}
                                        type={"number"}
                                        onWheel={(e: any) => e.target.blur()}
                                        onChange={(e) =>
                                            setUpdateOrderData((prev) => ({
                                                ...prev,
                                                [e.target.name]: e.target.value,
                                            }))
                                        }
                                        placeholder={"Est. Budget"}
                                        // required={true}
                                        onKeyDown={(evt) =>
                                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                                evt.key
                                            ) && evt.preventDefault()
                                        }
                                        pattern={"^[0-9]+$"}
                                        title={"must be digits"}
                                        containerClassname=""
                                    />
                                </div>

                                <SelectInput
                                    value={updateOrderData?.orderType}
                                    name="orderType"
                                    placeholder={"Type of Order"}
                                    className=""
                                    handleChange={(name, value) =>
                                        setUpdateOrderData((prev) => ({ ...prev, [name]: value }))
                                    }
                                    // isRequired={true}
                                    dropdownOptions={[
                                        {
                                            label: "Supply",
                                            value: "supply",
                                        },
                                        {
                                            label: "Logistics",
                                            value: "logistics",
                                        },
                                    ]}
                                />
                            </div>
                            <div>
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isAllSelectable={false}
                                    headerContainerClass="!bg-slate-50"
                                    tableClass=" w-full"
                                    // contentContainer="!pl-0"
                                    isScrollable={false}
                                    isCellBordered={true}
                                />
                                <div
                                    onClick={() =>
                                        setItemData((prev) => [
                                            ...prev,
                                            { description: "", qty: "", _id: "" },
                                        ])
                                    }
                                    className="mt-4 flex items-center w-fit gap-1 cursor-pointer"
                                >
                                    <i className="ri-add-fill"></i>
                                    <p className="text-sm font-rocGroteskMedium text-gm-50">
                                        Add more items
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full justify-end gap-3 py-4 px-6 border-t border-slate-100">
                            <Button
                                btnClassname="!bg-n-20 !text-gm-50 !w-[150px]"
                                btnText={"Close"}
                                onClick={() => setEditModalOpen(false)}
                                type={"button"}
                            />
                            <Button
                                btnText={"Save changes"}
                                isLoading={updatingOrder}
                                onClick={handleUpdateOrder}
                                type={"button"}
                                btnClassname="!w-[150px]"
                            />
                        </div>
                    </div>
                </ModalContainer>
            </div>
        </PageFrame>
    );
};

export default OrderDetails;
