import React from "react";
import ShipmentSubListTitle from "./ShipmentSubListTitle";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import Button from "components/common/Button";
import { useNavigate } from "react-router-dom";
import { getEditPermissionList } from "helpers/getEditPermissionList";

type ShipmentSubListDetailsProps = {
    customerName?: string;
    origin?: string;
    goodsDescription?: string;
    valueOfGoods?: string;
    destination?: string;
    billOfLaden?: string;
    incoterms?: string;
    POId?: string;
    packageType?: string;
    packages?: any[];
    dimensions?: any[];
    product?: { [key: string]: any };
    shipmentId?: string;
};

const ShipmentSubListDetails = ({
    customerName,
    origin,
    goodsDescription,
    valueOfGoods,
    destination,
    billOfLaden,
    incoterms,
    POId,
    packageType,
    packages,
    shipmentId,
    dimensions,
    product,
}: ShipmentSubListDetailsProps) => {
    const navigate = useNavigate();

    return (
        <div className="mt-5 pb-5">
            <div className="space-y-8">
                <div className="flex flex-col gap-10 md:gap-6">
                    <div className="mt-6">
                        <ShipmentSubListTitle title={"Customer details"} />
                        <div className="text-[14px] flex space-x-5 mt-2 bg-[#F1F5F9] md:bg-transparent border-y-[#F1F5F9] border-y md:border-none px-8 md:px-0 py-4 md:py-0">
                            <div className="flex flex-col text-[14px] text-slate-500 leading-6 font-rocGroteskMedium space-y-3 w-full md:w-auto">
                                <p className="flex space-x-5 justify-between md:justify-start">
                                    <span className="w-[170px] font-rocGroteskMedium md:font-rocGroteskRegular">
                                        Customer name
                                    </span>
                                    <span className="text-slate-700 text-right md:text-left">
                                        {customerName || "N/A"}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <ShipmentSubListTitle title={"Shipment details"} />
                        <div className="text-[14px] flex space-x-5 mt-2 bg-[#F8FAFC] md:bg-transparent  px-8 md:px-0 py-4 md:py-0">
                            <div className="flex flex-col text-[14px] text-slate-500 leading-6 font-rocGroteskMedium space-y-3 w-full md:w-auto">
                                <p className="flex  justify-between md:justify-start space-x-5">
                                    <span className="w-[170px] md:font-rocGroteskRegular">
                                        Shipment origin
                                    </span>
                                    <span className="text-slate-700 text-right md:text-left ">
                                        {origin || "N/A"}
                                    </span>
                                </p>
                                <p className="flex justify-between md:justify-start space-x-5">
                                    <span className="w-[170px] md:font-rocGroteskRegular">
                                        Shipment destination
                                    </span>
                                    <span className="text-slate-700 text-right md:text-left">
                                        {destination || "N/A"}
                                    </span>
                                </p>
                                <p className="flex justify-between md:justify-start space-x-5">
                                    <span className="w-[170px] md:font-rocGroteskRegular">
                                        Bill of laden
                                    </span>
                                    <span className="text-slate-700 text-right md:text-left">
                                        {billOfLaden || "N/A"}
                                    </span>
                                </p>
                                <p className="flex justify-between md:justify-start space-x-5">
                                    <span className="w-[170px] md:font-rocGroteskRegular">
                                        Incoterms
                                    </span>
                                    <span className="text-slate-700 text-right md:text-left">
                                        {incoterms || "N/A"}
                                    </span>
                                </p>
                                <p className="flex justify-between md:justify-start space-x-5">
                                    <span className="w-[170px] md:ont-rocGroteskRegular">
                                        PO ID
                                    </span>
                                    <span className="text-slate-700 text-right md:text-left">
                                        {POId || "N/A"}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    {packages?.map((item, idx) => {
                        return (
                            <div key={idx}>
                                <ShipmentSubListTitle
                                    title={`Package ${idx + 1} (${packageType})`}
                                />
                                <div className="text-[14px] flex space-x-5 mt-2 bg-[#F8FAFC] md:bg-transparent  px-8 md:px-0 py-4 md:py-0">
                                    <div className="flex flex-col text-[14px] text-slate-500 leading-6 font-rocGroteskMedium space-y-3 w-full md:w-auto">
                                        <p className="flex space-x-5 justify-between md:justify-start">
                                            <span className="w-[170px] font-rocGroteskRegular">
                                                Package type
                                            </span>
                                            <span className="text-slate-700 text-right md:text-left">
                                                {item?.packageType || item?.containerType || "N/A"}
                                            </span>
                                        </p>
                                        <p className="flex space-x-5 justify-between md:justify-start">
                                            <span className="w-[170px] font-rocGroteskRegular">
                                                Unit
                                            </span>
                                            <span className="text-slate-700 text-right md:text-left">
                                                {item?.units || item?.unit || "N/A"}
                                            </span>
                                        </p>

                                        {packageType === "non-containers" && (
                                            <p className="flex space-x-5 justify-between md:justify-start">
                                                <span className="w-[170px] font-rocGroteskRegular">
                                                    Dimensions (per unit)
                                                </span>
                                                <span className="text-slate-700 text-right md:text-left">
                                                    {`L: ${item?.length?.value} ${item?.length?.unit} • W: ${item?.width?.value} ${item?.width?.unit}  • H: ${item?.height?.value} ${item?.height?.unit}`}
                                                </span>
                                            </p>
                                        )}

                                        {item?.weight && (
                                            <p className="flex space-x-5 justify-between md:justify-start">
                                                <span className="w-[170px] font-rocGroteskRegular">
                                                    Weight
                                                </span>
                                                <span className="text-slate-700 text-right md:text-left">
                                                    {item?.weight?.value} {item?.weight?.unit}
                                                </span>
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                    <div>
                        <ShipmentSubListTitle title={"Goods details"} />
                        <div
                            className="text-[14px] flex space-x-5 mt-2 border border-[#4ADE80] md:border-none rounded md:rounded-none mx-8
                        md:mx-0 p-4 md:p-0 bg-[#F0FDF4]  md:bg-transparent"
                        >
                            <div className="flex flex-col text-[14px] text-slate-500 leading-6 font-rocGroteskMedium space-y-3">
                                <p className="flex md:space-x-5 flex-col md:flex-row">
                                    <span className="w-[170px] md:font-rocGroteskRegular">
                                        Product name
                                    </span>
                                    <a
                                        href={`/dashboard/inventory/product/${product?._id}`}
                                        className="text-[#12A589] underline capitalize"
                                    >
                                        {product?.productName || "N/A"}
                                    </a>
                                </p>
                                <p className="flex md:space-x-5 flex-col md:flex-row">
                                    <span className="w-[170px] md:font-rocGroteskRegular">
                                        Product category
                                    </span>
                                    <span className="text-slate-700">
                                        {product?.inventoryCategory || "N/A"}
                                    </span>
                                </p>
                                <p className="flex md:space-x-5 flex-col md:flex-row">
                                    <span className="w-[170px] md:font-rocGroteskRegular">Qty</span>
                                    <span className="text-slate-700">
                                        {product?.productStockDetails?.stockLevel || "N/A"}
                                    </span>
                                </p>
                                <p className="flex md:space-x-5 flex-col md:flex-row">
                                    <span className="w-[170px] md:font-rocGroteskRegular">
                                        Unit of measure
                                    </span>
                                    <span className="text-slate-700">
                                        {product?.productStockDetails?.unitOfMeasurement || "N/A"}
                                    </span>
                                </p>
                                <p className="flex md:space-x-5 flex-col md:flex-row">
                                    <span className="w-[170px] md:font-rocGroteskRegular">
                                        Description
                                    </span>
                                    <span className="text-slate-700">
                                        {product?.additionalComment || "N/A"}
                                    </span>
                                </p>
                                <p className="flex md:space-x-5 flex-col md:flex-row">
                                    <span className="w-[170px] md:font-rocGroteskRegular">
                                        Cost
                                    </span>
                                    <span className="text-slate-700">
                                        {getCurrencyFromCurrencyCode(product?.salesPrice?.currency)}
                                        {formatMoney()?.format(product?.salesPrice?.amount) ||
                                            "N/A"}
                                    </span>
                                </p>
                                <Button
                                    btnText="Add to stock"
                                    btnClassname="!w-[170px] !py-2"
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/inventory/order-stock?channel=shipment&shipmentId=${shipmentId}`
                                        )
                                    }
                                    permissions={getEditPermissionList("inventory")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShipmentSubListDetails;
