import React from "react";

type Props = {
    text: string;
    color: string;
    bgColor: string;
};
const NotificationAlert = ({ text, color, bgColor }: Props) => {
    return (
        <div className={`${bgColor} text-center flex items-center space-x-2 px-3 rounded-[8px]`}>
            <i className={`ri-information-fill ${color} text-sm`}></i>
            <p className={` py-2 ${color} text-sm font-rocGroteskMedium `}>{text}</p>
        </div>
    );
};

export default NotificationAlert