export const getEditPermissionList = (permission: string) => {
    switch (permission) {
        case "analytics":
            return ["analytics_edit", "all_access"];

        case "team":
            return ["team_edit", "all_access"];

        case "financials":
            return ["financials_edit", "all_access"];

        case "workflow":
            return ["workflow_edit", "all_access"];
        case "shipment":
            return ["shipment_view", "shipment_edit", "all_access", "operations_edit"];
        case "inventory":
            return ["inventory_edit", "all_access"];
        case "supplier":
            return ["supplier_edit", "all_access"];
        case "purchase_order":
            return ["purchase_order_edit", "all_access"];
        default:
            return [];
    }
};