import useGetUserProfile from "hooks/useGetUserProfile";
import React, { useEffect, useRef, useState } from "react";

type MultipleDropdownsProps = {
    options: {
        label: string | React.ReactNode;
        actionButtons?: React.ReactNode;
        onClick: () => void;
        children: {
            label?: string | React.ReactNode;
            onClick?: () => void;
            className?: string;
        }[];
        childClassName?: string;
    }[];
    className?: string;
    childClassName?: string;
    dropdown?: React.ReactNode;
    optionClassName?: string;
    hideIcon?: boolean;
    disabled?: boolean;
    containerClassName?: string;
    relativeParent?: string;
    childOptionCLassName?: string;
    permissions?: string[];
};

export const MultipleDropdowns = ({
    dropdown,
    options,
    className,
    childClassName,
    optionClassName,
    hideIcon,
    containerClassName,
    relativeParent,
    childOptionCLassName,
    disabled,
    permissions,
}: MultipleDropdownsProps) => {
    const dropdownRef = useRef(null);
    const [showIndex, setShowIndex] = useState<number>();

    const [showOptions, setShowOptions] = useState(Array(options.length).fill(false));
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

    const [profile] = useGetUserProfile();

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setShowOptions(Array(options.length).fill(false));
                setShowIndex(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const calculateDropdownPosition = () => {
        if (dropdownRef.current) {
            const dropdownRect = dropdownRef.current.getBoundingClientRect();
            const parentRect = dropdownRef?.current?.parentElement?.getBoundingClientRect();

            setDropdownPosition({
                top: parentRect?.height as number,
                left: dropdownRect.left - (parentRect?.left as number),
            });
        }
    };

    useEffect(() => {
        calculateDropdownPosition();
        window.addEventListener("resize", calculateDropdownPosition);

        return () => {
            window.removeEventListener("resize", calculateDropdownPosition);
        };
    }, []);

    const toggleOption = (index: number) => {
        const newShowOptions = [...showOptions];
        newShowOptions[index] = !newShowOptions[index];
        if (!newShowOptions[0]) {
            setShowOptions(Array(options.length).fill(false));
        } else {
            setShowOptions(newShowOptions);
        }
    };
    const isPermitted = permissions?.some((permission) =>
        profile?.permission?.includes(permission)
    );

    return (
        <div className={`p-4 ${containerClassName}`} ref={dropdownRef}>
            <div
                className={`inline-block ${relativeParent} ${
                    permissions?.length > 0 && !isPermitted && " group !z-[99] relative"
                }`}
            >
                {permissions?.length > 0 && !isPermitted && (
                    <span
                        className={` font-rocGroteskRegular pointer-events-none absolute top-[50px]  text-xs left-1/2  before:left-1/2 !w-[160px]  transform -translate-x-1/2 bg-gm-50 p-3 text-white opacity-0 transition before:absolute  before:bottom-full before:translate-x-1/2 before:border-4   before:border-transparent before:border-b-gm-50 rounded before:content-[''] group-hover:opacity-100 z-100 text-center`}
                    >
                        You don't have access to this feature
                    </span>
                )}
                <button
                    className={`outline-none focus:outline-none border-none px-3 py-1 rounded-sm flex items-center min-w-32  ${
                        permissions?.length > 0 && !isPermitted && "!opacity-50"
                    }`}
                    onClick={() =>
                        permissions?.length > 0 && !isPermitted ? null : toggleOption(0)
                    }
                    disabled={(permissions?.length > 0 && !isPermitted) || disabled}
                >
                    {dropdown ? (
                        dropdown
                    ) : (
                        <div
                            className={`h-[24px] w-[24px] ${
                                showOptions[0] ? "bg-slate-100" : ""
                            } font-semibold rounded-full justify-center items-center flex`}
                        >
                            <i className="ri-more-2-fill text-[16px] text-slate-500"></i>
                        </div>
                    )}
                </button>
                {showOptions[0] && (
                    <>
                        <ul
                            className={`bg-white border ${className} text-gm-50 font-rocGroteskMedium text-sm shadow-dropDownShadow border-slate-50 rounded-[4px] transform scale-100 right-24 absolute transition duration-150 ease-in-out origin-top min-w-32`}
                        >
                            {options.map((option, index) => (
                                <li key={index}>
                                    <button
                                        onClick={() => {
                                            // toggleOption(index);
                                            option.onClick();
                                            setShowIndex(index);
                                        }}
                                        className={`w-full text-left flex items-center outline-none focus:outline-none px-3 py-2 hover:bg-gray-100  ${optionClassName}`}
                                    >
                                        <span className="pr-1 flex-1">{option.label}</span>
                                        {option.children.length > 0 && !hideIcon && (
                                            <span className="mr-auto">
                                                <i className="ri-arrow-right-s-line"></i>
                                            </span>
                                        )}
                                    </button>
                                    {showIndex === index && option.children.length > 0 && (
                                        <ul
                                            className={`bg-white w-full border border-slate-50 rounded-[4px] absolute top-[58px] right-[180px] transition duration-150 ease-in-out origin-top-left min-w-32 ${childClassName} ${option?.childClassName}`}
                                        >
                                            {option.children.map((childOption, childIndex) => (
                                                <li key={childIndex}>
                                                    <button
                                                        onClick={childOption.onClick}
                                                        className={`px-3 py-2 whitespace-nowrap hover:bg-gray-100 w-full text-left flex items-center outline-none focus:outline-none ${childOption.className}`}
                                                    >
                                                        {childOption.label}
                                                    </button>
                                                </li>
                                            ))}
                                            {option?.actionButtons && option?.actionButtons}
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </div>
        </div>
    );
};
