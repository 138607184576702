/* eslint-disable */
import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CustomCalendar from "components/common/CalendarComp.js/Calendar";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { formatMoney, getCurrencyFromCurrencyCode, onScroll } from "helpers";
import { flatten } from "helpers/flattenArray";
import { debounce, flattenDeep, truncate } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { orderActions } from "redux/Ldb/actions";
import TabsContainer from "components/common/TabsContainer";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";

const PendingQuotes = ({
    filter,
    setFilter,
    debouncedFilter,
    setDebouncedFilter,
    status = "pending",
}) => {
    const limit = 20;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [quoteLimit, setQuoteLimit] = useState(limit);
    const [quotesList, setQuotesList] = useState([]);
    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [loadMoreQuotes, setLoadMoreQuotes] = useState<boolean>(false);
    const [quotePagination, setQuotePagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });

    const dashboardContent = document.getElementById("dashboardContent");
    const { fetchingQuotes, fetchedQuotesSuccess } = useAppSelector((state) => state.order);

    const handleSearch = (e) => {
        const { name, value } = e.target;

        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const onQuoteTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                quotePagination?.current as number,
                quotePagination?.number_of_pages as number,
                () => {
                    setQuoteLimit(() => quoteLimit + limit);
                    setLoadMoreQuotes(true);
                }
            ),
        [dashboardContent, quotePagination, quoteLimit]
    );
    const handleDebouncedChange = debounce((e) => {
        const { name, value } = e.target;

        setDebouncedFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, 1500);

    useEffect(() => {
        dispatch(
            orderActions.getQuotes(
                quoteLimit,
                debouncedFilter.search,
                debouncedFilter.vendor,
                debouncedFilter.quoteId,
                debouncedFilter.amount,
                "pending"
            )
        );
        setTimeout(() => {
            setLoadMoreQuotes(false);
        }, 800);
    }, [dispatch, debouncedFilter, quoteLimit]);

    const handleScroll = (e) => {
        if (e.target.scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };
    useEffect(() => {
        if (Boolean(fetchedQuotesSuccess)) {
            setQuotesList(fetchedQuotesSuccess?.quotes);
            setQuotePagination({
                current: fetchedQuotesSuccess?.pagination.current,
                number_of_pages: fetchedQuotesSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedQuotesSuccess]);

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onQuoteTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onQuoteTableScroll);
        };
    }, [dashboardContent, onQuoteTableScroll]);

    const tableHeader = [
        { title: "Quote ID", widthClass: "w-[290px]" },
        { title: "Total amount", widthClass: "w-[216px]" },
        { title: "Assigned RFQ ID", widthClass: "w-[216px]" },
        { title: "Supplier", widthClass: "w-[276px]" },
        { title: "Status", widthClass: "w-[189px]" },
        { title: "", widthClass: "w-[76px]" },
    ];
    console.log("quotesList", quotesList);
    const tableBody = quotesList?.map((quote) => [
        {
            content: (
                <div
                    className={`py-2 pr-4 border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                    key={quote?._id}
                >
                    <p className="text-slate-900">Quote {quote?.taId}</p>
                    <p className="text-slate-500 leading-5">
                        {truncate(
                            `${quote?.rfq?.inventories
                                ?.map((rfq) => rfq?.productName)
                                .join(", ")} `,
                            {
                                length: 39,
                            }
                        )}{" "}
                    </p>
                </div>
            ),
            widthClass: "!border-b !border-slate-100",

            cellClickAction: () => {
                navigate(`/dashboard/quotes/${quote?._id}?taId=${quote?.taId}`);
            },
        },
        {
            content: (
                <div
                    className={`py-2 pr-4 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-gm-50`}
                >
                    {quote?.amount?.amount === "0" ? (
                        <p>---</p>
                    ) : (
                        <>
                            {getCurrencyFromCurrencyCode(quote?.amount?.currency)}
                            {formatMoney().format(quote?.amount?.amount)}
                        </>
                    )}
                </div>
            ),
            widthClass: "!border-b !border-slate-100",
        },
        {
            content: (
                <div
                    className={`py-2 pr-4 border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    <p>RFQ - {quote?.rfq?.taId}</p>
                </div>
            ),
            widthClass: "!border-b !border-slate-100",
        },

        {
            content: (
                <div
                    className={`pr-3 flex space-x-1 items-center w-full font-rocGroteskMedium border-slate-100 text-sm text-gm-50 `}
                >
                    <VendorAvatar
                        imageSrc={quote?.vendor?.profile?.avatar}
                        size={30}
                        name={quote?.vendor?.profile?.businessName}
                    />
                    <p>{quote?.vendor?.profile?.businessName}</p>
                </div>
            ),
            widthClass: "!border-b !border-slate-100",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3  space-x-1.5 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-900 `}
                >
                    <div
                        className={`h-2 w-2 rounded-full 
                            ${quote?.currentStatus?.toLowerCase() === "pending" && "bg-[#F59E0B]"}
                            
                            `}
                    ></div>
                                     <p className="text-sm ">{quote?.currentStatus[0].toUpperCase() + quote?.currentStatus.slice(1)}</p>

                </div>
            ),
            widthClass: "!border-b !border-slate-100",
        },
        {
            content: (
                <div
                    onClick={(e) => e.stopPropagation()}
                    className={`py-3 flex justify-center w-[82%]  items-center h-full border-slate-100 text-sm text-slate-900 `}
                >
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownContainerClasses={`shadow-cardShadow-2 left-[-100px]  border-0 rounded !max-h-fit`}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "Show more",
                                value: "empty",
                                action: () => {
                                    console.log("action");
                                },
                            },
                        ]}
                    />
                </div>
            ),
            widthClass: "!border-b !border-slate-100",
        },
    ]);

    return (
        <div>
            {fetchingQuotes && quotesList.length === 0 && status === "pending" ? (
                <div className="my-[20%]">
                    <Loader />
                </div>
            ) : (
                <>
                    {quotesList?.length > 0 ? (
                        <div>
                            <div
                                className="overflow-x-scroll hidden lg:block"
                                onScroll={handleScroll}
                            >
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isAllSelectable={false}
                                    isScrollable={true}
                                    isScrolled={isScrolled}
                                    isCellBordered={false}
                                    headerContainerClass="!bg-slate-50 !border-slate-100 "
                                    bodyItemClass="hover:border-slate-100  !border-b hover:bg-slate-50 "
                                    headerItemClass="font-rocGroteskMedium !text-slate-700 "
                                />
                            </div>
                            <div className="py-4 lg:hidden block">
                                <p className="text-sm font-rocGroteskMedium mb-4 text-slate-900">
                                    Quote ID
                                </p>
                                {quotesList?.map((quote) => (
                                    <div
                                        key={quote?._id}
                                        className="border flex justify-between items-center border-slate-100 rounded mb-1 px-[17px] py-3 font-rocGroteskMedium cursor-pointer"
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/quotes/${quote?._id}?taId=${quote?.taId}`
                                            )
                                        }
                                    >
                                        <div>
                                            <p className="text-sm text-slate-900 ">
                                                Quote {quote?.taId} :{" "}
                                                {truncate(
                                                    `${quote?.rfq?.inventories
                                                        ?.map((rfq) => rfq?.productName)
                                                        .join(", ")} `,
                                                    {
                                                        length: 39,
                                                    }
                                                )}{" "}
                                            </p>
                                            <p className="text-sm text-slate-500">
                                                {" "}
                                                {quote?.amount?.amount === "0" ? (
                                                    <p>---</p>
                                                ) : (
                                                    <>
                                                        {getCurrencyFromCurrencyCode(
                                                            quote?.amount?.currency
                                                        )}
                                                        {formatMoney().format(
                                                            quote?.amount?.amount
                                                        )}
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                        <i className="ri-arrow-right-s-line text-2xl text-slate-500"></i>
                                    </div>
                                ))}
                            </div>
                            {loadMoreQuotes && fetchingQuotes && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {quotePagination?.current === quotePagination?.number_of_pages && (
                                <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                    <p className="text-center"> End of list</p>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="items-center flex flex-col !h-[calc(100vh-300px)] justify-center">
                            <div className="space-y-2  text-center">
                                <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                                <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                    {filter.search ? "No quote found" : "No quote yet"}
                                </p>
                                <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                    {filter.search
                                        ? "No quote found for this search query"
                                        : "Recent quotes from vendor will be displayed here"}
                                </p>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
export default PendingQuotes;
