import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import TabsContainer from "components/common/TabsContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import ExpiredProductHistory from "components/views/Ldb/Dashboard/Inventory/StockControl/ExpiredProductHistory";
import StockCountHistoryTable from "components/views/Ldb/Dashboard/Inventory/StockControl/StockCountHistoryTable";
import { getEditPermissionList } from "helpers/getEditPermissionList";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { set, truncate } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";

const StockCountHistory = () => {
    const limit = 20;
    const dispatch = useAppDispatch();
    const [stockLimit, setStockLimit] = useState(limit);
    const [activeTab, setActiveTab] = useState(0);
    const [totalStock, setTotalStock] = useState<any>([]);
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [stockCountList, setStockCountList] = useState<any>([]);
    const [stockData, setStockData] = useState<any>([]);
    const [stockCountPagination, setStockCountPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const { inventoryId } = useParams();
    const { fetchedSingleInventorySuccess, fetchedSingleInventoryFailure } = useAppSelector(
        (state) => state.inventory
    );

    useEffect(() => {
        dispatch(inventoryActions.fetchSingleInventory(inventoryId));
    }, [inventoryId]);

    const navigate = useNavigate();

    useEffect(() => {
        if (Boolean(fetchedSingleInventorySuccess)) {
            setStockData(fetchedSingleInventorySuccess?.inventory);
            setIsPageLoaded(true);
        }
    }, [fetchedSingleInventorySuccess]);

    useEffect(() => {
        if (Boolean(fetchedSingleInventoryFailure)) {
            setIsPageLoaded(true);
        }
    }, [fetchedSingleInventoryFailure]);

    const highestMin = stockData?.productStockSettings?.reduce(
        (acc, current) => (current.minimumStockLevel > acc.minimumStockLevel ? current : acc),
        stockData?.productStockSettings[0]
    );
    // Find the object with the highest max value
    const highestMax = stockData?.productStockSettings?.reduce(
        (acc, current) => (current.maximumStockLevel > acc.maximumStockLevel ? current : acc),
        stockData?.productStockSettings[0]
    );
    const tabs = ["Stock Count History", "Expired batch of this product"];

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return <StockCountHistoryTable setTotalStock={setTotalStock} />;
            case 1:
                return <ExpiredProductHistory />;
            default:
                return;
        }
    };
    return (
        <PageFrame containerClassName="" isLoading={!isPageLoaded}>
            <div className=" w-full pb-16 bg-white rounded border border-slate-100  ">
                <div className=" w-full">
                    <div className="top-[-25px] bg-white md:sticky md:px-8 py-4 px-[28px] w-full border-b border-n-20 flex items-center justify-between">
                        <div className="flex items-center">
                            <div
                                onClick={() => navigate(-1)}
                                className="cursor-pointer space-x-1 flex items-center justify-center"
                            >
                                <i className="ri-arrow-left-line text-sm text-carribean-green"></i>
                                <p className="text-sm underline text-gm-50 font-rocGroteskMedium">
                                    Back
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center space-x-3">
                            {/* <div>
                                <Button
                                    btnText="Adjust stock"
                                    btnClassname=" !bg-transparent !border border-n-20 !text-gm-50"
                                />
                            </div> */}
                            <div>
                                <Button
                                    btnText="Add stock count"
                                    btnClassname=" "
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/inventory/order-stock?productId=${totalStock?.products?.[0]?.inventoryId}&warehouseId=${totalStock?.products?.[0]?.warehouseId}`
                                        )
                                    }
                                    permissions={getEditPermissionList("inventory")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="md:px-8 py-4 border-b border-slate-200 px-[28px]">
                        <BreadCrumb
                            parentCrumb={"Product "}
                            parentUrl={"#"}
                            parentClassName="!text-slate-400"
                            childrenCrumb={[
                                {
                                    title: "Warehouse",
                                    path: `/dashboard/inventory/product/${totalStock?.products?.[0]?.inventory?._id}`,
                                },
                                { title: "Stock Count History", path: "/dashboard/rfqs" },
                            ]}
                            size="small"
                        />

                        <div className="mt-4 flex space-x-3 items-center">
                            <div className="h-[64px] border-slate-200 border w-[64px] rounded">
                                <VendorAvatar
                                    size={64}
                                    name={stockData?.productName}
                                    imageSrc={stockData?.productImageDetails?.productAvatar}
                                    containerClassname="!rounded-[4px] !border-slate-200 !"
                                />
                            </div>
                            <div>
                                <p className="text-base text-slate-700 font-rocGroteskMedium">
                                    {stockData?.productName}
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-slate-500">
                                    {truncate(stockData?.productDescription, {
                                        length: 45,
                                    })}
                                </p>
                            </div>
                        </div>
                        <div className="mt-6  w-full space-x-[70px] flex">
                            <div className="flex space-x-20 items-center">
                                <div className="font-rocGroteskMedium">
                                    <h3 className="text-3xl text-gm-50 ">
                                        {totalStock?.totalStock?.[0]?.total ?? 0}
                                    </h3>
                                    <p className="text-slate-500 text-sm">Current stock level</p>
                                </div>
                                <div className="border-r h-[51px] border-slate-100"></div>
                            </div>
                            <div className="flex space-x-20 items-center">
                                <div className="font-rocGroteskMedium">
                                    <h3 className="text-3xl text-gm-50 ">
                                        {" "}
                                        {highestMin?.minimumStockLevel ?? 0}
                                    </h3>
                                    <p className="text-slate-500 text-sm">Minimum stock level</p>
                                </div>
                                <div className="border-r h-[51px] border-slate-100"></div>
                            </div>
                            <div className="font-rocGroteskMedium">
                                <h3 className="text-3xl text-gm-50 ">
                                    {" "}
                                    {highestMax?.minimumStockLevel ?? 0}
                                </h3>
                                <p className="text-slate-500 text-sm">Maximum stock level</p>
                            </div>
                        </div>
                    </div>
                    <div className=" px-[28px] md:px-8 pt-2">
                        <TabsContainer
                            tabs={tabs}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            component={displayActiveTab()}
                            className="!px-0 !mt-3"
                            itemClassName="!pb-2"
                            borderLineClase={"!text-slate-100  w-full  mb-4"}
                        />
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default StockCountHistory;
