import React from "react";
import CustomToast from "components/common/CustomToast";
import { workFlowTypes } from "redux//Ldb/types";
import { toast } from "react-hot-toast";
import { workFlowService } from "services/Ldb";
import { Dispatch } from "redux";

export const workFlowActions = {
    getworkFlowList,
    pauseOrPlayWorkFlow,
    resetPauseOrPlayWorkFlowSuccess,
    deleteWorkFlow,
    resetDeleteWorkFlowSuccess,
    createWorkflow,
    resetCreateWorkFlowSuccess,
    createEventAndAction,
    resetCreateEventAndActionSuccess,
    getSingleWorkflow,
    updateWorkflowConnections,
    resetUpdateWorkflowConnectionsSuccess,
    resetUpdateWorkflowConnectionsFailure,
    updateWorkflowDisconnections,
    resetUpdateWorkflowDisconnectionsSuccess,
    resetUpdateWorkflowDisconnectionsFailure,
    updateWorkflowNodePosition,
    resetUpdateWorkflowNodePositionSuccess,
    createComment,
    resetCreateCommentSuccess,
    getNodeComments,
    resetGetNodesCommentsSuccess,
    updateEventAndAction,
    resetUpdateEventAndActionSuccess,
    deleteEvent,
    resetDeleteEventSuccess,
    deleteAction,
    resetDeleteActionSuccess,
    swapNodes,
    resetSwapNodesSuccess,
    getEventAndActionsOptionList,
    getWorkflowActivities,
    resetGetWorkflowActivitiesSuccess,
    duplicateWorkflow,
    resetDuplicateWorkflowSuccess,
    updateWorkflowName,
    resetUpdateWorkflowNameSuccess,
    getWorkflowRuns,
    getSingleWorkflowRun,
    retryAction,
    markActionAsDone,
    markActionAsRejected,
    togglePausePlayWorkflowRun,
    resetTogglePausePlayWorkflowRunSuccess,
    getWorkflowAnalytics,
    getDashboardWorkflowRuns,
    getSingleComments,
    deleteComment,
    getWorkflowTemplates,
    createAction,
    resetCreateActionSuccess,
    updateAction,
    resetUpdateActionSuccess,
    updateEvent,
    resetUpdateEventSuccess,
    duplicateAction,
    resetDuplicateActionSuccess,
    createMultipleActions,
    resetCreateMultipleActionSuccess,
    resetMarkActionAsRejectedSuccess,
};

function getworkFlowList(
    workflowLimit?: string | number,
    WorkflowName?: string,
    createdBy?: string,
    numOfRuns?: string | number,
    dateCreated?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.FETCH_WORKFLOW_LIST_REQUEST));

        workFlowService
            .getWorkFlowList(workflowLimit, WorkflowName, createdBy, numOfRuns, dateCreated)
            .then(
                (res) => {
                    dispatch(success(workFlowTypes.FETCH_WORKFLOW_LIST_SUCCESS, res?.data));
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(workFlowTypes.FETCH_WORKFLOW_LIST_FAILURE, error?.message)
                        );
                    }
                }
            );
    };
}

function getDashboardWorkflowRuns() {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.FETCH_DASHBOARD_WORKFLOW_RUNS_REQUEST));
        workFlowService.getDashboardWorkflowRuns().then(
            (res) => {
                dispatch(success(workFlowTypes.FETCH_DASHBOARD_WORKFLOW_RUNS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workFlowTypes.FETCH_DASHBOARD_WORKFLOW_RUNS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function createWorkflow(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.CREATE_WORKFLOW_REQUEST));

        workFlowService.createWorkFlow(data).then(
            (res) => {
                dispatch(success(workFlowTypes.CREATE_WORKFLOW_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(workFlowTypes.CREATE_WORKFLOW_FAILURE, error.message));
                }
            }
        );
    };
}

function updateWorkflowConnections(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.UPDATE_WORKFLOW_CONNECTIONS_REQUEST));

        workFlowService.updateWorkflowConnections(data).then(
            (res) => {
                dispatch(success(workFlowTypes.UPDATE_WORKFLOW_CONNECTIONS_SUCCESS, res?.data));
                // toast.custom((t) => (
                //     <CustomToast t={t} message={"Connection updated"} type="success" />
                // ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(
                        failure(workFlowTypes.UPDATE_WORKFLOW_CONNECTIONS_FAILURE, error.message)
                    );
                }
            }
        );
    };
}

function updateWorkflowDisconnections(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.UPDATE_WORKFLOW_DISCONNECTIONS_REQUEST));

        workFlowService.updateWorkflowDisconnections(data).then(
            (res) => {
                dispatch(success(workFlowTypes.UPDATE_WORKFLOW_DISCONNECTIONS_SUCCESS, res?.data));
                // toast.custom((t) => (
                //     <CustomToast t={t} message={"Connection deleted"} type="success" />
                // ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(
                        failure(workFlowTypes.UPDATE_WORKFLOW_DISCONNECTIONS_FAILURE, error.message)
                    );
                }
            }
        );
    };
}

function updateWorkflowNodePosition(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.UPDATE_WORKFLOW_NODE_POSITION_REQUEST));

        workFlowService.updateWorkflowNodePosition(data).then(
            (res) => {
                dispatch(success(workFlowTypes.UPDATE_WORKFLOW_NODE_POSITION_SUCCESS, res?.data));
                // toast.custom((t) => (
                //     <CustomToast t={t} message={"Position updated"} type="success" />
                // ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(
                        failure(workFlowTypes.UPDATE_WORKFLOW_NODE_POSITION_FAILURE, error.message)
                    );
                }
            }
        );
    };
}

function createEventAndAction(
    data: Record<string, any> | Array<Record<string, any>>,
    supressErrorMsg?: boolean
) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.CREATE_EVENT_AND_ACTION_REQUEST));

        workFlowService.createEventAndAction(data).then(
            (res) => {
                dispatch(success(workFlowTypes.CREATE_EVENT_AND_ACTION_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message && !supressErrorMsg) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(workFlowTypes.CREATE_EVENT_AND_ACTION_FAILURE, error.message));
                }
            }
        );
    };
}

function getSingleWorkflow(workflowId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.FETCH_SINGLE_WORKFLOW_REQUEST));

        workFlowService.getSingleWorkflow(workflowId).then(
            (res) => {
                dispatch(success(workFlowTypes.FETCH_SINGLE_WORKFLOW_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(workFlowTypes.FETCH_SINGLE_WORKFLOW_FAILURE, error.message));
                }
            }
        );
    };
}

function getWorkflowTemplates() {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.FETCH_WORKFLOW_TEMPLATES_REQUEST));

        workFlowService.getWorkflowTemplates().then(
            (res) => {
                dispatch(success(workFlowTypes.FETCH_WORKFLOW_TEMPLATES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(
                        failure(workFlowTypes.FETCH_WORKFLOW_TEMPLATES_FAILURE, error.message)
                    );
                }
            }
        );
    };
}

function pauseOrPlayWorkFlow(
    workflowId: string,
    data: Record<string, any> | Array<Record<string, any>>
) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.PLAY_PAUSE_WORKFLOW_REQUEST));

        workFlowService.pauseOrPlayWorkFlow(workflowId, data).then(
            (res) => {
                dispatch(success(workFlowTypes.PLAY_PAUSE_WORKFLOW_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(workFlowTypes.PLAY_PAUSE_WORKFLOW_FAILURE, error.message));
                }
            }
        );
    };
}

function deleteWorkFlow(workflowId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.DELETE_WORKFLOW_REQUEST));

        workFlowService.deleteWorkFlow(workflowId).then(
            (res) => {
                dispatch(success(workFlowTypes.DELETE_WORKFLOW_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Workflow deleted"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.DELETE_WORKFLOW_FAILURE, error?.message));
                }
            }
        );
    };
}

function createComment(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.CREATE_COMMENT_REQUEST));

        workFlowService.createComment(data).then(
            (res) => {
                dispatch(success(workFlowTypes.CREATE_COMMENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.CREATE_COMMENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function getNodeComments(nodeId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.FETCH_NODE_COMMENTS_REQUEST));

        workFlowService.getNodeComments(nodeId).then(
            (res) => {
                dispatch(success(workFlowTypes.FETCH_NODE_COMMENTS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.FETCH_NODE_COMMENTS_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateEventAndAction(
    eventId: string,
    data: Record<string, any> | Array<Record<string, any>>
) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.UPDATE_EVENT_AND_ACTION_REQUEST));

        workFlowService.updateWorkflowEvent(eventId, data).then(
            (res) => {
                dispatch(success(workFlowTypes.UPDATE_EVENT_AND_ACTION_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Update successful"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workFlowTypes.UPDATE_EVENT_AND_ACTION_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function deleteEvent(eventId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.DELETE_EVENT_REQUEST));

        workFlowService.deleteEvent(eventId).then(
            (res) => {
                dispatch(success(workFlowTypes.DELETE_EVENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.DELETE_EVENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function deleteAction(actionId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.DELETE_ACTION_REQUEST));

        workFlowService.deleteAction(actionId).then(
            (res) => {
                dispatch(success(workFlowTypes.DELETE_ACTION_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.DELETE_ACTION_FAILURE, error?.message));
                }
            }
        );
    };
}

function swapNodes(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.SWAP_NODE_REQUEST));

        workFlowService.swapNodes(data).then(
            (res) => {
                dispatch(success(workFlowTypes.SWAP_NODE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.SWAP_NODE_FAILURE, error?.message));
                }
            }
        );
    };
}

function getEventAndActionsOptionList(type: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.FETCH_EVENT_AND_ACTION_OPTIONS_REQUEST));

        workFlowService.getEventAndActionsOptionList(type).then(
            (res) => {
                dispatch(success(workFlowTypes.FETCH_EVENT_AND_ACTION_OPTIONS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            workFlowTypes.FETCH_EVENT_AND_ACTION_OPTIONS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getWorkflowActivities(workflowId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.FETCH_WORKFLOW_ACTIVITIES_REQUEST));

        workFlowService.getWorkflowActivities(workflowId).then(
            (res) => {
                dispatch(success(workFlowTypes.FETCH_WORKFLOW_ACTIVITIES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workFlowTypes.FETCH_WORKFLOW_ACTIVITIES_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function duplicateWorkflow(workflowId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.DUPLICATE_WORKFLOW_REQUEST));

        workFlowService.duplicateWorkflow(workflowId).then(
            (res) => {
                dispatch(success(workFlowTypes.DUPLICATE_WORKFLOW_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.DUPLICATE_WORKFLOW_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateWorkflowName(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.UPDATE_WORKFLOW_NAME_REQUEST));

        workFlowService.updateWorkflowName(data).then(
            (res) => {
                dispatch(success(workFlowTypes.UPDATE_WORKFLOW_NAME_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Workflow renamed successfully"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.UPDATE_WORKFLOW_NAME_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleWorkflowRun(workflowRunId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.FETCH_SINGLE_WORKFLOW_RUN_REQUEST));

        workFlowService.getSingleWorkflowRun(workflowRunId).then(
            (res) => {
                dispatch(success(workFlowTypes.FETCH_SINGLE_WORKFLOW_RUN_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workFlowTypes.FETCH_SINGLE_WORKFLOW_RUN_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getWorkflowRuns(workflowId: string, limit?: string | number) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.FETCH_WORKFLOW_RUNS_REQUEST));

        workFlowService.getWorkflowRuns(workflowId, limit).then(
            (res) => {
                dispatch(success(workFlowTypes.FETCH_WORKFLOW_RUNS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.FETCH_WORKFLOW_RUNS_FAILURE, error?.message));
                }
            }
        );
    };
}

function retryAction(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.RETRY_WORKFLOW_ACTION_REQUEST));

        workFlowService.retryAction(data).then(
            (res) => {
                dispatch(success(workFlowTypes.RETRY_WORKFLOW_ACTION_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Action retried"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.RETRY_WORKFLOW_ACTION_FAILURE, error?.message));
                }
            }
        );
    };
}

function markActionAsDone(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.MARK_WORKFLOW_ACTION_AS_DONE_REQUEST));

        workFlowService.markActionAsDone(data).then(
            (res) => {
                dispatch(success(workFlowTypes.MARK_WORKFLOW_ACTION_AS_DONE_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Action marked as done"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workFlowTypes.MARK_WORKFLOW_ACTION_AS_DONE_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function markActionAsRejected(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.MARK_WORKFLOW_ACTION_AS_REJECTED_REQUEST));

        workFlowService.markActionARejected(data).then(
            (res) => {
                dispatch(
                    success(workFlowTypes.MARK_WORKFLOW_ACTION_AS_REJECTED_SUCCESS, res?.data)
                );
                toast.custom((t) => (
                    <CustomToast t={t} message={"Action rejected"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            workFlowTypes.MARK_WORKFLOW_ACTION_AS_REJECTED_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function togglePausePlayWorkflowRun(
    data: Record<string, any> | Array<Record<string, any>>,
    runId: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.PAUSE_PLAY_WORKFLOW_RUN_REQUEST));

        workFlowService.playPauseWorkflowRun(data, runId).then(
            (res) => {
                dispatch(success(workFlowTypes.PAUSE_PLAY_WORKFLOW_RUN_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workFlowTypes.PAUSE_PLAY_WORKFLOW_RUN_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getWorkflowAnalytics() {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.FETCH_WORKFLOW_ANALYTICS_REQUEST));

        workFlowService.getWorkflowAnalytics().then(
            (res) => {
                dispatch(success(workFlowTypes.FETCH_WORKFLOW_ANALYTICS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workFlowTypes.FETCH_WORKFLOW_ANALYTICS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getSingleComments(commentId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.FETCH_SINGLE_COMMENT_REQUEST));

        workFlowService.getSingleComment(commentId).then(
            (res) => {
                dispatch(success(workFlowTypes.FETCH_SINGLE_COMMENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.FETCH_SINGLE_COMMENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function createAction(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.CREATE_ACTION_REQUEST));

        workFlowService.createAction(data).then(
            (res) => {
                dispatch(success(workFlowTypes.CREATE_ACTION_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.CREATE_ACTION_FAILURE, error?.message));
                }
            }
        );
    };
}

function deleteComment(commentId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.DELETE_COMMENT_REQUEST));

        workFlowService.deleteComment(commentId).then(
            (res) => {
                dispatch(success(workFlowTypes.DELETE_COMMENT_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Comment deleted"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.DELETE_COMMENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateAction(data: Record<string, any> | Array<Record<string, any>>, actionId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.UPDATE_ACTION_REQUEST));

        workFlowService.updateAction(data, actionId).then(
            (res) => {
                dispatch(success(workFlowTypes.UPDATE_ACTION_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.UPDATE_ACTION_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateEvent(data: Record<string, any> | Array<Record<string, any>>, eventId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.UPDATE_EVENT_REQUEST));

        workFlowService.updateEvent(data, eventId).then(
            (res) => {
                dispatch(success(workFlowTypes.UPDATE_EVENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.UPDATE_EVENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function duplicateAction(actionId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.DUPLICATE_ACTION_REQUEST));

        workFlowService.duplicateAction(actionId).then(
            (res) => {
                dispatch(success(workFlowTypes.DUPLICATE_ACTION_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.DUPLICATE_ACTION_FAILURE, error?.message));
                }
            }
        );
    };
}

function createMultipleActions(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workFlowTypes.CREATE_MULTIPLE_ACTIONS_REQUEST));

        workFlowService.createMultipleActions(data).then(
            (res) => {
                dispatch(success(workFlowTypes.CREATE_MULTIPLE_ACTIONS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workFlowTypes.CREATE_MULTIPLE_ACTIONS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function resetMarkActionAsRejectedSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.MARK_WORKFLOW_ACTION_AS_REJECTED_SUCCESS, null));
    };
}

function resetCreateMultipleActionSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.CREATE_MULTIPLE_ACTIONS_SUCCESS, null));
    };
}

function resetDuplicateActionSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.DUPLICATE_ACTION_SUCCESS, null));
    };
}

function resetUpdateEventSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.UPDATE_EVENT_SUCCESS, null));
    };
}

function resetUpdateActionSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.UPDATE_ACTION_SUCCESS, null));
    };
}

function resetCreateActionSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.CREATE_ACTION_SUCCESS, null));
    };
}

function resetTogglePausePlayWorkflowRunSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.PAUSE_PLAY_WORKFLOW_RUN_SUCCESS, null));
    };
}

function resetUpdateWorkflowNameSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.UPDATE_WORKFLOW_NAME_SUCCESS, null));
    };
}

function resetDuplicateWorkflowSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.DUPLICATE_WORKFLOW_SUCCESS, null));
    };
}

function resetGetWorkflowActivitiesSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.FETCH_WORKFLOW_ACTIVITIES_SUCCESS, null));
    };
}

function resetSwapNodesSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.SWAP_NODE_SUCCESS, null));
    };
}

function resetDeleteActionSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.DELETE_ACTION_SUCCESS, null));
    };
}

function resetDeleteEventSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.DELETE_EVENT_SUCCESS, null));
    };
}

function resetUpdateEventAndActionSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.UPDATE_EVENT_AND_ACTION_SUCCESS, null));
    };
}

function resetGetNodesCommentsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.FETCH_NODE_COMMENTS_SUCCESS, null));
    };
}

function resetCreateCommentSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.CREATE_COMMENT_SUCCESS, null));
    };
}

function resetDeleteWorkFlowSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.DELETE_WORKFLOW_SUCCESS, null));
    };
}

function resetCreateWorkFlowSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.CREATE_WORKFLOW_SUCCESS, null));
    };
}

function resetCreateEventAndActionSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.CREATE_EVENT_AND_ACTION_SUCCESS, null));
    };
}

function resetPauseOrPlayWorkFlowSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.PLAY_PAUSE_WORKFLOW_SUCCESS, null));
    };
}

function resetUpdateWorkflowConnectionsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.UPDATE_WORKFLOW_CONNECTIONS_SUCCESS, null));
    };
}

function resetUpdateWorkflowConnectionsFailure() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.UPDATE_WORKFLOW_CONNECTIONS_FAILURE, null));
    };
}

function resetUpdateWorkflowDisconnectionsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.UPDATE_WORKFLOW_DISCONNECTIONS_SUCCESS, null));
    };
}

function resetUpdateWorkflowDisconnectionsFailure() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.UPDATE_WORKFLOW_DISCONNECTIONS_FAILURE, null));
    };
}

function resetUpdateWorkflowNodePositionSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workFlowTypes.UPDATE_WORKFLOW_NODE_POSITION_SUCCESS, null));
    };
}

function request(type: string) {
    return { type: type };
}
function success(type: string, data: any) {
    return { type: type, payload: data };
}
function failure(type: string, error: any) {
    return { type: type, payload: error ?? "" };
}
