import React, { useState } from "react";
import Button from "../../../../../common/Button";
import { convertBytes } from "helpers/displayFileSize";

type NewUploadsProps = {
    fileToUpload: File;
    setFileToUpload: React.Dispatch<React.SetStateAction<File | null>>;
    skipDuplicates?: boolean;
    setSkipDuplicates?: React.Dispatch<React.SetStateAction<boolean>>;
};

const NewUploads = ({
    fileToUpload,
    setFileToUpload,
    skipDuplicates,
    setSkipDuplicates,
}: NewUploadsProps) => {
    const [uploadedFile, setUploadedFile] = useState<boolean>(true);

    return (
        <div className="mt-6 mb-[38px]">
            {/* {!uploadedFile ? (
                <div className="w-full rounded border-[#BFCDE0] border-dashed border ">
                    <div className="py-8 font-rocGroteskMedium flex justify-center flex-col items-center">
                        <i className="ri-upload-cloud-2-line mb-4 text-4xl  text-slate-500 "></i>
                        <p className="text-[#142837] mb-1 text-sm ">
                            Drag your file here to upload
                        </p>
                        <p className="text-[#6B778C] text-sm mb-4">XLSX | File size limit: 25MB</p>
                        <Button
                            btnText="Browse files"
                            btnClassname="!bg-white !py-2 !px-4 border border-[#DFE1E6] !text-[#142837] !text-[13px] !w-auto"
                        />
                    </div>
                </div>
            ) : ( */}
            <div>
                <div className="border-[#16A34A] border rounded flex">
                    <div className="bg-[#F0FDF4] px-[26px] py-5 rounded-l border-r-[#16A34A] border">
                        <i className="ri-upload-cloud-2-line mb-4 text-4xl  text-slate-500 "></i>
                    </div>
                    <div className="pl-4 pr-6 flex items-center justify-between gap-4 w-full">
                        <div className="">
                            <p className="">{fileToUpload?.name}</p>
                            <p className="">
                                XLSX | <span className="">{convertBytes(fileToUpload?.size)}</span>
                            </p>
                        </div>
                        <div className="gap-2 flex !w-auto justify-end relative">
                            <Button
                                btnText="Replace File"
                                onClick={() => document.getElementById("replaceFile")?.click()}
                                btnClassname="!py-2  !text-gun-metal !text-[13px] !w-auto border !border-n-40 !bg-transparent"
                            />
                            <Button
                                btnText="Remove"
                                onClick={() => setFileToUpload(null)}
                                btnClassname="!py-2  !text-[#f00] !text-[13px] !w-auto border !border-n-40 !bg-transparent"
                            />
                            <input
                                id="replaceFile"
                                type="file"
                                className="opacity-0 absolute w-[0px] h-[0px] top-0 z-[-1] cursor-pointer"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onChange={(e) => setFileToUpload(e.target.files[0])}
                            />
                        </div>
                    </div>
                </div>

                <div className="my-8 font-rocGroteskMedium text-sm">
                    <div className="mb-6">
                        <p
                            onClick={() => setSkipDuplicates(true)}
                            className="text-gun-metal ml-1 flex items-center space-x-1 w-fit cursor-pointer"
                        >
                            {skipDuplicates ? (
                                <i
                                    className={`ri-radio-button-fill text-xl ${
                                        skipDuplicates && "text-[#16A34A]"
                                    }`}
                                ></i>
                            ) : (
                                <span className={`material-icons text-xl text-n-50`}>
                                    radio_button_unchecked
                                </span>
                            )}
                            <span>Skip duplicates</span>
                        </p>
                        <p className="mt-1  text-slate-500">
                            Retains the product in Synthally Inventory and does not import the
                            duplicates in the import file.
                        </p>
                    </div>

                    <div className="mb-6">
                        <p
                            onClick={() => setSkipDuplicates(false)}
                            className="text-gun-metal ml-1 flex items-center space-x-1 w-fit cursor-pointer"
                        >
                            {!skipDuplicates ? (
                                <i
                                    className={`ri-radio-button-fill text-xl ${
                                        !skipDuplicates && "text-[#16A34A]"
                                    }`}
                                ></i>
                            ) : (
                                <span className={`material-icons text-xl text-n-50`}>
                                    radio_button_unchecked
                                </span>
                            )}
                            <span>Add duplicates as new products</span>
                        </p>
                        <p className="mt-1  text-slate-500">
                            Imports the duplicates in the import file and adds them as new products
                            in Synthally Inventory.
                        </p>
                    </div>
                </div>
            </div>
            {/* )} */}
        </div>
    );
};

export default NewUploads;
