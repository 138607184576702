import Dropdown from "components/common/Dropdown";
import { getEditPermissionList } from "helpers/getEditPermissionList";
import { truncate } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
    name: string;
    address: string;
    onClick: (e) => void;
    onDelete: () => void;
    onEdit: () => void;
};

const Card = ({ name, address, onClick, onDelete, onEdit }: Props) => {
    const navigate = useNavigate();
    return (
        <div
            className="cursor-pointer bg-white"
            onClick={(e) => {
                onClick(e);
            }}
        >
            <div className="border border-slate-100 rounded py-4 shadow-wareHouseCardShadow">
                <div className="flex justify-between px-4">
                    <div className="w-9 h-9 flex justify-center items-center bg-slate-100 rounded">
                        <i className="ri-community-line text-slate-500 text-base"></i>
                    </div>
                    <div onClick={(e) => e.stopPropagation()}>
                        <Dropdown
                            dropdown={
                                <i className="ri-more-2-fill before:content-['\ef76'] text-gm-50"></i>
                            }
                            dropdownClassName={"!w-full dropdown-container  mt-4 !mb-10 "}
                            dropdownContainerClasses={`shadow-cardShadow-2 left-[-120px]  border-0 rounded !max-h-fit`}
                            dropdownItemsClasses={``}
                            name={"faq-dropdown"}
                            dropdownOptions={[
                                {
                                    label: "Edit warehouse",
                                    value: "edit-warehouse",
                                    action: () => onEdit(),
                                    permissions: getEditPermissionList("inventory"),
                                },
                                {
                                    label: "Delete warehouse",
                                    value: "delete-warehouse",
                                    labelClassName: "!text-[red]",
                                    action: () => onDelete(),
                                    permissions: getEditPermissionList("inventory"),
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="pb-4 ">
                    <div className="text-sm font-rocGroteskMedium mt-4 px-4">
                        <p className="text-gm-50  ">{truncate(name, {length:47})}</p>
                        <p className="text-slate-500">{truncate(address, {length:47})}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Card;
