import config from "config/config";
import { initializeApp } from "firebase/app";
import { collection, getFirestore, limit, onSnapshot, orderBy, query } from "firebase/firestore";
import { getDatabase, onValue, ref as refRealtimeDB } from "firebase/database";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject,
} from "firebase/storage";

const app = initializeApp(config.FB);
const db = getFirestore(app);
const realtimeDB = getDatabase(app);
const storage = getStorage(app);

export const firebaseService = {
    uploadMultipleFiles,
    uploadFile,
    removeFile,
    removeMultipleFiles,
    fetchRealtimeMvmLocations,
    listenForNewMessages,
};

// this function has not been tested yet
async function uploadMultipleFiles(
    files: File[],
    filePathAndNames: string[],
    setReturnType: (arg: string[]) => void,
    setProgressFunc?: React.Dispatch<React.SetStateAction<{ progress: number }>>
) {
    try {
        const uploadPromises = files.map(async (file, index) => {
            const filePathAndName = filePathAndNames[index];
            const storageRef = ref(storage, filePathAndName);

            const uploadTask = uploadBytesResumable(storageRef, file);

            return new Promise<string>((resolve, reject) => {
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setProgressFunc && setProgressFunc({ progress });
                    },
                    (error) => {
                        console.log(error, error.code, " firebase storage upload error check");
                        reject(error);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            resolve(downloadURL);
                        });
                    }
                );
            });
        });

        const downloadURLs = await Promise.all(uploadPromises);
        setReturnType(downloadURLs);
    } catch (error) {
        console.error("Error uploading files:", error);
        removeMultipleFiles(filePathAndNames);
        setReturnType([]);
    }
}

// this function has not been tested yet
async function removeMultipleFiles(filePathAndNames: string[], onCompleteFunc?: () => void) {
    try {
        const removePromises = filePathAndNames.map(async (filePathAndName) => {
            const storageRef = ref(storage, filePathAndName);
            await deleteObject(storageRef);
        });

        await Promise.allSettled(removePromises);
        console.log("successfully removed multiple files");
        // All files deleted successfully
        onCompleteFunc?.();
    } catch (error) {
        // Uh-oh, an error occurred!
        console.error("Error removing files:", error);
    }
}

async function uploadFile(
    filePathAndName: string,
    file: File,
    setReturnType: (arg: string) => void,
    progressRef?: number,
    setProgressFunc?: React.Dispatch<React.SetStateAction<{ progress: number }>>
) {
    const storageRef = ref(storage, filePathAndName);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
        "state_changed",
        (snapshot) => {
            const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * (progressRef as number);
            setProgressFunc && setProgressFunc({ progress });
        },
        (error) => {
            console.log(error, error.code, " firebase storage upload error check");
            setReturnType("error");
        },
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) =>
                setReturnType(downloadURL)
            );
        }
    );
}

async function removeFile(filePathAndName: string, onCompleteFunc?: () => void) {
    const storageRef = ref(storage, filePathAndName);

    deleteObject(storageRef)
        .then((res) => {
            // File deleted successfully
            console.log("res", res);
            onCompleteFunc?.();
        })
        .catch((error) => {
            // Uh-oh, an error occurred!
            console.log("error", error);
        });
}

function fetchRealtimeMvmLocations(
    shipmentId: string,
    saveResult?: (arg: any) => void,
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
    unSubScribeFunc?: (arg: (arg: () => void) => void) => void
) {
    const locationPath = `ally/${
        process.env.REACT_APP_STAGE === "production" ? "production" : "staging"
    }/location_updates/${shipmentId}`;
    setLoading?.(true);
    const mvmLocationRef = refRealtimeDB(realtimeDB, locationPath);

    const unSubScribe = onValue(mvmLocationRef, (snapshot) => {
        const value = snapshot.val();

        saveResult?.(value);
        setLoading?.(false);
    });
    unSubScribeFunc && unSubScribe && unSubScribeFunc(unSubScribe);
}

function listenForNewMessages(
    collectionPath: string,
    setNewMessage: (arg: any) => void,
    unSub?: (arg: (arg: () => void) => void) => void
) {
    const q = query(collection(db, collectionPath), orderBy("createdAt", "desc"), limit(1));

    const unSubscribe = onSnapshot(q, (snapshot) => {
        snapshot.forEach((shot) => {
            console.log("shot ->", shot?.data());
            const formattedData = {
                ...shot.data(),
                createdAt: shot.data().createdAt.toDate().toISOString(),
                updatedAt: shot.data().updatedAt.toDate().toISOString(),
            };
            setNewMessage(formattedData);
        });
    });

    unSub && unSubscribe && unSub(unSubscribe);
}
