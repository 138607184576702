import Button from "components/common/Button";
import MultiselectInput from "components/common/InputField/MultiselectInput";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import {getFromStorage} from "helpers";
import React, {useEffect, useState} from "react";

type CreateNewGroupProps = {
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    email?: string;
    memberCategory: any;
    onChangeCategory?: React.Dispatch<React.SetStateAction<string>>;
    onAddNewMember: () => void;
    onChangeDocs: (value: any) => void;
    onChangeEmail?: (value: string) => void;
    isSending: boolean;
    roleCategory?: string;
    onClose?: () => void;
};

export const CreateNewGroup = ({
    onSubmit,
    email,
    memberCategory,
    onChangeCategory,
    onAddNewMember,
    onChangeDocs,
    onChangeEmail,
    isSending,
}: CreateNewGroupProps) => {
    const categoryDocs = [
        {
            label: "Emily Kate",
            value: "Emily",
            role: "Finance",
        },
        {
            label: "John Malcom",
            value: "John",
            role: "Operator/Logistician",
        },
        {
            label: "Sarah Dransna",
            value: "Sarah",
            role: "Viewer/Analyst",
        },
        {
            label: "David Ogbonna",
            value: "David",
            role: "Manager",
        },
    ];

    return (
        <div className="bg-white shadow-cardShadow relative rounded-[20px]">
            <div className="p-10 flex flex-col items-center ">
                <div className="text-center w-full mb-[46px]">
                    <p className="mb-2.5 text-2xl font-rocGroteskMedium text-g-75">
                        Create a new group
                    </p>
                    <p className="text-sm font-rocGroteskMedium text-gm-40">
                        Create a group and add team members to it
                    </p>
                </div>
                <form className="w-full" onSubmit={onSubmit}>
                    <div className="mb-10 space-y-7 w-full">
                        <TextInput
                            value={email}
                            name={"email"}
                            type={"email"}
                            onChange={(e) => onChangeEmail?.(e.target.value)}
                            placeholder={"Enter email address"}
                            required={true}
                            containerClassname="mb-3"
                        />
                        <MultiselectInput
                            value={memberCategory}
                            name="memberCategory"
                            placeholder={"Add team members"}
                            className=""
                            isRequired={false}
                            handleChange={(name, value, valuesArray) => onChangeDocs(valuesArray)}
                            switchOptions={memberCategory}
                            dropdownOptions={categoryDocs}
                            labelClasses={"!font-rocGroteskMedium !text-gm-50 !text-[14px]"}
                            roleClasses={"!font-rocGroteskMedium !text-slate-500 !text-[14px]"}
                            addModalButton
                            onAddNewMember={onAddNewMember}
                        />
                    </div>

                    <div className="w-full ">
                        <Button btnText={"Create group"} type={"submit"} isLoading={isSending} />
                    </div>
                </form>
            </div>
        </div>
    );
};
