import React from "react";

type ProgressBarProps = {
    progress: number;
    className?: string;
};

const ProgressBar = ({progress, className}: ProgressBarProps) => {
    return (
        <div className="h-1 w-full bg-gray-100">
            <div
                className={`h-full bg-carribean-green ${className}`}
                style={{width: `${progress}%`}}
            />
        </div>
    );
};

export default ProgressBar;
