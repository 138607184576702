export const businessCatgories = [
    { label: "Procurement", value: "Procurement" },
    { label: "Manufacturing", value: "Manufacturing" },
    { label: "Retailer", value: "Retailer" },
    { label: "E-commerce", value: "E-commerce" },
    { label: "Wholesaler and Distributor", value: "Wholesaler and Distributor" },
    { label: "Importer and Exporter", value: "Importer and Exporter" },
    { label: "Healthcare Providers", value: "Healthcare Providers" },
    { label: "Food and Beverage Company (FMCG)", value: "Food and Beverage Company (FMCG)" },
    { label: "Automotive Industry", value: "Automotive Industry" },
    { label: "Construction and Engineering", value: "Construction and Engineering" },
    { label: "Oil and Gas", value: "Oil and Gas" },
    { label: "Technology", value: "Technology" },
    { label: "Renewable energy", value: "Renewable energy" },
];
