import React, { useState, useEffect, useCallback } from "react";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import { displayFileIcon, onScroll } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions, vendorActions } from "redux/Ldb/actions";
import useGetUserProfile from "hooks/useGetUserProfile";
import Loader from "components/common/Loader";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import DateInput from "components/common/DateInput";
import { debounce } from "lodash";

type AddStockType = {
    shipmentId?: string;
    inventoryId: string;
    vendorId: string;
    cost: {
        amount: string;
        currency: string;
    };
    warehouseId: string;
    productStockDetails: {
        quantityAdded: string;
        unitOfMeasurement: string;
    };
    productViabilityDetails: {
        expiryDate: string;
        bestBefore: string;
        removalTime: string;
        manufacturingDate: string;
    };
    receivingChannel: string;
    attachments: [
        {
            filename: string;
            path: string;
            documentType?: string;
        }
    ];
    notes: string;
};

const EditDamages = ({
    stockDetails,
    handleStockDetailsChange,
    attachment,
    uploadingAttachment,
    handleUpload,
    handleDeleteAttachment,
    productDates,
    handleDateChange,
    receivingChannel,
}: any): JSX.Element => {
    const dispatch = useAppDispatch();
    const [showExpirationDate, setShowExpirationDate] = useState<boolean>(false);
    const [showBBDate, setShowBBDate] = useState<boolean>(false);
    const [showRemovalTimeDate, setShowRemovalTimeDate] = useState<boolean>(false);
    const [showManufactureDate, setShowManufactureDate] = useState<boolean>(false);
    const [debounceProductSearch, setDebounceProductSearch] = useState<string>();
    const [debounceWarehouseSearch, setDebounceWarehouseSearch] = useState<string>();
    const [product, setProduct] = useState<{ [key: string]: any }>([]);
    const [inventory, setInventory] = useState<any>([]);
    const [warehouses, setWarehouses] = useState<any>();
    const [suppliers, setSuppliers] = useState<any>();

    const { fetchedOnboardedVendorsSuccess } = useAppSelector((state) => state?.vendor);
    const {
        fetchingWarehouses,
        fetchedWarehousesSuccess,
        fetchingInventoryList,
        fetchedInventoryListSuccess,
    } = useAppSelector((state) => state?.inventory);

    useEffect(() => {
        if (Boolean(fetchedInventoryListSuccess)) {
            setInventory(fetchedInventoryListSuccess?.inventory?.data);
        }
    }, [fetchedInventoryListSuccess]);

    useEffect(() => {
        if (stockDetails?.inventoryId && inventory) {
            const copyInventory = [...inventory];
            const getProduct = copyInventory?.filter(
                (item) => item?._id === stockDetails?.inventoryId
            );
            setProduct(getProduct);
        }
    }, [inventory, stockDetails]);
    useEffect(() => {
        if (Boolean(fetchedWarehousesSuccess)) {
            setWarehouses(fetchedWarehousesSuccess?.warehouses.data);
        }
    }, [fetchedWarehousesSuccess]);

    useEffect(() => {
        dispatch(inventoryActions.fetchInventoryList(20, debounceProductSearch));
    }, [dispatch, debounceProductSearch]);
    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouses(20, debounceWarehouseSearch));
    }, [dispatch, debounceWarehouseSearch]);
    useEffect(() => {
        if (Boolean(fetchedOnboardedVendorsSuccess)) {
            setSuppliers(fetchedOnboardedVendorsSuccess?.vendors);
        }
    }, [fetchedOnboardedVendorsSuccess]);

    useEffect(() => {
        dispatch(vendorActions.getOnboardedVendors());
    }, [dispatch]);

    return (
        <div className="w-[65%]">
            <div className="mb-4">

                <TextInput
                    value={stockDetails?.quantity}
                    name={"quantity"}
                    type={"number"}
                    onChange={handleStockDetailsChange}
                    placeholder={"Quantity"}
                    containerClassname=""
                    required
                />
            </div>
            <div className="flex flex-col gap-6 mt-8">
                <div>
                    <label className="text-base font-rocGroteskMedium">Attachments</label>
                    <input
                        type="file"
                        className="hidden"
                        id="attachment"
                        accept="image*, .pdf, .xlsx"
                        disabled={uploadingAttachment}
                        onChange={(e) => {
                            if (e.target.files?.[0]) {
                                handleUpload(
                                    e.target.files?.[0]?.name as string,
                                    e.target.files?.[0]?.size as number
                                );
                            }
                        }}
                    />
                    <div className="mt-4 ">
                        {attachment?.map((item, idx) => {
                            return (
                                <div className="flex items-center justify-between p-3 rounded-md border border-slate-200 mb-4">
                                    <div className="flex items-center gap-2">
                                        {/* {displayFileIcon("pdf", false)} */}
                                        {displayFileIcon(getFileTypeFromUrl(item?.path), false)}
                                        <div className="font-rocGroteskMedium">
                                            <p className="text-sm">{item?.filename}</p>
                                            <p className="text-xs text-slate-500">
                                                {/* {item?.size} */}
                                            </p>
                                        </div>
                                    </div>
                                    <i
                                        className="ri-delete-bin-line text-xl text-r-50 cursor-pointer"
                                        onClick={() => handleDeleteAttachment(idx, item?._id)}
                                    ></i>
                                </div>
                            );
                        })}

                        <label
                            className={`flex items-center gap-4 py-3 px-6 rounded border border-dashed border-slate-200  ${
                                !uploadingAttachment && "cursor-pointer"
                            }`}
                            htmlFor="attachment"
                        >
                            <i className="ri-file-add-line text-2xl text-carribean-green"></i>
                            {uploadingAttachment ? (
                                <div className="">
                                    <Loader size={4} />
                                </div>
                            ) : (
                                <p className="text-sm underline text-carribean-green font-rocGroteskMedium">
                                    Click to upload document
                                </p>
                            )}
                        </label>
                    </div>
                </div>

                <div>
                    <label className="text-base font-rocGroteskMedium">Notes</label>
                    <div className="mt-4">
                        <TextareaInput
                            name={"notes"}
                            value={stockDetails?.notes}
                            onChange={handleStockDetailsChange}
                            rows={5}
                            placeholder={"Add note"}
                            required={false}
                            containerClassname=""
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditDamages;
