import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CreateRfqMethods from "components/common/CreateRfqMethods";
import CustomMobileTable from "components/common/CustomMobileTable";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import { SideDrawer } from "components/common/SideDrawer";
import StatusBadge from "components/common/StatusBadge";
import PageFrame from "components/layout/PageFrame";
import { onScroll } from "helpers";
import { convertSeconds, parseConvertedSeconds } from "helpers/convertSeconds";
import { truncate } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { workFlowActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { getEditPermissionList } from "helpers/getEditPermissionList";
import CustomToast from "components/common/CustomToast";
import toast from "react-hot-toast";
import useGetUserProfile from "hooks/useGetUserProfile";

const WorkFlowRun = () => {
    const limit = 20;
    const [profile] = useGetUserProfile();
    const [workFlow, setWorkFlow] = useState(false);
    const [showActivity, setShowActivity] = useState(false);
    const [deleteWorkFlow, setDeleteWorkFlow] = useState(false);
    const [stopWorkFlow, setStopWorkFlow] = useState(false);
    const [runId, setRunId] = useState("");
    const [runAction, setRunAction] = useState("");
    const [selectedRun, setSelectedRun] = useState<{ [key: string]: any }>({});
    const [selectedOption, setSelectedOption] = useState("");
    const [workFlowName, setWorkFlowName] = useState("");
    const [prompt, setPrompt] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [workFlowAction, setWorkFlowAction] = useState("");
    const [workflowRuns, setWorkflowRuns] = useState<{ [key: string]: any }[]>([]);

    const [loadMoreRuns, setLoadMoreRuns] = useState(false);
    const [workFlowRunPagination, setWorkFlowRunPagination] = useState<{ [key: string]: any }>({
        current: "",
        number_of_pages: "",
    });
    const [workFlowRunLimit, setWorkFlowRunLimit] = useState(limit);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = useParams();
    const {
        fetchingWorkflowRuns,
        fetchedWorkflowRunSuccess,
        togglingPausePlayWorkflowRun,
        togglePausePlayWorkflowRunSuccess,
    } = useAppSelector((state) => state.workFlow);

    const dashboardContent = document.getElementById("dashboardContent");

    const optionsData = [
        // {
        //     title: "Generate with AI",
        //     subText: "Prompt TradeAlly AI Co-Pilot to generate and automate your custom workflow",
        //     icon: <i className="ri-sparkling-line text-3xl text-p-50 mb-6"></i>,
        //     action: () => {
        //         setSelectedOption("AI");
        //         setWorkFlow(false);
        //     },
        // },
        {
            title: "Start a blank workflow",
            subText: "Manually set up your workflow and specify what happens on each touchpoint",
            icon: <i className="ri-file-2-line text-3xl text-p-50 mb-6"></i>,
            action: () => {
                setSelectedOption("manual");
                setWorkFlow(false);
            },
        },
        {
            title: "Import a document",
            subText:
                "Use SynthAlly AI to generate and automate your workflow based on your document",
            icon: <i className="ri-file-upload-line text-3xl text-p-50 mb-6"></i>,
            action: () => {
                setSelectedOption("document");
                setWorkFlow(false);
            },
        },
    ];

    const onPlay = () => {
        const body = {
            active: true,
        };

        dispatch(workFlowActions.togglePausePlayWorkflowRun(body, runId));
    };

    const onPause = () => {
        const body = {
            active: false,
        };
        dispatch(workFlowActions.togglePausePlayWorkflowRun(body, runId));
    };

    const tableHeader = [
        { title: `Runs (${workflowRuns?.length})`, widthClass: "w-[35.7%]" },
        { title: "Status", widthClass: "w-[28.1%]" },
        // { title: "Number of events", widthClass: "w-[20%]" },
        { title: "Average run time", widthClass: "w-[19.1%]" },
        { title: "", widthClass: "w-[6%]" },
        { title: "", widthClass: "w-[6%]" },
    ];

    const tableBody = workflowRuns?.map((run, idx) => {
        const completedActions = run?.workFlowActions?.filter(
            (action: { [key: string]: any }) => action?.currentStatus?.toLowerCase() === "completed"
        );
        const totalCompletedRunTime = completedActions.reduce(
            (accumulator: number, currentValue: { runTime: number }) =>
                accumulator + currentValue.runTime,
            0
        );
        const avgCompletedRunTime = totalCompletedRunTime / completedActions?.length;
        const convertedSeconds = parseConvertedSeconds(convertSeconds(avgCompletedRunTime));

        return [
            {
                content: (
                    <div className="flex py-[18px] items-center space-x-1">
                        <p
                            className={`text-[14px] ${
                                run?.active || run?.currentStatus?.toLowerCase() === "completed"
                                    ? "text-slate-800"
                                    : "text-slate-400"
                            } font-rocGroteskRegular whitespace-nowrap`}
                        >
                            WR {run?.taId}
                        </p>
                    </div>
                ),
                cellClickAction: () => {
                    navigate(`/dashboard/workflow/${run?._id}?run=${params?.workflowId}`);
                },
            },
            {
                content: <StatusBadge status={run?.currentStatus} />,
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskRegular border-slate-100 text-sm ${
                            run?.active || run?.currentStatus?.toLowerCase() === "completed"
                                ? "text-slate-800"
                                : "text-slate-400"
                        } `}
                    >
                        <div className="flex items-center gap-3">{convertedSeconds || "----"}</div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 space-x-1  flex items-center h-full border-slate-100 text-sm text-slate-800 `}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (
                                !getEditPermissionList("workflow")?.some((permission) =>
                                    profile?.permission?.includes(permission)
                                )
                            ) {
                                toast.custom((t) => (
                                    <CustomToast
                                        t={t}
                                        message="You don't have access to this feature"
                                        type="error"
                                    />
                                ));
                            } else {
                                if (run?.currentStatus?.toLowerCase() !== "completed") {
                                    setRunId(run?._id);
                                    setRunAction(`${run?.active ? "pause" : "play"}`);
                                    setSelectedRun(run);
                                }
                            }
                        }}
                    >
                        {run?.active || run?.currentStatus?.toLowerCase() === "completed" ? (
                            <i className="ri-pause-circle-line text-[24px]  text-slate-800"></i>
                        ) : (
                            <i className="ri-play-line text-[24px]  text-slate-400"></i>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] flex justify-center w-[82%] border-l pl-5 items-center h-full border-slate-100 text-sm text-slate-800 `}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Dropdown
                            dropdownTriggerClassName={"-mt-2 "}
                            dropdown={<i className="ri-more-2-fill text-lg text-slate-700"></i>}
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-2 -left-[120px] ${
                                workflowRuns?.length >= 10 &&
                                idx >= workflowRuns?.length - 2 &&
                                "!top-[-115px]"
                            } border-0 rounded !max-h-fit`}
                            dropdownItemsClasses={``}
                            name={"faq-dropdown"}
                            dropdownOptions={[
                                {
                                    label: "View workflow",
                                    value: "view workflow",
                                    action: () => {
                                        navigate(
                                            `/dashboard/workflow/${run?._id}?run=${params?.workflowId}`
                                        );
                                    },
                                },
                                {
                                    label: `${run?.active ? "Pause workflow" : "Play workflow"}`,
                                    value: `${run?.active ? "pause workflow" : "play workflow"}`,
                                    action: () => {
                                        if (run?.currentStatus?.toLowerCase() !== "completed") {
                                            setRunId(run?._id);
                                            setRunAction(`${run?.active ? "pause" : "play"}`);
                                            setSelectedRun(run);
                                        }
                                    },
                                    disabled: run?.currentStatus?.toLowerCase() === "completed",
                                    permissions: getEditPermissionList("workflow"),
                                },
                            ]}
                        />
                    </div>
                ),
                // widthClass: "absolute",
            },
        ];
    });

    const mobileTableBody = workflowRuns?.map((run, idx) => {
        const completedActions = run?.workFlowActions?.filter(
            (action) => action?.currentStatus?.toLowerCase() === "completed"
        );
        const totalCompletedRunTime = completedActions.reduce(
            (accumulator, currentValue) => accumulator + currentValue.runTime,
            0
        );
        const avgCompletedRunTime = totalCompletedRunTime / completedActions?.length;
        const convertedSeconds = parseConvertedSeconds(convertSeconds(avgCompletedRunTime));

        return {
            cellClickAction: () => {
                navigate(`/dashboard/workflow/${run?._id}?run=${params?.workflowId}`);
            },
            topString: run?.taId,
            topContent: <p className="text-sm font-rocGroteskMedium mb-3">WR {run?.taId}</p>,
            bottomContent: (
                <div>
                    <div className="flex items-center gap-3 ">
                        <StatusBadge status={run?.currentStatus} />
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                if (run?.currentStatus?.toLowerCase() !== "completed") {
                                    setRunId(run?._id);
                                    setRunAction(`${run?.active ? "pause" : "play"}`);
                                    setSelectedRun(run);
                                }
                            }}
                        >
                            {run?.active || run?.currentStatus?.toLowerCase() === "completed" ? (
                                <i className="ri-pause-circle-line text-base  text-slate-800"></i>
                            ) : (
                                <i className="ri-play-line text-base  text-slate-400"></i>
                            )}
                        </div>
                        <p className="text-sm font-rocGroteskMedium text-slate-500">
                            {convertedSeconds || "----"}
                        </p>
                    </div>
                </div>
            ),
            rightIcon: (
                <div
                    className={`py-[18px] flex justify-center w-[82%] pl-5 items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        dropdownTriggerClassName={"-mt-2 "}
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-700"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 -left-[120px] ${
                            workflowRuns?.length >= 10 &&
                            idx >= workflowRuns?.length - 2 &&
                            "!top-[-115px]"
                        } border-0 rounded !max-h-fit`}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "View workflow",
                                value: "view workflow",
                                action: () => {
                                    navigate(
                                        `/dashboard/workflow/${run?._id}?run=${params?.workflowId}`
                                    );
                                },
                            },
                            {
                                label: `${run?.active ? "Pause workflow" : "Play workflow"}`,
                                value: `${run?.active ? "pause workflow" : "play workflow"}`,
                                action: () => {
                                    if (run?.currentStatus?.toLowerCase() !== "completed") {
                                        setRunId(run?._id);
                                        setRunAction(`${run?.active ? "pause" : "play"}`);
                                        setSelectedRun(run);
                                    }
                                },
                                disabled: run?.currentStatus?.toLowerCase() === "completed",
                            },
                        ]}
                    />
                </div>
            ),
        };
    });

    const onWorkFlowRunTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent as HTMLElement,
                workFlowRunPagination?.current as number,
                workFlowRunPagination?.number_of_pages as number,
                () => {
                    setWorkFlowRunLimit(() => workFlowRunLimit + limit);
                    setLoadMoreRuns(true);
                }
            ),
        [dashboardContent, workFlowRunPagination]
    );

    useEffect(() => {
        dispatch(workFlowActions.getWorkflowRuns(params?.workflowId as string, workFlowRunLimit));
    }, [dispatch, params?.workflowId, togglePausePlayWorkflowRunSuccess, workFlowRunLimit]);

    useEffect(() => {
        if (Boolean(fetchedWorkflowRunSuccess)) {
            setWorkflowRuns(fetchedWorkflowRunSuccess?.workFlows);
            setWorkFlowRunPagination({
                current: fetchedWorkflowRunSuccess?.pagination?.current,
                number_of_pages: fetchedWorkflowRunSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedWorkflowRunSuccess]);

    useEffect(() => {
        if (Boolean(togglePausePlayWorkflowRunSuccess)) {
            setRunAction("");
            setRunId("");
            dispatch(workFlowActions.resetTogglePausePlayWorkflowRunSuccess());
        }
    }, [togglePausePlayWorkflowRunSuccess]);

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onWorkFlowRunTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onWorkFlowRunTableScroll);
        };
    }, [dashboardContent, onWorkFlowRunTableScroll]);

    return (
        <PageFrame
            containerClassName={"!h-[calc(100vh-108px)]"}
            isLoading={fetchingWorkflowRuns && !loadMoreRuns}
        >
            <div className="mx-7 h-full max-lg:mx-1">
                <div className="mb-[35px] ">
                    <BreadCrumb
                        parentCrumb={"Workflow"}
                        parentUrl={"/dashboard/workflow"}
                        childrenCrumb={[
                            {
                                title: `${truncate(
                                    workflowRuns?.[0]?.name ||
                                        fetchedWorkflowRunSuccess?.workflow?.name,
                                    {
                                        length: 25,
                                    }
                                )}`,
                                path: `/dashboard/workflow/run/${params?.workflowId}`,
                            },
                        ]}
                        size="large"
                    />
                </div>

                {workflowRuns && workflowRuns?.length > 0 ? (
                    <div className="h-[92%]">
                        <div className="mt-10 h-full">
                            {/* <div className="flex items-center    justify-between mb-[27px]">
                                <div className="flex items-center gap-3">
                                    <Dropdown
                                        value={""}
                                        dropdown={
                                            <div className="flex items-center cursor-pointer gap-2 py-1.5 px-3 bg-white rounded shadow-boxShadow-2 border border-n-20">
                                                <i className="ri-filter-3-fill"></i>
                                                <p className="text-[13px] font-rocGroteskMedium">
                                                    Filter
                                                </p>
                                            </div>
                                        }
                                        dropdownContainerClasses={
                                            " !py-0 top-[49px] shadow-dropdownShadow rounded !border-0"
                                        }
                                        dropdownClassName={"!max-h-fit"}
                                        name={"quickAction"}
                                        dropdownOptions={[
                                            {
                                                customChild: (
                                                    <div className="w-[318px]  relative  bg-white p-4 shadow-boxShadow-2 rounded border border-n-20">
                                                        <div className="flex relative flex-col h-fit gap-2">
                                                            <SelectInput
                                                                value={""}
                                                                name="status"
                                                                placeholder={"Status"}
                                                                // handleChange={(name, value) =>
                                                                //     handleChange({ target: { name, value } })
                                                                // }
                                                                isRequired={false}
                                                                dropdownOptions={[
                                                                    {
                                                                        label: "Accepted",
                                                                        value: "accepted",
                                                                    },
                                                                    {
                                                                        label: "Rejected",
                                                                        value: "rejected",
                                                                    },
                                                                    {
                                                                        label: "Pending",
                                                                        value: "pending",
                                                                    },
                                                                ].map((item) => ({
                                                                    label: item.label,
                                                                    value: item.value,
                                                                }))}
                                                            />
                                                            <TextInput
                                                                name={"amount"}
                                                                type={"text"}
                                                                // value={""}
                                                                placeholder={"Amount"}
                                                                // onChange={handleChange}
                                                                required={false}
                                                            />
                                                            <TextInput
                                                                name={"vendor"}
                                                                type={"text"}
                                                                // value={""}
                                                                placeholder={"Vendor"}
                                                                // onChange={handleChange}
                                                                required={false}
                                                            />
                                                        </div>
                                                    </div>
                                                ),
                                            },
                                        ]}
                                    />
                                    <Button
                                        icon={<i className="ri-arrow-right-up-line text-gm-50"></i>}
                                        btnText={"Export Workflow"}
                                        type={"button"}
                                        btnClassname="!py-2  !border-n-20 border !text-gm-50 !bg-white !px-2"
                                        onClick={() => setWorkFlow(true)}
                                    />
                                </div>
                                
                            </div> */}
                            <div className="h-[92%]">
                                <div className="max-lg:hidden">
                                    <CustomTable
                                        tableBody={tableBody}
                                        tableHeader={tableHeader}
                                        isScrollable={false}
                                        isCellBordered={false}
                                        isCellSelectable={false}
                                        isAllSelectable={false}
                                        bodyItemClass="hover:border-slate-100 hover:bg-slate-50 "
                                        headerContainerClass="!bg-slate-50 !border-slate-100 "
                                        headerItemClass="font-rocGroteskMedium "
                                    />
                                </div>
                                <div className="hidden max-lg:block">
                                    <CustomMobileTable data={mobileTableBody} />
                                </div>
                                {fetchingWorkflowRuns && loadMoreRuns && (
                                    <div className="flex justify-center my-4">
                                        <Loader color="gm-25" size={4} />
                                    </div>
                                )}
                                {workFlowRunPagination?.current ===
                                    workFlowRunPagination?.number_of_pages && (
                                    <div className="flex my-4 justify-center text-sm font-rocGroteskMedium">
                                        End of list
                                    </div>
                                )}
                            </div>
                            {showActivity && (
                                <SideDrawer
                                    title={"Workflow for the Procurement of 300 AlienWare Laptops"}
                                    onClick={() => setShowActivity(false)}
                                />
                            )}
                            <ModalContainer
                                open={runAction === "play"}
                                showCloseIcon={false}
                                closeModal={() => setRunAction("")}
                                tailwindClassName="w-[36%] max-lg:w-1/2 max-sm:w-[95%]"
                            >
                                <div className="bg-white flex flex-col rounded-[20px] ">
                                    <div
                                        onClick={() => setRunAction("")}
                                        className="cursor-pointer mt-5 px-6 flex justify-end "
                                    >
                                        <i className="ri-close-fill text-gm-45 text-[30px]"></i>
                                    </div>
                                    <div className="px-14 my-7 pb-6 max-sm:px-5">
                                        <div>
                                            <h6 className="text-center font-rocGroteskMedium text-[16px]">
                                                Are you sure you want to play{" "}
                                                <span className="font-rocGroteskBold">
                                                    WR {selectedRun?.taId}{" "}
                                                </span>{" "}
                                                You can always pause it later.
                                            </h6>
                                        </div>
                                        <div className="mt-5 space-y-4">
                                            <Button
                                                btnText={"Yes, play workflow"}
                                                type={"button"}
                                                btnClassname="!py-3  !px-4"
                                                onClick={() => onPlay()}
                                                isLoading={togglingPausePlayWorkflowRun}
                                                disabled={togglingPausePlayWorkflowRun}
                                            />
                                            <Button
                                                btnText={"No, close"}
                                                type={"button"}
                                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                                onClick={() => setRunAction("")}
                                                disabled={togglingPausePlayWorkflowRun}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </ModalContainer>
                            <ModalContainer
                                open={runAction === "pause"}
                                showCloseIcon={false}
                                closeModal={() => setRunAction("")}
                                tailwindClassName="w-[36%] max-lg:w-1/2 max-sm:w-[95%]"
                            >
                                <div className="bg-white flex flex-col rounded-[20px]  ">
                                    <div
                                        onClick={() => setRunAction("")}
                                        className="cursor-pointer mt-5 px-6 flex justify-end "
                                    >
                                        <i className="ri-close-fill text-gm-45 text-[30px]"></i>
                                    </div>
                                    <div className="px-14 mt-7 pb-6 max-sm:px-5">
                                        <div>
                                            <h6 className="text-center font-rocGroteskMedium text-[16px]">
                                                Are you sure you want to pause the{" "}
                                                <span className="font-rocGroteskBold">
                                                    WR {selectedRun?.taId}{" "}
                                                </span>{" "}
                                                The workflow will not keep running until you play
                                                it.
                                            </h6>
                                        </div>
                                        <div className="mt-5 space-y-4">
                                            <Button
                                                btnText={"Yes, pause workflow"}
                                                type={"button"}
                                                btnClassname="!py-3 !bg-[#FFEFEC] border border-r-50 !text-dark-gray-2 !px-4"
                                                onClick={() => onPause()}
                                                isLoading={togglingPausePlayWorkflowRun}
                                                disabled={togglingPausePlayWorkflowRun}
                                            />
                                            <Button
                                                btnText={"No, close"}
                                                type={"button"}
                                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                                onClick={() => setRunAction("")}
                                                disabled={togglingPausePlayWorkflowRun}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </ModalContainer>
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-center text-center">
                        <div className=" w-[444px] my-[10%] space-y-6 items-center justify-center flex flex-col">
                            <div>
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1693990938/Tradeally_2.0/emptyWorkflowImg_lzqqx7.svg"
                                    alt=""
                                />
                            </div>
                            <p className="text-slate-700 font-rocGroteskMedium text-[18px]">
                                No workflow run to show yet. Your workflow runs will appear here
                                when available
                            </p>
                        </div>
                    </div>
                )}

                <ModalContainer
                    open={workFlow}
                    showCloseIcon={false}
                    closeModal={() => setWorkFlow(false)}
                    tailwindClassName="w-[55%]  max-sm:w-[100%]"
                >
                    <div className="bg-white flex flex-col h-[445px] rounded-[20px] ">
                        <div
                            onClick={() => setWorkFlow(false)}
                            className="cursor-pointer mt-5 px-6 flex justify-end "
                        >
                            <i className="ri-close-fill text-gm-45 text-[30px]"></i>
                        </div>

                        <div className="px-14 flex flex-col space-y-10 justify-center items-center">
                            <div className="text-center font-rocGroteskMedium space-y-4">
                                <p className="text-slate-500 text-[14px] ">Step 1</p>
                                <p className="text-[22px] text-gm-50 ">
                                    How do you want to automate your new workflow?
                                </p>
                            </div>
                            <CreateRfqMethods
                                optionsData={optionsData}
                                selectedOption={selectedOption}
                            />
                        </div>
                    </div>
                </ModalContainer>

                <ModalContainer
                    open={selectedOption === "AI"}
                    showCloseIcon={false}
                    closeModal={() => setSelectedOption("")}
                    tailwindClassName="w-[40%] mt-[-10%] max-sm:w-[100%]"
                >
                    <div className="bg-white flex flex-col rounded-[20px] min-h-[568px] w-[572px] ">
                        <div
                            onClick={() => setSelectedOption("")}
                            className="cursor-pointer mt-3 px-6 flex justify-end "
                        >
                            <i className="ri-close-fill text-gm-45 text-[30px]"></i>
                        </div>
                        <div className="px-14 ">
                            <div className="text-center font-rocGroteskMedium space-y-4">
                                <p className="text-slate-500 text-[14px] ">Step 2</p>
                                <p className="text-[22px] text-gm-50 ">
                                    Give the SynthAlly AI Co-pilot a prompt to work with
                                </p>
                            </div>
                            <div className="mt-10">
                                <TextInput
                                    value={workFlowName}
                                    name={"workFlowName"}
                                    type={"text"}
                                    onChange={(e) => setWorkFlowName(e.target.value)}
                                    placeholder={"Enter workflow name"}
                                    required={true}
                                    containerClassname="mb-3"
                                />
                                <TextareaInput
                                    value={prompt}
                                    name={"prompt"}
                                    rows={6}
                                    onChange={(e) => setPrompt(e.target.value)}
                                    placeholder={"Enter prompt"}
                                    required={true}
                                    containerClassname="mb-3"
                                />
                            </div>
                            <div className="mt-7 space-y-4">
                                <Button
                                    btnText={"Create Workflow"}
                                    type={"button"}
                                    btnClassname="!py-3 !px-4"
                                    onClick={() => setSelectedOption("")}
                                />
                                <Button
                                    btnText={"Back"}
                                    type={"button"}
                                    btnClassname="!py-3 !bg-transparent  !text-dark-gray-2 !px-4"
                                    onClick={() => setSelectedOption("")}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
                <ModalContainer
                    open={selectedOption === "manual"}
                    showCloseIcon={false}
                    closeModal={() => setSelectedOption("")}
                    tailwindClassName="w-[40%] mt-[-10%] max-sm:w-[100%]"
                >
                    <div className="bg-white flex flex-col rounded-[20px] min-h-[568px] w-[572px] ">
                        <div
                            onClick={() => setSelectedOption("")}
                            className="cursor-pointer mt-3 px-6 flex justify-end "
                        >
                            <i className="ri-close-fill text-gm-45 text-[30px]"></i>
                        </div>
                        <div className="px-14 ">
                            <div className="text-center font-rocGroteskMedium space-y-4">
                                <p className="text-slate-500 text-[14px] ">Step 2</p>
                                <p className="text-[22px] text-gm-50 ">
                                    Enter basic details about your custom workflow
                                </p>
                            </div>
                            <div className="mt-10">
                                <TextInput
                                    value={workFlowName}
                                    name={"workFlowName"}
                                    type={"text"}
                                    onChange={(e) => setWorkFlowName(e.target.value)}
                                    placeholder={"Enter workflow name"}
                                    required={true}
                                    containerClassname="mb-3"
                                />
                                <TextInput
                                    value={customerName}
                                    name={"customerName"}
                                    type={"text"}
                                    onChange={(e) => setCustomerName(e.target.value)}
                                    placeholder={"Enter customer’s name"}
                                    required={true}
                                    containerClassname="mb-3"
                                />
                                <TextareaInput
                                    value={projectDescription}
                                    name={"projectDescription"}
                                    rows={6}
                                    onChange={(e) => setProjectDescription(e.target.value)}
                                    placeholder={"Enter project description"}
                                    required={true}
                                    containerClassname="mb-3"
                                />
                            </div>
                            <div className="mt-7 space-y-4">
                                <Button
                                    btnText={"Create Workflow"}
                                    type={"button"}
                                    btnClassname="!py-3 !px-4"
                                    onClick={() => setSelectedOption("")}
                                />
                                <Button
                                    btnText={"Back"}
                                    type={"button"}
                                    btnClassname="!py-3 !bg-transparent  !text-dark-gray-2 !px-4"
                                    onClick={() => setSelectedOption("")}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
                <ModalContainer
                    open={selectedOption === "document"}
                    showCloseIcon={false}
                    closeModal={() => setSelectedOption("")}
                    tailwindClassName="w-[40%] mt-[-10%] max-sm:w-[100%]"
                >
                    <div className="bg-white flex flex-col rounded-[20px] min-h-[568px] w-[572px] ">
                        <div
                            onClick={() => setSelectedOption("")}
                            className="cursor-pointer mt-3 px-6 flex justify-end "
                        >
                            <i className="ri-close-fill text-gm-45 text-[30px]"></i>
                        </div>
                        <div className="px-14 ">
                            <div className="text-center font-rocGroteskMedium space-y-4">
                                <p className="text-slate-500 text-[14px] ">Step 2</p>
                                <p className="text-[22px] text-gm-50 ">
                                    Enter basic details about your custom workflow
                                </p>
                            </div>
                            <div className="mt-10">
                                <TextInput
                                    value={workFlowName}
                                    name={"workFlowName"}
                                    type={"text"}
                                    onChange={(e) => setWorkFlowName(e.target.value)}
                                    placeholder={"Enter workflow name"}
                                    required={true}
                                    containerClassname="mb-3"
                                />
                                <TextInput
                                    value={customerName}
                                    name={"customerName"}
                                    type={"text"}
                                    onChange={(e) => setCustomerName(e.target.value)}
                                    placeholder={"Enter customer’s name"}
                                    required={true}
                                    containerClassname="mb-3"
                                />
                                <TextareaInput
                                    value={projectDescription}
                                    name={"projectDescription"}
                                    rows={6}
                                    onChange={(e) => setProjectDescription(e.target.value)}
                                    placeholder={"Enter project description"}
                                    required={true}
                                    containerClassname="mb-3"
                                />
                            </div>
                            <div className="mt-7 space-y-4">
                                <Button
                                    btnText={"Create Workflow"}
                                    type={"button"}
                                    btnClassname="!py-3 !px-4"
                                    onClick={() => setSelectedOption("")}
                                />
                                <Button
                                    btnText={"Back"}
                                    type={"button"}
                                    btnClassname="!py-3 !bg-transparent  !text-dark-gray-2 !px-4"
                                    onClick={() => setSelectedOption("")}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            </div>
        </PageFrame>
    );
};

export default WorkFlowRun;
