import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const orderService = {
    getRfqs,
    getOrders,
    getSingleRfq,
    getSingleOrder,
    getQuotes,
    getSingleQuotes,
    acceptQuote,
    updateOrder,
    updateRfq,
    sendRfqs,
    deleteRfq,
    createPurchaseOrder,
    updatePurchaseOrder,
    getSinglePurchaseOrder,
    getPurchaseOrder,
    sendPurchaseOrder,
    getApprovedResponses,
    getSingleQuoteResponse,
    getPONoPaginate,
    approveRejectPO,
};

async function getRfqs(
    rfqLimit?: number | string,
    search?: string,
    vendor?: string,
    orderId?: string,
    amount?: string | number
) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        search: search as string,
        vendor: vendor as string,
        taId: orderId as string,
        amount: amount as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/order/rfqs?limit=${rfqLimit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleRfq(rfqId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/single?rfqId=${rfqId}`,
        requestOptions
    );
    return res;
}

async function getOrders(orderLimit: string | number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/order?limit=${orderLimit}${
            Boolean(search) ? "&search=" + search : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleOrder(orderId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/single/order?orderId=${orderId}`,
        requestOptions
    );
    return res;
}

async function updateOrder(
    orderId: string,
    data: Record<string, any> | Array<Record<string, any>>
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/update/order/${orderId}`,
        requestOptions
    );
    return res;
}

async function updateRfq(rfqId: string, data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/update/rfq/${rfqId}`,
        requestOptions
    );
    return res;
}

async function getQuotes(
    quoteLimit?: string | number,
    search?: string,
    vendor?: string,
    quoteId?: string,
    amount?: string | number,
    status?: string,

) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        search: search as string,
        vendor: vendor as string,
        taId: quoteId as string,
        amount: amount as string,
        status: status as string,

    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/quotes/list?limit=${quoteLimit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleQuotes(quoteId: string) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        quoteId,
    });
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/quotes/rfqs?${urlParams?.toString()}`,
        requestOptions
    );
    return res;
}

async function getApprovedResponses(limit?: string | number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        search: search as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/approved/quoteResponse?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}



async function sendRfqs(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/create/quote/rfqs`,
        requestOptions
    );
    return res;
}

async function acceptQuote(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/quote/acceptance`,
        requestOptions
    );

    return res;
}
async function deleteRfq(rfqId: string) {
    const requestOptions = {
        method: "DELETE",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/delete/${rfqId}`,
        requestOptions
    );
    return res;
}

async function createPurchaseOrder(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(`${config.API_URL}/api/v1/ldb/po`, requestOptions);

    return res;
}

async function updatePurchaseOrder(
    poId: string,
    data: Record<string, any> | Array<Record<string, any>>
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/po/update/${poId}`,
        requestOptions
    );
    return res;
}
async function getPurchaseOrder(
    poLimit?: string | number,
    search?: string,
    taId?: string,
    approvalStatus?: string
) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        search: search as string,
        taId: taId as string,
        approvalStatus: approvalStatus as string,
    });
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/po/list?limit=${poLimit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSinglePurchaseOrder(poId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/po/single/purchaseOrder/${poId}`,
        requestOptions
    );
    return res;
}

async function sendPurchaseOrder(
    poId: string,
    data: Record<string, any> | Array<Record<string, any>>
) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/po/send/purchase-order/${poId}`,
        requestOptions
    );

    return res;
}

async function getSingleQuoteResponse(quoteId: string) {
    const requestOptions = {
        method: "GET",
    };
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/single/quoteResponse/${quoteId}`,
        requestOptions
    );
    return res;
}

async function getPONoPaginate(status) {
    const requestOptions = {
        method: "GET",
    };
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/po/status-list?status=${status}`,
        requestOptions
    );
    return res;
}

async function approveRejectPO(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/po/approve`,
        requestOptions
    );

    return res;
}
