import VendorAvatar from "components/common/VendorAvatar";
import Button from "components/common/Button";
import React, { useEffect, useRef, useState } from "react";
import { displayFileIcon } from "helpers";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import { isFileSizeLessThanOrEqualTo } from "helpers/compareFileSizeInMb";
import { firebaseService } from "services/firebaseService";
import useGetUserProfile from "hooks/useGetUserProfile";
import { convertBytes } from "helpers/displayFileSize";
import Loader from "components/common/Loader";
import { getEditPermissionList } from "helpers/getEditPermissionList";

const Overview = () => {
    const [profile] = useGetUserProfile();
    const [inventoryList, setInventory] = useState<any>([]);
    const [uploading, setUploading] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const [isPerishable, setIsPerishable] = React.useState(false);

    const { id } = useParams();
    const { fetchingSingleInventory, fetchedSingleInventorySuccess } = useAppSelector(
        (state) => state.inventory
    );

    const handleUpload = (fileName: string, file: File) => {
        if (fileName && file) {
            setUploading(true);
            firebaseService.uploadFile(
                `synthally/${process.env.REACT_APP_STAGE ?? "staging"}/inventory/${
                    profile?.profile?.businessName
                }/${file?.name}`,
                file,
                (url) => {
                    const body = {
                        attachments: [
                            ...inventoryList?.attachments,
                            {
                                filename: fileName,
                                path: url,
                                documentType: "Bill of Lajding",
                            },
                        ],
                    };
                    dispatch(inventoryActions.updateSingleProduct(body, id));

                    setUploading(false);
                }
            );
        }
    };

    useEffect(() => {
        if (!Boolean(fetchedSingleInventorySuccess && id)) {
            dispatch(inventoryActions.fetchSingleInventory(id));
        }
    }, [id, fetchedSingleInventorySuccess]);

    useEffect(() => {
        if (Boolean(fetchedSingleInventorySuccess)) {
            setInventory(fetchedSingleInventorySuccess?.inventory);
        }
    }, [fetchedSingleInventorySuccess]);

    useEffect(() => {
        setIsPerishable(inventoryList?.isProductPerishable);
    }, [inventoryList]);

    const onPerishable = (isPerishable) => {
        console.log(isPerishable);
        setIsPerishable(isPerishable);

        dispatch(inventoryActions.updateSingleProduct({ isProductPerishable: isPerishable }, id));
    };

    return (
        <div>
            <div className="w-full">
                <p className="text-xs font-rocGroteskMedium text-slate-500 mb-1">Description</p>
                <p className="text-sm font-rocGroteskMedium text-gm-50">
                    {inventoryList?.productDescription}
                </p>
            </div>
            <div className="mt-10">
                <p className="text-xs font-rocGroteskMedium text-slate-500 mb-1">
                    Created on: 24 Dec 2024 by
                </p>
                <div className="flex items-center gap-2">
                    <VendorAvatar
                        size={32}
                        name={inventoryList?.creator?.fullName}
                        imageSrc={inventoryList?.creator?.profile?.avatar}
                    />
                    <div>
                        <p className="text-slate-700 font-rocGroteskMedium text-sm">
                            {inventoryList?.creator?.fullName}
                        </p>
                        <p className="text-g-50 font-rocGroteskMedium text-xs">
                            {" "}
                            {inventoryList?.ldb?.role}
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-10">
                <p className="text-base font-rocGroteskMedium text-black mb-1">
                    Is this a perishable goods?
                </p>
                <div className="flex items-center mt-4">
                    <div
                        className="mr-5 cursor-pointer flex items-center"
                        onClick={() => onPerishable(true)}
                    >
                        {isPerishable ? (
                            <i
                                className={`ri-radio-button-fill text-xl ${
                                    isPerishable && "text-y-10"
                                }`}
                            ></i>
                        ) : (
                            <span className={`material-icons text-xl text-n-50`}>
                                radio_button_unchecked
                            </span>
                        )}
                        <span className="text-base font-rocGroteskMedium ml-[5px]">Yes</span>
                    </div>

                    <div
                        className="cursor-pointer flex items-center"
                        onClick={() => onPerishable(false)}
                    >
                        {!isPerishable ? (
                            <i
                                className={`ri-radio-button-fill text-xl ${
                                    !isPerishable && "text-y-10"
                                }`}
                            ></i>
                        ) : (
                            <span className={`material-icons text-xl text-n-50`}>
                                radio_button_unchecked
                            </span>
                        )}
                        <span className="text-base font-rocGroteskMedium ml-[5px] translate-y-[1px]">
                            No
                        </span>
                    </div>
                </div>
            </div>
            {/* <div className="mt-10 w-full">
                <p className="text-base mb-2 font-rocGroteskMedium text-black">
                    Product expiration date
                </p>
                <div className="text-sm py-4 border-b border-slate-100 font-rocGroteskMedium text-gm-50 flex justify-between">
                    <p>Expiration Time</p>
                    <p>120 Days</p>
                </div>
                <div className="text-sm py-4 border-b border-slate-100 font-rocGroteskMedium text-gm-50 flex justify-between">
                    <p>Best Before Time</p>
                    <p>120 Days</p>
                </div>
                <div className="text-sm py-4 border-b border-slate-100 font-rocGroteskMedium text-gm-50 flex justify-between">
                    <p>Removal Time</p>
                    <p>120 Days</p>
                </div>
                <div className="text-sm py-4 border-slate-100 font-rocGroteskMedium text-gm-50 flex justify-between">
                    <p>Alert Time</p>
                    <p>120 Days</p>
                </div>
            </div> */}

            <div className="mt-10 max-w-[80%]">
                <p className="text-base mb-6 font-rocGroteskMedium text-black">Attachments</p>
                <div className="mt-4">
                    {inventoryList?.attachments?.map((doc, idx) => (
                        <div
                            key={idx}
                            className="flex items-center justify-between p-3 rounded-md border border-slate-200 mb-4"
                        >
                            <div className="flex items-center gap-2">
                                {displayFileIcon("pdf", false)}
                                <div className="font-rocGroteskMedium">
                                    <p className="text-sm">{doc?.filename}</p>
                                    {/* <p className="text-xs text-slate-500"> {convertBytes(doc?.size)}</p> */}
                                </div>
                            </div>
                            <div className="w-fit">
                                <Link to={doc?.path} target="_blank">
                                    <Button btnClassname="!bg-n-20 !py-[6px]" btnText="View file" />
                                </Link>
                            </div>
                        </div>
                    ))}
                    {uploading ? (
                        <Loader />
                    ) : (
                        <div className="py-3 px-6 relative rounded border border-dashed border-slate-200">
                            <div
                                className="flex items-center  w-fit gap-4 cursor-pointer"
                                onClick={() => {
                                    if (
                                        !getEditPermissionList("inventory")?.some((permission) =>
                                            profile?.permission?.includes(permission)
                                        )
                                    ) {
                                        toast.custom((t) => (
                                            <CustomToast
                                                t={t}
                                                message="You don't have access to this feature"
                                                type="error"
                                            />
                                        ));
                                    } else {
                                        document.getElementById("fileInput")?.click();
                                    }
                                }}
                            >
                                <i className="ri-file-add-line text-2xl text-carribean-green"></i>
                                <p className="text-sm underline text-carribean-green font-rocGroteskMedium">
                                    Click to upload document
                                </p>
                                <div className=" !w-[230px] cursor-pointer  absolute">
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className={`z-[-1] absolute   opacity-0`}
                                        accept="image/png, image/jpeg"
                                        multiple
                                        onChange={(evt) => {
                                            handleUpload(
                                                evt.target.files[0].name,
                                                evt.target.files[0]
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default Overview;
