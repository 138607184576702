import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import SelectInput from "components/common/InputField/SelectInput";
import TabsContainer from "components/common/TabsContainer";
import PageFrame from "components/layout/PageFrame";
import Overview from "components/views/Ldb/Dashboard/Inventory/ProductDetailsPage/Oveview";
import ProductVendors from "components/views/Ldb/Dashboard/Inventory/ProductDetailsPage/ProductVendors";
import PurchaseOrderInventory from "components/views/Ldb/Dashboard/Inventory/ProductDetailsPage/PurchaseOrder";
import WareHouse from "components/views/Ldb/Dashboard/Inventory/ProductDetailsPage/WareHouse";
import History from "components/views/Ldb/Dashboard/Inventory/ProductDetailsPage/History";
import Order from "components/views/Ldb/Dashboard/Inventory/ProductDetailsPage/Order";
import { truncate } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ModalContainer from "components/common/ModalContainer";
import TextInput from "components/common/InputField/TextInput";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import VendorAvatar from "components/common/VendorAvatar";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import { firebaseService } from "services/firebaseService";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import { isFileSizeLessThanOrEqualTo } from "helpers/compareFileSizeInMb";
import useGetUserProfile from "hooks/useGetUserProfile";
import Loader from "components/common/Loader";
import { getEditPermissionList } from "helpers/getEditPermissionList";
import NotificationAlert from "components/common/NotificationAlert";

type ProductDataType = {
    inventoryCategory?: string;
    isProductPerishable?: boolean;
    productImages?: string[];
};
const ProductDetails = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [profile] = useGetUserProfile();
    const [productImage, setProductImage] = useState<string>();
    const [productImages, setProductImages] = useState<string[]>([]);
    const [showEditPrice, setShowEditPrice] = useState<boolean>(false);
    const [deletingImage, setDeletingImage] = useState<boolean>(false);
    const [deleteImageModal, setDeleteImageModal] = useState<boolean>(false);
    const [uploadImage, setUploadImage] = useState<boolean>(false);
    const [stopLoading, setStopLoading] = useState<boolean>(false);
    const [productId, setProductId] = useState<number>(0);
    const [singleInventory, setSingleInventory] = useState<any>([]);
    const [uploadingFiles, setUploadingFiles] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<number>(0);
    const [unitPrice, setUnitPrice] = useState({
        currency: "",
        sellingPrice: "",
    });
    const { id } = useParams();
    const [productDataArr, setProductDataArr] = useState<ProductDataType[]>([]);
    const [inventoryCategory, setInventoryCategory] = useState<string>();
    const {
        fetchingSingleInventory,
        fetchedSingleInventorySuccess,
        fetchedSingleInventoryFailure,
        updatedSingleProductSuccess,
        updatingSingleProduct,
    } = useAppSelector((state) => state.inventory);
    const prevProductImagesLength = useRef(productImages?.length);

    useEffect(() => {
        if (id) {
            dispatch(inventoryActions.fetchSingleInventory(id));
        }
    }, [id]);

    useEffect(() => {
        if (Boolean(fetchedSingleInventorySuccess)) {
            setSingleInventory(fetchedSingleInventorySuccess?.inventory);
            setInventoryCategory(fetchedSingleInventorySuccess?.inventory?.inventoryCategory);
            setUnitPrice({
                currency: fetchedSingleInventorySuccess?.inventory?.salesPrice?.currency,
                sellingPrice: fetchedSingleInventorySuccess?.inventory?.salesPrice?.amount,
            });
        }
    }, [fetchedSingleInventorySuccess]);

    const handleCostChange = (evt) => {
        const { name, value } = evt.target;
        setUnitPrice((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };
    const onUpdateSellingPrice = () => {
        const body = {
            salesPrice: {
                amount: unitPrice?.sellingPrice,
                currency: unitPrice?.currency,
            },
        };
        dispatch(inventoryActions.updateSingleProduct(body, id));
    };

    const highestMin = singleInventory?.productStockSettings?.reduce(
        (acc, current) => (current.minimumStockLevel > acc.minimumStockLevel ? current : acc),
        singleInventory?.productStockSettings[0]
    );

    // Find the object with the highest max value
    const highestMax = singleInventory?.productStockSettings?.reduce(
        (acc, current) => (current.maximumStockLevel > acc.maximumStockLevel ? current : acc),
        singleInventory?.productStockSettings[0]
    );

    function handleMultipleProductChange(name: string, value: any, idx: number) {
        const newArr = [...productDataArr];
        newArr[idx][name] = value;
        setProductDataArr(newArr);
    }

    useEffect(() => {
        if (Boolean(updatedSingleProductSuccess)) {
            setStopLoading(true);
            dispatch(inventoryActions.fetchSingleInventory(id));
            dispatch(inventoryActions.resetUpdateSingleProductSuccess());
            setShowEditPrice(false);
            setDeletingImage(false);
            setUploadImage(false);
            setDeleteImageModal(false);
        }
    }, [updatedSingleProductSuccess]);

    useEffect(() => {
        if (singleInventory?.productImageDetails?.productImages) {
            setProductImages(singleInventory?.productImageDetails?.productImages);
        }
    }, [singleInventory?.productImageDetails?.productImages]);

    const handleUpload = (files: File[]) => {
        if (files.length > 0) {
            const filePaths = files?.map((file) => {
                return `synthally/${process.env.REACT_APP_STAGE ?? "staging"}/inventory/${
                    profile?.profile?.businessName
                }/${file?.name}`;
            });
            setUploadingFiles(true);
            firebaseService.uploadMultipleFiles(files, filePaths, (urlArr) => {
                setUploadImage(true);
                setProductImages((prev) => [...prev, ...urlArr]);
                setUploadingFiles(false);
                setProductId(productImages?.length);
            });
        }
    };

    useEffect(() => {
        if (uploadImage) {
            if (productImages?.length > prevProductImagesLength?.current) {
                const body = {
                    productImageDetails: {
                        productImages: productImages,
                        productAvatar: productImages?.[0],
                    },
                };
                dispatch(inventoryActions.updateSingleProduct(body, id));
                prevProductImagesLength.current = productImages?.length;
            }
        }
    }, [productImages, uploadImage, productId, id, dispatch]);

    const handleInputUploadAction = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = Object.values(e.target.files)?.slice(0, 5 - productImages.length);
        const filesToUpload = [];
        const namesOfFilesNotToUpload = [];

        for (const file of files) {
            // const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to megabytes
            const isUploadable = isFileSizeLessThanOrEqualTo(file, 1); // Convert bytes to megabytes

            if (isUploadable) {
                filesToUpload.push(file);
            } else {
                namesOfFilesNotToUpload.push(file?.name);
            }
        }
        if (namesOfFilesNotToUpload.length > 0) {
            toast.custom((t) => (
                <CustomToast
                    t={t}
                    message={`The following file(s) are too large: ${namesOfFilesNotToUpload.join(
                        ", "
                    )}`}
                    type="error"
                />
            ));
        } else {
            handleUpload(filesToUpload);
        }
    };

    const handleRemoveImage = () => {
        const imagePath = decodeURIComponent(
            productImages[productId]?.split("/o/")?.[1]?.split("?alt")?.[0]
        );
        setDeletingImage(true);

        firebaseService.removeFile(imagePath, () => {
            const remainingImages = productImages?.filter((img) => img !== productImage);
            setProductImage(remainingImages[productId - 1] || "");
            const body = {
                productImageDetails: {
                    productImages: remainingImages,
                    productAvatar: remainingImages?.[0],
                },
            };
            setProductImages(remainingImages || []);
            dispatch(inventoryActions.updateSingleProduct(body, id));

            if (productId === 0) {
                setProductId(0);
            } else {
                setProductId(productId - 1);
            }
        });
    };

    useEffect(() => {
        setProductImage(productImages?.[productId]);
    }, [productImages, productId]);

    const handleChange = (name, value) => {
        const body = {
            inventoryCategory: value,
        };
        dispatch(inventoryActions.updateSingleProduct(body, id));
    };

    const tabs = [
        "Product details",
        "Product Vendors",
        "History",
        "Purchase Order",
        "Warehouse",
        "Orders",
    ];

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return <Overview />;

            case 1:
                return <ProductVendors />;
            case 2:
                return <History />;
            case 3:
                return <PurchaseOrderInventory />;

            case 4:
                return <WareHouse />;
            case 5:
                return <Order />;
            default:
                return;
        }
    };
    return (
        <PageFrame containerClassName={""} isLoading={fetchingSingleInventory && !stopLoading}>
            <div className="">
                {fetchedSingleInventoryFailure === "Inventory not found" ? (
                    <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                        <div className="space-y-2  text-center">
                            <i className="ri-archive-2-line text-gm-50 text-[24px]"></i>

                            <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                Inventory not found
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className=" w-full pb-16 bg-white border border-slate-100  rounded ">
                        <div className=" top-[-2px] md:sticky z-[40]">
                            <div className="flex  bg-white items-center justify-between px-8 py-5 border-b border-slate-100">
                                {/* <div className=" top-[-20px] md:sticky md:px-8 py-4 px-[28px] z-[40] bg-[red] w-full border-b border-n-20 flex items-center justify-between"> */}
                                <div className="flex items-center">
                                    <div
                                        onClick={() => navigate("/dashboard/inventory")}
                                        className="cursor-pointer space-x-1 flex items-center justify-center"
                                    >
                                        <i className="ri-arrow-left-line text-sm text-carribean-green"></i>
                                        <p className="text-sm underline text-gm-50 font-rocGroteskMedium">
                                            Back
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center space-x-3">
                                    {/* <div className="border border-n-20 px-3 py-2 rounded">
                        <Dropdown
                            dropdown={
                                <i className="ri-more-2-fill before:content-['\ef76'] text-gm-50"></i>
                            }
                            dropdownClassName={"!w-full mt-4 !mb-10 "}
                            dropdownContainerClasses={`shadow-cardShadow-2   border-0 rounded !max-h-fit`}
                            dropdownItemsClasses={``}
                            name={"faq-dropdown"}
                            dropdownOptions={[
                                {
                                    label: "Transfer stock",
                                    value: "settings",
                                    action: () => {},
                                },
                                {
                                    label: "Mark as inactive",
                                    value: "settings",
                                    action: () => {},
                                },
                                {
                                    label: "Mark items as expired ",
                                    value: "logout",
                                    labelClassName: "!text-[red]",
                                    action: () => {},
                                },
                            ]}
                        />
                    </div> */}
                                    {/* <div>
                        <Button
                            btnText="Reorder"
                            btnClassname=" !bg-transparent !border border-n-20 !text-gm-50"
                        />
                    </div> */}
                                    {/* <div>
                        <Button
                            btnText="Send message"
                            btnClassname=" !bg-transparent !border border-n-20 !text-gm-50"
                        />
                    </div> */}
                                    <div>
                                        <Button
                                            btnText="Edit item"
                                            btnClassname=" !py-2"
                                            onClick={() =>
                                                navigate(`/dashboard/inventory/edit-product/${id}`)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(singleInventory?.productStockDetails?.stockLevel
                            ? singleInventory?.productStockDetails?.stockLevel
                            : 0) < highestMin?.minimumStockLevel && (
                            <div className="w-full">
                                <NotificationAlert
                                    color="text-[#DC2626]"
                                    bgColor="bg-[#FEF2F2]"
                                    text=" Your currently running out of stock for this product."
                                />
                            </div>
                        )}
                        <div className="w-full ">
                            <div className="flex items-center w-full md:px-8 pt-8 px-[28px]">
                                <div className="w-[290px]">
                                    <div className="w-[252px]">
                                        <div className="w-fit">
                                            {productImages?.length > 0 ? (
                                                <div className="relative  w-full border rounded border-slate-200 ">
                                                    <div
                                                        className="left-[220px] absolute justify-end cursor-pointer  mt-2"
                                                        onClick={() => {
                                                            setDeleteImageModal(true);
                                                        }}
                                                    >
                                                        <img
                                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1705060965/Delete_icon_brttke.svg"
                                                            className="w-8 mr-2 bg-cover "
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div>
                                                        <img
                                                            src={productImage}
                                                            alt={singleInventory?.productName}
                                                            className="!rounded-none w-[252px] bg-contain h-[216px]"
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="w-[252px] h-[216px] relative px-6 py-12 flex items-center justify-center rounded border border-dashed bg-slate-50">
                                                    {uploadingFiles ? (
                                                        <Loader size={5} />
                                                    ) : (
                                                        <div className="w-full ">
                                                            <div className="font-rocGroteskMedium flex flex-col justify-center items-center">
                                                                <i className="ri-upload-cloud-2-line text-[40px] text-slate-500"></i>
                                                                <p className="underline text-sm mb-2 text-center">
                                                                    Add product images
                                                                </p>
                                                                <p className="text-tradeally-neutral-200 text-sm text-center">
                                                                    You can add up to 5 images, each
                                                                    not exceeding 5 MB.
                                                                </p>
                                                                <p className="text-tradeally-neutral-200 text-xs mt-8 text-center">
                                                                    file format: JPEG, PNG
                                                                </p>
                                                            </div>
                                                            <input
                                                                type="file"
                                                                id="uploadProductImages"
                                                                className="absolute w-full h-full left-0 top-0 opacity-0 cursor-pointer"
                                                                accept="image/png, image/jpeg"
                                                                multiple
                                                                onChange={(e) => {
                                                                    handleInputUploadAction(e);
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>

                                        <div className="mt-2 w-full flex items-center space-x-2">
                                            {productImages?.map((image, idx) => (
                                                <div
                                                    className="cursor-pointer"
                                                    key={idx}
                                                    onClick={() => {
                                                        setProductImage(image);
                                                        setProductId(idx);
                                                    }}
                                                >
                                                    <img
                                                        src={image}
                                                        alt={`product avatar-${idx + 1}`}
                                                        className={`bg-no-repeat h-[44px] w-[44px]  ${
                                                            productId === idx
                                                                ? "border-[1.5px] border-carribean-green"
                                                                : "border-slate-200 border"
                                                        } rounded  bg-contain`}
                                                    />
                                                </div>
                                            ))}
                                            {productImages?.length < 5 && (
                                                <div className=" ">
                                                    <div
                                                        onClick={() => {
                                                            const elem =
                                                                document.getElementById(
                                                                    "uploadProductImages"
                                                                );
                                                            elem?.click();
                                                        }}
                                                        className="cursor-pointer h-[44px] w-[44px] rounded border border-slate-200 border-dashed flex items-center justify-center"
                                                    >
                                                        <i className="ri-add-fill text-2xl"></i>
                                                    </div>

                                                    <input
                                                        type="file"
                                                        id="uploadProductImages"
                                                        className={`absolute z-[-1]  opacity-0`}
                                                        accept="image/png, image/jpeg"
                                                        multiple
                                                        onChange={(e) => {
                                                            handleInputUploadAction(e);
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="mt-2">
                                            <SelectInput
                                                value={inventoryCategory}
                                                name="inventoryCategory"
                                                placeholder={"Inventory categories"}
                                                handleChange={(name, value) => {
                                                    handleChange(name, value);
                                                }}
                                                isRequired={true}
                                                dropdownOptions={[
                                                    {
                                                        label: "Raw Materials",
                                                        value: "Raw Material",
                                                    },
                                                    {
                                                        label: "Work-in-Progress (WIP)",
                                                        value: "Work-In-Progress (WIP)",
                                                    },
                                                    {
                                                        label: "Finished Goods",
                                                        value: "Finished Goods",
                                                    },
                                                    {
                                                        label: "Maintenance, Repair, and Overall (MRO)",
                                                        value: "Maintenance, Repair and Overall (MRO)",
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[calc(100%-290px)] ">
                                    <div className="w-full  h-fit flex items-start  border-slate-100 justify-between">
                                        <div className="w-full font-rocGroteskMedium ">
                                            <h3 className="text-slate-700 text-2xl ">
                                                {singleInventory?.productName}
                                            </h3>
                                            <div className="text-sm">
                                                <p className="text-slate-500 ">
                                                    {truncate(singleInventory?.productDescription, {
                                                        length: 45,
                                                    })}
                                                </p>
                                                <div className="flex items-center space-x-2 mt-1">
                                                    <p className="text-gm-50 text-sm ">
                                                        Quantity:
                                                        {singleInventory?.productStockDetails
                                                            ?.stockLevel ?? 0}{" "}
                                                    </p>
                                                    <span className="material-icons text-[6px]">
                                                        fiber_manual_record
                                                    </span>
                                                    <p className="text-gm-50 text-sm">
                                                        Unit of measure:{" "}
                                                        {
                                                            singleInventory?.productStockDetails
                                                                ?.unitOfMeasurement
                                                        }
                                                    </p>
                                                    <span className="material-icons text-[6px]">
                                                        fiber_manual_record
                                                    </span>
                                                    <p className="text-gm-50 text-sm">
                                                        Unit Cost:{" "}
                                                        {getCurrencyFromCurrencyCode(
                                                            singleInventory?.unitCost?.currency
                                                        )}
                                                        {formatMoney()?.format(
                                                            singleInventory?.unitCost?.amount ?? 0
                                                        )}{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="w-full flex items-center justify-end">
                            <Dropdown
                                handleChange={(name, value) => {}}
                                dropdown={
                                    <div className="flex whitespace-nowrap border border-n-20 items-center rounded-[5px] shadow-[0_1px_2px_0_rgba(91,104,113,0.15)] py-2 px-[15px] gap-2">
                                        <i className="ri-map-pin-line"></i>
                                        <div
                                            className={`text-slate-800 font-rocGroteskMedium text-[13px]`}
                                        >
                                            <p>
                                                {truncate("Location: TechHub Warehouse A", {
                                                    length: 23,
                                                })}
                                            </p>
                                        </div>
                                        <i className="ri-arrow-down-s-line"></i>
                                    </div>
                                }
                                dropdownItemsClasses={`!px-0 !py-0 !w-full`}
                                dropdownClassName="!w-full "
                                dropdownContainerClasses={`shadow-cardShadow-4 border  !mb-0 border-slate-100  rounded !max-h-fit
                    `}
                                dropdownOptions={[
                                    ...location?.map((item, idx) => ({
                                        label: (
                                            <p
                                                key={idx}
                                                className=" text-gm-50  font-rocGroteskMedium text-xs flex justify-between
                                       px-4 py-[10px]"
                                            >
                                                {item.name}
                                            </p>
                                        ),
                                        value: item.id,
                                    })),
                                ]}
                            />
                        </div> */}
                                    </div>
                                    <div className="border-b  pt-6 pb-10 flex space-x-4 border-slate-100 w-full">
                                        <div className="font-rocGroteskMedium pb-3 bg-[#ECFDF5] w-[243px] p-4 ">
                                            <p className="text-gm-50 text-sm">Sale price</p>
                                            <h3 className="text-2xl text-[#16A34A] ">
                                                {getCurrencyFromCurrencyCode(
                                                    singleInventory?.salesPrice?.currency
                                                )}
                                                {formatMoney()?.format(
                                                    singleInventory?.salesPrice?.amount ?? 0
                                                )}
                                            </h3>
                                            <div
                                                className="w-fit"
                                                onClick={() => setShowEditPrice(true)}
                                            >
                                                <p className="text-xs text-slate-500 underline cursor-pointer">
                                                    Edit
                                                </p>
                                            </div>
                                        </div>
                                        <div className="font-rocGroteskMedium pb-4 bg-[#EFF6FF] w-[243px] p-4 ">
                                            <p className="text-gm-50 text-sm">Cost price</p>
                                            <h3 className="text-2xl text-[#1D4ED8] ">
                                                {getCurrencyFromCurrencyCode(
                                                    singleInventory?.unitCost?.currency
                                                )}
                                                {formatMoney()?.format(
                                                    singleInventory?.unitCost?.amount ?? 0
                                                )}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="w-full ">
                                        <div className=" border-b w-full">
                                            <div className=" py-4 max-w-[80%] w-full justify-between flex">
                                                <div className="font-rocGroteskMedium pr-10 border-r border-slate-100 ">
                                                    <h3 className="text-3xl text-gm-50 ">
                                                        {singleInventory?.productStockDetails
                                                            ?.stockLevel ?? 0}
                                                    </h3>
                                                    <p className="text-slate-500 text-sm">
                                                        Current stock level
                                                    </p>
                                                </div>
                                                <div className="font-rocGroteskMedium pr-10 border-r border-slate-100 ">
                                                    <h3 className="text-3xl text-gm-50 ">
                                                        {highestMin?.minimumStockLevel ?? 0}
                                                    </h3>
                                                    <p className="text-slate-500 text-sm">
                                                        Minimum stock level
                                                    </p>
                                                </div>
                                                <div className="font-rocGroteskMedium ">
                                                    <h3 className="text-3xl text-gm-50 ">
                                                        {highestMax?.maximumStockLevel ?? 0}
                                                    </h3>
                                                    <p className="text-slate-500 text-sm">
                                                        Maximum stock level
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="md:px-8 pt-8 px-[28px]">
                                <TabsContainer
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    component={displayActiveTab()}
                                    className="!px-0"
                                    itemClassName="!pb-2"
                                    borderLineClase={"!text-slate-100  w-full  mb-4"}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <ModalContainer
                open={showEditPrice}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setShowEditPrice(false);
                }}
            >
                <div className="bg-white py-7  shadow-cardShadow relative rounded-[8px]">
                    <div className="flex mb-4 justify-between px-6">
                        <h1 className="text-xl font-rocGroteskMedium">Edit product cost</h1>
                        <div className="cursor-pointer" onClick={() => setShowEditPrice(false)}>
                            <i className="ri-close-fill text-gm-45 text-xl"></i>
                        </div>
                    </div>
                    <div className="border border-slate-200 bg-slate-50 py-[18px]">
                        <div className="mt-2 flex space-x-3 items-center px-6">
                            <VendorAvatar
                                size={64}
                                imageSrc={productImage}
                                name={singleInventory?.productName}
                                containerClassname="!border-slate-200 !border !rounded"
                            />
                            <div>
                                <p className="text-base text-slate-700 font-rocGroteskMedium">
                                    {singleInventory?.productName}
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-slate-500">
                                    {truncate(singleInventory?.productDescription, {
                                        length: 45,
                                    })}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 space-y-4 pb-14 border-b border-slate-200">
                        <div className="px-6">
                            <p className="text-[#333333] mb-2 text-sm font-rocGroteskMedium">
                                Selling price
                            </p>
                            <div className="w-full grid grid-cols-[115px_calc(100%-119px)] gap-1 ">
                                <SelectInput
                                    value={unitPrice?.currency}
                                    name="currency"
                                    placeholder="Currency"
                                    handleChange={(name, value) => {
                                        handleCostChange({ target: { name, value } });
                                    }}
                                    isRequired={true}
                                    dropdownOptions={[
                                        {
                                            label: "GBP (£)",
                                            value: "GBP",
                                        },
                                        {
                                            label: "USD ($)",
                                            value: "USD",
                                        },
                                        {
                                            label: "EUR (€)",
                                            value: "EUR",
                                        },
                                        {
                                            label: "NGN (₦)",
                                            value: "NGN",
                                        },
                                    ]}
                                />
                                <TextInput
                                    name={"sellingPrice"}
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    value={unitPrice?.sellingPrice}
                                    onChange={handleCostChange}
                                    placeholder={"Selling price"}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    required={true}
                                    pattern={"^[0-9]+$"}
                                    title={"must be digits"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex space-x-2 mt-5 px-6">
                        <div className="w-full">
                            <Button
                                btnText={"Cancel"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                disabled={false}
                                onClick={() => setShowEditPrice(false)}
                            />
                        </div>
                        <div className="w-full">
                            <Button
                                btnClassname={"!py-3 !px-8"}
                                onClick={onUpdateSellingPrice}
                                btnText={"Update price"}
                                type={"button"}
                                isLoading={updatingSingleProduct}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={deleteImageModal}
                showCloseIcon={false}
                tailwindClassName="w-[30%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setDeleteImageModal(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="px-10 pt-3">
                        <div className="flex flex-col items-center text-center">
                            <div className="flex w-full justify-end mr-[-12px]">
                                <i
                                    onClick={() => setDeleteImageModal(false)}
                                    className="ri-close-fill text-2xl cursor-pointe"
                                ></i>
                            </div>

                            <i className="ri-delete-bin-6-line text-[32px]"></i>

                            <p className="text-xl mb-3 text-center mt-6 font-rocGroteskMedium text-g-75">
                                Delete Image?
                            </p>
                            <p className="text-sm text-center font-rocGroteskMedium text-slate-500">
                                Are you sure about deleting this image?
                            </p>
                        </div>

                        <div className="w-full flex items-center gap-2 py-6">
                            <div className="w-full">
                                <Button
                                    btnText={"No, Cancel"}
                                    type={"button"}
                                    btnClassname="!bg-tradeally-neutral-20"
                                    onClick={() => {
                                        setDeleteImageModal(false);
                                    }}
                                />
                            </div>
                            <div className="w-full">
                                <Button
                                    btnText={"Yes, delete"}
                                    type={"button"}
                                    btnClassname="!bg-r-50 !text-white"
                                    isLoading={deletingImage}
                                    onClick={() => {
                                        handleRemoveImage();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default ProductDetails;
