import React, { useCallback, useEffect, useRef, useState } from "react";
import ModalContainer from "components/common/ModalContainer";
import CustomTable from "components/common/CustomTable";
import Checkbox from "components/common/InputField/Checkbox";
import Button from "components/common/Button";
import VendorAvatar from "components/common/VendorAvatar";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions, vendorActions } from "redux/Ldb/actions";
import { formatMoney, getCurrencyFromCurrencyCode, onScroll } from "helpers";
import Loader from "components/common/Loader";

const ProductVendors = () => {
    const limit = 20;
    const [vendorLimit, setVendorLimit] = useState(limit);
    const [addVendorLimit, setAddVendorLimit] = useState(limit);
    const [isScrolled, setIsScrolled] = useState(false);
    const [selectedVendorIds, setSelectedVendorIds] = useState([]);
    const [showAddVendorModal, setShowAddVendorModal] = useState(false);
    const [loadMoreVendor, setLoadMoreVendor] = useState(false);
    const [loadMoreAddVendor, setLoadMoreAddVendor] = useState(true);
    const [total, setTotal] = useState(0);
    const [vendorList, setVendorList] = useState<any>([]);
    const [dashboardContentTwo, setDashboardContentTwo] = useState<any>();
    const [onboardedVendors, setOnboardedVendors] = useState<{ [key: string]: any }[]>([]);
    const [vendorPagination, setVendorPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [addVendorPagination, setAddVendorPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const dashboardContent = document.getElementById("dashboardContent");

    const {
        fetchedSingleInventorySuppliersSuccess,
        fetchingSingleInventorySuppliers,
        addingProductVendor,
        addedProductVendorSuccess
    } = useAppSelector((state) => state.inventory);

    const { fetchedLdbVendorsSuccess, fetchingLdbVendors } = useAppSelector(
        (state) => state.vendor
    );
    useEffect(() => {
        dispatch(vendorActions.getLdbVendors(addVendorLimit, "", "accepted"));
    }, [dispatch, addVendorLimit]);

    useEffect(() => {
        if (Boolean(fetchedLdbVendorsSuccess)) {
            setOnboardedVendors(fetchedLdbVendorsSuccess?.vendors);
            setAddVendorPagination({
                current: fetchedLdbVendorsSuccess?.pagination?.current,
                number_of_pages: fetchedLdbVendorsSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedLdbVendorsSuccess]);
    useEffect(() => {
        dispatch(inventoryActions.fetchSingleInventorySuppliers(id, vendorLimit));
    }, [id, vendorLimit]);

    useEffect(() => {
        if (Boolean(fetchedSingleInventorySuppliersSuccess)) {
            setVendorList(fetchedSingleInventorySuppliersSuccess?.suppliers);
            setVendorPagination({
                current: fetchedSingleInventorySuppliersSuccess?.pagination?.current,
                number_of_pages:
                    fetchedSingleInventorySuppliersSuccess?.pagination?.number_of_pages,
            });
            setTotal(fetchedSingleInventorySuppliersSuccess?.total);
        }
    }, [fetchedSingleInventorySuppliersSuccess]);

    const onVendorScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                vendorPagination?.current as number,
                vendorPagination?.number_of_pages as number,
                () => {
                    setVendorLimit(() => vendorLimit + limit);
                    setLoadMoreVendor(true);
                }
            ),
        [dashboardContent, vendorPagination, vendorLimit]
    );

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onVendorScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onVendorScroll);
        };
    }, [dashboardContent, onVendorScroll]);

    useEffect(() => {
        if (showAddVendorModal) {
            setDashboardContentTwo(document.getElementById("modal-scroll"));
        }
    }, [showAddVendorModal]);

    console.log("===fg", dashboardContentTwo)
    console.log("===addVendorPagination", addVendorPagination)

    const onAddVendorScroll = useCallback(
        () =>
            onScroll(
                dashboardContentTwo,
                addVendorPagination?.current as number,
                addVendorPagination?.number_of_pages as number,
                () => {
                    setAddVendorLimit(() => addVendorLimit + limit);
                    setLoadMoreAddVendor(true);
                }
            ),
        [dashboardContentTwo, addVendorPagination, addVendorLimit]
    );

    useEffect(() => {
        dashboardContentTwo?.addEventListener("scroll", onAddVendorScroll);
        return () => {
            dashboardContentTwo?.removeEventListener("scroll", onAddVendorScroll);
        };
    }, [dashboardContentTwo, onAddVendorScroll]);

    const navigate = useNavigate();

    const tableHeader = [
        { title: "Vendor", widthClass: "w-[30%]" },
        { title: "Email address", widthClass: "w-[35%]" },
        { title: "price", widthClass: "w-[25%]" },
    ];

    const tableBody = vendorList?.map((vendor) => [
        {
            content: (
                <div
                    key={vendor?._id}
                    className={`py-3 pr-3  border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-3">
                        <VendorAvatar
                            name={vendor?.vendorName}
                            imageSrc={vendor?.vendorAvatar}
                            size={32}
                            containerClassname=" !rounded-[6px]"
                        />

                        <span>{vendor?.vendorName}</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {vendor?.vendorEmail}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {getCurrencyFromCurrencyCode(vendor?.cost?.currency)}
                    {formatMoney()?.format(vendor?.cost?.amount ?? 0)}
                </div>
            ),
        },
    ]);

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if ((e.target as any).scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };
    const handleAddVendor = (vendorId) => {
        const isVendorSelected = selectedVendorIds.includes(vendorId);

        if (isVendorSelected) {
            const updatedVendorIds = selectedVendorIds.filter((id) => id !== vendorId);
            setSelectedVendorIds(updatedVendorIds);
        } else {
            setSelectedVendorIds([...selectedVendorIds, vendorId]);
        }
    };
    const addVendor = () => {
        const body = {
            vendors: selectedVendorIds,
            inventoryId: id,
        };
        if (selectedVendorIds?.length > 0) {
            dispatch(inventoryActions.addProductVendor(body));
        }
    };
    
    useEffect(() => {
        if (Boolean(addedProductVendorSuccess)) {
            dispatch(inventoryActions.resetAddProductVendorSuccess());
            dispatch(inventoryActions.fetchSingleInventorySuppliers(id, vendorLimit));
            setShowAddVendorModal(false);
        }
    }, [addedProductVendorSuccess]);

console.log("addedProductVendorSuccess", addedProductVendorSuccess)
    useEffect(() => {
        if (showAddVendorModal) {
            dispatch(inventoryActions.fetchSingleInventorySuppliers(id, total));
        }
    }, [showAddVendorModal, total, dispatch]);

    return (
        <div className="overflow-x-scroll w-full" onScroll={handleScroll}>
            {fetchingSingleInventorySuppliers && vendorList?.length === 0 ? (
                <div>
                    <Loader />
                </div>
            ) : (
                <>
                    {vendorList?.length > 0 ? (
                        <>
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isAllSelectable={false}
                                isScrollable={true}
                                isScrolled={isScrolled}
                                isCellBordered={false}
                                headerContainerClass="!bg-slate-50  !border-slate-100 "
                                bodyItemClass="hover:border-slate-100 hover:bg-slate-50 "
                                headerItemClass="font-rocGroteskMedium !text-slate-700"
                                inventoryHistoryTable
                            />
                            {loadMoreVendor && fetchingSingleInventorySuppliers && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {vendorPagination?.current === vendorPagination?.number_of_pages && (
                                <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="flex items-center justify-center mt-[76px]">
                            <div>
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1704890969/Tradeally_2.0/empty_state_img_a8twwl.svg"
                                    alt="empty state"
                                    className="w-[268px] h-[235px]"
                                />
                                <p className="text-center text-sm text-[#475569] font-rocGroteskMedium mt-5">
                                    You have no vendor
                                </p>
                            </div>
                        </div>
                    )}
                </>
            )}
            <div className="w-fit mt-6">
                <Button
                    btnText="Add vendor"
                    btnClassname="!py-2"
                    icon={<i className="ri-add-line"></i>}
                    onClick={() => setShowAddVendorModal(true)}
                />
            </div>
            {showAddVendorModal && (
                <div>
                    <ModalContainer
                        open={showAddVendorModal}
                        showCloseIcon={false}
                        modalClassName={"py-10 px-10"}
                        tailwindClassName="w-[500px]"
                        closeModal={() => {
                            setShowAddVendorModal(false);
                        }}
                    >
                        <div className="bg-white p-8  shadow-cardShadow relative rounded-[8px]">
                            <div className="flex justify-between">
                                <h1 className="text-xl font-rocGroteskMedium">
                                    Add vendor to product
                                </h1>
                                <div
                                    className="cursor-pointer"
                                    onClick={() => setShowAddVendorModal(false)}
                                >
                                    <i className="ri-close-fill text-gm-45 text-xl"></i>
                                </div>
                            </div>

                            <div className="my-6">
                                <div className="max-h-[300px] overflow-y-auto" id="modal-scroll">
                                    {fetchingLdbVendors && onboardedVendors?.length === 0 ? (
                                        <Loader />
                                    ) : (
                                        <>
                                            {onboardedVendors?.length > 0 ? (
                                                <>
                                                    {onboardedVendors?.map((vendor) => (
                                                        <div
                                                            className="flex justify-between py-2"
                                                            key={vendor?.vendor?._id}
                                                        >
                                                            <div className="flex space-x-4">
                                                                <VendorAvatar
                                                                    imageSrc={
                                                                        vendor?.vendor?.profile
                                                                            ?.avatar
                                                                    }
                                                                    name={
                                                                        vendor?.vendor?.profile
                                                                            ?.businessName
                                                                    }
                                                                    size={40}
                                                                    containerClassname="!rounded-[4px]"
                                                                />

                                                                <div className="font-rocGroteskMedium">
                                                                    <p className="text-base  text-slate-900">
                                                                        {
                                                                            vendor?.vendor?.profile
                                                                                ?.businessName
                                                                        }
                                                                    </p>
                                                                    <p className="text-sm  text-slate-500">
                                                                        {vendor?.vendor?.email}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <Checkbox
                                                                name=""
                                                                onChange={() =>
                                                                    handleAddVendor(
                                                                        vendor?.vendor?._id
                                                                    )
                                                                }
                                                                isChecked={selectedVendorIds.includes(
                                                                    vendor?.vendor?._id
                                                                )}
                                                            />
                                                        </div>
                                                    ))}
                                                    {loadMoreAddVendor && fetchingLdbVendors && (
                                                        <div className="flex my-4 justify-center">
                                                            <Loader color="gm-25" size={4} />
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <p className="text-center">No Vendor!</p>
                                            )}
                                        </>
                                    )}
                                </div>

                                <div
                                    className="text-sm mt-3 mb-3 font-rocGroteskMedium cursor-pointer flex items-center"
                                    onClick={() => navigate("/dashboard/suppliers/add-vendor")}
                                >
                                    <i className="ri-add-fill text-carribean-green"></i>
                                    <p className="text-carribean-green underline">
                                        Invite new vendor
                                    </p>
                                </div>
                            </div>
                            <div className="flex space-x-2">
                                <Button
                                    btnText={"Cancel"}
                                    type={"button"}
                                    btnClassname={"!bg-n-20 !text-gm-50"}
                                    disabled={addingProductVendor}
                                    onClick={() => setShowAddVendorModal(false)}
                                />
                                <Button
                                    btnClassname={"!py-3 !px-8"}
                                    onClick={addVendor}
                                    btnText={"Add vendors"}
                                    type={"button"}
                                    disabled={addingProductVendor}
                                    isLoading={addingProductVendor}
                                />
                            </div>
                        </div>
                    </ModalContainer>
                </div>
            )}
        </div>
    );
};

export default ProductVendors;
