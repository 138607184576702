export const inventoryTypes = {
    ADD_SINGLE_PRODUCT_REQUEST: "ADD_SINGLE_PRODUCT_REQUEST",
    ADD_SINGLE_PRODUCT_SUCCESS: "ADD_SINGLE_PRODUCT_SUCCESS",
    ADD_SINGLE_PRODUCT_FAILURE: "ADD_SINGLE_PRODUCT_FAILURE",
    UPDATE_SINGLE_PRODUCT_REQUEST: "UPDATE_SINGLE_PRODUCT_REQUEST",
    UPDATE_SINGLE_PRODUCT_SUCCESS: "UPDATE_SINGLE_PRODUCT_SUCCESS",
    UPDATE_SINGLE_PRODUCT_FAILURE: "UPDATE_SINGLE_PRODUCT_FAILURE",
    ADD_MULTIPLE_PRODUCTS_REQUEST: "ADD_MULTIPLE_PRODUCTS_REQUEST",
    ADD_MULTIPLE_PRODUCTS_SUCCESS: "ADD_MULTIPLE_PRODUCTS_SUCCESS",
    ADD_MULTIPLE_PRODUCTS_FAILURE: "ADD_MULTIPLE_PRODUCTS_FAILURE",
    FETCH_SINGLE_INVENTORY_REQUEST: "FETCH_SINGLE_INVENTORY_REQUEST",
    FETCH_SINGLE_INVENTORY_SUCCESS: "FETCH_SINGLE_INVENTORY_SUCCESS",
    FETCH_SINGLE_INVENTORY_FAILURE: "FETCH_SINGLE_INVENTORY_FAILURE",
    FETCH_STOCK_LIST_REQUEST: "FETCH_STOCK_LIST_REQUEST",
    FETCH_STOCK_LIST_SUCCESS: "FETCH_STOCK_LIST_SUCCESS",
    FETCH_STOCK_LIST_FAILURE: "FETCH_STOCK_LIST_FAILURE",
    ADD_PRODUCT_TO_STOCK_COUNT_REQUEST: "ADD_PRODUCT_TO_STOCK_COUNT_REQUEST",
    ADD_PRODUCT_TO_STOCK_COUNT_SUCCESS: "ADD_PRODUCT_TO_STOCK_COUNT_SUCCESS",
    ADD_PRODUCT_TO_STOCK_COUNT_FAILURE: "ADD_PRODUCT_TO_STOCK_COUNT_FAILURE",
    ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_REQUEST: "ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_REQUEST",
    ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_SUCCESS: "ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_SUCCESS",
    ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_FAILURE: "ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_FAILURE",
    FETCH_WAREHOUSE_LIST_REQUEST: "FETCH_WAREHOUSE_LIST_REQUEST",
    FETCH_WAREHOUSE_LIST_SUCCESS: "FETCH_WAREHOUSE_LIST_SUCCESS",
    FETCH_WAREHOUSE_LIST_FAILURE: "FETCH_WAREHOUSE_LIST_FAILURE",
    FETCH_LIST_STOCK_WAREHOUSE_REQUEST: "FETCH_LIST_STOCK_WAREHOUSE_REQUEST",
    FETCH_LIST_STOCK_WAREHOUSE_SUCCESS: "FETCH_LIST_STOCK_WAREHOUSE_SUCCESS",
    FETCH_LIST_STOCK_WAREHOUSE_FAILURE: "FETCH_LIST_STOCK_WAREHOUSE_FAILURE",
    FETCH_SINGLE_STOCK_WAREHOUSE_REQUEST: "FETCH_SINGLE_STOCK_WAREHOUSE_REQUEST",
    FETCH_SINGLE_STOCK_WAREHOUSE_SUCCESS: "FETCH_SINGLE_STOCK_WAREHOUSE_SUCCESS",
    FETCH_SINGLE_STOCK_WAREHOUSE_FAILURE: "FETCH_SINGLE_STOCK_WAREHOUSE_FAILURE",
    FETCH_SINGLE_WAREHOUSE_LIST_REQUEST: "FETCH_SINGLE_WAREHOUSE_LIST_REQUEST",
    FETCH_SINGLE_WAREHOUSE_LIST_SUCCESS: "FETCH_SINGLE_WAREHOUSE_LIST_SUCCESS",
    FETCH_SINGLE_WAREHOUSE_LIST_FAILURE: "FETCH_SINGLE_WAREHOUSE_LIST_FAILURE",
    CREATE_WAREHOUSE_REQUEST: "CREATE_WAREHOUSE_REQUEST",
    CREATE_WAREHOUSE_SUCCESS: "CREATE_WAREHOUSE_SUCCESS",
    CREATE_WAREHOUSE_FAILURE: "CREATE_WAREHOUSE_FAILURE",
    UPDATE_WAREHOUSE_REQUEST: "UPDATE_WAREHOUSE_REQUEST",
    UPDATE_WAREHOUSE_SUCCESS: "UPDATE_WAREHOUSE_SUCCESS",
    UPDATE_WAREHOUSE_FAILURE: "UPDATE_WAREHOUSE_FAILURE",
    DELETE_WAREHOUSE_REQUEST: "DELETE_WAREHOUSE_REQUEST",
    DELETE_WAREHOUSE_SUCCESS: "DELETE_WAREHOUSE_SUCCESS",
    DELETE_WAREHOUSE_FAILURE: "DELETE_WAREHOUSE_FAILURE",
    FETCH_SEARCH_INVENTORY_LISTING_REQUEST: "FETCH_SEARCH_INVENTORY_LISTING_REQUEST",
    FETCH_SEARCH_INVENTORY_LISTING_SUCCESS: "FETCH_SEARCH_INVENTORY_LISTING_SUCCESS",
    FETCH_SEARCH_INVENTORY_LISTING_FAILURE: "FETCH_SEARCH_INVENTORY_LISTING_FAILURE",
    FETCH_WAREHOUSES_REQUEST: "FETCH_WAREHOUSES_REQUEST",
    FETCH_WAREHOUSES_SUCCESS: "FETCH_WAREHOUSES_SUCCESS",
    FETCH_WAREHOUSES_FAILURE: "FETCH_WAREHOUSES_FAILURE",
    FETCH_INVENTORY_LIST_REQUEST: "FETCH_INVENTORY_LIST_REQUEST",
    FETCH_INVENTORY_LIST_SUCCESS: "FETCH_INVENTORY_LIST_SUCCESS",
    FETCH_INVENTORY_LIST_FAILURE: "FETCH_INVENTORY_LIST_FAILURE",
    FETCH_TRANSFER_STOCKS_REQUEST: "FETCH_TRANSFER_STOCKS_REQUEST",
    FETCH_TRANSFER_STOCKS_SUCCESS: "FETCH_TRANSFER_STOCKS_SUCCESS",
    FETCH_TRANSFER_STOCKS_FAILURE: "FETCH_TRANSFER_STOCKS_FAILURE",
    TRANSFER_STOCKS_REQUEST: "TRANSFER_STOCKS_REQUEST",
    TRANSFER_STOCKS_SUCCESS: "TRANSFER_STOCKS_SUCCESS",
    TRANSFER_STOCKS_FAILURE: "TRANSFER_STOCKS_FAILURE",
    TRANSFER_STOCK_FROM_WAREHOUSE_REQUEST: "TRANSFER_STOCK_FROM_WAREHOUSE_REQUEST",
    TRANSFER_STOCK_FROM_WAREHOUSE_SUCCESS: "TRANSFER_STOCK_FROM_WAREHOUSE_SUCCESS",
    TRANSFER_STOCK_FROM_WAREHOUSE_FAILURE: "TRANSFER_STOCK_FROM_WAREHOUSE_FAILURE",
    FETCH_SEARCH_PRODUCT_LISTING_REQUEST: "FETCH_SEARCH_PRODUCT_LISTING_REQUEST",
    FETCH_SEARCH_PRODUCT_LISTING_SUCCESS: "FETCH_SEARCH_PRODUCT_LISTING_SUCCESS",
    FETCH_SEARCH_PRODUCT_LISTING_FAILURE: "FETCH_SEARCH_PRODUCT_LISTING_FAILURE",
    FETCH_SINGLE_INVENTORY_SUPPLIERS_REQUEST: "FETCH_SINGLE_INVENTORY_SUPPLIERS_REQUEST",
    FETCH_SINGLE_INVENTORY_SUPPLIERS_SUCCESS: "FETCH_SINGLE_INVENTORY_SUPPLIERS_SUCCESS",
    FETCH_SINGLE_INVENTORY_SUPPLIERS_FAILURE: "FETCH_SINGLE_INVENTORY_SUPPLIERS_FAILURE",
    FETCH_INVENTORY_HISTORY_REQUEST: "FETCH_INVENTORY_HISTORY_REQUEST",
    FETCH_INVENTORY_HISTORY_SUCCESS: "FETCH_INVENTORY_HISTORY_SUCCESS",
    FETCH_INVENTORY_HISTORY_FAILURE: "FETCH_INVENTORY_HISTORY_FAILURE",
    FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_REQUEST: "FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_REQUEST",
    FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_SUCCESS: "FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_SUCCESS",
    FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_FAILURE: "FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_FAILURE",
    FETCH_INVENTORY_WAREHOUSE_REQUEST: "FETCH_INVENTORY_WAREHOUSE_REQUEST",
    FETCH_INVENTORY_WAREHOUSE_SUCCESS: "FETCH_INVENTORY_WAREHOUSE_SUCCESS",
    FETCH_INVENTORY_WAREHOUSE_FAILURE: "FETCH_INVENTORY_WAREHOUSE_FAILURE",
    FETCH_INVENTORY_ORDERS_REQUEST: "FETCH_INVENTORY_ORDERS_REQUEST",
    FETCH_INVENTORY_ORDERS_SUCCESS: "FETCH_INVENTORY_ORDERS_SUCCESS",
    FETCH_INVENTORY_ORDERS_FAILURE: "FETCH_INVENTORY_ORDERS_FAILURE",
    FETCH_SINGLE_STOCK_REQUEST: "FETCH_SINGLE_STOCK_REQUEST",
    FETCH_SINGLE_STOCK_SUCCESS: "FETCH_SINGLE_STOCK_SUCCESS",
    FETCH_SINGLE_STOCK_FAILURE: "FETCH_SINGLE_STOCK_FAILURE",
    UPDATE_STOCK_REQUEST: "UPDATE_STOCK_REQUEST",
    UPDATE_STOCK_SUCCESS: "UPDATE_STOCK_SUCCESS",
    UPDATE_STOCK_FAILURE: "UPDATE_STOCK_FAILURE",
    FETCH_SINGLE_TRANSFER_STOCK_REQUEST: "FETCH_SINGLE_TRANSFER_STOCK_REQUEST",
    FETCH_SINGLE_TRANSFER_STOCK_SUCCESS: "FETCH_SINGLE_TRANSFER_STOCK_SUCCESS",
    FETCH_SINGLE_TRANSFER_STOCK_FAILURE: "FETCH_SINGLE_TRANSFER_STOCK_FAILURE",
    FETCH_STOCK_ADJUSTMENT_LIST_REQUEST: "FETCH_STOCK_ADJUSTMENT_LIST_REQUEST",
    FETCH_STOCK_ADJUSTMENT_LIST_SUCCESS: "FETCH_STOCK_ADJUSTMENT_LIST_SUCCESS",
    FETCH_STOCK_ADJUSTMENT_LIST_FAILURE: "FETCH_STOCK_ADJUSTMENT_LIST_FAILURE",
    FETCH_SINGLE_STOCK_ADJUSTMENT_REQUEST: "FETCH_SINGLE_STOCK_ADJUSTMENT_REQUEST",
    FETCH_SINGLE_STOCK_ADJUSTMENT_SUCCESS: "FETCH_SINGLE_STOCK_ADJUSTMENT_SUCCESS",
    FETCH_SINGLE_STOCK_ADJUSTMENT_FAILURE: "FETCH_SINGLE_STOCK_ADJUSTMENT_FAILURE",
    ADD_NEW_STOCK_ADJUSTMENT_REQUEST: "ADD_NEW_STOCK_ADJUSTMENT_REQUEST",
    ADD_NEW_STOCK_ADJUSTMENT_SUCCESS: "ADD_NEW_STOCK_ADJUSTMENT_SUCCESS",
    ADD_NEW_STOCK_ADJUSTMENT_FAILURE: "ADD_NEW_STOCK_ADJUSTMENT_FAILURE",
    ADD_INVENTORY_SETTINGS_REQUEST: "ADD_INVENTORY_SETTINGS_REQUEST",
    ADD_INVENTORY_SETTINGS_SUCCESS: "ADD_INVENTORY_SETTINGS_SUCCESS",
    ADD_INVENTORY_SETTINGS_FAILURE: "ADD_INVENTORY_SETTINGS_FAILURE",
    ADD_PRODUCT_VENDOR_REQUEST: "ADD_PRODUCT_VENDOR_REQUEST",
    ADD_PRODUCT_VENDOR_SUCCESS: "ADD_PRODUCT_VENDOR_SUCCESS",
    ADD_PRODUCT_VENDOR_FAILURE: "ADD_PRODUCT_VENDOR_FAILURE",
    UPDATE_INVENTORY_SETTINGS_REQUEST: "UPDATE_INVENTORY_SETTINGS_REQUEST",
    UPDATE_INVENTORY_SETTINGS_SUCCESS: "UPDATE_INVENTORY_SETTINGS_SUCCESS",
    UPDATE_INVENTORY_SETTINGS_FAILURE: "UPDATE_INVENTORY_SETTINGS_FAILURE",
    FETCH_INVENTORY_SETTINGS_REQUEST: "FETCH_INVENTORY_SETTINGS_REQUEST",
    FETCH_INVENTORY_SETTINGS_SUCCESS: "FETCH_INVENTORY_SETTINGS_SUCCESS",
    FETCH_INVENTORY_SETTINGS_FAILURE: "FETCH_INVENTORY_SETTINGS_FAILURE",
    FETCH_SINGLE_SUPPLIED_STOCK_REQUEST: "FETCH_SINGLE_SUPPLIED_STOCK_REQUEST",
    FETCH_SINGLE_SUPPLIED_STOCK_SUCCESS: "FETCH_SINGLE_SUPPLIED_STOCK_SUCCESS",
    FETCH_SINGLE_SUPPLIED_STOCK_FAILURE: "FETCH_SINGLE_SUPPLIED_STOCK_FAILURE",
    FETCH_SUPPLIED_STOCK_LIST_REQUEST: "FETCH_SUPPLIED_STOCK_LIST_REQUEST",
    FETCH_SUPPLIED_STOCK_LIST_SUCCESS: "FETCH_SUPPLIED_STOCK_LIST_SUCCESS",
    FETCH_SUPPLIED_STOCK_LIST_FAILURE: "FETCH_SUPPLIED_STOCK_LIST_FAILURE",
    ADD_SUPPLIED_STOCK_REQUEST: "ADD_SUPPLIED_STOCK_REQUEST",
    ADD_SUPPLIED_STOCK_SUCCESS: "ADD_SUPPLIED_STOCK_SUCCESS",
    ADD_SUPPLIED_STOCK_FAILURE: "ADD_SUPPLIED_STOCK_FAILURE",
    UPDATE_SUPPLIED_STOCK_REQUEST: "UPDATE_SUPPLIED_STOCK_REQUEST",
    UPDATE_SUPPLIED_STOCK_SUCCESS: "UPDATE_SUPPLIED_STOCK_SUCCESS",
    UPDATE_SUPPLIED_STOCK_FAILURE: "UPDATE_SUPPLIED_STOCK_FAILURE",
    FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_REQUEST: "FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_REQUEST",
    FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_SUCCESS: "FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_SUCCESS",
    FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_FAILURE: "FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_FAILURE",
};
