import Button from "components/common/Button";
import TextInput from "components/common/InputField/TextInput";
import Navbar from "components/common/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { authActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";

const ResetPassword = () => {
    const [checks, setChecks] = useState(0);
    const [passwordInfo, setPasswordInfo] = useState({
        newPassword: "",
        confirmPassword: "",
    });
    const dispatch = useAppDispatch();
    const [param] = useSearchParams();
    const code = param.get("code");
    const token = param.get("token");
    const { resettingPassword } = useAppSelector((state) => state.auth);

    const handleChange = (evt) => {
        const confirmPasswordInput = document.getElementById("confirmPassword") as HTMLInputElement;
        const { name, value } = evt.target;
        setPasswordInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
        if (passwordInfo.newPassword !== value) {
            confirmPasswordInput.setCustomValidity("Confirm password must match new password");
        } else {
            confirmPasswordInput.setCustomValidity("");
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(checks>2){
        dispatch(authActions.resetPassword({ password: passwordInfo.newPassword, code }, token));
        }

    };
    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const isLowerCasePresent = lowercaseRegex.test(passwordInfo?.newPassword);
    const isUpperCasePresent = uppercaseRegex.test(passwordInfo?.newPassword);
    const isSpecialCharacterPresent = specialCharacterRegex.test(passwordInfo?.newPassword);

    useEffect(() => {
        let newChecks = 0;

        if (isLowerCasePresent) {
            newChecks++;
        }
        if (isUpperCasePresent) {
            newChecks++;
        }
        if (isSpecialCharacterPresent) {
            newChecks++;
        }
        if (passwordInfo?.newPassword.length > 8) {
            newChecks++;
        }

        if (newChecks !== checks) {
            setChecks(newChecks);
        }
    }, [
        isSpecialCharacterPresent,
        passwordInfo?.newPassword,
        isUpperCasePresent,
        isLowerCasePresent,
        checks,
    ]);

    return (
        <div className="w-full h-screen bg-body-1">
            <Navbar />
            <div className="h-[calc(100vh-84px)] w-full flex items-center justify-center">
                <div className="w-[36%] max-lg:w-[90%] bg-white p-10 flex flex-col items-center rounded-[20px] shadow-cardShadow">
                    <div className="text-center w-full mb-[46px]">
                        <p className="mb-2.5 text-2xl font-rocGroteskMedium text-g-75">
                            Reset your password
                        </p>
                        <p className="text-sm font-rocGroteskMedium text-gm-40">
                            Set a new secure password for your SynthAlly account
                        </p>
                    </div>
                    <form onSubmit={handleSubmit} className="w-full">
                        <TextInput
                            id="newPassword"
                            value={passwordInfo.newPassword}
                            name={"newPassword"}
                            type={"password"}
                            placeholder={"Enter new password"}
                            onChange={handleChange}
                            required={true}
                            minLength={8}
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).+$"
                            title="Password must contain at least one lowercase letter, one uppercase letter, and one special character."
                            containerClassname="mb-6"
                        />
                        <TextInput
                            id="confirmPassword"
                            value={passwordInfo.confirmPassword}
                            name={"confirmPassword"}
                            type={"password"}
                            onChange={handleChange}
                            placeholder={"Confirm password"}
                            required={true}
                            minLength={8}
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).+$"
                            title="Please fill this field"
                        />
                        <p className="text-[10px] leading-4 text-gm-35 mb-[10px]">
                            Password should contain at least 1 uppercase, lowercase, 8 characters
                            and 1 special character. (@, !, #, etc.)
                        </p>
                        {passwordInfo?.newPassword?.length > 0 && (
                           <div className="mb-[20px] flex space-x-1 w-full">
                           <div className=" flex w-full items-center space-x-3">
                           <div className={`w-full border-[3px] rounded ${checks<1?"border-n-40":"border-[#FF0000]"} `}></div>
                              <div className={`w-full border-[3px] rounded  ${checks<2?"border-n-40":"border-[#FBBF24]"}`}></div>
                              <div className={`w-full border-[3px] rounded  ${checks<3?"border-n-40":"border-carribean-green"}`}></div>
                              <div className={`w-full border-[3px] rounded  ${checks<4?"border-n-40":"border-carribean-green"}`}></div>
                           </div>
                              <p className="text-xs text-[#FF0000]">{checks===1 &&"Weak"}</p>
                              <p className="text-xs text-[#FBBF24]">{checks===2 &&"Good"}</p>
                              <p className="text-xs text-carribean-green">{checks===3 &&"Strong"}</p>
                              <p className="text-xs text-carribean-green whitespace-nowrap">{checks===4 &&"Very Strong"}</p>
                          </div>
                        )}
                        <div className="w-full ">
                            <Button
                                btnText={"Reset password"}
                                isLoading={resettingPassword}
                                type={"submit"}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
