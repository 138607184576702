import React from "react";

const LandingFooter = () => {
    const year = new Date().getFullYear();
    return (
        <div className="w-full h-[140px] max-lg:h-auto flex justify-center items-center">
            <div className="w-[84%] flex items-end justify-between max-lg:w-full max-lg:p-6 max-lg:flex-col max-lg:items-start">
                <div className="flex flex-col gap-4 max-lg:flex-col max-lg:items-start max-lg:gap-4 max-lg:mb-6">
                    <img
                        src={
                            "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702538223/Tradeally_2.0/synthAlly_logo_skcywb.svg"
                        }
                        alt="Logo Icon"
                        className="cursor-pointer"
                        height={24}
                        width={128}
                    />
                    <div className="flex gap-[18px] items-center">
                        <div className="flex gap-2 items-center">
                            <img
                                src={
                                    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1698171595/Web%20App/US_xfqqu5.svg"
                                }
                                alt="us flag"
                                height={20}
                                width={20}
                            />
                            <p className="text-sm font-rocGroteskMedium">SynthAlly Inc</p>
                        </div>
                        <div className="flex gap-2 items-center">
                            <img
                                src={
                                    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1698171582/Web%20App/NG_tn6d60.svg"
                                }
                                alt="nigerian flag"
                                height={20}
                                width={20}
                            />
                            <p className="text-sm font-rocGroteskMedium">
                            SynthAlly Technologies Ltd
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-6 max-lg:mb-6">
                    <p className="text-sm font-rocGroteskMedium">© {year} SynthAlly, Inc.</p>
                    <a href="/policy" className="text-sm font-rocGroteskMedium max-lg:hidden">
                        Privacy Notice
                    </a>
                    <a href="/terms" className="text-sm font-rocGroteskMedium max-lg:hidden">
                        Terms of Service
                    </a>
                    <a href="/policy" className="text-sm font-rocGroteskMedium hidden max-lg:block">
                        Privacy
                    </a>
                    <a href="/terms" className="text-sm font-rocGroteskMedium hidden max-lg:block">
                        Terms
                    </a>
                </div>
                <div className="flex items-center gap-6 mb-[-4px]">
                    <a href="https://twitter.com/TradeAlly_io" target={"_blank"} rel="noreferrer">
                        <i className="ri-twitter-x-fill text-2xl"></i>
                    </a>
                    <a href="https://facebook.com/TradeAlly" target={"_blank"} rel="noreferrer">
                        <i className="ri-facebook-circle-fill text-2xl"></i>
                    </a>
                    <a
                        href="https://www.linkedin.com/company/89720177/admin/"
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        <i className="ri-linkedin-box-fill text-2xl"></i>
                    </a>
                    <a
                        href="https://www.instagram.com/tradeally_io/"
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        <i className="ri-instagram-line text-2xl"></i>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default LandingFooter;
