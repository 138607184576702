import { useEffect, useState } from "react";
import { authActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "./storeHooks";
import { useLocation } from "react-router-dom";

const useGetUserProfile = () => {
    const [profile, setProfile] = useState<{ [key: string]: any }>({});
    const dispatch = useAppDispatch();
    const location = useLocation();

    const { fetchUserProfileSuccess } = useAppSelector((state) => state.auth);

    useEffect(() => {
        if (!Boolean(fetchUserProfileSuccess) && location?.pathname.includes("/dashboard") ) {
            dispatch(authActions.getLdbProfile());
        }
    }, [dispatch, fetchUserProfileSuccess]);

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setProfile(fetchUserProfileSuccess);
        }
    }, [fetchUserProfileSuccess]);

    return [profile];
};

export default useGetUserProfile;
