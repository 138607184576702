import React from "react";
import CustomToast from "components/common/CustomToast";
import { vendorTypes } from "redux//Ldb/types";
import { toast } from "react-hot-toast";
import { vendorService, websiteService } from "services/Ldb";
import { Dispatch } from "redux";

export const vendorActions = {
    joinWaitlist,
    resetJoinWaitListSuccess,
    getLdbVendors,
    getSingleVendor,
    authenticateGmail,
    resetAuthenticateGmailSuccess,
    connectGoogleWorkspace,
    getAllChannels,
    getVendorsByOrderType,
    getOnboardedVendors,
    getSuggestedVendors,
    resetGetSuggestedVendorsSuccess,
    resetConnectGoogleWorkspaceSuccess,
    authenticateMicrosoftAcct,
    connectMicrosoftWorkspace,
    resetAuthenticateMicrosoftSuccess,
    resetConnectMicrosoftWorkspaceSuccess,
    getVendorPo,
    manageConnection,
    resetManageConnectionSuccess,
    getVendorActivities,
    resetGetVendorActivitiesSuccess,
    authenticateGmailSettings,
    connectGoogleWorkspaceSettings,
    authenticateMicrosoftAcctSettings,
    connectMicrosoftWorkspaceSettings,
    resetAuthenticateGmailSettingsSuccess,
    resetAuthenticateMicrosoftSettingsSuccess,
    resetConnectMicrosoftWorkspaceSettingsSuccess,
    resetConnectGoogleWorkspaceSettingsSuccess,
    getVendorByCategory,
    getVendorCategories,
    addVendorCategory,
    resetAddVendorCategorySuccess,
    getVendorDocs,
    approveVendorDoc,
    resetApproveVendorDocSuccess,
    requestVendorDoc,
    resetRequestVendorDocSuccess,
    addDocComment,
    resetAddDocCommentSuccess,
    getDocComment,
    approveSupplier,
    resetApproveSupplierSuccess,
    reviewSupplierResponses
};

function joinWaitlist(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.JOIN_WAITLIST_REQUEST));
        websiteService.joinWaitList(data).then(
            (res) => {
                dispatch(success(vendorTypes.JOIN_WAITLIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.JOIN_WAITLIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function getLdbVendors(vendorLimit?: string | number, search?: string, status?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_LDB_VENDORS_REQUEST));

        vendorService.getLdbVendors(vendorLimit, search, status).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_LDB_VENDORS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_LDB_VENDORS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getOnboardedVendors() {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_ONBOARDED_VENDORS_REQUEST));

        vendorService.getOnboardedVendors().then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_ONBOARDED_VENDORS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_ONBOARDED_VENDORS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSuggestedVendors() {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_SUGGESTED_VENDORS_REQUEST));

        vendorService.getSuggestedVendors().then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_SUGGESTED_VENDORS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_SUGGESTED_VENDORS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleVendor(vendorId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_SINGLE_VENDOR_REQUEST));

        vendorService.getSingleVendor(vendorId).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_SINGLE_VENDOR_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_SINGLE_VENDOR_FAILURE, error?.message));
                }
            }
        );
    };
}

function authenticateGmail() {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.AUTHENTICATE_GMAIL_REQUEST));

        vendorService.authenticateGmail().then(
            (res) => {
                dispatch(success(vendorTypes.AUTHENTICATE_GMAIL_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.AUTHENTICATE_GMAIL_FAILURE, error?.message));
                }
            }
        );
    };
}

function authenticateGmailSettings() {
    return (dispatch) => {
        dispatch(request(vendorTypes.AUTHENTICATE_GMAIL_SETTINGS_REQUEST));

        vendorService.authenticateGmailSettings().then(
            (res) => {
                dispatch(success(vendorTypes.AUTHENTICATE_GMAIL_SETTINGS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(vendorTypes.AUTHENTICATE_GMAIL_SETTINGS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function connectGoogleWorkspace(reqObj: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.CONNECT_GOOGLE_WORKSPACE_REQUEST));

        vendorService.connectGoogleWorkspace(reqObj).then(
            (res) => {
                dispatch(success(vendorTypes.CONNECT_GOOGLE_WORKSPACE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.CONNECT_GOOGLE_WORKSPACE_FAILURE, error?.message));
                }
            }
        );
    };
}
function connectGoogleWorkspaceSettings(reqObj) {
    return (dispatch) => {
        dispatch(request(vendorTypes.CONNECT_GOOGLE_WORKSPACE_SETTINGS_REQUEST));

        vendorService.connectGoogleWorkspaceSettings(reqObj).then(
            (res) => {
                dispatch(success(vendorTypes.CONNECT_GOOGLE_WORKSPACE_SETTINGS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            vendorTypes.CONNECT_GOOGLE_WORKSPACE_SETTINGS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function manageConnection(reqObj) {
    return (dispatch) => {
        dispatch(request(vendorTypes.MANAGE_CONNECTION_REQUEST));

        vendorService.manageConnection(reqObj).then(
            (res) => {
                dispatch(success(vendorTypes.MANAGE_CONNECTION_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.MANAGE_CONNECTION_FAILURE, error?.message));
                }
            }
        );
    };
}

function authenticateMicrosoftAcct() {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.AUTHENTICATE_MICROSOFT_REQUEST));

        vendorService.authenticateMicrosoftAcct().then(
            (res) => {
                dispatch(success(vendorTypes.AUTHENTICATE_MICROSOFT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.AUTHENTICATE_MICROSOFT_FAILURE, error?.message));
                }
            }
        );
    };
}

function authenticateMicrosoftAcctSettings() {
    return (dispatch) => {
        dispatch(request(vendorTypes.AUTHENTICATE_MICROSOFT_SETTINGS_REQUEST));

        vendorService.authenticateMicrosoftAcctSettings().then(
            (res) => {
                dispatch(success(vendorTypes.AUTHENTICATE_MICROSOFT_SETTINGS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(vendorTypes.AUTHENTICATE_MICROSOFT_SETTINGS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function connectMicrosoftWorkspace(reqObj: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_REQUEST));

        vendorService.connectMicrosoftWorkspace(reqObj).then(
            (res) => {
                dispatch(success(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function connectMicrosoftWorkspaceSettings(reqObj) {
    return (dispatch) => {
        dispatch(request(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SETTINGS_REQUEST));

        vendorService.connectMicrosoftWorkspaceSettings(reqObj).then(
            (res) => {
                dispatch(
                    success(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SETTINGS_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SETTINGS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getAllChannels() {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_ALL_CHANNELS_REQUEST));

        vendorService.getAllChannels().then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_ALL_CHANNELS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_ALL_CHANNELS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getVendorsByOrderType(orderType: string = "supply", search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_REQUEST));

        vendorService.getVendorsByOrderType(orderType, search).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getVendorPo(vendorId: string, limit?: string | number) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_VENDOR_PO_REQUEST));

        vendorService.getVendorPo(vendorId, limit).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_VENDOR_PO_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_VENDOR_PO_FAILURE, error?.message));
                }
            }
        );
    };
}

function getVendorActivities(vendorId) {
    return (dispatch) => {
        dispatch(request(vendorTypes.FETCH_VENDOR_ACTIVITIES_REQUEST));

        vendorService.getVendorActivities(vendorId).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_VENDOR_ACTIVITIES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_VENDOR_ACTIVITIES_FAILURE, error?.message));
                }
            }
        );
    };
}
function getVendorByCategory(category, startDate, endDate) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_VENDOR_BY_CATEGORY_REQUEST));

        vendorService.getVendorByCategory(category, startDate, endDate).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_VENDOR_BY_CATEGORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_VENDOR_BY_CATEGORY_FAILURE, error?.message));
                }
            }
        );
    };
}

function getVendorCategories(limit?: number) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_VENDOR_CATEGORIES_REQUEST));

        vendorService.getVendorCategories(limit).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_VENDOR_CATEGORIES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_VENDOR_CATEGORIES_FAILURE, error?.message));
                }
            }
        );
    };
}

function addVendorCategory(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.ADD_VENDOR_CATEGORY_REQUEST));

        vendorService.addVendorCategory(data).then(
            (res) => {
                dispatch(success(vendorTypes.ADD_VENDOR_CATEGORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.ADD_VENDOR_CATEGORY_FAILURE, error?.message));
                }
            }
        );
    };
}

function getVendorDocs(vendorId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_VENDOR_DOCS_REQUEST));

        vendorService.getVendorDocs(vendorId).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_VENDOR_DOCS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_VENDOR_DOCS_FAILURE, error?.message));
                }
            }
        );
    };
}

function approveVendorDoc(
    reqObj: Record<string, any> | Array<Record<string, any>>,
    hideError?: boolean
) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.APPROVE_VENDOR_DOC_REQUEST));

        vendorService.approveVendorDoc(reqObj).then(
            (res) => {
                dispatch(success(vendorTypes.APPROVE_VENDOR_DOC_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    if (!hideError) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                    }
                    dispatch(failure(vendorTypes.APPROVE_VENDOR_DOC_FAILURE, error?.message));
                }
            }
        );
    };
}

function requestVendorDoc(reqObj: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.REQUEST_VENDOR_DOC_REQUEST));

        vendorService.requestVendorDoc(reqObj).then(
            (res) => {
                dispatch(success(vendorTypes.REQUEST_VENDOR_DOC_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Document requested"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.REQUEST_VENDOR_DOC_FAILURE, error?.message));
                }
            }
        );
    };
}

function addDocComment(reqObj: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.ADD_DOC_COMMENT_REQUEST));

        vendorService.addDocComment(reqObj).then(
            (res) => {
                dispatch(success(vendorTypes.ADD_DOC_COMMENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.ADD_DOC_COMMENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function getDocComment(vendorId: string, limit?: string | number) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.FETCH_DOC_COMMENT_REQUEST));

        vendorService.getDocComments(vendorId, limit).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_DOC_COMMENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_DOC_COMMENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function approveSupplier(vendorId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.APPROVE_SUPPLIER_REQUEST));

        vendorService.approveSupplier(vendorId).then(
            (res) => {
                dispatch(success(vendorTypes.APPROVE_SUPPLIER_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Supplier approved"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.APPROVE_SUPPLIER_FAILURE, error?.message));
                }
            }
        );
    };
}

function reviewSupplierResponses(vendorId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(vendorTypes.REVIEW_SUPPLIER_RESPONSES_REQUEST));

        vendorService.reviewSupplierResponses(vendorId).then(
            (res) => {
                dispatch(success(vendorTypes.REVIEW_SUPPLIER_RESPONSES_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Supplier approved"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.REVIEW_SUPPLIER_RESPONSES_FAILURE, error?.message));
                }
            }
        );
    };
}

function resetApproveSupplierSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.APPROVE_SUPPLIER_SUCCESS, null));
    };
}

function resetAddDocCommentSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.ADD_DOC_COMMENT_SUCCESS, null));
    };
}

function resetRequestVendorDocSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.APPROVE_VENDOR_DOC_SUCCESS, null));
    };
}

function resetApproveVendorDocSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.APPROVE_VENDOR_DOC_SUCCESS, null));
    };
}

function resetAddVendorCategorySuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.ADD_VENDOR_CATEGORY_SUCCESS, null));
    };
}

function resetGetVendorActivitiesSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.FETCH_VENDOR_ACTIVITIES_SUCCESS, null));
    };
}

function resetConnectMicrosoftWorkspaceSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SUCCESS, null));
    };
}

function resetConnectMicrosoftWorkspaceSettingsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SETTINGS_SUCCESS, null));
    };
}

function resetAuthenticateMicrosoftSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.AUTHENTICATE_MICROSOFT_SUCCESS, null));
    };
}
function resetAuthenticateMicrosoftSettingsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.AUTHENTICATE_MICROSOFT_SETTINGS_SUCCESS, null));
    };
}

function resetConnectGoogleWorkspaceSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.CONNECT_GOOGLE_WORKSPACE_SUCCESS, null));
    };
}
function resetConnectGoogleWorkspaceSettingsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.CONNECT_GOOGLE_WORKSPACE_SETTINGS_SUCCESS, null));
    };
}
function resetAuthenticateGmailSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.AUTHENTICATE_GMAIL_SUCCESS, null));
    };
}

function resetAuthenticateGmailSettingsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.AUTHENTICATE_GMAIL_SETTINGS_SUCCESS, null));
    };
}

function resetGetSuggestedVendorsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.FETCH_SUGGESTED_VENDORS_SUCCESS, null));
    };
}
function resetManageConnectionSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.MANAGE_CONNECTION_SUCCESS, null));
    };
}
function resetJoinWaitListSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(vendorTypes.JOIN_WAITLIST_SUCCESS, null));
    };
}

function request(type: string) {
    return { type: type };
}
function success(type: string, data: any) {
    return { type: type, payload: data };
}
function failure(type: string, error: any) {
    return { type: type, payload: error ?? "" };
}
