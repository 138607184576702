import LandingNav from "components/views/Landing/LandingNav";
import React, { useState } from "react";
import { tableOfContent } from "variables/websiteData";

const Terms = () => {
    const [activeSection, setActiveSection] = useState(0);

    const handleTableItemClick = (index) => {
        setActiveSection(index);
    };

    return (
        <div>
            <LandingNav bgClass="!bg-white" />
            <div className=" px-[42px] max-lg:px-6 w-full h-screen flex gap-[42px]">
                <div className="rounded mt-[112px] max-lg:hidden block mb-10 py-12 px-6 w-[34%] h-fit bg-[rgba(22,198,164,0.06)]">
                    <h3 className="text-xl text-slate-500 mb-6 font-rocGroteskBold">Contents</h3>
                    <div className="flex flex-col gap-2.5">
                        {tableOfContent?.map(({ title }, idx) => {
                            return (
                                <div
                                    key={title + idx}
                                    className="flex items-center gap-3 font-rocGroteskMedium"
                                >
                                    <div className="w-8 h-8 rounded-full flex items-center justify-center bg-carribean-green/10">
                                        <p className="text-carribean-green text-[13px]">
                                            {idx + 1}
                                        </p>
                                    </div>
                                    <a
                                        href={`#section${idx}`}
                                        className={`${
                                            activeSection === idx
                                                ? "text-carribean-green"
                                                : "text-slate-500"
                                        } cursor-pointer`}
                                        onClick={() => handleTableItemClick(idx)}
                                    >
                                        {title}
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="w-[66%] max-lg:w-full pt-[112px] pb-10 h-full overflow-y-auto scroll-smooth scroll-pt-[112px]">
                    <h3 className="text-xl font-rocGroteskBold mb-10 max-sm:text-center">
                        Terms and Conditions for SynthAlly Incorporation
                    </h3>
                    <div className="mb-[42px] flex justify-center">
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1698844597/Web%20App/website/amico_khlqpa.svg"
                            alt="contract"
                            className="w-[178px] h-[178px]"
                        />
                    </div>
                    <div className="flex flex-col gap-[42px] ">
                        {tableOfContent?.map(({ title, subtext }, idx) => {
                            return (
                                <div key={title + idx} id={"section" + idx}>
                                    <div className="flex mb-4 items-center gap-3 font-rocGroteskMedium">
                                        <div className="w-8 h-8 rounded-full flex items-center justify-center bg-carribean-green/10">
                                            <p className=" text-[13px]">{idx + 1}</p>
                                        </div>
                                        <p
                                            className={`text-lg ${
                                                activeSection === idx ? "text-carribean-green" : ""
                                            } max-lg:!text-gm-50`}
                                        >
                                            {title}
                                        </p>
                                    </div>
                                    <p className="text-sm text-slate-500">{subtext}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Terms;
