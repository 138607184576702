import { inventoryTypes } from "redux/Ldb/types";

const initialState = {
    addingSingleProduct: false,
    addSingleProductSuccess: null,
    addSingleProductFailure: null,
    updatingSingleProduct: false,
    updatedSingleProductSuccess: null,
    updatedSingleProductFailure: null,
    addingMultipleProducts: false,
    addMultipleProductsSuccess: null,
    addMultipleProductsFailure: null,
    fetchingSingleInventory: false,
    fetchedSingleInventorySuccess: null,
    fetchedSingleInventoryFailure: null,
    fetchingStockList: false,
    fetchedStockListSuccess: null,
    fetchedStockListFailure: null,
    addingProductToStockCount: false,
    addedProductToStockCountSuccess: null,
    addedProductToStockCountFailure: null,
    addingMultipleProductToStockCount: false,
    addedMultipleProductToStockCountSuccess: null,
    addedMultipleProductToStockCountFailure: null,
    fetchingWarehouseList: false,
    fetchedWarehouseListSuccess: null,
    fetchedWarehouseListFailure: null,
    createWarehouse: false,
    createWarehouseSuccess: null,
    createWarehouseFailure: null,
    updateWarehouse: false,
    updateWarehouseSuccess: null,
    updateWarehouseFailure: null,
    fetchingSingleWarehouseList: false,
    fetchedSingleWarehouseListSuccess: null,
    fetchedSingleWarehouseListFailure: null,
    deleteWarehouse: false,
    deleteWarehouseSuccess: null,
    deleteWarehouseFailure: null,
    fetchingSearchInventoryListing: false,
    fetchedSearchInventoryListingSuccess: null,
    fetchedSearchInventoryListingFailure: null,
    fetchingWarehouses: false,
    fetchedWarehousesSuccess: null,
    fetchedWarehousesFailure: null,
    fetchingInventoryList: false,
    fetchedInventoryListSuccess: null,
    fetchedInventoryListFailure: null,
    fetchingTransferStocks: false,
    fetchedTransferStocksSuccess: null,
    fetchedTransferStocksFailure: null,
    transferringStocks: false,
    transferStocksSuccess: null,
    transferStocksFailure: null,
    transferringStockFromWarehouse: false,
    transferStockFromWarehouseSuccess: null,
    transferStockFromWarehouseFailure: null,
    fetchingSearchProductListing: false,
    fetchedSearchProductListingSuccess: null,
    fetchedSearchProductListingFailure: null,
    fetchingListStockWarehouse: false,
    fetchedListStockWarehouseSuccess: null,
    fetchedListStockWarehouseFailure: null,
    fetchingSingleInventorySuppliers: false,
    fetchedSingleInventorySuppliersSuccess: null,
    fetchedSingleInventorySuppliersFailure: null,
    fetchingInventoryHistory: false,
    fetchedInventoryHistorySuccess: null,
    fetchedInventoryHistoryFailure: null,
    fetchingSingleInventoryPurchaseOrder: false,
    fetchedSingleInventoryPurchaseOrderSuccess: null,
    fetchedSingleInventoryPurchaseOrderFailure: null,
    fetchingInventoryWarehouse: false,
    fetchedInventoryWarehouseSuccess: null,
    fetchedInventoryWarehouseFailure: null,
    fetchingInventoryOrders: false,
    fetchedInventoryOrdersSuccess: null,
    fetchedInventoryOrdersFailure: null,
    fetchingSingleStockWarehouse: false,
    fetchedSingleStockWareSuccess: null,
    fetchedSingleStockWarehouseFailure: null,
    fetchingSingleStock: false,
    fetchedSingleStockSuccess: null,
    fetchedSingleStockFailure: null,
    updatingStock: false,
    updatedStockSuccess: null,
    updatedStockFailure: null,
    fetchingSingleTransferStock: false,
    fetchedSingleTransferStockSuccess: null,
    fetchedSingleTransferStockFailure: null,
    fetchingStockAdjustmentList: false,
    fetchedStockAdjustmentSuccess: null,
    fetchedStockAdjustmentFailure: null,
    fetchingSingleStockAdjustment: false,
    fetchedSingleStockAdjustmentSuccess: null,
    fetchedSingleStockAdjustmentFailure: null,
    addingNewStockAdjustment: false,
    addedNewStockAdjustmentSuccess: null,
    addedNewStockAdjustmentFailure: null,
    addingInventorySettings: false,
    addedInventorySettingsSuccess: null,
    addedInventorySettingsFailure: null,
    updatingInventorySettings: false,
    updatedInventorySettingsSuccess: null,
    updatedInventorySettingsFailure: null,
    fetchingInventorySettings: false,
    fetchedInventorySettingsSuccess: null,
    fetchedInventorySettingsFailure: null,
    addingProductVendor: false,
    addedProductVendorSuccess: null,
    addedProductVendorFailure: null,
    fetchingSuppliedStockList: false,
    fetchedSuppliedStockListSuccess: null,
    fetchedSuppliedStockListFailure: null,
    fetchingSingleSuppliedStock: false,
    fetchedSingleSuppliedStockSuccess: null,
    fetchedSingleSuppliedStockFailure: null,
    addingSuppliedStock: false,
    addedSuppliedStockSuccess: null,
    addedSuppliedStockFailure: null,
    updatingSuppliedStock: false,
    updatedSuppliedStockSuccess: null,
    updatedSuppliedStockFailure: null,
    fetchingSuppliedStockListAllData: false,
    fetchedSuppliedStockListAllDataSuccess: null,
    fetchedSuppliedStockListAllDataFailure: null,
};

export const inventory = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case inventoryTypes.ADD_SINGLE_PRODUCT_REQUEST:
            return {
                ...state,
                addingSingleProduct: true,
                addSingleProductSuccess: null,
                addSingleProductFailure: null,
            };
        case inventoryTypes.ADD_SINGLE_PRODUCT_SUCCESS:
            return {
                ...state,
                addingSingleProduct: false,
                addSingleProductSuccess: action.payload,
                addSingleProductFailure: null,
            };
        case inventoryTypes.ADD_SINGLE_PRODUCT_FAILURE:
            return {
                ...state,
                addingSingleProduct: false,
                addSingleProductSuccess: null,
                addSingleProductFailure: action.payload,
            };

        case inventoryTypes.UPDATE_SINGLE_PRODUCT_REQUEST:
            return {
                ...state,
                updatingSingleProduct: true,
                updatedSingleProductSuccess: null,
                updatedSingleProductFailure: null,
            };
        case inventoryTypes.UPDATE_SINGLE_PRODUCT_SUCCESS:
            return {
                ...state,
                updatingSingleProduct: false,
                updatedSingleProductSuccess: action.payload,
                updatedSingleProductFailure: null,
            };
        case inventoryTypes.UPDATE_SINGLE_PRODUCT_FAILURE:
            return {
                ...state,
                updatingSingleProduct: false,
                updatedSingleProductSuccess: null,
                updatedSingleProductFailure: action.payload,
            };

        case inventoryTypes.ADD_MULTIPLE_PRODUCTS_REQUEST:
            return {
                ...state,
                addingMultipleProducts: true,
                addMultipleProductsSuccess: null,
                addMultipleProductsFailure: null,
            };
        case inventoryTypes.ADD_MULTIPLE_PRODUCTS_SUCCESS:
            return {
                ...state,
                addingMultipleProducts: false,
                addMultipleProductsSuccess: action.payload,
                addMultipleProductsFailure: null,
            };
        case inventoryTypes.ADD_MULTIPLE_PRODUCTS_FAILURE:
            return {
                ...state,
                addingMultipleProducts: false,
                addMultipleProductsSuccess: null,
                addMultipleProductsFailure: action.payload,
            };

        case inventoryTypes.TRANSFER_STOCK_FROM_WAREHOUSE_REQUEST:
            return {
                ...state,
                transferringStockFromWarehouse: true,
                transferStockFromWarehouseSuccess: null,
                transferStockFromWarehouseFailure: null,
            };
        case inventoryTypes.TRANSFER_STOCK_FROM_WAREHOUSE_SUCCESS:
            return {
                ...state,
                transferringStockFromWarehouse: false,
                transferStockFromWarehouseSuccess: action.payload,
                transferStockFromWarehouseFailure: null,
            };
        case inventoryTypes.TRANSFER_STOCK_FROM_WAREHOUSE_FAILURE:
            return {
                ...state,
                transferringStockFromWarehouse: false,
                transferStockFromWarehouseSuccess: null,
                transferStockFromWarehouseFailure: action.payload,
            };

        case inventoryTypes.FETCH_SINGLE_INVENTORY_REQUEST:
            return {
                ...state,
                fetchingSingleInventory: true,
                fetchedSingleInventorySuccess: null,
                fetchedSingleInventoryFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_INVENTORY_SUCCESS:
            return {
                ...state,
                fetchingSingleInventory: false,
                fetchedSingleInventorySuccess: action.payload,
                fetchedSingleInventoryFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_INVENTORY_FAILURE:
            return {
                ...state,
                fetchingSingleInventory: false,
                fetchedSingleInventorySuccess: null,
                fetchedSingleInventoryFailure: action.payload,
            };
        case inventoryTypes.FETCH_STOCK_LIST_REQUEST:
            return {
                ...state,
                fetchingStockList: true,
                fetchedStockListSuccess: null,
                fetchedStockListFailure: null,
            };
        case inventoryTypes.FETCH_STOCK_LIST_SUCCESS:
            return {
                ...state,
                fetchingStockList: false,
                fetchedStockListSuccess: action.payload,
                fetchedStockListFailure: null,
            };
        case inventoryTypes.FETCH_STOCK_LIST_FAILURE:
            return {
                ...state,
                fetchingStockList: false,
                fetchedStockListSuccess: null,
                fetchedStockListFailure: action.payload,
            };
        case inventoryTypes.ADD_PRODUCT_TO_STOCK_COUNT_REQUEST:
            return {
                ...state,
                addingProductToStockCount: true,
                addedProductToStockCountSuccess: null,
                addedProductToStockCountFailure: null,
            };
        case inventoryTypes.ADD_PRODUCT_TO_STOCK_COUNT_SUCCESS:
            return {
                ...state,
                addingProductToStockCount: false,
                addedProductToStockCountSuccess: action.payload,
                addedProductToStockCountFailure: null,
            };
        case inventoryTypes.ADD_PRODUCT_TO_STOCK_COUNT_FAILURE:
            return {
                ...state,
                addingProductToStockCount: false,
                addedProductToStockCountSuccess: null,
                addedProductToStockCountFailure: action.payload,
            };
        case inventoryTypes.ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_REQUEST:
            return {
                ...state,
                addingMultipleProductToStockCount: true,
                addedMultipleProductToStockCountSuccess: null,
                addedMultipleProductToStockCountFailure: null,
            };
        case inventoryTypes.ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_SUCCESS:
            return {
                ...state,
                addingMultipleProductToStockCount: false,
                addedMultipleProductToStockCountSuccess: action.payload,
                addedMultipleProductToStockCountFailure: null,
            };
        case inventoryTypes.ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_FAILURE:
            return {
                ...state,
                addingProductToStockCount: false,
                addedMultipleProductToStockCountSuccess: null,
                addedMultipleProductToStockCountFailure: action.payload,
            };

        case inventoryTypes.FETCH_WAREHOUSE_LIST_REQUEST:
            return {
                ...state,
                fetchingWarehouseList: true,
                fetchedWarehouseListSuccess: null,
                fetchedWarehouseListFailure: null,
            };
        case inventoryTypes.FETCH_WAREHOUSE_LIST_SUCCESS:
            return {
                ...state,
                fetchingWarehouseList: false,
                fetchedWarehouseListSuccess: action.payload,
                fetchedWarehouseListFailure: null,
            };
        case inventoryTypes.FETCH_WAREHOUSE_LIST_FAILURE:
            return {
                ...state,
                fetchingWarehouseList: false,
                fetchedWarehouseListSuccess: null,
                fetchedWarehouseListFailure: action.payload,
            };
        case inventoryTypes.CREATE_WAREHOUSE_REQUEST:
            return {
                ...state,
                createWarehouse: true,
                createWarehouseSuccess: null,
                createWarehouseFailure: null,
            };
        case inventoryTypes.CREATE_WAREHOUSE_SUCCESS:
            return {
                ...state,
                createWarehouse: false,
                createWarehouseSuccess: action.payload,
                createWarehouseFailure: null,
            };
        case inventoryTypes.CREATE_WAREHOUSE_FAILURE:
            return {
                ...state,
                createWarehouse: false,
                createWarehouseSuccess: null,
                createWarehouseFailure: action.payload,
            };

        case inventoryTypes.FETCH_SEARCH_INVENTORY_LISTING_REQUEST:
            return {
                ...state,
                fetchingSearchInventoryListing: true,
                fetchedSearchInventoryListingSuccess: null,
                fetchedSearchInventoryListingFailure: null,
            };
        case inventoryTypes.FETCH_SEARCH_INVENTORY_LISTING_SUCCESS:
            return {
                ...state,
                fetchingSearchInventoryListing: false,
                fetchedSearchInventoryListingSuccess: action.payload,
                fetchedSearchInventoryListingFailure: null,
            };
        case inventoryTypes.FETCH_SEARCH_INVENTORY_LISTING_FAILURE:
            return {
                ...state,
                fetchingSearchInventoryListing: false,
                fetchedSearchInventoryListingSuccess: null,
                fetchedSearchInventoryListingFailure: action.payload,
            };
        case inventoryTypes.FETCH_WAREHOUSES_REQUEST:
            return {
                ...state,
                fetchingWarehouses: true,
                fetchedWarehousesSuccess: null,
                fetchedWarehousesFailure: null,
            };
        case inventoryTypes.FETCH_WAREHOUSES_SUCCESS:
            return {
                ...state,
                fetchingWarehouses: false,
                fetchedWarehousesSuccess: action.payload,
                fetchedWarehousesFailure: null,
            };
        case inventoryTypes.FETCH_WAREHOUSES_FAILURE:
            return {
                ...state,
                fetchingWarehouses: false,
                fetchedWarehousesSuccess: null,
                fetchedWarehousesFailure: action.payload,
            };
        case inventoryTypes.FETCH_INVENTORY_LIST_REQUEST:
            return {
                ...state,
                fetchingInventoryList: true,
                fetchedInventoryListSuccess: null,
                fetchedInventoryListFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_LIST_SUCCESS:
            return {
                ...state,
                fetchingInventoryList: false,
                fetchedInventoryListSuccess: action.payload,
                fetchedInventoryListFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_LIST_FAILURE:
            return {
                ...state,
                fetchingInventoryList: false,
                fetchedInventoryListSuccess: null,
                fetchedInventoryListFailure: action.payload,
            };
        case inventoryTypes.FETCH_TRANSFER_STOCKS_REQUEST:
            return {
                ...state,
                fetchingTransferStocks: true,
                fetchedTransferStocksSuccess: null,
                fetchedTransferStocksFailure: null,
            };
        case inventoryTypes.FETCH_TRANSFER_STOCKS_SUCCESS:
            return {
                ...state,
                fetchingTransferStocks: false,
                fetchedTransferStocksSuccess: action.payload,
                fetchedTransferStocksFailure: null,
            };
        case inventoryTypes.FETCH_TRANSFER_STOCKS_FAILURE:
            return {
                ...state,
                fetchingTransferStocks: false,
                fetchedTransferStocksSuccess: null,
                fetchedTransferStocksFailure: action.payload,
            };
        case inventoryTypes.TRANSFER_STOCKS_REQUEST:
            return {
                ...state,
                transferringStocks: true,
                transferStocksSuccess: null,
                transferStocksFailure: null,
            };
        case inventoryTypes.TRANSFER_STOCKS_SUCCESS:
            return {
                ...state,
                transferringStocks: false,
                transferStocksSuccess: action.payload,
                transferStocksFailure: null,
            };
        case inventoryTypes.TRANSFER_STOCKS_FAILURE:
            return {
                ...state,
                transferringStocks: false,
                transferStocksSuccess: null,
                transferStocksFailure: action.payload,
            };

        case inventoryTypes.FETCH_SEARCH_PRODUCT_LISTING_REQUEST:
            return {
                ...state,
                fetchingSearchProductListing: true,
                fetchedSearchProductListingSuccess: null,
                fetchedSearchProductListingFailure: null,
            };
        case inventoryTypes.FETCH_SEARCH_PRODUCT_LISTING_SUCCESS:
            return {
                ...state,
                fetchingSearchProductListing: false,
                fetchedSearchProductListingSuccess: action.payload,
                fetchedSearchProductListingFailure: null,
            };
        case inventoryTypes.FETCH_SEARCH_PRODUCT_LISTING_FAILURE:
            return {
                ...state,
                fetchingSearchProductListing: false,
                fetchedSearchProductListingSuccess: null,
                fetchedSearchProductListingFailure: action.payload,
            };
        case inventoryTypes.DELETE_WAREHOUSE_REQUEST:
            return {
                ...state,
                deleteWarehouse: true,
                deleteWarehouseSuccess: null,
                deleteWarehouseFailure: null,
            };
        case inventoryTypes.DELETE_WAREHOUSE_SUCCESS:
            return {
                ...state,
                deleteWarehouse: false,
                deleteWarehouseSuccess: action.payload,
                deleteWarehouseFailure: null,
            };
        case inventoryTypes.DELETE_WAREHOUSE_FAILURE:
            return {
                ...state,
                deleteWarehouse: false,
                deleteWarehouseSuccess: null,
                deleteWarehouseFailure: action.payload,
            };
        case inventoryTypes.FETCH_SINGLE_WAREHOUSE_LIST_REQUEST:
            return {
                ...state,
                fetchingSingleWarehouseList: true,
                fetchedSingleWarehouseListSuccess: null,
                fetchedSingleWarehouseListFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_WAREHOUSE_LIST_SUCCESS:
            return {
                ...state,
                fetchingSingleWarehouseList: false,
                fetchedSingleWarehouseListSuccess: action.payload,
                fetchedSingleWarehouseListFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_WAREHOUSE_LIST_FAILURE:
            return {
                ...state,
                fetchingSingleWarehouseList: false,
                fetchedSingleWarehouseListSuccess: null,
                fetchedSingleWarehouseListFailure: action.payload,
            };

        case inventoryTypes.FETCH_LIST_STOCK_WAREHOUSE_REQUEST:
            return {
                ...state,
                fetchingListStockWarehouse: true,
                fetchedListStockWarehouseSuccess: null,
                fetchedListStockWarehouseFailure: null,
            };
        case inventoryTypes.FETCH_LIST_STOCK_WAREHOUSE_SUCCESS:
            return {
                ...state,
                fetchingListStockWarehouse: false,
                fetchedListStockWarehouseSuccess: action.payload,
                fetchedListStockWarehouseFailure: null,
            };
        case inventoryTypes.FETCH_LIST_STOCK_WAREHOUSE_FAILURE:
            return {
                ...state,
                fetchingListStockWarehouse: false,
                fetchedListStockWarehouseSuccess: null,
                fetchedListStockWarehouseFailure: action.payload,
            };

        case inventoryTypes.UPDATE_WAREHOUSE_REQUEST:
            return {
                ...state,
                updateWarehouse: true,
                updateWarehouseSuccess: null,
                updateWarehouseFailure: null,
            };
        case inventoryTypes.UPDATE_WAREHOUSE_SUCCESS:
            return {
                ...state,
                updateWarehouse: false,
                updateWarehouseSuccess: action.payload,
                updateWarehouseFailure: null,
            };
        case inventoryTypes.UPDATE_WAREHOUSE_FAILURE:
            return {
                ...state,
                updateWarehouse: false,
                updateWarehouseSuccess: null,
                updateWarehouseFailure: action.payload,
            };

        case inventoryTypes.FETCH_SINGLE_INVENTORY_SUPPLIERS_REQUEST:
            return {
                ...state,
                fetchingSingleInventorySuppliers: true,
                fetchedSingleInventorySuppliersSuccess: null,
                fetchedSingleInventorySuppliersFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_INVENTORY_SUPPLIERS_SUCCESS:
            return {
                ...state,
                fetchingSingleInventorySuppliers: false,
                fetchedSingleInventorySuppliersSuccess: action.payload,
                fetchedSingleInventorySuppliersFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_INVENTORY_SUPPLIERS_FAILURE:
            return {
                ...state,
                fetchingSingleInventorySuppliers: false,
                fetchedSingleInventorySuppliersSuccess: null,
                fetchedSingleInventorySuppliersFailure: action.payload,
            };
        case inventoryTypes.FETCH_INVENTORY_HISTORY_REQUEST:
            return {
                ...state,
                fetchingInventoryHistory: true,
                fetchedInventoryHistorySuccess: null,
                fetchedInventoryHistoryFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_HISTORY_SUCCESS:
            return {
                ...state,
                fetchingInventoryHistory: false,
                fetchedInventoryHistorySuccess: action.payload,
                fetchedInventoryHistoryFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_HISTORY_FAILURE:
            return {
                ...state,
                fetchingInventoryHistory: false,
                fetchedInventoryHistorySuccess: null,
                fetchedInventoryHistoryFailure: action.payload,
            };

        case inventoryTypes.FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                fetchingSingleInventoryPurchaseOrder: true,
                fetchedSingleInventoryPurchaseOrderSuccess: null,
                fetchedSingleInventoryPurchaseOrderFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                fetchingSingleInventoryPurchaseOrder: false,
                fetchedSingleInventoryPurchaseOrderSuccess: action.payload,
                fetchedSingleInventoryPurchaseOrderFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_FAILURE:
            return {
                ...state,
                fetchingSingleInventoryPurchaseOrder: false,
                fetchedSingleInventoryPurchaseOrderSuccess: null,
                fetchedSingleInventoryPurchaseOrderFailure: action.payload,
            };

        case inventoryTypes.FETCH_INVENTORY_WAREHOUSE_REQUEST:
            return {
                ...state,
                fetchingInventoryWarehouse: true,
                fetchedInventoryWarehouseSuccess: null,
                fetchedInventoryWarehouseFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_WAREHOUSE_SUCCESS:
            return {
                ...state,
                fetchingInventoryWarehouse: false,
                fetchedInventoryWarehouseSuccess: action.payload,
                fetchedInventoryWarehouseFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_WAREHOUSE_FAILURE:
            return {
                ...state,
                fetchingInventoryWarehouse: false,
                fetchedInventoryWarehouseSuccess: null,
                fetchedInventoryWarehouseFailure: action.payload,
            };

        case inventoryTypes.FETCH_INVENTORY_ORDERS_REQUEST:
            return {
                ...state,
                fetchingInventoryOrders: true,
                fetchedInventoryOrdersSuccess: null,
                fetchedInventoryOrdersFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_ORDERS_SUCCESS:
            return {
                ...state,
                fetchingInventoryOrders: false,
                fetchedInventoryOrdersSuccess: action.payload,
                fetchedInventoryOrdersFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_ORDERS_FAILURE:
            return {
                ...state,
                fetchingInventoryOrders: false,
                fetchedInventoryOrdersSuccess: null,
                fetchedInventoryOrdersFailure: action.payload,
            };

        case inventoryTypes.FETCH_SINGLE_STOCK_WAREHOUSE_REQUEST:
            return {
                ...state,
                fetchingSingleStockWarehouse: true,
                fetchedSingleStockWareSuccess: null,
                fetchedSingleStockWarehouseFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_STOCK_WAREHOUSE_SUCCESS:
            return {
                ...state,
                fetchingSingleStockWarehouse: false,
                fetchedSingleStockWareSuccess: action.payload,
                fetchedSingleStockWarehouseFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_STOCK_WAREHOUSE_FAILURE:
            return {
                ...state,
                fetchingSingleStockWarehouse: false,
                fetchedSingleStockWareSuccess: null,
                fetchedSingleStockWarehouseFailure: action.payload,
            };

        case inventoryTypes.FETCH_SINGLE_STOCK_REQUEST:
            return {
                ...state,
                fetchingSingleStock: true,
                fetchedSingleStockSuccess: null,
                fetchedSingleStockFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_STOCK_SUCCESS:
            return {
                ...state,
                fetchingSingleStock: false,
                fetchedSingleStockSuccess: action.payload,
                fetchedSingleStockFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_STOCK_FAILURE:
            return {
                ...state,
                fetchingSingleStock: false,
                fetchedSingleStockSuccess: null,
                fetchedSingleStockFailure: action.payload,
            };
        case inventoryTypes.UPDATE_STOCK_REQUEST:
            return {
                ...state,
                updatingStock: true,
                updatedStockSuccess: null,
                updatedStockFailure: null,
            };
        case inventoryTypes.UPDATE_STOCK_SUCCESS:
            return {
                ...state,
                updatingStock: false,
                updatedStockSuccess: action.payload,
                updatedStockFailure: null,
            };
        case inventoryTypes.UPDATE_STOCK_FAILURE:
            return {
                ...state,
                updatingStock: false,
                updatedStockSuccess: null,
                updatedStockFailure: action.payload,
            };
        case inventoryTypes.FETCH_SINGLE_TRANSFER_STOCK_REQUEST:
            return {
                ...state,
                fetchingSingleTransferStock: true,
                fetchedSingleTransferStockSuccess: null,
                fetchedSingleTransferStockFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_TRANSFER_STOCK_SUCCESS:
            return {
                ...state,
                fetchingSingleTransferStock: false,
                fetchedSingleTransferStockSuccess: action.payload,
                fetchedSingleTransferStockFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_TRANSFER_STOCK_FAILURE:
            return {
                ...state,
                fetchingSingleTransferStock: false,
                fetchedSingleTransferStockSuccess: null,
                fetchedSingleTransferStockFailure: action.payload,
            };
        case inventoryTypes.FETCH_STOCK_ADJUSTMENT_LIST_REQUEST:
            return {
                ...state,
                fetchingStockAdjustmentList: true,
                fetchedStockAdjustmentSuccess: null,
                fetchedStockAdjustmentFailure: null,
            };
        case inventoryTypes.FETCH_STOCK_ADJUSTMENT_LIST_SUCCESS:
            return {
                ...state,
                fetchingStockAdjustmentList: false,
                fetchedStockAdjustmentSuccess: action.payload,
                fetchedStockAdjustmentFailure: null,
            };
        case inventoryTypes.FETCH_STOCK_ADJUSTMENT_LIST_FAILURE:
            return {
                ...state,
                fetchingStockAdjustmentList: false,
                fetchedStockAdjustmentSuccess: null,
                fetchedStockAdjustmentFailure: action.payload,
            };
        case inventoryTypes.FETCH_SINGLE_STOCK_ADJUSTMENT_REQUEST:
            return {
                ...state,
                fetchingSingleStockAdjustment: true,
                fetchedSingleStockAdjustmentSuccess: null,
                fetchedSingleStockAdjustmentFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_STOCK_ADJUSTMENT_SUCCESS:
            return {
                ...state,
                fetchingSingleStockAdjustment: false,
                fetchedSingleStockAdjustmentSuccess: action.payload,
                fetchedSingleStockAdjustmentFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_STOCK_ADJUSTMENT_FAILURE:
            return {
                ...state,
                fetchingSingleStockAdjustment: false,
                fetchedSingleStockAdjustmentSuccess: null,
                fetchedSingleStockAdjustmentFailure: action.payload,
            };
        case inventoryTypes.ADD_NEW_STOCK_ADJUSTMENT_REQUEST:
            return {
                ...state,
                addingNewStockAdjustment: true,
                addedNewStockAdjustmentSuccess: null,
                addedNewStockAdjustmentFailure: null,
            };
        case inventoryTypes.ADD_NEW_STOCK_ADJUSTMENT_SUCCESS:
            return {
                ...state,
                addingNewStockAdjustment: false,
                addedNewStockAdjustmentSuccess: action.payload,
                addedNewStockAdjustmentFailure: null,
            };
        case inventoryTypes.ADD_NEW_STOCK_ADJUSTMENT_FAILURE:
            return {
                ...state,
                addingNewStockAdjustment: false,
                addedNewStockAdjustmentSuccess: null,
                addedNewStockAdjustmentFailure: action.payload,
            };

        case inventoryTypes.ADD_INVENTORY_SETTINGS_REQUEST:
            return {
                ...state,
                addingInventorySettings: true,
                addedInventorySettingsSuccess: null,
                addedInventorySettingsFailure: null,
            };
        case inventoryTypes.ADD_INVENTORY_SETTINGS_SUCCESS:
            return {
                ...state,
                addingInventorySettings: false,
                addedInventorySettingsSuccess: action.payload,
                addedInventorySettingsFailure: null,
            };
        case inventoryTypes.ADD_INVENTORY_SETTINGS_FAILURE:
            return {
                ...state,
                addingInventorySettings: false,
                addedInventorySettingsSuccess: null,
                addedInventorySettingsFailure: action.payload,
            };

        case inventoryTypes.UPDATE_INVENTORY_SETTINGS_REQUEST:
            return {
                ...state,
                updatingInventorySettings: true,
                updatedInventorySettingsSuccess: null,
                updatedInventorySettingsFailure: null,
            };
        case inventoryTypes.UPDATE_INVENTORY_SETTINGS_SUCCESS:
            return {
                ...state,
                updatingInventorySettings: false,
                updatedInventorySettingsSuccess: action.payload,
                updatedInventorySettingsFailure: null,
            };
        case inventoryTypes.UPDATE_INVENTORY_SETTINGS_FAILURE:
            return {
                ...state,
                updatingInventorySettings: false,
                updatedInventorySettingsSuccess: null,
                updatedInventorySettingsFailure: action.payload,
            };

        case inventoryTypes.FETCH_INVENTORY_SETTINGS_REQUEST:
            return {
                ...state,
                fetchingInventorySettings: true,
                fetchedInventorySettingsSuccess: null,
                fetchedInventorySettingsFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_SETTINGS_SUCCESS:
            return {
                ...state,
                fetchingInventorySettings: false,
                fetchedInventorySettingsSuccess: action.payload,
                fetchedInventorySettingsFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_SETTINGS_FAILURE:
            return {
                ...state,
                fetchingInventorySettings: false,
                fetchedInventorySettingsSuccess: null,
                fetchedInventorySettingsFailure: action.payload,
            };

        case inventoryTypes.ADD_PRODUCT_VENDOR_REQUEST:
            return {
                ...state,
                addingProductVendor: true,
                addedProductVendorSuccess: null,
                addedProductVendorFailure: null,
            };
        case inventoryTypes.ADD_PRODUCT_VENDOR_SUCCESS:
            return {
                ...state,
                addingProductVendor: false,
                addedProductVendorSuccess: action.payload,
                addedProductVendorFailure: null,
            };
        case inventoryTypes.ADD_PRODUCT_VENDOR_FAILURE:
            return {
                ...state,
                addingProductVendor: false,
                addedProductVendorSuccess: null,
                addedProductVendorFailure: action.payload,
            };
        case inventoryTypes.FETCH_SINGLE_SUPPLIED_STOCK_REQUEST:
            return {
                ...state,
                fetchingSingleSuppliedStock: true,
                fetchedSingleSuppliedStockSuccess: null,
                fetchedSingleSuppliedStockFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_SUPPLIED_STOCK_SUCCESS:
            return {
                ...state,
                fetchingSingleSuppliedStock: false,
                fetchedSingleSuppliedStockSuccess: action.payload,
                fetchedSingleSuppliedStockFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_SUPPLIED_STOCK_FAILURE:
            return {
                ...state,
                fetchingSingleSuppliedStock: false,
                fetchedSingleSuppliedStockSuccess: null,
                fetchedSingleSuppliedStockFailure: action.payload,
            };
        case inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_REQUEST:
            return {
                ...state,
                fetchingSuppliedStockList: true,
                fetchedSuppliedStockListSuccess: null,
                fetchedSuppliedStockListFailure: null,
            };
        case inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_SUCCESS:
            return {
                ...state,
                fetchingSuppliedStockList: false,
                fetchedSuppliedStockListSuccess: action.payload,
                fetchedSuppliedStockListFailure: null,
            };
        case inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_FAILURE:
            return {
                ...state,
                fetchingSuppliedStockList: false,
                fetchedSuppliedStockListSuccess: null,
                fetchedSuppliedStockListFailure: action.payload,
            };
        case inventoryTypes.ADD_SUPPLIED_STOCK_REQUEST:
            return {
                ...state,
                addingSuppliedStock: true,
                addedSuppliedStockSuccess: null,
                addedSuppliedStockFailure: null,
            };
        case inventoryTypes.ADD_SUPPLIED_STOCK_SUCCESS:
            return {
                ...state,
                addingSuppliedStock: false,
                addedSuppliedStockSuccess: action.payload,
                addedSuppliedStockFailure: null,
            };
        case inventoryTypes.ADD_SUPPLIED_STOCK_FAILURE:
            return {
                ...state,
                addingSuppliedStock: false,
                addedSuppliedStockSuccess: null,
                addedSuppliedStockFailure: action.payload,
            };
        case inventoryTypes.UPDATE_SUPPLIED_STOCK_REQUEST:
            return {
                ...state,
                updatingSuppliedStock: true,
                updatedSuppliedStockSuccess: null,
                updatedSuppliedStockFailure: null,
            };
        case inventoryTypes.UPDATE_SUPPLIED_STOCK_SUCCESS:
            return {
                ...state,
                updatingSuppliedStock: false,
                updatedSuppliedStockSuccess: action.payload,
                updatedSuppliedStockFailure: null,
            };
        case inventoryTypes.UPDATE_SUPPLIED_STOCK_FAILURE:
            return {
                ...state,
                updatingSuppliedStock: false,
                updatedSuppliedStockSuccess: null,
                updatedSuppliedStockFailure: action.payload,
            };
        case inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_REQUEST:
            return {
                ...state,
                fetchingSuppliedStockListAllData: true,
                fetchedSuppliedStockListAllDataSuccess: null,
                fetchedSuppliedStockListAllDataFailure: null,
            };
        case inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_SUCCESS:
            return {
                ...state,
                fetchingSuppliedStockListAllData: false,
                fetchedSuppliedStockListAllDataSuccess: action.payload,
                fetchedSuppliedStockListAllDataFailure: null,
            };
        case inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_FAILURE:
            return {
                ...state,
                fetchingSuppliedStockListAllData: false,
                fetchedSuppliedStockListAllDataSuccess: null,
                fetchedSuppliedStockListAllDataFailure: action.payload,
            };
        default:
            return state;
    }
};
