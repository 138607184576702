import Button from "components/common/Button";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "redux/Ldb/actions";
import { businessCatgories } from "variables";
import countries from "variables/countries";
import AddressInput from "components/common/InputField/AddressInput";
import { getFromStorage } from "helpers";
import { toast } from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";

type BusinessInfoProps = {
    businessInfo: { [key: string]: any };
    setBusinessInfo: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
    address: { [key: string]: any };
    setAddress: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
    activeStep: number;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
};

const BusinessInfo = ({
    businessInfo,
    setBusinessInfo,
    address,
    setAddress,
    activeStep,
    setActiveStep,
}: BusinessInfoProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [profile, setProfile] = useState(() => getFromStorage("ally-user"));

    const { updateLdbProfileRequest, updateLdbProfileSuccess } = useAppSelector(
        (state) => state.auth
    );

    const handleChange = (
        evt: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }
    ) => {
        const { name, value } = evt.target;

        if (name?.toLowerCase() === "fullname") {
            const sanitizedValue = value.replace(/[^A-Za-z ]/g, "");

            setBusinessInfo((prev) => ({
                ...prev,
                [name]: sanitizedValue,
            }));
        } else {
            setBusinessInfo((prev) => {
                return {
                    ...prev,
                    [name]: value,
                };
            });
        }
    };

    const handleSubmit = () => {
        if (!address?.businessAddress?.address) {
            toast.custom((t) => (
                <CustomToast t={t} message={"Address is required!"} type="error" />
            ));
        }
        if (businessInfo.businessCountry === "") {
            toast.custom((t) => (
                <CustomToast t={t} message={"Country is required!"} type="error" />
            ));
        }
        const body = {
            country: businessInfo?.businessCountry,
            address: {
                address: address?.businessAddress.address,
                lng: address?.businessAddress.lng,
                lat: address?.businessAddress.lat,
                country: businessInfo?.businessCountry,
            },
            fullName: businessInfo?.fullName,
            businessType: businessInfo?.businessType,
            reason: businessInfo?.reason,
        };
        if (businessInfo.businessCountry && address?.businessAddress?.address) {
            dispatch(authActions.updateLdbProfile(body, profile?.token));
        }
    };

    useEffect(() => {
        if (Boolean(updateLdbProfileSuccess)) {
            setActiveStep(activeStep + 1);
            dispatch(authActions.resetProfileUpdate());
        }
    }, [dispatch, updateLdbProfileSuccess, activeStep, navigate, setActiveStep]);

    return (
        <div className="w-full flex justify-center">
            <div className="lg:w-[500px] w-[380px]">
                <div className="w-full mb-[36px] flex items-start justify-between">
                    <div>
                        <p className="mb-2 text-xl font-rocGroteskMedium text-g-75">
                            Tell us a bit about you and your business
                        </p>
                        <p className="text-sm font-rocGroteskRegular text-gm-40">
                            Verify your business by adding your information
                        </p>
                    </div>
                    <div></div>
                </div>
                <form
                    className="w-full"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <div className="flex flex-col gap-6 w-full">
                        <div className="flex space-x-5">
                            <TextInput
                                name={`fullName`}
                                type="text"
                                value={businessInfo?.fullName}
                                onChange={(e) => handleChange(e)}
                                placeholder="Full name"
                                required={true}
                            />
                            <TextInput
                                name={`businessName`}
                                type="text"
                                value={businessInfo?.businessName}
                                onChange={(e) => handleChange(e)}
                                placeholder="Business name"
                                required={true}
                                disabled={true}
                            />
                        </div>
                        <SelectInput
                            value={businessInfo.businessType}
                            name="businessType"
                            placeholder={"Select business category"}
                            handleChange={(name, value) =>
                                handleChange({ target: { name, value } })
                            }
                            className=""
                            isRequired={true}
                            dropdownOptions={businessCatgories.map((item) => ({
                                label: item.label,
                                value: item.value,
                            }))}
                        />
                        <AddressInput
                            setData={setAddress}
                            savedAddress={
                                address?.businessAddress ? address?.businessAddress?.address : ""
                            }
                            placeholder="Business address"
                            required={true}
                            name={"businessAddress"}
                        />
                        <SelectInput
                            value={businessInfo.businessCountry}
                            name="businessCountry"
                            placeholder={"Select business country"}
                            handleChange={(name, value) =>
                                handleChange({ target: { name, value } })
                            }
                            className=""
                            isRequired={true}
                            dropdownOptions={countries.map((item) => ({
                                label: item.name,
                                value: item.name,
                            }))}
                        />
                        <TextInput
                            name={"reason"}
                            type="text"
                            value={businessInfo.reason}
                            onChange={(e) => handleChange(e)}
                            placeholder="Major thing you want to use SynthAlly for"
                            required={true}
                        />
                    </div>
                    <div className="w-full mt-10">
                        <Button
                            btnText={"Next"}
                            isLoading={updateLdbProfileRequest}
                            type={"submit"}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BusinessInfo;
