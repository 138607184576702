import Loader from "components/common/Loader";
import useGetUserProfile from "hooks/useGetUserProfile";
import React from "react";

const NoMatchPage = () => {
    const [profile] = useGetUserProfile();
    return (
        <div className="">
            {!profile?.permission ? (
                <div
                    className={`w-full min-h-screen  flex items-center  justify-center`}
                >
                    <Loader color={"gm-50"} size={8} />
                </div>
            ) : (
                <div className="h-screen flex flex-col items-center justify-center">
                    <p className="font-rocGroteskMedium text-2xl mb-5">404</p>
                    <p className="font-rocGroteskMedium text-lg mb-5">PAGE NOT FOUND</p>
                </div>
            )}
        </div>
    );
};

export default NoMatchPage;
