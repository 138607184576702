import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import ReactFlow, {
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    Handle,
    Position,
    MarkerType,
    applyNodeChanges,
    useReactFlow,
    BaseEdge,
    EdgeLabelRenderer,
    getSmoothStepPath,
} from "reactflow";
import "reactflow/dist/style.css";
import Dropdown from "../Dropdown";
import SelectInput from "../InputField/SelectInput";
import Button from "../Button";
import { actions as actionsOpt, copilotActions, events as eventsOpt } from "variables/workflow";
import Checkbox from "../InputField/Checkbox";
import TextInput from "../InputField/TextInput";
import TextareaInput from "../InputField/TextareaInput";
import { useDispatch, useSelector } from "react-redux";
import { authActions, teamActions, vendorActions, workFlowActions } from "redux/Ldb/actions";
import { debounce, flattenDeep, set, sortBy, truncate, uniqBy } from "lodash";
import { displayFileIcon, findIndexWithLongestSubarray, getFromStorage } from "helpers";
import { toast } from "react-hot-toast";
import CustomToast from "../CustomToast";
import moment from "moment";
import Loader from "../Loader";
import ModalContainer from "../ModalContainer";
import { firebaseService } from "services/firebaseService";
import { convertBytes } from "helpers/displayFileSize";
import { isFileSizeLessThanOrEqualTo } from "helpers/compareFileSizeInMb";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { io } from "socket.io-client";
import { ThreeDots } from "react-loader-spinner";
import config from "config/config";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";
import { convertSeconds, parseConvertedSeconds } from "helpers/convertSeconds";
import StatusBadge from "../StatusBadge";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import ToggleInput from "../InputField/ToggleInput";
import Accordion from "../Accordion";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import { HTML5Backend } from "react-dnd-html5-backend";
import MultiselectInput from "../InputField/MultiselectInput";
import AvatarContainer from "../VendorAvatar";
import { setDoc } from "firebase/firestore";
import { accessTypes } from "variables";
import { ShowRolesAndResponsibiltyModal } from "components/views/Ldb/Dashboard/Teams/ShowRolesAndResponsibilty";
import { getEditPermissionList } from "helpers/getEditPermissionList";

const socket = io(`${config.API_URL}`);

const WorkflowBoard = ({
    workflowId,
    workflowCat,
    workflowData,
    setPlayable,
    currentStatus,
    profile,
    customer,
    setUserInteract,
    customEvents,
    customActions,
    creator,
    isTemplate,
}) => {
    const eventNodeWidth = 505;
    const actionNodeWidth = 419;
    const [openDetails, setOpenDetails] = useState(false);
    const [customEventModal, setCustomEventModal] = useState(false);
    const [customActionModal, setCustomActionModal] = useState(null);
    const [currencWorflowData, setCurrentWorkflowData] = useState([]);
    const [actions, setActions] = useState([]);
    const [events, setEvents] = useState([]);
    const [openComment, setOpenComment] = useState(false);
    const [deleteEventModal, setDeleteEventModal] = useState(false);
    const [attachDocModal, setAttachDocModal] = useState(false);
    const [seeAllDocsModal, setSeeAllDocsModal] = useState(false);
    const [deleteDocModal, setDeleteDocModal] = useState(false);
    const [eventModal, setEventModal] = useState(false);
    const [taskActionModal, setTaskActionModal] = useState(false);
    const [approvalActionModal, setApprovalActionModal] = useState(false);
    const [selectedNodeId, setSelectedNodeId] = useState("");
    const [selectedActionName, setSelectedActionName] = useState("");
    const [customEvent, setCustomEvent] = useState("");
    const [customAction, setCustomAction] = useState("");
    const [customActionType, setCustomActionType] = useState("");
    const [workFlowList, setWorkFlowList] = useState([]);
    const [thisActionDocs, setThisActionDocs] = useState([]);
    const [docToDelete, setDocToDelete] = useState({
        name: "",
        actionId: "",
        docId: "",
    });

    const [draggedNode, setDraggedNode] = useState({});
    const [selectedAction, setSelectedAction] = useState({});
    const [searchActions, setSearchActions] = useState("");

    const [teamMembers, setTeamMembers] = useState([]);
    const [retryModal, setRetryModal] = useState(false);
    const [actionSummaryModal, setActionSummaryModal] = useState(false);
    const [markAsDoneModal, setMarkAsDoneModal] = useState(false);

    const [vendors, setVendors] = useState([]);
    const [open, setOpen] = useState(false);
    const [deletingDoc, setDeletingDoc] = useState(false);
    const [addNote, setAddNote] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [AILoading, setAILoading] = useState(false);
    const [duplicateModal, setDuplicateModal] = useState(false);
    const [docData, setDocData] = useState(null);
    const [docUrl, setDocUrl] = useState([]);
    const [newEvent, setNewEvent] = useState({
        eventName: "",
        actions: [],
    });
    const [eventValue, setEventValue] = useState("");
    const [updatedEvent, setUpdatedEvent] = useState("");
    const [approvalField, setApprovalField] = useState(true);

    const [userProfile, setUserProfile] = useState(() => getFromStorage("ally-user"));
    const [inviteMember, setInviteMember] = useState(false);
    const [teamInfo, setTeamInfo] = useState([{ "email-0": "", "role-0": "" }]);
    const [showRolesAndResponsibiltyModal, setShowRolesAndResponsibiltyModal] = useState(false);
    const [debouncedSearch, setDebouncedSearch] = useState({
        search: "",
    });
    const [addCustomRole, setAddCustomRole] = useState(false);
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState("");
    const [selectedPermissions, setSelectedPermissions] = useState([]);

    const [newAction, setNewAction] = useState({
        actionName: "",
        assigneeType: "",
        isCopilot: false,
        assignedTo: "",
        actionTimeline: "",
        durationValue: "",
        durationUnit: "",
        emailSubject: "",
        emailBody: "",
        mailTo: "",
        sendTo: "",
        actionCat: "",
        rules: [],
        document: [],
        note: "",
        approval: [],
        linkToWorkflow: false,
        workflowToLink: "",
    });
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");
    const [approvalValue, setApprovalValue] = useState("");
    const selectedOption = teamMembers
        ?.map((member) => ({
            label: member?.parent?.fullName,
            subLabel: member?.role?.[0]?.toUpperCase() + member?.role?.slice(1),
            subText: member?.email,
            value: member?._id,
            icon: (
                <img
                    src={member?.parent?.avatar}
                    alt="avatar"
                    className="w-10 h-10 object-cover rounded-full"
                />
            ),
        }))
        ?.find((item) => item.value === selectedValue);

    const filterApprovalOption = teamMembers
        ?.map((member) => ({
            label: member?.fullName || member?.email,
            subLabel: member?.role?.[0]?.toUpperCase() + member?.role?.slice(1),
            subText: member?.fullName ? member?.email : "",
            value: member?._id,
            icon: (
                <AvatarContainer
                    size={40}
                    imageSrc={member?.avatar}
                    name={member?.fullName || member?.email}
                />
            ),
        }))
        ?.filter(
            (item) =>
                item.value?.toLowerCase()?.includes(approvalValue?.toLowerCase()) ||
                item.label?.toLowerCase()?.includes(approvalValue?.toLowerCase()) ||
                item.subText?.toLowerCase()?.includes(approvalValue?.toLowerCase()) ||
                item.subLabel?.toLowerCase()?.includes(approvalValue?.toLowerCase())
        );

    const ref = useRef(null);
    const togglRef = useRef(null);

    const [, drop] = useDrop({
        accept: "box",
        drop: (item) => handleDrop(item),
    });

    const DraggableBox = ({ id, text, value, custom, icon, category }) => {
        const [{ isDragging }, drag] = useDrag({
            type: "box",
            item: { id, text, value, custom, category },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        });

        return (
            <div
                ref={drag}
                onClick={() => custom && setCustomActionModal(true)}
                className={`${
                    isDragging ? "opacity-50 cursor-grabbing" : "opacity-100 cursor-grab"
                } ${
                    custom && "!cursor-pointer"
                } p-2 rounded-[10px] border border-dashed bg-slate-50 flex items-center gap-2 `}
            >
                {icon && <span>{icon}</span>}
                <span className="text-[13px] font-rocGroteskMedium">{text}</span>
            </div>
        );
    };

    const DraggableContainer = ({ index, member }) => {
        const moveContainer = (fromIndex, toIndex) => {
            const team = selectedItems?.map?.((memberId) =>
                teamMembers?.find((member) => member?._id === memberId)
            );
            const newContainers = [...team];
            let temp = newContainers[fromIndex];
            newContainers[fromIndex] = newContainers[toIndex];
            newContainers[toIndex] = temp;
            setSelectedTeam(newContainers);
            setSelectedItems(newContainers?.map((opt) => opt?._id));
        };

        const [{ isDragging }, ref] = useDrag({
            type: "CONTAINER",
            item: { index },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        });

        const [, drop] = useDrop({
            accept: "CONTAINER",
            drop: (item) => {
                moveContainer(item.index, index);
            },
        });

        return (
            <div
                ref={(node) => ref(drop(node))}
                className={`flex relative items-center space-x-2 cursor-grab ${
                    isDragging ? "opacity-50 cursor-grabbing" : "opacity-100 cursor-grab"
                }`}
            >
                <div className="grid grid-cols-[85%_15%] border-b border-x border-slate-200 w-full ">
                    <div className="px-4 py-2 border-r border-slate-200">
                        <div className="text-sm font-rocGroteskMedium">
                            <span>{member?.fullName || member?.email}</span>
                            {" - "}

                            <span className={"text-xs text-p-50 font-rocGroteskMedium"}>
                                {member?.role?.[0]?.toUpperCase() + member?.role?.slice(1)}
                            </span>
                        </div>

                        <div className="text-xs font-rocGroteskMedium text-slate-500">
                            {member?.fullName ? member?.email : ""}
                        </div>
                    </div>
                    <div className="flex w-full justify-center items-center px-4 py-2">
                        <p className="text-xs font-rocGroteskMedium">{index + 1}</p>
                    </div>
                </div>

                <div className="flex items-center absolute right-[-20px]">
                    <i className="ri-draggable text-slate-400 cursor-grab text-[14px]"></i>
                </div>
            </div>
        );
    };

    const { getIntersectingNodes } = useReactFlow();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const createdThrough = params.get("createdBy");
    const isRun = params.get("run");

    const {
        addingTeamMember,
        addTeamMemberSuccess,
        fetchingRoles,
        fetchedRolesSuccess,
        createRoles,
        createRolesSuccess,
        fetchedTeamMemberSuccess,
    } = useSelector((state) => state.auth);
    const {
        creatingEventAndAction,
        createEventAndActionSuccess,
        updateWorkflowConnectionsFailure,
        updatingEventsAndActions,
        deleteEventSuccess,
        updateEventsAndActionsSuccess,
        fetchedSingleWorkflowSuccess,
        fetchedWorkFlowListSuccess,
        deletingAction,
        deleteActionSuccess,
        swapNodeSuccess,
        fetchingEventAndActionOptionList,
        fetchedEventAndActionOptionListSuccess,
        retryingAction,
        retryActionSuccess,
        markingActionAsDone,
        markActionAsDoneSuccess,
        creatingAction,
        createActionSuccess,
        updatingAction,
        updateActionSuccess,
        updatingEvent,
        updateEventSuccess,
        duplicatingAction,
        duplicateActionSuccess,
        createMultipleActionsSuccess,
    } = useSelector((state) => state.workFlow);
    const { fetchedOnboardedVendorsSuccess } = useSelector((state) => state.vendor);

    const initialNodes = [];

    const initialEdges = [];

    const noteRef = useRef(null);
    const containerRef = useRef(null);

    const [nodes, setNodes] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const filteredActions = [
        { action: "Add a custom action", value: "customAction", icon: "", category: "", type: "" },
        ...actions,
    ]?.filter((data) => data?.action?.toLowerCase()?.includes(searchActions));

    function handleDrop(item) {
        if (item?.custom) {
            setCustomActionModal(true);
        } else {
            setNewAction((prev) => ({ ...prev, actionName: item?.value }));
            if (item?.category?.includes("task")) {
                setTaskActionModal(true);
            } else {
                setApprovalActionModal(true);
            }
        }
    }

    const handleEventChange = (evt, ruleId) => {
        const { name, value } = evt?.target;

        const actions = { ...newAction };
        const updatedRule = actions.rules[ruleId - 1];
        updatedRule[name] = value;

        setNewAction(actions);
    };

    const handleRemoveRule = (ruleId) => {
        const action = { ...newAction };
        const rules = action.rules;
        rules.splice(ruleId, 1);

        setNewAction(action);
    };

    const handleDeleteAction = (id, nodeId) => {
        if (Boolean(nodeId)) {
            return dispatch(workFlowActions.deleteAction(nodeId));
        }
        const newEventActions = [...newEvent.actions];

        newEventActions?.splice(id, 1);

        setNewEvent((prev) => ({
            ...prev,
            actions: newEventActions,
        }));
        setDeleteEventModal(false);
    };

    const handleAddLinkToWorkflow = () => {
        const action = { ...newAction };
        action.linkToWorkflow = true;

        setNewAction(action);
    };

    const handleRemoveLinkToWorkflow = () => {
        const action = { ...newAction };
        action.linkToWorkflow = false;
        action.workflowToLink = "";

        setNewAction(action);
    };

    const handleCloseSidebar = () => {
        setOpenDetails(false);
    };

    const handleAddNode = (data, isUpdate) => {
        const evt = events?.find((event) => event.value === data?.name);

        const eventActions = data.workFlowActions?.map((action, idx) => {
            const eventAction = actions.find((act) => act.value === action?.name);
            return {
                eventId: data?.id,
                id: action?._id,
                position: action?.position,
                data: {
                    string: eventAction?.action,
                    values: { id: data?._id, position: action?.position, ...newEvent },
                    actionData: {
                        actionName: action?.name + "-" + action?.actionType,
                        assigneeType: action?.assigneeType,
                        isCopilot: action?.assigneeType?.toLowerCase() === "copilot" ? true : false,
                        assignedTo: action?.assigneeId,
                        actionTimeline: "",
                        updatedAt: action?.updatedAt,
                        durationValue: action?.duration?.value,
                        durationUnit: action?.duration?.unit,
                        emailSubject: action?.data?.email?.subject,
                        emailBody: action?.data?.email?.body,
                        mailTo: action?.data?.email?.mailTo,
                        sendTo: action?.data?.email?.sendTo,
                        rules: action?.actionRules?.map((rule) => ({
                            id: rule?._id,
                            rule: rule?.action,
                            complete: rule?.status?.toLowerCase() === "completed" ? true : false,
                        })),
                        actionCat: action?.actionCategory,
                        document: action?.documents,
                        note: action?.comments ?? "",
                        approval: action?.approvals,
                        //                 linkToWorkflow: action?.nextWorkFlow? true : false,
                        // workflowToLink: action?.nextWorkFlow,
                        linkToWorkflow: Boolean(action?.nextWorkFlowId),
                        workflowToLink: action?.nextWorkFlowId,
                    },
                    id: action?._id,
                    type: "action",
                    fixedX: action?.position?.x,
                },
                deletable: false,
                type: "action",
                style: {
                    width: actionNodeWidth,
                    minHeight: 115,
                    maxHeight: 200,
                    background: "#F8FAFC",
                    borderRadius: "8px",
                },
            };
        });

        const newNodes = [
            {
                id: data?._id,
                type: "event",
                position: data?.position,
                data: {
                    content: (
                        <div className="flex flex-col gap-3">
                            <p className="text-sm text-slate-800 font-rocGroteskMedium">
                                {evt?.event}
                            </p>
                        </div>
                    ),
                    id: data?._id,
                    status: data?.status,
                    values: {
                        id: data?._id,
                        position: data?.position,
                        eventType: data?.eventType,
                        ...newEvent,
                    },
                    type: "event",
                    first: nodes?.[0]?.type === "add",
                    fixedX: data?.position?.x,
                },

                deletable: false,
                style: {
                    width: eventNodeWidth,

                    maxHeight: 200,
                    background: "white",
                    borderRadius: "8px",
                },
            },
            ...eventActions,
        ];

        setNodes((nds) => {
            const updatedNode = [
                ...newNodes,
                {
                    id: "add-new",
                    position: {
                        x: window.innerWidth / 4 + eventNodeWidth - 45 / 2,
                        y: newNodes?.slice(-1)?.[0]?.position?.y + 200,
                    },
                    type: "add",
                    data: {
                        values: newNodes?.[0]?.data?.values,
                        fixedX: window.innerWidth / 4 + eventNodeWidth - 45 / 2,
                        fixedY: newNodes?.slice(-1)?.[0]?.position?.y + 200,
                    },
                    deletable: false,
                    style: {
                        // width: "fit-content",
                        width: "fit-content",
                        height: "auto",
                        background: "white",
                        borderRadius: "4px",
                    },
                },
            ];

            return [...updatedNode];
        });
        setEdges((eds) => {
            const connections = newNodes?.map((node, idx) => {
                const edgeObj = {
                    id: `e${node.id}-${newNodes?.[idx + 1]?.id}`,
                    source: node.id,
                    target: newNodes?.[idx + 1]?.id,
                    // type: "smoothstep",
                    type: isRun ? "custom" : "smoothstep",
                    data: node,
                    deletable: false,
                    animated: true,
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 18,
                        height: 18,
                        color: "#6562E5",
                    },
                    style: {
                        stroke: "#6562E5",
                        strokeWidth: 1.5,
                    },
                };

                const runtime = parseConvertedSeconds(
                    convertSeconds(workflowData?.workFlowActions?.[idx]?.runTime)
                );

                if (isRun && workflowData?.workFlowActions?.[idx]?.runTime) {
                    edgeObj.data = {
                        ...edgeObj?.data,
                        label: `Runtime: ${runtime}`,
                    };
                }
                return edgeObj;
            });

            return uniqBy([...connections], "source");
        });
    };

    const handleUpdateEventAndAction = (data) => {
        let count = 0;
        const body = data
            ? {
                  eventObject: {
                      name: data?.eventName?.split("-")?.[0],
                      position: data?.position,
                      data: {},
                  },
                  actions: data?.actions?.map((action, idx) => {
                      const actionBody = {
                          actionRules: action?.rules?.map((rule) => ({
                              action: rule?.rule,
                          })),
                          position: action?.position || {
                              x: nodes?.slice(-2)?.[0]?.position?.x,
                              y: (nodes?.slice(-2)?.[0]?.position?.y || 0) + 150 * (idx + 1.2),
                          },
                          duration: {
                              value: Number(action?.durationValue),
                              unit: action?.durationUnit,
                          },
                          documents: action?.documents?.map((doc) => ({
                              path: doc?.path,
                              name: doc?.name,
                          })),
                          assigneeType: action?.assigneeType,
                          name: action?.actionName,
                          //   nextWorkFlowId: action.workflowToLink ?? "",
                      };

                      if (Boolean(action.workflowToLink)) {
                          actionBody.nextWorkFlowId = action.workflowToLink;
                      }

                      if (Boolean(action?.id)) {
                          actionBody._id = action?.id;
                      }

                      if (
                          action.assigneeType?.toLowerCase() === "ldb" ||
                          action.assigneeType === "vendor"
                      ) {
                          actionBody.assigneeId = action.assignedTo;
                      }

                      if (action.actionName?.toLowerCase()?.includes("sendemail")) {
                          actionBody.data = {
                              email: {
                                  mailTo: action?.mailTo,
                              },
                          };
                      }

                      if (action.actionName?.toLowerCase()?.includes("generateemail")) {
                          actionBody.data = {
                              email: {
                                  subject: action?.emailSubject,
                                  body: action?.emailBody,
                              },
                          };
                      }

                      return actionBody;
                  }),
              }
            : {
                  eventObject: {
                      name: newEvent?.eventName?.split("-")?.[0],
                      position: newEvent?.position,
                      data: {},
                  },
                  actions: newEvent?.actions?.map((action, idx) => {
                      const actionBody = {
                          actionRules: action?.rules?.map((rule) => ({
                              action: rule?.rule,
                          })),
                          position: action?.position || {
                              x:
                                  window.innerWidth / 4 +
                                  eventNodeWidth / 2 +
                                  (eventNodeWidth - actionNodeWidth) / 2,
                              y: (nodes?.slice(-2)?.[0]?.position?.y || 0) + 170 * (count + 1.2),
                          },
                          duration: {
                              value: Number(action?.durationValue),
                              unit: action?.durationUnit,
                          },
                          assigneeType: action?.assigneeType,
                          name: action?.actionName,
                          //   nextWorkFlowId: action.workflowToLink ?? "",
                      };

                      if (!Boolean(action?.id)) {
                          count++;
                      }

                      if (Boolean(action.workflowToLink)) {
                          actionBody.nextWorkFlowId = action.workflowToLink;
                      }

                      if (action?.documents && action?.documents?.length > 0) {
                          actionBody.documents = action.documents;
                      }

                      if (Boolean(action?.id)) {
                          actionBody._id = action?.id;
                      }

                      if (
                          action.assigneeType?.toLowerCase() === "ldb" ||
                          action.assigneeType === "vendor"
                      ) {
                          actionBody.assigneeId = action.assignedTo;
                      }

                      if (action.actionName?.toLowerCase()?.includes("sendemail")) {
                          actionBody.data = {
                              email: {
                                  mailTo: action?.mailTo,
                              },
                          };
                      }

                      if (action.actionName?.toLowerCase()?.includes("generateemail")) {
                          actionBody.data = {
                              email: {
                                  subject: action?.emailSubject,
                                  body: action?.emailBody,
                              },
                          };
                      }

                      return actionBody;
                  }),
              };

        if (newEvent.eventName?.split("-")?.[1] || data?.eventName?.split("-")?.[1]) {
            body.eventObject.eventType =
                newEvent.eventName?.split("-")?.[1] || data?.eventName?.split("-")?.[1];
        }

        dispatch(workFlowActions.updateEventAndAction(newEvent?.id, body));
    };

    const handleTaskCreationChange = (name, value) => {
        setNewAction((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    console.log("newAction", newAction);
    const handleUpdateAction = () => {
        const updateBody = {
            position: {
                x:
                    window.innerWidth / 4 +
                    eventNodeWidth / 2 +
                    (eventNodeWidth - actionNodeWidth) / 2,
                y: nodes?.find((d) => d?.id === selectedNodeId)?.position?.y,
            },
            // data: {},
            actionCategory: approvalActionModal ? "approval" : "task_action",
            name: newAction.actionName?.split("-")?.[0],
            documents: [],
            duration: {
                value: Number(newAction.durationValue),
                unit: newAction.durationUnit,
            },
        };

        if (newAction?.workflowToLink) {
            updateBody.nextWorkFlowId = newAction?.workflowToLink;
        }

        if (approvalActionModal) {
            updateBody.approvals = selectedTeam?.map((member) => member?._id);
        }

        if (newAction?.rules?.length > 0) {
            updateBody.actionRules = newAction?.rules?.map((rule) => {
                const ruleObj = {
                    action: rule?.rule,
                };

                if (rule?.id) {
                    ruleObj._id = rule?.id;
                }

                return ruleObj;
            });
        }

        if (docUrl?.length > 0) {
            updateBody.documents = docUrl?.map((data) => ({
                path: data?.url,
                name: data?.name,
            }));
        }

        if (!approvalActionModal) {
            if (!newAction.isCopilot) {
                updateBody.assignee = newAction?.assignedTo;
                updateBody.assigneeType = "LDB";
            } else {
                updateBody.assigneeType = "copilot";
            }
        }

        if (Boolean(newAction.note)) {
            updateBody.comments = newAction.note;
        }

        if (newAction.actionName?.split("-")?.[0]?.toLowerCase() === "sendemail") {
            updateBody.data = {
                email: {
                    mailTo: newAction?.mailTo,
                    sendTo: newAction?.sendTo,
                },
            };
        }

        if (newAction.actionName?.split("-")?.[0]?.toLowerCase() === "generateemail") {
            updateBody.data = {
                email: {
                    subject: newAction?.emailSubject,
                    body: newAction?.emailBody,
                },
            };
        }

        dispatch(workFlowActions.updateAction(updateBody, selectedNodeId));
    };

    const handleCreateAction = () => {
        const body = {
            name: newAction.actionName?.split("-")?.[0],
            // workFlowId: workflowId,
            workFlowEventId: nodes?.[0]?.id,
            position: {
                x:
                    window.innerWidth / 4 +
                    eventNodeWidth / 2 +
                    (eventNodeWidth - actionNodeWidth) / 2,
                y: nodes?.slice(-2)?.[0]?.position?.y + 200,
            },
            actionCategory: approvalActionModal ? "approval" : "task_action",
            previousConnection: {
                connectionId: nodes?.slice(-2)?.[0]?.id,
                connectionType: nodes?.length <= 2 ? "WorkFlowEvent" : "WorkFlowAction",
            },
            actionType: newAction.actionName?.split("-")?.[1],
            duration: {
                value: Number(newAction.durationValue),
                unit: newAction.durationUnit,
            },
        };

        if (newAction?.workflowToLink) {
            body.nextWorkFlowId = newAction?.workflowToLink;
        }

        if (approvalActionModal) {
            body.approvals = selectedTeam?.map((member) => member?._id);
        }

        if (newAction?.rules?.length > 0) {
            body.actionRules = newAction?.rules?.map((rule) => ({
                action: rule?.rule,
            }));
        }

        if (docUrl?.length > 0) {
            body.documents = docUrl?.map((data) => ({
                path: data?.url,
                name: data?.name,
            }));
        }

        if (body.actionCategory === "task_action") {
            if (!newAction.isCopilot) {
                body.assigneeId = newAction.assignedTo;
                body.assigneeType = "LDB";
            } else {
                body.assigneeType = "copilot";
            }
        } else {
            body.assigneeType = "LDB";
        }

        if (Boolean(newAction.note)) {
            body.comments = newAction.note;
        }

        if (newAction.actionName?.split("-")?.[0]?.toLowerCase() === "sendemail") {
            body.data = {
                email: {
                    mailTo: newAction?.mailTo,
                    sendTo: newAction?.sendTo,
                },
            };
        }

        if (newAction.actionName?.split("-")?.[0]?.toLowerCase() === "generateemail") {
            body.data = {
                email: {
                    subject: newAction?.emailSubject,
                    body: newAction?.emailBody,
                },
            };
        }

        dispatch(workFlowActions.createAction(body));
    };

    const handleRemoveDoc = (actionId, docId) => {
        if (actionId && docId) {
            const eventCopy = { ...newEvent };
            const eventActionCopy = [...eventCopy.actions];
            const findEventActionIdx = eventActionCopy?.findIndex(
                (eventAct) => eventAct?.id === actionId
            );

            if (findEventActionIdx !== -1) {
                const eventActionDocCopy = [...eventActionCopy[findEventActionIdx]?.documents];
                const findEventActionDocIdx = eventActionDocCopy?.findIndex(
                    (eventActDoc) => eventActDoc?._id === docId
                );

                if (findEventActionDocIdx !== -1) {
                    eventActionDocCopy.splice(findEventActionDocIdx, 1);
                }

                eventActionCopy[findEventActionIdx].documents = eventActionDocCopy;
            }

            handleUpdateEventAndAction(eventCopy);
        }
    };

    const handleRemoveDocument = (index, fileName) => {
        setDeletingDoc(true);

        firebaseService.removeFile(
            `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/workflow/${
                profile?.profile?.businessName
            }/${fetchedSingleWorkflowSuccess?.workFlow?.name}/${selectedActionName}/${fileName}`,
            () => {
                const newActionCopy = JSON.parse(JSON.stringify({ ...newAction }));
                const docsCopy = JSON.parse(JSON.stringify([...docUrl]));
                newActionCopy?.document.splice(index, 1);
                docsCopy.splice(index, 1);

                setDocUrl(docsCopy);
                setNewAction(newActionCopy);
                setDeletingDoc(false);
                setDeleteDocModal(false);
                setAttachDocModal(false);
            }
        );
    };

    const handleDeleteEvent = () => {
        const nodesCopy = [...nodes];
        const workflowEvent = currencWorflowData?.find((data) => data?._id === selectedNodeId);

        const eventActions = workflowEvent?.workFlowActions?.map((action) => {
            return action?._id;
        });

        const allNodes = [workflowEvent?._id, ...eventActions];
        const remainingNodes = nodesCopy?.filter((nod) => !allNodes?.includes(nod?.id));

        if (remainingNodes?.length <= 1) {
            remainingNodes[0].data = {
                eventName: "",
                actions: [],
            };
        }

        setNodes(remainingNodes);
    };

    const handleUpload = (fileName, file) => {
        if (fileName && file) {
            setUploading(true);
            // setDocData(file);
            firebaseService.uploadFile(
                `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/workflow/${
                    profile?.profile?.businessName
                }/${
                    fetchedSingleWorkflowSuccess?.workFlow?.name
                }/${selectedActionName}/${fileName}`,
                file,
                (url) => {
                    setDocUrl((prev) => [...prev, { name: fileName, url }]);
                    setUploading(false);
                    setAttachDocModal(false);
                }
            );
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const body = [];
        const filteredTeamInfo = teamInfo.filter(
            (member, index) => member[`email-${index}`] && member[`role-${index}`]
        );

        filteredTeamInfo.map((member, index) => {
            const teamMember = {
                email: member[`email-${index}`],
                role: member[`role-${index}`],
            };

            body.push(teamMember);
        });

        const teamsBody = { teamMembers: body };

        dispatch(authActions.addTeamMember(teamsBody, userProfile?.token));
    };

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedTeamInfo = [...teamInfo];
        updatedTeamInfo[index] = {
            ...updatedTeamInfo[index],
            [name]: value,
        };
        setTeamInfo(updatedTeamInfo);
    };

    const onDelete = (index) => {
        const updatedTeamInfo = [...teamInfo];
        updatedTeamInfo.splice(index, 1);
        setTeamInfo(updatedTeamInfo);
    };

    const handlePermissionChange = (permission) => {
        const isSelected = selectedPermissions.includes(permission);

        if (isSelected) {
            const updatedPermissions = selectedPermissions.filter((item) => item !== permission);
            setSelectedPermissions(updatedPermissions);
        } else {
            setSelectedPermissions([...selectedPermissions, permission]);
        }
    };

    const handleAddRole = (e) => {
        e.preventDefault();
        const permission = selectedPermissions;
        if (role === "") {
            toast.custom((t) => (
                <CustomToast t={t} message={"Role name is required"} type="error" />
            ));
        }
        if (role !== "") {
            const teamMember = {
                role,
                ...(permission.length > 0 && { permission }),
            };
            dispatch(authActions.createRoles(teamMember, profile?.token));
        }
    };

    const addNewInputField = () => {
        const newInputField = {
            email: "",
            role: "",
            permissions: "",
        };
        setTeamInfo([...teamInfo, newInputField]);
    };

    const EventNode = ({ data }) => {
        return (
            <>
                <div
                    className="relative"
                    onClick={() => {
                        if (!isRun && !isTemplate) {
                            setEventModal(true);
                        }

                        setEventValue(data?.name);
                    }}
                >
                    <div className="absolute top-[-72px] left-[175.7px] cursor-default bg-white rounded-[50px] w-[153px] flex items-center justify-center shadow-[0px_7px_24px_-6px_rgba(0,0,0,0.10)] py-3 px-[26px]">
                        <div className="flex items-center gap-5">
                            <span className="text-base font-rocGroteskMedium">Start</span>

                            <span className="w-10 h-10 rounded-full bg-white flex items-center justify-center shadow-[0px_2px_8px_0px_rgba(0,0,0,0.06)]">
                                <i className="ri-play-line text-[22px]"></i>
                            </span>
                        </div>
                    </div>
                    <div
                        className={`p-6 flex flex-col gap-3 cursor-pointer rounded border-l-p-50 border-l-[3px] bg-white shadow-[0px_4px_24px_0px_rgba(0,0,0,0.03)]`}
                        onClick={() => {
                            setNewEvent(data?.values);
                            if (!isRun) {
                                setOpenDetails(true);
                            }

                            setSelectedNodeId(data?.id);
                        }}
                    >
                        <div className="flex items-center justify-between gap-1.5">
                            <div className="flex items-center gap-2.5">
                                <div className="p-1 border rounded-full border-p-50">
                                    <div className="w-10 h-10 rounded-full bg-p-50 flex items-center justify-center">
                                        <i className="ri-play-line text-[22px] text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <p className="text-base font-rocGroteskMedium">
                                        {data?.content}
                                    </p>
                                </div>
                            </div>
                            {!isRun && !isTemplate && (
                                <div
                                    onClick={() => {
                                        setEventModal(true);
                                        setEventValue(data?.name);
                                    }}
                                    className="flex items-center gap-2 cursor-pointer"
                                >
                                    <span className="w-6 h-[18px] rounded-[134px] border border-[#505F79] flex items-center justify-center">
                                        <i className="ri-edit-2-fill text-[#505F79] text-xs"></i>
                                    </span>
                                    <span className="text-[#505F79] font-rocGroteskMedium text-base underline">
                                        Edit
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <Handle type="source" position={Position.Bottom} />
            </>
        );
    };

    const ActionNode = ({ data }) => {
        const [userComment, setUserComment] = useState("");
        const [nodeComments, setNodeComments] = useState([]);
        const thisAction = data?.values?.actions?.find((act) => act?.id === data?.id);
        const memberAssignedTo = teamMembers?.filter((member) =>
            data?.actionData?.assignedTo?.includes(member?._id)
        );
        const approvalMembers = teamMembers?.filter((member) =>
            data?.actionData?.approval?.includes(member?._id)
        );

        // const {
        //     creatingComment,
        //     createCommentSuccess,
        //     fetchingNodeComments,
        //     fetchedNodeCommentsSuccess,
        // } = useSelector((state) => state.workFlow);

        // const handleSendComment = (id, type, msg) => {
        //     const body = {
        //         comment: msg,
        //         commentSourceId: id,
        //         commentType: type?.toLowerCase() === "event" ? "WorkFlowEvent" : "WorkFlowAction",
        //         workFlowId: workflowId,
        //     };

        //     dispatch(workFlowActions.createComment(body));
        // };

        // useEffect(() => {
        //     if (Boolean(createCommentSuccess)) {
        //         setUserComment("");
        //         if (createCommentSuccess?.workFlowComment?.commentSourceId === data?.id) {
        //             setNodeComments((prev) => [...prev, createCommentSuccess?.workFlowComment]);
        //         }

        //         dispatch(workFlowActions.resetCreateCommentSuccess());
        //     }
        // }, [dispatch, createCommentSuccess]);

        // useEffect(() => {
        //     // if (selectedNodeId === data?.id) {
        //     dispatch(workFlowActions.getNodeComments(data?.id));
        //     // }
        // }, [dispatch, openComment]);

        // useEffect(() => {
        //     if (Boolean(fetchedNodeCommentsSuccess)) {
        //         if (
        //             fetchedNodeCommentsSuccess?.workFlowComments?.[0]?.commentSourceId === data?.id
        //         ) {
        //             const comments = sortBy(fetchedNodeCommentsSuccess?.workFlowComments, [
        //                 function (o) {
        //                     return o.createdAt;
        //                 },
        //             ]);
        //             setNodeComments(comments);
        //         }
        //     }
        // }, [fetchedNodeCommentsSuccess]);

        return (
            <>
                <Handle type="target" position={Position.Top} />
                <div
                    className="relative"
                    onClick={() => {
                        if (!isTemplate) {
                            setNewAction(data?.actionData);
                            setSelectedNodeId(data?.id);
                            setDocUrl(
                                data?.actionData?.document?.map((doc) => ({
                                    name: doc?.name,
                                    url: doc?.path,
                                }))
                            );
                            setSelectedAction(data?.wholeAction);
                        }

                        if (isRun) {
                            setActionSummaryModal(true);
                        }
                        if (isTemplate) {
                            console.log("isTemplate");
                        }
                        if (!isRun && !isTemplate) {
                            if (data?.actionData?.actionCat === "task_action") {
                                setTaskActionModal(true);
                            } else {
                                const team = data?.actionData?.approval?.map?.((memberId) =>
                                    teamMembers?.find((member) => member?._id === memberId)
                                );
                                setSelectedItems(data?.actionData?.approval);

                                setSelectedTeam(team);
                                setApprovalActionModal(true);
                            }
                        }
                    }}
                >
                    <div
                        className={`py-4 px-6 flex flex-col gap-4 cursor-pointer rounded border-l-carribean-green border-l-[3px] bg-white shadow-[0px_4px_24px_0px_rgba(0,0,0,0.05)]`}
                    >
                        <div className="flex items-center justify-between py-1.5 border-b border-slate-200">
                            <div className="flex items-center gap-2.5">
                                <i className="ri-timer-flash-line text-2xl text-[#FF8A00]"></i>
                                <span className="text-base font-rocGroteskMedium capitalize">
                                    {truncate(data?.string, { length: 20 })}
                                </span>
                            </div>
                            <div className="flex items-center gap-2.5">
                                <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                    {moment(data?.actionData?.updatedAt)?.fromNow()}
                                </span>
                                {!isTemplate && (
                                    <div
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <Dropdown
                                            dropdown={
                                                <i
                                                    // onClick={() => setSelectedNodeId(data?.id)}
                                                    className="ri-more-2-fill text-slate-500"
                                                ></i>
                                            }
                                            dropdownClassname={"!w-full !mb-10 !-mt-7"}
                                            dropdownContainerClasses={`shadow-cardShadow-2  border-0 rounded !max-h-fit`}
                                            dropdownItemsClasses={`${
                                                !isRun && "last:text-r-50 last:hover:bg-r-25"
                                            }`}
                                            name={"event dropdown"}
                                            dropdownOptions={
                                                isRun
                                                    ? [
                                                          //   {
                                                          //       label: "Add a comment",
                                                          //       value: "add a comment",
                                                          //       action: () => {
                                                          //           setSelectedNodeId(data?.id);
                                                          //           setOpenComment(true);
                                                          //       },
                                                          //   },
                                                          {
                                                              label: "Mark as done",
                                                              value: "mark as done",
                                                              action: () => {
                                                                  setSelectedNodeId(data?.id);
                                                                  setMarkAsDoneModal(true);
                                                              },
                                                              disabled:
                                                                  data?.status?.toLowerCase() ===
                                                                  "completed",
                                                              permissions:
                                                                  getEditPermissionList(
                                                                      "inventory"
                                                                  ),
                                                          },
                                                      ]
                                                    : [
                                                          {
                                                              label: "View details",
                                                              value: "view details",
                                                              action: () => {
                                                                  setSelectedNodeId(data?.id);
                                                                  setNewEvent(data?.values);
                                                                  setNewAction(data?.actionData);
                                                                  setDocUrl(
                                                                      data?.actionData?.document?.map(
                                                                          (doc) => ({
                                                                              name: doc?.name,
                                                                              url: doc?.path,
                                                                          })
                                                                      )
                                                                  );
                                                                  if (
                                                                      data?.actionData
                                                                          ?.actionCat ===
                                                                      "task_action"
                                                                  ) {
                                                                      setTaskActionModal(true);
                                                                  } else {
                                                                      const team =
                                                                          data?.actionData?.approval?.map?.(
                                                                              (memberId) =>
                                                                                  teamMembers?.find(
                                                                                      (member) =>
                                                                                          member?._id ===
                                                                                          memberId
                                                                                  )
                                                                          );
                                                                      setSelectedItems(
                                                                          data?.actionData?.approval
                                                                      );

                                                                      setSelectedTeam(team);
                                                                      setApprovalActionModal(true);
                                                                  }
                                                                  setSelectedActionName(
                                                                      data?.string
                                                                  );
                                                              },
                                                              permissions:
                                                                  getEditPermissionList(
                                                                      "inventory"
                                                                  ),
                                                          },
                                                          {
                                                              label: "Duplicate",
                                                              value: "duplicate",
                                                              action: () => {
                                                                  setSelectedNodeId(data?.id);
                                                                  setDuplicateModal(true);
                                                                  //   setOpenComment(true);
                                                              },
                                                              permissions:
                                                                  getEditPermissionList(
                                                                      "inventory"
                                                                  ),
                                                          },
                                                          {
                                                              label: "Delete action",
                                                              value: "delete action",
                                                              action: () => {
                                                                  setSelectedNodeId(data?.id);
                                                                  setNewEvent(data?.values);
                                                                  setNewAction(data?.actionData);
                                                                  setDeleteEventModal(true);
                                                                  setSelectedActionName(
                                                                      data?.string
                                                                  );
                                                              },
                                                              permissions:
                                                                  getEditPermissionList(
                                                                      "inventory"
                                                                  ),
                                                          },
                                                      ]
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            {data?.actionData?.approval?.length > 0 ? (
                                <div className="flex items-center gap-2.5">
                                    <div className="flex items-center">
                                        {approvalMembers
                                            ?.slice(0, 3)
                                            ?.map((approvalMember, idx) => (
                                                <AvatarContainer
                                                    key={idx}
                                                    containerClassname={`${
                                                        idx > 0 && "-ml-[16px]"
                                                    }`}
                                                    size={40}
                                                    imageSrc={approvalMember?.avatar}
                                                    name={
                                                        approvalMember?.fullName ||
                                                        approvalMember?.email
                                                    }
                                                />
                                            ))}
                                    </div>
                                    <span>
                                        {approvalMembers?.slice(3)?.length > 0 && (
                                            <span className="text-base text-slate-500 font-rocGroteskMedium">
                                                + {approvalMembers?.slice(3)?.length} more members
                                            </span>
                                        )}
                                    </span>
                                </div>
                            ) : (
                                <div className="flex items-center gap-2.5">
                                    {data?.actionData?.assigneeType?.toLowerCase() === "copilot" ? (
                                        <div className="w-5 h-5 p-2 rounded bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100% flex items-center justify-center">
                                            <i className="ri-robot-line text-white"></i>
                                        </div>
                                    ) : memberAssignedTo?.length > 1 ? (
                                        <div className="flex items-center">
                                            {memberAssignedTo?.slice(0, 3)?.map((member, idx) => (
                                                <AvatarContainer
                                                    key={idx}
                                                    containerClassname={`${
                                                        idx > 0 && "-ml-[16px]"
                                                    }`}
                                                    size={40}
                                                    imageSrc={member?.avatar}
                                                    name={member?.fullName || member?.email}
                                                />
                                            ))}
                                        </div>
                                    ) : (
                                        <AvatarContainer
                                            size={40}
                                            imageSrc={memberAssignedTo?.[0]?.avatar}
                                            name={
                                                memberAssignedTo?.[0]?.fullName ||
                                                memberAssignedTo?.[0]?.email ||
                                                "Team member"
                                            }
                                        />
                                    )}
                                    {data?.actionData?.assigneeType?.toLowerCase() === "copilot" ? (
                                        <p className="text-base font-rocGroteskMedium text-slate-500">
                                            Copilot
                                        </p>
                                    ) : memberAssignedTo?.length > 1 ? (
                                        data?.actionData?.assignedTo?.slice(3)?.length > 0 && (
                                            <span className="text-base text-slate-500 font-rocGroteskMedium">
                                                + {data?.actionData?.assignedTo?.slice(3)?.length}{" "}
                                                more members
                                            </span>
                                        )
                                    ) : (
                                        <div>
                                            <p className="text-base font-rocGroteskMedium text-slate-500">
                                                {memberAssignedTo?.[0]?.fullName}
                                            </p>
                                            <p className="text-sm font-rocGroteskMedium text-p-50">
                                                {memberAssignedTo?.[0]?.role?.[0]?.toUpperCase() +
                                                    memberAssignedTo?.[0]?.role?.slice(1)}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}

                            {isRun && (
                                <div className="">
                                    {data?.status?.toLowerCase() === "completed" && (
                                        <p className="text-xs font-rocGroteskBold text-slate-500 flex items-center gap-1">
                                            <span>Completed</span>
                                        </p>
                                    )}
                                    {data?.status?.toLowerCase() === "pending" && (
                                        <p className="text-xs font-rocGroteskBold text-slate-500 flex items-center gap-1">
                                            <span>Pending</span>
                                        </p>
                                    )}
                                    {data?.status?.toLowerCase() === "paused" && (
                                        <p className="text-xs font-rocGroteskBold text-slate-500 flex items-center gap-1">
                                            <span>Paused</span>
                                        </p>
                                    )}
                                    {data?.status?.toLowerCase() === "delayed" && (
                                        <p className="text-xs font-rocGroteskBold text-slate-500 flex items-center gap-1">
                                            <span>Delayed</span>
                                        </p>
                                    )}
                                    {data?.status?.toLowerCase() === "active" && (
                                        <p className="text-xs font-rocGroteskBold text-slate-500 flex items-center gap-1">
                                            <span>Active</span>
                                        </p>
                                    )}
                                    {data?.status?.toLowerCase() === "failed" && (
                                        <p className="text-xs font-rocGroteskBold text-slate-500 flex items-center gap-1">
                                            <span>Failed</span>
                                        </p>
                                    )}
                                    {data?.status?.toLowerCase() === "on-track" && (
                                        <p className="text-xs font-rocGroteskBold text-slate-500 flex items-center gap-1">
                                            <span>On-Track</span>
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>

                        {/* {thisAction?.documents && thisAction?.documents?.length > 0 && (
                            <div className="bg-slate-100 p-1.5 flex items-center justify-between">
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        if (thisAction?.documents?.length > 1) {
                                            setThisActionDocs(thisAction?.documents);
                                            setSelectedNodeId(data?.id);
                                            setSeeAllDocsModal(true);
                                        }
                                    }}
                                    className="flex items-center gap-1 text-xs rounded-[3px] font-rocGroteskMedium"
                                >
                                    {displayFileIcon("", true)}
                                    {truncate(thisAction?.documents?.[0]?.name, {
                                        length:
                                            thisAction?.documents?.slice(1)?.length > 0 ? 25 : 30,
                                    })}{" "}
                                    <span className="text-gm-40">
                                        {thisAction?.documents?.slice(1)?.length > 0 &&
                                            "+" + thisAction?.documents?.slice(1)?.length + " more"}
                                    </span>
                                </div>
                                <i
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        if (thisAction?.documents?.length > 1) {
                                            setThisActionDocs(thisAction?.documents);
                                            setSelectedNodeId(data?.id);
                                            setSeeAllDocsModal(true);
                                        } else {
                                            setDocToDelete({
                                                name: thisAction?.documents?.[0]?.name,
                                                actionId: data?.id,
                                                docId: thisAction?.documents?.[0]?._id,
                                            });
                                            setDeleteDocModal(true);
                                        }
                                    }}
                                    className="ri-delete-bin-line text-slate-500 cursor-pointer"
                                ></i>
                            </div>
                        )} */}
                    </div>
                </div>
                <Handle type="source" position={Position.Bottom} />
            </>
        );
    };

    const CustomEdge = ({
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        data,
        markerEnd,
    }) => {
        const [edgePath, labelX, labelY] = getSmoothStepPath({
            sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY,
            targetPosition,
            markerEnd,
        });

        return (
            <>
                <BaseEdge
                    id={id}
                    path={edgePath}
                    markerEnd={markerEnd}
                    style={{
                        stroke: "#9896EE",
                        strokeWidth: 1.5,
                    }}
                />
                <EdgeLabelRenderer>
                    <div
                        style={{
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                            pointerEvents: "all",
                        }}
                        className={`nodrag nopan absolute p-2.5 text-xs text-black flex items-center gap-[100px] ${
                            data?.data?.status === "failed" && data?.label
                                ? "ml-10"
                                : !data?.label
                                ? "-ml-8"
                                : "ml-[125px]"
                        }`}
                    >
                        {data?.data?.status === "failed" && (
                            <p
                                className={` ${
                                    data.label ? "mr-10" : "-ml-8"
                                } text-[#0046A2] flex items-center gap-1 cursor-pointer`}
                                onClick={() => {
                                    setSelectedNodeId(data?.id);
                                    setRetryModal(true);
                                }}
                            >
                                <i className="ri-refresh-line "></i>
                                <span className="underline">Retry</span>
                            </p>
                        )}
                        {data?.label && (
                            <p className="text-gm-40 flex items-center gap-1">
                                <i className="ri-error-warning-line mt-[-1px] text-base"></i>{" "}
                                <span className="">{data.label}</span>
                            </p>
                        )}
                    </div>
                </EdgeLabelRenderer>
            </>
        );
    };

    const AddNode = ({ data }) => {
        const [openAddOptions, setOpenAddOptions] = useState(false);
        return (
            <>
                <div className="p-1 border border-[#FF3D1D] border-dashed rounded-full">
                    <div
                        onClick={() => {
                            setNewAction({
                                actionName: "",
                                assigneeType: "",
                                isCopilot: false,
                                assignedTo: "",
                                actionTimeline: "",
                                durationValue: "",
                                durationUnit: "",
                                emailSubject: "",
                                emailBody: "",
                                mailTo: "",
                                sendTo: "",
                                actionCat: "",
                                rules: [],
                                document: [],
                                note: "",
                                approval: [],
                                linkToWorkflow: false,
                                workflowToLink: "",
                            });
                            setSelectedNodeId("");
                            setOpenAddOptions(true);
                            // setSelectedNodeId(data?.id);
                        }}
                        className="w-10 h-10 rounded-full bg-white flex items-center justify-center cursor-pointer shadow-[0px_1px_6px_0px_rgba(0,0,0,0.14)]"
                    >
                        <i className="ri-add-fill text-[#FF3D1D]"></i>
                    </div>
                </div>
                {openAddOptions && (
                    <div className="flex absolute bottom-[-112px] rounded-x-[100px] left-[-150px] cursor-pointer shadow-[0px_7px_24px_-6px_rgba(0,0,0,0.05)]">
                        <div
                            onClick={() => {
                                setTaskActionModal(true);
                                setOpenAddOptions(false);
                            }}
                            className="px-8 py-[15px] rounded-l-[100px] bg-white border-r border-slate-200 flex flex-col items-center justify-center group hover:bg-carribean-green"
                        >
                            <div className="w-10 h-10 flex items-center bg-white rounded-full justify-center mb-2.5 shadow-[0px_2px_8px_0px_rgba(0,0,0,0.06)]">
                                <i className="ri-timer-flash-line text-xl text-[#FF8A00]"></i>
                            </div>
                            <span className="font-rocGroteskMedium text-base whitespace-nowrap text-gm-45 group-hover:text-white">
                                Task action
                            </span>
                        </div>
                        <div
                            onClick={() => {
                                setApprovalActionModal(true);
                                setOpenAddOptions(false);
                            }}
                            className="px-8 py-[15px] rounded-r-[100px] bg-white flex flex-col items-center justify-center group hover:bg-carribean-green"
                        >
                            <div className="w-10 h-10 flex items-center bg-white rounded-full justify-center mb-2.5 shadow-[0px_2px_8px_0px_rgba(0,0,0,0.06)]">
                                <i className="ri-insert-row-top text-xl text-b-55 "></i>
                            </div>
                            <span className="font-rocGroteskMedium text-base whitespace-nowrap text-gm-45 group-hover:text-white">
                                Approval action
                            </span>
                        </div>
                    </div>
                )}
            </>
        );
    };

    const handleUpdateNodePosition = (data) => {
        if (data?.type !== "add") {
            const body = {
                // nodeType:
                //     data?.type?.toLowerCase() === "event" ? "WorkFlowEvent" : "WorkFlowAction",
                nodeId: data?.id,
                position: {
                    x:
                        window.innerWidth / 4 +
                        eventNodeWidth / 2 +
                        (eventNodeWidth - actionNodeWidth) / 2,
                    y: data?.position?.y,
                },
                // playable: edges?.length >= nodes?.length - 2,
            };

            dispatch(workFlowActions.updateWorkflowNodePosition(body));
        }
    };

    const handleCloseActionModal = () => {
        setTaskActionModal(false);
        setApprovalActionModal(false);
        setDocData(null);
        setDocUrl([]);
        setSelectedItems([]);
        setSelectedNodeId("");
        setAddNote(false);
        setNewAction({
            actionName: "",
            assigneeType: "",
            assignedTo: "",
            isCopilot: false,
            actionTimeline: "",
            durationValue: "",
            durationUnit: "",
            emailSubject: "",
            emailBody: "",
            sendTo: "",
            mailTo: "",
            actionCat: "",
            rules: [],
            document: [],
            note: "",
            approval: "",
            linkToWorkflow: false,
            workflowToLink: "",
        });
    };

    const nodeTypes = useMemo(
        () => ({ event: EventNode, action: ActionNode, add: AddNode }),
        [openComment, selectedNodeId, workflowId, isRun, teamMembers, isTemplate]
    );

    const edgeTypes = useMemo(() => ({ custom: CustomEdge }), []);

    useEffect(() => {
        if (Boolean(createRolesSuccess)) {
            setSelectedPermissions([]);
            setAddCustomRole(false);
            dispatch(authActions.resetCreateRoles());
            dispatch(authActions.getRoles(20, userProfile?.token));
        }
    }, [createRolesSuccess, dispatch]);

    useEffect(() => {
        dispatch(authActions.getRoles(20, userProfile?.token, debouncedSearch.search));
    }, [dispatch, debouncedSearch]);

    // useEffect(() => {
    //     dispatch(authActions.getRoles(20, userProfile?.token));
    // }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedRolesSuccess)) {
            setRoles(fetchedRolesSuccess?.rolesList);
        }
    }, [fetchedRolesSuccess]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            const lastItem = container?.lastChild;
            container.scrollTop = lastItem.offsetTop;
        }
    }, [teamInfo]);

    useEffect(() => {
        if (Boolean(addTeamMemberSuccess)) {
            dispatch(authActions.getTeamMembers(100));
            setInviteMember(false);
            dispatch(authActions.resetAddTeamMemberSuccess());
            setTeamInfo([{ "email-0": "", "role-0": "" }]);
        }
    }, [dispatch, addTeamMemberSuccess]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && event.target !== togglRef.current) {
                if (!ref.current.contains(event.target)) {
                    setOpen(false);
                    setApprovalField(false);
                }
            }

            if (!event.target) {
                setOpen(false);
                setApprovalField(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [noteRef, open]);

    useEffect(() => {
        const customActionsList = customActions?.map((actionValue) => ({
            action: camelCaseToRegularCase(actionValue?._id?.name),
            value: actionValue?._id?.name,
            type: workflowCat,
            category: actionValue?._id?.actionCategory,
            iconClass: "ri-edit-2-line",
        }));
        const customEventsList = customEvents?.map((eventValue) => ({
            event:
                eventValue?._id?.replace(/([A-Z])/g, " $1")?.[0]?.toUpperCase() +
                eventValue?._id?.replace(/([A-Z])/g, " $1")?.slice(1),
            value: eventValue?._id,
            eventType: [workflowCat],
        }));
        setEvents([
            ...customEventsList,
            ...eventsOpt?.filter((opt) => opt?.eventType?.includes(workflowCat)),
        ]);
        setActions([
            ...customActionsList,
            ...actionsOpt?.filter(
                (opt) =>
                    opt?.type?.toLowerCase() === workflowCat || opt?.type?.toLowerCase() === "all"
            ),
        ]);
    }, [customActions, customEvents, workflowCat]);

    useEffect(() => {
        setPlayable(nodes?.length > 2 ? edges?.length >= nodes?.length - 2 : false);
    }, [nodes?.length, edges?.length, workflowId]);

    useEffect(() => {
        dispatch(authActions.getTeamMembers(100));
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(retryActionSuccess)) {
            setRetryModal(false);
        }
    }, [retryActionSuccess]);

    useEffect(() => {
        if (Boolean(markActionAsDoneSuccess)) {
            setMarkAsDoneModal(false);
        }
    }, [markActionAsDoneSuccess]);

    useEffect(() => {
        if (Boolean(deleteEventSuccess)) {
            handleDeleteEvent();
            setDeleteEventModal(false);
            setOpenDetails(false);
            dispatch(workFlowActions.resetDeleteEventSuccess());
        }
    }, [deleteEventSuccess]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamMembers(fetchedTeamMemberSuccess.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        dispatch(vendorActions.getOnboardedVendors());
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedOnboardedVendorsSuccess)) {
            setVendors(fetchedOnboardedVendorsSuccess?.vendors);
        }
    }, [fetchedOnboardedVendorsSuccess]);

    useEffect(() => {
        if (Boolean(createActionSuccess) || Boolean(updateActionSuccess)) {
            if (createActionSuccess) {
                // for create action
                handleAddNode(createActionSuccess?.workFlowEvent?.event);
                setCurrentWorkflowData((prev) => [
                    ...prev,
                    createActionSuccess?.workFlowEvent?.event,
                ]);
            } else {
                // for update action
                handleAddNode(updateActionSuccess?.update);
                setCurrentWorkflowData((prev) => [...prev, updateActionSuccess?.update]);
            }

            handleCloseActionModal();
            dispatch(workFlowActions.resetCreateActionSuccess());
            dispatch(workFlowActions.resetUpdateActionSuccess());
        }
    }, [createActionSuccess, updateActionSuccess]);

    useEffect(() => {
        if (Boolean(duplicateActionSuccess)) {
            setDuplicateModal(false);
            handleAddNode(duplicateActionSuccess?.duplicatedWorkFlowAction);
        }
    }, [duplicateActionSuccess]);

    useEffect(() => {
        if (Boolean(updateWorkflowConnectionsFailure)) {
            const edgesCopy = [...edges];
            edgesCopy?.splice(-1, 1);
            setEdges(edgesCopy);
            dispatch(workFlowActions.resetUpdateWorkflowConnectionsFailure());
        }
    }, [updateWorkflowConnectionsFailure, dispatch]);

    useEffect(() => {
        if (Boolean(createMultipleActionsSuccess)) {
            handleAddNode(createMultipleActionsSuccess?.workFlowEvent?.event);
            setCurrentWorkflowData((prev) => [
                ...prev,
                createMultipleActionsSuccess?.workFlowEvent?.event,
            ]);
            setAILoading(false);
            // navigate(`/dashboard/workflow/${workflowId}`, { replace: true });
            navigate(
                `/dashboard/workflow/${createMultipleActionsSuccess?.workFlowEvent?.event?.workFlowId}`,
                {
                    replace: true,
                }
            );

            dispatch(workFlowActions.resetCreateWorkFlowSuccess());
            dispatch(workFlowActions.resetCreateMultipleActionSuccess());
        }
    }, [createMultipleActionsSuccess]);

    useEffect(() => {
        if (Boolean(updateEventsAndActionsSuccess)) {
            // handleAddNode(updateEventsAndActionsSuccess?.update, true);
            setDeleteEventModal(false);
            setDocData(null);
            setAttachDocModal(false);
            handleCloseSidebar();
            dispatch(workFlowActions.resetUpdateEventAndActionSuccess());
        }
    }, [updateEventsAndActionsSuccess]);

    useEffect(() => {
        if (Boolean(deleteActionSuccess)) {
            const nodesCopy = [...nodes?.slice(0, nodes?.length - 1)];

            const eventObjCopy = newEvent;
            const eventActionsCopy = [...eventObjCopy.actions];
            const selectedNodeIndex = nodesCopy.findIndex((node) => node?.id === selectedNodeId);
            const selectedActionIndex = eventActionsCopy.findIndex(
                (action) => action?.id === selectedNodeId
            );
            if (selectedNodeIndex !== -1) {
                nodesCopy.splice(selectedNodeIndex, 1);
            }
            if (selectedActionIndex !== -1) {
                eventActionsCopy.splice(selectedActionIndex, 1);
            }

            setNodes([
                ...nodesCopy,
                {
                    id: "add-new",
                    position: {
                        x: nodesCopy?.[0]?.position?.x + eventNodeWidth / 2 - 45 / 2,
                        y: nodesCopy?.slice(-1)?.[0]?.position?.y + 200,
                    },
                    type: "add",
                    deletable: false,
                    data: {
                        values: nodesCopy?.[0]?.data?.values || {
                            newEvent: "",
                            actions: [],
                        },
                        fixedX: nodesCopy?.[0]?.position?.x + eventNodeWidth / 2 - 45 / 2,
                        fixedY: nodesCopy?.slice(-1)?.[0]?.position?.y + 200,
                    },
                    style: {
                        width: "fit-content",
                        height: "auto",
                        background: "white",
                        borderRadius: "4px",
                    },
                },
            ]);
            setEdges(() => {
                const edgesArr = nodesCopy?.map((node, i) => {
                    const edgeObj = {
                        id: `e${node?.id}-${nodesCopy?.[i + 1]?.id}`,
                        source: node?.id,
                        target: nodesCopy?.[i + 1]?.id,
                        // type: "smoothstep",
                        type: isRun ? "custom" : "smoothstep",
                        data: node?.data,
                        deletable: false,
                        animated: true,
                        markerEnd: {
                            type: MarkerType.ArrowClosed,
                            width: 18,
                            height: 18,
                            color: "#6562E5",
                        },
                        style: {
                            stroke: "#6562E5",
                            strokeWidth: 1.5,
                        },
                    };
                    return edgeObj;
                });
                return edgesArr;
            });

            setNewEvent(() => ({ eventName: eventObjCopy?.eventName, actions: eventActionsCopy }));
            setDeleteEventModal(false);
            setSelectedNodeId("");
            dispatch(workFlowActions.resetDeleteActionSuccess());
        }
    }, [deleteActionSuccess, nodes, selectedNodeId]);

    useEffect(() => {
        if (Boolean(swapNodeSuccess)) {
            dispatch(workFlowActions.resetSwapNodesSuccess);
        }
    }, [swapNodeSuccess, dispatch]);

    useEffect(() => {
        dispatch(workFlowActions.getworkFlowList(50));
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedWorkFlowListSuccess)) {
            setWorkFlowList(fetchedWorkFlowListSuccess?.workFlows);
        }
    }, [fetchedWorkFlowListSuccess]);

    useEffect(() => {
        if (Boolean(updateEventSuccess)) {
            setUpdatedEvent(eventValue);
            setEventModal(false);
            dispatch(workFlowActions.resetUpdateEventSuccess());
        }
    }, [updateEventSuccess, dispatch, eventValue]);

    useEffect(() => {
        const handleNoteFieldClickOutside = (event) => {
            if (noteRef.current) {
                if (!noteRef.current.contains(event.target) && !Boolean(newAction?.note)) {
                    setAddNote(false);
                }
            }

            if (!event.target) setAddNote(false);
        };
        document.addEventListener("mousedown", handleNoteFieldClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleNoteFieldClickOutside);
        };
    }, [noteRef]);

    useEffect(() => {
        socket.on("workflow_document_processed", async (data) => {
            console.log("datttaaa ===>>>", data);
            if (data?.workflowId === workflowId) {
                const event = data?.event;
                const generatedActions = data?.actions;

                const body = generatedActions?.map((action, idx) => {
                    const isCopilotAction = copilotActions?.find(
                        (act) => act?.value === action?.name
                    );

                    const isApprovalAction = actions?.find((act) => act.value === action?.name);

                    const obj = {
                        name: action?.name,
                        workFlowEventId: workflowData?._id,
                        position: {
                            x:
                                window.innerWidth / 4 +
                                eventNodeWidth / 2 +
                                (eventNodeWidth - actionNodeWidth) / 2,
                            y: 200 * (idx + 1),
                        },
                        actionCategory: Boolean(isApprovalAction) ? "approval" : "task_action",

                        actionType: workflowData?.eventType,
                        duration: {
                            value: action?.duration?.value,
                            unit: action?.duration?.unit,
                        },
                    };

                    if (action?.rules?.length > 0) {
                        obj.actionRules = action?.rules?.map((rule) => ({
                            action: rule,
                        }));
                    }

                    if (!Boolean(isApprovalAction)) {
                        if (!isCopilotAction) {
                            obj.assigneeId = [creator?._id];
                            obj.assigneeType = "LDB";
                        } else {
                            obj.assigneeType = "copilot";
                        }
                    } else {
                        obj.approvals = [creator?._id];
                        obj.assigneeType = "LDB";
                    }

                    return obj;
                });

                console.log("body", body);

                dispatch(workFlowActions.createMultipleActions({ actions: body }));
            }
        });

        // Remove event listener on component unmount
        return () => socket.off("workflow_document_processed");
    }, [workflowId, workflowData?._id, nodes]);

    console.log("workflowData", workflowData);

    useLayoutEffect(() => {
        if (workflowData && !createdThrough) {
            const evt = events?.find(
                (event) => event.value === (updatedEvent || workflowData?.name)
            );

            const values = {
                id: workflowData?._id,
                eventType: workflowData?.eventType,
                position: workflowData?.position,
                eventName: workflowData?.name + "-" + workflowData?.eventType,
                status: workflowData?.status,
                actions: workflowData.workFlowActions?.map((action) => {
                    return {
                        id: action?._id,
                        actionName: action?.name,
                        assigneeType: action?.assigneeType,
                        assignedTo: action?.assigneeId ?? [],
                        position: action?.position,
                        actionTimeline: "",
                        durationValue: action?.duration?.value,
                        durationUnit: action?.duration?.unit,
                        documents: action?.documents,
                        linkToWorkflow: Boolean(action?.nextWorkFlowId),
                        workflowToLink: action?.nextWorkFlowId,
                        emailSubject: action?.data?.email?.subject,
                        emailBody: action?.data?.email?.body,
                        mailTo: action?.data?.email?.mailTo,
                        rules: action?.actionRules?.map((rule) => ({
                            id: rule?._id,
                            rule: rule?.action,
                            complete: rule?.status?.toLowerCase() === "completed" ? true : false,
                        })),
                    };
                }),
            };

            const eventActions = workflowData.workFlowActions?.map((action) => {
                const eventAction = actions.find((act) => act.value === action?.name);

                return {
                    id: action?._id,
                    eventId: workflowData?._id,
                    position: action?.position,
                    data: {
                        id: action?._id,
                        status: action?.currentStatus,
                        string: eventAction?.action || action?.name?.replace(/([A-Z])/g, " $1"),
                        wholeAction: action,
                        values: values,
                        actionData: {
                            actionName: action?.name + "-" + action?.actionType,
                            assigneeType: action?.assigneeType,
                            isCopilot:
                                action?.assigneeType?.toLowerCase() === "copilot" ? true : false,
                            assignedTo: action?.assignee,
                            actionTimeline: "",
                            updatedAt: action?.updatedAt,
                            durationValue: action?.duration?.value,
                            durationUnit: action?.duration?.unit,
                            emailSubject: action?.data?.email?.subject,
                            emailBody: action?.data?.email?.body,
                            mailTo: action?.data?.email?.mailTo,
                            sendTo: action?.data?.email?.sendTo,
                            rules: action?.actionRules?.map((rule) => ({
                                id: rule?._id,
                                rule: rule?.action,
                                complete:
                                    rule?.status?.toLowerCase() === "completed" ? true : false,
                            })),
                            actionCat: action?.actionCategory,
                            document: action?.documents,
                            note: action?.comments ?? "",
                            approval: action?.approvals,
                            linkToWorkflow: Boolean(action?.nextWorkFlowId),
                            workflowToLink: action?.nextWorkFlowId,
                        },
                        connections: [
                            action?.previousConnection || {},
                            action?.nextConnection || {},
                        ],
                        type: "action",
                        fixedX: action?.position?.x,
                    },
                    deletable: false,
                    type: "action",
                    style: {
                        width: actionNodeWidth,
                        minHeight: 115,
                        maxHeight: 200,
                        background: "#F8FAFC",
                        borderRadius: "8px",
                    },
                };
            });

            const allNodes = [
                {
                    id: workflowData?._id,
                    type: "event",
                    position: workflowData?.position,
                    data: {
                        content: (
                            <div className="flex flex-col gap-3">
                                <p className="text-sm text-slate-800 font-rocGroteskMedium capitalize">
                                    {evt?.event ||
                                        (updatedEvent || workflowData?.name)?.replace(
                                            /([A-Z])/g,
                                            " $1"
                                        )}
                                </p>
                            </div>
                        ),
                        name: evt?.value || workflowData?.name,
                        id: workflowData?._id,
                        status: workflowData?.status,
                        values: values,
                        commentCount: workflowData?.commentCount,
                        type: "event",
                        fixedX: workflowData?.position?.x,
                        connections: [
                            workflowData?.previousConnection || {},
                            workflowData?.nextConnection || {},
                        ],
                    },
                    deletable: false,
                    style: {
                        width: eventNodeWidth,
                        // minHeight: 95,
                        maxHeight: 200,
                        background: "white",
                        borderRadius: "8px",
                    },
                },
                ...eventActions,
            ];

            const updatedNode = uniqBy(
                isRun || isTemplate
                    ? allNodes
                    : [
                          ...allNodes,
                          {
                              id: "add-new",
                              position: {
                                  x: allNodes?.[0]?.position?.x + eventNodeWidth / 2 - 45 / 2,
                                  y: allNodes?.slice(-1)?.[0]?.position?.y + 200,
                              },
                              type: "add",
                              deletable: false,
                              data: {
                                  values: allNodes?.[0]?.data?.values || {
                                      newEvent: "",
                                      actions: [],
                                  },
                                  fixedX: allNodes?.[0]?.position?.x + eventNodeWidth / 2 - 45 / 2,
                                  fixedY: allNodes?.slice(-1)?.[0]?.position?.y + 200,
                              },
                              style: {
                                  width: "fit-content",
                                  height: "auto",
                                  background: "white",

                                  borderRadius: "4px",
                              },
                          },
                      ],
                "id"
            );

            setNewEvent(values);

            setNodes((nds) => {
                return uniqBy([...updatedNode, ...nds], "id");
            });

            setEdges((eds) => {
                const connectionPair = updatedNode.slice(0, -1)?.map((node, i) => {
                    const edgeObj = {
                        id: `e${node?.id}-${node?.data?.connections?.[1]?.connectionId}`,
                        source: node?.id,
                        target: node?.data?.connections?.[1]?.connectionId,
                        // type: "smoothstep",
                        type: isRun ? "custom" : "smoothstep",
                        data: updatedNode?.slice(1)?.[i],
                        deletable: false,
                        animated: true,
                        markerEnd: {
                            type: MarkerType.ArrowClosed,
                            width: 18,
                            height: 18,
                            color: "#6562E5",
                        },
                        style: {
                            stroke: "#6562E5",
                            strokeWidth: 1.5,
                        },
                    };

                    const runtime = parseConvertedSeconds(
                        convertSeconds(workflowData?.workFlowActions?.[i]?.runTime)
                    );

                    if (isRun && workflowData?.workFlowActions?.[i]?.runTime) {
                        edgeObj.data = {
                            ...edgeObj?.data,
                            label: `Runtime: ${runtime}`,
                        };
                    }

                    return edgeObj;
                });

                return uniqBy(
                    flattenDeep([...eds, ...connectionPair])?.filter((link) =>
                        Boolean(link?.target)
                    ),
                    "source"
                );
            });
        } else if (createdThrough && createdThrough?.toLowerCase() === "document") {
            setAILoading(true);
        } else {
            setNodes(initialNodes);
            setEdges(initialEdges);
        }
    }, [
        workflowData,
        workflowId,
        createdThrough,
        isRun,
        updatedEvent,
        updateEventSuccess,
        actions,
    ]);

    const onNodesChange = useCallback((changes) => {
        setNodes((nds) => {
            const ndsCopy = [...nds];
            const lastTwoNodes = ndsCopy?.slice(-2);
            const parsedChanges = changes.map((change) => {
                if (change.type === "position" && change.position && change.positionAbsolute) {
                    const findNode = nds.find((node) => change.id === node.id);
                    const fixedX = nds.find((node) => change.id === node.id)?.data.fixedX;
                    const fixedY = nds.find((node) => change.id === node.id)?.data.fixedXY;
                    const intersections = getIntersectingNodes(findNode).map((n) => n);

                    const bottomNode = intersections?.[0];

                    if (
                        findNode?.type?.toLowerCase() === "event" ||
                        findNode?.type?.toLowerCase() === "add"
                    ) {
                        change.position = findNode?.position;
                        change.positionAbsolute = findNode?.positionAbsolute;
                    }

                    if (fixedX && findNode?.type?.toLowerCase() !== "event") {
                        change.position = { x: fixedX, y: change.position.y };
                        change.positionAbsolute = {
                            x: fixedX,
                            y: change.positionAbsolute.y,
                        };
                    }

                    // if (
                    //     lastTwoNodes?.[0]?.id === findNode?.id &&
                    //     lastTwoNodes?.[1] &&
                    //     !bottomNode
                    // ) {
                    //     lastTwoNodes[1].position = {
                    //         ...lastTwoNodes[1].position,
                    //         y: change?.position?.y + 210,
                    //     };
                    // }
                }

                return change;
            });

            return applyNodeChanges(parsedChanges, ndsCopy);
        });
    }, []);

    const totalRunTimeInSeconds = workflowData?.workFlowActions?.reduce(
        (accumulator, currentValue) => accumulator + (currentValue.runTime || 0),
        0
    );

    const convertedSeconds = parseConvertedSeconds(convertSeconds(totalRunTimeInSeconds));

    // const onFlowLoad = (reactFlowInstance) => {
    //     reactFlowInstance.fitView({ padding: 0.9, zoom: 0.2 });
    // };

    return (
        <>
            <ReactFlow
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                nodes={nodes}
                ref={drop}
                className="relative"
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                nodesDraggable={isRun || isTemplate ? false : true}
                // nodesDraggable={false}
                nodeDragThreshold={4}
                onNodeDragStart={(evt, node, nds) => setDraggedNode(node)}
                onNodeDragStop={(evt, node, nds) => {
                    const intersections = getIntersectingNodes(node).map((n) => n);

                    const ndCopy = [...nodes];
                    const topNode = nds?.[0];
                    const bottomNode = intersections?.[0];
                    const findTopIndex = ndCopy?.findIndex((nod) => nod?.id === topNode?.id);
                    const findBottomIndex = ndCopy?.findIndex((nod) => nod?.id === bottomNode?.id);

                    if (
                        intersections.length > 0 &&
                        bottomNode?.type?.toLowerCase() === "action" &&
                        !isRun
                    ) {
                        if (findTopIndex !== -1) {
                            ndCopy[findTopIndex].position = bottomNode?.position;
                            ndCopy[findTopIndex].data.connections = bottomNode?.data?.connections;
                        }
                        if (findBottomIndex !== -1) {
                            ndCopy[findBottomIndex].position = draggedNode?.position;
                            ndCopy[findBottomIndex].data.connections =
                                draggedNode?.data.connections;
                        }
                        if (findTopIndex !== -1 && findBottomIndex !== -1) {
                            const data = {
                                nodeOne: {
                                    id: draggedNode?.id,
                                    position: draggedNode?.position,
                                },
                                nodeTwo: {
                                    id: bottomNode?.id,
                                    position: bottomNode?.position,
                                },
                            };

                            dispatch(workFlowActions.swapNodes(data));
                        }
                    }

                    if (
                        intersections.length > 0 &&
                        (bottomNode?.type?.toLowerCase() === "event" ||
                            bottomNode?.type?.toLowerCase() === "add") &&
                        !isRun
                    ) {
                        if (findTopIndex !== -1) {
                            ndCopy[findTopIndex].position = draggedNode?.position;
                            ndCopy[findBottomIndex].position = bottomNode?.position;
                        }
                    }

                    if (
                        (topNode?.position?.y > ndCopy?.slice(-1)?.[0]?.position?.y ||
                            topNode?.position?.y < ndCopy?.[0]?.position?.y) &&
                        !isRun
                    ) {
                        if (findTopIndex !== -1) {
                            ndCopy[findTopIndex].position = draggedNode?.position;
                        }
                    }

                    if (
                        intersections.length <= 0 &&
                        (topNode?.position?.y < ndCopy?.slice(-1)?.[0]?.position?.y ||
                            topNode?.position?.y > ndCopy?.[0]?.position?.y) &&
                        !isRun &&
                        topNode?.type?.toLowerCase() === "action"
                    ) {
                        handleUpdateNodePosition(topNode);
                    }

                    if (!isRun) {
                        // setNewEvent({
                        //     eventName: evntCopy?.eventName,
                        //     actions: evtActions,
                        // });

                        setNodes(() => {
                            return ndCopy;
                        });

                        setEdges(() => {
                            const sortedNd = sortBy(ndCopy, [
                                function (o) {
                                    return o.position.y;
                                },
                            ]);
                            const connection = sortedNd.slice(0, -1)?.map((nod, i) => {
                                const edgeObj = {
                                    id: `e${nod?.id}-${sortedNd?.[i + 1]?.id}`,
                                    source: nod?.id,
                                    target: sortedNd?.[i + 1]?.id,
                                    // type: "smoothstep",
                                    type: isRun ? "custom" : "smoothstep",
                                    data: nod,
                                    deletable: false,
                                    animated: true,
                                    markerEnd: {
                                        type: MarkerType.ArrowClosed,
                                        width: 18,
                                        height: 18,
                                        color: "#6562E5",
                                    },
                                    style: {
                                        stroke: "#6562E5",
                                        strokeWidth: 1.5,
                                    },
                                };

                                const runtime = parseConvertedSeconds(
                                    convertSeconds(workflowData?.workFlowActions?.[i]?.runTime)
                                );

                                if (isRun && workflowData?.workFlowActions?.[i]?.runTime) {
                                    edgeObj.data = {
                                        ...edgeObj?.data,
                                        label: `Runtime: ${runtime}`,
                                    };
                                }

                                return edgeObj;
                            });

                            return uniqBy(
                                connection?.filter((link) => Boolean(link?.target)),
                                "source"
                            );
                        });
                    }
                }}
                // onNodeDrag={onNodeDrag}
                // onInit={onFlowLoad}
                connectionLineType="smoothstep"
                zoomOnScroll={false}
                panOnScroll={true}
                panOnDrag={true}
                autoPanOnNodeDrag={true}
                autoPanOnConnect={true}
                proOptions={{ hideAttribution: true }}
                onClick={() => {
                    setOpenComment(false);
                    setUserInteract(true);
                }}
                fitView
                fitViewOptions={{ padding: 0.9, zoom: 0.2 }}
            >
                <Background />
            </ReactFlow>
            {/* </div> */}

            {isRun && (
                <div className="absolute top-[104px] max-lg:top-[40px] left-[28px] w-[354px]  p-5 flex flex-col gap-3 bg-white rounded-lg shadow-faintShadow">
                    <p className="text-sm font-rocGroteskMedium">Workflow run summary</p>
                    <p className="text-xs font-rocGroteskMedium flex gap-1 items-center">
                        <span>Customer:</span>{" "}
                        <span className="text-slate-500">{customer || "N/A"}</span>
                    </p>
                    <p className="text-xs font-rocGroteskMedium flex gap-1 items-center">
                        <span>Total runtime:</span>{" "}
                        <span className="text-slate-500">{convertedSeconds}</span>
                    </p>
                    <div className="text-xs font-rocGroteskMedium flex gap-1 items-center">
                        <span>Status:</span> <StatusBadge status={currentStatus} />
                    </div>
                    {/* <p className="text-xs font-rocGroteskMedium flex gap-1 items-center">
                        <span>Bottlenecks:</span>{" "}
                        <span className="text-slate-500"> workflowData?.status
                            Delay in getting vendor quote responses
                        </span>
                    </p> */}
                </div>
            )}

            {!isRun && !isTemplate && (
                <div
                    className={`absolute max-lg:hidden bg-white w-[26.4%] h-full overflow-auto top-0 right-0  border-l border-slate-100`}
                >
                    <div className="pb-5 px-5 pt-[25px] border-b border-slate-200">
                        <p className="text-sm font-rocGroteskMedium mb-[11px]">
                            {workflowCat === "rfq"
                                ? "RFQ (Request for quote) actions"
                                : workflowCat === "order"
                                ? "Order processing actions"
                                : "Purchase order actions"}
                        </p>
                        <div className=" mb-2.5">
                            <TextInput
                                name={"searchActions"}
                                onChange={(e) => setSearchActions(e.target.value)}
                                value={searchActions}
                                type={"text"}
                                inputPlaceholder={"Search for an action"}
                                inputClassName={"!h-[44px] pl-[0px] !mb-0 text-sm"}
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400"></i>
                                }
                                inputContainerClassname={
                                    "!rounded !pl-[40px] !bg-slate-50 border-slate-100 !h-[44px]"
                                }
                            />
                        </div>
                        <p className="text-xs px-1 font-rocGroteskRegular text-slate-600">
                            Drag and drop from the list of actions here into your workflow canvas
                        </p>
                    </div>

                    <div className="px-5 flex flex-col gap-4 py-[30px] h-[calc(100vh-315px)] overflow-y-auto">
                        {filteredActions?.map(
                            ({ action, value, category, type, iconClass }, index) => {
                                return (
                                    <DraggableBox
                                        key={value + index}
                                        id={index}
                                        text={action}
                                        value={value + "-" + type}
                                        category={category ?? ""}
                                        custom={value === "customAction"}
                                        icon={
                                            value === "customAction" ? (
                                                <i className="ri-add-fill text-b-45"></i>
                                            ) : (
                                                <i
                                                    className={`${
                                                        Boolean(iconClass)
                                                            ? iconClass
                                                            : "ri-edit-2-line"
                                                    } text-b-45`}
                                                ></i>
                                            )
                                        }
                                    />
                                );
                            }
                        )}
                    </div>
                </div>
            )}

            <ModalContainer
                open={eventModal}
                showCloseIcon={false}
                tailwindClassName="w-[38%] max-lg:w-[95%]"
                closeModal={() => setEventModal(false)}
            >
                <div className="bg-white rounded w-full py-8 px-[30px]">
                    <div className="flex items-center justify-between mb-5 pb-[14px] border-b border-slate-100">
                        <div className="flex items-center gap-3">
                            <i className="ri-flashlight-line text-2xl text-p-55"></i>
                            <span className="text-lg font-rocGroteskMedium ">Set an event</span>
                        </div>
                        <i
                            onClick={() => setEventModal(false)}
                            className="ri-close-fill text-xl cursor-pointer"
                        ></i>
                    </div>
                    <p className="text-sm font-rocGroteskMedium mb-[14px]">if...</p>
                    <div className="flex flex-col gap-4">
                        <SelectInput
                            value={eventValue}
                            name="selectedEvent"
                            placeholder={"Select event"}
                            handleChange={(name, value) => {
                                setEventValue(value);
                            }}
                            isRequired={true}
                            dropdownOptions={events.map(({ event, value }) => ({
                                label: event,
                                value: value,
                            }))}
                            customDropdownContainer={
                                <div
                                    onClick={() => setCustomEventModal(true)}
                                    className="pl-4 py-1 cursor-pointer "
                                >
                                    <p className="text-sm text-p-50 flex items-center gap-1">
                                        <i className="ri-add-fill text-p-50 text-lg mt-[-3px]"></i>
                                        <span>Add a custom event</span>
                                    </p>
                                </div>
                            }
                        />
                    </div>

                    <div className="flex items-center justify-end mt-8">
                        <div className="flex items-center gap-2.5">
                        <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"Back"}
                                type={"button"}
                                btnClassname="!h-[50px] !w-[140px] !bg-slate-100 !text-gm-50"
                                onClick={() => setEventModal(false)}
                                disabled={updatingEvent}
                            />
                            </div>
                            <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"Save"}
                                type={"button"}
                                btnClassname="!h-[50px] !w-[140px]"
                                onClick={() => {
                                    if (Boolean(eventValue)) {
                                        dispatch(
                                            workFlowActions.updateEvent(
                                                {
                                                    name: eventValue?._id
                                                        ? eventValue?._id
                                                        : eventValue,
                                                },
                                                nodes?.[0]?.id
                                            )
                                        );
                                    } else {
                                        toast.custom((t) => (
                                            <CustomToast
                                                t={t}
                                                message={"Please select an event"}
                                                type={"error"}
                                            />
                                        ));
                                    }
                                }}
                                isLoading={updatingEvent}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={taskActionModal}
                showCloseIcon={false}
                tailwindClassName="w-[38%] max-lg:w-[95%] max-h-[75%] overflow-auto"
                closeModal={() => {
                    handleCloseActionModal();
                }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (Boolean(selectedNodeId)) {
                            handleUpdateAction();
                        } else {
                            handleCreateAction();
                        }
                    }}
                    className="bg-white rounded w-full py-8 px-[30px] "
                >
                    <div className="flex items-center justify-between mb-6">
                        <div className="flex items-center gap-3">
                            <i className="ri-timer-flash-line text-2xl text-[#FF8A00]"></i>
                            <span className="text-lg font-rocGroteskMedium ">
                                {workflowCat === "rfq"
                                    ? "Add an RFQ action"
                                    : workflowCat === "order"
                                    ? "Add an Order processing action"
                                    : "Add a Purchase order action"}
                            </span>
                        </div>
                        <i
                            onClick={() => {
                                handleCloseActionModal();
                            }}
                            className="ri-close-fill text-xl cursor-pointer"
                        ></i>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div>
                            <SelectInput
                                value={newAction?.actionName}
                                name="actionName"
                                placeholder={"Select action"}
                                isRequired={true}
                                handleChange={(name, value) => {
                                    handleTaskCreationChange(name, value);
                                }}
                                dropdownOptions={
                                    newAction?.isCopilot
                                        ? copilotActions
                                              ?.filter((option) =>
                                                  option?.category?.includes(workflowCat)
                                              )
                                              ?.map(({ action, value, type, iconClass }) => ({
                                                  label: action,
                                                  value: value + "-" + type,
                                                  icon: (
                                                      <i
                                                          className={`${
                                                              Boolean(iconClass)
                                                                  ? iconClass
                                                                  : "ri-edit-2-line"
                                                          }e text-b-45`}
                                                      ></i>
                                                  ),
                                              }))
                                        : actions
                                              ?.filter((opt) =>
                                                  opt?.category?.toLowerCase()?.includes("task")
                                              )
                                              .map(({ action, value, type, iconClass }) => ({
                                                  label: action,
                                                  value: value + "-" + type,
                                                  icon: (
                                                      <i
                                                          className={`${
                                                              Boolean(iconClass)
                                                                  ? iconClass
                                                                  : "ri-edit-2-line"
                                                          } text-b-45`}
                                                      ></i>
                                                  ),
                                              }))
                                }
                                customDropdownContainer={
                                    !newAction?.isCopilot && (
                                        <div
                                            onClick={() => setCustomActionModal(true)}
                                            className="pl-4 py-1 cursor-pointer "
                                        >
                                            <p className="text-sm text-p-50 flex items-center gap-1">
                                                <i className="ri-add-fill text-p-50 text-lg mt-[-3px]"></i>
                                                <span>Add a custom action</span>
                                            </p>
                                        </div>
                                    )
                                }
                            />
                            <p className="text-[13px] text-slate-400 mt-2 font-rocGroteskRegular">
                                This is the task you want to be done
                            </p>
                        </div>
                        {newAction?.actionName?.toLowerCase()?.includes("sendemail") && (
                            <SelectInput
                                value={newAction?.mailTo}
                                name="mailTo"
                                placeholder={"Send email to"}
                                isRequired={true}
                                handleChange={(name, value) => {
                                    handleTaskCreationChange(name, value);
                                }}
                                dropdownOptions={[
                                    {
                                        label: "Customer",
                                        value: "customer",
                                    },
                                    {
                                        label: "Vendor",
                                        value: "vendor",
                                    },
                                    {
                                        label: "Internal",
                                        value: "internal",
                                    },
                                ]}
                            />
                        )}
                        {(newAction?.mailTo?.toLowerCase() === "vendor" ||
                            newAction?.mailTo === "internal") && (
                            <SelectInput
                                value={newAction?.sendTo}
                                name="sendTo"
                                placeholder={
                                    newAction?.mailTo?.toLowerCase() === "internal"
                                        ? "Select team member"
                                        : "Select vendor"
                                }
                                handleChange={(name, value) =>
                                    handleTaskCreationChange(name, value)
                                }
                                isRequired={true}
                                dropdownOptions={
                                    newAction?.mailTo?.toLowerCase() === "internal"
                                        ? teamMembers.map((member) => ({
                                              label: member?.email,
                                              value: member?._id,
                                          }))
                                        : vendors
                                              .map((vendor) => ({
                                                  label: vendor?.vendor?.profile?.businessName,
                                                  value: vendor?.vendorId,
                                              }))
                                              ?.filter(
                                                  (value) =>
                                                      Boolean(value?.label) && Boolean(value?.value)
                                              )
                                }
                            />
                        )}
                        {newAction?.actionName?.toLowerCase()?.includes("generateemail") && (
                            <div className="flex flex-col gap-[14px] ">
                                <TextInput
                                    value={newAction?.emailSubject}
                                    name={"emailSubject"}
                                    type={"text"}
                                    required={true}
                                    onChange={(e) =>
                                        handleTaskCreationChange(e.target.name, e.target.value)
                                    }
                                    placeholder={"Enter email subject"}
                                    containerClassname="w-full "
                                    inputContainerClassname={" "}
                                    inputClassName={`w-full`}
                                />
                                <TextareaInput
                                    name={"emailBody"}
                                    value={newAction?.emailBody}
                                    onChange={(e) =>
                                        handleTaskCreationChange(e.target.name, e.target.value)
                                    }
                                    rows={5}
                                    placeholder={"Describe email body"}
                                    required={true}
                                    containerClassname=""
                                />
                            </div>
                        )}
                        <div>
                            {!newAction.isCopilot && (
                                <MultiselectInput
                                    value={newAction?.assignedTo || []}
                                    name="assignedTo"
                                    placeholder={"Select assignee"}
                                    className=""
                                    isRequired={true}
                                    handleChange={(name, value, valuesArray) =>
                                        handleTaskCreationChange(name, valuesArray)
                                    }
                                    subLabelClass={"text-xs text-p-50 font-rocGroteskMedium"}
                                    dropdownOptions={teamMembers?.map((member) => ({
                                        label: member?.fullName || member?.email,
                                        subLabel:
                                            member?.role?.[0]?.toUpperCase() +
                                            member?.role?.slice(1),
                                        subText: member?.fullName ? member?.email : "",
                                        value: member?._id,
                                        icon: (
                                            <AvatarContainer
                                                size={40}
                                                imageSrc={member?.avatar}
                                                name={member?.fullName || member?.email}
                                            />
                                        ),
                                    }))}
                                    customDropdownContainer={
                                        <div
                                            onClick={() => setInviteMember(true)}
                                            className="pl-4 py-2.5 cursor-pointer "
                                        >
                                            <p className="text-sm text-p-50 flex items-center gap-1">
                                                <i className="ri-add-fill text-p-50 text-lg mt-[-3px]"></i>
                                                <span>Add team member</span>
                                            </p>
                                        </div>
                                    }
                                />
                            )}

                            {copilotActions?.filter(
                                (option) =>
                                    option?.category?.includes(workflowCat) &&
                                    option?.value === newAction?.actionName?.split("-")?.[0]
                            )?.length > 0 && (
                                <div className="flex items-center gap-1.5 mt-2">
                                    <ToggleInput
                                        labelClassname={"!w-8 !h-[18px]"}
                                        className={`before:!w-4 before:!h-4 before:!left-[1px] before:!bottom-[1px] ${
                                            newAction.isCopilot && "before:!translate-x-[14px]"
                                        }`}
                                        onChange={(evt) => {
                                            handleTaskCreationChange(
                                                evt.target.name,
                                                evt.target.checked
                                            );
                                        }}
                                        name={"isCopilot"}
                                        checked={newAction.isCopilot}
                                    />
                                    <span
                                        onClick={() =>
                                            handleTaskCreationChange(
                                                "isCopilot",
                                                !newAction.isCopilot
                                            )
                                        }
                                        className="text-[13px] cursor-pointer text-slate-400  font-rocGroteskMedium"
                                    >
                                        Assign action to Copilot
                                    </span>
                                </div>
                            )}
                        </div>
                        <div>
                            <p className="text-sm font-rocGroteskMedium mb-4">Set timeline</p>
                            <div className="flex items-center gap-2.5">
                                <TextInput
                                    value={newAction.durationValue}
                                    name={"durationValue"}
                                    type={"number"}
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    required={true}
                                    onChange={(e) =>
                                        handleTaskCreationChange(e.target.name, e.target.value)
                                    }
                                    placeholder={"Enter number"}
                                    containerClassname="w-full "
                                    inputContainerClassname={""}
                                    inputClassName={`w-full`}
                                />
                                <SelectInput
                                    value={newAction.durationUnit}
                                    name="durationUnit"
                                    placeholder={"Select duration"}
                                    handleChange={(name, value) =>
                                        handleTaskCreationChange(name, value)
                                    }
                                    isRequired={true}
                                    dropdownOptions={[
                                        {
                                            label: "Minute(s)",
                                            value: "minutes",
                                        },
                                        {
                                            label: "Hour(s)",
                                            value: "hours",
                                        },
                                        { label: "Day(s)", value: "days" },
                                        { label: "Month(s)", value: "months" },
                                    ]}
                                />
                            </div>
                        </div>
                        {docUrl?.length > 0 && (
                            <div>
                                <p className="text-sm font-rocGroteskMedium mb-4">Add attachment</p>
                                <div className="border border-slate-200 rounded p-4">
                                    <Accordion
                                        open={true}
                                        title={"Attachment"}
                                        titleContainerClass={"!border-0 !pb-0"}
                                        containerClass="!mb-0"
                                        titleClass={"!text-slate-400"}
                                    >
                                        <div>
                                            <div className="mb-4">
                                                {docUrl?.map((data, idx) => {
                                                    return (
                                                        <div
                                                            key={data?.url}
                                                            className="py-2.5 border-b-[0.5px] border-slate-200 flex items-center justify-between"
                                                        >
                                                            <div className="flex items-center gap-3">
                                                                <div>
                                                                    {displayFileIcon(
                                                                        getFileTypeFromUrl(
                                                                            data?.url
                                                                        )
                                                                    )}
                                                                </div>
                                                                <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                                    {truncate(data?.name, {
                                                                        length: 40,
                                                                    })}
                                                                </span>
                                                            </div>
                                                            <span
                                                                onClick={() => {
                                                                    // handleRemoveDocument(idx)
                                                                    setDocToDelete({
                                                                        name: data?.name,
                                                                        actionId: selectedNodeId,
                                                                        docId: idx,
                                                                    });
                                                                    setDeleteDocModal(true);
                                                                }}
                                                                className="underline cursor-pointer text-carribean-green text-xs font-rocGroteskMedium"
                                                            >
                                                                Remove
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <span
                                                onClick={() => setAttachDocModal(true)}
                                                className="underline w-fit cursor-pointer text-carribean-green text-[13px] font-rocGroteskMedium"
                                            >
                                                Add more
                                            </span>
                                        </div>
                                    </Accordion>
                                </div>
                            </div>
                        )}
                        {!newAction?.actionName?.toLowerCase().includes("email") &&
                            newAction?.rules.length > 0 && (
                                <div className="mt-[14px] relative">
                                    <div className="px-3 py-2 bg-[#F2F2F2] border-[0.5px] border-gm-25 rounded-t text-sm font-rocGroteskMedium">
                                        Rule Criteria
                                    </div>
                                    {newAction?.rules?.map((rule, index) => {
                                        return (
                                            <div
                                                key={"rule" + index}
                                                className="relative flex items-center"
                                            >
                                                <div className="absolute left-[12px] top-[16px] z-10">
                                                    <Checkbox
                                                        name="complete"
                                                        isChecked={rule.complete}
                                                        onChange={(evt) => {
                                                            handleEventChange(
                                                                {
                                                                    target: {
                                                                        name: evt.target.name,
                                                                        value: evt.target.checked,
                                                                    },
                                                                },
                                                                index + 1
                                                            );
                                                        }}
                                                        className={
                                                            "!h-[14px] !w-[14px] !rounded-[4px] "
                                                        }
                                                    />
                                                </div>
                                                <div className="w-full flex gap-2.5 items-center group">
                                                    <TextInput
                                                        value={rule?.rule}
                                                        name={"rule"}
                                                        type={"text"}
                                                        required={true}
                                                        disabled={rule.complete}
                                                        onChange={(e) =>
                                                            handleEventChange(
                                                                {
                                                                    target: {
                                                                        name: e.target.name,
                                                                        value: e.target.value,
                                                                    },
                                                                },
                                                                index + 1
                                                            )
                                                        }
                                                        inputPlaceholder={"Rule"}
                                                        containerClassname="w-full "
                                                        inputContainerClassname={" !rounded-none"}
                                                        inputClassName={`w-full pl-[35px] ${
                                                            rule.complete && "line-through"
                                                        }`}
                                                    />
                                                    <div
                                                        onClick={() => handleRemoveRule(index)}
                                                        className="group-hover:flex w-[14px] cursor-pointer h-[14px] hidden items-center justify-center bg-n-20 p-[2px] rounded"
                                                    >
                                                        <i className="ri-subtract-fill text-sm text-slate-500"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <p
                                        onClick={() =>
                                            setNewAction((prev) => ({
                                                ...prev,
                                                rules: [
                                                    ...prev?.rules,
                                                    { rule: "", complete: false },
                                                ],
                                            }))
                                        }
                                        className="underline mt-5 w-fit cursor-pointer text-carribean-green text-[13px] font-rocGroteskMedium"
                                    >
                                        Add more
                                    </p>
                                </div>
                            )}
                        {(addNote || newAction?.note) && (
                            <div ref={noteRef}>
                                <p className="text-sm font-rocGroteskMedium mb-4">Add Note</p>
                                <TextareaInput
                                    name={"note"}
                                    value={newAction?.note}
                                    onChange={(e) =>
                                        handleTaskCreationChange(e.target.name, e.target.value)
                                    }
                                    rows={3}
                                    placeholder={"Note"}
                                    required={false}
                                    containerClassname=""
                                />
                            </div>
                        )}

                        {newAction?.linkToWorkflow && (
                            <div className="group flex items-center gap-2.5">
                                <SelectInput
                                    value={newAction?.workflowToLink}
                                    name="workflowToLink"
                                    placeholder={"Select workflow to link to"}
                                    handleChange={(name, value) =>
                                        handleTaskCreationChange(name, value)
                                    }
                                    isRequired={true}
                                    dropdownOptions={workFlowList
                                        .map((list) => ({
                                            label: list?.name,
                                            value: list._id,
                                        }))
                                        ?.filter((val) => val.value !== workflowId)}
                                />
                                <div
                                    onClick={() => handleRemoveLinkToWorkflow()}
                                    className="group-hover:flex w-[14px] cursor-pointer h-[14px] hidden items-center justify-center bg-n-20 p-[2px] rounded"
                                >
                                    <i className="ri-subtract-fill text-sm text-slate-500"></i>
                                </div>
                            </div>
                        )}
                    </div>
                    <div
                        onClick={() => handleAddLinkToWorkflow()}
                        className="flex items-center gap-3 mt-[14px] w-fit cursor-pointer"
                    >
                        <div className="w-8 h-8 rounded bg-n-20 flex items-center justify-center">
                            <i className="ri-link text-slate-500"></i>
                        </div>
                        <p className="text-sm font-rocGroteskMedium">Link to another workflow</p>
                    </div>
                    <div className="flex gap-[14px] items-center mt-8 mb-[45px]">
                        <div
                            onClick={() => setAttachDocModal(true)}
                            className="flex h-10 items-center gap-2.5 p-2.5 rounded border border-slate-200 cursor-pointer"
                        >
                            <i className="ri-attachment-2 text-gm-40"></i>
                            <span className="text-slate-500 font-rocGroteskMedium text-sm">
                                Add attachment
                            </span>
                        </div>
                        {!addNote && !Boolean(newAction.note) && (
                            <div
                                onClick={() => setAddNote(true)}
                                className="flex h-10 items-center gap-2.5 p-2.5 rounded border border-slate-200 cursor-pointer"
                            >
                                <i className="ri-chat-3-line text-gm-40"></i>
                                <span className="text-slate-500 font-rocGroteskMedium text-sm">
                                    Add comments
                                </span>
                            </div>
                        )}
                        {!newAction?.actionName?.toLowerCase().includes("email") && (
                            <div
                                onClick={() => {
                                    // setAddRules(true);
                                    setNewAction((prev) => ({
                                        ...prev,
                                        rules: [...prev?.rules, { rule: "", complete: false }],
                                    }));
                                }}
                                className="flex h-10 items-center gap-2.5 p-2.5 rounded border border-slate-200 cursor-pointer"
                            >
                                <i className="ri-add-fill text-gm-40"></i>
                                <span className="text-slate-500 font-rocGroteskMedium text-sm">
                                    Add Rules
                                </span>
                            </div>
                        )}

                        {/* <div className="w-[30px] h-[30px] rounded-full bg-n-30 flex items-center justify-center cursor-pointer">
                            <i className="ri-add-fill slate-500"></i>
                        </div> */}
                    </div>
                    <div className="flex items-center justify-end">
                        <div className="flex items-center gap-2.5">
                        <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"Back"}
                                type={"button"}
                                btnClassname="!h-[50px] !w-[140px] !bg-slate-100 !text-gm-50"
                                onClick={() => {
                                    handleCloseActionModal();
                                }}
                                disabled={creatingAction || updatingAction}
                            />
                            </div>
                            <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={
                                    Boolean(selectedNodeId) ? "Update action" : "Create action"
                                }
                                type={"submit"}
                                btnClassname="!h-[50px] !w-[140px]"
                                disabled={creatingAction || updatingAction}
                                isLoading={creatingAction || updatingAction}
                            />
                            </div>
                        </div>
                    </div>
                </form>
            </ModalContainer>

            <ModalContainer
                open={approvalActionModal}
                showCloseIcon={false}
                tailwindClassName="w-[38%] max-lg:w-[95%] max-h-[75%] overflow-auto"
                closeModal={() => {
                    handleCloseActionModal();
                }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (Boolean(selectedNodeId)) {
                            handleUpdateAction();
                        } else {
                            handleCreateAction();
                        }
                    }}
                    className="bg-white rounded w-full py-8 px-[30px] "
                >
                    <div className="flex items-center justify-between mb-6">
                        <div className="flex items-center gap-3">
                            <i className="ri-insert-row-top text-2xl text-b-55"></i>
                            <span className="text-lg font-rocGroteskMedium ">
                                {workflowCat === "rfq"
                                    ? "Approval action for RFQ"
                                    : workflowCat === "order"
                                    ? "Approval action for order"
                                    : "Approval action for purchase order"}
                            </span>
                        </div>
                        <i
                            onClick={() => {
                                handleCloseActionModal();
                            }}
                            className="ri-close-fill text-xl cursor-pointer"
                        ></i>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div>
                            <SelectInput
                                value={newAction?.actionName}
                                name="actionName"
                                placeholder={"Select action"}
                                isRequired={true}
                                handleChange={(name, value) => {
                                    handleTaskCreationChange(name, value);
                                }}
                                dropdownOptions={actions
                                    ?.filter((opt) => opt?.category?.toLowerCase() === "approval")
                                    .map(({ action, value, type }) => ({
                                        label: action,
                                        value: value + "-" + type,
                                        icon: <i className="ri-edit-2-line text-b-45"></i>,
                                    }))}
                                customDropdownContainer={
                                    !newAction?.isCopilot && (
                                        <div
                                            onClick={() => setCustomActionModal(true)}
                                            className="pl-4 py-1 cursor-pointer "
                                        >
                                            <p className="text-sm text-p-50 flex items-center gap-1">
                                                <i className="ri-add-fill text-p-50 text-lg mt-[-3px]"></i>
                                                <span>Add a custom action</span>
                                            </p>
                                        </div>
                                    )
                                }
                            />
                            <p className="text-[13px] text-slate-400 mt-2 font-rocGroteskRegular">
                                This is the task you want to be done
                            </p>
                        </div>
                        <div>
                            <div>
                                <div>
                                    {(selectedItems?.length > 0 ||
                                        newAction?.approval?.length > 0) && (
                                        <div className="mb-5">
                                            <div className="mb-5">
                                                <p className="text-sm font-rocGroteskMedium text-slate-400 mb-2.5">
                                                    Add approval level{" "}
                                                    <span className=" text-r-50 text-sm mt-[-2px] font-rocGroteskMedium">
                                                        *
                                                    </span>
                                                </p>
                                                <div>
                                                    <div className="bg-slate-100 w-full grid grid-cols-[85%_15%] border-x border-y border-slate-200">
                                                        <div className="px-4 py-2 border-r border-slate-200">
                                                            <p className="text-[13px] text-slate-400">
                                                                Approvals
                                                            </p>
                                                        </div>
                                                        <div className="px-4 py-2">
                                                            <p className="text-[13px] text-slate-400">
                                                                Levels
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {selectedTeam?.map((member, idx) => (
                                                        <DraggableContainer
                                                            key={idx}
                                                            index={idx}
                                                            member={member}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                            {!approvalField && (
                                                <div>
                                                    <p
                                                        className="flex items-center gap-2 cursor-pointer"
                                                        onClick={() => setApprovalField(true)}
                                                    >
                                                        <i className="ri-add-fill text-g-60"></i>
                                                        <span className="text-g-60 underline text-[13px] font-rocGroteskMedium">
                                                            Add more
                                                        </span>
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>

                                {(approvalField ||
                                    (selectedItems?.length === 0 &&
                                        newAction?.approval?.length === 0)) && (
                                    <div className="relative">
                                        <div
                                            onClick={() => setOpen(!open)}
                                            className={`flex justify-between cursor-pointer rounded items-center border-[0.5px] border-gm-25 pr-2 475:pr-4 outline-0 w-full h-[50px]  py-3 text-sm font-rocGrotesk`}
                                        >
                                            <div className="w-full mb-[-8px]">
                                                <input
                                                    className={`inputText w-full outline-0 h-full max-475:pl-3 pl-4 bg-transparent rounded font-rocGroteskRegular outline-none text-gm-50 focus:border-0 placeholder:text-sm placeholder:!font-rocGroteskRegular`}
                                                    type={"text"}
                                                    value={approvalValue}
                                                    onChange={(e) =>
                                                        setApprovalValue(e.target.value)
                                                    }
                                                    required={selectedTeam?.length === 0}
                                                />

                                                <span
                                                    className={`floating-label text-sm text-gm-35 font-rocGroteskRegular`}
                                                >
                                                    Add approval level
                                                    {selectedTeam?.length === 0 &&
                                                        newAction?.approval?.length === 0 && (
                                                            <span className="required text-r-50 text-sm mt-[-2px] font-rocGroteskMedium">
                                                                *
                                                            </span>
                                                        )}
                                                </span>
                                            </div>

                                            <span
                                                className={`material-icons text-2xl text-gm-25`}
                                                ref={togglRef}
                                                onClick={() => setOpen(!open)}
                                            >
                                                keyboard_arrow_down
                                            </span>
                                        </div>
                                        {open &&
                                            (filterApprovalOption?.length > 0 ? (
                                                <div
                                                    ref={ref}
                                                    className={`py-2 bg-white absolute top-[101%] w-full h-auto max-h-64 z-[999] overflow-auto border `}
                                                    id="custom-select"
                                                >
                                                    {filterApprovalOption.map((option, idx) => {
                                                        return (
                                                            <div
                                                                key={idx}
                                                                onClick={() => {
                                                                    const selected = teamMembers
                                                                        ?.map((member) => ({
                                                                            label: member?.parent
                                                                                ?.fullName,
                                                                            subLabel:
                                                                                member?.role?.[0]?.toUpperCase() +
                                                                                member?.role?.slice(
                                                                                    1
                                                                                ),
                                                                            subText: member?.email,
                                                                            value: member?._id,
                                                                            icon: (
                                                                                <AvatarContainer
                                                                                    size={40}
                                                                                    imageSrc={
                                                                                        member?.avatar
                                                                                    }
                                                                                    name={
                                                                                        member?.fullName ||
                                                                                        member?.email
                                                                                    }
                                                                                />
                                                                            ),
                                                                        }))
                                                                        ?.find(
                                                                            (item) =>
                                                                                item?.value ===
                                                                                option?.value
                                                                        );
                                                                    const selectedItemsCopy = [
                                                                        ...selectedItems,
                                                                    ];
                                                                    const selectedItemIndex =
                                                                        selectedItemsCopy.findIndex(
                                                                            (item) =>
                                                                                item?.toLowerCase() ===
                                                                                selected?.value?.toLowerCase()
                                                                        );

                                                                    if (selectedItemIndex !== -1) {
                                                                        selectedItemsCopy.splice(
                                                                            selectedItemIndex,
                                                                            1
                                                                        );
                                                                    } else {
                                                                        selectedItemsCopy.push(
                                                                            option?.value
                                                                        );
                                                                    }

                                                                    const team =
                                                                        selectedItemsCopy?.map?.(
                                                                            (memberId) =>
                                                                                teamMembers?.find(
                                                                                    (member) =>
                                                                                        member?._id ===
                                                                                        memberId
                                                                                )
                                                                        );
                                                                    setSelectedTeam(team);
                                                                    setSelectedValue(option?.value);
                                                                    setSelectedItems(
                                                                        selectedItemsCopy
                                                                    );
                                                                }}
                                                                className={`flex items-center px-4 justify-between hover:bg-slate-100 ${
                                                                    selectedOption?.value ===
                                                                        option?.value &&
                                                                    selectedOption?.label ===
                                                                        option?.label &&
                                                                    // selectedOption?.role === option?.role &&
                                                                    "tradeally-blue "
                                                                } cursor-pointer `}
                                                            >
                                                                <p
                                                                    className={`text-sm flex gap-3 py-3 items-center font-rocGroteskRegular`}
                                                                >
                                                                    {option?.icon && (
                                                                        <span>{option?.icon}</span>
                                                                    )}{" "}
                                                                    <div>
                                                                        <div className="font-rocGroteskMedium">
                                                                            <span>
                                                                                {option?.label}
                                                                            </span>
                                                                            {option?.subLabel &&
                                                                                " - "}
                                                                            {option?.subLabel && (
                                                                                <span
                                                                                    className={
                                                                                        "text-xs text-p-50 font-rocGroteskMedium"
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        option?.subLabel
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        {option?.subText && (
                                                                            <div className="text-xs font-rocGroteskMedium text-slate-500">
                                                                                {option?.subText}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </p>
                                                                {selectedItems?.includes(
                                                                    option?.value
                                                                ) ? (
                                                                    <i className="ri-checkbox-fill before:content-['\eb82'] text-xl mt-[-2px] text-carribean-green"></i>
                                                                ) : (
                                                                    <i className="ri-checkbox-blank-line before:content-['\eb7f'] text-xl mt-[-2px] text-gm-25"></i>
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ) : (
                                                <div
                                                    className={`py-2 bg-white absolute top-[101%] w-full h-auto max-h-64 z-[999] overflow-auto border `}
                                                    id="custom-select"
                                                    ref={ref}
                                                >
                                                    <div className="flex px-4 justify-between hover:tradeally-blue cursor-pointer">
                                                        <p className="text-sm flex gap-3 py-3 items-center font-rocGroteskRegular">
                                                            <span>{"No Data"}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div>
                            <p className="text-sm font-rocGroteskMedium mb-4">Set timeline</p>
                            <div className="flex items-center gap-2.5">
                                <TextInput
                                    value={newAction.durationValue}
                                    name={"durationValue"}
                                    type={"number"}
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    required={true}
                                    onChange={(e) =>
                                        handleTaskCreationChange(e.target.name, e.target.value)
                                    }
                                    placeholder={"Enter number"}
                                    containerClassname="w-full "
                                    inputContainerClassname={""}
                                    inputClassName={`w-full`}
                                />
                                <SelectInput
                                    value={newAction.durationUnit}
                                    name="durationUnit"
                                    placeholder={"Select duration"}
                                    handleChange={(name, value) =>
                                        handleTaskCreationChange(name, value)
                                    }
                                    isRequired={true}
                                    dropdownOptions={[
                                        {
                                            label: "Minute(s)",
                                            value: "minutes",
                                        },
                                        {
                                            label: "Hour(s)",
                                            value: "hours",
                                        },
                                        { label: "Day(s)", value: "days" },
                                        { label: "Month(s)", value: "months" },
                                    ]}
                                />
                            </div>
                        </div>
                        {(docUrl?.length > 0 || newAction?.document?.length > 0) && (
                            <div>
                                <p className="text-sm font-rocGroteskMedium mb-4">Add attachment</p>
                                <div className="border border-slate-200 rounded p-4">
                                    <Accordion
                                        open={true}
                                        title={"Attachment"}
                                        titleContainerClass={"!border-0 !pb-0"}
                                        containerClass="!mb-0"
                                        titleClass={"!text-slate-400"}
                                    >
                                        <div>
                                            <div className="mb-4">
                                                {(docUrl || newAction?.document)?.map(
                                                    (data, idx) => {
                                                        return (
                                                            <div
                                                                key={data?.url}
                                                                className="py-2.5 border-b-[0.5px] border-slate-200 flex items-center justify-between"
                                                            >
                                                                <div className="flex items-center gap-3">
                                                                    <div>
                                                                        {displayFileIcon(
                                                                            getFileTypeFromUrl(
                                                                                data?.url ||
                                                                                    data?.path
                                                                            )
                                                                        )}
                                                                    </div>
                                                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                                        {truncate(data?.name, {
                                                                            length: 40,
                                                                        })}
                                                                    </span>
                                                                </div>
                                                                <span
                                                                    onClick={() => {
                                                                        // handleRemoveDocument(idx)
                                                                        setDocToDelete({
                                                                            name: data?.name,
                                                                            actionId:
                                                                                selectedNodeId,
                                                                            docId: idx,
                                                                        });
                                                                        setDeleteDocModal(true);
                                                                    }}
                                                                    className="underline cursor-pointer text-carribean-green text-xs font-rocGroteskMedium"
                                                                >
                                                                    Remove
                                                                </span>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                            <span
                                                onClick={() => setAttachDocModal(true)}
                                                className="underline w-fit cursor-pointer text-carribean-green text-[13px] font-rocGroteskMedium"
                                            >
                                                Add more
                                            </span>
                                        </div>
                                    </Accordion>
                                </div>
                            </div>
                        )}
                        {!newAction?.actionName?.toLowerCase().includes("email") &&
                            newAction?.rules.length > 0 && (
                                <div className="mt-[14px] relative">
                                    <div className="px-3 py-2 bg-[#F2F2F2] border-[0.5px] border-gm-25 rounded-t text-sm font-rocGroteskMedium">
                                        Rule Criteria
                                    </div>
                                    {newAction?.rules?.map((rule, index) => {
                                        return (
                                            <div
                                                key={"rule" + index}
                                                className="relative flex items-center"
                                            >
                                                <div className="absolute left-[12px] top-[16px] z-10">
                                                    <Checkbox
                                                        name="complete"
                                                        isChecked={rule.complete}
                                                        onChange={(evt) => {
                                                            handleEventChange(
                                                                {
                                                                    target: {
                                                                        name: evt.target.name,
                                                                        value: evt.target.checked,
                                                                    },
                                                                },
                                                                index + 1
                                                            );
                                                        }}
                                                        className={
                                                            "!h-[14px] !w-[14px] !rounded-[4px] "
                                                        }
                                                    />
                                                </div>
                                                <div className="w-full flex gap-2.5 items-center group">
                                                    <TextInput
                                                        value={rule?.rule}
                                                        name={"rule"}
                                                        type={"text"}
                                                        required={true}
                                                        disabled={rule.complete}
                                                        onChange={(e) =>
                                                            handleEventChange(
                                                                {
                                                                    target: {
                                                                        name: e.target.name,
                                                                        value: e.target.value,
                                                                    },
                                                                },
                                                                index + 1
                                                            )
                                                        }
                                                        inputPlaceholder={"Rule"}
                                                        containerClassname="w-full "
                                                        inputContainerClassname={" !rounded-none"}
                                                        inputClassName={`w-full pl-[35px] mt-[-7px] ${
                                                            rule.complete && "line-through"
                                                        }`}
                                                    />
                                                    <div
                                                        onClick={() => handleRemoveRule(index)}
                                                        className="group-hover:flex w-[14px] cursor-pointer h-[14px] hidden items-center justify-center bg-n-20 p-[2px] rounded"
                                                    >
                                                        <i className="ri-subtract-fill text-sm text-slate-500"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <span
                                        onClick={() =>
                                            setNewAction((prev) => ({
                                                ...prev,
                                                rules: [
                                                    ...prev?.rules,
                                                    { rule: "", complete: false },
                                                ],
                                            }))
                                        }
                                        className="underline mt-5 w-fit cursor-pointer text-carribean-green text-[13px] font-rocGroteskMedium"
                                    >
                                        Add more
                                    </span>
                                </div>
                            )}
                        {(addNote || newAction.note) && (
                            <div ref={noteRef}>
                                <p className="text-sm font-rocGroteskMedium mb-4">Add Note</p>
                                <TextareaInput
                                    name={"note"}
                                    value={newAction?.note}
                                    onChange={(e) =>
                                        handleTaskCreationChange(e.target.name, e.target.value)
                                    }
                                    rows={3}
                                    placeholder={"Note"}
                                    required={false}
                                    containerClassname=""
                                />
                            </div>
                        )}

                        {newAction?.linkToWorkflow && (
                            <div className="group flex items-center gap-2.5">
                                <SelectInput
                                    value={newAction?.workflowToLink}
                                    name="workflowToLink"
                                    placeholder={"Select workflow to link to"}
                                    handleChange={(name, value) =>
                                        handleTaskCreationChange(name, value)
                                    }
                                    isRequired={true}
                                    dropdownOptions={workFlowList
                                        .map((list) => ({
                                            label: list?.name,
                                            value: list._id,
                                        }))
                                        ?.filter((val) => val.value !== workflowId)}
                                />
                                <div
                                    onClick={() => handleRemoveLinkToWorkflow()}
                                    className="group-hover:flex w-[14px] cursor-pointer h-[14px] hidden items-center justify-center bg-n-20 p-[2px] rounded"
                                >
                                    <i className="ri-subtract-fill text-sm text-slate-500"></i>
                                </div>
                            </div>
                        )}
                    </div>
                    <div
                        onClick={() => handleAddLinkToWorkflow()}
                        className="flex items-center gap-3 mt-[14px] w-fit cursor-pointer"
                    >
                        <div className="w-8 h-8 rounded bg-n-20 flex items-center justify-center">
                            <i className="ri-link text-slate-500"></i>
                        </div>
                        <p className="text-sm font-rocGroteskMedium">Link to another workflow</p>
                    </div>
                    <div className="flex gap-[14px] items-center mt-8 mb-[45px]">
                        <div
                            onClick={() => setAttachDocModal(true)}
                            className="flex h-10 items-center gap-2.5 p-2.5 rounded border border-slate-200 cursor-pointer"
                        >
                            <i className="ri-attachment-2 text-gm-40"></i>
                            <span className="text-slate-500 font-rocGroteskMedium text-sm">
                                Add attachment
                            </span>
                        </div>
                        {!addNote && !Boolean(newAction.note) && (
                            <div
                                onClick={() => setAddNote(true)}
                                className="flex h-10 items-center gap-2.5 p-2.5 rounded border border-slate-200 cursor-pointer"
                            >
                                <i className="ri-chat-3-line text-gm-40"></i>
                                <span className="text-slate-500 font-rocGroteskMedium text-sm">
                                    Add comments
                                </span>
                            </div>
                        )}
                        {!newAction?.actionName?.toLowerCase().includes("email") && (
                            <div
                                onClick={() => {
                                    // setAddRules(true);
                                    setNewAction((prev) => ({
                                        ...prev,
                                        rules: [...prev?.rules, { rule: "", complete: false }],
                                    }));
                                }}
                                className="flex h-10 items-center gap-2.5 p-2.5 rounded border border-slate-200 cursor-pointer"
                            >
                                <i className="ri-add-fill text-gm-40"></i>
                                <span className="text-slate-500 font-rocGroteskMedium text-sm">
                                    Add Rules
                                </span>
                            </div>
                        )}

                        {/* <div className="w-[30px] h-[30px] rounded-full bg-n-30 flex items-center justify-center cursor-pointer">
                            <i className="ri-add-fill slate-500"></i>
                        </div> */}
                    </div>
                    <div className="flex items-center justify-end">
                        <div className="flex items-center gap-2.5">
                        <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"Back"}
                                type={"button"}
                                btnClassname="!h-[50px] !w-[140px] !bg-slate-100 !text-gm-50"
                                onClick={() => {
                                    handleCloseActionModal();
                                }}
                                disabled={retryingAction || updatingAction}
                            />
                            </div>
                            <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={
                                    Boolean(selectedNodeId) ? "Update action" : "Create action"
                                }
                                type={"submit"}
                                btnClassname="!h-[50px] !w-[140px]"
                                isLoading={creatingAction || updatingAction}
                                disabled={creatingAction || updatingAction}
                            />
                            </div>
                        </div>
                    </div>
                </form>
            </ModalContainer>

            <ModalContainer
                open={duplicateModal}
                showCloseIcon={false}
                tailwindClassName="w-[38%] max-lg:w-[95%]"
                closeModal={() => setDuplicateModal(false)}
            >
                <div className="bg-white rounded w-full py-8 px-[30px]">
                    <div className="flex items-center justify-between mb-5 pb-[14px] border-b border-slate-100">
                        <div className="flex items-center gap-3">
                            <i className="ri-flashlight-line text-2xl text-p-55"></i>
                            <span className="text-lg font-rocGroteskMedium ">Duplicate Action</span>
                        </div>
                        <i
                            onClick={() => setDuplicateModal(false)}
                            className="ri-close-fill text-xl cursor-pointer"
                        ></i>
                    </div>
                    <div className=" ">
                        <div>
                            <div>
                                <p className="text-sm text-center my-6 text-slate-500 font-rocGroteskMedium">
                                    Are you sure you want to duplicate this action?
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 space-y-4">
                        <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"Yes, duplicate action"}
                                type={"button"}
                                btnClassname="!py-3 !px-4"
                                onClick={() => {
                                    dispatch(workFlowActions.duplicateAction(selectedNodeId));
                                }}
                                isLoading={duplicatingAction}
                            />
                            </div>

                            <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"No, cancel"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => setDuplicateModal(false)}
                                disabled={duplicatingAction}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            {/* ====================================== */}

            <ModalContainer
                open={actionSummaryModal}
                showCloseIcon={false}
                tailwindClassName="w-[42%] max-lg:w-[95%]"
                closeModal={() => setActionSummaryModal(false)}
            >
                <div className="bg-white rounded-[8px] w-full relative">
                    <div className="cursor-pointer flex justify-end pr-8 pt-6">
                        <i
                            onClick={() => setActionSummaryModal(false)}
                            className="ri-close-fill text-gm-45 text-[20px]"
                        ></i>
                    </div>
                    <div className="px-8 pb-8">
                        <p className="text-[22px] font-rocGroteskMedium  capitalize mb-2.5">
                            {actions.find((act) => act.value === selectedAction?.name)?.action ||
                                selectedAction?.name?.replace(/([A-Z])/g, " $1")}
                        </p>
                        <div className="mb-[35px]">
                            <StatusBadge status={selectedAction?.currentStatus} />
                        </div>
                        {selectedAction?.currentStatus?.toLowerCase() !== "completed" && (
                            <div className="flex items-center gap-[14px] mb-5">
                                <SelectInput
                                    value={"copilot"}
                                    name="assigneeType"
                                    placeholder={"Assign to"}
                                    disabled={true}
                                    dropdownOptions={[
                                        {
                                            label: "Co-pilot",
                                            value: "copilot",
                                        },
                                        {
                                            label: "Team member",
                                            value: "LDB",
                                        },
                                        { label: "Vendor", value: "vendor" },
                                    ]}
                                />

                                <TextInput
                                    value={selectedAction?.duration?.value}
                                    name={"durationValue"}
                                    type={"number"}
                                    disabled={true}
                                    placeholder={"Duration value"}
                                    containerClassname="w-full "
                                    inputContainerClassname={""}
                                    inputClassName={`w-full`}
                                />
                                <SelectInput
                                    value={selectedAction?.duration?.unit}
                                    name="durationUnit"
                                    placeholder={"Duration unit"}
                                    disabled={true}
                                    dropdownOptions={[
                                        {
                                            label: "Minute(s)",
                                            value: "minutes",
                                        },
                                        {
                                            label: "Hour(s)",
                                            value: "hours",
                                        },
                                        { label: "Day(s)", value: "days" },
                                        { label: "Month(s)", value: "months" },
                                    ]}
                                />
                            </div>
                        )}
                        <div className="rounded p-3 bg-slate-50 flex flex-col gap-2">
                            <p className="flex items-center gap-1 text-sm ">
                                <span className="text-slate-700 font-rocGroteskBold">
                                    Assignee:
                                </span>
                                <span className="text-slate-700 font-rocGroteskMedium">
                                    Co-pilot
                                </span>
                            </p>
                            <p className="flex items-center gap-1 text-sm ">
                                <span className="text-slate-700 font-rocGroteskBold">
                                    Total Runtime:
                                </span>
                                <span className="text-slate-700 font-rocGroteskMedium">
                                    {parseConvertedSeconds(convertSeconds(selectedAction?.runTime))}
                                </span>
                            </p>
                            <div className=" gap-1 text-sm ">
                                <p className="flex items-center gap-1">
                                    <span className="text-slate-700 font-rocGroteskBold">
                                        Rules:
                                    </span>
                                    {selectedAction?.actionRules &&
                                        selectedAction?.actionRules?.length === 0 && (
                                            <span className="text-slate-700 font-rocGroteskMedium">
                                                N/A
                                            </span>
                                        )}
                                </p>

                                {selectedAction?.actionRules &&
                                    selectedAction?.actionRules?.length > 0 && (
                                        <div className="flex flex-col gap-1 pl-2">
                                            {selectedAction?.actionRules?.map((rule) => (
                                                <p className="text-sm font-rocGroteskMedium">
                                                    - {rule?.action}
                                                </p>
                                            ))}
                                        </div>
                                    )}
                            </div>
                            <div className=" gap-1 text-sm ">
                                <p className="flex items-center gap-1">
                                    <span className="text-slate-700 font-rocGroteskBold">
                                        Result:
                                    </span>
                                    {!selectedAction?.data?.quoteEvaluation?.response &&
                                        !selectedAction?.data?.craftedEmail && (
                                            <span className="text-slate-700 font-rocGroteskMedium">
                                                N/A
                                            </span>
                                        )}
                                </p>

                                {selectedAction?.data?.quoteEvaluation?.response && (
                                    <div className="flex flex-col gap-1 pl-2">
                                        <p className="grid grid-cols-[115px_auto] items-start gap-1 text-sm ">
                                            <span className="text-slate-700  font-rocGroteskMedium whitespace-nowrap">
                                                Payment terms:
                                            </span>
                                            <span className="text-slate-700 font-rocGroteskRegular">
                                                {selectedAction?.data?.quoteEvaluation?.response
                                                    ?.paymentTerms?.result || "N/A"}
                                            </span>
                                        </p>
                                        <p className="grid grid-cols-[115px_auto] items-start gap-1 text-sm ">
                                            <span className="text-slate-700  font-rocGroteskMedium whitespace-nowrap">
                                                Pricing:
                                            </span>
                                            <span className="text-slate-700 font-rocGroteskRegular">
                                                {selectedAction?.data?.quoteEvaluation?.response
                                                    ?.pricing?.result || "N/A"}
                                            </span>
                                        </p>
                                        <p className="grid grid-cols-[115px_auto] items-start gap-1 text-sm ">
                                            <span className="text-slate-700  font-rocGroteskMedium whitespace-nowrap">
                                                Delivery date:
                                            </span>
                                            <span className="text-slate-700 font-rocGroteskRegular">
                                                {selectedAction?.data?.quoteEvaluation?.response
                                                    ?.deliveryDate?.result || "N/A"}
                                            </span>
                                        </p>
                                    </div>
                                )}
                                {Boolean(selectedAction?.data?.craftedEmail) && (
                                    <div className="flex flex-col gap-1 pl-2">
                                        <p className="flex items-start gap-1 text-sm ">
                                            <span className="text-slate-700 font-rocGroteskMedium whitespace-nowrap">
                                                Crafted Email:
                                            </span>
                                            <span className="text-slate-700 font-rocGroteskRegular">
                                                {selectedAction?.data?.craftedEmail}
                                            </span>
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        onClick={() => setActionSummaryModal(false)}
                        className="cursor-pointer py-5 px-8 border-t border-slate-200"
                    >
                        <div className="text-sm w-fit border rounded py-2.5 px-4">Close</div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={AILoading}
                showCloseIcon={false}
                tailwindClassName="w-[32.3%] max-lg:w-[95%]"
            >
                <div className="bg-white flex flex-col items-center justify-center h-[442px] rounded-[8px] w-full ">
                    <div className="px-6 py-4 w-[98px] h-[82px] flex items-center justfy-center bg-white rounded-lg shadow-faintShadow mb-[29px]">
                        <i className="ri-robot-fill text-[50px] bg-clip-text text-transparent bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%"></i>
                    </div>
                    <div className="mb-1">
                        <ThreeDots
                            height="40"
                            width="60"
                            radius="9"
                            color="#64748B33"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                    <p className="text-sm font-rocGroteskMedium text-center">
                        Generating your workflow...
                    </p>
                </div>
            </ModalContainer>

            <ModalContainer
                open={retryModal}
                showCloseIcon={false}
                closeModal={() => setRetryModal(false)}
                tailwindClassName="w-[36%] max-lg:w-[95%]"
            >
                <div className="bg-white flex flex-col rounded-[8px] p-8 relative">
                    <div
                        onClick={() => setRetryModal(false)}
                        className="cursor-pointer flex justify-end absolute top-[30px] right-[32px]"
                    >
                        <i className="ri-close-fill text-gm-45 text-[20px]"></i>
                    </div>
                    <div className=" ">
                        <div>
                            <h6 className="text-center font-rocGroteskMedium text-2xl">
                                Retry action?
                            </h6>
                            <div>
                                <p className="text-sm text-center my-6 text-slate-500 font-rocGroteskMedium">
                                    Are you use you want to retry this action?
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 space-y-4">
                        <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"Yes, retry action"}
                                type={"button"}
                                btnClassname="!py-3 !px-4"
                                onClick={() => {
                                    dispatch(
                                        workFlowActions.retryAction({
                                            actionId: selectedNodeId,
                                        })
                                    );
                                }}
                                isLoading={retryingAction}
                            />
                            </div>
                            <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"No, cancel"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => {
                                    setRetryModal(false);
                                }}
                                disabled={retryingAction}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={markAsDoneModal}
                showCloseIcon={false}
                closeModal={() => setMarkAsDoneModal(false)}
                tailwindClassName="w-[36%] max-lg:w-[95%]"
            >
                <div className="bg-white flex flex-col rounded-[8px] p-8 relative">
                    <div
                        onClick={() => setMarkAsDoneModal(false)}
                        className="cursor-pointer flex justify-end absolute top-[30px] right-[32px]"
                    >
                        <i className="ri-close-fill text-gm-45 text-[20px]"></i>
                    </div>
                    <div className=" ">
                        <div>
                            <h6 className="text-center font-rocGroteskMedium text-2xl">
                                Mark action as wrong?
                            </h6>
                            <div>
                                <p className="text-sm text-center my-6 text-slate-500 font-rocGroteskMedium">
                                    Are you use you want to mark this action as done?
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 space-y-4">
                        <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"Yes, mark action as done"}
                                type={"button"}
                                btnClassname="!py-3 !px-4"
                                onClick={() => {
                                    dispatch(
                                        workFlowActions.markActionAsDone({
                                            actionId: selectedNodeId,
                                        })
                                    );
                                }}
                                isLoading={markingActionAsDone}
                                disabled={markingActionAsDone}
                            />
                            </div>
                            <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"No, cancel"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => {
                                    setMarkAsDoneModal(false);
                                }}
                                disabled={markingActionAsDone}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={customEventModal}
                showCloseIcon={false}
                closeModal={() => setCustomEventModal(false)}
                tailwindClassName="w-[34.5%] max-lg:w-[95%]"
            >
                <div className="bg-white flex flex-col rounded-[8px] p-8 relative">
                    <div
                        onClick={() => setCustomEventModal(false)}
                        className="cursor-pointer flex justify-end absolute top-[30px] right-[32px]"
                    >
                        <i className="ri-close-fill text-gm-45 text-[20px]"></i>
                    </div>
                    <div className=" ">
                        <div className="mb-10">
                            <h6 className="text-center font-rocGroteskMedium text-2xl mb-10">
                                Add a custom event
                            </h6>
                            <div>
                                <TextInput
                                    value={customEvent}
                                    name={"customEvent"}
                                    type={"text"}
                                    required={true}
                                    onChange={(e) => {
                                        setCustomEvent(e?.target?.value);
                                    }}
                                    placeholder={"Enter a custom event"}
                                    containerClassname="w-full "
                                    inputContainerClassname={""}
                                    inputClassName={`w-full`}
                                    showInputComment={true}
                                    inputComment={
                                        "Type the specific event that you want to trigger a set of actions after it happens"
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-6">
                        <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"Cancel"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => {
                                    setCustomEventModal(false);
                                }}
                            />
                            </div>
                            <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"Save event"}
                                type={"button"}
                                btnClassname="!py-3 !px-4"
                                onClick={() => {
                                    const data = customEvent?.replace(
                                        /\s+([a-zA-Z])/g,
                                        function (match, $1) {
                                            return $1.toUpperCase();
                                        }
                                    );
                                    const valueToSave = data?.[0]?.toLowerCase() + data?.slice(1);
                                    const eventToSave = customEvent?.replace(
                                        /\b(\w)/g,
                                        function (match) {
                                            return match.toUpperCase();
                                        }
                                    );

                                    setEvents((prev) => [
                                        {
                                            event: eventToSave,
                                            value: valueToSave,
                                            eventType: [workflowCat],
                                        },
                                        ...prev,
                                    ]);
                                    // setEventValue(valueToSave);
                                    setCustomEvent("");
                                    toast.custom((t) => (
                                        <CustomToast
                                            t={t}
                                            message={"Event added to option list"}
                                            type={"success"}
                                        />
                                    ));
                                    setNewEvent((prev) => ({
                                        ...prev,
                                        eventName: valueToSave + "-" + workflowCat,
                                    }));
                                    setCustomEventModal(false);
                                }}
                                // disabled={deletingAction}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={Boolean(customActionModal)}
                showCloseIcon={false}
                closeModal={() => setCustomActionModal(null)}
                tailwindClassName="w-[34.5%] max-lg:w-[95%]"
            >
                <div className="bg-white flex flex-col rounded-[8px] p-8 relative">
                    <div
                        onClick={() => setCustomActionModal(null)}
                        className="cursor-pointer flex justify-end absolute top-[30px] right-[32px]"
                    >
                        <i className="ri-close-fill text-gm-45 text-[20px]"></i>
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            const data = customAction?.replace(
                                /\s+([a-zA-Z])/g,
                                function (match, $1) {
                                    return $1.toUpperCase();
                                }
                            );

                            const valueToSave = data?.[0]?.toLowerCase() + data?.slice(1);

                            const actionToSave = customAction?.replace(/\b(\w)/g, function (match) {
                                return match.toUpperCase();
                            });

                            setActions((prev) => [
                                {
                                    action: actionToSave,
                                    value: valueToSave,
                                    category: taskActionModal
                                        ? "task"
                                        : approvalActionModal
                                        ? "approval"
                                        : customActionType,
                                    type: workflowCat,
                                    iconClass: "ri-edit-2-line",
                                },
                                ...prev,
                            ]);
                            setNewAction((prev) => ({
                                ...prev,
                                actionName: valueToSave + "-" + workflowCat,
                            }));
                            setCustomAction("");
                            toast.custom((t) => (
                                <CustomToast
                                    t={t}
                                    message={"Action added to option list"}
                                    type={"success"}
                                />
                            ));
                            setCustomActionModal(null);
                        }}
                        className=" "
                    >
                        <div className="mb-10">
                            <h6 className="text-center font-rocGroteskMedium text-2xl mb-10">
                                Add a custom action
                            </h6>
                            <div className="flex flex-col gap-4">
                                {!taskActionModal && !approvalActionModal && (
                                    <SelectInput
                                        value={customActionType}
                                        name="customActionType"
                                        placeholder={"Action type"}
                                        isRequired={true}
                                        handleChange={(name, value) => {
                                            setCustomActionType(value);
                                        }}
                                        dropdownOptions={[
                                            {
                                                label: "Task",
                                                value: "task",
                                            },
                                            {
                                                label: "Approval",
                                                value: "approval",
                                            },
                                        ]}
                                    />
                                )}

                                <TextInput
                                    value={customAction}
                                    name={"customAction"}
                                    type={"text"}
                                    required={true}
                                    onChange={(e) => {
                                        setCustomAction(e?.target?.value);
                                    }}
                                    placeholder={"Enter a custom action"}
                                    containerClassname="w-full "
                                    inputContainerClassname={""}
                                    inputClassName={`w-full`}
                                    showInputComment={true}
                                    inputComment={
                                        "Type the action that you want to trigger after a specific event happens"
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-6">
                        <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"Cancel"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => {
                                    setCustomActionModal(null);
                                }}
                            />
                            </div>

                            <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"Save action"}
                                type={"submit"}
                                btnClassname="!py-3 !px-4"
                            />
                            </div>
                        </div>
                    </form>
                </div>
            </ModalContainer>

            <ModalContainer
                open={seeAllDocsModal}
                showCloseIcon={false}
                closeModal={() => setSeeAllDocsModal(false)}
                tailwindClassName="w-[34.5%] max-lg:w-[95%]"
            >
                <div className="bg-white flex flex-col rounded-[8px] p-8 relative">
                    <div
                        onClick={() => setSeeAllDocsModal(false)}
                        className="cursor-pointer flex justify-end absolute top-[30px] right-[32px]"
                    >
                        <i className="ri-close-fill text-gm-45 text-[20px]"></i>
                    </div>
                    <div className=" ">
                        <div>
                            <h6 className="text-center font-rocGroteskMedium text-2xl">
                                Attachments
                            </h6>
                            <div>
                                {thisActionDocs?.map((actionDoc) => {
                                    return (
                                        <div
                                            key={actionDoc?._id}
                                            className="mt-4 p-4 rounded border border-slate-200 flex items-center gap-4"
                                        >
                                            {displayFileIcon("", null, "!w-10 !h-10")}
                                            <div className="w-full flex justify-between items-start">
                                                <div>
                                                    <p className="text-sm font-rocGroteskMedium ">
                                                        {truncate(actionDoc?.name, { length: 40 })}
                                                    </p>
                                                    {/* <p className="text-xs font-rocGroteskMedium text-tradeally-neutral-200 mb-1">
                                                116.7 kB
                                            </p> */}
                                                    <p
                                                        onClick={() => {
                                                            const a = document.createElement("a");
                                                            a.href = actionDoc?.path;
                                                            a.target = "_blank";
                                                            document.body.appendChild(a);
                                                            a.click();
                                                            document.body.removeChild(a);
                                                        }}
                                                        className="cursor-pointer text-xs underline font-rocGroteskMedium text-tradeally-neutral-200"
                                                    >
                                                        Click to view
                                                    </p>
                                                </div>
                                                <i
                                                    onClick={(e) => {
                                                        e.stopPropagation();

                                                        setDocToDelete({
                                                            name: actionDoc?.name,
                                                            actionId: selectedNodeId,
                                                            docId: actionDoc?._id,
                                                        });
                                                        setDeleteDocModal(true);
                                                    }}
                                                    // disabled={uploading || updatingEventsAndActions}
                                                    className="ri-delete-bin-line text-slate-500 cursor-pointer"
                                                ></i>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="mt-5 ">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => {
                                    setSeeAllDocsModal(false);
                                }}
                                // disabled={deletingAction}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={deleteDocModal}
                showCloseIcon={false}
                closeModal={() => setDeleteDocModal(false)}
                tailwindClassName="w-[36%] max-lg:w-[95%]"
            >
                <div className="bg-white flex flex-col rounded-[8px] p-8 relative">
                    <div
                        onClick={() => setDeleteDocModal(false)}
                        className="cursor-pointer flex justify-end absolute top-[30px] right-[32px]"
                    >
                        <i className="ri-close-fill text-gm-45 text-[20px]"></i>
                    </div>
                    <div className=" ">
                        <div>
                            <h6 className="text-center font-rocGroteskMedium text-2xl">
                                Remove Document?
                            </h6>
                            <div>
                                <p className="text-sm text-center text-slate-500 font-rocGroteskMedium">
                                    Are you use you want to remove this document{" "}
                                    <span className="font-rocGroteskBold">{docToDelete.name}</span>{" "}
                                    ?
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 space-y-4">
                        <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"Yes, remove document"}
                                type={"button"}
                                btnClassname="!py-3 !bg-[#FFEFEC] border border-r-50 !text-dark-gray-2 !px-4"
                                onClick={() => {
                                    // handleRemoveDoc(docToDelete.actionId, docToDelete.docId);
                                    handleRemoveDocument(docToDelete.docId, docToDelete?.name);
                                }}
                                isLoading={deletingDoc}
                                disabled={deletingDoc}
                            />
                            </div>
                            <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"No, cancel"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => {
                                    setDeleteDocModal(false);
                                }}
                                disabled={deletingDoc}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={deleteEventModal}
                showCloseIcon={false}
                closeModal={() => setDeleteEventModal(false)}
                tailwindClassName="w-[36%] max-lg:w-[95%]"
            >
                <div className="bg-white flex flex-col rounded  w-[572px] ">
                    <div
                        onClick={() => setDeleteEventModal(false)}
                        className="cursor-pointer mt-5 px-6 flex justify-end "
                    >
                        <i className="ri-close-fill text-gm-45 text-[30px]"></i>
                    </div>
                    <div className="px-14 my-7 ">
                        <div>
                            <h6 className="text-center font-rocGroteskMedium text-[16px]">
                                Are you sure you want to delete this action{" "}
                                <span className="font-rocGroteskBold">{selectedActionName}</span>
                            </h6>
                        </div>
                        <div className="mt-5 space-y-4">
                        <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"Yes, delete action"}
                                type={"button"}
                                btnClassname="!py-3 !bg-[#FFEFEC] border border-r-50 !text-dark-gray-2 !px-4"
                                onClick={() => {
                                    // setCurrentWorkflowData((prev) => [...prev, ...workflowData]);
                                    // dispatch(workFlowActions.deleteEvent(selectedNodeId));
                                    handleDeleteAction(null, selectedNodeId);
                                }}
                                isLoading={deletingAction}
                            />
                            </div>

                            <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"No, close"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => {
                                    setDeleteEventModal(false);
                                }}
                                disabled={deletingAction}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={attachDocModal}
                showCloseIcon={false}
                closeModal={() => {
                    setDocData(null);
                    setAttachDocModal(false);
                }}
                tailwindClassName="w-[40%] max-lg:w-[95%]"
            >
                <div className="bg-white flex flex-col rounded ">
                    <form className="bg-white shadow-cardShadow relative rounded py-8 ">
                        <div
                            onClick={() => {
                                setDocData(null);
                                setAttachDocModal(false);
                            }}
                            className="cursor-pointer  px-6 flex justify-end "
                        >
                            <i
                                onClick={() => {
                                    setDocData(null);
                                    setAttachDocModal(false);
                                }}
                                className="ri-close-fill text-gm-45 text-[30px]"
                            ></i>
                        </div>
                        <p className="text-xl font-rocGroteskMedium text-center mb-6">
                            Upload File
                        </p>
                        <div className="relative px-10 ">
                            <div className="flex flex-col items-center cursor-pointer justify-center mb-4 py-12 px-6 text-center text-sm rounded border border-dashed border-slate-200">
                                <i className="ri-upload-cloud-2-line text-slate-500 text-4xl mb-4"></i>
                                <p className="mb-1 font-rocGroteskMedium">
                                    <span>Click to upload</span>{" "}
                                    <span className="text-tradeally-neutral-200">
                                        or drag & drop
                                    </span>
                                </p>
                                <p className="text-tradeally-neutral-200  font-rocGroteskMedium">
                                    Supported formats: PDF, CSV, DOCX & TXT. File size limit: 25MB
                                </p>
                            </div>
                            <input
                                type="file"
                                accept=".pdf,.doc,.docx,.txt"
                                className="absolute top-0 w-full h-full outline-none opacity-0 cursor-pointer"
                                id="uploadDoc"
                                onChange={(evt) => {
                                    if (!isFileSizeLessThanOrEqualTo(evt.target.files?.[0], 25)) {
                                        toast.custom((t) => (
                                            <CustomToast
                                                t={t}
                                                message={"File to large"}
                                                type={"error"}
                                            />
                                        ));
                                        return;
                                    }

                                    if (evt.target.files?.[0]) {
                                        setDocData(evt.target.files?.[0]);
                                    }
                                }}
                            />
                        </div>
                        {docData && (
                            <div className="mt-4 p-4 rounded border border-slate-200 mx-10 flex items-center gap-4">
                                {displayFileIcon(docData?.type?.split("/")?.slice(-1)?.[0], null)}
                                <div className="w-full flex justify-between items-start">
                                    <div>
                                        <p className="text-sm font-rocGroteskMedium ">
                                            {truncate(docData?.name, { length: 34 })}
                                        </p>
                                        <p className="text-xs font-rocGroteskMedium text-tradeally-neutral-200">
                                            {convertBytes(docData?.size)}
                                        </p>
                                    </div>
                                    <i
                                        onClick={() => {
                                            const loading = uploading || updatingEventsAndActions;
                                            if (!loading) {
                                                setDocData(null);
                                            }
                                        }}
                                        disabled={uploading || updatingEventsAndActions}
                                        className="ri-delete-bin-line text-slate-500 cursor-pointer"
                                    ></i>
                                </div>
                            </div>
                        )}
                        <div className="px-10 mt-6">
                        <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={`Attach document`}
                                type={"button"}
                                btnClassname={" mb-3"}
                                onClick={() => handleUpload(docData?.name, docData)}
                                disabled={!Boolean(docData)}
                                isLoading={uploading || updatingEventsAndActions}
                            />
                            </div>
                            <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                onClick={() => {
                                    setDocData(null);
                                    setAttachDocModal(false);
                                }}
                            />
                            </div>
                        </div>
                    </form>
                </div>
            </ModalContainer>

            <ModalContainer
                open={inviteMember}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[553px]"
                closeModal={() => {
                    setInviteMember(false);
                    setTeamInfo([{ "email-0": "", "role-0": "" }]);
                }}
            >
                <div className="bg-white px-2 pt-3 pb-10 shadow-cardShadow relative rounded-[20px]">
                    <div
                        onClick={() => {
                            setInviteMember(false);
                            setTeamInfo([{ "email-0": "", "role-0": "" }]);
                        }}
                        className="cursor-pointer px-4 flex justify-end "
                    >
                        <i className="ri-close-fill text-gm-45 text-[24px]"></i>
                    </div>
                    <div className="w-full flex justify-center">
                        <div className="lg:w-[500px] relative w-[380px]">
                            <div className="w-full  pb-3   flex text-center items-center justify-center">
                                <div className="flex text-center flex-col items-center justify-center">
                                    <p className="text-xl mb-1.5 font-rocGroteskMedium text-g-75">
                                        Invite a team member
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-gm-40">
                                        Add team members to your workspace
                                    </p>
                                    <div
                                        className="cursor-pointer  w-fit my-2"
                                        onClick={() => setShowRolesAndResponsibiltyModal(true)}
                                    >
                                        <p className="text-b-55  text-xs   font-rocGroteskMedium  underline-offset-1 underline">
                                            View roles and responsibilities
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit} className="w-full  ">
                                <div
                                    className={`flex  flex-col max-h-[225px]  ${
                                        teamInfo?.length > 3 && " overflow-y-scroll "
                                    }  gap-4 w-full`}
                                    ref={containerRef}
                                >
                                    {teamInfo.map((inputData, index) => (
                                        <div
                                            key={index}
                                            className="flex   w-full items-center group"
                                        >
                                            <div className="w-[80%]">
                                                <TextInput
                                                    value={inputData[`email-${index}`]}
                                                    name={`email-${index}`}
                                                    type="email"
                                                    onChange={(e) => handleChange(e, index)}
                                                    placeholder="Enter email address"
                                                    required={true}
                                                    inputContainerClassname="!rounded-tr-none rounded-br-none"
                                                />
                                            </div>
                                            <div className="  right-0 z-[]   bg-white">
                                                <SelectInput
                                                    value={inputData[`role-${index}`] || ""}
                                                    name={`role-${index}`}
                                                    placeholder="Assign role"
                                                    handleChange={(name, value) =>
                                                        handleChange(
                                                            {
                                                                target: { name, value },
                                                            },
                                                            index
                                                        )
                                                    }
                                                    handleInputChange={debounce((evt) => {
                                                        setDebouncedSearch({
                                                            search: evt.target.value,
                                                        });
                                                    }, 800)}
                                                    searchLoading={fetchingRoles}
                                                    className={`!rounded-tl-none !bg-[#FCFCFC] rounded-bl-none`}
                                                    isRequired={true}
                                                    disabled={inputData[`email-${index}`] === ""}
                                                    dropdownClassName="!py-0 "
                                                    customDropdownContainer={
                                                        <div className="w-full  py-1.5 flex flex-col gap-3.5">
                                                            <div
                                                                onClick={() =>
                                                                    setAddCustomRole(true)
                                                                }
                                                                className="flex items-center gap-1 px-3 font-rocGroteskMedium cursor-pointer w-fit"
                                                            >
                                                                <i className="ri-add-fill before:content-['\ea12'] text-p-55 text-xl mt-[-2px]"></i>
                                                                <span className="text-sm text-p-50">
                                                                    Add custom role
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                    dropdownOptions={roles?.map((item) => ({
                                                        label: item?.label,
                                                        value: item?.role,
                                                    }))}
                                                />
                                            </div>
                                            {index !== 0 && (
                                                <div className="pl-2 group-hover:block hidden">
                                                    <i
                                                        className="ri-delete-bin-line text-[20px] text-slate-400 cursor-pointer"
                                                        onClick={() => onDelete(index)}
                                                    ></i>
                                                </div>
                                            )}
                                            <ModalContainer
                                                open={addCustomRole}
                                                showCloseIcon={false}
                                                modalClassName={"py-10 px-10"}
                                                tailwindClassName="w-[513px] xl:w-[600px]"
                                                closeModal={() => {
                                                    setAddCustomRole(false);
                                                }}
                                            >
                                                <div className="bg-white rounded-lg shadow-modalShadow relative pt-5 pb-10">
                                                    <div className="flex justify-end px-10">
                                                        <span
                                                            onClick={() => setAddCustomRole(false)}
                                                            className="material-icons text-gm-50 text-xl cursor-pointer"
                                                        >
                                                            close
                                                        </span>
                                                    </div>
                                                    <div className="px-10 pt-3">
                                                        <div className="flex flex-col items-center text-center mb-[45px]">
                                                            <p className="text-2xl mb-2.5 font-rocGroteskMedium text-g-75">
                                                                Create a custom role
                                                            </p>
                                                            <p className="text-sm font-rocGroteskMedium text-gm-40">
                                                                Define what the new role’s
                                                                responsibilities are
                                                            </p>
                                                        </div>
                                                        <form className="w-full">
                                                            <TextInput
                                                                name={`role`}
                                                                type={"text"}
                                                                placeholder={"Enter role name"}
                                                                value={role}
                                                                onChange={(e) =>
                                                                    setRole(e.target.value)
                                                                }
                                                                required={true}
                                                                containerClassname={"mb-6 "}
                                                            />
                                                            <div>
                                                                <p className="text-base font-rocGroteskMedium mb-6">
                                                                    Select access levels
                                                                </p>
                                                                <div className="flex flex-wrap gap-4 relative overflow-auto">
                                                                    {accessTypes.map(
                                                                        (access, idx) => (
                                                                            <div
                                                                                key={idx}
                                                                                onClick={() =>
                                                                                    handlePermissionChange(
                                                                                        access.value
                                                                                    )
                                                                                }
                                                                                className={`px-2.5 w-fit h-[30px] ${
                                                                                    selectedPermissions.includes(
                                                                                        access.value
                                                                                    )
                                                                                        ? "!bg-carribean-green !text-white !border-carribean-green"
                                                                                        : ""
                                                                                } border flex items-center gap-2 rounded-[8px] font-rocGroteskMedium bg-white hover:!bg-g-65 hover:text-white hover:!border-g-65 border-slate-300 text-[13px] cursor-pointer`}
                                                                            >
                                                                                {selectedPermissions.includes(
                                                                                    access.value
                                                                                ) ? (
                                                                                    <i className="ri-checkbox-circle-fill text-base"></i>
                                                                                ) : (
                                                                                    <i className="ri-add-fill text-base"></i>
                                                                                )}
                                                                                {access.label}
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="w-full whitespace-nowrap">
                                                            <Button
                                                                onClick={handleAddRole}
                                                                btnText={"Add role"}
                                                                isLoading={createRoles}
                                                                btnClassname={"my-10"}
                                                            />
                                                            </div>
                                                            <p
                                                                onClick={() =>
                                                                    setAddCustomRole(false)
                                                                }
                                                                className="text-sm font-rocGroteskMedium text-center cursor-pointer"
                                                            >
                                                                Close
                                                            </p>
                                                        </form>
                                                    </div>
                                                </div>
                                            </ModalContainer>
                                        </div>
                                    ))}
                                </div>
                                <div
                                    className="cursor-pointer  flex space-x-1 items-center w-fit my-3"
                                    onClick={addNewInputField}
                                >
                                    <i className="ri-add-fill text-b-55  text-[24px]"></i>
                                    <p className="text-b-55  text-xs font-rocGroteskMedium  underline-offset-1 underline">
                                        Invite more team members
                                    </p>
                                </div>
                                <div className="w-full mt-10">
                                <div className="w-full whitespace-nowrap">
                                    <Button
                                        btnText={"Invite member"}
                                        isLoading={addingTeamMember}
                                        type={"submit"}
                                    />
                                    </div>

                                    <div className="w-full whitespace-nowrap">
                                    <Button
                                        btnText={"Close"}
                                        onClick={() => {
                                            setInviteMember(false);
                                            setTeamInfo([{ "email-0": "", "role-0": "" }]);
                                        }}
                                        disabled={addingTeamMember}
                                        btnClassname={"!bg-transparent !text-gm-75 mt-2"}
                                    />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={showRolesAndResponsibiltyModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                closeModal={() => {
                    setShowRolesAndResponsibiltyModal(false);
                }}
                tailwindClassName="w-[768px]"
            >
                <ShowRolesAndResponsibiltyModal />
            </ModalContainer>
        </>
    );
};

export default WorkflowBoard;
