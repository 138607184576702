import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const vendorService = {
    getLdbVendors,
    getSingleVendor,
    authenticateGmail,
    connectGoogleWorkspace,
    getAllChannels,
    getVendorsByOrderType,
    getOnboardedVendors,
    getSuggestedVendors,
    authenticateMicrosoftAcct,
    connectMicrosoftWorkspace,
    getVendorPo,
    manageConnection,
    getVendorActivities,
    authenticateGmailSettings,
    authenticateMicrosoftAcctSettings,
    connectMicrosoftWorkspaceSettings,
    connectGoogleWorkspaceSettings,
    getVendorByCategory,
    getVendorCategories,
    addVendorCategory,
    getVendorDocs,
    approveVendorDoc,
    requestVendorDoc,
    addDocComment,
    getDocComments,
    approveSupplier,
    reviewSupplierResponses
};

async function getLdbVendors(vendorLimit?: string | number, search?: string, status?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor?limit=${vendorLimit}${
            Boolean(search) ? "&search=" + search : ""
        }${Boolean(status) ? "&status=" + status : ""}`,
        requestOptions
    );
    return res;
}

async function getOnboardedVendors() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/onboarded`,
        requestOptions
    );
    return res;
}

async function getSuggestedVendors() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/suggestions`,
        requestOptions
    );
    return res;
}

async function getSingleVendor(vendorId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/single?vendorId=${vendorId}`,
        requestOptions
    );
    return res;
}

async function authenticateGmail() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/google/authenticate`,
        requestOptions
    );
    return res;
}
async function authenticateGmailSettings() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/google/authenticate/settings`,
        requestOptions
    );
    return res;
}

async function connectGoogleWorkspace(reqObj: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/google/connect`,
        requestOptions
    );

    return res;
}
async function connectGoogleWorkspaceSettings(
    reqObj: Record<string, any> | Array<Record<string, any>>
) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/google/connect/settings`,
        requestOptions
    );

    return res;
}
async function authenticateMicrosoftAcct() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/microsoft/authenticate`,
        requestOptions
    );
    return res;
}

async function authenticateMicrosoftAcctSettings() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/microsoft/authenticate/settings`,
        requestOptions
    );
    return res;
}
async function manageConnection(reqObj: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/manage/connection`,
        requestOptions
    );
    return res;
}
async function connectMicrosoftWorkspace(reqObj: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/microsoft/connect`,
        requestOptions
    );

    return res;
}
async function connectMicrosoftWorkspaceSettings(
    reqObj: Record<string, any> | Array<Record<string, any>>
) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/microsoft/connect/settings`,
        requestOptions
    );

    return res;
}

async function getAllChannels() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/channels`,
        requestOptions
    );

    return res;
}

async function getVendorsByOrderType(orderType?: string, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/suggested?orderType=${orderType}${
            search ? "&search=" + search : ""
        }`,
        requestOptions
    );

    return res;
}

async function getVendorPo(vendorId: string, limit?: string | number) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/po/list/${vendorId}?limit=${limit}`,
        requestOptions
    );

    return res;
}

async function getVendorActivities(vendorId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendorActivity/?id=${vendorId}`,
        requestOptions
    );

    return res;
}

async function reviewSupplierResponses(vendorId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/review/responses/vendor/${vendorId}`,
        requestOptions
    );

    return res;
}

async function getVendorByCategory(category: string, startDate: string, endDate: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        category,
        startDate,
        endDate,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/category?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );

    return res;
}

async function getVendorCategories(limit?: number) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/invite-categories?limit=${limit}`,
        requestOptions
    );

    return res;
}

async function addVendorCategory(reqObj: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/invite-categories`,
        requestOptions
    );

    return res;
}

async function getVendorDocs(vendorId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/vendor-documents/${vendorId}`,
        requestOptions
    );

    return res;
}

async function approveVendorDoc(reqObj: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/validate/vendor-documents`,
        requestOptions
    );

    return res;
}

async function requestVendorDoc(reqObj: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/request/document/`,
        requestOptions
    );

    return res;
}

async function addDocComment(reqObj: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/add/document/comment`,
        requestOptions
    );

    return res;
}

async function getDocComments(vendorId: string, limit?: string | number) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/document/comments/${vendorId}?limit=${limit}`,
        requestOptions
    );

    return res;
}

async function approveSupplier(vendorId: string) {
    const requestOptions = {
        method: "PUT",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/approve/supplier/${vendorId}`,
        requestOptions
    );

    return res;
}
