import React, { useState, useEffect } from "react";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import { displayFileIcon } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions, vendorActions } from "redux/Ldb/actions";
import Loader from "components/common/Loader";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import DateInput from "components/common/DateInput";
import { debounce } from "lodash";


const OfflinePurchase = ({
    stockData,
    handleStockData,
    attachment,
    uploadingAttachment,
    handleUpload,
    deleteAttachment,
    productViabilityDetails,
    handleDateChange,
}: any): JSX.Element => {
    const dispatch = useAppDispatch();
    const [showExpirationDate, setShowExpirationDate] = useState<boolean>(false);
    const [showBBDate, setShowBBDate] = useState<boolean>(false);
    const [showRemovalTimeDate, setShowRemovalTimeDate] = useState<boolean>(false);
    const [showManufactureDate, setShowManufactureDate] = useState<boolean>(false);
    const [debounceProductSearch, setDebounceProductSearch] = useState<string>();
    const [debounceWarehouseSearch, setDebounceWarehouseSearch] = useState<string>();

    const [inventory, setInventory] = useState<any>();
    const [warehouses, setWarehouses] = useState<any>();
    const [suppliers, setSuppliers] = useState<any>();

    const { fetchedOnboardedVendorsSuccess, fetchingOnboardedVendors } = useAppSelector(
        (state) => state?.vendor
    );
    const {
        fetchingWarehouses,
        fetchedWarehousesSuccess,
        fetchingInventoryList,
        fetchedInventoryListSuccess,
    } = useAppSelector((state) => state?.inventory);

    useEffect(() => {
        if (Boolean(fetchedInventoryListSuccess)) {
            setInventory(fetchedInventoryListSuccess?.inventory?.data);
        }
    }, [fetchedInventoryListSuccess]);

    useEffect(() => {
        if (Boolean(fetchedWarehousesSuccess)) {
            setWarehouses(fetchedWarehousesSuccess?.warehouses.data);
        }
    }, [fetchedWarehousesSuccess]);

    useEffect(() => {
        dispatch(inventoryActions.fetchInventoryList(20, debounceProductSearch));
    }, [dispatch, debounceProductSearch]);
    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouses(20, debounceWarehouseSearch));
    }, [dispatch, debounceWarehouseSearch]);
    useEffect(() => {
        if (Boolean(fetchedOnboardedVendorsSuccess)) {
            setSuppliers(fetchedOnboardedVendorsSuccess?.vendors);
        }
    }, [fetchedOnboardedVendorsSuccess]);

    useEffect(() => {
        dispatch(vendorActions.getOnboardedVendors());
    }, [dispatch]);

    return (
        <div className="w-[65%]">
            <div>
                <div className="">
                    <p className="pb-4 font-rocGroteskMedium text-gun-metal text-base">
                        Stock details
                    </p>
                    <div className="flex flex-col gap-4">
                        <div className="grid grid-cols-2 items-center gap-4 ">
                            <SelectInput
                                value={stockData?.inventoryId}
                                name="inventoryId"
                                placeholder={"Select product"}
                                handleChange={(name, value) => {
                                    handleStockData({ target: { name, value } });
                                }}
                                isRequired={true}
                                className={`${
                                    stockData?.inventoryId &&
                                    "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                }`}
                                handleInputChange={debounce((evt) => {
                                    setDebounceProductSearch(evt.target.value);
                                }, 800)}
                                searchLoading={fetchingInventoryList}
                                dropdownOptions={inventory?.map((item) => ({
                                    label: <div className="flex gap-2">{item?.productName}</div>,
                                    value: item?._id,
                                }))}
                            />

                            <SelectInput
                                value={stockData?.vendorId}
                                name="vendorId"
                                placeholder={"Select Vendor/Supplier"}
                                handleChange={(name, value) => {
                                    handleStockData({ target: { name, value } });
                                }}
                                searchLoading={fetchingOnboardedVendors}
                                isRequired={true}
                                className={`${
                                    stockData?.vendorId &&
                                    "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                }`}
                                dropdownOptions={suppliers?.map((item) => ({
                                    label: item?.vendor?.profile?.businessName,
                                    value: item?.vendorId,
                                }))}
                            />
                            <div className="flex gap-1">
                                <div className="w-[160px]">
                                    <SelectInput
                                        value={stockData?.cost?.currency || ""}
                                        name="currency"
                                        placeholder="Currency"
                                        handleChange={(name, value) => {
                                            handleStockData({ target: { name, value } });
                                        }}
                                        className={`${
                                            stockData?.cost?.currency &&
                                            "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                        }`}
                                        isRequired={true}
                                        clearValue
                                        dropdownOptions={[
                                            {
                                                label: "GBP (£)",
                                                value: "GBP",
                                            },
                                            {
                                                label: "USD ($)",
                                                value: "USD",
                                            },
                                            {
                                                label: "EUR (€)",
                                                value: "EUR",
                                            },
                                            {
                                                label: "NGN (₦)",
                                                value: "NGN",
                                            },
                                        ]}
                                    />
                                </div>

                                <TextInput
                                    value={stockData?.cost?.amount || ""}
                                    name={"amount"}
                                    type={"number"}
                                    onChange={handleStockData}
                                    placeholder={"Unit cost"}
                                    inputClassName={`${
                                        stockData?.cost?.amount &&
                                        "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    }`}
                                    required
                                />
                            </div>

                            <div className="">
                                <TextInput
                                    value={stockData?.productStockDetails?.quantityAdded || ""}
                                    name={"quantityAdded"}
                                    type={"number"}
                                    onChange={handleStockData}
                                    placeholder={"Quantity"}
                                    inputClassName={`${
                                        stockData?.productStockDetails?.quantityAdded &&
                                        "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    }`}
                                    required
                                />
                            </div>
                        </div>
                        <SelectInput
                            value={stockData?.warehouseId}
                            name="warehouseId"
                            placeholder={"Warehouse"}
                            handleChange={(name, value) => {
                                handleStockData({ target: { name, value } });
                            }}
                            className={`${
                                stockData?.warehouseId &&
                                "!border-g-60 !border-[0.5px] !border-b-[1px]"
                            }`}
                            searchLoading={fetchingWarehouses}
                            isRequired={true}
                            handleInputChange={debounce((evt) => {
                                setDebounceWarehouseSearch(evt.target.value);
                            }, 800)}
                            dropdownOptions={
                                warehouses
                                    ? warehouses?.map((item) => ({
                                          label: item?.warehouseName,
                                          value: item?._id,
                                      }))
                                    : []
                            }
                        />
                    </div>
                </div>
                <div className="">
                    <div className="flex flex-col gap-4 mt-8">
                        <div>
                            <label className="text-base font-rocGroteskMedium">
                                Product lifecycle
                            </label>
                        </div>
                        <div className="grid grid-cols-2 items-center gap-4">
                            <DateInput
                                label="Manufacturing Date"
                                value={productViabilityDetails?.manufacturingDate}
                                onChange={(newDate) =>
                                    handleDateChange("manufacturingDate", newDate)
                                }
                                showCalendar={showManufactureDate}
                                onCalendarToggle={setShowManufactureDate}
                            />
                            <DateInput
                                label="Best before time"
                                value={productViabilityDetails?.bestBefore}
                                onChange={(newDate) => handleDateChange("bestBefore", newDate)}
                                showCalendar={showBBDate}
                                onCalendarToggle={setShowBBDate}
                            />
                        </div>
                        <div className="grid grid-cols-2 items-center gap-4 ">
                            <DateInput
                                label="Expiration time"
                                value={productViabilityDetails?.expiryDate}
                                onChange={(newDate) => handleDateChange("expiryDate", newDate)}
                                showCalendar={showExpirationDate}
                                onCalendarToggle={setShowExpirationDate}
                            />
                            <DateInput
                                label="Removal time"
                                value={productViabilityDetails?.removalTime}
                                onChange={(newDate) => handleDateChange("removalTime", newDate)}
                                showCalendar={showRemovalTimeDate}
                                onCalendarToggle={setShowRemovalTimeDate}
                            />
                        </div>


                        <div className="mt-4">
                            <label className="text-base font-rocGroteskMedium">Attachments</label>
                            <input
                                type="file"
                                className="hidden"
                                id="attachment"
                                accept="image/*, .pdf, .xlsx"
                                disabled={uploadingAttachment}
                                onChange={(e) => {
                                    if (e.target.files?.[0]) {
                                        handleUpload(
                                            e.target.files?.[0]?.name as string,
                                            e.target.files?.[0]?.size as number
                                        );
                                    }
                                }}
                            />
                            <div className="mt-4 ">
                                {attachment?.map((item, idx) => {
                                    return (
                                        <div className="flex items-center justify-between p-3 rounded-md border border-slate-200 mb-4">
                                            <div className="flex items-center gap-2">
                                                {displayFileIcon(
                                                    getFileTypeFromUrl(item?.path),
                                                    false
                                                )}
                                                <div className="font-rocGroteskMedium">
                                                    <p className="text-sm">{item?.filename}</p>
                                                    <p className="text-xs text-slate-500">
                                                        {/* {item?.size} */}
                                                    </p>
                                                </div>
                                            </div>
                                            <i
                                                className="ri-delete-bin-line text-xl text-r-50 cursor-pointer"
                                                onClick={() => deleteAttachment(idx)}
                                            ></i>
                                        </div>
                                    );
                                })}

                                <label
                                    className={`flex items-center gap-4 py-3 px-6 rounded border border-dashed border-slate-200  ${
                                        !uploadingAttachment && "cursor-pointer"
                                    }`}
                                    htmlFor="attachment"
                                >
                                    <i className="ri-file-add-line text-2xl text-carribean-green"></i>
                                    {uploadingAttachment ? (
                                        <div className="">
                                            <Loader size={4} />
                                        </div>
                                    ) : (
                                        <p className="text-sm underline text-carribean-green font-rocGroteskMedium">
                                            Click to upload document
                                        </p>
                                    )}
                                </label>
                            </div>
                        </div>

                        <div className="mt-4">
                            <label className="text-base font-rocGroteskMedium">Notes</label>
                            <div className="mt-4">
                                <TextareaInput
                                    name={"notes"}
                                    value={stockData?.notes || ""}
                                    onChange={handleStockData}
                                    rows={5}
                                    placeholder={"Add note"}
                                    required={false}
                                    inputContainerClassname={`${
                                        stockData?.notes &&
                                        "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    }`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OfflinePurchase;
