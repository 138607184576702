import ModalContainer from "components/common/ModalContainer";
import React from "react";

interface AuthFrameProps {
    children: React.ReactNode;
}

const AuthFrame = ({children}: AuthFrameProps) => {
    const [playVideo, setPlayVideo] = React.useState(false);
    return (
        <>
            <div className="w-full h-screen  flex">
                <div className="w-[43%] max-lg:hidden h-full bg-cover bg-no-repeat bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1694560472/Side_Container_Wrapper_vsyuhb.png')] ">
                    <div className="px-12 pt-[5.5rem]">
                        <p className="text-[40px]  text-gm-50 font-rocGroteskMedium  mb-[27px]">
                            Automate your <br /> supply-chain operations with SynthAlly’s AI
                            Co-Pilot
                        </p>
                        <div
                            onClick={() => setPlayVideo(true)}
                            className="py-3 cursor-pointer rounded-[50px] px-3 flex items-center gap-3  bg-[#FAFBFC26] w-[62%]"
                        >
                            <i className="ri-play-line cursor-pointer text-p-55 flex justify-center text-[24px] items-center bg-white rounded-full h-[24px] w-[24px]"></i>
                            <span className="text-sm  text-gm-45 font-rocGroteskMedium">
                                See how SynthAlly works
                            </span>
                        </div>
                    </div>
                </div>
                <div className="w-[57%] max-lg:w-full bg-white  flex items-center justify-center">
                    {children}
                </div>
            </div>

            {playVideo && (
                <ModalContainer open={playVideo} closeModal={() => setPlayVideo(false)}>
                    <div className=" h-[786px] max-lg:w-full max-lg:px-2 max-sm:h-[265px] max-lg:h-[500px] rounded-xl flex justify-center items-center ">
                        <video
                            poster="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1692968730/Tradeally_2.0/Landing%20page/demo_video_preview_psvkng.svg"
                            src="https://res.cloudinary.com/dvxi7qcmd/video/upload/q_auto,f_auto/v1694536885/Tradeally_2.0/Landing%20page/YC_Demo_No_sound_f7zhtk.mp4"
                            playsInline
                            controls={true}
                            autoPlay
                            controlsList="nodownload"
                            muted
                            className="z-10 cursor-pointer w-[1000px] h-[598px] max-lg:w-full max-lg:h-full"
                        />
                    </div>
                </ModalContainer>
            )}
        </>
    );
};

export default AuthFrame;
