/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import Loader from "../Loader";
// import Rating from "../Rating";
import ModalContainer from "../ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions, vendorActions } from "redux/Ldb/actions";
import AddVendorForm from "../AddVendorForm";
import { debounce, truncate } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";

type VendorSelectInputProps = {
    label?: string;
    handleChange?: (
        name: string,
        value: string,
        selectedValues: string[],
        selectedItems: string[]
    ) => void;
    name?: string;
    value?: string;
    disabled?: boolean;
    placeholder?: string;
    dropdownOptionsProp?: {
        label: string;
        value: string;
        icon?: JSX.Element;
        required?: boolean;
    }[];
    isRequired?: boolean;
    className?: string;
    orderType?: string;
    id?: string;
    labelClassName?: string;
    dropdownClassName?: string;
    optionItemClassName?: string;
    optionItemContainerClassName?: string;
    activeOptionItemContainerClassName?: string;
    activeOptionItemClassName?: string;
    switchOptions?: boolean;
    searchLoading?: boolean;
    handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const VendorSelectInput = ({
    label,
    handleChange,
    name,
    value,
    disabled,
    placeholder,
    dropdownOptionsProp,
    isRequired,
    className,
    orderType,
    id,
    labelClassName,
    dropdownClassName,
    optionItemClassName,
    optionItemContainerClassName,
    activeOptionItemContainerClassName,
    activeOptionItemClassName,
    switchOptions,
    searchLoading,
    handleInputChange,
}: VendorSelectInputProps) => {
    const [addVendorOpen, setAddVendorOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [vendorCategory, setVendorCategory] = useState("");
    const [requiredDocuments, setRequiredDocuments] = useState([]);

    const [open, setOpen] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState<string | JSX.Element | null>(null);
    const [selectedItems, setSelectedItems] = useState<string[] | string | undefined>([]);
    const [selectedValues, setSelecteValues] = useState<string[] | string | undefined>([]);
    const [requiredItems, setRequiredItems] = useState([]);
    const [debouncedFilter, setDebouncedFilter] = useState("");
    const [dropdownOptions, setDropdownOptions] = useState<
        | {
              label: string;
              value: string;
              required?: boolean | undefined;
              icon?: JSX.Element;
          }[]
        | undefined
    >([]);
    const [filter, setfilter] = useState<string>("");
    const customDropdown = document.getElementById("custom-select");
    const selectedOption:
        | {
              label: string;
              value: string;
              required?: boolean | undefined;
              icon?: JSX.Element;
          }
        | undefined = (dropdownOptionsProp || dropdownOptions)?.find(
        (item) => item.value === value && item?.label?.includes(filter)
    );

    const { addingVendor, addVendorSuccess } = useAppSelector((state) => state.auth);
    const {
        fetchingVendorsByOrderType,
        fetchedVendorsByOrderTypeSuccess,
        fetchedLdbVendorsSuccess,
    } = useAppSelector((state) => state.vendor);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement>(null);
    const inputRef = useRef(null);
    const toggleRef = useRef(null);

    const filteredOptions = isTyping
        ? (dropdownOptionsProp || dropdownOptions)?.filter((item) => {
              return (
                  item?.label?.toLowerCase().includes(filter.toLowerCase()) ||
                  item?.value?.toLowerCase().includes(filter.toLowerCase())
              );
          })
        : dropdownOptionsProp || dropdownOptions;

    const handleAddVendor = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body = {
            vendors: [
                {
                    email,
                    documents: requiredDocuments?.map((docName) => ({ name: docName })),
                    category: vendorCategory,
                },
            ],
        };

        dispatch(authActions.addVendor(body));
    };

    const handleDebouncedChange = debounce((e) => {
        setDebouncedFilter(e.target.value);
    }, 1000);

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsTyping(true);
        setOpen(true);
        handleChange?.(name as string, "", [], []);
        setSelectedIcon(null);
        setfilter(event.target.value);
    };

    const handleSelect = (label: string | React.ReactNode, value: string, icon: JSX.Element) => {
        const selected = (dropdownOptionsProp || dropdownOptions)?.find(
            (item) => item?.value === value && item?.label === label
        );
        const selectedItemsCopy = [...(selectedItems as string[])];
        const selectedValuesCopy = [...(selectedValues as string[])];

        const selectedItemIndex = selectedValuesCopy.findIndex(
            (item) => item?.toLowerCase() === selected?.value?.toLowerCase()
        );

        if (selectedItemIndex !== -1) {
            selectedItemsCopy.splice(selectedItemIndex, 1);
            selectedValuesCopy.splice(selectedItemIndex, 1);
        } else {
            selectedItemsCopy.push(label as string);
            selectedValuesCopy.push(value);
        }
        setSelectedItems(selectedItemsCopy);
        setSelecteValues(selectedValuesCopy);
        handleChange?.(name as string, value, selectedValuesCopy, selectedItemsCopy);
        setSelectedIcon(icon);
        setfilter("");
        setIsTyping(false);
    };

    useEffect(() => {
        if (Boolean(addVendorSuccess)) {
            // setVendors((prev) => [addVendorSuccess?.vendor, ...prev]);
            setEmail("");
            setVendorCategory("");
            setRequiredDocuments([]);
            setAddVendorOpen(false);
            dispatch(authActions.resetAddVendorSuccess());
        }
    }, [addVendorSuccess, dispatch]);

    useEffect(() => {
        if (Boolean(orderType)) {
            dispatch(vendorActions.getVendorsByOrderType(orderType, debouncedFilter));
        } else {
            dispatch(vendorActions.getVendorsByOrderType());
        }
    }, [dispatch, orderType, debouncedFilter]);

    useEffect(() => {
        if (Boolean(fetchedVendorsByOrderTypeSuccess)) {
            const options = fetchedVendorsByOrderTypeSuccess?.result?.map((vendor: any) => ({
                label: vendor?.profile?.businessName,
                value: vendor?._id,
                icon: <img src={vendor?.profile?.avatar} width={24} height={24} alt="logo" />,
            }));

            setDropdownOptions(options);
        }
    }, [fetchedVendorsByOrderTypeSuccess, navigate, dispatch]);


    useEffect(() => {
        setfilter(selectedOption?.label ?? "");
        if (selectedOption?.icon) {
            setSelectedIcon(selectedOption?.icon);
        }
    }, [value]);

    useEffect(() => {
        if (dropdownOptionsProp?.length && Boolean(value)) {
            const optValue = value?.split(", ");
            const optLabel = dropdownOptionsProp
                ?.filter((opt) => optValue?.includes(opt?.value))
                ?.map((opt) => opt?.label);

            setSelectedItems(optLabel);
            setSelecteValues(optValue);
        }
    }, [dropdownOptionsProp, value]);

    useEffect(() => {
        if (dropdownOptions && Boolean(value)) {
            const optValue = value?.split(", ");
            const optLabel = dropdownOptions
                ?.filter((opt) => optValue?.includes(opt?.value))
                ?.map((opt) => opt?.label);

            setSelectedItems(optLabel);
            setSelecteValues(optValue);
        }
    }, [dropdownOptions, value]);

    useEffect(() => {
        setfilter(selectedOption?.label ?? "");
    }, [selectedOption]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && event.target !== toggleRef.current) {
                if (!ref.current.contains(event.target as Node)) {
                    setOpen(false);
                }
            }

            if (!event.target) setOpen(false);
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, open]);

    return (
        <div className="w-full">
            <div className="w-full">
                {Boolean(label) && (
                    <p className={`text-sm mb-1 font-rocGroteskMedium ${labelClassName}`}>
                        {label}
                    </p>
                )}
                <div className="relative">
                    <div
                        onClick={() => setOpen(!open)}
                        className={`flex justify-between cursor-pointer rounded items-center border-[0.5px] ${
                            isFocused
                                ? "!border-carribean-green border-[0.5px] !border-b-[2px]"
                                : "border-gm-25"
                        } ${disabled && "!bg-slate-50"} ${
                            selectedIcon && filter && "pl-4 max-475:pl-3"
                        } pr-2 475:pr-4 outline-0 w-full min-h-[50px]   text-sm font-rocGrotesk font-medium ${className}`}
                    >
                        <div className="w-full relative">
                            <p
                                className={`text-sm font-rocGroteskRegular pl-3 max-lg:hidden ${
                                    (selectedItems as string[]).join(", ")
                                        ? "text-gm-50"
                                        : "text-slate-400"
                                }`}
                            >
                                {truncate((selectedItems as string[]).join(", "), {
                                    length: 50,
                                }) || (
                                    <span>
                                        {placeholder}
                                        {""}
                                        {isRequired && (
                                            <span className="required text-r-50 text-sm mt-[-2px] font-rocGroteskMedium">
                                                *
                                            </span>
                                        )}
                                    </span>
                                )}
                            </p>
                            <p
                                className={`text-sm font-rocGroteskRegular pl-3 hidden max-lg:block ${
                                    (selectedItems as string[]).join(", ")
                                        ? "text-gm-50"
                                        : "text-slate-400"
                                }`}
                            >
                                {truncate((selectedItems as string[]).join(", "), {
                                    length: 20,
                                }) || (
                                    <span>
                                        {placeholder}
                                        {""}
                                        {isRequired && (
                                            <span className="required text-r-50 text-sm mt-[-2px] font-rocGroteskMedium">
                                                *
                                            </span>
                                        )}
                                    </span>
                                )}
                            </p>
                        </div>
                        <input
                            value={(selectedItems as string[]).join(", ")}
                            required={isRequired}
                            onChange={() => {}}
                            className="absolute outline-none opacity-0"
                        />
                        {searchLoading || fetchingVendorsByOrderType ? (
                            <Loader size={5} color={"gm-50"} />
                        ) : (
                            !disabled && (
                                <span
                                    className={`material-icons text-2xl ${
                                        isFocused ? "text-g-75" : "text-gm-25"
                                    }`}
                                    ref={toggleRef}
                                    onClick={() => setOpen(!open)}
                                >
                                    keyboard_arrow_down
                                </span>
                            )
                        )}
                    </div>
                    {open && !disabled && (
                        <div
                            ref={ref}
                            id="custom-select"
                            className="bg-white absolute top-[101%] w-full h-auto rounded z-[999] shadow-boxShadow-3"
                        >
                            <div>
                                <div>
                                    <input
                                        className={`inputText w-full outline-0 h-full py-1.5 ${
                                            selectedIcon ? "pl-2" : "pl-3"
                                        }  bg-transparent font-rocGroteskRegular outline-none text-gm-50 border-b placeholder:text-sm placeholder:!font-rocGroteskRegular`}
                                        placeholder={"Search for a vendor"}
                                        type={"text"}
                                        value={filter}
                                        id={id}
                                        ref={inputRef}
                                        required={
                                            isRequired
                                                ? !Boolean((selectedItems as string[])?.length > 0)
                                                : isRequired
                                        }
                                        onChange={handleFilterChange}
                                        // onInput={handleInputChange}
                                        onInput={handleDebouncedChange}
                                    />
                                </div>
                                <p className="px-3 py-3 text-xs text-gm-50 font-rocGroteskMedium">
                                    Suggested vendors
                                </p>
                            </div>
                            {!searchLoading && filteredOptions && filteredOptions?.length > 0 ? (
                                <div>
                                    <div
                                        className={`py-1 max-h-[215px] overflow-auto ${dropdownClassName}`}
                                        // id="custom-select"
                                    >
                                        {filteredOptions.map((option, idx) => {
                                            return (
                                                <div
                                                    key={idx}
                                                    onClick={() => {
                                                        if (!option?.required) {
                                                            handleSelect(
                                                                option?.label,
                                                                option?.value,
                                                                option?.icon as JSX.Element
                                                            );
                                                        }
                                                    }}
                                                    className={`flex items-center border-b border-slate-100 px-4 py-3 justify-between hover:bg-slate-100 ${
                                                        selectedOption?.value === option?.value &&
                                                        selectedOption?.label === option?.label &&
                                                        "tradeally-blue " +
                                                            activeOptionItemContainerClassName
                                                    } cursor-pointer ${optionItemContainerClassName}`}
                                                >
                                                    <div className="flex items-center gap-3">
                                                        <div className="max-sm:hidden">
                                                            {option?.icon && option?.icon}
                                                        </div>
                                                        <div className="max-sm:hidden">
                                                            <p
                                                                className={`text-sm font-rocGroteskMedium ${optionItemClassName}`}
                                                            >
                                                                {option?.label}
                                                            </p>
                                                            {/* <Rating ratingNo={4} totalRating={5} /> */}
                                                        </div>
                                                        <div className="hidden max-sm:block">
                                                            <p
                                                                className={`text-sm font-rocGroteskMedium ${optionItemClassName}`}
                                                            >
                                                                {truncate(option?.label, {
                                                                    length: 10,
                                                                })}
                                                            </p>
                                                            {/* <Rating ratingNo={4} totalRating={5} /> */}
                                                        </div>
                                                    </div>
                                                    {selectedValues?.includes(option?.value) ? (
                                                        <i className="ri-checkbox-fill before:content-['\eb82'] text-xl mt-[-2px] text-carribean-green"></i>
                                                    ) : (
                                                        <i className="ri-checkbox-blank-line before:content-['\eb7f'] text-xl mt-[-2px] text-gm-25"></i>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {/* <div
                                        onClick={() => setAddVendorOpen(true)}
                                        className="flex items-center gap-3 px-4 py-3 border-t border-slate-100 cursor-pointer"
                                    >
                                        <div className="w-8 h-8 rounded bg-n-20 flex items-center justify-center">
                                            <i className="ri-add-fill"></i>
                                        </div>
                                        <div>
                                            <p className={`text-sm font-rocGroteskMedium`}>
                                                Add new vendor
                                            </p>
                                        </div>
                                    </div> */}
                                </div>
                            ) : (
                                <div
                                    className={`py-2  w-full h-auto max-h-60 z-[999] overflow-auto ${dropdownClassName}`}
                                    // id="custom-select"
                                    // ref={ref}
                                >
                                    <div className="flex px-4 justify-between hover:tradeally-blue cursor-pointer">
                                        <p className="text-sm flex gap-3 py-3 items-center font-rocGroteskRegular">
                                            <span>
                                                {searchLoading ? "Searching..." : "No Data"}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <ModalContainer
                open={addVendorOpen}
                showCloseIcon={false}
                tailwindClassName="w-[36%] mt-[-10%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setAddVendorOpen(false);
                    setEmail("");
                    setVendorCategory("");
                    setRequiredDocuments([]);
                }}
            >
                <AddVendorForm
                    onSubmit={handleAddVendor}
                    email={email}
                    vendorCategory={vendorCategory}
                    onChangeEmail={setEmail}
                    onChangeCategory={setVendorCategory}
                    onChangeDocs={setRequiredDocuments}
                    isSending={addingVendor}
                />
            </ModalContainer>
        </div>
    );
};

export default VendorSelectInput;
