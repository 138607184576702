import Button from "components/common/Button";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { settingsActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks";

type SubscriptionViewProps = {
    onClick: () => void;
    setShowUpgradePlan: React.Dispatch<React.SetStateAction<boolean>>;
    setSubscriptionPlanSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    subscriptionPlanSuccess: boolean;
    setSubscriptionPlanFailure: React.Dispatch<React.SetStateAction<boolean>>;
    subscriptionPlanFailure: boolean;
};

const SubscriptionView = ({
    onClick,
    setShowUpgradePlan,
    setSubscriptionPlanSuccess,
    subscriptionPlanSuccess,
    setSubscriptionPlanFailure,
    subscriptionPlanFailure,
}: SubscriptionViewProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const planMode = param.get("planMode");
    const [planInterval, setPlanInterval] = useState("monthly");
    const planIntervals = param.get("planInterval");
    const [planId, setPlanId] = useState("");
    const [subscriptionPlans, setSubscriptionPlans] = useState<{ [key: string]: any }>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<{ [key: string]: any }>({});
    const [expandedPlans, setExpandedPlans] = useState(Array(subscriptionPlans.length).fill(true));

    const {
        fetchingSubscriptionPlan,
        fetchSubscriptionPlanSuccess,
        createStripeSession,
        createStripeSessionSuccess,
        cancelSubscription,
        cancelSubscriptionSuccess,
        fetchCurrentSubscriptionSuccess,
        fetchingCurrentSubscription,
        updateStripeSession,
        updateStripeSessionSuccess,
        updateStripeSessionFailure,
    } = useAppSelector((state) => state.settings);

    useEffect(() => {
        if (planInterval) {
            dispatch(settingsActions.getSubscriptionPlan(planInterval));
        }
    }, [dispatch, planInterval]);

    useEffect(() => {
        if (planIntervals === "monthly") {
            setPlanInterval("monthly");
        } else if (planIntervals === "year") {
            setPlanInterval("year");
        }
    }, [planIntervals]);
    useEffect(() => {
        if (!Boolean(fetchCurrentSubscriptionSuccess)) {
            dispatch(settingsActions.fetchCurrentSubscriptionPlan());
        }
    }, [dispatch, fetchCurrentSubscriptionSuccess]);


    useEffect(() => {
        if (Boolean(fetchSubscriptionPlanSuccess)) {
            setSubscriptionPlans(fetchSubscriptionPlanSuccess?.plans);
            
        }
    }, [fetchSubscriptionPlanSuccess]);

    useEffect(() => {
        if (Boolean(fetchCurrentSubscriptionSuccess)) {
            setData(fetchCurrentSubscriptionSuccess?.subscription);

            if (loading && !fetchingCurrentSubscription) {
                setSubscriptionPlanSuccess(true);
            }
        }
    }, [fetchCurrentSubscriptionSuccess, loading, fetchingCurrentSubscription]);

    const onUpgradePlan = (planData: { [key: string]: any }) => {
        setPlanId(planData?._id);
        if (planData) {
            const body = {
                planId: planData?._id,
            };
            if (!data?.trial) {
                if (data?.active) {
                    dispatch(settingsActions.updateStripeSession(body));
                } else {
                    dispatch(settingsActions.createStripeSession(body));
                }
            }
            if (data?.trial) {
                dispatch(settingsActions.createStripeSession(body));
            }
        }
    };

    const onDeletePlan = (planData) => {
        setPlanId(planData?._id);
        dispatch(settingsActions.cancelSubscriptionPlan());
    };

    useEffect(() => {
        if (Boolean(cancelSubscriptionSuccess)) {
            dispatch(settingsActions.resetCancelSubscription());
            dispatch(settingsActions.fetchCurrentSubscriptionPlan());
            setShowUpgradePlan(false);
        }
    }, [cancelSubscriptionSuccess]);

    useEffect(() => {
        if (Boolean(createStripeSessionSuccess)) {
            window.location.href = createStripeSessionSuccess?.subscription?.checkoutUrl;
            dispatch(settingsActions.resetCreateStripeSession());
        }
    }, [createStripeSessionSuccess]);

    useEffect(() => {
        if (Boolean(updateStripeSessionSuccess)) {
            dispatch(settingsActions.fetchCurrentSubscriptionPlan());
            dispatch(settingsActions.resetUpdateStripeSession());
            setShowUpgradePlan(true);
            setLoading(true);

        }
    }, [updateStripeSessionSuccess, dispatch]);

    useEffect(() => {
        if (updateStripeSessionFailure) {
            setSubscriptionPlanFailure(true);
        }
    }, [updateStripeSessionFailure]);

    const toggleSeeMore = (idx) => {
        setExpandedPlans((prev) => ({
            ...prev,
            [idx]: !prev[idx],
        }));
    };
    return (
        <div className="mt-9 ">
            <div className="flex sm:flex-row sm:justify-between flex-col sm:space-y-0 space-y-4">
                <div
                    className="text-base cursor-pointer space-x-1 font-rocGroteskMedium text-gm-50 flex items-center"
                    onClick={onClick}
                >
                    <i className="ri-arrow-left-line"></i>
                    <p className="">Upgrade your plan</p>
                </div>

                <div className="w-[288px] flex justify-between  px-1 rounded-full bg-n-20 font-rocGroteskMedium">
                    <div
                        className={`my-[0.3px] ${
                            planInterval === "monthly" &&
                            "transition ease-in-out slide-in delay-100  bg-white slide-in 0.1s"
                        }   cursor-pointer rounded-full text-center py-2.5 w-[50%]`}
                        onClick={() => setPlanInterval("monthly")}
                    >
                        <p className="text-base text-gm-50 ">Monthly</p>
                    </div>
                    <div
                        className={`${
                            planInterval === "year" &&
                            "transition ease-in-out slide-in delay-100  bg-white slide-in 0.1s"
                        } w-[50%]  cursor-pointer rounded-[200px] text-center py-2.5 `}
                        onClick={() => setPlanInterval("year")}
                    >
                        <p className="text-base text-gm-50 ">Yearly</p>
                    </div>
                </div>
            </div>
            {!fetchingSubscriptionPlan ? (
                <div className="mt-6 flex lg:flex-row flex-col gap-2 w-full">
                    {subscriptionPlans &&
                        subscriptionPlans.length > 0 &&
                        subscriptionPlans?.map((sub: { [key: string]: any }, idx: number) => (
                            <div
                                key={idx}
                                className={`rounded-lg w-full  lg:w-[33.3%]  ${
                                    sub?.category === data?.plan?.category &&
                                    sub?.interval === data?.plan?.interval
                                        ? "border-[3px]  border-gm-75 "
                                        : "border border-slate-200"
                                } flex flex-col justify-between bg-white p-6 `}
                            >
                                <div>
                                    <div className="pb-4 border-b border-slate-200">
                                        <div className="flex space-x-1">
                                            <p className="text-[18px] text-slate-900  font-rocGroteskMedium">
                                                {sub?.category}
                                            </p>
                                            {sub?.category === data?.plan?.category &&
                                                sub?.interval === data?.plan?.interval && (
                                                    <div className="bg-g-50 py-1 px-2 rounded text-xs flex items-center justify-center text-white font-rocGroteskMedium">
                                                        <p>
                                                            {data?.trial
                                                                ? "Free trial"
                                                                : "Current plan"}
                                                        </p>
                                                    </div>
                                                )}
                                        </div>
                                        <p className="text-sm font-rocGroteskRegular text-gm-50 mb-1">
                                            {sub?.description}
                                        </p>
                                        {sub?.category?.toLowerCase() !== "enterprise" ? (
                                            <p className=" font-rocGroteskMedium">
                                                <span className="text-sm text-slate-900">
                                                    {getCurrencyFromCurrencyCode(
                                                        sub?.annualPrice?.currency
                                                    )}
                                                    {planInterval === "year"
                                                        ? `${formatMoney()?.format(
                                                              sub?.annualPrice?.amount
                                                          )}/Year `
                                                        : `${formatMoney()?.format(
                                                              sub?.monthlyPrice?.amount
                                                          )}/Month`}
                                                </span>
                                                {sub?.category?.toLowerCase() === "pro" &&
                                                    planInterval === "year" && (
                                                        <span className="text-[18px] text-p-50">
                                                            (save $60)
                                                        </span>
                                                    )}
                                            </p>
                                        ) : (
                                            <p className=" font-rocGroteskMedium">
                                                {sub?.shortDescription}
                                            </p>
                                        )}
                                    </div>
                                    <div className="py-4 lg:flex hidden mb-4 flex-col gap-3">
                                        {Object.values(sub?.benefit)?.map(
                                            (d: { description: string }, idx: number) =>
                                                d?.description &&
                                                d?.description.trim() !== "" && (
                                                    <div
                                                        key={idx}
                                                        className="flex gap-2 items-start"
                                                    >
                                                        <img
                                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1706704537/Vector_icfsse.svg"
                                                            className="w-fit h-fit mt-1"
                                                            alt="check-icon"
                                                        />
                                                        <span className="text-gm-50 text-sm font-rocGroteskRegular">
                                                            {d.description}
                                                        </span>
                                                    </div>
                                                )
                                        )}
                                    </div>
                                    <div className="py-4 lg:hidden flex mb-4 flex-col gap-3">
                                        {Object.values(sub?.benefit)
                                            ?.slice(0, expandedPlans[idx] ? sub?.benefit.length : 6)
                                            .map(
                                                (d: { description: string }, idx: number) =>
                                                    d?.description &&
                                                    d?.description.trim() !== "" && (
                                                        <div
                                                            key={idx}
                                                            className="flex gap-2 items-start"
                                                        >
                                                            <img
                                                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1706704537/Vector_icfsse.svg"
                                                                className="w-fit h-fit mt-1"
                                                                alt="check-icon"
                                                            />
                                                            <span className="text-gm-50 text-sm font-rocGroteskRegular">
                                                                {d.description}
                                                            </span>
                                                        </div>
                                                    )
                                            )}
                                        <div className="mt-5">
                                            <div
                                                className="lg:hidden flex whitespace-nowrap font-rocGroteskMedium space-x-1 text-sm text-slate-500 cursor-pointer"
                                                onClick={() => toggleSeeMore(idx)}
                                            >
                                                <p className="text-sm text-gm-75 ">
                                                    {" "}
                                                    {expandedPlans[idx] ? "See less " : "See more"}
                                                </p>
                                                <i
                                                    className={`ri-arrow-${
                                                        expandedPlans[idx] ? "up" : "down"
                                                    }-s-line text-gm-75`}
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full">
                                    {sub?.category !== "Business+" ? (
                                        <Button
                                            btnText={
                                                sub?.category.toLowerCase() !== "enterprise"
                                                    ? data?.plan?.category === sub.category &&
                                                      sub?.interval === data?.plan?.interval &&
                                                      !data?.trial
                                                        ? "Cancel plan"
                                                        : "Select this plan"
                                                    : "Contact sales"
                                            }
                                            btnClassname={` ${
                                                data?.plan?.category === sub.category &&
                                                sub?.interval === data?.plan?.interval &&
                                                !data?.trial &&
                                                "!bg-n-20 !text-gm-50"
                                            } `}
                                            type={"button"}
                                            disabled={
                                                createStripeSession ||
                                                updateStripeSession ||
                                                cancelSubscription
                                            }
                                            isLoading={
                                                (createStripeSession && sub?._id === planId) ||
                                                (updateStripeSession && sub?._id === planId) ||
                                                (cancelSubscription && sub?._id === planId)
                                            }
                                            onClick={() =>
                                                sub?.category.toLowerCase() !== "enterprise"
                                                    ? data?.plan?.category === sub.category &&
                                                      !data?.trial
                                                        ? onDeletePlan(sub)
                                                        : onUpgradePlan(sub)
                                                    : console.log("Contact sales")
                                            }
                                        />
                                    ) : (
                                        <Button
                                            disabled={
                                                createStripeSession ||
                                                updateStripeSession ||
                                                cancelSubscription
                                            }
                                            btnClassname={` ${
                                                data?.plan?.category === sub.category &&
                                                sub?.interval === data?.plan?.interval &&
                                                !data?.trial &&
                                                "!bg-n-20 !text-gm-50"
                                            } `}
                                            btnText={
                                                data?.plan?.category === sub.category
                                                    ? "Cancel plan"
                                                    : "Select this plan"
                                            }
                                            type={"button"}
                                            isLoading={
                                                (createStripeSession && sub?._id === planId) ||
                                                (updateStripeSession && sub?._id === planId) ||
                                                (cancelSubscription && sub?._id === planId)
                                            }
                                            onClick={() =>
                                                data?.plan?.category === sub.category
                                                    ? onDeletePlan(sub)
                                                    : onUpgradePlan(sub)
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                </div>
            ) : (
                <Loader />
            )}

            {subscriptionPlanSuccess && (
                <ModalContainer
                    open={subscriptionPlanSuccess}
                    showCloseIcon={false}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[500px]"
                    closeModal={() => {
                        setSubscriptionPlanSuccess(false);
                        setShowUpgradePlan(true);
                        setPlanInterval("year");
                    }}
                >
                    <div className="bg-white !px-2 p-8  rounded-[12px]">
                        <div className="px-6">
                            <div className="text-center flex justify-center flex-col items-center space-y-5">
                                <div className="w-14 h-14 flex justify-center items-center bg-[#00DB8F] rounded-full  shadow-lightGreenShadow">
                                    <i className="ri-checkbox-circle-fill text-[29px] text-white "></i>
                                </div>
                                <div>
                                    <h3 className="text-2xl text-black mb-2 font-rocGroteskMedium">
                                        You’ve upgraded your <br /> account to{" "}
                                        {data?.plan?.category}
                                    </h3>
                                    <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                        You're now on the {data?.plan?.category} Plan with exciting
                                        features. A payment receipt will be emailed to you shortly.
                                    </p>
                                </div>
                            </div>
                            <div className="my-5">
                                {!fetchingSubscriptionPlan ? (
                                    <div>
                                        {Object.values((data && data.benefit) || {})
                                            .slice(0, 4)
                                            .map((d: { description: string }, index: number) => (
                                                <React.Fragment key={index}>
                                                    {d?.description && (
                                                        <div className="flex gap-2 items-center">
                                                            <i className="ri-check-fill text-g-50"></i>
                                                            <span className="text-gm-50 text-[13px] leading-[18px] font-rocGroteskRegular">
                                                                {d?.description}
                                                            </span>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        <div className="flex gap-2 items-center">
                                            <i className="ri-check-fill text-g-50"></i>
                                            <span className="text-gm-50 text-sm font-rocGroteskRegular">
                                                And many more feature...
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <Loader />
                                )}
                            </div>
                            <div className="mt-5 flex space-x-3">
                                <Button
                                    btnText={"Close"}
                                    type={"button"}
                                    btnClassname={"!bg-n-20 !text-gm-50"}
                                    isLoading={false}
                                    onClick={() => {
                                        setSubscriptionPlanSuccess(false);
                                        setShowUpgradePlan(false);
                                        setPlanInterval("year");
                                    }}
                                />
                                <Button
                                    onClick={() => {
                                        setSubscriptionPlanSuccess(false);
                                        setShowUpgradePlan(true);
                                        navigate("/dashboard/");
                                    }}
                                    btnText={"Explore all features"}
                                    type={"button"}
                                    isLoading={false}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}

            {subscriptionPlanFailure && (
                <ModalContainer
                    open={subscriptionPlanFailure}
                    showCloseIcon={false}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[500px]"
                    closeModal={() => {
                        setSubscriptionPlanFailure(false);
                        navigate("/dashboard/settings/2");
                        setShowUpgradePlan(true);
                    }}
                >
                    <div className="bg-white !px-2 p-8  rounded-[12px]">
                        <div className="px-6">
                            <div className="text-center flex justify-center flex-col items-center space-y-5">
                                <div className="w-14 h-14 flex justify-center items-center bg-r-50 shadow-icon-red rounded-full">
                                    <i className="ri-close-fill text-[29px] text-white "></i>
                                </div>
                                <div>
                                    <h3 className="text-2xl mb-2 text-black font-rocGroteskMedium">
                                        Payment Failed
                                    </h3>
                                    <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                        We've detected a problem with your recent payment. To
                                        resolve this issue and continue using our services, please
                                        update your payment information or reach out to our support
                                        team for assistance.
                                    </p>
                                </div>
                            </div>

                            <div className="mt-5 flex space-x-3">
                                <Button
                                    btnText={"Close"}
                                    type={"button"}
                                    btnClassname={"!bg-n-20 !text-gm-50"}
                                    isLoading={false}
                                    onClick={() => {
                                        setSubscriptionPlanFailure(false);
                                        setShowUpgradePlan(true);
                                    }}
                                />
                                <Button
                                    onClick={() => onUpgradePlan(data)}
                                    btnText={"Retry"}
                                    type={"button"}
                                    isLoading={createStripeSession}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </div>
    );
};

export default SubscriptionView;
