/* eslint-disable */
import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CustomCalendar from "components/common/CalendarComp.js/Calendar";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { getCurrencyFromCurrencyCode, onScroll } from "helpers";
import { flatten } from "helpers/flattenArray";
import { debounce, flattenDeep, truncate } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";
import { useNavigate } from "react-router-dom";
import { orderActions } from "redux/Ldb/actions";
import TabsContainer from "components/common/TabsContainer";
import ApprovedQuotes from "components/views/Ldb/Dashboard/Quotes/ApprovedQuotes";
import RejectedQuotes from "components/views/Ldb/Dashboard/Quotes/RejectedQuotes";
import PendingQuotes from "components/views/Ldb/Dashboard/Quotes/PendingQuotes";

const Quotes = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState<number>(0);

    const [filter, setFilter] = useState({
        search: "",
        amount: "",
        vendor: "",
        quoteId: "",
    });
    const [debouncedFilter, setDebouncedFilter] = useState({
        search: "",
        amount: "",
        vendor: "",
        quoteId: "",
    });

    const handleSearch = (e) => {
        const { name, value } = e.target;

        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const tabs = ["Approved quote", "Pending quote", "Rejected quote"];

    const handleDebouncedChange = debounce((e) => {
        const { name, value } = e.target;

        setDebouncedFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, 1500);

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <ApprovedQuotes
                        filter={filter}
                        debouncedFilter={debouncedFilter}
                        setDebouncedFilter={setDebouncedFilter}
                        setFilter={setFilter}
                    />
                );
            case 1:
                return (
                    <PendingQuotes
                        filter={filter}
                        debouncedFilter={debouncedFilter}
                        setDebouncedFilter={setDebouncedFilter}
                        setFilter={setFilter}
                    />
                );
            case 2:
                return (
                    <RejectedQuotes
                        filter={filter}
                        debouncedFilter={debouncedFilter}
                        setDebouncedFilter={setDebouncedFilter}
                        setFilter={setFilter}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <PageFrame containerClassName={"!h-[calc(100vh-108px)]"} isLoading={false}>
            <div className="px-2 xs:px-[20px] lg:px-[26px]">
                <div className="mb-8 flex justify-between">
                    <BreadCrumb
                        parentCrumb={"Orders"}
                        parentUrl={"/dashboard/quotes"}
                        childrenCrumb={[{ title: "Quotes", path: `/dashboard/quotes` }]}
                        size="large"
                        parentClassName={"text-slate-400"}
                    />
                    <div className="w-full md:w-fit">
                        <Button
                            btnText={"Create PO"}
                            type={"button"}
                            onClick={() => navigate("/dashboard/purchase-orders/create")}
                            btnClassname="!py-2 !whitespace-nowrap !px-4"
                        />
                    </div>
                </div>
                <div className="relative">
                    <div className="flex justify-end mb-[-40px]">
                        <div className="flex items-center gap-2 w-1/2">
                            <TextInput
                                name={"search"}
                                type={"text"}
                                value={filter.search}
                                inputPlaceholder={"Search"}
                                inputClassName={"!h-[40px] pl-[0px] !bg-white text-sm !mb-0"}
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-[4px] !h-[40px]  w-full !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                                }
                                onChange={handleSearch}
                                onInput={handleDebouncedChange}
                            />
                            <Dropdown
                                value={""}
                                dropdown={
                                    <div className="flex items-center cursor-pointer gap-2 py-1.5 px-3 bg-white rounded shadow-boxShadow-2 border border-n-20">
                                        <i className="ri-filter-3-fill"></i>
                                        <p className="text-[13px] lg:block hidden font-rocGroteskMedium">
                                            Filter
                                        </p>
                                    </div>
                                }
                                dropdownContainerClasses={
                                    " left-[-234px] !py-0 top-[49px] shadow-dropdownShadow rounded !border-0"
                                }
                                dropdownClassName={"!max-h-fit"}
                                name={"quickAction"}
                                dropdownOptions={[
                                    {
                                        customChild: (
                                            <div className="w-[318px] relative  bg-white p-4 shadow-boxShadow-2 rounded border border-n-20">
                                                <div className="flex relative flex-col h-fit gap-2">
                                                    <TextInput
                                                        name={"quoteId"}
                                                        type={"text"}
                                                        value={filter.quoteId}
                                                        placeholder={"Quote ID"}
                                                        onChange={handleSearch}
                                                        onInput={handleDebouncedChange}
                                                        required={false}
                                                    />
                                                    <TextInput
                                                        name={"amount"}
                                                        type={"number"}
                                                        onWheel={(e: any) => e.target.blur()}
                                                        value={filter.amount}
                                                        placeholder={"Amount"}
                                                        onChange={handleSearch}
                                                        onKeyDown={(evt) =>
                                                            [
                                                                "e",
                                                                "E",
                                                                "+",
                                                                "-",
                                                                "ArrowUp",
                                                                "ArrowDown",
                                                            ].includes(evt.key) &&
                                                            evt.preventDefault()
                                                        }
                                                        onInput={handleDebouncedChange}
                                                        required={false}
                                                    />
                                                    <TextInput
                                                        name={"vendor"}
                                                        type={"text"}
                                                        value={filter.vendor}
                                                        placeholder={"Supplier"}
                                                        onChange={handleSearch}
                                                        onInput={handleDebouncedChange}
                                                        required={false}
                                                    />
                                                </div>
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <TabsContainer
                        tabs={tabs}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        component={displayActiveTab()}
                        className="!px-0"
                        itemClassName="!pb-2"
                        borderLineClase={"!text-slate-100 w-[40%] mb-4"}
                    />
                </div>
            </div>
        </PageFrame>
    );
};

export default Quotes;
