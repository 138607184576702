import React, { useEffect, useRef, useState } from "react";
import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import PageFrame from "components/layout/PageFrame";
import ReactECharts from "echarts-for-react";
import * as htmlToImage from "html-to-image";
import { AnalyticsCard } from "components/views/Ldb/Dashboard/BusinessAnalytics/Card";
import ShareModal from "components/views/Ldb/Dashboard/BusinessAnalytics/ShareModal";
import { businessAnalyticsActions } from "redux/Ldb/actions/businessAnalyticsActions";
import { fillMissingWithZero } from "helpers/fillMissingWithZero";
import CustomCalendar from "components/common/CalendarComp.js/Calendar";
import moment from "moment";
import Loader from "components/common/Loader";
import { MultipleDropdowns } from "components/common/MultipleDropdown";
import { BlobProvider, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import ProcurementAnalysisPdf from "components/views/Ldb/Dashboard/BusinessAnalytics/ProcurementAnalysisPdf";
import { getFromStorage } from "helpers";
import pptxgen from "pptxgenjs";
import { convertPDFtoImages } from "helpers/convertPDFBlobToImages";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { getEditPermissionList } from "helpers/getEditPermissionList";

const ProcurementCycle = () => {
    const dispatch = useAppDispatch();
    const chartRef = useRef(null);
    const [shareModal, setShareModal] = useState(false);
    const [showCustomDateOne, setShowCustomDateOne] = useState(false);
    const [changeValue, setChangeValue] = useState("year");
    const [series1Data, setSeries1Data] = useState<any>([]);
    const [series2Data, setSeries2Data] = useState<any>([]);
    const [procurementData, setProcurementData] = useState<any>([]);
    const [procurementGraphData, setProcurementGraphData] = useState<any>([]);
    const [procurementGraphDataTrends, setProcurementGraphDataTrends] = useState<any>([]);
    const [insights, setInsights] = useState<any>(0);
    const [procurementGraphDataRatio, setProcurementGraphDataRatio] = useState<any>(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [exportOpt, setExportOpt] = useState("");
    const [startDateRange, setStartDateRange] = useState("");
    const [endDateRange, setEndDateRange] = useState("");
    const [year, setYear] = useState<number>(0);
    const [month, setMonth] = useState("");
    const [captureChartLoading, setCaptureChartLoading] = useState(false);
    const [changeGraphValue, setChangeGraphValue] = useState("year");
    const [value, onChange] = useState(new Date());
    const [profile, setProfile] = useState(getFromStorage("ally-user"));
    const [chartImage, setChartImage] = useState("");
    const [imageUrls, setImageUrls] = useState<string[]>();
    const [generatedBlob, setGeneratedBlob] = useState(null);
    const {
        fetchingProcurementAnalytics,
        fetchedProcurementAnalyticsSuccess,
        fetchingProcurementAnalyticsGraph,
        fetchedProcurementAnalyticsGraphSuccess,
        fetchingProcurementAnalyticsInsight,
        fetchedProcurementAnalyticsInsightSuccess,
    } = useAppSelector((state) => state.businessAnalytics);
    
    const values = [
        {
            label: "1 year",
            value: "year",
        },
        {
            label: "6 months",
            value: "six_month",
        },
        {
            label: "1 month",
            value: "month",
        },
        {
            label: "1 week",
            value: "week",
        },
        {
            label: "custom",
            value: "custom",
        },
    ];

    function convertDurationLabel(input) {
        const durationMap = {
            "1 year": "1 year",
            six_month: "six months",
            month: "month",
            week: "week",
            custom: "period",
        };

        return durationMap[input] || input;
    }

    useEffect(() => {
        if (value) {
            setStartDate(moment(value[0]).format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
            setEndDate(moment(value[1]).format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
        }
    }, [value]);

    useEffect(() => {
        if (procurementGraphData) {
            const check = fillMissingWithZero(
                procurementGraphData,
                changeValue,
                "procurement",
                setStartDateRange,
                setEndDateRange
            );
            setSeries1Data({
                data1: check?.x,
                data2: check?.y,
            });
        }
        if (procurementGraphDataTrends) {
            const check = fillMissingWithZero(
                procurementGraphDataTrends,
                changeValue,
                "procurement"
            );

            setSeries2Data({
                data1: check?.x,
                data2: check?.y,
            });
        }
    }, [procurementGraphData, procurementGraphDataTrends, setStartDateRange, setEndDateRange]);

    const options = {
        grid: { top: 8, right: 0, bottom: 24, left: 0 },
        xAxis: {
            type: "category",
            data: series1Data?.data2,
            nameTextStyle: {
                borderType: "dotted",
                fontSize: "9",
                fontFamily: "rocGrotesk-regular",
            },
            axisLabel: {
                color: "#89939b",
                align: "center",
            },
            axisLine: {
                lineStyle: {
                    color: "#E2E8F0",
                },
            },
            axisTick: {
                show: false,
            },
        },

        yAxis: [
            {
                type: "value",
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: "#7581BD",
                    },
                },
                axisLabel: {
                    formatter: "{value}",
                },
                splitLine: {
                    show: false,
                },
                show: false,
            },
            {
                type: "value",

                axisLabel: {
                    formatter: "{value}",
                },
                splitLine: {
                    show: false,
                },
            },
        ],
        series: [
            {
                name: "Av. team efficiency",
                data: series1Data?.data1,
                type: "line",
                yAxisIndex: 0,
                lineStyle: {
                    width: 1.4,
                    color: "rgba(0, 165, 208, 1)",
                },
                areaStyle: {
                    color: {
                        type: "linear",
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            { offset: 0, color: "rgba(0, 240, 255, 0.15)" },
                            { offset: 1, color: "rgba(0, 240, 255, 0)" },
                        ],
                    },
                },
                showSymbol: false,
                symbol: "none",
            },
            {
                name: "Previous team efficiency",
                data: series2Data?.data1,
                type: "line",
                yAxisIndex: 1,
                lineStyle: {
                    type: "dashed",
                    color: "rgba(255, 61, 29, 1)",
                },

                showSymbol: false,
                symbol: "none",
            },
        ],
        tooltip: {
            trigger: "axis",
            backgroundColor: "#142837",
            textStyle: {
                color: "#fff",
            },

            axisPointer: {
                type: "line",
                lineStyle: {
                    color: "#00A5D0",
                    width: 1,
                    type: "dashed",
                },
            },
            formatter: function (params) {
                if (params) {
                    return `<div className="font-normal !text-white"> Efficiency ratio: <span className="font-bold text-white">${
                        procurementGraphDataRatio?.currentValue
                    }%</span></div>
                    <div className="font-normal"> Period: <span className="font-bold">${
                        params[0].name
                    } </span> </div>
                          <div className="font-normal" >${
                              params[0]?.seriesName ? params[0]?.seriesName : "Av. team efficiency"
                          }: <span className="font-bold"> ${params[0]?.value}% </span></div>
                          <div className="font-normal">${
                              params[1]?.seriesName
                                  ? params[1]?.seriesName
                                  : "Previous team efficiency"
                          }: <span className="font-bold">${
                        params[1]?.value ? params[1]?.value : 0
                    }% </span></div>
                          `;
                } else {
                    return "";
                }
            },
        },
    };

    useEffect(() => {
        if (changeValue !== "custom") {
            dispatch(businessAnalyticsActions.procurementOrderGraph(changeValue));
            dispatch(businessAnalyticsActions.procurementInsight(changeValue));
            dispatch(businessAnalyticsActions.procurementOrder(changeValue));
            const procurementYear = new Date().getFullYear();
            const procurementMonth = new Date().toLocaleString("default", { month: "long" });
            setYear(procurementYear);
            setMonth(procurementMonth);
        } else {
            dispatch(
                businessAnalyticsActions.procurementOrderGraph(changeValue, startDate, endDate)
            );
            dispatch(businessAnalyticsActions.procurementInsight(changeValue, startDate, endDate));

            dispatch(businessAnalyticsActions.procurementOrder(changeValue, startDate, endDate));
            const procurementYear = new Date(endDate).getFullYear();
            const procurementMonth = new Date(endDate).toLocaleString("default", {
                month: "long",
            });
            setYear(procurementYear);
            setMonth(procurementMonth);
        }
    }, [dispatch, endDate, changeValue, startDate]);

    useEffect(() => {
        if (Boolean(fetchedProcurementAnalyticsInsightSuccess)) {
            setInsights(fetchedProcurementAnalyticsInsightSuccess);
        }
    }, [fetchedProcurementAnalyticsInsightSuccess]);

    useEffect(() => {
        if (Boolean(fetchedProcurementAnalyticsGraphSuccess)) {
            setProcurementGraphData(
                fetchedProcurementAnalyticsGraphSuccess?.graphData?.currentValue
            );
            setProcurementGraphDataTrends(
                fetchedProcurementAnalyticsGraphSuccess?.graphData?.trends
            );
            setProcurementGraphDataRatio(
                fetchedProcurementAnalyticsGraphSuccess?.procurementEfficeincyRatioData
            );
        }
    }, [fetchedProcurementAnalyticsGraphSuccess]);

    useEffect(() => {
        if (Boolean(fetchedProcurementAnalyticsSuccess)) {
            setProcurementData(fetchedProcurementAnalyticsSuccess);
        }
    }, [fetchedProcurementAnalyticsSuccess]);

    const createPPT = (imageUrls) => {
        const pres = new pptxgen();
        let slides = [];

        imageUrls?.forEach((imageUrl, index) => {
            slides[index + 1] = pres.addSlide();
            slides[index + 1].addImage({
                data: imageUrl,
                x: 0,
                y: 0,
                w: "100%",
                h: "100%",
            });
        });

        pres.writeFile({ fileName: "procurement_analysis.pptx" });
    };

    useEffect(() => {
        if (imageUrls) createPPT(imageUrls);
    }, [imageUrls]);

    const getBlobImages = async (blob) => {
        const blobImages = await convertPDFtoImages(blob);
        setImageUrls(blobImages);
    };

    const captureChart = async () => {
        setCaptureChartLoading(true);
        if (!chartRef.current) {
            return;
        }
        if (!chartImage) {
            const chartElement = chartRef.current;
            const img = await htmlToImage.toPng(chartElement);
            setChartImage(img);
            setCaptureChartLoading(false);
        }
    };

    const dropOptions = [
        {
            label: (
                <div
                    className=" text-gm-50 font-rocGroteskMedium text-[13px] flex gap-3
                                 border-b border-b-slate-100 !w-[192px] px-[10px] !py-[10px]"
                >
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702458892/ppt_qm1phs.svg"
                        alt="powerpoint icon"
                        className="w-3"
                    />
                    Export as powerpoint
                </div>
            ),
            onClick: () => {
                setExportOpt("ppt");
            },
            childClassName: "!top-2",
            actionButtons: (
                <div className="flex justify-end mt-[25px] px-4 mb-3 gap-3 relative !z-[999]">
                    {exportOpt === "ppt" ? (
                        <Button
                            btnClassname={"!w-fit !py-3 !px-6 !bg-[#FFB902] !text-black"}
                            disabled={true}
                            btnText={"Export"}
                        />
                    ) : captureChartLoading ? (
                        <Button
                            btnClassname={"!w-fit !py-3 !px-8 !bg-[#FFB902] !text-black"}
                            disabled={true}
                            isLoading={true}
                        />
                    ) : (
                        <BlobProvider
                            document={
                                <ProcurementAnalysisPdf
                                    year={
                                        changeValue !== "custom"
                                            ? `${startDateRange} - ${endDateRange}`
                                            : `${moment(startDate).format("MMMM YYYY")} - ${moment(
                                                  endDate
                                              ).format("MMMM YYYY")}`
                                    }
                                    month={month}
                                    chartName={"Procurement Analysis"}
                                    businessLogo={profile?.profile?.avatar}
                                    chart={chartImage || null}
                                    cycleInsightsShow={
                                        exportOpt === "pdf-procure-analysis" ? false : true
                                    }
                                    procurementAnalysisShow={
                                        exportOpt === "pdf-procure-cycle" ? false : true
                                    }
                                    filterDuration={convertDurationLabel(changeValue)}
                                    percentageRequisitionValue={Math.abs(
                                        procurementData?.averageTimeOfRequsitionData
                                            ?.percentageChanges
                                    )}
                                    percentageTeamValue={Math.abs(
                                        procurementData?.averageTeamEfficeincyData
                                            ?.percentageChanges
                                    )}
                                    percentageGoodsValue={Math.abs(
                                        procurementData?.goodsDeliveryTimelineAnalytics
                                            ?.percentageChanges
                                    )}
                                    percentagePOApprovalValue={Math.abs(
                                        procurementData?.purchaseOrderApprovalAnalytics
                                            ?.percentageChanges
                                    )}
                                    increaseRequisition={
                                        procurementData?.averageTimeOfRequsitionData
                                            ?.percentageChanges > 0
                                    }
                                    increaseTeam={
                                        procurementData?.averageTeamEfficeincyData
                                            ?.percentageChanges > 0
                                    }
                                    increasePOApproval={
                                        procurementData?.purchaseOrderApprovalAnalytics
                                            ?.percentageChanges > 0
                                    }
                                    increaseGoods={
                                        procurementData?.goodsDeliveryTimelineAnalytics
                                            ?.percentageChanges > 0
                                    }
                                    poApprovalTime={
                                        procurementData?.purchaseOrderApprovalAnalytics
                                            ?.currentValue
                                    }
                                    goodsTime={
                                        procurementData?.goodsDeliveryTimelineAnalytics
                                            ?.currentValue
                                    }
                                    teamTime={
                                        procurementData?.averageTeamEfficeincyData?.currentValue
                                    }
                                    requisitionTime={
                                        procurementData?.averageTimeOfRequsitionData?.currentValue
                                    }
                                    totalWorkflow={
                                        insights?.totalWorkFlows ? insights?.totalWorkFlows : 0
                                    }
                                    totalWorkflowRuns={
                                        insights?.totalWorkFLowRuns
                                            ? insights?.totalWorkFLowRuns
                                            : 0
                                    }
                                    insights={insights?.insights}
                                    procurementGraphDataRatio={procurementGraphDataRatio}
                                    trendsValue={convertDurationLabel(changeValue)}
                                    changeValue={convertDurationLabel(changeGraphValue)}
                                />
                            }
                        >
                            {({ blob, loading }) => {
                                setGeneratedBlob(blob);
                                return !loading && generatedBlob ? (
                                    <Button
                                        btnText={"Export"}
                                        btnClassname={
                                            "!w-fit !py-3 !px-6 !bg-[#FFB902] !text-black"
                                        }
                                        onClick={() => getBlobImages(generatedBlob)}
                                    />
                                ) : (
                                    <Button
                                        btnClassname={
                                            "!w-fit !py-3 !px-8 !bg-[#FFB902] !text-black"
                                        }
                                        btnText={"Export"}
                                        isLoading={true}
                                    />
                                );
                            }}
                        </BlobProvider>
                    )}
                </div>
            ),
            children: [
                {
                    label: (
                        <div
                            className=" text-gm-50 font-rocGroteskMedium text-base flex justify-between items-center
                                                border-b border-b-slate-100 !w-full !px-6 !pt-4 !pb-3
                                                !hover:bg-[#fff]  mb-[15px]"
                        >
                            Export options
                        </div>
                    ),
                    className: "hover:bg-transparent cursor-default !px-0",
                },
                {
                    label: (
                        <div className=" text-gm-50 font-rocGroteskMedium text-sm flex justify-between items-center px-2 md:px-6 py-3 gap-3">
                            {exportOpt === "pdf-all" ? (
                                <>
                                    <i className="ri-radio-button-line text-[#7895FF] text-base"></i>
                                </>
                            ) : (
                                <>
                                    <i className="ri-circle-line text-[#7895FF] text-base"></i>
                                </>
                            )}
                            Export this entire page
                        </div>
                    ),
                    onClick: () => {
                        setExportOpt("pdf-all");
                        captureChart();
                    },
                },
                {
                    label: (
                        <div className=" text-gm-50 font-rocGroteskMedium text-sm flex justify-between items-center px-2 md:px-6 py-3 gap-3">
                            {exportOpt === "pdf-procure-analysis" ? (
                                <>
                                    <i className="ri-radio-button-line text-[#7895FF] text-base"></i>
                                </>
                            ) : (
                                <>
                                    <i className="ri-circle-line text-[#7895FF] text-base"></i>
                                </>
                            )}
                            Export Procurement analysis alone
                        </div>
                    ),
                    onClick: () => {
                        setExportOpt("pdf-procure-analysis");
                        captureChart();
                    },
                },
                {
                    label: (
                        <div className=" text-gm-50 font-rocGroteskMedium whitespace-pre-line   text-sm flex justify-between items-center px-2 md:px-6 py-3 gap-3 ">
                            {exportOpt === "pdf-procure-cycle" ? (
                                <>
                                    <i className="ri-radio-button-line text-[#7895FF] text-base"></i>
                                </>
                            ) : (
                                <>
                                    <i className="ri-circle-line text-[#7895FF] text-base"></i>
                                </>
                            )}
                            Export Procurement cycle time analysis alone
                        </div>
                    ),
                    onClick: () => {
                        setExportOpt("pdf-procure-cycle");
                        setChartImage("");
                    },
                },
            ],
        },
        {
            label: (
                <div
                    className="text-gm-50 font-rocGroteskMedium text-[13px] flex gap-3
                            border-b border-b-slate-100 !w-[192px] px-[10px] py-[10px]"
                >
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700580775/pdf-document_kqjkit.svg"
                        alt="pdf icon"
                        className="w-3"
                    />
                    Export as PDF
                </div>
            ),
            onClick: () => {
                setExportOpt("pdf");
            },
            actionButtons: (
                <div className="flex justify-end mt-[25px] px-4 mb-3 gap-3 relative !z-[999]">
                    {exportOpt === "pdf" ? (
                        <Button
                            btnClassname={"!w-fit !py-3 !px-6 !bg-[#FFB902] !text-black"}
                            disabled={true}
                            btnText={"Export"}
                        />
                    ) : captureChartLoading ? (
                        <Button
                            btnClassname={"!w-fit !py-3 !px-8 !bg-[#FFB902] !text-black"}
                            disabled={true}
                            isLoading={true}
                        />
                    ) : (
                        <PDFDownloadLink
                            document={
                                <ProcurementAnalysisPdf
                                year={
                                    changeValue !== "custom"
                                        ? `${startDateRange} - ${endDateRange}`
                                        : `${moment(startDate).format("MMMM YYYY")} - ${moment(
                                              endDate
                                          ).format("MMMM YYYY")}`
                                }
                                    month={month}
                                    chartName={"Procurement Analysis"}
                                    businessLogo={profile?.profile?.avatar}
                                    chart={chartImage || null}
                                    cycleInsightsShow={
                                        exportOpt === "pdf-procure-analysis" ? false : true
                                    }
                                    procurementAnalysisShow={
                                        exportOpt === "pdf-procure-cycle" ? false : true
                                    }
                                    filterDuration={convertDurationLabel(changeValue)}
                                    percentageRequisitionValue={Math.abs(
                                        procurementData?.averageTimeOfRequsitionData
                                            ?.percentageChanges
                                    )}
                                    percentageTeamValue={Math.abs(
                                        procurementData?.averageTeamEfficeincyData
                                            ?.percentageChanges
                                    )}
                                    percentageGoodsValue={Math.abs(
                                        procurementData?.goodsDeliveryTimelineAnalytics
                                            ?.percentageChanges
                                    )}
                                    percentagePOApprovalValue={Math.abs(
                                        procurementData?.purchaseOrderApprovalAnalytics
                                            ?.percentageChanges
                                    )}
                                    increaseRequisition={
                                        procurementData?.averageTimeOfRequsitionData
                                            ?.percentageChanges > 0
                                    }
                                    increaseTeam={
                                        procurementData?.averageTeamEfficeincyData
                                            ?.percentageChanges > 0
                                    }
                                    increasePOApproval={
                                        procurementData?.purchaseOrderApprovalAnalytics
                                            ?.percentageChanges > 0
                                    }
                                    increaseGoods={
                                        procurementData?.goodsDeliveryTimelineAnalytics
                                            ?.percentageChanges > 0
                                    }
                                    poApprovalTime={
                                        procurementData?.purchaseOrderApprovalAnalytics
                                            ?.currentValue
                                    }
                                    goodsTime={
                                        procurementData?.goodsDeliveryTimelineAnalytics
                                            ?.currentValue
                                    }
                                    teamTime={
                                        procurementData?.averageTeamEfficeincyData?.currentValue
                                    }
                                    requisitionTime={
                                        procurementData?.averageTimeOfRequsitionData?.currentValue
                                    }
                                    totalWorkflow={
                                        insights?.totalWorkFlows ? insights?.totalWorkFlows : 0
                                    }
                                    totalWorkflowRuns={
                                        insights?.totalWorkFLowRuns
                                            ? insights?.totalWorkFLowRuns
                                            : 0
                                    }
                                    insights={insights?.insights}
                                    procurementGraphDataRatio={procurementGraphDataRatio}
                                    trendsValue={convertDurationLabel(changeValue)}
                                    changeValue={convertDurationLabel(changeGraphValue)}
                                />
                            }
                            fileName={"procurement_analysis.pdf"}
                        >
                            {({ loading }) => (
                                <>
                                    {loading ? (
                                        <Button
                                            isLoading={true}
                                            btnClassname={
                                                "!w-fit !py-3 !px-6 !bg-[#FFB902] !text-black"
                                            }
                                        />
                                    ) : (
                                        <Button
                                            btnText={"Export"}
                                            btnClassname={
                                                "!w-fit !py-3 !px-6 !bg-[#FFB902] !text-black"
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </PDFDownloadLink>
                    )}
                </div>
            ),
            children: [
                {
                    label: (
                        <div
                            className=" text-gm-50 font-rocGroteskMedium text-base flex justify-between items-center
                                                border-b border-b-slate-100 !w-full px-3 md:px-6 !pt-4 !pb-3
                                                !hover:bg-[#fff]  mb-[15px]"
                        >
                            Export options
                        </div>
                    ),
                    className: "hover:bg-transparent cursor-default !px-0",
                },
                {
                    label: (
                        <div className=" text-gm-50 font-rocGroteskMedium text-sm flex justify-between items-center md:px-6 py-3 gap-3">
                            {exportOpt === "pdf-all" ? (
                                <>
                                    <i className="ri-radio-button-line text-[#7895FF] text-base"></i>
                                </>
                            ) : (
                                <>
                                    <i className="ri-circle-line text-[#7895FF] text-base"></i>
                                </>
                            )}
                            Export this entire page
                        </div>
                    ),
                    onClick: () => {
                        setExportOpt("pdf-all");
                        captureChart();
                    },
                },
                {
                    label: (
                        <div className=" text-gm-50 font-rocGroteskMedium text-sm flex justify-between items-center md:px-6 py-3 gap-3">
                            {exportOpt === "pdf-procure-analysis" ? (
                                <>
                                    <i className="ri-radio-button-line text-[#7895FF] text-base"></i>
                                </>
                            ) : (
                                <>
                                    <i className="ri-circle-line text-[#7895FF] text-base"></i>
                                </>
                            )}
                            Export Procurement analysis alone
                        </div>
                    ),
                    onClick: () => {
                        setExportOpt("pdf-procure-analysis");
                        captureChart();
                    },
                },
                {
                    label: (
                        <div className=" text-gm-50 font-rocGroteskMedium whitespace-pre-line   text-sm flex justify-between items-center md:px-6 py-3 gap-3 ">
                            {exportOpt === "pdf-procure-cycle" ? (
                                <>
                                    <i className="ri-radio-button-line text-[#7895FF] text-base"></i>
                                </>
                            ) : (
                                <>
                                    <i className="ri-circle-line text-[#7895FF] text-base"></i>
                                </>
                            )}
                            Export Procurement cycle time analysis alone
                        </div>
                    ),
                    onClick: () => {
                        setExportOpt("pdf-procure-cycle");
                        setChartImage("");
                    },
                },
            ],
        },
    ];

    return (
        <PageFrame containerClassName={"!h-[calc(100vh-108px)]"}>
            <div>
                <div className="px-[14px] overflow-auto">
                    <div className="mb-8">
                        <BreadCrumb
                            parentCrumb={"Business analytics"}
                            parentUrl={"#"}
                            childrenCrumb={[
                                { title: "Procurement analysis", path: "/dashboard/rfqs" },
                            ]}
                            size="large"
                        />
                    </div>
                    <div className="flex md:flex-row flex-col md:items-center md:justify-between mb-[27px]">
                        <div>
                            <p className="text-slate-900  font-rocGroteskMedium text-lg">
                                Procurement analysis
                            </p>
                        </div>

                        <div className="flex items-center  space-x-[14px]">
                            <div>
                                <Dropdown
                                    handleChange={(name, value) => {
                                        if (value?.toLowerCase() === "custom") {
                                            setShowCustomDateOne(true);
                                            setChangeValue(value);
                                        } else {
                                            setShowCustomDateOne(false);
                                            setChangeValue(value);
                                        }
                                    }}
                                    dropdown={
                                        <div className="flex whitespace-nowrap border border-n-20 items-center rounded-[5px] shadow-[0_1px_2px_0_rgba(91,104,113,0.15)] py-2 px-[15px] gap-2">
                                            <i className="ri-filter-3-line text-[#142837]"></i>

                                            <div
                                                className={`text-slate-800 font-rocGroteskMedium text-[13px]`}
                                            >
                                                {
                                                    values.find(
                                                        (item) => item.value === changeValue
                                                    )?.label
                                                }
                                            </div>
                                        </div>
                                    }
                                    dropdownItemsClasses={`!px-0 !py-0`}
                                    // dropdownClassname={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4  !z-[150] border border-slate-100  rounded !max-h-fit
                            `}
                                    dropdownOptions={[
                                        ...values?.map((item, idx) => ({
                                            label: (
                                                <span
                                                    key={idx}
                                                    className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                                border-b border-b-slate-100 !w-[159px] px-4 py-[10px]"
                                                >
                                                    {item.label}
                                                    {idx === values?.length - 1 && (
                                                        <i className="ri-arrow-right-s-line text-gm-50 text-xs "></i>
                                                    )}
                                                </span>
                                            ),
                                            value: item.value,
                                        })),
                                    ]}
                                />
                            </div>
                            <div>
                                <MultipleDropdowns
                                    containerClassName={`!p-0`}
                                    hideIcon={true}
                                    optionClassName={`!px-0 !py-0`}
                                    dropdown={
                                        <div className="flex items-center border border-n-20 rounded-[5px] shadow-boxShadow-2 !py-2 !px-[15px] gap-2">
                                            <i className="ri-expand-right-line text-lg text-[#142837]"></i>
                                            <div
                                                className={`text-slate-800 font-rocGroteskMedium text-[13px]`}
                                            >
                                                Export
                                            </div>
                                        </div>
                                    }
                                    disabled={fetchingProcurementAnalytics}
                                    options={dropOptions}
                                    className={` !rounded-[5px] !z-[150]  !shadow-boxShadow-2 !py-2 !px-0  !right-[20px] `}
                                    childClassName={` !rounded-[5px] !shadow-boxShadow-2 !py-2 !px-0 min-w-[300px] ${
                                        exportOpt === "ppt" ? "!mt-20" : "!mt-10"
                                    }  second-child-dropdown  `}
                                    relativeParent={`relative`}
                                      permissions={getEditPermissionList("analytics")}
                                />
                            </div>

                            {/* <div>
                                <Button
                                    btnText={"Share"}
                                    type={"button"}
                                    disabled={fetchingProcurementAnalytics}
                                    icon={<i className="ri-share-forward-line text-base"></i>}
                                    btnClassname="!py-2 !text-[13px]  !shadow-boxShadow-2 !rounded !bg-white !border !border-n-20 !text-gm-50 !whitespace-nowrap !px-4"
                                    onClick={() => setShareModal(true)}
                                />
                            </div> */}
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-3 mb-[43px]">
                        {showCustomDateOne && (
                            <div className="w-[630px]  z-[10000] absolute  right-20 px-[28px] pt-8 pb-8 bg-white rounded-2xl shadow-[0px_0px_4px_rgba(0,0,0,0.04),0px_4px_32px_rgba(0,0,0,0.06)]">
                                <CustomCalendar
                                    showDoubleView={true}
                                    value={value}
                                    onChange={onChange}
                                />
                                <div className="flex justify-end">
                                    <Button
                                        btnText={"Close"}
                                        btnClassname={"!w-fit !py-2"}
                                        onClick={() => setShowCustomDateOne(false)}
                                    />
                                </div>
                            </div>
                        )}
                        <AnalyticsCard
                            title={"Av. time for requisition to PO"}
                            time={procurementData?.averageTimeOfRequsitionData?.currentValue ?? 0}
                            filterDuration={`this ${convertDurationLabel(changeValue)}`}
                            iconOne
                            percentageValue={
                                procurementData?.averageTimeOfRequsitionData?.percentageChanges
                                    ? Math.abs(
                                          procurementData?.averageTimeOfRequsitionData
                                              ?.percentageChanges
                                      )
                                    : 0
                            }
                            increase={
                                procurementData?.averageTimeOfRequsitionData?.percentageChanges > 0
                            }
                            loader={fetchingProcurementAnalytics}
                            iconTwo
                            toolTipContent="This is the average time it takes for a 
                            PO to be raised for an order request"
                        />
                        <AnalyticsCard
                            iconOne={"!text-b-50 !bg-light-blue"}
                            title={"Av. time for PO approval"}
                            iconTwo
                            loader={fetchingProcurementAnalytics}
                            filterDuration={`this ${convertDurationLabel(changeValue)}`}
                            percentageValue={
                                procurementData?.purchaseOrderApprovalAnalytics?.percentageChanges
                                    ? Math.abs(
                                          procurementData?.purchaseOrderApprovalAnalytics
                                              ?.percentageChanges
                                      )
                                    : 0
                            }
                            increase={
                                procurementData?.purchaseOrderApprovalAnalytics?.percentageChanges >
                                0
                            }
                            time={
                                procurementData?.purchaseOrderApprovalAnalytics?.currentValue ?? 0
                            }
                            toolTipContent="This is the average time it takes 
                            for a raised PO to be approved"
                        />
                        <AnalyticsCard
                            iconOne={"!text-p-50 !bg-p-25"}
                            title={"Av. goods delivery timeline"}
                            iconTwo
                            loader={fetchingProcurementAnalytics}
                            increase={
                                procurementData?.goodsDeliveryTimelineAnalytics?.percentageChanges >
                                0
                            }
                            filterDuration={`this ${convertDurationLabel(changeValue)}`}
                            percentageValue={
                                procurementData?.goodsDeliveryTimelineAnalytics?.percentageChanges
                                    ? Math.abs(
                                          procurementData?.goodsDeliveryTimelineAnalytics
                                              ?.percentageChanges
                                      )
                                    : 0
                            }
                            time={
                                procurementData?.goodsDeliveryTimelineAnalytics?.currentValue ?? 0
                            }
                            toolTipContent="This is the average time it takes a supplier
                            to deliver an item after PO has been 
                            accepted"
                        />
                        <AnalyticsCard
                            iconOne={"!text-g-55 !bg-g-25"}
                            title={"Av. team time efficiency"}
                            iconTwo
                            loader={fetchingProcurementAnalytics}
                            increase={
                                procurementData?.averageTeamEfficeincyData?.percentageChanges > 0
                            }
                            percentageValue={
                                procurementData?.averageTeamEfficeincyData?.percentageChanges
                                    ? Math.abs(
                                          procurementData?.averageTeamEfficeincyData
                                              ?.percentageChanges
                                      )
                                    : 0
                            }
                            filterDuration={`this ${convertDurationLabel(changeValue)}`}
                            time={procurementData?.averageTeamEfficeincyData?.currentValue ?? 0}
                            lastChecker
                            toolTipContent="The is the average time each of your
                            team members spends on completing any 
                            given task"
                        />
                    </div>
                    <div className="w-full flex lg:flex-row flex-col lg:space-y-0 space-y-6 lg:space-x-4">
                        <div className="lg:w-[65%] bg-white shadow-faintShadow  rounded-[12px] p-6">
                            <div className="flex md:flex-col md:space-y-0  flex-col-reverse">
                                {fetchingProcurementAnalyticsGraph ? (
                                    <Loader />
                                ) : (
                                    <div>
                                        <div className="flex items-center justify-between mb-[27px]">
                                            <div>
                                                <p className="text-slate-900  font-rocGroteskMedium text-lg">
                                                    Procurement analysis
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex sm:flex-row flex-col md:mt-0 mt-4 sm:space-y-0 space-y-5 sm:space-x-6">
                                            <div>
                                                <p className="text-slate-400 text-sm mb-2">
                                                    Procurement efficiency ratio
                                                </p>
                                                <p className="text-3xl text-slate-800 font-rocGroteskBold">
                                                    {procurementGraphDataRatio?.currentValue}%
                                                </p>
                                                <div className="flex items-center">
                                                    <img
                                                        src={`${
                                                            procurementGraphDataRatio?.percentageChanges <=
                                                            0
                                                                ? "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1705249747/Vector_15_b0mpma.svg"
                                                                : "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1705249747/Vector_14_fg1bnn.svg"
                                                        }   `}
                                                        alt="ratio-icon"
                                                    />
                                                    <p className="text-gm-35 font-rocGroteskBold text-[12px] leading-3">
                                                        {
                                                            procurementGraphDataRatio?.percentageChanges
                                                        }
                                                        %{" "}
                                                        <span className="font-rocGroteskMedium">
                                                            {procurementGraphDataRatio?.percentageChanges <=
                                                            0
                                                                ? "down"
                                                                : "up"}{" "}
                                                            this{" "}
                                                            {convertDurationLabel(changeGraphValue)}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex items-center">
                                                    <img
                                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1705249747/Vector_13_axqdis.svg"
                                                        alt="this-trend"
                                                    />
                                                    <p className="text-gm-35 font-rocGroteskMedium text-[10px] leading-3">
                                                        Efficiency trend this{" "}
                                                        {convertDurationLabel(changeValue)}
                                                    </p>
                                                </div>
                                                <div className="flex items-center">
                                                    <img
                                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1701948550/Vector_311_npy4bh.svg"
                                                        alt="last-trend"
                                                    />
                                                    <p className="text-gm-35 font-rocGroteskMedium text-[10px] leading-3">
                                                        Efficiency trend last{" "}
                                                        {convertDurationLabel(changeValue)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div ref={chartRef}>
                                    {fetchingProcurementAnalyticsGraph ? (
                                        <Loader />
                                    ) : (
                                        <div>
                                            {procurementGraphData?.length === 0 &&
                                            procurementGraphDataTrends?.length === 0 ? (
                                                <div className="w-full h-[450px] flex justify-center items-center flex-col">
                                                    <img
                                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1701863824/graph_s30b7n.svg"
                                                        alt="no data"
                                                    />
                                                    <p className="text-gm-40 text-xs font-rocGroteskMedium mt-[19px]">
                                                        No data to show yet{" "}
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="mt-7">
                                                    <ReactECharts option={options} />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-[35%] bg-white shadow-faintShadow  rounded-[12px] p-6">
                            <div className="flex mt-2 items-center pb-3 border-b justify-between">
                                <div className="flex items-center space-x-1">
                                    <div className="">
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1701795190/Group_55864_mbxosg.svg"
                                            alt="cycle-icon"
                                        />
                                    </div>
                                    <p className="font-rocGroteskBold text-slate-800 text-base">
                                        Cycle time insights
                                    </p>
                                </div>
                            </div>
                            {!fetchingProcurementAnalyticsInsight ? (
                                <>
                                    {insights?.totalWorkFLowRuns === 0 &&
                                    insights?.totalWorkFlows === 0 ? (
                                        <div className="h-[450px] flex items-center justify-center flex-col">
                                            <img
                                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702157493/no_report_pf7dd2.svg"
                                                alt="no data"
                                            />
                                            <p className="text-gm-40 text-xs font-rocGroteskMedium mt-[22px]">
                                                No report generated yet
                                            </p>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="mt-5">
                                                <div className="mb-3.5">
                                                    <p className="text-slate-400 text-xs font-rocGroteskMedium mb-1.5">
                                                        Total workflows
                                                    </p>
                                                    <p className="text-slate-600 text-sm font-rocGroteskMedium">
                                                        {insights?.totalWorkFlows}
                                                    </p>
                                                </div>
                                                <div className="">
                                                    <p className="text-slate-400 text-xs font-rocGroteskMedium mb-1.5">
                                                        Total workflow runs
                                                    </p>
                                                    <p className="text-slate-600 text-sm font-rocGroteskMedium">
                                                        {insights?.totalWorkFLowRuns} workflow runs
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mt-6">
                                                <div className="flex items-center space-x-2 pb-2 border-b-2 border-slate-200 w-fit">
                                                    <div className="bg-p-25 p-1 rounded-full h-[20px] w-[20px] flex justify-center items-center">
                                                        <i className="ri-lightbulb-flash-fill text-p-50 text-xs "></i>
                                                    </div>
                                                    <div>
                                                        <p className="text-slate-600 text-sm font-rocGroteskMedium">
                                                            Efficiency report
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <ul className="text-slate-500 font-rocGroteskMedium text-xs list-disc gap-1">
                                                        {insights?.insights?.map((content, idx) => (
                                                            <li className="mb-1" key={idx}>
                                                                {content}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="w-fit mt-6">
                                                    <PDFDownloadLink
                                                        document={
                                                            <ProcurementAnalysisPdf
                                                            year={
                                                                changeValue !== "custom"
                                                                    ? `${startDateRange} - ${endDateRange}`
                                                                    : `${moment(startDate).format("MMMM YYYY")} - ${moment(
                                                                          endDate
                                                                      ).format("MMMM YYYY")}`
                                                            }
                                                                month={month}
                                                                chartName={"Procurement Analysis"}
                                                                businessLogo={
                                                                    profile?.profile?.avatar
                                                                }
                                                                chart={chartImage || null}
                                                                cycleInsightsShow={true}
                                                                procurementAnalysisShow={false}
                                                                filterDuration={convertDurationLabel(
                                                                    changeValue
                                                                )}
                                                                percentageRequisitionValue={Math.abs(
                                                                    procurementData
                                                                        ?.averageTimeOfRequsitionData
                                                                        ?.percentageChanges
                                                                )}
                                                                percentageTeamValue={Math.abs(
                                                                    procurementData
                                                                        ?.averageTeamEfficeincyData
                                                                        ?.percentageChanges
                                                                )}
                                                                percentageGoodsValue={Math.abs(
                                                                    procurementData
                                                                        ?.goodsDeliveryTimelineAnalytics
                                                                        ?.percentageChanges
                                                                )}
                                                                percentagePOApprovalValue={Math.abs(
                                                                    procurementData
                                                                        ?.purchaseOrderApprovalAnalytics
                                                                        ?.percentageChanges
                                                                )}
                                                                increaseRequisition={
                                                                    procurementData
                                                                        ?.averageTimeOfRequsitionData
                                                                        ?.percentageChanges > 0
                                                                }
                                                                increaseTeam={
                                                                    procurementData
                                                                        ?.averageTeamEfficeincyData
                                                                        ?.percentageChanges > 0
                                                                }
                                                                increasePOApproval={
                                                                    procurementData
                                                                        ?.purchaseOrderApprovalAnalytics
                                                                        ?.percentageChanges > 0
                                                                }
                                                                increaseGoods={
                                                                    procurementData
                                                                        ?.goodsDeliveryTimelineAnalytics
                                                                        ?.percentageChanges > 0
                                                                }
                                                                poApprovalTime={
                                                                    procurementData
                                                                        ?.purchaseOrderApprovalAnalytics
                                                                        ?.currentValue
                                                                }
                                                                goodsTime={
                                                                    procurementData
                                                                        ?.goodsDeliveryTimelineAnalytics
                                                                        ?.currentValue
                                                                }
                                                                teamTime={
                                                                    procurementData
                                                                        ?.averageTeamEfficeincyData
                                                                        ?.currentValue
                                                                }
                                                                requisitionTime={
                                                                    procurementData
                                                                        ?.averageTimeOfRequsitionData
                                                                        ?.currentValue
                                                                }
                                                                totalWorkflow={
                                                                    insights?.totalWorkFlows
                                                                        ? insights?.totalWorkFlows
                                                                        : 0
                                                                }
                                                                totalWorkflowRuns={
                                                                    insights?.totalWorkFLowRuns
                                                                        ? insights?.totalWorkFLowRuns
                                                                        : 0
                                                                }
                                                                insights={insights?.insights}
                                                            />
                                                        }
                                                        fileName={"cycle_insights.pdf"}
                                                    >
                                                        {({ loading }) => (
                                                            <>
                                                                {loading ? (
                                                                    <Button
                                                                        isLoading={true}
                                                                        btnClassname={
                                                                            "!bg-p-25 !text-p-70 "
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <Button
                                                                        btnClassname={
                                                                            "!bg-p-25 !text-p-70 "
                                                                        }
                                                                        btnText={
                                                                            "Download full report"
                                                                        }
                                                                        icon={
                                                                            <i className="ri-download-cloud-2-line"></i>
                                                                        }
                                                                        permissions={getEditPermissionList(
                                                                            "analytics"
                                                                        )}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    </PDFDownloadLink>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : (
                                <Loader />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {shareModal && (
                <ShareModal openModal={shareModal} closeModal={() => setShareModal(false)} />
            )}
        </PageFrame>
    );
};
export default ProcurementCycle;
