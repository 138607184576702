export function convertToTime(hours, minutes) {
    if (Number.isInteger(hours) && Number.isInteger(minutes) && hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
        let period = (hours < 12) ? 'am' : 'pm';
        let formattedHours = (hours % 12 === 0) ? 12 : hours % 12;

        return `${formattedHours}:${(minutes < 10 ? '0' : '')}${minutes} ${period}`;
    } else {
        return 'Invalid input: Hours should be between 0 and 23, and minutes should be between 0 and 59.';
    }
}
