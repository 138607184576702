import React, { useEffect, useState } from "react";
import PrivateRoutes from "components/common/Routes/PrivateRoute";
import PublicRoutes from "components/common/Routes/PublicRoute";
import NoMatchPage from "pages/Ldb/404Page";

// Private Pages
import Copilot from "pages/Ldb/Copilot";
import Dashboard from "pages/Ldb/Dashboard";
import DashboardHome from "pages/Ldb/Home";
import PurchaseOrder from "pages/Ldb/Orders/PurchaseOrder";
import PurchaseOrderDetail from "pages/Ldb/Orders/PurchaseOrder/PurchaseOrderDetails";
import Quotes from "pages/Ldb/Orders/Quotes";
import QuoteVendorDetails from "pages/Ldb/Orders/Quotes/QuoteVendorDetails";
import OrderList from "pages/Ldb/Orders/Rfqs/OrderList";
import OrderDetails from "pages/Ldb/Orders/Rfqs/OrderDetails";
import RfqDetails from "pages/Ldb/Orders/Rfqs/RfqDetails";
import Shipment from "pages/Ldb/Shipment";
import ShipmentDetails from "pages/Ldb/Shipment/ShipmentDetails";
import Inventory from "pages/Ldb/Inventory/Product";
import AddProduct from "pages/Ldb/Inventory/Product/AddProduct";
import EditProduct from "pages/Ldb/Inventory/Product/EditProduct";
import TeamManagement from "pages/Ldb/TeamManagement";
import AddVendor from "pages/Ldb/VendorManagement/AddVendor";
import VendorAnalytics from "pages/Ldb/VendorManagement/VendorAnalytics";
import VendorDetails from "pages/Ldb/VendorManagement/VendorDetails";
import WorkFlow from "pages/Ldb/Workflow";
import WorkFlowRun from "pages/Ldb/Workflow/WorkFlowRun";
import WorkflowGraph from "pages/Ldb/Workflow/WorkflowGraph";
import Tasks from "pages/Ldb/Task";
import VendorList from "pages/Ldb/VendorManagement/VendorList";
import EditPurchaseOrder from "pages/Ldb/Orders/PurchaseOrder/EditOrders";
import CreatePurchaseOrder from "pages/Ldb/Orders/PurchaseOrder/CreateOrders";
import PurchaseOrderAnalytics from "pages/Ldb/BusinessAnalytics/PurchaseOrder";
import Settings from "pages/Ldb/Settings";
import ProcurementCycle from "pages/Ldb/BusinessAnalytics/ProcurementCycle";
import AnalyticsPurchaseOrder from "pages/Ldb/BusinessAnalytics/PurchaseOrder";
import SpendAnalysis from "pages/Ldb/BusinessAnalytics/SpendAnalysis";
import StockControl from "pages/Ldb/Inventory/StockControl/index";
import ProductDetailsPage from "pages/Ldb/Inventory/Product/ProductDetails";
import StockCountHistory from "pages/Ldb/Inventory/StockControl/StockCountHistory";
import OrderStock from "pages/Ldb/Inventory/StockControl/RecordStock";
import NewStockTransfer from "pages/Ldb/Inventory/StockControl/NewStockTransfer";
import WareHouse from "pages/Ldb/Inventory/Warehouse";
import AddWareHouse from "pages/Ldb/Inventory/Warehouse/AddWarehouse";
import EditWareHouse from "pages/Ldb/Inventory/Warehouse/EditWarehouse";
import WarehouseSettings from "pages/Ldb/Inventory/Warehouse/Settings";
import WarehousePreview from "pages/Ldb/Inventory/Warehouse/WarehouseDetails";
import EditStock from "pages/Ldb/Inventory/StockControl/EditStock";
import StockDetail from "./pages/Ldb/Inventory/StockControl/StockDetail";
import PreviewTransferStock from "pages/Ldb/Inventory/StockControl/PreviewTransferStock";
import NewStockAdjustment from "pages/Ldb/Inventory/StockControl/NewStockAdjustment";
import StockAdjustmentDetails from "pages/Ldb/Inventory/StockControl/StockAdjustmentDetails";
import RfqList from "pages/Ldb/Orders/Rfqs/rfqList";
import RfqCreateBlank from "pages/Ldb/Orders/Rfqs/CreateRfqBlanks";
import RfqSettings from "pages/Ldb/Orders/Rfqs/Settings";

// end private pages

// Public Pages
import Home from "pages/Landing/Home";
import BookADemo from "pages/Landing/BookADemo";
import Pricing from "pages/Landing/Pricing";
import Terms from "pages/Landing/Terms";
import Policy from "pages/Landing/Policy";
import AuthSteps from "pages/Ldb/Auth/AuthSteps";
import Login from "pages/Ldb/Auth/Login";
import Signup from "pages/Ldb/Auth/Signup";
import ForgotPassword from "pages/Ldb/Auth/ForgotPassword";
import ResetPassword from "pages/Ldb/Auth/ResetPassword";
import SignupSuccess from "pages/Ldb/Auth/SignupSuccess";
import NewOnboard from "pages/Ldb/Onboarding/newOnboard";
import AcctVerification from "pages/Ldb/Auth/AcctVerification";
import ChangeVerificationEmail from "pages/Ldb/Auth/ChangeVerificationEmail";
import GoogleAuth from "pages/Ldb/Auth/GoogleAuth";
import useGetUserProfile from "hooks/useGetUserProfile";
import RecordSuppliedStock from "pages/Ldb/Inventory/StockControl/RecordSuppliedStock";
import SuppliedStockDetails from "pages/Ldb/Inventory/StockControl/SuppliedStockDetails";
import EditSuppliedStock from "pages/Ldb/Inventory/StockControl/EditSuppliedStock";
import GenerateWithAI from "components/views/Ldb/Dashboard/Orders/CreateRfqs/GenerateWithAI";

const Routes = () => {
    const [profile] = useGetUserProfile();
    const [routes, setRoutes] = useState<{ [key: string]: any }[]>([]);

    useEffect(() => {
        const allRoutes = [
            {
                path: "/",
                element: <Home />,
            },

            {
                path: "/book-a-demo",
                element: <BookADemo />,
            },
            {
                path: "/pricing",
                element: <Pricing />,
            },
            {
                path: "/terms",
                element: <Terms />,
            },

            {
                path: "/policy",
                element: <Policy />,
            },

            {
                path: "/auth-steps",
                element: (
                    <PrivateRoutes>
                        <AuthSteps />
                    </PrivateRoutes>
                ),
            },
            {
                path: "/login",
                element: (
                    <PublicRoutes>
                        <Login />
                    </PublicRoutes>
                ),
            },
            {
                path: "/register",
                element: (
                    <PublicRoutes>
                        <Signup />
                    </PublicRoutes>
                ),
            },

            {
                path: "/forgot-password",
                element: (
                    <PublicRoutes>
                        <ForgotPassword />
                    </PublicRoutes>
                ),
            },
            {
                path: "/reset-password",
                element: (
                    <PublicRoutes>
                        <ResetPassword />
                    </PublicRoutes>
                ),
            },
            {
                path: "/registration-success/:token",
                element: (
                    <PublicRoutes>
                        <SignupSuccess />
                    </PublicRoutes>
                ),
            },
            {
                path: "/new-onboard",
                element: (
                    <PrivateRoutes>
                        <NewOnboard />
                    </PrivateRoutes>
                ),
            },
            {
                path: "/verify",
                element: (
                    <PublicRoutes>
                        <AcctVerification />
                    </PublicRoutes>
                ),
            },
            {
                path: "/change-email/:token",
                element: (
                    <PublicRoutes>
                        <ChangeVerificationEmail />
                    </PublicRoutes>
                ),
            },

            {
                path: "/google-auth",
                element: (
                    <PublicRoutes>
                        <GoogleAuth />
                    </PublicRoutes>
                ),
            },
            {
                path: "/dashboard",
                element: <Dashboard />,
                children: [
                    {
                        index: true,
                        element: <DashboardHome />,
                        permissions: [],
                    },
                    {
                        path: "workflow",
                        element: <WorkFlow />,
                        permissions: ["workflow_view", "workflow_edit", "all_access"],
                    },
                    {
                        path: "workflow/run/:workflowId",
                        element: <WorkFlowRun />,
                        permissions: ["workflow_view", "workflow_edit", "all_access"],
                    },
                    {
                        path: "workflow/:workflowId",
                        element: <WorkflowGraph />,
                        permissions: ["workflow_view", "workflow_edit", "all_access"],
                    },
                    {
                        path: "workflow/template",
                        element: <WorkflowGraph />,
                        permissions: ["workflow_view", "workflow_edit", "all_access"],
                    },
                    {
                        path: "task/:id",
                        element: <Tasks />,
                        permissions: [],
                    },
                    {
                        path: "suppliers",
                        element: <VendorList />,
                        permissions: ["supplier_view", "supplier_edit", "all_access"],
                    },
                    {
                        path: "suppliers/add-vendor",
                        element: <AddVendor />,
                        permissions: ["supplier_edit", "all_access"],
                    },
                    {
                        path: "supplier/:vendorId",
                        element: <VendorDetails />,
                        permissions: ["supplier_view", "supplier_edit", "all_access"],
                    },
                    {
                        path: "suppliers/analytics",
                        element: <VendorAnalytics />,
                        permissions: ["supplier_view", "supplier_edit", "all_access"],
                    },
                    {
                        path: "shipment",
                        element: <Shipment />,
                        permissions: [
                            "operations_edit",
                            "shipment_view",
                            "shipment_edit",
                            "all_access",
                        ],
                    },
                    {
                        path: "shipment/:shipmentId",
                        element: <ShipmentDetails />,
                        permissions: [
                            "operations_edit",
                            "shipment_view",
                            "shipment_edit",
                            "all_access",
                        ],
                    },
                    {
                        path: "inventory",
                        element: <Inventory />,
                        permissions: ["inventory_view", "inventory_edit", "all_access"],
                    },
                    {
                        path: "inventory/add-product",
                        element: <AddProduct />,
                        permissions: ["inventory_edit", "all_access"],
                    },
                    {
                        path: "inventory/edit-product/:id",
                        element: <EditProduct />,
                        permissions: ["inventory_edit", "all_access"],
                    },
                    {
                        path: "inventory/product/:id",
                        element: <ProductDetailsPage />,
                        permissions: ["inventory_view", "inventory_edit", "all_access"],
                    },
                    {
                        path: "inventory/stock-control",
                        element: <StockControl />,
                        permissions: ["inventory_view", "inventory_edit", "all_access"],
                    },
                    {
                        path: "inventory/stock-control/:id",
                        element: <StockDetail />,
                        permissions: ["inventory_view", "inventory_edit", "all_access"],
                    },
                    {
                        path: "inventory/stock-control/new-transfer",
                        element: <NewStockTransfer />,
                        permissions: ["inventory_edit", "all_access"],
                    },
                    {
                        path: "inventory/stock-control/transfer-stock/:id",
                        element: <PreviewTransferStock />,
                        permissions: ["inventory_view", "inventory_edit", "all_access"],
                    },
                    {
                        path: `inventory/order-stock`,
                        element: <OrderStock />,
                        permissions: ["inventory_edit", "all_access"],
                    },
                    {
                        path: `inventory/supplied-stock`,
                        element: <RecordSuppliedStock />,
                        permissions: ["inventory_edit", "all_access"],
                    },
                    {
                        path: `inventory/edit-supplied-stock/:id`,
                        element: <EditSuppliedStock />,
                        permissions: ["inventory_edit", "all_access"],
                    },
                    {
                        path: `inventory/supplied-stock/:id`,
                        element: <SuppliedStockDetails />,
                        permissions: ["inventory_edit", "all_access"],
                    },
                    {
                        path: "inventory/stock-count/:inventoryId/warehouse/:warehouseId",
                        element: <StockCountHistory />,
                        permissions: ["inventory_view", "inventory_edit", "all_access"],
                    },
                    {
                        path: "inventory/warehouse",
                        element: <WareHouse />,
                        permissions: ["inventory_view", "inventory_edit", "all_access"],
                    },
                    {
                        path: "inventory/add-warehouse",
                        element: <AddWareHouse />,
                        permissions: ["inventory_edit", "all_access"],
                    },
                    {
                        path: "inventory/warehouse/:id/settings",
                        element: <WarehouseSettings />,
                        permissions: ["inventory_edit", "all_access"],
                    },
                    {
                        path: "inventory/edit-warehouse/:id",
                        element: <EditWareHouse />,
                        permissions: ["inventory_edit", "all_access"],
                    },
                    {
                        path: "inventory/warehouse/:id",
                        element: <WarehousePreview />,
                        permissions: ["inventory_view", "inventory_edit", "all_access"],
                    },
                    {
                        path: "inventory/stock-control/edit-stock/:id",
                        element: <EditStock />,
                        permissions: ["inventory_edit", "all_access"],
                    },
                    {
                        path: "inventory/stock-control/stock-adjustment",
                        element: <NewStockAdjustment />,
                        permissions: ["inventory_edit", "all_access"],
                    },
                    {
                        path: "inventory/stock-control/stock-adjustment/:id",
                        element: <StockAdjustmentDetails />,
                        permissions: ["inventory_view", "inventory_edit", "all_access"],
                    },

                    {
                        path: "orders",
                        element: <OrderList />,
                        permissions: [],
                    },
                    {
                        path: "co-pilot",
                        element: <Copilot />,
                        permissions: [],
                    },
                    {
                        path: "quotes",
                        element: <Quotes />,
                        permissions: [],
                    },
                    {
                        path: "order/:orderId",
                        element: <OrderDetails />,
                        permissions: [],
                    },
                    {
                        path: "quotes/:quoteId",
                        element: <QuoteVendorDetails />,
                        permissions: [],
                    },
                    {
                        path: "purchase-orders",
                        element: <PurchaseOrder />,
                        permissions: ["purchase_order_view", "purchase_order_edit", "all_access"],
                    },
                    {
                        path: "purchase-orders/:poId",
                        element: <PurchaseOrderDetail />,
                        permissions: ["purchase_order_view", "purchase_order_edit", "all_access"],
                    },
                    {
                        path: "purchase-orders/edit",
                        element: <EditPurchaseOrder />,
                        permissions: ["purchase_order_edit", "all_access"],
                    },
                    {
                        path: "purchase-orders/create",
                        element: <CreatePurchaseOrder />,
                        permissions: ["purchase_order_view", "purchase_order_edit", "all_access"],
                    },

                    {
                        path: "rfqs",
                        element: <RfqList />,
                        permissions: [],
                    },

                    {
                        path: "rfqs/create",
                        element: <RfqCreateBlank />,
                        permissions: [],
                    },
                    {
                        path: "rfqs/settings",
                        element: <RfqSettings />,
                        permissions: [],
                    },
                    {
                        path: "rfq/:rfqId",
                        element: <RfqDetails />,
                        permissions: [],
                    },
                    {
                        path: "rfqs/creates/ai",
                        element: <GenerateWithAI />,
                        permissions: [],
                    },
                    {
                        path: "team-management",
                        element: <TeamManagement />,
                        permissions: ["team_view", "team_edit", "all_access"],
                    },
                    {
                        path: "analytics/purchase-orders",
                        element: <PurchaseOrderAnalytics />,
                        permissions: ["analytics_view", "analytics_edit", "all_access"],
                    },
                    {
                        path: "analytics/procurement-cycle",
                        element: <ProcurementCycle />,
                        permissions: ["analytics_view", "analytics_edit", "all_access"],
                    },
                    {
                        path: "analytics/spend-analysis",
                        element: <SpendAnalysis />,
                        permissions: ["analytics_view", "analytics_edit", "all_access"],
                    },
                    {
                        path: "analytics/purchase-orders",
                        element: <AnalyticsPurchaseOrder />,
                        permissions: ["analytics_view", "analytics_edit", "all_access"],
                    },
                    {
                        path: "analytics/procurement-cycle",
                        element: <ProcurementCycle />,
                        permissions: ["analytics_view", "analytics_edit", "all_access"],
                    },
                    {
                        path: "settings/:id",
                        element: <Settings />,
                        permissions: [],
                    },
                ],
            },

            {
                path: "*",
                element: <NoMatchPage />,
            },
        ];

        const allowedUserRoutes = allRoutes?.map((route) => {
            if (route.children) {
                route.children = route.children?.filter((childrenRoute) => {
                    const allowedRoutes =
                        childrenRoute?.permissions?.length === 0 ||
                        childrenRoute?.permissions?.some((permission) =>
                            profile?.permission?.includes(permission)
                        );

                    return allowedRoutes;
                });
            }

            return route;
        });
        setRoutes(allowedUserRoutes);
    }, [profile]);

    return routes;
};

export default Routes;
