import CircularProgressBar from "components/common/CircledProgressBar";
import CustomTable from "components/common/CustomTable";
import Rating from "components/common/Rating";
import VendorAvatar from "components/common/VendorAvatar";
import React from "react";

const Performance = () => {
    const tableHeader = [
        { title: "Team members (12)", widthClass: "w-[30%]" },
        { title: "Review date", widthClass: "w-[25%]" },
        { title: "Rating summary", widthClass: "w-[20%]" },
        { title: "", widthClass: "w-[20%]" },
    ];

    const tableBody = [1, 1, 1, 1]?.map((vendor, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm text-[#0F172A] font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-3">
                        <VendorAvatar imageSrc="" name="" size={32} />
                        <span>Sarah Jrue</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3  flex items-center h-full border-slate-100 text-sm text-[#0F172A ] font-rocGroteskMedium`}
                >
                    Jul. 10, 2023; 3:45pm
                </div>
            ),
        },
        {
            content: (
                <div className={`py-3 pr-3 flex items-center h-full border-slate-100  `}>
                    <Rating ratingNo={3} totalRating={5} size="lg" />
                </div>
            ),
        },
        {
            content: (
                <div className={`py-3 pr-3  flex items-center h-full border-slate-100 text-sm `}>
                    <div className="flex text-[#002A61] bg-[#F4F5F7] py-[5px] px-[12px] whitespace-nowrap rounded space-x-2 text-sm font-rocGroteskMedium">
                        <p>View review</p>
                        <i className="ri-arrow-right-up-line"></i>
                    </div>
                </div>
            ),
            widthClass: "border-l",
        },
    ]);
    return (
        <div className="p-6">
            <div className="p-6 flex items-center bg-[white] space-x-2 rounded-lg shadow-boxShadow-8">
                <div className="flex w-full flex-col justify-center items-center">
                    <div className="mb-3">
                        <CircularProgressBar progress={30} radius={50} strokeWidth={10} />
                    </div>

                    <Rating ratingNo={3.5} size="lg" />
                    <p className="text-lg text-center mt-4 text-[#333333] font-rocGroteskMedium">
                        Shopify, Order fulfillment Centre Inc.
                    </p>
                    <div className="flex justify-center bg-[blue] mt-2 mb-6">
                        <div className="relative">
                            <div className="h-[34px] w-[34px] border-[1.5px] border-white bg-green-500 rounded-full absolute -left-[43px]"></div>
                            <div className="h-[34px] w-[34px] border-[1.5px] border-white bg-yellow-500 rounded-full absolute -left-[30px]"></div>
                            <div className="h-[34px] w-[34px] border-[1.5px] border-white bg-red-500 rounded-full absolute -left-[15px]"></div>
                            <div className="h-[34px] w-[34px] border-[1.5px] border-white flex justify-center items-center bg-slate-700 rounded-full absolute -left-[2px]">
                                <p className="text-white text-[10px] font-rocGroteskBold text-center">
                                    +9
                                </p>
                            </div>
                        </div>
                    </div>
                    <p className="text-sm mt-4 font-rocGroteskMedium  text-center text-[#64748B]">
                        12 members of your team reviewed this supplier{" "}
                    </p>
                </div>
                <div className="w-full">
                    <div className="text-slate-900 text-sm py-2 border-b border-[#F1F5F9] font-rocGroteskMedium flex justify-between">
                        <p>Criteria</p>
                        <p>Av. Rating</p>
                    </div>
                    <div className="text-[#475569] text-sm py-3 border-b border-[#F1F5F9] font-rocGroteskMedium flex justify-between">
                        <p>Pricing </p>
                        <p>3.5 stars</p>
                    </div>
                    <div className="text-[#475569] text-sm py-3 border-b border-[#F1F5F9] font-rocGroteskMedium flex justify-between">
                        <p>Delivery timeliness </p>
                        <p>3 stars</p>
                    </div>
                    <div className="text-[#475569] text-sm py-3 border-b border-[#F1F5F9] font-rocGroteskMedium flex justify-between">
                        <p>Product/service quality</p>
                        <p>3.5 stars</p>
                    </div>
                    <div className="text-[#475569] text-sm py-3 border-b border-[#F1F5F9] font-rocGroteskMedium flex justify-between">
                        <p>Adherence to service terms</p>
                        <p>3stars</p>
                    </div>
                </div>
            </div>
            <div className="mt-6 bg-[#F9F2F8] p-6 rounded-[8px] space-x-4 flex items-center">
                <div className="h-[48px] w-[48px] rounded-full border-[3px] border-red-600 flex justify-center items-center">
                    <p className="text-center flex items-center h-full text-[#333333] font-rocGroteskMedium">
                        253
                    </p>
                </div>
                <p className="text-sm font-rocGroteskMedium text-slate-800">
                    Number of times that this supplier has breached their lead time agreements with
                    you
                </p>
            </div>
            <div className="mt-6">
                <h3 className="text-xl text-[#333333] font-rocGroteskMedium mb-3">Team review</h3>
                <div className="mt-4 w-full">
                    <div className="max-lg:hidden">
                        <CustomTable
                            tableBody={tableBody}
                            tableHeader={tableHeader}
                            isCellBordered={false}
                            headerContainerClass="!bg-[#F8FAFC] !rounded-[6px] !border-[#F1F5F9] !"
                            bodyItemClass="hover:border-slate-100 hover:bg-slate-50 "
                            headerItemClass="font-rocGroteskMedium !text-[#334155]"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Performance;
