import React, { useEffect, useState } from "react";
import Button from "components/common/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import FromWarehouse from "components/views/Ldb/Dashboard/Inventory/StockControl/TransferStockMethods/FromWarehouse";
import FromVendor from "components/views/Ldb/Dashboard/Inventory/StockControl/TransferStockMethods/FromVendor";
import ModalContainer from "components/common/ModalContainer";
import Loader from "components/common/Loader";
import { StockTransferProductType, StockTransferType } from "types/StockTransferType";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions, orderActions } from "redux/Ldb/actions";
import useGetUserProfile from "hooks/useGetUserProfile";
import moment from "moment";
import { truncate } from "lodash";
import { getEditPermissionList } from "helpers/getEditPermissionList";

const NewStockTransfer = () => {
    const [profile] = useGetUserProfile();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [transferModal, setTransferModal] = useState<boolean>(false);
    const [restockMethod, setRestockMethod] = useState<string>("vendor");
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [warehousesData, setWarehousesData] = useState<any>();
    const [transferModalLoading, setTransferModalLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<any>();
    const [productData, setProductData] = useState<{ [key: string]: any }[]>(new Array(1).fill({}));
    const [transferData, setTransferData] = useState<{ [key: string]: any }>();
    const [warehouse, setWarehouse] = useState<{ [key: string]: any }>({});
    const [destinationWarehouse, setDestinationWarehouse] = useState<{ [key: string]: any }>();
    const [productList, setProductList] = useState<{ [key: string]: any }>([]);
    const [successFromVendorModal, setSuccessFromVendorModal] = useState<boolean>(false);
    const [sentRfqs, setSentRfqa] = useState<{ [key: string]: any }[]>([]);
    const [stockData, setStockData] = useState<StockTransferType>({
        contactPerson: "",
        sourceLocation: "",
        destinationLocation: "",
        assignedTo: "",
        note: "",
    });
    const [productInfo, setProductInfo] = useState<StockTransferProductType[]>([
        {
            productName: "",
            dateScheduled: "",
            qty: "",
            cost: "",
            totalCost: "",
        },
    ]);
    const [param] = useSearchParams();
    const method = param.get("method");
    const { sendingRfqs, sendRfqsSuccess } = useAppSelector((state) => state.order);

    const {
        transferringStocks,
        transferStocksSuccess,
        transferStocksFailure,
        fetchedStockListSuccess,
        fetchedWarehousesSuccess,
    } = useAppSelector((state) => state.inventory);

    const handleChangeWarehouse = (e) => {
        const { name, value } = e.target;
        setWarehouse((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleDeleteProduct = (idx) => {
        if (productData?.length > 1) {
            const copyProductData = [...productData];
            copyProductData.splice(idx, 1);
            setProductData(copyProductData);
        }
    };

    const handleAddProduct = () => {
        setProductData([...productData, {}]);
    };

    const handleTransferData = (e) => {
        const { name, value } = e.target;
        setTransferData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleChange = (name: string, value: any) => {
        setStockData((prev) => ({ ...prev, [name]: value }));
    };

    function handleMultipleProductChange(name: string, value: any, idx: number) {
        const newArr = [...productInfo];
        newArr[idx][name] = value;
        setProductInfo(newArr);
    }

    const handleDeleteRow = (idx: number) => {
        const newArr = [...productInfo];
        newArr.splice(idx, 1);
        setProductInfo(newArr);
    };

    const handleSendRfqs = () => {
        const body: { [key: string]: any } = {
            itemsAndVendors: productInfo
                ?.map((service) => ({
                    item: service?.productName?.split("-")[1],
                    quantity: service?.qty,
                    vendors: JSON.parse(service?.productName?.split("-")[2]),
                    inventoryId: service?.productName?.split("-")[0],
                }))
                ?.filter(
                    (value) =>
                        value.item && value.quantity && value.vendors && value.vendors?.length > 0
                ),
            orderType: "supply",
            customerDetails: {
                email: profile?.email,
                phone: {
                    mobile: profile?.mobile,
                    rawMobile: profile?.rawMobile,
                    mobileExtension: profile?.mobileExtension,
                },
                country: profile?.profile?.country,
                firstNane: profile?.fullName?.split(" ")[0],
                lastName: profile?.fullName?.split(" ")[1],
                companyName: profile?.profile?.businessName,
            },
        };
        if (Boolean(stockData?.note)) {
            body.specifications = stockData?.note;
        }
        dispatch(orderActions.sendRfqs(body));
    };

    const handleAddNewRow = () => {
        const newArr = [...productInfo];
        newArr.push({
            productName: "",
            dateScheduled: "",
            qty: "",
            cost: "",
            totalCost: "",
        });
        setProductInfo(newArr);
    };

    useEffect(() => {
        const copyProductData = [...productData];
        const formatProductData = copyProductData?.map((item) => {
            const { totalCost, ...rest } = item;
            return rest;
        });
        setTransferData((prev) => ({
            ...prev,
            data: formatProductData,
            warehouse: warehouse,
        }));
    }, [productData, warehouse]);

    const getWarehouseName = (warehouseId) => {
        const matchWarehouse = warehousesData?.find((item) => item._id === warehouseId);
        return matchWarehouse ? matchWarehouse.warehouseName : "Unknown Warehouse";
    };
    const getProductName = (inventoryId) => {
        const productName = productList?.find((item) => item._id === inventoryId);
        return productName && productName?.inventory?.productName;
    };

    const handleDateChange = (idx, date) => {
        const copyProductData = [...productData];
        copyProductData[idx] = {
            ...copyProductData[idx],
            movementDate: moment(date).toISOString(),
        };
        setProductData(copyProductData);
    };

    const handleProductDataChange = (e, idx) => {
        const { name, value } = e.target;
        const copyProductData = [...productData];
        if (name === "quantity") {
            if (copyProductData[idx]?.movementCost?.amount) {
                copyProductData[idx] = {
                    ...copyProductData[idx],
                    totalCost: copyProductData[idx]?.movementCost?.amount * value,
                };
            }
            copyProductData[idx][name] = value;
        } else if (name === "amount") {
            copyProductData[idx] = {
                ...copyProductData[idx],
                movementCost: {
                    ...copyProductData[idx]?.movementCost,
                    [name]: value,
                    currency: "USD",
                },
            };
            if (copyProductData[idx]?.quantity) {
                copyProductData[idx] = {
                    ...copyProductData[idx],
                    totalCost: copyProductData[idx]?.quantity * value,
                };
            }
        } else if (name === "product") {
            const batchAndInventoryId = value?.split(" ");
            copyProductData[idx] = {
                ...copyProductData[idx],
                inventoryId: batchAndInventoryId[0],
                batchId: batchAndInventoryId[1],
            };
        } else {
            copyProductData[idx][name] = value;
        }

        setProductData(copyProductData);
    };

    const handleTransferStock = () => {
        setTransferModal(false);
        dispatch(inventoryActions.transferStocks(transferData));
    };

    const retryTransferStock = (batchId) => {
        const failedTransfer = transferData?.data?.filter((item) => item?.batchId === batchId);
        const failedFilteredList = productData?.filter((item) => item?.batchId === batchId);
        setTransferData((prev) => ({
            ...prev,
            data: failedTransfer,
        }));
        setProductData(failedFilteredList);
        setSuccessModal(false);
    };
    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouses());
    }, [dispatch]);

    useEffect(() => {
        setTransferModalLoading(transferringStocks);
    }, [transferringStocks]);

    useEffect(() => {
        if (Boolean(transferStocksSuccess)) {
            setTransferModalLoading(false);
            setSuccessModal(true);
        } else {
            if (Boolean(transferStocksFailure)) {
                setTransferModalLoading(false);
            }
        }
    }, [transferStocksSuccess, transferStocksFailure]);
    useEffect(() => {
        if (Boolean(transferStocksSuccess)) {
            setResponse(transferStocksSuccess);
        }
    }, [transferStocksSuccess]);
    useEffect(() => {
        if (Boolean(transferStocksSuccess)) {
            dispatch(inventoryActions.resetTransferringStocks());
        }
    }, [dispatch, transferStocksSuccess]);

    useEffect(() => {
        if (warehouse?.warehouseFromId === warehouse?.warehouseToId) {
            setWarehouse({
                ...warehouse,
                warehouseToId: "",
            });
        }
    }, [warehouse?.warehouseFromId]);

    useEffect(() => {
        if (Boolean(fetchedWarehousesSuccess)) {
            setWarehousesData(fetchedWarehousesSuccess?.warehouses?.data);
        }
    }, [fetchedWarehousesSuccess]);

    useEffect(() => {
        if (Boolean(fetchedStockListSuccess)) {
            setProductList(fetchedStockListSuccess?.stock);
        }
    }, [fetchedStockListSuccess]);

    useEffect(() => {
        if (Boolean(warehouse?.warehouseFromId)) {
            dispatch(inventoryActions.fetchStockList(20, "", warehouse?.warehouseFromId));
        }
    }, [dispatch, warehouse?.warehouseFromId]);

    useEffect(() => {
        if (warehouse?.warehouseFromId) {
            const copyWarehouse = [...warehousesData];
            let filterWarehouse = copyWarehouse?.filter(
                (item) => item?._id !== warehouse?.warehouseFromId
            );
            setDestinationWarehouse(filterWarehouse);
            const copyProductData = [...productData];
            const format = copyProductData?.map((item) => {
                const { inventoryId, batchId, ...rest } = item;
                return rest;
            });
            setProductData(format);
        } else if (warehousesData?.length > 0) {
            setDestinationWarehouse(warehousesData);
        }
    }, [warehouse?.warehouseFromId, warehousesData]);

    const displayTransferStockMethod = (method: string) => {
        switch (method) {
            case "warehouse":
                return (
                    <FromWarehouse
                        productData={productData}
                        handleProductDataChange={handleProductDataChange}
                        handleDateChange={handleDateChange}
                        handleDeleteProduct={handleDeleteProduct}
                        transferData={transferData}
                        handleTransferData={handleTransferData}
                        warehouse={warehouse}
                        handleAddProduct={handleAddProduct}
                        handleChangeWarehouse={handleChangeWarehouse}
                        destinationWarehouse={destinationWarehouse}
                        warehousesData={warehousesData}
                        productList={productList}
                    />
                );

            case "vendor":
                return (
                    <FromVendor
                        productData={productInfo}
                        handleChange={handleChange}
                        stockData={stockData}
                        handleMultipleProductChange={handleMultipleProductChange}
                        handleDeleteRow={handleDeleteRow}
                        handleAddNewRow={handleAddNewRow}
                    />
                );

            default:
                return;
        }
    };

    useEffect(() => {
        if (sendRfqsSuccess) {
            setSentRfqa(sendRfqsSuccess?.quote?.rfqs);
            setStockData({
                contactPerson: "",
                sourceLocation: "",
                destinationLocation: "",
                assignedTo: "",
                note: "",
            });
            setProductInfo([
                {
                    productName: "",
                    dateScheduled: "",
                    qty: "",
                    cost: "",
                    totalCost: "",
                },
            ]);
            setSuccessFromVendorModal(true);
            dispatch(orderActions.resetSendRfqsSuccess());
        }
    }, [sendRfqsSuccess]);

    useEffect(() => {
        if (method && method === "2") {
            setRestockMethod("vendor");
            navigate("/dashboard/inventory/stock-control/new-transfer", { replace: true });
        }
        if (method === "warehouse") {
            setRestockMethod("warehouse");
        }
    }, [method]);

    return (
        <div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    if (restockMethod === "warehouse") {
                        setTransferModal(true);
                    }
                    if (restockMethod === "vendor") {
                        handleSendRfqs();
                    }
                }}
                className="bg-white rounded-lg border border-slate-100"
            >
                <div className="flex items-center justify-between px-8 py-5 border-b border-slate-100 top-[-10px] md:sticky  z-[50] bg-white">
                    <p className="text-base font-rocGroteskMedium">
                        {restockMethod === "warehouse" ? "Transfer stock" : "Reorder stock"}
                    </p>

                    <div className="flex items-center gap-3">
                        <Button
                            btnText="Cancel"
                            btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                            disabled={transferringStocks || sendingRfqs}
                            onClick={() =>
                                restockMethod === "warehouse"
                                    ? navigate("/dashboard/inventory/stock-control?tabId=1")
                                    : navigate("/dashboard/inventory/stock-control")
                            }
                        />
                        <Button
                            btnText={
                                restockMethod === "warehouse" ? "Transfer stock" : "Reorder stock"
                            }
                            type="submit"
                            isLoading={transferringStocks || sendingRfqs}
                            disabled={transferringStocks || sendingRfqs}
                            btnClassname="!px-4 !py-2.5 !w-fit"
                            permissions={getEditPermissionList("inventory")}
                        />
                    </div>
                </div>
                <div className="px-8 py-6 flex gap-[7.7%]">
                    <div className="">
                        <div className="w-full">
                            <div className="mb-6 w-[65%]">
                                <label className="text-base font-rocGroteskMedium"></label>
                                <div className="grid grid-cols-2 gap-4 mt-4">
                                    <div
                                        onClick={() => setRestockMethod("vendor")}
                                        className={`flex items-center gap-2.5 p-4 rounded-md border-[1.5px] border-slate-200 cursor-pointer hover:bg-[#FFF9EA] hover:border-[#FFB903] ${
                                            restockMethod === "vendor" &&
                                            "bg-[#FFF9EA] !border-[#FFB903]"
                                        }`}
                                    >
                                        <i className="ri-box-1-line text-[28px]"></i>
                                        <div className="font-rocGroteskMedium">
                                            <p className="text-sm">From Vendor</p>
                                            <p className="text-sm text-slate-500">
                                                Manage and track a specific type within the
                                                inventory.
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => setRestockMethod("warehouse")}
                                        className={`flex items-center gap-2.5 p-4 rounded-md border-[1.5px] border-slate-200 cursor-pointer hover:bg-[#FFF9EA] hover:border-[#FFB903] ${
                                            restockMethod === "warehouse" &&
                                            "bg-[#FFF9EA] !border-[#FFB903]"
                                        }`}
                                    >
                                        <i className="ri-box-1-line text-[28px]"></i>
                                        <div className="font-rocGroteskMedium">
                                            <p className="text-sm">From Warehouse</p>
                                            <p className="text-sm text-slate-500">
                                                Manage and track a specific type within the
                                                inventory.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {displayTransferStockMethod(restockMethod)}
                        </div>
                    </div>
                </div>
            </form>

            <ModalContainer
                open={transferModal}
                showCloseIcon={false}
                tailwindClassName="w-[42%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative p-6">
                    <div className="flex flex-col items-start text-left">
                        <p className="text-lg mb-3 text-left font-rocGroteskMedium text-g-75">
                            Transfer Stock Request
                        </p>
                        <p className="text-base text-left font-rocGroteskMedium text-slate-500">
                            You've initiated a warehouse transfer. Confirm the details before
                            submitting the request. Once submitted, it will be reviewed for
                            approval.
                        </p>
                    </div>

                    <div className="w-full flex items-center justify-end pt-5">
                        <div className="flex items-center gap-2">
                            <Button
                                btnText={"No, don't tranfer"}
                                type={"button"}
                                btnClassname="!py-3 !px-6 !bg-white !border !border-n-20 !w-fit whitespace-nowrap"
                                onClick={() => {
                                    setTransferModal(false);
                                }}
                            />
                            <Button
                                btnText={"Yes, transfer"}
                                btnClassname={"!py-3 !px-6 !w-fit"}
                                type={"button"}
                                onClick={handleTransferStock}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={transferModalLoading}
                showCloseIcon={false}
                tailwindClassName="w-[42%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="p-10">
                        <div className="flex flex-col items-center">
                            <Loader size={6} color="slate-300" />
                            <p className="text-base text-center font-rocGroteskMedium mt-5">
                                Transferring stocks from warehouses. Please wait
                            </p>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={successModal}
                showCloseIcon={false}
                tailwindClassName="w-[42%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="px-10 pt-8 max-h-[70vh] overflow-y-scroll ">
                        {response?.inventory?.stocksTransferred?.length > 0 && (
                            <div className="flex flex-col items-center">
                                <div className="w-12 h-12 rounded-full bg-[#00DB8F] flex items-center justify-center">
                                    <i className="ri-checkbox-circle-fill text-xl text-white"></i>
                                </div>

                                <p className="text-xl mb-3 text-center mt-4 font-rocGroteskMedium text-g-75">
                                    Stock transfer scheduled{" "}
                                </p>
                                <p className="text-sm text-center font-rocGroteskMedium text-slate-500 max-w-[411px]">
                                    Take a look at the stocks set for transfer, each with its
                                    scheduled date. Check out the details below!{" "}
                                </p>
                                <div className="mt-8 w-full">
                                    <p className="text-base text-left font-rocGroteskMedium">
                                        {response?.inventory?.stocksTransferred?.length} stock(s)
                                        transferred
                                    </p>

                                    {response?.inventory?.stocksTransferred?.map((item, idx) => {
                                        return (
                                            <div
                                                key={idx}
                                                className="w-full py-4 border-b border-n-30 flex items-center justify-between gap-8"
                                            >
                                                <div className="flex items-center gap-4">
                                                    <div className="w-[38px] h-[38px] flex items-center justify-center rounded bg-[#ECFDF5] ">
                                                        <i className="ri-community-fill text-xl text-[#10B981]"></i>
                                                    </div>
                                                    <div className="text-sm font-rocGroteskMedium">
                                                        <p>{item?.reference}</p>
                                                        <p className="text-slate-500 flex items-center gap-1 flex-wrap">
                                                            <span>
                                                                {getWarehouseName(
                                                                    item?.warehouse?.warehouseFromId
                                                                )}
                                                            </span>
                                                            <i className="ri-arrow-right-line text-sm text-slate-500"></i>
                                                            <span>
                                                                {getWarehouseName(
                                                                    item?.warehouse?.warehouseToId
                                                                )}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <Button
                                                    btnText="View"
                                                    btnClassname="!bg-n-20 !px-4 !py-2.5 !w-fit"
                                                    onClick={() =>
                                                        navigate(
                                                            `/dashboard/inventory/stock-control/transfer-stock/${item?._id}`
                                                        )
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}

                        {response?.inventory?.failedBatch?.length > 0 && (
                            <div className="flex flex-col items-center ">
                                {response?.inventory?.stocksTransferred?.length === 0 && (
                                    <>
                                        <div className="flex flex-col items-center">
                                            <div className="w-12 h-12 rounded-full bg-[#DC2626] flex items-center justify-center">
                                                <i className="ri-close-fill text-xl text-white"></i>
                                            </div>
                                        </div>
                                        <p className="text-xl mb-3 text-center mt-4 font-rocGroteskMedium text-g-75">
                                            Stock transfer failed
                                        </p>
                                        <p className="text-sm text-center font-rocGroteskMedium text-slate-500 max-w-[411px]">
                                            Your stock transfer failed as the requested quantity
                                            exceeds available stock in the transfer warehouse.
                                            Please adjust quantities before retrying.
                                        </p>
                                    </>
                                )}

                                <div className="mt-8 w-full">
                                    <p className="text-base text-left font-rocGroteskMedium text-[#FF3D1D]">
                                        {response?.inventory?.failedBatch?.length} stock(s) transfer
                                        failed
                                    </p>

                                    {response?.inventory?.failedBatch?.map((item, idx) => {
                                        return (
                                            <div
                                                key={idx}
                                                className="w-full py-4 border-b border-n-30 flex items-center justify-between gap-8"
                                            >
                                                <div className="flex items-center gap-4">
                                                    <div className="w-[38px] h-[38px] flex items-center justify-center rounded bg-[#FEF2F2] ">
                                                        <i className="ri-close-circle-fill text-xl text-[#EF4444]"></i>
                                                    </div>
                                                    <div className="text-sm font-rocGroteskMedium mb-1">
                                                        <p>{getProductName(item?.inventoryId)}</p>
                                                        <p className="text-slate-500 flex items-center gap-1 flex-wrap">
                                                            <span>
                                                                {getWarehouseName(
                                                                    warehouse?.warehouseFromId
                                                                )}
                                                            </span>
                                                            <i className="ri-arrow-right-line text-sm text-slate-500"></i>
                                                            <span>
                                                                {getWarehouseName(
                                                                    warehouse?.warehouseToId
                                                                )}
                                                            </span>
                                                        </p>
                                                        <p className="flex items-center gap-2 flex-wrap">
                                                            {item?.currentStock && (
                                                                <span
                                                                    className="bg-[#EEF2F9] text-slate-500 rounded px-2 pt-[6px]
                                                                pb-1"
                                                                >
                                                                    Current Stock:
                                                                    {item?.currentStock}
                                                                </span>
                                                            )}
                                                            {item?.quantity && (
                                                                <span
                                                                    className="bg-[#EEF2F9] text-slate-500 rounded px-2 pt-[6px]
                                                                pb-1"
                                                                >
                                                                    Transfer Stock:
                                                                    {item?.quantity}
                                                                </span>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                                <Button
                                                    btnText="Retry"
                                                    icon={
                                                        <i className="ri-restart-line text-gun-metal"></i>
                                                    }
                                                    btnClassname="!bg-[#FFB903] !px-4 !py-2.5 !w-fit"
                                                    btnType="textFirst"
                                                    onClick={() =>
                                                        retryTransferStock(item?.batchId)
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="w-full flex items-center gap-2 px-8 py-4 border-t border-slate-200 ">
                        <div className="w-full">
                            <Button
                                btnText={"Transfer more products"}
                                type={"button"}
                                btnClassname="!bg-tradeally-neutral-20"
                                onClick={() => {
                                    setSuccessModal(false);
                                    setWarehouse([]);
                                    setTransferData(null);
                                    setProductData(new Array(1).fill({}));
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <Button
                                btnText={"Done"}
                                type={"button"}
                                onClick={() => {
                                    navigate("/dashboard/inventory/stock-control?stockId=1");
                                    setSuccessModal(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={successFromVendorModal}
                showCloseIcon={false}
                tailwindClassName="w-[42%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setSuccessFromVendorModal(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="px-10 pt-8">
                        <div className="flex flex-col items-center">
                            <div className="w-12 h-12 rounded-full bg-[#00DB8F] flex items-center justify-center">
                                <i className="ri-checkbox-circle-fill text-xl text-white"></i>
                            </div>

                            <p className="text-xl mb-3 text-center mt-4 font-rocGroteskMedium text-g-75">
                                {sentRfqs?.length} RFQ created and sent
                            </p>
                            <p className="text-sm text-center font-rocGroteskMedium text-slate-500">
                                Here are all the RFQ that were created for this order.
                            </p>
                            <div className="mt-8 w-full max-h-[300px] overflow-y-scroll">
                                {sentRfqs?.map((rfq, idx) => {
                                    return (
                                        <div
                                            key={rfq?._id}
                                            className="w-full py-4 border-b border-n-30 flex items-center justify-between"
                                        >
                                            <div className="flex items-center gap-4">
                                                <div className="w-[38px] h-[38px] flex items-center justify-center rounded bg-[#ECFDF5] ">
                                                    <i className="ri-file-list-3-fill text-xl text-[#04C582]"></i>
                                                </div>
                                                <div className="text-sm font-rocGroteskMedium">
                                                    <p>RFQ-{rfq?.taId}</p>
                                                    <p className="text-slate-500 flex items-center gap-1">
                                                        {truncate(rfq?.inventory?.productName, {
                                                            length: 35,
                                                        })}
                                                    </p>
                                                </div>
                                            </div>
                                            <Button
                                                btnText="View"
                                                btnClassname="!bg-n-20 !px-4 !py-2.5 !w-fit"
                                                onClick={() =>
                                                    navigate(`/dashboard/rfq/${rfq?._id}`)
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex items-center gap-2 px-8 py-4 border-t border-slate-200 mt-10">
                        <div className="w-full">
                            <Button
                                btnText={"Reorder more products"}
                                type={"button"}
                                btnClassname="!bg-tradeally-neutral-20"
                                onClick={() => {
                                    setSuccessFromVendorModal(false);
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <Button
                                btnText={"Done"}
                                type={"button"}
                                onClick={() => {
                                    setSuccessFromVendorModal(false);
                                    navigate("/dashboard/inventory/stock-control");
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default NewStockTransfer;
