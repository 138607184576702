import React, { useCallback, useEffect, useState } from "react";
import Card from "../../../../components/views/Ldb/Dashboard/Inventory/Warehouse/Card";
import PageFrame from "components/layout/PageFrame";
import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import Loader from "components/common/Loader";
import { onScroll } from "helpers";
import ModalContainer from "components/common/ModalContainer";
import { getEditPermissionList } from "helpers/getEditPermissionList";

const WareHouse = () => {
    const limit = 20;
    const [warehouseLimit, setWarehouseLimit] = useState(limit);
    const [warehouseList, setWarehouseList] = useState([]);
    const [loadMoreWarehouse, setLoadMoreWarehouse] = useState(false);
    const [loadedPage, setLoadedPage] = useState(false);
    const [deleteWarehouseShow, setDeleteWarehouseShow] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [warehousePagination, setWarehousePagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {
        fetchingWarehouseList,
        fetchedWarehouseListSuccess,
        fetchedWarehouseListFailure,
        deleteWarehouse,
        deleteWarehouseSuccess,
    } = useAppSelector((state) => state.inventory);

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouseList(warehouseLimit));
    }, [warehouseLimit]);

    const dashboardContent = document.getElementById("dashboardContent");
    const onWarehouseScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                warehousePagination?.current as number,
                warehousePagination?.number_of_pages as number,
                () => {
                    setWarehouseLimit(() => warehouseLimit + limit);
                    setLoadMoreWarehouse(true);
                }
            ),
        [dashboardContent, warehousePagination, warehouseLimit]
    );

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onWarehouseScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onWarehouseScroll);
        };
    }, [dashboardContent, onWarehouseScroll]);

    useEffect(() => {
        if (Boolean(fetchedWarehouseListSuccess)) {
            setWarehouseList(fetchedWarehouseListSuccess?.warehouses?.data);
            setWarehousePagination({
                current: fetchedWarehouseListSuccess?.pagination?.current,
                number_of_pages: fetchedWarehouseListSuccess?.pagination?.number_of_pages,
            });
            setLoadedPage(true);
        }
    }, [fetchedWarehouseListSuccess]);

    useEffect(() => {
        if (Boolean(fetchedWarehouseListFailure)) {
            setLoadedPage(true);
        }
    }, [fetchedWarehouseListFailure]);

    const deleteProductWarehouse = () => {
        dispatch(inventoryActions.deleteWarehouse(deleteId));
    };

    useEffect(() => {
        if (Boolean(deleteWarehouseSuccess)) {
            dispatch(inventoryActions.resetDeleteWarehouse());
            dispatch(inventoryActions.fetchWarehouseList(warehouseLimit));
            setDeleteWarehouseShow(false);
        }
    }, [deleteWarehouseSuccess]);

    return (
        <PageFrame isLoading={!loadedPage}>
            <div className="px-[28px] md:px-8">
                <div className="flex justify-between items-center">
                    <BreadCrumb
                        parentCrumb={"Inventory Management "}
                        parentUrl={"#"}
                        parentClassName="!text-slate-400"
                        childrenCrumb={[
                            {
                                title: "Warehouse Management",
                                path: "/dashboard/inventory/warehouse",
                            },
                        ]}
                        size="large"
                    />
                    {warehouseList?.length > 0 && (
                        <div className="w-fit">
                            <Button
                                disabled={deleteWarehouse}
                                btnText="Add new warehouse"
                                onClick={() => navigate("/dashboard/inventory/add-warehouse")}
                                permissions={getEditPermissionList("inventory")}
                            />
                        </div>
                    )}
                </div>
                {fetchingWarehouseList ? (
                    <div className="mt-10">
                        <Loader />
                    </div>
                ) : (
                    <>
                        {warehouseList?.length > 0 ? (
                            <div className="w-full grid grid-cols-3 gap-4 mt-8">
                                {warehouseList?.map((warehouse) => (
                                    <Card
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(
                                                `/dashboard/inventory/warehouse/${warehouse?._id}`
                                            );
                                        }}
                                        onDelete={() => {
                                            setDeleteWarehouseShow(true);
                                            setDeleteId(warehouse?._id);
                                        }}
                                        onEdit={() =>
                                            navigate(
                                                `/dashboard/inventory/edit-warehouse/${warehouse?._id}`
                                            )
                                        }
                                        key={warehouse?._id}
                                        name={warehouse?.warehouseName}
                                        address={warehouse?.address?.address}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div>
                                <div
                                    className="flex mt-3 max-md:flex-col-reverse max-md:gap-4 max-md:px-6 rounded-[10px] py-[38px] pl-[29px] pr-[70px] shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)] 
                                    relative justify-between h-[264px] bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1706264206/Frame_2613169_t7yp2c.png')] w-full bg-cover bg-center bg-no-repeat"
                                >
                                    <div className="">
                                        <h1 className="text-[30px] max-md:text-2xl leading-[35px] font-rocGroteskBold max-w-[522px]">
                                            Start organizing your inventory effortlessly with our
                                            warehouse management feature.
                                        </h1>
                                        <Button
                                            btnType="textFirst"
                                            btnText={"Add warehouse"}
                                            type="button"
                                            btnClassname="!leading-[24px] !bg-[#042821] !text-white !w-auto mt-[43px] max-md:mt-3 !rounded-[4px] !py-[10px] !px-6"
                                            icon={<i className="ri-arrow-right-line"></i>}
                                            onClick={() =>
                                                navigate("/dashboard/inventory/add-warehouse")
                                            }
                                            permissions={getEditPermissionList("inventory")}
                                        />
                                    </div>
                                </div>

                                <div className="flex items-center justify-center mt-[76px]">
                                    <div>
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1704890969/Tradeally_2.0/empty_state_img_a8twwl.svg"
                                            alt="empty state"
                                            className="w-[268px] h-[235px]"
                                        />
                                        <p className="text-center text-sm text-[#475569] font-rocGroteskMedium mt-5">
                                            You have no warehouse yet
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}

                {deleteWarehouseShow && (
                    <ModalContainer
                        open={deleteWarehouseShow}
                        showCloseIcon={false}
                        modalClassName={"py-10 px-10"}
                        tailwindClassName="w-[600px]"
                        closeModal={() => {
                            setDeleteWarehouseShow(false);
                        }}
                    >
                        <div className="bg-white py-7 px-6  shadow-cardShadow relative rounded-[8px]">
                            <div className="border-[2px] h-[50px] bg-[#FEF2F2] w-[50px] flex items-center justify-center rounded-full border-[#DC2626]">
                                <i className="ri-delete-bin-line text-[#DC2626] text-[26px]"></i>
                            </div>
                            <div className="mb-5 mt-4">
                                <h1 className="text-lg mb-2  font-rocGroteskMedium">
                                    Delete warehouse?
                                </h1>
                                <p className="text-slate-500 font-rocGroteskMedium text-base">
                                    Are you sure you want to delete this warehouse and all its
                                    products? We recommend transferring products first, as this
                                    can't be undone.
                                </p>
                            </div>
                            <div className="flex justify-end items-center ">
                                <div className="flex items-center !whitespace-nowrap space-x-2 ">
                                    <Button
                                        btnText={"No, cancel"}
                                        type={"button"}
                                        btnClassname={"!bg-n-20 !w-fit !text-gm-50"}
                                        disabled={deleteWarehouse}
                                        onClick={() => setDeleteWarehouseShow(false)}
                                    />
                                    <Button
                                        btnClassname={"!py-3 !w-fit"}
                                        onClick={deleteProductWarehouse}
                                        btnText={"Delete warehouse"}
                                        type={"button"}
                                        isLoading={deleteWarehouse}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalContainer>
                )}
            </div>
        </PageFrame>
    );
};

export default WareHouse;
