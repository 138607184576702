import React from "react";
import CustomToast from "components/common/CustomToast";
import { customFieldTypes } from "redux//Ldb/types";
import { toast } from "react-hot-toast";
import { Dispatch } from "redux";
import { customFieldService } from "services/Ldb/customFieldsService";

export const customFieldActions = {
    createCustomField,
    getCustomFields,
    deleteCustomField,
    updateCustomField,
    resetCreateCustomField,
    resetUpdateCustomField,
};

function createCustomField(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(customFieldTypes.CREATE_CUSTOM_FIELD_REQUEST));

        customFieldService.createCustomField(data).then(
            (res) => {
                dispatch(success(customFieldTypes.CREATE_CUSTOM_FIELD_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(customFieldTypes.CREATE_CUSTOM_FIELD_FAILURE, error?.message));
                }
            }
        );
    };
}

function getCustomFields(fieldType: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(customFieldTypes.FETCH_CUSTOM_FIELD_REQUEST));

        customFieldService.getCustomFields(fieldType).then(
            (res) => {
                dispatch(success(customFieldTypes.FETCH_CUSTOM_FIELD_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(customFieldTypes.FETCH_CUSTOM_FIELD_FAILURE, error?.message));
                }
            }
        );
    };
}

function deleteCustomField(id: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(customFieldTypes.DELETE_CUSTOM_FIELD_REQUEST));

        customFieldService.deleteCustomField(id).then(
            (res) => {
                dispatch(success(customFieldTypes.DELETE_CUSTOM_FIELD_SUCCESS, res?.data));
                toast.custom((t) => <CustomToast t={t} message={res?.message} type="success" />);
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(customFieldTypes.DELETE_CUSTOM_FIELD_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateCustomField(id: string, data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(customFieldTypes.UPDATE_CUSTOM_FIELD_REQUEST));

        customFieldService.updateCustomField(id, data).then(
            (res) => {
                dispatch(success(customFieldTypes.UPDATE_CUSTOM_FIELD_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(customFieldTypes.UPDATE_CUSTOM_FIELD_FAILURE, error?.message));
                }
            }
        );
    };
}

function resetCreateCustomField() {
    return (dispatch: Dispatch) => {
        dispatch(success(customFieldTypes.CREATE_CUSTOM_FIELD_SUCCESS, null));
    };
}

function resetUpdateCustomField() {
    return (dispatch: Dispatch) => {
        dispatch(success(customFieldTypes.UPDATE_CUSTOM_FIELD_SUCCESS, null));
    };
}

function request(type: string) {
    return { type: type };
}
function success(type: string, data?: any) {
    return { type: type, payload: data };
}
function failure(type: string, error?: any) {
    return { type: type, payload: error ?? "" };
}
