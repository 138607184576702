import React, {useEffect, useState} from "react";
import AuthFrame from "./AuthFrame";
import TextInput from "components/common/InputField/TextInput";
import Button from "components/common/Button";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "redux/Ldb/actions";
import {useAppDispatch, useAppSelector} from "../../../hooks/storeHooks";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const dispatch = useAppDispatch();
    const {forgotPasswordRequest, forgotPasswordSuccess} = useAppSelector((state) => state.auth);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(authActions.forgotPassword({email}));
    };

    useEffect(() => {
        if (Boolean(forgotPasswordSuccess)) {
            setEmail("");
        }
    }, [forgotPasswordSuccess]);

    return (
        <AuthFrame>
            <div className="w-[48%] max-lg:w-[90%]">
                <div className="mb-6">
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702538223/Tradeally_2.0/synthAlly_logo_skcywb.svg"
                        alt="trade ally logo"
                        width={157}
                        height={29}
                    />
                </div>
                <div className="mb-[38px]">
                    <p className="text-[32px] font-rocGroteskMedium text-p-70 mb-1.5">
                        Forgot your password?
                    </p>
                    <p className="text-sm font-rocGroteskRegular text-gm-35 leading-[24px]">
                        Enter your email address and we will send you a link to create a new
                        password
                    </p>
                </div>
                <form onSubmit={handleSubmit}>
                    <TextInput
                        name={"email"}
                        value={email}
                        type={"email"}
                        placeholder={"Enter work email"}
                        required={true}
                        onChange={(evt) => setEmail(evt.target.value)}
                        containerClassname="mb-6"
                    />
                    <div className="w-full mb-[30px]">
                        <Button
                            btnText={"Send me the link"}
                            isLoading={forgotPasswordRequest}
                            type={"submit"}
                        />
                    </div>

                    <p className="text-center">
                        <span className="text-xs text-gm-45 font-rocGroteskRegular">
                            Remember your password?
                        </span>{" "}
                        <Link
                            to={"/login"}
                            className="text-xs text-carribean-green font-rocGroteskBold cursor-pointer"
                        >
                            Log in
                        </Link>
                    </p>
                </form>
            </div>
        </AuthFrame>
    );
};

export default ForgotPassword;
