import { vendorTypes } from "redux/Ldb/types";

const initialState = {
    joiningWaitList: false,
    joinWaitListSuccess: null,
    joinWaitListFailure: null,
    fetchingLdbVendors: false,
    fetchedLdbVendorsSuccess: null,
    fetchedLdbVendorsFailure: null,
    fetchingSingleVendor: false,
    fetchedSingleVendorSuccess: null,
    fetchedSingleVendorFailure: null,
    authenticatingGmail: false,
    authenticateGmailSuccess: null,
    authenticateGmailFailure: null,
    authenticatingGmailSettings: false,
    authenticateGmailSettingsSuccess: null,
    authenticateGmailSettingsFailure: null,
    connectingGoogleWorkspace: false,
    connectGoogleWorkspaceSuccess: null,
    connectGoogleWorkspaceFailure: null,
    connectingGoogleWorkspaceSettings: false,
    connectGoogleWorkspaceSettingsSuccess: null,
    connectGoogleWorkspaceSettingsFailure: null,
    fetchingAllChannels: false,
    fetchedAllChannelsSuccess: null,
    fetchedAllChannelsFailure: null,
    fetchingVendorsByOrderType: false,
    fetchedVendorsByOrderTypeSuccess: null,
    fetchedVendorsByOrderTypeFailure: null,
    fetchingOnboardedVendors: false,
    fetchedOnboardedVendorsSuccess: null,
    fetchedOnboardedVendorsFailure: null,
    fetchingSuggestedVendors: false,
    fetchedSuggestedVendorsSuccess: null,
    fetchedSuggestedVendorsFailure: null,
    authenticatingMicrosoft: false,
    authenticateMicrosoftSuccess: null,
    authenticateMicrosoftFailure: null,
    authenticatingMicrosoftSettings: false,
    authenticateMicrosoftSettingsSuccess: null,
    authenticateMicrosoftSettingsFailure: null,
    connectingMicrosoftWorkspace: false,
    connectMicrosoftWorkspaceSuccess: null,
    connectMicrosoftWorkspaceFailure: null,
    connectingMicrosoftWorkspaceSettings: false,
    connectMicrosoftWorkspaceSettingsSuccess: null,
    connectMicrosoftWorkspaceSettingsFailure: null,
    fetchingVendorPo: false,
    fetchVendorPoSuccess: null,
    fetchVendorPoFailure: null,
    manageConnection: false,
    manageConnectionSuccess: null,
    manageConnectionFailure: null,
    fetchingVendorActivities: false,
    fetchVendorActivitiesSuccess: null,
    fetchVendorActivitiesFailure: null,
    fetchingVendorByCategory: false,
    fetchVendorByCategorySuccess: null,
    fetchVendorByCategoryFailure: null,
    fetchingVendorCategories: false,
    fetchVendorCategoriesSuccess: null,
    fetchVendorCategoriesFailure: null,
    addingVendorCategory: false,
    addVendorCategorySuccess: null,
    addVendorCategoryFailure: null,
    fetchingVendorDocs: false,
    fetchVendorDocsSuccess: null,
    fetchVendorDocsFailure: null,
    approvingVendorDoc: false,
    approveVendorDocSuccess: null,
    approveVendorDocFailure: null,
    requestingVendorDoc: false,
    requestVendorDocSuccess: null,
    requestVendorDocFailure: null,
    addingVendorDocComment: false,
    addVendorDocCommentSuccess: null,
    addVendorDocCommentFailure: null,
    fetchingVendorDocComment: false,
    fetchVendorDocCommentSuccess: null,
    fetchVendorDocCommentFailure: null,
    approvingSupplier: false,
    approveSupplierSuccess: null,
    approveSupplierFailure: null,
    reviewingSupplierResponses: false,
    reviewSupplierResponsesSuccess: null,
    reviewSupplierResponsesFailure: null,
};

export const vendor = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case vendorTypes.APPROVE_SUPPLIER_REQUEST:
            return {
                ...state,
                approvingSupplier: true,
                approveSupplierSuccess: null,
                approveSupplierFailure: null,
            };
        case vendorTypes.APPROVE_SUPPLIER_SUCCESS:
            return {
                ...state,
                approvingSupplier: false,
                approveSupplierSuccess: action.payload,
                approveSupplierFailure: null,
            };
        case vendorTypes.APPROVE_SUPPLIER_FAILURE:
            return {
                ...state,
                approvingSupplier: false,
                approveSupplierSuccess: null,
                approveSupplierFailure: action.payload,
            };

        case vendorTypes.FETCH_DOC_COMMENT_REQUEST:
            return {
                ...state,
                fetchingVendorDocComment: true,
                fetchVendorDocCommentSuccess: null,
                fetchVendorDocCommentFailure: null,
            };
        case vendorTypes.FETCH_DOC_COMMENT_SUCCESS:
            return {
                ...state,
                fetchingVendorDocComment: false,
                fetchVendorDocCommentSuccess: action.payload,
                fetchVendorDocCommentFailure: null,
            };
        case vendorTypes.FETCH_DOC_COMMENT_FAILURE:
            return {
                ...state,
                fetchingVendorDocComment: false,
                fetchVendorDocCommentSuccess: null,
                fetchVendorDocCommentFailure: action.payload,
            };

        case vendorTypes.ADD_DOC_COMMENT_REQUEST:
            return {
                ...state,
                addingVendorDocComment: true,
                addVendorDocCommentSuccess: null,
                addVendorDocCommentFailure: null,
            };
        case vendorTypes.ADD_DOC_COMMENT_SUCCESS:
            return {
                ...state,
                addingVendorDocComment: false,
                addVendorDocCommentSuccess: action.payload,
                addVendorDocCommentFailure: null,
            };
        case vendorTypes.ADD_DOC_COMMENT_FAILURE:
            return {
                ...state,
                addingVendorDocComment: false,
                addVendorDocCommentSuccess: null,
                addVendorDocCommentFailure: action.payload,
            };

        case vendorTypes.REQUEST_VENDOR_DOC_REQUEST:
            return {
                ...state,
                requestingVendorDoc: true,
                requestVendorDocSuccess: null,
                requestVendorDocFailure: null,
            };
        case vendorTypes.REQUEST_VENDOR_DOC_SUCCESS:
            return {
                ...state,
                requestingVendorDoc: false,
                requestVendorDocSuccess: action.payload,
                requestVendorDocFailure: null,
            };
        case vendorTypes.REQUEST_VENDOR_DOC_FAILURE:
            return {
                ...state,
                requestingVendorDoc: false,
                requestVendorDocSuccess: null,
                requestVendorDocFailure: action.payload,
            };

        case vendorTypes.APPROVE_VENDOR_DOC_REQUEST:
            return {
                ...state,
                approvingVendorDoc: true,
                approveVendorDocSuccess: null,
                approveVendorDocFailure: null,
            };
        case vendorTypes.APPROVE_VENDOR_DOC_SUCCESS:
            return {
                ...state,
                approvingVendorDoc: false,
                approveVendorDocSuccess: action.payload,
                approveVendorDocFailure: null,
            };
        case vendorTypes.APPROVE_VENDOR_DOC_FAILURE:
            return {
                ...state,
                approvingVendorDoc: false,
                approveVendorDocSuccess: null,
                approveVendorDocFailure: action.payload,
            };

        case vendorTypes.FETCH_VENDOR_DOCS_REQUEST:
            return {
                ...state,
                fetchingVendorDocs: true,
                fetchVendorDocsSuccess: null,
                fetchVendorDocsFailure: null,
            };
        case vendorTypes.FETCH_VENDOR_DOCS_SUCCESS:
            return {
                ...state,
                fetchingVendorDocs: false,
                fetchVendorDocsSuccess: action.payload,
                fetchVendorDocsFailure: null,
            };
        case vendorTypes.FETCH_VENDOR_DOCS_FAILURE:
            return {
                ...state,
                fetchingVendorDocs: false,
                fetchVendorDocsSuccess: null,
                fetchVendorDocsFailure: action.payload,
            };

        case vendorTypes.ADD_VENDOR_CATEGORY_REQUEST:
            return {
                ...state,
                addingVendorCategory: true,
                addVendorCategorySuccess: null,
                addVendorCategoryFailure: null,
            };
        case vendorTypes.ADD_VENDOR_CATEGORY_SUCCESS:
            return {
                ...state,
                addingVendorCategory: false,
                addVendorCategorySuccess: action.payload,
                addVendorCategoryFailure: null,
            };
        case vendorTypes.ADD_VENDOR_CATEGORY_FAILURE:
            return {
                ...state,
                addingVendorCategory: false,
                addVendorCategorySuccess: null,
                addVendorCategoryFailure: action.payload,
            };

        case vendorTypes.FETCH_VENDOR_CATEGORIES_REQUEST:
            return {
                ...state,
                fetchingVendorCategories: true,
                fetchVendorCategoriesSuccess: null,
                fetchVendorCategoriesFailure: null,
            };
        case vendorTypes.FETCH_VENDOR_CATEGORIES_SUCCESS:
            return {
                ...state,
                fetchingVendorCategories: false,
                fetchVendorCategoriesSuccess: action.payload,
                fetchVendorCategoriesFailure: null,
            };
        case vendorTypes.FETCH_VENDOR_CATEGORIES_FAILURE:
            return {
                ...state,
                fetchingVendorCategories: false,
                fetchVendorCategoriesSuccess: null,
                fetchVendorCategoriesFailure: action.payload,
            };

        case vendorTypes.FETCH_VENDOR_ACTIVITIES_REQUEST:
            return {
                ...state,
                fetchingVendorActivities: true,
                fetchVendorActivitiesSuccess: null,
                fetchVendorActivitiesFailure: null,
            };
        case vendorTypes.FETCH_VENDOR_ACTIVITIES_SUCCESS:
            return {
                ...state,
                fetchingVendorActivities: false,
                fetchVendorActivitiesSuccess: action.payload,
                fetchVendorActivitiesFailure: null,
            };
        case vendorTypes.FETCH_VENDOR_ACTIVITIES_FAILURE:
            return {
                ...state,
                fetchingVendorActivities: false,
                fetchVendorActivitiesSuccess: null,
                fetchVendorActivitiesFailure: action.payload,
            };

        case vendorTypes.FETCH_VENDOR_PO_REQUEST:
            return {
                ...state,
                fetchingVendorPo: true,
                fetchVendorPoSuccess: null,
                fetchVendorPoFailure: null,
            };
        case vendorTypes.FETCH_VENDOR_PO_SUCCESS:
            return {
                ...state,
                fetchingVendorPo: false,
                fetchVendorPoSuccess: action.payload,
                fetchVendorPoFailure: null,
            };
        case vendorTypes.FETCH_VENDOR_PO_FAILURE:
            return {
                ...state,
                fetchingVendorPo: false,
                fetchVendorPoSuccess: null,
                fetchVendorPoFailure: action.payload,
            };

        case vendorTypes.JOIN_WAITLIST_REQUEST:
            return {
                ...state,
                joiningWaitList: true,
                joinWaitListSuccess: null,
                joinWaitListFailure: null,
            };
        case vendorTypes.JOIN_WAITLIST_SUCCESS:
            return {
                ...state,
                joiningWaitList: false,
                joinWaitListSuccess: action.payload,
                joinWaitListFailure: null,
            };
        case vendorTypes.JOIN_WAITLIST_FAILURE:
            return {
                ...state,
                joiningWaitList: false,
                joinWaitListSuccess: null,
                joinWaitListFailure: action.payload,
            };

        case vendorTypes.FETCH_LDB_VENDORS_REQUEST:
            return {
                ...state,
                fetchingLdbVendors: true,
                fetchedLdbVendorsSuccess: null,
                fetchedLdbVendorsFailure: null,
            };
        case vendorTypes.FETCH_LDB_VENDORS_SUCCESS:
            return {
                ...state,
                fetchingLdbVendors: false,
                fetchedLdbVendorsSuccess: action.payload,
                fetchedLdbVendorsFailure: null,
            };
        case vendorTypes.FETCH_LDB_VENDORS_FAILURE:
            return {
                ...state,
                fetchingLdbVendors: false,
                fetchedLdbVendorsSuccess: null,
                fetchedLdbVendorsFailure: action.payload,
            };

        case vendorTypes.FETCH_SINGLE_VENDOR_REQUEST:
            return {
                ...state,
                fetchingSingleVendor: true,
                fetchedSingleVendorSuccess: null,
                fetchedSingleVendorFailure: null,
            };
        case vendorTypes.FETCH_SINGLE_VENDOR_SUCCESS:
            return {
                ...state,
                fetchingSingleVendor: false,
                fetchedSingleVendorSuccess: action.payload,
                fetchedSingleVendorFailure: null,
            };
        case vendorTypes.FETCH_SINGLE_VENDOR_FAILURE:
            return {
                ...state,
                fetchingSingleVendor: false,
                fetchedSingleVendorSuccess: null,
                fetchedSingleVendorFailure: action.payload,
            };

        case vendorTypes.AUTHENTICATE_GMAIL_REQUEST:
            return {
                ...state,
                authenticatingGmail: true,
                authenticateGmailSuccess: null,
                authenticateGmailFailure: null,
            };
        case vendorTypes.AUTHENTICATE_GMAIL_SUCCESS:
            return {
                ...state,
                authenticatingGmail: false,
                authenticateGmailSuccess: action.payload,
                authenticateGmailFailure: null,
            };
        case vendorTypes.AUTHENTICATE_GMAIL_FAILURE:
            return {
                ...state,
                authenticatingGmail: false,
                authenticateGmailSuccess: null,
                authenticateGmailFailure: action.payload,
            };

        case vendorTypes.AUTHENTICATE_GMAIL_SETTINGS_REQUEST:
            return {
                ...state,
                authenticatingGmailSettings: true,
                authenticateGmailSettingsSuccess: null,
                authenticateGmailSettingsFailure: null,
            };
        case vendorTypes.AUTHENTICATE_GMAIL_SETTINGS_SUCCESS:
            return {
                ...state,
                authenticatingGmailSettings: false,
                authenticateGmailSettingsSuccess: action.payload,
                authenticateGmailSettingsFailure: null,
            };
        case vendorTypes.AUTHENTICATE_GMAIL_SETTINGS_FAILURE:
            return {
                ...state,
                authenticatingGmailSettings: false,
                authenticateGmailSettingsSuccess: null,
                authenticateGmailSettingsFailure: action.payload,
            };

        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_REQUEST:
            return {
                ...state,
                connectingGoogleWorkspace: true,
                connectGoogleWorkspaceSuccess: null,
                connectGoogleWorkspaceFailure: null,
            };
        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_SUCCESS:
            return {
                ...state,
                connectingGoogleWorkspace: false,
                connectGoogleWorkspaceSuccess: action.payload,
                connectGoogleWorkspaceFailure: null,
            };
        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_FAILURE:
            return {
                ...state,
                connectingGoogleWorkspace: false,
                connectGoogleWorkspaceSuccess: null,
                connectGoogleWorkspaceFailure: action.payload,
            };

        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_SETTINGS_REQUEST:
            return {
                ...state,
                connectingGoogleWorkspaceSettings: true,
                connectGoogleWorkspaceSettingsSuccess: null,
                connectGoogleWorkspaceSettingsFailure: null,
            };
        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_SETTINGS_SUCCESS:
            return {
                ...state,
                connectingGoogleWorkspaceSettings: false,
                connectGoogleWorkspaceSettingsSuccess: action.payload,
                connectGoogleWorkspaceSettingsFailure: null,
            };
        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_SETTINGS_FAILURE:
            return {
                ...state,
                connectingGoogleWorkspaceSettings: false,
                connectGoogleWorkspaceSettingsSuccess: null,
                connectGoogleWorkspaceSettingsFailure: action.payload,
            };

        case vendorTypes.FETCH_ALL_CHANNELS_REQUEST:
            return {
                ...state,
                fetchingAllChannels: true,
                fetchedAllChannelsSuccess: null,
                fetchedAllChannelsFailure: null,
            };
        case vendorTypes.FETCH_ALL_CHANNELS_SUCCESS:
            return {
                ...state,
                fetchingAllChannels: false,
                fetchedAllChannelsSuccess: action.payload,
                fetchedAllChannelsFailure: null,
            };
        case vendorTypes.FETCH_ALL_CHANNELS_FAILURE:
            return {
                ...state,
                fetchingAllChannels: false,
                fetchedAllChannelsSuccess: null,
                fetchedAllChannelsFailure: action.payload,
            };

        case vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_REQUEST:
            return {
                ...state,
                fetchingVendorsByOrderType: true,
                fetchedVendorsByOrderTypeSuccess: null,
                fetchedVendorsByOrderTypeFailure: null,
            };
        case vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_SUCCESS:
            return {
                ...state,
                fetchingVendorsByOrderType: false,
                fetchedVendorsByOrderTypeSuccess: action.payload,
                fetchedVendorsByOrderTypeFailure: null,
            };
        case vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_FAILURE:
            return {
                ...state,
                fetchingVendorsByOrderType: false,
                fetchedVendorsByOrderTypeSuccess: null,
                fetchedVendorsByOrderTypeFailure: action.payload,
            };

        case vendorTypes.FETCH_ONBOARDED_VENDORS_REQUEST:
            return {
                ...state,
                fetchingOnboardedVendors: true,
                fetchedOnboardedVendorsSuccess: null,
                fetchedOnboardedVendorsFailure: null,
            };
        case vendorTypes.FETCH_ONBOARDED_VENDORS_SUCCESS:
            return {
                ...state,
                fetchingOnboardedVendors: false,
                fetchedOnboardedVendorsSuccess: action.payload,
                fetchedOnboardedVendorsFailure: null,
            };
        case vendorTypes.FETCH_ONBOARDED_VENDORS_FAILURE:
            return {
                ...state,
                fetchingOnboardedVendors: false,
                fetchedOnboardedVendorsSuccess: null,
                fetchedOnboardedVendorsFailure: action.payload,
            };

        case vendorTypes.FETCH_SUGGESTED_VENDORS_REQUEST:
            return {
                ...state,
                fetchingSuggestedVendors: true,
                fetchedSuggestedVendorsSuccess: null,
                fetchedSuggestedVendorsFailure: null,
            };
        case vendorTypes.FETCH_SUGGESTED_VENDORS_SUCCESS:
            return {
                ...state,
                fetchingSuggestedVendors: false,
                fetchedSuggestedVendorsSuccess: action.payload,
                fetchedSuggestedVendorsFailure: null,
            };
        case vendorTypes.FETCH_SUGGESTED_VENDORS_FAILURE:
            return {
                ...state,
                fetchingSuggestedVendors: false,
                fetchedSuggestedVendorsSuccess: null,
                fetchedSuggestedVendorsFailure: action.payload,
            };

        case vendorTypes.AUTHENTICATE_MICROSOFT_REQUEST:
            return {
                ...state,
                authenticatingMicrosoft: true,
                authenticateMicrosoftSuccess: null,
                authenticateMicrosoftFailure: null,
            };
        case vendorTypes.AUTHENTICATE_MICROSOFT_SUCCESS:
            return {
                ...state,
                authenticatingMicrosoft: false,
                authenticateMicrosoftSuccess: action.payload,
                authenticateMicrosoftFailure: null,
            };
        case vendorTypes.AUTHENTICATE_MICROSOFT_FAILURE:
            return {
                ...state,
                authenticatingMicrosoft: false,
                authenticateMicrosoftSuccess: null,
                authenticateMicrosoftFailure: action.payload,
            };

        case vendorTypes.AUTHENTICATE_MICROSOFT_SETTINGS_REQUEST:
            return {
                ...state,
                authenticatingMicrosoftSettings: true,
                authenticateMicrosoftSettingsSuccess: null,
                authenticateMicrosoftSettingsFailure: null,
            };
        case vendorTypes.AUTHENTICATE_MICROSOFT_SETTINGS_SUCCESS:
            return {
                ...state,
                authenticatingMicrosoftSettings: false,
                authenticateMicrosoftSettingsSuccess: action.payload,
                authenticateMicrosoftSettingsFailure: null,
            };
        case vendorTypes.AUTHENTICATE_MICROSOFT_SETTINGS_FAILURE:
            return {
                ...state,
                authenticatingMicrosoftSettings: false,
                authenticateMicrosoftSettingsSuccess: null,
                authenticateMicrosoftSettingsFailure: action.payload,
            };
        case vendorTypes.CONNECT_MICROSOFT_WORKSPACE_REQUEST:
            return {
                ...state,
                connectingMicrosoftWorkspace: true,
                connectMicrosoftWorkspaceSuccess: null,
                connectMicrosoftWorkspaceFailure: null,
            };
        case vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SUCCESS:
            return {
                ...state,
                connectingMicrosoftWorkspace: false,
                connectMicrosoftWorkspaceSuccess: action.payload,
                connectMicrosoftWorkspaceFailure: null,
            };
        case vendorTypes.CONNECT_MICROSOFT_WORKSPACE_FAILURE:
            return {
                ...state,
                connectingMicrosoftWorkspace: false,
                connectMicrosoftWorkspaceSuccess: null,
                connectMicrosoftWorkspaceFailure: action.payload,
            };
        case vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SETTINGS_REQUEST:
            return {
                ...state,
                connectingMicrosoftWorkspaceSettings: true,
                connectMicrosoftWorkspaceSettingsSuccess: null,
                connectMicrosoftWorkspaceSettingsFailure: null,
            };
        case vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SETTINGS_SUCCESS:
            return {
                ...state,
                connectingMicrosoftWorkspaceSettings: false,
                connectMicrosoftWorkspaceSettingsSuccess: action.payload,
                connectMicrosoftWorkspaceSettingsFailure: null,
            };
        case vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SETTINGS_FAILURE:
            return {
                ...state,
                connectingMicrosoftWorkspaceSettings: false,
                connectMicrosoftWorkspaceSettingsSuccess: null,
                connectMicrosoftWorkspaceSettingsFailure: action.payload,
            };
        case vendorTypes.MANAGE_CONNECTION_REQUEST:
            return {
                ...state,
                manageConnection: true,
                manageConnectionSuccess: null,
                manageConnectionFailure: null,
            };
        case vendorTypes.MANAGE_CONNECTION_SUCCESS:
            return {
                ...state,
                manageConnection: false,
                manageConnectionSuccess: action.payload,
                manageConnectionFailure: null,
            };
        case vendorTypes.MANAGE_CONNECTION_FAILURE:
            return {
                ...state,
                manageConnection: false,
                manageConnectionSuccess: null,
                manageConnectionFailure: action.payload,
            };
        case vendorTypes.FETCH_VENDOR_BY_CATEGORY_REQUEST:
            return {
                ...state,
                fetchingVendorByCategory: true,
                fetchVendorByCategorySuccess: null,
                fetchVendorByCategoryFailure: null,
            };
        case vendorTypes.FETCH_VENDOR_BY_CATEGORY_SUCCESS:
            return {
                ...state,
                fetchingVendorByCategory: false,
                fetchVendorByCategorySuccess: action.payload,
                fetchVendorByCategoryFailure: null,
            };
        case vendorTypes.FETCH_VENDOR_BY_CATEGORY_FAILURE:
            return {
                ...state,
                fetchingVendorByCategory: false,
                fetchVendorByCategorySuccess: null,
                fetchVendorByCategoryFailure: action.payload,
            };
            case vendorTypes.REVIEW_SUPPLIER_RESPONSES_REQUEST:
                return {
                    ...state,
                    reviewingSuplierResponses: true,
                   reviewSupplierResponsesSuccess: null,
                   reviewSupplierResponsesFailure: null,
                };
            case vendorTypes.REVIEW_SUPPLIER_RESPONSES_SUCCESS:
                return {
                    ...state,
                    reviewingSuplierResponses: false,
                   reviewSupplierResponsesSuccess: action.payload,
                   reviewSupplierResponsesFailure: null,
                };
            case vendorTypes.REVIEW_SUPPLIER_RESPONSES_FAILURE:
                return {
                    ...state,
                    reviewingSuplierResponses: false,
                   reviewSupplierResponsesSuccess: null,
                   reviewSupplierResponsesFailure: action.payload,
                };
        default:
            return state;
    }
};
