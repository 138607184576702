/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import SelectInput from "components/common/InputField/SelectInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import Loader from "components/common/Loader";
import { debounce } from "lodash";
import Button from "components/common/Button";
import { useNavigate } from "react-router-dom";
import ModalContainer from "components/common/ModalContainer";
import TextInput from "components/common/InputField/TextInput";
import CustomTable from "components/common/CustomTable";
import { onScroll } from "helpers";
import { getEditPermissionList } from "helpers/getEditPermissionList";

const NewStockAdjustment = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [debounceProductSearch, setDebounceProductSearch] = useState<string>();
    const [productModal, setProductModal] = useState<boolean>(false);
    const [warehouses, setWarehouses] = useState<any>();
    const [search, setSearch] = useState<string>("");
    const [product, setProduct] = useState<any>([]);
    const [debounceWarehouseSearch, setDebounceWarehouseSearch] = useState<string>();
    const [stockData, setStockData] = useState<{ [key: string]: any }>();
    const [warehouseId, setWarehouseId] = useState<string>("");
    const [selectedProduct, setSelectedProduct] = useState<any>([]);
    const [addSelectedProduct, setAddSelectedProduct] = useState<any>([]);
    const [productData, setProductData] = useState<{ [key: string]: any }>([]);
    const [response, setResponse] = useState<any>();
    const [transferModal, setTransferModal] = useState<boolean>(false);
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [approvalModal, setApprovalModal] = useState<boolean>(false);
    const [transferModalLoading, setTransferModalLoading] = useState<boolean>(false);
    const [loadMore, setLoadMore] = useState<boolean>(false);
    const [limit, setLimit] = useState<number>(10);
    const [dashboardContent, setDashboardContent] = useState<any>();
    const [paginate, setPaginate] = useState<{
        current: number | string;
        number_of_pages: number | string;
    }>({
        current: "",
        number_of_pages: "",
    });
    useEffect(() => {
        if (productModal) {
            setDashboardContent(document.getElementById("scrollableTable"));
        }
    }, [productModal]);
    const onTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                paginate?.current as number,
                paginate?.number_of_pages as number,
                () => {
                    setLimit(() => limit + 10);
                    setLoadMore(true);
                }
            ),
        [dashboardContent, paginate]
    );
    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onTableScroll);
        };
    }, [dashboardContent, onTableScroll]);

    const handleDebouncedChange = debounce((e) => {
        setDebounceProductSearch(e.target.value);
    }, 800);

    const {
        fetchingWarehouses,
        fetchedWarehousesSuccess,
        fetchingStockList,
        fetchedStockListSuccess,
        addedNewStockAdjustmentSuccess,
        addingNewStockAdjustment,
    } = useAppSelector((state) => state?.inventory);

    const handleQuantityChange = (item, idx, action) => {
        const copyProduct = [...addSelectedProduct];
        if (action === "increase") {
            if (
                stockData?.reason !== "adjustment" &&
                item?.quantity < item?.productStockDetails?.stockLevel
            ) {
                copyProduct[idx] = {
                    ...copyProduct[idx],
                    quantity: +item?.quantity + 1,
                };
            } else if (stockData?.reason === "adjustment") {
                copyProduct[idx] = {
                    ...copyProduct[idx],
                    quantity: +item?.quantity + 1,
                };
            }
        } else {
            if (item?.quantity > 0) {
                copyProduct[idx] = {
                    ...copyProduct[idx],
                    quantity: item?.quantity - 1,
                };
            }
        }
        setAddSelectedProduct(copyProduct);
    };

    const handleInputChange = (e, idx) => {
        const { value } = e.target;
        const copySelectedProduct = [...addSelectedProduct];
        copySelectedProduct[idx] = {
            ...copySelectedProduct[idx],
            quantity: value,
        };
        setAddSelectedProduct(copySelectedProduct);
    };
    useEffect(() => {
        if (warehouseId) {
            setAddSelectedProduct([]);
            setProductData([]);
            setProduct([]);
        }
    }, [warehouseId]);

    useEffect(() => {
        if (addSelectedProduct?.length > 0) {
            const copySelectProduct = [...addSelectedProduct];
            const getProductIds = copySelectProduct?.map((item) => {
                const { _id, quantity } = item;
                return {
                    id: _id,
                    quantity,
                };
            });

            setProductData(getProductIds);
        }
    }, [addSelectedProduct]);

    useEffect(() => {
        setStockData((prev) => ({
            ...prev,
            data: productData,
        }));
    }, [productData]);

    const handleSelectAllProducts = () => {
        if (product?.length === selectedProduct?.length) {
            setSelectedProduct([]);
        } else {
            setSelectedProduct(product);
        }
    };

    const handleSelectProduct = (item) => {
        const checkIfSelected = selectedProduct?.filter((prod) => prod?._id === item?._id);
        if (checkIfSelected?.length === 0) {
            setSelectedProduct((prev) => {
                const selected = [...prev];
                selected.push(item);
                return selected;
            });
        } else {
            const copySelectedProduct = [...selectedProduct];
            const filter = copySelectedProduct?.filter((prod) => prod?._id !== item?._id);
            setSelectedProduct(filter);
        }
    };

    const handleAddButton = () => {
        const copySelectedProduct = [...selectedProduct];

        const addQuantity = copySelectedProduct?.map((item) => {
            if (!item?.quantity) {
                return {
                    ...item,
                    quantity: item?.productStockDetails?.stockLevel,
                };
            } else {
                return {
                    ...item,
                };
            }
        });
        setAddSelectedProduct([...addQuantity]);
        setProductModal(false);
    };

    useEffect(() => {
        setSelectedProduct([]);
        setAddSelectedProduct([]);
    }, [warehouseId]);
    useEffect(() => {
        if (addSelectedProduct?.length > 0) {
            setSelectedProduct(addSelectedProduct);
        }
    }, [addSelectedProduct]);
    const handleStockData = (e) => {
        const { name, value } = e.target;
        setStockData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleDeleteProduct = (idx) => {
        if (addSelectedProduct?.length > 1) {
            const copyProductData = [...addSelectedProduct];
            copyProductData.splice(idx, 1);
            setAddSelectedProduct(copyProductData);
        }
    };
    useEffect(() => {
        if (stockData?.reason) {
            const copyProduct = [...addSelectedProduct];
            const resetQty = copyProduct?.map((item) => ({
                ...item,
                quantity: item?.productStockDetails?.stockLevel,
            }));
            setAddSelectedProduct(resetQty);
        }
    }, [stockData?.reason]);

    const handleRetryFailedStock = (id) => {
        const failedTransfer = stockData?.data?.filter((item) => item?._id === id);
        const failedProduct = selectedProduct?.filter((item) => item?._id === id);

        setStockData((prev) => ({
            ...prev,
            data: failedTransfer,
        }));
        setAddSelectedProduct(failedProduct);
        setSelectedProduct(failedProduct);
        setSuccessModal(false);
    };

    const handleStockAdjustment = () => {
        dispatch(inventoryActions.addNewStockAdjustment(stockData));
        setTransferModal(false);
        setTransferModalLoading(true);
    };

    useEffect(() => {
        if (Boolean(fetchedStockListSuccess)) {
            setProduct(fetchedStockListSuccess?.stock);
            setPaginate({
                current: fetchedStockListSuccess?.pagination?.current,
                number_of_pages: fetchedStockListSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedStockListSuccess]);

    useEffect(() => {
        if (Boolean(fetchedWarehousesSuccess)) {
            setWarehouses(fetchedWarehousesSuccess?.warehouses.data);
        }
    }, [fetchedWarehousesSuccess]);

    useEffect(() => {
        if (warehouseId) {
            dispatch(inventoryActions.fetchStockList(limit, debounceProductSearch, warehouseId));
        }
    }, [debounceProductSearch, warehouseId, limit]);

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouses(20, debounceWarehouseSearch));
    }, [debounceWarehouseSearch]);

    useEffect(() => {
        if (Boolean(addedNewStockAdjustmentSuccess)) {
            setResponse(addedNewStockAdjustmentSuccess);
            setTransferModalLoading(false);
            setSuccessModal(true);
            dispatch(inventoryActions.resetAddNewStockAdjustment());
        }
    }, [addedNewStockAdjustmentSuccess]);

    const tableModalHeader = [
        {
            title: "",
            widthClass: "w-[5%] !font-rocGroteskMedium  !border-r !border-slate-200",
            itemClass: "!py-4",
            onSelectAll: () => {
                handleSelectAllProducts();
            },
            isAllChecked: selectedProduct?.length === product?.length,
        },
        {
            title: "Product name",
            widthClass: "w-[70%] !font-rocGroteskMedium  !border-slate-200",
        },
        {
            title: "Quantity",
            widthClass: "w-[20%] !font-rocGroteskMedium  !border-slate-200",
            itemClass: " !justify-end !py-4",
        },
        {
            title: "",
            widthClass: "w-[5%] !font-rocGroteskMedium  !border-slate-200",
            itemClass: "!py-4",
        },
    ];
    const tableModalBody = product?.map((item, idx) => {
        const isSelected = selectedProduct?.filter((prod) => prod?._id === item?._id);
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                    ></div>
                ),
                widthClass: "!border-b !border-r !border-slate-200",
                isItemChecked: isSelected?.length === 0 ? false : true,
                onCheckBoxClick: () => {
                    handleSelectProduct(item);
                },
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex items-center h-full
                         border-slate-100 text-sm font-rocGroteskMedium text-gm-50 gap-3`}
                    >
                        <div className="w-8 h-8">
                            <img
                                src={item?.inventory?.productImageDetails?.productImages}
                                alt=""
                                className=""
                            />
                        </div>

                        <div className="">
                            <p className="text-gm-50"> {item?.inventory?.productName}</p>
                            <p className="text-slate-500"> {item?.inventory?.sku}</p>
                        </div>
                    </div>
                ),
                widthClass: "!border-b  !border-slate-200",
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex w-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 gap-8 justify-end`}
                    >
                        <p className="bg-slate-200 rounded px-2 py-1 uppercase flex gap-1">
                            <span className="">
                                {item?.productStockDetails?.stockLevel &&
                                    item?.productStockDetails?.stockLevel}
                            </span>

                            <span className="">
                                {item?.productStockDetails?.unitOfMeasurement &&
                                    item?.productStockDetails?.unitOfMeasurement}
                            </span>
                        </p>
                    </div>
                ),
                widthClass: "!border-b  !border-slate-200",
            },
            {
                content: (
                    <div
                        className={`py-3 !pr-8 flex h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 gap-8`}
                    >
                        <i
                            className="ri-external-link-line text-gun-metal text-base"
                            onClick={() => {
                                navigate(`/dashboard/inventory/product/${item?.inventory?._id}`);
                            }}
                        ></i>
                    </div>
                ),
                widthClass: "!border-b  !border-slate-200",
            },
        ];
    });

    const tableProductHeader = [
        { title: "Product ", widthClass: "w-[45%] !font-rocGroteskMedium" },
        {
            title: "Initial Qty",
            widthClass: "w-[15%] !font-rocGroteskMedium ",
            itemClass: " !justify-center",
        },
        {
            title: "Change Qty",
            widthClass: "w-[20%] !font-rocGroteskMedium ",
            itemClass: "!py-4 !justify-center",
        },
        {
            title: "Final Qty",
            widthClass: "w-[20%] !font-rocGroteskMedium ",
            itemClass: "!py-4 !justify-center",
        },
        {
            title: "",
            widthClass: "w-[5%] !font-rocGroteskMedium ",
            itemClass: "!py-4 !justify-center",
        },
    ];

    const tableProductBody = addSelectedProduct?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 gap-3`}
                    >
                        <div className="w-8 h-8">
                            <img
                                src={item?.inventory?.productImageDetails?.productImages}
                                alt=""
                                className=""
                            />
                        </div>

                        <div className="">
                            <p className="text-gm-50"> {item?.inventory?.productName}</p>
                            <p className="text-slate-500"> {item?.inventory?.sku}</p>
                        </div>
                    </div>
                ),
                widthClass: "!border-b",
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 text-center w-full  text-sm font-rocGroteskMedium text-gm-50 `}
                    >
                        <span className="bg-slate-200 rounded !px-2 !py-1 uppercase border-slate-100 w-auto">
                            {item?.productStockDetails?.quantityAdded
                                ? item?.productStockDetails?.quantityAdded
                                : 0}
                        </span>
                    </div>
                ),
                widthClass: "!border-b",
            },
            {
                content: (
                    <div
                        className={`py-3 px-3 flex justify-center w-full items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50`}
                    >
                        <span
                            className="bg-[#166534] w-6  rounded-l text-center py-1"
                            onClick={() => {
                                handleQuantityChange(item, idx, "decrease");
                            }}
                        >
                            <i className="ri-subtract-line text-white text-sm font-rocGroteskMedium"></i>
                        </span>
                        <div className="text-center">
                            <TextInput
                                name="quantity"
                                type="number"
                                value={item?.quantity}
                                onChange={(e) => handleInputChange(e, idx)}
                                onInput={handleDebouncedChange}
                                inputClassName={"!text-center !min-w-[84px] !w-[84px] "}
                                max={
                                    stockData?.reason !== "adjustment" &&
                                    item?.productStockDetails?.stockLevel
                                }
                                min={0}
                                inputContainerClassname={
                                    "!rounded-none text-sm text-gun-metal px-2 py-1 uppercase !bg-[#F0FDF4]  !text-center !px-0 !py-0 !w-auto !h-[30px] !border-0"
                                }
                            />
                        </div>

                        <span
                            className="bg-[#166534] w-6  rounded-r text-center py-1"
                            onClick={() => {
                                handleQuantityChange(item, idx, "increase");
                            }}
                        >
                            <i className="ri-add-line text-white text-sm"></i>
                        </span>
                    </div>
                ),
                widthClass: "!border-b",
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`py-3 text-sm font-rocGroteskMedium text-[#78350F] flex items-center justify-center`}
                    >
                        <p className="bg-[#FFFBEB] rounded px-2 py-1 text-center !w-24 h-6 overflow-x-scroll">
                            {stockData?.reason === "adjustment" ? (
                                <>{item?.quantity}</>
                            ) : (
                                <>
                                    {item?.productStockDetails?.stockLevel - item?.quantity < 0
                                        ? 0
                                        : item?.productStockDetails?.stockLevel - item?.quantity}
                                </>
                            )}
                        </p>
                    </div>
                ),
                widthClass: "!border-b ",
                tableDataDivClass: "!pl-0 !justify-center",
            },
            {
                content: (
                    <div className={`py-3 !pr-8 text-sm font-rocGroteskMedium text-gun-metal`}>
                        <i
                            className={`ri-delete-bin-line ${
                                productData?.length === 1 && "cursor-default"
                            }`}
                            onClick={() => handleDeleteProduct(idx)}
                        ></i>
                    </div>
                ),
                widthClass: "!border-b",
            },
        ];
    });

    return (
        <div className="">
            <form
                className="bg-white rounded-lg border border-slate-100"
                onSubmit={(e) => {
                    e.preventDefault();
                    setTransferModal(true);
                }}
            >
                <div className="flex items-center justify-between px-8 py-5 border-b border-slate-100 top-[-10px] md:sticky  z-[50] bg-white">
                    <p className="text-base font-rocGroteskMedium">New Adjustment</p>
                    <div className="flex items-center gap-3">
                        <Button
                            btnText="Cancel"
                            btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                            onClick={() => navigate("/dashboard/inventory/stock-control?tabId=2")}
                            disabled={addingNewStockAdjustment}
                        />
                        <Button
                            btnText="Adjust Stock"
                            type="submit"
                            btnClassname="!px-4 !py-2.5 !w-fit"
                            disabled={addingNewStockAdjustment}
                            permissions={getEditPermissionList("inventory")}
                        />
                    </div>
                </div>
                <div className="w-[65%] px-8">
                    <div className="flex flex-col gap-4 mt-8">
                        <div className="grid grid-cols-2 items-center gap-4 ">
                            <SelectInput
                                value={stockData?.reason || ""}
                                name="reason"
                                placeholder={"Select reason"}
                                handleChange={(name, value) => {
                                    handleStockData({ target: { name, value } });
                                }}
                                isRequired={true}
                                className={`${
                                    stockData?.reason &&
                                    "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                }`}
                                clearValue={true}
                                dropdownOptions={[
                                    {
                                        label: "Adjustment",
                                        value: "adjustment",
                                    },
                                    {
                                        label: "Damages",
                                        value: "damage",
                                    },
                                    {
                                        label: "Consumption",
                                        value: "consumption",
                                    },
                                    {
                                        label: "Returned",
                                        value: "return",
                                    },
                                ]}
                            />

                            <SelectInput
                                value={warehouseId || ""}
                                name="warehouseId"
                                placeholder={"Select Warehouse"}
                                handleChange={(name, value) => {
                                    setWarehouseId(value);
                                }}
                                searchLoading={fetchingWarehouses}
                                isRequired={true}
                                className={`${
                                    warehouseId && "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                }`}
                                clearValue={true}
                                handleInputChange={debounce((evt) => {
                                    setDebounceWarehouseSearch(evt.target.value);
                                }, 800)}
                                dropdownOptions={warehouses?.map((item) => ({
                                    label: item?.warehouseName,
                                    value: item?._id,
                                }))}
                            />
                        </div>
                    </div>
                    <div className="mt-6">
                        <label className="text-base font-rocGroteskMedium">Notes</label>
                        <div className="mt-4">
                            <TextareaInput
                                name={"notes"}
                                value={stockData?.notes || ""}
                                onChange={handleStockData}
                                rows={5}
                                placeholder={"Add note"}
                                required={false}
                                inputContainerClassname={`${
                                    stockData?.notes &&
                                    "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                }`}
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-8 px-8 mb-20">
                    <p className="pb-4 font-rocGroteskMedium text-gun-metal text-base">Products</p>

                    {addSelectedProduct?.length > 0 ? (
                        <div>
                            <CustomTable
                                tableBody={tableProductBody}
                                tableHeader={tableProductHeader}
                                isAllSelectable={false}
                                headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                                headerContainerClass="!bg-slate-50"
                                isCellBordered={true}
                            />
                            <Button
                                btnText="Select stock to adjust"
                                btnClassname="!bg-white !py-3 !pl-4 !pl-5 border border-slate-200 !text-[#142837] 
                                !text-[13px] !w-auto !mt-6"
                                icon=<i className="ri-add-fill text-base text-gm-50"></i>
                                onClick={() => {
                                    setProductModal(true);
                                }}
                            />
                        </div>
                    ) : (
                        <div className=" rounded-md border-[#BFCDE0] border-dashed border-[1.5px] w-[65%]">
                            <div className="p-8 font-rocGroteskMedium flex justify-center flex-col items-center">
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1705745863/box-3-line_ybcpgn.svg"
                                    alt=""
                                    className="w-10 h-10"
                                />
                                <p className="text-[#142837] mb-1 text-sm ">
                                    Your adjustment is empty
                                </p>
                                <p className="text-[#6B778C] text-sm mb-[19px]">
                                    Select and add the products you want to adjust
                                </p>
                               <div>
                               <Button
                                    btnText="Select stock to adjust"
                                    btnClassname="!bg-white !py-4 !pl-4 !pl-5 border border-slate-200 !text-[#142837] 
                                !text-[13px] !w-auto"
                                    onClick={() => setProductModal(true)}
                                    icon=<i className="ri-add-fill text-base text-gm-50"></i>
                                />
                               </div>
                            </div>
                        </div>
                    )}
                </div>
            </form>

            <ModalContainer
                open={productModal}
                // showCloseIcon={false}
                tailwindClassName="w-[90%] lg:w-[1000px]"
            >
                <div className="bg-white  rounded-lg max-h-[90vh] overflow-y-scroll">
                    <div className=" px-6 py-4 flex gap-4 items-center justify-between border-b border-b-slate-200">
                        <p className="text-lg text-gm-50 font-rocGroteskMedium">
                            Select products to adjust
                        </p>
                        <i
                            onClick={() => setProductModal(false)}
                            className="ri-close-fill cursor-pointer text-gm-50 text-2xl "
                        ></i>
                    </div>
                    <div className="my-8 mx-8  ">
                        <div className="">
                            <TextInput
                                name="search"
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onInput={handleDebouncedChange}
                                inputPlaceholder="Search for product"
                                inputClassName={
                                    "!h-[50px] pl-[0px] !mb-0 !font-rocGroteskMedium !text-slate-400"
                                }
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-tl-md !rounded-tr-md !rounded-bl-none !rounded-br-none !h-[50px] !pl-[40px] !bg-white !border !border-slate-200 !border-b-[0.5px]"
                                }
                            />
                        </div>
                        {product?.length > 0 ? (
                            <div className="">
                                <div className="max-h-[300px] overflow-y-auto" id="scrollableTable">
                                    <CustomTable
                                        tableBody={tableModalBody}
                                        tableHeader={tableModalHeader}
                                        isAllSelectable={true}
                                        headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                                        headerContainerClass="!bg-slate-50"
                                        isScrollable={false}
                                        isCellBordered={false}
                                    />

                                    {loadMore && fetchingStockList && (
                                        <div className="flex my-4 justify-center">
                                            <Loader color="gm-25" size={4} />
                                        </div>
                                    )}
                                    {paginate?.current === paginate?.number_of_pages && (
                                        <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                            End of list
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="flex my-10 text-slate-500  justify-center text-sm font-rocGroteskMedium h-40">
                                No product
                            </div>
                        )}
                    </div>
                    <div className=" px-8 py-4 flex gap-4 items-center justify-between border-t border-t-slate-200">
                        <Button
                            btnText="Add new product"
                            btnClassname="!bg-white !py-3 !pl-4 !pl-5 border border-slate-200 !text-[#142837] 
                                !text-[13px] !w-auto"
                            icon=<i className="ri-add-fill text-base text-gm-50"></i>
                            onClick={() => navigate("/dashboard/inventory/add-product")}
                        />
                        <div className="flex items-center gap-3">
                            <Button
                                btnText="Cancel"
                                btnClassname="!px-6 !py-3 !bg-tradeally-neutral-20 !w-fit"
                                onClick={() => setProductModal(false)}
                                disabled={addingNewStockAdjustment}
                            />
                            <Button
                                btnText="Add"
                                btnClassname="!px-6 !py-3 !w-fit"
                                onClick={() => {
                                    handleAddButton();
                                }}
                                disabled={addingNewStockAdjustment}
                                // isLoading={addingNewStockAdjustment}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={transferModal}
                showCloseIcon={false}
                tailwindClassName="w-[42%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative p-6">
                    <div className="flex flex-col items-start text-left">
                        <p className="text-lg mb-3 text-left font-rocGroteskMedium text-g-75">
                            Stock Adjustment Request
                        </p>
                        <p className="text-base text-left font-rocGroteskMedium text-slate-500">
                            You've initiated stock adjustment. Confirm the details before submitting
                            the request. Once submitted, it will be reviewed for approval.
                        </p>
                    </div>

                    <div className="w-full flex items-center justify-end pt-5">
                        <div className="flex items-center gap-2">
                            <Button
                                btnText={"No, don't adjust"}
                                type={"button"}
                                btnClassname="!py-3 !px-6 !bg-white !border !border-n-20 !w-fit whitespace-nowrap"
                                onClick={() => {
                                    setTransferModal(false);
                                }}
                            />
                            <Button
                                btnText={"Yes, Adjust"}
                                btnClassname={"!py-3 !px-6 !w-fit"}
                                type={"button"}
                                onClick={handleStockAdjustment}
                                isLoading={addingNewStockAdjustment}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={transferModalLoading}
                showCloseIcon={false}
                tailwindClassName="w-[42%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="p-10">
                        <div className="flex flex-col items-center">
                            <Loader size={6} color="slate-300" />
                            <p className="text-base text-center font-rocGroteskMedium mt-5">
                                Adjusting stocks . Please wait
                            </p>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={successModal}
                showCloseIcon={false}
                tailwindClassName="w-[42%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="px-10 pt-8 max-h-[70vh] overflow-y-scroll ">
                        {response?.stockSuccessfull?.length > 0 && (
                            <div className="flex flex-col items-center">
                                <div className="w-12 h-12 rounded-full bg-[#00DB8F] flex items-center justify-center">
                                    <i className="ri-checkbox-circle-fill text-xl text-white"></i>
                                </div>

                                <p className="text-xl mb-3 text-center mt-4 font-rocGroteskMedium text-g-75">
                                    Stock adjustment successful
                                </p>
                                <p className="text-sm text-center font-rocGroteskMedium text-slate-500 max-w-[411px]">
                                    Stock levels have been successfully adjusted, and the changes
                                    are now accurately reflected in the inventory.
                                </p>
                                <div className="mt-8 w-full">
                                    <p className="text-base text-left font-rocGroteskMedium">
                                        {response?.stockSuccessfull.length} stock(s) adjusted
                                    </p>

                                    {response?.stockSuccessfull?.map((item, idx) => {
                                        console.log(item);
                                        return (
                                            <div
                                                key={idx}
                                                className="w-full py-4 border-b border-n-30 flex items-center justify-between gap-8"
                                            >
                                                <div className="flex items-center gap-4">
                                                    <div className="w-[38px] h-[38px] flex items-center justify-center rounded bg-[#ECFDF5] ">
                                                        <i className="ri-box-2-fill text-xl text-[#10B981]"></i>
                                                    </div>
                                                    <div className="text-sm font-rocGroteskMedium">
                                                        <p className="text-gm-50">
                                                            {" "}
                                                            {item?.stock?.batchId}
                                                        </p>
                                                        <p className="text-slate-500">
                                                            {item?.stock?.inventory?.productName}
                                                        </p>
                                                    </div>
                                                </div>
                                                <Button
                                                    btnText="View"
                                                    btnClassname="!bg-n-20 !px-4 !py-2.5 !w-fit"
                                                    onClick={() => {
                                                        navigate(
                                                            `/dashboard/inventory/stock-control/stock-adjustment/${item?.stockMovement?._id}`
                                                        );
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}

                        {response?.stocksFailed?.length > 0 && (
                            <div className="flex flex-col items-center ">
                                {response?.stockSuccessfull?.length === 0 && (
                                    <div className="">
                                        <div className="flex flex-col items-center">
                                            <div className="w-12 h-12 rounded-full bg-[#DC2626] flex items-center justify-center">
                                                <i className="ri-close-fill text-xl text-white"></i>
                                            </div>
                                        </div>
                                        <p className="text-xl mb-3 text-center mt-4 font-rocGroteskMedium text-g-75">
                                            Stock adjustment failed
                                        </p>
                                        <p className="text-sm text-center font-rocGroteskMedium text-slate-500 max-w-[480px]">
                                            Stock adjustment failed due to discrepancies in the
                                            source warehouse count. Double-check the information and
                                            try again.
                                        </p>
                                    </div>
                                )}

                                {response?.stockSuccessfull?.length > 0 && (
                                    <p className="text-base text-left font-rocGroteskMedium text-[#FF3D1D] mt-8 ">
                                        {response?.stocksFailed?.length} stock(s) adjustment failed
                                        due to low count from source warehouse
                                    </p>
                                )}

                                <div className="mt-6 w-full mb-10">
                                    {response?.stocksFailed?.map((item, idx) => {
                                        return (
                                            <div
                                                key={idx}
                                                className="w-full py-4 border-b border-n-30 flex items-center justify-between gap-8"
                                            >
                                                <div className="flex items-center gap-4">
                                                    <div className="w-[38px] h-[38px] flex items-center justify-center rounded bg-[#FEF2F2] ">
                                                        <i className="ri-close-circle-fill text-xl text-[#EF4444]"></i>
                                                    </div>
                                                    <div className="text-sm font-rocGroteskMedium mb-1">
                                                        <div className="text-sm font-rocGroteskMedium">
                                                            <p className="text-gm-50">
                                                                {item?.stock?.batchId}
                                                            </p>
                                                            <p className="text-slate-500">
                                                                {
                                                                    item?.stock?.inventory
                                                                        ?.productName
                                                                }
                                                            </p>
                                                        </div>
                                                        <p className="flex items-center gap-2 flex-wrap">
                                                            <span
                                                                className="bg-[#EEF2F9] text-slate-500 rounded px-2 pt-[6px]
                                                                pb-1"
                                                            >
                                                                Source:
                                                                {
                                                                    item?.stock?.productStockDetails
                                                                        ?.stockLevel
                                                                }
                                                            </span>

                                                            <span
                                                                className="bg-[#EEF2F9] text-slate-500 rounded px-2 pt-[6px]
                                                                pb-1"
                                                            >
                                                                Destination:
                                                                {item?.adjustment?.quantity}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <Button
                                                    btnText="Retry"
                                                    icon={
                                                        <i className="ri-restart-line text-gun-metal"></i>
                                                    }
                                                    btnClassname="!bg-[#FFB903] !px-4 !py-2.5 !w-fit"
                                                    btnType="textFirst"
                                                    onClick={() => {
                                                        handleRetryFailedStock(item?.stock?._id);
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="w-full flex items-center gap-2 px-8 py-4 border-t border-slate-200 ">
                        <div className="w-full">
                            <Button
                                btnText={"Adjust more stock"}
                                type={"button"}
                                btnClassname="!bg-tradeally-neutral-20"
                                onClick={() => {
                                    setSuccessModal(false);
                                    setProduct([]);
                                    setAddSelectedProduct([]);
                                    setSelectedProduct([]);
                                    setStockData({});
                                    setWarehouseId("");
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                onClick={() => {
                                    navigate("/dashboard/inventory/stock-control?tabId=2");
                                    setSuccessModal(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={approvalModal}
                showCloseIcon={false}
                tailwindClassName="w-[42%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="px-10 pt-8 ">
                        <div className="text-center mx-auto font-rocGroteskMedium">
                            <p className="text-xl mb-1 text-center  text-g-75">
                                Stock Adjustment Initiated
                            </p>
                            <p className="text-sm mx-auto text-[#6B778C] max-w-[492px] text-center">
                                You've successfully initiated a stock adjustment. Approval is now
                                pending. Please await confirmation from relevant personnel.
                            </p>
                            <p className=" text-slate-500 mt-[28px] text-xs">
                                STOCK ADJUSTMENT STATUS
                            </p>
                        </div>

                        <div
                            className="mt-4 mb-[34px] rounded-lg border border-slate-200
                        shadow-[0px_4px_32px_0px_rgba(0,0,0,0.06),0px_0px_4px_0px_rgba(0,0,0,0.04)] p-6"
                        >
                            <div className="flex gap-5">
                                <div className="flex flex-col items-center ">
                                    <i className="ri-checkbox-circle-fill text-[#4ADE80] text-2xl justify-end leading-none"></i>
                                    <div className="border-dashed border-l border-l-slate-300 h-[70px]"></div>
                                    <div className="rounded-full bg-[#FFFBEB] w-6 h-6 flex items-center justify-center">
                                        <i className="ri-timer-2-fill text-[#F59E0B] text-lg"></i>
                                    </div>
                                    <div className="border-dashed border-l border-l-slate-300 h-[70px]"></div>
                                    <div className="rounded-full bg-[#FFFBEB] w-6 h-6 flex items-center justify-center">
                                        <i className="ri-timer-2-fill text-[#F59E0B] text-lg"></i>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-between font-rocGroteskMedium text-xs w-full">
                                    <div className="flex justify-between items-start w-full">
                                        <div className="text-gun-metal">
                                            <p className="">Stock Adjustment Initiated</p>
                                            <p className="">You've initiated a stock adjustment.</p>
                                        </div>
                                        <p className="text-slate-500">May 25, 2023 • 6:30 AM</p>
                                    </div>
                                    <div className="flex justify-between items-start w-full">
                                        <div className="text-slate-500">
                                            <p className="">Pending Approval</p>
                                            <p className="">
                                                Awaiting review by authorized personnel.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-start w-full">
                                        <div className="text-slate-500">
                                            <p className="">Stock adjustment approved</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex items-center gap-2 px-8 py-4 border-t border-slate-200 ">
                        <div className="w-full">
                            <Button
                                btnText={"Adjust more stock"}
                                type={"button"}
                                btnClassname="!bg-tradeally-neutral-20"
                                onClick={() => {
                                    setSuccessModal(false);
                                    setProduct([]);
                                    setAddSelectedProduct([]);
                                    setSelectedProduct([]);
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                onClick={() => {
                                    navigate("/dashboard/inventory/stock-control?tabId=2");
                                    setSuccessModal(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default NewStockAdjustment;
