import React, { useEffect, useState } from "react";
import AuthFrame from "./AuthFrame";
import TextInput from "components/common/InputField/TextInput";
import Button from "components/common/Button";
import { Link, useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import SelectInput from "components/common/InputField/SelectInput";
import countries from "variables/countries";
import { authActions } from "redux/Ldb/actions";
import config from "config/config";
import Checkbox from "components/common/InputField/Checkbox";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";

const Signup = () => {
    const [signupData, setSignupData] = useState({
        businessName: "",
        workEmail: "",
        phoneExt: "",
        phoneNo: "",
        password: "",
        agreed: false,
    });
    const [checks, setChecks] = useState(0);
    const { registeringUser, registerUserSuccess } = useAppSelector((state) => state.auth);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleChange = (evt) => {
        const { name, value } = evt.target;

        setSignupData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const body = {
            profile: {
                businessName: signupData.businessName,
            },
            email: signupData.workEmail,
            password: signupData.password,
            mobile: signupData.phoneExt + signupData.phoneNo,
            rawMobile: signupData.phoneNo,
            mobileExtension: signupData.phoneExt,
        };

        dispatch(authActions.register(body));
    };

    const hangleGoogleSignup = () => {
        window.open(`${config.API_URL}/api/v1/ldb/auth/google/signup`);
    };

    useEffect(() => {
        if (Boolean(registerUserSuccess)) {
            navigate(`/registration-success/${registerUserSuccess?.user?.token}`);
        }
    }, [registerUserSuccess, navigate]);

    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const isLowerCasePresent = lowercaseRegex.test(signupData?.password);
    const isUpperCasePresent = uppercaseRegex.test(signupData?.password);
    const isSpecialCharacterPresent = specialCharacterRegex.test(signupData?.password);

    useEffect(() => {
        let newChecks = 0;

        if (isLowerCasePresent) {
            newChecks++;
        }
        if (isUpperCasePresent) {
            newChecks++;
        }
        if (isSpecialCharacterPresent) {
            newChecks++;
        }
        if (signupData?.password?.length > 8) {
            newChecks++;
        }

        if (newChecks !== checks) {
            setChecks(newChecks);
        }
    }, [
        isSpecialCharacterPresent,
        signupData?.password,
        isUpperCasePresent,
        isLowerCasePresent,
        checks,
    ]);

    return (
        <AuthFrame>
            <div className="w-[48%] max-lg:w-[90%]">
                <div className="mb-6">
                    <a href="/">
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702538223/Tradeally_2.0/synthAlly_logo_skcywb.svg"
                            alt="trade ally logo"
                            width={157}
                            height={29}
                        />
                    </a>
                </div>
                <div className="mb-[38px]">
                    <p className="text-[24px] leading-[40px] font-rocGroteskMedium text-p-70">
                        Start your 7-day free trial
                    </p>
                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                        No credit card required. Cancel anytime
                    </p>
                </div>
                <form onSubmit={handleSubmit}>
                    <TextInput
                        name={"workEmail"}
                        type={"email"}
                        value={signupData.workEmail}
                        onChange={handleChange}
                        placeholder={"Enter your work email"}
                        required={true}
                        containerClassname="mb-6"
                        inputClassName={
                            "!placeholder:text-slate-400 !placeholder:font-rocGroteskMedium"
                        }
                    />
                    <TextInput
                        name={"businessName"}
                        type={"text"}
                        value={signupData.businessName}
                        onChange={handleChange}
                        placeholder={"Company/business name"}
                        required={true}
                        containerClassname="mb-6"
                        inputClassName={
                            "!placeholder:text-slate-400 !placeholder:font-rocGroteskMedium"
                        }
                    />

                    <div className="w-full grid grid-cols-[115px_calc(100%-119px)] gap-1 mb-6">
                        <SelectInput
                            value={signupData.phoneExt}
                            name="phoneExt"
                            placeholder={"Ext"}
                            handleChange={(name, value) =>
                                handleChange({ target: { name, value } })
                            }
                            isRequired={true}
                            inputClassName={
                                "!placeholder:text-slate-400 !placeholder:font-rocGroteskMedium"
                            }
                            dropdownOptions={countries
                                .filter((data) => Boolean(data?.codes[0]))
                                .sort((p1, p2) =>
                                    p1.codes[0] > p2.codes[0]
                                        ? 1
                                        : p1.codes[0] < p2.codes[0]
                                        ? -1
                                        : 0
                                )
                                .map((item) => ({
                                    label: item.codes[0]?.replace(" ", ""),
                                    value: item.codes[0]?.replace(" ", ""),
                                    icon: (
                                        <ReactCountryFlag
                                            countryCode={item?.abbreviation}
                                            svg
                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                            cdnSuffix="svg"
                                            style={{
                                                width: "16px",
                                                height: "16px",
                                                borderRadius: "50%",
                                                marginTop: "-4px",
                                            }}
                                        />
                                    ),
                                }))}
                        />
                        <TextInput
                            name={"phoneNo"}
                            type={"number"}
                            value={signupData.phoneNo}
                            onChange={handleChange}
                            placeholder={"Phone number"}
                            minLength={10}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            required={true}
                            pattern={"^[0-9]+$"}
                            title={"must be digits"}
                            inputClassName={
                                "!placeholder:text-slate-400 !placeholder:font-rocGroteskMedium"
                            }
                        />
                    </div>
                    <div className="mb-6">
                        <TextInput
                            name={"password"}
                            type={"password"}
                            value={signupData.password}
                            onChange={handleChange}
                            placeholder={"Enter a password"}
                            required={true}
                            minLength={8}
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).+$"
                            title="Password must contain at least one lowercase letter, one uppercase letter, and one special character."
                            containerClassname=""
                            inputClassName={
                                "!placeholder:text-slate-400 !placeholder:font-rocGroteskMedium"
                            }
                        />
                        <p className="text-[10px] leading-4 text-gm-35 mb-[10px]">
                            Password should contain at least 1 uppercase, lowercase, 8 characters
                            and 1 special character. (@, !, #, etc.)
                        </p>
                        {signupData?.password?.length > 0 && (
                            <div className="mb-[20px] flex space-x-1 w-full">
                                <div className=" flex w-full items-center space-x-3">
                                    <div
                                        className={`w-full border-[3px] rounded ${
                                            checks < 1 ? "border-n-40" : "border-[#FF0000]"
                                        } `}
                                    ></div>
                                    <div
                                        className={`w-full border-[3px] rounded  ${
                                            checks < 2 ? "border-n-40" : "border-[#FBBF24]"
                                        }`}
                                    ></div>
                                    <div
                                        className={`w-full border-[3px] rounded  ${
                                            checks < 3 ? "border-n-40" : "border-carribean-green"
                                        }`}
                                    ></div>
                                    <div
                                        className={`w-full border-[3px] rounded  ${
                                            checks < 4 ? "border-n-40" : "border-carribean-green"
                                        }`}
                                    ></div>
                                </div>
                                <p className="text-xs text-[#FF0000]">{checks === 1 && "Weak"}</p>
                                <p className="text-xs text-[#FBBF24]">{checks === 2 && "Good"}</p>
                                <p className="text-xs text-carribean-green">
                                    {checks === 3 && "Strong"}
                                </p>
                                <p className="text-xs text-carribean-green whitespace-nowrap">
                                    {checks === 4 && "Very Strong"}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="flex items-center gap-2 mb-6">
                        <Checkbox
                            name="agreed"
                            isChecked={signupData.agreed}
                            required
                            onChange={(evt) => {
                                handleChange({
                                    target: {
                                        name: "agreed",
                                        value: evt.target.checked,
                                    },
                                });
                            }}
                            className={"!h-[14px] !w-[14px] !rounded-[4px]"}
                        />
                        <label
                            htmlFor="agreed"
                            className="text-xs text-slate-500 font-rocGroteskMedium mt-[2px]"
                        >
                            I agree with SynthAlly's{" "}
                            <Link to="/terms" className="underline font-rocGroteskMedium">
                                terms
                            </Link>{" "}
                            and{" "}
                            <Link to="/terms" className="underline font-rocGroteskMedium">
                                conditions
                            </Link>
                        </label>
                    </div>
                    <div className="w-full mb-6">
                        <Button
                            btnText={"Get started for free"}
                            type={"submit"}
                            isLoading={registeringUser}
                        />
                    </div>
                    <div className="flex items-center gap-[10px] w-full mb-6">
                        <hr className="w-[46%]" />{" "}
                        <span className="text-sm whitespace-nowrap text-slate-400 font-rocGroteskMedium">
                            Or sign up with your work email
                        </span>{" "}
                        <hr className="w-[46%]" />
                    </div>
                    <div className="w-full mb-6">
                        <Button
                            btnClassname={"!bg-white border"}
                            btnCustomContent={
                                <div className="flex items-center gap-2">
                                    <img
                                        src={
                                            "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1690905410/Web%20App/google_logo_u0iqig.svg"
                                        }
                                        width={24}
                                        height={24}
                                        alt="google logo"
                                    />
                                    <span className="text-[14px] font-rocGroteskMedium text-dark-gray-2">
                                        Sign up with google
                                    </span>
                                </div>
                            }
                            onClick={hangleGoogleSignup}
                            type={"button"}
                        />
                    </div>
                    <p className="text-center">
                        <span className="text-xs text-gm-45 font-rocGroteskRegular">
                            Already have an account?
                        </span>{" "}
                        <Link
                            to={"/login"}
                            className="text-xs text-carribean-green font-rocGroteskBold cursor-pointer"
                        >
                            Log in
                        </Link>
                    </p>
                </form>
            </div>
        </AuthFrame>
    );
};

export default Signup;
