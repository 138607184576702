import React, { useEffect, useState } from "react";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import Button from "components/common/Button";
import TextInput from "components/common/InputField/TextInput";
import TabsContainer from "components/common/TabsContainer";
import StocksList from "./StocksList";
import TransferStock from "./TransferStock";
import BreadCrumb from "components/common/BreadCrumb";
import Dropdown from "components/common/Dropdown";
import { useNavigate, useSearchParams } from "react-router-dom";
import PageFrame from "components/layout/PageFrame";
import { debounce, truncate } from "lodash";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import AdjustingStock from "./StockAdjustment";
import StockSupplied from "./SuppliedStock";
import { getEditPermissionList } from "helpers/getEditPermissionList";
import SuppliedStockPdf from "components/views/Ldb/Dashboard/Inventory/StockControl/SuppliedStockPdf";
import useGetUserProfile from "hooks/useGetUserProfile";

const StockControl = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [params] = useSearchParams();
    const tabId = params.get("tabId");

    const tabs = ["Stock Entry", "Transfer Stocks", "Adjusting Stocks", "Supplied Stocks"];
    const [profile] = useGetUserProfile();
    const [activeTab, setActiveTab] = useState(0);
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e.target.value);
    }, 1500);
    const [stockCategory, setStockCategory] = useState<string>();
    const [warehouseData, setWarehouseData] = useState<{ [key: string]: any }[]>([]);
    const [debouncedWarehouseSearch, setDebouncedWarehouseSearch] = useState<string>("");
    const [location, setLocation] = useState("");
    const [warehouse, setWarehouse] = useState<string>("");
    const [suppliedStockData, setSuppliedStockData] = useState<{ [key: string]: any }[]>([]);

    const {
        fetchedWarehousesSuccess,
        fetchedSuppliedStockListAllDataSuccess,
        fetchingSuppliedStockListAllData,
    } = useAppSelector((state) => state.inventory);

    useEffect(() => {
        if (tabId) {
            setActiveTab(+tabId);
        } else {
            setActiveTab(0);
        }
    }, [tabId]);
    useEffect(() => {
        if (Boolean(fetchedSuppliedStockListAllDataSuccess)) {
            // setSuppliedStockData(fetchedSuppliedStockListAllDataSuccess?.stock);
        }
    }, [fetchedSuppliedStockListAllDataSuccess]);

    useEffect(() => {
        if (Boolean(fetchedWarehousesSuccess)) {
            setWarehouseData(fetchedWarehousesSuccess?.warehouses?.data);
        }
    }, [fetchedWarehousesSuccess]);

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouses(15, debouncedWarehouseSearch));
    }, [dispatch, debouncedWarehouseSearch]);

    const handleWarehouseDebouncedChange = debounce((e) => {
        setDebouncedWarehouseSearch(e.target.value);
    }, 1500);

    useEffect(() => {
        dispatch(inventoryActions?.fetchSuppliedStockListAllData());
    }, [dispatch]);

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <StocksList
                        debouncedSearch={debouncedSearch}
                        location={location}
                        stockCategory={stockCategory}
                    />
                );
            case 1:
                return (
                    <TransferStock
                        debouncedSearch={debouncedSearch}
                        location={location}
                        stockCategory={stockCategory}
                    />
                );
            case 2:
                return (
                    <AdjustingStock
                        debouncedSearch={debouncedSearch}
                        location={location}
                        stockCategory={stockCategory}
                    />
                );
            case 3:
                return (
                    <StockSupplied
                        debouncedSearch={debouncedSearch}
                        location={location}
                        stockCategory={stockCategory}
                    />
                );
            default:
                return;
        }
    };
    return (
        <PageFrame containerClassName={"!h-[calc(100vh-108px)]"}>
            <div>
                <div className="mb-[33px] flex item-center justify-between ">
                    <BreadCrumb
                        parentCrumb="Inventory Management"
                        size="large"
                        parentClassName="!text-slate-400 !text-xl "
                        childrenCrumb={[
                            {
                                title: "Stock Control",
                                path: "/dashboard/inventory/stock-control",
                            },
                        ]}
                        childClassName="!text-lg !text-slate-900"
                    />

                    <div className="gap-2 flex !w-auto justify-end">
                        {activeTab === 0 && (
                            <Button
                                btnText="Record Stock"
                                btnClassname="!bg-[#F4F5F7] !py-[10px]  !text-gun-metal !text-[13px] !w-auto"
                                onClick={() => navigate("/dashboard/inventory/order-stock")}
                                permissions={getEditPermissionList("inventory")}
                            />
                        )}
                        {activeTab === 2 && (
                            <Button
                                btnText="New adjustment"
                                btnClassname="!py-[10px]  !text-black !text-[13px] !w-auto !bg-[#ffb903]"
                                onClick={() =>
                                    navigate("/dashboard/inventory/stock-control/stock-adjustment")
                                }
                                permissions={getEditPermissionList("inventory")}
                            />
                        )}
                        {activeTab === 3 && (
                            <Button
                                btnText="Record supplied goods"
                                btnClassname="!bg-[#F4F5F7] !py-[10px]  !text-gun-metal !text-[13px] !w-auto"
                                onClick={() => navigate("/dashboard/inventory/supplied-stock")}
                                permissions={getEditPermissionList("inventory")}
                            />
                        )}
                        {activeTab !== 2 && (
                            <Button
                                btnText={`${activeTab === 0 ? "Reorder Stock" : "Transfer Stock"}`}
                                btnClassname="!py-[10px]  !text-black !text-[13px] !w-auto !bg-[#ffb903]"
                                onClick={() =>
                                    activeTab === 0
                                        ? navigate(
                                              "/dashboard/inventory/stock-control/new-transfer"
                                          )
                                        : navigate(
                                              "/dashboard/inventory/stock-control/new-transfer?method=warehouse"
                                          )
                                }
                                permissions={getEditPermissionList("inventory")}
                            />
                        )}
                    </div>
                </div>

                <div className="relative">
                    <div className="absolute right-0 bg-[#fcfcfc] pl-2">
                        <div className="flex gap-2 pb-[2px]">
                            <TextInput
                                name="search"
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onInput={handleDebouncedChange}
                                inputPlaceholder="Search"
                                inputClassName={
                                    "!h-[40px] pl-[0px] !mb-0 !font-rocGroteskMedium !text-slate-400"
                                }
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-[4px] !w-[160px] !h-[40px] !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                                }
                            />
                            <div className="flex items-center gap-2">
                                {activeTab === 3 &&
                                    (fetchingSuppliedStockListAllData || suppliedStockData?.length ===
                                    0 ? (
                                        <Button
                                            btnText={"Export"}
                                            btnClassname={
                                                "!w-[100px] !h-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20 !rounded-[4px] !text-gm-50"
                                            }
                                            disabled
                                        />
                                    ) : (
                                        <PDFDownloadLink
                                            document={
                                                <SuppliedStockPdf
                                                    suppliedStockData={suppliedStockData || null}
                                                    businessLogo={profile?.profile?.avatar}
                                                />
                                            }
                                            fileName={"Supplied_Stock.pdf"}
                                        >
                                            {({ loading }) => (
                                                <>
                                                    {loading ? (
                                                        <Button
                                                            btnClassname={
                                                                "!w-[100px] !text-gm-50 !h-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20 !rounded-[4px]"
                                                            }
                                                            isLoading={true}
                                                        />
                                                    ) : (
                                                        <Button
                                                            btnText={"Export"}
                                                            btnClassname={
                                                                "!w-[100px] !h-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20 !rounded-[4px] !text-gm-50"
                                                            }
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </PDFDownloadLink>
                                    ))}
                                <Dropdown
                                    name="location"
                                    value={""}
                                    dropdownTriggerClassName={" "}
                                    handleChange={(name, value) => {
                                        setLocation(value?.split("-")?.[1]);
                                        setWarehouse(value?.split("-")?.[0]);
                                    }}
                                    dropdown={
                                        <div className="relative">
                                            <TextInput
                                                value={truncate(warehouse, { length: 13 })}
                                                type={"text"}
                                                onChange={(e) => setWarehouse(e.target.value)}
                                                onInput={(e) => handleWarehouseDebouncedChange(e)}
                                                inputPlaceholder={"Location"}
                                                required={false}
                                                inputClassName="!w-[145px] !h-10 !border-0 placeholder:!font-rocGroteskMedium placeholder:!text-gm-50 placeholder:!text-[13px] !font-rocGroteskMedium !text-[13px]"
                                                inputContainerClassname={"!border-0 !h-10"}
                                                containerClassname="bg-white flex items-center h-10 gap-2 py-2.5 shadow-boxShadow-2 border border-tradeally-neutral-20"
                                            />
                                            {Boolean(location) && (
                                                <i
                                                    onClick={(e) => {
                                                        e?.stopPropagation();
                                                        setLocation("");
                                                        setWarehouse("");
                                                    }}
                                                    className="ri-close-circle-fill text-sm text-slate-500 absolute right-[30px] top-[10px]"
                                                ></i>
                                            )}
                                            <i className="ri-arrow-down-s-line text-lg text-gm-50 absolute right-[12px] top-[6px]"></i>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 left-[-60px] !mt-0 !w-[159px] rounded !p-0 !top-[105%] !max-h-[290px] overflow-y-auto`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={warehouseData?.map((warehouse) => ({
                                        label: warehouse?.warehouseName,
                                        value: warehouse?.warehouseName + "-" + warehouse?._id,
                                    }))}
                                />

                                <Dropdown
                                    name="stockCategory"
                                    value={""}
                                    dropdownTriggerClassName={" "}
                                    handleChange={(name, value) => setStockCategory(value)}
                                    dropdown={
                                        <div className="bg-white flex items-center h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-tradeally-neutral-20 !rounded">
                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                {truncate(stockCategory, { length: 12 }) ||
                                                    "Category"}
                                            </span>
                                            {Boolean(stockCategory) && (
                                                <i
                                                    onClick={(e) => {
                                                        e?.stopPropagation();
                                                        setStockCategory("");
                                                    }}
                                                    className="ri-close-circle-fill text-sm text-slate-500"
                                                ></i>
                                            )}
                                            <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 left-[-190px] !mt-0  !w-[159px] rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={[
                                        {
                                            label: "Raw Materials",
                                            value: "Raw Material",
                                        },
                                        {
                                            label: "Work-in-Progress (WIP)",
                                            value: "Work-In-Progress (WIP)",
                                        },
                                        {
                                            label: "Finished Goods",
                                            value: "Finished Goods",
                                        },
                                        {
                                            label: "Maintenance, Repair, and Overall (MRO)",
                                            value: "Maintenance, Repair, and Overall (MRO)",
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <TabsContainer
                        tabs={tabs}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        component={displayActiveTab()}
                        className="!px-0"
                        itemClassName="!pb-2"
                    />
                </div>
            </div>
        </PageFrame>
    );
};

export default StockControl;
