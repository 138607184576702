import config from "config/config";
import {authService} from "./authService";

export const shipmentService = {
    getShipments,
    getSingleShipment,
    assignShipmentToMember,
};

async function getShipments(limit?: string | number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/shipment?limit=${limit}${
            Boolean(search) ? "&search=" + search : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleShipment(shipmentId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/shipment/single/shipment/${shipmentId}`,
        requestOptions
    );
    return res;
}

async function assignShipmentToMember(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/shipment/ldb/member`,
        requestOptions
    );
    return res;
}
