import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../../../components/common/CustomTable";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import Button from "components/common/Button";
import { useNavigate } from "react-router-dom";
import { onScroll } from "helpers";
import Loader from "components/common/Loader";
import { getEditPermissionList } from "helpers/getEditPermissionList";

type StockProps = {
    debouncedSearch: string;
    location: string;
    stockCategory: string;
};

const TransferStock = ({ debouncedSearch, location, stockCategory }: StockProps) => {
    const addLimit = 10;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [transferStockList, setTransferStockList] = useState<any>([]);
    const [limit, setLimit] = useState<number>(addLimit);
    const [loadMore, setLoadMore] = useState<boolean>(false);
    const [paginate, setPaginate] = useState<{
        current: number | string;
        number_of_pages: number | string;
    }>({
        current: "",
        number_of_pages: "",
    });

    const { fetchingTransferStocks, fetchedTransferStocksSuccess } = useAppSelector(
        (state) => state.inventory
    );

    useEffect(() => {
        if (Boolean(fetchedTransferStocksSuccess)) {
            setTransferStockList(fetchedTransferStocksSuccess?.stock);
            setPaginate({
                current: fetchedTransferStocksSuccess?.pagination?.current,
                number_of_pages: fetchedTransferStocksSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedTransferStocksSuccess]);

    const scrollTable = document.getElementById("scrollTable");

    const onTableScroll = useCallback(
        () =>
            onScroll(
                scrollTable,
                paginate?.current as number,
                paginate?.number_of_pages as number,
                () => {
                    setLimit(() => limit + addLimit);
                    setLoadMore(true);
                }
            ),
        [scrollTable, paginate]
    );
    useEffect(() => {
        scrollTable?.addEventListener("scroll", onTableScroll);
        return () => {
            scrollTable?.removeEventListener("scroll", onTableScroll);
        };
    }, [scrollTable, onTableScroll]);

    useEffect(() => {
        if (!Boolean(fetchingTransferStocks)) {
            dispatch(
                inventoryActions.fetchTransferStocks(
                    limit,
                    debouncedSearch,
                    location,
                    stockCategory
                )
            );
        }
    }, [dispatch, limit, debouncedSearch, location, stockCategory]);

    const handleScroll = (e) => {
        if (e.target.scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const tableHeader = [
        {
            title: "Reference",
            widthClass: "w-[12%] !border-b !border-slate-200",
            itemClass: "",
        },
        {
            title: "From",
            widthClass: "w-[22%] !border-b !border-slate-200",
            itemClass: "",
        },
        {
            title: "To",
            widthClass: "w-[22%] !border-b !border-slate-200",
            itemClass: "",
        },
        {
            title: "Schedule Date",
            widthClass: "w-[22%] !border-b !border-slate-200",
            itemClass: "",
        },
    ];

    const tableBody =
        transferStockList?.length > 0
            ? transferStockList?.map((item, idx) => [
                  {
                      content: (
                          <div className="py-[18px]" key={idx}>
                              <p className=""> {item?.reference}</p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                      cellClickAction: () =>
                          navigate(
                              `/dashboard/inventory/stock-control/transfer-stock/${item?._id}`
                          ),
                  },
                  {
                      content: (
                          <div className="py-[18px] flex items-center gap-3">
                              <p className="">{item?.warehouseData[0]?.warehouseName}</p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-[18px]">
                              <p className="">{item?.warehouseData[1]?.warehouseName} </p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-4 flex items-center gap-2">
                              <p className="">
                                  {moment(item?.movementDate).format("MMM D, YYYY • h:mm:ss A")}
                              </p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                 
              ])
            : [];

    return (
        <div>
            {fetchingTransferStocks && transferStockList?.length === 0 ? (
                <div className={`w-full min-h-[30vh]  flex items-center  justify-center`}>
                    <Loader color={"gm-50"} size={8} />
                </div>
            ) : (
                <>
                    {transferStockList?.length > 0 ? (
                        <div>
                            <div
                                id="scrollTable"
                                className="overflow-x-scroll mt-6 max-h-[calc(100vh-220px)]"
                                onScroll={handleScroll}
                            >
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isScrollable={true}
                                    isScrolled={isScrolled}
                                    isCellBordered={false}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs !bg-slate-50"
                                    headerContainerClass="!bg-slate-50"
                                    tableClass=" w-full !font-rocGroteskMedium cursor-pointer"
                                    tbodyClass="!text-sm !text-gm-50"
                                    bodyItemClass="hover:!bg-slate-50 "
                                />
                            </div>

                            {loadMore && fetchingTransferStocks && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {paginate?.current === paginate?.number_of_pages && (
                                <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            {debouncedSearch || stockCategory || location ? (
                                <div className=" min-h-[30vh] my text-slate-500  flex items-center  justify-center text-sm font-rocGroteskMedium">
                                    <p className=""> "No data matches your search query" </p>
                                </div>
                            ) : (
                                <div className="w-full flex justify-center">
                                    <div
                                        className="flex mt-[85px] p-10 rounded-lg border border-slate-100 text-slate-500 gap-6
                    font-rocGroteskMedium max-w-[635px] shadow-tableNoDataShadow"
                                    >
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1705573225/arrow-left-right-fill_r7n5yi.svg"
                                            alt="no data "
                                            className=""
                                        />

                                        <div className="">
                                            <p className="text-black text-base  pb-[9px]">
                                                No stock transfers recorded yet.
                                            </p>
                                            <p className="text-sm text-slate-500 pb-6">
                                                Currently, there are no recorded stock details for
                                                your products. Ensure accurate tracking by adding
                                                stock records, each tagged with a unique batch
                                                number for easy identification and management.
                                            </p>
                                            <Button
                                                btnText="Transfer Stock"
                                                btnClassname="!py-[10px]  !text-black !text-[13px] !w-auto"
                                                onClick={() =>
                                                    navigate(
                                                        "/dashboard/inventory/stock-control/new-transfer"
                                                    )
                                                }
                                                permissions={getEditPermissionList("inventory")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default TransferStock;
