import React, {useEffect, useState} from "react";
import Dropdown from "../Dropdown";
import {authActions, settingsActions} from "redux/Ldb/actions";
import {truncate} from "lodash";
import {Link, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks/storeHooks";
import ProgressBar from "../ProgressBar";
import ModalContainer from "../ModalContainer";
import TextInput from "../InputField/TextInput";
import Button from "../Button";
import Loader from "../Loader";
import {transformString} from "helpers/transformStrings";
import {io} from "socket.io-client";
import config from "config/config";

const socket = io(`${config.API_URL}`);

const DashboardNav = ({setSidebarCollapsed}) => {
    const [profile, setProfile] = useState<{[key: string]: any}>({});
    const [showCreditPurchase, setShowCreditPurchase] = useState(false);
    const [purchaseCreditModal, setPurchaseCreditModal] = useState(false);
    const [creditUsageHistory, setCreditUsageHistory] = useState([]);
    const [credit, setCredit] = useState<number | string>(0);
    const [purchaseCreditFailure, setPurchaseCreditFailure] = useState(false);
    const [purchaseCreditLimit, setPurchaseCreditLimit] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {fetchUserProfileSuccess} = useAppSelector((state) => state.auth);
    const {
        fetchCreditHistorySuccess,
        fetchingCreditHistory,
        fetchStripeSession,
        fetchStripeSessionSuccess,
    } = useAppSelector((state) => state.settings);
    const nameArr = profile?.profile?.businessName
        ? profile?.profile?.businessName?.split(" ")
        : [];
    const initials = nameArr
        ?.map((name: string) => name[0]?.toUpperCase())
        ?.join("")
        ?.slice(0, 3);

    useEffect(() => {
        if (!Boolean(fetchUserProfileSuccess)) {
            dispatch(authActions.getLdbProfile());
        }
    }, [dispatch, fetchUserProfileSuccess]);

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setProfile(fetchUserProfileSuccess);
        }
    }, [fetchUserProfileSuccess]);
    useEffect(() => {
        dispatch(settingsActions.getCreditHistory());
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchCreditHistorySuccess)) {
            setCreditUsageHistory(fetchCreditHistorySuccess?.creditUsageHistory);
        } else {
        }
    }, [fetchCreditHistorySuccess]);

    const OnSubmitPurchase = () => {
        if (Number(credit) > 0) {
            dispatch(settingsActions.getStripeSession(credit));
        }
    };
    useEffect(() => {
        if (Boolean(fetchStripeSessionSuccess)) {
            localStorage.setItem("credit", String(credit));
            setPurchaseCreditFailure(false);
            setPurchaseCreditModal(false);
            window.location.href = fetchStripeSessionSuccess?.session?.checkoutUrl;
        }
    }, [fetchStripeSessionSuccess]);

    useEffect(() => {
        socket.on("INSUFFICIENT_CREDIT_ERROR", (data) => {
            if (data?.ldbId === profile?._id) {
                setPurchaseCreditLimit(true);
            }
        });

        // Remove event listener on component unmount
        return () => {
            socket.off("INSUFFICIENT_CREDIT_ERROR");
        };
    }, [profile?._id]);

    return (
        <div className="w-full">
            <nav className="w-full h-[84px] max-lg:h-[65px] flex justify-between pr-[47px] pl-[34px] max-lg:px-6 items-center bg-white border-b border-slate-100">
                <div className="mr-[56px]">
                    <Link to={"/dashboard"}>
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702538223/Tradeally_2.0/synthAlly_logo_skcywb.svg"
                            alt="synthAlly logo"
                            className="w-[128px] h-6 max-lg:w-[200px] max-lg:h-[28px]"
                        />
                    </Link>
                </div>

                <div className="w-full flex items-center justify-end pl-[37px]">
                    {/* <div className="w-[30%]">
                    <TextInput
                        name={"navSearch"}
                        type={"text"}
                        inputPlaceholder={"Search"}
                        inputClassName={"!h-[36px] pl-[0px] !mb-0 text-sm"}
                        leftIcon={
                            <i className="ri-search-line before:content-['\f0d1] text-slate-400"></i>
                        }
                        inputContainerClassname={
                            "!rounded-[4px] !pl-[40px] !bg-slate-50 border-slate-100 !h-[36px]"
                        }
                    />
                </div> */}
                    {!fetchingCreditHistory && (
                        <div
                            className="pr-3 cursor-pointer flex space-x-2 items-center w-[148px] max-lg:hidden"
                            onClick={() => setShowCreditPurchase(true)}
                        >
                            <i className="ri-sparkling-fill text-xl text-center inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#5C58FF] from-0% to-[#FF3D1D] to-100%"></i>
                            <div>
                                <p className="text-sm text-center font-rocGroteskMedium inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                                    {`${fetchCreditHistorySuccess?.creditBalance} AI credits`}
                                </p>
                                <div className=" ">
                                    <ProgressBar
                                        className={" !bg-gradient-to-r from-[#5C58FF] to-[#FF3D1D]"}
                                        progress={fetchCreditHistorySuccess?.percentageCreditUsed}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex items-center gap-4">
                        {/* <div className="cursor-pointer">
                        <i className="ri-question-line text-xl text-slate-700"></i>
                    </div>
                    <div className="relative cursor-pointer">
                        <i className="ri-chat-4-fill text-xl text-slate-700"></i>
                        <p className="p-[2px] flex items-center justify-center absolute top-0 right-0 bg-r-50 rounded-[1px] text-white font-rocGroteskMedium text-[5px]">
                            12
                        </p>
                    </div>
                    <div className="relative cursor-pointer">
                        <i className="ri-notification-3-fill text-xl text-slate-700"></i>
                        <p className="p-[2px] flex items-center justify-center absolute top-0 right-0 bg-r-50 rounded-[1px] text-white font-rocGroteskMedium text-[5px]">
                            12
                        </p>
                    </div> */}
                        <Dropdown
                            value={""}
                            dropdown={
                                <div>
                                    <div className="max-lg:hidden  grid grid-cols-[0.3fr_2fr_0.2fr] items-center gap-2 py-2.5 px-2.5 w-[224px] border rounded">
                                        {Boolean(profile?.profile?.avatar) ? (
                                            <img
                                                src={profile?.profile?.avatar}
                                                alt="logo"
                                                className="rounded-full w-[22px] h-[22px] border-[1.5px] border-g-75"
                                            />
                                        ) : (
                                            <div className=" rounded-full w-[22px] h-[22px] border-[1.5px] border-g-75 flex items-center justify-center text-[10px]">
                                                {initials}
                                            </div>
                                        )}
                                        <p className="text-sm capitalize">
                                            {truncate(profile?.profile?.businessName, {
                                                length: 20,
                                            })}
                                        </p>
                                        <div className="flex flex-col justify-center items-center gap-[1px] mt-[-21px]">
                                            <i className="ri-arrow-drop-up-fill h-[4px] before:content-['\ea55']"></i>
                                            <i className="ri-arrow-drop-down-fill h-[4px] before:content-['\ea4f']"></i>
                                        </div>
                                    </div>
                                    <div className="hidden max-lg:block">
                                        {Boolean(profile?.profile?.avatar) ? (
                                            <img
                                                src={profile?.profile?.avatar}
                                                alt="logo"
                                                className="rounded-full w-[30px] h-[30px] border-[1.5px] border-g-75"
                                            />
                                        ) : (
                                            <div className=" rounded-full w-[22px] h-[22px] border-[1.5px] border-g-75 flex items-center justify-center text-[10px]">
                                                {initials}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                            dropdownContainerClasses={
                                " max-lg:left-[-90px] top-[50px] !z-[100] shadow-faintShadow rounded !py-0 !border-0"
                            }
                            dropdownClassName={"!max-h-fit "}
                            dropdownItemsClasses={
                                "border-b border-slate-100 text-slate-500 last:border-none !px-2.5 "
                            }
                            name={"quickAction"}
                            dropdownOptions={[
                                {
                                    label: "Settings",
                                    value: "settings",
                                    icon: (
                                        <div className="bg-slate-50 w-[26px] h-[26px] rounded-full flex items-center justify-center">
                                            <i className="ri-settings-4-line text-slate-500"></i>
                                        </div>
                                    ),
                                    action: () => navigate("/dashboard/settings/0"),
                                },
                                {
                                    label: "Logout",
                                    value: "logout",
                                    icon: (
                                        <div className="bg-slate-50 w-[26px] h-[26px] rounded-full flex items-center justify-center">
                                            <i className="ri-logout-circle-line text-[#FF7E68]"></i>
                                        </div>
                                    ),
                                    action: () => {
                                        navigate("/login")
                                        dispatch(authActions.logOut())
                                    },
                                },
                            ]}
                        />
                    </div>
                </div>
                {showCreditPurchase && (
                    <ModalContainer
                        open={showCreditPurchase}
                        showCloseIcon={false}
                        modalClassName={"py-10 px-10 max-sm:p-5"}
                        tailwindClassName="w-[41.6%] max-lg:w-[95%]"
                        closeModal={() => {
                            setShowCreditPurchase(false);
                        }}
                    >
                        <div
                            className={` mt-10 py-6 px-8 bg-[white] rounded-lg shadow-faintShadow font-rocGroteskMedium  text-gm-50`}
                        >
                            <p className="text-base mb-8">Your business AI credit balance</p>
                            <div className="p-6 border mb-6 border-border-light shadow-sm rounded-md">
                                <p className="text-4xl font-rocGroteskMedium text-center inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                                    {fetchCreditHistorySuccess?.creditBalance}
                                </p>
                                <p className="text-sm">
                                    AI credit usage (
                                    {fetchCreditHistorySuccess?.percentageCreditUsed}
                                    %)
                                </p>
                                <div className="mt-2 mb-6">
                                    <ProgressBar
                                        className={" !bg-gradient-to-r from-[#5C58FF] to-[#FF3D1D]"}
                                        progress={fetchCreditHistorySuccess?.percentageCreditUsed}
                                    />
                                </div>
                                <div className="w-fit ">
                                    <Button
                                        btnClassname={
                                            "!py-3 !px-8 !bg-gradient-to-r from-[#5C58FF] to-[#FF3D1D]"
                                        }
                                        onClick={() => {
                                            setPurchaseCreditModal(true);
                                            setShowCreditPurchase(false);
                                        }}
                                        btnText={"Purchase more credit"}
                                        type={"button"}
                                        isLoading={false}
                                    />
                                </div>
                            </div>
                            <div>
                                <p className="text-lg">Usage history</p>
                                <p className="text-slate-500 text-sm font-rocGroteskMedium">
                                    Here is the list of items you have utilized your AI Credit for
                                    up to this point.
                                </p>
                                {!fetchingCreditHistory ? (
                                    <div className="mb-8">
                                        {creditUsageHistory && creditUsageHistory.length > 0 && (
                                            <>
                                                {creditUsageHistory.slice(0, -1).map((history) => (
                                                    <div className="py-3 border-slate-200 border-b flex justify-between text-gm-50 text-sm">
                                                        <p>{transformString(history?.action)}</p>
                                                        <p>{history?.totalAmount} used</p>
                                                    </div>
                                                ))}
                                                <div className="py-3 flex justify-between text-gm-50 text-sm">
                                                    <p>
                                                        {transformString(
                                                            creditUsageHistory[
                                                                creditUsageHistory.length - 1
                                                            ]?.action
                                                        )}
                                                    </p>
                                                    <p>
                                                        {
                                                            creditUsageHistory[
                                                                creditUsageHistory.length - 1
                                                            ]?.totalAmount
                                                        }{" "}
                                                        used
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                        {creditUsageHistory.length === 0 && (
                                            <div className="h-[200px] flex space-y-6 flex-col items-center justify-center">
                                                <i className="ri-sparkling-fill text-3xl text-[#C1C7D0]"></i>
                                                <h1 className="text-center w-[73%] text-sm text-[#6B778C]">
                                                    Your AI usage is currently empty. Your AI usage
                                                    for this task will be displayed here once you
                                                    start using it.
                                                </h1>
                                            </div>
                                        )}
                                        <div className="pt-4 pb-1 font-rocGroteskMedium flex justify-between text-slate-500 text-sm">
                                            <p>{"Total AI Credits bought this month"}</p>
                                            <p>
                                                {fetchCreditHistorySuccess?.creditBalance} Credits
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <Loader />
                                )}
                                <div className="mb-2">
                                    <Button
                                        btnText={"Close"}
                                        onClick={() => setShowCreditPurchase(false)}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalContainer>
                )}
                {purchaseCreditModal && (
                    <ModalContainer
                        open={purchaseCreditModal}
                        showCloseIcon={false}
                        modalClassName={"py-10 px-10 max-sm:p-5"}
                        tailwindClassName="w-[600px] w-[41.6%] max-lg:w-[95%]"
                        closeModal={() => {
                            setPurchaseCreditModal(false);
                        }}
                    >
                        <div className="bg-white p-8  shadow-cardShadow relative rounded-[8px]">
                            <h1 className="text-2xl font-rocGroteskMedium">
                                Get more done with SynthAlly AI
                            </h1>
                            <p className="text-sm font-rocGroteskMedium text-slate-500">
                                Go unlimited with SynthAlly AI for all members in your organization
                                workspace.
                            </p>
                            <div className="my-8">
                                <div className="text-sm mb-3 font-rocGroteskMedium flex items-center justify-between">
                                    <p className="text-gm-50">Amount of credit</p>
                                    <p className=" font-rocGroteskMedium text-center inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                                        $2 Per 1 credit
                                    </p>
                                </div>
                                <div className="flex space-x-2">
                                    <div className="w-[80%]">
                                        <TextInput
                                            value={credit as string}
                                            floatingPlaceholderClass={
                                                "!font-rocGroteskMedium !text-gm-50"
                                            }
                                            type={"text"}
                                            name="noOfCredit"
                                            inputContainerClassname={""}
                                            onChange={(e) => {
                                                if (!isNaN(Number(e.target.value))) {
                                                    setCredit(e.target.value);
                                                }
                                            }}
                                            required={true}
                                        />
                                    </div>
                                    <div className="flex space-x-2">
                                        <div
                                            className="border cursor-pointer flex justify-center items-center border-slate-200 p-3 h-12 w-12 rounded "
                                            onClick={() => {
                                                if (Number(credit) > 0) {
                                                    setCredit(Number(credit) - 1);
                                                }
                                            }}
                                        >
                                            <i className="ri-subtract-line text-slate-500"></i>
                                        </div>
                                        <div
                                            onClick={() => setCredit(Number(credit) + 1)}
                                            className="border cursor-pointer flex justify-center items-center border-slate-200 p-3 h-12 w-12 rounded "
                                        >
                                            <i className="ri-add-line text-slate-500"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-sm mt-5 font-rocGroteskMedium ">
                                    <p className="text-gm-50">Order summary</p>
                                    <div className="text-sm py-3 font-rocGroteskMedium flex items-center justify-between">
                                        <p className="text-slate-500">Amount of credit</p>
                                        <p className="text-slate-500">${Number(credit) * 2}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full space-x-2">
                               <div className="w-full">
                               <Button
                                    btnText={"Close"}
                                    type={"button"}
                                    btnClassname={"!bg-n-20 !text-gm-50"}
                                    isLoading={false}
                                    disabled={fetchStripeSession}
                                    onClick={() => {
                                        setPurchaseCreditModal(false);
                                        navigate("/dashboard/settings/2");
                                    }}
                                />
                               </div>
                               <div className="w-full">
                               <Button
                                    btnClassname={
                                        "!py-3 !px-8 !bg-gradient-to-r from-[#5C58FF] to-[#FF3D1D]"
                                    }
                                    onClick={OnSubmitPurchase}
                                    btnText={"Purchase more credit"}
                                    type={"button"}
                                    isLoading={fetchStripeSession}
                                />
                               </div>
                            </div>
                        </div>
                    </ModalContainer>
                )}
                {purchaseCreditFailure && (
                    <ModalContainer
                        open={purchaseCreditFailure}
                        showCloseIcon={false}
                        modalClassName={"py-10 px-10 max-sm:p-5"}
                        tailwindClassName="w-[34.7%] max-lg:w-[95%]"
                        closeModal={() => {
                            setPurchaseCreditFailure(false);
                            navigate("/dashboard/settings/2");
                        }}
                    >
                        <div className="bg-white !px-2 p-8  rounded-[12px]">
                            <div className="mt-4 px-6">
                                <div className="text-center flex justify-center flex-col items-center space-y-5">
                                    <div className="w-14 h-14 flex justify-center items-center bg-r-50 shadow-icon-red rounded-full">
                                        <i className="ri-close-fill text-[29px] text-white "></i>
                                    </div>
                                    <div>
                                        <h3 className="text-2xl mb-2 text-black font-rocGroteskMedium">
                                            Payment Failed
                                        </h3>
                                        <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                            We've detected a problem with your recent payment. To
                                            resolve this issue and continue using our services,
                                            please update your payment information or reach out to
                                            our support team for assistance."
                                        </p>
                                    </div>
                                </div>

                                <div className="mt-5 flex space-x-3">
                                    <div className="w-full">
                                    <Button
                                        btnText={"Close"}
                                        type={"button"}
                                        btnClassname={"!bg-n-20 !text-gm-50"}
                                        isLoading={false}
                                        onClick={() => {
                                            setPurchaseCreditFailure(false);
                                            navigate("/dashboard/settings/2");
                                        }}
                                    />
                                    </div>
                                    <div  className="w-full">
                                    <Button
                                        onClick={() => OnSubmitPurchase()}
                                        btnText={"Retry"}
                                        type={"button"}
                                        isLoading={false}
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalContainer>
                )}
            </nav>
            <div className="hidden max-lg:flex items-center justify-end h-[64px] px-6 bg-white border-b border-slate-100">
                <div className="flex items-center gap-5">
                    {/* <div className="cursor-pointer">
                        <i className="ri-question-line text-lg text-slate-700"></i>
                    </div>

                    <div className="relative cursor-pointer">
                        <i className="ri-notification-3-fill text-xl text-slate-700"></i>
                        <p className="p-[2px] flex items-center justify-center absolute top-0 right-0 bg-r-50 rounded-[1px] text-white font-rocGroteskMedium text-[5px]">
                            12
                        </p>
                    </div> */}
                    <div className="cursor-pointer">
                        <i
                            onClick={() => setSidebarCollapsed(false)}
                            className="ri-menu-fill text-lg text-slate-700"
                        ></i>
                    </div>
                </div>
            </div>

            <ModalContainer
                open={purchaseCreditLimit}
                showCloseIcon={false}
                modalClassName={"py-10 px-10 max-sm:p-5"}
                tailwindClassName="w-[34.7%] max-lg:w-[95%]"
                closeModal={() => {
                    setPurchaseCreditLimit(false);
                }}
            >
                <div className="bg-white !px-2 pt-6 pb-7  rounded-[12px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-1">
                            <p className=" font-rocGroteskMedium text-center inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                                <i className="ri-sparkling-2-fill text-[53px]"></i>
                            </p>
                            <div>
                                <h3 className="text-2xl text-black font-rocGroteskMedium">
                                    AI Credit Limit Reached
                                </h3>
                                <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                    You've reached your AI credit limit for this feature. To
                                    continue, please consider upgrading your plan or managing your
                                    AI usage in your account settings. If you have any questions,
                                    feel free to contact our support team."
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 flex space-x-3">
                           <div className="w-full">
                           <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => setPurchaseCreditLimit(false)}
                            />
                           </div>
                           <div className="w-full whitespace-nowrap">
                           <Button
                                btnClassname={
                                    "!py-3 !px-8 !bg-gradient-to-r from-[#5C58FF] to-[#FF3D1D]"
                                }
                                onClick={() => {
                                    setPurchaseCreditLimit(false);
                                    setPurchaseCreditModal(true);
                                }}
                                btnText={"Purchase more credit"}
                                type={"button"}
                                isLoading={false}
                            />
                           </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default DashboardNav;
