import Button from "components/common/Button";
import Loader from "components/common/Loader";
import { getCurrencyFromCurrencyCode } from "helpers";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { settingsActions } from "redux/Ldb/actions";

const SubscriptionWebsiteView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [planInterval, setPlanInterval] = useState("monthly");
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [expandedPlans, setExpandedPlans] = useState(Array(subscriptionPlans.length).fill(true));
    const { fetchingWebsiteSubscriptionPlan, fetchWebsiteSubscriptionPlanSuccess } = useSelector(
        (state) => state.settings
    );

    useEffect(() => {
        if (planInterval) {
            dispatch(settingsActions.getWebsiteSubscriptionPlan(planInterval));
        }
    }, [dispatch, planInterval]);

    useEffect(() => {
        if (Boolean(fetchWebsiteSubscriptionPlanSuccess)) {
            setSubscriptionPlans(fetchWebsiteSubscriptionPlanSuccess?.plans);
        }
    }, [fetchWebsiteSubscriptionPlanSuccess]);

    const toggleSeeMore = (idx) => {
        setExpandedPlans((prev) => ({
            ...prev,
            [idx]: !prev[idx],
        }));
    };
    return (
        <div className="">
            <div className={`flex justify-center`}>
                <div className="w-[288px]  mt-[67px] mb-[16px] md:my-[50px] lg:my-[70px] flex justify-between py-0.5 px-1 rounded-full bg-n-20 font-rocGroteskMedium">
                    <div
                        className={`my-[0.3px] ${
                            planInterval === "monthly" &&
                            "transition ease-in-out slide-in delay-100   bg-white slide-in 0.1s"
                        }   cursor-pointer rounded-full text-center py-2 w-[50%]`}
                        onClick={() => setPlanInterval("monthly")}
                    >
                        <p className="text-base text-gm-50 ">Monthly</p>
                    </div>
                    <div
                        className={`${
                            planInterval === "year" &&
                            "transition ease-in-out slide-in delay-100  bg-white slide-in 0.1s"
                        } w-[60%]  cursor-pointer rounded-[200px] py-2 text-center 
                         `}
                        onClick={() => setPlanInterval("year")}
                    >
                        <p className="text-base text-gm-50 ">
                            Yearly <span className="text-sm text-p-50">Save 40%</span>
                        </p>
                    </div>
                </div>
            </div>
            {!fetchingWebsiteSubscriptionPlan ? (
                <div className="flex lg:flex-row flex-col justify-center gap-4 w-full">
                    {subscriptionPlans &&
                        subscriptionPlans.length > 0 &&
                        subscriptionPlans?.map((sub, idx) => (
                            <div
                                key={idx}
                                className={`rounded-lg w-full  lg:w-[33.3%] ${
                                    sub?.category === "Business+"
                                        ? "border-[1.5px]  border-gm-75 "
                                        : "border border-slate-200"
                                } flex flex-col justify-between bg-white p-6 `}
                            >
                                <div>
                                    <div className="pb-4 flex justify-between border-b border-slate-200">
                                        <div className="">
                                            <p className="text-[18px] text-slate-900  font-rocGroteskMedium">
                                                {sub?.category}
                                                {sub?.category.toLowerCase() === "business+" && (
                                                    <span className="px-2 ml-1 py-1 text-xs bg-p-50 text-white rounded-[4px]">
                                                        (Popular)
                                                    </span>
                                                )}
                                            </p>
                                            <p className="text-sm font-rocGroteskRegular text-gm-50 mb-1">
                                                {sub?.description}
                                            </p>
                                            {sub?.category?.toLowerCase() !== "enterprise" ? (
                                                <p className=" font-rocGroteskMedium">
                                                    <span className="text-sm text-slate-900">
                                                        {getCurrencyFromCurrencyCode(
                                                            sub?.annualPrice?.currency
                                                        )}
                                                        {planInterval === "year"
                                                            ? `${sub?.annualPrice?.amount}/Yearly `
                                                            : `${sub?.monthlyPrice?.amount}/Monthly`}
                                                    </span>
                                                    {sub?.category?.toLowerCase() === "pro" &&
                                                        planInterval === "year" && (
                                                            <span className="text-[18px] text-p-50">
                                                                (save $60)
                                                            </span>
                                                        )}
                                                </p>
                                            ) : (
                                                <p className=" font-rocGroteskMedium">
                                                    {sub?.shortDescription}
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className="lg:flex hidden h-fit whitespace-nowrap space-x-1 text-sm text-slate-500 cursor-pointer"
                                            onClick={() => toggleSeeMore(idx)}
                                        >
                                            <p>{expandedPlans[idx] ? "See more" : "See less"}</p>
                                            <i
                                                className={`ri-arrow-${
                                                    expandedPlans[idx] ? "up" : "down"
                                                }-s-line`}
                                            ></i>
                                        </div>
                                    </div>
                                    <div className="py-4 lg:flex hidden mb-4 flex-col gap-3">
                                        {Object.values(sub?.benefit)
                                            ?.slice(0, expandedPlans[idx] ? 6 : sub?.benefit.length)
                                            .map(
                                                (d, i) =>
                                                    d?.description &&
                                                    d?.description.trim() !== "" && (
                                                        <div
                                                            key={i}
                                                            className="flex gap-2 items-center"
                                                        >
                                                            <i className="ri-check-fill text-g-50"></i>
                                                            <span className="text-gm-50 leading-[18.7px]  text-[13px] md:text-sm font-rocGroteskRegular">
                                                                {d.description}
                                                            </span>
                                                        </div>
                                                    )
                                            )}
                                    </div>
                                    <div className="py-4 lg:hidden flex mb-4 flex-col gap-3">
                                        {Object.values(sub?.benefit)
                                            ?.slice(0, expandedPlans[idx] ? sub?.benefit.length : 6)
                                            .map(
                                                (d, i) =>
                                                    d?.description &&
                                                    d?.description.trim() !== "" && (
                                                        <div
                                                            key={i}
                                                            className="flex gap-2 items-center"
                                                        >
                                                            <i className="ri-check-fill text-g-50"></i>
                                                            <span className="text-gm-50 leading-[18.7px]  text-[13px] md:text-sm font-rocGroteskRegular">
                                                                {d.description}
                                                            </span>
                                                        </div>
                                                    )
                                            )}
                                        <div className="mt-5">
                                            <div
                                                className="lg:hidden flex whitespace-nowrap font-rocGroteskMedium space-x-1 text-sm text-slate-500 cursor-pointer"
                                                onClick={() => toggleSeeMore(idx)}
                                            >
                                                <p className="text-sm text-gm-75 ">
                                                    {" "}
                                                    {expandedPlans[idx] ? "See less " : "See more"}
                                                </p>
                                                <i
                                                    className={`ri-arrow-${
                                                        expandedPlans[idx] ? "up" : "down"
                                                    }-s-line text-gm-75`}
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full">
                                    {sub?.category !== "Business+" ? (
                                        <Button
                                            btnText={
                                                sub?.category.toLowerCase() !== "enterprise"
                                                    ? "Get me started"
                                                    : "Contact sales"
                                            }
                                            btnClassname={"!bg-n-20 !text-gm-50"}
                                            type={"button"}
                                            isLoading={false}
                                            onClick={() =>
                                                sub?.category.toLowerCase() !== "enterprise"
                                                    ? navigate("/dashboard/settings")
                                                    : navigate("/book-a-demo")
                                            }
                                        />
                                    ) : (
                                        <Button
                                            btnText={"Get me started"}
                                            type={"button"}
                                            onClick={() => navigate("/dashboard/settings")}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                </div>
            ) : (
                <Loader />
            )}
        </div>
    );
};

export default SubscriptionWebsiteView;
