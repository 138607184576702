import React from "react";
import CustomToast from "components/common/CustomToast";
import { toast } from "react-hot-toast";
import { inventoryTypes } from "redux/Ldb/types";
import { inventoryService } from "services/Ldb";
import { Dispatch } from "redux";

export const inventoryActions = {
    addSingleProduct,
    addMultipleProducts,
    resetAddSingleProductSuccess,
    fetchStockList,
    fetchSingleInventory,
    addProductToStockCount,
    fetchWarehouseList,
    createWarehouse,
    updateWarehouse,
    fetchSearchInventoryListing,
    fetchSearchProductListing,
    fetchSingleWarehouseList,
    deleteWarehouse,
    resetDeleteWarehouse,
    resetCreateWarehouse,
    resetUpdateWarehouse,
    fetchListStockWarehouse,
    resetCreateWarehouseSuccess,
    resetAddMultipleProductsSuccess,
    fetchWarehouses,
    fetchInventoryList,
    resetAddProductToStockCount,
    fetchTransferStocks,
    transferStocks,
    resetTransferringStocks,
    transferStockFromWarehouse,
    resetResetTransferStockFromWarehouseSuccess,
    fetchSingleInventorySuppliers,
    fetchInventoryHistory,
    fetchSingleInventoryPO,
    fetchInventoryWarehouse,
    fetchInventoryOrders,
    updateSingleProduct,
    resetUpdateSingleProductSuccess,
    fetchSingleStockWarehouseList,
    fetchSingleStock,
    updateStock,
    resetUpdateStock,
    fetchSingleTransferStock,
    resetAddNewStockAdjustment,
    fetchSingleStockAdjusment,
    fetchStockAdjustmentList,
    addNewStockAdjustment,
    fetchInventorySettings,
    addInventorySettings,
    updateInventorySettings,
    addProductVendor,
    resetAddProductVendorSuccess,
    addMultipleProductToStockCount,
    resetAddMultipleProductToStockCount,
    addSuppliedStock,
    updateSuppliedStock,
    fetchSingleSuppliedStock,
    fetchSuppliedStockList,
    resetAddSuppliedStock,
    resetUpdateSuppliedStock,
    fetchSuppliedStockListAllData,
};

function addSingleProduct(data: Record<string, any> | Record<string, any>[]) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_SINGLE_PRODUCT_REQUEST));
        inventoryService.addSingleProduct(data).then(
            (res) => {
                dispatch(success(inventoryTypes.ADD_SINGLE_PRODUCT_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Inventory entry saved"} type="success" />
                ));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.ADD_SINGLE_PRODUCT_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateSingleProduct(data, productId) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.UPDATE_SINGLE_PRODUCT_REQUEST));

        inventoryService.updateSingleProduct(data, productId).then(
            (res) => {
                dispatch(success(inventoryTypes.UPDATE_SINGLE_PRODUCT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.UPDATE_SINGLE_PRODUCT_FAILURE, error?.message));
                }
            }
        );
    };
}

function addMultipleProducts(data: Record<string, any> | Record<string, any>[]) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_MULTIPLE_PRODUCTS_REQUEST));
        inventoryService.addMultipleProducts(data).then(
            (res) => {
                dispatch(success(inventoryTypes.ADD_MULTIPLE_PRODUCTS_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Inventory entries saved"} type="success" />
                ));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.ADD_MULTIPLE_PRODUCTS_FAILURE, error?.message));
                }
            }
        );
    };
}

function transferStockFromWarehouse(data: Record<string, any> | Record<string, any>[]) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.TRANSFER_STOCK_FROM_WAREHOUSE_REQUEST));
        inventoryService.transferStockFromWarehouse(data).then(
            (res) => {
                dispatch(success(inventoryTypes.TRANSFER_STOCK_FROM_WAREHOUSE_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.TRANSFER_STOCK_FROM_WAREHOUSE_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function fetchStockList(limit?: number, search?: string, warehouseId?: string, category?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_STOCK_LIST_REQUEST));

        inventoryService.getStockList(limit, search, warehouseId, category).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_STOCK_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_STOCK_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchSingleInventory(id: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_INVENTORY_REQUEST));

        inventoryService.getSingleInventory(id).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SINGLE_INVENTORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_SINGLE_INVENTORY_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchInventorySettings(id: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_INVENTORY_SETTINGS_REQUEST));

        inventoryService.fetchInventorySettings(id).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_INVENTORY_SETTINGS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    if (error.message !== "Stock Setting not found") {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                    }
                    dispatch(
                        failure(inventoryTypes.FETCH_INVENTORY_SETTINGS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function addInventorySettings(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_INVENTORY_SETTINGS_REQUEST));

        inventoryService.addInventorySettings(data).then(
            (res) => {
                dispatch(success(inventoryTypes.ADD_INVENTORY_SETTINGS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.ADD_INVENTORY_SETTINGS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function updateInventorySettings(data, id) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.UPDATE_INVENTORY_SETTINGS_REQUEST));

        inventoryService.updateInventorySettings(data, id).then(
            (res) => {
                dispatch(success(inventoryTypes.UPDATE_INVENTORY_SETTINGS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.UPDATE_INVENTORY_SETTINGS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function addProductToStockCount(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_PRODUCT_TO_STOCK_COUNT_REQUEST));

        inventoryService.addProductToStockCount(data).then(
            (res) => {
                dispatch(success(inventoryTypes.ADD_PRODUCT_TO_STOCK_COUNT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.ADD_PRODUCT_TO_STOCK_COUNT_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}
function addMultipleProductToStockCount(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_REQUEST));

        inventoryService.addMultipleProductToStockCount(data).then(
            (res) => {
                dispatch(success(inventoryTypes.ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchWarehouseList(limit: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_WAREHOUSE_LIST_REQUEST));

        inventoryService.fetchWarehouseList(limit, search).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_WAREHOUSE_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_WAREHOUSE_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchWarehouses(limit?: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_WAREHOUSES_REQUEST));
        inventoryService.getWarehouses(limit, search).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_WAREHOUSES_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_WAREHOUSES_FAILURE, error?.message));
                }
            }
        );
    };
}
function createWarehouse(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.CREATE_WAREHOUSE_REQUEST));

        inventoryService.createWarehouse(data).then(
            (res) => {
                dispatch(success(inventoryTypes.CREATE_WAREHOUSE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.CREATE_WAREHOUSE_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchInventoryList(limit?: number, search?: string, category?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_INVENTORY_LIST_REQUEST));
        inventoryService.getInventoryList(limit, search, category).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_INVENTORY_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_INVENTORY_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateWarehouse(id, data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.UPDATE_WAREHOUSE_REQUEST));

        inventoryService.updateWarehouse(id, data).then(
            (res) => {
                dispatch(success(inventoryTypes.UPDATE_WAREHOUSE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.UPDATE_WAREHOUSE_FAILURE, error?.message));
                }
            }
        );
    };
}

function deleteWarehouse(id) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.DELETE_WAREHOUSE_REQUEST));

        inventoryService.deleteWarehouse(id).then(
            (res) => {
                dispatch(success(inventoryTypes.DELETE_WAREHOUSE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.DELETE_WAREHOUSE_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchSearchInventoryListing(
    limit: number,
    search?: string,
    location?: string,
    category?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SEARCH_INVENTORY_LISTING_REQUEST));

        inventoryService.fetchSearchInventoryListing(limit, search, location, category).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SEARCH_INVENTORY_LISTING_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.FETCH_SEARCH_INVENTORY_LISTING_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}
function fetchSearchProductListing(
    limit: number,
    search?: string,
    data?: Record<string, any> | Array<Record<string, any>>
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SEARCH_PRODUCT_LISTING_REQUEST));

        inventoryService.fetchSearchProductListing(limit, search, data).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SEARCH_PRODUCT_LISTING_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_SEARCH_PRODUCT_LISTING_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}
function fetchSingleWarehouseList(id: string, limit?: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_WAREHOUSE_LIST_REQUEST));

        inventoryService.fetchSingleWarehouseList(id, limit, search).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SINGLE_WAREHOUSE_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_SINGLE_WAREHOUSE_LIST_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchSingleStockWarehouseList(id: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_STOCK_WAREHOUSE_REQUEST));

        inventoryService.fetchSingleStockWarehouse(id).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SINGLE_STOCK_WAREHOUSE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_SINGLE_STOCK_WAREHOUSE_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchListStockWarehouse(
    id: string,
    limit: number,
    inventoryId?: string,
    search?: string,
    filter?: string,
    isExpired?: boolean
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_LIST_STOCK_WAREHOUSE_REQUEST));

        inventoryService
            .fetchListStockWarehouse(id, inventoryId, limit, search, filter, isExpired)
            .then(
                (res) => {
                    dispatch(success(inventoryTypes.FETCH_LIST_STOCK_WAREHOUSE_SUCCESS, res?.data));
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                inventoryTypes.FETCH_LIST_STOCK_WAREHOUSE_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function fetchSingleInventorySuppliers(id: string, limit: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_INVENTORY_SUPPLIERS_REQUEST));

        inventoryService.fetchSingleInventorySuppliers(id, limit, search).then(
            (res) => {
                dispatch(
                    success(inventoryTypes.FETCH_SINGLE_INVENTORY_SUPPLIERS_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.FETCH_SINGLE_INVENTORY_SUPPLIERS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}
function fetchInventoryHistory(id: string, limit: number, filter?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_INVENTORY_HISTORY_REQUEST));

        inventoryService.fetchInventoryHistory(id, limit, filter).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_INVENTORY_HISTORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_INVENTORY_HISTORY_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchSingleInventoryPO(id: string, limit: number, filter?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_REQUEST));

        inventoryService.fetchSingleInventoryPO(id, limit, filter).then(
            (res) => {
                dispatch(
                    success(inventoryTypes.FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function fetchInventoryWarehouse(id: string, limit: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_INVENTORY_WAREHOUSE_REQUEST));

        inventoryService.fetchInventoryWarehouse(id, limit, search).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_INVENTORY_WAREHOUSE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_INVENTORY_WAREHOUSE_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchInventoryOrders(id: string, limit: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_INVENTORY_ORDERS_REQUEST));

        inventoryService.fetchInventoryOrders(id, limit, search).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_INVENTORY_ORDERS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_INVENTORY_ORDERS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function updateStock(id: string, data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.UPDATE_STOCK_REQUEST));

        inventoryService.editStock(id, data).then(
            (res) => {
                dispatch(success(inventoryTypes.UPDATE_STOCK_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.UPDATE_STOCK_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchSingleStock(id: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_STOCK_REQUEST));

        inventoryService.fetchSingleStock(id).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SINGLE_STOCK_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_SINGLE_STOCK_FAILURE, error?.message));
                }
            }
        );
    };
}
function fetchSingleTransferStock(id: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_TRANSFER_STOCK_REQUEST));

        inventoryService.fetchSingleTransferStock(id).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SINGLE_TRANSFER_STOCK_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_SINGLE_TRANSFER_STOCK_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchTransferStocks(
    limit?: number,
    search?: string,
    warehouseId?: string,
    category?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_TRANSFER_STOCKS_REQUEST));
        inventoryService.getTransferStocks(limit, search, warehouseId, category).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_TRANSFER_STOCKS_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_TRANSFER_STOCKS_FAILURE, error?.message));
                }
            }
        );
    };
}

function transferStocks(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.TRANSFER_STOCKS_REQUEST));
        inventoryService.transferStocks(data).then(
            (res) => {
                dispatch(success(inventoryTypes.TRANSFER_STOCKS_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.TRANSFER_STOCKS_FAILURE, error?.message));
                }
            }
        );
    };
}
function addNewStockAdjustment(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_NEW_STOCK_ADJUSTMENT_REQUEST));
        inventoryService.addNewStockAdjustment(data).then(
            (res) => {
                dispatch(success(inventoryTypes.ADD_NEW_STOCK_ADJUSTMENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.ADD_NEW_STOCK_ADJUSTMENT_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}
function addProductVendor(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_PRODUCT_VENDOR_REQUEST));
        inventoryService.addProductVendor(data).then(
            (res) => {
                dispatch(success(inventoryTypes.ADD_PRODUCT_VENDOR_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.ADD_PRODUCT_VENDOR_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}
function fetchStockAdjustmentList(
    limit: number,
    search?: string,
    warehouseId?: string,
    category?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_STOCK_ADJUSTMENT_LIST_REQUEST));
        inventoryService.fetchStockAdjustment(limit, search, warehouseId, category).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_STOCK_ADJUSTMENT_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_STOCK_ADJUSTMENT_LIST_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}
function fetchSingleStockAdjusment(id: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_STOCK_ADJUSTMENT_REQUEST));
        inventoryService.fetchSingleStockAdjustment(id).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SINGLE_STOCK_ADJUSTMENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.FETCH_SINGLE_STOCK_ADJUSTMENT_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function addSuppliedStock(data: Record<string, any> | Record<string, any>[]) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_SUPPLIED_STOCK_REQUEST));
        inventoryService.addSuppliedStock(data).then(
            (res) => {
                dispatch(success(inventoryTypes.ADD_SUPPLIED_STOCK_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.ADD_SUPPLIED_STOCK_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateSuppliedStock(id: string, data: Record<string, any> | Record<string, any>[]) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.UPDATE_SUPPLIED_STOCK_REQUEST));
        inventoryService.updateSuppliedStock(id, data).then(
            (res) => {
                dispatch(success(inventoryTypes.UPDATE_SUPPLIED_STOCK_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.UPDATE_SUPPLIED_STOCK_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchSuppliedStockList(limit, search?:string, warehouseId?:string, category?:string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_REQUEST));
        inventoryService.fetchSuppliedStockList(limit, search,warehouseId, category).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchSuppliedStockListAllData(
    limit?: number,
    search?: string,
    warehouseId?: string,
    category?: string,
    page?: number
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_REQUEST));

        inventoryService
            .fetchSuppliedStockListAllData(limit, search, warehouseId, category, page)
            .then(
                (res) => {
                    dispatch(
                        success(
                            inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_SUCCESS,
                            res?.data
                        )
                    );
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}
function fetchSingleSuppliedStock(id) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_SUPPLIED_STOCK_REQUEST));
        inventoryService.fetchSingleSuppliedStock(id).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SINGLE_SUPPLIED_STOCK_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_SINGLE_SUPPLIED_STOCK_FAILURE, error?.message));
                }
            }
        );
    };
}

function resetDeleteWarehouse() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.DELETE_WAREHOUSE_SUCCESS, null));
    };
}
function resetCreateWarehouse() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.CREATE_WAREHOUSE_SUCCESS, null));
    };
}
function resetUpdateWarehouse() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.UPDATE_WAREHOUSE_SUCCESS, null));
    };
}
function resetUpdateStock() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.UPDATE_STOCK_SUCCESS, null));
    };
}

function resetResetTransferStockFromWarehouseSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.TRANSFER_STOCK_FROM_WAREHOUSE_SUCCESS, null));
    };
}

function resetAddMultipleProductsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.ADD_MULTIPLE_PRODUCTS_SUCCESS, null));
    };
}

function resetCreateWarehouseSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.CREATE_WAREHOUSE_SUCCESS, null));
    };
}

function resetAddProductToStockCount() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.ADD_PRODUCT_TO_STOCK_COUNT_SUCCESS, null));
    };
}

function resetAddMultipleProductToStockCount() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_SUCCESS, null));
    };
}

function resetAddProductVendorSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.ADD_PRODUCT_VENDOR_SUCCESS, null));
    };
}
function resetAddSingleProductSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.ADD_SINGLE_PRODUCT_SUCCESS, null));
    };
}

function resetUpdateSingleProductSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.UPDATE_SINGLE_PRODUCT_SUCCESS, null));
    };
}

function resetTransferringStocks() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.TRANSFER_STOCKS_SUCCESS, null));
    };
}
function resetAddNewStockAdjustment() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.ADD_NEW_STOCK_ADJUSTMENT_SUCCESS, null));
    };
}
function resetAddSuppliedStock() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.ADD_SUPPLIED_STOCK_SUCCESS, null));
    };
}
function resetUpdateSuppliedStock() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.UPDATE_SUPPLIED_STOCK_SUCCESS, null));
    };
}
function request(type: string) {
    return { type: type };
}
function success(type: string, data?: any) {
    return { type: type, payload: data };
}
function failure(type: string, error?: any) {
    return { type: type, payload: error ?? "" };
}
