import Button from "components/common/Button";
import DocumentCard from "components/common/DocumentCard";
import Dropdown from "components/common/Dropdown";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import ToggleInput from "components/common/InputField/ToggleInput";
import ModalContainer from "components/common/ModalContainer";
import { displayFileIcon } from "helpers";
import { downloadUrl } from "helpers/fileDownload";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import React, { useEffect, useState } from "react";
import { vendorActions } from "redux/Ldb/actions";

type DocumentsTabProps = {
    docs: { [key: string]: any }[];
    showNoContent?: boolean;
    dropDownClass?: string;
    hideUpload?: boolean;
    contentClass?: string;
    titleBtn?: JSX.Element;
    vendorId: string;
    vendorEmail?: string;
    setDocs?: React.Dispatch<React.SetStateAction<{ [key: string]: any }[]>>;
};

const DocumentsTab = ({
    docs,
    showNoContent,
    dropDownClass,
    hideUpload,
    contentClass,
    titleBtn,
    vendorId,
    vendorEmail,
    setDocs,
}: DocumentsTabProps) => {
    const [addCommentModal, setAddCommentModal] = useState(false);
    const [requestDocModal, setRequestDocModal] = useState(false);
    const [docStatus, setDocStatus] = useState([]);
    const [comment, setComment] = useState("");
    const [selectedDoc, setSelectedDoc] = useState({ status: "", idx: null, documentId: "" });
    const [requestDocData, setRequestDocData] = useState({
        requestType: "",
        replaceDoc: "",
        newDocName: "",
        expiryDate: false,
    });
    const dispatch = useAppDispatch();
    const {
        approvingVendorDoc,
        approveVendorDocSuccess,
        requestingVendorDoc,
        requestVendorDocSuccess,
        addingVendorDocComment,
        addVendorDocCommentSuccess,
    } = useAppSelector((state) => state.vendor);

    const handleRequestDocChange = (name: string, value: string | boolean) => {
        setRequestDocData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleRequestDoc = (e: React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        const body: { [key: string]: any } = {
            name: requestDocData?.newDocName || requestDocData?.replaceDoc?.split("/-/")[0],
            requestType: requestDocData?.requestType,
            expiryDate: requestDocData?.expiryDate,
            vendorId: vendorId,
        };

        if (requestDocData?.requestType?.toLowerCase() === "replacement") {
            body.replacementId = requestDocData?.replaceDoc?.split("/-/")[1];
            setSelectedDoc({
                status: "pending",
                idx: null,
                documentId: requestDocData?.replaceDoc?.split("/-/")[1],
            });
        }
        dispatch(vendorActions.requestVendorDoc(body));
    };

    useEffect(() => {
        if (Boolean(approveVendorDocSuccess)) {
            setDocs(approveVendorDocSuccess?.documents);
            dispatch(vendorActions.resetApproveVendorDocSuccess());
        }
    }, [approveVendorDocSuccess]);

    useEffect(() => {
        if (Boolean(requestVendorDocSuccess)) {
            setRequestDocModal(false);
            setRequestDocData({
                requestType: "",
                replaceDoc: "",
                newDocName: "",
                expiryDate: false,
            });
            dispatch(
                vendorActions.approveVendorDoc(
                    {
                        documentId: selectedDoc?.documentId,
                        status: "pending",
                        vendorId: vendorId,
                    },
                    true
                )
            );
            dispatch(vendorActions.resetRequestVendorDocSuccess());
        }
    }, [dispatch, requestVendorDocSuccess, selectedDoc, vendorId]);

    useEffect(() => {
        if (Boolean(addVendorDocCommentSuccess)) {
            setComment("");
            setAddCommentModal(false);
        }
    }, [dispatch, addVendorDocCommentSuccess]);

    return (
        <>
            {docs && docs?.length > 0 ? (
                <div className="">
                    {!showNoContent && (
                        <div className="flex justify-between items-center mb-4 px-6 pt-6">
                            <p className="text-base text-slate-700 font-rocGroteskMedium">
                                Documents
                            </p>
                            {!hideUpload && (
                                <p className="text-[13px] border border-n-20 rounded px-4 py-2 cursor-pointer text-gm-50 font-rocGroteskMedium ">
                                    Upload document
                                </p>
                            )}
                            <div className="flex items-center space-x-3">
                                <div
                                    onClick={() => setAddCommentModal(true)}
                                    className="text-[13px] flex items-center space-x-2.5 bg-n-20 rounded px-4 py-2 cursor-pointer text-gm-50 font-rocGroteskMedium "
                                >
                                    <i className="ri-message-2-line text-base"></i>
                                    <span>Add comment</span>
                                </div>
                                <div
                                    onClick={() => setRequestDocModal(true)}
                                    className="text-[13px] border flex items-center space-x-2.5 border-n-20 rounded px-4 py-2 cursor-pointer text-gm-50 font-rocGroteskMedium "
                                >
                                    <i className="ri-file-upload-line text-base"></i>
                                    <span>Request document</span>
                                </div>
                            </div>
                        </div>
                    )}

                    <div
                        className={`overflow-auto ${contentClass} space-y-2.5 px-6 pb-[150px] mb-[-150px]`}
                    >
                        {docs?.map((doc, idx: number) => {
                            return (
                                <div
                                    key={doc?.name + idx}
                                    className="flex py-4 items-center border rounded font-rocGroteskMedium"
                                >
                                    <div className="flex w-[63%] py-1 items-center justify-between px-4 border-r">
                                        <div className="flex items-center space-x-2">
                                            {displayFileIcon(getFileTypeFromUrl(doc?.url))}
                                            <span className="text-sm">{doc?.name}</span>
                                        </div>
                                        {Boolean(doc?.url) &&
                                            !doc?.replacementRequested &&
                                            !doc?.newDocumentRequest && (
                                                <div className="w-fit flex items-center space-x-2.5">
                                                    <span
                                                        onClick={() => {
                                                            const anchor =
                                                                document.createElement("a");
                                                            anchor.style.display = "none";
                                                            anchor.href = doc?.url;
                                                            anchor.target = "_blank";
                                                            anchor.rel = "noreferrer";
                                                            document.body.appendChild(anchor);
                                                            anchor.click();
                                                            document.body.removeChild(anchor);
                                                        }}
                                                        className="text-b-55 text-sm underline cursor-pointer"
                                                    >
                                                        Preview
                                                    </span>
                                                </div>
                                            )}
                                        {(doc?.replacementRequested || doc?.newDocumentRequest) && (
                                            <div className="w-fit flex items-center space-x-2.5">
                                                <span className="text-b-55 text-sm ">
                                                    {doc?.replacementRequested
                                                        ? "Replacement requested"
                                                        : "Document requested"}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="w-[31%] border-r px-4">
                                        <Dropdown
                                            name="docStatus"
                                            value={docStatus[idx]}
                                            dropdownTriggerClassName={"!w-full"}
                                            handleChange={(name, value) => {
                                                dispatch(
                                                    vendorActions.approveVendorDoc({
                                                        documentId: doc?._id,
                                                        status: value,
                                                        vendorId: vendorId,
                                                    })
                                                );
                                                setSelectedDoc({
                                                    status: value,
                                                    idx,
                                                    documentId: doc?._id,
                                                });
                                                // handleUpdateDocStatus(value, idx);
                                            }}
                                            dropdown={
                                                <div className="flex gap-2 justify-between !w-full">
                                                    <div
                                                        className={`!w-fit pr-3 py-1 flex items-center space-x-2`}
                                                    >
                                                        <div
                                                            className={`h-2 w-2 ${
                                                                doc?.status?.toLowerCase() ===
                                                                    "pending" && "bg-[#FF8A00]"
                                                            } ${
                                                                doc?.status?.toLowerCase() ===
                                                                    "accepted" && "bg-g-55"
                                                            } ${
                                                                doc?.status?.toLowerCase() ===
                                                                    "rejected" && "bg-r-50"
                                                            } rounded-full`}
                                                        ></div>
                                                        <p className="text-sm font-rocGroteskMedium text-slate-500 capitalize">
                                                            {doc?.status}
                                                        </p>
                                                    </div>
                                                    <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                                </div>
                                            }
                                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                            dropdownContainerClasses={`shadow-cardShadow-4 left-[0px] !mt-0  !w-[159px] rounded !max-h-fit !p-0 !top-[105%]
                            `}
                                            dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                            dropdownOptions={[
                                                {
                                                    label: "Accept",
                                                    value: "accepted",
                                                },
                                                {
                                                    label: "Reject",
                                                    value: "rejected",
                                                },
                                                {
                                                    label: "Pending",
                                                    value: "pending",
                                                },
                                            ]}
                                        />
                                    </div>
                                    <div className="w-[6%] flex items-center px-4">
                                        <div>
                                            <Dropdown
                                                dropdown={
                                                    <i className="ri-more-2-fill before:content-['\ef76'] text-slate-500"></i>
                                                }
                                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                                dropdownContainerClasses={`shadow-cardShadow-2 left-[-120px]  border-0 rounded !max-h-fit`}
                                                dropdownItemsClasses={``}
                                                name={"faq-dropdown"}
                                                dropdownOptions={[
                                                    {
                                                        label: "Contact supplier",
                                                        value: "contact supplier",
                                                        action: () => {
                                                            window.location.href = `mailto:${vendorEmail}`;
                                                        },
                                                    },
                                                    // },
                                                    ,
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : (
                <div className={`mt-[111px] flex justify-center`}>
                    <div className="text-center w-[35%]">
                        <i className="ri-file-line text-5xl text-slate-500 "></i>
                        <p className="text-base font-rocGroteskMedium mb-1 mt-4">
                            No Documents found
                        </p>
                        <p className="text-sm font-rocGroteskRegular text-slate-500">
                            It seems like there are no documents found.
                        </p>
                    </div>
                </div>
            )}

            <ModalContainer
                open={addCommentModal}
                showCloseIcon={false}
                tailwindClassName="w-[37%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => setAddCommentModal(false)}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        dispatch(
                            vendorActions.addDocComment({
                                comment,
                                vendorId,
                            })
                        );
                    }}
                    className="w-full py-4 px-4 bg-white rounded-md font-rocGroteskMedium"
                >
                    <div className="flex items-center justify-between py-2.5 mb-4 border-b border-slate-200">
                        <span className="text-base">Add comment</span>
                        <i
                            onClick={() => setAddCommentModal(false)}
                            className="ri-close-fill text-xl cursor-pointer"
                        ></i>
                    </div>
                    <div className="mb-6">
                        <TextareaInput
                            value={comment}
                            name={"comment"}
                            rows={2}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder={"Comment"}
                            required={true}
                            containerClassname="mb-4"
                        />
                    </div>
                    <div className="pt-4 px-6 border-t border-slate-200 flex items-center space-x-2">
                        <Button
                            onClick={() => setAddCommentModal(false)}
                            btnText={"Cancel"}
                            type="button"
                            btnClassname="py-3 !bg-n-20"
                        />
                        <Button
                            btnText={"Send"}
                            type="submit"
                            isLoading={addingVendorDocComment}
                            btnClassname="py-3"
                        />
                    </div>
                </form>
            </ModalContainer>

            <ModalContainer
                open={requestDocModal}
                showCloseIcon={false}
                tailwindClassName="w-[37%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setRequestDocModal(false);
                    setRequestDocData({
                        requestType: "",
                        replaceDoc: "",
                        newDocName: "",
                        expiryDate: false,
                    });
                }}
            >
                <form
                    onSubmit={(e) => {
                        handleRequestDoc(e);
                    }}
                    className="w-full py-4 px-4 bg-white rounded-md font-rocGroteskMedium"
                >
                    <div className="flex items-center justify-between py-2.5 mb-4 border-b border-slate-200">
                        <span className="text-base">Request document</span>
                        <i
                            onClick={() => {
                                setRequestDocModal(false);
                                setRequestDocData({
                                    requestType: "",
                                    replaceDoc: "",
                                    newDocName: "",
                                    expiryDate: false,
                                });
                            }}
                            className="ri-close-fill text-xl cursor-pointer"
                        ></i>
                    </div>
                    <div className="mb-6 space-y-3">
                        <SelectInput
                            value={requestDocData?.requestType}
                            name="requestType"
                            placeholder={"Request type"}
                            handleChange={(name, value) => {
                                handleRequestDocChange(name, value);
                            }}
                            isRequired={true}
                            dropdownOptions={[
                                {
                                    label: "Replace document",
                                    value: "replacement",
                                },
                                {
                                    label: "New document",
                                    value: "new document",
                                },
                            ]}
                        />
                        {requestDocData?.requestType?.toLowerCase() === "replacement" && (
                            <SelectInput
                                value={requestDocData?.replaceDoc}
                                name="replaceDoc"
                                placeholder={"Select document to replace"}
                                handleChange={(name, value) => {
                                    handleRequestDocChange(name, value);
                                }}
                                isRequired={true}
                                dropdownOptions={docs
                                    ?.filter(
                                        (doc) =>
                                            !doc?.replacementRequested && !doc?.newDocumentRequest
                                    )
                                    ?.map((doc) => {
                                        return {
                                            label: doc?.name,
                                            value: doc?.name + "/-/" + doc?._id,
                                        };
                                    })}
                            />
                        )}
                        {requestDocData?.requestType?.toLowerCase()?.includes("new") && (
                            <TextInput
                                value={requestDocData?.newDocName}
                                name={"newDocName"}
                                onChange={(e) =>
                                    handleRequestDocChange(e.target.name, e.target.value)
                                }
                                placeholder={"Enter document name"}
                                required={true}
                                containerClassname="mb-4"
                            />
                        )}
                        {Boolean(requestDocData?.requestType) && (
                            <div className="flex items-center justify-between">
                                <span className="text-sm text-slate-500">Expiry date</span>
                                <ToggleInput
                                    onChange={(e) =>
                                        handleRequestDocChange(e.target.name, e.target.checked)
                                    }
                                    name={"expiryDate"}
                                    checked={requestDocData?.expiryDate}
                                />
                            </div>
                        )}
                    </div>
                    <div className="pt-4 px-6 border-t border-slate-200 flex items-center space-x-2">
                        <Button
                            onClick={() => {
                                setRequestDocModal(false);
                                setRequestDocData({
                                    requestType: "",
                                    replaceDoc: "",
                                    newDocName: "",
                                    expiryDate: false,
                                });
                            }}
                            btnText={"Cancel"}
                            type="button"
                            btnClassname="py-3 !bg-n-20"
                        />
                        <Button
                            btnText={"Send request"}
                            isLoading={requestingVendorDoc}
                            type="submit"
                            btnClassname="py-3"
                        />
                    </div>
                </form>
            </ModalContainer>
        </>
    );
};

export default DocumentsTab;
