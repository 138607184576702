import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const taskService = {
    createTask,
    updateTask,
    getTaskList,
    getSingleTask,
};

async function createTask(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/task/create`,
        requestOptions
    );
    return res;
}

async function updateTask(data: Record<string, any> | Array<Record<string, any>>, taskId: string) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/task/update/${taskId}`,
        requestOptions
    );
    return res;
}

async function getTaskList(
    limit?: string | number,
    status?: string,
    keyword?: string,
    search?: string,
    category?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        status: status as string,
        keyword: keyword as string,
        search: search as string,
        category: category as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/task?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleTask(workflowId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/task/${workflowId}`,
        requestOptions
    );
    return res;
}
