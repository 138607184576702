export const convertSeconds = (seconds: number) => {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsRemaining = Math.ceil(seconds % 60);

    return {
        days,
        hours,
        minutes,
        secondsRemaining,
    };
};

export const parseConvertedSeconds = (data: {
    days: number,
    hours: number,
    minutes: number,
    secondsRemaining: number,
}) => {
    return `${Boolean(data?.days) ? data?.days + " day(s)" : ""} ${
        Boolean(data?.hours) ? data?.hours + " hr(s)" : ""
    } ${Boolean(data?.minutes) ? data?.minutes + " min(s)" : ""} ${
        Boolean(data?.secondsRemaining) ? data?.secondsRemaining + " s" : ""
    }`;
};
