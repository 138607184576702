import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import TextInput from "components/common/InputField/TextInput";
import ProgressBar from "components/common/ProgressBar";
import React, { useEffect, useState } from "react";
import { settingsActions } from "redux/Ldb/actions";
import Loader from "components/common/Loader";
import { transformString } from "helpers/transformStrings";
import moment from "moment";
import ModalContainer from "components/common/ModalContainer";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/storeHooks";
import RoiCalculator from "../RoiCalculator";
import SubscriptionView from "../SubscriptionView";
import NotificationAlert from "components/common/NotificationAlert";
import { formatMoney } from "helpers";

const Billing = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showBenefit, setShowBenefit] = useState<boolean>(false);
    const [showUpgradePlan, setShowUpgradePlan] = useState<boolean>(false);
    const [purchaseCreditModal, setPurchaseCreditModal] = useState<boolean>(false);
    const [purchaseCreditSuccess, setPurchaseCreditSuccess] = useState<boolean>(false);
    const [purchaseCreditFailure, setPurchaseCreditFailure] = useState<boolean>(false);
    const [isPaymentFailed, setIsPaymentFailed] = useState<boolean>(false);
    const [purchaseCreditLimit, setPurchaseCreditLimit] = useState<boolean>(false);
    const [subscriptionPlanSuccess, setSubscriptionPlanSuccess] = useState<boolean>(false);
    const [subscriptionPlanFailure, setSubscriptionPlanFailure] = useState<boolean>(false);
    const [creditUsageHistory, setCreditUsageHistory] = useState([]);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [subscriptionPlan, setSubscriptionPlan] = useState<{ [key: string]: any }>({});

    const [credit, setCredit] = useState<string | number>(0);
    const [showMore, setShowMore] = useState<number | undefined>(undefined);

    const {
        fetchingPaymentHistory,
        fetchPaymentHistorySuccess,
        fetchingCreditHistory,
        fetchCreditHistorySuccess,
        fetchStripeSession,
        fetchStripeSessionSuccess,
        fetchingCurrentSubscription,
        fetchCurrentSubscriptionSuccess,
        fetchingSubscriptionPlan,
    } = useAppSelector((state) => state.settings);
    const [param] = useSearchParams();
    const showUpgrade = param.get("showUpgrade");
    const paymentMode = param.get("paymentMode");
    const planMode = param.get("planMode");

    useEffect(() => {
        dispatch(settingsActions.fetchCurrentSubscriptionPlan());
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchPaymentHistorySuccess)) {
            setPaymentHistory(fetchPaymentHistorySuccess?.paymentHistory);
        }
    }, [fetchPaymentHistorySuccess]);

    useEffect(() => {
        dispatch(settingsActions.getCreditHistory());
        dispatch(settingsActions.getPaymentHistory());
    }, [dispatch]);

    useEffect(() => {
        if (!Boolean(fetchCurrentSubscriptionSuccess)) {
            dispatch(settingsActions.fetchCurrentSubscriptionPlan());
        }
    }, [dispatch, fetchCurrentSubscriptionSuccess]);

    useEffect(() => {
        if (Boolean(fetchCurrentSubscriptionSuccess)) {
            setSubscriptionPlan(fetchCurrentSubscriptionSuccess?.subscription);
        }
    }, [fetchCurrentSubscriptionSuccess]);

    const creditTotal = localStorage.getItem("credit");
    const onResubmitPurchase = () => {
        if (Number(creditTotal) > 0) {
            dispatch(settingsActions.getStripeSession(creditTotal));
        }
    };
    const OnSubmitPurchase = () => {
        if (Number(credit) > 0) {
            dispatch(settingsActions.getStripeSession(credit));
        }
    };

    useEffect(() => {
        if (Boolean(fetchStripeSessionSuccess)) {
            localStorage.setItem("credit", String(credit));
            setPurchaseCreditFailure(false);
            setPurchaseCreditModal(false);
            window.location.href = fetchStripeSessionSuccess?.session?.checkoutUrl;
        }
    }, [fetchStripeSessionSuccess]);

    useEffect(() => {
        if (Boolean(fetchCreditHistorySuccess)) {
            setCreditUsageHistory(fetchCreditHistorySuccess?.creditUsageHistory);
        }
    }, [fetchCreditHistorySuccess]);

    useEffect(() => {
        if (paymentMode?.toLowerCase() === "success") {
            setPurchaseCreditSuccess(true);
            localStorage.removeItem("credit");
            dispatch(settingsActions.resetGetStripeSession());
        } else if (paymentMode?.toLowerCase() === "error") {
            setPurchaseCreditFailure(true);
        }
    }, [paymentMode, dispatch]);

    useEffect(() => {
        if (planMode?.toLowerCase() === "success") {
            setShowUpgradePlan(true);
            setSubscriptionPlanSuccess(true);
            dispatch(settingsActions.getSubscriptionPlan());
            dispatch(settingsActions.resetCreateStripeSession());
        } else if (planMode?.toLowerCase() === "error") {
            setShowUpgradePlan(true);
            setSubscriptionPlanFailure(true);
        }
    }, [planMode, dispatch]);

    useEffect(() => {
        if (showUpgrade) {
            setShowUpgradePlan(true);
        }
    }, [showUpgrade]);

    const tableHeader = [
        { title: "Date", widthClass: "w-[25%]" },
        { title: "Description", widthClass: "w-[28%]" },
        { title: "Paid by", widthClass: "w-[28%]" },
        { title: "Invoice Total", widthClass: "w-[20%]" },
        { title: "Status", widthClass: "w-[24%]" },
        { title: "", widthClass: "w-[20%]" },
    ];

    const tableBody =
        paymentHistory &&
        paymentHistory?.length > 0 &&
        paymentHistory?.map((history) => [
            {
                content: (
                    <div
                        key={history?._id}
                        className={`py-[18px] pr-4 border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                    >
                        <p className="leading-5">{moment(history?.createdAt).format("LL")}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-4 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-gm-50 `}
                    >
                        <p>{history?.reason}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-4 border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                    >
                        <p>{history?.ldb?.profile?.businessName}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex items-center w-full border-slate-100 text-sm text-gm-50 `}
                    >
                        <p>${formatMoney()?.format(history?.amount)}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex space-x-1 items-center w-full border-slate-100 text-sm text-gm-50 `}
                    >
                        <div className="h-2 w-2 rounded-full bg-[#00DB8F]"></div>
                        <p>Paid</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 underline cursor-pointer flex items-center w-full border-slate-100 text-sm text-g-50 `}
                        onClick={() => window.open(history?.receiptUrl, "_blank")}
                    >
                        <p>View invoice</p>
                    </div>
                ),
            },
        ]);

    const mobileTable = () =>
        paymentHistory?.length > 0 &&
        paymentHistory?.map((history, idx) => (
            <div className="flex rounded shadow-boxShadow-9 py-3 px-4 mb-4 flex-col" key={idx}>
                <div className="">
                    <div className="flex justify-between items-start gap-2">
                        <div
                            className={` pr-4 flex font-rocGroteskMedium h-full border-slate-100 text-[15px]
                             text-gm-50 gap-x-2 relative`}
                        >
                            <p>{history?.reason}</p>

                            <i
                                onClick={() => {
                                    setShowMore(idx);
                                    if (idx === showMore) {
                                        setShowMore(undefined);
                                    }
                                }}
                                className={`${
                                    showMore === idx ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"
                                }  text-2xl text-gm-50 -mt-1 cursor-pointer`}
                            ></i>
                        </div>
                        <div
                            className={` pr-3 flex space-x-1 items-center border-slate-100 text-sm text-gm-50 `}
                        >
                            <div className="h-2 w-2 rounded-full bg-[#00DB8F]"></div>
                            <p>Paid</p>
                        </div>
                    </div>
                    {showMore === idx && (
                        <div className="my-[10px] w-full flex flex-col gap-[10px] text-sm text-[#64748B] font-rocGroteskMedium">
                            <div className="flex justify-between items-center">
                                <p className="text-[13px]">Paid by </p>

                                <p>{history?.ldb?.profile?.businessName}</p>
                            </div>
                            <div className="flex justify-between items-center">
                                <p className="text-[13px]">Invoice Total </p>
                                <p>${formatMoney()?.format(history?.amount)}</p>
                            </div>
                            <div className="flex justify-between items-center">
                                <p className="text-[13px]">Date </p>
                                <p className="">{moment(history?.createdAt).format("LL")}</p>
                            </div>
                        </div>
                    )}
                </div>

                <div
                    className={`mt-1 underline cursor-pointer text-sm text-g-50 `}
                    onClick={() => window.open(history?.receiptUrl, "_blank")}
                >
                    <p>View invoice</p>
                </div>
            </div>
        ));

    return (
        <div className="">
            {!showUpgradePlan ? (
                <>
                    <div className="mt-6  rounded-lg py-6  px-4 md:px-8 shadow-faintShadow font-rocGroteskMedium  text-gm-50">
                        {isPaymentFailed && (
                            <div className="mb-6">
                                <NotificationAlert
                                    text="Oops! It seems there was an issue processing your subscription payment. We've sent you an email with instructions to complete your payment."
                                    color="text-[#DC2626]"
                                    bgColor="bg-[#FEF2F2]"
                                />
                            </div>
                        )}
                        <div
                            className={`flex justify-between  items-center ${
                                showBenefit && "pb-6 border-b border-slate-200"
                            }`}
                        >
                            {fetchingCurrentSubscription ? (
                                <Loader />
                            ) : (
                                <div className="flex justify-between md:block w-full md:w-auto">
                                    {fetchCurrentSubscriptionSuccess?.subscription?.plan
                                        ?.category ? (
                                        <p className="text-base w-[150px] sm:w-auto">
                                            You’re currently on{" "}
                                            {!fetchingCurrentSubscription &&
                                                fetchCurrentSubscriptionSuccess &&
                                                fetchCurrentSubscriptionSuccess?.subscription?.plan
                                                    ?.category}{" "}
                                            plan
                                        </p>
                                    ) : (
                                        <p className="text-base w-[150px] sm:w-auto">
                                            You’re not on any plan
                                        </p>
                                    )}

                                    {fetchCurrentSubscriptionSuccess?.subscription?.plan
                                        ?.category && (
                                        <div
                                            className="flex items-center cursor-pointer text-g-50 md:text-slate-800   text-sm"
                                            onClick={() => setShowBenefit(!showBenefit)}
                                        >
                                            <p className="underline text-right">
                                                {showBenefit
                                                    ? "Hide all benefits"
                                                    : "View all benefits"}
                                            </p>
                                            <i
                                                className={`ri-arrow-${
                                                    showBenefit ? "up" : "down"
                                                }-s-line text-[16px]`}
                                            ></i>
                                        </div>
                                    )}
                                </div>
                            )}

                            <div className="hidden md:block">
                                <Button
                                    btnText={"Upgrade plan"}
                                    type={"button"}
                                    isLoading={false}
                                    onClick={() => setShowUpgradePlan(true)}
                                />
                            </div>
                        </div>
                        {showBenefit && (
                            <>
                                {!fetchingCurrentSubscription ? (
                                    <>
                                        {Boolean(fetchCurrentSubscriptionSuccess) &&
                                            fetchCurrentSubscriptionSuccess?.subscription && (
                                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-3 pt-6 text-slate-500 font-rocGroteskRegular text-xs">
                                                    {Object.values(subscriptionPlan?.benefit)?.map(
                                                        (d: { description: string }, idx: number) =>
                                                            d?.description &&
                                                            d?.description.trim() !== "" && (
                                                                <div
                                                                    key={idx}
                                                                    className="flex items-center space-x-1 mb-3"
                                                                >
                                                                    <i className="text-g-50 ri-check-line text-[16px]"></i>
                                                                    <p>{d?.description}</p>
                                                                </div>
                                                            )
                                                    )}
                                                </div>
                                            )}
                                    </>
                                ) : (
                                    <Loader />
                                )}
                            </>
                        )}

                        <div className=" md:hidden mt-6">
                            <Button
                                btnText={"Upgrade plan"}
                                type={"button"}
                                isLoading={false}
                                onClick={() => setShowUpgradePlan(true)}
                                btnClassname={``}
                            />
                        </div>
                    </div>
                    {!showBenefit && <hr className="mt-4" />}
                    <div
                        className={`${
                            !showBenefit ? "mt-6" : "mt-10"
                        } py-6 px-4 md:px-8 md:rounded-lg md:shadow-faintShadow font-rocGroteskMedium  text-gm-50`}
                    >
                        <p className="text-base mb-8">Your business AI credit balance</p>
                        <div className="p-6 border mb-6 border-border-light shadow-sm rounded-md">
                            <p className="text-4xl font-rocGroteskMedium text-center inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                                {fetchCreditHistorySuccess?.creditBalance}
                            </p>
                            <p className="text-sm">
                                AI responses used ({fetchCreditHistorySuccess?.percentageCreditUsed}
                                %)
                            </p>
                            <div className="mt-2 mb-6">
                                <ProgressBar
                                    className={" !bg-gradient-to-r from-[#5C58FF] to-[#FF3D1D]"}
                                    progress={fetchCreditHistorySuccess?.percentageCreditUsed}
                                />
                            </div>
                            <div className="w-fit ">
                                <Button
                                    btnClassname={
                                        "!py-3 !px-8 !bg-gradient-to-r from-[#5C58FF] to-[#FF3D1D]"
                                    }
                                    onClick={() => setPurchaseCreditModal(true)}
                                    btnText={"Purchase more credit"}
                                    type={"button"}
                                    isLoading={false}
                                />
                            </div>
                        </div>
                        <div>
                            <p className="text-gm-50 text-base font-rocGroteskMedium">
                                Here is the list of items you have utilized your AI Credit for up to
                                this point.
                            </p>
                            {!fetchingCreditHistory ? (
                                <div>
                                    {creditUsageHistory && creditUsageHistory.length > 0 && (
                                        <>
                                            {creditUsageHistory.slice(0, -1).map((history) => (
                                                <div className="py-3 border-slate-200 border-b flex justify-between text-gm-50 text-sm">
                                                    <p>{transformString(history?.action)}</p>
                                                    <p>{history?.totalAmount} used</p>
                                                </div>
                                            ))}
                                            <div className="py-3 flex justify-between text-gm-50 text-sm">
                                                <p>
                                                    {transformString(
                                                        creditUsageHistory[
                                                            creditUsageHistory.length - 1
                                                        ]?.action
                                                    )}
                                                </p>
                                                <p>
                                                    {
                                                        creditUsageHistory[
                                                            creditUsageHistory.length - 1
                                                        ]?.totalAmount
                                                    }{" "}
                                                    used
                                                </p>
                                            </div>
                                        </>
                                    )}
                                    {creditUsageHistory.length === 0 && (
                                        <div className="h-[200px] flex space-y-6 flex-col items-center justify-center">
                                            <i className="ri-sparkling-fill text-3xl text-[#C1C7D0]"></i>
                                            <h1 className="text-center md:w-[35%] 2xl:w-[25%]  text-sm text-[#6B778C]">
                                                Your AI usage is currently empty. Your AI usage for
                                                this task will be displayed here once you start
                                                using it.
                                            </h1>
                                        </div>
                                    )}
                                    <div className="pt-4 pb-1 font-rocGroteskMedium flex justify-between text-gm-50 text-sm">
                                        <p>{"Total AI Credits bought this month"}</p>
                                        <p>{fetchCreditHistorySuccess?.creditBalance} Credits</p>
                                    </div>
                                </div>
                            ) : (
                                <Loader />
                            )}
                        </div>
                    </div>
                    <RoiCalculator />
                </>
            ) : (
                <div className="pb-8 border-b border-slate-200">
                    <SubscriptionView
                        setShowUpgradePlan={setShowUpgradePlan}
                        onClick={() => setShowUpgradePlan(false)}
                        setSubscriptionPlanFailure={setSubscriptionPlanFailure}
                        setSubscriptionPlanSuccess={setSubscriptionPlanSuccess}
                        subscriptionPlanFailure={subscriptionPlanFailure}
                        subscriptionPlanSuccess={subscriptionPlanSuccess}
                    />
                </div>
            )}
            <div>
                <p className="text-lg font-rocGroteskMedium text-gm-50 pt-6 pb-3">
                    Payment receipts and billing history
                </p>
            </div>

            {!fetchingPaymentHistory ? (
                <>
                    {paymentHistory && paymentHistory.length > 0 && (
                        <>
                            <div className="text-slate-500 font-rocGroteskMedium text-sm hidden lg:block">
                                <CustomTable
                                    tableBody={
                                        tableBody as {
                                            content: React.JSX.Element;
                                        }[][]
                                    }
                                    tableHeader={tableHeader}
                                    isAllSelectable={true}
                                    isScrollable={false}
                                    isCellBordered={false}
                                    headerContainerClass="!bg-slate-50  !border !rounded-[8px] !border-slate-200"
                                    headerItemClass="font-rocGroteskMedium !text-slate-700 !text-sm"
                                />
                            </div>

                            <div className=" lg:hidden">{mobileTable()}</div>
                        </>
                    )}
                    {paymentHistory.length === 0 && (
                        <p className="text-slate-500 font-rocGroteskMedium text-sm">
                            There are no invoice to display.
                        </p>
                    )}
                </>
            ) : (
                <Loader />
            )}
            {purchaseCreditModal && (
                <ModalContainer
                    open={purchaseCreditModal}
                    showCloseIcon={false}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[600px]"
                    closeModal={() => {
                        setCredit(0);
                        setPurchaseCreditModal(false);
                    }}
                >
                    <div className="bg-white  p-8  shadow-faintShadow relative rounded-[8px]">
                        <h1 className="text-2xl font-rocGroteskMedium">
                            Get more done with SynthAlly AI
                        </h1>
                        <p className="text-sm font-rocGroteskMedium text-slate-500">
                            Go unlimited with SynthAlly AI for all members in your organization
                            workspace.
                        </p>
                        <div className="my-8">
                            <div className="text-sm mb-3 font-rocGroteskMedium flex items-center justify-between">
                                <p className="text-gm-50">Amount of credit</p>
                                <p className=" font-rocGroteskMedium text-center inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                                    $2 Per 1 credit
                                </p>
                            </div>
                            <div className="flex space-x-2">
                                <div className="w-[80%]">
                                    <TextInput
                                        value={credit as string}
                                        floatingPlaceholderClass={
                                            "!font-rocGroteskMedium !text-gm-50"
                                        }
                                        type={"text"}
                                        name="noOfCredit"
                                        inputContainerClassname={""}
                                        onChange={(e) => {
                                            if (!isNaN(e.target.value as any)) {
                                                setCredit(e.target.value);
                                            }
                                        }}
                                        required={true}
                                    />
                                </div>
                                <div className="flex space-x-2">
                                    <div
                                        className="border cursor-pointer flex justify-center items-center border-slate-200 p-3 h-12 w-12 rounded "
                                        onClick={() => {
                                            if (Number(credit) > 0) {
                                                setCredit(Number(credit) - 1);
                                            }
                                        }}
                                    >
                                        <i className="ri-subtract-line text-slate-500"></i>
                                    </div>
                                    <div
                                        onClick={() => setCredit(Number(credit) + 1)}
                                        className="border cursor-pointer flex justify-center items-center border-slate-200 p-3 h-12 w-12 rounded "
                                    >
                                        <i className="ri-add-line text-slate-500"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="text-sm mt-5 font-rocGroteskMedium ">
                                <p className="text-gm-50">Order summary</p>
                                <div className="text-sm py-3 font-rocGroteskMedium flex items-center justify-between">
                                    <p className="text-slate-500">Amount of credit</p>
                                    <p className="text-slate-500">${Number(credit) * 2}</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex space-x-2">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                disabled={fetchStripeSession}
                                onClick={() => {
                                    setCredit(0);
                                    setPurchaseCreditModal(false);
                                }}
                            />
                            <Button
                                btnClassname={
                                    "!py-3 !px-8 !bg-gradient-to-r from-[#5C58FF] to-[#FF3D1D]"
                                }
                                onClick={OnSubmitPurchase}
                                btnText={"Purchase more credit"}
                                type={"button"}
                                isLoading={fetchStripeSession}
                            />
                        </div>
                    </div>
                </ModalContainer>
            )}
            {purchaseCreditSuccess && (
                <ModalContainer
                    open={purchaseCreditSuccess}
                    showCloseIcon={false}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[500px]"
                    closeModal={() => {
                        setPurchaseCreditSuccess(false);
                        navigate("/dashboard/settings/2");
                    }}
                >
                    <div className="bg-white shadow-faintShadow !px-2 p-8  rounded-[12px]">
                        <div className="mt-4 px-6">
                            <div className="text-center flex justify-center flex-col items-center space-y-5">
                                <div className="w-14 h-14 flex justify-center items-center bg-[#00DB8F] rounded-full">
                                    <i className="ri-checkbox-circle-fill text-[29px] text-white "></i>
                                </div>
                                <div>
                                    <h3 className="text-2xl text-black font-rocGroteskMedium">
                                        AI Credit purchase successful!
                                    </h3>
                                    <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                        Your purchase of AI credits was successful and the
                                        additional credits are now available in your account for
                                        immediate use. A confirmation and receipt will be sent to
                                        your email shortly.
                                    </p>
                                </div>
                            </div>
                            <div className="my-4">
                                <p className="text-center font-rocGroteskMedium mb-5 text-black text-sm">
                                    Order summary
                                </p>
                                <div className="border-t py-3 border-slate-100">
                                    <div className="flex justify-between items-center font-rocGroteskMedium text-sm">
                                        <div className="flex space-x-1 items-center">
                                            <p className=" font-rocGroteskMedium text-center inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                                                <i className="ri-check-line text-base"></i>
                                            </p>{" "}
                                            <p>{Number(creditTotal)} AI Credit</p>
                                        </div>
                                        <p>${Number(creditTotal) * 2}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 flex space-x-3">
                                <Button
                                    btnText={"Close"}
                                    type={"button"}
                                    btnClassname={"!bg-n-20 !text-gm-50"}
                                    isLoading={false}
                                    onClick={() => {
                                        setPurchaseCreditSuccess(false);
                                        navigate("/dashboard/settings/2");
                                    }}
                                />
                                <Button
                                    btnClassname={
                                        "!py-3 !px-8 !bg-gradient-to-r from-[#5C58FF] to-[#FF3D1D]"
                                    }
                                    onClick={() => {
                                        setPurchaseCreditSuccess(false);
                                        navigate("/dashboard/settings/2");
                                    }}
                                    btnText={"View credit balance"}
                                    type={"button"}
                                    isLoading={false}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}

            {purchaseCreditFailure && (
                <ModalContainer
                    open={purchaseCreditFailure}
                    showCloseIcon={false}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[500px]"
                    closeModal={() => {
                        setPurchaseCreditFailure(false);
                        navigate("/dashboard/settings/2");
                    }}
                >
                    <div className="bg-white shadow-faintShadow !px-2 p-8  rounded-[12px]">
                        <div className="mt-4 px-6">
                            <div className="text-center flex justify-center flex-col items-center space-y-5">
                                <div className="w-14 h-14 flex justify-center items-center bg-r-50 shadow-icon-red rounded-full">
                                    <i className="ri-close-fill text-[29px] text-white "></i>
                                </div>
                                <div>
                                    <h3 className="text-2xl mb-2 text-black font-rocGroteskMedium">
                                        Payment Failed
                                    </h3>
                                    <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                        We've detected a problem with your recent payment. To
                                        resolve this issue and continue using our services, please
                                        update your payment information or reach out to our support
                                        team for assistance."
                                    </p>
                                </div>
                            </div>

                            <div className="mt-5 flex space-x-3">
                                <Button
                                    btnText={"Close"}
                                    type={"button"}
                                    btnClassname={"!bg-n-20 !text-gm-50"}
                                    disabled={fetchStripeSession}
                                    onClick={() => {
                                        setPurchaseCreditFailure(false);
                                        navigate("/dashboard/settings/2");
                                    }}
                                />
                                <Button
                                    onClick={() => onResubmitPurchase()}
                                    btnText={"Retry"}
                                    type={"button"}
                                    isLoading={fetchStripeSession}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}
            {purchaseCreditLimit && (
                <ModalContainer
                    open={purchaseCreditLimit}
                    showCloseIcon={false}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[500px]"
                    closeModal={() => {
                        setPurchaseCreditLimit(false);
                    }}
                >
                    <div className="bg-white shadow-faintShadow !px-2 pt-6 pb-7  rounded-[12px]">
                        <div className="mt-4 px-6">
                            <div className="text-center flex justify-center flex-col items-center space-y-1">
                                <p className=" font-rocGroteskMedium text-center inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                                    <i className="ri-sparkling-2-fill text-[53px]"></i>
                                </p>
                                <div>
                                    <h3 className="text-2xl text-black font-rocGroteskMedium">
                                        AI Credit Limit Reached
                                    </h3>
                                    <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                        You've reached your AI credit limit for this feature. To
                                        continue, please consider upgrading your plan or managing
                                        your AI usage in your account settings. If you have any
                                        questions, feel free to contact our support team."
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 flex space-x-3">
                                <Button
                                    btnText={"Close"}
                                    type={"button"}
                                    btnClassname={"!bg-n-20 !text-gm-50"}
                                    isLoading={false}
                                    onClick={() => {
                                        setPurchaseCreditSuccess(false);
                                        setPurchaseCreditModal(false);
                                    }}
                                />
                                <Button
                                    btnClassname={
                                        "!py-3 !px-8 !bg-gradient-to-r from-[#5C58FF] to-[#FF3D1D]"
                                    }
                                    onClick={() => {
                                        setPurchaseCreditModal(true);
                                    }}
                                    btnText={"Purchase more credit"}
                                    type={"button"}
                                    isLoading={false}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </div>
    );
};

export default Billing;
