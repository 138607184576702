import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import TabsContainer from "components/common/TabsContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { onScroll } from "helpers";
import { getEditPermissionList } from "helpers/getEditPermissionList";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { debounce, truncate } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";

const Inventory = () => {
    const limit = 20;
    const [activeTab, setActiveTab] = useState(0);
    const [isScrolled, setIsScrolled] = useState(false);
    const [productLimit, setProductLimit] = useState(limit);
    const [allProducts, setAllProducts] = useState<{ [key: string]: any }[]>([]);
    const [loadMoreProducts, setLoadMoreProducts] = useState(false);
    const [productPagination, setProductPagination] = useState<{
        current: number | string;
        number_of_pages: number | string;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [search, setSearch] = useState("");
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [debounceSearch, setDebounceSearch] = useState("");
    const [inventoryCategory, setInventoryCategory] = useState("");
    const [debouncedWarehouseSearch, setDebouncedWarehouseSearch] = useState<string>("");
    const [warehouse, setWarehouse] = useState("");
    const [location, setLocation] = useState("");
    const [warehouses, setWarehouses] = useState<{ [key: string]: any }[]>([]);
    const [lowStockAlertModal, setLowStockAlertModal] = useState<boolean>(false);
    const [pendingOrderModal, setPendingOrderModal] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        fetchingSearchInventoryListing,
        fetchedSearchInventoryListingSuccess,
        fetchedWarehouseListSuccess,
    } = useAppSelector((state) => state.inventory);

    const dashboardContent = document.getElementById("dashboardContent");
    const tabs = ["Product list"];

    const pageLoaded = useMemo(() => isPageLoaded, [isPageLoaded]);

    const tableHeader = [
        { title: "Product name", widthClass: "w-[312px]" },
        { title: "Product Category", widthClass: "w-[200px]" },
        { title: "Unit of Measure", widthClass: "w-[128px]" },
        { title: "Stock Level", widthClass: "w-[100px]" },
        { title: "SKU", widthClass: "w-[110px]" },
        { title: "Vendor/Supplier", widthClass: "w-[191px]" },
        { title: "Warehouse", widthClass: "w-[270px]" },
    ];

    const tableBody = allProducts?.map((product, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center gap-3">
                        <VendorAvatar
                            imageSrc={product?.productImageDetails?.productAvatar}
                            size={30}
                            name={product?.productName}
                        />
                        <div>
                            <p className="capitalize">
                                {truncate(product?.productName, { length: 25 })}
                            </p>
                            <p className="text-slate-500">{product?.sku}</p>
                        </div>
                    </div>
                </div>
            ),
            cellClickAction: () => navigate(`/dashboard/inventory/product/${product?._id}`),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p>{product?.inventoryCategory}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p>{product?.productStockDetails?.unitOfMeasurement}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p>{product?.productStockDetails?.stockLevel || 0}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 flex items-center h-full font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p>{product?.sku}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center gap-3">
                        <VendorAvatar
                            imageSrc={product?.vendors?.[0]?.profile?.avatar}
                            size={30}
                            name={"Vendor"}
                        />
                        <span>
                            {truncate(product?.vendors?.[0]?.profile?.businessName, { length: 15 })}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p>{product?.productStockSettings?.[0]?.warehouse?.warehouseName}</p>
                </div>
            ),
        },
    ]);

    const onProductTableVerticalScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                productPagination?.current as number,
                productPagination?.number_of_pages as number,
                () => {
                    setProductLimit(() => productLimit + limit);
                    setLoadMoreProducts(true);
                }
            ),
        [dashboardContent, productPagination]
    );

    const handleScroll = (e) => {
        if (e.target.scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const handleSearch = (e) => {
        const { name, value } = e.target;
        setSearch(value);
    };

    const handleDebouncedChange = debounce((e) => {
        const { name, value } = e.target;

        setDebounceSearch(value);
    }, 1000);

    const handleWarehouseDebouncedChange = debounce((e) => {
        setDebouncedWarehouseSearch(e.target.value);
    }, 1500);

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <div className="">
                        <div className="overflow-x-scroll hidden lg:block" onScroll={handleScroll}>
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isScrollable={true}
                                isScrolled={isScrolled}
                                isCellBordered={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium !text-[#334155]"
                                tableClass="!border-collapse"
                            />
                        </div>
                        {loadMoreProducts && fetchingSearchInventoryListing && (
                            <div className="flex my-4 justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {productPagination?.current === productPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                <p className="text-center"> End of list</p>
                            </div>
                        )}
                    </div>
                );

            default:
                return <div></div>;
        }
    };

    const EmptyStateComp = () => {
        return (
            <div>
                <div
                    className="flex max-md:flex-col-reverse max-md:gap-4 max-md:px-6 rounded-[10px] py-[38px] pl-[29px] pr-[70px] shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)] 
                        relative justify-between h-[264px] bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1704890971/Tradeally_2.0/Green_background_empty_ejd22c.jpg')] w-full bg-cover bg-center bg-no-repeat"
                >
                    <div className="">
                        <h1 className="text-[30px] max-md:text-2xl leading-[35px] font-rocGroteskBold max-w-[522px]">
                            Increase your sales and keep track of every unit with our powerful
                            inventory management feature.
                        </h1>
                        <Button
                            btnType="textFirst"
                            btnText={"Add Inventory"}
                            onClick={() => navigate("/dashboard/inventory/add-product")}
                            type="button"
                            btnClassname="!leading-[24px] !bg-[#042821] !text-white !w-auto mt-[43px] max-md:mt-3 !rounded-[4px] !py-[10px] !px-6"
                            icon={<i className="ri-arrow-right-line"></i>}
                            permissions={getEditPermissionList("inventory")}
                            tipParentClassname="!z-[9]"
                            tipClassName="!left-[15%]"
                        />
                    </div>
                </div>

                <div className="flex items-center justify-center mt-[76px]">
                    <div>
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1704890969/Tradeally_2.0/empty_state_img_a8twwl.svg"
                            alt="empty state"
                            className="w-[268px] h-[235px]"
                        />
                        <p className="text-center text-sm text-[#475569] font-rocGroteskMedium mt-5">
                            You have no inventory yet
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        dispatch(
            inventoryActions.fetchSearchInventoryListing(
                productLimit,
                debounceSearch,
                location,
                inventoryCategory
            )
        );
    }, [dispatch, productLimit, debounceSearch, inventoryCategory, location]);

    useEffect(() => {
        if (Boolean(fetchedSearchInventoryListingSuccess)) {
            setAllProducts(fetchedSearchInventoryListingSuccess?.inventory?.data);
            setProductPagination({
                current: fetchedSearchInventoryListingSuccess?.pagination?.current,
                number_of_pages: fetchedSearchInventoryListingSuccess?.pagination?.number_of_pages,
            });
            setIsPageLoaded(true);
        }
    }, [fetchedSearchInventoryListingSuccess]);

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouseList(15, debouncedWarehouseSearch));
    }, [dispatch, debouncedWarehouseSearch]);

    useEffect(() => {
        if (Boolean(fetchedWarehouseListSuccess)) {
            setWarehouses(fetchedWarehouseListSuccess?.warehouses?.data);
        }
    }, [fetchedWarehouseListSuccess]);

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onProductTableVerticalScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onProductTableVerticalScroll);
        };
    }, [dashboardContent, onProductTableVerticalScroll]);

    return (
        <PageFrame
            isLoading={
                fetchingSearchInventoryListing && !pageLoaded
                // !Boolean(debounceSearch) &&
                // !loadMoreProducts &&
                // !location &&
                // !inventoryCategory
            }
            containerClassName={"!h-[calc(100vh-108px)]"}
        >
            <div className="px-4">
                {allProducts?.length === 0 &&
                    !fetchingSearchInventoryListing &&
                    !Boolean(debounceSearch) && <EmptyStateComp />}

                {(allProducts?.length > 0 || Boolean(debounceSearch)) && (
                    <div className="w-full">
                        <div className="mb-6 flex items-center justify-between">
                            <BreadCrumb parentCrumb="Inventory Management" size="large" />
                            <div className="flex items-center gap-2">
                                <Button
                                    btnText="Reorder"
                                    btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                                    onClick={() =>
                                        navigate(
                                            "/dashboard/inventory/stock-control/new-transfer?method=2"
                                        )
                                    }
                                    permissions={getEditPermissionList("inventory")}
                                />
                                <Button
                                    btnText="Add product"
                                    btnClassname="!px-4 !py-2.5 !w-fit"
                                    onClick={() => navigate("/dashboard/inventory/add-product")}
                                    permissions={getEditPermissionList("inventory")}
                                />
                            </div>
                        </div>

                        <div className="relative">
                            <div className="flex justify-end mb-[-40px]">
                                <div className="flex items-center gap-2 w-1/2">
                                    <TextInput
                                        name={"search"}
                                        value={search}
                                        type={"text"}
                                        inputPlaceholder={"Search"}
                                        inputClassName={"!h-[40px] pl-[0px] !mb-0 text-sm "}
                                        onChange={handleSearch}
                                        onInput={handleDebouncedChange}
                                        leftIcon={
                                            <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                        }
                                        inputContainerClassname={
                                            "!rounded-[4px] !w-full !w-[208px] !h-[40px] !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                                        }
                                    />

                                    <Dropdown
                                        name="location"
                                        value={""}
                                        dropdownTriggerClassName={" "}
                                        handleChange={(name, value) => {
                                            setLocation(value?.split("-")?.[1]);
                                            setWarehouse(value?.split("-")?.[0]);
                                        }}
                                        dropdown={
                                            <div className="relative">
                                                <TextInput
                                                    value={truncate(warehouse, { length: 10 })}
                                                    name={"location"}
                                                    type={"text"}
                                                    onChange={(e) => setWarehouse(e.target.value)}
                                                    onInput={(e) =>
                                                        handleWarehouseDebouncedChange(e)
                                                    }
                                                    inputPlaceholder={"Location"}
                                                    required={false}
                                                    inputClassName="!w-[145px] !h-10 !border-0 placeholder:!font-rocGroteskMedium placeholder:!text-gm-50 placeholder:!text-[13px]"
                                                    inputContainerClassname={"!border-0 !h-10"}
                                                    containerClassname="bg-white flex items-center h-10 gap-2 py-2.5 shadow-boxShadow-2 border border-tradeally-neutral-20"
                                                />
                                                {Boolean(location) && (
                                                    <i
                                                        onClick={(e) => {
                                                            e?.stopPropagation();
                                                            setLocation("");
                                                            setWarehouse("");
                                                        }}
                                                        className="ri-close-circle-fill text-sm text-slate-500 absolute right-[30px] top-[10px]"
                                                    ></i>
                                                )}
                                                <i className="ri-arrow-down-s-line text-lg text-gm-50 absolute right-[12px] top-[6px]"></i>
                                            </div>
                                        }
                                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                        dropdownContainerClasses={`shadow-cardShadow-4 left-[-60px] !mt-0 !w-[159px] rounded !max-h-[290px] !p-0 !top-[105%]`}
                                        dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                        dropdownOptions={warehouses?.map((warehouse) => ({
                                            label: warehouse?.warehouseName,
                                            value: warehouse?.warehouseName + "-" + warehouse?._id,
                                        }))}
                                    />

                                    <Dropdown
                                        name="inventoryCategory"
                                        value={""}
                                        dropdownTriggerClassName={" "}
                                        handleChange={(name, value) => setInventoryCategory(value)}
                                        dropdown={
                                            <div className="bg-white flex items-center h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-tradeally-neutral-20">
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    {inventoryCategory || "Category"}
                                                </span>
                                                {Boolean(inventoryCategory) && (
                                                    <i
                                                        onClick={(e) => {
                                                            e?.stopPropagation();
                                                            setInventoryCategory("");
                                                        }}
                                                        className="ri-close-circle-fill text-sm text-slate-500"
                                                    ></i>
                                                )}
                                                <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                            </div>
                                        }
                                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                        dropdownContainerClasses={`shadow-cardShadow-4 left-[-190px] !mt-0  !w-[159px] rounded !max-h-fit !p-0 !top-[105%]`}
                                        dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                        dropdownOptions={[
                                            {
                                                label: "Raw Materials",
                                                value: "Raw Material",
                                            },
                                            {
                                                label: "Work-in-Progress (WIP)",
                                                value: "Work-In-Progress (WIP)",
                                            },
                                            {
                                                label: "Finished Goods",
                                                value: "Finished Goods",
                                            },
                                            {
                                                label: "Maintenance, Repair, and Overall (MRO)",
                                                value: "Maintenance, Repair, and Overall (MRO)",
                                            },
                                        ]}
                                    />

                                    {/* <Button
                                        btnClassname="!py-2 !px-3 !h-[40px] !bg-white shadow-boxShadow-2 border border-tradeally-neutral-20 !w-fit"
                                        btnText="Settings"
                                        icon={<i className="ri-settings-5-line text-base"></i>}
                                    /> */}
                                </div>
                            </div>
                            <TabsContainer
                                tabs={tabs}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                component={displayActiveTab()}
                                className="!px-0"
                                itemClassName="!pb-2"
                                borderLineClase={"!text-slate-100 w-[40%] mb-4"}
                                //     showButton={
                                //         <div className="flex items-center gap-2">
                                //             <TextInput
                                //                 name={"search"}
                                //                 value={search}
                                //                 type={"text"}
                                //                 inputPlaceholder={"Search"}
                                //                 inputClassName={"!h-[40px] pl-[0px] !mb-0 text-sm "}
                                //                 onChange={handleSearch}
                                //                 onInput={handleDebouncedChange}
                                //                 leftIcon={
                                //                     <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                //                 }
                                //                 inputContainerClassname={
                                //                     "!rounded-[4px] !w-full !w-[208px] !h-[40px] !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                                //                 }
                                //             />

                                //             <Dropdown
                                //                 name="rawMaterials"
                                //                 value={""}
                                //                 dropdownTriggerClassName={" "}
                                //                 handleChange={(name, value) => console.log("--")}
                                //                 dropdown={
                                //                     <div className="bg-white flex items-center h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-tradeally-neutral-20">
                                //                         <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                //                             Location
                                //                         </span>
                                //                         <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                //                     </div>
                                //                 }
                                //                 dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                //                 dropdownContainerClasses={`shadow-cardShadow-4 left-[-60px] !mt-0  !w-[159px] rounded !max-h-fit !p-0 !top-[105%]`}
                                //                 dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                //                 dropdownOptions={[
                                //                     {
                                //                         label: "Raw Materials",
                                //                         value: "raw materials",
                                //                     },
                                //                     {
                                //                         label: "Work-in-Progress (WIP)",
                                //                         value: "work-in-progress",
                                //                     },
                                //                     {
                                //                         label: "Finished Goods",
                                //                         value: "finished goods",
                                //                     },
                                //                     {
                                //                         label: "Maintenance, Repair, and Overall (MRO)",
                                //                         value: "maintenance, repair, and overall",
                                //                     },
                                //                 ]}
                                //             />

                                //             <Dropdown
                                //                 name="rawMaterials"
                                //                 value={""}
                                //                 dropdownTriggerClassName={" "}
                                //                 handleChange={(name, value) => console.log("--")}
                                //                 dropdown={
                                //                     <div className="bg-white flex items-center h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-tradeally-neutral-20">
                                //                         <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                //                             Raw Materials
                                //                         </span>
                                //                         <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                //                     </div>
                                //                 }
                                //                 dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                //                 dropdownContainerClasses={`shadow-cardShadow-4 left-[-60px] !mt-0  !w-[159px] rounded !max-h-fit !p-0 !top-[105%]
                                // `}
                                //                 dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                //                 dropdownOptions={[
                                //                     {
                                //                         label: "Raw Materials",
                                //                         value: "raw materials",
                                //                     },
                                //                     {
                                //                         label: "Work-in-Progress (WIP)",
                                //                         value: "work-in-progress",
                                //                     },
                                //                     {
                                //                         label: "Finished Goods",
                                //                         value: "finished goods",
                                //                     },
                                //                     {
                                //                         label: "Maintenance, Repair, and Overall (MRO)",
                                //                         value: "maintenance, repair, and overall",
                                //                     },
                                //                 ]}
                                //             />

                                //             <Button
                                //                 btnClassname="!py-2 !px-3 !h-[40px] !bg-white shadow-boxShadow-2 border border-tradeally-neutral-20 !w-fit"
                                //                 btnText="Settings"
                                //                 icon={<i className="ri-settings-5-line text-base"></i>}
                                //             />
                                //         </div>
                                //     }
                            />
                        </div>
                    </div>
                )}

                <ModalContainer
                    open={lowStockAlertModal}
                    showCloseIcon={false}
                    tailwindClassName="w-[35%] max-lg:w-1/2 max-sm:w-[95%]"
                    closeModal={() => {
                        setLowStockAlertModal(false);
                    }}
                >
                    <div className="bg-white rounded-lg shadow-cardShadow relative">
                        <div className="px-10 pt-3">
                            <div className="flex flex-col items-center text-center">
                                <div className="flex w-full justify-end mr-[-12px]">
                                    <i
                                        onClick={() => setLowStockAlertModal(false)}
                                        className="ri-close-fill text-2xl cursor-pointe"
                                    ></i>
                                </div>

                                <i className="ri-archive-2-line text-[50px] text-y-10"></i>

                                <p className="text-xl mb-3 text-center mt-4 font-rocGroteskMedium text-g-75">
                                    Inventory Low Stock Alert
                                </p>
                                <p className="text-sm text-center font-rocGroteskMedium text-slate-500">
                                    We've identified low stock levels for three crucial items in
                                    your inventory.we recommend restocking. Details below
                                </p>
                                <div className="w-full py-6 mt">
                                    <div className="w-full flex flex-col gap-1 py-4 border-t border-slate-100">
                                        <div className="w-full flex items-center font-rocGroteskMedium justify-between text-sm ">
                                            <span className="w-1/2 text-slate-500 text-left">
                                                Item
                                            </span>
                                            <span className="w-1/2 text-right">Laptop</span>
                                        </div>
                                        <div className="w-full flex items-center font-rocGroteskMedium justify-between text-sm ">
                                            <span className="w-1/2 text-slate-500 text-left">
                                                Current Stock
                                            </span>
                                            <span className="w-1/2 text-right text-r-50">
                                                5 units
                                            </span>
                                        </div>
                                        <div className="w-full flex items-center font-rocGroteskMedium justify-between text-sm ">
                                            <span className="w-1/2 text-slate-500 text-left">
                                                Minimum stock level
                                            </span>
                                            <span className="w-1/2 text-right">10 units</span>
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-col gap-1 py-4 border-t border-slate-100">
                                        <div className="w-full flex items-center font-rocGroteskMedium justify-between text-sm ">
                                            <span className="w-1/2 text-slate-500 text-left">
                                                Item
                                            </span>
                                            <span className="w-1/2 text-right">Laptop</span>
                                        </div>
                                        <div className="w-full flex items-center font-rocGroteskMedium justify-between text-sm ">
                                            <span className="w-1/2 text-slate-500 text-left">
                                                Current Stock
                                            </span>
                                            <span className="w-1/2 text-right text-r-50">
                                                5 units
                                            </span>
                                        </div>
                                        <div className="w-full flex items-center font-rocGroteskMedium justify-between text-sm ">
                                            <span className="w-1/2 text-slate-500 text-left">
                                                Minimum stock level
                                            </span>
                                            <span className="w-1/2 text-right">10 units</span>
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-col gap-1 py-4 border-t border-slate-100">
                                        <div className="w-full flex items-center font-rocGroteskMedium justify-between text-sm ">
                                            <span className="w-1/2 text-slate-500 text-left">
                                                Item
                                            </span>
                                            <span className="w-1/2 text-right">Laptop</span>
                                        </div>
                                        <div className="w-full flex items-center font-rocGroteskMedium justify-between text-sm ">
                                            <span className="w-1/2 text-slate-500 text-left">
                                                Current Stock
                                            </span>
                                            <span className="w-1/2 text-right text-r-50">
                                                5 units
                                            </span>
                                        </div>
                                        <div className="w-full flex items-center font-rocGroteskMedium justify-between text-sm ">
                                            <span className="w-1/2 text-slate-500 text-left">
                                                Minimum stock level
                                            </span>
                                            <span className="w-1/2 text-right">10 units</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full flex items-center gap-2 py-6">
                                <div className="w-full">
                                    <Button
                                        btnText={"Not now"}
                                        type={"button"}
                                        btnClassname="!bg-tradeally-neutral-20"
                                        onClick={() => {
                                            setLowStockAlertModal(false);
                                        }}
                                    />
                                </div>
                                <div className="w-full">
                                    <Button
                                        btnText={"Restock now"}
                                        type={"button"}
                                        onClick={() => {
                                            console.log("--");
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalContainer>

                <ModalContainer
                    open={pendingOrderModal}
                    showCloseIcon={false}
                    tailwindClassName="w-[35%] max-lg:w-1/2 max-sm:w-[95%]"
                    closeModal={() => {
                        setPendingOrderModal(false);
                    }}
                >
                    <div className="bg-white rounded-lg shadow-cardShadow relative">
                        <div className="px-10 pt-3">
                            <div className="flex flex-col items-center text-center">
                                <div className="flex w-full justify-end mr-[-12px]">
                                    <i
                                        onClick={() => setPendingOrderModal(false)}
                                        className="ri-close-fill text-2xl cursor-pointe"
                                    ></i>
                                </div>

                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1697712771/Caution_sign_g1u9nv.svg"
                                    alt="caution"
                                    className="w-[48px] h-[43px]"
                                />

                                <p className="text-xl mb-3 text-center mt-4 font-rocGroteskMedium text-g-75">
                                    Pending Orders & Potential Delays
                                </p>
                                <p className="text-sm text-center font-rocGroteskMedium text-slate-500">
                                    Our automated system has detected pending orders in the
                                    fulfillment process. There may be potential delays that require
                                    your attention. Please review the details below:
                                </p>
                            </div>

                            <div className="w-full flex items-center gap-2 py-6">
                                <div className="w-full">
                                    <Button
                                        btnText={"Not now"}
                                        type={"button"}
                                        btnClassname="!bg-tradeally-neutral-20"
                                        onClick={() => {
                                            setPendingOrderModal(false);
                                        }}
                                    />
                                </div>
                                <div className="w-full">
                                    <Button
                                        btnText={"Review"}
                                        type={"button"}
                                        onClick={() => {
                                            console.log("--");
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            </div>
        </PageFrame>
    );
};

export default Inventory;
