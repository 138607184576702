import React, { useCallback, useEffect, useState } from "react";
import VendorAvatar from "components/common/VendorAvatar";
import Dropdown from "components/common/Dropdown";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import { convertToTime, onScroll } from "helpers";
import moment from "moment";
import PageFrame from "components/layout/PageFrame";
import Loader from "components/common/Loader";

const History = () => {
    const dispatch = useAppDispatch();
    const limit = 20;
    const [historyLimit, setHistoryLimit] = useState(limit);
    const [historyList, setHistoryList] = useState<any>([]);
    const [loadMoreHistory, setLoadMoreHistory] = useState(false);
    const [historyPagination, setHistoryPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const { id } = useParams();
    const { fetchedInventoryHistorySuccess, fetchingInventoryHistory } = useAppSelector(
        (state) => state.inventory
    );
    const dashboardContent = document.getElementById("dashboardContent");

    useEffect(() => {
        dispatch(inventoryActions.fetchInventoryHistory(id, historyLimit));
    }, [id, historyLimit]);

    useEffect(() => {
        if (Boolean(fetchedInventoryHistorySuccess)) {
            setHistoryList(fetchedInventoryHistorySuccess?.history);
            setHistoryPagination({
                current: fetchedInventoryHistorySuccess?.pagination?.current,
                number_of_pages: fetchedInventoryHistorySuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedInventoryHistorySuccess]);

    const onHistoryScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                historyPagination?.current as number,
                historyPagination?.number_of_pages as number,
                () => {
                    setHistoryLimit(() => historyLimit + limit);
                    setLoadMoreHistory(true);
                }
            ),
        [dashboardContent, historyPagination, historyLimit]
    );

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onHistoryScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onHistoryScroll);
        };
    }, [dashboardContent, onHistoryScroll]);

    const location = [
        { id: "1", name: "Raw Materials" },
        { id: "2", name: "Work-in-Progress (WIP)" },
        { id: "3", name: "Finished Goods" },
        { id: "4", name: "Maintenance, Repair, and Overall  (MRO)" },
    ];
    return (
        <PageFrame>
            {fetchingInventoryHistory && historyList?.length === 0 ? (
                <div className="flex my-4 justify-center">
                    <Loader color="gm-25" size={4} />
                </div>
            ) : (
                <>
                    {historyList?.length > 0 ? (
                        <>
                            <div className="flex w-full justify-between">
                                <div className=" ">
                                    {historyList?.map((data, idx) => (
                                        <div className="" key={idx}>
                                            {idx > 0 && (
                                                <div className="h-4 bg-[#80E2FC] w-[2.5px] ml-2 rounded my-0.5"></div>
                                            )}
                                            <div className="mb-3">
                                                <p className="font-rocGroteskMedium text-slate-900 text-sm">
                                                    {moment(data?._id?.month, "M").format("MMM")}{" "}
                                                    {data?._id?.day}, {data?._id?.year}{" "}
                                                    <span className="text-slate-500 text-[8px]">
                                                        {convertToTime(
                                                            data?._id?.hourOfDay,
                                                            data?._id?.minuteOfHour
                                                        )}
                                                    </span>
                                                </p>
                                            </div>
                                            {data?.history?.map((history, index) => (
                                                <div key={index}>
                                                    <div className="p-[15px] border-[0.4px] border-slate-300 bg-[#FAFDFF] rounded-[8px]">
                                                        <p className="mb-[7px] text-sm font-rocGroteskMedium text-slate-800">
                                                            {history?.activityText}
                                                        </p>
                                                        <div className="space-y-1">
                                                            {history?.actionByDetails &&
                                                                history?.actionByDetails
                                                                    ?.profile && (
                                                                    <div className="text-[8px] text-slate-600 font-rocGroteskMedium flex  space-x-1">
                                                                        <p>Action by:</p>
                                                                        <VendorAvatar
                                                                            size={14}
                                                                            name={
                                                                                history
                                                                                    ?.actionByDetails
                                                                                    ?.fullName ??
                                                                                history
                                                                                    ?.actionByDetails
                                                                                    ?.profile
                                                                                    ?.businessName
                                                                            }
                                                                            imageSrc={
                                                                                history
                                                                                    ?.actionByDetails
                                                                                    ?.profile
                                                                                    ?.avatar
                                                                            }
                                                                            textClassname={
                                                                                "!text-[6px]"
                                                                            }
                                                                        />
                                                                        <p>
                                                                            {history
                                                                                ?.actionByDetails
                                                                                ?.fullName ??
                                                                                history
                                                                                    ?.actionByDetails
                                                                                    ?.profile
                                                                                    ?.businessName}
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            {history?.supplierDetails &&
                                                                history?.supplierDetails
                                                                    ?.profile && (
                                                                    <div className="text-[8px] text-slate-600 font-rocGroteskMedium flex  space-x-1">
                                                                        <p>Supplier:</p>
                                                                        <VendorAvatar
                                                                            size={14}
                                                                            name={
                                                                                history
                                                                                    ?.supplierDetails
                                                                                    ?.fullName ??
                                                                                history
                                                                                    ?.supplierDetails
                                                                                    ?.profile
                                                                                    ?.businessName
                                                                            }
                                                                            imageSrc={
                                                                                history
                                                                                    ?.supplierDetails
                                                                                    ?.profile
                                                                                    ?.avatar
                                                                            }
                                                                            textClassname={
                                                                                "!text-[6px]"
                                                                            }
                                                                        />
                                                                        <p>
                                                                            {history
                                                                                ?.supplierDetails
                                                                                ?.fullName ??
                                                                                history
                                                                                    ?.supplierDetails
                                                                                    ?.profile
                                                                                    ?.businessName}
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            {history?.assigneeDetails &&
                                                                history?.assigneeDetails
                                                                    ?.profile && (
                                                                    <div className="text-[8px] text-slate-600 font-rocGroteskMedium flex  space-x-1">
                                                                        <p>Assignee:</p>
                                                                        <VendorAvatar
                                                                            size={14}
                                                                            name={
                                                                                history
                                                                                    ?.assigneeDetails
                                                                                    ?.fullName ??
                                                                                history
                                                                                    ?.assigneeDetails
                                                                                    ?.profile
                                                                                    ?.businessName
                                                                            }
                                                                            imageSrc={
                                                                                history
                                                                                    ?.assigneeDetails
                                                                                    ?.profile
                                                                                    ?.avatar
                                                                            }
                                                                            textClassname={
                                                                                "!text-[6px]"
                                                                            }
                                                                        />
                                                                        <p>
                                                                            {history
                                                                                ?.assigneeDetails
                                                                                ?.fullName ??
                                                                                history
                                                                                    ?.assigneeDetails
                                                                                    ?.profile
                                                                                    ?.businessName}
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            {history?.inventoryDetails &&
                                                                history?.inventoryDetails
                                                                    ?.productName && (
                                                                    <div className="text-[8px] text-slate-600 font-rocGroteskMedium flex  space-x-1">
                                                                        <p>Inventory details:</p>
                                                                        <VendorAvatar
                                                                            size={14}
                                                                            name={
                                                                                history
                                                                                    ?.inventoryDetails
                                                                                    ?.productName
                                                                            }
                                                                            imageSrc={
                                                                                history
                                                                                    ?.inventoryDetails
                                                                                    ?.productAvatar
                                                                            }
                                                                            textClassname={
                                                                                "!text-[6px]"
                                                                            }
                                                                        />
                                                                        <p>
                                                                            {
                                                                                history
                                                                                    ?.inventoryDetails
                                                                                    ?.productName
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                    {index !== data.history.length - 1 && (
                                                        <div className="h-4 bg-[#80E2FC] w-[2.5px] ml-2 rounded my-0.5"></div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                                <div className="w-fit">
                                    <Dropdown
                                        handleChange={(name, value) => {}}
                                        dropdown={
                                            <div className="flex text-gm-50 whitespace-nowrap border border-n-20 items-center rounded-[5px] shadow-[0_1px_2px_0_rgba(91,104,113,0.15)] py-2 px-[15px] gap-2">
                                                <i className="ri-filter-3-line"></i>

                                                <p className="font-rocGroteskMedium  text-sm">
                                                    Filter
                                                </p>
                                                <i className="ri-arrow-down-s-line"></i>
                                            </div>
                                        }
                                        dropdownItemsClasses={`!px-0 !py-0 !w-full`}
                                        dropdownClassName="!w-full !mb-10  !-mt-7"
                                        dropdownContainerClasses={`shadow-cardShadow-4 !border-b !mb-0 border-slate-100  rounded !max-h-fit
            `}
                                        dropdownOptions={[
                                            ...location?.map((item, idx) => ({
                                                label: (
                                                    <p
                                                        key={idx}
                                                        className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                px-4 py-[10px]"
                                                    >
                                                        {item.name}
                                                    </p>
                                                ),
                                                value: item.id,
                                            })),
                                        ]}
                                    />
                                </div>
                            </div>
                            {loadMoreHistory && fetchingInventoryHistory && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {historyPagination?.current === historyPagination?.number_of_pages && (
                                <></>
                            )}
                        </>
                    ) : (
                        <div className="flex items-center justify-center mt-[76px]">
                            <div>
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1704890969/Tradeally_2.0/empty_state_img_a8twwl.svg"
                                    alt="empty state"
                                    className="w-[268px] h-[235px]"
                                />
                                <p className="text-center text-sm text-[#475569] font-rocGroteskMedium mt-5">
                                    You have no history
                                </p>
                            </div>
                        </div>
                    )}
                </>
            )}
        </PageFrame>
    );
};
export default History;
