/* eslint-disable react-hooks/exhaustive-deps */
import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CustomMobileTable from "components/common/CustomMobileTable";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import OrderSummaryCardOne from "components/views/Ldb/Dashboard/Orders/OrderSummaryCardOne";
import OrderSummaryCardTwo from "components/views/Ldb/Dashboard/Orders/OrderSummaryCardTwo";
import { getCurrencyFromCurrencyCode, onScroll } from "helpers";
import { debounce, truncate } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { orderActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";
import moment from "moment";

const Orders = () => {
    const limit = 20;

    const [orders, setOrders] = useState([]);
    const [orderTotal, setOrderTotal] = useState(0);

    const [orderLimit, setOrderLimit] = useState(limit);

    const [orderPagination, setOrderPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [rfqModalOpen, setRfqModalOpen] = useState(false);
    const [loadMoreOrders, setLoadMoreOrders] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<{ [key: string]: any }>({});
    const [filter, setFilter] = useState({
        search: "",
        amount: "",
        vendor: "",
        orderId: "",
    });
    const [debouncedFilter, setDebouncedFilter] = useState({
        search: "",
        amount: "",
        vendor: "",
        orderId: "",
    });

    const dashboardContent = document.getElementById("dashboardContent");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { fetchingOrders, fetchedOrdersSuccess, sendingRfqs, sendRfqsSuccess } = useAppSelector(
        (state) => state.order
    );

    const handleSendRfqs = (orderDetails?: { [key: string]: any }) => {
        const body: { [key: string]: any } = {
            itemsAndVendors: orderDetails?.items?.map((service: { [key: string]: any }) => ({
                item: service?.description,
                quantity: service?.quantity,
                vendors: orderDetails.suggestedVendors?.map(
                    (vendor: { [key: string]: any }) => vendor?._id
                ),
            })),
            deliveryDate: orderDetails?.deliveryDate,
            specifications: orderDetails?.specifications,
            orderType: orderDetails?.orderType,

            budget: {
                currency: orderDetails?.budget?.currency,
                amount: orderDetails?.budget?.amount,
            },
        };

        if (Boolean(orderDetails?.customerDetails?.email)) {
            body.customerDetails = {
                ...body.customerDetails,
                email: orderDetails?.customerDetails?.email,
            };
        }
        if (Boolean(orderDetails?.customerDetails?.country)) {
            body.customerDetails = {
                ...body.customerDetails,
                country: orderDetails?.customerDetails?.country,
            };
        }
        if (Boolean(orderDetails?.customerDetails?.firstNane)) {
            body.customerDetails = {
                ...body.customerDetails,
                firstNane: orderDetails?.customerDetails?.firstNane,
            };
        }
        if (Boolean(orderDetails?.customerDetails?.lastName)) {
            body.customerDetails = {
                ...body.customerDetails,
                lastName: orderDetails?.customerDetails?.lastName,
            };
        }
        if (Boolean(orderDetails?.customerDetails?.companyName)) {
            body.customerDetails = {
                ...body.customerDetails,
                companyName: orderDetails?.customerDetails?.companyName,
            };
        }
        if (
            Boolean(orderDetails?.customerDetails?.phone?.mobile) &&
            Boolean(orderDetails?.customerDetails?.phone?.rawMobile) &&
            Boolean(orderDetails?.customerDetails?.phone?.mobileExtension)
        ) {
            body.customerDetails = {
                ...body.customerDetails,
                phone: {
                    mobile: orderDetails?.customerDetails?.phone?.mobile,
                    rawMobile: orderDetails?.customerDetails?.phone?.rawMobile,
                    mobileExtension: orderDetails?.customerDetails?.phone?.mobileExtension,
                },
            };
        }

        dispatch(orderActions.sendRfqs(body));
    };

    const tableHeader = [
        { title: "Order ID", widthClass: "w-[19%]" },
        { title: "Customer", widthClass: "w-[16%]" },
        { title: "Source", widthClass: "w-[13.6%]" },
        // { title: "Stock level", widthClass: "w-[8.4%]" },
        { title: "Order date", widthClass: "w-[11%]" },
        // { title: "Status", widthClass: "w-[12%]" },
        { title: "Vendor/Supplier", widthClass: "w-[20%]" },
        { title: "", widthClass: "w-[4%]" },
    ];
    console.log("orders", orders);
    const tableBody = orders?.map((order) => {
        const name = order?.items
            ?.map((item: { [key: string]: any }) => item?.description)
            ?.join(", ");

        return [
            {
                content: (
                    <div
                        className={`py-[18px] pr-4 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                    >
                        <p className="text-slate-900 ">Ord {order?.taId}</p>
                        <p className="text-slate-500">{truncate(name, { length: 25 })}</p>
                    </div>
                ),
                cellClickAction: () => {
                    navigate(`/dashboard/order/${order?._id}`);
                },
            },
            {
                content: (
                    <div
                        className={`py-[18px]  pr-4 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                    >
                        <div className="flex items-center gap-3">
                            <VendorAvatar
                                size={24}
                                name={order?.customerDetails?.companyName}
                                imageSrc={
                                    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1694624630/Tradeally_2.0/undefined_-_Imgur_1_prpxel.jpg"
                                }
                            />
                            <span>
                                {truncate(order?.customerDetails?.companyName, { length: 15 })}
                            </span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-4 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-900 `}
                    >
                        <div className="flex items-center gap-3">
                            <img
                                src={
                                    order?.channel === "microsoft"
                                        ? "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700839953/Tradeally_2.0/icons/microsoft_logo_cwqmmo.svg"
                                        : order?.channel === "gmail"
                                        ? "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700554454/Tradeally_2.0/icons/google_wtrcmu.svg"
                                        : "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700554454/Tradeally_2.0/icons/whatsapp_vjsjhe.svg"
                                }
                                alt="logo"
                                className="w-6 h-6"
                            />
                            <span className="capitalize">
                                {order?.channel === "microsoft" ? "Outlook" : order?.channel}
                            </span>
                        </div>
                    </div>
                ),
            },
            // {
            //     content: (
            //         <div
            //             className={`py-[18px] pr-4 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-900 `}
            //         >
            //             {order?.amount
            //                 ? getCurrencyFromCurrencyCode(order?.amount?.from?.currency) +
            //                   order?.amount?.from?.amount +
            //                   " - " +
            //                   getCurrencyFromCurrencyCode(order?.amount?.from?.currency) +
            //                   order?.amount?.to?.amount
            //                 : "----"}
            //         </div>
            //     ),
            // },
            {
                content: (
                    <div
                        className={`py-[18px] pr-4 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-900 `}
                    >
                        {moment(order?.createdAt).format("MMM DD, YYYY")}
                    </div>
                ),
            },
            // {
            //     content: (
            //         <div
            //             className={`py-[18px] pr-4 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-900 `}
            //         >
            //             {order?.amount
            //                 ? getCurrencyFromCurrencyCode(order?.amount?.from?.currency) +
            //                   order?.amount?.from?.amount +
            //                   " - " +
            //                   getCurrencyFromCurrencyCode(order?.amount?.from?.currency) +
            //                   order?.amount?.to?.amount
            //                 : "----"}
            //         </div>
            //     ),
            // },
            {
                content: (
                    <div
                        className={`py-[18px] pr-4 flex items-center h-full border-slate-100 text-sm text-slate-900 `}
                    >
                        {order?.suggestedVendors && order?.suggestedVendors?.length > 0 && (
                            <div
                                className={` flex space-x-1 items-center border-slate-100 text-sm text-slate-900 `}
                            >
                                <div className="flex items-center gap-1">
                                    {order?.suggestedVendors?.slice(0, 8)?.map((vendor, idx) => (
                                        <VendorAvatar
                                            key={idx + vendor?.profile?.avatar}
                                            size={24}
                                            imageSrc={vendor?.profile?.avatar}
                                            name={vendor?.profile?.businessName}
                                        />
                                    ))}
                                </div>
                                {order?.suggestedVendors?.slice(8)?.length > 0 && (
                                    <div className="h-[32px] flex items-center justify-center w-[32px] border border-white bg-n-20">
                                        <p className="font-rocGroteskMedium text-gm-50 text-[10px] rounded-[4px]">
                                            +{order?.suggestedVendors?.slice(8)?.length}
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className={`py-[18px] flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-900 `}
                    >
                        <Dropdown
                            dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={
                                "shadow-cardShadow-2 left-[-130px] border-0 rounded !max-h-fit"
                            }
                            dropdownItemsClasses={``}
                            name={"faq-dropdown"}
                            dropdownOptions={[
                                {
                                    label: "Request for quote",
                                    value: "request for quote",
                                    action: () => {
                                        setSelectedOrder(order);
                                        setRfqModalOpen(true);
                                    },
                                },
                            ]}
                        />
                    </div>
                ),
            },
        ];
    });

    const mobileTableBody = orders?.map((order, idx) => {
        return {
            cellClickAction: () => {
                navigate(`/dashboard/order/${order?._id}`);
            },
            topString: "Ord" + order?.taId,
            topContent: <p className="text-sm font-rocGroteskMedium mb-1.5">Ord {order?.taId}:</p>,
            bottomContent: (
                <div className="flex items-center gap-3">
                    <div className="flex items-center gap-3 text-sm font-rocGroteskMedium">
                        <img
                            src={
                                order?.channel === "microsoft"
                                    ? "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700839953/Tradeally_2.0/icons/microsoft_logo_cwqmmo.svg"
                                    : order?.channel === "gmail"
                                    ? "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700554454/Tradeally_2.0/icons/google_wtrcmu.svg"
                                    : "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700554454/Tradeally_2.0/icons/whatsapp_vjsjhe.svg"
                            }
                            alt="logo"
                            className="w-4 h-4"
                        />
                        <span className="capitalize">
                            {order?.channel === "microsoft" ? "Outlook" : order?.channel}
                        </span>
                    </div>
                    <p className="flex items-center gap-2 text-sm font-rocGroteskMedium">
                        <VendorAvatar
                            name={order?.customerDetails?.companyName}
                            imageSrc={order?.customerDetails?.avatar}
                            size={24}
                        />
                        <span>{truncate(order?.customerDetails?.companyName, { length: 15 })}</span>
                    </p>
                </div>
            ),
            rightIcon: <i className="ri-arrow-right-s-line text-2xl"></i>,
        };
    });

    const handleDebouncedChange = debounce((e) => {
        const { name, value } = e.target;

        setDebouncedFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, 1500);

    const handleSearch = (e) => {
        const { name, value } = e.target;

        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const onOrderTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                orderPagination?.current as number,
                orderPagination?.number_of_pages as number,
                () => {
                    setOrderLimit(() => orderLimit + limit);
                    setLoadMoreOrders(true);
                }
            ),
        [dashboardContent, orderPagination]
    );

    useEffect(() => {
        dispatch(orderActions.getOrders(orderLimit, debouncedFilter.search));
        setTimeout(() => {
            setLoadMoreOrders(false);
        }, 800);
    }, [dispatch, orderLimit, debouncedFilter]);

    useEffect(() => {
        if (Boolean(fetchedOrdersSuccess)) {
            setOrders(fetchedOrdersSuccess?.orders);
            setOrderTotal(fetchedOrdersSuccess?.total);
            setOrderPagination({
                current: fetchedOrdersSuccess?.pagination?.current,
                number_of_pages: fetchedOrdersSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedOrdersSuccess]);

    useEffect(() => {
        if (Boolean(sendRfqsSuccess)) {
            setRfqModalOpen(false);
            dispatch(orderActions.resetSendRfqsSuccess());
        }
    }, [dispatch, sendRfqsSuccess]);

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onOrderTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onOrderTableScroll);
        };
    }, [dashboardContent, onOrderTableScroll]);

    return (
        <PageFrame
            containerClassName={"!h-[calc(100vh-108px)]"}
            isLoading={fetchingOrders && orderLimit === limit && !debouncedFilter.search}
        >
            <div>
                <div className="px-[14px] overflow-auto max-lg:px-0">
                    <div className="flex items-center justify-between mb-5 max-lg:flex-col max-lg:gap-2.5">
                        <BreadCrumb
                            parentCrumb={"Orders"}
                            parentUrl={"/dashboard/rfqs"}
                            size="large"
                        />
                        <div className="flex items-center gap-3 max-lg:w-full">
                            <TextInput
                                name={"search"}
                                value={filter.search}
                                type={"text"}
                                inputPlaceholder={"Search by order id (e.g 00014)"}
                                inputClassName={"!h-[40px] pl-[0px] !mb-0 text-sm "}
                                onChange={handleSearch}
                                onInput={handleDebouncedChange}
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-[4px] !w-[428px] max-lg:!w-full !h-[40px] !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                                }
                            />

                            {/* <Dropdown
                                        value={""}
                                        dropdown={
                                            <div className="flex items-center cursor-pointer gap-2 py-1.5 px-3 bg-white rounded shadow-boxShadow-2 border border-n-20">
                                                <i className="ri-filter-3-fill"></i>
                                                <p className="text-[13px] font-rocGroteskMedium">
                                                    Filter
                                                </p>
                                            </div>
                                        }
                                        dropdownContainerClasses={
                                            " left-[-234px] !py-0 top-[49px] shadow-dropdownShadow rounded !border-0"
                                        }
                                        dropdownClassName={"!max-h-fit"}
                                        name={"quickAction"}
                                        dropdownOptions={[
                                            {
                                                customChild: (
                                                    <div className="w-[318px] relative  bg-white p-4 shadow-boxShadow-2 rounded border border-n-20">
                                                        <div className="flex relative flex-col h-fit gap-2">
                                                            <TextInput
                                                                name={"orderId"}
                                                                type={"text"}
                                                                value={filter.orderId}
                                                                placeholder={"Order ID"}
                                                                onChange={handleSearch}
                                                                onInput={handleDebouncedChange}
                                                                required={false}
                                                            />

                                                            <TextInput
                                                                name={"amount"}
                                                                type={"number"}
                                                                onWheel={(e) => e.target.blur()}
                                                                value={filter.amount}
                                                                placeholder={"Amount"}
                                                                onKeyDown={(evt) =>
                                                                    [
                                                                        "e",
                                                                        "E",
                                                                        "+",
                                                                        "-",
                                                                        "ArrowUp",
                                                                        "ArrowDown",
                                                                    ].includes(evt.key) &&
                                                                    evt.preventDefault()
                                                                }
                                                                onChange={handleSearch}
                                                                onInput={handleDebouncedChange}
                                                                required={false}
                                                            />
                                                            <TextInput
                                                                name={"vendor"}
                                                                type={"text"}
                                                                value={filter.vendor}
                                                                placeholder={"Vendor"}
                                                                onChange={handleSearch}
                                                                onInput={handleDebouncedChange}
                                                                required={false}
                                                            />
                                                        </div>
                                                    </div>
                                                ),
                                            },
                                        ]}
                                    /> */}
                        </div>
                    </div>
                    <div>
                        {/* {orders?.length > 0 && (
                            <>
                                <div className="flex items-center justify-between mb-3 max-lg:items-start max-lg:flex-col max-lg:gap-2">
                                    <p className="text-base font-rocGroteskMedium ">
                                        Co-Pilot detected new orders that need RFQs: Here are the
                                        details we've gathered:
                                    </p>
                                    <p
                                        onClick={() => setSeeAllModal(true)}
                                        className="text-sm font-rocGroteskMedium underline cursor-pointer text-g-50 max-lg:flex max-lg:w-full max-lg:justify-end"
                                    >
                                        See all
                                    </p>
                                </div>

                                <div className="grid grid-cols-4 gap-3 mb-8 max-lg:grid-cols-1 max-lg:gap-0 max-lg:bg-white max-lg:rounded max-lg:px-4 max-lg:py-2.5 max-lg:border max-lg:border-slate-100">
                                    {orders?.slice(0, 4)?.map((order, idx) => (
                                        <OrderSummaryCardOne key={idx} index={idx} order={order} />
                                    ))}
                                </div>
                            </>
                        )} */}

                        {orders?.length > 0 ? (
                            <div>
                                <div className="max-lg:hidden">
                                    <CustomTable
                                        tableBody={tableBody}
                                        tableHeader={tableHeader}
                                        isAllSelectable={false}
                                        isScrollable={false}
                                        isCellBordered={false}
                                        headerContainerClass={"!bg-slate-50"}
                                    />
                                </div>
                                <div className="hidden max-lg:block">
                                    <CustomMobileTable data={mobileTableBody} />
                                </div>
                                {loadMoreOrders && fetchingOrders && (
                                    <div className="flex my-4 justify-center">
                                        <Loader color="gm-25" size={4} />
                                    </div>
                                )}
                                {orderPagination?.current === orderPagination?.number_of_pages && (
                                    <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                        End of list
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                                <div className="space-y-2  text-center">
                                    <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                                    <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                        No orders yet
                                    </p>
                                    <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                        Your list of orders will be displayed here
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <ModalContainer
                open={rfqModalOpen}
                showCloseIcon={false}
                tailwindClassName="w-[38%] max-lg:w-[95%]"
                closeModal={() => {
                    setRfqModalOpen(false);
                }}
            >
                <div className="bg-white shadow-cardShadow relative rounded-[6px]">
                    <div className="px-8 py-6 flex items-center justify-between border-b border-slate-100">
                        <div className=" w-full">
                            <p className="text-base font-rocGroteskMedium text-slate-700">
                                Request for quote
                            </p>
                        </div>
                        <i
                            className="ri-close-fill text-xl cursor-pointer"
                            onClick={() => {
                                setRfqModalOpen(false);
                            }}
                        ></i>
                    </div>
                    <div className="text-sm px-8 py-4 font-rocGroteskMedium">
                        Are you sure you want to request for quote for Ord {selectedOrder?.taId}{" "}
                        from all suggested vendors
                    </div>
                    <div className="flex w-full justify-center gap-3 py-4 px-6 border-t border-slate-100">
                        <Button
                            btnClassname="!bg-n-20 !text-gm-50 "
                            btnText={"No, Cancel"}
                            onClick={() => setRfqModalOpen(false)}
                            disabled={sendingRfqs}
                            type={"button"}
                        />
                        <Button
                            btnText={"Yes, proceed"}
                            isLoading={sendingRfqs}
                            disabled={sendingRfqs}
                            onClick={() => handleSendRfqs(selectedOrder)}
                            type={"button"}
                            btnClassname=""
                        />
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default Orders;
