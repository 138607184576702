import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const inventoryService = {
    addSingleProduct,
    getSingleInventory,
    getStockList,
    addProductToStockCount,
    fetchWarehouseList,
    createWarehouse,
    updateWarehouse,
    fetchSearchInventoryListing,
    fetchSearchProductListing,
    deleteWarehouse,
    fetchSingleWarehouseList,
    fetchListStockWarehouse,
    addMultipleProducts,
    getWarehouses,
    getInventoryList,
    getTransferStocks,
    transferStocks,
    transferStockFromWarehouse,
    fetchSingleInventorySuppliers,
    fetchInventoryHistory,
    fetchSingleInventoryPO,
    fetchInventoryWarehouse,
    fetchInventoryOrders,
    updateSingleProduct,
    fetchSingleStockWarehouse,
    fetchSingleStock,
    editStock,
    fetchSingleTransferStock,
    fetchSingleStockAdjustment,
    fetchStockAdjustment,
    addNewStockAdjustment,
    addInventorySettings,
    updateInventorySettings,
    fetchInventorySettings,
    addProductVendor,
    addMultipleProductToStockCount,
    addSuppliedStock,
    updateSuppliedStock,
    fetchSingleSuppliedStock,
    fetchSuppliedStockList,
    fetchSuppliedStockListAllData,
};

async function getStockList(
    limit?: number,
    search?: string,
    warehouseId?: string,
    category?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit,
        search,
        warehouseId,
        category,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/list/stock?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}
async function fetchSingleStock(id: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/single/stock/${id}`,
        requestOptions
    );
    return res;
}
async function fetchSingleTransferStock(id: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/single/stock/movement/${id}`,
        requestOptions
    );
    return res;
}
async function editStock(id: string, data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/product/stock/${id}`,
        requestOptions
    );
    return res;
}
async function getSingleInventory(id: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/inventory/${id}/details`,
        requestOptions
    );
    return res;
}

async function addProductToStockCount(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/product/stock-count`,
        requestOptions
    );
    return res;
}

async function addMultipleProductToStockCount(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/product/stock-count/multiple`,
        requestOptions
    );
    return res;
}

async function transferStocks(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/stock/transfer`,
        requestOptions
    );
    return res;
}

async function fetchWarehouseList(limit: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit: limit as number,
        search: search as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/warehouses?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function transferStockFromWarehouse(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/stock/transfer`,
        requestOptions
    );
    return res;
}

async function createWarehouse(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/warehouse`,
        requestOptions
    );
    return res;
}

async function addSingleProduct(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/product`,
        requestOptions
    );
    return res;
}
async function addProductVendor(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/vendor`,
        requestOptions
    );
    return res;
}
async function updateWarehouse(id: string, data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/warehouse/${id}`,
        requestOptions
    );
    return res;
}
async function addMultipleProducts(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/product/multiple`,
        requestOptions
    );
    return res;
}
async function deleteWarehouse(id: string) {
    const requestOptions = {
        method: "DELETE",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/warehouse/${id}`,
        requestOptions
    );
    return res;
}

async function getWarehouses(limit?: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        limit,
        search,
    });
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/warehouses?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getInventoryList(limit?: number, search?: string, category?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        category,
        limit,
        search,
    });
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/listing?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchSingleWarehouseList(id: string, limit: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit: limit as number,
        search: search as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/warehouse/${id}?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchSearchInventoryListing(
    limit: number,
    search?: string,
    location?: string,
    category?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/listing?limit=${limit}${
            search ? "&search=" + search : ""
        }${location ? "&location=" + location : ""}${category ? "&category=" + category : ""}`,
        requestOptions
    );
    return res;
}

async function getTransferStocks(
    limit?: number,
    search?: string,
    warehouseId?: string,
    category?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit,
        search,
        warehouseId,
        category,
    });
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/list/transfer-stock?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}
async function fetchSearchProductListing(
    limit: number,
    search?: string,
    data?: Record<string, any> | Array<Record<string, any>>
) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const urlParams = createUrlSearchParams({
        limit: limit as number,
        search: search as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/listing-by-products?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchListStockWarehouse(
    id: string,
    inventoryId?: string,
    limit?: number,
    search?: string,
    category?: string,
    isExpired?: boolean
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        inventoryId,
        limit: limit as number,
        search: search as string,
        category: category as string,
        isExpired: isExpired as boolean,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/stock/warehouse/${id}?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchSingleStockWarehouse(id: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/single/stock/${id}`,
        requestOptions
    );
    return res;
}

async function fetchSingleInventorySuppliers(id: string, limit: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit: limit as number,
        search: search as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/inventory/${id}/suppliers?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchInventoryHistory(id: string, limit: number, filter?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit: limit as number,
        filter: filter as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/inventory/${id}/history?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchSingleInventoryPO(id: string, limit: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit: limit as number,
        search: search as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/inventory/${id}/purchaseOrders?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchInventoryWarehouse(id: string, limit: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit: limit as number,
        search: search as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/inventory/${id}/warehouse?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}
async function fetchInventoryOrders(id: string, limit: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit: limit as number,
        search: search as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/inventory/${id}/orders?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchStockAdjustment(
    limit: number,
    search?: string,
    warehouseId?: string,
    category?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit: limit,
        search: search,
        warehouseId: warehouseId,
        category: category,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/list/adjustment-stock?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchSingleStockAdjustment(id: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/single/stock-adjustment/movement/${id}`,
        requestOptions
    );
    return res;
}

async function addNewStockAdjustment(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/product/stock/multiple`,
        requestOptions
    );
    return res;
}

async function updateSingleProduct(
    data: Record<string, any> | Array<Record<string, any>>,
    productId: string
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/update/inventory/${productId}`,
        requestOptions
    );
    return res;
}

async function addInventorySettings(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/stock/settings`,
        requestOptions
    );
    return res;
}

async function updateInventorySettings(
    data: Record<string, any> | Array<Record<string, any>>,
    settingsId: string
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/stockSettings/${settingsId}`,
        requestOptions
    );
    return res;
}

async function fetchInventorySettings(settingsId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/single/stockSetting/${settingsId}`,
        requestOptions
    );
    return res;
}

async function addSuppliedStock(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/multiple/stock-record`,
        requestOptions
    );
    return res;
}

async function updateSuppliedStock(
    id: string,
    data: Record<string, any> | Array<Record<string, any>>
    
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/update/stock-record/${id}`,
        requestOptions
    );
    return res;
}

async function fetchSuppliedStockList(limit, search?:string, warehouseId?:string, category?:string) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        limit: limit,
        search: search,
        warehouseId: warehouseId,
        category: category,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/list/supply-stock?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchSuppliedStockListAllData(
    limit,
    search?: string,
    warehouseId?: string,
    category?: string,
    page?: number
) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        limit: limit,
        search: search,
        warehouseId: warehouseId,
        category: category,
        page

    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/supply-stock/full-list?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchSingleSuppliedStock(id: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/single/supply-stock/${id}`,
        requestOptions
    );
    return res;
}