import React from "react";
import Dropdown from "components/common/Dropdown";
import { getEditPermissionList } from "helpers/getEditPermissionList";
import { truncate } from "lodash";

type Props = {
    name: string;
    address: string;
    currentStockLevel: number;
    minimumStockLevel: number;
    maximumStockLevel: number;
    onClick: (e) => void;
};
const WarehouseCard = ({
    onClick,
    name,
    address,
    currentStockLevel,
    maximumStockLevel,
    minimumStockLevel,
}: Props) => {
    return (
        <div
            className=" cursor-pointer"
            onClick={(e) => {
                onClick(e);
            }}
        >
            <div className="border w-full  border-slate-100 rounded py-4 shadow-wareHouseCardShadow">
                <div className="flex justify-between px-4">
                    <div className="w-9 h-9 flex justify-center items-center bg-slate-100 rounded">
                        <i className="ri-community-line text-slate-500 text-base"></i>
                    </div>
                    <div onClick={(e) => e.stopPropagation()}>
                        <Dropdown
                            dropdown={
                                <i className="ri-more-2-fill before:content-['\ef76'] text-gm-50"></i>
                            }
                            dropdownClassName={"!w-full  mt-4 !mb-10 "}
                            dropdownContainerClasses={`shadow-cardShadow-2 left-[-110px]  border-0 rounded !max-h-fit`}
                            dropdownItemsClasses={``}
                            name={"faq-dropdown"}
                            dropdownOptions={[
                                {
                                    label: "Transfer stock",
                                    value: "settings",
                                    action: () => {},
                                    permissions: getEditPermissionList("inventory"),
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="pb-4 border-b border-b-slate-100">
                    <div className="text-sm font-rocGroteskMedium mt-4 px-4">
                        <p className="text-gm-50  ">{name}</p>
                        <p className="text-slate-500">{truncate(address, {length:30})}</p>
                    </div>
                </div>
                <div className="px-4">
                    <div className="text-xs py-4 border-b border-b-slate-100 font-rocGroteskMedium text-[#16A34A] flex justify-between">
                        <p>Current Stock Level</p>
                        <p>{currentStockLevel}</p>
                    </div>
                    <div className="text-xs py-4 border-b border-b-slate-100 font-rocGroteskMedium text-slate-500 flex justify-between">
                        <p>Minimum Stock Level</p>
                        <p>{minimumStockLevel}</p>
                    </div>
                    <div className="text-xs py-4 border-b border-b-slate-100 font-rocGroteskMedium text-slate-500 flex justify-between">
                        <p>Maximum Stock Level</p>
                        <p>{maximumStockLevel}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default WarehouseCard;
