import Button from "components/common/Button";
import React, { useEffect, useRef, useState } from "react";
import TextInput from "../../../../components/common/InputField/TextInput";
import { useNavigate, useParams } from "react-router-dom";
import ModalContainer from "components/common/ModalContainer";
import { authActions, inventoryActions, vendorActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import SelectInput from "components/common/InputField/SelectInput";
import AddressInput from "components/common/InputField/AddressInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import { isFileSizeLessThanOrEqualTo } from "helpers/compareFileSizeInMb";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import { firebaseService } from "services/firebaseService";
import useGetUserProfile from "hooks/useGetUserProfile";
import Loader from "components/common/Loader";
import { debounce, truncate } from "lodash";
import VendorAvatar from "components/common/VendorAvatar";
import MultiselectInput from "components/common/InputField/MultiselectInput";
import PageFrame from "components/layout/PageFrame";

export type ProductDataTypeTwo = {
    productName: string;
    inventoryCategory: string;
    vendors: string[];
    unitOfMeasurement: string;
    selectAttribute: string;
    attributeValue: string;
    description: string;
    warehouse?: string;
    productStockSettings: any;
    minimumStockLevel?: number;
    maximumStockLevel?: number;
    bufferStockLevel?: number;
    isProductPerishable: boolean;
    productImages?: string[];
};
const EditProduct = () => {
    const { id } = useParams();
    const [profile] = useGetUserProfile();
    const [deleteImageModal, setDeleteImageModal] = useState<boolean>(false);
    const [addWarehouseModal, setAddWarehouseModal] = useState<boolean>(false);
    const [addMoreWarehouseModal, setAddMoreWarehouseModal] = useState<boolean>(false);
    const [uploadingFiles, setUploadingFiles] = useState<boolean>(false);
    const [deletingImage, setDeletingImage] = useState<boolean>(false);
    const [address, setAddress] = useState<{ [key: string]: any }>({});
    const [teamList, setTeamList] = useState<{ [key: string]: any }[]>([]);
    const [productImages, setProductImages] = useState<string[]>([]);
    const [productImage, setProductImage] = useState<string>("");
    const [productId, setProductId] = useState<number>(0);
    const [selectedWarehouse, setSelectedWarehouse] = useState({
        id: "",
        name: "",
        address: "",
    });
    const [addWarehouseData, setAddWarehouseData] = useState<{
        contactPerson: string;
        note: string;
        name: string;
    }>({
        contactPerson: "",
        note: "",
        name: "",
    });
    const [productDataTwo, setProductDataTwo] = useState({
        productStockSettings: [
            {
                bufferStock: "0",
                minimumStockLevel: "0",
                maximumStockLevel: "0",
                warehouseId: "",
                warehouse: {
                    address: {
                        address: "",
                    },
                    warehouseName: "",
                },
            },
        ],
    });
    const [showWarehouseDropdown, setShowWarehouseDropdown] = useState(false);
    const [selectedDropdownIndex, setSelectedDropdownIndex] = useState(null);
    const [addWarehouseDetails, setAddWarehouseDetails] = useState({
        warehouse: "",
        minStockLevel: "",
        maxStockLevel: "",
        bufferStock: "",
    });

    const [productData, setProductData] = useState<ProductDataTypeTwo>({
        productName: "",
        vendors: [],
        inventoryCategory: "",
        unitOfMeasurement: "",
        selectAttribute: "",
        attributeValue: "",
        description: "",
        warehouse: "",
        productStockSettings: [],
        minimumStockLevel: 0,
        maximumStockLevel: 0,
        bufferStockLevel: 0,
        isProductPerishable: false,
    });
    const [debouncedWarehouseSearch, setDebouncedWarehouseSearch] = useState<string>("");
    const [warehouses, setWarehouses] = useState<{ [key: string]: any }[]>([]);
    const [singleInventory, setSingleInventory] = useState<any>([]);
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [onboardedVendors, setOnboardedVendors] = useState<{ [key: string]: any }[]>([]);
    const handleWarehouseDebouncedChange = debounce((e) => {
        setDebouncedWarehouseSearch(e.target.value);
    }, 1500);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        addSingleProductSuccess,
        createWarehouse,
        createWarehouseSuccess,
        addMultipleProductsSuccess,
        fetchingWarehouseList,
        fetchedWarehouseListSuccess,
        fetchingSingleInventory,
        fetchedSingleInventorySuccess,
        updatedSingleProductSuccess,
        updatingSingleProduct,
    } = useAppSelector((state) => state.inventory);
    const { fetchedLdbVendorsSuccess, fetchingLdbVendors } = useAppSelector(
        (state) => state.vendor
    );
    const { fetchingTeamMember, fetchedTeamMemberSuccess } = useAppSelector((state) => state.auth);
    const handleWarehouseChange = (name: string, value: string) => {
        const selectedWarehouseDetails = warehouses.find((warehouse) => warehouse._id === value);

        setSelectedWarehouse({
            id: value,
            name: selectedWarehouseDetails?.warehouseName || "",
            address: selectedWarehouseDetails?.address?.address || "",
        });

        handleChange(name, value);
    };
    useEffect(() => {
        dispatch(inventoryActions.fetchSingleInventory(id));
    }, [id]);

    useEffect(() => {
        dispatch(vendorActions.getLdbVendors(20, debouncedSearch, "accepted"));
    }, [dispatch, debouncedSearch]);

    useEffect(() => {
        if (Boolean(fetchedLdbVendorsSuccess)) {
            setOnboardedVendors(fetchedLdbVendorsSuccess?.vendors);
        }
    }, [fetchedLdbVendorsSuccess]);

    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e.target.value);
    }, 1500);

    useEffect(() => {
        if (Boolean(fetchedSingleInventorySuccess)) {
            setSingleInventory(fetchedSingleInventorySuccess?.inventory);
            setProductImages(
                fetchedSingleInventorySuccess?.inventory?.productImageDetails?.productImages
            );
        }
    }, [fetchedSingleInventorySuccess]);
    useEffect(() => {
        setProductDataTwo({
            productStockSettings:
                singleInventory?.productStockSettings?.map((stockSetting) => ({
                    bufferStock: Number(stockSetting.bufferStock) || 0,
                    minimumStockLevel: Number(stockSetting.minimumStockLevel) || 0,
                    maximumStockLevel: Number(stockSetting.maximumStockLevel) || 0,
                    warehouseId: stockSetting.warehouse?._id || "",
                    warehouse: {
                        address: {
                            address: stockSetting.warehouse?.address?.address || "",
                        },
                        warehouseName: stockSetting.warehouse?.warehouseName || "",
                    },
                })) || [],
        });
    }, [singleInventory?.productStockSettings]);

    const handleStockSettingChange = (index, fieldName, value) => {
        setProductDataTwo((prevData) => {
            const updatedSettings = [...prevData.productStockSettings];
            updatedSettings[index] = {
                ...updatedSettings[index],
                [fieldName]: value,
            };
            return {
                ...prevData,
                productStockSettings: updatedSettings,
            };
        });
    };

    const handleDeleteStockSetting = (index) => {
        setProductDataTwo((prevData) => {
            const updatedSettings = [...prevData.productStockSettings];
            updatedSettings.splice(index, 1);
            return {
                ...prevData,
                productStockSettings: updatedSettings,
            };
        });
    };

    const color = Object.entries?.(singleInventory?.customFields || {});
    useEffect(() => {
        setProductData({
            productName: singleInventory?.productName,
            inventoryCategory: singleInventory?.inventoryCategory,
            unitOfMeasurement: singleInventory?.productStockDetails?.unitOfMeasurement,
            vendors: singleInventory?.vendors,
            selectAttribute: (color?.[0]?.[0] as string) || "",
            attributeValue: (color?.[0]?.[1] as string) || "",
            description: singleInventory?.productDescription,
            productStockSettings: singleInventory?.productStockSettings,
            isProductPerishable: singleInventory?.isProductPerishable,
        });
    }, [singleInventory]);

    useEffect(() => {
        setProductImage(productImages?.[productId]);
    }, [productImages, productId]);

    const handleChange = (name: string, value: any) => {
        setProductData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const handleSaveProduct = () => {
        const body: { [key: string]: any } = {
            productName: productData.productName,
            inventoryCategory: productData.inventoryCategory,
            productStockDetails: {
                unitOfMeasurement: productData.unitOfMeasurement,
            },
            productStockSettings: productDataTwo?.productStockSettings?.map((item) => {
                return {
                    bufferStock: Number(item.bufferStock),
                    minimumStockLevel: Number(item.minimumStockLevel),
                    maximumStockLevel: Number(item.maximumStockLevel),
                    warehouseId: item.warehouseId,
                };
            }),
            isProductPerishable: productData.isProductPerishable,
            productDescription: productData.description,
        };

        if (productImages?.length > 0) {
            body.productImageDetails = {
                productImages: productImages?.slice(0, 5)?.map((img) => img),
                productAvatar: productImages[0],
            };
        }

        if (productData.selectAttribute && productData.attributeValue) {
            body.customFields = {
                [productData.selectAttribute]: productData.attributeValue,
            };
        }
        dispatch(inventoryActions.updateSingleProduct(body, id));
    };

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouseList(20, debouncedWarehouseSearch));
    }, [dispatch, debouncedWarehouseSearch, createWarehouseSuccess]);

    useEffect(() => {
        if (Boolean(fetchedWarehouseListSuccess)) {
            setWarehouses(fetchedWarehouseListSuccess?.warehouses?.data);
        }
    }, [fetchedWarehouseListSuccess]);

    const handleUpload = (files: File[]) => {
        if (files.length > 0) {
            const filePaths = files?.map((file) => {
                return `synthally/${process.env.REACT_APP_STAGE ?? "staging"}/inventory/${
                    profile?.profile?.businessName
                }/${file?.name}`;
            });
            setUploadingFiles(true);
            firebaseService.uploadMultipleFiles(files, filePaths, (urlArr) => {
                setProductImages((prev) => [...prev, ...urlArr]);
                setUploadingFiles(false);
                setProductId(productImages?.length);
            });
        }
    };

    useEffect(() => {
        if (!uploadingFiles) {
            setProductImage(productImages?.[productId]);
        }
    }, [productImages, productId]);

    const handleRemoveImage = () => {
        const imagePath = decodeURIComponent(
            productImages[productId]?.split("/o/")?.[1]?.split("?alt")?.[0]
        );
        setDeletingImage(true);
        firebaseService.removeFile(imagePath, () => {
            const remainingImages = productImages?.filter((img) => img !== productImage);

            setProductImage(remainingImages[productId - 1] || "");
            const body = {
                productImageDetails: {
                    productImages: remainingImages,
                    productAvatar: remainingImages?.[0],
                },
            };
            setProductImages(remainingImages || []);
            if (productId === 0) {
                setProductId(0);
            } else {
                setProductId(productId - 1);
            }
            setDeletingImage(false);
            setDeleteImageModal(false);
        });
    };

    const handleInputUploadAction = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = Object.values(e.target.files)?.slice(0, 5 - productImages.length);
        const filesToUpload = [];
        const namesOfFilesNotToUpload = [];

        for (const file of files) {
            const isUploadable = isFileSizeLessThanOrEqualTo(file, 5);

            if (isUploadable) {
                filesToUpload.push(file);
            } else {
                namesOfFilesNotToUpload.push(file?.name);
            }
        }
        console.log(files);
        if (namesOfFilesNotToUpload.length > 0) {
            toast.custom((t) => (
                <CustomToast
                    t={t}
                    message={`The following file(s) are too large: ${namesOfFilesNotToUpload.join(
                        ", "
                    )}`}
                    type="error"
                />
            ));
        } else {
            handleUpload(filesToUpload);
        }
    };

    const handleAddNewWarehouse = () => {
        const body = {
            address: address?.location,
            contactPersonId: addWarehouseData.contactPerson,
            warehouseName: addWarehouseData.name,
            additionalNotes: addWarehouseData.note,
        };

        dispatch(inventoryActions.createWarehouse(body));
    };

    useEffect(() => {
        if (addSingleProductSuccess) {
            navigate("/dashboard/inventory");
            dispatch(inventoryActions.resetAddSingleProductSuccess());
        }
    }, [dispatch, addSingleProductSuccess]);

    useEffect(() => {
        if (addMultipleProductsSuccess) {
            navigate("/dashboard/inventory");
            dispatch(inventoryActions.resetAddMultipleProductsSuccess());
        }
    }, [dispatch, addMultipleProductsSuccess]);

    useEffect(() => {
        dispatch(authActions.getTeamMembers(100));
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamList(fetchedTeamMemberSuccess?.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        if (createWarehouseSuccess) {
            setAddWarehouseModal(false);
            setAddWarehouseData({
                contactPerson: "",
                note: "",
                name: "",
            });
            dispatch(inventoryActions.resetCreateWarehouseSuccess());
        }
    }, [createWarehouseSuccess]);
    const handleAddwarehouseChange = (name: string, value: any) => {
        setAddWarehouseDetails((prev) => ({ ...prev, [name]: value }));
    };

    const handleAddMorewWarehouse = () => {
        const isWarehouseExists = productDataTwo?.productStockSettings.some(
            (data) => data.warehouseId === selectedWarehouse.id
        );

        if (isWarehouseExists) {
            toast.custom((t) => (
                <CustomToast
                    t={t}
                    message={`Warehouse already exists in stock settings`}
                    type="error"
                />
            ));
            return;
        }

        const newStockSetting = {
            warehouse: {
                warehouseName: selectedWarehouse.name,
                address: {
                    address: selectedWarehouse.address,
                },
            },
            warehouseId: selectedWarehouse.id,
            minimumStockLevel: addWarehouseDetails.minStockLevel,
            maximumStockLevel: addWarehouseDetails.maxStockLevel,
            bufferStock: addWarehouseDetails.bufferStock,
        };

        setProductDataTwo((prev) => ({
            ...prev,
            productStockSettings: [...prev.productStockSettings, newStockSetting],
        }));

        setSelectedWarehouse({
            id: "",
            name: "",
            address: "",
        });
        setAddWarehouseDetails({
            minStockLevel: "",
            maxStockLevel: "",
            bufferStock: "",
            warehouse: "",
        });
        setAddMoreWarehouseModal(false);
    };

    useEffect(() => {
        if (Boolean(updatedSingleProductSuccess)) {
            navigate("/dashboard/inventory");
        }
    }, [updatedSingleProductSuccess]);

    useEffect(() => {
        if (addWarehouseModal) {
            setAddMoreWarehouseModal(false);
        }
    }, [addWarehouseModal]);

    return (
        <PageFrame isLoading={fetchingSingleInventory}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSaveProduct();
                }}
                className="bg-white rounded-lg border border-slate-100"
            >
                <div className="flex  top-[-2px] md:sticky z-[40]  bg-white items-center justify-between px-8 py-5 border-b border-slate-100">
                    <p className="text-base font-rocGroteskMedium">Edit product</p>
                    <div className="flex items-center gap-3">
                        <Button
                            btnText="Cancel"
                            btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                            disabled={updatingSingleProduct}
                            onClick={() => navigate("/dashboard/inventory")}
                        />
                        <Button
                            btnText="Save"
                            type="submit"
                            isLoading={updatingSingleProduct}
                            disabled={updatingSingleProduct}
                            btnClassname="!px-4 !py-2.5 !w-fit"
                        />
                    </div>
                </div>
                <div className="px-8 py-6 flex gap-[7.7%]">
                    <div className="w-full">
                        <div className="mb-6">
                            <div className="flex items-center w-full justify-between mb-4">
                                <p className="text-sm font-rocGroteskMedium">
                                    Product name <span className="text-r-50">*</span>
                                </p>
                            </div>
                            <div className="border-b-[1px] border-b-[#E2E8F0] pb-5">
                                <input
                                    type="text"
                                    placeholder="Enter a product name"
                                    className="!text-2xl font-rocGroteskMedium  placeholder:text-[#CBD5E1] !border-none outline-none w-full placeholder:pl-1 caret-slate-700 !leading-6"
                                    // ref={inputRef}
                                    name="productName"
                                    value={productData.productName || ""}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-6">
                            <div className="grid grid-cols-2 items-center gap-4">
                                <SelectInput
                                    value={productData?.inventoryCategory?.replace(/\s/g, "")}
                                    name="inventoryCategory"
                                    placeholder={"Inventory categories"}
                                    handleChange={(name, value) => {
                                        // handleChange(name, value);
                                        if (value === "RawMaterials") {
                                            handleChange(name, "Raw Material");
                                        } else if (value === "Work-In-Progress(WIP)") {
                                            handleChange(name, "Work-in-Progress (WIP)");
                                        } else if (value === "FinishedGoods") {
                                            handleChange(name, "Finished Goods");
                                        } else if (value === "Maintenance,RepairandOverall(MRO)") {
                                            handleChange(
                                                name,
                                                "Maintenance, Repair and Overall (MRO)"
                                            );
                                        }
                                    }}
                                    isRequired={true}
                                    dropdownOptions={[
                                        {
                                            label: "Raw Material",
                                            value: "RawMaterial",
                                        },
                                        {
                                            label: "Work-in-Progress (WIP)",
                                            value: "Work-In-Progress(WIP)",
                                        },
                                        {
                                            label: "Finished Goods",
                                            value: "FinishedGoods",
                                        },
                                        {
                                            label: "Maintenance, Repair and Overall (MRO)",
                                            value: "Maintenance,RepairandOverall(MRO)",
                                        },
                                    ]}
                                />

                                <MultiselectInput
                                    value={productData?.vendors}
                                    name="vendors"
                                    placeholder={"Vendor"}
                                    handleChange={(name, value, selectedItems) => {
                                        handleChange(name, selectedItems);
                                    }}
                                    searchLoading={fetchingLdbVendors}
                                    handleInputChange={(e) => handleDebouncedChange(e)}
                                    isRequired={true}
                                    dropdownOptions={onboardedVendors?.map((vendor) => ({
                                        label: vendor?.vendor?.profile?.businessName,
                                        value: vendor.vendorId,
                                        subText: vendor?.category,
                                        icon: (
                                            <VendorAvatar
                                                size={30}
                                                imageSrc={vendor?.vendor?.profile?.avatar}
                                                name={vendor?.vendor?.profile?.businessName}
                                            />
                                        ),
                                    }))}
                                />
                                <SelectInput
                                    value={productData.unitOfMeasurement}
                                    name="unitOfMeasurement"
                                    placeholder={"Unit of measure"}
                                    handleChange={(name, value) => {
                                        handleChange(name, value);
                                    }}
                                    isRequired={true}
                                    dropdownOptions={[
                                        {
                                            label: "Unit",
                                            value: "unit",
                                        },
                                        {
                                            label: "Pcs",
                                            value: "pcs",
                                        },
                                        {
                                            label: "Units",
                                            value: "units",
                                        },
                                        {
                                            label: "Cartridges",
                                            value: "cartridges",
                                        },
                                        {
                                            label: "Rolls",
                                            value: "rolls",
                                        },
                                        {
                                            label: "Box",
                                            value: "box",
                                        },
                                    ]}
                                />

                                <SelectInput
                                    value={productData.selectAttribute}
                                    name="selectAttribute"
                                    placeholder={"Select attribute"}
                                    handleChange={(name, value) => {
                                        handleChange(name, value);
                                    }}
                                    isRequired={false}
                                    dropdownOptions={[
                                        {
                                            label: "Color",
                                            value: "color",
                                        },
                                        {
                                            label: "Size",
                                            value: "size",
                                        },
                                    ]}
                                />
                                <TextInput
                                    value={productData.attributeValue}
                                    name={"attributeValue"}
                                    type={"text"}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    placeholder={"Attribute"}
                                    required={false}
                                    containerClassname=""
                                />
                            </div>

                            <div>
                                <TextareaInput
                                    name={"description"}
                                    value={productData.description}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    rows={5}
                                    placeholder={"Description"}
                                    required={true}
                                    containerClassname=""
                                />
                            </div>

                            <div>
                                <label className="text-base font-rocGroteskMedium">
                                    Warehouse details
                                </label>
                                <div className="mt-4">
                                    {productDataTwo?.productStockSettings?.map((data, index) => (
                                        <div className="relative mb-3" key={index}>
                                            <div className="flex items-center justify-between border rounded border-slate-200 p-4">
                                                <div className="flex items-center space-x-4">
                                                    <div className="w-9 h-9 flex justify-center items-center bg-slate-100 rounded">
                                                        <i className="ri-community-line text-slate-500 text-base"></i>
                                                    </div>
                                                    <div className="text-sm font-rocGroteskMedium">
                                                        <p className=" text-gm-50">
                                                            {data?.warehouse?.warehouseName}
                                                        </p>
                                                        <p className="text-slate-500">
                                                            {data?.warehouse?.address?.address}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex items-center space-x-4">
                                                    <div
                                                        className="cursor-pointer w-fit"
                                                        onClick={() =>
                                                            handleDeleteStockSetting(index)
                                                        }
                                                    >
                                                        <i className="ri-delete-bin-6-line text-gm-50"></i>
                                                    </div>
                                                    <div
                                                        className="cursor-pointer w-fit"
                                                        onClick={() => {
                                                            setShowWarehouseDropdown(
                                                                (prev) => !prev
                                                            );
                                                            setSelectedDropdownIndex(index);
                                                        }}
                                                    >
                                                        <i className="ri-arrow-down-s-line text-gm-50"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            {showWarehouseDropdown &&
                                                selectedDropdownIndex === index && (
                                                    <div className="grid grid-cols-2 gap-4 border p-2">
                                                        <TextInput
                                                            value={data.minimumStockLevel}
                                                            name={"minimumStockLevel"}
                                                            type={"number"}
                                                            onWheel={(e: any) => e.target.blur()}
                                                            onKeyDown={(evt) =>
                                                                [
                                                                    "e",
                                                                    "E",
                                                                    "+",
                                                                    "-",
                                                                    "ArrowUp",
                                                                    "ArrowDown",
                                                                ].includes(evt.key) &&
                                                                evt.preventDefault()
                                                            }
                                                            onChange={(e) =>
                                                                handleStockSettingChange(
                                                                    index,
                                                                    "minimumStockLevel",
                                                                    e.target.value
                                                                )
                                                            }
                                                            placeholder={"Minimum stock level"}
                                                            required={true}
                                                            containerClassname=""
                                                        />
                                                        <TextInput
                                                            value={data.maximumStockLevel}
                                                            name={"maximumStockLevel"}
                                                            type={"number"}
                                                            onWheel={(e: any) => e.target.blur()}
                                                            onKeyDown={(evt) =>
                                                                [
                                                                    "e",
                                                                    "E",
                                                                    "+",
                                                                    "-",
                                                                    "ArrowUp",
                                                                    "ArrowDown",
                                                                ].includes(evt.key) &&
                                                                evt.preventDefault()
                                                            }
                                                            onChange={(e) =>
                                                                handleStockSettingChange(
                                                                    index,
                                                                    "maximumStockLevel",
                                                                    e.target.value
                                                                )
                                                            }
                                                            placeholder={"Maximum stock level"}
                                                            required={true}
                                                            containerClassname=""
                                                        />
                                                        <TextInput
                                                            value={data.bufferStock}
                                                            name={"bufferStockLevel"}
                                                            type={"number"}
                                                            onWheel={(e: any) => e.target.blur()}
                                                            onKeyDown={(evt) =>
                                                                [
                                                                    "e",
                                                                    "E",
                                                                    "+",
                                                                    "-",
                                                                    "ArrowUp",
                                                                    "ArrowDown",
                                                                ].includes(evt.key) &&
                                                                evt.preventDefault()
                                                            }
                                                            onChange={(e) =>
                                                                handleStockSettingChange(
                                                                    index,
                                                                    "bufferStock",
                                                                    e.target.value
                                                                )
                                                            }
                                                            placeholder={"Buffer stock level"}
                                                            required={true}
                                                            containerClassname=""
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                    ))}
                                    <div
                                        onClick={() => setAddMoreWarehouseModal(true)}
                                        className="flex items-center gap-2 mt-6 text-b-50 font-rocGroteskMedium w-fit cursor-pointer"
                                    >
                                        <i className="ri-add-fill text-xl"></i>
                                        <span className="underline text-sm">Add new warehouse</span>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <label className="text-base font-rocGroteskMedium">
                                    Is this a perishable goods?
                                </label>
                                <div className="flex items-center mt-4">
                                    <div
                                        className="mr-5 cursor-pointer flex items-center"
                                        onClick={() => handleChange("isProductPerishable", true)}
                                    >
                                        {productData.isProductPerishable ? (
                                            <i
                                                className={`ri-radio-button-fill text-xl ${
                                                    productData.isProductPerishable && "text-y-10"
                                                }`}
                                            ></i>
                                        ) : (
                                            <span className={`material-icons text-xl text-n-50`}>
                                                radio_button_unchecked
                                            </span>
                                        )}
                                        <span className="text-base font-rocGroteskMedium ml-[5px]">
                                            Yes
                                        </span>
                                    </div>

                                    <div
                                        className="cursor-pointer flex items-center"
                                        onClick={() => handleChange("isProductPerishable", false)}
                                    >
                                        {!productData.isProductPerishable ? (
                                            <i
                                                className={`ri-radio-button-fill text-xl ${
                                                    !productData.isProductPerishable && "text-y-10"
                                                }`}
                                            ></i>
                                        ) : (
                                            <span className={`material-icons text-xl text-n-50`}>
                                                radio_button_unchecked
                                            </span>
                                        )}
                                        <span className="text-base font-rocGroteskMedium ml-[5px] translate-y-[1px]">
                                            No
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[27%] flex justify-end">
                        <div className="w-fit">
                            {productImages?.length > 0 ? (
                                <div className="w-[252px] ">
                                    <div className="relative h-[252px] w-full border rounded border-slate-200 ">
                                        <div
                                            className="left-[220px] absolute justify-end cursor-pointer  mt-2"
                                            onClick={() => {
                                                setDeleteImageModal(true);
                                            }}
                                        >
                                            <img
                                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1705060965/Delete_icon_brttke.svg"
                                                className="w-8 mr-2 object-contain "
                                                alt="delete icon"
                                            />
                                        </div>
                                        <div>
                                            <img
                                                src={productImage}
                                                className="bg-no-repeat h-[252px] object-cover"
                                                alt="product Avatar"
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-3 w-full flex items-center space-x-2">
                                        {productImages.map((image, idx) => (
                                            <div
                                                className="cursor-pointer"
                                                key={image}
                                                onClick={() => {
                                                    setProductImage(image);
                                                    setProductId(idx);
                                                }}
                                            >
                                                <img
                                                    src={image}
                                                    className={`bg-no-repeat h-[44px] w-[44px]  ${
                                                        productImage === image
                                                            ? "border-[1.5px] border-carribean-green"
                                                            : "border-slate-200 border"
                                                    } rounded  bg-contain`}
                                                    alt={`product avatar ${idx + 1}`}
                                                />
                                            </div>
                                        ))}
                                        {productImages?.length < 5 && (
                                            <div className="relative">
                                                <div
                                                    onClick={() => {
                                                        const elem =
                                                            document.getElementById(
                                                                "uploadProductImages"
                                                            );
                                                        elem?.click();
                                                    }}
                                                    className="cursor-pointer h-[44px] w-[44px] rounded border border-slate-200 border-dashed flex items-center justify-center"
                                                >
                                                    <i className="ri-add-fill text-2xl"></i>
                                                </div>

                                                <input
                                                    type="file"
                                                    id="uploadProductImages"
                                                    className="absolute w-full h-full left-0 top-0 opacity-0 cursor-pointer"
                                                    accept="image/png, image/jpeg"
                                                    multiple
                                                    onChange={(e) => {
                                                        handleInputUploadAction(e);
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="w-[252px] h-[252px] relative px-6 py-12 flex items-center justify-center rounded border border-dashed bg-slate-50">
                                    {uploadingFiles ? (
                                        <Loader size={5} />
                                    ) : (
                                        <div className="w-full h-full">
                                            <div className="font-rocGroteskMedium flex flex-col justify-center items-center">
                                                <i className="ri-upload-cloud-2-line text-[40px] text-slate-500"></i>
                                                <p className="underline text-sm mb-2 text-center">
                                                    Add product images
                                                </p>
                                                <p className="text-tradeally-neutral-200 text-sm text-center">
                                                    You can add up to 5 images, each not exceeding 5
                                                    MB.
                                                </p>
                                                <p className="text-tradeally-neutral-200 text-xs mt-8 text-center">
                                                    file format: JPEG, PNG
                                                </p>
                                            </div>
                                            <input
                                                type="file"
                                                id="uploadProductImages"
                                                className="absolute w-full h-full left-0 top-0 opacity-0 cursor-pointer"
                                                accept="image/png, image/jpeg"
                                                multiple
                                                onChange={(e) => {
                                                    handleInputUploadAction(e);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </form>

            <ModalContainer
                open={deleteImageModal}
                showCloseIcon={false}
                tailwindClassName="w-[30%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setDeleteImageModal(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="px-10 pt-3">
                        <div className="flex flex-col items-center text-center">
                            <div className="flex w-full justify-end mr-[-12px]">
                                <i
                                    onClick={() => setDeleteImageModal(false)}
                                    className="ri-close-fill text-2xl cursor-pointe"
                                ></i>
                            </div>

                            <i className="ri-delete-bin-6-line text-[32px]"></i>

                            <p className="text-xl mb-3 text-center mt-6 font-rocGroteskMedium text-g-75">
                                Delete Image?
                            </p>
                            <p className="text-sm text-center font-rocGroteskMedium text-slate-500">
                                Are you sure about deleting this image?
                            </p>
                        </div>

                        <div className="w-full flex items-center gap-2 py-6">
                            <div className="w-full">
                                <Button
                                    btnText={"No, Cancel"}
                                    type={"button"}
                                    btnClassname="!bg-tradeally-neutral-20"
                                    onClick={() => {
                                        setDeleteImageModal(false);
                                    }}
                                />
                            </div>
                            <div className="w-full">
                                <Button
                                    btnText={"Yes, delete"}
                                    type={"button"}
                                    btnClassname="!bg-r-50 !text-white"
                                    isLoading={deletingImage}
                                    onClick={() => {
                                        handleRemoveImage();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={addWarehouseModal}
                showCloseIcon={false}
                tailwindClassName="w-[42%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setAddWarehouseModal(false);
                }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleAddNewWarehouse();
                    }}
                    className="bg-white rounded-lg shadow-cardShadow relative"
                >
                    <div className="px-10 pt-3">
                        <div className="flex flex-col items-center">
                            <div className="flex w-full justify-end mr-[-12px]">
                                <i
                                    onClick={() => setAddWarehouseModal(false)}
                                    className="ri-close-fill text-2xl cursor-pointe"
                                ></i>
                            </div>

                            <p className="text-xl mb-3 text-center  font-rocGroteskMedium text-g-75">
                                Create a new warehouse location
                            </p>
                            <div className="flex flex-col gap-6 w-full">
                                <TextInput
                                    value={addWarehouseData.name}
                                    name={"name"}
                                    type={"text"}
                                    onChange={(e) =>
                                        setAddWarehouseData((prev) => ({
                                            ...prev,
                                            [e.target.name]: e.target.value,
                                        }))
                                    }
                                    placeholder={"Warehouse name"}
                                    required={true}
                                    containerClassname=""
                                />
                                <SelectInput
                                    value={addWarehouseData.contactPerson}
                                    name="contactPerson"
                                    placeholder={"Contact Person"}
                                    handleChange={(name, value) => {
                                        setAddWarehouseData((prev) => ({ ...prev, [name]: value }));
                                    }}
                                    isRequired={true}
                                    searchLoading={fetchingTeamMember}
                                    dropdownOptions={teamList?.map((team) => ({
                                        label: team?.fullName ? team?.fullName : team?.email,
                                        value: team?._id,
                                        icon: (
                                            <VendorAvatar
                                                name={team?.fullName ? team?.fullName : team?.email}
                                                imageSrc={team?.profile?.avatar}
                                                size={20}
                                            />
                                        ),
                                    }))}
                                />
                                <AddressInput
                                    setData={setAddress}
                                    savedAddress={
                                        address?.location ? address?.location?.address : ""
                                    }
                                    placeholder="Location"
                                    required={true}
                                    name={"location"}
                                />
                                <TextareaInput
                                    name={"note"}
                                    value={addWarehouseData.note}
                                    onChange={(e) =>
                                        setAddWarehouseData((prev) => ({
                                            ...prev,
                                            [e.target.name]: e.target.value,
                                        }))
                                    }
                                    rows={5}
                                    placeholder={"Additional note"}
                                    required={true}
                                    containerClassname=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full items-center justify-between px-8 py-4 border-t border-slate-200 mt-10">
                        <Button
                            btnText={"View all warehouse"}
                            type={"button"}
                            btnClassname="!bg-white !border !border-n-20 !py-3 !pl-3 !pr-4 !w-fit !h-[48px]"
                            icon={<i className="ri-external-link-line text-lg"></i>}
                            btnType="textFirst"
                            onClick={() => {
                                navigate("/dashboard/inventory/warehouse");
                            }}
                        />
                        <div className="flex items-center gap-2">
                            <Button
                                btnText={"Cancel"}
                                type={"button"}
                                btnClassname="!bg-tradeally-neutral-20 !w-fit !h-[48px]"
                                disabled={createWarehouse}
                                onClick={() => {
                                    setAddWarehouseModal(false);
                                }}
                            />
                            <Button
                                btnText={"Add warehouse"}
                                type={"submit"}
                                btnClassname="!py-3 !px-6 !w-fit !h-[48px]"
                                isLoading={createWarehouse}
                                disabled={createWarehouse}
                            />
                        </div>
                    </div>
                </form>
            </ModalContainer>

            <ModalContainer
                open={addMoreWarehouseModal}
                showCloseIcon={false}
                tailwindClassName="w-[42%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setAddMoreWarehouseModal(false);
                }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleAddMorewWarehouse();
                    }}
                    className="bg-white rounded-lg shadow-cardShadow relative"
                >
                    <div className="px-10 pt-3">
                        <div className="flex flex-col items-center">
                            <div className="flex w-full justify-end mr-[-12px]">
                                <i
                                    onClick={() => setAddMoreWarehouseModal(false)}
                                    className="ri-close-fill text-2xl cursor-pointe"
                                ></i>
                            </div>

                            <p className="text-xl mb-3 text-center  font-rocGroteskMedium text-g-75">
                                Add a new warehouse location
                            </p>
                            <div className="flex flex-col gap-6 w-full">
                                <SelectInput
                                    value={productData.warehouse}
                                    name="warehouse"
                                    placeholder={"Select location"}
                                    handleChange={(name, value) => {
                                        if (value !== "") {
                                            handleWarehouseChange(name, value);
                                        }
                                    }}
                                    searchLoading={fetchingWarehouseList}
                                    handleInputChange={(e) => handleWarehouseDebouncedChange(e)}
                                    isRequired={true}
                                    optionItemContainerClassName="border-b border-slate-200"
                                    dropdownOptions={[
                                        {
                                            label: (
                                                <div
                                                    onClick={() => setAddWarehouseModal(true)}
                                                    className="flex items-center -mt-3 gap-2 text-b-50 font-rocGroteskMedium w-fit cursor-pointer"
                                                >
                                                    <i className="ri-add-fill text-xl"></i>
                                                    <span className="underline text-sm">
                                                        Create new warehouse
                                                    </span>
                                                </div>
                                            ),
                                            value: "",
                                        },
                                        ...warehouses?.map((warehouse) => ({
                                            label: warehouse?.warehouseName,
                                            value: warehouse?._id,
                                        })),
                                    ]}
                                />
                                <TextInput
                                    value={addWarehouseDetails.minStockLevel}
                                    name={"minStockLevel"}
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    onChange={(e) =>
                                        handleAddwarehouseChange(e.target.name, e.target.value)
                                    }
                                    placeholder={"Minimum stock level"}
                                    required={true}
                                    containerClassname=""
                                />
                                <TextInput
                                    value={addWarehouseDetails.maxStockLevel}
                                    name={"maxStockLevel"}
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    onChange={(e) =>
                                        handleAddwarehouseChange(e.target.name, e.target.value)
                                    }
                                    placeholder={"Maximum stock level"}
                                    required={true}
                                    containerClassname=""
                                />
                                <TextInput
                                    value={addWarehouseDetails.bufferStock}
                                    name={"bufferStock"}
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    onChange={(e) =>
                                        handleAddwarehouseChange(e.target.name, e.target.value)
                                    }
                                    placeholder={"Buffer stock level"}
                                    required={true}
                                    containerClassname=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full items-center justify-end px-8 py-4 border-t border-slate-200 mt-10">
                        <Button
                            btnText={"Add warehouse"}
                            type={"submit"}
                            btnClassname="!py-3 !px-6 !w-fit !h-[48px]"
                            isLoading={createWarehouse}
                            disabled={createWarehouse}
                        />
                    </div>
                </form>
            </ModalContainer>
        </PageFrame>
    );
};

export default EditProduct;
