import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
import rocGroteskLight from "./../../../../../../assets/fonts/rocGrotesk/RocGrotesk-Light.otf";
import rocGroteskRegular from "./../../../../../../assets/fonts/rocGrotesk/RocGrotesk-Regular.otf";
import rocGroteskMedium from "./../../../../../../assets/fonts/rocGrotesk/RocGrotesk-Medium.otf";
import rocGroteskBold from "./../../../../../../assets/fonts/rocGrotesk/RocGrotesk-Bold.otf";
import moment from "moment";

Font.register({
    family: "roc-grotesk",
    fonts: [
        {
            src: rocGroteskLight,
            fontWeight: "light",
        },
        {
            src: rocGroteskRegular,
            fontWeight: "normal",
        },
        {
            src: rocGroteskMedium,
            fontWeight: "medium",
        },
        {
            src: rocGroteskBold,
            fontWeight: "bold",
        },
    ],
});

Font.register({
    family: "Roboto",
    fonts: [
        {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
            fontWeight: 400,
        },
    ],
});

const signFix = StyleSheet.create({
    page: {
        fontFamily: "Roboto",
        fontWeight: 400,
    },
});

const styles = StyleSheet.create({
    page: {
        flexDirection: "row",
        backgroundColor: "#fff",
        color: "#000",
        fontWeight: "400",
        fontFamily: "roc-grotesk",
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    coverPage: {
        width: "100%",
        backgroundColor: "#0B213A",
        color: "#fff",
        flexDirection: "row",
    },
    image: {
        width: 200,
        height: 200,
        margin: 10,
    },
    boldText: {
        fontWeight: "bold",
    },
    footer: {
        paddingTop: 150,
        paddingLeft: 30,
        backgroundColor: "#0B213A",
        color: "#fff",
        width: "100%",
    },
    footerLogo: {
        paddingBottom: 80,
        width: "7%",
    },
    footerText: {
        paddingBottom: 136,
    },
    divider1: {
        backgroundColor: "#12ad68",
        height: 15,
        width: "95%",
    },
    divider2: {
        backgroundColor: "#ceaa0c",
        height: 15,
        width: "95%",
        marginTop: 100,
    },
    flex1: {
        width: "60%",
        paddingTop: 140,
    },
    flex2: {
        flexDirection: "column",
        width: "40%",
        paddingTop: 112,
        alignItems: "center",
        gap: 8,
    },
    logo1: {
        marginLeft: 10,
        paddingTop: 50,
        marginBottom: 15,
    },
    chartName: {
        marginLeft: 40,
    },
    businessLogo: {
        height: 80,
        marginBottom: 26,
    },
    times: {
        width: 18,
    },
    logo2: {
        marginTop: 26,
        width: 100,
    },
    imgWidth: {
        width: "80%",
        marginBottom: 40,
    },
    flex: {
        display: "flex",
        gap: "2",
    },
    rowDirection: {
        flexDirection: "row",
    },
    colDirection: {
        flexDirection: "column",
    },
    flexWrap: {
        flexWrap: "wrap",
    },
    alignItems: {
        alignItems: "center",
    },
    chartDiv: {
        height: "70%",
        width: "100%",
        marginTop: 20,
        marginBottom: 20,
    },
    chartValueContainer: {
        fontSize: "18",
    },
    chartValueItem: {
        marginRight: "20",
    },
    graphValues: {
        color: "#334155",
        fontSize: 16,
    },
    pageLayout: {
        padding: "40",
    },
    container: { flexDirection: "row", alignItems: "center", gap: "22px" },
    text1: {
        color: "#0F172A",
        fontSize: 18,
        fontWeight: "medium",
    },
    infoContainer: {
        borderRadius: 4,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 5,
        borderWidth: "1px",
        borderColor: "#e2e8f0",
        borderStyle: "solid",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: 4,
    },
    icon: {
        fontSize: "18pt",
        color: "#fff",
        marginRight: "3px",
        backgroundColor: "#CBD5E1",
        width: "16",
        height: "16",
        borderRadius: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    text2: {
        color: "#142837",
        fontSize: 14,
        fontWeight: "medium",
    },
    text3: {
        color: "#142837",
        fontSize: 16,
        fontWeight: "medium",
    },
    summarySection: {
        flexDirection: "row",
        backgroundColor: "#fff",
        color: "#000",
        fontWeight: "400",
    },
    card: {
        border: "0.8px solid #e0e0e0",
        borderRadius: "8px",
        padding: "15px",
        width: "192px",
        backgroundColor: "#fff",
        margin: "10px",
        color: "#000",
        height: "100pt",
    },
    cardTitle: {
        fontSize: "12pt",
        marginBottom: "10px",
        color: "#374151",
    },
    cardValue: {
        // fontSize: "20pt",
        fontWeight: "medium",
        marginBottom: "7px",
        fontSize: 40,
        color: "#22214c",
        marginTop: "7pt",
    },
    cardPercentage: {
        fontSize: "12pt",
        color: "#757575",
    },
    icon2: {
        width: "20px",
        height: "20px",
        marginRight: "5px",
    },
});

const cardStyles = StyleSheet.create({
    container: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "start",
        gap: 10,
    },
    titleFlex: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        gap: 8,
    },
    card: {
        width: "auto",
        borderRadius: "4px",
        border: "1 solid #CBD5E1",
        paddingLeft: "7px",
        paddingRight: "25px",
        paddingTop: "7px",
        marginBottom: "10px",
    },
    cardHeader: {
        flexDirection: "row",
        marginBottom: "14px",
        alignItems: "center",
    },
    image: {
        marginRight: "5px",
    },
    cardTitle: {
        fontWeight: "medium",
        fontSize: 10,
        color: "#475569",
    },
    cardValue: {
        fontSize: "20px",
        fontWeight: "normal",
        color: "#22214C",
    },
    cardPercentage: {
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
        marginBottom: 4,
    },
    percentageImage: {
        marginRight: "5",
    },
    percentageText: {
        fontSize: 8,
        color: "#89939B",
        fontWeight: "medium",
    },
    icon: {
        width: 12,
    },
    pIcon: {
        height: 6,
    },
});
const titleStyles = StyleSheet.create({
    heading: {
        fontSize: "18px",
        fontWeight: "medium",
    },
    date: {
        fontSize: "12px",
        fontWeight: "medium",
    },
    section: {
        marginBottom: "32px",
    },
    divider: {
        backgroundColor: "#F1F5F9",
        height: "1px",
        width: "100%",
    },
});

const tableStyles = StyleSheet.create({
    section: {
        marginTop: 20,
        marginBottom: 20,
    },
    headingSection: {
        marginBottom: 2,
        color: "#334155",
        fontWeight: "medium",
    },
    heading: {
        fontSize: 16,
        marginBottom: "16px",
        color: "#1E293B",
        borderBottom: "1px solid #F1F5F9",
    },
    container: {
        width: "100%",
        backgroundColor: "white",
    },
    tableBody: {
        borderLeft: "1 solid #E2E8F0",
        borderRight: "1 solid #E2E8F0",
        borderBottom: "1 solid #E2E8F0",
    },
    header: {
        backgroundColor: "#F8FAFC",
        flexDirection: "row",
        justifyContent: "space-between",
        borderLeft: "1 solid #E2E8F0",
        borderTop: "1 solid #E2E8F0",
        borderRight: "1 solid #E2E8F0",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        paddingTop: 18,
        paddingLeft: 16,
        textAlign: "left",
        fontSize: 10,
        color: "#334155",
        fontWeight: "medium",
    },
    row: {
        flexDirection: "row",
        borderTop: "1 solid #E2E8F0",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: 10,
        paddingTop: 18,
        paddingLeft: 16,
        color: "#3B4C58",
        fontWeight: "medium",
    },
    cellWdith: {
        width: "23%",
    },
    cellWdith2: {
        width: "10%",
    },
    cellWdith3: {
        width: "12%",
    },
    rounded: {
        borderRadius: 9999,
        width: 7,
        height: 7,
    },
    imgRounded: {
        borderRadius: 9999,
    },

    fulfilled: {
        backgroundColor: "#16C6A4",
    },
    inProgress: {
        backgroundColor: "#00C6FA",
    },
    pending: {
        backgroundColor: "#F59E0B",
    },
    rejected: {
        backgroundColor: "#ff3347",
    },
    draft: {
        backgroundColor: "#CBD5E1",
    },
    statusFlex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        gap: 6,
    },
    imgMargin: {
        marginTop: -4,
    },
    cellFlex: {
        flexDirection: "row",
        gap: 4,
        alignItems: "center",
    },
    profileImg: {
        width: 16,
        // marginTop: -4,
        rounded: 9999
    },
});

const SuppliedStockPdf = ({ suppliedStockData, businessLogo }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page} orientation="landscape">
                <View style={styles.coverPage}>
                    <View style={styles.flex1}>
                        <View style={styles.divider1} />
                        <View style={styles.divider2} />
                        <Image
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702357166/synthAlly_dcohmp.png"
                            style={styles.logo1}
                        />
                        <Text style={styles.chartName}>Supplied Stock</Text>
                    </View>
                    <View style={styles.flex2}>
                        <Image src={businessLogo} style={styles.businessLogo} />
                        <Image
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702359704/X_mfthox.png"
                            style={styles.times}
                        />
                        <Image
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702357366/synthAllylogo_fhimzk.png"
                            style={styles.logo2}
                        />
                    </View>
                </View>
            </Page>

            <Page size="A4" style={[styles.page, styles?.pageLayout]} orientation="landscape">
                <View>
                    <View>
                        {suppliedStockData !== null && (
                            <View style={tableStyles.section}>
                                <View style={tableStyles.headingSection}>
                                    <Text style={tableStyles.heading}>Supplied Stock List </Text>
                                </View>
                                <View style={tableStyles.container}>
                                    <View style={tableStyles.header}>
                                        <Text style={tableStyles.cellWdith3}>Reference</Text>
                                        <Text style={tableStyles.cellWdith}>Product</Text>
                                        <Text style={tableStyles.cellWdith}>Customer</Text>
                                        <Text style={tableStyles.cellWdith}>Date of Supply</Text>
                                        <Text style={tableStyles.cellWdith3}>Status</Text>
                                        <Text style={tableStyles.cellWdith}>Recorded by</Text>
                                    </View>

                                    <View style={tableStyles.tableBody}>
                                        {suppliedStockData.map((item) => (
                                            <View style={tableStyles.row}>
                                                <Text style={tableStyles.cellWdith3}>
                                                    {item.reference}
                                                </Text>
                                                <View
                                                    style={[
                                                        tableStyles.cellFlex,
                                                        tableStyles.cellWdith,
                                                    ]}
                                                >
                                                    {item.vendor?.profile?.avatar ? (
                                                        <Image
                                                            src={
                                                                item?.inventory?.productImageDetails
                                                                    ?.productAvatar
                                                            }
                                                            style={tableStyles?.profileImg}
                                                        />
                                                    ) : (
                                                        <Image
                                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1694624630/Tradeally_2.0/undefined_-_Imgur_1_prpxel.jpg"
                                                            style={tableStyles?.profileImg}
                                                        />
                                                    )}
                                                    <View>
                                                        <Text>
                                                            {item?.inventory?.productName}
                                                        </Text>
                                                        <Text>{item?.batchId}</Text>
                                                    </View>
                                                </View>

                                                <Text style={tableStyles.cellWdith}>
                                                    {item?.customerDetails?.name}
                                                </Text>
                                                <Text style={tableStyles.cellWdith}>
                                                    {moment(item?.dateOfSupply).format(
                                                        "MMM DD, YYYY • h:mm:ss A"
                                                    )}
                                                </Text>
                                                <View
                                                    style={[
                                                        tableStyles.statusFlex,
                                                        tableStyles.cellWdith3,
                                                    ]}
                                                >
                                                    <Text
                                                        style={[
                                                            item?.status === "fulfilled"
                                                                ? tableStyles.fulfilled
                                                                : item?.status === "inProgress"
                                                                ? tableStyles.inProgress
                                                                : item?.status === "pending"
                                                                ? tableStyles.pending
                                                                : item?.status === "rejected"
                                                                ? tableStyles.rejected
                                                                : item?.status === "draft" &&
                                                                  tableStyles.draft,
                                                            tableStyles.rounded,
                                                            tableStyles.imgMargin,
                                                        ]}
                                                    ></Text>
                                                    <Text>
                                                        {item?.status === "inProgress"
                                                            ? "In Progress"
                                                            : item?.status}
                                                    </Text>
                                                </View>
                                                <View
                                                    style={[
                                                        tableStyles.cellFlex,
                                                        tableStyles.cellWdith,
                                                    ]}
                                                >
                                                   
                                                        {item.creator?.profile?.avatar ? (
                                                            <Image
                                                                src={item?.creator?.profile?.avatar}
                                                                style={tableStyles?.profileImg}
                                                            />
                                                        ) : (
                                                            <Image
                                                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1694624630/Tradeally_2.0/undefined_-_Imgur_1_prpxel.jpg"
                                                                style={tableStyles?.profileImg}
                                                            />
                                                        )}
                                                    <View>
                                                        <Text>
                                                            {item?.creator?.fullName}
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>
                                        ))}
                                    </View>
                                </View>
                            </View>
                        )}
                    </View>
                </View>
            </Page>

            <Page size="A4" style={styles.page} orientation="landscape">
                <View style={styles.footer}>
                    <View style={styles.imgWidth}>
                        <Image src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702358337/thanks_yjmk0q.png" />
                    </View>
                    <View style={[styles.flex, styles.rowDirection]}>
                        <Text style={styles.boldText}>Address:</Text>
                        <Text> 7000 North Mopac Expressway, Austin TX 78759</Text>
                    </View>
                    <View style={[styles.flex, styles.rowDirection]}>
                        <Text style={styles.boldText}>Phone Number:</Text>
                        <Text> +18329972582</Text>
                    </View>
                    <View style={[styles.flex, styles.rowDirection]}>
                        <Text style={styles.boldText}>E-Mail:</Text>
                        <Text style={styles.footerText}> hello@tradeally.io</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};
export default SuppliedStockPdf;
