import Button from "components/common/Button";
import { formatMoney, getCurrencyFromCurrencyCode, hasHTMLTag, sanitizeHtml } from "helpers";
import { truncate } from "lodash";
import React from "react";
import PurchaseOrdersPDF from "./PDFPurchase";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useNavigate } from "react-router-dom";
import { getEditPermissionList } from "helpers/getEditPermissionList";

type PurchaseOrderListDetailsProps = {
    item: string;
    taId: string;
    issuedDate: string;
    dueDate: string;
    selectedRFQData?: { [key: string]: any };
    address: string;
    postalCode: string;
    userName: string;
    userEmail: string;
    city: string;
    country: string;
    containers: { [key: string]: any }[];
    tax: string;
    currency: string;
    taxAmount: string | number;
    shipping: string | number;
    totalAmount: string;
    orderDescription: string;
    title: string;
    poNumber: string;
    imageDetails: string;
    currentStatus: string;
    onEdit: () => void;
    vendorName: string;
    vendorEmail: string;
    imageUrl?: string;
    userProfile?: { [key: string]: any };
    showVendor?: boolean;
    successPreview?: boolean;
    disabled?: boolean;
    isLoading?: boolean;
    defaultIssueDate?: string;
    defaultDueDate?: string;
    approvalStatus?: string;
};

const PurchaseOrderListDetails = ({
    item,
    taId,
    issuedDate,
    dueDate,
    selectedRFQData,
    address,
    postalCode,
    userName,
    userEmail,
    city,
    country,
    containers,
    tax,
    currency,
    taxAmount,
    shipping,
    totalAmount,
    orderDescription,
    title,
    poNumber,
    imageDetails,
    currentStatus,
    approvalStatus,
    onEdit,
    vendorName,
    vendorEmail,
    imageUrl,
    userProfile,
    isLoading,
    successPreview,
    showVendor,
    disabled,
    defaultIssueDate,
    defaultDueDate,
}: PurchaseOrderListDetailsProps) => {
    const navigate = useNavigate();

    return (
        <div className="w-full">
            <div className="pb-6 rounded-[8px]  h-full ">
                <div className=" px-8  border-b py-6 border-b-slate-100 sm:flex justify-between items-center ">
                    <p className="font-rocGroteskMedium text-gm-50 md:text-2xl"> {title}</p>
                    {/* <div className="flex whitespace-nowrap space-x-3 items-center">
                        <div
                            className={`py-[10px] md:block hidden rounded px-3 ${
                                currentStatus?.toLowerCase() === "accepted" &&
                                "bg-btn-light-green text-[#16A34A]"
                            } ${
                                currentStatus?.toLowerCase() === "pending" &&
                                "bg-btn-light-yellow text-[#F59E0B]"
                            }  ${
                                currentStatus?.toLowerCase() === "rejected" &&
                                "bg-btn-light-red text-r-55"
                            }`}
                        >
                            <p>PO {currentStatus}</p>
                        </div>
                        {currentStatus?.toLowerCase() !== "accepted" && (
                            <Button
                                btnText={"Edit PO"}
                                type={"button"}
                                btnClassname="!py-[10px] cursor-pointer  !font-rocGroteskMedium !px-4"
                                onClick={onEdit}
                            />
                        )}
                    </div> */}

                    <div className="mt-5 flex items-center space-x-3">
                        <Button
                            btnText={"Close"}
                            type={"button"}
                            btnClassname={
                                "!w-fit sm:border-none  !bg-transparent !border !text-gm-45"
                            }
                            onClick={() => navigate("/dashboard/purchase-orders")}
                        />

                        <PDFDownloadLink
                            document={
                                <PurchaseOrdersPDF
                                    title={"PO"}
                                    address={address}
                                    country={country}
                                    city={city}
                                    imageDetails={imageDetails}
                                    item={item}
                                    issuedDate={defaultIssueDate || issuedDate}
                                    dueDate={defaultDueDate || dueDate}
                                    vendorName={vendorName}
                                    vendorEmail={vendorEmail}
                                    userName={userName}
                                    userEmail={userEmail}
                                    containers={containers}
                                    tax={tax}
                                    currency={currency}
                                    taxAmount={taxAmount}
                                    shipping={shipping}
                                    totalAmount={totalAmount}
                                    orderDescription={orderDescription}
                                    tagNumber={taId}
                                    status={currentStatus}
                                />
                            }
                            fileName="purchase-order.pdf"
                        >
                            {({ loading }) => {
                                return loading ? (
                                    <Button
                                        btnText={"Download PDF"}
                                        isLoading={loading}
                                        type={"button"}
                                        btnClassname={"!bg-n-20 !text-gm-50"}
                                    />
                                ) : (
                                    <Button
                                        btnText={"Download PDF"}
                                        type={"button"}
                                        btnClassname={"!bg-n-20 !text-gm-50"}
                                    />
                                );
                            }}
                        </PDFDownloadLink>
                    </div>
                </div>
                <div className="md:px-8 px-2  pt-10">
                    <div className="flex justify-between items-center">
                        <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-3 sm:space-x-6 items-center mb-6 justify-between w-full">
                            <div className="border border-dashed rounded-[4px]  border-[n-40] w-[128px] h-[128px] flex items-center justify-center">
                                <div className="">
                                    {imageDetails === "" ? (
                                        <i className="ri-image-add-fill text-[35px] text-tradeally-neutral-200"></i>
                                    ) : (
                                        <img src={imageDetails} alt="business-logo" />
                                    )}
                                </div>
                            </div>

                            <div className="flex flex-nowrap space-x-3 items-center">
                                {approvalStatus?.toLowerCase() === "approved" && (
                                    <div
                                        className={`py-[10px] rounded px-3 ${
                                            currentStatus?.toLowerCase() === "accepted" &&
                                            "bg-btn-light-green text-[#16A34A]"
                                        } ${
                                            currentStatus?.toLowerCase() === "pending" &&
                                            "bg-btn-light-yellow text-[#F59E0B]"
                                        }  ${
                                            currentStatus?.toLowerCase() === "rejected" &&
                                            "bg-btn-light-red text-r-55"
                                        }`}
                                    >
                                        <p>PO {currentStatus}</p>
                                    </div>
                                )}

                                {approvalStatus?.toLowerCase() === "rejected" && (
                                    <div
                                        className={`py-[10px] rounded-[8px] px-3 bg-btn-light-red text-r-55`}
                                    >
                                        <p>PR rejected</p>
                                    </div>
                                )}
                                {currentStatus?.toLowerCase() !== "accepted" &&
                                    approvalStatus?.toLowerCase() !== "rejected" && (
                                        <Button
                                            btnText={
                                                approvalStatus?.toLowerCase() === "pending"
                                                    ? "Edit PR"
                                                    : "Edit PO"
                                            }
                                            type={"button"}
                                            btnClassname="!py-[10px] cursor-pointer  !font-rocGroteskMedium !px-4"
                                            onClick={onEdit}
                                            permissions={getEditPermissionList("purchase_order")}
                                        />
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="md:mt-10 mt-3 flex sm:flex-row flex-col  w-full border-b  pb-5">
                        <div className="space-y-4 w-[60%]">
                            <div>
                                <h6 className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                    RFQ {taId}
                                </h6>
                            </div>
                            <div>
                                <h6 className=" text-gm-50  font-rocGroteskMedium text-sm">
                                    PO Number
                                </h6>
                                <p className="text-slate-500 font-rocGroteskMedium text-sm">
                                    {poNumber ? poNumber : "----"}
                                </p>
                            </div>
                            <div>
                                <h6 className="text-gm-50  font-rocGroteskMedium text-[14px]">
                                    Currency
                                </h6>
                                <p className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                    {currency}
                                </p>
                            </div>
                        </div>
                        <div className="space-y-4 w-[35%] ">
                            <div>
                                <h6 className="text-gm-50  font-rocGroteskMedium text-[14px]">
                                    From
                                </h6>
                                <p className="text-gm-50 mt-4 font-rocGroteskMedium text-[14px]">
                                    {userName}
                                </p>
                                <h6 className="text-slate-500 font-rocGroteskRegular text-[14px]">
                                    {userEmail}
                                </h6>
                            </div>
                            <div>
                                <h6 className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                    To
                                </h6>
                                <h6 className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                    {vendorName}
                                </h6>
                                <p className="text-slate-500 font-rocGroteskRegular text-[14px]">
                                    {vendorEmail}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className=" flex mt-5 sm:flex-row flex-col sm:space-y-0 space-y-4 ">
                        <div className="w-[60%] ">
                            <h6 className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                Issued Date
                            </h6>
                            <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                {issuedDate}
                            </p>
                        </div>
                        <div className="w-[35%] ">
                            <h6 className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                Due Date
                            </h6>
                            <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                {dueDate}
                            </p>
                        </div>
                    </div>
                    <div className="mt-6 pb-5 border-b">
                        <p className="text-[14px] mb-2 text-slate-500 font-rocGroteskMedium">
                            Ship to
                        </p>
                        <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-4 ">
                            <div className="sm:w-[60%]  ">
                                <p className="text-gm-50  font-rocGroteskMedium text-[14px]">
                                    Street: <span className="font-rocGroteskMedium">{address}</span>
                                </p>
                                <p className="text-gm-50  font-rocGroteskMedium text-[14px]">
                                    Postal Code:{" "}
                                    <span className="font-rocGroteskMedium">{postalCode}</span>
                                </p>
                            </div>
                            <div className="sm:w-[35%] ">
                                <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                    City: <span className="font-rocGroteskMedium">{city}</span>
                                </p>
                                <p className="text-gm-50  font-rocGroteskMedium text-[14px]">
                                    Country:{" "}
                                    <span className="font-rocGroteskMedium">{country}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="  mt-10 gap-10 pb-6  w-full">
                        <p className="md:text-[22px] text-base pb-6 font-rocGroteskMedium text-gm-50">
                            Quote for {item}
                        </p>
                        <div className="">
                            <div className="text-[12px] md:flex hidden justify-between w-full font-rocGroteskMedium text-slate-500 uppercase">
                                <p className="w-[50%]">Items/services</p>
                                <div className="flex w-[45%] justify-between">
                                    <p>QTY</p>
                                    <p>RATE</p>
                                    <p>TOTAL</p>
                                </div>
                            </div>
                            <div className="flex md:hidden font-rocGroteskMedium items-center">
                                <p className=" px-2  w-[40%] text-xs text-[#334155] border-[#DFE1E6] text-center py-[10px]">
                                    Item/Quantity
                                </p>
                                <p className=" px-2  w-[40%] text-xs text-[#334155] border-[#DFE1E6] text-center py-[10px]">
                                    Rate
                                </p>
                            </div>
                            {containers?.map((d, index) => (
                                <div key={index}>
                                    <div className="text-[14px] md:flex hidden  pb-3 border-b border-slate-100 mt-4  justify-between w-full font-rocGroteskMedium text-gm-50">
                                        <p className="w-[50%]">{d?.name}</p>
                                        <div className="flex w-[45%] justify-between">
                                            <p>{d?.qty}</p>
                                            <p>
                                                {getCurrencyFromCurrencyCode(d?.price?.currency)}
                                                {d?.price?.amount}
                                            </p>
                                            <p>
                                                {getCurrencyFromCurrencyCode(d?.subtotal?.currency)}
                                                {d?.qty * d?.price?.amount}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="space-y-1 mb-1 md:hidden block">
                                        <div className=" w-full flex bg-[#F4F5F7] font-rocGroteskMedium">
                                            <p className="border-b border-l text-sm  border-[#DFE1E6] text-slate-900 border-t px-2 w-[60%] text-center py-[10px]">
                                                {truncate(d?.name, {
                                                    length: 40,
                                                })}{" "}
                                                {d?.qty}
                                            </p>
                                            <p className="border-r border-l border-b text-sm  border-[#DFE1E6] text-slate-900 border-t px-2 w-[60%] text-center py-[10px]">
                                                {getCurrencyFromCurrencyCode(d?.amount?.currency)}
                                                {formatMoney()?.format(
                                                    d?.qty * d?.price?.amount
                                                )}{" "}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className=" space-y-3 pb-5">
                        <div className="flex justify-between text-[14px] font-rocGroteskMedium text-gm-50">
                            <div className="flex space-x-1 items-center">
                                <div className="flex items-center">
                                    <p className="text-slate-500">Tax ({tax} %)</p>
                                </div>
                            </div>
                            <p>
                                {getCurrencyFromCurrencyCode(currency)}
                                {taxAmount}
                            </p>
                        </div>
                        <div className="flex justify-between text-[14px] pb-4 border-b font-rocGroteskMedium text-gm-50">
                            <p className="text-slate-500">Shipping and Handling</p>
                            <p>
                                {getCurrencyFromCurrencyCode(currency)}
                                {shipping !== "" ? shipping : 0}
                            </p>
                        </div>
                        <div className="flex justify-between text-[16px] font-rocGroteskMedium text-gm-50">
                            <p className="text-slate-500 ">Total Amount</p>
                            <p>
                                {getCurrencyFromCurrencyCode(currency)}
                                {totalAmount}
                            </p>
                        </div>
                    </div>
                    <div className="py-10">
                        <h6 className="flex justify-between text-[14px] font-rocGroteskMedium text-gm-50">
                            Purchase Order Terms and Conditions
                        </h6>
                        <div className="space-y-0.5 text-[14px] font-rocGroteskMedium text-slate-500">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: hasHTMLTag(orderDescription)
                                        ? sanitizeHtml(orderDescription).sanitizedHTML?.join("")
                                        : orderDescription,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchaseOrderListDetails;
