/* eslint-disable */
import React from "react";
import ReactEcharts from "echarts-for-react";
import BreadCrumb from "components/common/BreadCrumb";
import PageFrame from "components/layout/PageFrame";
import { useEffect, useRef, useState } from "react";
import Dropdown from "components/common/Dropdown";
import Button from "components/common/Button";
import { AnalyticsCard } from "components/views/Ldb/Dashboard/BusinessAnalytics/Card";
import ShareModal from "components/views/Ldb/Dashboard/BusinessAnalytics/ShareModal";
import { businessAnalyticsActions } from "redux/Ldb/actions/businessAnalyticsActions";
import { periodStartDateAndEndDate } from "helpers/periodStartDateAndEndDate";
import { fillMissingWithZero } from "helpers/fillMissingWithZero";
import Loader from "components/common/Loader";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import { authActions, orderActions } from "redux/Ldb/actions";
import * as htmlToImage from "html-to-image";
import { MultipleDropdowns } from "components/common/MultipleDropdown";
import AnalyticsPdf from "components/views/Ldb/Dashboard/BusinessAnalytics/AnalyticsPdf";
import CustomCalendar from "components/common/CalendarComp.js/Calendar";
import moment from "moment";
import pptxgen from "pptxgenjs";
import { convertPDFtoImages } from "helpers/convertPDFBlobToImages";
import { formatDateRange } from "helpers/formatDateRange";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { getEditPermissionList } from "helpers/getEditPermissionList";

const PurchaseOrder = () => {
    const dispatch = useAppDispatch();
    const chartRef = useRef(null);
    const customRef = useRef(null);
    const [profile, setProfile] = useState<any>({});
    const [captureChartLoading, setCaptureChartLoading] = useState(false);
    const [summary, setSummary] = useState();
    const [sortedSummary, setSortedSummary] = useState<any>();
    const [shareModal, setShareModal] = useState(false);
    const [summaryFilter, setSummaryFilter] = useState("1 year");
    const [fulfilledOrderValues, setFulfilledOrderValues] = useState();
    const [rfqValues, setRfqValues] = useState();
    const [rfqChart, setRfqChart] = useState<any>();
    const [fulfilledChart, setfulfilledChart] = useState<any>();
    const [noRfqChartData, setNoRfqChartData] = useState(false);
    const [noFulfilledChartData, setNoFulfilledChartData] = useState(false);
    const [chartImage, setChartImage] = useState("");
    const [purchaseOrder, setPurchaseOrder] = useState<any>();
    const [summaryDuration, setSummaryDuration] = useState("year");
    const [noData, setNoData] = useState(false);
    const [year, setYear] = useState<number>();
    const summaryFilterValues = ["1 year", "6 months", "1 month", "1 week", "custom"];
    const [exportOpt, setExportOpt] = useState("");
    const [purchaseOrderData, setPurchaseOrderData] = useState<any>();
    const [month, setMonth] = useState<string>("");
    const [showCustomDate, setShowCustomDate] = useState(false);
    const [customValue, customOnChange] = useState(new Date());
    const [showCustomDateTwo, setShowCustomDateTwo] = useState(false);
    const [customValueTwo, customOnChangeTwo] = useState(new Date());
    const [imageUrls, setImageUrls] = useState<string[]>();
    const [generatedBlob, setGeneratedBlob] = useState(null);
    const [dateRange, setDateRange] = useState("");
    const [startAndEndDate, setStartAndEndDate] = useState<any>({});
    const [showDateRange, setShowDateRange] = useState<boolean>(false);
    const [period, setPeriod] = useState<string>("");

    const {
        getPurchaseOrderSummarySuccess,
        getPurchaseOrderSuccess,
        gettingPurchaseOrder,
        gettingPurchaseOrderSummary,
    } = useAppSelector((state) => state.businessAnalytics);

    const { fetchUserProfileSuccess } = useAppSelector((state) => state.auth);

    useEffect(() => {
        const status = ["pending", "approved", "rejected"];
        const fetchAllData = async () => {
            try {
                const responses = await Promise.all(
                    status.map(async (category) => {
                        return await dispatch(orderActions.getPONoPaginate(category));
                    })
                );

                setPurchaseOrderData(responses);
            } catch (error) {
                return error;
            }
        };
        fetchAllData();
    }, [dispatch]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (customRef.current && !customRef.current.contains(event.target)) {
                setShowCustomDate(false);
                setShowCustomDateTwo(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [customRef]);

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setProfile(fetchUserProfileSuccess);
        }
    }, [fetchUserProfileSuccess]);

    useEffect(() => {
        if (!Boolean(fetchUserProfileSuccess)) {
            dispatch(authActions.getLdbProfile());
        }
    }, [dispatch, fetchUserProfileSuccess]);

    useEffect(() => {
        if (Boolean(getPurchaseOrderSummarySuccess)) {
            setSummary(getPurchaseOrderSummarySuccess?.summary);
        }
        if (Boolean(getPurchaseOrderSuccess)) {
            setPurchaseOrder(getPurchaseOrderSuccess);
            setRfqValues(getPurchaseOrderSuccess?.rfq);
            setFulfilledOrderValues(getPurchaseOrderSuccess?.po);
        }
    }, [getPurchaseOrderSummarySuccess, getPurchaseOrderSuccess]);

    useEffect(() => {
        let period, numberOfMonths;
        if (summaryFilter !== "custom" || customValue[0] === customValue[1]) {
            if (summaryFilter === "1 week") {
                period = "week";
            } else if (summaryFilter === "1 month") {
                period = "month";
            } else if (summaryFilter === "6 months") {
                period = "month";
                numberOfMonths = "6";
            } else if (summaryFilter === "1 year") {
                period = "year";
                numberOfMonths = "12";
            } else {
                return period;
            }
            const value = periodStartDateAndEndDate(period, numberOfMonths);
            setStartAndEndDate({
                startDate: value.startDate,
                endDate: value.endDate,
            });
            setShowDateRange(false);
            setPeriod(period);
        } else {
            if (customValue[0] !== customValue[1]) {
                setStartAndEndDate({
                    startDate: moment(customValue[0]).format("YYYY-MM-DD"),
                    endDate: moment(customValue[1]).format("YYYY-MM-DD"),
                });
                setShowDateRange(true);
                period = "custom";
                setPeriod("custom");
            }
        }

        if (numberOfMonths === "6") {
            const duration = `${numberOfMonths} ${period}`;
            setSummaryDuration(duration);
        } else {
            setSummaryDuration(period);
        }
    }, [summaryFilter, customValue]);

    useEffect(() => {
        if (startAndEndDate?.startDate && startAndEndDate?.endDate) {
            dispatch(
                businessAnalyticsActions.purchaseSummary(
                    period,
                    startAndEndDate?.startDate,
                    startAndEndDate?.endDate
                )
            );

            if (summaryFilter === "custom") {
                dispatch(
                    businessAnalyticsActions.purchaseOrder(
                        "",
                        startAndEndDate?.startDate,
                        startAndEndDate?.endDate
                    )
                );
            } else if (summaryFilter === "6 months") {
                dispatch(businessAnalyticsActions.purchaseOrder("six_month"));
            } else {
                dispatch(businessAnalyticsActions.purchaseOrder(period));
            }
        }
        const formattedRange = formatDateRange(
            startAndEndDate?.startDate,
            startAndEndDate?.endDate,
            "short"
        );
        setDateRange(formattedRange);
        const PoYear = new Date(startAndEndDate?.endDate).getFullYear();
        setYear(PoYear);
    }, [startAndEndDate, period]);
    const handleSummaryFilter = (e) => {
        const { value } = e.target;
        setSummaryFilter(value);

        if (value === "custom") {
            setShowCustomDate(true);
        }
        if (value !== "custom") {
            customOnChange(new Date());
        }
    };

    const cardOrder = {
        "in-progress": 1,
        completed: 2,
        pending: 3,
        rejected: 4,
    };

    useEffect(() => {
        if (summary) {
            const copyValue = [...summary];
            const sortedData = copyValue?.sort(
                (a: any, b: any) => cardOrder[a._id] - cardOrder[b._id]
            );
            setSortedSummary(sortedData);
        }
    }, [summary]);

    const createPPT = (imageUrls) => {
        const pres = new pptxgen();
        let slides = [];

        imageUrls?.forEach((imageUrl, index) => {
            slides[index + 1] = pres.addSlide();
            slides[index + 1].addImage({
                data: imageUrl,
                x: 0,
                y: 0,
                w: "100%",
                h: "100%",
            });
        });

        pres.writeFile({ fileName: "purchase_analysis.pptx" });
    };

    useEffect(() => {
        if (imageUrls) createPPT(imageUrls);
    }, [imageUrls]);

    const getBlobImages = async (blob) => {
        const blobImages = await convertPDFtoImages(blob);
        setImageUrls(blobImages);
    };

    const renderAnalyticsCards = () => {
        return sortedSummary?.map((item) => {
            const increase = item.percentage > 0;
            const value = Math.abs(item.percentage);
            const title =
                item?._id === "in-progress"
                    ? "Active"
                    : item?._id === "completed"
                    ? "Fulfilled"
                    : item?._id === "pending"
                    ? "Pending"
                    : item?._id === "rejected" && "Cancelled";
            return (
                <div key={item?._id}>
                    <AnalyticsCard
                        title={`${title} purchase orders`}
                        className="!shadow-none !border-none !bg-[#fcfcfc]"
                        value={item?.count >= 0 ? item.count : "--"}
                        increase={increase}
                        percentageValue={value}
                        filterDuration={
                            Boolean(showDateRange) ? dateRange : `this ${summaryDuration}`
                        }
                        loader={gettingPurchaseOrderSummary}
                        iconOne={false}
                        iconTwo={false}
                        time={false}
                    />
                </div>
            );
        });
    };

    useEffect(() => {
        if (rfqValues) {
            const check = fillMissingWithZero(rfqValues, summaryDuration);
            setRfqChart({
                x: check?.x,
                y: check?.y,
            });
            const allZeros = check?.x?.every((number) => number === 0);
            setNoRfqChartData(allZeros);
        }
        if (fulfilledOrderValues) {
            const check = fillMissingWithZero(fulfilledOrderValues, summaryDuration);
            setfulfilledChart({
                x: check?.x,
                y: check?.y,
            });
            const allZeros = check?.x?.every((number) => number === 0);
            setNoFulfilledChartData(allZeros);
        }
    }, [rfqValues, fulfilledOrderValues]);

    const rfqChartValue = rfqChart?.y?.map((month, ind) => ({
        month,
        value: rfqChart?.x[ind],
    }));

    const fulfilledChartValue = fulfilledChart?.y?.map((month, ind) => ({
        month,
        value: fulfilledChart?.x[ind],
    }));

    const rfq = {
        tooltip: {
            trigger: "item",
            backgroundColor: "#142837",
            borderWidth: "0",
            padding: 0,
            formatter: function (params) {
                return `<div style="padding: 4px 12px; border-radius: 4px; max-width: 38px; font-weight: 500 !important; position: relative;
                color: #fff !important;
                font-size: 12px; font-family: 'rocGrotesk-regular';">
                        ${params.value}
 <i class="ri-arrow-down-s-fill text-[#142837]" style="position: absolute;
  z-index: 2; font-size: 20px !important; bottom: -12px; left: 25%;"></i>
                    </div>`;
            },
        },
        grid: {
            left: "5%",
            right: "0%",
        },
        xAxis: {
            type: "category",
            data: rfqChart?.y,
            nameTextStyle: {
                borderType: "dotted",
                fontSize: "9",
                fontFamily: "rocGrotesk-regular",
            },
            axisLabel: {
                rotate: 30,
                margin: 20,
                color: "#89939b",
                align: "center",
            },
            axisLine: {
                lineStyle: {
                    color: "#E2E8F0",
                    z: 2,
                },
                z: 2,
            },

            axisTick: {
                show: false,
            },
        },
        yAxis: {
            type: "value",
            splitLine: {
                show: true,
                lineStyle: {
                    type: "dashed",
                    color: "#E2E8F0",
                },
            },
            nameTextStyle: {
                fontFamily: "rocGrotesk-regular",
            },
        },
        series: [
            {
                data: rfqChart?.x,
                type: "bar",
                barWidth: "18px",
            },
        ],
        color: ["#ffce6a"],
    };

    const fulfilled = {
        tooltip: {
            backgroundColor: "#142837",
            borderWidth: "0",
            padding: 0,

            formatter: function (params) {
                return `<div style="padding: 4px 12px; border-radius: 4px; max-width: 38px; font-weight: 500 !important; position: relative;
                color: #fff !important;
                font-size: 12px; font-family: 'rocGrotesk-regular';">
                        ${params.value}
 <i class="ri-arrow-down-s-fill text-[#142837]" style="position: absolute;
  z-index: 2; font-size: 20px !important; bottom: -12px; left: 25%;"></i>
                    </div>`;
            },
        },
        grid: {
            left: "0",
            right: "10%",
        },
        xAxis: {
            type: "category",
            data: fulfilledChart?.y,
            nameTextStyle: {
                borderType: "dotted",
                fontSize: "9",
            },
            axisLabel: {
                rotate: 30,
                margin: 20,
                color: "#89939b",
                align: "center",
            },
            axisLine: {
                lineStyle: {
                    color: "#E2E8F0",
                    z: 2,
                },
                z: 2,
            },

            axisTick: {
                show: false,
            },
        },
        yAxis: {
            type: "value",
            position: "right",
            splitLine: {
                show: true,
                lineStyle: {
                    type: "dashed",
                    color: "#E2E8F0",
                },
            },
        },
        series: [
            {
                data: fulfilledChart?.x,
                type: "bar",
                barWidth: "18px",
            },
        ],
        color: ["#64D9C2"],
    };
    const captureChart = async () => {
        if (chartRef.current) {
            if (!chartImage) {
                setCaptureChartLoading(true);
                const chartElement = chartRef.current;
                const img = await htmlToImage.toPng(chartElement);
                setChartImage(img);
                setCaptureChartLoading(false);
            } else {
                setCaptureChartLoading(false);
            }
        }
    };

    const dropOptions = [
        {
            label: (
                <div
                    className=" text-gm-50 font-rocGroteskMedium text-[13px] flex gap-3
                                                border-b border-b-slate-100 !w-[192px] !px-[10px] !py-[10px]"
                >
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702458892/ppt_qm1phs.svg"
                        alt="powerpoint icon"
                        className="w-3"
                    />
                    Export as powerpoint
                </div>
            ),
            onClick: () => {
                setExportOpt("ppt");
            },
            childClassName: "!top-2",
            actionButtons: (
                <div className="flex justify-end mt-[20px] px-4 mb-3 gap-3 relative !z-[999]">
                    {exportOpt === "ppt" ? (
                        <Button
                            btnClassname={"!w-fit !py-3 !px-6 !bg-[#FFB902] !text-black"}
                            disabled={true}
                            btnText={"Export"}
                        />
                    ) : captureChartLoading ? (
                        <Button
                            btnClassname={"!w-fit !py-3 !px-8 !bg-[#FFB902] !text-black"}
                            disabled={true}
                            isLoading={true}
                        />
                    ) : (
                        <BlobProvider
                            document={
                                <AnalyticsPdf
                                    year={year}
                                    title={"RFQs Analytics"}
                                    chartName={"Purchase Order"}
                                    businessLogo={profile?.profile?.avatar}
                                    chart={chartImage || null}
                                    showPo={exportOpt === "ppt-rfq" ? null : true}
                                    chartValue1={rfqChartValue}
                                    chartValue2={fulfilledChartValue}
                                    rfqDetail={
                                        purchaseOrder?.requisition >= 0
                                            ? `${purchaseOrder?.requisition}%`
                                            : "--"
                                    }
                                    summary={summary || null}
                                    summaryDuration={summaryDuration}
                                    dateRange={dateRange}
                                    showDateRange={showDateRange}
                                    pendingPO={
                                        purchaseOrderData?.[0]?.data?.purchaseOrders?.length > 0
                                            ? purchaseOrderData?.[0]?.data?.purchaseOrders
                                            : null
                                    }
                                    approvedPO={
                                        purchaseOrderData?.[1]?.data?.purchaseOrders?.length > 0
                                            ? purchaseOrderData?.[1]?.data?.purchaseOrders
                                            : null
                                    }
                                    rejected={
                                        purchaseOrderData?.[2]?.data?.purchaseOrders?.length > 0
                                            ? purchaseOrderData?.[2]?.data?.purchaseOrders
                                            : null
                                    }
                                />
                            }
                        >
                            {({ blob, loading }) => {
                                setGeneratedBlob(blob);
                                return !loading && generatedBlob ? (
                                    <Button
                                        btnText={"Export"}
                                        btnClassname={
                                            "!w-fit !py-3 !px-6 !bg-[#FFB902] !text-black"
                                        }
                                        onClick={() => getBlobImages(generatedBlob)}
                                    />
                                ) : (
                                    <Button
                                        btnClassname={
                                            "!w-fit !py-3 !px-8 !bg-[#FFB902] !text-black"
                                        }
                                        isLoading={exportOpt === "ppt" ? false : true}
                                    />
                                );
                            }}
                        </BlobProvider>
                    )}
                </div>
            ),
            children: [
                {
                    label: (
                        <div
                            className=" text-gm-50 font-rocGroteskMedium text-base flex justify-between items-center
                                                border-b border-b-slate-100 !w-full !px-6 !pt-4 !pb-3
                                                !hover:bg-[#fff]  mb-[15px]"
                        >
                            Export Options
                        </div>
                    ),
                    className: "hover:bg-transparent cursor-default !px-0",
                },
                {
                    label: (
                        <div className=" text-gm-50 font-rocGroteskMedium text-sm flex justify-between items-center px-6 py-3 gap-3">
                            {exportOpt === "ppt-all" ? (
                                <>
                                    <i className="ri-radio-button-line text-[#7895FF] text-base"></i>
                                </>
                            ) : (
                                <>
                                    <i className="ri-circle-line text-[#7895FF] text-base"></i>
                                </>
                            )}
                            Export this entire page
                        </div>
                    ),
                    onClick: () => {
                        setExportOpt("ppt-all");
                        captureChart();
                    },
                },
                {
                    label: (
                        <div className=" text-gm-50 font-rocGroteskMedium text-sm flex justify-between items-center px-6 py-3 gap-3">
                            {exportOpt === "ppt-po" ? (
                                <>
                                    <i className="ri-radio-button-line text-[#7895FF] text-base"></i>
                                </>
                            ) : (
                                <>
                                    <i className="ri-circle-line text-[#7895FF] text-base"></i>
                                </>
                            )}
                            Export purchase order analytics alone
                        </div>
                    ),
                    onClick: () => {
                        setExportOpt("ppt-po");
                        setChartImage("");
                    },
                },
                {
                    label: (
                        <div className=" text-gm-50 font-rocGroteskMedium text-sm flex justify-between items-center px-6 py-3 gap-3 ">
                            {exportOpt === "ppt-rfq" ? (
                                <>
                                    <i className="ri-radio-button-line text-[#7895FF] text-base"></i>
                                </>
                            ) : (
                                <>
                                    <i className="ri-circle-line text-[#7895FF] text-base"></i>
                                </>
                            )}
                            Export RFQ analytics alone
                        </div>
                    ),
                    onClick: () => {
                        setExportOpt("ppt-rfq");
                        captureChart();
                    },
                },
            ],
        },
        {
            label: (
                <div
                    className=" text-gm-50 font-rocGroteskMedium text-[13px] flex gap-3
                                                border-b border-b-slate-100 !w-[192px] px-[10px] py-[10px]"
                >
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700580775/pdf-document_kqjkit.svg"
                        alt="pdf icon"
                        className="w-3"
                    />
                    Export as PDF
                </div>
            ),
            onClick: () => {
                setExportOpt("pdf");
            },
            actionButtons: (
                <div className="flex justify-end mt-[20px] px-4 mb-3 gap-3 relative !z-[999]">
                    {exportOpt === "pdf" ? (
                        <Button
                            btnClassname={"!w-fit !py-3 !px-6 !bg-[#FFB902] !text-black"}
                            disabled={true}
                            btnText={"Export"}
                        />
                    ) : captureChartLoading ? (
                        <Button
                            btnClassname={"!w-fit !py-3 !px-8 !bg-[#FFB902] !text-black"}
                            disabled={true}
                            isLoading={true}
                        />
                    ) : (
                        <PDFDownloadLink
                            document={
                                <AnalyticsPdf
                                    year={year}
                                    title={"RFQs Analytics"}
                                    chartName={"Purchase Order"}
                                    businessLogo={profile?.profile?.avatar}
                                    chart={chartImage || null}
                                    showPo={exportOpt === "pdf-rfq" ? null : true}
                                    chartValue1={rfqChartValue}
                                    chartValue2={fulfilledChartValue}
                                    rfqDetail={
                                        purchaseOrder?.requisition
                                            ? `${purchaseOrder?.requisition}%`
                                            : "--"
                                    }
                                    summary={summary || null}
                                    summaryDuration={summaryDuration}
                                    dateRange={dateRange}
                                    showDateRange={showDateRange}
                                    pendingPO={
                                        purchaseOrderData?.[0]?.data?.purchaseOrders?.length > 0
                                            ? purchaseOrderData?.[0]?.data?.purchaseOrders
                                            : null
                                    }
                                    approvedPO={
                                        purchaseOrderData?.[1]?.data?.purchaseOrders?.length > 0
                                            ? purchaseOrderData?.[1]?.data?.purchaseOrders
                                            : null
                                    }
                                    rejected={
                                        purchaseOrderData?.[2]?.data?.purchaseOrders?.length > 0
                                            ? purchaseOrderData?.[2]?.data?.purchaseOrders
                                            : null
                                    }
                                />
                            }
                            fileName={"purchase_analysis.pdf"}
                        >
                            {({ loading }) => (
                                <>
                                    {loading ? (
                                        <Button
                                            btnClassname={
                                                "!w-fit !py-3 !px-8 !bg-[#FFB902] !text-black"
                                            }
                                            isLoading={true}
                                        />
                                    ) : (
                                        <Button
                                            btnText={"Export"}
                                            btnClassname={
                                                "!w-fit !py-3 !px-6 !bg-[#FFB902] !text-black"
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </PDFDownloadLink>
                    )}
                </div>
            ),
            children: [
                {
                    label: (
                        <div
                            className=" text-gm-50 font-rocGroteskMedium text-base flex justify-between items-center
                                                border-b border-b-slate-100 !w-full !px-6 !pt-4 !pb-3
                                                !hover:bg-[#fff]  mb-[15px]"
                        >
                            Export Options
                        </div>
                    ),
                    className: "hover:bg-transparent cursor-default !px-0",
                },
                {
                    label: (
                        <div className=" text-gm-50 font-rocGroteskMedium text-sm flex justify-between items-center px-6 py-3 gap-3">
                            {exportOpt === "pdf-all" ? (
                                <>
                                    <i className="ri-radio-button-line text-[#7895FF] text-base"></i>
                                </>
                            ) : (
                                <>
                                    <i className="ri-circle-line text-[#7895FF] text-base"></i>
                                </>
                            )}
                            Export this entire page
                        </div>
                    ),
                    onClick: () => {
                        setExportOpt("pdf-all");
                        captureChart();
                    },
                },
                {
                    label: (
                        <div className=" text-gm-50 font-rocGroteskMedium text-sm flex justify-between items-center px-6 py-3 gap-3">
                            {exportOpt === "pdf-po" ? (
                                <>
                                    <i className="ri-radio-button-line text-[#7895FF] text-base"></i>
                                </>
                            ) : (
                                <>
                                    <i className="ri-circle-line text-[#7895FF] text-base"></i>
                                </>
                            )}
                            Export purchase order analytics alone
                        </div>
                    ),
                    onClick: () => {
                        setExportOpt("pdf-po");
                        setChartImage("");
                    },
                },
                {
                    label: (
                        <div className=" text-gm-50 font-rocGroteskMedium text-sm flex justify-between items-center px-6 py-3 gap-3 ">
                            {exportOpt === "pdf-rfq" ? (
                                <>
                                    <i className="ri-radio-button-line text-[#7895FF] text-base"></i>
                                </>
                            ) : (
                                <>
                                    <i className="ri-circle-line text-[#7895FF] text-base"></i>
                                </>
                            )}
                            Export RFQ analytics alone
                        </div>
                    ),
                    onClick: () => {
                        setExportOpt("pdf-rfq");
                        captureChart();
                    },
                },
            ],
        },
    ];

    return (
        <PageFrame containerClassName={"h-[calc(100vh-84px)] "}>
            {shareModal && (
                <ShareModal openModal={shareModal} closeModal={() => setShareModal(false)} />
            )}

            <div className="px-[14px]">
                <div className="mb-[33px] flex item-center justify-between ">
                    {noData ? (
                        <BreadCrumb
                            parentCrumb="Business analytics"
                            size="large"
                            parentClassName="!text-slate-900 !text-xl "
                        />
                    ) : (
                        <BreadCrumb
                            parentCrumb="Business analytics"
                            size="large"
                            parentClassName="!text-slate-400 !text-xl "
                            childrenCrumb={[
                                {
                                    title: "Purchase Order",
                                    path: "/dashboard/analytics/purchase-orders",
                                },
                            ]}
                            childClassName="!text-lg !text-slate-900"
                        />
                    )}
                </div>

                {noData ? (
                    <div className="flex items-center justify-center gap-10 mt-[150px]">
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1701768395/analytics_fnnp21.svg"
                            alt="graph"
                        />
                        <div className="max-w-[451px]">
                            <h5 className="text-xl font-rocGroteskMedium text-slate-800 mb-[14px]">
                                Business analytics
                            </h5>
                            <p className="text-slate-500 text-sm leading-6">
                                Through the business analytics page, you are able to gain quality
                                insight into how well your business operations is performing. Here,
                                TradeAlly aggregates your business performance into
                                easy-to-understand data visualization. You can also export these
                                reports for your presentations whenever you need them. The data
                                would show up here when you start transacting with your suppliers.
                            </p>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="flex items-center justify-between">
                            <div>
                                <p className="text-slate-900  font-rocGroteskMedium text-lg">
                                    Purchase order summary
                                </p>
                            </div>

                            <div className="flex gap-[14px] items-center">
                                <div className="relative">
                                    <Dropdown
                                        handleChange={(name, value) =>
                                            handleSummaryFilter({ target: { name, value } })
                                        }
                                        dropdown={
                                            <div
                                                className="flex items-center rounded-[5px] shadow-[0_1px_2px_0_rgba(91,104,113,0.15)]
                                             py-2 px-[15px] gap-2"
                                            >
                                                <i className="ri-filter-3-line text-lg text-[#142837]"></i>

                                                <div
                                                    className={`text-slate-800 font-rocGroteskMedium text-[13px] min-w-[52px]`}
                                                >
                                                    {Boolean(showDateRange)
                                                        ? dateRange
                                                        : summaryFilter}
                                                </div>
                                            </div>
                                        }
                                        disabled={
                                            gettingPurchaseOrder || gettingPurchaseOrderSummary
                                        }
                                        dropdownItemsClasses={`!px-0 !py-0`}
                                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                        dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100  rounded !max-h-fit !w-auto
                            `}
                                        dropdownOptions={[
                                            ...summaryFilterValues?.map((item, idx) => ({
                                                label: (
                                                    <div
                                                        key={idx}
                                                        className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                                border-b border-b-slate-100 !w-[159px] px-4 py-[10px]"
                                                    >
                                                        {item}
                                                        {idx ===
                                                            summaryFilterValues?.length - 1 && (
                                                            <i className="ri-arrow-right-s-line text-gm-50 text-xs"></i>
                                                        )}
                                                    </div>
                                                ),
                                                value: item,
                                            })),
                                        ]}
                                    />
                                    {showCustomDate && (
                                        <div
                                            ref={customRef}
                                            className={`w-[630px]  z-[10000] absolute top-16 px-[28px] pt-8 pb-8 bg-white
                                             rounded-2xl shadow-[0px_0px_4px_rgba(0,0,0,0.04),0px_4px_32px_rgba(0,0,0,0.06)] -right-40`}
                                        >
                                            <CustomCalendar
                                                showDoubleView={true}
                                                value={customValue}
                                                onChange={customOnChange}
                                            />
                                            <div className="flex justify-end">
                                                <Button
                                                    btnText={"Close"}
                                                    btnClassname={"!w-fit !py-2"}
                                                    onClick={() => setShowCustomDate(false)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <MultipleDropdowns
                                    containerClassName={`!p-0`}
                                    hideIcon={true}
                                    optionClassName={`!px-0 !py-0`}
                                    dropdown={
                                        <div className="flex items-center rounded-[5px] shadow-boxShadow-2 !py-2 !px-[15px] gap-2">
                                            <i className="ri-expand-right-line text-lg text-[#142837]"></i>
                                            <div
                                                className={`text-slate-800 font-rocGroteskMedium text-[13px]`}
                                            >
                                                Export
                                            </div>
                                        </div>
                                    }
                                    disabled={gettingPurchaseOrder || gettingPurchaseOrderSummary}
                                    options={dropOptions}
                                    className={` !rounded-[5px] !shadow-boxShadow-2 !py-2 !px-0 !right-0`}
                                    childClassName={` !rounded-[5px] !shadow-boxShadow-2 !py-2 !px-0 !w-[370px] !right-[200px]`}
                                    relativeParent={`relative !z-[1000]`}
                                    permissions={getEditPermissionList("analytics")}
                                />

                                <div>
                                    {/* <Button
                                        btnText={"Share"}
                                        type={"button"}
                                        icon={<i className="ri-share-forward-line text-base"></i>}
                                        btnClassname="!py-2 !text-[13px] !shadow-boxShadow-2 !rounded !bg-white !border !border-n-20 !text-gm-50 
                                        !whitespace-nowrap !px-4"
                                        onClick={() => setShareModal(true)}
                                    /> */}
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-4 gap-3 mt-[43px] mb-[61px]">
                            {renderAnalyticsCards()}
                        </div>
                        <div
                            className="rounded-[15px] shadow-[1px_-6px_15px_0px_rgba(0,0,0,0.02),-1px_6px_15px_0px_rgba(0,0,0,0.02)]
            pt-6 pb-[37px] pl-[25px] pr-[30px]"
                        >
                            {showCustomDateTwo && (
                                <div
                                    ref={customRef}
                                    className="w-[630px]  z-[10000] absolute  right-20 top-24 px-[28px] pt-8 pb-8 bg-white rounded-2xl shadow-[0px_0px_4px_rgba(0,0,0,0.04),0px_4px_32px_rgba(0,0,0,0.06)]"
                                >
                                    <CustomCalendar
                                        showDoubleView={true}
                                        value={customValueTwo}
                                        onChange={customOnChangeTwo}
                                    />
                                    <div className="flex justify-end">
                                        <Button
                                            btnText={"Close"}
                                            btnClassname={"!w-fit !py-2"}
                                            onClick={() => setShowCustomDateTwo(false)}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="flex justify-between items-center">
                                <div className="flex gap-[22px] items-center">
                                    <p className="text-slate-900 font-rocGroteskMedium text-lg">
                                        RFQs Analytics
                                    </p>
                                    <div className="rounded py-2 px-[10px] border-n-50 border flex items-center font-rocGroteskMedium">
                                        <i className="ri-information-fill text-slate-300"></i>
                                        <p className="pl-[3px] pr-2 text-sm">
                                            Requisition to Fulfilled Order ratio:
                                        </p>
                                        <p className="text-[#142837] text-lg">
                                            {purchaseOrder?.requisition >= 0 ? (
                                                <>{purchaseOrder?.requisition}%</>
                                            ) : (
                                                "--"
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex gap-[14px] ">
                                    {/* <div>
                                        <Button
                                            btnText={"Share"}
                                            type={"button"}
                                            icon={
                                                <i className="ri-share-forward-line text-base"></i>
                                            }
                                            btnClassname="!py-2 !text-[13px] !shadow-boxShadow-2 !rounded !bg-white !border !border-n-20 !text-gm-50 !whitespace-nowrap !px-4"
                                            onClick={() => setShareModal(true)}
                                        />
                                    </div> */}
                                </div>
                            </div>

                            <div className="flex gap-0 mt-10 items-end pb-4" ref={chartRef}>
                                <div className="w-[50%] border-r border-r-[rgb(226,232,240)]">
                                    <p className="text-slate-950 font-rocGroteskMedium text-base">
                                        RFQs{" "}
                                    </p>
                                    {gettingPurchaseOrder ? (
                                        <div className="flex items-center justify-center  h-[400px]">
                                            <Loader size={4} />
                                        </div>
                                    ) : rfqChart?.x?.length > 0 && !noRfqChartData ? (
                                        <ReactEcharts
                                            option={rfq}
                                            style={{
                                                height: "400px",
                                                width: "100% !important",
                                            }}
                                        />
                                    ) : (
                                        !gettingPurchaseOrder &&
                                        noRfqChartData && (
                                            <div className="w-full h-[400px] flex justify-center items-center flex-col">
                                                <img
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1701863824/graph_s30b7n.svg"
                                                    alt="no data"
                                                />
                                                <p className="text-gm-40 text-xs font-rocGroteskMedium mt-[19px]">
                                                    No data to show yet{" "}
                                                </p>
                                            </div>
                                        )
                                    )}
                                </div>
                                <div className="w-[50%]">
                                    <p className="text-slate-950 font-rocGroteskMedium text-base ml-3">
                                        Fulfilled Orders
                                    </p>
                                    {gettingPurchaseOrder ? (
                                        <div className="flex items-center justify-center h-[400px]">
                                            {" "}
                                            <Loader size={4} />
                                        </div>
                                    ) : fulfilledChart?.x?.length > 0 && !noFulfilledChartData ? (
                                        <ReactEcharts
                                            option={fulfilled}
                                            style={{
                                                height: "400px",
                                                width: "100% !important",
                                            }}
                                        />
                                    ) : (
                                        !gettingPurchaseOrder &&
                                        noFulfilledChartData && (
                                            <div className="w-full h-[400px] flex justify-center items-center flex-col">
                                                <img
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1701863824/graph_s30b7n.svg"
                                                    alt="no data"
                                                />
                                                <p className="text-gm-40 text-xs font-rocGroteskMedium mt-[19px]">
                                                    No data to show yet{" "}
                                                </p>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </PageFrame>
    );
};

export default PurchaseOrder;
