import React, { useEffect, useState } from "react";
import TextInput from "./InputField/TextInput";
import SelectInput from "./InputField/SelectInput";
import Button from "./Button";
import customFieldsOption from "variables/customFieldsOption";
import moment from "moment";
import ModalContainer from "./ModalContainer";
import { firebaseService } from "services/firebaseService";
import useGetUserProfile from "hooks/useGetUserProfile";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { customFieldActions } from "redux/Ldb/actions";
import CustomFields from "./CustomFields";

type customFieldProps = {
    openModal?: boolean;
    closeModal?: () => void;
    fieldType: string;
    fieldName: string;
    recurringSetting?: boolean;
    editCustomFieldIdx?: number;
    setFieldType: any;
    setFieldName: any;
    setRecurringSetting: any;
    setEditCustomFieldIdx: any;
    multipleValuesField: any;
    singleValueField: any;
    customAttachments: any;
    setMultipleValuesField: any;
    setSingleValueField: any;
    setCustomAttachments: any;
    openDeleteModal?: () => void;
    setDeleteIdx?: any;
    customFieldArray: any;
    setCustomFieldArray: any;
    savedRecurringFieldId?: string;
    showDeleteButton: boolean;
    pageEdit?: boolean;
};

const CustomFieldsImplement = ({
    openModal,
    closeModal,
    fieldType,
    fieldName,
    recurringSetting,
    editCustomFieldIdx,
    setFieldType,
    setFieldName,
    setRecurringSetting,
    setEditCustomFieldIdx,
    multipleValuesField,
    singleValueField,
    customAttachments,
    setMultipleValuesField,
    setSingleValueField,
    setCustomAttachments,
    openDeleteModal,
    setDeleteIdx,
    customFieldArray,
    setCustomFieldArray,
    savedRecurringFieldId,
    showDeleteButton,
    pageEdit,
}: customFieldProps) => {
    const [profile] = useGetUserProfile();
    const dispatch = useAppDispatch();
    const customFieldType = "stockCount";
    const [customDate, onChangeCustomDate] = useState<string | Date>();
    const [changeFieldType, setChangeFieldType] = useState<boolean>(false);
    const [uploadingCustomAttachment, setUploadingCustomAttachment] = useState<boolean>(false);
    const [recurringFieldData, setRecurringFieldData] = useState<any>();
    const [deletingImage, setDeletingImage] = useState<boolean>(true);

    const {
        creatingCustomField,
        createCustomFieldSuccess,
        updateCustomFieldSuccess,
        updatingCustomField,
    } = useAppSelector((state) => state.customField);

    useEffect(() => {
        if (Boolean(createCustomFieldSuccess)) {
            addToCustomArray();
            dispatch(customFieldActions.resetCreateCustomField());
            setRecurringFieldData("");
            closeModal();
        }
    }, [createCustomFieldSuccess]);

    useEffect(() => {
        if (Boolean(updateCustomFieldSuccess)) {
            addToCustomArray();
            dispatch(customFieldActions.resetUpdateCustomField());
            setRecurringFieldData("");
            closeModal();
        }
    }, [updateCustomFieldSuccess]);

    useEffect(() => {
        let formatData;
        if (recurringSetting) {
            if (fieldType === "text" || fieldType === "multiline") {
                formatData = {
                    fieldName: singleValueField?.name,
                    formType: customFieldType,
                    fieldType: singleValueField?.fieldType,
                    placeholder: singleValueField?.placeholder,
                };
            } else if (
                fieldType === "checkbox" ||
                fieldType === "radio" ||
                fieldType === "dropDown"
            ) {
                if (multipleValuesField) {
                    let newArr = [...multipleValuesField?.value];
                    const formattedArray = newArr?.map((item) => ({
                        name: item.value,
                        label: item.value,
                    }));
                    formatData = {
                        fieldName: multipleValuesField?.name,
                        formType: customFieldType,
                        fieldType: fieldType === "dropDown" ? "dropdown" : fieldType,
                        options: formattedArray,
                    };
                }
            } else if (fieldType === "currency") {
                formatData = {
                    fieldName: singleValueField?.name,
                    formType: customFieldType,
                    fieldType: singleValueField?.fieldType,
                    currency: singleValueField?.currency,
                };
            } else if (fieldType === "file") {
                formatData = {
                    fieldName: customAttachments?.name,
                    formType: customFieldType,
                    fieldType: customAttachments?.fieldType,
                };
            } else {
                formatData = {
                    fieldName: singleValueField?.name,
                    formType: customFieldType,
                    fieldType: singleValueField?.fieldType,
                };
            }

            setRecurringFieldData(formatData);
        } else {
            setRecurringFieldData("");
        }
    }, [customAttachments, singleValueField, multipleValuesField]);

    const handleFieldName = (e) => {
        setFieldName(e.target.value);
    };

    useEffect(() => {
        setMultipleValuesField((prev) => ({
            ...prev,
            recurringSetting,
        }));
        setSingleValueField((prev) => ({
            ...prev,
            recurringSetting,
        }));
        setCustomAttachments((prev) => ({
            ...prev,
            recurringSetting,
        }));
    }, [recurringSetting]);

    const deleteCustomAttachment = (idx) => {
        const newArr = [...customAttachments?.value];

        const imagePath = decodeURIComponent(
            customAttachments?.value[idx]?.path?.split("/o/")?.[1]?.split("?alt")?.[0]
        );

        setDeletingImage(true);
        firebaseService.removeFile(imagePath, () => {
            newArr?.splice(idx, 1);
            setCustomAttachments(() => ({
                fieldType,
                name: fieldName,
                value: newArr,
            }));
            setDeletingImage(false);
        });
    };

    const handleCustomAttachmentUpload = (fileName: string, file: File) => {
        if (fileName && file) {
            setUploadingCustomAttachment(true);
            firebaseService.uploadFile(
                `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/customFields/${
                    profile?.profile?.businessName
                }/createdByDocument/${fileName}`,
                file,
                (url) => {
                    setCustomAttachments((prev) => {
                        return {
                            fieldType,
                            name: fieldName,

                            value: [...prev?.value, { path: url, filename: fileName }],
                        };
                    });
                    setUploadingCustomAttachment(false);
                }
            );
        }
    };

    const handleCustomFieldData = (e) => {
        const { name, value } = e.target;
        if (name === "currency" || name === "amount") {
            setSingleValueField((prev) => ({
                ...prev,
                [name]: value,
                fieldType: fieldType,
                name: fieldName,
                recurringSetting,
            }));
        } else if (name === "mobileExtension" || name === "mobile") {
            setSingleValueField((prev) => ({
                ...prev,
                [name]: value,
                fieldType: fieldType,
                name: fieldName,
                recurringSetting,
            }));
        } else {
            if (name === "placeholder") {
                setSingleValueField((prev) => ({
                    ...prev,
                    placeholder: value,
                }));
            } else {
                setSingleValueField((prev) => ({
                    ...prev,
                    [name]: value,
                    fieldType: fieldType,
                    name: fieldName,
                    value: value,
                    recurringSetting,
                }));
            }
        }
    };
    useEffect(() => {
        setSingleValueField({
            fieldType: fieldType,
            name: fieldName,
            recurringSetting,
            value: moment(customDate).toISOString(),
            withValue: true,
        });
    }, [customDate]);

    const handleSelect = (type, clickedId, value) => {
        let updateMultipleValues = [...multipleValuesField.value];

        if (type === "check") {
            updateMultipleValues[clickedId] = {
                ...updateMultipleValues[clickedId],
                selected: !updateMultipleValues[clickedId]?.selected,
            };

            setMultipleValuesField({
                name: fieldName,
                fieldType: multipleValuesField?.fieldType,
                value: updateMultipleValues,
                recurringSetting: multipleValuesField?.recurringSetting,
            });
        } else if (type === "radio") {
            const radioSelect = updateMultipleValues?.map((item, idx) => ({
                ...item,
                selected: idx === clickedId,
            }));
            setMultipleValuesField({
                name: fieldName,
                fieldType: multipleValuesField?.fieldType,
                value: radioSelect,
                recurringSetting: multipleValuesField?.recurringSetting,
            });
        } else {
            updateMultipleValues[clickedId] = {
                ...updateMultipleValues[clickedId],
                value,
            };

            setMultipleValuesField({
                name: fieldName,
                fieldType: multipleValuesField?.fieldType,
                value: updateMultipleValues,
                recurringSetting: multipleValuesField?.recurringSetting,
            });
        }
    };

    const addMoreOption = () => {
        let updateMultipleValues = [...multipleValuesField.value];

        updateMultipleValues.push({
            selected: false,
            value: "",
        });

        setMultipleValuesField((prev) => ({
            ...prev,
            value: updateMultipleValues,
        }));
    };

    const handleDelete = (idx) => {
        let updateMultipleValues = [...multipleValuesField?.value];
        updateMultipleValues.splice(idx, 1);
        setMultipleValuesField((prev) => ({
            ...prev,
            value: updateMultipleValues,
        }));
    };

    useEffect(() => {
        if (!openModal) {
            setMultipleValuesField({
                value: [
                    {
                        selected: true,
                        value: "",
                    },
                ],
            });
            setSingleValueField("");
            setChangeFieldType(false);
        }
    }, [openModal]);

    const createCustomField = () => {
        dispatch(customFieldActions.createCustomField(recurringFieldData));
    };
    const handleEditCustomField = () => {
        const { formType, fieldType, ...updateRecurringFieldData } = recurringFieldData;
        dispatch(
            customFieldActions.updateCustomField(savedRecurringFieldId, updateRecurringFieldData)
        );
    };

    const addToCustomArray = () => {
        closeModal();
        const newObj = [...customFieldArray];

        if (editCustomFieldIdx >= 0) {
            if (fieldType === "checkbox" || fieldType === "radio" || fieldType === "dropDown") {
                newObj.splice(editCustomFieldIdx, 1, { withValue: true, ...multipleValuesField });
            } else if (fieldType === "file") {
                newObj.splice(editCustomFieldIdx, 1, { withValue: true, ...customAttachments });
            } else {
                newObj.splice(editCustomFieldIdx, 1, { withValue: true, ...singleValueField });
            }
        } else {
            if (fieldType === "checkbox" || fieldType === "radio" || fieldType === "dropDown") {
                let value = [...multipleValuesField?.value];
                newObj?.push({
                    value: value,
                    name: fieldName,
                    fieldType,
                    recurringSetting: recurringSetting,
                    withValue: true,
                });
            } else if (fieldType === "file") {
                let value = [...customAttachments?.value];
                newObj?.push({
                    value,
                    name: fieldName,
                    fieldType,
                    recurringSetting: recurringSetting,
                    withValue: true,
                });
            } else if (fieldType === "date") {
                newObj?.push({
                    value: moment(customDate).toISOString(),
                    name: fieldName,
                    fieldType,
                    recurringSetting: recurringSetting,
                    withValue: true,
                });
            } else {
                newObj?.push({
                    ...singleValueField,
                    name: fieldName,
                    recurringSetting: recurringSetting,
                    withValue: true,
                });
            }
        }

        setCustomFieldArray(newObj);
    };
    useEffect(() => {
        if (singleValueField?.fieldType) {
            setSingleValueField((prev) => ({
                ...prev,
                name: fieldName,
            }));
        } else if (multipleValuesField?.fieldType) {
            setMultipleValuesField((prev) => ({
                ...prev,
                name: fieldName,
            }));
        } else {
            setCustomAttachments((prev) => ({
                ...prev,
                name: fieldName,
            }));
        }
    }, [fieldName]);

    useEffect(() => {
        if (editCustomFieldIdx >= 0 && changeFieldType) {
            const newData = {
                fieldType,
                name:
                    singleValueField?.name ??
                    multipleValuesField?.name ??
                    customAttachments?.name ??
                    "",
                recurringSetting:
                    singleValueField?.recurringSetting ??
                    multipleValuesField?.recurringSetting ??
                    customAttachments?.recurringSetting ??
                    "",
                withValue:
                    singleValueField?.withValue ??
                    multipleValuesField?.withValue ??
                    customAttachments?.withValue ??
                    "",
            };
            if (fieldType === "checkbox" || fieldType === "radio" || fieldType === "dropDown") {
                setMultipleValuesField({
                    ...newData,
                    value: [
                        {
                            selected: true,
                            value: "",
                        },
                    ],
                });
                setSingleValueField("");
                setCustomAttachments({
                    value: [],
                });
            } else if (fieldType === "file") {
                setCustomAttachments({
                    ...newData,
                    value: [],
                });
                setSingleValueField("");
                setMultipleValuesField({
                    value: [
                        {
                            selected: true,
                            value: "",
                        },
                    ],
                });
            } else {
                setSingleValueField(newData);
                setMultipleValuesField({
                    value: [
                        {
                            selected: true,
                            value: "",
                        },
                    ],
                });
                setCustomAttachments({
                    value: [],
                });
            }
        } else if (editCustomFieldIdx < 0) {
            setMultipleValuesField({
                value: [
                    {
                        selected: true,
                        value: "",
                    },
                ],
            });
            setSingleValueField("");
            setCustomAttachments({
                value: [],
            });
        }
    }, [fieldType]);

    const selectedOption = (fieldType) => {
        const selectedOptionString = customFieldsOption.find((item) => item?.value === fieldType);
        return selectedOptionString ? selectedOptionString?.type : "";
    };

    return (
        <div>
            <ModalContainer
                open={openModal}
                tailwindClassName="w-[42%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (savedRecurringFieldId && editCustomFieldIdx >= 0) {
                            handleEditCustomField();
                        } else if (recurringSetting) {
                            createCustomField();
                        } else {
                            addToCustomArray();
                        }
                    }}
                    className=""
                >
                    <div className="bg-white rounded-lg shadow-cardShadow relative text-gm-50 font-rocGroteskMedium ">
                        <div className=" px-[22px] py-4 border-b border-b-slate-200">
                            <p className="text-base text-center">
                                {editCustomFieldIdx >= 0 ? "Edit custom field" : "New custom field"}
                            </p>
                            <i
                                className="ri-close-line text-2xl absolute right-5 top-2 cursor-pointer"
                                onClick={() => {
                                    closeModal();
                                    setFieldType("");
                                }}
                            ></i>
                        </div>
                        <div
                            className={`${fieldType !== "date" && "overflow-y-auto max-h-[70vh]"}`}
                        >
                            <div className="px-6 py-6 flex flex-col gap-6">
                                <TextInput
                                    value={fieldName || ""}
                                    name={"fieldName"}
                                    type={"text"}
                                    onChange={handleFieldName}
                                    placeholder={"Field Name"}
                                    required
                                    inputContainerClassname={`${
                                        fieldName && "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    }`}
                                />
                                <SelectInput
                                    value={fieldType}
                                    name="fieldType"
                                    placeholder={"Field Type"}
                                    handleChange={(name, value) => {
                                        setFieldType(value);
                                        setChangeFieldType(true);
                                    }}
                                    className={`${
                                        fieldType && "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    }`}
                                    isRequired={true}
                                    disabled={pageEdit || editCustomFieldIdx >= 0}
                                    dropdownOptions={customFieldsOption?.map((item) => ({
                                        label: item?.type,
                                        value: item?.value,
                                        icon: (
                                            <span className="text-gm-50 text-base">
                                                <i className={item?.icon}> </i>{" "}
                                            </span>
                                        ),
                                        subText: item?.subText,
                                    }))}
                                />
                            </div>
                            <div className="px-6  pt-5 pb-3 !font-rocGroteskMedium w-full ">
                                <hr className="bg-slate-200" />
                                <div className="flex justify-center !-mt-5">
                                    <p className="!w-auto !bg-white !px-4 !py-[6px] text-slate-500 ">
                                        Configure {selectedOption(fieldType)}
                                    </p>
                                </div>
                            </div>

                            <div className="px-6 ">
                                <CustomFields
                                    fieldType={fieldType}
                                    handleCustomFieldData={handleCustomFieldData}
                                    singleValueField={singleValueField}
                                    fieldName={fieldName}
                                    multipleValuesField={multipleValuesField}
                                    addMoreOption={addMoreOption}
                                    handleSelect={handleSelect}
                                    handleDelete={handleDelete}
                                    customAttachments={customAttachments}
                                    customDate={customDate}
                                    onChangeCustomDate={onChangeCustomDate}
                                    handleCustomAttachmentUpload={handleCustomAttachmentUpload}
                                    uploadingCustomAttachment={uploadingCustomAttachment}
                                    openModal={openModal}
                                    recurringSetting={recurringSetting}
                                    deleteCustomAttachment={deleteCustomAttachment}
                                    deletingImage={deletingImage}
                                />
                            </div>
                            <div
                                className={`${
                                    !editCustomFieldIdx ? "text-gm-50" : "text-slate-400"
                                } px-6 py-6`}
                            >
                                <div className="border-t border-t-slate-200 pt-6">
                                    <div className="flex text-sm flex-col">
                                        <div className="flex items-center gap-2">
                                            <i
                                                className={`${
                                                    !recurringSetting
                                                        ? "ri-radio-button-line text-[#16A34A] "
                                                        : "ri-circle-line text-slate-400"
                                                }   text-xl rounded ${
                                                    editCustomFieldIdx < 0
                                                        ? "cursor-pointer"
                                                        : "text-slate-400"
                                                }`}
                                                onClick={() =>
                                                    editCustomFieldIdx < 0 &&
                                                    setRecurringSetting(false)
                                                }
                                            ></i>

                                            <p
                                                className={`${
                                                    editCustomFieldIdx >= 0
                                                        ? "text-slate-400"
                                                        : "text-gm-50"
                                                } `}
                                            >
                                                One-off Custom Field
                                            </p>
                                        </div>
                                        <p className="text-slate-400">
                                            Capture unique details for a specific record.
                                        </p>
                                    </div>
                                    <div className="flex text-sm flex-col pt-3">
                                        <div className="flex items-center gap-2">
                                            <i
                                                className={`${
                                                    recurringSetting
                                                        ? "ri-radio-button-line text-[#16A34A]"
                                                        : "ri-circle-line text-slate-400"
                                                }   text-xl rounded ${
                                                    editCustomFieldIdx < 0
                                                        ? "cursor-pointer"
                                                        : "text-slate-400"
                                                }`}
                                                onClick={() =>
                                                    editCustomFieldIdx < 0 &&
                                                    setRecurringSetting(true)
                                                }
                                            ></i>

                                            <p
                                                className={`${
                                                    editCustomFieldIdx >= 0
                                                        ? "text-slate-400"
                                                        : "text-gm-50"
                                                } `}
                                            >
                                                Recurring Custom Field
                                            </p>
                                        </div>
                                        <p className="text-slate-400">
                                            Apply the same information across similar records for
                                            consistency.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between py-5 px-6 border-t border-t-slate-200 w-full">
                            {editCustomFieldIdx >= 0 && showDeleteButton && (
                                <div className="w-[150px]">
                                    <Button
                                        btnText={"Delete field"}
                                        type={"button"}
                                        btnClassname="!py-2 !px-4 !border-[#DC2626] !border !bg-transparent !w-fit !text-[#DC2626]"
                                        onClick={() => {
                                            openDeleteModal();
                                            setDeleteIdx(editCustomFieldIdx);
                                        }}
                                    />
                                </div>
                            )}
                            <div
                                className={`w-full flex items-center ${
                                    editCustomFieldIdx >= 0 && "justify-end"
                                } `}
                            >
                                <div
                                    className={`flex items-center gap-2 ${
                                        editCustomFieldIdx >= 0 ? "" : "w-full"
                                    }`}
                                >
                                    <div className={` ${editCustomFieldIdx >= 0 ? "" : "w-full"} `}>
                                        <Button
                                            btnText={"Cancel"}
                                            type={"button"}
                                            btnClassname="!py-[10px] !px-4 !border-none !bg-n-20"
                                            onClick={() => {
                                                closeModal();
                                                setFieldType("");
                                            }}
                                        />
                                    </div>
                                    <div className={` ${editCustomFieldIdx >= 0 ? "" : "w-full"} `}>
                                        <Button
                                            btnText={
                                                editCustomFieldIdx >= 0
                                                    ? "Save edit"
                                                    : "Add custom field"
                                            }
                                            btnClassname={`!py-[10px] !px-4 ${
                                                creatingCustomField && "py-3"
                                            } `}
                                            type={"submit"}
                                            isLoading={creatingCustomField || updatingCustomField}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ModalContainer>
        </div>
    );
};

export default CustomFieldsImplement;
