import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../../../components/common/CustomTable";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import { getCurrencyFromCurrencyCode, onScroll } from "helpers";
import moment from "moment";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import Button from "components/common/Button";
import { useNavigate } from "react-router-dom";
import Dropdown from "components/common/Dropdown";
import { getEditPermissionList } from "helpers/getEditPermissionList";

type StockProps = {
    debouncedSearch: string;
    location: string;
    stockCategory: string;
};

const StocksList = ({ debouncedSearch, location, stockCategory }: StockProps) => {
    const limit = 10;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [stockListLimit, setStockListLimit] = useState<number>(limit);
    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [stockList, setStockList] = useState<{ [key: string]: any }[]>([]);
    const [loadMore, setLoadMore] = useState<boolean>(false);
    const [stockListPagination, setStockListPagination] = useState<{
        current: number | string;
        number_of_pages: number | string;
    }>({
        current: "",
        number_of_pages: "",
    });

    const { fetchingStockList, fetchedStockListSuccess } = useAppSelector(
        (state) => state.inventory
    );

    useEffect(() => {
        if (Boolean(fetchedStockListSuccess)) {
            setStockList(fetchedStockListSuccess?.stock);
            setStockListPagination({
                current: fetchedStockListSuccess?.pagination?.current,
                number_of_pages: fetchedStockListSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedStockListSuccess]);

    useEffect(() => {
        if (!Boolean(fetchingStockList)) {
            dispatch(
                inventoryActions.fetchStockList(
                    stockListLimit,
                    debouncedSearch,
                    location,
                    stockCategory
                )
            );
        }
    }, [dispatch, stockListLimit, debouncedSearch, location, stockCategory]);

    const dashboardContent = document.getElementById("dashboardContent");

    const onTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                stockListPagination?.current as number,
                stockListPagination?.number_of_pages as number,
                () => {
                    setStockListLimit(() => stockListLimit + limit);
                    setLoadMore(true);
                }
            ),
        [dashboardContent, stockListPagination]
    );
    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onTableScroll);
        };
    }, [dashboardContent, onTableScroll]);

    const handleScroll = (e) => {
        if (e.target.scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const tableHeader = [
        {
            title: "Batch ID",
            widthClass: "!border-b !border-slate-200 w-[200px]",
            itemClass: "",
        },
        {
            title: "Vendor/Supplier",
            widthClass: "!border-b !border-slate-200 w-[297px]",
            itemClass: "",
        },
        {
            title: "Quantity",
            widthClass: "!border-b !border-slate-200 w-[83px]",
            itemClass: "",
        },
        {
            title: "Unit of Measure",
            widthClass: "!border-b !border-slate-200 w-[125px]",
            itemClass: "",
        },
        {
            title: "Unit cost (USD)",
            widthClass: "!border-b !border-slate-200 w-[118px]",
            itemClass: "",
        },
        {
            title: "Manufacture Date",
            widthClass: "!border-b !border-slate-200 w-[140px]",
            itemClass: "",
        },
        {
            title: "Best Before Date",
            widthClass: "!border-b !border-slate-200 w-[125px]",
            itemClass: "",
        },
        {
            title: "Expiration Time",
            widthClass: "!border-b !border-slate-200 w-[125px]",
            itemClass: "",
        },
        {
            title: "Removal Time",
            widthClass: "!border-b !border-slate-200 w-[125px]",
            itemClass: "",
        },
        {
            title: "Warehouse",
            widthClass: "!border-b !border-slate-200 w-[297px]",
            itemClass: "",
        },
        {
            title: "",
            widthClass: "!border-b !border-slate-200 w-[50px]",
            itemClass: "",
        },
    ];
console.log("stockList", stockList)
    const tableBody =
        stockList?.length > 0
            ? stockList?.map((item, idx) => [
                  {
                      content: (
                          <div className="py-[18px]" key={idx}>
                              <p className=""> {item?.batchId}</p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-[18px] flex items-center gap-3">
                              <div className="flex gap-3 items-center">
                                  {item?.vendor?.profile?.businessName && (
                                      <VendorAvatar
                                          containerClassname="!rounded"
                                          imageSrc={item?.vendor?.profile?.avatar}
                                          name={item?.vendor?.profile?.businessName}
                                          size={32}
                                      />
                                  )}

                                  {item?.vendor?.profile?.businessName}
                              </div>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-[18px]">
                              <p className="">
                                  {item?.productStockDetails?.stockLevel}{" "}
                              </p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-4 flex items-center gap-2">
                              <p className="">
                                  {item?.inventory?.productStockDetails?.unitOfMeasurement}
                              </p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-[18px]" key={idx}>
                              <p className="">
                                  {getCurrencyFromCurrencyCode(item?.inventory?.unitCost?.currency)}
                                  {item?.inventory?.unitCost?.amount}
                              </p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },

                  {
                      content: (
                          <div className="py-[18px] flex items-center gap-3">
                              <p className="">
                                  {moment(item?.productViabilityDetails?.manufacturingDate).format(
                                      "MMM DD, YYYY"
                                  )}
                              </p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-[18px]">
                              <p className="">
                                  {moment(item?.productViabilityDetails?.bestBefore).format(
                                      "MMM DD, YYYY"
                                  )}
                              </p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-4 flex items-center gap-2">
                              <p className="">
                                  {moment(item?.productViabilityDetails?.expiryDate).format(
                                      "MMM DD, YYYY"
                                  )}
                              </p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-4 flex items-center gap-2">
                              <p className="">
                                  {moment(item?.productViabilityDetails?.removalTime).format(
                                      "MMM DD, YYYY"
                                  )}
                              </p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-4 flex items-center gap-2">
                              <p className="">{item?.warehouse?.warehouseName}</p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div onClick={(e) => e.stopPropagation()}>
                              <Dropdown
                                  dropdown={
                                      <i className="ri-more-2-fill before:content-['\ef76'] text-gm-50"></i>
                                  }
                                  dropdownClassName={"!w-full dropdown-container  mt-4 !mb-10 "}
                                  dropdownContainerClasses={`shadow-cardShadow-2 left-[-120px]  border-0 rounded !max-h-fit`}
                                  dropdownItemsClasses={``}
                                  name={"faq-dropdown"}
                                  dropdownOptions={[
                                      {
                                          label: "Edit Stock",
                                          value: "e",
                                          action: () =>
                                              navigate(
                                                  `/dashboard/inventory/stock-control/edit-stock/${item?._id}`
                                              ),
                                          permissions: getEditPermissionList("inventory"),
                                      },
                                      {
                                          label: "Preview Stock",
                                          value: "d",
                                          action: () =>
                                              navigate(
                                                  `/dashboard/inventory/stock-control/${item?._id}`
                                              ),
                                      },
                                  ]}
                              />
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200 ",
                  },
              ])
            : [];

    return (
        <>
            {fetchingStockList && stockList?.length === 0 ? (
                <div className={`w-full min-h-[30vh]  flex items-center  justify-center`}>
                    <Loader color={"gm-50"} size={8} />
                </div>
            ) : (
                <div>
                    {stockList?.length > 0 ? (
                        <div>
                            <div
                                className="overflow-x-scroll  mt-6 "
                                onScroll={handleScroll}
                                // id="dashboardContent"
                            >
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isScrollable={true}
                                    isScrolled={isScrolled}
                                    isCellBordered={false}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                                    headerContainerClass="!bg-slate-50"
                                    tableClass=" w-full !font-rocGroteskMedium cursor-pointer"
                                    tbodyClass="!text-sm !text-slate-900"
                                    bodyItemClass="hover:!bg-slate-50"
                                />
                            </div>

                            {loadMore && fetchingStockList && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {stockListPagination?.current ===
                                stockListPagination?.number_of_pages && (
                                <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className=" min-h-[30vh] my text-slate-500  flex items-center  justify-center text-sm font-rocGroteskMedium">
                            {debouncedSearch ? (
                                <p className="">"No data matches your search query"</p>
                            ) : (
                                <div className="w-full flex justify-center">
                                    <div
                                        className="flex mt-[85px] p-10 rounded-lg border border-slate-100 text-slate-500
                        items-start gap-6 font-rocGroteskMedium max-w-[635px] shadow-tableNoDataShadow"
                                    >
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1705745863/box-3-line_ybcpgn.svg"
                                            alt="no data "
                                            className=""
                                        />
                                        <div className="">
                                            <p className="text-black text-base  pb-[9px]">
                                                No stock records found.
                                            </p>
                                            <p className="text-sm text-slate-500 pb-6">
                                                Currently, there are no recorded stock details for
                                                your products. Ensure accurate tracking by adding
                                                stock records, each tagged with a unique batch
                                                number for easy identification and management.
                                            </p>
                                            <Button
                                                btnText="Record your first stock"
                                                btnClassname="!py-[10px]  !text-black !text-[13px] !w-auto !bg-[#ffb903]"
                                                onClick={() =>
                                                    navigate("/dashboard/inventory/order-stock")
                                                }
                                                permissions={getEditPermissionList("inventory")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default StocksList;
