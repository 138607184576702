import BreadCrumb from "components/common/BreadCrumb";
import TabsContainer from "components/common/TabsContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import ActivityTab from "components/views/Ldb/Dashboard/Vendors/VendorDetails/Tabs/Activity";
import DocumentsTab from "components/views/Ldb/Dashboard/Vendors/VendorDetails/Tabs/Document";
import JobsTab from "components/views/Ldb/Dashboard/Vendors/VendorDetails/Tabs/Jobs";
import PeopleTab from "components/views/Ldb/Dashboard/Vendors/VendorDetails/Tabs/People";
import { isArray, truncate } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { vendorActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import Button from "components/common/Button";
import Loader from "components/common/Loader";
import { onScroll } from "helpers";
import Performance from "components/views/Ldb/Dashboard/Vendors/VendorDetails/Tabs/Performance";

const VendorDetails = () => {
    const limit = 20;
    const [activeTab, setActiveTab] = useState(4);
    const [vendor, setVendor] = useState<{ [key: string]: any }>({});
    const [vendorDocs, setVendorDocs] = useState<{ [key: string]: any }[]>([]);
    const [team, setTeam] = useState<{ [key: string]: any }[]>([]);
    const [allComments, setAllComments] = useState<{ [key: string]: any }[]>([]);
    const [commentPagination, setCommentPagination] = useState<{ [key: string]: any }>({
        current: "",
        number_of_pages: "",
    });
    const [commentLimit, setCommentLimit] = useState(limit);
    const [loadMoreComments, setLoadMoreComments] = useState(false);
    const [activities, setActivities] = useState([]);
    const [purchaseOrderList, setPurchaseOrderList] = useState<{ [key: string]: any }[]>([]);
    const { vendorId } = useParams();
    const dispatch = useAppDispatch();
    const {
        fetchingSingleVendor,
        fetchedSingleVendorSuccess,
        fetchVendorPoSuccess,
        fetchVendorActivitiesSuccess,
        fetchingVendorDocs,
        fetchVendorDocsSuccess,
        fetchingVendorDocComment,
        fetchVendorDocCommentSuccess,
        addVendorDocCommentSuccess,
        approvingSupplier,
        approveSupplierSuccess,
        requestVendorDocSuccess,
    } = useAppSelector((state) => state.vendor);

    const tabs = ["Activity", "Documents", "People", "Jobs", "Performance"];


    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <ActivityTab
                        activities={activities?.map((activity) => ({
                            title: activity?.activity,
                            dateTime: moment(activity?.createdAt).format("MMM DD, YYYY LT"),
                            icon: (
                                <i className="ri-flag-fill before:content-['\ed3a'] text-slate-500"></i>
                            ),
                        }))}
                        subContent={moment(activities?.[0]?.createdAt).format("MMMM YYYY")}
                        contentClass={"max-h-[47vh]"}
                    />
                );

            case 1:
                return fetchingVendorDocs ? (
                    <div className="flex justify-center my-5">
                        <Loader size={5} />
                    </div>
                ) : (
                    <DocumentsTab
                        contentClass={"max-h-[47vh]"}
                        hideUpload
                        docs={vendorDocs && isArray(vendorDocs) ? vendorDocs : [vendorDocs]}
                        setDocs={setVendorDocs}
                        vendorId={vendorId}
                        vendorEmail={vendor?.vendor?.email}
                        dropDownClass="left-[-80px]"
                    />
                );

            case 2:
                return <PeopleTab people={team} />;

            case 3:
                return (
                    <JobsTab
                        jobs={purchaseOrderList?.map((po) => {
                            return {
                                id: po?._id,
                                name: truncate(
                                    po?.itemsOrServices
                                        ?.map((item: { [key: string]: any }) => item?.name)
                                        ?.join(", "),
                                    { length: 30 }
                                ),
                                amount: po?.totalAmount?.amount,
                                currency: po?.totalAmount?.currency,
                                status: po?.currentStatus,
                            };
                        })}
                        contentClass={"max-h-[47vh]"}
                    />
                );

            case 4:
                    return <Performance />;
    
            default:
                return (
                    <ActivityTab
                        activities={activities?.map((activity) => ({
                            title: activity?.activity,
                            dateTime: moment(activity?.createdAt).format("MMM DD, YYYY LT"),
                            icon: (
                                <i className="ri-flag-fill before:content-['\ed3a'] text-slate-500"></i>
                            ),
                        }))}
                        subContent={moment(activities?.[0]?.createdAt).format("MMMM YYYY")}
                        contentClass={"max-h-[47vh]"}
                    />
                );
        }
    };

    const onCommentScroll = useCallback(
        (e: React.UIEvent<HTMLDivElement, UIEvent>) =>
            onScroll(
                e.target as HTMLElement,
                Number(commentPagination?.current),
                Number(commentPagination?.number_of_pages),
                () => {
                    setCommentLimit(() => commentLimit + limit);

                    setLoadMoreComments(true);
                }
            ),
        [commentPagination]
    );

    useEffect(() => {
        if (vendorId) {
            dispatch(vendorActions.getSingleVendor(vendorId));
        }
    }, [vendorId, dispatch]);

    useEffect(() => {
        dispatch(vendorActions.getVendorDocs(vendorId));
    }, [vendorId, dispatch, requestVendorDocSuccess]);

    useEffect(() => {
        if (vendorId) {
            dispatch(vendorActions.getDocComment(vendorId, commentLimit));
        }
    }, [vendorId, commentLimit]);

    useEffect(() => {
        dispatch(vendorActions.getVendorPo(vendorId as string, 10));
    }, [dispatch, vendorId]);

    useEffect(() => {
        if (Boolean(fetchVendorPoSuccess)) {
            setPurchaseOrderList(fetchVendorPoSuccess?.purchaseOrders);
        }
    }, [fetchVendorPoSuccess]);

    useEffect(() => {
        if (Boolean(fetchVendorDocCommentSuccess)) {
            setAllComments(fetchVendorDocCommentSuccess?.documentComments);
            setCommentPagination(() => ({
                current: fetchVendorDocCommentSuccess?.pagination?.current,
                number_of_pages: fetchVendorDocCommentSuccess?.pagination?.number_of_pages,
            }));
        }
    }, [fetchVendorDocCommentSuccess]);

    useEffect(() => {
        if (Boolean(fetchVendorDocsSuccess)) {
            setVendorDocs(fetchVendorDocsSuccess?.documents);
        }
    }, [fetchVendorDocsSuccess]);

    useEffect(() => {
        if (Boolean(fetchedSingleVendorSuccess)) {
            setVendor(fetchedSingleVendorSuccess?.vendor);
            setTeam(fetchedSingleVendorSuccess?.team);
        }
    }, [fetchedSingleVendorSuccess]);

    useEffect(() => {
        if (Boolean(approveSupplierSuccess)) {
            setVendor((prev) => ({ ...prev, status: "accepted" }));
            dispatch(vendorActions.resetApproveSupplierSuccess());
        }
    }, [approveSupplierSuccess]);

    useEffect(() => {
        if (vendorId) {
            dispatch(vendorActions.getVendorActivities(vendorId));
        }
    }, [dispatch, vendorId]);

    useEffect(() => {
        if (Boolean(fetchVendorActivitiesSuccess)) {
            setActivities(fetchVendorActivitiesSuccess?.activities);
            dispatch(vendorActions.resetGetVendorActivitiesSuccess());
        }
    }, [fetchVendorActivitiesSuccess, dispatch]);

    useEffect(() => {
        if (Boolean(addVendorDocCommentSuccess)) {
            setAllComments((prev) => [addVendorDocCommentSuccess?.documentComment, ...prev]);
            dispatch(vendorActions.resetAddDocCommentSuccess());
        }
    }, [dispatch, addVendorDocCommentSuccess]);

    return (
        <PageFrame containerClassName={"!h-[calc(100vh-108px)]"} isLoading={fetchingSingleVendor}>
            <div className="w-full h-full flex max-lg:flex-col max-lg:gap-6 max-lg:h-auto rounded-[12px] bg-white border border-slate-100">
                <div className=" w-[68%] max-lg:w-full border-r border-slate-100">
                    <div className=" px-6 pt-6 pb-4">
                        <BreadCrumb
                            parentCrumb={"Vendor list"}
                            parentUrl={"/dashboard/suppliers"}
                            childrenCrumb={[
                                {
                                    title:
                                        vendor?.vendor?.profile?.businessName ||
                                        vendor?.vendor?.email,
                                    path: "#",
                                },
                            ]}
                            size="small"
                        />
                    </div>
                    <div className="flex items-center justify-between w-full mb-6 px-6 pb-6">
                        <div className="flex items-center gap-3">
                            <div className="border border-slate-300 rounded-[6px] p-2">
                                <VendorAvatar
                                    size={40}
                                    name={vendor?.vendor?.profile?.businessName}
                                    imageSrc={vendor?.vendor?.profile?.avatar}
                                />
                            </div>
                            <div>
                                <p className="text-lg font-rocGroteskMedium mb-1 text-slate-700">
                                    {vendor?.vendor?.profile?.businessName || "N/A"}
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-slate-500">
                                    {vendor?.category}{" "}
                                    {vendor?.vendor?.profile?.country &&
                                        `• ${vendor?.vendor?.profile?.country}`}
                                </p>
                            </div>
                        </div>
                        {vendor?.status?.toLowerCase() !== "accepted" && (
                            <div className="flex items-center ">
                                <Button
                                    btnText={"Approve onboarding"}
                                    type={"button"}
                                    isLoading={approvingSupplier}
                                    onClick={() =>
                                        dispatch(vendorActions.approveSupplier(vendorId))
                                    }
                                    btnClassname="!py-2 px-[50px] !text-[13px] !rounded"
                                />
                            </div>
                        )}
                    </div>
                    <TabsContainer
                        tabs={tabs}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        component={displayActiveTab()}
                    />
                </div>
                <div className="w-[32%] max-lg:w-full">
                    <div className="px-4 py-3 border-b border-slate-100 mb-6">
                        <p className="text-slate-700 text-base font-rocGroteskMedium ">
                            Company details
                        </p>
                    </div>
                    <div className="px-4">
                        <div className="flex flex-col gap-4 mb-10">
                            <div className="flex items-center justify-between font-rocGroteskRegular">
                                <p className="text-sm text-slate-500 w-1/2">First name</p>
                                <p className="text-sm text-slate-700 w-1/2 font-rocGroteskMedium">
                                    {vendor?.vendor?.firstName || "----"}
                                </p>
                            </div>
                            <div className="flex items-center justify-between font-rocGroteskRegular">
                                <p className="text-sm text-slate-500 w-1/2">Last name</p>
                                <p className="text-sm text-slate-700 w-1/2 font-rocGroteskMedium">
                                    {vendor?.vendor?.lastName || "----"}
                                </p>
                            </div>
                            <div className="flex items-center justify-between font-rocGroteskRegular">
                                <p className="text-sm text-slate-500 w-1/2">Email address</p>
                                <p className="text-sm text-slate-700 w-1/2 font-rocGroteskMedium">
                                    {truncate(vendor?.vendor?.email, { length: 23 }) || "----"}
                                </p>
                            </div>
                            <div className="flex items-center justify-between font-rocGroteskRegular">
                                <p className="text-sm text-slate-500 w-1/2">Phone</p>
                                <p className="text-sm text-slate-700 w-1/2 font-rocGroteskMedium">
                                    {vendor?.vendor?.mobile || "----"}
                                </p>
                            </div>
                            <div className="flex items-center justify-between font-rocGroteskRegular">
                                <p className="text-sm text-slate-500 w-1/2">Country</p>
                                <p className="text-sm text-slate-700 w-1/2 font-rocGroteskMedium">
                                    {vendor?.vendor?.profile?.country || "----"}
                                </p>
                            </div>
                            <div className="flex items-center justify-between font-rocGroteskRegular">
                                <p className="text-sm text-slate-500 w-1/2">Status</p>
                                {vendor?.status ? (
                                    <p className="text-sm text-slate-700 w-1/2 flex items-center gap-2 font-rocGroteskMedium">
                                        <span
                                            className={`material-icons text-xs ${
                                                vendor?.status?.toLowerCase() === "accepted"
                                                    ? "text-g-50"
                                                    : "text-[#F59E0B]"
                                            } mt-[-2px]`}
                                        >
                                            fiber_manual_record
                                        </span>
                                        <span className="capitalize">{vendor?.status}</span>
                                    </p>
                                ) : (
                                    "----"
                                )}
                            </div>
                            <div className="flex items-center justify-between font-rocGroteskRegular">
                                <p className="text-sm text-slate-500 w-1/2">Time of onboarding</p>
                                <p className="text-sm text-slate-700 w-1/2 font-rocGroteskMedium">
                                    {moment(vendor?.createdAt)?.format("MMM DD, YYYY - LT")}
                                </p>
                            </div>
                        </div>

                        <div className="font-rocGroteskMedium">
                            <p className="text-sm text-slate-700 mb-4">Services offered</p>
                            <div className="flex flex-col gap-3 mb-4">
                                {vendor?.vendor?.profile?.services?.map((service, idx) => (
                                    <p
                                        key={service + String(idx)}
                                        className="text-sm text-slate-500 flex items-center gap-2"
                                    >
                                        <span className="material-icons text-[10px] text-slate-500 mt-[-2px]">
                                            fiber_manual_record
                                        </span>
                                        <span>{service}</span>
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="px-4 py-3 border-y border-slate-100 mb-2">
                            <p className="text-slate-700 text-base font-rocGroteskMedium ">
                                Comments
                            </p>
                        </div>
                        {fetchingVendorDocComment && allComments?.length === 0 ? (
                            <div className="flex justify-center">
                                <Loader size={4} />
                            </div>
                        ) : (
                            <div
                                className="max-h-[310px] overflow-y-auto"
                                onScroll={(e: React.UIEvent<HTMLDivElement, UIEvent>) => {
                                    onCommentScroll(e);
                                }}
                            >
                                {allComments?.map((comment, idx) => (
                                    <div key={idx} className="py-5 px-6 even:bg-[#E2E8F033]">
                                        <div className="flex space-x-1.5 items-start mb-3">
                                            <img
                                                src={
                                                    comment?.ldb
                                                        ? comment?.ldb?.avatar ||
                                                          comment?.ldb?.profile?.avatar
                                                        : comment?.vendor?.profile?.avatar
                                                }
                                                alt="avatar"
                                                className="w-6 h-6 rounded-[24px] object-cover"
                                            />
                                            <div>
                                                <p className="flex items-center space-x-1 mb-[2px] font-rocGroteskMedium">
                                                    <span className="text-[13px]">
                                                        {truncate(
                                                            comment?.ldb
                                                                ? comment?.ldb?.fullName ||
                                                                      comment?.ldb?.profile
                                                                          ?.businessName
                                                                : `${comment?.vendor?.firstName} ${comment?.vendor?.lastName}` ||
                                                                      comment?.vendor?.profile
                                                                          ?.businessName,
                                                            { length: 20 }
                                                        )}
                                                    </span>
                                                    <span className="text-[13px]">|</span>
                                                    <span className="text-[13px] text-slate-500">
                                                        {moment(comment?.createdAt).format("LT")}
                                                    </span>
                                                </p>
                                                <p className="text-[13px] text-slate-500 font-rocGroteskMedium">
                                                    {comment?.comment}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {fetchingVendorDocComment && loadMoreComments && (
                                    <div className="flex justify-center my-4">
                                        <Loader color="gm-25" size={4} />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default VendorDetails;
