import React from "react";

export function displaySocialMediaIcons(mediaName: string): JSX.Element {
    const lowerCaseMethod = mediaName.toLowerCase();

    if (lowerCaseMethod === "gmail") {
        return (
            <img
                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1706286695/Gmail_icon__2020_1_rypvay.svg"
                alt="Gmail"
            />
        );
    } else if (lowerCaseMethod === "whatsapp") {
        return (
            <img
                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1706286733/whatsapp_jlyfky.svg"
                alt="WhatsApp"
            />
        );
    } else if (lowerCaseMethod === "outlook") {
        return (
            <img
                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1706286740/ms_outlook_balgzm.svg"
                alt="WhatsApp"
            />
        );
    } else if (lowerCaseMethod === "copilotchat") {
        return (
            <div className="w-5 h-5 p-2 rounded bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100% flex items-center justify-center">
                <i className="ri-robot-line text-white"></i>
            </div>
        );
    }
}
