import Button from "components/common/Button";
import { getFromStorage } from "helpers";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { authActions, chatActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";

const AuthSteps = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { fetchUserProfileSuccess } = useAppSelector((state) => state.auth);
    const [userProfile, setUserProfile] = useState(() => getFromStorage("ally-user"));
    const [name, setName] = useState("");
    const { creatingConversation, createConversationSuccess } = useAppSelector(
        (state) => state.chat
    );

    useEffect(() => {
        if (userProfile) {
            dispatch(authActions.getLdbProfile(userProfile?.token));
        }
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setName(fetchUserProfileSuccess?.fullName?.split(" ")[0]);
        }
    }, [fetchUserProfileSuccess]);

    const handleCreateConversation = () => {
        dispatch(
            chatActions.createConversation({
                title: "RFQ Creation",
                receiverType: "co-pilot",
                conversationType: "CoPilotRFQ",
            })
        );
    };

    useEffect(() => {
        if (Boolean(createConversationSuccess)) {
            navigate(
                `/dashboard/rfqs/create?by=AI&conversationId=${createConversationSuccess?.conversation?._id}`,
                {
                    replace: false,
                }
            );
            dispatch(chatActions.resetCreateConversationSuccess());
        }
    }, [dispatch, navigate, createConversationSuccess]);

    return (
        <div className="w-full h-screen  flex">
            <div className="w-full h-full bg-cover flex  justify-center bg-no-repeat bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1694606734/Bg_2_dtp8j4.png')] ">
                <div className="xl:w-[60%] py-16 ">
                    <div className="mb-6 flex justify-center">
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702538223/Tradeally_2.0/synthAlly_logo_skcywb.svg"
                            alt="trade ally logo"
                            width={157}
                            height={29}
                        />
                    </div>
                    <div className="py-3  mt-20 px-3 flex flex-col justify-center items-center ">
                        <div className="bg-white mb-3 drop-shadow-sm font-rocGroteskMedium shadow-boxShadow text-[10px] rounded-[24px] px-3 py-1 border border-slate-200">
                            <p>Welcome to SynthAlly</p>
                        </div>
                        <p className="text-[32px] max-sm:text-xl mb-7 font-rocGroteskMedium text-center inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                            Hi {name}, I’m Kiri, your AI Copilot.
                            <br /> What would you like to create today?
                        </p>
                        <div className="bg-white  rounded-[8px] px-[50px] py-12 border-[4px] border-white  drop-shadow-md w-full">
                            <div className=" gap-4 grid grid-cols-3 max-lg:grid-cols-1">
                                <div className="min-w-[238px] gap-5 flex flex-col items-center justify-center max-lg:flex-row max-lg:justify-between max-lg:p-4 max-lg:min-w-full max-lg:h-full rounded-[10px] bg-no-repeat bg-cover h-[229px] bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1694609336/Bg_3_qolerx.png')] border-[1px]  border-slate-100">
                                    <div className="gap-5 flex flex-col max-lg:flex-row items-center justify-center">
                                        <div className="h-[48px] w-[48px] rounded-full drop-shadow-[#8D5CED] shadow-sm bg-white flex justify-center items-center">
                                            <i className="ri-file-list-3-line text-[24px] text-transparent bg-clip-text bg-gradient-to-tr  from-[#4C00E1] from-90% to-[#BE9DFF] to-90%"></i>
                                        </div>
                                        <div className="text-center space-y-0.5">
                                            <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                                Create RFQ
                                            </p>
                                            {/* <p className="text-slate-500 font-rocGroteskMedium">
                                            Micro text goes here
                                        </p> */}
                                        </div>
                                    </div>
                                    <Button
                                        btnText={"Create"}
                                        isLoading={creatingConversation}
                                        onClick={handleCreateConversation}
                                        type={"button"}
                                        btnClassname={
                                            "!w-fit !py-1.5 !bg-white !text-slate-950 !border !border-[#F4F5F7] shadow-sm "
                                        }
                                    />
                                </div>
                                <div className="min-w-[238px] gap-5 flex flex-col items-center justify-center max-lg:flex-row max-lg:justify-between max-lg:p-4 max-lg:min-w-full max-lg:h-full rounded-[10px] bg-cover h-[229px] bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1694609817/Bg_4_pknwpf.png')] border-[1px]  border-slate-100">
                                    <div className="gap-5 flex flex-col max-lg:flex-row items-center justify-center">
                                        <div className="h-[48px] w-[48px] rounded-full drop-shadow-[#8D5CED] shadow-sm bg-white flex justify-center items-center">
                                            <i className="ri-flow-chart text-[24px] text-transparent bg-clip-text bg-gradient-to-tr  from-[#4C00E1] from-90% to-[#BE9DFF] to-90%"></i>
                                        </div>
                                        <div className="text-center space-y-0.5">
                                            <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                                Setup your workflow
                                            </p>
                                            {/* <p className="text-slate-500 font-rocGroteskMedium">
                                            Micro text goes here
                                        </p> */}
                                        </div>
                                    </div>
                                    <Button
                                        btnText={"Set up"}
                                        isLoading={false}
                                        type={"button"}
                                        onClick={() => navigate("/dashboard/workflow")}
                                        btnClassname={
                                            "!w-fit !py-1.5 !bg-white !text-slate-950 !border !border-[#F4F5F7] shadow-sm "
                                        }
                                    />
                                </div>
                                <div className="min-w-[238px] gap-5 flex flex-col items-center justify-center max-lg:flex-row max-lg:justify-between max-lg:p-4 max-lg:min-w-full max-lg:h-full rounded-[10px] bg-cover h-[229px] bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1694609863/Bg_5_v77w9g.png')] border-[1px]  border-slate-100">
                                    <div className="gap-5 flex flex-col max-lg:flex-row items-center justify-center">
                                        <div className="h-[48px] w-[48px] rounded-full drop-shadow-[#8D5CED] shadow-sm bg-white flex justify-center items-center">
                                            <i className="ri-building-line text-[24px] text-transparent bg-clip-text bg-gradient-to-tr  from-[#4C00E1] from-90% to-[#BE9DFF] to-90%"></i>
                                        </div>
                                        <div className="text-center space-y-0.5">
                                            <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                                Add vendor
                                            </p>
                                            {/* <p className="text-slate-500 font-rocGroteskMedium">
                                            Micro text goes here
                                        </p> */}
                                        </div>
                                    </div>
                                    <Button
                                        btnText={"Add"}
                                        isLoading={false}
                                        type={"button"}
                                        onClick={() => {
                                            navigate("/dashboard/suppliers/add-vendor");
                                        }}
                                        btnClassname={
                                            "!w-fit !py-1.5 !bg-white !text-slate-950 !border !border-[#F4F5F7] shadow-sm "
                                        }
                                    />
                                </div>
                            </div>

                            <div className="mt-8 flex justify-center">
                                <p className="flex items-center gap-2.5 text-center">
                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                        Quick start by yourself
                                    </span>
                                    <Link
                                        to="/dashboard"
                                        className="text-sm font-rocGroteskMedium text-transparent bg-clip-text bg-gradient-to-tr  from-[#5C58FF] from-0% to-[#FF3D1D] to-100%"
                                    >
                                        Go to dashboard
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthSteps;
