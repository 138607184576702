import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import TabsContainer from "components/common/TabsContainer";
import PageFrame from "components/layout/PageFrame";
import StockCountHistory from "components/views/Ldb/Dashboard/Inventory/StockControl/StockCountHistoryTable";
import ProductList from "components/views/Ldb/Dashboard/Inventory/Warehouse/ProductList";
import ProductStock from "components/views/Ldb/Dashboard/Inventory/Warehouse/ProductStock";
import { onScroll } from "helpers";
import { getEditPermissionList } from "helpers/getEditPermissionList";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { truncate } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";

const WarehouseDetails = () => {
    const limit = 20;
    const { id } = useParams<string>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [inventoryCategory, setInventoryCategory] = useState("");
    const [productSearch, setProductSearch] = useState("");
    const [activeTab, setActiveTab] = useState<number>(0);
    const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
    const [isPageLoadedTwo, setIsPageLoadedTwo] = useState<boolean>(false);
    const [warehouseDetails, setWarehouseDetails] = useState<any>([]);
    const [expiryAlerts, setExpiryAlerts] = useState<any>([]);
    const [alerts, setAlerts] = useState<any>([]);
    const {
        fetchingSingleWarehouseList,
        fetchedInventorySettingsFailure,
        fetchedSingleWarehouseListSuccess,
        fetchedInventorySettingsSuccess,
        fetchingInventorySettings,
    } = useAppSelector((state) => state.inventory);

    useEffect(() => {
        dispatch(inventoryActions.fetchSingleWarehouseList(id));
    }, [id, dispatch]);
    useEffect(() => {
        dispatch(inventoryActions.fetchInventorySettings(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (Boolean(fetchedSingleWarehouseListSuccess)) {
            setWarehouseDetails(fetchedSingleWarehouseListSuccess?.warehouses);
            setIsPageLoaded(true);
        }
    }, [fetchedSingleWarehouseListSuccess]);


    useEffect(() => {
        if (Boolean(fetchedInventorySettingsSuccess)) {
            if (fetchedInventorySettingsSuccess?.stockSetting) {
                const { stockLevelAlerts, stockExpiryAlerts } =
                    fetchedInventorySettingsSuccess?.stockSetting;
                setExpiryAlerts(stockExpiryAlerts);
                setAlerts(stockLevelAlerts);
            }
            setIsPageLoadedTwo(true);
        }
    }, [fetchedInventorySettingsSuccess, fetchedInventorySettingsSuccess?.stockSetting]);

    useEffect(() => {
        if (fetchedInventorySettingsFailure) {
            setIsPageLoadedTwo(true);
        }
    }, [fetchedInventorySettingsFailure]);

    const tabs = ["Stocks Count History ", "Product List"];
    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return <ProductStock productSearch={productSearch} filter={inventoryCategory} />;

            case 1:
                return <ProductList productSearch={productSearch} filter={inventoryCategory} />;
            default:
                return;
        }
    };

    return (
        <PageFrame isLoading={fetchingSingleWarehouseList && isPageLoaded}>
            <div className="">
                <div className="bg-white rounded-lg border border-slate-100">
                    <div className="">
                        <div className="flex top-[-2px] md:sticky z-[40] bg-white items-center justify-between px-8 py-5 border-b border-slate-100">
                            <p className="text-base font-rocGroteskMedium">New warehouse</p>
                            <div className="flex items-center gap-3">
                                <Button
                                    btnText="Cancel"
                                    onClick={() => navigate("/dashboard/inventory/warehouse")}
                                    btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                                />
                                <Button
                                    btnText="Edit warehouse"
                                    btnClassname="!px-4 !py-2.5 !w-fit"
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/inventory/edit-warehouse/${warehouseDetails?._id}`
                                        )
                                    }
                                    permissions={getEditPermissionList("inventory")}
                                />
                            </div>
                        </div>
                        <div className="w-full py-5 px-8">
                            <div className="mb-3 space-y-6 w-[70%]">
                                <div className="w-full mb-4">
                                    <p className="text-sm font-rocGroteskMedium">Warehouse name</p>
                                    <p className="text-2xl font-rocGroteskMedium text-slate-700">
                                        {warehouseDetails?.warehouseName ?? "N/A"}
                                    </p>
                                </div>
                                <div className="w-full pb-4 border-b border-slate-200">
                                    <p className="text-sm font-rocGroteskMedium">Contact Person</p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        {warehouseDetails?.contactPerson ? (
                                            <>
                                                {warehouseDetails?.contactPerson?.profile
                                                    ?.businessName ??
                                                    warehouseDetails?.contactPerson?.businessName}
                                            </>
                                        ) : (
                                            "  N/A"
                                        )}
                                    </p>
                                </div>
                                <div className="w-full pb-4 border-b  border-slate-200">
                                    <p className="text-sm font-rocGroteskMedium">Location</p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        {warehouseDetails?.address?.address??"N/A"}
                                    </p>
                                </div>
                                <div className="w-full ">
                                    <p className="text-sm font-rocGroteskMedium">Additional Note</p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        {warehouseDetails?.additionalNotes??"N/A"}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="px-8">
                            <p className="text-base text-gm-50 font-rocGroteskMedium mb-4">
                                Warehouse Settings
                            </p>
                            {!isPageLoaded ? (
                                <Loader />
                            ) : (
                                <>
                                    <div className="font-rocGroteskMedium mb-6 text-gm-50  p-6 border-[1.5px] border-slate-200 rounded-[8px]">
                                        <div className="flex space-x-2 items-center mb-2">
                                            <i className="ri-notification-3-line text-[20px]"></i>
                                            <p className="text-base">Stocks level alert</p>
                                        </div>
                                        <div>
                                            {alerts?.map((alert, idx) => (
                                                <div className="flex space-x-2" key={idx}>
                                                    <i className="ri-check-line text-[#00DB8F]"></i>
                                                    <p className="text-sm">
                                                        When stock level is {alert?.alertLevel}{" "}
                                                        _notify team members{" "}
                                                        {alert?.recipients?.map((name, index) => (
                                                            <span
                                                                key={index}
                                                                className="text-p-50 underline "
                                                            >
                                                                {name?.fullName??name?.email}{" "}
                                                                {alert?.recipients?.length - 1 !==
                                                                index
                                                                    ? ", "
                                                                    : ""}
                                                            </span>
                                                        ))}
                                                        _nvery {alert?.alertPeriod?.quantity}{" "}
                                                        {alert?.alertPeriod?.unit}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="font-rocGroteskMedium mb-6 text-gm-50  p-6  border-[1.5px]  border-slate-200 rounded-[8px]">
                                        <div className="flex space-x-2 items-center mb-2">
                                            <i className="ri-notification-3-line text-[20px]"></i>
                                            <p className="text-base">Expiry date alert</p>
                                        </div>
                                        <div>
                                            {expiryAlerts?.map((expiryAlert, idx) => (
                                                <div key={idx} className="flex space-x-2 ">
                                                    <i className="ri-check-line text-[#00DB8F]"></i>
                                                    <p className="text-sm">
                                                        When product {expiryAlert?.whenStock}{" "}
                                                        _notify{" "}
                                                        {expiryAlert?.recipients?.map(
                                                            (name, index) => (
                                                                <span
                                                                    key={index}
                                                                    className="text-p-50 underline mr-[0.2px]"
                                                                >
                                                                    {name?.fullName??name?.email}{" "}
                                                                    {expiryAlert?.recipients
                                                                        ?.length -
                                                                        1 !==
                                                                    index
                                                                        ? ", "
                                                                        : ""}
                                                                </span>
                                                            )
                                                        )}
                                                        _nvery {expiryAlert?.alertPeriod?.quantity}{" "}
                                                        {expiryAlert?.alertPeriod?.unit}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="px-8 relative">
                        <div>
                            <p className="text-base font-rocGroteskMedium text-gm-50">
                                All product stock in warehouse
                            </p>
                            <div className="pt-8 ">
                                <div className="absolute right-8 bg-[#fcfcfc] pl-2">
                                    <div className="flex gap-2 pb-[2px]">
                                        <TextInput
                                            name="search"
                                            type="text"
                                            value={productSearch}
                                            onChange={(e) => setProductSearch(e.target.value)}
                                            onInput={() => {}}
                                            inputPlaceholder="Search"
                                            inputClassName={
                                                "!h-[40px] pl-[0px] !mb-0 !font-rocGroteskMedium !text-slate-400"
                                            }
                                            leftIcon={
                                                <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                            }
                                            inputContainerClassname={
                                                "!rounded-[4px] !w-[208px] !h-[40px] !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                                            }
                                        />

                                        <Dropdown
                                            name="inventoryCategory"
                                            value={""}
                                            dropdownTriggerClassName={" "}
                                            handleChange={(name, value) =>
                                                setInventoryCategory(value)
                                            }
                                            dropdown={
                                                <div className="bg-white flex items-center h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-tradeally-neutral-20">
                                                    <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                        {inventoryCategory || "Category"}
                                                    </span>
                                                    {Boolean(inventoryCategory) && (
                                                        <i
                                                            onClick={(e) => {
                                                                e?.stopPropagation();
                                                                setInventoryCategory("");
                                                            }}
                                                            className="ri-close-circle-fill text-sm text-slate-500"
                                                        ></i>
                                                    )}
                                                    <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                                </div>
                                            }
                                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                            dropdownContainerClasses={`shadow-cardShadow-4 left-[-190px] !mt-0  !w-[159px] rounded !max-h-fit !p-0 !top-[105%]`}
                                            dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                            dropdownOptions={[
                                                {
                                                    label: "Raw Materials",
                                                    value: "Raw Material",
                                                },
                                                {
                                                    label: "Work-in-Progress (WIP)",
                                                    value: "Work-In-Progress (WIP)",
                                                },
                                                {
                                                    label: "Finished Goods",
                                                    value: "Finished Goods",
                                                },
                                                {
                                                    label: "Maintenance, Repair, and Overall (MRO)",
                                                    value: "Maintenance, Repair, and Overall (MRO)",
                                                },
                                            ]}
                                        />
                                        <Button
                                            icon={<i className="ri-settings-5-line"></i>}
                                            btnClassname="!py-2 !bg-white !border !border-[#F4F5F7] !shadow-settinsBtnShadow !text-gm-50"
                                            btnText="Settings"
                                            onClick={() =>
                                                navigate(
                                                    `/dashboard/inventory/warehouse/${id}/settings`
                                                )
                                            }
                                            permissions={getEditPermissionList("inventory")}
                                        />
                                    </div>
                                </div>
                                <TabsContainer
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    component={displayActiveTab()}
                                    className="!px-0"
                                    itemClassName="!pb-2"
                                    borderLineClase={"!text-slate-100  w-full  !mb-6"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default WarehouseDetails;
