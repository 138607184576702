import Loader from "components/common/Loader";
import WareHouseCard from "./WareHouseCard";
import { onScroll } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";
const WareHouse = () => {
    const limit = 20;
    const { id } = useParams();
    const [warehouseLimit, setWarehouseLimit] = useState(limit);
    const [warehouseList, setWarehouseList] = useState([]);
    const [loadMoreWarehouse, setLoadMoreWarehouse] = useState(false);
    const [warehousePagination, setWarehousePagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { fetchedInventoryWarehouseSuccess, fetchingInventoryWarehouse } = useAppSelector(
        (state) => state.inventory
    );
    const dashboardContent = document.getElementById("dashboardContent");
    const onWarehouseScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                warehousePagination?.current as number,
                warehousePagination?.number_of_pages as number,
                () => {
                    setWarehouseLimit(() => warehouseLimit + limit);
                    setLoadMoreWarehouse(true);
                }
            ),
        [dashboardContent, warehousePagination, warehouseLimit]
    );

    useEffect(() => {
        dispatch(inventoryActions.fetchInventoryWarehouse(id, warehouseLimit));
    }, [id, warehouseLimit]);

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onWarehouseScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onWarehouseScroll);
        };
    }, [dashboardContent, onWarehouseScroll]);

    useEffect(() => {
        if (Boolean(fetchedInventoryWarehouseSuccess)) {
            setWarehouseList(fetchedInventoryWarehouseSuccess?.warehouses);
            setWarehousePagination({
                current: fetchedInventoryWarehouseSuccess?.pagination?.current,
                number_of_pages: fetchedInventoryWarehouseSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedInventoryWarehouseSuccess]);

    return (
        <div className="w-full ">
            {fetchingInventoryWarehouse ? (
                <div className="mt-10">
                    <Loader />
                </div>
            ) : (
                <div className="w-full ">
                    {warehouseList?.length > 0 ? (
                        <div className="w-full  grid grid-cols-3 gap-4 mt-8">
                            {warehouseList?.map((warehouse) => (
                                <WareHouseCard
                                key={warehouse?._id}
                                    name={warehouse?.warehouse?.warehouseName}
                                    address={warehouse?.warehouse?.address?.address}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(`/dashboard/inventory/stock-count/${id}/warehouse/${warehouse?.warehouseId}`);
                                    }}
                                    maximumStockLevel={warehouse?.productStockSettings?.maximumStockLevel??"N/A"}
                                    minimumStockLevel={warehouse?.productStockSettings?.minimumStockLevel??"N/A"}
                                    currentStockLevel={warehouse?.productStockDetails?.stockLevel??0}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="flex items-center justify-center mt-[76px]">
                            <div>
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1704890969/Tradeally_2.0/empty_state_img_a8twwl.svg"
                                    alt="empty state"
                                    className="w-[268px] h-[235px]"
                                />
                                <p className="text-center text-sm text-[#475569] font-rocGroteskMedium mt-5">
                                    You have no warehouse
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
export default WareHouse;
