import TextInput from "components/common/InputField/TextInput";
import React, { useRef, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { BoardList } from "./BoardList";
import { authActions, taskActions } from "redux/Ldb/actions";
import { useEffect } from "react";
import { debounce, truncate } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks";
import VendorAvatar from "components/common/VendorAvatar";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";
import { getFromStorage, onScroll } from "helpers";
import Loader from "components/common/Loader";
import Button from "components/common/Button";
import CustomToast from "components/common/CustomToast";
import SelectInput from "components/common/InputField/SelectInput";
import toast from "react-hot-toast";
import ModalContainer from "components/common/ModalContainer";
import TaskDetailsModal from "./TaskDetails";
import { accessTypes } from "variables";

const Board = () => {
    const limit = 20;
    const containerRef = useRef<HTMLDivElement>(null);
    const [taskCompletedLimit, setTaskCompletedLimit] = useState<number>(limit);
    const [taskLimit, setTaskLimit] = useState<number>(limit);
    const [userProfile, setUserProfile] = useState(() => getFromStorage("ally-user"));
    const [inviteMember, setInviteMember] = useState<boolean>(false);
    const [taskPendingLimit, setTaskPendingLimit] = useState<number>(limit);
    const [taskActiveLimit, setTaskActiveLimit] = useState<number>(limit);
    const [taskOverdueLimit, setTaskOverdueLimit] = useState<number>(limit);
    const [pendingTaskList, setPendingTaskList] = useState<{ [key: string]: any }[]>([]);
    const [overdueTaskList, setOverdueTaskList] = useState<{ [key: string]: any }[]>([]);
    const [activeTaskList, setActiveTaskList] = useState<{ [key: string]: any }[]>([]);
    const [completedTaskList, setCompletedTaskList] = useState<{ [key: string]: any }[]>([]);
    const [loadMoreCompletedTask, setLoadMoreCompletedTask] = useState<boolean>(false);
    const [loadMorePendingTask, setLoadMorePendingTask] = useState<boolean>(false);
    const [loadMoreActiveTask, setLoadMoreActiveTask] = useState<boolean>(false);
    const [loadMoreOverdueTask, setLoadMoreOverdueTask] = useState<boolean>(false);
    const [scrollTitle, setScrollTitle] = useState<string>("");
    const [openMobileContainer, setOpenMobileContainer] = useState<null | number>(null);
    const [showRolesAndResponsibiltyModal, setShowRolesAndResponsibiltyModal] =
        useState<boolean>(false);
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
    const [roles, setRoles] = useState<{ [key: string]: any }[]>([]);
    const [role, setRole] = useState<string>("");
    const [numDivs, setNumDivs] = useState<number>(1);
    const [debouncedSearch, setDebouncedSearch] = useState<{ search: string }>({
        search: "",
    });
    const [addCustomRole, setAddCustomRole] = useState(false);
    const [teamInfo, setTeamInfo] = useState<{ [key: string]: any }[]>([
        { "email-0": "", "role-0": "" },
    ]);
    const [filter, setFilter] = useState<{ search: string }>({
        search: "",
    });
    const [debouncedFilter, setDebouncedFilter] = useState<{ search: string }>({
        search: "",
    });

    const [listData, setListData] = useState([]);
    const [activeTaskPagination, setActivePagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [completedTaskPagination, setCompletedPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [pendingTaskPagination, setPendingPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [overdueTaskPagination, setOverduePagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [showTaskDetails, setShowTaskDetails] = useState<boolean>(false);
    const [taskId, setTaskId] = useState("");

    const dispatch = useAppDispatch();

    const {
        fetchActiveTaskSuccess,
        fetchPendingTaskSuccess,
        fetchCompletedTaskSuccess,
        fetchOverdueTaskSuccess,
        updateTaskSuccess,
        fetchingCompletedTaskList,
        fetchingOverdueTaskList,
        fetchingActiveTaskList,
        fetchingPendingTaskList,
        fetchActiveTaskTwoSuccess,
        fetchingActiveTaskTwoList,
        fetchingPendingTaskTwoList,
        fetchingCompletedTaskTwoList,
        fetchingOverdueTaskTwoList,
        fetchPendingTaskTwoSuccess,
        fetchCompletedTaskTwoSuccess,
        fetchOverdueTaskTwoSuccess,
    } = useAppSelector((state) => state.task);
    const {
        addingTeamMember,
        fetchingRoles,
        fetchedRolesSuccess,
        createRoles,
        createRolesSuccess,
    } = useAppSelector((state) => state.auth);
    let loadingTaskTotal = true;
    const taskPagination =
        scrollTitle.toLowerCase() === "on-track"
            ? activeTaskPagination
            : scrollTitle.toLowerCase() === "pending"
            ? pendingTaskPagination
            : scrollTitle.toLowerCase() === "delayed"
            ? overdueTaskPagination
            : scrollTitle.toLowerCase() === "completed"
            ? completedTaskPagination
            : completedTaskPagination;

    const setLoadMoreTask =
        scrollTitle.toLowerCase() === "on-track"
            ? setLoadMoreActiveTask
            : scrollTitle.toLowerCase() === "pending"
            ? setLoadMorePendingTask
            : scrollTitle.toLowerCase() === "delayed"
            ? setLoadMoreOverdueTask
            : scrollTitle.toLowerCase() === "completed"
            ? setLoadMoreCompletedTask
            : setLoadMoreCompletedTask;

    const loadMoreTask =
        scrollTitle.toLowerCase() === "on-track"
            ? loadMoreActiveTask
            : scrollTitle.toLowerCase() === "pending"
            ? loadMorePendingTask
            : scrollTitle.toLowerCase() === "delayed"
            ? loadMoreOverdueTask
            : scrollTitle.toLowerCase() === "completed"
            ? loadMoreCompletedTask
            : false;

    const setTasksLimit =
        scrollTitle.toLowerCase() === "on-track"
            ? setTaskActiveLimit
            : scrollTitle.toLowerCase() === "pending"
            ? setTaskPendingLimit
            : scrollTitle.toLowerCase() === "delayed"
            ? setTaskOverdueLimit
            : scrollTitle.toLowerCase() === "completed"
            ? setTaskCompletedLimit
            : setTaskCompletedLimit;

    const tasksLimit =
        scrollTitle.toLowerCase() === "on-track"
            ? taskActiveLimit
            : scrollTitle.toLowerCase() === "pending"
            ? taskPendingLimit
            : scrollTitle.toLowerCase() === "delayed"
            ? taskOverdueLimit
            : scrollTitle.toLowerCase() === "completed"
            ? taskCompletedLimit
            : taskCompletedLimit;

    const loading =
        scrollTitle.toLowerCase() === "on-track"
            ? fetchingActiveTaskList
            : scrollTitle.toLowerCase() === "pending"
            ? fetchingPendingTaskList
            : scrollTitle.toLowerCase() === "delayed"
            ? fetchingOverdueTaskList
            : scrollTitle.toLowerCase() === "completed"
            ? fetchingCompletedTaskList
            : false;

    loadingTaskTotal =
        scrollTitle.toLowerCase() === "on-track"
            ? fetchingActiveTaskTwoList
            : scrollTitle.toLowerCase() === "pending"
            ? fetchingPendingTaskTwoList
            : scrollTitle.toLowerCase() === "delayed"
            ? fetchingOverdueTaskTwoList
            : scrollTitle.toLowerCase() === "completed"
            ? fetchingCompletedTaskTwoList
            : fetchingCompletedTaskTwoList;
    const handleCardClick = (id) => {
        setTaskId(id);
    };

    useEffect(() => {
        dispatch(
            taskActions.fetchCompletedTaskList(
                taskCompletedLimit,
                "completed",
                "",
                debouncedFilter.search
            )
        );
    }, [dispatch, taskCompletedLimit, debouncedFilter.search]);

    useEffect(() => {
        dispatch(
            taskActions.fetchOverdueTaskList(
                taskOverdueLimit,
                "delayed",
                "",
                debouncedFilter.search
            )
        );
    }, [dispatch, taskOverdueLimit, debouncedFilter.search]);

    useEffect(() => {
        dispatch(
            taskActions.fetchActiveTaskList(taskActiveLimit, "on-track", "", debouncedFilter.search)
        );
    }, [dispatch, taskActiveLimit, debouncedFilter.search]);

    useEffect(() => {
        dispatch(
            taskActions.fetchPendingTaskList(
                taskPendingLimit,
                "pending",
                "",
                debouncedFilter.search
            )
        );
    }, [dispatch, taskPendingLimit, debouncedFilter.search]);

    useEffect(() => {
        if (Boolean(fetchActiveTaskSuccess)) {
            setActiveTaskList(fetchActiveTaskSuccess?.tasks);
            setActivePagination({
                current: fetchActiveTaskSuccess?.pagination?.current,
                number_of_pages: fetchActiveTaskSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchActiveTaskSuccess]);

    useEffect(() => {
        if (Boolean(fetchPendingTaskSuccess)) {
            setPendingTaskList(fetchPendingTaskSuccess?.tasks);
            setPendingPagination({
                current: fetchPendingTaskSuccess?.pagination?.current,
                number_of_pages: fetchPendingTaskSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchPendingTaskSuccess]);

    useEffect(() => {
        if (Boolean(fetchCompletedTaskSuccess)) {
            setCompletedTaskList(fetchCompletedTaskSuccess?.tasks);
            setCompletedPagination({
                current: fetchCompletedTaskSuccess?.pagination?.current,
                number_of_pages: fetchCompletedTaskSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchCompletedTaskSuccess]);

    useEffect(() => {
        if (Boolean(fetchOverdueTaskSuccess)) {
            setOverdueTaskList(fetchOverdueTaskSuccess?.tasks);
            setOverduePagination({
                current: fetchOverdueTaskSuccess?.pagination?.current,
                number_of_pages: fetchOverdueTaskSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchOverdueTaskSuccess]);

    const handleSearch = (e) => {
        const { name, value } = e.target;

        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        dispatch(taskActions.fetchPendingTaskTwoList(taskLimit, "pending"));
        dispatch(taskActions.fetchOverdueTaskTwoList(taskLimit, "delayed"));
        dispatch(taskActions.fetchCompletedTaskTwoList(taskLimit, "completed"));
        dispatch(taskActions.fetchActiveTaskTwoList(taskLimit, "on-track"));
        dispatch(taskActions.fetchMyTaskTwoList(taskLimit, "", "assignee"));
        dispatch(taskActions.fetchAllTaskTwoList(taskLimit));
    }, [dispatch]);

    const handleDebouncedChange = debounce((e) => {
        const { name, value } = e.target;

        setDebouncedFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, 1500);

    useEffect(() => {
        if (debouncedFilter.search !== "") {
            dispatch(
                taskActions.fetchPendingTaskList(taskLimit, "pending", "", debouncedFilter.search)
            );
            dispatch(
                taskActions.fetchOverdueTaskList(taskLimit, "delayed", "", debouncedFilter.search)
            );
            dispatch(
                taskActions.fetchCompletedTaskList(
                    taskLimit,
                    "completed",
                    "",
                    debouncedFilter.search
                )
            );
            dispatch(
                taskActions.fetchActiveTaskList(taskLimit, "on-track", "", debouncedFilter.search)
            );
        }
    }, [dispatch, taskLimit, debouncedFilter.search]);

    useEffect(() => {
        setListData([
            {
                id: "1",
                taskTitle: activeTaskList[0]?.status ? activeTaskList[0]?.status : "on-track",
                taskTotal: loadingTaskTotal ? (
                    <Loader />
                ) : fetchActiveTaskTwoSuccess?.totalCount ? (
                    fetchActiveTaskTwoSuccess?.totalCount
                ) : (
                    "0"
                ),
                cardDetails: activeTaskList,
            },
            {
                id: "2",
                taskTitle: pendingTaskList[0]?.status ? pendingTaskList[0]?.status : "pending",
                taskTotal: loadingTaskTotal ? (
                    <Loader />
                ) : fetchPendingTaskTwoSuccess?.totalCount ? (
                    fetchPendingTaskTwoSuccess?.totalCount
                ) : (
                    "0"
                ),
                cardDetails: pendingTaskList,
            },
            {
                id: "3",
                taskTitle: completedTaskList[0]?.status
                    ? completedTaskList[0]?.status
                    : "completed",
                taskTotal: loadingTaskTotal ? (
                    <Loader />
                ) : fetchCompletedTaskTwoSuccess?.totalCount ? (
                    fetchCompletedTaskTwoSuccess?.totalCount
                ) : (
                    "0"
                ),
                cardDetails: completedTaskList,
            },
            {
                id: "4",
                taskTitle: overdueTaskList[0]?.status ? overdueTaskList[0]?.status : "delayed",
                taskTotal: loadingTaskTotal ? (
                    <Loader />
                ) : fetchOverdueTaskTwoSuccess?.totalCount ? (
                    fetchOverdueTaskTwoSuccess?.totalCount
                ) : (
                    "0"
                ),
                cardDetails: overdueTaskList,
            },
        ]);
    }, [
        activeTaskList,
        pendingTaskList,
        completedTaskList,
        overdueTaskList,
        fetchActiveTaskSuccess,
        fetchPendingTaskSuccess,
        fetchCompletedTaskSuccess,
        fetchOverdueTaskSuccess,
        fetchActiveTaskTwoSuccess,
        fetchPendingTaskTwoSuccess,
        fetchCompletedTaskTwoSuccess,
        fetchOverdueTaskTwoSuccess,
    ]);

    useEffect(() => {
        if (Boolean(updateTaskSuccess)) {
            dispatch(taskActions.fetchPendingTaskList(taskLimit, "pending"));
            dispatch(taskActions.fetchOverdueTaskList(taskLimit, "delayed"));
            dispatch(taskActions.fetchCompletedTaskList(taskLimit, "completed"));
            dispatch(taskActions.fetchActiveTaskList(taskLimit, "on-track"));
            dispatch(taskActions.fetchAllTaskList(taskLimit, ""));
            dispatch(taskActions.resetUpdateTask());
        }
    }, [dispatch, updateTaskSuccess, taskLimit]);

    const onDragEnd = (result, listData, setListData) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = listData.find((column) => column.taskTitle === source.droppableId);
            const destColumn = listData.find(
                (column) => column.taskTitle === destination.droppableId
            );
            const sourceItems = [...sourceColumn.cardDetails];
            const destItems = [...destColumn.cardDetails];
            const [movedItem] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, movedItem);
            if (source.droppableId?.toLowerCase() !== "completed") {
                if (movedItem?.assigneeType?.toLowerCase() !== "copilot") {
                    setListData((prevListData) => {
                        const updatedListData = prevListData.map((column) => {
                            if (column.taskTitle === source.droppableId) {
                                return { ...column, cardDetails: sourceItems };
                            }
                            if (column.taskTitle === destination.droppableId) {
                                return { ...column, cardDetails: destItems };
                            }
                            return column;
                        });

                        return updatedListData;
                    });
                }
            }
            dispatch(taskActions.updateTask({ status: destColumn?.taskTitle }, movedItem?._id));
        } else {
            const column = listData.find((column) => column.taskTitle === source.droppableId);
            const copiedItems = [...column.cardDetails];
            // const [removed] = copiedItems.splice(source.index, 1);
            // copiedItems.splice(destination.index, 0, removed);
            setListData((prevListData) => {
                const updatedListData = prevListData.map((c) => {
                    if (c.taskTitle === source.droppableId) {
                        return { ...c, cardDetails: copiedItems };
                    }
                    return c;
                });

                return updatedListData;
            });
        }
    };

    const addNewInputField = () => {
        const newInputField = {
            email: "",
            role: "",
            permissions: "",
        };
        setTeamInfo([...teamInfo, newInputField]);
    };

    const handleAddRole = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const permission = selectedPermissions;
        if (role === "") {
            toast.custom((t) => (
                <CustomToast t={t} message={"Role name is required"} type="error" />
            ));
        }
        if (role !== "") {
            const teamMember = {
                role,
                ...(permission.length > 0 && { permission }),
            };
            dispatch(authActions.createRoles(teamMember));
        }
    };

    useEffect(() => {
        if (Boolean(createRolesSuccess)) {
            setSelectedPermissions([]);
            setAddCustomRole(false);
            dispatch(authActions.resetCreateRoles());
            dispatch(authActions.getRoles(20));
        }
    }, [createRolesSuccess, dispatch]);

    useEffect(() => {
        if (Boolean(fetchedRolesSuccess)) {
            setRoles(fetchedRolesSuccess?.rolesList);
        }
    }, [fetchedRolesSuccess]);

    const handlePermissionChange = (permission: string) => {
        const isSelected = selectedPermissions.includes(permission);

        if (isSelected) {
            const updatedPermissions = selectedPermissions.filter((item) => item !== permission);
            setSelectedPermissions(updatedPermissions);
        } else {
            setSelectedPermissions([...selectedPermissions, permission]);
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            const lastItem = (container as any).lastChild;
            (container as any).scrollTop = lastItem.offsetTop;
        }
    }, [teamInfo]);

    const onDelete = (index: number) => {
        const updatedTeamInfo = [...teamInfo];
        updatedTeamInfo.splice(index, 1);
        setTeamInfo(updatedTeamInfo);
    };

    const handleRoleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: { [key: string]: any }[] = [];
        const filteredTeamInfo = teamInfo.filter(
            (member, index) => member[`email-${index}`] && member[`role-${index}`]
        );

        filteredTeamInfo.map((member, index) => {
            const teamMember = {
                email: member[`email-${index}`],
                role: member[`role-${index}`],
            };

            body.push(teamMember);
        });

        const teamsBody = { teamMembers: body };

        dispatch(authActions.addTeamMember(teamsBody, userProfile?.token));
    };

    const handleRoleChange = (
        e: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } },
        index: number
    ) => {
        const { name, value } = e.target;
        const updatedTeamInfo = [...teamInfo];
        updatedTeamInfo[index] = {
            ...updatedTeamInfo[index],
            [name]: value,
        };
        setTeamInfo(updatedTeamInfo);
    };

    return (
        <div className="mt-5 ">
            <div className="flex justify-between items-center max-sm:flex-col-reverse max-sm:gap-3 max-sm:items-start max-sm:mb-4">
                <div>
                    <h1 className="text-slate-900 font-rocGroteskMedium text-lg">All tasks</h1>
                </div>
                <div className="flex items-center gap-3 max-sm:w-full">
                    <div className="max-sm:w-full">
                        <TextInput
                            name={"search"}
                            value={filter.search}
                            type={"text"}
                            inputPlaceholder={"Search"}
                            inputClassName={"!h-[40px] pl-[0px] !bg-white text-sm !mb-0"}
                            onChange={handleSearch}
                            onInput={handleDebouncedChange}
                            leftIcon={
                                <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                            }
                            inputContainerClassname={
                                "!rounded-[4px] !w-[300px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                            }
                        />
                    </div>

                    {/* <div>
                        <Dropdown
                            value={""}
                            dropdown={
                                <div className="flex items-center cursor-pointer gap-2 py-1.5 px-3 bg-white rounded shadow-boxShadow-2 border border-n-20">
                                    <i className="ri-filter-3-fill"></i>
                                    <p className="text-[13px] font-rocGroteskMedium">Filter</p>
                                </div>
                            }
                            dropdownContainerClasses={
                                " left-[-234px] !py-0 top-[49px] shadow-dropdownShadow rounded !border-0"
                            }
                            dropdownClassName={"!max-h-fit"}
                            name={"quickAction"}
                        />
                    </div> */}
                </div>
            </div>
            <div className="mt-5 flex space-x-[17px] w-full max-sm:hidden">
                <DragDropContext onDragEnd={(result) => onDragEnd(result, listData, setListData)}>
                    <div className="mt-7  flex space-x-[17px] w-full">
                        <BoardList
                            listData={listData}
                            scrollBar={"scrollBar"}
                            taskPagination={taskPagination}
                            setLoadMoreTask={setLoadMoreTask}
                            loadMoreTask={loadMoreTask}
                            setTaskLimit={setTasksLimit}
                            scrollTitle={scrollTitle}
                            taskLimit={tasksLimit}
                            limit={limit}
                            setScrollTitle={setScrollTitle}
                            loading={loading}
                            loadingTaskTotal={loadingTaskTotal}
                        />
                    </div>
                </DragDropContext>
            </div>
            <div className="hidden max-sm:block w-full">
                {listData?.map((list, index) => {
                    return (
                        <div
                            key={list?.id}
                            className={`rounded p-4 shadow-faintShadow border-l-[4px] ${
                                list?.taskTitle?.toLowerCase() === "on-track" && "border-p-40"
                            } ${
                                list?.taskTitle?.toLowerCase() === "pending" &&
                                "border-logo-task-yellow"
                            } ${list?.taskTitle?.toLowerCase() === "completed" && "border-g-50"} ${
                                list?.taskTitle?.toLowerCase() === "delayed" && "border-r-40"
                            } mb-4`}
                        >
                            <div
                                onClick={() => {
                                    if (openMobileContainer === index) {
                                        setOpenMobileContainer(null);
                                    } else {
                                        setOpenMobileContainer(index);
                                    }
                                }}
                                className="flex items-center justify-between py-2.5"
                            >
                                <div className="flex items-center gap-3">
                                    {list?.taskTitle?.toLowerCase() === "on-track" && (
                                        <i className="ri-flashlight-line text-p-40"></i>
                                    )}
                                    {list?.taskTitle?.toLowerCase() === "pending" && (
                                        <i className="ri-hourglass-line text-logo-task-yellow"></i>
                                    )}
                                    {list?.taskTitle?.toLowerCase() === "completed" && (
                                        <i className="ri-checkbox-multiple-line text-g-50"></i>
                                    )}
                                    {list?.taskTitle?.toLowerCase() === "delayed" && (
                                        <i className="ri-alarm-warning-line text-r-40"></i>
                                    )}
                                    <p className="text-sm text-gm-40 font-rocGroteskMedium">
                                        {list?.taskTitle?.toLowerCase() === "on-track" &&
                                            "Active Tasks"}
                                        {list?.taskTitle?.toLowerCase() === "pending" &&
                                            "Pending Tasks"}
                                        {list?.taskTitle?.toLowerCase() === "completed" &&
                                            "Completed Tasks"}
                                        {list?.taskTitle?.toLowerCase() === "delayed" &&
                                            "Overdue Tasks"}
                                    </p>
                                    {!loadingTaskTotal && list.taskTotal && (
                                        <div
                                            className={`border  border-slate-200 rounded-[16px] px-[10px] py-[4px]`}
                                        >
                                            <p className="text-[13px] text-gm-40 font-rocGroteskMedium">
                                                {list.taskTotal}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <i
                                    className={`ri-arrow-right-s-line text-2xl ${
                                        openMobileContainer === index && "rotate-90"
                                    }`}
                                ></i>
                            </div>

                            {openMobileContainer === index && (
                                <div
                                    onScroll={(e) => {
                                        setScrollTitle(list?.taskTitle);
                                        onScroll(
                                            e.target as HTMLElement,
                                            taskPagination?.current as number,
                                            taskPagination?.number_of_pages as number,
                                            () => {
                                                scrollTitle.toLowerCase() === "on-track"
                                                    ? setTaskActiveLimit(() => taskLimit + limit)
                                                    : scrollTitle.toLowerCase() === "pending"
                                                    ? setTaskPendingLimit(() => taskLimit + limit)
                                                    : scrollTitle.toLowerCase() === "delayed"
                                                    ? setTaskOverdueLimit(() => taskLimit + limit)
                                                    : scrollTitle.toLowerCase() === "completed"
                                                    ? setTaskCompletedLimit(() => taskLimit + limit)
                                                    : setTaskCompletedLimit(
                                                          () => taskLimit + limit
                                                      );

                                                setLoadMoreTask(true);
                                            }
                                        );
                                    }}
                                    className="flex flex-col gap-2.5 max-h-[350px] overflow-y-auto border-t-[1px] border-slate-200 pt-2.5"
                                >
                                    {list?.cardDetails?.map((card) => {
                                        return (
                                            <div
                                                key={card?._id}
                                                className="flex items-center gap-4"
                                                onClick={() => {
                                                    handleCardClick(card?._id);
                                                    setShowTaskDetails(true);
                                                }}
                                            >
                                                <VendorAvatar
                                                    size={32}
                                                    name={
                                                        card?.assignee?.fullName
                                                            ? card?.assignee?.fullName
                                                            : card?.assignee?.email
                                                    }
                                                    imageSrc={card?.assignee?.avatar}
                                                />
                                                <div>
                                                    <p className="text-xs text-slate-900 font-rocGroteskMedium leading-[24px]">
                                                        {truncate(
                                                            camelCaseToRegularCase(card?.name),
                                                            {
                                                                length: 30,
                                                            }
                                                        )}
                                                    </p>
                                                    <p className="text-sm text-slate-400 font-rocGroteskMedium">
                                                        {card?.action?.taId}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {loading && scrollTitle === list?.taskTitle && loadMoreTask && (
                                        <Loader size={5} color="p-40" />
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            {showTaskDetails && (
                <TaskDetailsModal
                    inviteMember={inviteMember}
                    setInviteMember={setInviteMember}
                    id={taskId}
                    openModal={showTaskDetails}
                    setOpenModal={setShowTaskDetails}
                    closeModal={() => {
                        setShowTaskDetails(false);
                        setTaskId("");
                    }}
                />
            )}
            <ModalContainer
                open={inviteMember}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[553px]"
                closeModal={() => {
                    setInviteMember(false);
                    setTeamInfo([{ "email-0": "", "role-0": "" }]);
                    setNumDivs(1);
                }}
            >
                <div className="bg-white px-2 pt-3 pb-10 shadow-cardShadow relative rounded-[20px]">
                    <div
                        onClick={() => {
                            setInviteMember(false);
                            setTeamInfo([{ "email-0": "", "role-0": "" }]);
                            setNumDivs(1);
                        }}
                        className="cursor-pointer px-4 flex justify-end "
                    >
                        <i className="ri-close-fill text-gm-45 text-[24px]"></i>
                    </div>
                    <div className="w-full flex justify-center">
                        <div className="lg:w-[500px] relative w-[380px]">
                            <div className="w-full  pb-3   flex text-center items-center justify-center">
                                <div className="flex text-center flex-col items-center justify-center">
                                    <p className="text-xl mb-1.5 font-rocGroteskMedium text-g-75">
                                        Invite a team member
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-gm-40">
                                        Add team members to your workspace
                                    </p>
                                    <div
                                        className="cursor-pointer  w-fit my-2"
                                        onClick={() => setShowRolesAndResponsibiltyModal(true)}
                                    >
                                        <p className="text-b-55  text-xs   font-rocGroteskMedium  underline-offset-1 underline">
                                            View roles and responsibilities
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={handleRoleSubmit} className="w-full  ">
                                <div
                                    className={`flex  flex-col max-h-[225px]  ${
                                        teamInfo?.length > 3 && " overflow-y-scroll "
                                    }  gap-4 w-full`}
                                    ref={containerRef}
                                >
                                    {teamInfo.map((inputData, index) => (
                                        <div
                                            key={index}
                                            className="flex   w-full items-center group"
                                        >
                                            <div className="w-[80%]">
                                                <TextInput
                                                    value={inputData[`email-${index}`]}
                                                    name={`email-${index}`}
                                                    type="email"
                                                    onChange={(e) => handleRoleChange(e, index)}
                                                    placeholder="Enter email address"
                                                    required={true}
                                                    inputContainerClassname="!rounded-tr-none rounded-br-none"
                                                />
                                            </div>
                                            <div className="  right-0  bg-white">
                                                <SelectInput
                                                    value={inputData[`role-${index}`] || ""}
                                                    name={`role-${index}`}
                                                    placeholder="Assign role"
                                                    handleChange={(name, value) =>
                                                        handleRoleChange(
                                                            {
                                                                target: { name, value },
                                                            },
                                                            index
                                                        )
                                                    }
                                                    handleInputChange={debounce((evt) => {
                                                        setDebouncedSearch({
                                                            search: evt.target.value,
                                                        });
                                                    }, 800)}
                                                    searchLoading={fetchingRoles}
                                                    className={`!rounded-tl-none !bg-[#FCFCFC] rounded-bl-none`}
                                                    isRequired={true}
                                                    disabled={inputData[`email-${index}`] === ""}
                                                    dropdownClassName="!py-0 "
                                                    customDropdownContainer={
                                                        <div className="w-full  py-1.5 flex flex-col gap-3.5">
                                                            <div
                                                                onClick={() =>
                                                                    setAddCustomRole(true)
                                                                }
                                                                className="flex items-center gap-1 px-3 font-rocGroteskMedium cursor-pointer w-fit"
                                                            >
                                                                <i className="ri-add-fill before:content-['\ea12'] text-p-55 text-xl mt-[-2px]"></i>
                                                                <span className="text-sm text-p-50">
                                                                    Add custom role
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                    dropdownOptions={roles?.map((item) => ({
                                                        label: item?.label,
                                                        value: item?.role,
                                                    }))}
                                                />
                                            </div>
                                            {index !== 0 && (
                                                <div className="pl-2 group-hover:block hidden">
                                                    <i
                                                        className="ri-delete-bin-line text-[20px] text-slate-400 cursor-pointer"
                                                        onClick={() => onDelete(index)}
                                                    ></i>
                                                </div>
                                            )}
                                            <ModalContainer
                                                open={addCustomRole}
                                                showCloseIcon={false}
                                                modalClassName={"py-10 px-10"}
                                                tailwindClassName="w-[513px] xl:w-[600px]"
                                                closeModal={() => {
                                                    setAddCustomRole(false);
                                                }}
                                            >
                                                <div className="bg-white rounded-lg shadow-modalShadow relative pt-5 pb-10">
                                                    <div className="flex justify-end px-10">
                                                        <span
                                                            onClick={() => setAddCustomRole(false)}
                                                            className="material-icons text-gm-50 text-xl cursor-pointer"
                                                        >
                                                            close
                                                        </span>
                                                    </div>
                                                    <div className="px-10 pt-3">
                                                        <div className="flex flex-col items-center text-center mb-[45px]">
                                                            <p className="text-2xl mb-2.5 font-rocGroteskMedium text-g-75">
                                                                Create a custom role
                                                            </p>
                                                            <p className="text-sm font-rocGroteskMedium text-gm-40">
                                                                Define what the new role’s
                                                                responsibilities are
                                                            </p>
                                                        </div>
                                                        <form className="w-full">
                                                            <TextInput
                                                                name={`role`}
                                                                type={"text"}
                                                                placeholder={"Enter role name"}
                                                                value={role}
                                                                onChange={(e) =>
                                                                    setRole(e.target.value)
                                                                }
                                                                required={true}
                                                                containerClassname={"mb-6 "}
                                                            />
                                                            <div>
                                                                <p className="text-base font-rocGroteskMedium mb-6">
                                                                    Select access levels
                                                                </p>
                                                                <div className="flex flex-wrap gap-4 relative overflow-auto">
                                                                    {accessTypes.map(
                                                                        (access, idx) => (
                                                                            <div
                                                                                key={idx}
                                                                                onClick={() =>
                                                                                    handlePermissionChange(
                                                                                        access.value
                                                                                    )
                                                                                }
                                                                                className={`px-2.5 w-fit h-[30px] ${
                                                                                    selectedPermissions.includes(
                                                                                        access.value
                                                                                    )
                                                                                        ? "!bg-carribean-green !text-white !border-carribean-green"
                                                                                        : ""
                                                                                } border flex items-center gap-2 rounded-[8px] font-rocGroteskMedium bg-white hover:!bg-g-65 hover:text-white hover:!border-g-65 border-slate-300 text-[13px] cursor-pointer`}
                                                                            >
                                                                                {selectedPermissions.includes(
                                                                                    access.value
                                                                                ) ? (
                                                                                    <i className="ri-checkbox-circle-fill text-base"></i>
                                                                                ) : (
                                                                                    <i className="ri-add-fill text-base"></i>
                                                                                )}
                                                                                {access.label}
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <Button
                                                                onClick={handleAddRole}
                                                                btnText={"Add role"}
                                                                isLoading={createRoles}
                                                                btnClassname={"my-10"}
                                                            />
                                                            <p
                                                                onClick={() =>
                                                                    setAddCustomRole(false)
                                                                }
                                                                className="text-sm font-rocGroteskMedium text-center cursor-pointer"
                                                            >
                                                                Close
                                                            </p>
                                                        </form>
                                                    </div>
                                                </div>
                                            </ModalContainer>
                                        </div>
                                    ))}
                                </div>
                                <div
                                    className="cursor-pointer  flex space-x-1 items-center w-fit my-3"
                                    onClick={addNewInputField}
                                >
                                    <i className="ri-add-fill text-b-55  text-[24px]"></i>
                                    <p className="text-b-55  text-xs font-rocGroteskMedium  underline-offset-1 underline">
                                        Invite more team members
                                    </p>
                                </div>
                                <div className="w-full mt-10">
                                    <Button
                                        btnText={"Invite member"}
                                        isLoading={addingTeamMember}
                                        type={"submit"}
                                    />
                                    <Button
                                        btnText={"Close"}
                                        onClick={() => {
                                            setInviteMember(false);
                                            setTeamInfo([{ "email-0": "", "role-0": "" }]);
                                            setNumDivs(1);
                                        }}
                                        disabled={addingTeamMember}
                                        btnClassname={"!bg-transparent !text-gm-75 mt-2"}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};
export default Board;
