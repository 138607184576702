import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
// import rocGroteskLight from "./../../../../../assets/fonts/rocGrotesk/RocGrotesk-Light.otf";
// import rocGroteskRegular from "./../../../../../assets/fonts/rocGrotesk/RocGrotesk-Regular.otf";
// import rocGroteskMedium from "./../../../../../assets/fonts/rocGrotesk/RocGrotesk-Medium.otf";
// import rocGroteskBold from "./../../../../../assets/fonts/rocGrotesk/RocGrotesk-Bold.otf";

import { convertSeconds } from "helpers/convertSeconds";

Font.register({
    family: "roc-grotesk",
    fonts: [
        {
            src: "./../../../../../assets/fonts/rocGrotesk/RocGrotesk-Light.otf",
            fontWeight: "light",
        },
        {
            src: "./../../../../../assets/fonts/rocGrotesk/RocGrotesk-Regular.otf",
            fontWeight: "normal",
        },
        {
            src: "./../../../../../assets/fonts/rocGrotesk/RocGrotesk-Medium.otf",
            fontWeight: "medium",
        },
        {
            src: "./../../../../../assets/fonts/rocGrotesk/RocGrotesk-Bold.otf",
            fontWeight: "bold",
        },
        ,
    ],
});

const styles = StyleSheet.create({
    page: {
        flexDirection: "row",
        backgroundColor: "#fff",
        color: "#000",
        fontWeight: "normal",
        fontFamily: "roc-grotesk",
    },
    pageOne: {
        flexDirection: "column",
        display: "flex",
        backgroundColor: "#fff",
        color: "#000",
        fontWeight: "normal",
        fontFamily: "roc-grotesk",
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    coverPage: {
        width: "100%",
        backgroundColor: "#0B213A",
        color: "#fff",
        flexDirection: "row",
    },
    image: {
        width: 200,
        height: 200,
        margin: 10,
    },
    boldText: {
        fontWeight: "bold",
    },
    footer: {
        paddingTop: 150,
        paddingLeft: 30,
        backgroundColor: "#0B213A",
        color: "#fff",
        width: "100%",
    },
    footerLogo: {
        paddingBottom: 80,
        width: "7%",
    },
    footerText: {
        paddingBottom: 136,
    },
    divider1: {
        backgroundColor: "#12ad68",
        height: 15,
        width: "95%",
    },
    divider2: {
        backgroundColor: "#ceaa0c",
        height: 15,
        width: "95%",
        marginTop: 100,
    },
    flex1: {
        width: "60%",
        paddingTop: 140,
    },
    flex2: {
        flexDirection: "column",
        width: "40%",
        paddingTop: 112,
        alignItems: "center",
        gap: 8,
    },
    logo1: {
        marginLeft: 10,
        paddingTop: 50,
        marginBottom: 15,
    },
    chartName: {
        marginLeft: 40,
    },
    businessLogo: {
        height: 80,
        marginBottom: 26,
    },
    times: {
        width: 18,
    },
    logo2: {
        marginTop: 26,
        width: 100,
    },
    imgWidth: {
        width: "80%",
        marginBottom: 40,
    },
    flex: {
        display: "flex",
        gap: "2",
    },
    rowDirection: {
        flexDirection: "row",
    },
    colDirection: {
        flexDirection: "column",
    },
    flexWrap: {
        flexWrap: "wrap",
    },
    alignItems: {
        alignItems: "center",
    },
    chartDiv: {
        height: "70%",
        width: "100%",
        marginTop: 20,
        marginBottom: 20,
    },
    chartValueContainer: {
        fontSize: "18",
    },
    chartValueItem: {
        marginRight: "20",
    },
    graphValues: {
        color: "#334155",
        fontSize: 16,
    },
    pageLayout: {
        padding: "30",
    },
    container: { flexDirection: "row", alignItems: "center", gap: "22px" },
    text1: {
        color: "#0F172A",
        fontSize: 18,
        fontWeight: "medium",
    },
    infoContainer: {
        borderRadius: 4,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 5,
        borderWidth: "1px",
        borderColor: "#e2e8f0",
        borderStyle: "solid",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: 4,
    },
    icon: {
        fontSize: "18pt",
        color: "#fff",
        marginRight: "3px",
        backgroundColor: "#CBD5E1",
        width: "16",
        height: "16",
        borderRadius: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    text2: {
        color: "#142837",
        fontSize: 14,
        fontWeight: "medium",
    },
    text3: {
        color: "#142837",
        fontSize: 16,
        fontWeight: "medium",
    },
    summarySection: {
        flexDirection: "row",
        backgroundColor: "#fff",
        color: "#000",
        fontWeight: "normal",
    },
    card: {
        border: "0.8px solid #e0e0e0",
        borderRadius: "8px",
        padding: "15px",
        width: "192px",
        backgroundColor: "#fff",
        margin: "10px",
        color: "#000",
        height: "100pt",
    },
    cardTitle: {
        fontSize: "12pt",
        marginBottom: "10px",
        color: "#374151",
    },
    cardValue: {
        // fontSize: "20pt",
        fontWeight: "medium",
        marginBottom: "7px",
        fontSize: 40,
        color: "#22214c",
        marginTop: "7pt",
    },
    cardPercentage: {
        fontSize: "12pt",
        color: "#757575",
    },
    icon2: {
        width: "20px",
        height: "20px",
        marginRight: "5px",
    },
});

const cardStyles = StyleSheet.create({
    container: {
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        gap: 15,
    },
    titleFlex: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 8,
    },
    card: {
        width: "250px",
        borderRadius: "4px",
        border: "1 solid #CBD5E1",
        paddingLeft: "7px",
        paddingRight: "25px",
        paddingTop: "7px",
        paddingBottom: "5px",
        height:"100px",
        marginBottom:25
    },
    cardHeader: {
        flexDirection: "row",
        paddingBottom: "10px",
        alignItems: "center",
        marginTop:7
    },
    image: {
        marginRight: "5px",
    },
    cardTitle: {
        fontWeight: "medium",
        fontSize: 10,
        color: "#475569",
    },
    cardValue: {
        fontSize: "20px",
        fontWeight: "normal",
        color: "#22214C",
        paddingTop:10
       
    },
    cardPercentage: {
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
        marginBottom: 4,
    },
    percentageImage: {
        marginRight: "5",
    },
    percentageText: {
        fontSize: 8,
        color: "#89939B",
        fontWeight: "medium",
    },
    icon: {
        width: 12,
        height: 12,
    },
    pIcon: {
        height: 6,
    },

    timeContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    timeItem: {
        marginRight: "2px",
    },
    timeValue: {
        fontSize: "20px",
        fontWeight: "medium",
        color: "#22214C",
    },
    timeUnit: {
        fontSize: "10px",
        fontWeight: "medium",
        color: "#22214C",
    },
});
const titleStyles = StyleSheet.create({
    heading: {
        fontSize: "36px",
        fontWeight: "medium",
    },
    date: {
        fontSize: "24px",
        fontWeight: "medium",
    },
    section: {
        marginBottom: "32px",
    },
    divider: {
        backgroundColor: "#F1F5F9",
        height: "1px",
        width: "100%",
    },
});

const tableStyles = StyleSheet.create({
    section: {
        marginTop: 20,
        marginBottom: 20,
    },
    headingSection: {
        marginBottom: 2,
        color: "#334155",
        fontWeight: "medium",
    },
    heading: {
        fontSize: 16,
        marginBottom: "16px",
        color: "#1E293B",
        borderBottom: "1px solid #F1F5F9",
    },
    container: {
        width: "100%",
        backgroundColor: "white",
    },

    header: {
        backgroundColor: "#F8FAFC",
        flexDirection: "row",
        justifyContent: "space-between",
        borderLeft: "1 solid #E2E8F0",
        borderTop: "1 solid #E2E8F0",
        borderRight: "1 solid #E2E8F0",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        paddingTop: 18,
        paddingLeft: 16,
        textAlign: "left",
        fontSize: 10,
        color: "#334155",
        fontWeight: "medium",
    },

    cellWdith: {
        width: "23%",
    },
    cellWdith2: {
        width: "10%",
    },
    cellWdith3: {
        width: "12%",
    },
    rounded: {
        borderRadius: 9999,
        width: 7,
        height: 7,
    },

    statusFlex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 6,
    },
    imgMargin: {
        marginTop: -4,
    },
    imgMarginTwo: {
        marginTop: 4,
        marginRight:3
    },
    cellFlex: {
        flexDirection: "row",
        gap: 4,
        alignItems: "center",
    },
    profileImg: {
        width: 16,
        marginTop: -4,
    },
});
const cyclestyles = StyleSheet.create({
    mainSection: {
        display: "flex",
        flexDirection: "row",
        gap: 20,
    },
    metric: {
        fontSize: "16px",
        fontWeight: "medium",
        marginBottom: 5,
        color: "#94A3B8",
    },
    data: {
        fontSize: "18px",
        color: "#475569",
        fontWeight: "medium",
    },
    reportSection: {
        marginTop: "30px",
    },
    noReportSection: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    },
    noReportImage: {
        width: "170px",
        height: "84px",
        marginBottom: "30px",
    },
    reportHeaderSection: {
        display: "flex",
        flexDirection: "row",
        gap: 10,
        width: "180px",
        alignContent: "flex-start",
        alignSelf: "flex-start",
        justifyContent: "center",
        paddingBottom: 7,
        marginBottom: "10px",
        borderBottom: "2 solid #E2E8F0",
    },
    reportHeader: {
        fontSize: "16px",
        fontWeight: "medium",
        color: "#475569",
        marginTop: 1,
    },
    reportText: {
        fontSize: "18px",
        marginBottom: 5,
        color: "#64748B",
        fontFamily: "roc-grotesk",
        fontWeight: "medium",
    },
    icon: {
        height: "20px",
        width: "20px",
    },
});
const pointerStyles = StyleSheet.create({
    container: {
        flexDirection: "row",
        paddingVertical: "5px",
    },
    circle: {
        width: "5px",
        height: "5px",
        borderRadius: 50,
        backgroundColor: "#64748B",
        marginTop: "5px",
        marginRight: "5px",
    },
});

const proChartStyles = StyleSheet.create({
    section: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 25,
    },
    title: {
        color: "#374151",
        fontWeight: "medium",
        fontSize: "18px",
    },
    cardContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    card: {
        flexDirection: "column",
        marginRight: 6,
    },
    label: {
        color: "#6B7280",
        fontSize: "14px",
        marginBottom: 2,
        fontWeight: "medium",

    },
    value: {
        color: "#1F2937",
        fontSize: "20px",
        fontWeight: "medium",
    },
    infoContainer: {
        flexDirection: "row",
        alignItems: "flex-start",
        alignSelf: "flex-start",
    },
    icon: {
        width: "12px",
        height: "9px",
        marginRight: 4,
        marginTop: 3,
    },
    infoText: {
        color: "#6B7280",
        fontWeight: "medium",
        fontSize: "10px",
        marginTop: 2,
    },
});

type ProcurementPDFProps = {
    year: string;
    chartName: string;
    month: string;
    filterDuration: string;
    percentageRequisitionValue: number;
    percentagePOApprovalValue: number;
    percentageGoodsValue: number;
    percentageTeamValue: number;
    chart: string;
    businessLogo: string;
    increaseRequisition: boolean;
    increaseTeam: boolean;
    increasePOApproval: boolean;
    increaseGoods: boolean;
    cycleInsightsShow: boolean;
    procurementAnalysisShow: boolean;
    requisitionTime: number;
    poApprovalTime: number;
    goodsTime: number;
    teamTime: number;
    value?: boolean;
    totalWorkflow: number | undefined;
    totalWorkflowRuns: number;
    insights: string[];
    procurementGraphDataRatio?: any;
    trendsValue?: string;
    changeValue?: string;
};

const ProcurementAnalysisPdf = ({
    year,
    chartName,
    month,
    filterDuration,
    percentageRequisitionValue,
    percentagePOApprovalValue,
    percentageGoodsValue,
    percentageTeamValue,
    chart,
    businessLogo,
    increaseRequisition,
    increaseTeam,
    increasePOApproval,
    increaseGoods,
    cycleInsightsShow,
    procurementAnalysisShow,
    requisitionTime,
    poApprovalTime,
    goodsTime,
    teamTime,
    value,
    totalWorkflow,
    totalWorkflowRuns,
    insights,
    procurementGraphDataRatio,
    trendsValue,
    changeValue,
}: ProcurementPDFProps) => {
    const {
        hours: requisitionHours,
        minutes: requisitionMinutes,
        secondsRemaining: requisitionSeconds,
    } = convertSeconds(requisitionTime);
    const {
        hours: poApprovalHours,
        minutes: poApprovalMinutes,
        secondsRemaining: poApprovalSeconds,
    } = convertSeconds(poApprovalTime);
    const {
        hours: teamHours,
        minutes: teamMinutes,
        secondsRemaining: teamSeconds,
    } = convertSeconds(teamTime);
    const {
        hours: goodsHours,
        minutes: goodsMinutes,
        secondsRemaining: goodsSeconds,
    } = convertSeconds(goodsTime);
    return (
        <Document>
            <Page size="A4" style={styles.page} orientation="landscape">
                <View style={styles.coverPage}>
                    <View style={styles.flex1}>
                        <View style={styles.divider1} />
                        <View style={styles.divider2} />
                        <Image
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702357166/synthAlly_dcohmp.png"
                            style={styles.logo1}
                        />
                        <Text style={styles.chartName}>{`Procurement Analysis - ${year}`}</Text>
                    </View>
                    <View style={styles.flex2}>
                        {businessLogo && <Image src={businessLogo} style={styles.businessLogo} />}

                        <Image
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702359704/X_mfthox.png"
                            style={styles.times}
                        />
                        <Image
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702357366/synthAllylogo_fhimzk.png"
                            style={styles.logo2}
                        />
                    </View>
                </View>
            </Page>
            {procurementAnalysisShow && (
                <Page
                    size="A4"
                    style={[styles.pageOne, styles?.pageLayout]}
                    orientation="landscape"
                >
                    <View>
                        <View style={[titleStyles.section]}>
                            <Text style={titleStyles.heading}>{`${chartName}`}</Text>
                                <Text style={titleStyles.date}>{`${year}`}</Text>
                        </View>
                        <View style={cardStyles.container}>
                            <View style={cardStyles.card}>
                                <View style={cardStyles.cardHeader}>
                                    <View style={cardStyles.titleFlex}>
                                        <Image
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1704362439/icon_one_show.png"
                                            style={[cardStyles.icon, tableStyles.imgMargin]}
                                        />
                                        <Text style={cardStyles.cardTitle}>
                                            Av. time for requisition to PO
                                        </Text>
                                    </View>
                                </View>
                                <Text style={cardStyles.cardValue}>
                                    {requisitionTime === 0 ? (
                                        0
                                    ) : (requisitionMinutes !== 0 ||
                                          requisitionSeconds !== 0 ||
                                          requisitionHours !== 0) &&
                                      !value ? (
                                        <View style={cardStyles.timeContainer}>
                                            {requisitionHours !== 0 && (
                                                <View style={cardStyles.timeItem}>
                                                    <Text style={cardStyles.timeValue}>
                                                        {requisitionHours}
                                                        <Text style={cardStyles.timeUnit}>
                                                            {requisitionHours === 1 ? "hr" : "hrs"}
                                                        </Text>
                                                    </Text>
                                                </View>
                                            )}
                                            {requisitionMinutes !== 0 && (
                                                <View style={cardStyles.timeItem}>
                                                    <Text style={cardStyles.timeValue}>
                                                        {requisitionMinutes}
                                                        <Text style={cardStyles.timeUnit}>
                                                            {requisitionMinutes === 1
                                                                ? "min"
                                                                : "mins"}
                                                        </Text>
                                                    </Text>
                                                </View>
                                            )}
                                            {!requisitionHours && requisitionSeconds !== 0 && (
                                                <View style={cardStyles.timeItem}>
                                                    <Text style={cardStyles.timeValue}>
                                                        {requisitionSeconds}
                                                        <Text style={cardStyles.timeUnit}>
                                                            {requisitionSeconds === 1
                                                                ? "sec"
                                                                : "secs"}
                                                        </Text>
                                                    </Text>
                                                </View>
                                            )}
                                        </View>
                                    ) : (
                                        <Text>----</Text>
                                    )}
                                </Text>
                                <View style={[cardStyles.cardPercentage, tableStyles.statusFlex]}>
                                    {increaseRequisition ? (
                                        <Image
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702621597/arrowUp_wfxvaj.png"
                                            style={[cardStyles.pIcon, tableStyles.imgMargin]}
                                        />
                                    ) : (
                                        <Image
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702621381/arrowDown_l4wmfy.png"
                                            style={[cardStyles.pIcon, tableStyles.imgMargin]}
                                        />
                                    )}

                                    <Text style={cardStyles.percentageText}>
                                        {`${percentageRequisitionValue}% ${
                                            increaseRequisition ? "up" : "down"
                                        } this ${filterDuration} `}
                                    </Text>
                                </View>
                            </View>
                            <View style={cardStyles.card}>
                                <View style={cardStyles.cardHeader}>
                                    <View style={cardStyles.titleFlex}>
                                        <Image
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1704362544/icon_four_show.png"
                                            style={[cardStyles.icon, tableStyles.imgMargin]}
                                        />
                                        <Text style={cardStyles.cardTitle}>
                                            Av. time for PO approval
                                        </Text>
                                    </View>
                                </View>
                                <Text style={cardStyles.cardValue}>
                                    {poApprovalTime === 0 ? (
                                        0
                                    ) : (poApprovalMinutes !== 0 ||
                                          poApprovalSeconds !== 0 ||
                                          poApprovalHours !== 0) &&
                                      !value ? (
                                        <View style={cardStyles.timeContainer}>
                                            {poApprovalHours !== 0 && (
                                                <View style={cardStyles.timeItem}>
                                                    <Text style={cardStyles.timeValue}>
                                                        {poApprovalHours}
                                                        <Text style={cardStyles.timeUnit}>
                                                            {poApprovalHours === 1 ? "hr" : "hrs"}
                                                        </Text>
                                                    </Text>
                                                </View>
                                            )}
                                            {poApprovalMinutes !== 0 && (
                                                <View style={cardStyles.timeItem}>
                                                    <Text style={cardStyles.timeValue}>
                                                        {poApprovalMinutes}
                                                        <Text style={cardStyles.timeUnit}>
                                                            {poApprovalMinutes === 1
                                                                ? "min"
                                                                : "mins"}
                                                        </Text>
                                                    </Text>
                                                </View>
                                            )}
                                            {!poApprovalHours && poApprovalSeconds !== 0 && (
                                                <View style={cardStyles.timeItem}>
                                                    <Text style={cardStyles.timeValue}>
                                                        {poApprovalSeconds}
                                                        <Text style={cardStyles.timeUnit}>
                                                            {poApprovalSeconds === 1
                                                                ? "sec"
                                                                : "secs"}
                                                        </Text>
                                                    </Text>
                                                </View>
                                            )}
                                        </View>
                                    ) : (
                                        <Text>----</Text>
                                    )}
                                </Text>
                                <View style={[cardStyles.cardPercentage, tableStyles.statusFlex]}>
                                    {increasePOApproval ? (
                                        <Image
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702621597/arrowUp_wfxvaj.png"
                                            style={[cardStyles.pIcon, tableStyles.imgMargin]}
                                        />
                                    ) : (
                                        <Image
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702621381/arrowDown_l4wmfy.png"
                                            style={[cardStyles.pIcon, tableStyles.imgMargin]}
                                        />
                                    )}

                                    <Text style={cardStyles.percentageText}>
                                        {`${percentagePOApprovalValue}% ${
                                            increasePOApproval ? "up" : "down"
                                        } this ${filterDuration} `}
                                    </Text>
                                </View>
                            </View>
                            <View style={cardStyles.card}>
                                <View style={cardStyles.cardHeader}>
                                    <View style={cardStyles.titleFlex}>
                                        <Image
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1704362515/icon_three_show.png"
                                            style={[cardStyles.icon, tableStyles.imgMargin]}
                                        />
                                        <Text style={cardStyles.cardTitle}>
                                            Av. goods delivery timeline
                                        </Text>
                                    </View>
                                </View>
                                <Text style={cardStyles.cardValue}>
                                    {goodsTime === 0 ? (
                                        0
                                    ) : (goodsMinutes !== 0 ||
                                          goodsSeconds !== 0 ||
                                          goodsHours !== 0) &&
                                      !value ? (
                                        <View style={cardStyles.timeContainer}>
                                            {goodsHours !== 0 && (
                                                <View style={cardStyles.timeItem}>
                                                    <Text style={cardStyles.timeValue}>
                                                        {goodsHours}
                                                        <Text style={cardStyles.timeUnit}>
                                                            {goodsHours === 1 ? "hr" : "hrs"}
                                                        </Text>
                                                    </Text>
                                                </View>
                                            )}
                                            {goodsMinutes !== 0 && (
                                                <View style={cardStyles.timeItem}>
                                                    <Text style={cardStyles.timeValue}>
                                                        {goodsMinutes}
                                                        <Text style={cardStyles.timeUnit}>
                                                            {goodsMinutes === 1 ? "min" : "mins"}
                                                        </Text>
                                                    </Text>
                                                </View>
                                            )}
                                            {!goodsHours && goodsSeconds !== 0 && (
                                                <View style={cardStyles.timeItem}>
                                                    <Text style={cardStyles.timeValue}>
                                                        {goodsSeconds}
                                                        <Text style={cardStyles.timeUnit}>
                                                            {goodsSeconds === 1 ? "sec" : "secs"}
                                                        </Text>
                                                    </Text>
                                                </View>
                                            )}
                                        </View>
                                    ) : (
                                        <Text>----</Text>
                                    )}
                                </Text>
                                <View style={[cardStyles.cardPercentage, tableStyles.statusFlex]}>
                                    {increaseGoods ? (
                                        <Image
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702621597/arrowUp_wfxvaj.png"
                                            style={[cardStyles.pIcon, tableStyles.imgMargin]}
                                        />
                                    ) : (
                                        <Image
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702621381/arrowDown_l4wmfy.png"
                                            style={[cardStyles.pIcon, tableStyles.imgMargin]}
                                        />
                                    )}

                                    <Text style={cardStyles.percentageText}>
                                        {`${percentageGoodsValue}% ${
                                            increaseGoods ? "up" : "down"
                                        } this ${filterDuration} `}
                                    </Text>
                                </View>
                            </View>
                            <View style={cardStyles.card}>
                                <View style={cardStyles.cardHeader}>
                                    <View style={cardStyles.titleFlex}>
                                        <Image
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1704362544/icon_four_show.png"
                                            style={[cardStyles.icon, tableStyles.imgMargin]}
                                        />
                                        <Text style={cardStyles.cardTitle}>
                                            Av. team time efficiency
                                        </Text>
                                    </View>
                                </View>
                                <Text style={cardStyles.cardValue}>
                                    {teamTime === 0 ? (
                                        0
                                    ) : (teamMinutes !== 0 ||
                                          teamSeconds !== 0 ||
                                          teamHours !== 0) &&
                                      !value ? (
                                        <View style={cardStyles.timeContainer}>
                                            {teamHours !== 0 && (
                                                <View style={cardStyles.timeItem}>
                                                    <Text style={cardStyles.timeValue}>
                                                        {teamHours}
                                                        <Text style={cardStyles.timeUnit}>
                                                            {teamHours === 1 ? "hr" : "hrs"}
                                                        </Text>
                                                    </Text>
                                                </View>
                                            )}
                                            {teamMinutes !== 0 && (
                                                <View style={cardStyles.timeItem}>
                                                    <Text style={cardStyles.timeValue}>
                                                        {teamMinutes}
                                                        <Text style={cardStyles.timeUnit}>
                                                            {teamMinutes === 1 ? "min" : "mins"}
                                                        </Text>
                                                    </Text>
                                                </View>
                                            )}
                                            {!teamHours && teamSeconds !== 0 && (
                                                <View style={cardStyles.timeItem}>
                                                    <Text style={cardStyles.timeValue}>
                                                        {teamSeconds}
                                                        <Text style={cardStyles.timeUnit}>
                                                            {teamSeconds === 1 ? "sec" : "secs"}
                                                        </Text>
                                                    </Text>
                                                </View>
                                            )}
                                        </View>
                                    ) : (
                                        <Text>----</Text>
                                    )}
                                </Text>
                                <View style={[cardStyles.cardPercentage, tableStyles.statusFlex]}>
                                    {increaseTeam ? (
                                        <Image
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702621597/arrowUp_wfxvaj.png"
                                            style={[cardStyles.pIcon, tableStyles.imgMargin]}
                                        />
                                    ) : (
                                        <Image
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702621381/arrowDown_l4wmfy.png"
                                            style={[cardStyles.pIcon, tableStyles.imgMargin]}
                                        />
                                    )}

                                    <Text style={cardStyles.percentageText}>
                                        {`${percentageTeamValue}% ${
                                            increaseTeam ? "up" : "down"
                                        } this ${filterDuration} `}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View>
                            <View>
                                <View style={proChartStyles.section}>
                                </View>
                                <View style={proChartStyles.cardContainer}>
                                    <View style={proChartStyles.card}>
                                        <Text style={proChartStyles.label}>
                                            Procurement efficiency ratio
                                        </Text>
                                        <Text style={proChartStyles.value}>
                                            {procurementGraphDataRatio?.currentValue}%
                                        </Text>
                                        <View style={proChartStyles.infoContainer}>
                                            <Image
                                                src={
                                                    procurementGraphDataRatio?.percentageChanges <=
                                                    0
                                                        ? "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702621381/arrowDown_l4wmfy.png"

                                                        : "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702621597/arrowUp_wfxvaj.png"
                                                }
                                                style={[cardStyles.pIcon, tableStyles.imgMarginTwo]}
                                            />
                                            <Text style={proChartStyles.infoText}>
                                                {procurementGraphDataRatio?.percentageChanges}%{" "}
                                                {procurementGraphDataRatio?.percentageChanges <= 0
                                                    ? "down"
                                                    : "up"}{" "}
                                                this {changeValue}
                                            </Text>
                                        </View>
                                    </View>
                                    <View>
                                        <View style={proChartStyles.infoContainer}>
                                            <Image
                                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1707644282/Vector_jcce6r.png"
                                                style={proChartStyles.icon}
                                            />
                                            <Text style={proChartStyles.infoText}>
                                                Efficiency trend this {trendsValue}
                                            </Text>
                                        </View>
                                        <View style={proChartStyles.infoContainer}>
                                            <Image
                                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1707644287/Vector_311_korwef.png"
                                                style={proChartStyles.icon}
                                            />
                                            <Text style={proChartStyles.infoText}>
                                                Efficiency trend last {trendsValue}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={[styles.chartDiv, styles.flex, styles.alignItems]}>
                                <Image src={chart} />
                            </View>
                        </View>
                    </View>
                </Page>
            )}
            {cycleInsightsShow && (
                <Page
                    size="A4"
                    style={[styles.pageOne, styles?.pageLayout]}
                    orientation="landscape"
                >
                    {totalWorkflow === 0 && totalWorkflowRuns === 0 ? (
                        <View style={cyclestyles.noReportSection}>
                            <Image
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1707538239/Frame_2613301_fu8y02.png"
                                style={cyclestyles.noReportImage}
                            />
                            <Text> No report generated yet</Text>
                        </View>
                    ) : (
                        <View>
                            <View style={cyclestyles.mainSection}>
                                <View>
                                    <Text style={cyclestyles.metric}>Total workflows</Text>
                                    <Text style={cyclestyles.data}>{totalWorkflow}</Text>
                                </View>
                                <View>
                                    <Text style={cyclestyles.metric}>Total workflow runs</Text>
                                    <Text style={cyclestyles.data}>
                                        {totalWorkflowRuns} workflow runs
                                    </Text>
                                </View>
                            </View>
                            <View style={cyclestyles.reportSection}>
                                <View style={cyclestyles.reportHeaderSection}>
                                    <View style={cyclestyles.icon}>
                                        <Image
                                            src={
                                                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702952051/Frame_55865_mnt1bw.png"
                                            }
                                        />
                                    </View>
                                    <Text style={cyclestyles.reportHeader}>Efficiency Report</Text>
                                </View>
                                <View>
                                    {insights?.map((content, idx) => (
                                        <View style={pointerStyles.container}>
                                            <View style={pointerStyles.circle}></View>
                                            <Text key={idx} style={cyclestyles.reportText}>
                                                {content}
                                            </Text>
                                        </View>
                                    ))}
                                </View>
                            </View>
                        </View>
                    )}
                </Page>
            )}
            <Page size="A4" style={styles.page} orientation="landscape">
                <View style={styles.footer}>
                    <View style={styles.imgWidth}>
                        <Image src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702358337/thanks_yjmk0q.png" />
                    </View>
                    <View style={[styles.flex, styles.rowDirection]}>
                        <Text style={styles.boldText}>Address:</Text>
                        <Text> 7000 North Mopac Expressway, Austin TX 78759</Text>
                    </View>
                    <View style={[styles.flex, styles.rowDirection]}>
                        <Text style={styles.boldText}>Phone Number:</Text>
                        <Text> +18329972582</Text>
                    </View>
                    <View style={[styles.flex, styles.rowDirection]}>
                        <Text style={styles.boldText}>E-Mail:</Text>
                        <Text style={styles.footerText}> hello@tradeally.io</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};
export default ProcurementAnalysisPdf;
