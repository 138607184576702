import Button from "components/common/Button";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import { formatMoney } from "helpers";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

type RoiCalculatorProps = {
    isWebsite?: boolean;
};

const RoiCalculator = ({ isWebsite }: RoiCalculatorProps) => {
    const [roi, setRoi] = useState<number | string>("");
    const navigate = useNavigate();

    const [roiData, setRoiData] = useState<{ [key: string]: any }>({
        currency: "USD",
        avgHourSpent: "",
        plan: "",
        noOfScEmployees: "",
        avgMonthlyWage: "",
        noOfWeeklyWorkHours: "",
        avgHourWage: "",
        weeklyManualCost: "",
        monthlyManualCost: "",
        annualManualCost: "",
        monthlyCostSavings: "",
        annualCostSavings: "",
        weeklyCostSavings: "",
    });

    useEffect(() => {
        if (
            roiData?.avgMonthlyWage &&
            roiData?.noOfWeeklyWorkHours &&
            roiData.noOfScEmployees &&
            roiData?.plan
        ) {
            setRoiData((prev) => ({
                ...prev,
                weeklyCostSavings:
                    ((roiData?.plan === "pro" && 5) || (roiData?.plan === "business" && 30)) *
                    (Number(roiData.avgMonthlyWage) / (Number(roiData.noOfWeeklyWorkHours) * 4)) *
                    Number(roiData.noOfScEmployees),
            }));
        }
    }, [
        roiData.avgMonthlyWage,
        roiData.noOfWeeklyWorkHours,
        roiData?.plan,
        roiData.noOfScEmployees,
    ]);

    useEffect(() => {
        if (roiData?.weeklyCostSavings) {
            setRoiData((prev) => ({
                ...prev,
                monthlyCostSavings: Number(roiData?.weeklyCostSavings) * 4,
            }));
        }
    }, [roiData?.weeklyCostSavings]);

    useEffect(() => {
        if (roiData?.monthlyCostSavings) {
            setRoiData((prev) => ({
                ...prev,
                annualCostSavings: Number(roiData?.monthlyCostSavings) * 12,
            }));
        }
    }, [roiData?.monthlyCostSavings]);

    useEffect(() => {
        if (roiData.avgMonthlyWage && roiData.noOfWeeklyWorkHours) {
            setRoiData((prev) => ({
                ...prev,
                avgHourWage:
                    Number(roiData.avgMonthlyWage) / (Number(roiData.noOfWeeklyWorkHours) * 4),
            }));
        }
    }, [roiData.avgMonthlyWage, roiData.noOfWeeklyWorkHours]);

    useEffect(() => {
        if (roiData?.avgHourSpent && roiData?.avgHourWage && roiData?.noOfScEmployees) {
            setRoiData((prev) => ({
                ...prev,
                weeklyManualCost:
                    Number(roiData?.avgHourSpent) *
                    Number(roiData?.avgHourWage) *
                    Number(roiData?.noOfScEmployees),
            }));
        }
    }, [roiData?.avgHourSpent, roiData?.avgHourWage, roiData?.noOfScEmployees]);

    useEffect(() => {
        if (roiData?.weeklyManualCost) {
            setRoiData((prev) => ({
                ...prev,
                monthlyManualCost: Number(roiData?.weeklyManualCost) * 4,
            }));
        }
    }, [roiData?.weeklyManualCost]);

    useEffect(() => {
        if (roiData?.monthlyManualCost) {
            setRoiData((prev) => ({
                ...prev,
                annualManualCost: Number(roiData?.monthlyManualCost) * 12,
            }));
        }
    }, [roiData?.monthlyManualCost]);

    useEffect(() => {
        if (roiData?.annualCostSavings && roiData?.plan === "pro") {
            setRoi((Number(roiData?.annualCostSavings) / 1140) * 100);
        }
        if (roiData?.annualCostSavings && roiData?.plan === "business") {
            setRoi((Number(roiData?.annualCostSavings) / 6480) * 100);
        }
    }, [roiData?.annualCostSavings, roiData?.plan]);

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        if (!isNaN(value)) {
            setRoiData((prev) => {
                return {
                    ...prev,
                    [name]: value,
                };
            });
        }
    };

    const onResetCalculator = () => {
        setRoiData({
            currency: "USD",
            avgHourSpent: "",
            noOfScEmployees: "",
            avgMonthlyWage: "",
            noOfWeeklyWorkHours: "",
            avgHourWage: "",
            weeklyManualCost: "",
            monthlyManualCost: "",
            annualManualCost: "",
            monthlyCostSavings: "",
            annualCostSavings: "",
            weeklyCostSavings: "",
        });
        setRoi("");
    };
    return (
        <div className="mt-6 pb-10 flex lg:flex-row flex-col gap-8 justify-between w-full">
            <div className="lg:w-[63%] bg-white h-fit w-full px-3 md:px-8 py-8 rounded-lg shadow-faintShadow font-rocGroteskMedium  text-gm-50">
                <p className="text-gm-50 mb-[32px] text-base font-rocGroteskMedium">
                    ROI (Return on Investment) calculator
                </p>
                <div className={`${isWebsite ? "space-y-8" : "space-y-4"}`}>
                    <div>
                        <div className="flex  md:flex-row flex-col md:space-y-0 space-y-4 gap-3 ">
                            <SelectInput
                                floatingPlaceholderClass={"!font-rocGroteskMedium !text-gm-50"}
                                className={"!border-g-60 !border"}
                                value={roiData.currency}
                                disabled={true}
                                name="currency"
                                placeholder={"Your currency"}
                                // handleChange={(name, value) => handleChange("===")}
                                dropdownOptions={[
                                    {
                                        label: "GBP (£)",
                                        value: "GBP",
                                    },
                                    {
                                        label: "USD ($)",
                                        value: "USD",
                                    },
                                    {
                                        label: "EUR (€)",
                                        value: "EUR",
                                    },
                                    {
                                        label: "NGN (₦)",
                                        value: "NGN",
                                    },
                                ]}
                            />
                            <SelectInput
                                floatingPlaceholderClass={"!font-rocGroteskMedium !text-gm-50"}
                                className={"!border-g-60 !border"}
                                value={roiData.plan}
                                isRequired
                                name="plan"
                                placeholder={"Subscription tier (yearly)"}
                                handleChange={(name, value) =>
                                    setRoiData((prev) => ({ ...prev, plan: value }))
                                }
                                dropdownOptions={[
                                    {
                                        label: "Pro (1140)",
                                        value: "pro",
                                    },
                                    {
                                        label: "Business + (6480)",
                                        value: "business",
                                    },
                                ]}
                            />
                        </div>
                        {roiData?.plan === "business" && (
                            <div className="border w-full mt-3 drop-shadow-sm bg-[#F0FDF4] shadow-dropCardShadow border-[#4ADE80] p-3 rounded">
                                <p className="text-sm text-slate-700 font-rocGroteskMedium">
                                    Considering the Business+ plan? It offers your business up to 30
                                    hours of saved time, every week
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="md:block hidden">
                        <TextInput
                            value={roiData.avgHourSpent}
                            floatingPlaceholderClass={"!font-rocGroteskMedium !text-gm-50"}
                            type={"text"}
                            name="avgHourSpent"
                            inputContainerClassname={"!border-g-60 !border"}
                            placeholder={
                                "Average hours spent on manual planning & sourcing activities weekly per supply chain (SC) employee"
                            }
                            maxLength={12}
                            onChange={handleChange}
                            required={true}
                        />
                    </div>
                    <div className="md:hidden block">
                        <TextInput
                            value={roiData.avgHourSpent}
                            floatingPlaceholderClass={"!font-rocGroteskMedium !text-gm-50"}
                            type={"text"}
                            name="avgHourSpent"
                            inputContainerClassname={"!border-g-60 !border"}
                            placeholder={
                                "Av. hours spent on manual activities per supply chain (SC) employee"
                            }
                            maxLength={12}
                            onChange={handleChange}
                            required={true}
                        />
                    </div>

                    <TextInput
                        type={"text"}
                        value={roiData.noOfScEmployees}
                        floatingPlaceholderClass={"!font-rocGroteskMedium !text-gm-50"}
                        name="noOfScEmployees"
                        inputContainerClassname={"!border-g-60 !border"}
                        placeholder={"No. of SC employees on team"}
                        onChange={handleChange}
                        required={true}
                        maxLength={12}
                    />
                    <div className="flex md:flex-row flex-col md:space-y-0 space-y-4 gap-3 ">
                        <div className="md:hidden block w-full">
                            <TextInput
                                value={roiData?.avgMonthlyWage}
                                floatingPlaceholderClass={"!font-rocGroteskMedium !text-gm-50"}
                                type={"text"}
                                name="avgMonthlyWage"
                                inputContainerClassname={"!border-g-60 !border"}
                                placeholder={"Av. monthly wage per SC employee"}
                                onChange={handleChange}
                                required={true}
                                maxLength={12}
                            />
                        </div>
                        <div className="md:block hidden w-full">
                            <TextInput
                                value={roiData?.avgMonthlyWage}
                                floatingPlaceholderClass={"!font-rocGroteskMedium !text-gm-50"}
                                type={"text"}
                                name="avgMonthlyWage"
                                inputContainerClassname={"!border-g-60 !border"}
                                placeholder={"Average monthly wage per SC employee"}
                                onChange={handleChange}
                                required={true}
                                maxLength={12}
                            />
                        </div>
                        <div className="w-full">
                            <TextInput
                                value={roiData?.noOfWeeklyWorkHours}
                                floatingPlaceholderClass={"!font-rocGroteskMedium !text-gm-50"}
                                type={"text"}
                                name="noOfWeeklyWorkHours"
                                inputContainerClassname={"!border-g-60 !border"}
                                placeholder={"No. of weekly work hours per SC employee"}
                                onChange={handleChange}
                                required={true}
                                maxLength={12}
                            />
                        </div>
                    </div>
                    <div className="md:flex hidden">
                        <TextInput
                            value={roiData?.avgHourWage}
                            floatingPlaceholderClass={"!font-rocGroteskMedium !text-gm-50"}
                            type={"text"}
                            name="avgHourWage"
                            disabled={true}
                            inputContainerClassname={"!border-g-60 !border"}
                            placeholder={
                                "Average hourly wage per SC employee (Average monthly wage/(No. of work hours weekly * 4)"
                            }
                            onChange={handleChange}
                        />
                    </div>
                    <div className="md:hidden block">
                        <TextInput
                            value={roiData?.avgHourWage}
                            floatingPlaceholderClass={"!font-rocGroteskMedium !text-gm-50"}
                            type={"text"}
                            name="avgHourWage"
                            disabled={true}
                            inputContainerClassname={"!border-g-60 !border"}
                            placeholder={"Av. hourly wage per SC employee"}
                            onChange={handleChange}
                        />
                        <div className="flex space-x-1">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702632483/Group_55823_fhkwup.svg"
                                alt="icon"
                            />
                            <p className="text-[8px] text-gm-50 font-rocGroteskMedium">
                                Calculated as (Av. monthly wage/No. of hours weekly) * 4)
                            </p>
                        </div>
                    </div>
                    <div className="flex md:flex-row flex-col md:space-y-0 space-y-4 gap-3 ">
                        <TextInput
                            type={"text"}
                            value={formatMoney()?.format(roiData?.weeklyManualCost)}
                            floatingPlaceholderClass={"!font-rocGroteskMedium !text-gm-50"}
                            disabled={true}
                            name="weeklyManualCost"
                            inputContainerClassname={"!border-g-60 !border"}
                            placeholder={"Weekly manual cost"}
                            onChange={handleChange}
                        />
                        <TextInput
                            value={formatMoney()?.format(roiData?.monthlyManualCost)}
                            floatingPlaceholderClass={"!font-rocGroteskMedium !text-gm-50"}
                            type={"text"}
                            disabled={true}
                            name="monthlyManualCost"
                            inputContainerClassname={"!border-g-60 !border"}
                            placeholder={"Monthly manual cost"}
                            onChange={handleChange}
                        />
                    </div>
                    <TextInput
                        value={formatMoney()?.format(roiData?.annualManualCost)}
                        floatingPlaceholderClass={"!font-rocGroteskMedium !text-gm-50"}
                        type={"text"}
                        disabled={true}
                        name="annualManualCost"
                        inputContainerClassname={"!border-g-60 !border"}
                        placeholder={"Yearly manual cost"}
                        onChange={handleChange}
                    />
                </div>
                <div className="mt-8 pb-4">
                    <Button
                        onClick={onResetCalculator}
                        btnText={"Reset calculator"}
                        icon={<i className="ri-restart-line"></i>}
                        btnClassname={"!w-fit !py-2 !bg-slate-100 !text-gm-50 cursor-pointer"}
                    />
                </div>
            </div>
            <div className="lg:w-[35%] flex flex-col h-fit my-auto w-full">
                <p className="text-gm-50 text-center pt-1 font-rocGroteskMedium text-base">
                    SynthAlly's Cost Savings
                </p>
                <div className="shadow-faintShadow rounded-tl-md mt-4 rounded-tr-md border border-slate-100  bg-[white]">
                    <div className="py-2 rounded-tl-md  mb-8 rounded-tr-md bg-p-50"></div>
                    <div className="flex flex-col mx-8 justify-center items-center space-y-2 border-b border-slate-100 pb-8">
                        <p className="text-[10px] text-gm-50 font-rocGroteskMedium">
                            YOUR RETURN ON INVESTMENT
                        </p>
                        <p className="text-[32px] leading-[46.0px]">
                            {roi === ""
                                ? "---"
                                : formatMoney()?.format(Number(Number(roi).toFixed(1)))}
                            %/
                            <span className="text-slate-500 text-[22px] leading-[31.68px]">
                                year
                            </span>
                        </p>
                    </div>
                    <div className="px-8 text-sm pb-6 mb-6">
                        <div className="flex w-full  py-4 border-slate-100 border-b justify-between space-x-2">
                            <p className=" w-[70%] text-gm-50">
                                SynthAlly's Guaranteed minimum weekly time savings
                            </p>
                            <p className="w-[30%] text-g-50 flex justify-end">
                                {roiData?.plan === "pro" && "5hrs"}
                                {roiData?.plan === "business" && "30hrs"}
                                {roiData?.plan !== "business" &&
                                    roiData?.plan !== "pro" &&
                                    " ------------"}
                            </p>
                        </div>
                        <div className="flex w-full border-slate-100 border-b py-4 justify-between space-x-2">
                            <p className=" w-[70%] text-gm-50">Weekly cost savings</p>
                            <p className="w-[30%] text-g-50 flex justify-end">
                                {roiData?.weeklyCostSavings !== "" &&
                                !isNaN(roiData?.weeklyCostSavings)
                                    ? `$${formatMoney()?.format(
                                          Number(Number(roiData?.weeklyCostSavings).toFixed(1))
                                      )}`
                                    : " ------------"}
                            </p>
                        </div>
                        <div className="flex w-full border-slate-100 border-b py-4 justify-between space-x-2">
                            <p className=" w-[70%] text-gm-50">Monthly cost savings</p>
                            <p className="w-[30%] text-g-50 flex justify-end">
                                {roiData?.monthlyCostSavings !== ""
                                    ? `$${formatMoney()?.format(
                                          Number(Number(roiData?.monthlyCostSavings).toFixed(1))
                                      )}`
                                    : " ------------"}
                            </p>
                        </div>
                        <div className="flex w-full border-slate-100 border-b py-4 justify-between space-x-2">
                            <p className=" w-[70%] text-gm-50">Yearly cost savings</p>
                            <p className="w-[30%] text-g-50 flex justify-end">
                                {roiData?.annualCostSavings !== ""
                                    ? `$${formatMoney()?.format(
                                          Number(Number(roiData?.annualCostSavings).toFixed(1))
                                      )}`
                                    : " ------------"}
                            </p>
                        </div>
                        <div className="flex w-full border-slate-100 border-b py-4 justify-between space-x-2">
                            <p className=" w-[70%] text-gm-50">Subscription tier</p>
                            <p className="w-[30%] text-g-50 flex justify-end">
                                {roiData?.plan === "pro" && "$1,140"}
                                {roiData?.plan === "business" && "$6,480"}
                                {roiData?.plan !== "business" &&
                                    roiData?.plan !== "pro" &&
                                    " ------------"}
                            </p>
                        </div>
                        <div className="flex w-full py-4 justify-between space-x-2">
                            <p className=" w-[70%] flex flex-col text-gm-50">
                                % ROI
                                <span className="text-xs">
                                    (Net Benefit / Cost of Investment) x 100
                                </span>
                            </p>
                            <p className="w-[30%] text-g-50 flex justify-end">
                                {roi === ""
                                    ? " ------------"
                                    : `${formatMoney()?.format(Number(Number(roi).toFixed(1)))}`}
                            </p>
                        </div>
                        {isWebsite && (
                            <Button
                                onClick={() => (window.location.href = "/book-a-demo#ROI")}
                                btnText={"Book a demo"}
                                btnClassname={"!py-2 cursor-pointer !mt-8"}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default RoiCalculator;
