import BreadCrumb from "components/common/BreadCrumb";
import TabsContainer from "components/common/TabsContainer";
import PageFrame from "components/layout/PageFrame";
import Board from "components/views/Ldb/Dashboard/Tasks/Board";
import TaskLists from "components/views/Ldb/Dashboard/Tasks/TaskList";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { taskActions } from "redux/Ldb/actions/taskActions";
import NewTasksModal from "../../../components/views/Ldb/Dashboard/Tasks/NewTasksModal";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { authActions } from "redux/Ldb/actions";
import toast from "react-hot-toast";
import ModalContainer from "components/common/ModalContainer";
import TextInput from "components/common/InputField/TextInput";
import Button from "components/common/Button";
import CustomToast from "components/common/CustomToast";
import SelectInput from "components/common/InputField/SelectInput";
import { debounce } from "lodash";
import { accessTypes } from "variables";
import { getFromStorage } from "helpers";

const Task = () => {
    const { id } = useParams();
    const [params] = useSearchParams();
    const newTask = params.get("new");
    const containerRef = useRef<HTMLDivElement>(null);
    const [addTasks, setAddTasks] = useState(false);
    const [inviteMember, setInviteMember] = useState<boolean>(false);
    const [showRolesAndResponsibiltyModal, setShowRolesAndResponsibiltyModal] =
        useState<boolean>(false);
    const [userProfile, setUserProfile] = useState(() => getFromStorage("ally-user"));
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
    const [roles, setRoles] = useState<{ [key: string]: any }[]>([]);
    const [role, setRole] = useState<string>("");
    const [numDivs, setNumDivs] = useState<number>(1);
    const [debouncedSearch, setDebouncedSearch] = useState<{ search: string }>({
        search: "",
    });
    const [addCustomRole, setAddCustomRole] = useState<boolean>(false);
    const [teamInfo, setTeamInfo] = useState<{ [key: string]: any }[]>([
        { "email-0": "", "role-0": "" },
    ]);
    const {
        addingTeamMember,
        fetchingRoles,
        fetchedRolesSuccess,
        createRoles,
        createRolesSuccess,
    } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { fetchOverdueTaskTwoSuccess, fetchingOverdueTaskTwoList } = useAppSelector(
        (state) => state.task
    );
    const [currentTab, setCurrentTab] = useState(0);
    const tabs = ["Board", "Task list"];

    const displayActiveTab = () => {
        switch (currentTab) {
            case 0:
                return <Board />;

            case 1:
                return <TaskLists />;

            default:
                return;
        }
    };

    useEffect(() => {
        if (id) {
            setCurrentTab(parseInt(id));
        }
    }, [id]);

    useEffect(() => {
        dispatch(taskActions.fetchOverdueTaskTwoList(1, "delayed"));
    }, [dispatch]);
    useEffect(() => {
        if (!Boolean(fetchedRolesSuccess)) {
            dispatch(authActions.getRoles(20, "", debouncedSearch?.search));
        }
    }, [dispatch, debouncedSearch?.search]);
    useEffect(() => {
        if (inviteMember) {
            setAddTasks(false);
        }
    }, [inviteMember]);

    const addNewInputField = () => {
        const newInputField = {
            email: "",
            role: "",
            permissions: "",
        };
        setTeamInfo([...teamInfo, newInputField]);
    };

    const handleAddRole = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const permission = selectedPermissions;
        if (role === "") {
            toast.custom((t) => (
                <CustomToast t={t} message={"Role name is required"} type="error" />
            ));
        }
        if (role !== "") {
            const teamMember = {
                role,
                ...(permission.length > 0 && { permission }),
            };
            dispatch(authActions.createRoles(teamMember));
        }
    };

    useEffect(() => {
        if (Boolean(createRolesSuccess)) {
            setSelectedPermissions([]);
            setAddCustomRole(false);
            dispatch(authActions.resetCreateRoles());
            dispatch(authActions.getRoles(20));
        }
    }, [createRolesSuccess, dispatch]);

    useEffect(() => {
        if (Boolean(fetchedRolesSuccess)) {
            setRoles(fetchedRolesSuccess?.rolesList);
        }
    }, [fetchedRolesSuccess]);

    const handlePermissionChange = (permission: string) => {
        const isSelected = selectedPermissions.includes(permission);

        if (isSelected) {
            const updatedPermissions = selectedPermissions.filter((item) => item !== permission);
            setSelectedPermissions(updatedPermissions);
        } else {
            setSelectedPermissions([...selectedPermissions, permission]);
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            const lastItem = (container as any).lastChild;
            (container as any).scrollTop = lastItem.offsetTop;
        }
    }, [teamInfo]);

    const onDelete = (index: number) => {
        const updatedTeamInfo = [...teamInfo];
        updatedTeamInfo.splice(index, 1);
        setTeamInfo(updatedTeamInfo);
    };

    const handleRoleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: { [key: string]: any }[] = [];
        const filteredTeamInfo = teamInfo.filter(
            (member, index) => member[`email-${index}`] && member[`role-${index}`]
        );

        filteredTeamInfo.map((member, index) => {
            const teamMember = {
                email: member[`email-${index}`],
                role: member[`role-${index}`],
            };

            body.push(teamMember);
        });

        const teamsBody = { teamMembers: body };

        dispatch(authActions.addTeamMember(teamsBody, userProfile?.token));
    };

    const handleRoleChange = (
        e: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } },
        index: number
    ) => {
        const { name, value } = e.target;
        const updatedTeamInfo = [...teamInfo];
        updatedTeamInfo[index] = {
            ...updatedTeamInfo[index],
            [name]: value,
        };
        setTeamInfo(updatedTeamInfo);
    };

    useEffect(() => {
        if (Boolean(newTask)) {
            setAddTasks(true);
            navigate("/dashboard/task/0", { replace: true });
        }
    }, [newTask]);

    return (
        <PageFrame containerClassName={"h-full w-full"}>
            {addTasks && (
                <NewTasksModal
                    openModal={addTasks}
                    closeModal={() => setAddTasks(!addTasks)}
                    inviteMember={inviteMember}
                    setInviteMember={setInviteMember}
                />
            )}

            {/* <TaskDetailsModal /> */}

            <div className="mx-7 h-full fixed w-[76.5%] 2xl:w-[77.5%] max-lg:relative max-lg:mx-0 max-lg:w-full">
                <div className="mb-6 space-x-5  flex item-center ">
                    <div>
                        <BreadCrumb
                            parentCrumb={"My tasks"}
                            size="large"
                            parentClassName="!text-slate-700 !text-lg"
                        />
                    </div>
                    {fetchOverdueTaskTwoSuccess?.totalCount !== 0 &&
                        !fetchingOverdueTaskTwoList && (
                            <div
                                className="flex items-center py-[4px] rounded-[20px] cursor-pointer space-x-2 px-[15px] bg-r-25"
                                onClick={() => navigate("/dashboard/task/1?show=delayed")}
                            >
                                <i className="ri-error-warning-fill text-r-70"></i>
                                <p className="text-slate-900 text-xs font-rocGroteskRegular">
                                    {fetchOverdueTaskTwoSuccess?.totalCount} overdue tasks
                                </p>
                            </div>
                        )}
                </div>
                <div className="mt-2">
                    <TabsContainer
                        tabs={tabs}
                        showTabMenu={"task"}
                        activeTab={currentTab}
                        setActiveTab={setCurrentTab}
                        component={displayActiveTab()}
                        className={"!px-1"}
                        showButton={
                            <Button
                                btnText={"Add new task"}
                                btnClassname="!py-2 !px-4 max-lg:mt-3"
                                isLoading={false}
                                onClick={() => {
                                    setAddTasks(true);
                                }}
                            />
                        }
                    />
                </div>
            </div>
            <ModalContainer
                open={inviteMember}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[553px]"
                closeModal={() => {
                    setInviteMember(false);
                    setTeamInfo([{ "email-0": "", "role-0": "" }]);
                    setNumDivs(1);
                }}
            >
                <div className="bg-white px-2 pt-3 pb-10 shadow-cardShadow relative rounded-[20px]">
                    <div
                        onClick={() => {
                            setInviteMember(false);
                            setTeamInfo([{ "email-0": "", "role-0": "" }]);
                            setNumDivs(1);
                        }}
                        className="cursor-pointer px-4 flex justify-end "
                    >
                        <i className="ri-close-fill text-gm-45 text-[24px]"></i>
                    </div>
                    <div className="w-full flex justify-center">
                        <div className="lg:w-[500px] relative w-[380px]">
                            <div className="w-full  pb-3   flex text-center items-center justify-center">
                                <div className="flex text-center flex-col items-center justify-center">
                                    <p className="text-xl mb-1.5 font-rocGroteskMedium text-g-75">
                                        Invite a team member
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-gm-40">
                                        Add team members to your workspace
                                    </p>
                                    <div
                                        className="cursor-pointer  w-fit my-2"
                                        onClick={() => setShowRolesAndResponsibiltyModal(true)}
                                    >
                                        <p className="text-b-55  text-xs   font-rocGroteskMedium  underline-offset-1 underline">
                                            View roles and responsibilities
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={handleRoleSubmit} className="w-full  ">
                                <div
                                    className={`flex  flex-col max-h-[225px]  ${
                                        teamInfo?.length > 3 && " overflow-y-scroll "
                                    }  gap-4 w-full`}
                                    ref={containerRef}
                                >
                                    {teamInfo.map((inputData, index) => (
                                        <div
                                            key={index}
                                            className="flex   w-full items-center group"
                                        >
                                            <div className="w-[80%]">
                                                <TextInput
                                                    value={inputData[`email-${index}`]}
                                                    name={`email-${index}`}
                                                    type="email"
                                                    onChange={(e) => handleRoleChange(e, index)}
                                                    placeholder="Enter email address"
                                                    required={true}
                                                    inputContainerClassname="!rounded-tr-none rounded-br-none"
                                                />
                                            </div>
                                            <div className="  right-0  bg-white">
                                                <SelectInput
                                                    value={inputData[`role-${index}`] || ""}
                                                    name={`role-${index}`}
                                                    placeholder="Assign role"
                                                    handleChange={(name, value) =>
                                                        handleRoleChange(
                                                            {
                                                                target: { name, value },
                                                            },
                                                            index
                                                        )
                                                    }
                                                    handleInputChange={debounce((evt) => {
                                                        setDebouncedSearch({
                                                            search: evt.target.value,
                                                        });
                                                    }, 800)}
                                                    searchLoading={fetchingRoles}
                                                    className={`!rounded-tl-none !bg-[#FCFCFC] rounded-bl-none`}
                                                    isRequired={true}
                                                    disabled={inputData[`email-${index}`] === ""}
                                                    dropdownClassName="!py-0 "
                                                    customDropdownContainer={
                                                        <div className="w-full  py-1.5 flex flex-col gap-3.5">
                                                            <div
                                                                onClick={() =>
                                                                    setAddCustomRole(true)
                                                                }
                                                                className="flex items-center gap-1 px-3 font-rocGroteskMedium cursor-pointer w-fit"
                                                            >
                                                                <i className="ri-add-fill before:content-['\ea12'] text-p-55 text-xl mt-[-2px]"></i>
                                                                <span className="text-sm text-p-50">
                                                                    Add custom role
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                    dropdownOptions={roles?.map((item) => ({
                                                        label: item?.label,
                                                        value: item?.role,
                                                    }))}
                                                />
                                            </div>
                                            {index !== 0 && (
                                                <div className="pl-2 group-hover:block hidden">
                                                    <i
                                                        className="ri-delete-bin-line text-[20px] text-slate-400 cursor-pointer"
                                                        onClick={() => onDelete(index)}
                                                    ></i>
                                                </div>
                                            )}
                                            <ModalContainer
                                                open={addCustomRole}
                                                showCloseIcon={false}
                                                modalClassName={"py-10 px-10"}
                                                tailwindClassName="w-[513px] xl:w-[600px]"
                                                closeModal={() => {
                                                    setAddCustomRole(false);
                                                }}
                                            >
                                                <div className="bg-white rounded-lg shadow-modalShadow relative pt-5 pb-10">
                                                    <div className="flex justify-end px-10">
                                                        <span
                                                            onClick={() => setAddCustomRole(false)}
                                                            className="material-icons text-gm-50 text-xl cursor-pointer"
                                                        >
                                                            close
                                                        </span>
                                                    </div>
                                                    <div className="px-10 pt-3">
                                                        <div className="flex flex-col items-center text-center mb-[45px]">
                                                            <p className="text-2xl mb-2.5 font-rocGroteskMedium text-g-75">
                                                                Create a custom role
                                                            </p>
                                                            <p className="text-sm font-rocGroteskMedium text-gm-40">
                                                                Define what the new role’s
                                                                responsibilities are
                                                            </p>
                                                        </div>
                                                        <form className="w-full">
                                                            <TextInput
                                                                name={`role`}
                                                                type={"text"}
                                                                placeholder={"Enter role name"}
                                                                value={role}
                                                                onChange={(e) =>
                                                                    setRole(e.target.value)
                                                                }
                                                                required={true}
                                                                containerClassname={"mb-6 "}
                                                            />
                                                            <div>
                                                                <p className="text-base font-rocGroteskMedium mb-6">
                                                                    Select access levels
                                                                </p>
                                                                <div className="flex flex-wrap gap-4 relative overflow-auto">
                                                                    {accessTypes.map(
                                                                        (access, idx) => (
                                                                            <div
                                                                                key={idx}
                                                                                onClick={() =>
                                                                                    handlePermissionChange(
                                                                                        access.value
                                                                                    )
                                                                                }
                                                                                className={`px-2.5 w-fit h-[30px] ${
                                                                                    selectedPermissions.includes(
                                                                                        access.value
                                                                                    )
                                                                                        ? "!bg-carribean-green !text-white !border-carribean-green"
                                                                                        : ""
                                                                                } border flex items-center gap-2 rounded-[8px] font-rocGroteskMedium bg-white hover:!bg-g-65 hover:text-white hover:!border-g-65 border-slate-300 text-[13px] cursor-pointer`}
                                                                            >
                                                                                {selectedPermissions.includes(
                                                                                    access.value
                                                                                ) ? (
                                                                                    <i className="ri-checkbox-circle-fill text-base"></i>
                                                                                ) : (
                                                                                    <i className="ri-add-fill text-base"></i>
                                                                                )}
                                                                                {access.label}
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <Button
                                                                onClick={handleAddRole}
                                                                btnText={"Add role"}
                                                                isLoading={createRoles}
                                                                btnClassname={"my-10"}
                                                            />
                                                            <p
                                                                onClick={() =>
                                                                    setAddCustomRole(false)
                                                                }
                                                                className="text-sm font-rocGroteskMedium text-center cursor-pointer"
                                                            >
                                                                Close
                                                            </p>
                                                        </form>
                                                    </div>
                                                </div>
                                            </ModalContainer>
                                        </div>
                                    ))}
                                </div>
                                <div
                                    className="cursor-pointer  flex space-x-1 items-center w-fit my-3"
                                    onClick={addNewInputField}
                                >
                                    <i className="ri-add-fill text-b-55  text-[24px]"></i>
                                    <p className="text-b-55  text-xs font-rocGroteskMedium  underline-offset-1 underline">
                                        Invite more team members
                                    </p>
                                </div>
                                <div className="w-full mt-10">
                                    <Button
                                        btnText={"Invite member"}
                                        isLoading={addingTeamMember}
                                        type={"submit"}
                                    />
                                    <Button
                                        btnText={"Close"}
                                        onClick={() => {
                                            setInviteMember(false);
                                            setTeamInfo([{ "email-0": "", "role-0": "" }]);
                                            setNumDivs(1);
                                        }}
                                        disabled={addingTeamMember}
                                        btnClassname={"!bg-transparent !text-gm-75 mt-2"}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};
export default Task;
