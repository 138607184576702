import React from "react";
import GoogleMapReact from "google-map-react";
import {useEffect, useState} from "react";
import Loader from "../Loader";

type MapProps = {
    lat: number;
    long: number;
    locationData: {lat: number; lng: number; name: string}[];
    onMapMarkerClick: (marker: {lat: number; lng: number; name: string}) => void;
    mapLoading: boolean;
};

type MarkerProps = {
    text?: string;
    onClick: () => void;
    lat: number;
    lng: number;
};

export const Map = ({lat, long, locationData, onMapMarkerClick, mapLoading}: MapProps) => {
    const [defaultCenter, setDefaultCenter] = useState({
        lat: lat,
        lng: long,
    });
    const defaultProps = {
        center: {
            lat: locationData?.slice(-1)?.[0]?.lat || lat,
            lng: locationData?.slice(-1)?.[0]?.lng || long,
        },
        zoom: 5,
    };

    useEffect(() => {
        if (locationData?.slice(-1)?.[0]?.lat) {
            setDefaultCenter({
                lat: locationData?.slice(-1)?.[0]?.lat,
                lng: locationData?.slice(-1)?.[0]?.lng,
            });
        }
    }, [locationData?.slice(-1)?.[0]?.lat]);

    if (mapLoading) {
        return (
            <div style={{height: "100%", width: "100%", borderRadius: "50%"}}>
                <Loader size={6} />
            </div>
        );
    }

    return (
        <div style={{height: "100%", width: "100%", borderRadius: "50%"}}>
            <GoogleMapReact
                defaultCenter={defaultCenter}
                defaultZoom={defaultProps.zoom}
                options={{
                    disableDefaultUI: true,
                    mapId: "da34cfe7a3f21d74",
                    zoomControl: true,
                    fullscreenControl: true,
                    zoomControlOptions: {position: 5},
                }}
            >
                {locationData.map((marker, index) => (
                    <Marker
                        key={index}
                        onClick={() => onMapMarkerClick(marker)}
                        lat={marker.lat}
                        lng={marker.lng}
                        text={marker.name}
                    />
                ))}
            </GoogleMapReact>
        </div>
    );
};

const Marker = ({text, onClick}: MarkerProps) => (
    <div
        onClick={onClick}
        className="h-[20px] w-[20px] cursor-pointer bg-btnColor bg-[#5452BF] border-[#9a98f1] border-[3px] rounded-full"
    ></div>
);
