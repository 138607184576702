import React from "react";
import { Link } from "react-router-dom";

type BreadCrumbProps = {
    parentCrumb: string;
    parentUrl?: string;
    parentClassName?: string;
    childClassName?: string;
    childrenCrumb?: {
        title: string;
        path: string;
    }[];
    size?: "large" | "small";
};

const BreadCrumb = ({
    parentCrumb,
    parentClassName,
    parentUrl,
    childrenCrumb,
    size,
    childClassName,
}: BreadCrumbProps) => {
    return (
        <div
            className={`flex items-center gap-1 font-rocGroteskMedium ${
                size?.toLowerCase() === "large" && "text-xl"
            } ${size?.toLowerCase() === "small" && "text-sm"}`}
        >
            <Link
                to={parentUrl as string}
                className={` ${parentClassName}  ${
                    childrenCrumb && childrenCrumb?.length > 0 && "max-lg:hidden"
                }`}
            >
                {parentCrumb}
            </Link>
            <Link
                to={
                    childrenCrumb && childrenCrumb?.length > 1
                        ? (childrenCrumb?.[childrenCrumb?.length - 2]?.path as string)
                        : (parentUrl as string)
                }
                className={`${
                    childrenCrumb &&
                    childrenCrumb?.length > 0 &&
                    childrenCrumb?.[0]?.path !== parentUrl &&
                    "max-lg:block"
                } hidden`}
            >
                <i className={`ri-arrow-left-s-line text-xl lg:mr-5 `}></i>
            </Link>
            <p className="hidden max-sm:block capitalize mt-[1px]">
                {childrenCrumb?.[childrenCrumb?.length - 1]?.title}
            </p>
            {childrenCrumb?.map((crumb, idx) => {
                return (
                    <div className={`max-sm:hidden`} key={crumb?.path + "-" + idx}>
                        {idx !== childrenCrumb.length && (
                            <i
                                className={`ri-arrow-right-s-line ${
                                    idx !== childrenCrumb.length && "text-slate-400"
                                } ${idx === 0 && "max-lg:hidden"}`}
                            ></i>
                        )}
                        <Link
                            to={crumb?.path}
                            key={crumb?.title + idx}
                            className={`${
                                idx !== childrenCrumb.length - 1
                                    ? "text-slate-400"
                                    : "text-slate-700"
                            }  ${childClassName} capitalize`}
                        >
                            {crumb?.title}
                        </Link>
                    </div>
                );
            })}
        </div>
    );
};

export default BreadCrumb;
