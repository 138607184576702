import useGetUserProfile from "hooks/useGetUserProfile";
import React, { useEffect, useState } from "react";

const SidebarLinks = () => {
    const [profile] = useGetUserProfile();

    const [sideBarLinks, setSideBarLinks] = useState([]);

    useEffect(() => {
        const links = [
            {
                title: "Home",
                icons: "ri-home-3-line",
                link: "/dashboard",
                permissions: [],
            },
            {
                title: "Workflow",
                icons: "ri-flow-chart",
                link: "/dashboard/workflow",
                permissions: ["workflow_view", "workflow_edit", "all_access"],
            },
            {
                title: "My tasks",
                icons: "ri-task-line",
                link: "/dashboard/task/0",
                permissions: [],
            },
            // {
            //     title: "Co-pilot",
            //     icons: "ri-robot-line",
            //     link: "/dashboard/co-pilot",
            // },
            {
                title: "Supplier management",
                icons: "ri-building-line",
                link: [
                    "/dashboard/suppliers",
                    "/dashboard/suppliers/analytics",
                    "/dashboard/supplier",
                    "/suppliers",
                    "/suppliers/analytics",
                    "/supplier",
                ],
                children: [
                    {
                        title: "Supplier list",
                        link: "/dashboard/suppliers",
                    },
                ],
                permissions: ["supplier_view", "supplier_edit", "all_access"],
            },
            {
                title: "Orders",
                icons: "ri-file-list-3-line",
                link: [
                    "/dashboard/orders",
                    "/dashboard/rfqs",
                    "/dashboard/quotes",
                    "/dashboard/purchase-orders",
                    "/dashboard/rfq",
                    "/orders",
                    "/rfqs",
                    "/quotes",
                    "/purchase-orders",
                    "/rfq",
                ],
                children: [
                    {
                        title: "Order List",
                        link: "/dashboard/orders",
                    },
                    {
                        title: "RFQs",
                        link: "/dashboard/rfqs",
                    },
                    {
                        title: "Quotes",
                        link: "/dashboard/quotes",
                    },
                    {
                        title: "Purchase Orders",
                        link: "/dashboard/purchase-orders",
                    },
                ],
                permissions: ["purchase_order_view", "purchase_order_edit", "all_access"],
            },
            {
                title: "Shipment",
                icons: "ri-box-1-line",
                link: "/dashboard/shipment",
                permissions: ["operations_edit", "shipment_view", "shipment_edit", "all_access"],
            },

            {
                title: "Inventory",
                icons: "ri-archive-2-line",
                link: [
                    "/dashboard/inventory",
                    "/dashboard/inventory/stock-control",
                    "/dashboard/inventory/warehouse",
                    "/dashboard/inventory/stock-control/transfer-stock",
                    "/inventory",
                    "/inventory/stock-control",
                    "/inventory/stock-control/transfer-stock",
                    "/inventory/warehouse",
                ],
                children: [
                    {
                        title: "Product List",
                        link: "/dashboard/inventory",
                    },
                    {
                        title: "Stock Control",
                        link: "/dashboard/inventory/stock-control",
                    },
                    {
                        title: "Warehouse Management",
                        link: "/dashboard/inventory/warehouse",
                    },
                ],
                permissions: ["inventory_view", "inventory_edit", "all_access"],
            },
            {
                title: "Business analytics",
                icons: "ri-slideshow-line",
                link: [
                    "/dashboard/analytics/purchase-orders",
                    "/dashboard/analytics/procurement-cycle",
                    "/dashboard/analytics/spend-analysis",
                    "/dashboard/analytics/commodity-report",
                    "/analytics/purchase-orders",
                    "/analytics/procurement-cycle",
                    "/analytics/spend-analysis",
                    "/analytics/commodity-report",
                ],
                children: [
                    {
                        title: "Purchase orders",
                        link: "/dashboard/analytics/purchase-orders",
                    },
                    {
                        title: "Procurement cycle",
                        link: "/dashboard/analytics/procurement-cycle",
                    },
                    {
                        title: "Spend analysis",
                        link: "/dashboard/analytics/spend-analysis",
                    },
                    // {
                    //     title: "Commodity report",
                    //     link: "/dashboard/analytics/commodity-report",
                    // },
                ],
                permissions: ["analytics_view", "analytics_edit", "all_access"],
            },
            {
                title: "Teams",
                icons: "ri-team-line",
                link: "/dashboard/team-management",
                permissions: ["team_view", "team_edit", "all_access"],
            },
            // {
            //     title: "Settings",
            //     icons: "ri-settings-4-line",
            //     link: "/dashboard/settings",
            //permissions: []
            // },
        ];

        const filteredLinks = links?.filter((link) => {
            const allowedRoutes =
                link?.permissions?.length === 0 ||
                link?.permissions?.some((permission) => profile?.permission?.includes(permission));

            return allowedRoutes;
        });
        setSideBarLinks(filteredLinks);
    }, [profile]);
    return sideBarLinks;
};

export default SidebarLinks;
