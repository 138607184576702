import CustomTable from "components/common/CustomTable";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { onScroll } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { truncate } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";

type Props = {
    productSearch: string;
    filter: string;
};

const ProductList = ({ productSearch, filter }: Props) => {
    const limit = 20;
    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [productLimit, setProductLimit] = useState(limit);
    const [allProducts, setAllProducts] = useState<{ [key: string]: any }[]>([]);
    const [loadMoreProducts, setLoadMoreProducts] = useState(false);
    const [productPagination, setProductPagination] = useState<{
        current: number | string;
        number_of_pages: number | string;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        fetchingSearchInventoryListing,
        fetchedSearchInventoryListingSuccess,
        fetchedSearchInventoryListingFailure,
    } = useAppSelector((state) => state.inventory);

    const dashboardContent = document.getElementById("dashboardContent");

    const pageLoaded = useMemo(() => isPageLoaded, [isPageLoaded]);

    const tableHeader = [
        { title: "Product name", widthClass: "w-[312px]" },
        { title: "Product Category", widthClass: "w-[200px]" },
        { title: "Unit of Measure", widthClass: "w-[128px]" },
        { title: "Stock Level", widthClass: "w-[100px]" },
        { title: "SKU", widthClass: "w-[110px]" },
        { title: "Vendor/Supplier", widthClass: "w-[191px]" },
        { title: "Warehouse", widthClass: "w-[270px]" },
    ];

    const tableBody = allProducts?.map((product, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center gap-3">
                        <VendorAvatar
                            imageSrc={product?.productImageDetails?.productAvatar}
                            size={30}
                            name={product?.productName}
                        />
                        <div>
                            <p className="capitalize">
                                {truncate(product?.productName, { length: 25 })}
                            </p>
                            <p className="text-slate-500">{product?.sku}</p>
                        </div>
                    </div>
                </div>
            ),
            cellClickAction: () => navigate(`/dashboard/inventory/product/${product?._id}`),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p>{product?.inventoryCategory}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p>{product?.productStockDetails?.unitOfMeasurement}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p>{product?.productStockDetails?.stockLevel || 0}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 flex items-center h-full font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p>{product?.sku}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center gap-3">
                        <VendorAvatar
                            imageSrc={product?.vendors?.[0]?.profile?.avatar}
                            size={30}
                            name={"Vendor"}
                        />
                        <span>
                            {truncate(product?.vendors?.[0]?.profile?.businessName, { length: 15 })}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p>{product?.productStockSettings?.[0]?.warehouse?.warehouseName}</p>
                </div>
            ),
        },
    ]);

    const onProductTableVerticalScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                productPagination?.current as number,
                productPagination?.number_of_pages as number,
                () => {
                    setProductLimit(() => productLimit + limit);
                    setLoadMoreProducts(true);
                }
            ),
        [dashboardContent, productPagination]
    );

    const handleScroll = (e) => {
        if (e.target.scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        dispatch(
            inventoryActions.fetchSearchInventoryListing(productLimit, productSearch, id, filter)
        );
    }, [dispatch, productLimit, productSearch, id, filter]);

    useEffect(() => {
        if (fetchedSearchInventoryListingFailure !== "") {
            setIsPageLoaded(true);
        }
    }, [fetchedSearchInventoryListingFailure]);

    useEffect(() => {
        if (Boolean(fetchedSearchInventoryListingSuccess)) {
            setAllProducts(fetchedSearchInventoryListingSuccess?.inventory?.data);
            setProductPagination({
                current: fetchedSearchInventoryListingSuccess?.pagination?.current,
                number_of_pages: fetchedSearchInventoryListingSuccess?.pagination?.number_of_pages,
            });
            setIsPageLoaded(true);
        }
    }, [fetchedSearchInventoryListingSuccess]);

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onProductTableVerticalScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onProductTableVerticalScroll);
        };
    }, [dashboardContent, onProductTableVerticalScroll]);

    return (
        <div className="overflow-x-scroll w-full">
            {!pageLoaded ? (
                <Loader />
            ) : (
                <>
                    {allProducts?.length > 0 ? (
                        <>
                            <div
                                className="overflow-x-scroll hidden lg:block"
                                onScroll={handleScroll}
                            >
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isScrollable={true}
                                    isScrolled={isScrolled}
                                    isCellBordered={false}
                                    headerContainerClass="!bg-slate-50"
                                    headerItemClass="font-rocGroteskMedium !text-[#334155]"
                                    tableClass="!border-collapse"
                                />
                            </div>
                            {loadMoreProducts && fetchingSearchInventoryListing && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {productPagination?.current === productPagination?.number_of_pages && (
                                <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                    <p className="text-center"> End of list</p>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="flex items-center justify-center mt-[76px]">
                            <div>
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1704890969/Tradeally_2.0/empty_state_img_a8twwl.svg"
                                    alt="empty state"
                                    className="w-[268px] h-[235px]"
                                />
                                <p className="text-center text-sm text-[#475569] font-rocGroteskMedium mt-5">
                                    You have no product
                                </p>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ProductList;
