/* eslint-disable */
import Button from "components/common/Button";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authActions, inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import moment from "moment";
import PageFrame from "components/layout/PageFrame";
import SelectInput from "components/common/InputField/SelectInput";
import { debounce, truncate } from "lodash";
import TextInput from "components/common/InputField/TextInput";
import DateInput from "components/common/DateInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import CustomTable from "components/common/CustomTable";
import ModalContainer from "components/common/ModalContainer";
import { displayFileIcon, getCurrencyFromCurrencyCode } from "helpers";
import { firebaseService } from "services/firebaseService";
import useGetUserProfile from "hooks/useGetUserProfile";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import Loader from "components/common/Loader";
import ReactCountryFlag from "react-country-flag";
import countries from "variables/countries";
import AddressInput from "components/common/InputField/AddressInput";
import VendorAvatar from "components/common/VendorAvatar";

const RecordSuppliedStock = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [profile] = useGetUserProfile();
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [workflowApprovalModal, setWorkflowApprovalModal] = useState<boolean>(false);
    const [suppliedStockData, setSuppliedStockData] = useState<{ [key: string]: any }>({});
    const [productData, setProductData] = useState<{ [key: string]: any }[]>([{}]);
    const [dateOfSupply, setDateOfSupply] = useState<Date | null>();
    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [deliveryTimeline, setDeliveryTimeline] = useState<Date | null>();
    const [showCalendarTwo, setShowCalendarTwo] = useState<boolean>(false);
    const [attachment, setAttachment] = useState([]);
    const [address, setAddress] = useState<{ [key: string]: any }>({});
    const [uploadingAttachment, setUploadingAttachment] = useState<boolean>(false);
    const [mobile, setMobile] = useState<string>("");
    const [response, setResponse] = useState<any>();
    const [productList, setProductList] = useState<{ [key: string]: any }>([]);
    const [debounceProductSearch, setDebounceProductSearch] = useState<string>();
    const [debounceWarehouseSearch, setDebounceWarehouseSearch] = useState<string>();
    const [warehouses, setWarehouses] = useState<any>();
    const [teamMembers, setTeamMembers] = useState<{ [key: string]: any }>([]);
    const [debounceTeamMemberSearch, setDebounceTeamMemberSearch] = useState<string>();

    const { fetchedTeamMemberSuccess, fetchingTeamMember } = useAppSelector((state) => state.auth);

    const {
        fetchingWarehouses,
        fetchedWarehousesSuccess,
        addingSuppliedStock,
        addedSuppliedStockSuccess,
        fetchingStockList,
        fetchedStockListSuccess,
    } = useAppSelector((state) => state?.inventory);

    useEffect(() => {
        if (Boolean(fetchedStockListSuccess)) {
            setProductList(fetchedStockListSuccess?.stock);
        }
    }, [fetchedStockListSuccess]);

    useEffect(() => {
        if (Boolean(fetchedWarehousesSuccess)) {
            setWarehouses(fetchedWarehousesSuccess?.warehouses.data);
        }
    }, [fetchedWarehousesSuccess]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamMembers(fetchedTeamMemberSuccess?.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        dispatch(authActions.getTeamMembers("", debounceTeamMemberSearch));
    }, [dispatch, debounceTeamMemberSearch]);

    useEffect(() => {
        if (suppliedStockData?.warehouseId) {
            dispatch(
                inventoryActions.fetchStockList(
                    20,
                    debounceProductSearch,
                    suppliedStockData?.warehouseId
                )
            );
        } else {
            setProductList([]);
        }
    }, [dispatch, debounceProductSearch, suppliedStockData?.warehouseId]);

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouses(20, debounceWarehouseSearch));
    }, [dispatch, debounceWarehouseSearch]);

    const handleDateChange = (newDate) => {
        setDateOfSupply(newDate);
    };
    const handleTimelineChange = (newDate) => {
        setDeliveryTimeline(newDate);
    };

    useEffect(() => {
        if (deliveryTimeline) {
            setSuppliedStockData({
                ...suppliedStockData,
                deliveryTimeline: {
                    start: deliveryTimeline[0]?.toISOString(),
                    end: deliveryTimeline[1]?.toISOString(),
                },
            });
        }
    }, [deliveryTimeline]);

    useEffect(() => {
        if (productData?.length > 0) {
            const newProductData = productData?.map(({ cost, ...rest }) => rest);
            setSuppliedStockData({
                ...suppliedStockData,
                data: newProductData,
            });
        }
    }, [productData]);

    useEffect(() => {
        if (dateOfSupply) {
            setSuppliedStockData({
                ...suppliedStockData,
                dateOfSupply: dateOfSupply?.toISOString(),
            });
        }
    }, [dateOfSupply]);

    useEffect(() => {
        if (attachment?.length > 0) {
            setSuppliedStockData({
                ...suppliedStockData,
                documents: attachment,
            });
        }
    }, [attachment]);

    useEffect(() => {
        if (address?.Location?.address)
            setSuppliedStockData({
                ...suppliedStockData,
                deliveryAddress: address?.Location,
            });
    }, [address]);

    useEffect(() => {
        if (mobile)
            setSuppliedStockData((prev) => ({
                ...prev,
                customerDetails: {
                    ...prev?.customerDetails,
                    mobile,
                },
            }));
    }, [mobile]);

    const handleSuppliedStockData = (e) => {
        const { name, value } = e.target;
        const customerDetails = [
            "name",
            "email",
            "companyName",
            "country",
            "rawMobile",
            "mobileExtension",
        ];
        setSuppliedStockData((prev: any) => {
            if (customerDetails?.includes(name)) {
                return {
                    ...prev,
                    customerDetails: {
                        ...prev?.customerDetails,
                        [name]: value,
                    },
                };
            } else {
                return { ...prev, [name]: value };
            }
        });
    };

    useEffect(() => {
        if (
            suppliedStockData?.customerDetails?.mobileExtension ||
            suppliedStockData?.customerDetails?.rawMobile
        ) {
            setMobile(
                `${suppliedStockData?.customerDetails?.mobileExtension}${suppliedStockData?.customerDetails?.rawMobile}`
            );
        }
    }, [suppliedStockData?.customerDetails]);

    const handleSaveStock = () => {
        dispatch(inventoryActions?.addSuppliedStock(suppliedStockData));
    };

    useEffect(() => {
        if (addedSuppliedStockSuccess) {
            setResponse(addedSuppliedStockSuccess?.records);
            setSuccessModal(true);
            dispatch(inventoryActions.resetAddSuppliedStock());
        }
    }, [addedSuppliedStockSuccess]);

    const handleProductDataChange = (e, idx) => {
        const { name, value } = e.target;
        const copyProductData = [...productData];
        if (name === "inventoryId") {
            const inventoryId = value?.split(" ");
            const getSelectedProduct = productList?.filter(
                (item) => item?.inventoryId === inventoryId[0]
            );
            copyProductData[idx] = {
                ...copyProductData[idx],
                inventoryId: inventoryId[0],
                cost: `${getSelectedProduct[0]?.cost?.amount}`,
            };
        } else {
            copyProductData[idx][name] = value;
        }

        setProductData(copyProductData);
    };

    const handleUpload = (fileName: string, file: File) => {
        if (fileName && file) {
            setUploadingAttachment(true);
            firebaseService.uploadFile(
                `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/inventory/${
                    profile?.profile?.businessName
                }/createdByDocument/${fileName}`,
                file,
                (url) => {
                    setAttachment((prev) => [...prev, { url: url, name: fileName }]);

                    setUploadingAttachment(false);
                }
            );
        }
    };

    const deleteAttachment = (idx) => {
        const newArr = [...attachment];
        const imagePath = decodeURIComponent(
            newArr[idx]?.url?.split("/o/")?.[1]?.split("?alt")?.[0]
        );

        firebaseService.removeFile(imagePath, () => {
            newArr?.splice(idx, 1);
            setAttachment(newArr);
        });
    };
    const handleDeleteProduct = (idx) => {
        if (productData?.length > 1) {
            const copyProductData = [...productData];
            copyProductData.splice(idx, 1);
            setProductData(copyProductData);
        }
    };

    const handleAddNewRow = () => {
        const newArr = [...productData];
        newArr.push({
            inventoryId: "",
            quantity: "",
        });
        setProductData(newArr);
    };

    const tableHeader = [
        { title: "No", widthClass: "w-[7%] !font-rocGroteskMedium " },
        { title: "Product name", widthClass: "w-[54%] !font-rocGroteskMedium" },
        { title: "Qty", widthClass: "w-[9%] !font-rocGroteskMedium" },
        { title: "Unit cost", widthClass: "w-[13%] !font-rocGroteskMedium" },
        { title: "Total cost", widthClass: "w-[12%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[4%] !font-rocGroteskMedium" },
    ];
    const tableBody = productData?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        className={`w-full flex items-center gap-2 border-slate-100 text-sm text-slate-700 `}
                    >
                        {idx + 1}
                    </div>
                ),
            },
            {
                content: (
                    <div className={`w-full  border-slate-100 text-sm text-slate-700 `}>
                        <SelectInput
                            value={item?.inventoryId || ""}
                            name="inventoryId"
                            inputPlaceholder={"Select"}
                            handleChange={(name, value) =>
                                handleProductDataChange({ target: { name, value } }, idx)
                            }
                            isRequired={true}
                            className="!border-0 !rounded-none"
                            inputContainerClassName="!mb-0"
                            searchLoading={fetchingStockList}
                            clearValue={true}
                            hideSelectedIcon={false}
                            dropdownOptions={productList?.map((item) => ({
                                label: item?.inventory?.productName,
                                icon: (
                                    <div className="h-8  w-8 rounded">
                                        <img
                                            src={
                                                item?.inventory?.productImageDetails?.productAvatar
                                            }
                                            alt=""
                                        />
                                    </div>
                                ),
                                subText: item?.batchId,
                                value: `${item?.inventoryId} ${item?.batchId}`,
                            }))}
                            handleInputChange={debounce((evt) => {
                                setDebounceProductSearch(evt.target.value);
                            }, 800)}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={item?.quantity || ""}
                            name={"quantity"}
                            type={"number"}
                            required={true}
                            onChange={(e) => handleProductDataChange(e, idx)}
                            inputPlaceholder="0"
                            containerClassname=""
                            inputContainerClassname={"!border-none !rounded-none "}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <p className=""></p>
                        <TextInput
                            value={item?.cost || ""}
                            name={"cost"}
                            type={"number"}
                            required={true}
                            onChange={(e) => handleProductDataChange(e, idx)}
                            inputPlaceholder="0.00"
                            containerClassname=""
                            inputContainerClassname={"!border-none !rounded-none "}
                            inputClassName="w-full "
                            disabled
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={(item?.quantity * item?.cost).toString()}
                            name={"totalCost"}
                            type="number"
                            inputPlaceholder="0.00"
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none !bg-transparent"}
                            inputClassName="w-full "
                            disabled
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <i
                            className={`ri-delete-bin-line ${
                                productData?.length === 1 && "cursor-default"
                            }`}
                            onClick={() => handleDeleteProduct(idx)}
                        ></i>
                    </div>
                ),
            },
        ];
    });


    return (
        <PageFrame containerClassName={""}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSaveStock();
                }}
            >
                <div className="bg-white rounded-lg border border-slate-100">
                    <div className="flex items-center justify-between px-8 py-5 border-b border-slate-100">
                        <p className="text-base font-rocGroteskMedium">Record Supplied Stocks</p>
                        <div className="flex items-center gap-3">
                            <Button
                                btnText="Cancel"
                                btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                                onClick={() =>
                                    navigate("/dashboard/inventory/stock-control?tabId=3")

                                }
                                disabled={addingSuppliedStock}
                            />
                            <Button
                                btnText="Process order"
                                type="submit"
                                btnClassname="!px-4 !py-2.5 !w-fit"
                                disabled={addingSuppliedStock}
                                isLoading={addingSuppliedStock}
                            />
                        </div>
                    </div>

                    <div className="px-8 pt-[56px] pb-8">
                        <div className="w-full">
                            <div>
                                <div className="">
                                    <div className="flex flex-col gap-4">
                                        <div className="grid grid-cols-2 items-center gap-4 ">
                                            <TextInput
                                                value={
                                                    suppliedStockData?.customerDetails
                                                        ?.companyName || ""
                                                }
                                                name="companyName"
                                                type={"text"}
                                                onChange={handleSuppliedStockData}
                                                placeholder={"Company"}
                                                inputContainerClassname={`${
                                                    suppliedStockData?.customerDetails
                                                        ?.companyName &&
                                                    "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                                }  `}
                                                required={true}
                                            />
                                            <TextInput
                                                value={
                                                    suppliedStockData?.customerDetails?.name || ""
                                                }
                                                name="name"
                                                type={"text"}
                                                onChange={handleSuppliedStockData}
                                                placeholder={"Customer name"}
                                                inputContainerClassname={`${
                                                    suppliedStockData?.customerDetails?.name &&
                                                    "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                                }  `}
                                                required={true}
                                            />

                                            <div className="flex gap-1">
                                                <div className="w-[160px]">
                                                    <SelectInput
                                                        value={
                                                            suppliedStockData?.customerDetails
                                                                ?.mobileExtension || ""
                                                        }
                                                        name="mobileExtension"
                                                        placeholder="Ext"
                                                        handleChange={(name, value) => {
                                                            handleSuppliedStockData({
                                                                target: { name, value },
                                                            });
                                                        }}
                                                        className={`${
                                                            suppliedStockData?.customerDetails
                                                                ?.mobileExtension &&
                                                            "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                                        }`}
                                                        isRequired={true}
                                                        clearValue
                                                        dropdownOptions={countries
                                                            .filter((data) =>
                                                                Boolean(data?.codes[0])
                                                            )
                                                            .sort((p1, p2) =>
                                                                p1.codes[0] > p2.codes[0]
                                                                    ? 1
                                                                    : p1.codes[0] < p2.codes[0]
                                                                    ? -1
                                                                    : 0
                                                            )
                                                            .map((item) => ({
                                                                label: item.codes[0]?.replace(
                                                                    " ",
                                                                    ""
                                                                ),
                                                                value: item.codes[0]?.replace(
                                                                    " ",
                                                                    ""
                                                                ),
                                                                icon: (
                                                                    <ReactCountryFlag
                                                                        countryCode={
                                                                            item?.abbreviation
                                                                        }
                                                                        svg
                                                                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                                        cdnSuffix="svg"
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            borderRadius: "50%",
                                                                            marginTop: "-4px",
                                                                        }}
                                                                    />
                                                                ),
                                                            }))}
                                                    />
                                                </div>

                                                <TextInput
                                                    value={
                                                        suppliedStockData?.customerDetails
                                                            ?.rawMobile || ""
                                                    }
                                                    name={"rawMobile"}
                                                    type={"number"}
                                                    onChange={handleSuppliedStockData}
                                                    placeholder={"Mobile"}
                                                    inputContainerClassname={`${
                                                        suppliedStockData?.customerDetails
                                                            ?.rawMobile &&
                                                        "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                                    }`}
                                                    required
                                                />
                                            </div>
                                            <TextInput
                                                value={
                                                    suppliedStockData?.customerDetails?.email || ""
                                                }
                                                name="email"
                                                type={"text"}
                                                onChange={handleSuppliedStockData}
                                                placeholder={"Email Address"}
                                                inputContainerClassname={`${
                                                    suppliedStockData?.customerDetails?.email &&
                                                    "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                                }  `}
                                                required={true}
                                            />

                                            <SelectInput
                                                value={
                                                    suppliedStockData?.customerDetails?.country ||
                                                    ""
                                                }
                                                name="country"
                                                placeholder="Country"
                                                handleChange={(name, value) => {
                                                    handleSuppliedStockData({
                                                        target: { name, value },
                                                    });
                                                }}
                                                className={`${
                                                    suppliedStockData?.customerDetails?.country &&
                                                    "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                                }`}
                                                isRequired={true}
                                                clearValue
                                                dropdownOptions={countries?.map((item) => ({
                                                    label: item?.name,
                                                    value: item?.name,
                                                    icon: (
                                                        <ReactCountryFlag
                                                            countryCode={item?.abbreviation}
                                                            svg
                                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                            cdnSuffix="svg"
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                                borderRadius: "50%",
                                                                marginTop: "-4px",
                                                            }}
                                                        />
                                                    ),
                                                }))}
                                            />

                                            <DateInput
                                                label={"Supply Date"}
                                                value={dateOfSupply}
                                                onChange={(newDate) => handleDateChange(newDate)}
                                                showCalendar={showCalendar}
                                                onCalendarToggle={setShowCalendar}
                                                calendarClassname="!right-0"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-6 mt-8">
                                    <div>
                                        <label className="text-base font-rocGroteskMedium">
                                            Order details
                                        </label>
                                    </div>
                                    <div className="grid grid-cols-2 items-center gap-4">
                                        <SelectInput
                                            value={suppliedStockData?.warehouseId || ""}
                                            name="warehouseId"
                                            placeholder={"Warehouse"}
                                            handleChange={(name, value) => {
                                                handleSuppliedStockData({
                                                    target: { name, value },
                                                });
                                            }}
                                            className={`${
                                                suppliedStockData?.warehouseId &&
                                                "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                            }`}
                                            searchLoading={fetchingWarehouses}
                                            isRequired={true}
                                            handleInputChange={debounce((evt) => {
                                                setDebounceWarehouseSearch(evt.target.value);
                                            }, 800)}
                                            dropdownOptions={
                                                warehouses
                                                    ? warehouses?.map((item) => ({
                                                          label: item?.warehouseName,
                                                          value: item?._id,
                                                      }))
                                                    : []
                                            }
                                        />
                                        <SelectInput
                                            value={suppliedStockData?.procurementManagerId || ""}
                                            name="procurementManagerId"
                                            placeholder={"Procurement Manager"}
                                            handleChange={(name, value) => {
                                                handleSuppliedStockData({
                                                    target: { name, value },
                                                });
                                            }}
                                            className={`${
                                                suppliedStockData?.procurementManagerId &&
                                                "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                            }`}
                                            searchLoading={fetchingTeamMember}
                                            isRequired={true}
                                            handleInputChange={debounce((evt) => {
                                                setDebounceTeamMemberSearch(evt.target.value);
                                            }, 800)}
                                            dropdownOptions={
                                                teamMembers
                                                    ? teamMembers?.map((item) => ({
                                                          label: item?.fullName || item?.email,
                                                          value: item?._id,
                                                          icon: (
                                                              <VendorAvatar
                                                                  size="24"
                                                                  containerClassname="rounded-full"
                                                                  name={
                                                                      item?.fullName || item?.email
                                                                  }
                                                                  imageSrc={item?.avatar}
                                                              />
                                                          ),
                                                      }))
                                                    : []
                                            }
                                        />

                                        <DateInput
                                            label={"Delivery timeline"}
                                            value={deliveryTimeline}
                                            onChange={(newDate) => handleTimelineChange(newDate)}
                                            showCalendar={showCalendarTwo}
                                            onCalendarToggle={setShowCalendarTwo}
                                            calendarClassname="!right-0"
                                            doubleValue={true}
                                            required
                                        />
                                        <AddressInput
                                            setData={setAddress}
                                            savedAddress={
                                                address?.streetAddress
                                                    ? address?.streetAddress?.address
                                                    : ""
                                            }
                                            placeholder="Location"
                                            required={true}
                                            name={"Location"}
                                        />
                                        <SelectInput
                                            value={suppliedStockData?.assigneeId || ""}
                                            name="assigneeId"
                                            placeholder={"Assigned To"}
                                            handleChange={(name, value) => {
                                                handleSuppliedStockData({
                                                    target: { name, value },
                                                });
                                            }}
                                            className={`${
                                                suppliedStockData?.assigneeId &&
                                                "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                            }`}
                                            searchLoading={fetchingTeamMember}
                                            isRequired={true}
                                            handleInputChange={debounce((evt) => {
                                                setDebounceTeamMemberSearch(evt.target.value);
                                            }, 800)}
                                            dropdownOptions={
                                                teamMembers
                                                    ? teamMembers?.map((item) => ({
                                                          label: item?.fullName || item?.email,
                                                          value: item?._id,
                                                          icon: (
                                                              <VendorAvatar
                                                                  size="24"
                                                                  containerClassname="rounded-full"
                                                                  name={
                                                                      item?.fullName || item?.email
                                                                  }
                                                                  imageSrc={item?.avatar}
                                                              />
                                                          ),
                                                      }))
                                                    : []
                                            }
                                        />
                                        <TextInput
                                            value={suppliedStockData?.orderSource || ""}
                                            name={"orderSource"}
                                            type={"text"}
                                            onChange={handleSuppliedStockData}
                                            placeholder={"Order Source"}
                                            inputContainerClassname={`${
                                                suppliedStockData?.orderSource &&
                                                "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                            }`}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="mb-6 mt-12">
                                    <label className="text-base font-rocGroteskMedium">
                                        Products
                                    </label>
                                    <div className="mt-4">
                                        <CustomTable
                                            tableBody={tableBody}
                                            tableHeader={tableHeader}
                                            isAllSelectable={true}
                                            isCellSelectable
                                            headerContainerClass=""
                                            isScrollable={false}
                                            isCellBordered={true}
                                            bodyItemClass={
                                                "hover:!border-[1.5px] hover:!border-gm-50 "
                                            }
                                        />
                                    </div>

                                    <div className="flex items-center gap-3 mt-4">
                                        <Button
                                            btnText="Add more products"
                                            btnClassname="text-[13px] !px-4 !py-2 !bg-n-20 !w-fit"
                                            onClick={() => handleAddNewRow()}
                                        />
                                    </div>
                                </div>
                                <div className="mt-8">
                                    <label className="text-base font-rocGroteskMedium">
                                        Attachments
                                    </label>
                                    <input
                                        type="file"
                                        className="hidden"
                                        id="attachment"
                                        accept="image/*, .pdf, .xlsx"
                                        disabled={uploadingAttachment}
                                        onChange={(e) => {
                                            if (e.target.files?.[0]) {
                                                handleUpload(
                                                    e.target.files?.[0]?.name as string,
                                                    e.target.files?.[0]?.size as any
                                                    // e.target.files?.[0] as File
                                                );
                                            }
                                        }}
                                    />
                                    <div className="">
                                        <div className="mt-4 grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                                            {attachment?.map((item, idx) => {
                                                return (
                                                    <div className="flex items-center justify-between p-3 rounded-md border border-slate-200 mb-4 w-full">
                                                        <div className="flex items-center gap-2">
                                                            {displayFileIcon(
                                                                getFileTypeFromUrl(item?.url),
                                                                false
                                                            )}
                                                            <div className="font-rocGroteskMedium">
                                                                <p className="text-sm">
                                                                    {truncate(item?.name, {
                                                                        length: 30,
                                                                    })}
                                                                </p>
                                                                <p className="text-xs text-slate-500"></p>
                                                            </div>
                                                        </div>
                                                        <i
                                                            className="ri-delete-bin-line text-xl text-r-50 cursor-pointer"
                                                            onClick={() => deleteAttachment(idx)}
                                                        ></i>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        <label
                                            className={`flex items-center gap-4 py-3 px-6 rounded border border-dashed border-slate-200  ${
                                                !uploadingAttachment && "cursor-pointer"
                                            }`}
                                            htmlFor="attachment"
                                        >
                                            <i className="ri-file-add-line text-2xl text-carribean-green"></i>
                                            {uploadingAttachment ? (
                                                <div className="">
                                                    <Loader size={4} />
                                                </div>
                                            ) : (
                                                <p className="text-sm underline text-carribean-green font-rocGroteskMedium">
                                                    Click to upload document
                                                </p>
                                            )}
                                        </label>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-4 mt-8">
                                    <div className="mt-4">
                                        <label className="text-base font-rocGroteskMedium">
                                            Notes
                                        </label>
                                        <div className="mt-4">
                                            <TextareaInput
                                                name={"notes"}
                                                value={suppliedStockData?.notes || ""}
                                                onChange={handleSuppliedStockData}
                                                rows={5}
                                                placeholder={"Add note"}
                                                required={false}
                                                inputContainerClassname={`${
                                                    suppliedStockData?.notes &&
                                                    "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                                }`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <ModalContainer
                open={successModal}
                showCloseIcon={false}
                tailwindClassName="w-[42%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="px-10 pt-8 max-h-[70vh] overflow-y-scroll ">
                        {response?.stockSuccessfull?.length > 0 && (
                            <div className="flex flex-col items-center">
                                <div className="w-12 h-12 rounded-full bg-[#00DB8F] flex items-center justify-center">
                                    <i className="ri-checkbox-circle-fill text-xl text-white"></i>
                                </div>

                                <p className="text-xl mb-3 text-center mt-4 font-rocGroteskMedium text-gm-50">
                                    {`Supplied stocks Entry ${
                                        response?.approvals ? "Initiated (0/3)" : "Successful"
                                    }`}
                                </p>
                                <p
                                    className={`text-base text-center font-rocGroteskMedium text-slate-500 ${
                                        response?.approvals ? "max-w-[507px]" : "max-w-[450px]"
                                    } `}
                                >
                                    {response?.approvals
                                        ? "You've initiated Supplied stocks Entry. It will now undergo steps for verification and approval. Expect updates on the progress."
                                        : " Stock levels have been successfully adjusted, and the changes are now accurately reflected in the inventory."}
                                </p>
                                <div className="mt-8 w-full">
                                    <p className="text-base text-left text-gm-50 font-rocGroteskMedium">
                                        {response?.approvals
                                            ? "Approvals"
                                            : `${response?.stockSuccessfull.length} Supplied stock(s)
                                        recorded`}
                                    </p>

                                    <div className="mt-2 mb-8">
                                        {response?.approvals ? (
                                            <div className="flex gap-3 flex-col font-rocGroteskMedium">
                                                <div className="flex items-center justify-between border-b border-slate-100 py-4">
                                                    <div className=" flex items-center gap-2 text-sm">
                                                        <VendorAvatar size={32} />
                                                        <div className="">
                                                            <p className="text-gun-metal">
                                                                Tom Smith - Finance
                                                            </p>
                                                            <p className="text-slate-500">
                                                                April 17, 2023; 2:00pm
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="flex items-center gap-2 text-xs font-rocGroteskMedium">
                                                        <p className="bg-[#f1f5f9] rounded py-1 px-3">
                                                            {" "}
                                                            Level 1
                                                        </p>
                                                        <p className="bg-[#F9DFB3] rounded py-1 px-3">
                                                            Pending
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            response?.stockSuccessfull?.map((item, idx) => {
                                                return (
                                                    <div
                                                        key={idx}
                                                        className="w-full py-4 border-b border-n-30 flex items-center justify-between gap-8"
                                                    >
                                                        <div className="flex items-center gap-4">
                                                            <div className="w-[38px] h-[38px] flex items-center justify-center rounded bg-[#ECFDF5] ">
                                                                <i className="ri-box-2-fill text-xl text-[#10B981]"></i>
                                                            </div>
                                                            <div className="text-sm font-rocGroteskMedium">
                                                                <p className="text-gm-50">
                                                                    {item?.stock?.batchId}
                                                                </p>
                                                                <p className="text-slate-500">
                                                                    {
                                                                        item?.stock?.inventory
                                                                            ?.productName
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <Button
                                                            btnText="View"
                                                            btnClassname="!bg-n-20 !px-4 !py-2.5 !w-fit"
                                                            onClick={() => {
                                                                navigate(
                                                                    `/dashboard/inventory/supplied-stock/${item?.stockMovement?._id}`
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {response?.approvals ? (
                        <div className="w-full flex items-center gap-4 px-8 py-4 border-t border-slate-200 ">
                            <div className="flex-1">
                                <Button
                                    btnText={"Close"}
                                    type={"button"}
                                    btnClassname="!bg-tradeally-neutral-20"
                                    onClick={() => {
                                        // setSuccessModal(false);
                                    }}
                                />
                            </div>
                            <div className="flex-1">
                                <Button
                                    btnText={"View details"}
                                    type={"button"}
                                    onClick={() => {
                                        // navigate("/dashboard/inventory/stock-control?tabId=3");
                                        setSuccessModal(false);
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="w-full flex items-center gap-4 px-8 py-4 border-t border-slate-200 ">
                            <div className="flex-1">
                                <Button
                                    btnText={"Record more Supplied stock"}
                                    type={"button"}
                                    btnClassname="!bg-tradeally-neutral-20"
                                    onClick={() => {
                                       
                                        setSuccessModal(false);
                                        setSuppliedStockData({});
                                        setDateOfSupply(null);
                                        setAttachment([]);
                                        setDeliveryTimeline(null);
                                        setProductData([{}]);
                                        setProductList([]);
                                    }}
                                />
                            </div>
                            <div className="flex-1">
                                <Button
                                    btnText={"Close"}
                                    type={"button"}
                                    onClick={() => {
                                        navigate("/dashboard/inventory/stock-control?tabId=3");
                                        setSuccessModal(false);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </ModalContainer>

            <ModalContainer
                open={workflowApprovalModal}
                showCloseIcon={false}
                tailwindClassName="max-w-[95%]"
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="px-8 py-8 ">
                        <div className="flex flex-col items-center">
                            <div className="w-12 h-12 rounded-full bg-[#00DB8F] flex items-center justify-center">
                                <i className="ri-checkbox-circle-fill text-xl text-white"></i>
                            </div>

                            <p className="text-xl mb-3 text-center mt-4 font-rocGroteskMedium text-g-75">
                                Set Up Approval Workflow
                            </p>
                            <p className="text-base text-center font-rocGroteskMedium text-slate-500 max-w-[435px]">
                                Configure your approval workflow to proceed with recording Supplied
                                stocks. This ensures necessary verifications and approvals. You'll
                                receive updates on the progress
                            </p>
                        </div>
                    </div>
                    <div className="w-full flex items-center gap-4 px-8 py-4 border-t border-slate-200 ">
                        <div className="flex-1">
                            <Button
                                btnText={"Not now"}
                                type={"button"}
                                btnClassname="!bg-tradeally-neutral-20"
                                onClick={() => {
                                    // setSuccessModal(false);
                                }}
                            />
                        </div>
                        <div className="flex-1">
                            <Button
                                btnText={"Create a new workflow"}
                                type={"button"}
                                onClick={() => {
                                    // setSuccessModal(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default RecordSuppliedStock;
