import Button from "components/common/Button";
import React from "react";
import { Link } from "react-router-dom";

const PreFooterCard = () => {
    return (
        <div className="flex justify-center mb-[161px] max-lg:mb-[45px] max-lg:px-6">
            <div className="bg-white mt-6 border border-slate-50 relative shadow-[0px_12px_16px_-4px_rgba(74,222,128,0.08)] rounded-2xl w-[87%] h-[472px] pl-14 max-lg:pl-0 max-lg:h-full max-lg:w-full flex items-center max-lg:flex-col">
                <div className="w-[53%] max-lg:w-full max-lg:px-6 max-lg:mb-[22px] max-lg:mt-12">
                    <p className="text-[42px] leading-[50.4px] max-lg:text-[32px] max-lg:leading-[38.4px] font-rocGroteskMedium  mb-4">
                        Ready to Breathe Life into Your Projects + Procurement Process?
                    </p>
                    <p className="text-lg max-lg:text-sm font-rocGroteskMedium text-slate-500 mb-8 pr-5">
                        Join Synth and take your procurement-dependent business to new heights by
                        eliminating redundant manual processes with an AI-powered software that’ll
                        save you hours every day and thousands of dollars every month by eliminating
                        redundant manual processes.
                    </p>
                    <Link to={"/book-a-demo"} className="block w-fit">
                        <Button
                            btnText={"Talk to sales"}
                            btnClassname={"!shadow-boxShadow-4 !w-[132px] !h-[48px]"}
                        />
                    </Link>
                </div>
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702546611/Tradeally_2.0/Landing%20page/tl_map_prye5t.svg"
                    alt="globe"
                    className="absolute right-0 max-lg:hidden"
                    height={1064}
                />
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702560538/Tradeally_2.0/Landing%20page/mobile_Map_sn5kse.png"
                    alt="globe"
                    className=" max-lg:block hidden w-full"
                    // height={1064}
                />
            </div>
        </div>
    );
};

export default PreFooterCard;
