import React from "react";
import {toast} from "react-hot-toast";
import {shipmentService} from "services/Ldb";
import {shipmentTypes} from "../types";
import CustomToast from "components/common/CustomToast";
import {Dispatch} from "redux";

export const shipmentActions = {
    getShipments,
    getSingleShipment,
    assignShipment,
    resetAssignShipmentSuccess,
};

function getShipments(limit?: string | number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(shipmentTypes.FETCH_SHIPMENTS_REQUEST));

        shipmentService.getShipments(limit, search).then(
            (res) => {
                dispatch(success(shipmentTypes.FETCH_SHIPMENTS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(shipmentTypes.FETCH_SHIPMENTS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleShipment(shipmentId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(shipmentTypes.FETCH_SINGLE_SHIPMENT_REQUEST));

        shipmentService.getSingleShipment(shipmentId).then(
            (res) => {
                dispatch(success(shipmentTypes.FETCH_SINGLE_SHIPMENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(shipmentTypes.FETCH_SINGLE_SHIPMENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function assignShipment(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(shipmentTypes.ASSIGN_SHIPMENT_TO_MEMBER_REQUEST));

        shipmentService.assignShipmentToMember(data).then(
            (res) => {
                dispatch(success(shipmentTypes.ASSIGN_SHIPMENT_TO_MEMBER_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Shipment assigned"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(shipmentTypes.ASSIGN_SHIPMENT_TO_MEMBER_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function resetAssignShipmentSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(shipmentTypes.ASSIGN_SHIPMENT_TO_MEMBER_SUCCESS, null));
    };
}

function request(type: string) {
    return {type: type};
}
function success(type: string, data: any) {
    return {type: type, payload: data};
}
function failure(type: string, error: any) {
    return {type: type, payload: error ?? ""};
}
