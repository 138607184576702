import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import useGetUserProfile from "hooks/useGetUserProfile";
import CustomToast from "./CustomToast";
import toast from "react-hot-toast";

type ButtonProps = {
    btnText?: string;
    type?: "button" | "submit";
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    btnClassname?: string;
    btnCustomContent?: React.ReactNode;
    isLoading?: boolean;
    disabled?: boolean;
    icon?: React.ReactNode;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    btnTextClassName?: string;
    btnType?: string;
    permissions?: string[];
    tipClassName?: string;
    tipParentClassname?: string;
};

const Button = ({
    btnText,
    type,
    onClick,
    btnClassname,
    btnCustomContent,
    isLoading,
    disabled,
    icon,
    btnTextClassName,
    btnType,
    permissions,
    tipClassName,
    tipParentClassname,
    onMouseEnter,
    onMouseLeave,
}: ButtonProps) => {
    const [profile] = useGetUserProfile();

    const isPermitted = permissions?.some((permission) =>
        profile?.permission?.includes(permission)
    );

    return (
            
        <div
            className={`${
                permissions?.length > 0 && !isPermitted && "relative group !z-[9999]"
            } ${tipParentClassname}`}
        >
            {permissions?.length > 0 && !isPermitted && (
                <span
                    className={` font-rocGroteskRegular pointer-events-none absolute top-[50px]  text-xs left-1/2  before:left-1/2 !w-[160px]  transform -translate-x-1/2 bg-gm-50 p-3 text-white opacity-0 transition before:absolute  before:bottom-full before:translate-x-1/2 before:border-4   before:border-transparent before:border-b-gm-50 rounded before:content-[''] group-hover:opacity-100 z-100 text-center ${tipClassName}`}
                >
                    You don't have access to this feature
                </span>
            )}

            <button
                onClick={(e) => {
                    permissions?.length > 0 && !isPermitted
                        ? toast.custom((t) => (
                              <CustomToast
                                  t={t}
                                  message="You don't have access to this feature"
                                  type="error"
                              />
                          ))
                        : onClick?.(e);
                }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                type={type ? type : "button"}
                disabled={permissions?.length > 0 ? !isPermitted : disabled}
                className={`${btnClassname} w-full flex justify-center py-3 items-center px-4
             bg-y-10 rounded text-sm font-rocGroteskMedium text-black ${
                 permissions?.length > 0 && !isPermitted && "!opacity-50"
             }`}
            >
                {isLoading ? (
                    <Loader color={"inherit"} size={4} />
                ) : // <p>loading</p>
                btnCustomContent ? (
                    btnCustomContent
                ) : (
                    <>
                        {btnType === "textFirst" ? (
                            <div className="flex space-x-2 items-center">
                                <p className={btnTextClassName}> {btnText}</p>
                                <div> {icon}</div>
                            </div>
                        ) : icon ? (
                            <div className="flex space-x-2 items-center">
                                <div> {icon}</div>
                                {btnText && <p className={btnTextClassName}> {btnText}</p>}
                            </div>
                        ) : (
                            btnText
                        )}
                    </>
                )}
            </button>
    </div>
    );
};

export default Button;
