import React, { useEffect, useState } from "react";
import Button from "components/common/Button";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import ReactCountryFlag from "react-country-flag";
import countries from "variables/countries";
import {
    displayFileIcon,
    getCurrencyFromCurrencyCode,
    hasHTMLTag,
    restrictPastDate,
    sanitizeHtml,
} from "helpers";
import AddressInput from "components/common/InputField/AddressInput";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import Loader from "components/common/Loader";
import { Editor } from "@tinymce/tinymce-react";
import config from "config/config";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions, orderActions } from "redux/Ldb/actions";
import VendorAvatar from "components/common/VendorAvatar";
import VendorSelectInput from "components/common/InputField/VendorSelectInput";
import { useNavigate } from "react-router-dom";
import { firebaseService } from "services/firebaseService";
import useGetUserProfile from "hooks/useGetUserProfile";
import moment from "moment";
import ModalContainer from "components/common/ModalContainer";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";

const CreateRfqBlank = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [profile] = useGetUserProfile();
    const [uploadingAttachment, setUploadingAttachment] = useState<boolean>(false);
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [deletingImage, setDeletingImage] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [allProducts, setAllProducts] = useState<{ [key: string]: any }[]>([]);
    const [vendorNames, setVendorNames] = useState<any>([]);
    const [address, setAddress] = useState<{ [key: string]: any }>({});
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [deleteFile, setDeleteFile] = useState<string>("");
    const [showProduct, setShowProduct] = useState([
        {
            productName: "",
            productAvatar: "",
            sku: "",
            uom: "",
            id: "",
        },
    ]);
    const [serviceData, setServiceData] = useState([
        {
            inventoryId: "",
            item: "",
            quantity: "",
            uom: "",
            vendors: [],
            vendorNames: [],
        },
    ]);

    const [rfqDetails, setRfqDetails] = useState({
        budget: "",
        currency: "",
        orderType: "",
        rfqStartDate: "",
        rfqStartTime: "",
        rfqEndDate: "",
        rfqEndTime: "",
        deliveryDate: "",
        country: "",
        vendorCategory: "",
        orderDescription: "",
        terms: "",
        companyName: "",
        contactName: "",
        emailAddress: "",
        ext: "",
        phone: "",
        attachments: [],
    });
    const { fetchingSearchInventoryListing, fetchedSearchInventoryListingSuccess } = useAppSelector(
        (state) => state.inventory
    );
    const { sendingRfqs, sendRfqsSuccess } = useAppSelector((state) => state.order);

    const tableHeader = [
        { title: "Product name", widthClass: "w-[40.5%]" },
        { title: "Quantity", widthClass: "w-[12.7%]" },
        { title: "UOM", widthClass: "w-[[12.7%]" },
        { title: "Supplier", widthClass: "w-[30.7%]" },
        { title: "", widthClass: "w-[4.6%]" },
    ];
    const handleUpload = (fileName: string, file: File) => {
        if (fileName && file) {
            setUploadingAttachment(true);
            firebaseService.uploadFile(
                `synthally/${process.env.REACT_APP_STAGE ?? "staging"}/rfqItem/${
                    profile?.profile?.businessName
                }/${file?.name}`,
                file,
                (url) => {
                    const newAttachment = {
                        filename: fileName,
                        path: url,
                        documentType: "pdf",
                    };

                    setRfqDetails((prevRfqDetails) => ({
                        ...prevRfqDetails,
                        attachments: [...prevRfqDetails.attachments, newAttachment],
                    }));

                    setUploadingAttachment(false);
                }
            );
        }
    };
    const removeFile = (idx) => {
        const newArr = [...rfqDetails.attachments];

        const imagePath = decodeURIComponent(
            rfqDetails.attachments[idx]?.path?.split("/o/")?.[1]?.split("?alt")?.[0]
        );
        setDeletingImage(true);

        firebaseService.removeFile(imagePath, () => {
            newArr.splice(idx, 1);
            setRfqDetails((prevRfqDetails) => ({
                ...prevRfqDetails,
                attachments: newArr,
            }));
            setDeletingImage(false);
        });
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }
    ) => {
        const { name, value } = e.target;
        setRfqDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleItemChange = (
        idx: number,
        name: string,
        value: string | string[],
        itemsValue?: string | string[]
    ) => {
        const propName = name.split("-")[0];

        if (propName === "item") {
            if (typeof value === "string") {
                const productIdParts = value.split("-");
                const firstId = productIdParts[0];
                const filteredProducts = allProducts.filter((product) => product._id === firstId);
                setShowProduct((prevShowProduct) => {
                    const updatedShowProduct = [...prevShowProduct];
                    updatedShowProduct[idx] = {
                        ...updatedShowProduct[idx],
                        productAvatar: filteredProducts[0]?.productImageDetails?.productAvatar,
                        productName: filteredProducts[0]?.productName,
                        sku: filteredProducts[0]?.sku,
                        uom: filteredProducts[0]?.productStockDetails?.unitOfMeasurement,
                        id: filteredProducts[0]?._id,
                    };
                    return updatedShowProduct;
                });
            }
            setDebouncedSearch("");
        }
        setServiceData((prev) => {
            const updatedServiceData = prev.map((item, i) => {
                if (i === idx) {
                    const itemDataCopy = { ...item };
                    if (propName === "item") {
                        if (typeof value === "string") {
                            const itemParts = value.split("-");
                            itemDataCopy.inventoryId = itemParts[0];
                            itemDataCopy.item = itemParts[1];
                        }
                    } else {
                        if (itemsValue) {
                            itemDataCopy.vendorNames = [itemsValue];
                        }
                        itemDataCopy[propName] = value;
                    }
                    return itemDataCopy;
                }
                return item;
            });
            return updatedServiceData;
        });

        if (name === "productName" && value === "") {
            setShowProduct((prevShowProduct) => {
                const updatedShowProduct = [...prevShowProduct];
                updatedShowProduct[idx] = {
                    productName: "",
                    productAvatar: "",
                    sku: "",
                    uom: "",
                    id: "",
                };
                return updatedShowProduct;
            });
        }
    };
console.log("error", error)
    const tableBody = serviceData?.map((data, idx) => {
        return [
            {
                content: (
                    <div className={`w-full  border-slate-100 text-sm text-slate-700 `}>
                        <Dropdown
                            name={`item-${idx}`}
                            value={" Select..."}
                            dropdownTriggerClassName={"w-full "}
                            handleChange={(name, value) => {
                                handleItemChange(idx, name, value);
                                if (value === "") {
                                    setError(true);
                                } else {
                                    setError(false);
                                }
                            }}
                            search={
                                <TextInput
                                    name={"search"}
                                    value={debouncedSearch}
                                    type={"text"}
                                    inputPlaceholder={"Search"}
                                    inputClassName={"!h-[40px] pl-[0px] !bg-white text-sm !mb-0"}
                                    onChange={(e: any) => setDebouncedSearch(e.target.value)}
                                    onInput={(e: any) => {}}
                                    leftIcon={
                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                    }
                                    inputContainerClassname={
                                        "!rounded-[4px] w-full !pl-[40px]  !h-[40px] !bg-white shadow-boxShadow-2 !border-none"
                                    }
                                />
                            }
                            dropdown={
                                <div
                                    className={`px-4 py-2 rounded flex items-center w-full justify-between`}
                                >
                                    {showProduct[idx]?.productName ? (
                                        <div className="flex space-x-2 items-center px-2  hover:bg-slate-100 cursor-pointer">
                                            <div>
                                                <VendorAvatar
                                                    size={32}
                                                    imageSrc={showProduct[idx]?.productAvatar}
                                                    containerClassname="!rounded"
                                                />
                                            </div>
                                            <div>
                                                <p className="text-gm-50 text-sm font-rocGroteskMedium">
                                                    {showProduct[idx]?.productName}
                                                </p>
                                                <p className="text-xs font-rocGroteskMedium text-slate-500">
                                                    {showProduct[idx]?.sku}
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <p className="text-sm font-rocGroteskMedium text-slate-400">
                                                Select...
                                            </p>
                                        </div>
                                    )}
                                </div>
                            }
                            dropdownClassName={"!w-full  !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-4   !left-[0px] !mt-0  border border-slate-100 w-full rounded !max-h-fit
                                    h-[250px] !top-[100%]
                            `}
                            dropdownOptions={allProducts.map((product) => ({
                                label: (
                                    <div
                                        className="flex space-x-2 items-center px-2  hover:bg-slate-100 cursor-pointer"
                                        key={product?._id}
                                    >
                                        <div>
                                            <VendorAvatar
                                                size={32}
                                                imageSrc={
                                                    product?.productImageDetails?.productAvatar
                                                }
                                                containerClassname="!rounded"
                                            />
                                        </div>
                                        <div>
                                            <p className="text-gm-50 text-sm font-rocGroteskMedium">
                                                {product?.productName}
                                            </p>
                                            <p className="text-xs font-rocGroteskMedium text-slate-500">
                                                {product?.sku}
                                            </p>
                                        </div>
                                    </div>
                                ),
                                value: product?._id + "-" + product?.productName,
                            }))}
                            addMoreButton={
                                <div
                                    className="border-t pt-3 flex space-x-3 cursor-pointer px-4 items-center"
                                    onClick={() => navigate("/dashboard/inventory/add-product")}
                                >
                                    <div className="h-8 w-8 flex justify-center items-center bg-[#EDFFFC] ">
                                        <i className="ri-add-fill text-g-55"></i>
                                    </div>
                                    <p className="text-g-55 text-sm font-rocGroteskMedium  underline">
                                        Add a new product
                                    </p>
                                </div>
                            }
                        />
                    </div>
                ),

                widthClass: `hover:bg-slate-100`,
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`w-full hover:bg-slate-100 border-slate-100 text-sm text-slate-700 `}
                    >
                        <TextInput
                            value={data?.quantity as string}
                            name={`quantity-${idx}`}
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            required={true}
                            onChange={(e) => handleItemChange(idx, e.target.name, e.target.value)}
                            inputPlaceholder="0"
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                widthClass: `hover:bg-slate-100`,
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full  border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={showProduct[idx]?.uom}
                            name={`uom-${idx}`}
                            type={"text"}
                            disabled
                            required={true}
                            placeholder="uom"
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                widthClass: `hover:bg-slate-100`,
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full  border-slate-100 text-sm text-slate-700 `}>
                        <VendorSelectInput
                            value={data?.vendors?.join(", ")}
                            name={`vendors-${idx}`}
                            placeholder={"Select"}
                            isRequired={true}
                            orderType={rfqDetails?.orderType}
                            className="!border-0 !pl-3"
                            handleChange={(name, value, valuesArray, itemsArray) => {
                                handleItemChange(idx, name, valuesArray, itemsArray);
                                if (showProduct[idx]?.productName === "") {
                                    setError(true);
                                } else {
                                    setError(false);
                                }
                            }}
                        />
                    </div>
                ),
                widthClass: `hover:bg-slate-100`,
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        {idx > 0 && (
                            <i
                                onClick={() => {
                                    const serviceDataCopy = [...serviceData];
                                    serviceDataCopy.splice(idx, 1);
                                    setServiceData(serviceDataCopy);
                                    const showProductCopy = [...showProduct];
                                    showProductCopy.splice(idx, 1);
                                    setShowProduct(showProductCopy);
                                }}
                                className="ri-delete-bin-line cursor-pointer text-slate-500"
                            ></i>
                        )}
                    </div>
                ),
            },
        ];
    });

    useEffect(() => {
        dispatch(inventoryActions.fetchSearchInventoryListing(70, debouncedSearch));
    }, [dispatch, debouncedSearch]);

    useEffect(() => {
        if (Boolean(fetchedSearchInventoryListingSuccess)) {
            setAllProducts(fetchedSearchInventoryListingSuccess?.inventory?.data);
        }
    }, [fetchedSearchInventoryListingSuccess]);

    const joinedArray = serviceData.map((dataItem) => {
        const correspondingProduct = showProduct.find(
            (product) => product.id === dataItem.inventoryId
        );
        return { ...dataItem, ...correspondingProduct };
    });
    const tablePreviewBody = joinedArray?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        className={`w-full flex space-x-1.5 items-center py-1  border-slate-100  `}
                    >
                        <div>
                            <VendorAvatar
                                imageSrc={item?.productAvatar}
                                size={32}
                                containerClassname="!rounded-[4px]"
                            />
                        </div>
                        <div className="font-rocGroteskMedium  text-sm text-slate-900">
                            <p>{item?.productName}</p>
                            <p className="text-slate-500">{item?.sku}</p>
                        </div>
                    </div>
                ),

                tableDataDivClass: "!pl-2",
            },

            {
                content: (
                    <div
                        className={`w-full  font-rocGroteskMedium border-slate-100 text-sm text-slate-900 `}
                    >
                        <p>{item?.quantity}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full font-rocGroteskMedium  border-slate-100 text-sm text-slate-900 `}
                    >
                        <p>{item?.uom}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full  font-rocGroteskMedium border-slate-100 text-sm text-slate-900 `}
                    >
                        <p>{item?.vendorNames?.join(", ")}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
        ];
    });
    const sendRfqDetails = () => {
        const [lastName, firstNane] = rfqDetails?.contactName?.split(" ");
        const body: { [key: string]: any } = {
            itemsAndVendors: serviceData.map(({ uom, vendorNames, ...rest }) => rest),
            deliveryDate: rfqDetails.deliveryDate,
            deliveryAddress: address?.deliveryAddress?.address,
            orderType: rfqDetails.orderType,
            customerDetails: {
                email: rfqDetails.emailAddress,
                phone: {
                    mobile: `${rfqDetails.ext}${rfqDetails.phone}`,
                    rawMobile: rfqDetails.phone,
                    mobileExtension: rfqDetails.ext,
                },
                country: rfqDetails.country,
                firstNane,
                lastName,
                companyName: rfqDetails.companyName,
            },
            termsAndConditions: rfqDetails.terms,
            startDate: `${rfqDetails.rfqStartDate} ${rfqDetails.rfqStartTime}`,
            endDate: `${rfqDetails.rfqEndDate} ${rfqDetails.rfqEndTime}`,
            attachments: rfqDetails.attachments,
        };
        if (Boolean(rfqDetails?.orderDescription)) {
            body.specifications = rfqDetails?.orderDescription;
        }
        if (Boolean(rfqDetails?.currency) && Boolean(rfqDetails?.budget)) {
            body.budget = {
                currency: rfqDetails?.currency,
                amount: rfqDetails?.budget,
            };
        }
        dispatch(orderActions.sendRfqs(body));
    };

    useEffect(() => {
        if (Boolean(sendRfqsSuccess)) {
            dispatch(orderActions.resetSendRfqsSuccess());
            setSuccessModal(true);
        }
    }, [dispatch, navigate, sendRfqsSuccess]);
    return (
        <div>
            {!showPreview && (
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (rfqDetails.terms === "") {
                            toast.custom((t) => (
                                <CustomToast
                                    t={t}
                                    message={"Terms and conditions are required"}
                                    type="error"
                                />
                            ));
                        } else if (error) {
                            toast.custom((t) => (
                                <CustomToast
                                    t={t}
                                    message={"Product name is required"}
                                    type="error"
                                />
                            ));
                        } else {
                            setShowPreview(true);
                        }
                    }}
                >
                    <div className="bg-white rounded-lg border border-slate-100">
                        <div className="flex top-[-2px] rounded-t-lg md:sticky px-8 py-4 z-[50] bg-white  items-center justify-between  border-b border-slate-100">
                            <p className="text-base text-gm-50 font-rocGroteskMedium">
                                New Request for Quotation
                            </p>
                            <div className="flex items-center gap-3">
                                <div className="flex items-center space-x-2">
                                    <div className="h-2 w-2 rounded-full bg-slate-300"></div>
                                    <p className="text-slate-500 text-sm font-rocGroteskMedium">
                                        Draft saved
                                    </p>
                                </div>
                                <Button
                                    btnText="Cancel"
                                    disabled={sendingRfqs}
                                    onClick={() => {}}
                                    btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                                />
                                <Button
                                    btnText="Save and preview"
                                    btnClassname="!px-4 !py-2.5 !w-fit"
                                    isLoading={false}
                                    disabled={sendingRfqs}
                                    type="submit"
                                />
                            </div>
                        </div>
                        <div className="px-8 py-6">
                            <div>
                                <p className="text-base text-gm-50 font-rocGroteskMedium">
                                    Customer details{" "}
                                    <span className="text-sm text-slate-500">
                                        (this information will not be shared with your suppliers)
                                    </span>
                                </p>
                                <div className="mt-3">
                                    <div>
                                        <div className="flex space-x-4">
                                            <TextInput
                                                name={"companyName"}
                                                value={rfqDetails.companyName}
                                                onChange={handleChange}
                                                placeholder={"Company name"}
                                                required={true}
                                                containerClassname=""
                                            />
                                            <TextInput
                                                name={"contactName"}
                                                value={rfqDetails.contactName}
                                                onChange={handleChange}
                                                placeholder={"Contact name"}
                                                required={true}
                                                containerClassname=""
                                            />
                                            <TextInput
                                                name={"emailAddress"}
                                                value={rfqDetails.emailAddress}
                                                onChange={handleChange}
                                                placeholder={"Email address"}
                                                required={true}
                                                type="email"
                                                containerClassname=""
                                            />
                                        </div>
                                        <div className="flex space-x-4 mt-4">
                                            <div className="w-full grid grid-cols-[115px_calc(100%-119px)] gap-1 ">
                                                <SelectInput
                                                    value={rfqDetails.ext}
                                                    name="ext"
                                                    placeholder={"Ext"}
                                                    handleChange={(name, value) =>
                                                        handleChange({ target: { name, value } })
                                                    }
                                                    isRequired={true}
                                                    dropdownOptions={countries
                                                        .filter((data) => Boolean(data?.codes[0]))
                                                        .sort((p1, p2) =>
                                                            p1.codes[0] > p2.codes[0]
                                                                ? 1
                                                                : p1.codes[0] < p2.codes[0]
                                                                ? -1
                                                                : 0
                                                        )
                                                        .map((item) => ({
                                                            label: item.codes[0]?.replace(" ", ""),
                                                            value: item.codes[0]?.replace(" ", ""),
                                                            icon: (
                                                                <ReactCountryFlag
                                                                    countryCode={item?.abbreviation}
                                                                    svg
                                                                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                                    cdnSuffix="svg"
                                                                    style={{
                                                                        width: "16px",
                                                                        height: "16px",
                                                                        borderRadius: "50%",
                                                                        marginTop: "-4px",
                                                                    }}
                                                                />
                                                            ),
                                                        }))}
                                                />
                                                <TextInput
                                                    name={"phone"}
                                                    type={"number"}
                                                    onWheel={(e: any) => e.target.blur()}
                                                    value={rfqDetails.phone}
                                                    onChange={handleChange}
                                                    placeholder={"Phone number"}
                                                    onKeyDown={(evt) =>
                                                        [
                                                            "e",
                                                            "E",
                                                            "+",
                                                            "-",
                                                            "ArrowUp",
                                                            "ArrowDown",
                                                        ].includes(evt.key) && evt.preventDefault()
                                                    }
                                                    minLength={10}
                                                    required={true}
                                                    pattern={"^[0-9]+$"}
                                                    title={"must be digits"}
                                                />
                                            </div>
                                            <SelectInput
                                                value={rfqDetails.country}
                                                name="country"
                                                placeholder={"Country"}
                                                handleChange={(name, value) =>
                                                    handleChange({ target: { name, value } })
                                                }
                                                isRequired={true}
                                                dropdownOptions={countries.map((item) => ({
                                                    label: item.name,
                                                    value: item.name,
                                                }))}
                                            />
                                            <div className="w-full grid grid-cols-[115px_calc(100%-119px)] gap-1 ">
                                                <SelectInput
                                                    value={rfqDetails.currency}
                                                    name="currency"
                                                    placeholder={"Currency"}
                                                    handleChange={(name, value) =>
                                                        handleChange({ target: { name, value } })
                                                    }
                                                    isRequired={Boolean(rfqDetails.budget)}
                                                    dropdownOptions={[
                                                        {
                                                            label: "GBP (£)",
                                                            value: "GBP",
                                                        },
                                                        {
                                                            label: "USD ($)",
                                                            value: "USD",
                                                        },
                                                        {
                                                            label: "EUR (€)",
                                                            value: "EUR",
                                                        },
                                                        {
                                                            label: "NGN (₦)",
                                                            value: "NGN",
                                                        },
                                                    ]}
                                                />
                                                <TextInput
                                                    name={"budget"}
                                                    type={"number"}
                                                    onWheel={(e: any) => e.target.blur()}
                                                    value={rfqDetails.budget}
                                                    onChange={handleChange}
                                                    placeholder={"Customer budget (Optional)"}
                                                    required={Boolean(rfqDetails.currency)}
                                                    onKeyDown={(evt) =>
                                                        [
                                                            "e",
                                                            "E",
                                                            "+",
                                                            "-",
                                                            "ArrowUp",
                                                            "ArrowDown",
                                                        ].includes(evt.key) && evt.preventDefault()
                                                    }
                                                    pattern={"^[0-9]+$"}
                                                    title={"must be digits"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-[40px]">
                                        <p className="text-lg font-rocGroteskMedium text-gm-50">
                                            Basic info
                                        </p>
                                        <div className="mt-4 flex space-x-4">
                                            <SelectInput
                                                value={rfqDetails.orderType}
                                                name="orderType"
                                                placeholder={"Order type"}
                                                handleChange={(name, value) =>
                                                    handleChange({ target: { name, value } })
                                                }
                                                isRequired={true}
                                                dropdownOptions={[
                                                    {
                                                        label: (
                                                            <>
                                                                <i className="ri-user-location-line"></i>{" "}
                                                                Supply
                                                            </>
                                                        ),
                                                        value: "supply",
                                                    },
                                                    {
                                                        label: (
                                                            <>
                                                                <i className="ri-truck-line"></i>{" "}
                                                                Logistics
                                                            </>
                                                        ),
                                                        value: "logistics",
                                                    },
                                                ]}
                                            />
                                            <TextInput
                                                name={"rfqStartDate"}
                                                type={"date"}
                                                value={rfqDetails.rfqStartDate}
                                                onChange={handleChange}
                                                placeholder={"RFQ start date"}
                                                required={true}
                                                min={restrictPastDate()}
                                                containerClassname=""
                                            />
                                            <TextInput
                                                name={"rfqStartTime"}
                                                type={"time"}
                                                value={rfqDetails.rfqStartTime}
                                                onChange={handleChange}
                                                placeholder={"RFQ start time"}
                                                required={true}
                                                containerClassname=""
                                            />
                                        </div>
                                        <div className="flex space-x-4 mt-4">
                                            <TextInput
                                                name={"rfqEndDate"}
                                                type={"date"}
                                                value={rfqDetails.rfqEndDate}
                                                onChange={handleChange}
                                                placeholder={"RFQ end date"}
                                                required={true}
                                                min={restrictPastDate()}
                                                containerClassname=""
                                            />
                                            <TextInput
                                                name={"rfqEndTime"}
                                                type={"time"}
                                                value={rfqDetails.rfqEndTime}
                                                onChange={handleChange}
                                                placeholder={"RFQ end time"}
                                                required={true}
                                                containerClassname=""
                                            />
                                            <TextInput
                                                name={"deliveryDate"}
                                                type={"date"}
                                                value={rfqDetails.deliveryDate}
                                                onChange={handleChange}
                                                placeholder={"Delivery date"}
                                                required={true}
                                                min={restrictPastDate()}
                                                containerClassname=""
                                            />
                                            <AddressInput
                                                setData={setAddress}
                                                savedAddress={
                                                    address?.deliveryAddress
                                                        ? address?.deliveryAddress?.address
                                                        : ""
                                                }
                                                placeholder="Delivery address"
                                                required={true}
                                                name={"deliveryAddress"}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-[40px]">
                                        <div className="mb-6">
                                            <p className="text-lg font-rocGroteskMedium text-gm-50">
                                                Request for Quote Items
                                            </p>
                                            <div className="mt-4">
                                                <CustomTable
                                                    tableBody={tableBody}
                                                    tableHeader={tableHeader}
                                                    headerContainerClass=""
                                                    isScrollable={false}
                                                    isCellBordered={true}
                                                    bodyItemClass={"hover:!bg-transparent "}
                                                    headerItemClass="!text-slate-700 !text-sm !font-rocGroteskMedium"
                                                />
                                            </div>
                                            <div className="flex items-center gap-3 mt-2">
                                                <Button
                                                    btnText="Add more products"
                                                    btnClassname="text-[13px] !text-gm-50 !px-4 !py-2 !bg-[#F4F5F7] !w-fit"
                                                    onClick={() =>
                                                        setServiceData((prev) => [
                                                            ...prev,
                                                            {
                                                                inventoryId: "",
                                                                item: "",
                                                                quantity: "",
                                                                uom: "",
                                                                vendors: [],
                                                                vendorNames: [],
                                                            },
                                                        ])
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-[40px]">
                                        <div className="">
                                            <p className="text-lg mb-2 font-rocGroteskMedium text-gm-50">
                                                Description
                                            </p>
                                            <TextareaInput
                                                name={"orderDescription"}
                                                value={rfqDetails.orderDescription}
                                                onChange={handleChange}
                                                rows={5}
                                                placeholder={"Order description"}
                                                containerClassname=""
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-10 max-w-[80%]">
                                        <p className="text-base mb-6 font-rocGroteskMedium text-black">
                                            Attachments
                                        </p>
                                        <div className="mt-4">
                                            {rfqDetails?.attachments?.map((doc, idx) => (
                                                <div
                                                    key={idx}
                                                    className="flex items-center justify-between p-3 rounded-md border border-slate-200 mb-4"
                                                >
                                                    <div className="flex items-center gap-2">
                                                        {displayFileIcon("pdf", false)}
                                                        <div className="font-rocGroteskMedium">
                                                            <p className="text-sm">
                                                                {doc?.filename}
                                                            </p>
                                                            {/* <p className="text-xs text-slate-500"> {convertBytes(doc?.size)}</p> */}
                                                        </div>
                                                    </div>
                                                    <div className="w-fit">
                                                        {deletingImage &&
                                                        doc?.filename === deleteFile ? (
                                                            <Loader size={6} />
                                                        ) : (
                                                            <i
                                                                className="ri-delete-bin-line text-xl text-r-50 cursor-pointer"
                                                                onClick={() => {
                                                                    removeFile(idx);
                                                                    setDeleteFile(doc?.filename);
                                                                }}
                                                            ></i>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                            {uploadingAttachment ? (
                                                <Loader />
                                            ) : (
                                                <div className="py-3 px-6 relative rounded border border-dashed border-slate-200">
                                                    <div
                                                        className="flex items-center  w-fit gap-4 cursor-pointer"
                                                        onClick={() => {
                                                            document
                                                                .getElementById("fileInput")
                                                                ?.click();
                                                        }}
                                                    >
                                                        <i className="ri-file-add-line text-2xl text-carribean-green"></i>
                                                        <p className="text-sm underline text-carribean-green font-rocGroteskMedium">
                                                            Click to upload document
                                                        </p>
                                                        <div className=" !w-[230px] cursor-pointer  absolute">
                                                            <input
                                                                type="file"
                                                                id="fileInput"
                                                                className={`z-[-1] absolute   opacity-0`}
                                                                accept="application/pdf"
                                                                multiple
                                                                // required
                                                                onChange={(evt) => {
                                                                    handleUpload(
                                                                        evt.target.files[0].name,
                                                                        evt.target.files[0]
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="mt-[40px] ">
                                        <p className="text-lg mb-2 font-rocGroteskMedium text-gm-50">
                                            Terms and conditions
                                        </p>
                                        <Editor
                                            apiKey={config.TINYMICE_API}
                                            value={rfqDetails.terms}
                                            onEditorChange={(newValue, editor) => {
                                                setRfqDetails((prev) => ({
                                                    ...prev,
                                                    terms: newValue,
                                                }));
                                            }}
                                            init={{
                                                height: 400,
                                                menubar: false,
                                                plugins: [
                                                    "advlist",
                                                    "autolink",
                                                    "lists",
                                                    "link",
                                                    "anchor",
                                                    "searchreplace",
                                                    "visualblocks",
                                                    "fullscreen",
                                                    "insertdatetime",
                                                    "media",
                                                ],
                                                placeholder: "Enter your terms of service",
                                                // selector: "textarea",
                                                resize: false,
                                                branding: false,
                                                toolbar:
                                                    "undo redo | casechange blocks | bold italic underline link backcolor | " +
                                                    "alignleft aligncenter alignright alignjustify | " +
                                                    "bullist numlist checklist outdent indent | removeformat",
                                                content_style:
                                                    "body { font-family:rocGrotesk,Helvetica,Arial,sans-serif; font-size:14px; }",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            {showPreview && (
                <div className="bg-white rounded-lg border border-slate-200">
                    <div className="flex top-[-2px] rounded-t-lg md:sticky px-8 py-4 z-[50] bg-white  items-center justify-between  border-b border-slate-100">
                        <div className="" onClick={() => setShowPreview(false)}>
                            <i className="ri-arrow-left-line"></i>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="flex items-center space-x-2">
                                <div className="h-2 w-2 rounded-full bg-[#FFB903]"></div>
                                <p className="text-[#FFB903] text-sm font-rocGroteskMedium">
                                    Waiting to be published
                                </p>
                            </div>
                            <Dropdown
                                value={""}
                                dropdown={
                                    <div className="flex items-center cursor-pointer gap-2 py-1.5 px-3 bg-white rounded shadow-boxShadow-2 border border-n-20">
                                        <i className="ri-more-2-fill"></i>
                                    </div>
                                }
                                dropdownContainerClasses={
                                    " left-[-234px] !py-0 top-[49px] shadow-dropdownShadow rounded !border-0"
                                }
                                dropdownClassName={"!max-h-fit"}
                                name={"quickAction"}
                                dropdownOptions={[]}
                            />
                            <Button
                                disabled={sendingRfqs}
                                btnText="Close"
                                onClick={() => {}}
                                btnClassname="!px-4 !py-2.5 !bg-transparent !border !border-tradeally-neutral-20 !w-fit"
                            />
                            <Button
                                disabled={sendingRfqs}
                                btnText="Duplicate"
                                onClick={() => {}}
                                btnClassname="!px-4 !py-2.5 !bg-transparent !border !border-tradeally-neutral-20 !w-fit"
                            />
                            <Button
                                disabled={sendingRfqs}
                                btnText="Activity"
                                onClick={() => {}}
                                btnClassname="!px-4 !py-2.5 !bg-transparent !border !border-tradeally-neutral-20 !w-fit"
                            />
                            <Button
                                disabled={sendingRfqs}
                                btnText="Edit RFQ"
                                onClick={() => setShowPreview(false)}
                                btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                            />
                            <Button
                                btnText="Send RFQ"
                                btnClassname="!px-4 !py-2.5 !w-fit"
                                isLoading={sendingRfqs}
                                onClick={sendRfqDetails}
                            />
                        </div>
                    </div>
                    <div className="">
                        <div className="bg-slate-50 border border-slate-100 flex justify-between items-center py-4 px-8">
                            <p className="text-sm font-rocGroteskMedium text-gm-50">
                                Created by{" "}
                                <span className="text-slate-500">{rfqDetails.contactName}</span>
                            </p>
                            <p className="text-sm font-rocGroteskMedium text-gm-50">
                                Created on{" "}
                                <span className="text-slate-500">
                                    {moment(new Date()).format("MMM DD, YYYY")}
                                </span>
                            </p>
                            {/* <p className="text-sm font-rocGroteskMedium text-gm-50">
                                Publishing date:{" "}
                                <span className="text-slate-500">Jan 15, 2023</span>
                            </p> */}
                            <p className="text-sm font-rocGroteskMedium text-gm-50">
                                Approver: <span className="text-slate-500">Not stated</span>
                            </p>
                        </div>
                        <div className="px-8 mt-4 mb-4">
                            <p className="text-base mb-4 text-gm-50 font-rocGroteskMedium">
                                Customer details{" "}
                                <span className="text-sm text-slate-500">
                                    (this information will not be shared with your suppliers)
                                </span>
                            </p>
                            <div className="grid grid-cols-3 gap-4 pb-6 border-b border-slate-200 ">
                                <div className="text-sm font-rocGroteskMedium">
                                    <p className="text-gm-50 ">Company name</p>
                                    <p className="text-slate-500">{rfqDetails.companyName}</p>
                                </div>
                                <div className="text-sm font-rocGroteskMedium">
                                    <p className="text-gm-50 ">Full name</p>
                                    <p className="text-slate-500">{rfqDetails.contactName}</p>
                                </div>
                                <div className="text-sm font-rocGroteskMedium">
                                    <p className="text-gm-50 ">Email address</p>
                                    <p className="text-slate-500">{rfqDetails.emailAddress}</p>
                                </div>
                                <div className="text-sm font-rocGroteskMedium">
                                    <p className="text-gm-50 ">Phone number</p>
                                    <p className="text-slate-500">
                                        {rfqDetails.ext} {rfqDetails.phone}
                                    </p>
                                </div>
                                <div className="text-sm font-rocGroteskMedium">
                                    <p className="text-gm-50 ">Currency</p>
                                    <p className="text-slate-500">{rfqDetails.currency}</p>
                                </div>
                                <div className="text-sm font-rocGroteskMedium">
                                    <p className="text-gm-50 ">Customer budget</p>
                                    <p className="text-slate-500">
                                        {getCurrencyFromCurrencyCode(rfqDetails.currency)}
                                        {rfqDetails.budget}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-8 mt-4 mb-4">
                        <p className="text-base mb-4 text-gm-50 font-rocGroteskMedium">
                            Basic info
                        </p>
                        <div className="grid grid-cols-3 gap-4 pb-8 border-b ">
                            <div className="text-sm font-rocGroteskMedium">
                                <p className="text-gm-50 ">Order type</p>
                                <p className="text-slate-500">{rfqDetails.orderType} </p>
                            </div>
                            <div className="text-sm font-rocGroteskMedium">
                                <p className="text-gm-50 ">Country</p>
                                <p className="text-slate-500">{rfqDetails.country}</p>
                            </div>
                            <div className="text-sm font-rocGroteskMedium">
                                <p className="text-gm-50 ">RFQ start date</p>
                                <p className="text-slate-500">
                                    {moment(rfqDetails.rfqStartDate).format("MMM DD, YYYY")}
                                </p>
                            </div>
                            <div className="text-sm font-rocGroteskMedium">
                                <p className="text-gm-50 ">RFQ end date</p>
                                <p className="text-slate-500">
                                    {moment(rfqDetails.rfqEndDate).format("MMM DD, YYYY")}
                                </p>
                            </div>
                            <div className="text-sm font-rocGroteskMedium">
                                <p className="text-gm-50 ">Delivery date</p>
                                <p className="text-slate-500">
                                    {moment(rfqDetails.deliveryDate).format("MMM DD, YYYY")}
                                </p>
                            </div>
                            <div className="text-sm font-rocGroteskMedium">
                                <p className="text-gm-50 ">Delivery address</p>
                                <p className="text-slate-500">
                                    {address?.deliveryAddress?.address}
                                </p>
                            </div>
                        </div>
                        <div className="mt-[30px]">
                            <div className="mb-6">
                                <p className="text-lg font-rocGroteskMedium text-gm-50">
                                    Request for Quote Items
                                </p>
                                <div className="mt-4">
                                    <CustomTable
                                        tableBody={tablePreviewBody}
                                        tableHeader={tableHeader}
                                        headerContainerClass=""
                                        isScrollable={false}
                                        isCellBordered={true}
                                        bodyItemClass={"hover:!bg-transparent "}
                                        headerItemClass="!text-slate-700 !text-sm !font-rocGroteskMedium"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-[30px] pb-8 border-b  border-slate-200">
                            <div className="">
                                <p className="text-lg mb-2 font-rocGroteskMedium text-gm-50">
                                    Description
                                </p>
                                <p className="text-sm text-slate-500 font-rocGroteskMedium">
                                    {rfqDetails.orderDescription}
                                </p>
                            </div>
                        </div>
                        {rfqDetails?.attachments.length > 0 && (
                            <div className="mt-[30px] pb-8 border-b  border-slate-200">
                                <p className="text-lg mb-2 font-rocGroteskMedium text-gm-50">
                                    File attachments
                                </p>
                                {rfqDetails?.attachments?.map((doc) => (
                                    <div className="flex items-center justify-between p-3 rounded-md border border-slate-200">
                                        <div className="flex items-center gap-2">
                                            {displayFileIcon("pdf")}
                                            <div className="font-rocGroteskMedium">
                                                <p className="text-sm">{doc.filename}</p>
                                                <p className="text-xs text-slate-500">
                                                    {/* {item?.size} */}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {/* <div className="mt-[30px] pb-8 ">
                   <p className="text-lg mb-3 font-rocGroteskMedium text-gm-50">
                       Approval workflow
                   </p>
                   <div className="flex items-center justify-between py-2  border-t  border-slate-100">
                       <div className="flex items-center w-full justify-between">
                           <p className="text-sm font-rocGroteskMedium text-gm-50">
                               Augustine Asiuwhu -{" "}
                               <span className="text-slate-500">Chief Executive Officer</span>:
                           </p>
                           <div className="flex items-center space-x-32">
                               <p className="text-slate-500 font-rocGroteskMedium text-sm">
                                   Level 1
                               </p>
                               <div className="bg-[#F9DFB3] rounded w-fit py-1 px-3 flex justify-center items-center">
                                   <p className="text-xs font-rocGroteskMedium text-slate-900 ">
                                       Pending
                                   </p>
                               </div>
                           </div>
                       </div>
                   </div>
                   <div className="flex items-center justify-between py-2  border-t  border-slate-100">
                       <div className="flex items-center w-full justify-between">
                           <p className="text-sm font-rocGroteskMedium text-gm-50">
                               Emily Kate -{" "}
                               <span className="text-slate-500">Procument Manager</span>:
                           </p>
                           <div className="flex items-center space-x-32">
                               <p className="text-slate-500 font-rocGroteskMedium text-sm">
                                   Level 2
                               </p>
                               <div className="bg-[#F9DFB3] rounded w-fit py-1 px-3 flex justify-center items-center">
                                   <p className="text-xs font-rocGroteskMedium text-slate-900 ">
                                       Pending
                                   </p>
                               </div>
                           </div>
                       </div>
                   </div>
                   <div className="flex items-center justify-between py-2  border-t border-slate-100">
                       <div className="flex items-center w-full justify-between">
                           <p className="text-sm font-rocGroteskMedium text-gm-50">
                               Caroline Fedoño -{" "}
                               <span className="text-slate-500">Chief Finance Officer:</span>:
                           </p>
                           <div className="flex items-center space-x-32">
                               <p className="text-slate-500 font-rocGroteskMedium text-sm">
                                   Level 3
                               </p>
                               <div className="bg-[#F9DFB3] rounded w-fit py-1 px-3 flex justify-center items-center">
                                   <p className="text-xs font-rocGroteskMedium text-slate-900 ">
                                       Pending
                                   </p>
                               </div>
                           </div>
                       </div>
                   </div>
               </div> */}
                        <div className="mt-[30px]">
                            <h6 className="flex justify-between text-[14px] font-rocGroteskMedium text-gm-50">
                                Terms and Conditions
                            </h6>
                            <div className="space-y-0.5 text-[14px] font-rocGroteskMedium text-slate-500">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: hasHTMLTag(rfqDetails.terms)
                                            ? sanitizeHtml(rfqDetails.terms).sanitizedHTML?.join("")
                                            : rfqDetails.terms,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <ModalContainer open={successModal} showCloseIcon={false} tailwindClassName="">
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="flex justify-end pt-5 pr-5">
                        <i
                            className="ri-close-fill text-2xl text-gm-50 "
                            onClick={() => setSuccessModal(false)}
                        ></i>
                    </div>

                    <div className="px-8 max-h-[70vh] overflow-y-scroll ">
                        <div className="flex flex-col items-center mb-8">
                            <div className="w-12 h-12 rounded-full bg-[#00DB8F] flex items-center justify-center">
                                <i className="ri-checkbox-circle-fill text-xl text-white cursor-pointer"></i>
                            </div>

                            <p className="text-[22px] text-center mt-[18px] font-rocGroteskMedium text-g-75">
                                RFQs created
                                {/* Approval! (0/3) */}
                            </p>
                            <p className="text-base text-center font-rocGroteskMedium text-slate-500 max-w-[496px]">
                                You've successfully published and sent your RFQ for approval.
                                {/* Your part is done, and we've notified the next approver in line. */}
                            </p>
                        </div>

                        {/* <div className="flex justify-between text-sm text-gm-50 font-rocGroteskMedium mb-[14px]">
                            <p className="">RFQs</p>
                            <p className="">Suppliers sent</p>
                        </div>
                        <div className="px-[10px] bg-[#F8FAFC] mb-4">
                            <div className="py-4  flex items-center justify-between  font-rocGroteskMedium border-b border-slate-200">
                                <div className="border-2 rounded border-[#4ADE80] bg-[#F0FDF4] py-2 px-[10px] gap-4 cursor-pointer w-[234px]">
                                    <div className="flex gap-4">
                                        <div className="bg-[#4ADE80] rounded p-2 !w-auto">
                                            <i className="ri-file-list-3-fill text-lg text-white"></i>
                                        </div>
                                        <div>
                                            <p className="text-black text-sm">RFQ 001</p>
                                            <p className="text-slate-700 text-xs">Click to view</p>
                                        </div>
                                    </div>
                                </div>
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702538223/Tradeally_2.0/synthAlly_logo_skcywb.svg"
                                    alt="company logo"
                                    className=""
                                />
                            </div>
                            <div className="py-4  flex items-center justify-between  font-rocGroteskMedium border-b border-slate-200">
                                <div className="border-2 rounded border-[#4ADE80] bg-[#F0FDF4] py-2 px-[10px] gap-4 cursor-pointer w-[234px]">
                                    <div className="flex gap-4">
                                        <div className="bg-[#4ADE80] rounded p-2 !w-auto">
                                            <i className="ri-file-list-3-fill text-lg text-white"></i>
                                        </div>
                                        <div>
                                            <p className="text-black text-sm">RFQ 001</p>
                                            <p className="text-slate-700 text-xs">Click to view</p>
                                        </div>
                                    </div>
                                </div>
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702538223/Tradeally_2.0/synthAlly_logo_skcywb.svg"
                                    alt="company logo"
                                    className=""
                                />
                            </div>
                            <div className="py-4  flex items-center justify-between  font-rocGroteskMedium border-b border-slate-200">
                                <div className="border-2 rounded border-[#4ADE80] bg-[#F0FDF4] py-2 px-[10px] gap-4 cursor-pointer w-[234px]">
                                    <div className="flex gap-4">
                                        <div className="bg-[#4ADE80] rounded p-2 !w-auto">
                                            <i className="ri-file-list-3-fill text-lg text-white"></i>
                                        </div>
                                        <div>
                                            <p className="text-black text-sm">RFQ 001</p>
                                            <p className="text-slate-700 text-xs">Click to view</p>
                                        </div>
                                    </div>
                                </div>
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702538223/Tradeally_2.0/synthAlly_logo_skcywb.svg"
                                    alt="company logo"
                                    className=""
                                />
                            </div>
                            <div className="py-4  flex items-center justify-between  font-rocGroteskMedium border-b border-slate-200">
                                <div className="border-2 rounded border-[#4ADE80] bg-[#F0FDF4] py-2 px-[10px] gap-4 cursor-pointer w-[234px]">
                                    <div className="flex gap-4">
                                        <div className="bg-[#4ADE80] rounded p-2 !w-auto">
                                            <i className="ri-file-list-3-fill text-lg text-white"></i>
                                        </div>
                                        <div>
                                            <p className="text-black text-sm">RFQ 001</p>
                                            <p className="text-slate-700 text-xs">Click to view</p>
                                        </div>
                                    </div>
                                </div>
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702538223/Tradeally_2.0/synthAlly_logo_skcywb.svg"
                                    alt="company logo"
                                    className=""
                                />
                            </div>
                        </div> */}
                    </div>

                    <div className=" flex items-center justify-between gap-4 px-8 py-4 border-t border-slate-200 w-full ">
                        <div className="flex-1">
                            {" "}
                            <Button
                                btnText={"Create more RFQ"}
                                type={"button"}
                                btnClassname="!bg-tradeally-neutral-20 !text-sm"
                                onClick={() => {
                                    setSuccessModal(false);
                                    setShowPreview(false);
                                    setRfqDetails({
                                        budget: "",
                                        currency: "",
                                        orderType: "",
                                        rfqStartDate: "",
                                        rfqStartTime: "",
                                        rfqEndDate: "",
                                        rfqEndTime: "",
                                        deliveryDate: "",
                                        country: "",
                                        vendorCategory: "",
                                        orderDescription: "",
                                        terms: "",
                                        companyName: "",
                                        contactName: "",
                                        emailAddress: "",
                                        ext: "",
                                        phone: "",
                                        attachments: [],
                                    });
                                    setServiceData([
                                        {
                                            inventoryId: "",
                                            item: "",
                                            quantity: "",
                                            uom: "",
                                            vendors: [],
                                            vendorNames: [],
                                        },
                                    ]);
                                    navigate("/dashboard/rfqs/create-blank");
                                }}
                            />
                        </div>
                        <div className="flex-1">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname="!flex-1 !text-sm"
                                onClick={() => {
                                    setSuccessModal(false);
                                    navigate("/dashboard/rfqs");
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default CreateRfqBlank;
