/* eslint-disable react-hooks/exhaustive-deps */
import AddVendorForm from "components/common/AddVendorForm";
import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CustomMobileTable from "components/common/CustomMobileTable";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import TabsContainer from "components/common/TabsContainer";
// import Rating from "components/common/Rating";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { onScroll, removeFromStorage } from "helpers";
import { debounce, truncate } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authActions, vendorActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { getEditPermissionList } from "helpers/getEditPermissionList";

const Vendors = () => {
    const limit = 15;
    const [addVendorOpen, setAddVendorOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [vendors, setVendors] = useState<{ [key: string]: any }[]>([]);
    const [onboardedVendors, setOnboardedVendors] = useState<{ [key: string]: any }[]>([]);
    const [vendorLimit, setVendorLimit] = useState<number>(limit);
    const [loadMoreVendors, setLoadMoreVendors] = useState(false);
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [vendorPagination, setVendorPagination] = useState<{ [key: string]: any }>({
        current: "",
        number_of_pages: "",
    });
    const [onBoardedVendorLimit, setOnboardedVendorLimit] = useState(limit);
    const [onBoardedVendorPagination, setOnboardedVendorPagination] = useState({
        current: "",
        number_of_pages: "",
    });
    const [pendingVendorTotal, setPendingVendorTotal] = useState(0);
    const [onboardedVendorTotal, setOnboardedVendorTotal] = useState(0);
    const [email, setEmail] = useState("");
    const [vendorCategory, setVendorCategory] = useState("");
    const [requiredDocuments, setRequiredDocuments] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const tabs = [
        <div className="flex items-center gap-1">
            <span>Onboarded suppliers</span>

            <span className="px-2 py-[2px] bg-slate-100 rounded-[10px] text-xs text-tradeally-neutral-200 font-rocGroteskMedium">
                {onboardedVendorTotal}
            </span>
        </div>,
        <div className="flex items-center gap-1">
            <span>Pending suppliers</span>

            <span className="px-2 py-[2px] bg-slate-100 rounded-[10px] text-xs text-tradeally-neutral-200 font-rocGroteskMedium">
                {pendingVendorTotal}
            </span>
        </div>,
    ];
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const dashboardContent = document.getElementById("dashboardContent");

    const { addingVendor } = useAppSelector((state) => state.auth);

    const { fetchingLdbVendors, fetchedLdbVendorsSuccess } = useAppSelector(
        (state) => state.vendor
    );

    const tableHeader = [
        { title: "Company", widthClass: "w-[307px]" },
        { title: "Category", widthClass: "w-[250px]" },
        { title: "Email address", widthClass: "w-[250px]" },
        { title: "Time of onboarding", widthClass: "w-[250px]" },
        { title: "", widthClass: "w-[90px]" },
    ];

    const tableBody = vendors?.map((vendor, idx) => [
        {
            content: (
                <div className={`py-3 pr-3 border-slate-100 text-sm text-slate-800 `}>
                    <div className="flex items-center gap-3">
                        <div className="w-8 h-8 rounded bg-n-20 flex items-center justify-center">
                            <i className="ri-building-4-fill text-lg text-slate-500"></i>
                        </div>
                        <span>Supplier 00{idx + 1}</span>
                    </div>
                </div>
            ),
            cellClickAction: () => {
                navigate(`/dashboard/supplier/${vendor?.vendorId}`);
            },
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    {truncate(vendor?.category, { length: 30 }) || "----"}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    {truncate(vendor?.vendor?.email, { length: 30 }) || "----"}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    {moment(vendor?.createdAt)?.format("MMM DD, YYYY - LT")}
                </div>
            ),
        },
        {
            content: (
                <div
                    onClick={(e) => e.stopPropagation()}
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    {/* <Rating ratingNo={3} totalRating={5} /> */}
                    <Dropdown
                        dropdown={
                            <i className="ri-more-2-fill before:content-['\ef76'] text-slate-500"></i>
                        }
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 left-[-120px] ${
                            vendors?.length >= 9 && idx >= vendors?.length - 2 && "!top-[-70px]"
                        } border-0 rounded !max-h-fit`}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "Re-invite vendor",
                                value: "re-invite vendor",
                                action: () => {
                                    const body = {
                                        vendors: [
                                            {
                                                email: vendor?.vendor?.email,
                                                documents: vendor?.documents?.map(
                                                    (doc: { [key: string]: any }) => ({
                                                        name: doc?.name,
                                                    })
                                                ),
                                                category: vendor?.category,
                                            },
                                        ],
                                    };
                                    dispatch(authActions.addVendor(body));
                                },
                                disabled: vendor?.status?.toLowerCase() === "accepted",
                                permissions: getEditPermissionList("supplier"),
                            },
                        ]}
                    />
                </div>
            ),
        },
    ]);

    const onboardedTableHeader = [
        { title: "Company", widthClass: "w-[307px]" },
        { title: "Category", widthClass: "w-[250px]" },
        { title: "Email address", widthClass: "w-[250px]" },
        { title: "Phone number", widthClass: "w-[250px]" },
        { title: "Time of onboarding", widthClass: "w-[250px]" },
    ];

    const onboardedTableBody = onboardedVendors?.map((vendor, idx) => [
        {
            content: (
                <div className={`py-3 pr-3 border-slate-100 text-sm text-slate-800 `}>
                    <div className="flex items-center gap-3">
                        <VendorAvatar
                            size={30}
                            imageSrc={vendor?.vendor?.profile?.avatar}
                            name={vendor?.vendor?.profile?.businessName}
                        />
                        <span>{vendor?.vendor?.profile?.businessName || "----"}</span>
                    </div>
                </div>
            ),
            cellClickAction: () => {
                navigate(`/dashboard/supplier/${vendor?.vendorId}`);
            },
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    {truncate(vendor?.category, { length: 30 }) || "----"}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    {truncate(vendor?.vendor?.email, { length: 30 }) || "----"}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    {vendor?.vendor?.mobile || "----"}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    {moment(vendor?.createdAt)?.format("MMM DD, YYYY - LT")}
                </div>
            ),
        },
    ]);

    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e.target.value);
    }, 1500);

    const onboardedVendorsMobileTableContent = onboardedVendors?.map((vendor) => {
        return {
            cellClickAction: () => {
                navigate(`/dashboard/supplier/${vendor?.vendorId}`);
            },
            topString: vendor?.vendor?.profile?.businessName || "----",
            avatar: (
                <VendorAvatar
                    size={48}
                    imageSrc={vendor?.vendor?.profile?.avatar}
                    name={vendor?.vendor?.profile?.businessNam}
                />
            ),
            topContent: (
                <p className="text-sm font-rocGroteskMedium">
                    {vendor?.vendor?.profile?.businessName || "----"}
                </p>
            ),
            bottomContent: (
                <div className="flex items-center gap-3">
                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                        {truncate(vendor?.category, { length: 15 }) || "----"}
                    </p>
                    <p className="flex items-center gap-2 text-sm font-rocGroteskMedium">
                        <span className="w-2 h-2 rounded-full bg-carribean-green"></span>
                        <span>Active</span>
                    </p>
                </div>
            ),
            rightIcon: <i className="ri-arrow-right-s-line text-2xl"></i>,
        };
    });

    const pendingVendorsMobileTableContent = vendors?.map((vendor, idx) => {
        return {
            cellClickAction: () => {
                navigate(`/dashboard/supplier/${vendor?.vendorId}`);
            },
            topString: "Vendor 00" + (idx + 1),
            avatar: (
                <div className="w-12 h-12 rounded bg-n-20 flex items-center justify-center">
                    <i className="ri-building-4-fill text-2xl text-slate-500"></i>
                </div>
            ),
            topContent: <p className="text-sm font-rocGroteskMedium">{"Vendor 00" + (idx + 1)}</p>,
            bottomContent: (
                <div className="flex items-center gap-3">
                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                        {truncate(vendor?.category, { length: 15 }) || "----"}
                    </p>
                    <p className="flex items-center gap-2 text-sm font-rocGroteskMedium">
                        <span className="w-2 h-2 rounded-full bg-slate-500"></span>
                        <span>Inactive</span>
                    </p>
                </div>
            ),
            rightIcon: <i className="ri-arrow-right-s-line text-2xl"></i>,
        };
    });

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if ((e.target as any).scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleAddVendor = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body = {
            vendors: [
                {
                    email,
                    documents: requiredDocuments?.map((docName) => ({ name: docName })),
                    category: vendorCategory,
                },
            ],
        };

        dispatch(authActions.addVendor(body));
    };

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return onboardedVendors?.length > 0 ? (
                    <div className="mt-4">
                        <div className="overflow-x-scroll max-lg:hidden" onScroll={handleScroll}>
                            <CustomTable
                                tableBody={onboardedTableBody}
                                tableHeader={onboardedTableHeader}
                                isAllSelectable={true}
                                isScrollable={true}
                                isScrolled={isScrolled}
                                isCellBordered={false}
                                headerContainerClass="!bg-slate-50 !border-slate-100 "
                                bodyItemClass="hover:border-slate-100 hover:bg-slate-50 "
                                headerItemClass="font-rocGroteskMedium "
                            />
                        </div>
                        <div className="hidden max-lg:block">
                            <CustomMobileTable data={onboardedVendorsMobileTableContent} />
                        </div>
                        {activeTab === 0 && fetchingLdbVendors && loadMoreVendors && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {onBoardedVendorPagination?.current ===
                            onBoardedVendorPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                        <div className="space-y-2  text-center">
                            <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                            <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                {Boolean(debouncedSearch)
                                    ? "No supplier found"
                                    : "No suppliers yet"}
                            </p>
                            {
                                <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                    {Boolean(debouncedSearch)
                                        ? "No supplier found with the search query"
                                        : "Your list of suppliers will be displayed here"}
                                </p>
                            }
                        </div>
                    </div>
                );
            case 1:
                return vendors?.length > 0 ? (
                    <div className="mt-4">
                        <div className="max-lg:hidden">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isCellBordered={false}
                                headerContainerClass="!bg-slate-50 !border-slate-100 "
                                bodyItemClass="hover:border-slate-100 hover:bg-slate-50 "
                                headerItemClass="font-rocGroteskMedium "
                            />
                        </div>
                        <div className="hidden max-lg:block">
                            <CustomMobileTable data={pendingVendorsMobileTableContent} />
                        </div>
                        {activeTab === 1 && fetchingLdbVendors && loadMoreVendors && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {vendorPagination?.current === vendorPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                        <div className="space-y-2  text-center">
                            <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                            <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                {Boolean(debouncedSearch)
                                    ? "No supplier found"
                                    : "No suppliers yet"}
                            </p>
                            {
                                <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                    {Boolean(debouncedSearch)
                                        ? "No supplier found with the search query"
                                        : "Your list of suppliers will be displayed here"}
                                </p>
                            }
                        </div>
                    </div>
                );

            default:
                return onboardedVendors?.length > 0 ? (
                    <div className="mt-4">
                        <div className="overflow-x-scroll max-lg:hidden" onScroll={handleScroll}>
                            <CustomTable
                                tableBody={onboardedTableBody}
                                tableHeader={onboardedTableHeader}
                                isAllSelectable={true}
                                isScrollable={true}
                                isScrolled={isScrolled}
                                isCellBordered={false}
                                headerContainerClass="!bg-slate-50 !border-slate-100 "
                                bodyItemClass="hover:border-slate-100 hover:bg-slate-50 "
                                headerItemClass="font-rocGroteskMedium "
                            />
                        </div>
                        <div className="hidden max-lg:block">
                            <CustomMobileTable data={onboardedVendorsMobileTableContent} />
                        </div>
                        {activeTab === 0 && fetchingLdbVendors && loadMoreVendors && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {vendorPagination?.current === vendorPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                        <div className="space-y-2  text-center">
                            <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                            <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                {Boolean(debouncedSearch)
                                    ? "No supplier found"
                                    : "No suppliers yet"}
                            </p>
                            {
                                <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                    {Boolean(debouncedSearch)
                                        ? "No supplier found with the search query"
                                        : "Your list of suppliers will be displayed here"}
                                </p>
                            }
                        </div>
                    </div>
                );
        }
    };

    const onVendorTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent as HTMLElement,
                Number(
                    activeTab === 0 ? onBoardedVendorPagination?.current : vendorPagination?.current
                ),
                Number(
                    activeTab === 0
                        ? onBoardedVendorPagination?.number_of_pages
                        : vendorPagination?.number_of_pages
                ),
                () => {
                    if (activeTab === 1) {
                        setVendorLimit(() => vendorLimit + limit);
                    }
                    if (activeTab === 0) {
                        setOnboardedVendorLimit(() => onBoardedVendorLimit + limit);
                    }

                    setLoadMoreVendors(true);
                }
            ),
        [dashboardContent, vendorPagination, onBoardedVendorPagination, activeTab]
    );

    useEffect(() => {
        dispatch(vendorActions.getLdbVendors(vendorLimit, debouncedSearch, "pending"));
        removeFromStorage("connectionSource");
    }, [dispatch, vendorLimit, debouncedSearch]);

    useEffect(() => {
        dispatch(vendorActions.getLdbVendors(onBoardedVendorLimit, debouncedSearch, "accepted"));
    }, [dispatch, onBoardedVendorLimit, debouncedSearch]);

    useEffect(() => {
        if (Boolean(fetchedLdbVendorsSuccess)) {
            if (
                fetchedLdbVendorsSuccess?.vendors?.length > 0 &&
                fetchedLdbVendorsSuccess?.vendors?.[0]?.status?.toLowerCase() === "accepted"
            ) {
                setOnboardedVendors(fetchedLdbVendorsSuccess?.vendors);
                setOnboardedVendorPagination({
                    current: fetchedLdbVendorsSuccess?.pagination?.current,
                    number_of_pages: fetchedLdbVendorsSuccess?.pagination?.number_of_pages,
                });
                setOnboardedVendorTotal(fetchedLdbVendorsSuccess?.total);
            } else {
                setOnboardedVendors((prev) => [...prev]);
            }
        }
    }, [fetchedLdbVendorsSuccess]);

    useEffect(() => {
        if (Boolean(fetchedLdbVendorsSuccess)) {
            if (
                fetchedLdbVendorsSuccess?.vendors?.length > 0 &&
                fetchedLdbVendorsSuccess?.vendors?.[0]?.status?.toLowerCase() === "pending"
            ) {
                setVendors(fetchedLdbVendorsSuccess?.vendors);
                setVendorPagination({
                    current: fetchedLdbVendorsSuccess?.pagination?.current,
                    number_of_pages: fetchedLdbVendorsSuccess?.pagination?.number_of_pages,
                });
                setPendingVendorTotal(fetchedLdbVendorsSuccess?.total);
            } else {
                setVendors((prev) => [...prev]);
            }
        }
    }, [fetchedLdbVendorsSuccess]);

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onVendorTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onVendorTableScroll);
        };
    }, [dashboardContent, onVendorTableScroll]);

    return (
        <PageFrame
            containerClassName={"!h-[calc(100vh-108px)]"}
            isLoading={fetchingLdbVendors && !loadMoreVendors && !Boolean(search)}
        >
            <div className="px-[14px] max-lg:px-0">
                <div className="mb-8">
                    <BreadCrumb
                        parentCrumb={"Supplier Management"}
                        parentUrl={"/dashboard/suppliers"}
                        childrenCrumb={[{ title: "Supplier list", path: "/dashboard/suppliers" }]}
                        size="large"
                    />
                </div>

                {/* {onboardedVendors?.length > 0 && vendors?.length > 0 ? ( */}
                <div className="" id="tabContainer">
                    <TabsContainer
                        tabs={tabs}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        component={displayActiveTab()}
                        className="!px-0"
                        itemClassName="!pb-2"
                        borderLineClase={"!text-slate-100 w-[40%] max-lg:w-full"}
                        showButton={
                            <div className="flex items-center gap-3 max-lg:flex-col max-lg:mt-3">
                                <div className="flex items-center gap-3 max-lg:w-full">
                                    <TextInput
                                        name={"search"}
                                        value={search}
                                        type={"text"}
                                        inputPlaceholder={"Search by company name or email"}
                                        inputClassName={
                                            "!h-[38px] pl-[0px] !bg-white text-sm !mb-0"
                                        }
                                        onChange={handleSearch}
                                        onInput={handleDebouncedChange}
                                        leftIcon={
                                            <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                        }
                                        inputContainerClassname={
                                            "!rounded-[4px] !w-[300px] !pl-[40px] !h-[40px] max-lg:!w-full !bg-white shadow-boxShadow-2 !border !border-n-20"
                                        }
                                    />
                                </div>
                                <div className="max-lg:w-full">
                                    <Button
                                        btnText={"Initiate onboarding"}
                                        type={"button"}
                                        onClick={() => navigate("/dashboard/suppliers/add-vendor")}
                                        btnClassname="!py-2 !px-4"
                                    />
                                </div>
                            </div>
                        }
                    />
                </div>
                {/* ) : (
                    <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                        <div className="space-y-2  text-center">
                            <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                            <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                {Boolean(debouncedSearch) ? "No supplier found" : "No supplier yet"}
                            </p>
                            {
                                <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                    {Boolean(debouncedSearch)
                                        ? "No supplier found with the search query"
                                        : "Your list of supplier will be displayed here"}
                                </p>
                            }
                        </div>
                    </div>
                )} */}
            </div>
            <ModalContainer
                open={addVendorOpen}
                showCloseIcon={false}
                tailwindClassName="w-[36%] mt-[-10%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setAddVendorOpen(false);
                    setEmail("");
                    setVendorCategory("");
                    setRequiredDocuments([]);
                }}
            >
                <AddVendorForm
                    onSubmit={handleAddVendor}
                    email={email}
                    vendorCategory={vendorCategory}
                    onChangeEmail={setEmail}
                    onChangeCategory={setVendorCategory}
                    onChangeDocs={setRequiredDocuments}
                    isSending={addingVendor}
                />
            </ModalContainer>
        </PageFrame>
    );
};

export default Vendors;
