import LandingNav from "components/views/Landing/LandingNav";
import React from "react";

const Policy = () => {
    return (
        <div>
            <LandingNav />
            <div className="pt-[104px] pb-10 px-16 max-lg:px-6">
                <h2 className="text-xl font-rocGroteskBold mb-6">Policy</h2>
                <div className="flex flex-col gap-[42px]">
                    <div>
                        <h3 className="text-lg font-rocGroteskBold mb-4">Introduction</h3>
                        <p className="text-slate-500 text-sm font-rocGroteskMedium">
                            Welcome to SynthAlly Inc, we are committed to protecting your privacy
                            and ensuring the security of your personal and business information.
                            This Privacy Policy explains how we collect, use, disclose, and
                            safeguard your data when you use our AI-powered supply chain solution.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-lg font-rocGroteskBold mb-4 flex gap-3 items-center">
                            <i className="ri-information-line text-[32px] mt-[-2px] text-carribean-green"></i>
                            Information we collect
                        </h3>
                        <ul className="list-disc list-outside pl-3.5 text-slate-500 text-sm font-rocGroteskMedium">
                            <li>
                                User Information: When you sign up for our service, we may collect
                                personal information such as your name, email address, company name,
                                and contact details.
                            </li>
                            <li>
                                Business Documents: Our product may collect and store business
                                documents you upload, including but not limited to purchase orders
                                (POs), invoices, receipts, and other supply chain-related documents.
                            </li>
                            <li>
                                AI Data: To provide AI-powered features, our system reads and
                                processes emails, social channels, and other communication channels
                                for the purpose of extracting supply chain-related information, such
                                as orders, requests for quotation (RFQs), and purchase orders (POs).
                            </li>
                            <li>
                                Usage Data: We may collect data on how you use our product, such as
                                feature usage, time spent on the platform, and interactions with
                                AI-generated recommendations.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-rocGroteskBold mb-4 flex gap-3 items-center">
                            <i className="ri-question-line text-[32px] mt-[-2px] text-carribean-green"></i>
                            How we use your Information
                        </h3>
                        <ul className="list-disc list-outside pl-3.5 text-slate-500 text-sm font-rocGroteskMedium">
                            <li>
                                We use the collected data to improve our AI-powered supply chain ERP
                                solution for your specific business needs.
                            </li>
                            <li>
                                To extract and process supply chain-related data from emails, social
                                channels, and other communication sources.
                            </li>
                            <li>
                                To offer personalized experiences, including AI-generated
                                recommendations and insights.
                            </li>
                            <li>
                                To communicate with you, respond to inquiries, and provide customer
                                support.
                            </li>
                            <li>To meet legal and regulatory requirements.</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-rocGroteskBold mb-4 flex gap-3 items-center">
                            <i className="ri-share-line text-[32px] mt-[-2px] text-carribean-green"></i>
                            Data sharing and Disclosure
                        </h3>
                        <p className="text-slate-500 text-sm font-rocGroteskMedium">
                            We do not share your personal or business data with third parties,
                            except in the following circumstances:
                        </p>
                        <ul className="list-disc list-outside pl-3.5 text-slate-500 text-sm font-rocGroteskMedium">
                            <li>
                                We use the collected data to improve our AI-powered supply chain ERP
                                solution for your specific business needs.
                            </li>
                            <li>
                                To extract and process supply chain-related data from emails, social
                                channels, and other communication sources.
                            </li>
                            <li>
                                To offer personalized experiences, including AI-generated
                                recommendations and insights.
                            </li>
                            <li>
                                To communicate with you, respond to inquiries, and provide customer
                                support.
                            </li>
                            <li>To meet legal and regulatory requirements.</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-rocGroteskBold mb-4 flex gap-3 items-center">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1698849800/Web%20App/website/icons/3rd-party-privacy_p2nqxz.svg"
                                alt="third party privacy"
                                className="w-8 h-8"
                            />
                            Third Party Privacy
                        </h3>
                        <p className="text-slate-500 text-sm font-rocGroteskMedium">
                            Our product may integrate with third-party services or platforms. Your
                            use of such third-party services is subject to their respective privacy
                            policies. We are not responsible for the privacy practices of these
                            third parties.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-lg font-rocGroteskBold mb-4 flex gap-3 items-center">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1698849800/Web%20App/website/icons/cookies-and-beacon_zgoxke.svg"
                                alt="third party privacy"
                                className="w-8 h-8"
                            />
                            Cookies and Web Beacons
                        </h3>
                        <p className="text-slate-500 text-sm font-rocGroteskMedium">
                            We use cookies and web beacons to collect and store information about
                            your usage of our product. This helps us understand user behavior,
                            improve user experience, and tailor content to your preferences. You can
                            manage cookie preferences through your browser settings.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-lg font-rocGroteskBold mb-4 flex gap-3 items-center">
                            <i className="ri-file-copy-2-line text-[32px] mt-[-2px] text-carribean-green"></i>
                            Log Files
                        </h3>
                        <p className="text-slate-500 text-sm font-rocGroteskMedium">
                            Our system collects log files that include IP addresses, browser types,
                            referring pages, operating systems, and timestamps. These logs are used
                            for system administration, analyzing trends, and troubleshooting issues.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-lg font-rocGroteskBold mb-4 flex gap-3 items-center">
                            <i className="ri-lock-password-line text-[32px] mt-[-2px] text-carribean-green"></i>
                            Data Security
                        </h3>
                        <p className="text-slate-500 text-sm font-rocGroteskMedium">
                            We employ industry-standard security measures to protect your data.
                            However, no data transmission or storage system can be guaranteed to be
                            100% secure. If you have reason to believe your interaction with us is
                            no longer secure, please notify us immediately.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-lg font-rocGroteskBold mb-4 flex gap-3 items-center">
                            <i className="ri-scales-3-line text-[32px] mt-[-2px] text-carribean-green"></i>
                            Your Choice and Rights
                        </h3>
                        <p className="text-slate-500 text-sm font-rocGroteskMedium">
                            We may update this Privacy Policy to reflect changes in our practices
                            and services. We encourage you to review this policy periodically to
                            stay informed about how we are protecting your data.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-lg font-rocGroteskBold mb-4 flex gap-3 items-center">
                            <i className="ri-shield-line text-[32px] mt-[-2px] text-carribean-green"></i>
                            Changes in Privacy Policy
                        </h3>
                        <p className="text-slate-500 text-sm font-rocGroteskMedium">
                            We may update this Privacy Policy to reflect changes in our practices
                            and services. We encourage you to review this policy periodically to
                            stay informed about how we are protecting your data.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-lg font-rocGroteskBold mb-4 flex gap-3 items-center">
                            <i className="ri-message-2-line text-[32px] mt-[-2px] text-carribean-green"></i>
                            Contact Us
                        </h3>
                        <p className="text-slate-500 text-sm font-rocGroteskMedium">
                            If you have any questions, concerns, or requests regarding this Privacy
                            Policy, please contact us at hello@synthally.com
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Policy;
