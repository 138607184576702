import {shipmentTypes} from "redux/Ldb/types";

const initialState = {
    fetchingShipments: false,
    fetchShipmentsSuccess: null,
    fetchShipmentsFailure: null,
    fetchingSingleShipment: false,
    fetchSingleShipmentSuccess: null,
    fetchSingleShipmentFailure: null,
    assigningShipmentToMember: false,
    assignShipmentToMemberSuccess: null,
    assignShipmentToMemberFailure: null,
};

export const shipment = (state = initialState, action: {type: string, payload: any}) => {
    switch (action.type) {
        case shipmentTypes.FETCH_SHIPMENTS_REQUEST:
            return {
                ...state,
                fetchingShipments: true,
                fetchShipmentsSuccess: null,
                fetchShipmentsFailure: null,
            };
        case shipmentTypes.FETCH_SHIPMENTS_SUCCESS:
            return {
                ...state,
                fetchingShipments: false,
                fetchShipmentsSuccess: action.payload,
                fetchShipmentsFailure: null,
            };
        case shipmentTypes.FETCH_SHIPMENTS_FAILURE:
            return {
                ...state,
                fetchingShipments: false,
                fetchShipmentsSuccess: null,
                fetchShipmentsFailure: action.payload,
            };

        case shipmentTypes.FETCH_SINGLE_SHIPMENT_REQUEST:
            return {
                ...state,
                fetchingSingleShipment: true,
                fetchSingleShipmentSuccess: null,
                fetchSingleShipmentFailure: null,
            };
        case shipmentTypes.FETCH_SINGLE_SHIPMENT_SUCCESS:
            return {
                ...state,
                fetchingSingleShipment: false,
                fetchSingleShipmentSuccess: action.payload,
                fetchSingleShipmentFailure: null,
            };
        case shipmentTypes.FETCH_SINGLE_SHIPMENT_FAILURE:
            return {
                ...state,
                fetchingSingleShipment: false,
                fetchSingleShipmentSuccess: null,
                fetchSingleShipmentFailure: action.payload,
            };

        case shipmentTypes.ASSIGN_SHIPMENT_TO_MEMBER_REQUEST:
            return {
                ...state,
                assigningShipmentToMember: true,
                assignShipmentToMemberSuccess: null,
                assignShipmentToMemberFailure: null,
            };
        case shipmentTypes.ASSIGN_SHIPMENT_TO_MEMBER_SUCCESS:
            return {
                ...state,
                assigningShipmentToMember: false,
                assignShipmentToMemberSuccess: action.payload,
                assignShipmentToMemberFailure: null,
            };
        case shipmentTypes.ASSIGN_SHIPMENT_TO_MEMBER_FAILURE:
            return {
                ...state,
                assigningShipmentToMember: false,
                assignShipmentToMemberSuccess: null,
                assignShipmentToMemberFailure: action.payload,
            };

        default:
            return state;
    }
};
