import Button from "components/common/Button";
import Rating from "components/common/Rating";
import VendorAvatar from "components/common/VendorAvatar";
import { displayFileIcon, formatMoney, hasHTMLTag, sanitizeHtml } from "helpers";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import { truncate } from "lodash";
import React from "react";

type ItemDetailsCardProps = {
    simalarResponses?: boolean;
    showQuotesDownload?: boolean;
    showSuggestionQuote?: boolean;
    title?: string;
    email?: string;
    description?: string;
    documents?: { path: string; filename: string }[];
    logo?: string;
    amount?: number | string;
    currency?: string;
    rating: number;
    sentAt?: string;
    rfqTaId?: string;
};

export const ItemDetailsCard = ({
    simalarResponses,
    showSuggestionQuote,
    showQuotesDownload,
    title = "Alfa Express Cargo Solutions",
    email = "augustine@augustine.com",
    description,
    documents,
    logo,
    rating,
    amount,
    currency,
    sentAt,
    rfqTaId,
}: ItemDetailsCardProps) => {
    return (
        <div className={`${simalarResponses && ""}  mx-1 border-slate-100 mb-3`}>
            <div className="mt-5 px-4 lg:px-[34px]">
                {!simalarResponses && (
                    <div className="flex items-center justify-between w-full">
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1708617615/Tags_mij6er.svg"
                            alt="recommended-icon"
                        />

                        <div className="text-end text-gm-50">
                            <p className="text-sm  font-rocGroteskRegular">Total Amount: </p>
                            <p className="text-[32px] leading-[46.08px] font-rocGroteskBold">
                                {currency}
                                {amount}
                            </p>
                        </div>
                    </div>
                )}
                {simalarResponses && (
                    <div className="w-full">
                        <div className="text-gm-50">
                            <p className="text-sm  font-rocGroteskRegular">Quote Amount: </p>
                            <p className="text-2xl font-rocGroteskBold">
                                {currency}
                                {amount}
                            </p>
                        </div>
                    </div>
                )}
                <div className="mt-3">
                    <p className="text-slate-900 text-[12px] font-rocGroteskMedium">
                        RFQ-{rfqTaId}
                    </p>
                    <div className="flex justify-between mt-1.5 items-center">
                        <div className="flex items-center space-x-3 ">
                            <div className="my-[15px]">
                                <VendorAvatar size={40} imageSrc={logo} name={title} />
                            </div>
                            <div>
                                <div className="flex items-center space-x-2">
                                    <div>
                                        <h5 className="text-[16px] font-rocGroteskMedium leading-6">
                                            {title}
                                        </h5>
                                    </div>
                                    <div className="lg:block hidden">
                                        <Rating ratingNo={rating} totalRating={5} />
                                    </div>
                                </div>
                                <h6 className="text-[14px] font-rocGroteskRegular text-slate-500">
                                    {email}
                                </h6>
                                <div className="lg:hidden block">
                                    <Rating ratingNo={rating} totalRating={5} />
                                </div>
                            </div>
                        </div>
                        <div className="cursor-pointer justify-center bg-white border w-[34px] h-[34px] border-slate-100 p-2 rounded-full flex items-center">
                            <i className="ri-thumb-down-line"></i>
                        </div>
                    </div>
                    {description && (
                        <p className="text-gm-50 font-rocGroteskMedium mb-2 text-sm mt-3">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: hasHTMLTag(description)
                                        ? sanitizeHtml(description).sanitizedHTML?.join("")
                                        : description,
                                }}
                            />
                        </p>
                    )}
                </div>
                {showQuotesDownload && (
                    <div className="flex mt-2 mb-4">
                        {showQuotesDownload &&
                            documents?.map((doc) => {
                                return (
                                    <div
                                        onClick={() => {
                                            fetch(doc?.path)
                                                .then((response) => {
                                                    response.blob().then((blob) => {
                                                        // Creating new object of PDF file
                                                        const fileURL =
                                                            window.URL.createObjectURL(blob);

                                                        let alink = document.createElement("a");
                                                        alink.href = fileURL;
                                                        alink.download = doc?.filename;
                                                        alink.click();
                                                    });
                                                })
                                                .catch((error) => console.error(error));
                                        }}
                                        key={doc?.path}
                                        className="bg-white cursor-pointer border flex items-center py-2 pl-3 pr-6 space-x-2 rounded-[6px]  border-slate-200 shadow-box-shadow-5 drop-shadow-sm"
                                    >
                                        {displayFileIcon(getFileTypeFromUrl(doc?.path), true)}
                                        <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                            {truncate(doc?.filename, { length: 30 })}
                                        </p>
                                        <div className="cursor-pointer">
                                            <i className="ri-download-line text-gm-50"></i>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                )}
                <div
                    className={`flex  w-full mt-2 items-center pb-5 ${
                        simalarResponses && "border-b"
                    } `}
                >
                    <div className="bg-slate-100 rounded-[4px] px-2 py-1">
                        <p className="text-slate-700  text-[14px] font-rocGroteskMedium">
                            Sent: {sentAt ? sentAt : "11 hours ago"}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
