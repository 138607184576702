import React from "react";
import Checkbox from "./InputField/Checkbox";

type CustomTableProps = {
    tableBody: {
        content: React.ReactNode;
        widthClass?: string;
        tableDataDivClass?: string;
        cellClickAction?: (e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
        isItemChecked?:
            | boolean
            | {
                  [key: string]: any;
              };
        onCheckBoxClick?: () => void;
    }[][];
    tableHeader: {
        title: React.ReactNode | string;
        widthClass?: string;
        itemClass?: string;
        isAllChecked?: boolean;
        onSelectAll?: () => void;
    }[];
    tbodyClass?: string;
    isAllSelectable?: boolean;
    isCellSelectable?: boolean;
    isScrollable?: boolean;
    headerContainerClass?: string;
    headerItemClass?: string;
    bodyItemClass?: string;
    tableClass?: string;
    isCellBordered?: boolean;
    isScrolled?: boolean;
    inventoryHistoryTable?: boolean;
    leftFixedColumnCount?: number;
    rightFixedColumnCount?: number;
    leftFixedColumnClass?: string[];
    rightFixedColumnClass?: string[];
    noTableBody?: React.ReactElement | null;
    stickyHeader?: boolean;
};

const CustomTable = ({
    tableBody,
    tableHeader,
    tbodyClass,
    isAllSelectable,
    isCellSelectable,
    isScrollable,
    headerContainerClass,
    headerItemClass,
    bodyItemClass,
    tableClass,
    isCellBordered,
    isScrolled,
    inventoryHistoryTable,
    leftFixedColumnCount,
    rightFixedColumnCount,
    leftFixedColumnClass,
    rightFixedColumnClass,
    noTableBody,
    stickyHeader,
}: CustomTableProps) => {
    return (
        <div
            className={`${
                isScrollable ? "overflow-x-auto" : "overflow-x-hidden"
            } w-full h-full contents relative`}
        >
            <table
                className={`table-fixed relative ${
                    isScrollable && "border-separate border-spacing-0"
                } bg-white w-full ${tableClass} rounded-[6px]  ${
                    inventoryHistoryTable ? "border-none" : "border border-slate-100"
                }`}
            >
                <thead className={`${stickyHeader && "sticky top-0 left-0 right-0"}`}>
                    <tr className={`text-left bg-white ${headerContainerClass}`}>
                        {tableHeader?.map((header, idx) => (
                            <th
                                key={(header?.title as string) + idx}
                                className={`${header?.widthClass} ${
                                    isCellBordered && "border-r"
                                } border-slate-100 ${
                                    (leftFixedColumnCount
                                        ? idx <= leftFixedColumnCount - 1
                                        : idx === 0) &&
                                    isScrollable &&
                                    "sticky left-0 bg-inherit z-[1000]" +
                                        " " +
                                        (idx <= (leftFixedColumnCount as number) - 1 &&
                                            (leftFixedColumnClass as string[])?.length > 0 &&
                                            (leftFixedColumnClass as string[])[idx])
                                } ${
                                    (rightFixedColumnCount
                                        ? idx >= tableHeader?.length - rightFixedColumnCount
                                        : idx === 0) &&
                                    isScrollable &&
                                    "sticky right-0 bg-inherit z-[1000]" +
                                        " " +
                                        (idx >=
                                            tableHeader?.length -
                                                (rightFixedColumnCount as number) &&
                                            (rightFixedColumnClass as string[])?.length > 0 &&
                                            (rightFixedColumnClass as string[])[idx])
                                } ${
                                    (leftFixedColumnCount
                                        ? idx <= leftFixedColumnCount - 1
                                        : idx === 0) &&
                                    isScrollable &&
                                    isScrolled &&
                                    "shadow-[3px_0px_3px_0px_rgba(122,122,122,0.1)]"
                                }  `}
                            >
                                <div
                                    className={`p-3 text-xs text-slate-800 ${
                                        header?.title === "price" &&
                                        inventoryHistoryTable &&
                                        idx === 2 &&
                                        "justify-end"
                                    }  flex items-center gap-3 ${headerItemClass} ${
                                        header?.itemClass
                                    } `}
                                >
                                    {isAllSelectable && idx === 0 && (
                                        <Checkbox
                                            name="selectAll"
                                            isChecked={tableHeader?.[0]?.isAllChecked}
                                            onChange={() => {
                                                tableHeader?.[0]?.onSelectAll?.();
                                            }}
                                            className={"!h-4 !w-4 !rounded-[4px]"}
                                        />
                                    )}
                                    {header?.title}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                {!noTableBody ? (
                    <tbody className={`${tbodyClass} `}>
                        {tableBody?.map((item, index) => {
                            return (
                                <tr
                                    key={index}
                                    className={`text-left  bg-white border-t  border-slate-100 cursor-pointer ellipsis hover:bg-slate-100 ${bodyItemClass}`}
                                    onClick={(e) => item?.[0]?.cellClickAction?.(e)}
                                >
                                    {item?.map((col, idx) => (
                                        <td
                                            key={index + "-" + idx}
                                            className={`${col?.widthClass} ${
                                                isCellBordered &&
                                                idx !== item?.length - 1 &&
                                                "border-r"
                                            } border-slate-100 ${
                                                (leftFixedColumnCount
                                                    ? idx <= leftFixedColumnCount - 1
                                                    : idx === 0) &&
                                                isScrollable &&
                                                "sticky left-0 bg-inherit z-[1000]" +
                                                    " " +
                                                    (idx <= (leftFixedColumnCount as number) - 1 &&
                                                        (leftFixedColumnClass as string[])?.length >
                                                            0 &&
                                                        (leftFixedColumnClass as string[])[idx])
                                            } ${
                                                (rightFixedColumnCount
                                                    ? idx >= item?.length - rightFixedColumnCount
                                                    : idx === 0) &&
                                                isScrollable &&
                                                "sticky right-0 bg-inherit z-[1000]" +
                                                    " " +
                                                    (idx >=
                                                        tableHeader?.length -
                                                            (rightFixedColumnCount as number) &&
                                                        (rightFixedColumnClass as string[])
                                                            ?.length > 0 &&
                                                        (rightFixedColumnClass as string[])[idx])
                                            } ${
                                                (leftFixedColumnCount
                                                    ? idx <= leftFixedColumnCount - 1
                                                    : idx === 0) &&
                                                isScrollable &&
                                                isScrolled &&
                                                "shadow-[3px_0px_3px_0px_rgba(122,122,122,0.1)]"
                                            } ${inventoryHistoryTable && "border-b"}`}
                                        >
                                            <div
                                                className={`flex ${
                                                    item?.[2]?.content &&
                                                    idx === 2 &&
                                                    inventoryHistoryTable &&
                                                    "justify-end"
                                                } pl-3 text-slate-800  items-center gap-3 ${
                                                    col?.tableDataDivClass
                                                } `}
                                            >
                                                {idx === 0 &&
                                                    (isAllSelectable || isCellSelectable) && (
                                                        <Checkbox
                                                            name="select"
                                                            isChecked={
                                                                item?.[0]?.isItemChecked as boolean
                                                            }
                                                            onChange={() => {
                                                                item?.[0]?.onCheckBoxClick?.();
                                                            }}
                                                            className={"!h-4 !w-4 !rounded-[4px] "}
                                                        />
                                                    )}
                                                {col?.content}
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                ) : (
                    <tbody className="w-[100%]">{noTableBody}</tbody>
                )}
            </table>
        </div>
    );
};

export default CustomTable;
