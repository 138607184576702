import Loader from "components/common/Loader";
import { convertSeconds } from "helpers/convertSeconds";
import React from "react";

type CardProps = {
    className?: string;
    title: string;
    value?: number;
    iconOne?: any;
    iconTwo?: any;
    time: any;
    increase: boolean;
    percentageValue: number;
    filterDuration: string;
    toolTipContent?:string
    loader: boolean;
    lastChecker?: boolean;
};

export const AnalyticsCard = ({
    className,
    title,
    value,
    iconOne,
    iconTwo,
    time,
    increase,
    percentageValue,
    filterDuration,
    loader,
    lastChecker,
    toolTipContent
}: CardProps) => {
    const { hours, minutes, secondsRemaining } = convertSeconds(time);

    return (
        <div
            className={`bg-white border-[0.8px] border-slate-200 rounded-lg shadow-boxShadow-2 p-[15px] w-full ${className}`}
        >
            <div className="flex items-center  space-x-[5px]">
                {iconOne && (
                    <div
                        className={` p-1.5 rounded-full text-[#FCC34E] h-[29px] w-[29px] flex justify-center items-center bg-light-yellow-2 ${iconOne}`}
                    >
                        <i className="ri-time-fill  text-base"></i>
                    </div>
                )}
                <p className="text-slate-600 font-rocGroteskMedium text-sm">{title}</p>
                {iconTwo && (
                    <div>
                        <span className="relative group  bg-gm-50">
                            <span className={` font-rocGroteskRegular pointer-events-none absolute top-[25px]  text-[13px] ${lastChecker?"lg:left-[-120px] left-1/2  lg:before:left-[95%] before:left-1/2 w-[280px]":"left-1/2  before:left-1/2 w-[256px]"} transform -translate-x-1/2 bg-gm-50 p-3 text-white opacity-0 transition before:absolute  before:bottom-full before:translate-x-1/2 before:border-4   before:border-transparent before:border-b-gm-50 rounded before:content-[''] group-hover:opacity-100`}>
                              {toolTipContent}
                            </span>
                            <div className=" cursor-pointer">
                                <i className="ri-information-fill text-slate-300 text-base"></i>
                            </div>
                        </span>
                    </div>
                )}
            </div>
            {loader ? (
                <Loader />
            ) : (
                <>
                    <div className="mt-7 flex flex-row lg:flex-col lg:justify-start justify-between">
                        <div className="text-[36px]  leading-[51.8px] font-rocGroteskMedium text-p-70">
                            {value || value >= 0 ? (
                                value
                            ) : time === 0 ? (
                                0
                            ) : (minutes !== 0 || secondsRemaining !== 0 || hours !== 0) &&
                              !value ? (
                                <div className="space-x-2 flex">
                                    {hours !== 0 && (
                                        <div className="">
                                            {hours}
                                            <span className="text-xl pl-2">
                                                {hours === 1 ? "hr" : "hrs"}
                                            </span>
                                        </div>
                                    )}
                                    {minutes !== 0 && (
                                        <div className="">
                                            {minutes}
                                            <span className="text-xl pl-2">
                                                {minutes === 1 ? "min" : "mins"}
                                            </span>
                                        </div>
                                    )}

                                    {!hours && secondsRemaining !== 0 && (
                                        <div>
                                            {secondsRemaining}
                                            <span className="text-xl pl-2">
                                                {secondsRemaining === 1 ? "sec" : "secs"}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                "----"
                            )}
                        </div>
                        <div className="flex items-center">
                            {increase ? (
                                <i className="ri-arrow-up-line text-g-50"></i>
                            ) : (
                                <i className="ri-arrow-down-line text-r-50"></i>
                            )}
                            <p className="text-gm-35 flex space-x-1 font-rocGroteskBold text-[13px] leading-3">
                                {percentageValue}%{" "}
                                <span className="font-rocGroteskMedium pl-1 lg:block hidden">
                                    {increase ? "up" : "down"} {filterDuration}
                                </span>
                            </p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
