import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useSearchParams} from "react-router-dom";

type TabsContainerProps = {
    tabs: string[] | JSX.Element[];
    className?: string;
    button?: React.ReactNode;
    children?: React.ReactNode;
    component?: React.ReactNode;
    setActiveTab: (idx: number) => void;
    showTabMenu?: string;
    activeTab: number;
    itemClassName?: string;
    showButton?: React.ReactNode;
    borderLineClase?: string;
    tabContainerClass?: string
};

const TabsContainer = ({
    tabs,
    className,
    button,
    children,
    component,
    setActiveTab,
    showTabMenu,
    activeTab,
    itemClassName,
    showButton,
    borderLineClase,
    tabContainerClass
}: TabsContainerProps) => {
    const [param] = useSearchParams();
    const tab = param.get("tab");
    const navigate = useNavigate();

    const setActiveTabAndNavigate = (idx: number) => {
        showTabMenu && navigate(`/dashboard/${showTabMenu}/${idx}`);
        setActiveTab(idx);
    };

    useEffect(() => {
        if (tab) {
            setActiveTab(Number(tab));
        }
    }, [tab]);

    return (
        <div className="w-full">
            <div className="w-full ">
                <div className={` flex overflow-x-scroll justify-between ${tabContainerClass}`}>
                    <div
                        className={`flex gap-x-8 ${button && "mt-2"} 
                            px-6
                        ${className}`}
                    >
                        {tabs?.map((value, idx) => (
                            <div
                                key={`${value + idx}`}
                                onClick={() => setActiveTabAndNavigate(idx)}
                                className={`${itemClassName} text-sm  font-rocGroteskMedium pt-[6.5px] ${
                                    showButton ? "pb-0" : "pb-2"
                                } cursor-pointer ${
                                    activeTab === idx
                                        ? "text-slate-700 border-slate-900 border-b-[2px]"
                                        : "text-slate-500"
                                }`}
                            >
                                {value}
                            </div>
                        ))}
                    </div>
                    {showButton && <div className="max-lg:hidden">{showButton}</div>}
                </div>

                <hr className={`text-slate-200 ${borderLineClase}`} />
                {showButton && <div className="hidden max-lg:block">{showButton}</div>}
                {(activeTab === 0 || activeTab === 1) && button && <>{children}</>}
            </div>
            <div className="">{component}</div>
        </div>
    );
};

export default TabsContainer;
