import DashboardSidebar from "components/common/DashboardSidebar";
import Loader from "components/common/Loader";
import DashboardNav from "components/common/Navbar/DashboardNav";
import { calculateDaysUntilToday } from "helpers/calculateInDays";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { settingsActions } from "redux/Ldb/actions";

type DashboardFrameProps = {
    children: React.ReactNode;
};

const DashboardFrame = ({ children }: DashboardFrameProps) => {
    const dispatch = useAppDispatch();
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [subscriptionPlan, setSubscriptionPlan] = useState<{ [key: string]: any }>({});
    const {
        fetchingCurrentSubscription,
        fetchCurrentSubscriptionSuccess,
        createStripeSessionSuccess,
        createStripeSession,
    } = useAppSelector((state) => state.settings);
    const location = useLocation();

    useEffect(() => {
        if (!Boolean(fetchCurrentSubscriptionSuccess)) {
            dispatch(settingsActions.fetchCurrentSubscriptionPlan());
        }
    }, [dispatch, fetchCurrentSubscriptionSuccess]);

    useEffect(() => {
        if (Boolean(fetchCurrentSubscriptionSuccess)) {
            setSubscriptionPlan(fetchCurrentSubscriptionSuccess?.subscription);
        }
    }, [fetchCurrentSubscriptionSuccess]);

    const handleResize = () => {
        const windowWidth = window.innerWidth;

        setSidebarCollapsed(windowWidth <= 1024);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const expiryDays = calculateDaysUntilToday(subscriptionPlan.expiryDate);

    const onUpgradePlan = () => {
        const body = {
            planId: subscriptionPlan?.plan?._id,
        };

        dispatch(settingsActions.createStripeSession(body));
    };

    useEffect(() => {
        if (Boolean(createStripeSessionSuccess)) {
            window.location.href = createStripeSessionSuccess?.subscription?.checkoutUrl;
            dispatch(settingsActions.resetCreateStripeSession());
        }
    }, [createStripeSessionSuccess]);
    return (
        <div className="bg-body-1 w-full min-h-screen overflow-x-hidden">
            <DashboardNav setSidebarCollapsed={setSidebarCollapsed} />
            <div className={`flex w-full h-[calc(100vh-84px)] `}>
                <div className={`${!sidebarCollapsed && "w-[22%]"}`}>
                    <DashboardSidebar
                        setSidebarCollapsed={setSidebarCollapsed}
                        sidebarCollapsed={sidebarCollapsed}
                    />
                </div>

                <div id="dashboardContent" className="w-full overflow-y-scroll">
                    {expiryDays > 0 && !fetchingCurrentSubscription && (
                        <>
                            {expiryDays < 4 && location.pathname === "/dashboard/settings/2" && (
                                <div className="bg-alert-yellow py-4 px-[30px] flex space-x-[10px] items-center text-black text-[13px] leading-[18.72px]">
                                    <p className=" font-rocGroteskRegular">
                                        Your subscription plan will expire in {expiryDays} Days
                                    </p>
                                    {createStripeSession ? (
                                        <Loader />
                                    ) : (
                                        <>
                                            {subscriptionPlan?.trial && (
                                                <div
                                                    className="w-fit cursor-pointer"
                                                    onClick={onUpgradePlan}
                                                >
                                                    <p className="font-rocGroteskBold underline">
                                                        Buy plan
                                                    </p>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                    <div className="px-4 pt-6 pb-6 w-full">{children}</div>
                </div>
            </div>
        </div>
    );
};

export default DashboardFrame;
