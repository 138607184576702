/* eslint-disable */
import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import TabsContainer from "components/common/TabsContainer";
import PageFrame from "components/layout/PageFrame";
import { CreateNewGroup } from "components/views/Ldb/Dashboard/Teams/AddTeams";
import { DocumentUpload } from "components/views/Ldb/Dashboard/Teams/DocumentUpload";
import { ShowRolesAndResponsibiltyModal } from "components/views/Ldb/Dashboard/Teams/ShowRolesAndResponsibilty";
import { TeamGroups } from "components/views/Ldb/Dashboard/Teams/TeamGroups";
import TeamMembers from "components/views/Ldb/Dashboard/Teams/TeamMembers";
import { TeamUpdates } from "components/views/Ldb/Dashboard/Teams/TeamUpdates";
import React, { useEffect, useRef, useState } from "react";
import { accessTypes } from "variables";
import { authActions } from "redux/Ldb/actions";
import { getFromStorage } from "helpers";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectInput from "components/common/InputField/SelectInput";
import EmailInput from "components/common/InputField/EmailInput";
import { EmailChip } from "components/common/InputField/EmailChip";
import { debounce } from "lodash";
import { toast } from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { getEditPermissionList } from "helpers/getEditPermissionList";

const TeamManagement = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [inviteMember, setInviteMember] = useState(false);
    const [createGroup, setCreateGroup] = useState(false);
    const [showRolesAndResponsibiltyModal, setShowRolesAndResponsibiltyModal] = useState(false);
    const [email, setEmail] = useState("");
    const [emailCategory, setEmailCategory] = useState("");
    const [memberCategory, setMemberCategory] = useState("");
    const [roleCategory, setRoleCategory] = useState("");
    const [requiredDocuments, setRequiredDocuments] = useState([]);
    const [teamInfo, setTeamInfo] = useState<{ [key: string]: any }[]>([
        { "email-0": "", "role-0": "" },
    ]);
    const [addCustomRole, setAddCustomRole] = useState(false);
    const [selectedAccess, setSelectedAccess] = useState<string[]>([]);
    const [hoveredId, setHoveredId] = useState(null);
    const [userProfile, setUserProfile] = useState(() => getFromStorage("ally-user"));
    const [focused, setFocused] = useState(false);
    const [profile, setProfile] = useState(() => getFromStorage("ally-user"));
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
    const [roles, setRoles] = useState<{ [key: string]: any }[]>([]);
    const [role, setRole] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState({
        search: "",
    });
    const {
        addingTeamMember,
        addTeamMemberSuccess,
        fetchingRoles,
        fetchedRolesSuccess,
        createRoles,
        createRolesSuccess,
    } = useAppSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const containerRef = useRef<HTMLDivElement>(null);

    const handleAddRole = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const permission = selectedPermissions;
        if (role === "") {
            toast.custom((t) => (
                <CustomToast t={t} message={"Role name is required"} type="error" />
            ));
        }
        if (role !== "") {
            const teamMember = {
                role,
                ...(permission.length > 0 && { permission }),
            };
            dispatch(authActions.createRoles(teamMember, profile?.token));
        }
    };

    useEffect(() => {
        if (Boolean(createRolesSuccess)) {
            setSelectedPermissions([]);
            setAddCustomRole(false);
            dispatch(authActions.resetCreateRoles());
            dispatch(authActions.getRoles(20, profile?.token));
        }
    }, [createRolesSuccess, dispatch]);

    const handlePermissionChange = (permission: string) => {
        const isSelected = selectedPermissions.includes(permission);

        if (isSelected) {
            const updatedPermissions = selectedPermissions.filter((item) => item !== permission);
            setSelectedPermissions(updatedPermissions);
        } else {
            setSelectedPermissions([...selectedPermissions, permission]);
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            const lastItem = (container as any).lastChild;
            (container as any).scrollTop = lastItem.offsetTop;
        }
    }, [teamInfo]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: { [key: string]: any }[] = [];
        const filteredTeamInfo = teamInfo.filter(
            (member, index) => member[`email-${index}`] && member[`role-${index}`]
        );

        filteredTeamInfo.map((member, index) => {
            const teamMember = {
                email: member[`email-${index}`],
                role: member[`role-${index}`],
            };

            body.push(teamMember);
        });

        const teamsBody = { teamMembers: body };

        dispatch(authActions.addTeamMember(teamsBody, profile?.token));
    };

    const onDelete = (index: number) => {
        const updatedTeamInfo = [...teamInfo];
        updatedTeamInfo.splice(index, 1);
        setTeamInfo(updatedTeamInfo);
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } },
        index: number
    ) => {
        const { name, value } = e.target;
        const updatedTeamInfo = [...teamInfo];
        updatedTeamInfo[index] = {
            ...updatedTeamInfo[index],
            [name]: value,
        };
        setTeamInfo(updatedTeamInfo);
    };

    const addNewInputField = () => {
        const newInputField = {
            email: "",
            role: "",
            permissions: "",
        };
        setTeamInfo([...teamInfo, newInputField]);
    };

    useEffect(() => {
        dispatch(authActions.getRoles(20, profile?.token, debouncedSearch.search));
    }, [dispatch, debouncedSearch]);

    useEffect(() => {
        if (Boolean(fetchedRolesSuccess)) {
            setRoles(fetchedRolesSuccess?.rolesList);
        }
    }, [fetchedRolesSuccess]);

    const handleSelectAccess = (access: string) => {
        const selectedAccessCopy = [...selectedAccess];
        const itemIndex = selectedAccessCopy.findIndex(
            (item) => item?.toLowerCase() === access?.toLowerCase()
        );

        if (itemIndex !== -1) {
            selectedAccessCopy.splice(itemIndex, 1);
        } else {
            selectedAccessCopy.push(access);
        }
        setSelectedAccess(selectedAccessCopy);
    };

    useEffect(() => {
        if (Boolean(addTeamMemberSuccess)) {
            dispatch(authActions.getTeamMembers(10));
            setInviteMember(false);
            dispatch(authActions.resetAddTeamMemberSuccess());
            setTeamInfo([{ "email-0": "", "role-0": "" }]);
        }
    }, [dispatch, addTeamMemberSuccess]);

    useEffect(() => {
        dispatch(authActions.getRoles(20, userProfile?.token));
    }, [dispatch]);

    // const tabs = ["Members", "Groups", "Team updates", "Documents"];
    const tabs = ["Members"];

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return <TeamMembers />;

            case 1:
            // return <TeamGroups />;

            case 2:
            // return <TeamUpdates isSending={false} />;

            default:
            // return <DocumentUpload />;
        }
    };

    const handleCreateGroup = (e: React.FormEvent<HTMLFormElement>) => {};
    return (
        <PageFrame containerClassName={"!h-[calc(100vh-108px)]"}>
            <div className="px-0 lg:px-[14px] lg:mx-5 !pb-36 lg:pb-0">
                <div className="mb-8">
                    <BreadCrumb
                        parentCrumb={"Teams"}
                        parentUrl={"/dashboard/team-management"}
                        parentClassName={"!text-slate-700 "}
                        size="large"
                    />
                </div>

                <TabsContainer
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    component={displayActiveTab()}
                    button
                    className={`!px-0 `}
                    children={
                        <div className="w-full lg:w-[300px] mt-8 ">
                            <div className="flex w-full space-x-2 mb-3  items-center">
                                {/* <Button
                                    btnClassname="!bg-n-20 !text-gm-50"
                                    btnText={"Create new group"}
                                    type={"button"}
                                    onClick={() => setCreateGroup(true)}
                                /> */}
                                <Button
                                    btnText={"Invite new member"}
                                    onClick={() => setInviteMember(true)}
                                    type={"button"}
                                    btnClassname={"w-full lg:!w-fit "}
                                    permissions={getEditPermissionList("team")}
                                />
                            </div>
                        </div>
                    }
                />

                <ModalContainer
                    open={inviteMember}
                    showCloseIcon={false}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[553px]"
                    closeModal={() => {
                        setInviteMember(false);
                        setTeamInfo([{ "email-0": "", "role-0": "" }]);
                    }}
                >
                    <div className="bg-white px-2 pt-3 pb-10 shadow-cardShadow relative rounded-[20px]">
                        <div
                            onClick={() => {
                                setInviteMember(false);
                                setTeamInfo([{ "email-0": "", "role-0": "" }]);
                            }}
                            className="cursor-pointer px-4 flex justify-end "
                        >
                            <i className="ri-close-fill text-gm-45 text-[24px]"></i>
                        </div>
                        <div className="w-full flex justify-center">
                            <div className="lg:w-[500px] relative w-[380px]">
                                <div className="w-full  pb-3   flex text-center items-center justify-center">
                                    <div className="flex text-center flex-col items-center justify-center">
                                        <p className="text-xl mb-1.5 font-rocGroteskMedium text-g-75">
                                            Invite a team member
                                        </p>
                                        <p className="text-sm font-rocGroteskMedium text-gm-40">
                                            Add team members to your workspace
                                        </p>
                                        <div
                                            className="cursor-pointer  w-fit my-2"
                                            onClick={() => setShowRolesAndResponsibiltyModal(true)}
                                        >
                                            <p className="text-b-55  text-xs   font-rocGroteskMedium  underline-offset-1 underline">
                                                View roles and responsibilities
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit} className="w-full  ">
                                    <div
                                        className={`flex  flex-col max-h-[225px]  ${
                                            teamInfo?.length > 3 && " overflow-y-scroll "
                                        }  gap-4 w-full`}
                                        ref={containerRef}
                                    >
                                        {teamInfo.map((inputData, index) => (
                                            <div
                                                key={index}
                                                className="flex   w-full items-center group"
                                            >
                                                <div className="w-[80%]">
                                                    <TextInput
                                                        value={inputData[`email-${index}`]}
                                                        name={`email-${index}`}
                                                        type="email"
                                                        onChange={(e) => handleChange(e, index)}
                                                        placeholder="Enter email address"
                                                        required={true}
                                                        inputContainerClassname="!rounded-tr-none rounded-br-none"
                                                    />
                                                </div>
                                                <div className="  right-0 z-[]   bg-white">
                                                    <SelectInput
                                                        value={inputData[`role-${index}`] || ""}
                                                        name={`role-${index}`}
                                                        placeholder="Assign role"
                                                        handleChange={(name, value) =>
                                                            handleChange(
                                                                {
                                                                    target: { name, value },
                                                                },
                                                                index
                                                            )
                                                        }
                                                        handleInputChange={debounce((evt) => {
                                                            setDebouncedSearch({
                                                                search: evt.target.value,
                                                            });
                                                        }, 800)}
                                                        searchLoading={fetchingRoles}
                                                        className={`!rounded-tl-none !bg-[#FCFCFC] rounded-bl-none`}
                                                        isRequired={true}
                                                        disabled={
                                                            inputData[`email-${index}`] === ""
                                                        }
                                                        dropdownClassName="!py-0 "
                                                        customDropdownContainer={
                                                            <div className="w-full  py-1.5 flex flex-col gap-3.5">
                                                                <div
                                                                    onClick={() =>
                                                                        setAddCustomRole(true)
                                                                    }
                                                                    className="flex items-center gap-1 px-3 font-rocGroteskMedium cursor-pointer w-fit"
                                                                >
                                                                    <i className="ri-add-fill before:content-['\ea12'] text-p-55 text-xl mt-[-2px]"></i>
                                                                    <span className="text-sm text-p-50">
                                                                        Add custom role
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                        dropdownOptions={roles?.map((item) => ({
                                                            label: item?.label,
                                                            value: item?.role,
                                                        }))}
                                                    />
                                                </div>
                                                {index !== 0 && (
                                                    <div className="pl-2 group-hover:block hidden">
                                                        <i
                                                            className="ri-delete-bin-line text-[20px] text-slate-400 cursor-pointer"
                                                            onClick={() => onDelete(index)}
                                                        ></i>
                                                    </div>
                                                )}
                                                <ModalContainer
                                                    open={addCustomRole}
                                                    showCloseIcon={false}
                                                    modalClassName={"py-10 px-10"}
                                                    tailwindClassName="w-[513px] xl:w-[600px]"
                                                    closeModal={() => {
                                                        setAddCustomRole(false);
                                                    }}
                                                >
                                                    <div className="bg-white rounded-lg shadow-modalShadow relative pt-5 pb-10">
                                                        <div className="flex justify-end px-10">
                                                            <span
                                                                onClick={() =>
                                                                    setAddCustomRole(false)
                                                                }
                                                                className="material-icons text-gm-50 text-xl cursor-pointer"
                                                            >
                                                                close
                                                            </span>
                                                        </div>
                                                        <div className="px-10 pt-3">
                                                            <div className="flex flex-col items-center text-center mb-[45px]">
                                                                <p className="text-2xl mb-2.5 font-rocGroteskMedium text-g-75">
                                                                    Create a custom role
                                                                </p>
                                                                <p className="text-sm font-rocGroteskMedium text-gm-40">
                                                                    Define what the new role’s
                                                                    responsibilities are
                                                                </p>
                                                            </div>
                                                            <form className="w-full">
                                                                <TextInput
                                                                    name={`role`}
                                                                    type={"text"}
                                                                    placeholder={"Enter role name"}
                                                                    value={role}
                                                                    onChange={(e) =>
                                                                        setRole(e.target.value)
                                                                    }
                                                                    required={true}
                                                                    containerClassname={"mb-6 "}
                                                                />
                                                                <div>
                                                                    <p className="text-base font-rocGroteskMedium mb-6">
                                                                        Select access levels
                                                                    </p>
                                                                    <div className="flex flex-wrap gap-4 relative overflow-auto">
                                                                        {accessTypes.map(
                                                                            (access, idx) => (
                                                                                <div
                                                                                    key={idx}
                                                                                    onClick={() =>
                                                                                        handlePermissionChange(
                                                                                            access.value
                                                                                        )
                                                                                    }
                                                                                    className={`px-2.5 w-fit h-[30px] ${
                                                                                        selectedPermissions.includes(
                                                                                            access.value
                                                                                        )
                                                                                            ? "!bg-carribean-green !text-white !border-carribean-green"
                                                                                            : ""
                                                                                    } border flex items-center gap-2 rounded-[8px] font-rocGroteskMedium bg-white hover:!bg-g-65 hover:text-white hover:!border-g-65 border-slate-300 text-[13px] cursor-pointer`}
                                                                                >
                                                                                    {selectedPermissions.includes(
                                                                                        access.value
                                                                                    ) ? (
                                                                                        <i className="ri-checkbox-circle-fill text-base"></i>
                                                                                    ) : (
                                                                                        <i className="ri-add-fill text-base"></i>
                                                                                    )}
                                                                                    {access.label}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <Button
                                                                    onClick={handleAddRole}
                                                                    btnText={"Add role"}
                                                                    isLoading={createRoles}
                                                                    btnClassname={"my-10"}
                                                                />
                                                                <p
                                                                    onClick={() =>
                                                                        setAddCustomRole(false)
                                                                    }
                                                                    className="text-sm font-rocGroteskMedium text-center cursor-pointer"
                                                                >
                                                                    Close
                                                                </p>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </ModalContainer>
                                            </div>
                                        ))}
                                    </div>
                                    <div
                                        className="cursor-pointer  flex space-x-1 items-center w-fit my-3"
                                        onClick={addNewInputField}
                                    >
                                        <i className="ri-add-fill text-b-55  text-[24px]"></i>
                                        <p className="text-b-55  text-xs font-rocGroteskMedium  underline-offset-1 underline">
                                            Invite more team members
                                        </p>
                                    </div>
                                    <div className="w-full mt-10">
                                        <Button
                                            btnText={"Invite member"}
                                            isLoading={addingTeamMember}
                                            type={"submit"}
                                        />
                                        <Button
                                            btnText={"Close"}
                                            onClick={() => {
                                                setInviteMember(false);
                                                setTeamInfo([{ "email-0": "", "role-0": "" }]);
                                            }}
                                            disabled={addingTeamMember}
                                            btnClassname={"!bg-transparent !text-gm-75 mt-2"}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </ModalContainer>
                
                <ModalContainer
                    open={createGroup}
                    showCloseIcon={false}
                    tailwindClassName="w-[50%] mt-[-10%] max-lg:w-[80%]max-sm:w-[95%]"
                    closeModal={() => {
                        setCreateGroup(false);
                        setEmail("");
                        setMemberCategory("");
                        setRequiredDocuments([]);
                    }}
                >
                    <CreateNewGroup
                        onSubmit={handleCreateGroup}
                        roleCategory={roleCategory}
                        onChangeCategory={setEmailCategory}
                        onChangeDocs={setRequiredDocuments}
                        memberCategory={memberCategory}
                        isSending={false}
                        onClose={() => setCreateGroup(false)}
                        onAddNewMember={() => {
                            setCreateGroup(false);
                            setInviteMember(true);
                        }}
                    />
                </ModalContainer>
                <ModalContainer
                    open={showRolesAndResponsibiltyModal}
                    showCloseIcon={false}
                    modalClassName={"py-10 px-10"}
                    closeModal={() => {
                        setShowRolesAndResponsibiltyModal(false);
                    }}
                    tailwindClassName="w-[768px]"
                >
                    <ShowRolesAndResponsibiltyModal />
                </ModalContainer>

                {/* <ModalContainer
                    open={addCustomRole && !inviteMember}
                    showCloseIcon={false}
                    tailwindClassName="xl:w-[40%] 2xl:w-[30%] lg:w-[50%] lg:w-[60%] w-[80%] sm:w-[70%] max-sm:w-[90%]"
                    closeModal={() => {
                        setAddCustomRole(false);
                        setInviteMember(true);
                    }}
                >
                    <div className="bg-white rounded-[12px] shadow-modalShadow relative pt-5 pb-10">
                        <div className="flex justify-end px-10">
                            <span
                                onClick={() => {
                                    setAddCustomRole(false);
                                    setInviteMember(true);
                                }}
                                className="material-icons text-gm-50 text-xl cursor-pointer"
                            >
                                close
                            </span>
                        </div>
                        <div className="px-10 pt-3">
                            <div className="flex flex-col items-center text-center mb-[45px]">
                                <p className="text-2xl mb-2.5 font-rocGroteskMedium text-g-75">
                                    Create a custom role
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-gm-40">
                                    Define what the new role’s responsibilities are
                                </p>
                            </div>
                            <form
                                className="w-full"
                            >
                                <TextInput
                                    name={"role"}
                                    type={"text"}
                                    value={teamInfo?.role}
                                    placeholder={"Enter role name"}
                                    onChange={handleChange}
                                    required={true}
                                    containerClassname={"mb-6"}
                                />
                                <div>
                                    <p className="text-base font-rocGroteskMedium mb-6">
                                        Select access levels
                                    </p>
                                    <div className="flex flex-wrap gap-4 relative overflow-auto">
                                        {accessTypes.map((access, idx) => (
                                            <div
                                                key={idx}
                                                onClick={() => {
                                                    if (idx === 0) {
                                                        const permissions = accessTypes?.map(
                                                            (permission) => permission.value
                                                        );
                                                        selectedAccess.includes(access.value)
                                                            ? setSelectedAccess([])
                                                            : setSelectedAccess(permissions);
                                                    } else {
                                                        handleSelectAccess(access.value);
                                                    }
                                                }}
                                                onMouseEnter={() => setHoveredId(idx)}
                                                onMouseLeave={() => setHoveredId(-1)}
                                                className={`px-2.5 w-fit h-[30px] ${
                                                    selectedAccess.includes(access.value) &&
                                                    "!bg-carribean-green !text-white !border-carribean-green"
                                                } border flex items-center gap-2 rounded-[8px] font-rocGroteskMedium bg-white hover:!bg-g-65 hover:text-white hover:!border-g-65 border-slate-300 text-[13px] cursor-pointer`}
                                            >
                                                {selectedAccess.includes(access.value) &&
                                                hoveredId !== idx ? (
                                                    <i className="ri-checkbox-circle-fill text-base"></i>
                                                ) : hoveredId === idx &&
                                                  !selectedAccess.includes(access.value) ? (
                                                    <i className="ri-add-fill text-base"></i>
                                                ) : hoveredId === idx &&
                                                  selectedAccess.includes(access.value) ? (
                                                    <i className="ri-subtract-fill text-base"></i>
                                                ) : null}
                                                {access.label}
                                            </div>
                                        ))}
                                        <input
                                            name="permission"
                                            value={selectedAccess?.[0]}
                                            required
                                            className="absolute outline-none opacity-0"
                                        />
                                    </div>
                                </div>
                                <Button
                                    type={"submit"}
                                    btnText={"Add role"}
                                    isLoading={addingTeamMember}
                                    btnClassname={"my-10"}
                                />
                                <p
                                    onClick={() => setAddCustomRole(false)}
                                    className="text-sm font-rocGroteskMedium text-center cursor-pointer"
                                >
                                    Close
                                </p>
                            </form>
                        </div>
                    </div>
                </ModalContainer> */}
            </div>
        </PageFrame>
    );
};

export default TeamManagement;
