import React, {useEffect, useState} from "react";
import LandingNav from "components/views/Landing/LandingNav";
import TextInput from "components/common/InputField/TextInput";
import ReactCountryFlag from "react-country-flag";
import countries from "variables/countries";
import SelectInput from "components/common/InputField/SelectInput";
import Button from "components/common/Button";
import {useDispatch, useSelector} from "react-redux";
import {vendorActions} from "redux/Ldb/actions";
import {toast} from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import {useAppDispatch, useAppSelector} from "../../hooks/storeHooks";
import PreFooterCard from "components/views/Landing/PreFooterCard";
import LandingFooter from "components/views/Landing/LandingFooter";
import { scrollToTop } from "helpers/scrollToTop";

const BookADemo = () => {
    const [useSoftware, setUseSoftware] = useState(true);
    const [waitListData, setWaitListData] = useState({
        fullName: "",
        firstName: "",
        lastName: "",
        businessName: "",
        findOut: "",
        email: "",
        phoneExt: "",
        phoneNo: "",
        employeeNum: "",
        numberOfEmployees: "",
        softwareUsed: "",
    });

    const dispatch = useAppDispatch();
    const { joiningWaitList, joinWaitListSuccess } = useAppSelector((state) => state.vendor);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | {target: {name: string; value: string}}
    ) => {
        const {name, value} = e.target;

        if (name?.toLowerCase() === "fullname") {
            const sanitizedValue = value.replace(/[^A-Za-z ]/g, "");

            setWaitListData((prev) => ({
                ...prev,
                [name]: sanitizedValue,
            }));
        } else {
            setWaitListData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };
    useEffect(() => {
        scrollToTop();
    }, []);

    const submitWaitList = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        const data: {[key: string]: any} = {
            email: waitListData.email,
            fullName: waitListData.fullName,
            mobile: waitListData.phoneExt + waitListData.phoneNo,
            rawMobile: waitListData.phoneNo,
            mobileExtension: waitListData.phoneExt,
            businessName: waitListData.businessName,
            numberOfEmployees: Number(waitListData.numberOfEmployees),
            useSoftware: useSoftware,
            channel: waitListData.findOut,
        };

        if (useSoftware) {
            data.softwareUsed = waitListData.softwareUsed;
        }

        dispatch(vendorActions.joinWaitlist(data));
    };

    useEffect(() => {
        if (Boolean(joinWaitListSuccess)) {
            setWaitListData({
                fullName: "",
                firstName: "",
                lastName: "",
                businessName: "",
                findOut: "",
                email: "",
                phoneExt: "",
                phoneNo: "",
                employeeNum: "",
                numberOfEmployees: "",
                softwareUsed: "",
            });
            toast.custom((t) => (
                <CustomToast t={t} message={"You have been added to our waitlist"} type="success" />
            ));
            dispatch(vendorActions.resetJoinWaitListSuccess());
            window.open("https://calendar.app.google/zLPU8pHyV6asLu7t9", "_blank");
        }
    }, [joinWaitListSuccess, dispatch]);

    return (
        <div className="bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702555384/Tradeally_2.0/Landing%20page/Backgorund_mxo0kt.png')] max-lg:bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1702561357/Tradeally_2.0/Landing%20page/mobile_demo_bg_o8aydw.png')] bg-cover bg-no-repeat min-h-screen w-screen">
            <LandingNav />

            <section
                className="pt-[157px] px-6 max-lg:flex-col flex justify-center gap-x-[127px] max-lg:gap-y-[60px]"
                id="wishlist-form"
            >
                <div className="w-[34%] max-lg:w-full">
                    <h2 className="text-[44px] font-rocGroteskMedium leading-[57.2px] mb-6">
                        Hitch-Free Processes, From Requisition-to-Delivery
                    </h2>
                    <p className="font-rocGroteskMedium mb-8 text-slate-600">
                        Integrate the Synth procurement and project management software into your
                        systems and scale your operations using AI. Book a demo to learn how you can
                        eliminate redundant manual processes, save time, and grow your business.
                    </p>
                    <div className="w-full ">
                    <img src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1704863623/Group_2_idkopl.png"     className="w-full  object-cover"/>
                    </div>
                </div>

                <form
                    onSubmit={submitWaitList}
                    className="w-[35%] max-lg:w-full bg-white rounded-lg shadow-boxShadow-7 p-8 max-lg:p-6"
                >
                    <p className="text-[22px] font-rocGroteskMedium mb-8">Book a Demo</p>
                    <div className="flex flex-col gap-5 mb-8">
                        <TextInput
                            value={waitListData.fullName}
                            name={"fullName"}
                            type={"text"}
                            onChange={handleChange}
                            placeholder={"Full name"}
                            required={true}
                            containerClassname=""
                        />
                        <TextInput
                            value={waitListData.businessName}
                            name={"businessName"}
                            type={"text"}
                            onChange={handleChange}
                            placeholder={"Business name"}
                            required={true}
                            containerClassname=""
                        />
                        <TextInput
                            value={waitListData.email}
                            name={"email"}
                            type={"email"}
                            onChange={handleChange}
                            placeholder={"Email address"}
                            required={true}
                            containerClassname=""
                        />

                        <div className="w-full grid grid-cols-[115px_calc(100%-119px)] gap-1">
                            <SelectInput
                                value={waitListData.phoneExt}
                                name="phoneExt"
                                placeholder={"Ext"}
                                handleChange={(name, value) =>
                                    handleChange({target: {name, value}})
                                }
                                isRequired={true}
                                dropdownOptions={countries
                                    .filter((data) => Boolean(data?.codes[0]))
                                    .sort((p1, p2) =>
                                        p1.codes[0] > p2.codes[0]
                                            ? 1
                                            : p1.codes[0] < p2.codes[0]
                                            ? -1
                                            : 0
                                    )
                                    .map((item) => ({
                                        label: item.codes[0]?.replace(" ", ""),
                                        value: item.codes[0]?.replace(" ", ""),
                                        icon: (
                                            <ReactCountryFlag
                                                countryCode={item?.abbreviation}
                                                svg
                                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                cdnSuffix="svg"
                                                style={{
                                                    width: "16px",
                                                    height: "16px",
                                                    borderRadius: "50%",
                                                    marginTop: "-4px",
                                                }}
                                            />
                                        ),
                                    }))}
                            />
                            <TextInput
                                name={"phoneNo"}
                                type={"number"}
                                value={waitListData.phoneNo}
                                onChange={handleChange}
                                placeholder={"Phone number"}
                                minLength={10}
                                required={true}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                        evt.key
                                    ) && evt.preventDefault()
                                }
                            />
                        </div>
                        <TextInput
                            value={waitListData.numberOfEmployees}
                            name={"numberOfEmployees"}
                            type={"number"}
                            onChange={handleChange}
                            placeholder={"Number of employees"}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            required={true}
                            containerClassname=""
                        />
                        <div>
                            <label className=" text-sm font-rocGroteskMedium mb-3">
                                Do you use any software to manage your business processes (ERP or
                                Excel Sheet)?
                            </label>
                            <div className="flex items-center mt-2">
                                <div
                                    className="mr-5 cursor-pointer flex items-center"
                                    onClick={() => setUseSoftware(true)}
                                >
                                    <span className="material-icons text-xl">
                                        {useSoftware
                                            ? "radio_button_checked"
                                            : "radio_button_unchecked"}
                                    </span>
                                    <span className="text-xs font-rocGroteskMedium ml-[5px]">
                                        Yes
                                    </span>
                                </div>

                                <div
                                    className="cursor-pointer flex items-center"
                                    onClick={() => setUseSoftware(false)}
                                >
                                    <span className="material-icons text-xl">
                                        {!useSoftware
                                            ? "radio_button_checked"
                                            : "radio_button_unchecked"}
                                    </span>
                                    <span className="text-xs font-rocGroteskMedium ml-[5px] translate-y-[1px]">
                                        No
                                    </span>
                                </div>
                            </div>
                        </div>

                        <TextInput
                            value={waitListData.softwareUsed}
                            name={"softwareUsed"}
                            type={"text"}
                            onChange={handleChange}
                            placeholder={"If “yes”, which one?"}
                            required={useSoftware}
                            containerClassname=""
                        />
                        <TextInput
                            value={waitListData.findOut}
                            name={"findOut"}
                            type={"text"}
                            onChange={handleChange}
                            placeholder={"How did you find out about us?"}
                            containerClassname=""
                            inputClassName="w-full "
                        />
                    </div>
                    <Button
                        type={"submit"}
                        btnText={"Book a demo"}
                        btnClassname={" "}
                        isLoading={joiningWaitList}
                    />
                </form>
            </section>

            <div className="mt-[99px]">
                <PreFooterCard />
            </div>

            <LandingFooter />
        </div>
    );
};

export default BookADemo;
