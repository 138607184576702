import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import CustomToast from "components/common/CustomToast";
import TextareaInput from "components/common/InputField/TextareaInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PurchaseOrderListDetails from "components/views/Ldb/Orders/PurchaseOrderListDetails";
import { flattenDeep } from "lodash";
import { formatMoney, getCurrencyFromCurrencyCode, getFromStorage } from "helpers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";
import { useNavigate, useParams } from "react-router-dom";
import { orderActions } from "redux/Ldb/actions";
import { getEditPermissionList } from "helpers/getEditPermissionList";

const PurchaseOrderDetail = () => {
    const [approvalStatusArr, setApprovalStatusArr] = useState([]);
    const [purchaseOrderList, setPurchaseOrderList] = useState<{ [key: string]: any }>({});
    const [openApproveModal, setOpenApproveModal] = useState(false);
    const [openApprovalSuccessModal, setOpenApprovalSuccessModal] = useState(false);
    const [openRejectModal, setOpentRejectModal] = useState(false);
    const [openRejectionReasonModal, setOpentRejectionReasonModal] = useState(false);
    const [rejectionReason, setRejectionReason] = useState("");
    const [allowAction, setAllowAction] = useState(true);
    const [userProfile, setUserProfile] = useState<{ [key: string]: any }>(() =>
        getFromStorage("ally-user")
    );
    const { poId } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        fetchingSinglePurchaseOrder,
        fetchedSinglePurchaseOrderSuccess,
        loadingPOApprovalOrRejection,
        approveOrRejectPOSuccess,
    } = useAppSelector((state) => state.order);

    const hasApprovals =
        purchaseOrderList?.approvalActions?.length > 0 || purchaseOrderList?.tasks?.length > 0;

    const approvalPersonnelsArr = purchaseOrderList?.approvalActions?.map((item) => {
        return item?.assignee;
    });

    const taskPersonnelsArr = purchaseOrderList?.tasks?.map((item) => {
        return item?.assignee;
    });

    const approvalPersonnel: { [key: string]: any }[] = flattenDeep(approvalPersonnelsArr);
    const taskPersonnel: { [key: string]: any }[] = flattenDeep(taskPersonnelsArr);
    const shouldUserApprove =
        approvalPersonnel?.map((item) => item?._id)?.includes(userProfile?.user?._id) &&
        taskPersonnel?.map((item) => item?._id)?.includes(userProfile?.user?._id) &&
        !purchaseOrderList?.approvedBy
            ?.map((approvedUser) => approvedUser?._id)
            ?.includes(userProfile?.user?._id) &&
        !purchaseOrderList?.rejectedBy
            ?.map((rejectedUser) => rejectedUser?._id)
            ?.includes(userProfile?.user?._id) &&
        allowAction;

    const tableHeader = [
        { title: "Approvals/Levels", widthClass: "w-[69%]" },
        { title: "Status", widthClass: "w-[31%]" },
    ];

    const tableBody = (hasApprovals ? approvalPersonnel : [{}])?.map((item, idx) => {
        const hasBeenApproved =
            purchaseOrderList?.approvedBy
                ?.map((approvedUser) => approvedUser?._id)
                ?.includes(item?._id) || approvalStatusArr?.includes(item?._id);

        const hasBeenRejected =
            purchaseOrderList?.rejectedBy
                ?.map((rejectedUser) => rejectedUser?._id)
                ?.includes(item?._id) || approvalStatusArr?.includes(item?._id);

        return [
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 py-[14px]`}>
                        {item?.email ? (
                            <div>
                                <p className="text-xs font-rocGroteskMedium text-slate-500">
                                    Level {idx + 1}
                                </p>
                                <p className="text-[13px] font-rocGroteskMedium">
                                    <span>{item?.fullName || item?.email} - </span>
                                    <span className="text-p-50 ml-1">{item?.role}</span>
                                </p>
                            </div>
                        ) : (
                            <p className="">---</p>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full flex justify-center h-full border-slate-100 text-sm text-slate-700 py-[14px] pr-3`}
                    >
                        {item?.email ? (
                            hasBeenApproved ? (
                                <div className="flex items-center justify-center h-[25px] bg-g-25 px-2.5 py-1 rounded-[20px]">
                                    <span className="text-xs font-rocGroteskMedium">Approved</span>
                                </div>
                            ) : hasBeenRejected ? (
                                <div className="flex items-center justify-center h-[25px] bg-r-40 px-2.5 py-1 rounded-[20px]">
                                    <span className="text-xs font-rocGroteskMedium">Rejected</span>
                                </div>
                            ) : (
                                <div className="flex items-center justify-center h-[25px] bg-[#F9DFB399] px-2.5 py-1 rounded-[20px]">
                                    <span className="text-xs font-rocGroteskMedium">Pending</span>
                                </div>
                            )
                        ) : (
                            <p className="">---</p>
                        )}
                    </div>
                ),
            },
        ];
    });

    const handleApproveRejectPO = (isApprove: boolean) => {
        const specificTask = purchaseOrderList?.tasks?.find(
            (task) => task?.assignee?._id === userProfile?.user?._id
        );
        const body: { [key: string]: any } = {
            approved: isApprove,
            taskId: specificTask?._id,
            poId: poId,
        };

        if (!isApprove) {
            body.rejectReason = rejectionReason;
        }

        dispatch(orderActions.approveRejectPO(body));
    };

    useEffect(() => {
        dispatch(orderActions.getSinglePurchaseOrder(poId));
    }, [dispatch, poId]);

    useEffect(() => {
        if (Boolean(fetchedSinglePurchaseOrderSuccess)) {
            setPurchaseOrderList(fetchedSinglePurchaseOrderSuccess?.purchaseOrder);
        }
    }, [fetchedSinglePurchaseOrderSuccess]);

    useEffect(() => {
        if (Boolean(approveOrRejectPOSuccess)) {
            const isApprovedAction = approveOrRejectPOSuccess?.po?.approvedById?.includes(
                userProfile?.user?._id
            );

            const isRejectionAction = approveOrRejectPOSuccess?.po?.rejectedById?.includes(
                userProfile?.user?._id
            );

            if (isApprovedAction) {
                setApprovalStatusArr(approveOrRejectPOSuccess?.po?.approvedById);
                setOpenApproveModal(false);
                setOpenApprovalSuccessModal(true);
            }

            if (isRejectionAction) {
                setApprovalStatusArr(approveOrRejectPOSuccess?.po?.rejectedById);
                setOpentRejectModal(false);
                setOpentRejectionReasonModal(false);
                toast.custom((t) => (
                    <CustomToast
                        t={t}
                        message={"Purchase order rejected successfully"}
                        type={"error"}
                    />
                ));
            }

            setRejectionReason("");
            setAllowAction(false);
            dispatch(orderActions.resetApproveRejectPOSuccess());
        }
    }, [approveOrRejectPOSuccess, dispatch, userProfile?.user?._id]);

 
    return (
        <>
            <div className="relative w-full  top-[-20px]  ">
                {!fetchingSinglePurchaseOrder ? (
                    <div className=" w-full">
                        <div className="bg-[white] top-[-25px] md:sticky px-2 py-3 md:px-[28px] h-[44px] w-full border-b border-slate-100 flex items-center justify-between">
                            <div className="flex items-center">
                                <div
                                    onClick={() => navigate("/dashboard/purchase-orders")}
                                    className="cursor-pointer space-x-1 flex items-center justify-center"
                                >
                                    <i className="ri-arrow-left-line text-sm text-gm-45"></i>
                                    <p className="text-sm underline text-gm-45 font-rocGroteskMedium">
                                        Back
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex md:flex-row mb-5 md:mb-0 flex-col">
                            <div className="h-full md:w-[calc(100%-285px)] w-full  md:pl-5 bg-[white] shadow-sm ">
                                <PurchaseOrderListDetails
                                    showVendor
                                    selectedRFQData={{}}
                                    address={purchaseOrderList?.destination?.address}
                                    country={purchaseOrderList?.destination?.country}
                                    city={purchaseOrderList?.destination?.details[0]}
                                    postalCode={purchaseOrderList?.destination?.postalCode}
                                    successPreview={true}
                                    poNumber={purchaseOrderList?.taId}
                                    imageDetails={purchaseOrderList?.ldb?.profile?.avatar}
                                    item={purchaseOrderList?.rfq?.item?.description}
                                    disabled={false}
                                    isLoading={false}
                                    title={
                                        purchaseOrderList?.approvalStatus?.toLowerCase() ===
                                        "pending"
                                            ? `PR ${purchaseOrderList?.taId}`
                                            : `PO ${purchaseOrderList?.taId}`
                                    }
                                    imageUrl={purchaseOrderList?.ldb?.profile?.avatar}
                                    userProfile={userProfile}
                                    issuedDate={moment(purchaseOrderList?.issuedDate).format(
                                        "MMM Do, YYYY"
                                    )}
                                    dueDate={moment(purchaseOrderList?.dueDate).format(
                                        "MMM Do, YYYY"
                                    )}
                                    defaultIssueDate={purchaseOrderList?.issuedDate}
                                    defaultDueDate={purchaseOrderList?.dueDate}
                                    vendorName={purchaseOrderList?.vendor?.profile?.businessName}
                                    userName={userProfile?.user?.profile?.businessName}
                                    userEmail={
                                        userProfile?.user?.profile?.email
                                            ? userProfile?.user?.profile?.email
                                            : userProfile?.user?.email
                                    }
                                    vendorEmail={purchaseOrderList?.vendor?.email}
                                    containers={purchaseOrderList?.itemsOrServices}
                                    tax={
                                        isNaN(purchaseOrderList?.tax?.percentage)
                                            ? 0
                                            : purchaseOrderList?.tax?.percentage
                                    }
                                    currency={purchaseOrderList?.totalAmount?.currency}
                                    taxAmount={
                                        isNaN(purchaseOrderList?.tax?.amount)
                                            ? 0
                                            : Intl.NumberFormat("en-US", {
                                                  maximumFractionDigits: 2,
                                              }).format(purchaseOrderList?.tax?.amount)
                                    }
                                    shipping={
                                        isNaN(purchaseOrderList?.shippingAndHandling?.amount)
                                            ? 0
                                            : formatMoney()?.format(
                                                  purchaseOrderList?.shippingAndHandling?.amount
                                              )
                                    }
                                    totalAmount={formatMoney()?.format(
                                        purchaseOrderList?.totalAmount?.amount
                                    )}
                                    orderDescription={purchaseOrderList?.additionalComment}
                                    taId={purchaseOrderList?.rfq?.taId}
                                    currentStatus={purchaseOrderList?.currentStatus}
                                    approvalStatus={purchaseOrderList?.approvalStatus}
                                    onEdit={() =>
                                        navigate(
                                            `/dashboard/purchase-orders/edit?id=${purchaseOrderList?._id}`
                                        )
                                    }
                                />
                            </div>
                            <div className=" bg-[white] md:w-[300px] w-full  h-full  right-0  border-l border-slate-100 px-2  md:px-6 pt-6  top-[130px]   ">
                                <div className="flex pb-3 border-b border-slate-100  justify-between ">
                                    <div className="font-rocGroteskMedium md:block hidden text-gm-50">
                                        <p className="text-xs">Total amount</p>
                                        <p className="text-lg">
                                            {" "}
                                            {getCurrencyFromCurrencyCode(
                                                purchaseOrderList?.totalAmount?.currency
                                            )}
                                            {Intl.NumberFormat("en-US", {
                                                maximumFractionDigits: 2,
                                            }).format(purchaseOrderList?.totalAmount?.amount)}
                                        </p>
                                    </div>
                                </div>
                                <div className="text-sm font-rocGroteskMedium py-3 border-b border-b-slate-100">
                                    <h3 className="text-slate-500 mb-2">Vendor details</h3>
                                    <div className="space-y-1">
                                        <div className="flex items-center space-x-1">
                                            <VendorAvatar
                                                name={
                                                    purchaseOrderList?.vendor?.profile?.businessName
                                                }
                                                imageSrc={
                                                    purchaseOrderList?.vendor?.profile?.avatar
                                                }
                                                size={32}
                                                containerClassname={"!rounded-none"}
                                            />
                                            <p className="text-slate-900">
                                                {purchaseOrderList?.vendor?.profile?.businessName}
                                            </p>
                                        </div>
                                        <div className="flex items-center space-x-1">
                                            <i className="ri-mail-line text-slate-400 text-lg"></i>
                                            <p className="text-slate-900">
                                                {purchaseOrderList?.vendor?.email}
                                            </p>
                                        </div>
                                        <div className="flex items-center space-x-1">
                                            <i className="ri-phone-line text-slate-400 text-lg"></i>
                                            <p className="text-slate-900">
                                                {purchaseOrderList?.vendor?.rawMobile}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-sm font-rocGroteskMedium py-3 border-b border-b-slate-100">
                                    <h3 className="text-slate-500 mb-2">Buyer's details</h3>
                                    <div className="space-y-1">
                                        <div className="flex items-center space-x-1">
                                            <VendorAvatar
                                                name={userProfile?.user?.profile?.businessName}
                                                imageSrc={userProfile?.user?.profile?.avatar}
                                                size={24}
                                            />

                                            <p className="text-slate-900">
                                                {" "}
                                                {userProfile?.user?.profile?.businessName}
                                            </p>
                                        </div>
                                        <div className="flex items-center space-x-1">
                                            <i className="ri-mail-line text-slate-400 text-lg"></i>
                                            <p className="text-slate-900">
                                                {" "}
                                                {userProfile?.user?.email}
                                            </p>
                                        </div>
                                        <div className="flex items-center space-x-1">
                                            <i className="ri-phone-line text-slate-400 text-lg"></i>
                                            <p className="text-slate-900">
                                                {userProfile?.user?.rawMobile}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    {hasApprovals ? (
                                        <>
                                            <h3 className="text-sm text-slate-500 mb-2.5">
                                                Approval levels
                                            </h3>
                                            <div className="my-4">
                                                <CustomTable
                                                    tableBody={tableBody}
                                                    tableHeader={tableHeader}
                                                    isAllSelectable={false}
                                                    headerContainerClass="!bg-slate-50"
                                                    tableClass=" w-full"
                                                    // contentContainer="!pl-0"
                                                    isScrollable={false}
                                                    isCellBordered={true}
                                                />
                                            </div>

                                            <div
                                                className={`mt-10 space-y-3 ${
                                                    !shouldUserApprove && "opacity-25"
                                                }`}
                                            >
                                                <Button
                                                    btnText={"Approve"}
                                                    type={"button"}
                                                    btnClassname={""}
                                                    onClick={() =>
                                                        shouldUserApprove &&
                                                        setOpenApproveModal(true)
                                                    }
                                                    permissions={getEditPermissionList(
                                                        "purchase_order"
                                                    )}
                                                    // isLoading={loadingPOApprovalOrRejection}
                                                    // disabled={loadingPOApprovalOrRejection}
                                                />

                                                <Button
                                                    btnText={"Reject"}
                                                    type={"button"}
                                                    btnClassname={
                                                        "!border !bg-transparent !border-r-25 !text-r-50"
                                                    }
                                                    // disabled={loadingPOApprovalOrRejection}
                                                    // isLoading={loadingPOApprovalOrRejection}
                                                    onClick={() =>
                                                        shouldUserApprove &&
                                                        setOpentRejectModal(true)
                                                    }
                                                    permissions={getEditPermissionList(
                                                        "purchase_order"
                                                    )}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="flex items-center gap-2 bg-[#FFB90212] p-4 rounded mb-4">
                                                <i className="ri-information-line text-gm-35 text-2xl"></i>
                                                <p className="text-[13px] font-rocGroteskBold">
                                                    Set up your{" "}
                                                    <a
                                                        href="/dashboard/workflow/template?templateName=purchaseRequestApprovalWorkflow"
                                                        className="underline text-[#FFB902]"
                                                    >
                                                        workflow
                                                    </a>{" "}
                                                    for approvals
                                                </p>
                                            </div>
                                            <div className="opacity-40">
                                                <div className="my-4">
                                                    <CustomTable
                                                        tableBody={tableBody}
                                                        tableHeader={tableHeader}
                                                        isAllSelectable={false}
                                                        headerContainerClass="!bg-slate-50"
                                                        tableClass=" w-full"
                                                        // contentContainer="!pl-0"
                                                        isScrollable={false}
                                                        isCellBordered={true}
                                                    />
                                                </div>

                                                <div className="mt-10  space-y-3">
                                                    <Button
                                                        btnText={"Approve"}
                                                        type={"button"}
                                                        btnClassname={"!bg-[#FFB902] !text-gm-50"}
                                                    />

                                                    <Button
                                                        btnText={"Reject"}
                                                        type={"button"}
                                                        btnClassname={
                                                            "!border !bg-transparent !border-r-25 !text-r-50"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Loader />
                )}
            </div>

            <ModalContainer
                open={openApproveModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setOpenApproveModal(false);
                }}
            >
                <div className="bg-white !px-2 p-8  rounded-[8px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <div className="w-16 h-16 flex justify-center items-center bg-[#00DB8F] rounded-full">
                                <i className="ri-checkbox-circle-fill text-[29px] text-white"></i>
                            </div>
                            <div>
                                <h3 className="text-2xl mb-2 text-black font-rocGroteskMedium">
                                    Approved
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-sm font-rocGroteskMedium">
                                    The purchase request has been approved by you, kindly proceed to
                                    the next approval level.
                                </p>
                            </div>
                        </div>

                        <div className="mt-5 flex gap-3">
                        <div className="w-full whitespace-nowrap">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => {
                                    setOpenApproveModal(false);
                                }}
                                disabled={loadingPOApprovalOrRejection}
                            />
                            </div>
                            <div className="w-full whitespace-nowrap">
                            <Button
                                onClick={() => handleApproveRejectPO(true)}
                                btnText={"Proceed"}
                                type={"button"}
                                disabled={loadingPOApprovalOrRejection}
                                isLoading={loadingPOApprovalOrRejection}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={openRejectModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setOpentRejectModal(false);
                }}
            >
                <div className="bg-white !px-2 p-8  rounded-[8px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1697712771/Caution_sign_g1u9nv.svg"
                                alt="caution"
                                className="w-[60px] h-[53px]"
                            />
                            <div>
                                <h3 className="text-2xl mb-2 text-black font-rocGroteskMedium">
                                    Rejected
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-sm font-rocGroteskMedium">
                                    This purchase order will be rejected by you, kindly add a
                                    comment to complete rejection
                                </p>
                            </div>
                        </div>

                        <div className="mt-5 flex gap-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => {
                                    setOpentRejectModal(false);
                                }}
                                disabled={loadingPOApprovalOrRejection}
                            />
                            <Button
                                onClick={() => setOpentRejectionReasonModal(true)}
                                btnText={"Add Comment"}
                                type={"button"}
                                btnClassname={"!bg-r-50 !text-white"}
                                disabled={loadingPOApprovalOrRejection}
                                isLoading={loadingPOApprovalOrRejection}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={openApprovalSuccessModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setOpenApprovalSuccessModal(false);
                }}
            >
                <div className="bg-white !px-2 p-8  rounded-[8px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <div className="w-16 h-16 flex justify-center items-center bg-[#00DB8F] rounded-full">
                                <i className="ri-checkbox-circle-fill text-[29px] text-white"></i>
                            </div>
                            <div>
                                <h3 className="text-2xl mb-2 text-black font-rocGroteskMedium">
                                    Success
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-sm font-rocGroteskMedium">
                                    Request forwarded to next approver successfully. Approver will
                                    be notified of the pending task.Your request is pending the
                                    approval of the next level and you will be notified upon
                                    completion
                                </p>
                            </div>
                        </div>

                        <div className="mt-5 flex gap-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => {
                                    setOpenApprovalSuccessModal(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={openRejectionReasonModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setOpentRejectionReasonModal(false);
                    setRejectionReason("");
                }}
            >
                <div className="bg-white !px-2 p-8  rounded-[8px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center">
                            <div className="mb-8">
                                <h3 className="text-2xl mb-2.5 text-black font-rocGroteskMedium">
                                    Provide a comment to reject this PO
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-sm font-rocGroteskMedium">
                                    To ensure transparency and compliance, please provide a brief
                                    comment explaining your decision to rejecting this PO.
                                </p>
                            </div>

                            <div className="w-full">
                                <TextareaInput
                                    name={"rejectionReason"}
                                    value={rejectionReason}
                                    onChange={(e) => setRejectionReason(e.target.value)}
                                    rows={5}
                                    placeholder={"Comment"}
                                    required={false}
                                    containerClassname=""
                                />
                            </div>
                        </div>

                        <div className="mt-5 flex flex-col items-center gap-2.5">
                            <Button
                                onClick={() => handleApproveRejectPO(false)}
                                btnText={"Send"}
                                type={"button"}
                                disabled={loadingPOApprovalOrRejection}
                                isLoading={loadingPOApprovalOrRejection}
                            />
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-transparent !text-gm-50 !w-fit"}
                                isLoading={false}
                                onClick={() => {
                                    setOpentRejectionReasonModal(false);
                                    setRejectionReason("");
                                }}
                                disabled={loadingPOApprovalOrRejection}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </>
    );
};

export default PurchaseOrderDetail;
