import moment from "moment";

type DataEntry = {
    count: number;
    month: number;
    day: number;
    year: number;
};

export const fillMissingWithZero = (
    data: any,
    period: string,
    procurement?: string,
    setStartDateRange?: React.Dispatch<React.SetStateAction<string>>,
    setEndDateRange?: React.Dispatch<React.SetStateAction<string>>
) => {
    const result = [];
    let x = [];
    let y = [];

    if (period === "month") {
        const lastDayOfMonth = moment().endOf("month").date();
        // Fill in the values for available days
        if (Boolean(procurement)) {
            const today = moment();
            const oneMonthAgo = today.clone().subtract(1, "months");

            let currentDate = oneMonthAgo.clone();
            while (currentDate.isSameOrBefore(today, "day")) {
                result.push({ day: currentDate.format("D M"), value: 0 });
                currentDate.add(1, "day");
            }
            result.forEach((dateObject) => {
                const matchingData = data.find((entry: { day: any; month: any }) => {
                    const entryDate = `${entry.day} ${entry.month}`;
                    return entryDate === dateObject.day;
                });
                const [day, month] = result[0]?.day?.split(" ");
                const [endday, endmonth] = result[result.length - 1]?.day?.split(" ");
                const fullMonth = moment().month(month).format("MMMM");
                const endFullMonth = moment().month(endmonth).format("MMMM");
                console.log("result", day, fullMonth);
                if (
                    typeof setStartDateRange === "function" ||
                    typeof setEndDateRange === "function"
                ) {
                    setStartDateRange(`${day} ${fullMonth}`);
                    setEndDateRange(`${endday} ${endFullMonth}`);
                } else {
                    console.error("setStartDateRange is not a function");
                }

                const matchingDataTwo = data.find((entry: { day: any; month: any }) => {
                    const entryDate = `${entry.day} ${entry.month}`;
                    let [day1, month1] = dateObject.day.split(" ");
                    month1 = (parseInt(month1, 10) - 1).toString();
                    return entryDate === day1 + " " + month1;
                });

                if (matchingData) {
                    dateObject.value = matchingData.count;
                }
                if (matchingDataTwo) {
                    dateObject.value = matchingDataTwo.count;
                }
            });
            x = result.map((entry) => entry.value);
            y = result.map((entry) => {
                let [day1, month1] = entry.day?.split(" ");
                month1 = (parseInt(month1, 10) - 1).toString();
                return `${day1} ${moment().month(month1).format("MMM")}`;
            });
        } else {
            for (let day = 1; day <= lastDayOfMonth; day++) {
                result.push({ day, value: 0 });
            }
            for (const [key, value] of Object.entries(data)) {
                const day = parseInt(key.split("_")[1]);
                if (!isNaN(day) && day >= 1 && day <= lastDayOfMonth) {
                    result[day - 1].value = value;
                }
            }
            x = result.map((entry) => entry.value);
            y = result.map((entry) => entry.day);
        }
        // Extract values and days from the result array
    } else if (period === "week") {
        for (let day = 1; day <= 7; day++) {
            result.push({ day, value: 0 });
        }
        if (Boolean(procurement)) {
            for (const { dayOfWeek, count } of data) {
                if (!isNaN(dayOfWeek) && dayOfWeek >= 1 && dayOfWeek <= 7) {
                    result[dayOfWeek - 1].value = count;
                }
            }
            const currentDate = moment();

            const sevenDaysAgo = moment().subtract(7, "days");
            const formattedCurrentDate = currentDate.format("DD MMMM, YYYY");
            const formattedSevenDaysAgo = sevenDaysAgo.format("DD MMMM, YYYY");

            if (typeof setStartDateRange === "function" || typeof setEndDateRange === "function") {
                setStartDateRange(formattedSevenDaysAgo);
                setEndDateRange(formattedCurrentDate);
            } else {
                console.error("setStartDateRange is not a function");
            }
        } else {
            for (const [weekday, value] of Object.entries(data)) {
                const isoWeekday = moment().day(weekday).isoWeekday(); // Get ISO weekday number
                if (!isNaN(isoWeekday) && isoWeekday >= 1 && isoWeekday <= 7) {
                    result[isoWeekday - 1].value = value;
                }
            }
        }

        x = result.map((entry) => entry.value);
        y = result.map((entry) => moment().isoWeekday(entry.day).format("dddd"));
    } else if (period === "year") {
        if (Boolean(procurement)) {
            const currentDate = moment();

            for (let i = 0; i < 12; i++) {
                result.push({
                    month: currentDate.clone().subtract(i, "months").format("MMM YY"),
                    value: 0,
                });
            }

            result.reverse();
            const [month, year] = result[0]?.month?.split(" ");
            const [endmonth, endyear] = result[result.length - 1]?.month?.split(" ");
            const fullMonth = moment(month, "MMM").format("MMMM");
            const fullYear = moment(year, "YY").format("YYYY");
            const endFullMonth = moment(endmonth, "MMM").format("MMMM");
            const endFullYear = moment(endyear, "YY").format("YYYY");
            console.log("result", fullMonth, fullYear);
            if (typeof setStartDateRange === "function" || typeof setEndDateRange === "function") {
                setStartDateRange(`${fullMonth} ${fullYear}`);
                setEndDateRange(`${endFullMonth} ${endFullYear}`);
            } else {
                console.error("setStartDateRange is not a function");
            }
            result.forEach((dateObject) => {
                const matchingData = data.find((entry: { month: number; year: any }) => {
                    const entryDate = `${moment()
                        .month(entry?.month - 1)
                        .format("MMM")} ${String(entry.year).slice(-2)}`;
                    return entryDate === dateObject.month;
                });

                const matchingDataTwo = data.find((entry: { month: number; year: any }) => {
                    const entryDate = `${moment()
                        .month(entry?.month - 1)
                        .format("MMM")} ${String(entry.year).slice(-2)}`;
                    let [month, year]: any = dateObject.month.split(" ");
                    year = (parseInt(year) - 1).toString();
                    return entryDate === month + " " + year;
                });

                if (matchingData) {
                    dateObject.value = matchingData.count;
                }
                if (matchingDataTwo) {
                    dateObject.value = matchingDataTwo.count;
                }
            });

            x = result.map((entry) => entry?.value);
            y = result.map((entry) => {
                return entry?.month;
            });
        } else {
            const currentMonth = moment();
            const startMonth = currentMonth.clone().subtract(11, "months");

            for (let i = 0; i < 12; i++) {
                const month = startMonth.clone().add(i, "months");

                result.push({ month: month.format("MMM"), value: 0 });
            }

            result.forEach((item, index) => {
                const month = item.month;
                if (data.hasOwnProperty(month)) {
                    result[index].value = data[month];
                }
            });

            x = result.map((entry) => entry.value);
            y = result.map((entry) => entry.month);
        }
    } else if (period === "six_month") {
        if (Boolean(procurement)) {
            const currentDate = moment();
            for (let i = 0; i < 6; i++) {
                result.push({
                    month: currentDate.clone().subtract(i, "months").format("MMM YY"),
                    value: 0,
                });
            }

            result.reverse();
            const [month, year] = result[0]?.month?.split(" ");
            const [endmonth, endyear] = result[result.length - 1]?.month?.split(" ");
            const fullMonth = moment(month, "MMM").format("MMMM");
            const fullYear = moment(year, "YY").format("YYYY");
            const endFullMonth = moment(endmonth, "MMM").format("MMMM");
            const endFullYear = moment(endyear, "YY").format("YYYY");
            console.log("result", fullMonth, fullYear);
            if (typeof setStartDateRange === "function" || typeof setEndDateRange === "function") {
                setStartDateRange(`${fullMonth} ${fullYear}`);
                setEndDateRange(`${endFullMonth} ${endFullYear}`);
            } else {
                console.error("setStartDateRange is not a function");
            }

            result.forEach((dateObject) => {
                const matchingData = data.find((entry: { month: number; year: any }) => {
                    const entryDate = `${moment()
                        .month(entry?.month - 1)
                        .format("MMM")} ${String(entry.year).slice(-2)}`;
                    return entryDate === dateObject.month;
                });
                const matchingDataTwo = data.find((entry: { month: number; year: any }) => {
                    const entryDate = `${moment()
                        .month(entry?.month - 1)
                        .format("MMM")} ${String(entry.year).slice(-2)}`;
                    let [month, year] = dateObject.month.split(" ");
                    year = (parseInt(year) - 1).toString();
                    return entryDate === month + " " + year;
                });

                if (matchingData) {
                    dateObject.value = matchingData.count;
                }
                if (matchingDataTwo) {
                    dateObject.value = matchingDataTwo.count;
                }
            });
            x = result.map((entry) => entry.value);
            y = result.map((entry) => entry.month);
        }
    } else if (period === "6 month") {
        const currentMonth = moment();
        const startMonth = currentMonth.clone().subtract(5, "months");
        for (let i = 0; i < 6; i++) {
            const month = startMonth.clone().add(i, "months");
            result.push({ month: month.format("MMM"), value: 0 });
        }
        result.forEach((item, index) => {
            const month = item.month;
            if (data.hasOwnProperty(month)) {
                result[index].value = data[month];
            }
        });
        x = result.map((entry) => entry.value);
        y = result.map((entry) => entry.month);
    } else if (period === "custom") {
        if (Boolean(procurement)) {
            const values = Object.values(data) as DataEntry[];
            for (const { count, month, day, year } of values) {
                result.push({
                    date: `${day}-${moment()
                        .month(month - 1)
                        .format("MMM")}-${String(year).slice(-2)}`,
                    value: count,
                });

                y = result.map((entry) => entry?.date);
                x = result.map((entry) => entry?.value);
            }
        } else {
            const keys = Object.keys(data);
            const values = Object.values(data);
            x = values.map((entry) => entry);
            y = keys.map((entry) => entry);
        }
    }
    return {
        result,
        x,
        y,
    };
};
