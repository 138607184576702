import useGetUserProfile from "hooks/useGetUserProfile";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import CustomToast from "./CustomToast";

type DropdownProps = {
    id?: string;
    label?: string;
    handleChange?: (name: string, value: string) => void;
    name?: string;
    value?: any;
    disabled?: boolean;
    placeholder?: string;
    dropdownOptions?: {
        customChild?: JSX.Element;
        value?: string;
        action?: () => void;
        disabled?: boolean;
        label?: string | JSX.Element;
        icon?: JSX.Element;
        rightIcon?: JSX.Element;
        labelClassName?: string;
        permissions?: string[];
    }[];
    required?:boolean;
    dropdown?: React.ReactNode;
    dropdownTriggerClassName?: string;
    dropdownContainerClasses?: string;
    dropdownItemsClasses?: string;
    className?: string;
    dropdownClassName?: string;
    search?: React.ReactNode;
    addMoreButton?: React.ReactNode;
    clearSearchString?: () => void;
    leaveOpen?: boolean;
    permissions?: string[];
};

const Dropdown = ({
    id,
    label,
    handleChange,
    name,
    value,
    disabled,
    placeholder,
    dropdownOptions,
    dropdown,
    dropdownTriggerClassName,
    dropdownContainerClasses,
    dropdownItemsClasses,
    className,
    dropdownClassName,
    search,
    addMoreButton,
    leaveOpen,
    clearSearchString,
    permissions,
}: DropdownProps) => {
    const [profile] = useGetUserProfile();
    const [open, setOpen] = useState(false);
    const selectedOption = dropdownOptions?.find((item) => item?.value === value);
    const ref = useRef<HTMLDivElement>(null);
    const [permissionList, setPermissionList] = useState<string[]>();

    const handleSelect = (value: string) => {
        if (!leaveOpen) {
            handleChange?.(name as string, value);
            setOpen(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node) && open) {
                setOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, open]);

    const isPermitted = permissions?.some((permission) =>
        profile?.permission?.includes(permission)
    );

    return (
        <div className={`relative ${permissions?.length > 0 && !isPermitted && " group !z-[99]"}`}>
            {permissions?.length > 0 && !isPermitted && (
                <span
                    className={` font-rocGroteskRegular pointer-events-none absolute top-[50px] 
                     text-xs left-1/2  before:left-1/2 !w-[160px]  transform -translate-x-1/2
                      bg-gm-50 p-3 text-white opacity-0 transition before:absolute  before:bottom-full
                       before:translate-x-1/2 before:border-4   before:border-transparent before:border-b-gm-50
                       rounded before:content-[''] group-hover:opacity-100 z-100 text-center !z-[100]`}
                >
                    You don't have access to this feature
                </span>
            )}
            {dropdown ? (
                <div
                    onClick={() =>
                        permissions?.length > 0 && !isPermitted
                            ? toast.custom((t) => (
                                  <CustomToast
                                      t={t}
                                      message="You don't have access to this feature"
                                      type="error"
                                  />
                              ))
                            : disabled
                            ? null
                            : setOpen(!open)
                    }
                    className={`w-fit  ${dropdownTriggerClassName} ${
                        disabled ? "cursor-default" : "cursor-pointer"
                    } ${permissions?.length > 0 && !isPermitted && "!opacity-50 cursor-default "}`}
                >
                    {dropdown}
                </div>
            ) : (
                <div
                    onClick={() =>
                        permissions?.length > 0 && !isPermitted
                            ? toast.custom((t) => (
                                  <CustomToast
                                      t={t}
                                      message="You don't have access to this feature"
                                      type="error"
                                  />
                              ))
                            : disabled
                            ? null
                            : setOpen(!open)
                    }
                    className={`flex gap-2.5 justify-between cursor-pointer items-center border border-gray-200 py-3 px-4 outline-0 w-full
                     h-[50px] text-sm font-rocGroteskMedium bg-tradeally-neutral-20 ${className} `}
                >
                    <p className="text-tradeally-neutral-200 ">
                        {Boolean(value) ? (
                            <span className="flex items-center">
                                {selectedOption?.icon && (
                                    <> {selectedOption?.icon}&nbsp;&nbsp;&nbsp; </>
                                )}
                                {selectedOption?.label}
                            </span>
                        ) : (
                            placeholder
                        )}
                    </p>
                    {!search && <span className="material-icons">arrow_drop_down</span>}
                </div>
            )}

            {open &&
                (dropdownOptions && dropdownOptions?.length > 0 ? (
                    <div
                        ref={ref}
                        className={`${
                            dropdown ? "min-w-max" : ""
                        } py-2 bg-white absolute top-[101%] w-full   h-auto max-h-64 z-[10] overflow-auto border ${dropdownContainerClasses} `}
                    >
                        {search && <div className="px-[5px] pb-[5px]">{search}</div>}
                        {dropdownOptions.map((option, idx) => {
                            return option.customChild ? (
                                <div key={idx}>{option.customChild}</div>
                            ) : (
                                <div
                                    key={option.value}
                                    onClick={() => {
                                        if (
                                            option?.permissions?.length > 0 &&
                                            !option?.permissions?.some((permission) =>
                                                profile?.permission?.includes(permission)
                                            )
                                        ) {
                                            toast.custom((t) => (
                                                <CustomToast
                                                    t={t}
                                                    message="You don't have access to this feature"
                                                    type="error"
                                                />
                                            ));
                                        } else if (option?.action && !option?.disabled) {
                                            option.action?.();
                                        }
                                        if (!option?.disabled) {
                                            handleSelect(option?.value as string);
                                        }
                                    }}
                                    className={`flex  items-center px-4 justify-between  hover:bg-tradeally-neutral-20 ${
                                        selectedOption?.value === option?.value &&
                                        "bg-tradeally-neutral-20"
                                    } ${option?.disabled && "opacity-40"} ${
                                        option?.permissions?.length > 0 &&
                                        !option?.permissions?.some((permission) =>
                                            profile?.permission?.includes(permission)
                                        ) &&
                                        "opacity-40"
                                    } cursor-pointer py-3 ${dropdownItemsClasses}`}
                                >
                                    <p
                                        className={`text-sm flex gap-3  w-full items-center font-rocGroteskMedium text-inherit`}
                                    >
                                        {option?.icon && <span>{option?.icon}</span>}{" "}
                                        <span
                                            className={`text-inherit ${`${option?.labelClassName}`}`}
                                        >
                                            {option?.label}
                                        </span>
                                        {option?.rightIcon && <span>{option?.rightIcon}</span>}{" "}
                                    </p>
                                    {selectedOption?.value === option?.value && (
                                        <span className="material-icons text-tradeally-black text-base">
                                            done
                                        </span>
                                    )}
                                </div>
                            );
                        })}
                        {addMoreButton && <div className="px-[5px] pb-[5px]">{addMoreButton}</div>}
                    </div>
                ) : (
                    <div
                        className={`${
                            search ? "mt-3" : "w-full"
                        } py-2 bg-white absolute top-[101%] h-auto max-h-64 z-20  overflow-auto border ${dropdownClassName}`}
                        id="custom-dropdown"
                    >
                        {search && <div className="px-[5px] pb-[5px]">{search}</div>}

                        <div className="flex px-4 justify-between hover:bg-tradeally-neutral-20 cursor-pointer">
                            <p className="text-sm flex gap-3 py-3 items-center font-rocGroteskMedium">
                                <span>No Data</span>
                            </p>
                        </div>
                        {addMoreButton && <div className="px-[5px] pb-[5px]">{addMoreButton}</div>}
                    </div>
                ))}
        </div>
    );
};

export default Dropdown;
