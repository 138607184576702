import Button from "components/common/Button";
import CopilotChat from "components/common/CopilotChat";
import Dropdown from "components/common/Dropdown";
import Loader from "components/common/Loader";
import PageFrame from "components/layout/PageFrame";
import { getCurrencyFromCurrencyCode, getFromStorage } from "helpers";
import { convertSeconds, parseConvertedSeconds } from "helpers/convertSeconds";
import { set, truncate } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    chatActions,
    orderActions,
    settingsActions,
    taskActions,
    workFlowActions,
} from "redux/Ldb/actions";
import { shipmentActions } from "redux/Ldb/actions/shipmentActions";
import { calculateDaysUntilToday } from "helpers/calculateInDays";
import countries from "variables/countries";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import VendorAvatar from "components/common/VendorAvatar";
import ModalContainer from "components/common/ModalContainer";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";

const DashboardHome = () => {
    const [time, setTime] = useState("");
    const [chat, setChat] = useState("");
    const [orders, setOrders] = useState([]);
    const [shipments, setShipments] = useState([]);
    const [chats, setChats] = useState([]);
    const [workflows, setWorkFlows] = useState([]);
    const [isAlmostExpired, setIsAlmostExpired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [taskList, setTaskList] = useState([]);
    const [approvalTasks, setApprovalTasks] = useState([]);
    const [purchaseOrderList, setPurchaseOrderList] = useState([]);
    const [selectedPoHistory, setSelectedPoHistory] = useState<{ [key: string]: any }>([]);
    const [selectedTask, setSelectedTask] = useState<{ [key: string]: any }>({});
    const [approveTaskOpen, setApproveTaskOpen] = useState(false);
    const [rejectTaskOpen, setRejectTaskOpen] = useState(false);
    const [openPoHistory, setOpenPoHistory] = useState(false);
    const [profile, setProfile] = useState(() => getFromStorage("ally-user"));
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        fetchingOrders,
        fetchedOrdersSuccess,
        fetchingPurchaseOrder,
        fetchedPurchaseOrderSuccess,
    } = useAppSelector((state) => state.order);
    const { fetchingShipments, fetchShipmentsSuccess } = useAppSelector((state) => state.shipment);
    const { creatingConversation, createConversationSuccess } = useAppSelector(
        (state) => state.chat
    );
    const [subscriptionPlan, setSubscriptionPlan] = useState<{ [key: string]: any }>({});
    const {
        fetchingCurrentSubscription,
        createStripeSession,
        createStripeSessionSuccess,
        fetchCurrentSubscriptionSuccess,
    } = useAppSelector((state) => state.settings);
    const {
        fetchingDashboardWorkflowRuns,
        fetchedDashboardWorkflowRunsSuccess,
        markingActionAsRejected,
        markActionAsRejectedSuccess,
    } = useAppSelector((state) => state.workFlow);
    const { sendingAiMessage, sendAiMessageSuccess } = useAppSelector((state) => state.chat);
    const { fetchingTaskList, fetchTaskListSuccess, updateTaskSuccess, updateTask } =
        useAppSelector((state) => state.task);

    let now = new Date();

    const todayDate = moment(now).format("dddd, MMMM Do");

    const handleApproveTask = () => {
        const body = {
            status: "completed",
        };
        // setDone("completed");
        dispatch(taskActions.updateTask(body, selectedTask?._id));
    };

    const handleRejectTask = () => {
        const body = {
            actionId: selectedTask?.action?._id,
        };
        // setDone("completed");
        dispatch(workFlowActions.markActionAsRejected(body));
    };

    useEffect(() => {
        if (now) {
            if (now.getHours() > 5 && now.getHours() < 12) {
                setTime("Morning");
            }
            if (now.getHours() >= 12 && now.getHours() < 18) {
                setTime("Afternoon");
            }
            if (now.getHours() >= 18 && now.getHours() < 22) {
                setTime("Evening");
            }
            if (now.getHours() >= 22 || now.getHours() <= 5) {
                setTime("Night");
            }
        }
    }, [now]);

    useEffect(() => {
        dispatch(orderActions.getOrders(2));
        dispatch(shipmentActions.getShipments(2));
        dispatch(workFlowActions.getDashboardWorkflowRuns());
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedOrdersSuccess)) {
            setOrders(fetchedOrdersSuccess?.orders);
        }
    }, [fetchedOrdersSuccess]);

    useEffect(() => {
        if (Boolean(fetchShipmentsSuccess)) {
            setShipments(fetchShipmentsSuccess?.data);
        }
    }, [fetchShipmentsSuccess]);

    useEffect(() => {
        if (Boolean(fetchedDashboardWorkflowRunsSuccess)) {
            setWorkFlows(fetchedDashboardWorkflowRunsSuccess?.workFlows);
        }
    }, [fetchedDashboardWorkflowRunsSuccess]);

    const onSendAIChat = (e) => {
        e.preventDefault();
        dispatch(
            chatActions.createConversation({
                title: "RFQ Creation",
                receiverType: "co-pilot",
                conversationType: "CoPilotRFQ",
            })
        );
    };

    useEffect(() => {
        if (Boolean(createConversationSuccess)) {
            dispatch(
                chatActions.sendAiMessage(
                    {
                        conversationId: createConversationSuccess?.conversation?._id,
                        message: chat,
                    },
                    setChats
                )
            );
        }
    }, [createConversationSuccess]);
    useEffect(() => {
        if (!Boolean(fetchCurrentSubscriptionSuccess)) {
            dispatch(settingsActions.fetchCurrentSubscriptionPlan());
        }
    }, [dispatch, fetchCurrentSubscriptionSuccess]);

    useEffect(() => {
        if (Boolean(fetchCurrentSubscriptionSuccess)) {
            setSubscriptionPlan(fetchCurrentSubscriptionSuccess?.subscription);
            setLoading(true);
        }
    }, [fetchCurrentSubscriptionSuccess]);
    useEffect(() => {
        if (Boolean(sendAiMessageSuccess && chats?.length > 0)) {
            navigate(
                `/dashboard/rfqs/create?by=AI&conversationId=${createConversationSuccess?.conversation?._id}`,
                { state: { chatsArr: [sendAiMessageSuccess?.chat, ...chats] } }
            );
            dispatch(chatActions.resetCreateConversationSuccess());
            setChat("");
        }
    }, [dispatch, navigate, sendAiMessageSuccess, chats]);

    const expiryDays = calculateDaysUntilToday(subscriptionPlan.expiryDate);

    useEffect(() => {
        if (expiryDays < 4 && expiryDays > 0) {
            setIsAlmostExpired(true);
        }
    }, [expiryDays]);

    const onUpgradePlan = () => {
        const body = {
            planId: subscriptionPlan?.plan?._id,
        };
        dispatch(settingsActions.createStripeSession(body));
    };

    useEffect(() => {
        if (Boolean(createStripeSessionSuccess)) {
            window.location.href = createStripeSessionSuccess?.subscription?.checkoutUrl;
            dispatch(settingsActions.resetCreateStripeSession());
        }
    }, [createStripeSessionSuccess]);
    useEffect(() => {
        dispatch(taskActions.fetchTaskList(3, "", "", "", "task_action"));
    }, [dispatch]);

    useEffect(() => {
        dispatch(taskActions.fetchTaskList(3, "on-track", "", "", "approval"));
    }, [dispatch, updateTaskSuccess, markActionAsRejectedSuccess]);

    useEffect(() => {
        if (
            Boolean(
                fetchTaskListSuccess &&
                    fetchTaskListSuccess?.tasks?.[0]?.action?.actionCategory?.toLowerCase() ===
                        "task_action"
            )
        ) {
            setTaskList(fetchTaskListSuccess?.tasks);
        }
    }, [fetchTaskListSuccess]);

    useEffect(() => {
        if (
            Boolean(
                fetchTaskListSuccess &&
                    fetchTaskListSuccess?.tasks?.[0]?.action?.actionCategory?.toLowerCase() ===
                        "approval"
            )
        ) {
            setApprovalTasks(fetchTaskListSuccess?.tasks);
        }
    }, [fetchTaskListSuccess]);

    useEffect(() => {
        if (Boolean(updateTaskSuccess) || Boolean(markActionAsRejectedSuccess)) {
            setApproveTaskOpen(false);
            setRejectTaskOpen(false);
        }
    }, [updateTaskSuccess, markActionAsRejectedSuccess]);

    useEffect(() => {
        dispatch(orderActions.getPurchaseOrder(3, "", "", ""));
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedPurchaseOrderSuccess)) {
            const poSuccessCopy = JSON.parse(JSON.stringify(fetchedPurchaseOrderSuccess));

            setPurchaseOrderList(poSuccessCopy?.po);
        }
    }, [fetchedPurchaseOrderSuccess]);
    console.log("selectedPoHistory", selectedPoHistory);
    return (
        <PageFrame>
            <div className="py-5">
                <div className="flex flex-col justify-center text-center mb-10">
                    <p className="text-slate-500 font-rocGroteskMedium text-base">{todayDate}</p>
                    <h1 className="text-gm-50 font-rocGroteskMedium text-[22px] leading-8">
                        Good {time}, {profile?.user?.fullName?.split(" ")[0]}.
                    </h1>
                    {/* <div className="flex justify-center w-full">
                        <div className="mt-3 border border-slate-200 bg-slate-50 rounded-[8px] flex   !w-fit space-x-3">
                            <div className="flex  py-3 px-4 border-r">
                                <Dropdown
                                    dropdown={
                                        <div className="flex items-center space-x-2">
                                            <i className="ri-arrow-down-s-line text-2xl"></i>
                                            <p className="font-rocGroteskMedium text-base">
                                                Filter
                                            </p>
                                        </div>
                                    }
                                    dropdownClassname={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-2  border-0 rounded !max-h-fit`}
                                    dropdownItemsClasses={``}
                                    name={"faq-dropdown"}
                                    // dropdownOptions={dropdownOptions}
                                />
                            </div>
                            <div className="flex items-center cursor-pointer space-x-2 border-r px-4 py-3">
                                <i className="ri-check-double-fill text-2xl"></i>
                                <p className="font-rocGroteskMedium text-base">
                                    234 completed tasks
                                </p>
                            </div>
                            <div className="flex  py-3 px-4">
                                <Dropdown
                                    dropdown={
                                        <div className="flex items-center space-x-2">
                                            <i className="ri-add-fill text-2xl"></i>
                                            <p className="font-rocGroteskMedium text-base">
                                                Create new
                                            </p>
                                        </div>
                                    }
                                    dropdownClassname={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-2  border-0 rounded !max-h-fit`}
                                    dropdownItemsClasses={``}
                                    name={"faq-dropdown"}
                                    // dropdownOptions={dropdownOptions}
                                />
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="my-5 p-6 max-lg:p-3 rounded-lg border border-slate-100 bg-white">
                    <div className=" shadow-faintShadow ">
                        <div className="bg-slate-50 border-slate-100 border px-4 py-1 rounded-tl-[8px] rounded-tr-[8px]">
                            <p className="text-black font-rocGroteskMedium text-sm ">
                                Create RFQ with your Co-pilot:
                            </p>
                        </div>
                        <form onSubmit={onSendAIChat}>
                            <div className="p-4 min-h-[130px] border rounded-bl-[8px] rounded-br-[8px] border-slate-100">
                                <div className="flex items-center justify-between">
                                    <div className="flex  space-x-3 w-full ">
                                        <i className="ri-robot-fill text-[21px] bg-clip-text text-transparent bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%"></i>
                                        <textarea
                                            rows={6}
                                            required={true}
                                            placeholder=" What can Kiri do for you?"
                                            className="text-sm pt-2 font-rocGroteskMedium ring-0 border-0 outline-none w-full text-slate-500 resize-none"
                                            onChange={(e) => setChat(e.target.value)}
                                        />
                                        <div>
                                            <Button
                                                type={"submit"}
                                                icon={
                                                    <i className="ri-send-plane-2-fill  text-[21px] bg-clip-text text-transparent bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%"></i>
                                                }
                                                isLoading={creatingConversation || sendingAiMessage}
                                                btnClassname={
                                                    "!py-2 !px-3 !w-fit !text-gm-50 !bg-transparent"
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-7 w-full max-lg:grid-cols-1">
                    {!fetchingTaskList ? (
                        <div className=" w-full bg-white border border-slate-100 rounded-lg">
                            <div className="flex justify-between items-center px-4 py-3 border-b border-slate-100 ">
                                <p className="text-gm-50 font-rocGroteskMedium text-[18px]">
                                    My tasks
                                </p>
                                {taskList?.length > 0 && (
                                    <div
                                        className="flex items-center cursor-pointer"
                                        onClick={() => navigate("/dashboard/task/0?new=true")}
                                    >
                                        <i className="ri-add-fill text-g-55"></i>
                                        <p className="text-g-55 underline text-sm font-rocGroteskMedium">
                                            New task
                                        </p>
                                    </div>
                                )}
                            </div>
                            {taskList?.length > 0 ? (
                                <div className="px-4 mt-5 mb-5 space-y-6">
                                    {taskList?.slice(0, 3)?.map((task) => {
                                        return (
                                            <div
                                                key={task?._id}
                                                className="flex items-center justify-between pb-3 border-b-[0.7px] border-slate-200 "
                                            >
                                                <div className="flex space-x-3 items-start">
                                                    {task?.status?.toLowerCase() === "completed" ? (
                                                        <i className="ri-checkbox-circle-fill text-lg text-g-55"></i>
                                                    ) : (
                                                        <i className="ri-checkbox-circle-line text-lg text-gm-40"></i>
                                                    )}
                                                    <div>
                                                        <p className="text-[13px] font-rocGroteskMedium text-gm-45 mb-1">
                                                            {truncate(
                                                                camelCaseToRegularCase(task?.name),
                                                                { length: 40 }
                                                            )}
                                                        </p>
                                                        <p className="text-[10px] font-rocGroteskMedium">
                                                            <span className="text-slate-700 ">
                                                                Due date:{" "}
                                                            </span>
                                                            <span className="text-slate-500">
                                                                {moment(task?.endDate)?.format(
                                                                    "MMM DD, YYYY"
                                                                )}{" "}
                                                                at{" "}
                                                                {moment(task?.endDate)?.format(
                                                                    "LT"
                                                                )}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`
                                                 rounded-[25px] !w-fit px-3 py-1 flex items-center space-x-2
                                                 ${
                                                     task?.status?.toLowerCase() === "overdue" &&
                                                     "bg-light-red"
                                                 } 
                                                ${
                                                    task?.status?.toLowerCase() === "pending" &&
                                                    "bg-light-orange"
                                                }
                                                ${
                                                    task?.status?.toLowerCase() === "on-track" &&
                                                    "bg-p-25"
                                                }
                                                ${
                                                    task?.status?.toLowerCase() === "completed" &&
                                                    "bg-light-green"
                                                }
                                                ${
                                                    task?.status?.toLowerCase() === "delayed" &&
                                                    "bg-light-yellow"
                                                } 
                                                 `}
                                                >
                                                    <p className="text-[10px] font-rocGroteskMedium text-gm-50">
                                                        {task?.status?.toLowerCase() === "on-track"
                                                            ? "active"
                                                            : task?.status}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })}

                                    <div
                                        className="flex w-fit items-center cursor-pointer"
                                        onClick={() => navigate("/dashboard/task/0")}
                                    >
                                        <p className="text-gm-50 underline font-rocGroteskMedium text-sm">
                                            See all
                                        </p>
                                        <i className="ri-arrow-right-s-line"></i>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex py-[10%] px-10  space-y-4 flex-col justify-center items-center">
                                    <i className="ri-task-fill text-[43px] bg-clip-text text-transparent bg-gradient-to-tr from-logo-opacity from-1.2% to-logo-opacity-two to-91.14%"></i>
                                    <p className="text-gm-40 w-[70%] text-center font-rocGroteskMedium text-[13px]">
                                        Boost your productivity by creating tasks that can be
                                        tracked and automated.
                                    </p>
                                    <Button
                                        btnText={"Add a new task"}
                                        btnClassname="!py-2 !w-fit  !px-3 !text-[13px] !text-gm-50 !bg-transparent !border !border-slate-200 whitespace-nowrap"
                                        onClick={() => navigate("/dashboard/task/0?new=true")}
                                        isLoading={false}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <Loader />
                    )}

                    {!fetchingOrders ? (
                        <div className=" w-full h-fit bg-white border border-slate-100 rounded-lg">
                            <div className="px-4 py-3 flex justify-between items-center  border-b border-slate-100 ">
                                <p className="text-gm-50 font-rocGroteskMedium text-[18px]">
                                    Orders
                                </p>
                                {orders?.length > 0 && (
                                    <div
                                        className="flex items-center cursor-pointer"
                                        onClick={() => navigate("/dashboard/rfqs/create")}
                                    >
                                        <i className="ri-add-fill text-g-55"></i>
                                        <p className="text-g-55 underline text-sm font-rocGroteskMedium">
                                            New order
                                        </p>
                                    </div>
                                )}
                            </div>
                            {orders?.length > 0 ? (
                                <div className="px-4 mt-5 mb-5">
                                    {orders?.map((order) => {
                                        const itemsDescriptions = order?.items
                                            ?.map((item) => item.description)
                                            .join(", ");
                                        const itemsQuantity = order?.items
                                            ?.map((item) => item.quantity)
                                            .join(", ");

                                        return (
                                            <div className="" key={order?._id}>
                                                <div className="flex mt-3 space-x-2 items-center">
                                                    <h1 className="text-sm font-rocGroteskMedium text-gm-50">
                                                        #Order-{order?.taId}
                                                    </h1>
                                                    <div className="bg-slate-100 rounded px-2 py-1 flex items-center space-x-1.5">
                                                        {order?.channel?.toLowerCase() ===
                                                            "whatsapp" && (
                                                            <div className="h-4 w-4 rounded-full bg-white flex justify-center items-center">
                                                                <i className="ri-whatsapp-fill text-base text-carribean-green"></i>
                                                            </div>
                                                        )}
                                                        {order?.channel?.toLowerCase() ===
                                                            "gmail" && (
                                                            <div className="h-3 w-3 bg-white flex justify-center items-center">
                                                                <img
                                                                    alt="gmail-icon"
                                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700130041/File-assests/gmail_fi1uvb.svg"
                                                                    className="h-3 w-3"
                                                                />
                                                            </div>
                                                        )}
                                                        <p className="text-xs font-rocGroteskMedium text-gm-40">
                                                            From {order?.channel}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="mt-0.5">
                                                    <p className="text-[13px] text-gm-45 font-rocGroteskMedium">
                                                        {truncate(itemsDescriptions, {
                                                            length: 23,
                                                        })}
                                                    </p>
                                                </div>
                                                <div className="flex pb-4 border-b-[0.7px] border-slate-200 mt-1 space-x-2">
                                                    <div className="bg-slate-50 !w-fit rounded px-3 py-1 flex items-center space-x-2">
                                                        <p className="text-[10px] font-rocGroteskMedium text-gm-35">
                                                            Quantity:{itemsQuantity}
                                                        </p>
                                                    </div>
                                                    <div className="bg-slate-50 !w-fit rounded px-3 py-1 flex items-center space-x-2">
                                                        <p className="text-[10px] font-rocGroteskMedium text-gm-35">
                                                            Est. Budget:{" "}
                                                            {order?.budget?.amount
                                                                ? getCurrencyFromCurrencyCode(
                                                                      order?.budget?.currency
                                                                  ) + order?.budget?.amount
                                                                : "N/A"}
                                                        </p>
                                                    </div>
                                                    <div className="bg-slate-50 !w-fit rounded px-3 py-1 flex items-center space-x-2">
                                                        <p className="text-[10px] font-rocGroteskMedium text-gm-35">
                                                            Type of Order: {order?.orderType}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}

                                    <div
                                        className="flex w-fit items-center pt-7 cursor-pointer"
                                        onClick={() => navigate("/dashboard/rfqs")}
                                    >
                                        <p className="text-gm-50 underline font-rocGroteskMedium text-sm">
                                            See all
                                        </p>
                                        <i className="ri-arrow-right-s-line"></i>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex py-[10%] px-10  space-y-4 flex-col justify-center items-center">
                                    <i className="ri-file-list-3-fill text-[43px] bg-clip-text text-transparent bg-gradient-to-tr  from-logo-opacity from-1.2% to-logo-opacity-two to-91.14%"></i>
                                    <p className="text-gm-40 w-[70%] text-center font-rocGroteskMedium text-[13px]">
                                        Efficiently manage your orders and keep your procurement
                                        process running smoothly.
                                    </p>
                                    <Button
                                        btnText={"Create your first order"}
                                        btnClassname="!py-2 !w-fit  !px-3 !text-[13px] !text-gm-50 !bg-transparent !border !border-slate-200 whitespace-nowrap"
                                        onClick={() => navigate("/dashboard/rfqs/create")}
                                        isLoading={false}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <Loader />
                    )}

                    {!fetchingPurchaseOrder ? (
                        <div className=" w-full bg-white border border-slate-100 rounded-lg">
                            <div className="flex justify-between items-center px-4 py-3 border-b border-slate-100 ">
                                <p className="text-gm-50 font-rocGroteskMedium text-[18px]">
                                    Current POs
                                </p>
                                {purchaseOrderList?.length > 0 && (
                                    <div
                                        className="flex items-center cursor-pointer"
                                        onClick={() => navigate("/dashboard/purchase-orders")}
                                    >
                                        <p className="text-g-55 underline text-sm font-rocGroteskMedium">
                                            See all
                                        </p>
                                        <i className="ri-arrow-right-s-line text-g-55"></i>
                                    </div>
                                )}
                            </div>
                            {purchaseOrderList?.length > 0 ? (
                                <div className="px-4 mt-5 mb-5 space-y-6">
                                    {purchaseOrderList?.slice(0, 3)?.map((po) => {
                                        const sortedPoHistory = (po?.trackingEvents || [])?.sort(
                                            (a, b) =>
                                                (new Date(a.eventTime) as any) -
                                                (new Date(b.eventTime) as any)
                                        );
                                        console.log(po?.trackingEvents);
                                        const historyStatus =
                                            sortedPoHistory?.[0]?.status?.toLowerCase();
                                        return (
                                            <div
                                                key={po?._id}
                                                className="flex items-start justify-between pb-3 border-b-[0.7px] border-slate-200 "
                                            >
                                                <div>
                                                    <p className="text-[13px] font-rocGroteskMedium text-gm-45 mb-2">
                                                        {truncate(
                                                            `PO for ${
                                                                po?.inventory?.productName ||
                                                                po?.itemsOrServices
                                                                    ?.map((item) => item?.name)
                                                                    ?.join(",")
                                                            }`,
                                                            {
                                                                length: 40,
                                                            }
                                                        )}
                                                    </p>
                                                    {sortedPoHistory &&
                                                        sortedPoHistory?.length > 0 && (
                                                            <div className="flex items-center space-x-3">
                                                                <div
                                                                    className={`bg-light-green ${
                                                                        historyStatus?.includes(
                                                                            "rejected"
                                                                        ) && "!bg-light-red"
                                                                    } ${
                                                                        historyStatus?.includes(
                                                                            "progress"
                                                                        ) && "!bg-light-orange"
                                                                    } rounded-[25px] !w-fit px-3 py-1 flex items-center space-x-2 `}
                                                                >
                                                                    {historyStatus?.includes(
                                                                        "rejected"
                                                                    ) ? (
                                                                        <div
                                                                            className={`h-2 w-2 bg-red-nice rounded-full`}
                                                                        ></div>
                                                                    ) : historyStatus?.includes(
                                                                          "progress"
                                                                      ) ? (
                                                                        <i className="ri-hourglass-fill text-[8px] text-orange"></i>
                                                                    ) : (
                                                                        <i className="ri-checkbox-circle-fill text-[8px] text-g-60"></i>
                                                                    )}

                                                                    <p className="text-[10px] font-rocGroteskMedium text-gm-50">
                                                                        {
                                                                            sortedPoHistory?.[0]
                                                                                ?.status
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    onClick={() => {
                                                                        setSelectedPoHistory(
                                                                            sortedPoHistory
                                                                        );
                                                                        setOpenPoHistory(true);
                                                                    }}
                                                                    className="flex items-center space-x-[2px] cursor-pointer"
                                                                >
                                                                    <span className="text-sm text-gm-50 font-rocGroteskMedium underline">
                                                                        Track PO
                                                                    </span>
                                                                    <i className="ri-arrow-right-s-line text-base"></i>
                                                                </div>
                                                            </div>
                                                        )}
                                                </div>
                                                <VendorAvatar
                                                    name={po?.vendor?.profile?.businessName}
                                                    imageSrc={po?.vendor?.profile?.avatar}
                                                    size={24}
                                                    textClassname="!text-[10px]"
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className="flex py-[10%] px-10  space-y-4 flex-col justify-center items-center">
                                    <i className="ri-survey-fill text-[43px] bg-clip-text text-transparent bg-gradient-to-tr  from-logo-opacity from-1.2% to-logo-opacity-two to-91.14%"></i>
                                    <p className="text-gm-40  w-[70%] text-center font-rocGroteskMedium text-[13px]">
                                        Manage and track all purchase orders all purchase orders
                                    </p>
                                    <Button
                                        btnText={"Create purchase order"}
                                        btnClassname="!py-2 !w-fit  !px-3 !text-[13px] !text-gm-50 !bg-transparent !border !border-slate-200 whitespace-nowrap"
                                        onClick={(e) => {}}
                                        isLoading={false}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <Loader />
                    )}

                    {!fetchingShipments ? (
                        <div className=" w-full h-fit bg-white border border-slate-100 rounded-lg">
                            <div className="px-4 py-3 flex justify-between items-center  border-b border-slate-100 ">
                                <p className="text-gm-50 font-rocGroteskMedium text-[18px]">
                                    Shipments
                                </p>
                                {shipments.length > 0 && (
                                    <div
                                        className="flex items-center cursor-pointer"
                                        onClick={() =>
                                            navigate("/dashboard/shipment?assign=success")
                                        }
                                    >
                                        <i className="ri-add-fill text-g-55"></i>
                                        <p className="text-g-55 underline text-sm font-rocGroteskMedium">
                                            Assign a shipment
                                        </p>
                                    </div>
                                )}
                            </div>
                            {shipments.length > 0 ? (
                                <div className="px-4 mt-5 mb-5">
                                    {shipments?.slice(0, 2)?.map((shipment) => (
                                        <div className="" key={shipment?._id}>
                                            <div className="flex mt-3 space-x-2 items-center">
                                                <h1 className="text-[13px] font-rocGroteskMedium text-gm-45">
                                                    {shipment?.freight?.goodsDetails?.description
                                                        ? shipment?.freight?.goodsDetails
                                                              ?.description
                                                        : shipment?.supplier?.ItemDetails
                                                              ?.description}
                                                </h1>
                                            </div>
                                            <div className="mt-0.5 flex items-center space-x-1">
                                                <p className="text-xs text-slate-500 font-rocGroteskMedium">
                                                    {truncate(
                                                        shipment?.movementdetails?.origin?.address,
                                                        { length: 40 }
                                                    )}
                                                </p>
                                                <i className="ri-arrow-right-line text-sm tex-slate-400"></i>
                                                <p className="text-xs text-slate-500 font-rocGroteskMedium">
                                                    {truncate(
                                                        shipment?.movementdetails?.destination
                                                            ?.address,
                                                        { length: 40 }
                                                    )}
                                                </p>
                                            </div>
                                            <div className="flex pb-4 border-b-[0.7px] border-slate-200 mt-1 space-x-2">
                                                <div
                                                    className={`${
                                                        shipment?.status?.toLowerCase() ===
                                                            "delayed" && "bg-light-red"
                                                    } 
                                                ${
                                                    shipment?.status?.toLowerCase() ===
                                                        "in-transit" && "bg-light-orange"
                                                }
                                                ${
                                                    shipment?.status?.toLowerCase() ===
                                                        "delivered" && "bg-light-green"
                                                }
                                                bg-light-red rounded-[25px] !w-fit px-3 py-1 flex items-center space-x-2`}
                                                >
                                                    <div
                                                        className={`h-2 w-2 ${
                                                            shipment?.status?.toLowerCase() ===
                                                                "delayed" && "bg-red-nice"
                                                        } ${
                                                            shipment?.status?.toLowerCase() ===
                                                                "in-transit" && "bg-orange"
                                                        }  ${
                                                            shipment?.status?.toLowerCase() ===
                                                                "delivered" && "bg-g-50"
                                                        }  rounded-full`}
                                                    ></div>
                                                    <p className="text-[10px] font-rocGroteskMedium text-gm-50">
                                                        {shipment?.status}
                                                    </p>
                                                </div>
                                                <div
                                                    className="flex items-center cursor-pointer"
                                                    onClick={() =>
                                                        navigate(
                                                            `/dashboard/shipment/${shipment?._id}`
                                                        )
                                                    }
                                                >
                                                    <p className="text-gm-50 underline font-rocGroteskMedium text-sm">
                                                        Track shipment
                                                    </p>
                                                    <i className="ri-arrow-right-s-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    <div
                                        className="flex w-fit items-center pt-7 cursor-pointer"
                                        onClick={() => navigate(`/dashboard/shipment`)}
                                    >
                                        <p className="text-gm-50 underline font-rocGroteskMedium text-sm">
                                            See all
                                        </p>
                                        <i className="ri-arrow-right-s-line"></i>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex py-[10%] px-10  space-y-4 flex-col justify-center items-center">
                                    <i className="ri-dashboard-fill text-[43px] bg-clip-text text-transparent bg-gradient-to-tr  from-logo-opacity from-1.2% to-logo-opacity-two to-91.14%"></i>
                                    <p className="text-gm-40 text-center  w-[70%] font-rocGroteskMedium text-[13px]">
                                        Track and manage shipments effortlessly. Monitor and stay
                                        updated on the delivery status of your orders.
                                    </p>
                                    <Button
                                        btnText={"Track your first shipment"}
                                        btnClassname="!py-2 !w-fit  !px-3 !text-[13px] !text-gm-50 !bg-transparent !border !border-slate-200 whitespace-nowrap"
                                        onClick={() =>
                                            navigate("/dashboard/shipment?assign=success")
                                        }
                                        isLoading={false}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <Loader />
                    )}

                    {!fetchingTaskList ? (
                        <div className=" w-full bg-white border border-slate-100 rounded-lg">
                            <div className="flex justify-between items-center px-4 py-3 border-b border-slate-100 ">
                                <p className="text-gm-50 font-rocGroteskMedium text-[18px]">
                                    Approvals
                                </p>
                                {approvalTasks?.length > 0 && (
                                    <div
                                        className="flex items-center cursor-pointer"
                                        onClick={() => navigate("/dashboard/task/0")}
                                    >
                                        <p className="text-g-55 underline text-sm font-rocGroteskMedium">
                                            See all
                                        </p>
                                        <i className="ri-arrow-right-s-line text-g-55"></i>
                                    </div>
                                )}
                            </div>
                            {approvalTasks?.length > 0 ? (
                                <div className="px-4 my-6 space-y-6">
                                    {approvalTasks?.slice(0, 3)?.map((task) => {
                                        return (
                                            <div
                                                key={task?._id}
                                                className="flex items-start justify-between pb-3 border-b-[0.7px] border-slate-200 "
                                            >
                                                <div className="flex space-x-3 items-start">
                                                    <div>
                                                        <p className="text-[13px] font-rocGroteskMedium text-gm-45 mb-1">
                                                            {truncate(
                                                                camelCaseToRegularCase(task?.name),
                                                                { length: 40 }
                                                            )}
                                                        </p>
                                                        <p className="text-sm space-x-2 font-rocGroteskMedium">
                                                            <span
                                                                onClick={() => {
                                                                    if (
                                                                        task?.name?.toLowerCase() ===
                                                                        "approvepo"
                                                                    ) {
                                                                        return navigate(
                                                                            `/dashboard/purchase-orders/${task?.action?.data?.poIds?.[0]?._id}`
                                                                        );
                                                                    }
                                                                    setApproveTaskOpen(true);
                                                                    setSelectedTask(task);
                                                                }}
                                                                className="text-g-55 underline cursor-pointer"
                                                            >
                                                                Approve
                                                            </span>
                                                            <span
                                                                onClick={() => {
                                                                    if (
                                                                        task?.name?.toLowerCase() ===
                                                                        "approvepo"
                                                                    ) {
                                                                        return navigate(
                                                                            `/dashboard/purchase-orders/${task?.action?.data?.poIds?.[0]?._id}`
                                                                        );
                                                                    }
                                                                    setRejectTaskOpen(true);
                                                                    setSelectedTask(task);
                                                                }}
                                                                className="text-r-55 underline cursor-pointer"
                                                            >
                                                                Reject
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <VendorAvatar
                                                    name={task?.reporter?.fullName}
                                                    imageSrc={task?.reporter?.avatar}
                                                    size={24}
                                                    textClassname="!text-[10px]"
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className="flex py-[10%] px-10  space-y-4 flex-col justify-center items-center">
                                    <i className="ri-list-check-3 text-[43px] bg-clip-text text-transparent bg-gradient-to-tr  from-logo-opacity from-1.2% to-logo-opacity-two to-91.14%"></i>
                                    <p className="text-gm-40  w-[70%] text-center font-rocGroteskMedium text-[13px]">
                                        Manage all pending approval tasks, as well as any approved
                                        task you have sent out within your workflow
                                    </p>
                                    <Button
                                        btnText={"See approval requests"}
                                        btnClassname="!py-2 !w-fit  !px-3 !text-[13px] !text-gm-50 !bg-transparent !border !border-slate-200 whitespace-nowrap"
                                        onClick={() => navigate("/dashboard/task/0")}
                                        isLoading={false}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <Loader />
                    )}

                    {!fetchingDashboardWorkflowRuns ? (
                        <div className=" w-full bg-white border border-slate-100 rounded-lg">
                            <div className="px-4 py-3 flex justify-between items-center  border-b border-slate-100 ">
                                <p className="text-gm-50 font-rocGroteskMedium text-[18px]">
                                    Ongoing workflow runs
                                </p>
                                {workflows?.length > 0 && (
                                    <div
                                        className="flex items-center cursor-pointer"
                                        onClick={() =>
                                            navigate("/dashboard/workflow?createWorkflow=success")
                                        }
                                    >
                                        <i className="ri-add-fill text-g-55"></i>
                                        <p className="text-g-55 underline text-sm font-rocGroteskMedium">
                                            New workflow
                                        </p>
                                    </div>
                                )}
                            </div>
                            {workflows?.length > 0 ? (
                                <div className=" px-4 mt-5 mb-5">
                                    {workflows.slice(0, 3)?.map((workflow) => {
                                        const completedActions = workflow?.workFlowActions?.filter(
                                            (action) =>
                                                action?.currentStatus?.toLowerCase() === "completed"
                                        );
                                        const totalCompletedRunTime = completedActions.reduce(
                                            (accumulator, currentValue) =>
                                                accumulator + currentValue.runTime,
                                            0
                                        );
                                        const avgCompletedRunTime =
                                            totalCompletedRunTime / completedActions?.length;
                                        const convertedSeconds = parseConvertedSeconds(
                                            convertSeconds(avgCompletedRunTime)
                                        );
                                        return (
                                            <div
                                                key={workflow?._id}
                                                className="mt-3 border-b-[0.7px] border-slate-200 flex justify-between"
                                            >
                                                <div>
                                                    <div className="mt-0.5">
                                                        <p className="text-[13px] text-gm-45 font-rocGroteskMedium">
                                                            {workflow?.name} - {workflow?.taId}
                                                        </p>
                                                    </div>
                                                    <div className="flex pb-4  mt-1 space-x-2">
                                                        <div
                                                            className={`${
                                                                workflow?.currentStatus?.toLowerCase() ===
                                                                    "delayed" ||
                                                                (workflow?.currentStatus?.toLowerCase() ===
                                                                    "failed" &&
                                                                    "bg-light-red")
                                                            } ${
                                                                workflow?.currentStatus?.toLowerCase() ===
                                                                    "active" && "bg-g-25"
                                                            } ${
                                                                workflow?.currentStatus?.toLowerCase() ===
                                                                    "completed" && "bg-g-25"
                                                            } ${
                                                                workflow?.currentStatus?.toLowerCase() ===
                                                                    "pending" && "bg-light-yellow"
                                                            } rounded !w-fit px-3 py-1 flex items-center space-x-2`}
                                                        >
                                                            <div
                                                                className={`h-2 w-2 ${
                                                                    workflow?.currentStatus?.toLowerCase() ===
                                                                        "delayed" ||
                                                                    (workflow?.currentStatus?.toLowerCase() ===
                                                                        "failed" &&
                                                                        "bg-red-nice")
                                                                }  ${
                                                                    workflow?.currentStatus?.toLowerCase() ===
                                                                        "active" && "bg-g-55"
                                                                } ${
                                                                    workflow?.currentStatus?.toLowerCase() ===
                                                                        "completed" && "bg-g-55"
                                                                } ${
                                                                    workflow?.currentStatus?.toLowerCase() ===
                                                                        "pending" && "bg-[#FF8A00]"
                                                                }   rounded-full`}
                                                            ></div>
                                                            <p className="text-[10px] font-rocGroteskMedium text-gm-50">
                                                                {workflow?.currentStatus}
                                                            </p>
                                                        </div>
                                                        <div className="bg-slate-50 !w-fit rounded px-3 py-1 flex items-center space-x-2">
                                                            <p className="text-[10px] font-rocGroteskMedium text-gm-35">
                                                                Run time:
                                                                {workflow?.currentStatus?.toLowerCase() ===
                                                                "completed"
                                                                    ? convertedSeconds
                                                                    : " N/A"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div class="flex items-center">
                                                <div class="w-6 h-6 rounded-full bg-green-500 ring-2 ring-white"></div>
                                                <div class="w-6 h-6 rounded-full bg-red-500 ring-2 ring-white -ml-0.5 "></div>
                                                <div class="w-6 h-6 rounded-full bg-green-500 ring-2 ring-white -ml-1 "></div>
                                            </div> */}
                                            </div>
                                        );
                                    })}
                                    <div
                                        className="flex w-fit items-center pt-7 cursor-pointer"
                                        onClick={() => navigate(`/dashboard/workflow`)}
                                    >
                                        <p className="text-gm-50 underline font-rocGroteskMedium text-sm">
                                            See all
                                        </p>
                                        <i className="ri-arrow-right-s-line"></i>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex py-[10%] px-10  space-y-4 flex-col justify-center items-center">
                                    <i className="ri-flow-chart text-[43px] bg-clip-text text-transparent bg-gradient-to-tr  from-logo-opacity from-1.2% to-logo-opacity-two to-91.14%"></i>
                                    <p className="text-gm-40 w-[30%] text-center font-rocGroteskMedium text-[13px]">
                                        Automate your operations process and cut down the time spent
                                        on manual, repetitive work
                                    </p>
                                    <Button
                                        btnText={"Create new workflow"}
                                        btnClassname="!py-2 !w-fit  !px-3 !text-[13px] !text-gm-50 !bg-transparent !border !border-slate-200 whitespace-nowrap"
                                        onClick={() =>
                                            navigate("/dashboard/workflow?createWorkflow=success")
                                        }
                                        isLoading={false}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <Loader />
                    )}
                </div>
            </div>
            {loading && (
                <ModalContainer
                    open={isAlmostExpired}
                    showCloseIcon={false}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[500px]"
                    closeModal={() => {
                        setIsAlmostExpired(false);
                    }}
                >
                    <div className="bg-white shadow-faintShadow !px-2 pt-6 pb-7  rounded-[12px]">
                        <div className="mt-4 px-6">
                            <div className="text-center flex justify-center flex-col items-center space-y-1">
                                <div className=" mb-6">
                                    <img
                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1707140954/Software_xcvcl6.svg"
                                        alt="warn-sign"
                                    />
                                </div>
                                <div>
                                    <h3 className="text-2xl text-black font-rocGroteskMedium mb-3 px-10">
                                        Your {subscriptionPlan?.trial ? "free trial" : "current"}{" "}
                                        plan would expire in {expiryDays} days!
                                    </h3>
                                    <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                        Your{" "}
                                        {subscriptionPlan?.trial
                                            ? "free trial"
                                            : subscriptionPlan?.plan?.category}{" "}
                                        subscription plan expires in {expiryDays} days, ensure your
                                        debit card is active and funded to keep orchestrating your
                                        supply chain seamlessly.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 w-full flex space-x-3">
                                <div className="w-full">
                                    <Button
                                        btnText={"Not now"}
                                        type={"button"}
                                        btnClassname={"!bg-n-20 !text-gm-50"}
                                        disabled={createStripeSession}
                                        onClick={() => {
                                            setIsAlmostExpired(false);
                                        }}
                                    />
                                </div>
                                {subscriptionPlan?.trial && (
                                    <div className="w-full">
                                        <Button
                                            btnClassname={"!py-3 !px-8"}
                                            onClick={onUpgradePlan}
                                            btnText={"Buy plan"}
                                            type={"button"}
                                            isLoading={createStripeSession}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}

            <ModalContainer
                open={openPoHistory}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[35%]"
                closeModal={() => {
                    setOpenPoHistory(false);
                }}
            >
                <div className="bg-white rounded-[8px]">
                    <div className="">
                        <div className="flex justify-between items-center rounded-t-[8px] py-5 px-8 bg-white shadow-wareHouseCardShadow">
                            <p className="text-lg text-slate-700 font-rocGroteskMedium">
                                Purchase order history
                            </p>
                            <i
                                onClick={() => setOpenPoHistory(false)}
                                className="ri-close-fill text-2xl cursor-pointer"
                            ></i>
                        </div>
                        <div className="p-8 max-h-[450px] overflow-y-auto space-y-[2px]">
                            {selectedPoHistory?.map((history, idx) => (
                                <div key={history?._id} className="flex items-start space-x-3">
                                    <div className="flex flex-col space-y-[2px] items-center">
                                        <div
                                            className={`flex items-center justify-center rounded-full bg-g-50 ${
                                                selectedPoHistory.length - 1 === idx && "!bg-orange"
                                            } w-6 h-6 `}
                                        >
                                            <i
                                                className={`${
                                                    selectedPoHistory.length - 1 === idx
                                                        ? "ri-hourglass-fill"
                                                        : "ri-checkbox-circle-fill"
                                                } text-[12px] text-white`}
                                            ></i>
                                        </div>
                                        {selectedPoHistory.length - 1 !== idx && (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="2"
                                                height="85"
                                                viewBox="0 0 2 85"
                                                fill="none"
                                            >
                                                <path
                                                    d="M1 0V85"
                                                    stroke="#E2E8F0"
                                                    stroke-width="2"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                    <div className="flex flex-col space-y-1 ">
                                        <p className="text-base font-rocGroteskMedium">
                                            {history?.status}
                                        </p>
                                        <div className="text-xs font-rocGroteskMedium flex items-center space-x-2">
                                            <span className="text-slate-400">By:</span>
                                            <div className="flex items-center space-x-1">
                                                <VendorAvatar
                                                    name={history?.ldb?.fullName}
                                                    imageSrc={history?.ldb?.avatar}
                                                    size={24}
                                                    textClassname={"!text-[10px]"}
                                                />
                                                <span className="text-slate-500">
                                                    {history?.ldb?.fullName}
                                                </span>
                                            </div>
                                        </div>
                                        <p className="text-[13px] font-rocGroteskMedium text-slate-500">
                                            Created:{" "}
                                            {moment(history?.eventTime)?.format(
                                                "MMMM DD, YYYY; LT"
                                            )}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={approveTaskOpen}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[40%]"
                closeModal={() => {
                    setApproveTaskOpen(false);
                }}
            >
                <div className="bg-white rounded-[8px]">
                    <div className="">
                        <div className="flex justify-end items-center rounded-t-[8px] py-5 px-8">
                            <i
                                onClick={() => setApproveTaskOpen(false)}
                                className="ri-close-fill text-2xl cursor-pointer"
                            ></i>
                        </div>
                        <div className="px-8 pb-8 text-center">
                            <p className="text-lg text-slate-700 font-rocGroteskMedium">
                                Approve Task
                            </p>
                            <p className="text-sm text-slate-500 font-rocGroteskMedium">
                                Are you sure you want to approve this task{" "}
                                <strong>{camelCaseToRegularCase(selectedTask?.name)}</strong>?
                            </p>
                        </div>
                        <div className="flex items-center justify-between space-x-3 px-8 py-3 border-t-[0.7px] border-slate-200">
                            <Button
                                onClick={() => setApproveTaskOpen(false)}
                                disabled={updateTask}
                                btnText="No, close"
                                btnClassname="!bg-n-20 !py-3 !px-4"
                            />
                            <Button
                                btnText="Yes, proceed"
                                btnClassname="!py-3 !px-4"
                                isLoading={updateTask}
                                disabled={updateTask}
                                onClick={() => handleApproveTask()}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={rejectTaskOpen}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[40%]"
                closeModal={() => {
                    setRejectTaskOpen(false);
                }}
            >
                <div className="bg-white rounded-[8px]">
                    <div className="">
                        <div className="flex justify-end items-center rounded-t-[8px] py-5 px-8">
                            <i
                                onClick={() => setRejectTaskOpen(false)}
                                className="ri-close-fill text-2xl cursor-pointer"
                            ></i>
                        </div>
                        <div className="px-8 pb-8 text-center">
                            <p className="text-lg text-slate-700 font-rocGroteskMedium">
                                Reject Task
                            </p>
                            <p className="text-sm text-slate-500 font-rocGroteskMedium">
                                Are you sure you want to reject this task{" "}
                                <strong>{camelCaseToRegularCase(selectedTask?.name)}</strong>?
                            </p>
                        </div>
                        <div className="flex items-center justify-between space-x-3 px-8 py-3 border-t-[0.7px] border-slate-200">
                            <Button
                                onClick={() => setRejectTaskOpen(false)}
                                btnText="No, close"
                                btnClassname="!bg-n-20 !py-3 !px-4"
                                disabled={markingActionAsRejected}
                            />
                            <Button
                                btnText="Yes, proceed"
                                btnClassname="!py-3 !px-4 "
                                isLoading={markingActionAsRejected}
                                disabled={markingActionAsRejected}
                                onClick={() => handleRejectTask()}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default DashboardHome;
