import React, { useCallback, useEffect, useState } from "react";
import ModalContainer from "components/common/ModalContainer";
import CustomTable from "components/common/CustomTable";
import Checkbox from "components/common/InputField/Checkbox";
import Button from "components/common/Button";
import VendorAvatar from "components/common/VendorAvatar";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import { onScroll } from "helpers";
import Loader from "components/common/Loader";
import { truncate } from "lodash";

type Props = {
    productSearch: string;
    filter: string;
};
const ProductList = ({ productSearch, filter }: Props) => {
    const limit = 20;
    const [warehouseLimit, setWarehouseLimit] = useState(limit);
    const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
    const [warehouseList, setWarehouseList] = useState<any>([]);
    const [loadMoreWarehouse, setLoadMoreWarehouse] = useState<boolean>(false);
    const [warehousePagination, setWarehousePagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const dashboardContent = document.getElementById("dashboardContent");

    const {
        fetchingListStockWarehouse,
        fetchedListStockWarehouseSuccess,
        fetchedListStockWarehouseFailure,
    } = useAppSelector((state) => state.inventory);

    useEffect(() => {
        dispatch(
            inventoryActions.fetchListStockWarehouse(id, warehouseLimit, "", productSearch, filter)
        );
    }, [id, warehouseLimit, productSearch, filter]);

    useEffect(() => {
        if (Boolean(fetchedListStockWarehouseSuccess)) {
            setWarehouseList(fetchedListStockWarehouseSuccess?.products);
            setWarehousePagination({
                current: fetchedListStockWarehouseSuccess?.pagination?.current,
                number_of_pages: fetchedListStockWarehouseSuccess?.pagination?.number_of_pages,
            });
            setIsPageLoaded(true);
        }
    }, [fetchedListStockWarehouseSuccess]);

    useEffect(() => {
        if (fetchedListStockWarehouseFailure !== "") {
            setIsPageLoaded(true);
        }
    }, [fetchedListStockWarehouseFailure]);

    const tableHeader = [
        { title: "Product name", widthClass: "w-[25%]" },
        { title: "Product Category", widthClass: "w-[25%]" },
        { title: "Batch no.", widthClass: "w-[20%]" },
        { title: "Stock level", widthClass: "w-[15%]" },
        { title: "UOM", widthClass: "w-[12%]" },
        { title: "SKU", widthClass: "w-[12%]" },
        { title: "Vendor/Supplier", widthClass: "w-[25%]" },
    ];

    const onWarehouseScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                warehousePagination?.current as number,
                warehousePagination?.number_of_pages as number,
                () => {
                    setWarehouseLimit(() => warehouseLimit + limit);
                    setLoadMoreWarehouse(true);
                }
            ),
        [dashboardContent, warehousePagination, warehouseLimit]
    );

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onWarehouseScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onWarehouseScroll);
        };
    }, [dashboardContent, onWarehouseScroll]);

    const tableBody = warehouseList?.map((item) => [
        {
            content: (
                <div
                    key={item?._id}
                    className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-2">
                        <VendorAvatar
                            name="05"
                            imageSrc={item?.inventory?.productImageDetails?.productAvatar}
                            size={32}
                            containerClassname="!rounded-none !border !bg-white"
                        />

                        <span>{truncate(item?.inventory?.productName, { length: 20 })}</span>
                    </div>
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {truncate(item?.inventory?.inventoryCategory, { length: 20 })}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {item?.batchId}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {item?.productStockDetails?.stockLevel}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {item?.inventory?.productStockDetails?.unitOfMeasurement}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {item?.inventory?.sku}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-2">
                        <VendorAvatar
                            name={item?.vendor?.profile?.businessName}
                            imageSrc={item?.vendor?.profile?.avatar}
                            size={32}
                            containerClassname="!rounded-[4px]"
                        />

                        <span>{item?.vendor?.profile?.businessName}</span>
                    </div>
                </div>
            ),
            widthClass: "!border-b",
        },
    ]);

    return (
        <div className="overflow-x-scroll w-full">
            {!isPageLoaded ? (
                <Loader />
            ) : (
                <>
                    {warehouseList?.length > 0 ? (
                        <>
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isAllSelectable={false}
                                isCellBordered={false}
                                headerContainerClass="!bg-slate-50  !border-slate-100 "
                                bodyItemClass="hover:border-slate-100 !border-b hover:bg-slate-50 "
                                headerItemClass="font-rocGroteskMedium !text-slate-700"
                            />

                            {loadMoreWarehouse && fetchingListStockWarehouse && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {warehousePagination?.current ===
                                warehousePagination?.number_of_pages && (
                                <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="flex items-center justify-center mt-[76px]">
                            <div>
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1704890969/Tradeally_2.0/empty_state_img_a8twwl.svg"
                                    alt="empty state"
                                    className="w-[268px] h-[235px]"
                                />
                                <p className="text-center text-sm text-[#475569] font-rocGroteskMedium mt-5">
                                    You have no product
                                </p>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ProductList;
