import Button from "components/common/Button";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SelectInput from "components/common/InputField/SelectInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import AddressInput from "components/common/InputField/AddressInput";
import CustomTable from "components/common/CustomTable";
import VendorAvatar from "components/common/VendorAvatar";
import ModalContainer from "components/common/ModalContainer";
import { onScroll } from "helpers";
import { authActions, inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { useNavigate, useParams } from "react-router-dom";
import PageFrame from "components/layout/PageFrame";
import { debounce } from "lodash";
import Loader from "components/common/Loader";
import { getEditPermissionList } from "helpers/getEditPermissionList";

interface Address {
    lat: any;
    lng: any;
    address: any;
    details: any[];
    country: any;
}

interface Body {
    address: Address;
    contactPersonId: any;
    warehouseName?: any;
    additionalNotes: any;
}

const EditWarehouse = () => {
    const limit = 10;
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [address, setAddress] = useState<{ [key: string]: any }>({});
    const [removeProduct, setRemoveProduct] = useState<boolean>(false);
    const [editWarehouseSuccess, setEditWarehouseSuccess] = useState<boolean>(false);
    const [warehouseList, setWarehouseList] = useState<any>([]);
    const [loadMoreWarehouse, setLoadMoreWarehouse] = useState(false);
    const [warehouseLimit, setWarehouseLimit] = useState(limit);
    const [teamList, setTeamList] = useState([]);
    const [warehousePagination, setWarehousePagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [productDetails, setProductDetails] = useState<{
        [key: string]: any;
    }>({
        warehouseName: "",
        contactPerson: "",
        additionalNotes: "",
    });
    const [debouncedSearch, setDebouncedSearch] = useState({
        search: "",
    });
    const {
        fetchingSingleWarehouseList,
        fetchedSingleWarehouseListSuccess,
        updateWarehouse,
        updateWarehouseSuccess,
    } = useAppSelector((state) => state.inventory);
    const { fetchingTeamMember, fetchedTeamMemberSuccess } = useAppSelector((state) => state.auth);

    const inputRef = useRef(null);

    useEffect(() => {
        (inputRef?.current as any)?.focus();
    }, []);
    const dashboardContent = document.getElementById("custom-table");

    const onWarehouseScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                warehousePagination?.current as number,
                warehousePagination?.number_of_pages as number,
                () => {
                    setWarehouseLimit(() => warehouseLimit + limit);
                    setLoadMoreWarehouse(true);
                }
            ),
        [dashboardContent, warehousePagination, warehouseLimit]
    );
    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onWarehouseScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onWarehouseScroll);
        };
    }, [dashboardContent, onWarehouseScroll]);

    useEffect(() => {
        dispatch(inventoryActions.fetchSingleWarehouseList(id, warehouseLimit));
    }, [id, warehouseLimit]);
    useEffect(() => {
        dispatch(authActions.getTeamMembers(20, debouncedSearch.search));
    }, [dispatch, debouncedSearch]);
    console.log("====", warehouseList);
    useEffect(() => {
        if (Boolean(fetchedSingleWarehouseListSuccess)) {
            setWarehouseList(fetchedSingleWarehouseListSuccess?.warehouses);
            setProductDetails((prev) => ({
                ...prev,
                warehouseName: fetchedSingleWarehouseListSuccess?.warehouses?.warehouseName,
                contactPerson: fetchedSingleWarehouseListSuccess?.warehouses?.contactPerson?._id,
                additionalNotes: fetchedSingleWarehouseListSuccess?.warehouses?.additionalNotes,
            }));
            setAddress({
                address: fetchedSingleWarehouseListSuccess?.warehouses?.address?.address,
                lat: fetchedSingleWarehouseListSuccess?.warehouses?.address?.lng,
                lng: fetchedSingleWarehouseListSuccess?.warehouses?.address?.lat,
                details: [fetchedSingleWarehouseListSuccess?.warehouses?.address?.details?.[0]],
                country: "Nigeria",
            });
            setWarehousePagination({
                current: fetchedSingleWarehouseListSuccess?.pagination?.current,
                number_of_pages: fetchedSingleWarehouseListSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedSingleWarehouseListSuccess]);
    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamList(fetchedTeamMemberSuccess?.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    const tableHeader = [
        { title: "Product name", widthClass: "w-[25%]" },
        { title: "Product Category", widthClass: "w-[25%]" },
        { title: "Batch no.", widthClass: "w-[20%]" },
        { title: "Stock level", widthClass: "w-[15%]" },
        { title: "UOM", widthClass: "w-[12%]" },
        { title: "SKU", widthClass: "w-[12%]" },
        { title: "Vendor/Supplier", widthClass: "w-[25%]" },
        { title: "", widthClass: "w-[5%]" },
    ];

    const tableBody = warehouseList?.products?.map((item, idx) => [
        {
            content: (
                <div
                    key={item?._id}
                    className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-2">
                        <VendorAvatar
                            name={item?.productName}
                            imageSrc={item?.productImageDetails?.productAvatar}
                            size={32}
                            containerClassname="!rounded-none !border !bg-white"
                        />

                        <span>{item?.productName}</span>
                    </div>
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {item?.inventoryCategory}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    98765432
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    50
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    pcs
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {item?.sku}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-2">
                        <VendorAvatar
                            name="05"
                            imageSrc=""
                            size={32}
                            containerClassname="!rounded-[4px]"
                        />

                        <span>Logitech</span>
                    </div>
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm  font-rocGroteskMedium `}
                    onClick={() => setRemoveProduct(true)}
                >
                    <i className="ri-arrow-left-right-fill text-gm-50 text-sm"></i>
                </div>
            ),
            widthClass: "!border-b !border-l",
        },
    ]);

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setProductDetails((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };
    const countryComponent = address?.Location?.details?.[0]?.address_components.find((component) =>
        component.types.includes("country")
    );
    const onEditWarehouse = (e) => {
        e.preventDefault();
        const body: Body = {
            address: {
                lat: address?.Location?.lng ? address?.Location?.lng : address?.lng,
                lng: address?.Location?.lat ? address?.Location?.lat : address?.lat,
                address: address?.Location?.address ? address?.Location?.address : address?.address,
                details: [
                    address?.Location?.details?.[0]?.formatted_address
                        ? address?.Location?.details?.[0]?.formatted_address
                        : address?.details?.[0],
                ],
                country: countryComponent?.long_name
                    ? countryComponent?.long_name
                    : address?.country,
            },
            contactPersonId: productDetails?.contactPerson,

            additionalNotes: productDetails?.additionalNote,
        };
        if (
            fetchedSingleWarehouseListSuccess?.warehouses?.warehouseName !==
            productDetails?.warehouseName
        ) {
            body.warehouseName = productDetails?.warehouseName;
        }
        dispatch(inventoryActions.updateWarehouse(id, body));
    };
    useEffect(() => {
        if (Boolean(updateWarehouseSuccess)) {
            dispatch(inventoryActions.resetUpdateWarehouse());
            setEditWarehouseSuccess(true);
        }
    }, [updateWarehouseSuccess]);
    return (
        <PageFrame
            isLoading={fetchingSingleWarehouseList && warehouseList?.length === 0}
            containerClassName={""}
        >
            <form onSubmit={(e) => onEditWarehouse(e)}>
                <div className="">
                    <div className="bg-white rounded-lg border border-slate-100">
                        <div className="h-[450px]">
                            <div className="flex bg-white top-[-2px] md:sticky z-[40] items-center justify-between px-8 py-5 border-b border-slate-100">
                                <p className="text-base font-rocGroteskMedium">Edit warehouse</p>
                                <div className="flex items-center gap-3">
                                    <Button
                                        btnText="Cancel"
                                        onClick={() => navigate("/dashboard/inventory/warehouse")}
                                        disabled={updateWarehouse}
                                        btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                                    />
                                    <Button
                                        btnText="Save"
                                        btnClassname="!px-4 !py-2.5 !w-fit"
                                        type="submit"
                                        isLoading={updateWarehouse}
                                        permissions={getEditPermissionList("inventory")}
                                    />
                                </div>
                            </div>
                            <div className="px-8 py-6 flex gap-[7.7%]">
                                <div className="w-full">
                                    <div className="mb-6">
                                        <div className="flex items-center w-full justify-between mb-4">
                                            <p className="text-sm font-rocGroteskMedium">
                                                Warehouse name <span className="text-r-50">*</span>
                                            </p>
                                        </div>
                                        <div className="border-b-[1px] border-b-[#E2E8F0] pb-5">
                                            <input
                                                type="text"
                                                placeholder="Enter a warehouse name"
                                                className="!text-2xl font-rocGroteskMedium  !border-none outline-none w-full placeholder:pl-1 text-slate-700 !leading-6"
                                                ref={inputRef}
                                                name="warehouseName"
                                                value={productDetails?.warehouseName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col  gap-6">
                                        <div className="grid grid-cols-2 w-[70%]  items-center gap-4">
                                            <SelectInput
                                                value={productDetails?.contactPerson}
                                                name="contactPerson"
                                                placeholder={"Contact Person"}
                                                handleChange={(name, value) => {
                                                    handleChange({ target: { name, value } });
                                                }}
                                                handleInputChange={debounce((evt) => {
                                                    setDebouncedSearch({
                                                        search: evt.target.value,
                                                    });
                                                }, 800)}
                                                disabled={false}
                                                inputClassName="!text-gm-50 !font-rocGroteskMedium"
                                                searchLoading={fetchingTeamMember}
                                                isRequired={true}
                                                dropdownOptions={teamList?.map((team) => ({
                                                    label: team?.email,
                                                    value: team?._id,
                                                }))}
                                            />
                                            <AddressInput
                                                setData={setAddress}
                                                savedAddress={address?.address}
                                                placeholder="Location"
                                                required={true}
                                                name={"Location"}
                                                classNames="!text-gm-50 !font-rocGroteskMedium"
                                            />
                                        </div>
                                        <div className="w-[70%]">
                                            <TextareaInput
                                                name={"additionalNotes"}
                                                value={productDetails?.additionalNotes}
                                                onChange={handleChange}
                                                rows={5}
                                                placeholder={"Additional Note"}
                                                required={true}
                                                containerClassname="!text-gm-50 !font-rocGroteskMedium"
                                                inputClassName="!text-gm-50 !font-rocGroteskMedium"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pt-4 px-8">
                            <p className="text-gm-50 mb-4 font-rocGroteskMedium text-base">
                                All products to warehouse
                            </p>
                            <div
                                className={`${
                                    warehouseList?.products?.length > 0
                                } w-full mt-5  mb-5`}
                                id="custom-table"
                            >
                                {warehouseList?.products?.length > 0 ? (
                                    <>
                                        <CustomTable
                                            tableBody={tableBody}
                                            tableHeader={tableHeader}
                                            isAllSelectable={false}
                                            isScrollable={true}
                                            isCellBordered={false}
                                            headerContainerClass="!bg-slate-50  !border-slate-100 "
                                            bodyItemClass="hover:border-slate-100 !border-b hover:bg-slate-50 "
                                            headerItemClass="font-rocGroteskMedium !text-slate-700"
                                        />
                                        {loadMoreWarehouse && fetchingSingleWarehouseList && (
                                            <div className="flex my-4 justify-center">
                                                <Loader color="gm-25" size={4} />
                                            </div>
                                        )}
                                        {warehousePagination?.current ===
                                            warehousePagination?.number_of_pages && (
                                            <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                                End of list
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="flex items-center justify-center mt-[76px]">
                                        <div>
                                            <img
                                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1704890969/Tradeally_2.0/empty_state_img_a8twwl.svg"
                                                alt="empty state"
                                                className="w-[268px] h-[235px]"
                                            />
                                            <p className="text-center text-sm text-[#475569] font-rocGroteskMedium mt-5">
                                                You have no product
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {removeProduct && (
                                <ModalContainer
                                    open={removeProduct}
                                    showCloseIcon={false}
                                    modalClassName={"py-10 px-10"}
                                    tailwindClassName="w-[600px]"
                                    closeModal={() => {
                                        setRemoveProduct(false);
                                    }}
                                >
                                    <div className="bg-white py-7 px-6  shadow-cardShadow relative rounded-[8px]">
                                        <div className="border-[2px] h-[50px] bg-[#FEF2F2] w-[50px] flex items-center justify-center rounded-full border-[#DC2626]">
                                            <i className="ri-delete-bin-line text-[#DC2626] text-[26px]"></i>
                                        </div>
                                        <div className="mb-5 mt-4">
                                            <h1 className="text-lg mb-2  font-rocGroteskMedium">
                                                Remove product from warehouse?
                                            </h1>
                                            <p className="text-slate-500 font-rocGroteskMedium text-base">
                                                Are you certain you want to remove this product from
                                                the warehouse? This action will dissociate the
                                                product from the selected warehouse.
                                            </p>
                                        </div>
                                        <div className="flex justify-end items-center ">
                                            <div className="flex items-center !whitespace-nowrap space-x-2 ">
                                                <Button
                                                    btnText={"No, don't transfer"}
                                                    type={"button"}
                                                    btnClassname={"!bg-n-20 !w-fit !text-gm-50"}
                                                    disabled={false}
                                                    onClick={() => setRemoveProduct(false)}
                                                />
                                                <Button
                                                    btnClassname={
                                                        "!py-3 !bg-n-20 !text-gm-50  !w-fit"
                                                    }
                                                    onClick={() => {}}
                                                    btnText={"Remove product"}
                                                    type={"button"}
                                                    isLoading={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ModalContainer>
                            )}
                            {editWarehouseSuccess && (
                                <ModalContainer
                                    open={editWarehouseSuccess}
                                    showCloseIcon={false}
                                    modalClassName={"py-10 px-10"}
                                    tailwindClassName="w-[600px]"
                                    closeModal={() => {
                                        setProductDetails({
                                            warehouseName: "",
                                            contactPerson: "",
                                            additionalNote: "",
                                        });
                                        setAddress({});
                                        setEditWarehouseSuccess(false);
                                        navigate("/dashboard/inventory/warehouse");
                                    }}
                                >
                                    <div className="bg-white py-7 px-6  shadow-cardShadow relative rounded-[8px]">
                                        <div className="mb-5">
                                            <div className="w-10 h-10 flex justify-center mb-4 items-center bg-[#00DB8F] rounded-full  shadow-lightGreenShadow">
                                                <i className="ri-checkbox-circle-fill text-[29px] text-white "></i>
                                            </div>
                                            <h1 className="text-lg mb-2  font-rocGroteskMedium">
                                                Warehouse Updated Successfully
                                            </h1>
                                            <p className="text-slate-500 font-rocGroteskMedium  text-base">
                                                The warehouse has been updated to the system
                                                successfully. Ensure all details are accurate for
                                                seamless operations.
                                            </p>
                                        </div>
                                        <div className="flex justify-end items-center ">
                                            <div className="flex items-center !whitespace-nowrap space-x-2 ">
                                                <Button
                                                    btnText={"No, don't transfer"}
                                                    type={"button"}
                                                    btnClassname={
                                                        "!bg-white !border !border-[#F4F5F7]!w-fit !text-gm-50"
                                                    }
                                                    disabled={false}
                                                    onClick={() => {
                                                        setProductDetails({
                                                            warehouseName: "",
                                                            contactPerson: "",
                                                            additionalNote: "",
                                                        });
                                                        setAddress({});
                                                        setEditWarehouseSuccess(false);
                                                        navigate("/dashboard/inventory/warehouse");
                                                    }}
                                                />
                                                <Button
                                                    btnClassname={"!py-3 !text-gm-50  !w-fit"}
                                                    onClick={() =>
                                                        navigate("/dashboard/inventory/warehouse")
                                                    }
                                                    btnText={"View warehouse"}
                                                    type={"button"}
                                                    isLoading={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ModalContainer>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </PageFrame>
    );
};

export default EditWarehouse;
