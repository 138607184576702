import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CustomMobileTable from "components/common/CustomMobileTable";
import CustomTable from "components/common/CustomTable";
import CustomToast from "components/common/CustomToast";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { displayFileIcon, formatMoney, getCurrencyFromCurrencyCode, onScroll } from "helpers";
import { isFileSizeLessThanOrEqualTo } from "helpers/compareFileSizeInMb";
import { convertBytes } from "helpers/displayFileSize";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useGetUserProfile from "hooks/useGetUserProfile";
import { debounce, truncate } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { chatActions, orderActions } from "redux/Ldb/actions";
import { firebaseService } from "services/firebaseService";

const RfqList = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const limit = 20;
    const [rfqLimit, setRfqLimit] = useState(limit);
    const [rfqPagination, setRfqPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [loadMoreRfqs, setLoadMoreRfqs] = useState(false);
    const [rfqs, setRfqs] = useState([]);
    const [filter, setFilter] = useState({
        search: "",
        amount: "",
        vendor: "",
        orderId: "",
    });
    const [debouncedFilter, setDebouncedFilter] = useState({
        search: "",
        amount: "",
        vendor: "",
        orderId: "",
    });
    const [profile] = useGetUserProfile();
    const [docData, setDocData] = useState<File | null>(null);
    const [attachDocModal, setAttachDocModal] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [docUrl, setDocUrl] = useState({
        name: "",
        url: "",
    });
    const dashboardContent = document.getElementById("dashboardContent");
    const { creatingConversation, createConversationSuccess } = useAppSelector(
        (state) => state.chat
    );
    const { fetchingRfqs, fetchedRfqsSuccess } = useAppSelector((state) => state.order);

    const handleCreateConversation = () => {
        dispatch(
            chatActions.createConversation({
                title: "RFQ Creation",
                receiverType: "co-pilot",
                conversationType: "CoPilotRFQ",
            })
        );
    };

    const handleUpload = (fileName: string, file: File) => {
        if (fileName && file) {
            setUploading(true);
            // setDocData(file);
            firebaseService.uploadFile(
                `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/ai-conversation/${
                    profile?.profile?.businessName
                }/rfqs/${fileName}`,
                file,
                (url) => {
                    setDocUrl({
                        name: fileName,
                        url: url,
                    });
                    handleCreateConversation();
                    setUploading(false);
                }
            );
        }
    };

    useEffect(() => {
        if (Boolean(createConversationSuccess)) {
            navigate(
                `/dashboard/rfqs/creates/ai?conversationId=${createConversationSuccess?.conversation?._id}`,
                {
                    replace: true,
                    state: { doc: docUrl?.url, docName: docUrl?.name },
                }
            );
            dispatch(chatActions.resetCreateConversationSuccess());
        }
    }, [dispatch, navigate, createConversationSuccess, docUrl]);

    const tableHeader = [
        { title: "RFQ ID", widthClass: "w-[100px]" },
        { title: "RFQ Description", widthClass: "w-[268px]" },
        { title: "Supplier", widthClass: "w-[244px]" },
        { title: "Customer", widthClass: "w-[172px]" },
        { title: "Budget", widthClass: "w-[140px]" },
        { title: "Status", widthClass: "w-[140px]" },
        { title: "Date created", widthClass: "w-[165px]" },
        { title: "", widthClass: "w-[60px]" },
    ];
    const handleDebouncedChange = debounce((e) => {
        const { name, value } = e.target;

        setDebouncedFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, 1500);

    const handleSearch = (e) => {
        const { name, value } = e.target;

        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const onRfqTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                rfqPagination?.current as number,
                rfqPagination?.number_of_pages as number,
                () => {
                    setRfqLimit(() => rfqLimit + limit);
                    setLoadMoreRfqs(true);
                }
            ),
        [dashboardContent, rfqPagination]
    );

    useEffect(() => {
        dispatch(
            orderActions.getRfqs(
                rfqLimit,
                debouncedFilter.search,
                debouncedFilter.vendor,
                debouncedFilter.orderId,
                debouncedFilter.amount
            )
        );
        setTimeout(() => {
            setLoadMoreRfqs(false);
        }, 800);
    }, [dispatch, debouncedFilter, rfqLimit]);

    useEffect(() => {
        if (Boolean(fetchedRfqsSuccess)) {
            setRfqs(fetchedRfqsSuccess?.rfqs);
            setRfqPagination({
                current: fetchedRfqsSuccess?.pagination?.current,
                number_of_pages: fetchedRfqsSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedRfqsSuccess]);

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onRfqTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onRfqTableScroll);
        };
    }, [dashboardContent, onRfqTableScroll]);
    console.log("rfqmmxss", rfqs);
    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if ((e.target as any).scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const tableBody = rfqs?.map((rfq) => [
        {
            content: (
                <div
                    className={`py-3 pr-3  border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    <p className="text-slate-900 ">RFQ {rfq?.taId}</p>
                </div>
            ),
            widthClass: "!border-b !border-slate-200",
            cellClickAction: () => {
                navigate(`/dashboard/rfq/${rfq?._id}`);
            },
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    <p className="text-slate-900 "> {rfq?.order?.specifications}</p>
                </div>
            ),
            widthClass: "!border-b !border-slate-200",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    <div className="flex items-center gap-3">
                        <VendorAvatar
                            size={32}
                            imageSrc={rfq?.vendor?.profile?.avatar}
                            name={rfq?.vendor?.profile?.businessName ?? rfq?.vendor?.email}
                            containerClassname="!rounded-[6px]"
                        />

                        <span>
                            {truncate(rfq?.vendor?.profile?.businessName ?? rfq?.vendor?.email, {
                                length: 15,
                            })}
                        </span>
                    </div>
                </div>
            ),
            widthClass: "!border-b !border-slate-200",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    <p>{rfq?.order?.customerDetails?.companyName}</p>
                </div>
            ),
            widthClass: "!border-b !border-slate-200",
        },

        {
            content: (
                <div
                    className={`py-3 pr-3  flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-900 `}
                >
                    {getCurrencyFromCurrencyCode(rfq?.order?.budget?.currency)}
                    {formatMoney()?.format(rfq?.order?.budget?.amount ?? 0)}
                </div>
            ),
            widthClass: "!border-b !border-slate-200",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3  space-x-1.5 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-900 `}
                >
                    <div
                        className={`h-2 w-2 rounded-full ${
                            rfq?.currentStatus?.toLowerCase() === "pending" && "bg-[#F59E0B]"
                        }  ${rfq?.currentStatus?.toLowerCase() === "published" && "bg-[#005ECD]"} 
                        ${rfq?.currentStatus?.toLowerCase() === "draft" && "bg-[#CBD5E1]"}
                        ${
                            rfq?.currentStatus?.toLowerCase() === "approved" ||
                            (rfq?.currentStatus?.toLowerCase() === "accepted" && "bg-[#16C6A4]")
                        }
                        ${rfq?.currentStatus?.toLowerCase() === "rejected" && "bg-[#FF3347]"}
                        
                        `}
                    ></div>
                    <p className="text-sm ">{rfq?.currentStatus}</p>
                </div>
            ),
            widthClass: "!border-b !border-slate-200",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3  font-rocGroteskMedium flex items-center h-full border-slate-100 text-sm text-slate-900 `}
                >
                    {moment(rfq?.createdAt).format("MMM DD, YYYY")}
                </div>
            ),
            widthClass: "!border-b !border-slate-200",
        },
        {
            content: (
                <div
                    onClick={(e) => e.stopPropagation()}
                    className={`py-3 flex justify-center w-[82%]  items-center h-full border-slate-100 text-sm text-slate-900 `}
                >
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownContainerClasses={`shadow-cardShadow-2 left-[-100px]  border-0 rounded !max-h-fit`}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "Show more",
                                value: "empty",
                                action: () => {
                                    console.log("action");
                                },
                            },
                        ]}
                    />
                </div>
            ),
            widthClass: "!border-b !border-slate-200",
        },
    ]);

    const mobileTableBody = rfqs?.map((rfq, idx) => {
        return {
            cellClickAction: () => {
                navigate(`/dashboard/rfq/${rfq?._id}`);
            },
            topString: "RFQ" + rfq?.taId,
            topContent: <p className="text-sm font-rocGroteskMedium mb-1.5">RFQ {rfq?.taId}:</p>,
            bottomContent: (
                <div className="flex items-center gap-3">
                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                        {truncate(rfq?.item?.description, { length: 15 }) || "----"}
                    </p>
                    <p className="flex items-center gap-2 text-sm font-rocGroteskMedium">
                        <VendorAvatar
                            name={rfq?.order?.customerDetails?.companyName}
                            imageSrc={rfq?.order?.customerDetails?.avatar}
                            size={24}
                        />
                        <span>
                            {truncate(rfq?.order?.customerDetails?.companyName, { length: 15 })}
                        </span>
                    </p>
                </div>
            ),
            rightIcon: <i className="ri-arrow-right-s-line text-2xl"></i>,
        };
    });

    return (
        <PageFrame containerClassName={"!h-[calc(100vh-108px)]"} isLoading={false}>
            <div>
                <div className="px-[14px] overflow-y-auto max-lg:px-0">
                    <div className="mb-4">
                        <BreadCrumb parentCrumb={"Request for Quotation (RFQs)"} size="large" />
                    </div>
                    <div>
                        <div>
                            <div className="flex items-center justify-between mb-[27px] max-lg:flex-col max-lg:gap-2.5">
                                <div className="flex items-center gap-3 max-lg:w-full">
                                    <TextInput
                                        name={"search"}
                                        value={filter.search}
                                        type={"text"}
                                        inputPlaceholder={"Search by RFQ id (e.g 00014)"}
                                        inputClassName={"!h-[40px] pl-[0px] !mb-0 text-sm "}
                                        onChange={handleSearch}
                                        onInput={handleDebouncedChange}
                                        leftIcon={
                                            <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                        }
                                        inputContainerClassname={
                                            "!rounded-[4px] !w-[428px] max-lg:!w-full !h-[40px] !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                                        }
                                    />
                                </div>
                                <div className="max-lg:w-full flex space-x-2 items-center ">
                                    <Button
                                        icon={<i className="ri-settings-5-line"></i>}
                                        btnClassname="!py-2 !bg-white !border !border-[#F4F5F7] !shadow-settinsBtnShadow !text-gm-50"
                                        btnText="Settings"
                                        onClick={() => navigate(`/dashboard/rfqs/settings`)}
                                    />
                                    <Dropdown
                                        value={""}
                                        dropdown={
                                            <div className="flex items-center cursor-pointer gap-2 py-1.5 px-3 bg-white rounded shadow-boxShadow-2 border border-n-20">
                                                <i className="ri-filter-3-fill"></i>
                                                <p className="text-[13px] font-rocGroteskMedium">
                                                    Filter
                                                </p>
                                            </div>
                                        }
                                        dropdownContainerClasses={
                                            " left-[-234px] !py-0 top-[49px] shadow-dropdownShadow rounded !border-0"
                                        }
                                        dropdownClassName={"!max-h-fit"}
                                        name={"quickAction"}
                                        dropdownOptions={[
                                            {
                                                customChild: (
                                                    <div className="w-[318px] relative  bg-white p-4 shadow-boxShadow-2 rounded border border-n-20">
                                                        <div className="flex relative flex-col h-fit gap-2">
                                                            {/* <TextInput
                                                                name={"orderId"}
                                                                type={"text"}
                                                                value={filter.orderId}
                                                                placeholder={"Order ID"}
                                                                onChange={handleSearch}
                                                                onInput={handleDebouncedChange}
                                                                required={false}
                                                            />

                                                            <TextInput
                                                                name={"amount"}
                                                                type={"number"}
                                                                onWheel={(e) => e.target.blur()}
                                                                value={filter.amount}
                                                                placeholder={"Amount"}
                                                                onKeyDown={(evt) =>
                                                                    [
                                                                        "e",
                                                                        "E",
                                                                        "+",
                                                                        "-",
                                                                        "ArrowUp",
                                                                        "ArrowDown",
                                                                    ].includes(evt.key) &&
                                                                    evt.preventDefault()
                                                                }
                                                                onChange={handleSearch}
                                                                onInput={handleDebouncedChange}
                                                                required={false}
                                                            />
                                                            <TextInput
                                                                name={"vendor"}
                                                                type={"text"}
                                                                value={filter.vendor}
                                                                placeholder={"Vendor"}
                                                                onChange={handleSearch}
                                                                onInput={handleDebouncedChange}
                                                                required={false}
                                                            /> */}
                                                        </div>
                                                    </div>
                                                ),
                                            },
                                        ]}
                                    />
                                    <div className="group relative w-fit">
                                        <Button
                                            btnText={"Create RFQs"}
                                            type={"button"}
                                            onClick={() => {}}
                                            disabled={true}
                                            btnClassname="!py-2 !px-4"
                                        />
                                        <div className="bg-white border absolute z-[1000] w-fit whitespace-nowrap  right-1 top-[38px] border-slate-100 hidden group-hover:block">
                                            <div
                                                className="text-gm-50 flex items-center space-x-3 py-[10px] px-4 hover:cursor-pointer hover:bg-tradeally-neutral-20"
                                                onClick={() => navigate("/dashboard/rfqs/create")}
                                            >
                                                <i className="ri-file-line"></i>
                                                <p className="text-sm font-rocGroteskMedium">
                                                    Create a blank RFQ
                                                </p>
                                            </div>
                                            <div
                                                className="text-gm-50 flex items-center space-x-3 py-[10px] px-4 hover:cursor-pointer hover:bg-tradeally-neutral-20"
                                                onClick={() => {
                                                    setAttachDocModal(true);
                                                }}
                                            >
                                                <i className="ri-file-upload-line"></i>
                                                <p className="text-sm font-rocGroteskMedium">
                                                    Import Document
                                                </p>
                                            </div>
                                            <div
                                                className="text-gm-50 w-full flex items-center space-x-3 py-[10px] px-4 hover:cursor-pointer hover:bg-tradeally-neutral-20"
                                                onClick={() => handleCreateConversation()}
                                            >
                                                {creatingConversation && !attachDocModal ? (
                                                    <div className="flex w-full justify-center items-center">
                                                        <Loader />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <i className="ri-sparkling-fill"></i>
                                                        <p className="text-sm font-rocGroteskMedium">
                                                            Generate with AI
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {rfqs?.length > 0 ? (
                            <div>
                                <div
                                    className="overflow-x-scroll max-lg:hidden"
                                    onScroll={handleScroll}
                                >
                                    <CustomTable
                                        tableBody={tableBody}
                                        tableHeader={tableHeader}
                                        isAllSelectable={false}
                                        isScrollable={true}
                                        isScrolled={isScrolled}
                                        isCellBordered={false}
                                        headerContainerClass="!bg-slate-50 !border-slate-100 "
                                        bodyItemClass="hover:border-slate-100  !border-b hover:bg-slate-50 "
                                        headerItemClass="font-rocGroteskMedium !text-slate-700 "
                                    />
                                </div>
                                <div className="hidden max-lg:block">
                                    <CustomMobileTable data={mobileTableBody} />
                                </div>
                                {loadMoreRfqs && fetchingRfqs && (
                                    <div className="flex my-4 justify-center">
                                        <Loader color="gm-25" size={4} />
                                    </div>
                                )}
                                {rfqPagination?.current === rfqPagination?.number_of_pages && (
                                    <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                        End of list
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                                <div className="space-y-2  text-center">
                                    <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                                    <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                        No RFQs yet
                                    </p>
                                    <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                        Your list of RFQs will be displayed here
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ModalContainer
                open={attachDocModal}
                showCloseIcon={false}
                closeModal={() => {
                    setDocData(null);
                    setAttachDocModal(false);
                }}
                tailwindClassName="w-[35%] max-lg:w-[95%]"
            >
                <div className="bg-white flex flex-col rounded-[20px]">
                    <form className="bg-white shadow-cardShadow relative rounded-[20px] py-8 ">
                        <div
                            onClick={() => {
                                setDocData(null);
                                setAttachDocModal(false);
                            }}
                            className="cursor-pointer  px-6 flex justify-end "
                        >
                            <i
                                onClick={() => {
                                    setDocData(null);
                                    setAttachDocModal(false);
                                }}
                                className="ri-close-fill text-gm-45 text-[30px]"
                            ></i>
                        </div>
                        <p className="text-xl font-rocGroteskMedium text-center mb-6">
                            Upload File
                        </p>
                        <div className="relative px-10 ">
                            <div className="relative flex flex-col items-center cursor-pointer justify-center mb-4 py-12 px-6 text-center text-sm rounded border border-dashed border-slate-200">
                                <i className="ri-upload-cloud-2-line text-slate-500 text-4xl mb-4"></i>
                                <p className="mb-1 font-rocGroteskMedium">
                                    <span>Click to upload</span>{" "}
                                    <span className="text-tradeally-neutral-200">
                                        or drag & drop
                                    </span>
                                </p>
                                <p className="text-tradeally-neutral-200  font-rocGroteskMedium">
                                    Supported formats: PDF, CSV, DOCX & TXT. File size limit: 25MB
                                </p>
                                <input
                                    type="file"
                                    accept=".pdf,.doc,.docx,.csv"
                                    className="absolute top-0 w-full outline-none h-full opacity-0 cursor-pointer"
                                    id="uploadDoc"
                                    onChange={(evt) => {
                                        if (
                                            !isFileSizeLessThanOrEqualTo(
                                                evt.target.files?.[0] as File,
                                                25
                                            )
                                        ) {
                                            toast.custom((t) => (
                                                <CustomToast
                                                    t={t}
                                                    message={"File to large"}
                                                    type={"error"}
                                                />
                                            ));
                                            return;
                                        }

                                        if (evt.target.files?.[0]) {
                                            setDocData(evt.target.files?.[0] as File);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {docData && (
                            <div className="mt-4 p-4 rounded border border-slate-200 mx-10 flex items-center gap-4">
                                {displayFileIcon(
                                    docData?.type?.split("/")?.slice(-1)?.[0],
                                    undefined
                                )}
                                <div className="w-full flex justify-between items-start">
                                    <div>
                                        <p className="text-sm font-rocGroteskMedium ">
                                            {truncate(docData?.name, { length: 34 })}
                                        </p>
                                        <p className="text-xs font-rocGroteskMedium text-tradeally-neutral-200">
                                            {convertBytes(docData?.size)}
                                        </p>
                                    </div>
                                    <i
                                        onClick={() => {
                                            const loading = uploading || creatingConversation;
                                            if (!loading) {
                                                setDocData(null);
                                            }
                                        }}
                                        className="ri-delete-bin-line text-slate-500 cursor-pointer"
                                    ></i>
                                </div>
                            </div>
                        )}
                        <div className="px-10 mt-6">
                            <Button
                                btnText={`Attach document`}
                                type={"button"}
                                btnClassname={" mb-3"}
                                onClick={() =>
                                    handleUpload(docData?.name as string, docData as File)
                                }
                                disabled={!Boolean(docData) || uploading || creatingConversation}
                                isLoading={uploading || creatingConversation}
                            />
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                onClick={() => {
                                    setDocData(null);
                                    setAttachDocModal(false);
                                }}
                                disabled={uploading || creatingConversation}
                            />
                        </div>
                    </form>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default RfqList;
