import { BlobProvider } from "@react-pdf/renderer";
import Button from "components/common/Button";
import { getCurrencyFromCurrencyCode, hasHTMLTag, sanitizeHtml } from "helpers";
import React from "react";
import PurchaseOrdersPDF from "./PDFPurchase";

type PurchaseOrderDetailsProps = {
    item: string;
    taId: string;
    successPreview: boolean;
    issuedDate: string;
    dueDate: string;
    selectedRFQData: { [key: string]: any };
    address: string;
    postalCode: string;
    userName: string;
    userEmail: string;
    city: string;
    country: string;
    containers: { [key: string]: any }[];
    tax: number;
    currency: string;
    taxAmount: number;
    shipping: number | string;
    totalAmount: number;
    orderDescription: string;
    title: string;
    btnTextOne: string;
    btnTextTwo?: string;
    onClickOne?: () => void;
    onClickTwo?: () => void;
    poNumber?: string;
    imageDetails: string;
    disabled: boolean;
    cancelDisabled?: boolean;
    isLoading: boolean;
    imageUrl?: string;
    vendorEmail?: string;
    vendorName?: string;
    saveItem?: string;
    setSaveBlob?: React.Dispatch<React.SetStateAction<File | undefined | any>>;
    showBtn?: boolean;
    btnTextThree?: string;
    onClickThree?: () => void;
    uploading?: boolean;
    handleUpload?: (fileName: any, file: any) => void;
    customerDetails?: { [key: string]: any };
    selectedRFQLabel?: string;
    userProfile?: { [key: string]: any };
    remainders?: number;
    setPdfUrl?: React.Dispatch<React.SetStateAction<string>>;
    setShowSendNow?: React.Dispatch<React.SetStateAction<boolean>>;
    handleRemoveImage?: () => void;
};

const PurchaseOrderDetails = ({
    item,
    taId,
    successPreview,
    issuedDate,
    dueDate,
    selectedRFQData,
    address,
    postalCode,
    userName,
    userEmail,
    city,
    country,
    containers,
    tax,
    currency,
    taxAmount,
    shipping,
    totalAmount,
    orderDescription,
    title,
    btnTextOne,
    btnTextTwo,
    onClickOne,
    onClickTwo,
    poNumber,
    imageDetails,
    disabled,
    cancelDisabled,
    isLoading,
    uploading,
    imageUrl,
    vendorEmail,
    vendorName,
    saveItem,
    setSaveBlob,
    showBtn,
    btnTextThree,
    onClickThree,
    handleUpload,
    customerDetails,
    selectedRFQLabel,
    userProfile,
    remainders,
    setPdfUrl,
    setShowSendNow,
    handleRemoveImage,
}: PurchaseOrderDetailsProps) => {
    return (
        <div className="flex justify-center">
            <div className="bg-white rounded-[8px] py-6 shadow-sm w-full">
                <div className=" px-6 md:px-16 sticky flex md:flex-row flex-col md:space-y-0 space-y-2 md:justify-between md:items-center pb-6 shadow-faintShadow">
                    <p className="font-rocGroteskMedium text-gm-50 md:text-2xl "> {title}</p>
                    <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-2  whitespace-nowrap  sm:space-x-3 items-center">
                        {btnTextOne.toLowerCase() === "keep editing" && !showBtn && (
                            <Button
                                btnText={btnTextThree}
                                type={"button"}
                                btnClassname="!py-2 md:border-none border border-n-20  cursor-pointer !bg-white !text-gm-50  !font-rocGroteskMedium !px-4"
                                onClick={onClickThree}
                                // isLoading={isLoading}
                                disabled={isLoading}
                            />
                        )}
                        {btnTextOne.toLowerCase() === "keep editing" && (
                            <Button
                                btnText={btnTextOne}
                                type={"button"}
                                btnClassname="!py-2 cursor-pointer !bg-white !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={onClickOne}
                                disabled={disabled}
                                icon={<i className="ri-edit-line"></i>}
                            />
                        )}

                        {btnTextTwo && (
                            <BlobProvider
                                document={
                                    <PurchaseOrdersPDF
                                        imageDetails={imageUrl}
                                        item={saveItem}
                                        selectedRFQData={selectedRFQData}
                                        issuedDate={issuedDate}
                                        dueDate={dueDate}
                                        address={address}
                                        postalCode={postalCode}
                                        userName={userName}
                                        userEmail={userEmail}
                                        city={city}
                                        vendorEmail={vendorEmail}
                                        vendorName={vendorName}
                                        country={country}
                                        containers={containers}
                                        tax={tax}
                                        currency={currency}
                                        taxAmount={taxAmount}
                                        shipping={shipping}
                                        totalAmount={totalAmount}
                                        orderDescription={orderDescription}
                                        taId={taId}
                                        tagNumber={""}
                                        title={""}
                                        status={""}
                                    />
                                }
                            >
                                {({ blob, url }) => {
                                    return (
                                        <Button
                                            btnText={btnTextTwo}
                                            type={"button"}
                                            btnClassname="!py-2 cursor-pointer  !font-rocGroteskMedium !px-4"
                                            onClick={() => {
                                                if (blob) {
                                                    const pdfUrl = new File(
                                                        [blob],
                                                        "purchase-order",
                                                        {
                                                            lastModified: new Date().getTime(),
                                                            type: blob?.type,
                                                        }
                                                    );
                                                    setSaveBlob(pdfUrl);
                                                }
                                                onClickTwo();
                                            }}
                                            disabled={disabled}
                                            isLoading={isLoading}
                                        />
                                    );
                                }}
                            </BlobProvider>
                        )}
                    </div>
                </div>
                <div className="px-6 md:px-16 h-[calc(100vh-132px)] overflow-y-auto pt-10">
                    <div className="flex justify-between items-center">
                        <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-3 sm:space-x-6 items-center mb-6 justify-center">
                            <div className="border border-dashed rounded-[4px]  border-[n-40] w-[128px] h-[128px] flex items-center justify-center">
                                <div className="">
                                    {imageDetails === "" ? (
                                        <i className="ri-image-add-fill text-[35px] text-tradeally-neutral-200"></i>
                                    ) : (
                                        <img src={imageDetails} alt="business-logo" />
                                    )}
                                </div>
                            </div>
                        </div>
                        {successPreview ? (
                            <div className="bg-[#F0FDF4] px-2 py-1 rounded-[4px] cursor-pointer">
                                <p className="text-[#16A34A] text-[14px]">PO Created</p>
                            </div>
                        ) : (
                            <div className="bg-slate-100 md:block hidden px-4 py-1 rounded-[4px] cursor-pointer">
                                <p className="text-slate-500 text-[14px]">Draft</p>
                            </div>
                        )}
                    </div>
                    <div className="mt-10 flex md:flex-row flex-col  w-full border-b  pb-5">
                        <div className="space-y-4 md:w-[60%] w-full">
                            <div>
                                <h6 className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                    RFQ {taId}
                                </h6>
                            </div>
                            <div>
                                <h6 className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                    PO Number
                                </h6>
                                <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                    {poNumber ? poNumber : "----"}
                                </p>
                            </div>
                            <div>
                                <h6 className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                    Currency
                                </h6>
                                <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                    {currency}
                                </p>
                            </div>
                        </div>
                        <div className="space-y-4 md:w-[35%] w-full ">
                            <div>
                                <h6 className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                    From
                                </h6>
                                <p className="text-gm-50 mt-4 font-rocGroteskMedium text-[14px]">
                                    {userName}
                                </p>
                                <h6 className="text-slate-500 font-rocGroteskRegular text-[14px]">
                                    {userEmail}
                                </h6>
                            </div>
                            {selectedRFQData?.vendorDetails && (
                                <div>
                                    <h6 className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                        To
                                    </h6>
                                    <h6 className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                        {selectedRFQData?.vendorDetails?.profile?.businessName
                                            ? selectedRFQData?.vendorDetails?.profile?.businessName
                                            : `${selectedRFQData?.vendorDetails?.firstName} ${" "}
                                                    ${selectedRFQData?.vendorDetails?.lastName}`}
                                    </h6>
                                    <p className="text-slate-500 font-rocGroteskRegular text-[14px]">
                                        {selectedRFQData?.vendorDetails?.email}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className=" flex md:flex-row flex-col md:space-y-0 space-y-2 mt-5 ">
                        <div className="md:w-[60%] w-full">
                            <h6 className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                Issued Date
                            </h6>
                            <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                {issuedDate}
                            </p>
                        </div>
                        <div className="md:w-[35%] w-full">
                            <h6 className="text-slate-500 font-rocGroteskMedium text-[14px]">
                                Due Date
                            </h6>
                            <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                {dueDate}
                            </p>
                        </div>
                    </div>
                    <div className="mt-6 pb-5 border-b">
                        <p className="text-[14px] mb-2 text-slate-500 font-rocGroteskMedium">
                            Ship to
                        </p>
                        <div className="flex md:flex-row flex-col md:space-y-0 space-y-2">
                            <div className="md:w-[60%] w-full md:space-y-0 space-y-2">
                                <p className="text-gm-50 md:w-[80%] font-rocGroteskRegular text-[14px]">
                                    Street: <span className="font-rocGroteskMedium">{address}</span>
                                </p>
                                <p className="text-gm-50  font-rocGroteskRegular text-[14px]">
                                    Postal Code:{" "}
                                    <span className="font-rocGroteskMedium">{postalCode}</span>
                                </p>
                            </div>
                            <div className="md:w-[35%] w-full md:space-y-0 space-y-2 ">
                                <p className="text-gm-50  font-rocGroteskRegular text-[14px]">
                                    City: <span className="font-rocGroteskMedium">{city}</span>
                                </p>
                                <p className="text-gm-50  font-rocGroteskRegular text-[14px]">
                                    Country:{" "}
                                    <span className="font-rocGroteskMedium">{country}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="  mt-10 gap-10 pb-6 border-b border-slate-100 w-full">
                        <p className="text-[22px] pb-6 font-rocGroteskMedium text-gm-50">
                            Quote for {item}
                        </p>
                        <div className="">
                            <div className="text-[12px] md:flex justify-between hidden w-full font-rocGroteskMedium text-slate-500 uppercase">
                                <p className="w-[50%]">Items/services</p>
                                <div className="flex w-[45%] justify-between">
                                    <p>QTY</p>
                                    <p>RATE</p>
                                    <p>TOTAL</p>
                                </div>
                            </div>
                            <div className="text-[12px] md:hidden flex justify-between w-full font-rocGroteskMedium text-slate-500 uppercase">
                                <p className="">Items/Quantity</p>
                                <div className=" justify-between">
                                    <p>TOTAL</p>
                                </div>
                            </div>
                            {containers?.map((d, index) => (
                                <div key={index}>
                                    <div className="text-[14px] md:flex hidden pb-3 border-b border-slate-100 mt-4  justify-between w-full font-rocGroteskMedium text-gm-50">
                                        <p className="w-[50%]">{d?.name}</p>
                                        <div className="flex w-[45%] justify-between">
                                            <p>{d?.qty}</p>
                                            <p>
                                                {getCurrencyFromCurrencyCode(d?.price?.currency)}
                                                {d?.price?.amount}
                                            </p>
                                            <p>
                                                {getCurrencyFromCurrencyCode(d?.subtotal?.currency)}
                                                {d?.subtotal?.amount}
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        className="text-[14px] md:hidden flex pb-3 border-b border-slate-100 mt-4 justify-between w-full font-rocGroteskMedium text-gm-50"
                                        key={index}
                                    >
                                        <p className="">
                                            {d?.name} - {d?.qty}
                                        </p>
                                        <div className="flex justify-between">
                                            <p>
                                                {getCurrencyFromCurrencyCode(d?.subtotal?.currency)}
                                                {d?.subtotal?.amount}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="mt-5 space-y-3  boder-slate-100 pb-5">
                        <div className="flex justify-between text-[14px] font-rocGroteskMedium text-gm-50">
                            <div className="flex space-x-1 items-center">
                                <div className="flex items-center">
                                    <p className="text-slate-500">Tax ({tax} %)</p>
                                </div>
                            </div>
                            <p>
                                {getCurrencyFromCurrencyCode(currency)}
                                {taxAmount}
                            </p>
                        </div>
                        <div className="flex justify-between text-[14px] pb-4 border-b font-rocGroteskMedium text-gm-50">
                            <p className="text-slate-500">Shipping and Handling</p>
                            <p>
                                {getCurrencyFromCurrencyCode(currency)}
                                {shipping !== "" ? shipping : 0}
                            </p>
                        </div>
                        <div className="flex justify-between text-[16px] font-rocGroteskMedium text-gm-50">
                            <p className="text-slate-500 ">Total Amount</p>
                            <p>
                                {getCurrencyFromCurrencyCode(currency)}
                                {totalAmount}
                            </p>
                        </div>
                    </div>
                    <div className="py-10">
                        <h6 className="flex justify-between text-[14px] font-rocGroteskMedium text-gm-50">
                            Purchase Order Terms and Conditions
                        </h6>
                        <div className="space-y-0.5 text-[14px] font-rocGroteskMedium text-slate-500">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: hasHTMLTag(orderDescription)
                                        ? sanitizeHtml(orderDescription).sanitizedHTML?.join("")
                                        : orderDescription,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchaseOrderDetails;
